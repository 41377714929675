// @flow

export default {
    type: 'menu',
    max: 2,
    pinned: [{
        type: 'workload',
        pinned: true
    },{
        type: 'project',
        pinned: true
    }],
    prototype: {
        type: 'router',
        routes: {
            workload: {
              filter: {type: 'object', prototype: 'string'}
            },
            project: {
              filter: {type: 'object', prototype: 'string'}
            },
        }
    }
};
