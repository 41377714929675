import React from 'react';
import PropTypes from 'prop-types';
import QuestionItem from './item';
import QuestionForm from './form';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import { toJS } from 'mobx';
const styles = {
    container: {
        margin: '1px 0',
    },
    header: {},
    bullet: {
        display: 'inline-block',
        width: '55px',
        textAlign: 'center',
        color: colors.userCold,
    },
    label: {
        display: 'inline-block',
        marginRight: '20px',
        lineHeight: '40px',
    },
    list: {
        marginLeft: '55px',
    },
    add: {
        display: 'inline-block',
        marginLeft: '10px',
        padding: '3px 5px',
        color: 'white',
        borderRadius: '3px',
        lineHeight: '16px',
        background: colors.userCold,
        fontSize: '13px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 10px',
        cursor: 'pointer',
    },
    icon: {
        width: '16px',
        height: '16px',
        float: 'left',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.management.questions.question.embed';

    static contextTypes = {
        createQuestion: PropTypes.func.isRequired,
        updateQuestion: PropTypes.func.isRequired,
        removeQuestion: PropTypes.func.isRequired,
    };

    static propTypes = {
        store: PropTypes.object.isRequired,
        parent: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(PropTypes.string).isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };

    state = { addHover: false };

    render() {
        const parent = toJS(this.props.store.questionsMap.get(this.props.parent));

        if (!parent) {
            return null;
        }

        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <span style={styles.bullet}>▸</span>

                    <span style={styles.label}>{this.props.label}</span>

                    {this.props.children.length > 0 ||
                    toJS(this.props.store.questionsMap.get(this.props.parent)).payload.embedded ? null : (
                        <span
                            style={Object.assign({}, styles.add, { opacity: this.state.addHover ? '1' : '0.67' })}
                            onClick={event => this.refs['questionForm'].open({ category: parent.category })}
                            onMouseEnter={event => this.setState({ addHover: true })}
                            onMouseLeave={event => this.setState({ addHover: false })}
                        >
                            <Icon type="do.create" color="white" style={styles.icon} />
                            &nbsp;Embed&nbsp;question
                        </span>
                    )}
                </div>

                <div style={styles.list}>
                    {this.props.children.map((id, index) => (
                        <QuestionItem
                            key={id}
                            id={id}
                            index={index + 1}
                            embedded={{}}
                            store={this.props.store}
                            onEdit={question => this.refs['questionForm'].open(toJS(question))}
                            onRemove={event => this.context.removeQuestion(id)}
                        />
                    ))}
                </div>

                <QuestionForm
                    ref="questionForm"
                    onSubmit={data => {
                        data.payload.embedded = {
                            question: this.props.parent,
                            value: this.props.value,
                        };

                        if (data.id) {
                            this.context.updateQuestion(data.id, data.payload);
                        } else {
                            this.context.createQuestion(parent.category, data.payload);
                        }
                    }}
                />
            </div>
        );
    }
}
