// @flow

import React from 'react';
import PropTypes from 'prop-types';
import muiTheme from './muiTheme';

export default class ThemeProvider extends React.PureComponent<any, any> {
    static displayName = 'app.theme.provider';

    static childContextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };

    getChildContext () {
        return { muiTheme };
    }

    render () {
        return React.Children.only(this.props.children);
    }
}
