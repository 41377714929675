import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'app/theme/colors';
import Header from './message/header';
import Actions from './message/actions';

const styles = {
    container: {
        position: 'relative',
        maxWidth: '700px',
        padding: '5px 5px 15px 35px',
        borderRadius: '5px',
    },
    alt: {
        background: Colors.grey100,
    },
    content: {
        color: Colors.grey700,
        padding: '0 0 3px 10px',
    },
    attachments: {
        marginRight: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'app.views.discussion.thread.message';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        child: PropTypes.bool,
        style: PropTypes.object,
        onEdit: PropTypes.func,
        handleDelete: PropTypes.func.isRequired,
        handleReply: PropTypes.func,
    };

    state = {
        hover: false,
    };

    render() {
        const style = _.extend({}, styles.container, this.props.style);

        return (
            <div
                style={style}
                onMouseLeave={event => this.setState({ hover: false })}
                onMouseEnter={event => this.setState({ hover: true })}
            >
                <Header entity={this.props.entity} />
                <div style={styles.content}>{this.props.entity.payload.content}</div>
                {this.renderActions()}
            </div>
        );
    }

    renderActions() {
        if (!this.state.hover) return null;

        return (
            <Actions
                entity={this.props.entity}
                onEdit={null /*this.props.onEdit*/}
                onRemove={this.props.handleDelete}
                handleReply={this.props.handleReply}
                child={this.props.child}
            />
        );
    }
}
