// @flow

import type { EntityInnerTarget, LinkMandateLevel } from '@deecision/deefind-types/model';

const SCORE = { o: 0, m: 0.25, x: 0.5, c: 0.5, a: 0.75, b: 1, s: 2, i: 3 };
const BIJECTION = { i: 13, s: 7, b: 3, a: 2, c: 1, x: 1, m: 1, o: 0 };
const BONUS = { is: 5, si: 5, ib: 4, bi: 4, ia: 3.75, ai: 3.75, sb: 3, bs: 3 };

export function makePathStrength(levels: LinkMandateLevel[], start: ?number, end: ?number): number {
    return (
        (levels.length > 1 ? makeRelationStrength(levels) : makeMandateStrength(levels)) +
        makeSeniorityStrength(levels, start, end)
    );
}

export function makeLevelsStrength(levels: LinkMandateLevel[][]): number {
    let strength = 0;

    for (const bits of levels) {
        if (bits.length > 1 && bits[0] !== bits[1] && ['i', 's'].includes(bits[0])) {
            strength += 0.5;
        }
    }

    return strength;
}

export function makeTargetStrength(target: EntityInnerTarget): number {
    if (target.type === 'company') {
        if (target.turnover) {
            if (target.flags.includes('listed')) {
                return 0.4;
            }

            if (target.flags.includes('holding')) {
                return 0.3;
            }

            return 0.2;
        }

        if (target.flags.includes('holding')) {
            return 0.1;
        }
    }

    return 0;
}

function makeSeniorityStrength(levels: LinkMandateLevel[], start: ?number, end: ?number): number {
    if (! start || ! end || levels.filter(level => !['i', 's', 'b'].includes(level)).length) {
        return 0;
    }

    // todo: test this
    end = Math.min(end, new Date().getFullYear());

    const duration = Math.abs(end - start);

    if (duration <= 2) return 0;
    if (duration <= 5) return 0.5;
    if (duration <= 10) return 1;
    if (duration <= 15) return 2;
    if (duration <= 20) return 3;

    return 4;
}

function makeMandateStrength(levels: LinkMandateLevel[]): number {
    return levels.length ? SCORE[levels[0]] || 0 : 0;
}

function makeRelationStrength(levels: LinkMandateLevel[]): number {
    const source = levels[0] || 'o';
    const target = levels[levels.length - 1] || 'o';

    if (source && source === target && BIJECTION[source]) {
        return BIJECTION[source];
    }

    return (SCORE[source] || 0) + (SCORE[target] || 0) + (BONUS[source + target] || 0);
}
