// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import DataProvider from 'app/layout/providers/data';

const styles = {
    menu: {
        width: '200px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'layout.application.header.cursor.menu';

    static propTypes = {
        anchor: PropTypes.object,
        open: PropTypes.bool.isRequired,
        value: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Popover
                open={this.props.open}
                anchorEl={this.props.anchor}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                onRequestClose={this.props.onClose}
            >
                <Menu style={styles.menu}>
                    {this.props.items.map(id => (
                        <DataProvider
                            key={id}
                            service="cleerance"
                            model="team"
                            id={id}
                            scope="public"
                            renderValue={value => (
                                <MenuItem
                                    value={id}
                                    key={id}
                                    primaryText={value.profile.name}
                                    onClick={() => this.props.onChange(id)}
                                />
                            )}
                        />
                    ))}
                </Menu>
            </Popover>
        );
    }
}
