// @flow

import React from 'react';
import pool from './pool';

import omit from 'lodash/omit';

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.theme.icon';

    props: {
        type: string,
        size?: number,
        color?: string,
        style?: Object
    };

    render () {
        const Icon = pool[this.props.type];
        const props = omit(this.props, 'type');

        if (! Icon) {
            throw new Error(`Invalid app theme icon type: ${this.props.type}.`);
        }

        return (<Icon { ...props } />);
    }
}
