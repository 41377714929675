import React from 'react';
import Application from 'app/layout/application';

import Admin from './admin';
import t from 'app/translation';
import { inject, observer } from 'mobx-react';

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'cleerance';

    render() {
        const flags = this.props.global.flags.get();

        return (
            <Application
                path="cleerance"
                title="Cleerance"
                index="admin"
                entries={[
                    {
                        path: 'admin',
                        icon: 'cleerance.admin',
                        label: t('cleerance.admin.label'),
                        screen: Admin,
                        access: flags.includes('cleerance.admin'),
                    },
                ]}
            />
        );
    }
}
