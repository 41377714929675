// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Scope from 'app/layout/providers/scope';

import SearchHandle from 'dna/explorer/search/handle';
import SearchDisplay from 'dna/explorer/search/display';
import EntityHandle from 'dna/entity/handle';
import EntityDisplay from 'dna/entity/display';

import Relation from './relation';

// POC
import DeefindProvider from 'app/layout/providers/deefind';
import Devtools from 'mobx-react-devtools';

import { inject, observer } from 'mobx-react';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.stack';

    static contextTypes = {
        selectHandle: PropTypes.func.isRequired,
        selectSearch: PropTypes.func.isRequired,
    };

    props: {
        state: {
            form?: Object,
            result?: Object,
            entities?: {
                items: Object[],
                cursor: number
            },
            depth?: number,
            search: any
        },
        style: Object
    };

    static defaultProps = {
        state: {}
    };

    render () {
        return (
            <div style={ this.props.style }>
                { this.renderSearch() }
                { this.renderEntities() }
            </div>
        );
    }

    renderSearch () {
        if (!this.props.state || this.props.state.type !== 'search') {
            return;
        }

        if (this.props.state.search && this.props.state.search.open) {
            return (
                <Scope path="search" type="object">
                    <SearchDisplay state={ this.props.state } />
                </Scope>
            );
        }

        return (
            <Scope path="search" type="object">
                <SearchHandle
                    state={ this.props.state }
                    onSelect={ event => this.context.selectSearch() }
                />
            </Scope>
        );
    }

    renderEntities () {
        if (! this.props.state ||
            ! this.props.state.entities ||
            ! this.props.state.entities.items ||
            ! this.props.state.entities.items.length
        ) {
            return;
        }

        const items = this.props.state.entities.items || [];
        const cursor = this.props.state.entities.cursor || 0;
        const open = !this.props.state.search || !this.props.state.search.open;

        const stack = [];

        for (let index = 0; index < items.length; index ++) {
            if (! items[index].id) {
                continue;
            }

            if (index > 0 && items[index].id && items[index - 1].id) {
                stack.push(
                    <Relation
                        key={ `r${index}` }
                        source={ items[index - 1].id }
                        target={ items[index].id }
                    />
                );
            }

            if (open && index === cursor) {
                stack.push(
                    <Scope key={ `e${index}` } path={ `entities.items.${index}` } type="object">
                        <EntityDisplay id={ items[index].id } />
                    </Scope>
                );
            }

            else {
                stack.push(
                    <EntityHandle
                        key={ `h${index}` }
                        id={ items[index].id }
                        onSelect={ event => this.context.selectHandle(index) }
                    />
                );
            }
        }

        return stack;
    }
}
