// @flow

import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        borderTop: '1px solid ' + colors.grey300
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.screen.tabs.placeholder';

    render () {
        return (
            <div style={ styles.container } />
        )
    }
}
