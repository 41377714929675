import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import Infos from './cockpit/infos';
import Identity from './cockpit/identity';
import Biography from './cockpit/biography';
import Preesm from './cockpit/preesm';
import Relations from './cockpit/relations';
import Equity from './cockpit/equity';
import Companies from './cockpit/companies';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.cockpit';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <DeefindProvider
                state={this.props.state}
                type="network"
                id={this.props.entity.id}
                renderEntity={(network, state) => (
                    <div>
                        <div style={{ verticalAlign: 'top', display: 'inline-block', width: '33%' }}>
                            <Infos entity={this.props.entity} network={network} />
                            <Identity entity={this.props.entity} network={network} />
                            <Preesm entity={this.props.entity} network={network} />
                            <Biography entity={this.props.entity} network={network} />
                        </div>
                        <div style={{ verticalAlign: 'top', display: 'inline-block', width: '67%' }}>
                            <Equity entity={this.props.entity} network={network} />
                            <div style={{ verticalAlign: 'top', display: 'inline-block', width: '50%' }}>
                                <Relations entity={this.props.entity} network={network} />
                            </div>
                            <div style={{ verticalAlign: 'top', display: 'inline-block', width: '50%' }}>
                                {/* <Deals entity={this.props.entity} network={network} /> */}
                                <Companies entity={this.props.entity} network={network} />
                            </div>
                        </div>
                    </div>
                )}
            />
        );
    }
}
