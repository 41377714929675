
import get from 'lodash/get';
import set from 'lodash/set';
import size from 'lodash/size';
import cloneDeep from 'lodash/cloneDeep';

export default function (data?: Object, focus?: string) {
    const state = {
        data: data ? cloneDeep(data) : {},
        errors: {},
        focus: focus
    };

    const listeners = {
        change: [],
        focus: []
    };

    return {
        reset (data?: Object, focus?: string) {
            state.data = data ? cloneDeep(data) : {};
            state.focus = focus;

            return this;
        },

        getData (): Object {
            return state.data;
        },

        getValue (path: string): any {
            return get(state.data, path);
        },

        setValue (path: string, value: any) {
            state.data = set(state.data, path, cloneDeep(value));
            listeners.change.forEach(listener => listener(state.data));

            return this;
        },

        onChange (listener) {
            listeners.change.push(listener);

            return this;
        },

        hasFocus (path: string): ?string {
            return state.focus === path;
        },

        setFocus (focus: ?string) {
            state.focus = focus;
            listeners.focus.forEach(listener => listener(focus));

            return this;
        },

        onFocus (listener) {
            listeners.focus.push(listener);

            return this;
        },

        setError (path: string, label: ?string): void {
            if (label) {
                state.errors[path] = label;
            } else {
                delete state.errors[path];
            }
        },

        getError (path: string): ?string {
            return state.errors[path];
        },

        countErrors (): number {
            return size(state.errors);
        },
    };
}
