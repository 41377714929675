import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import Header from './tabs/header';
import IdentityTab from './tabs/identity';
import RelationsTab from './tabs/relations';
import MandatesTab from './tabs/mandates';
import FinancialTab from './tabs/financial';
import DealsTab from 'dna/entity/common/deals/tab';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.tabs';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        children: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div>
                <Header entity={this.props.entity} state={this.props.state} />
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        switch (this.props.state.tab || 0) {
            case 0:
                return <IdentityTab key="identity" entity={this.props.entity} />;

            case 1:
                return <FinancialTab key="financial" entity={this.props.entity} view={this.props.state.financial} children={this.props.children} />;

            case 2:
                return (
                    <DeefindProvider
                        state={this.props.state}
                        type="network"
                        id={this.props.entity.id}
                        depth={1}
                        renderEntity={(network, state) => (
                            <MandatesTab key="mandates" entity={this.props.entity} network={network} state={this.props.state} />
                        )}
                    />
                );

            case 3:
                return (
                    <DeefindProvider
                        state={this.props.state}
                        type="network"
                        id={this.props.entity.id}
                        depth={2}
                        renderEntity={(network, state) => (
                            <RelationsTab key="relations" entity={this.props.entity} network={network} state={this.props.state} />
                        )}
                    />
                );

            case 4:
                return (
                    <DeefindProvider
                        state={this.props.state}
                        type={(this.props.state.dealsView === 'direct')?'deals':'dealsCompany'}
                        id={this.props.entity.id}
                        depth={(this.props.state.dealsView === 'direct')?1:2}
                        renderEntity={(deals, state) => (
                            <DealsTab key="deals" entity={this.props.entity} deals={deals} state={this.props.state}/>
                        )}
                    />
                );
        }
    }
}
