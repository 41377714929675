// @flow

import type { DriveStore, GlobalStore } from '../core/types';
import type StateStore from 'app/stores/state';
import type AuthStore from 'app/stores/auth';
import type { ObservableMap, IObservableValue } from 'mobx';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';

import { action, runInAction } from 'mobx';
import { observable, toJS } from 'mobx';
import { without, set } from 'lodash';
import cleerance from '@deecision/cleerance-utils';

const PATH = 'teem.prospects';

export default class TeemProspectsStore {
    global: GlobalStore;
    state: StateStore;
    auth: AuthStore;
    drive: DriveStore;

    team: ?string;
    loading: IObservableValue<boolean>;
    prospects: ObservableMap<string, Object>;
    fields: ObservableMap<string, Object>;
    attendees: ObservableMap<string, Object[]>;
    fetched: any;

    constructor(global: GlobalStore, state: StateStore, auth: AuthStore, drive: DriveStore) {
        this.global = global;
        this.state = state;
        this.auth = auth;
        this.drive = drive;

        this.team = null;
        this.loading = observable.box(false);
        this.prospects = observable.map();
        this.fields = observable.map();
        this.attendees = observable.map();
        this.fetched = [];
    }

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        runInAction(() => {
            this.team = cursor.team;
            this.loading.set(true);
            this.prospects.clear();
            this.fields.clear();
            this.attendees.clear();
            this.fetched = [];
        });

        if (!cleerance.resolveFlags(session, cursor).includes('teem.access')) {
            return;
        }

        const promises = [];

        for (const project of await this.global.execute('teem', 'prospectings.list')) {
            this.prospects.set(project.id, project);
            promises.push(this.fetchAttendees(project.id));
            promises.push(this.fetchProspect(project.id));
        }

        await Promise.all(promises);
        this.loading.set(false);
    }

    // workspace management

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };

    @action openProspect = (id: string): void => {
        const prospect = this.prospects.get(id);

        if (!prospect) {
            console.error('invalid prospect id', id);
        }

        const menu = this.state.enter(PATH, 'menu');
        const data = menu.read() || {};

        let cursor = -1;

        (data.items || []).forEach((item, index) => {
            if (cursor < 0 && item.type === 'display' && item.id === id) {
                cursor = index;
            }
        });

        if (cursor < 0) {
            menu.assign(':next', { type: 'display', id }).select(':last');
        } else {
            menu.select(cursor);
        }
    };

    @action selectProject = async (id: string): Promise<any> => {
        await this.fetchProspect(id);
        const project = toJS(this.prospects.get(id));

        if (!project) {
            return;
        }

        await this.global.open({
            teams: [project.teamUid],
            accounts: [project.ownerUid],
            interventions: (toJS(this.attendees.get(id)) || []).map(attendee => attendee.intervention),
        });
    };

    @action fetchAttendees = async (project: string): Promise<any> => {
        const attendees = await this.global.execute('teem', 'attendees.list', { project });
        this.attendees.set(project, attendees);

        return attendees;
    };

    // prospects management
    @action fetchProspect = async (id: string): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const prospect = await this.global.execute('teem', 'prospectings.get', { id });
            const attendees = await this.global.execute('teem', 'attendees.list', { project: id });

            const events = [];

            for (const event of prospect.events) {
                this.fields.set(event.id, event);
                events.push(event.id);
            }

            delete prospect.events;
            prospect.events = events;

            const milestones = [];

            for (const milestone of prospect.milestones) {
                this.fields.set(milestone.id, milestone);
                milestones.push(milestone.id);
            }

            delete prospect.milestones;
            prospect.milestones = milestones;

            const meetings = [];

            for (const meeting of prospect.meetings) {
                this.fields.set(meeting.id, meeting);
                meetings.push(meeting.id);
            }

            delete prospect.meetings;
            prospect.meetings = meetings;

            this.attendees.set(prospect.id, attendees);
            this.prospects.set(prospect.id, prospect);
            this.fetched.push(prospect.id);

            resolve(this.prospects.get(prospect.id));
        });
    };

    @action createProspect = async (data: Object): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const prospect = await this.global.execute('teem', 'prospectings.create', {
                team: this.team,
                payload: data.payload,
            });

            delete prospect.events;
            delete prospect.milestones;
            delete prospect.meetings;

            this.prospects.set(prospect.id, prospect);

            resolve(this.prospects.get(prospect.id));
        });
    };

    @action updateProspect = async (id: string, data: Object): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const prospect = await this.global.execute('teem', 'prospectings.update', { id, payload: data.payload });

            delete prospect.events;
            delete prospect.milestones;
            delete prospect.meetings;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));

            resolve(this.prospects.get(prospect.id));
        });
    };

    // @todo refacto by applyProspect
    @action startProspect = async (id: string): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const prospect = await this.global.execute('teem', 'prospectings.apply', { id, transition: 'start' });

            delete prospect.events;
            delete prospect.milestones;
            delete prospect.meetings;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));

            resolve(this.prospects.get(prospect.id));
        });
    };

    @action endProspect = async (id: string, status: string): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const prospect = await this.global.execute('teem', 'prospectings.apply', { id, transition: status });

            delete prospect.events;
            delete prospect.milestones;
            delete prospect.meetings;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));

            resolve(this.prospects.get(prospect.id));
        });
    };

    @action commentProspect = async (prospectId: string, data: Object, parent: ?string): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const prospect = await this.global.execute('teem', 'prospectings.comment', {
                id: prospectId,
                payload: data,
                parent,
            });

            delete prospect.events;
            delete prospect.milestones;
            delete prospect.meetings;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));

            resolve(prospect);
        });
    };

    @action removeComment = async (prospectId: string, commentId: string): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const res = await this.global.execute('teem', 'comments.remove', { id: commentId });

            this.fetchProspect(prospectId);

            resolve(res);
        });
    };

    // events management

    @action createEvent = async (prospectId: string, data: Object): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const event = await this.global.execute('teem', 'events.create', { id: prospectId, payload: data.payload });

            this.fields.set(event.id, event);
            const prospect = this.prospects.get(prospectId);
            if (prospect) {
                if (!prospect.events) {
                    prospect.events = [];
                }
                prospect.events.push(event.id);
            }

            resolve(event);
        });
    };

    @action updateEvent = async (id: string, data: Object): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const event = await this.global.execute('teem', 'events.update', { id, payload: data.payload });

            this.fields.set(event.id, event);

            resolve(event);
        });
    };

    @action removeEvent = async (prospectId: string, id: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const event = await this.global.execute('teem', 'events.remove', { id });

            const prospect = this.prospects.get(prospectId);
            if (prospect) {
                prospect.events = without(prospect.events, id);
            }
            this.fields.delete(id);

            resolve(event);
        });
    };

    @action commentEvent = async (eventId: string, data: Object, parent: ?string): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const event = await this.global.execute('teem', 'events.comment', { id: eventId, payload: data, parent });

            this.fields.set(event.id, Object.assign(toJS(this.fields.get(event.id)) || {}, event));

            resolve(event);
        });
    };

    // milestones management

    @action createMilestone = async (prospectId: string, data: Object): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const milestone = await this.global.execute('teem', 'milestones.create', {
                id: prospectId,
                payload: data.payload,
            });

            this.fields.set(milestone.id, milestone);
            const prospect = this.prospects.get(prospectId);
            if (prospect) {
                if (!prospect.milestones) {
                    prospect.milestones = [];
                }

                prospect.milestones.push(milestone.id);
            }

            resolve(milestone);
        });
    };

    @action updateMilestone = async (id: string, data: Object): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const milestone = await this.global.execute('teem', 'milestones.update', { id, payload: data.payload });

            this.fields.set(milestone.id, milestone);

            resolve(milestone);
        });
    };

    @action removeMilestone = async (prospectId: string, id: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const prospectId = await this.global.execute('teem', 'milestones.remove', { id });

            const prospect = this.prospects.get(prospectId);

            if (prospect) {
                prospect.milestones = without(prospect.milestones, id);
            }

            this.fields.delete(id);

            resolve();
        });
    };

    @action commentMilestone = async (milestoneId: string, data: Object, parent: ?string): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const milestone = await this.global.execute('teem', 'milestones.comment', {
                id: milestoneId,
                payload: data,
                parent,
            });

            this.fields.set(milestone.id, Object.assign(toJS(this.fields.get(milestone.id)) || {}, milestone));

            resolve(milestone);
        });
    };

    @action checkMilestone = async (milestoneId: string, list: []): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const milestone = await this.global.execute('teem', 'milestones.check', { id: milestoneId, list });

            const field = this.fields.get(milestone.id);
            if (field) {
                field.payload.checklist = list;
            }

            resolve(milestone);
        });
    };

    // meetings management

    @action createMeeting = async (prospectId: string, data: Object): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const meeting = await this.global.execute('teem', 'meetings.create', {
                id: prospectId,
                payload: data.payload,
            });

            this.fields.set(meeting.id, meeting);
            const prospect = this.prospects.get(prospectId);
            if (prospect) {
                if (!prospect.meetings) {
                    prospect.meetings = [];
                }

                prospect.meetings.push(meeting.id);
            }

            resolve(meeting);
        });
    };

    @action updateMeeting = async (id: string, data: Object): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const meeting = await this.global.execute('teem', 'meetings.update', { id, payload: data.payload });
            this.fields.set(meeting.id, meeting);
            resolve(meeting);
        });
    };

    @action removeMeeting = async (prospectId: string, id: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            const prospectId = await this.global.execute('teem', 'meetings.remove', { id });
            const prospect = this.prospects.get(prospectId);
            if (prospect) prospect.meetings = without(prospect.meetings, id);
            this.fields.delete(id);
            resolve();
        });
    };

    @action commentMeeting = async (id: string, payload: Object, parent: ?string): Promise<Object> => {
        return new Promise(async (resolve, reject) => {
            const meeting = await this.global.execute('teem', 'meetings.comment', { id, payload, parent });
            this.fields.set(meeting.id, Object.assign(toJS(this.fields.get(meeting.id)) || {}, meeting));
            resolve(meeting);
        });
    };

    // files management

    @action getFileUrl = async (id: string): Promise<string> => {
        return this.drive.locate({ service: 'teem', bucket: 'teem', id });
    };

    @action downloadFile = async (id: string, name: string): Promise<void> => {
        return this.drive.download({ service: 'teem', bucket: 'teem', id }, name);
    };

    @action uploadFile = async (
        type: string,
        parent: string,
        name: string,
        mimeType: string,
        binary: string,
    ): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            let data = null;
            switch (type) {
                case 'prospect':
                    data = await this.global.execute('teem', 'prospectings.attach', { parent, name, type: mimeType });
                    break;
                case 'milestone':
                    data = await this.global.execute('teem', 'milestones.attach', { parent, name, type: mimeType });
                    break;
                case 'event':
                    data = await this.global.execute('teem', 'events.attach', { parent, name, type: mimeType });
                    break;
                case 'meeting':
                    data = await this.global.execute('teem', 'meetings.attach', { parent, name, type: mimeType });
                    break;
                default:
                    console.error('Error while uploading file', { type, parent, name, binary });
                    return;
            }

            switch (type) {
                case 'prospect':
                    const p = toJS(this.prospects.get(parent)) || {};
                    set(p, 'payload.files', data.files);
                    this.prospects.set(parent, p);

                    break;
                case 'milestone':
                case 'event':
                case 'meeting':
                    const em = toJS(this.fields.get(parent)) || {};
                    set(em, 'payload.files', data.files);
                    this.fields.set(parent, em);
            }

            await this.drive.upload({ service: 'teem', bucket: 'teem', id: data.id }, binary);

            resolve(data);
        });
    };

    @action deleteFile = async (type: string, parent: string, id: string): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            let data = null;

            switch (type) {
                case 'prospect':
                    data = await this.global.execute('teem', 'prospectings.detach', { parent, id });
                    break;

                case 'milestone':
                    data = await this.global.execute('teem', 'milestones.detach', { parent, id });
                    break;

                case 'event':
                    data = await this.global.execute('teem', 'events.detach', { parent, id });
                    break;

                case 'meeting':
                    data = await this.global.execute('teem', 'meetings.detach', { parent, id });
                    break;

                default:
                    console.error('Error while deleting file', { type, parent, id });
                    return;
            }

            switch (type) {
                case 'prospect':
                    const p = toJS(this.prospects.get(parent)) || {};
                    set(p, 'payload.files', data.files);
                    this.prospects.set(parent, p);
                    break;

                case 'milestone':
                case 'event':
                case 'meeting':
                    const em = toJS(this.fields.get(parent)) || {};
                    set(em, 'payload.files', data.files);
                    this.fields.set(parent, em);
            }

            resolve(data);
        });
    };

    @action createAttendee = async (project: string, profile: Object): Promise<void> => {
        const attendees = await this.global.execute('teem', 'attendees.create', { project, profile });
        // await this.auth.refreshInterventions(attendees.map(attendee => attendee.intervention));
        this.attendees.set(project, attendees);
    };

    @action removeAttendee = async (project: string, intervention: string): Promise<void> => {
        const attendees = await this.global.execute('teem', 'attendees.remove', { project, intervention });
        this.attendees.set(project, attendees);
    };
}
