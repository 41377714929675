// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import DataProvider from 'app/layout/providers/data';
import ListProvider from 'app/layout/providers/list';

const styles = {
    container: {
        margin: '10px 0',
    },
    item: {
        position: 'relative',
        lineHeight: '40px',
        padding: '0 15px',
        cursor: 'pointer',
        display: 'flex',
    },
    label: {
        flexGrow: '1',
    },
    size: {
        width: '35px',
        opacity: '0.75',
    },
    active: {
        background: colors.userCold,
        color: 'white',
    },
    hover: {
        background: colors.grey300,
    },
    arrow: {
        position: 'absolute',
        right: '-20px',
        top: '0',
        height: '0',
        width: '0',
        border: '20px solid transparent',
        borderRightWidth: '0',
        borderLeftColor: colors.userCold,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.list.select';

    static propTypes = {
        selection: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
    };

    state = { hover: null };

    render() {
        return (
            <div style={styles.container}>
                <ListProvider
                    service="cleerance"
                    model="team"
                    query={{ order: 'name' }}
                    renderList={list => list.items.map(item => this.renderItem(item.id))}
                />
            </div>
        );
    }

    renderItem(id: string) {
        const active = id === this.props.selection;

        const style = Object.assign(
            {},
            styles.item,
            active ? styles.active : this.state.hover === id ? styles.hover : {},
        );

        return (
            <div
                key={id}
                style={style}
                onMouseEnter={active ? null : () => this.setState({ hover: id })}
                onMouseLeave={active ? null : () => this.setState({ hover: null })}
                onClick={() => this.props.onSelect(id)}
            >
                <DataProvider
                    service="cleerance"
                    model="team"
                    scope="public"
                    id={id}
                    renderValue={data => {
                        return [
                            <span key="label" style={styles.label}>{data.profile.name}</span>,
                            <span key="size" style={styles.size}>{Object.keys(data.members || {}).length}</span>,
                        ];
                    }}
                    renderLoader={() => 'Loading ...'}
                />

                {active ? <div style={styles.arrow} /> : null}
            </div>
        );
    }
}
