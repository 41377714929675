// @flow

import type { LinkProfile } from '@deecision/deefind-types/model';

import orderBy from 'lodash/orderBy';

export function makeRanks(
    links: LinkProfile[],
    valuate: (link: LinkProfile) => ?number,
): { [id: string]: number } {
    const result: { [id: string]: number } = {};
    const tokens: Array<{ id: string, value: number }> = [];

    for (const link of links) {
        const value = valuate(link) || 0;
        tokens.push({ id: link.target.id, value: Math.max(value, 0) });
    }

    let rank = 0;
    let value = -1;
    let offset = 1;

    for (const token of orderBy(tokens, 'value', 'asc')) {
        if (value === token.value) {
            offset += 1;
        } else {
            rank += offset;
            offset = 1;
            value = token.value;
        }

        result[token.id] = rank;
    }

    return result;
}

export function mergeRanks(ids: string[], ranks: Array<{ [id: string]: number }>): { [id: string]: number } {
    const max = ids.length + 1;
    const result: { [id: string]: number } = {};

    for (const id of ids) {
        let value = 0;

        for (const rank of ranks) {
            value += rank.hasOwnProperty(id) ? rank[id] : max;
        }

        result[id] = value;
    }

    return result;
}
