// @flow

import type { SdkService, QueueResult, QueueScope, QueueStatus, StoreQueueList } from '@deecision/infra-types/client';
import type { DataStore, QueueStore } from './types';
import type { IObservableValue } from 'mobx';

import { observable, action } from 'mobx';
import Data from './data';
import hashObject from 'object-hash';

export default function(sdk: SdkService): QueueStore {
    const listsData: DataStore<StoreQueueList> = Data();
    const statusData: DataStore<QueueStatus> = Data();
    const resultsData: DataStore<QueueResult[]> = Data();

    return {
        push(scope: QueueScope, inputs: Object[]): Promise<void> {
            return sdk.queue.push(scope, inputs);
        },

        start(scope: QueueScope): Promise<void> {
            return sdk.queue.start(scope);
        },

        stop(scope: QueueScope): Promise<void> {
            return sdk.queue.stop(scope);
        },

        drop(scope: QueueScope): Promise<void> {
            return sdk.queue.drop(scope);
        },

        watchList(service: string, consumer: string): Function {
            const key = [service, consumer].join(':');
            listsData.watch(key, () => sdk.queue.watchList(service, consumer));

            return () => listsData.end(key);
        },

        watchStatus(scope: QueueScope): Function {
            const key = [scope.service, scope.consumer, scope.batch].join(':');
            statusData.watch(key, () => sdk.queue.watchStatus(scope));

            return () => statusData.end(key);
        },

        watchResults(scope: QueueScope): Function {
            const key = [scope.service, scope.consumer, scope.batch].join(':');
            resultsData.watch(key, () => sdk.queue.watchResults(scope));

            return () => resultsData.end(key);
        },

        getList(service: string, consumer: string): IObservableValue<?StoreQueueList> {
            return listsData.getData([service, consumer].join(':'));
        },

        getStatus(scope: QueueScope): IObservableValue<?QueueStatus> {
            return statusData.getData([scope.service, scope.consumer, scope.batch].join(':'));
        },

        getResults(scope: QueueScope): IObservableValue<?(QueueResult[])> {
            return resultsData.getData([scope.service, scope.consumer, scope.batch].join(':'));
        },
    };
}
