// @flow

import { cleanFile, cleanDeal, cleanTarget, cleanSource, cleanLink, cleanMedia, cleanAccounting } from './clean';
import { cleanCompanyData, cleanCompanyPreview, cleanPersonData, cleanPersonPreview } from './clean';

import { encodeEntityId, decodeEntityId, decodeEntityType, encodeEntityType } from './keys/entity';
import { encodeArtifactId, decodeArtifactId } from './keys/artifact';
import { encodeTargetId, decodeTargetId, encodeTargetType, decodeTargetType } from './keys/target';
import { encodeSearchKey } from './keys/search';

import { makeVigilanceSetup, makeVigilanceDirect, makeVigilanceNetwork } from './make';
import { makeVigilanceNorkomNetwork, makeVigilanceReport } from './make';
import { makeCompanyScore, makeCompanyPreview, makePersonScore, makePersonPreview, makeVigilanceNorkom } from './make';
import { makeValuationEstimate, makeListedValuation, makeValuationProfile, makeValuationPreview } from './make';
import { makeValuationCountries, makeEntityGics, makeEntityActivity } from './make';
import { makeStockLinks, makeStockStats, makeStockChild, makeStockParent, makeStockDirects } from './make';
import { makeStockChildren, makeStockParents, makeStockCashout, makeStockSums, makeStockWeasse } from './make';
import { makeStockUltimate } from './make';
import { makeComputedInvolvements, makeComputedSums } from './make';
import { makeRelationNode, makeRelationEntry } from './make';
import { makeInnerTarget } from './make';
import { makePrediction } from './make';
import { makeLinkData, makeLinkProfile, makeLinkRank, makeLinkTop } from './make';
import { makeDealsAttachments, makeDealsSum, makeDealsStakeholders, makeDealsTargets } from './make';

import { matchProfile, matchTarget, matchIdentifiers, matchLastName } from './match';

import { mergeCompanyData, mergePersonData, mergeMedia, mergeLinks, mergeDeals } from './merge';
import { combineMedia, reverseLink } from './misc';
import { normCompanyData, normCompanyPreview, normPersonData } from './norm';
import { normPersonPreview, normCountryCode, normCountryCodeLower } from './norm';

export default {
    // clean
    cleanFile,
    cleanDeal,
    cleanTarget,
    cleanSource,
    cleanCompanyData,
    cleanCompanyPreview,
    cleanPersonData,
    cleanPersonPreview,
    cleanLink,
    cleanMedia,
    cleanAccounting,

    // keys
    encodeEntityId,
    decodeEntityId,
    decodeEntityType,
    encodeEntityType,
    encodeArtifactId,
    decodeArtifactId,
    encodeTargetId,
    decodeTargetId,
    encodeSearchKey,
    encodeTargetType,
    decodeTargetType,

    // vigilance
    makeVigilanceSetup,
    makeVigilanceDirect,
    makeVigilanceNetwork,
    makeVigilanceNorkomNetwork,
    makeVigilanceReport,
    makeVigilanceNorkom,

    // entity
    makeCompanyScore,
    makeCompanyPreview,
    makePersonScore,
    makePersonPreview,
    makeEntityGics,
    makeEntityActivity,

    // valuation
    makeValuationEstimate,
    makeListedValuation,
    makeValuationProfile,
    makeValuationPreview,
    makeValuationCountries,

    // stock
    makeStockLinks,
    makeStockStats,
    makeStockChild,
    makeStockParent,
    makeStockChildren,
    makeStockParents,
    makeStockCashout,
    makeStockSums,
    makeStockWeasse,
    makeStockDirects,
    makeStockUltimate,

    makePrediction,

    // relations
    makeComputedInvolvements,
    makeComputedSums,
    makeRelationNode,
    makeRelationEntry,
    makeInnerTarget,
    makeLinkData,
    makeLinkProfile,
    makeLinkRank,
    makeLinkTop,

    // deals
    makeDealsAttachments,
    makeDealsSum,
    makeDealsStakeholders,
    makeDealsTargets,

    // match
    matchTarget,
    matchProfile,
    matchIdentifiers,
    matchLastName,

    // merge
    mergeCompanyData,
    mergePersonData,
    mergeMedia,
    mergeLinks,
    mergeDeals,

    // misc
    combineMedia,
    reverseLink,

    // norm
    normCompanyData,
    normCompanyPreview,
    normPersonData,
    normPersonPreview,
    normCountryCode,
    normCountryCodeLower
};
