import PropTypes from 'prop-types';
import React from 'react';

import Value from './financial/value';
import Valuation from './financial/valuation';
import Weasse from 'dna/entity/common/weasse';
import DeefindProvider from 'app/layout/providers/deefind';

import { get } from 'lodash';
import t from 'app/translation';

const styles = {
    container: {
    },
    values: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.display.tabs.financial';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render () {
        if (! this.props.entity.data) {
            return null;
        }

        return (
            <div style={ styles.container }>
                <div style={ styles.values }>
                    { this.renderValue('turnover', t('common.label.turnover')) }
                    { this.renderValue('ebitda', t('common.label.ebitda')) }
                    { this.renderValue('capital', t('common.label.capital')) }
                    { this.renderValue('equity', 'Equity') }
                    <Valuation entity={ this.props.entity } />
                </div>

                <DeefindProvider
                    type="stocks"
                    id={ this.props.entity.id }
                    renderEntity={ (stocks, state) => {
                        return (<Weasse entity={this.props.entity} stocks={stocks}/>);
                    }}
                />
            </div>
        );
    }

    renderValue (prop, label) {
      const historyValues = get(this.props.entity, `data.indicators`, []).map(item => item[prop]).reverse();
      const historyYears = get(this.props.entity, `data.indicators`, []).map(item => item.year).reverse();

        return (
            <Value
                label={ label }
                value={ this.props.entity.preview ? this.props.entity.preview[prop] : undefined }
                historyYears={ historyYears }
                historyValues={ historyValues }
            />
        );
    }
}
