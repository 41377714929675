import { get } from 'lodash';

export default async function (data: Object, services: Object): Promise<Object> {
    return {
        investmentManager: get(data, 'study.payload.manager', ''),
        investmentAdvisor: get(data, 'study.payload.advisor', ''),
        fundName: get(data, 'study.payload.fund', ''),
        type: get(data, 'study.payload.type', ''),
        domicile: get(data, 'study.payload.country', ''),
    };
}
