import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Paper from 'material-ui/Paper';
import Icon from 'app/theme/icon';
import Toggle from '../common/toggle';
import Button from '../common/button';

const styles = {
    onTop: {
        zIndex: 200000
    },
    wrapper: {
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        background: colors.lightBlack,
    },
    paper: {
        position: 'absolute',
        top: '20px',
        bottom: '20px',
        left: '50%',
        marginLeft: '-700px',
        width: '1400px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    head: {
        height: '40px',
        padding: '10px',
        background: colors.grey100,
        borderBottom: '1px solid ' + colors.grey300,
        flexGrow: '0',
        flexShrink: '0',
        position: 'relative',
        textAlign: 'center',
        color: colors.grey500,
        lineHeight: '40px',
    },
    scale: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
    },
    range: {
        margin: '0 10px',
    },
    page: {
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        lineHeight: '30px',
        width: '40px',
        textAlign: 'center',
        fontSize: 'inherit',
        fontFamily: 'inherit',
    },
    close: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    body: {
        flexGrow: '1',
        flexShrink: '2',
        background: colors.grey200,
        overflow: 'scroll',
    },
    canvas: {
        display: 'block',
        margin: '20px auto',
        border: '1px solid ' + colors.grey300,
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.modal.pdf';

    static propTypes = {
        onTop: PropTypes.bool,
        slot1: PropTypes.node,
        onClose: PropTypes.func,
    };

    state = {
        open: false
    };

    open (pdf) {
        window.document.body.style.overflow = 'hidden';
        this.setState({ open: true, pdf, page: 1, scale: 2, pages: pdf.numPages });
    }

    close () {
        window.document.body.style.overflow = 'auto';
        this.setState({ open: false, pdf: null, page: 0, pages: 0 });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    async componentDidUpdate () {
        if (this.state.pdf && this.canvas) {
            const page = await this.state.pdf.getPage(this.state.page);
            const viewport = page.getViewport(this.state.scale);

            this.canvas.width = viewport.width;
            this.canvas.height = viewport.height;
            page.render({ viewport, canvasContext: this.canvas.getContext('2d') });
        }
    }

    render () {
        if (! this.state.open) {
            return null;
        }

        return (
            <div style={ Object.assign({}, styles.wrapper, this.props.onTop ? styles.onTop : {}) }>
                <Paper
                    style={ styles.paper }
                    zDepth={ 2 }
                >
                    <div style={ styles.head }>
                        <div style={ styles.scale }>
                            <Icon
                                type="nav.scale.minus"
                                color={ colors.grey400 }
                            />

                            <input
                                style={ styles.range }
                                value={ this.state.scale }
                                onChange={ event => this.setState({ scale: event.target.value })}
                                type="range"
                                step={ 0.5 }
                                min={ 1 }
                                max={ 5 }
                            />

                            <Icon
                                type="nav.scale.plus"
                                color={ colors.grey400 }
                            />

                            { this.props.slot1 }
                        </div>

                        <Button
                            type="flat"
                            color="cold"
                            icon={ <Icon type="nav.chevron.left" /> }
                            onClick={ event => this.setState({ page: this.state.page - 1 }) }
                            disabled={ this.state.page < 2 }
                        />

                        &nbsp;&nbsp;

                        <input
                            style={ styles.page }
                            value={ this.state.page }
                            onFocus={ event => event.target.select() }
                            onChange={ event => {
                                let value = parseInt(event.target.value.replace(/[^\d]+/, ''));
                                if (! value) value = 1;
                                if (value > this.state.pages) value = this.state.pages;
                                this.setState({ page: value });
                            } }
                        />

                        <span>
                            &nbsp;&nbsp;/&nbsp;&nbsp;
                            { this.state.pages }
                        </span>

                        &nbsp;&nbsp;

                        <Button
                            type="flat"
                            color="cold"
                            icon={ <Icon type="nav.chevron.right" /> }
                            onClick={ event => this.setState({ page: this.state.page + 1 }) }
                            disabled={ this.state.page >= this.state.pages }
                        />

                        <Button
                            type="flat"
                            color="hot"
                            icon={ <Icon type="do.close" /> }
                            label="Close"
                            style={ styles.close }
                            onClick={ event => this.close() }
                        />
                    </div>
                    <div style={ styles.body }>
                        <canvas
                            ref={ element => this.canvas = element }
                            style={ styles.canvas }
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}
