import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Compliance from 'dna/entity/common/compliance/button';
import Vigilance from 'dna/entity/common/vigilance/button';
import Relation from 'dna/entity/common/relation';
import Activity from 'dna/entity/common/activity';
import Closed from 'dna/entity/common/display/closed';
import Flag from 'dna/entity/common/flag';
import { ACTIVITIES } from 'dna/entity/aml';
import Info from '../info';

const styles = {
    container: {
        marginBottom: '20px',
        position: 'relative',
    },
    info: {
        color: colors.userCold,
    },
    listed: {
        position: 'absolute',
        bottom: '8px',
        left: '2px',
    },
    c19: {
        position: 'absolute',
        bottom: '39px',
        left: '-3px',
        width: '40px',
        borderRadius: '20px',
    },
    holding: {
        position: 'absolute',
        bottom: '8px',
        left: '2px',
    },
    amlFlag: {
        fontSize: '9px',
        background: colors.userHot,
        borderRadius: '3px',
        padding: '4px',
        display: 'inline-block',
        color: 'white',
        verticalAlign: 'bottom',
        marginRight: '5px',
    },
    closed: {
        marginRight: '10px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.card.header';

    static propTypes = {
        entity: PropTypes.object,
        parent: PropTypes.object,
        relation: PropTypes.object,
    };

    render() {
        return (
            <div style={styles.container}>
                <Views.Common.Header
                    light
                    icon={<Icon type="dna.company" />}
                    title={this.props.entity.preview.name || '(?)'}
                    content={[
                        <Info entity={this.props.entity} />,
                        <span>
                            {this.props.entity.preview.closed && this.props.entity.data ? (
                                <Closed key="closed" data={this.props.entity.data.identity.closed} style={styles.closed} />
                            ) : null}
                            <Activity
                                nace={this.props.entity.preview.naceCode}
                                gics={this.props.entity.preview.gicsCode}
                            />
                        </span>,
                    ]}
                    aside={this.renderAside()}
                />

                <Flag
                    key="h"
                    label="H"
                    tooltip="Holding company"
                    color="cold"
                    active={this.props.entity.preview.flags.includes('holding')}
                    style={styles.holding}
                />

                <Flag
                    key="f"
                    label="F"
                    tooltip="Investment fund"
                    color="cold"
                    active={this.props.entity.preview.flags.includes('fund')}
                    style={styles.holding}
                />

                <Flag
                    key="l"
                    label="L"
                    tooltip="Listed company"
                    active={this.props.entity.preview.flags.includes('listed')}
                    style={styles.listed}
                />

                <Flag
                    key="vc"
                    label="VC"
                    tooltip={
                        typeof this.props.entity.preview.funded === 'number'
                            ? `Funded since ${this.props.entity.preview.funded}`
                            : 'Funded'
                    }
                    active={!!this.props.entity.preview.funded}
                    style={styles.listed}
                />

                <Flag
                    key="CV19"
                    label="SC19"
                    tooltip="Sensitivity to covid-19 crisis"
                    color="hot"
                    active={this.props.entity.preview.flags.includes('crisis-impact')}
                    style={styles.c19}
                />
            </div>
        );
    }

    renderAside() {
        const items = [];

        if (this.props.entity) {
            items.push(<Compliance key="compliance" entity={this.props.entity} />);
            items.push(<Vigilance key="vigilance" entity={this.props.entity} />);
        }

        if (this.props.relation) {
            items.push(<Relation key="relation" entity={this.props.parent} data={this.props.relation} />);
        }

        return items;
    }
}
