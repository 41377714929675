// @flow

import type {
    SdkWorker,
    CoreLogger,
    PortFeedback,
    PortQuery,
    PortMessage,
    CoreChannel,
    GlobalEvent,
} from '@deecision/infra-types/client';

import Channel from '../core/channel';
import uuid from 'uuid';

export default function(logger: CoreLogger, build: string): SdkWorker {
    const channel: CoreChannel<PortMessage<PortFeedback>> = Channel(logger);
    const worker = new SharedWorker('/worker.js?build=' + build);

    worker.port.onmessage = (event: Object) => channel.send(event.data);
    worker.port.start();

    return {
        listen(listener: (event: GlobalEvent) => any): Function {
            return channel.listen((message: PortMessage<PortFeedback>) => {
                const feedback = message[1];

                if (feedback.type === 'global') {
                    listener(feedback.event);
                }
            });
        },

        send(query: PortQuery, listener: (feedback: PortFeedback) => any): Function {
            const token = uuid.v4();
            const message: PortMessage<PortQuery> = [token, query];

            const dispose: Function = channel.listen((message: PortMessage<PortFeedback>) => {
                if (token === message[0]) {
                    listener(message[1]);
                }
            });

            worker.port.postMessage(message);

            return dispose;
        },
    };
}
