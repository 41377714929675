// @flow

import type { ResolverInterface, ResolverSpecs, StateLocation } from '../../types';
import type StateStore from '../../index';
import type StatePath from '../../path';
import type StateNode from '../../node';

export default class RouterResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;
    routes: { [type: string]: { [key: string]: ResolverSpecs } };

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
        this.routes = {};

        for (const type of Object.keys(specs.routes || {})) {
            const route = specs.routes[type];

            for (const key of Object.keys(route || {})) {
                let specs = route[key];

                if (typeof route === 'string') {
                    specs = { type: specs };
                }

                if (typeof specs !== 'object' || ! specs.type) {
                    throw new Error(`Found invalid router state resolver specs: ${JSON.stringify(specs)}`);
                }

                if (! this.routes[type]) {
                    this.routes[type] = {};
                }

                this.routes[type][key] = specs;
            }
        }
    }

    register (store: StateStore) {
        store.define(this.path.concat('type'), {
            type: 'string',
            values: Object.keys(this.specs.routes || {}),
            fallback: this.specs.fallback
        });

        for (const type of Object.keys(this.routes || {})) {
            for (const key of Object.keys(this.routes[type] || {})) {
                store.define(this.path.concat(`${type}+${key}`), this.routes[type][key]);

                // fixme: this is dangerous, remove this please!
                store.define(this.path.concat(key), this.routes[type][key]);
            }
        }
    }

    locate (node: StateNode, data: any, location: StateLocation): StateLocation {
        // if (location === 'type') {
        //     return 'type';
        // }
        //
        // if (typeof location === 'string' && location._match(/^\w+\+\w+$/)) {
        //     return location;
        // }
        //
        // const type = node.get('type', 'string').read(data) || '';
        //
        // return `${type}+${location}`;

        return location;
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        if (! value || typeof value !== 'object') {
            value = {};
        }

        const type = node
            .get('type', 'string')
            .normalize(value.type, external);

        const result = {};

        if (! type) {
            return null;
        }

        for (const name of Object.keys(this.routes[type] || {})) {
            const specs = this.specs.routes[type][name];

            const loadedValue = node
                .forge(name, specs)
                .normalize(value[name], external);

            // const realValue = node
            //     .get(`${type}+${name}`, specs.type)
            //     .normalize(value[`${type}+${name}`], external);

            // result[`${type}+${name}`] = realValue === undefined ? loadedValue : realValue;
            // result[name] = realValue === undefined ? loadedValue : realValue;

            result[name] = loadedValue;
        }

        result.type = type;

        return result;
    }
}
