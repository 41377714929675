// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import t from 'app/translation';
import colors from 'app/theme/colors';

const styles = {
    container: {
        width: '200px',
        marginRight: '10px',
        flexShrink: '0',
    },
    entry: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '0 15px',
        borderRadius: '3px',
        background: 'transparent',
        color: colors.grey700,
        marginBottom: '1px',
        fontSize: '17px',
        height: '40px',
    },
    entryHover: {
        background: colors.grey200,
    },
    entryActive: {
        background: colors.userCold,
        color: 'white',
    },
    icon: {
        fill: colors.grey700,
        marginRight: '15px',
        opacity: '0.75',
    },
    iconActive: {
        fill: 'white',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'cleerance.admin.teams.details.menu';

    static propTypes = {
        selection: PropTypes.string.isRequired,
        onSelect: PropTypes.func.isRequired,
    };

    state = { hover: null };

    render() {
        return (
            <div style={styles.container}>
                {this.renderEntry('settings')}
                {this.renderEntry('members')}
                {this.renderEntry('links')}
            </div>
        );
    }

    renderEntry(id: string) {
        const active = id === this.props.selection;
        const hover = id === this.state.hover;

        return (
            <div
                style={Object.assign({}, styles.entry, active ? styles.entryActive : hover ? styles.entryHover : {})}
                onMouseEnter={() => this.setState({ hover: id })}
                onMouseLeave={() => this.setState({ hover: null })}
                onClick={() => this.props.onSelect(id)}
            >
                <Icon
                    style={Object.assign({}, styles.icon, active ? styles.iconActive : {})}
                    type={`cleerance.${id}`}
                />

                <span>
                    {t(`cleerance.admin.teams.menu.${id}`)}
                </span>
            </div>
        );
    }
}
