// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        display: 'flex',
        background: colors.grey200,
        padding: '10px',
        borderRadius: '3px',
        marginBottom: '1px',
    },
    title: {
        width: '130px',
        color: colors.userCold,
        lineHeight: '40px',
        paddingLeft: '10px',
    },
    body: {
        flexGrow: '1',
        lineHeight: '20px',
    },
    edit: {
        width: '40px',
        paddingLeft: '20px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'cleerance.admin.teams.details.settings.block';

    static propTypes = {
        title: PropTypes.string.isRequired,
        body: PropTypes.element,
        edit: PropTypes.element,
    };

    render() {
        if (!this.props.edit) {
            return this.renderBlock();
        }

        return [
            this.renderBlock(),
            React.cloneElement(this.props.edit, { key: 'edit', ref: 'edit' }),
        ];
    }

    renderBlock() {
        return (
            <div key="block" style={styles.container}>
                <div style={styles.title}>{this.props.title}</div>
                <div style={styles.body}>{this.props.body}</div>

                <div style={styles.edit}>
                    <Views.Common.Button
                        key="button"
                        type="round"
                        icon={<Icon type="do.update" />}
                        onClick={this.props.edit ? () => this.refs['edit'].open() : null}
                        disabled={!this.props.edit}
                        mini
                    />
                </div>
            </div>
        );
    }
}
