import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';

let styles = {
    container: {
        position: 'relative',
        maxWidth: '1000px'
    },
    title: {
        position: 'absolute',
        top: '-14px',
        left: '0',
        right: '0',
        color: colors.userCold,
        textAlign: 'center',
        fontSize: '11px',
        lineHeight: '11px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        borderBottom: '1px solid ' + colors.userCold
    },
    link: {
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        lineHeight: '30px',
        padding: '5px 10px',
        background: colors.grey200
    },
    hoverLink: {
        background: colors.grey300
    },
    currentLink: {
        background: colors.userCold
    },
    firstLink: {
        borderRadius: '3px 0 0 3px'
    },
    lastLink: {
        borderRadius: '0 3px 3px 0'
    },
    emptyLink: {
        background: colors.grey100
    },
    label: {
        display: 'inline-block',
        float: 'left',
        height: '30px',
        color: colors.userCold,
        fill: colors.grey500,
        minWidth: '15px',
        textAlign: 'center'
    },
    currentLabel: {
        color: 'white',
        fill: 'white'
    },
    emptyLabel: {
        color: colors.grey400
    },
    count: {
        display: 'inline-block',
        float: 'right',
        color: colors.grey500,
        marginLeft: '10px'
    },
    currentCount: {
        color: 'white'
    },
    emptyCount: {
        color: colors.grey400
    },
    icon: {
        fill: colors.grey500,
        float: 'left',
        padding: '3px 0'
    },
    currentIcon: {
        fill: 'white'
    },
    emptyIcon: {
        fill: colors.grey400
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.header.groups';

    static propTypes = {
        title: PropTypes.string,
        groups: PropTypes.object.isRequired,
        value: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func
    };

    state = {
        hover: undefined
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                { this.renderTitle() }
                { _.map(this.props.groups, this.renderGroup.bind(this)) }
            </div>
        );
    }

    renderTitle () {
        if (! this.props.title) {
            return null;
        }

        return (
            <div style={ styles.title }>
                { this.props.title }
            </div>
        );
    }

    renderGroup (group, key) {
        const current = key === this.props.value;
        const keys = _.keys(this.props.groups);

        const style = Object.assign({},
            styles.link,
            current
                ? styles.currentLink
                : (
                    group.count
                        ? (key === this.state.hover ? styles.hoverLink : {})
                        : styles.emptyLink
                )
        );

        if (key === _.first(keys))
            Object.assign(style, styles.firstLink);

        if (key === _.last(keys))
            Object.assign(style, styles.lastLink);

        return (
            <Views.Common.Tooltip
                key={ key }
                content={ group.info }
            >
                <a
                    style={ style }
                    onClick={ event => this.props.onChange(current ? undefined : key) }
                    onMouseEnter={ event => this.setState({ hover: key }) }
                    onMouseLeave={ event => this.setState({ hover: undefined }) }
                >
                    { this.renderIcon(group, current) }
                    { this.renderLabel(group, current) }
                    { this.renderCount(group, current) }
                </a>
            </Views.Common.Tooltip>
        );
    }

    renderIcon (group, current) {
        if (! group.icon)
            return null;

        return React.cloneElement(group.icon, {
            style: Object.assign({},
                styles.icon,
                current ? styles.currentIcon : (group.count ? {} : styles.emptyIcon),
                group.icon.props.style
            )
        });
    }

    renderLabel (group, current) {
        if (! group.label)
            return null;

        const style = Object.assign({},
            styles.label,
            current ? styles.currentLabel : (group.count ? {} : styles.emptyLabel)
        );

        return (
            <span style={ style }>
                { group.label }
            </span>
        );
    }

    renderCount (group, current) {
        if (_.isUndefined(group.count))
            return null;

        const style = Object.assign({},
            styles.count,
            current ? styles.currentCount : (group.count ? {} : styles.emptyCount)
        );

        return (
            <span style={ style }>
                { group.count }
            </span>
        );
    }
}
