import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        padding: '20px',
        height: '200px',
    },
    header: {
        marginBottom: '15px',
    },
    preview: {
        marginRight: '74px',
    },
    duplicate: {
        position: 'absolute',
        left: '0',
        bottom: '0',
    },
    remove: {
        position: 'absolute',
        left: '140px',
        bottom: '0',
    },
    opener: {
        position: 'absolute',
        right: '0',
        bottom: '0',
    },
    opener2: {
        position: 'absolute',
        right: '60px',
        bottom: '0',
    },
    body: {
        display: 'flex',
        marginTop: '5px',
        color: colors.grey800,
    },
    aum: {
        lineHeight: '18px',
        marginLeft: '15px',
        color: colors.grey800,
    },
    icon: {
        fill: colors.userCold,
        marginRight: '5px',
        width: '18px',
        height: '18px',
    },
    owner: {
        lineHeight: '18px',
    },
};

@inject(stores => ({
    store: stores.deeligenzManagement,
    contacts: stores.auth.contacts,
    session: stores.global.session,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.templates.summary.card';

    static propTypes = {
        entity: PropTypes.object,
        contacts: PropTypes.object,
    };

    render() {
        if (!this.props.entity) {
            return null;
        }

        const payload = this.props.entity.payload || {};
        const type = payload.target.type || 'manager';
        const contacts = this.props.contacts;
        const owner = contacts && contacts.users ? contacts.users[this.props.entity.owner] || {} : {};

        return (
            <Views.Common.Card style={styles.container}>
                <Views.Common.Header
                    light
                    style={styles.header}
                    icon={<Icon type={type === 'fund' ? 'deeligenz.fund' : 'deeligenz.company'} />}
                    title={payload.label}
                    content={[
                        <div style={styles.body}>
                            <Icon type="base.attendee" style={styles.icon} />

                            <span style={styles.owner}>{owner.firstName + ' ' + owner.lastName}</span>
                        </div>,
                    ]}
                    aside={[]}
                />

                <div style={styles.preview}>{(this.props.entity.payload.questions || []).length} questions</div>

                <Views.Common.Button
                    type="round"
                    style={styles.opener}
                    icon={<Icon type="do.open" />}
                    onClick={event => this.props.store.openTemplate(this.props.entity.id)}
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Duplicate"
                    style={styles.duplicate}
                    icon={<Icon type="do.duplicate" />}
                    onClick={event => this.props.store.duplicateTemplate(this.props.entity.id)}
                />

                <Views.Modal.Confirm
                    title="Confirmation"
                    ref="confirmation"
                    onClick={event => {
                        this.props.store.removeTemplate(this.props.entity.id);
                        this.refs.apply.closePopover();
                    }}
                    message="Are you sure to delete this template ?"
                >
                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        label="Remove"
                        style={styles.remove}
                        icon={<Icon type="do.delete" />}
                        onClick={event => this.refs.confirmation.open()}
                    />
                </Views.Modal.Confirm>
            </Views.Common.Card>
        );
    }
}
