// @flow

import { get } from 'lodash';

export function filterFields(fields: Object[]): Object[] {
    const questions: Object = {};

    for (const field of fields) {
        const question = get(field, 'questionPayload.question_id');
        questions[question] = field;
    }

    return fields.filter(field => !!field && display(field, questions));
}

function display(field: Object, questions: Object): boolean {
    const embedded = get(field, 'questionPayload.embedded');

    if (!embedded) {
        return true;
    }

    const parent = questions[embedded.question];

    if (!parent) {
        return false;
    }

    const answer = get(parent, 'answerEditPayload') || get(parent, 'answerPayload') || {};

    switch (get(parent, 'questionPayload.type')) {
        case 'boolean':
            return answer.value === (embedded.value === 'Y');

        case 'select':
            return (
                !get(parent, 'questionPayload.options.multiple') &&
                get(answer, 'choices[0]') === embedded.value
            );
    }

    return false;
}
