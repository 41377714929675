// @flow

import type { StockLink, StockStats } from '@deecision/deefind-types/model';

import sum from 'lodash/sum';

export default function makeStockStats(links: StockLink[]): StockStats {
    // todo: add current/ceased separation
    // todo: add location/activity clustering

    const stats: StockStats = { total: 0, indirect: 0, direct: 0, valued: 0, average: undefined };
    const minValues: number[] = [];
    const maxValues: number[] = [];

    for (const link of links) {
        stats.total ++;

        if (link.direct) {
            stats.direct ++;
        } else {
            stats.indirect ++;
        }

        const value = link.value;

        if (value) {
            minValues.push(value.min || 0);
            maxValues.push(value.max || 100);
            stats.valued ++;
        }
    }

    if (minValues.length && maxValues.length) {
        stats.average = { min: sum(minValues) / minValues.length, max: sum(maxValues) / maxValues.length };
    }

    return stats;
}
