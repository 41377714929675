import React from 'react';
import PropTypes from 'prop-types';
import Scope from 'app/layout/providers/scope';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Form from '../common/form';
import List from './list';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ global: stores.global, store: stores.deeligenzStudies, session: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.summary';

    static contextTypes = {
        createStudy: PropTypes.func.isRequired,
        openStudy: PropTypes.func.isRequired,
    };

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    async handleSubmit(type: string, data: Object) {
        const study = await this.context.createStudy(type, data);
        this.refs.form.unlock();
        //this.security.openStudy(study.id);
    }

    render() {
        const entities = this.props.store.studies;
        const loaded = toJS(this.props.store.loaded);
        const session = this.props.session.get();

        if (!loaded) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div style={this.props.style}>
                {this.renderList(Array.from(entities.values()))}

                {session.level === 'intervention' ? null : (
                    <Form ref="form" onSubmit={this.handleSubmit.bind(this)} />
                )}
            </div>
        );
    }

    renderList(entities) {
        entities = entities.filter(e => !(!!e.payload.archive));
        if (!this.props.session) {
            return null;
        }

        if (!entities.length && this.props.session.get().level !== 'intervention') {
            return (
                <Views.Common.Empty
                    title="No due diligence found"
                    actions={[
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            icon={<Icon type="do.create" />}
                            label="New Due Diligence"
                            onClick={event => this.refs.form.open()}
                        />,
                    ]}
                />
            );
        }

        const cursor = this.props.global.cursor.get();
        const account = this.props.session.get().user.accounts.filter(account => account.id === cursor.account)[0];

        return (
            <Scope path="list" type="list">
                <List
                    account={account.id}
                    entities={entities}
                    onCreate={(type: string) => this.refs.form.open(type)}
                    type={this.props.session.get().level}
                />
            </Scope>
        );
    }
}
