import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import t from 'app/translation';

export const defaults = {
    payload: {
        settings: {
            months: 9,
        },
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.prospects.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    open(extra) {
        this.refs.dialog.open(extra);
    }

    handleChange(data) {
        this.setState(data.payload);
        return data;
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                model="comeet:prospectings"
                data={this.props.entity || defaults}
                label={t('dna.teem.saveProspecting')}
                onChange={this.handleChange.bind(this)}
                onSubmit={this.props.onSubmit}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        size={3}
                        label={t('common.label.name')}
                        path="payload.target.name"
                        widget="string"
                        required
                    />
                    <Views.Form.Field
                        size={1}
                        label={t('dna.comeet.deadline')}
                        path="payload.settings.months"
                        widget={{ type: 'number', unit: 'Months' }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label={t('dna.comeet.description')} path="payload.description" widget="text" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
