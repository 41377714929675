import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';

import DeefindProvider from 'app/layout/providers/deefind';
import Button from 'app/views/common/button';
import Card from 'dna/entity/card';


export default class extends React.Component<any, any> {
    static displayName = 'dna.person.display.block.family';

    static propTypes = {
        entity: PropTypes.object,
    };

    constructor (props) {
        super(props);
        this.state = { activate: null };
    }

    render() {

        if (this.state.activate === null){
            return (
                <Button
                    onClick={event => this.setState({ activate: 1 })}
                    label={'Explorer le reseau familial'}
                >
                </Button>
            );
        }
        if (this.state.activate === 1){
            return (
                <DeefindProvider
                    type="family"
                    id={this.props.entity.id}
                    depth={3}
                    renderEntity={(family, state) => {
                        return this.renderCard(family.relations);
                    }}
                />
            );
        }
    }

    renderCard(relations){
        const entities = relations.map(item => Object.assign({}, item, {id: item.target.id}));
        return <Views.List.Container entities={entities} >
            <Views.List.Frame
                title={ 'Réseau familial étendu'}
                type="cards"
                element={ <Card  /> }
            />
        </Views.List.Container>;
    }
}
