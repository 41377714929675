import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

const styles = {
    container: {},
    button: {
        marginRight: '15px',
    },
    name: {
        display: 'inline-block',
        margin: '5px 0',
        color: colors.grey500,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'views.form.widget.file';

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        onAsync: PropTypes.func.isRequired,
    };

    state = { content: null };

    render() {
        return (
            <div style={styles.container}>
                {this.renderUpload()}
                {this.renderView()}
            </div>
        );
    }

    async handleUpload (event) {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = async event => {
            const content = event.target.result;
            let value = null;
            if (file.type.match(/^image\//g)) {
                value = {
                    type: file.type,
                    name: file.name,
                    size: file.size,
                    content,
                };
            } else {
                const pdf = await PDFJS.getDocument({ data: new Uint8Array(content) }).promise;

                value = {
                    type: file.type,
                    name: file.name,
                    size: file.size,
                    pages: pdf.numPages,
                    content,
                };
            }

            this.setState({ content, loading: true }, async () => {
              this.props.onChange(await this.props.onAsync(value));
              this.setState({ content, loading: false })
            });

        };

        reader.readAsArrayBuffer(file);
    }

    async handleDelete (event) {
        this.props.onChange(await this.props.onAsync(null))
    }

    renderUpload() {
        if (this.props.readOnly) {
            return null;
        }

        const label = !this.props.value || (!this.props.value.name && !this.props.value.size) ? 'Upload' : 'Replace';

        return (
            <span>
                <input
                    ref={element => (this.inputElement = element)}
                    type="file"
                    hidden
                    accept=".pdf,.jpg,.jpeg,.png,.gif"
                    onChange={this.handleUpload.bind(this)}
                />

                {this.state.loading ? <Views.Common.Loader tiny={true} /> : null}

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label={label}
                    icon={<Icon type="do.upload" />}
                    style={styles.button}
                    onClick={event => this.inputElement.click()}
                />
            </span>
        );
    }

    renderView() {
        if (!this.props.value || (!this.props.value.name && !this.props.value.size)) {
            return null;
        }

        return (
            <span>
                <Views.Common.Button
                    type="flat"
                    color="hot"
                    label="Delete"
                    icon={<Icon type="do.delete" />}
                    style={styles.button}
                    onClick={this.handleDelete.bind(this)}
                />

                <br />
                <span style={styles.name}>Name: {this.props.value.name}</span>
                <br />
                <span style={styles.name}>Type: {this.props.value.type}</span>

                <Views.Modal.Img ref="img_modal" />
                <Views.Modal.Pdf ref="pdf_modal" />
            </span>
        );
    }
}
