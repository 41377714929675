import PropTypes from 'prop-types';
import React from 'react';

import Handle from 'dna/entity/common/handle';
import Info from './info';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.person.handle';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    render () {
        return (
            <Handle
                onSelect={ this.props.onSelect }
                icon="dna.person"
                title={ this.props.entity.preview.name || '(?)' }
                content={ <Info entity={this.props.entity} /> }
            />
        );
    }
}
