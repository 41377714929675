import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import QuestionItem from './question/item';
import QuestionForm from './question/form';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Survey from 'deeligenz/studies/common/survey';

const styles = {
    container: {
        display: 'flex',
    },
    title: {
        marginBottom: '10px',
        display: 'flex',
    },
    titleLabel: {
        width: '100%',
        lineHeight: '40px',
        fontSize: '19px',
    },
    titleAdd: {},
    section: {
        marginBottom: '20px',
    },
    questions: {
        width: '100%',
        marginLeft: '20px',
    },
    message: {
        background: colors.grey200,
        borderRadius: '10px',
        padding: '50px',
        textAlign: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexBasis: '100%',
        marginBottom: '10px',
    },
    headerTitle: {
        fontSize: '19px',
    },
    headerCount: {
        color: colors.userCold,
        marginLeft: '15px',
        flexGrow: '1',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.questions';

    static contextTypes = {
        answerEditField: PropTypes.func.isRequired,
        answerField: PropTypes.func.isRequired,
        applyField: PropTypes.func.isRequired,
        setFieldFlags: PropTypes.func.isRequired,
        setFieldRemark: PropTypes.func.isRequired,
        commentField: PropTypes.func.isRequired,
        getFileUrl: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired,
        updateState: PropTypes.func.isRequired,
        // setNda: PropTypes.func.isRequired,
        // readNda: PropTypes.func.isRequired,
        // writeNda: PropTypes.func.isRequired,
    };

    static propTypes = {
        id: PropTypes.string.isRequired,
        style: PropTypes.object,
        store: PropTypes.object,
        readOnly: PropTypes.bool
    };

    state = { category: null };

    render() {
        if (!this.props.store) {
            return null;
        }

        if (toJS(this.props.store.loading)) {
            return <Views.Common.Loader title="Loading data" />;
        }

        const questions = (toJS(this.props.store.studies.get(this.props.id)).fields || []).filter(
            id => toJS(this.props.store.fields.get(id)).onsite,
        );

        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                <div style={styles.questions}>
                    {this.renderHeader(questions)}
                    {this.renderQuestions(questions)}

                    <QuestionForm
                        ref="questionForm"
                        onCancel={data => {
                            this.setState({ pending: false });
                        }}
                        onSubmit={this.props.readOnly ? () => {} : data => {
                            this.props.store.additionalField(this.props.id, data.questionPayload).then(() => {
                                this.setState({ pending: false });
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderHeader(questions) {
        return (
            <div style={styles.header}>
                <span style={styles.headerCount}>{questions.length} additional question(s)</span>

                {this.props.readOnly ? null : <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.create" />}
                    label="New question"
                    onClick={event => {
                        this.refs['questionForm'].open({
                            questionPayload: { category: { label: 'additional' }, scope: 'onSite' },
                        });
                        this.setState({ pending: true });
                    }}
                />}
            </div>
        );
    }

    renderQuestions(questions) {
        if (!questions.length) {
            return <div style={styles.message}>There are no additional questions</div>;
        }

        if (this.state.pending) {
            return <div style={styles.message}>In edition mode</div>;
        }

        return (
            <Survey
                ref="survey"
                entity={this.props.store.studies.get(this.props.id)}
                scope="onSite"
                onScope={scope => scope === 'onSite'}
                onAnswer={this.context.answerField}
                onRemark={this.context.setFieldRemark}
                onFlags={this.context.setFieldFlags}
                onComment={this.context.commentField}
                additional={true}
                readOnly={false}
                withCorrection={true}
                onCorrection={this.context.answerEditField}
            />
        );
    }
}
