import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import get from 'lodash/get';

const styles = {
    container: {
        display: 'flex',
        width: '24px',
        padding: '8px',
        cursor: 'pointer',
        borderRadius: '3px',
        background: 'transparent',
        textAlign: 'center',
        margin: '5px',
    },
    action: {
        marginBottom: '10px',
    },
    modal: {
        width: '800px',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        margin: '-20px',
    }
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.aside.flag';

    static propTypes = {
        label: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['cold', 'hot', 'green', 'grey']).isRequired,
        value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        onChange: PropTypes.func,
        style: PropTypes.object,
        comment: PropTypes.bool,
        readOnly: PropTypes.bool
    };

    state = Object.assign({}, { hover: false}, typeof this.props.value === 'object' ? {title: get(this.props.value, 'title') , comment: get(this.props.value, 'comment') } : null);

    render() {
        let color = colors.grey600;

        if (this.props.color === 'cold') {
            color = colors.userCold;
        }

        if (this.props.color === 'hot') {
            color = colors.userHot;
        }

        if (this.props.color === 'green') {
            color = colors.userGreen;
        }

        const style = Object.assign(
            {},
            styles.container,
            this.props.style,
            this.props.onChange ? {} : { cursor: 'default' },
            this.props.value
                ? { background: color }
                : this.state.hover
                ? { background: colors.grey200 }
                : { background: 'transparent' },
        );

        return (
          [this.props.comment ? this.renderModal() : null,
            <Views.Common.Tooltip key="tooltip" content={this.props.label}>
                <span
                    style={style}
                    onClick={this.props.comment ? () => this.refs.dialog.open() : this.props.onChange ? event => this.props.onChange(!this.props.value) : undefined}
                    onMouseEnter={this.props.onChange ? event => this.setState({ hover: true }) : undefined}
                    onMouseLeave={event => this.setState({ hover: false })}
                >
                    <Icon type={this.props.icon} color={this.props.value ? 'white' : color} />
                </span>
            </Views.Common.Tooltip>]
        );
    }

    renderModal() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                key="dialog"
                icon={<Icon type={this.props.icon} />}
                label={this.props.label}
                style={styles.modal}
                data={this.state}
                onSubmit={this.props.onChange ? event => this.props.onChange({title: this.state.title, comment: this.state.comment}) : undefined}
                onChange={(data) => {this.setState(data)}}
            >
                <div style={styles.body}>
                  <Views.Form.Row>
                      <Views.Form.Field readOnly={this.props.readOnly} label="Title" path="title" widget="string" size={1} required />
                  </Views.Form.Row>
                  <Views.Form.Row>
                      <Views.Form.Field readOnly={this.props.readOnly} label="Comment" path="comment" widget="text" size={2} required />
                  </Views.Form.Row>
                  <Views.Form.Row>
                      <Views.Common.Button color='hot' label='reset' type='flat' onClick={() => {
                        this.refs.dialog.setData(null)
                        this.props.onChange(null);
                        this.refs.dialog.close();
                      }}/>
                  </Views.Form.Row>

                </div>
            </Views.Modal.Edition>
        );
    }
}
