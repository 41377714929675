import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Paper from 'material-ui/Paper';
import Icon from 'app/theme/icon';

let styles = {
    container: {
        margin: '0',
        height: '24px',
        padding: '5px',
        borderRadius: '3px',
        position: 'relative',
        clear: 'both',
        textAlign: 'right',
        background: 'transparent'
    },
    hover: {
        background: colors.grey300
    },
    icon: {
        fill: colors.grey500,
        marginLeft: '10px'
    },
    count: {
        fontSize: '20px',
        verticalAlign: 'top',
        color: colors.userCold
    },
    tooltip: {
        position: 'absolute',
        textAlign: 'left',
        right: '100%',
        bottom: '-2em',
        marginLeft: '0.5em',
        padding: '1em',
        zIndex: '3'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.card.relations.relation';

    static propTypes = {
        type: PropTypes.oneOf(['persons', 'companies']).isRequired,
        count: PropTypes.number.isRequired,
        tooltip: PropTypes.node
    };

    state = { hover: false };

    render () {
        if (!this.props.count) {
            return null;
        }

        const style = this.state.hover
            ? Object.assign({}, styles.container, styles.hover)
            : styles.container;

        return (
            <div
                style={ style }
            >
                <span style={ styles.count }>
                    { this.props.count }
                </span>

                { this.renderIcon() }
                { this.renderTooltip() }
            </div>
        );
    }

    renderIcon () {
        switch (this.props.type) {
            case 'persons': return (<Icon type="dna.person" style={ styles.icon } />);
            case 'companies': return (<Icon type="dna.company" style={ styles.icon } />);
        }

        return null;
    }

    renderTooltip () {
        if (! this.props.tooltip)
            return null;

        if (! this.state.hover)
            return null;

        return (
            <Paper style={ styles.tooltip }>
                { this.props.tooltip }
            </Paper>
        );
    }
}
