// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';

const styles = {
    container: {
        height: '65px',
        position: 'relative',
    },
    title: {
        position: 'absolute',
        top: '48px',
        left: '0',
        width: '55px',
        textAlign: 'right',
        textTransform: 'uppercase',
        fontSize: '13px',
    },
    ruleOutline: {
        position: 'absolute',
        top: '50px',
        left: '70px',
        right: '65px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '3px',
        height: '8px',
    },
    maxContainer: {
        position: 'absolute',
        top: '0',
        right: '0',
        width: '150px',
        textAlign: 'center',
    },
    ruleFill: {
        position: 'absolute',
        top: '0',
        left: '0',
        height: '8px',
        opacity: '0.5',
    },
    pointContainer: {
        position: 'absolute',
        top: '0',
    },
    pointMarker: {
        position: 'absolute',
        top: '-5px',
        left: '-9px',
        width: '18px',
        height: '18px',
        borderRadius: '50%',
    },
    pointLabel: {
        position: 'absolute',
        top: '-50px',
        left: '-75px',
        width: '150px',
        height: '50px',
        textAlign: 'center',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'comeet.management.dashboard.rule';

    static propTypes = {
        title: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        max: PropTypes.object.isRequired,
        points: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
        style: PropTypes.object
    };

    render () {
        const fillWidth = this.props.max.value > 0
            ? 100 * (Math.max(...this.props.points.map(point => point.value))) / this.props.max.value
            : 0;

        return (
            <div style={ Object.assign({}, styles.container, this.props.style || {}) }>
                <div style={ Object.assign({}, styles.title, { color: this.props.color }) }>
                    { this.props.title }
                </div>

                <div style={ Object.assign({}, styles.maxContainer, { color: this.props.color }) }>
                    { this.props.max.label }
                    <br/>
                    <Views.Common.Number
                        value={ this.props.max.value }
                        type="currency"
                        scale="K"
                    />
                </div>

                <div style={ Object.assign({}, styles.ruleOutline, { borderColor: this.props.color }) }>
                    <div style={ Object.assign({}, styles.ruleFill, { background: this.props.color, width: fillWidth + '%' }) } />
                    { this.props.points.map(this.renderPoint.bind(this)) }
                </div>
            </div>
        );
    }

    renderPoint (point: any, index: any) {
        const position = this.props.max.value > 0
            ? 100 * point.value / this.props.max.value
            : 0;

        return (
            <div key={ 'p' + index } style={ Object.assign({}, styles.pointContainer, { left: position + '%' }) }>
                <div style={ Object.assign({}, styles.pointMarker, { background: this.props.color }) } />

                <div style={ Object.assign({}, styles.pointLabel, { color: this.props.color }) }>
                    { point.label }
                    <br/>
                    <Views.Common.Number
                        value={ point.value }
                        type="currency"
                        scale="K"
                    />
                </div>
            </div>
        );
    }
}
