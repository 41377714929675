// @flow

import type { LinkMandateLevel, LinkStock, RelationNode } from '@deecision/deefind-types/model';

import every from 'lodash/every';
import orderBy from 'lodash/orderBy';

const orderedLevels = ['o', 'm', 'x', 'c', 'a', 'b', 's', 'i'];

export function makePathYears(path: RelationNode[]): { start: ?number, end: ?number } {
    return {
        start: makeLowestYear(path.map((node: RelationNode) => node.start)),
        end: makeHighestYear(path.map((node: RelationNode) => node.end)),
    };
}

export function makePathProxemia(path: RelationNode[]): number {
    const list = path.map((node: RelationNode) => node.proxemia || 0);
    return list.length ? Math.min(...list) : 0;
}

export function makePathFamily(path: RelationNode[]): number {
    if (
        path.length === 2 &&
        path[0].target.flags.includes('property') &&
        parseInt(path[0].target.links) <= 3 &&
        path[1].ceased === false &&
        path[1].target.type === 'person' &&
        path[1].level === 's'
    ) {
        return 1;
    }
    const list = path.map((node: RelationNode) => node.family || 0);
    return list.length ? Math.max(...list) : 0;
}

export function makePathGuessed(path: RelationNode[]): boolean {
    return path.filter((node: RelationNode) => node.guessed).length > 0;
}

export function makePathCeased(path: RelationNode[]): boolean {
    return path.filter((node: RelationNode) => node.ceased).length > 0;
}

export function makeDateYear(date: ?string): ?number {
    const year = date ? date.substr(0, 4) : '';
    return year.length === 4 ? parseInt(year) : null;
}

export function makeHighestYear(years: (?number)[]): ?number {
    let values = years.filter((year: ?number) => !!year);
    values = orderBy(values, v => v, 'desc');
    return values[0];
}

export function makeLowestYear(years: (?number)[]): ?number {
    let values = years.filter((year: ?number) => !!year);
    values = orderBy(values, v => v, 'asc');
    return values[0];
}

export function makePathStocks(paths: RelationNode[][]): { owned?: LinkStock, shared?: LinkStock } {
    let owned = undefined;
    let shared = undefined;

    for (const path of paths) {
        if (path.length === 1 && path[0].owned) {
            owned = path[0].owned;
        }

        if (path.length === 1 && path[0].shared) {
            shared = path[0].shared;
        }
    }

    return { owned, shared };
}
