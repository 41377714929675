import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import { computeProspectValues } from '../compute';
import t from 'app/translation';

export const defaults = {
    payload: {
        settings: {
            aum: 6,
            margin: 1.4,
            period: 4,
            attrition: 1.2,
            cost: 15,
            months: 9,
        },
    },
};

const styles = {
    resultItem: {
        padding: '20px',
        textAlign: 'center',
    },
    resultLabel: {
        textTransform: 'uppercase',
        fontSize: '11px',
        color: colors.grey600,
        marginRight: '10px',
        letterSpacing: '1px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'comeet.common.prospects.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { values: computeProspectValues(props.entity ? props.entity.payload : {}) };
    }

    open(extra) {
        this.refs.dialog.open(extra);
    }

    handleChange(data) {
        this.setState({ values: computeProspectValues(data ? data.payload : {}) });
        return data;
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                model="comeet:prospectings"
                data={this.props.entity || defaults}
                label={t('dna.comeet.saveProspecting')}
                onChange={this.handleChange.bind(this)}
                onSubmit={this.props.onSubmit}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        size={3}
                        label={t('common.label.name')}
                        path="payload.target.name"
                        widget="string"
                        required
                    />
                    <Views.Form.Field
                        size={1}
                        label={t('dna.comeet.wealth')}
                        path="payload.target.fortune"
                        widget={{ type: 'number', decimal: 2, unit: 'M€' }}
                        required
                        onInput={value => value / 1000000}
                        onOutput={value => value * 1000000}
                    />
                    <Views.Form.Field
                        size={1}
                        label={t('dna.comeet.deadline')}
                        path="payload.settings.months"
                        widget={{ type: 'number', unit: 'Months' }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label={t('dna.comeet.aumRate')}
                        path="payload.settings.aum"
                        widget={{ type: 'number', decimals: 2, unit: '%' }}
                        required
                    />
                    <Views.Form.Field
                        label={t('dna.comeet.margin')}
                        path="payload.settings.margin"
                        widget={{ type: 'number', decimals: 2, unit: '%' }}
                        required
                    />
                    <Views.Form.Field
                        label={t('dna.comeet.holdingPeriod')}
                        path="payload.settings.period"
                        widget={{ type: 'number', unit: 'years' }}
                        required
                    />
                    <Views.Form.Field
                        label={t('dna.comeet.attrition')}
                        path="payload.settings.attrition"
                        widget={{ type: 'number', decimals: 2, unit: '%' }}
                        required
                    />
                    <Views.Form.Field
                        label={t('dna.comeet.budgetedCost')}
                        path="payload.settings.cost"
                        widget={{ type: 'number', decimals: 2, unit: '%' }}
                        required
                    />
                </Views.Form.Row>

                {this.renderResults()}

                <Views.Form.Row>
                    <Views.Form.Field label={t('dna.comeet.description')} path="payload.description" widget="text" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }

    renderResults() {
        if (!this.state.values) return null;

        return (
            <Views.Form.Row>
                {this.renderResult(t('dna.comeet.aum'), this.state.values.deposit)}
                {this.renderResult(t('dna.comeet.expectedRevenue'), this.state.values.profit)}
                {this.renderResult(t('dna.comeet.budgetedCost'), this.state.values.investment)}
            </Views.Form.Row>
        );
    }

    renderResult(label, value) {
        return (
            <Views.Form.Cell style={styles.resultItem}>
                <span style={styles.resultLabel}>{label}</span>

                <Views.Common.Number type="currency" value={value} scale="K" />
            </Views.Form.Cell>
        );
    }
}
