import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../common/button';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Dialog from 'material-ui/Dialog';
import Views from 'app/views';

const styles = {
    container: {
        overflow: 'scroll',
        marginBottom: '74px',
    },
    header: {
        color: colors.userCold,
        background: colors.grey100,
        borderBottom: '1px solid ' + colors.grey300,
        fontSize: '17px',
        lineHeight: '40px',
        padding: '10px 0',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1
    },
    icon: {
        fill: colors.grey500,
        width: '24px',
        height: '24px',
        marginLeft: '10px',
        flexGrow: '0',
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: '10px',
        flexGrow: '1',
    },
    close: {
        margin: '0 10px',
        flexGrow: '0',
        minWidth: '110px',
    },
    content: {
        maxWidth: '1500px',
    },
    body: {
        padding: '80px 20px 20px 20px',
        overflow: 'auto',
        color: colors.black,
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.modal.confirm';

    static propTypes = {
        style: PropTypes.object,
        title: PropTypes.node,
        icon: PropTypes.element,
        onClick: PropTypes.func,
        comment: PropTypes.bool,
        message: PropTypes.string
    };

    state = {
        open: false
    };

    open () {
        this.setState({ open: true });
    }

    close () {
        this.setState({ open: false });
    }

    handleClose () {
        this.setState({ open: false }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
    }

    render () {
          const { children } = this.props;

          const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child));

          return (<div>
            <Dialog
                open={ this.state.open }
                style={ styles.container }
                onRequestClose={ this.handleClose.bind(this) }
                contentStyle={ Object.assign({}, styles.content, this.props.style) }
                bodyStyle={ Object.assign({}, styles.body, _.pick(this.props.style, [ 'background' ])) }
                title={ this.renderTitle() }
            >
                <div>
                  { this.props.message ? this.props.message : "Are you sure you want to validate the current phase ?" }
                  <Button
                      style={ styles.close }
                      type="flat"
                      label="Yes"
                      icon={ <Icon type="do.submit" /> }
                      color="hot"
                      onClick={e => {
                        this.props.comment ?
                          (this.state.comment ? (this.props.onClick(this.state.comment) && this.close()) : (this.setState({error: true})))
                          :
                          (this.props.onClick() && this.close())

                      }}
                  />


                  <Button
                      style={ styles.close }
                      type="flat"
                      label="No"
                      icon={ <Icon type="do.cancel" /> }
                      color="hot"
                      onClick={e => {this.handleClose(); this.close()}}
                  />
                    {!this.props.comment ? null : (
                      <div style={{ marginTop: '10px'}}>
                        <span style={{verticalAlign: 'top'}}>
                          Why ?
                        </span>
                        <textarea
                          style={{verticalAlign: 'top', borderRadius: '3px',
                          border: '1px solid ' + (this.state.error ? colors.userHot : colors.userCold),
                          background: 'white',
                          height: '64px',
                          width: '400px',
                          marginLeft: '15px',
                          fontSize: 'inherit',
                          fontFamily: 'inherit',}}
                            value={ this.state.comment }
                            onChange={ event => this.setState({ comment: event.target.value }) }
                        />
                      </div>
                    )}
              </div>
            </Dialog>
            {childrenWithProps}
          </div>);
    }

    renderTitle () {
        if (! this.props.title && ! this.props.onClose) {
            return null;
        }

        return (
            <div style={ styles.header }>
                { this.renderIcon() }

                <span style={ styles.title }>
                    { this.props.title }
                </span>

                <Button
                    style={ styles.close }
                    type="flat"
                    label="Close"
                    icon={ <Icon type="do.close" /> }
                    color="hot"
                    onClick={ this.handleClose.bind(this) }
                />
            </div>
        );
    }

    renderIcon () {
        if (! this.props.icon)
            return null;

        return React.cloneElement(this.props.icon, {
            style: Object.assign({}, styles.icon, this.props.icon.props.style)
        });
    }
}
