import formatPictures from './pictures';
import formatFigures from './figures';
import formatTags from './tags';

export default async function (data: Object, services: Object): Promise<Object> {
    return {
        ...await formatPictures(data, services),
        ...await formatFigures(data, services),
        ...await formatTags(data, services),
    };
}
