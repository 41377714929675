import PropTypes from 'prop-types';
import React from 'react';
import Country from 'dna/entity/common/country';
import get from 'lodash/get';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.info';

    static propTypes = {
        entity: PropTypes.object,
    };

    render() {
        const size = this.renderSize();

        return (
            <span>
                {size ? `${size} — ` : ''}
                <Country code={this.props.entity.preview.country} />
            </span>
        );
    }

    renderSize() {
        if (!this.props.entity || !this.props.entity.data) {
            return null;
        }

        const sourcedSize = get(this.props.entity, 'data.identity.category', 0);
        const info = [];
        const employees = get(this.props.entity, 'data.identity.employees', 0);
        const turnover = get(this.props.entity, 'preview.turnover', 0);
        let size = 0;

        switch (true) {
            case employees < 10 && turnover < 20e6:
                size = 1;
                break;

            case employees < 250 && turnover < 50e6:
                size = 2;
                break;

            case employees < 5000 && turnover < 2000e6:
                size = 3;
                break;
        }

        size = sourcedSize > size ? sourcedSize : size;

        switch (size) {
            case 1:
                info.push(t('dna.deefind.company.size.micro')); // s
                break;

            case 2:
                info.push(t('dna.deefind.company.size.small')); // m
                break;

            case 3:
                info.push(t('dna.deefind.company.size.mid')); // l
                break;

            case 4:
                info.push(t('dna.deefind.company.size.large')); // h
                break;
        }

        if (employees) {
            info.push(t('dna.deefind.company.employees', { value: employees }));
        }

        return info.length ? info.join(' — ') : null;
    }
}
