// @flow
import React from 'react';

import DoCreate from 'material-ui/svg-icons/content/add';
import DoUpdate from 'material-ui/svg-icons/content/create';
import DoDelete from 'material-ui/svg-icons/action/delete';
import DoCancel from 'material-ui/svg-icons/navigation/close';
import DoSubmit from 'material-ui/svg-icons/navigation/check';
import DoClose from 'material-ui/svg-icons/navigation/close';
import DoClear from 'material-ui/svg-icons/navigation/cancel';
import DoSort from 'material-ui/svg-icons/content/sort';
import DoFilter from 'material-ui/svg-icons/content/filter-list';
import DoAssignmentPeople from 'material-ui/svg-icons/action/assignment-ind';
import DoAssignmentBar from 'material-ui/svg-icons/action/assignment';
import DoStart from 'material-ui/svg-icons/av/play-arrow';
import DoPause from 'material-ui/svg-icons/av/pause';
import DoRefresh from 'material-ui/svg-icons/navigation/refresh';
import DoDownload from 'material-ui/svg-icons/file/file-download';
import DoUpload from 'material-ui/svg-icons/file/file-upload';
import DoShare from 'material-ui/svg-icons/content/filter-list';
import DoSearch from 'material-ui/svg-icons/action/search';
import DoDuplicate from 'material-ui/svg-icons/av/library-add';
import DoReport from 'material-ui/svg-icons/action/assignment';
import DoOpen from 'material-ui/svg-icons/av/play-arrow';
import DoWatch from 'material-ui/svg-icons/action/visibility';
import DoTerminate from 'material-ui/svg-icons/hardware/keyboard-tab';
import DoUnfold from 'material-ui/svg-icons/navigation/arrow-drop-down';
import DoUndo from 'material-ui/svg-icons/content/undo';
import DoRedo from 'material-ui/svg-icons/content/redo';
import DoLink from 'material-ui/svg-icons/content/link';
import DoConfigure from 'material-ui/svg-icons/action/settings';
import DoPDF from 'material-ui/svg-icons/image/picture-as-pdf'
import DoExclude from 'material-ui/svg-icons/image/picture-as-pdf'

import BaseWait from 'material-ui/svg-icons/device/access-time';
import BaseYes from 'material-ui/svg-icons/action/done';
import BaseEye from 'material-ui/svg-icons/image/remove-red-eye';
import BaseNo from 'material-ui/svg-icons/content/clear';
import BaseEmpty from 'material-ui/svg-icons/content/block';
import BaseList from 'material-ui/svg-icons/action/list';
import BaseAttachment from 'material-ui/svg-icons/editor/attach-file';
import BaseAttendee from 'material-ui/svg-icons/action/account-circle';
import BaseEmail from 'material-ui/svg-icons/communication/email';
import BasePhone from 'material-ui/svg-icons/communication/phone';
import BaseDiscussion from 'material-ui/svg-icons/communication/message';
import BaseTabsGroup from 'material-ui/svg-icons/action/group-work';
import BasePicture from 'material-ui/svg-icons/image/photo-camera';
import BaseError from 'material-ui/svg-icons/alert/error';
import BaseLock from 'material-ui/svg-icons/action/lock';
import BaseLockOpen from 'material-ui/svg-icons/action/lock-open';
import BaseTreeOpen from 'material-ui/svg-icons/content/add-box';
import BaseTreeClose from 'material-ui/svg-icons/toggle/indeterminate-check-box';
import BaseFlowPending from 'material-ui/svg-icons/navigation/more-horiz';
import BaseFlowBusy from 'material-ui/svg-icons/action/pan-tool';
import BaseFlowReady from 'material-ui/svg-icons/action/info';
import BaseFlowFailure from 'material-ui/svg-icons/content/report';
import BaseFlowSuccess from 'material-ui/svg-icons/action/check-circle';
import BaseFlowWarning from 'material-ui/svg-icons/action/report-problem';
import BaseStatusLoading from 'material-ui/svg-icons/action/query-builder';
import BaseStatusFailure from 'material-ui/svg-icons/action/report-problem';
import BaseStatusSuccess from 'material-ui/svg-icons/action/done';
import BaseCompanionOpen from 'material-ui/svg-icons/av/featured-video';
import BaseCheckboxOff from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import BaseCheckboxOn from 'material-ui/svg-icons/toggle/check-box';
import BaseSignalSmall from 'material-ui/svg-icons/device/signal-wifi-0-bar';
import BaseSignalMedium from 'material-ui/svg-icons/device/signal-wifi-2-bar';
import BaseSignalHigh from 'material-ui/svg-icons/device/signal-wifi-4-bar';
import BaseSignal2Small from 'material-ui/svg-icons/device/signal-cellular-0-bar';
import BaseSignal2Medium from 'material-ui/svg-icons/device/signal-cellular-2-bar';
import BaseSignal2High from 'material-ui/svg-icons/device/signal-cellular-4-bar';
import BaseStarFull from 'material-ui/svg-icons/toggle/star';
import BaseStarHalf from 'material-ui/svg-icons/toggle/star-half';
import BaseStarEmpty from 'material-ui/svg-icons/toggle/star-border';

import MainDashboard from 'material-ui/svg-icons/action/home';
import MainCalendar from 'material-ui/svg-icons/notification/event-note';
import MainTeam from 'material-ui/svg-icons/action/group-work';
import MainMessaging from 'material-ui/svg-icons/action/question-answer';
import MainNotifications from 'material-ui/svg-icons/social/notifications';
import MainAccount from 'material-ui/svg-icons/social/person';
import MainToday from 'material-ui/svg-icons/action/date-range';
import MainText from 'material-ui/svg-icons/action/description';
import MainHelp from 'material-ui/svg-icons/action/help';

import NavApps from 'material-ui/svg-icons/navigation/apps';
import NavMenu from 'material-ui/svg-icons/navigation/menu';
import NavArrowDown from 'material-ui/svg-icons/navigation/arrow-downward';
import NavArrowLeft from 'material-ui/svg-icons/navigation/arrow-back';
import NavArrowRight from 'material-ui/svg-icons/navigation/arrow-forward';
import NavArrowUp from 'material-ui/svg-icons/navigation/arrow-upward';
import NavChevronDown from 'material-ui/svg-icons/navigation/expand-more';
import NavChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import NavChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import NavChevronUp from 'material-ui/svg-icons/navigation/expand-less';
import NavDropDown from 'material-ui/svg-icons/navigation/arrow-drop-down';
import NavDropUp from 'material-ui/svg-icons/navigation/arrow-drop-up';
import NavScalePlus from 'material-ui/svg-icons/content/add-circle';
import NavScaleMinus from 'material-ui/svg-icons/notification/do-not-disturb-on';
import NavLevelPlus from 'material-ui/svg-icons/content/add';
import NavLevelMinus from 'material-ui/svg-icons/content/remove';

import DnaPreesm from 'material-ui/svg-icons/maps/terrain';
import DnaCompany from 'material-ui/svg-icons/social/domain';
import DnaPerson from 'material-ui/svg-icons/social/person';
import DnaSearch from 'material-ui/svg-icons/action/search';
import DnaIdentity from 'material-ui/svg-icons/action/fingerprint';
import DnaTypology from 'material-ui/svg-icons/action/group-work';
import DnaExport from 'material-ui/svg-icons/file/file-download';
import DnaDepth from 'material-ui/svg-icons/action/group-work';
import DnaExplorer from 'material-ui/svg-icons/action/explore';
import DnaDetector from 'material-ui/svg-icons/action/line-weight';
import DnaAnalyze from 'material-ui/svg-icons/editor/insert-chart';
import DnaSharing from 'material-ui/svg-icons/action/swap-vertical-circle';
import DnaFavorites from 'material-ui/svg-icons/toggle/star';
import DnaFinancial from 'material-ui/svg-icons/action/trending-up';
import DnaFinancialList from 'material-ui/svg-icons/action/list';
import DnaFinancialTree from 'material-ui/svg-icons/social/share';
import DnaRelations from 'material-ui/svg-icons/social/people';
import DnaContacts from 'material-ui/svg-icons/hardware/device-hub';
import DnaDeals from 'material-ui/svg-icons/action/dns';
import DnaReport from 'material-ui/svg-icons/action/swap-horiz';
import DnaCompliance from 'material-ui/svg-icons/action/assignment-turned-in';
import DnaFolder from 'material-ui/svg-icons/file/create-new-folder';
import DnaReechFlag from 'material-ui/svg-icons/content/flag';
import DnaReechWarning from 'material-ui/svg-icons/alert/warning';
import DnaReechMain from 'material-ui/svg-icons/content/link';
import DnaMediaRss from 'material-ui/svg-icons/communication/rss-feed';
import DnaMediaLaunch from 'material-ui/svg-icons/action/launch';
import DnaEquity from 'material-ui/svg-icons/action/donut-small';

import DeetectMain from 'material-ui/svg-icons/device/signal-cellular-3-bar';

import VeegilenzAllright from 'material-ui/svg-icons/navigation/check';
import VeegilenzWarning from 'material-ui/svg-icons/alert/error-outline';
import VeegilenzAlert from 'material-ui/svg-icons/content/clear';
import VeegilenzUnknown from 'material-ui/svg-icons/content/block';

import ComeetCustomer from 'material-ui/svg-icons/file/folder-shared';
import ComeetProspect from 'material-ui/svg-icons/file/create-new-folder';
import ComeetSummary from 'material-ui/svg-icons/action/view-module';
import ComeetEvent from 'material-ui/svg-icons/action/event';
import ComeetMilestone from 'material-ui/svg-icons/content/flag';
import ComeetMeeting from 'material-ui/svg-icons/action/record-voice-over';
import ComeetReport from 'material-ui/svg-icons/action/assignment';

import DeeligenzArchive from 'material-ui/svg-icons/file/folder';
import DeeligenzSurveyOnSiteQuestions from 'material-ui/svg-icons/av/playlist-add';
import DeeligenzSummary from 'material-ui/svg-icons/image/view-comfy';
import DeeligenzStudies from 'material-ui/svg-icons/av/playlist-add-check';
import DeeligenzCalendar from 'material-ui/svg-icons/notification/event-note';
import DeeligenzManagement from 'material-ui/svg-icons/action/settings';
import DeeligenzQuestions from 'material-ui/svg-icons/action/list';
import DeeligenzFund from 'material-ui/svg-icons/editor/insert-chart';
import DeeligenzCompany from 'material-ui/svg-icons/social/domain';
import DeeligenzApproved from 'material-ui/svg-icons/action/check-circle';
import DeeligenzRejected from 'material-ui/svg-icons/alert/error';
import DeeligenzVetoed from 'material-ui/svg-icons/alert/error';
import DeeligenzNdaMain from 'material-ui/svg-icons/action/card-membership';
import DeeligenzNdaMissing from 'material-ui/svg-icons/file/cloud';
import DeeligenzNdaPending from 'material-ui/svg-icons/file/cloud-download';
import DeeligenzNdaSigned from 'material-ui/svg-icons/file/cloud-done';
import DeeligenzNdaOk from 'material-ui/svg-icons/action/check-circle';
import DeeligenzNdaKo from 'material-ui/svg-icons/action/report-problem';
import DeeligenzSurveyAll from 'material-ui/svg-icons/device/brightness-auto';
import DeeligenzSurveyInput from 'material-ui/svg-icons/hardware/keyboard';
import DeeligenzSurveyFile from 'material-ui/svg-icons/editor/insert-drive-file';
import DeeligenzSurveyTodo from 'material-ui/svg-icons/av/play-circle-outline';
import DeeligenzSurveyDraft from 'material-ui/svg-icons/av/pause-circle-filled';
import DeeligenzSurveyPending from 'material-ui/svg-icons/action/query-builder';
import DeeligenzSurveyValidated from 'material-ui/svg-icons/action/check-circle';
import DeeligenzSurveyRejected from 'material-ui/svg-icons/navigation/cancel';
import DeeligenzSurveyDdq from 'material-ui/svg-icons/av/equalizer';
import DeeligenzSurveyReport from 'material-ui/svg-icons/action/assignment';
import DeeligenzSurveyPicture from 'material-ui/svg-icons/action/picture-in-picture';
import DeeligenzSurveyOverview from 'material-ui/svg-icons/action/view-quilt';
import DeeligenzSurveyInfo from 'material-ui/svg-icons/action/info';
import DeeligenzLibrary from 'material-ui/svg-icons/device/storage';
import DeeligenzSurveyOnSite from 'material-ui/svg-icons/action/record-voice-over';
import DeeligenzSurveyOnSiteAttendee from 'material-ui/svg-icons/social/person-add';
import DeeligenzSurveyOnSiteBooking from 'material-ui/svg-icons/action/compare-arrows';
import DeeligenzSurveyOnSiteEvent from 'material-ui/svg-icons/action/event';
import DeeligenzSurveyOnSiteEvaluation from 'material-ui/svg-icons/action/assignment-turned-in';
import DeeligenzSurveyAllRight from 'material-ui/svg-icons/action/thumb-up';
import DeeligenzSurveyConcern from 'material-ui/svg-icons/alert/error';
import DeeligenzSurveyFlag from 'material-ui/svg-icons/content/flag';
import DeeligenzSurveyFields from 'material-ui/svg-icons/action/list';
import DeeligenzSurveyKeyFinding from 'material-ui/svg-icons/communication/vpn-key';
import DeeligenzSurveyAgenda from 'material-ui/svg-icons/notification/event-available';
import DeeligenzSurveyNextReview from 'material-ui/svg-icons/content/redo';

import CleeranceAdmin from 'material-ui/svg-icons/action/group-work';
import CleeranceTeams from 'material-ui/svg-icons/action/group-work';
import CleeranceSettings from 'material-ui/svg-icons/action/settings';
import CleeranceMembers from 'material-ui/svg-icons/action/supervisor-account';
import CleeranceLinks from 'material-ui/svg-icons/action/swap-horiz';
import CleeranceDisabled from 'material-ui/svg-icons/content/block';
import CleeranceCloud from 'material-ui/svg-icons/file/cloud-done';
import CleerancePrivate from 'material-ui/svg-icons/action/home';

import { BaseGrip } from './svgs'

export default {
    'do.create': DoCreate,
    'do.update': DoUpdate,
    'do.delete': DoDelete,
    'do.cancel': DoCancel,
    'do.submit': DoSubmit,
    'do.close': DoClose,
    'do.clear': DoClear,
    'do.sort': DoSort,
    'do.filter': DoFilter,
    'do.start': DoStart,
    'do.pause': DoPause,
    'do.refresh': DoRefresh,
    'do.download': DoDownload,
    'do.upload': DoUpload,
    'do.share': DoShare,
    'do.duplicate': DoDuplicate,
    'do.search': DoSearch,
    'do.report': DoReport,
    'do.open': DoOpen,
    'do.watch': DoWatch,
    'do.terminate': DoTerminate,
    'do.unfold': DoUnfold,
    'do.undo': DoUndo,
    'do.redo': DoRedo,
    'do.link': DoLink,
    'do.configure': DoConfigure,
    'do.pdf': DoPDF,
    'do.assignment.people': DoAssignmentPeople,
    'do.assignment.bar': DoAssignmentBar,
    'do.exclude': DoExclude,

    'base.blank': () => { return React.createElement('div', {style: {height: '44px', width: '44px'}}) },
    'base.wait': BaseWait,
    'base.yes': BaseYes,
    'base.eye': BaseEye,
    'base.no': BaseNo,
    'base.empty': BaseEmpty,
    'base.attachment': BaseAttachment,
    'base.attendee': BaseAttendee,
    'base.email': BaseEmail,
    'base.phone': BasePhone,
    'base.discussion': BaseDiscussion,
    'base.picture': BasePicture,
    'base.error': BaseError,
    'base.list': BaseList,
    'base.lock': BaseLock,
    'base.lockOpen': BaseLockOpen,
    'base.grip': BaseGrip,
    'base.tabs.group': BaseTabsGroup,
    'base.tree.open': BaseTreeOpen,
    'base.tree.close': BaseTreeClose,
    'base.flow.pending': BaseFlowPending,
    'base.flow.busy': BaseFlowBusy,
    'base.flow.ready': BaseFlowReady,
    'base.flow.failure': BaseFlowFailure,
    'base.flow.success': BaseFlowSuccess,
    'base.flow.warning': BaseFlowWarning,
    'base.status.loading': BaseStatusLoading,
    'base.status.failure': BaseStatusFailure,
    'base.status.success': BaseStatusSuccess,
    'base.companion.open': BaseCompanionOpen,
    'base.signal.small': BaseSignalSmall,
    'base.signal.medium': BaseSignalMedium,
    'base.signal.high': BaseSignalHigh,
    'base.signal2.small': BaseSignal2Small,
    'base.signal2.medium': BaseSignal2Medium,
    'base.signal2.high': BaseSignal2High,
    'base.star.full': BaseStarFull,
    'base.star.half': BaseStarHalf,
    'base.star.empty': BaseStarEmpty,
    'base.checkbox.on': BaseCheckboxOn,
    'base.checkbox.off': BaseCheckboxOff,

    'main.help': MainHelp,
    'main.today': MainToday,
    'main.dashboard': MainDashboard,
    'main.account': MainAccount,
    'main.team': MainTeam,
    'main.calendar': MainCalendar,
    'main.messaging': MainMessaging,
    'main.notifications': MainNotifications,
    'main.text': MainText,

    'nav.apps': NavApps,
    'nav.menu': NavMenu,
    'nav.arrow.down': NavArrowDown,
    'nav.arrow.left': NavArrowLeft,
    'nav.arrow.right': NavArrowRight,
    'nav.arrow.up': NavArrowUp,
    'nav.chevron.down': NavChevronDown,
    'nav.chevron.left': NavChevronLeft,
    'nav.chevron.right': NavChevronRight,
    'nav.chevron.up': NavChevronUp,
    'nav.drop.down': NavDropDown,
    'nav.drop.up': NavDropUp,
    'nav.scale.plus': NavScalePlus,
    'nav.scale.minus': NavScaleMinus,
    'nav.level.plus': NavLevelPlus,
    'nav.level.minus': NavLevelMinus,

    'dna.equity': DnaEquity,
    'dna.preesm': DnaPreesm,
    'dna.explorer': DnaExplorer,
    'dna.detector': DnaDetector,
    'dna.analyze': DnaAnalyze,
    'dna.company': DnaCompany,
    'dna.person': DnaPerson,
    'dna.search': DnaSearch,
    'dna.typology': DnaTypology,
    'dna.export': DnaExport,
    'dna.depth': DnaDepth,
    'dna.sharing': DnaSharing,
    'dna.favorites': DnaFavorites,
    'dna.identity': DnaIdentity,
    'dna.financial': DnaFinancial,
    'dna.financial.list': DnaFinancialList,
    'dna.financial.tree': DnaFinancialTree,
    'dna.relations': DnaRelations,
    'dna.source': DnaRelations,
    'dna.contacts': DnaContacts,
    'dna.deals': DnaDeals,
    'dna.media.rss': DnaMediaRss,
    'dna.media.launch': DnaMediaLaunch,
    'dna.report': DnaReport,
    'dna.compliance': DnaCompliance,
    'dna.folder': DnaFolder,
    'dna.reech.flag': DnaReechFlag,
    'dna.reech.warning': DnaReechWarning,
    'dna.reech.main': DnaReechMain,

    'comeet.prospect': ComeetProspect,
    'comeet.customer': ComeetCustomer,
    'comeet.summary': ComeetSummary,
    'comeet.event': ComeetEvent,
    'comeet.milestone': ComeetMilestone,
    'comeet.meeting': ComeetMeeting,
    'comeet.report': ComeetReport,

    'deetect.main': DeetectMain,

    'veegilenz.allright': VeegilenzAllright,
    'veegilenz.warning': VeegilenzWarning,
    'veegilenz.alert': VeegilenzAlert,
    'veegilenz.unknown': VeegilenzUnknown,

    'deeligenz.archive': DeeligenzArchive,
    'deeligenz.summary': DeeligenzSummary,
    'deeligenz.studies': DeeligenzStudies,
    'deeligenz.study': DeeligenzStudies,
    'deeligenz.calendar': DeeligenzCalendar,
    'deeligenz.fund': DeeligenzFund,
    'deeligenz.management': DeeligenzManagement,
    'deeligenz.questions': DeeligenzQuestions,
    'deeligenz.library': DeeligenzLibrary,
    'deeligenz.company': DeeligenzCompany,
    'deeligenz.approved': DeeligenzApproved,
    'deeligenz.rejected': DeeligenzRejected,
    'deeligenz.vetoed': DeeligenzVetoed,
    'deeligenz.nda.main': DeeligenzNdaMain,
    'deeligenz.nda.missing': DeeligenzNdaMissing,
    'deeligenz.nda.pending': DeeligenzNdaPending,
    'deeligenz.nda.signed': DeeligenzNdaSigned,
    'deeligenz.nda.ok': DeeligenzNdaOk,
    'deeligenz.nda.ko': DeeligenzNdaKo,
    'deeligenz.survey.all': DeeligenzSurveyAll,
    'deeligenz.survey.input': DeeligenzSurveyInput,
    'deeligenz.survey.file': DeeligenzSurveyFile,
    'deeligenz.survey.todo': DeeligenzSurveyTodo,
    'deeligenz.survey.draft': DeeligenzSurveyDraft,
    'deeligenz.survey.pending': DeeligenzSurveyPending,
    'deeligenz.survey.validated': DeeligenzSurveyValidated,
    'deeligenz.survey.rejected': DeeligenzSurveyRejected,
    'deeligenz.survey.info': DeeligenzSurveyInfo,
    'deeligenz.survey.ddq': DeeligenzSurveyDdq,
    'deeligenz.survey.report': DeeligenzSurveyReport,
    'deeligenz.survey.picture': DeeligenzSurveyPicture,
    'deeligenz.survey.overview': DeeligenzSurveyOverview,
    'deeligenz.survey.onSite': DeeligenzSurveyOnSite,
    'deeligenz.survey.onSiteBooking': DeeligenzSurveyOnSiteBooking,
    'deeligenz.survey.onSite.questions': DeeligenzSurveyOnSiteQuestions,
    'deeligenz.survey.onSiteEvent': DeeligenzSurveyOnSiteEvent,
    'deeligenz.survey.onSiteAttendee': DeeligenzSurveyOnSiteAttendee,
    'deeligenz.survey.onSiteEvaluation': DeeligenzSurveyOnSiteEvaluation,
    'deeligenz.survey.allRight': DeeligenzSurveyAllRight,
    'deeligenz.survey.concern': DeeligenzSurveyConcern,
    'deeligenz.survey.flag': DeeligenzSurveyFlag,
    'deeligenz.survey.fields': DeeligenzSurveyFields,
    'deeligenz.survey.agenda': DeeligenzSurveyAgenda,
    'deeligenz.survey.keyFinding': DeeligenzSurveyKeyFinding,
    'deeligenz.survey.nextReview': DeeligenzSurveyNextReview,

    'cleerance.admin': CleeranceAdmin,
    'cleerance.teams': CleeranceTeams,
    'cleerance.settings': CleeranceSettings,
    'cleerance.members': CleeranceMembers,
    'cleerance.links': CleeranceLinks,
    'cleerance.disabled': CleeranceDisabled,
    'cleerance.cloud': CleeranceCloud,
    'cleerance.private': CleerancePrivate,
};
