import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Moment from 'moment';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {},
    transition: {
        marginBottom: '10px',
    },
    status: {
        display: 'flex',
        lineHeight: '24px',
        fontSize: '13px',
    },
    statusName: {
        fontWeight: 'bold',
        fontSize: '15px',
    },
    statusIcon: {
        marginRight: '10px',
        opacity: 0.75,
    },
};

@inject(stores => ({ contacts: stores.auth.contacts }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.studies.common.survey.field.aside.history';

    static propTypes = {
        scope: PropTypes.oneOf(['input', 'ddq', 'onSite', 'report']),
        history: PropTypes.arrayOf(PropTypes.object),
    };

    render() {
        if (this.props.scope !== 'input') {
            return null;
        }

        return (
            <div style={styles.container}>
                {(this.props.history || []).map(transition => this.renderTransition(transition))}
            </div>
        );
    }

    renderTransition(transition: Object) {
        let color = colors.grey500;
        let name = transition.transition;
        let icon = 'deeligenz.survey.todo';

        switch (transition.transition) {
            case 'answer':
                color = colors.userCold;
                name = 'Answered';
                icon = 'deeligenz.survey.pending';
                break;

            case 'validate':
                color = colors.userGreen;
                name = 'Validated';
                icon = 'deeligenz.survey.validated';
                break;

            case 'reject':
                color = colors.userHot;
                name = 'Rejected';
                icon = 'deeligenz.survey.rejected';
                break;
        }

        return (
            <div key={transition.id} style={styles.transition}>
                <div style={styles.status}>
                    <Icon type={icon} style={styles.statusIcon} color={color} />

                    <span style={{ color }}>
                        <strong style={styles.statusName}>{name}</strong>
                    </span>
                </div>
                <div>
                    <span style={{ fontSize: '13px', paddingLeft: '34px', color }}>
                        {' on ' + Moment(transition.createStamp).format('lll')}
                        {' by ' + this.renderContactName(transition.ownerUid)}
                    </span>
                </div>
            </div>
        );
    }

    renderContactName(uuid) {
        const users = this.props.contacts ? this.props.contacts.users : null;

        if (!users || !users[uuid]) {
            return 'Unknown';
        }

        return users[uuid].name ? users[uuid].name : users[uuid].firstName + ' ' + users[uuid].lastName;
    }
}
