import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import colors from 'app/theme/colors';
import Views from 'app/views';

const styles = {
    container: {},
    item: {
        display: 'block',
        padding: '5px 5px',
        width: 'auto',
        cursor: 'pointer',
        height: '20px',
    },
    hoverItem: {
        background: colors.grey300,
    },
    label: {
        width: 'calc(100% - 25px)',
        fontSize: '15px',
        lineHeight: '20px',
        minHeight: '20px',
        color: colors.grey800,
    },
    checkedLabel: {
        color: colors.grey500,
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
        fill: colors.userCold,
    },
};

export default class extends React.PureComponent<any, any> {
    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                checked: PropTypes.bool,
            }),
        ).isRequired,
        onChange: PropTypes.func,
        style: PropTypes.object,
    };

    state = {
        hover: null,
    };

    render() {
        return (
            <div
                style={Object.assign({}, styles.container, this.props.style)}
                onMouseLeave={event => this.setState({ hover: null })}
            >
                {this.renderActions()}
                <br/>
                {this.props.items.map(this.renderItem.bind(this))}
            </div>
        );
    }

    renderActions() {
        if (this.props.items.filter(item => item.checked).length === this.props.items.length) {
            return (
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Un-Select everybody"
                    onClick={() => {
                        this.props.onChange(
                            this.props.items.map(item => Object.assign({}, item, { checked: false })),
                        );
                    }}
                />
            );
        }

        return (
            <Views.Common.Button
                type="flat"
                color="cold"
                label="Select everybody"
                onClick={() => {
                    this.props.onChange(
                        this.props.items.map(item => Object.assign({}, item, { checked: true })),
                    );
                }}
            />
        );
    }

    renderItem(item) {
        const itemStyle = Object.assign({}, styles.item, this.state.hover === item.id ? styles.hoverItem : {});

        const labelStyle = Object.assign({}, styles.label, item.checked ? styles.checkedLabel : {});

        const onChange = this.props.onChange
            ? (event, checked) =>
                  this.props.onChange(
                      _.map(this.props.items, input =>
                          input.id === item.id ? Object.assign({}, input, { checked }) : input,
                      ),
                  )
            : undefined;

        return (
            <Checkbox
                key={item.id}
                label={item.label}
                style={itemStyle}
                labelStyle={labelStyle}
                iconStyle={styles.icon}
                checked={!!item.checked}
                onCheck={onChange}
                onMouseEnter={event => this.setState({ hover: item.id })}
            />
        );
    }
}
