import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';

import Scalar from './scalar';
import Table from './table';
import ObjectWidget from './object';
import Select from './select';
import Nd from './nd';
import Na from './na';
import omit from 'lodash/omit';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    options: {
        margin: '-20px 0 20px 0',
        textAlign: 'center',
    },
    widget: {
        margin: 'auto'
    },
    actions: {
        marginTop: '20px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget';

    static propTypes = {
        readOnly: PropTypes.bool,
        question: PropTypes.object.isRequired,
        answer: PropTypes.any,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = { empty: true, changed: false, answer: props.answer };
    }

    handleChange(answer, deleted) {
        if (
            (answer.choices && !answer.choices.length) ||
            (answer.values && !answer.values.length) ||
            (answer.rows && !answer.rows.length)
        ) {
            this.setState({ changed: !!deleted, answer: undefined });
        } else {
            this.setState({ changed: true, answer: omit(answer, ['na', 'nd', 'meeting']) });
        }

        if (answer.values && !answer.values.filter(v => !!v).length) {
            this.setState({empty: true})
        } else {
            this.setState({empty: false})
        }

    }

    handleSubmit(answer?: Object, draft: ?boolean = false) {
        this.props.onChange(answer || Object.assign({}, this.state.answer, { draft }));
        this.setState({ changed: false, answer: draft ? this.state.answer : undefined });
    }

    render() {
        if (this.props.readOnly) {
            return (
                <div style={styles.container}>
                    <div style={styles.widget}>{this.renderWidget()}</div>
                </div>
            );
        }

        return (
            <div style={styles.container}>
                <div style={styles.options}>{this.renderOptions()}</div>

                <form style={{}} encType="multipart/form-data" onSubmit={(e) => {this.handleSubmit(null); e.preventDefault()}}>
                    <div style={styles.widget}>{this.renderWidget(this.props.readOnly, this.props.type || 'invalid')}</div>
                    <div style={styles.actions}>{this.renderActions()}</div>
                </form>
            </div>
        );
    }

    renderWidget() {
        let type = (this.props.question ? this.props.question.type : 'invalid') || 'invalid';
        let options = (this.props.question ? this.props.question.options : {}) || {};

        const readOnly = this.props.readOnly;
        const list = type.substr(-2) === '[]';
        const answer = (this.state.changed ? this.state.answer : this.props.answer) || {};

        if (list) {
            type = type.substr(0, type.length - 2);
        }

        switch (type) {
            case 'string':
            case 'number':
            case 'date':
            case 'text':
            case 'boolean':
            case 'location':
            case 'email':
            case 'file':
            case 'country':
            case 'currency':
                return (
                    <Scalar
                        type={type}
                        list={list}
                        options={Object.assign({}, options, {decimal: true})}
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                    />
                );

            case 'money': {
                return (
                    <ObjectWidget
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                        fields={[{ options: {decimal :true}, type: 'number', label: 'Amount' }, { type: 'currency', label: 'Currency' }]}
                    />
                );
            }

            case 'select':
                return (
                    <Select
                        options={options}
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                    />
                );

            case 'object':
                return (
                    <ObjectWidget
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                        fields={options.fields || []}
                    />
                );

            case 'table':
                return (
                    <Table
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                        columns={options.columns || []}
                    />
                );

            case 'persons': {
                const columns = [
                    {
                        type: 'string',
                        label: 'First name',
                    },
                    {
                        type: 'string',
                        label: 'Last name',
                    },
                    {
                        type: 'date',
                        label: 'Birth date',
                    },
                    {
                        type: 'string',
                        label: 'Position',
                    },
                ];

                return (
                    <Table
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                        columns={columns.concat(options.columns || [])}
                    />
                );
            }

            case 'companies': {
                const columns = [
                    {
                        type: 'string',
                        label: 'Name',
                    },
                    {
                        type: 'country',
                        label: 'Country',
                    },
                    {
                        type: 'string',
                        label: 'National identification number',
                    },
                ];

                return (
                    <Table
                        answer={answer}
                        readOnly={readOnly}
                        onChange={this.handleChange.bind(this)}
                        columns={columns.concat(options.columns || [])}
                    />
                );
            }

            default:
                return <pre>{JSON.stringify(type)}</pre>;
        }
    }

    renderOptions() {
        const question = this.props.question || {};
        const answer = this.props.answer || {};

        return [
            <Na
                key="na"
                question={question.label}
                value={answer ? answer.na : false}
                onChange={value => {
                    this.handleSubmit({ na: value });
                }}
            />,
            <Nd
                key="nd"
                question={question.label}
                value={answer ? answer.nd : false}
                onChange={(value, meeting) => this.handleSubmit({ nd: value, meeting })}
            />,
        ];
    }

    renderActions() {
        return (
            <div>
                <Views.Common.Button
                    key="submit"
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    label="Submit"
                    submit={true}

                    disabled={this.props.answer.draft ? false : this.state.changed ? false : true}
                />
                <Views.Common.Button
                    style={{paddingLeft: '20px'}}
                    key="draft"
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    label="Save as draft"
                    onClick={() => this.handleSubmit(null, true)}
                    disabled={!this.state.changed}
                />
            </div>
    );
    }
}
