import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import get from 'lodash/get';
import Sum from 'dna/entity/common/display/sum';
import t from 'app/translation';

const styles = {
    container: {
        margin: '0 170px 0 0'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.person.card.properties';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={ styles.container }>
                <Views.Common.Properties
                    align={ 90 }
                    values={[
                        {
                            label: t('common.label.fortune'),
                            value: this.renderValue(get(this.props.entity, 'preview.fortune'))
                        }, {
                            label: t('common.label.weasse'),
                            value: <Sum data={ get(this.props.entity, 'preview.weasse' || {}) } />
                        }, {
                            label: 'Weasse Max',
                            value: <Sum data={ get(this.props.entity, 'preview.weasseMax' || {}) } />
                        }, {
                            label: t('common.label.sumTurnover'),
                            value: <Sum data={ get(this.props.entity, 'preview.turnoversSum' || {}) } />
                        }
                    ]}
                />
            </div>
        );
    }

    renderValue(value) {
        return (
            <Views.Common.Number
                type="currency"
                scale="M"
                value={ value }
            />
        );
    }
}
