import { get } from 'lodash';

import formatODD from './odd';
import formatMDD from './mdd';
import formatCommon from './common';
import formatDocuments from './common/documents';
import formatQuestions from './common/questions';

export default async function(data: Object, services: Object, reportType: string): Promise<Object> {
    const isFund = get(data.study, 'payload.target.type') === 'fund';
    const isMdd = data.study.type === 'mdd';

    let common = {
        isFund,
        isMdd,
        owner: data.owner,
        ...(await formatCommon(data, services)),
    };

    if (['full', 'survey'].includes(reportType)) {
        common = {
            ...common,
            ...(await formatDocuments(data, services, reportType)),
            ...(await formatQuestions(data, services, reportType)),
        };
    }

    if (isMdd) {
        return {
            ...common,
            ...(await formatMDD(data, services)),
        };
    }

    return {
        ...common,
        ...(await formatODD(data, services)),
    };
}
