import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    wrapper: {
        overflow: 'hidden',
        position: 'relative',
        display: 'inline-block',
        borderRadius: '3px',
    },
    rule: {
        position: 'absolute',
        top: '0',
        left: '0',
        background: `linear-gradient(to right, ${colors.userGreen}, ${colors.userGreen}, ${colors.userGreen}, ${colors.userGreen}, ${colors.userWarning}, ${colors.userWarning}, ${colors.userHot}, ${colors.userHot}, ${colors.userHot})`,
    },
    value: {
        position: 'absolute',
        top: '0',
        right: '5px',
        color: 'white',
        fontSize: '21px',
    },
    empty: {
        background: 'white',
        color: 'white',
        padding: '0 5px',
        display: 'inline-block',
        borderRadius: '3px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.vigilance.score';

    static propTypes = {
        score: PropTypes.number,
        big: PropTypes.bool,
    };

    render() {
        const commonStyle = this.props.big
            ? { height: '30px', lineHeight: '30px' }
            : { height: '20px', lineHeight: '20px' };


        if (this.props.score < 1) {
            return <div style={Object.assign({}, styles.empty, commonStyle)}>N/A</div>;
        }

        const ruleStyle = this.props.big ? { width: '250px' } : { width: '175px' };
        const valueStyle = this.props.big ? { fontSize: '20px' } : { fontSize: '15px' };
        const width = ((this.props.big ? 250 : 175) * (this.props.score / 4)) + 'px';

        return (
            <div style={ Object.assign({}, styles.wrapper, commonStyle, { width }) }>
                &nbsp;
                <div style={ Object.assign({}, styles.rule, commonStyle, ruleStyle) }>&nbsp;</div>
                <div style={ Object.assign({}, styles.value, commonStyle, valueStyle) }>{ (this.props.score || 0 ).toFixed(2) }</div>
            </div>
        );
    }
}
