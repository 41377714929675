// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import WorkLoad from './workload';
import Project from './project';
import Scope from 'app/layout/providers/scope';

import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.deeligenzCalendar }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.calendar';

    static propTypes = {
      store: PropTypes.object.isRequired
    };

    render () {
        return (
          // <Scope
          //     path="filter"
          //     type="object"
          // >
            <Screen
                path="calendar"
                type="workspace"
                select={ this.props.store.selectStage }

                stages={{
                    workload: {
                        entry: { icon: 'do.assignment.people', label: 'Workload' },
                        content: WorkLoad,
                    },
                    project: {
                        entry: { icon: 'do.assignment.bar', label: 'Project' },
                        content: Project,
                    }
                }}
            />
          // </Scope>
        );
    }
}
