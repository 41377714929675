import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import ActiveIcon from 'material-ui/svg-icons/toggle/check-box';
import InctiveIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank';

const styles = {
    container: {
        flexGrow: '0',
        flexShrink: '0',
        borderRadius: '3px 0 0 3px',
        paddingRight: '10px',
        width: '30px',
        textAlign: 'center',
        color: colors.userCold,
        lineHeight: '30px',
        cursor: 'pointer',
    },
    icon: {
        margin: '3px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'index';

    static propTypes = {
        data: PropTypes.object,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const value = !!(this.props.data || {}).active;

        return (
            <div
                style={styles.container}
                onClick={() => {
                    const data = this.props.data ? _.clone(this.props.data) : {};
                    data.active = !value;
                    this.props.onChange(data);
                }}
            >
                {value ? (
                    <ActiveIcon style={Object.assign({}, styles.icon, { fill: colors.userCold })} />
                ) : (
                    <InctiveIcon style={Object.assign({}, styles.icon, { fill: colors.grey500 })} />
                )}
            </div>
        );
    }
}
