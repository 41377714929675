import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

let styles = {
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
    },
    remove: {
        color: colors.userHot,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.contacts.item';

    static propTypes = {
        entity: PropTypes.object,
        style: PropTypes.object,
        onUpdateSales: PropTypes.func.isRequired,
        onUpdateManager: PropTypes.func.isRequired,
        onAddAttendee: PropTypes.func.isRequired,
        onRemoveAttendee: PropTypes.func.isRequired,
    };

    render() {
        const cellProps = { style: styles.cell };
        let role = this.props.entity.type === 2 ? 'Sales' : this.props.entity.type === 1 ? 'Manager' : 'Attendee';

        return (
            <tr>
                <td {...cellProps}>{role}</td>
                <td {...cellProps}>{this.props.entity.name}</td>
                <td {...cellProps}>{this.props.entity.email}</td>
                <td {...cellProps}>{this.props.entity.phone}</td>
                <td {...cellProps}>{this.renderAction()}</td>
            </tr>
        );
    }

    renderAction() {
        if (this.props.entity.type !== 0) {
            return null;
        }

        return (
            <a
                style={styles.remove}
                onClick={() => this.props.onRemoveAttendee(this.props.entity)}
            >
                Remove
            </a>
        )
    }
}
