import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Flag from 'dna/entity/common/flag';

import VigilanceButton from 'dna/entity/common/vigilance/button';
import ComplianceButton from 'dna/entity/common/compliance/button';
import Reech from 'dna/entity/common/reech';
import t from 'app/translation';

const styles = {
    header: {
        padding: '5px'
    },
    name: {
        display: 'inline-block',
        marginRight: '15px',
        verticalAlign: 'middle',
        fontSize: '15px'
    },
    button: {
        display: 'inline-block',
        marginRight: '15px',
        verticalAlign: 'middle'
    },
    info: {
        display: 'inline-block',
        fontSize: '15px',
        marginLeft: '20px',
        color: colors.grey500,
        verticalAlign: 'middle'
    },
    flag: {
        marginLeft: '5px',
        verticalAlign: 'text-bottom'
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.cockpit.infos';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
        return (
            <div style={{marginTop: '5px', marginRight: '5px', marginLeft: '5px', border: '2px solid '+colors.grey400 }} >
                { this.renderHeader() }
                <div style={{ padding: '5px'}}>
                      <Views.Common.Header
                          style={ styles.header }
                          title={
                              <div style={{ display: 'flex' }}>
                                  <VigilanceButton
                                      entity={ this.props.entity }
                                      network={ this.props.network }
                                      style={ styles.button }
                                  />

                                  <ComplianceButton
                                      entity={ this.props.entity }
                                      network={ this.props.network }
                                      style={ styles.button }
                                      mini
                                  />

                                  <Reech
                                      entity={ this.props.entity }
                                      style={ styles.button }
                                  />

                                  <span style={ styles.name }>
                                      { this.props.entity.preview.name }
                                  </span>

                                  <Flag
                                      label="F"
                                      tooltip="Ranked fortune owner"
                                      active={ this.props.entity.preview.flags.includes('fortune') }
                                      style={ styles.flag }
                                  />

                                  <Flag
                                      label="Ld"
                                      tooltip="Direct relation with listed company"
                                      active={ this.props.entity.preview.flags.includes('listed-direct') }
                                      style={ styles.flag }
                                  />

                                  <Flag
                                      label="Li"
                                      light={ true }
                                      tooltip="Indirect relation with listed company"
                                      active={ this.props.entity.preview.flags.includes('listed-indirect') }
                                      style={ styles.flag }
                                  />
                              </div>
                          }
                      />
                      <Views.Common.Properties
                          values={ [
                            { label: t('dna.deefind.person.genderLabel'), value: (t('dna.deefind.person.genderOptions.' + this.props.entity.data.identity.gender)) },
                            { label: t('dna.deefind.veegilenzData.person.birthdate'), value: (this.props.entity.data.identity.birthDate) },
                            { label: t('dna.deefind.person.ageLabel'), value: (this.props.entity.data.identity.birthDate ? new Date().getFullYear() - parseInt((this.props.entity.data.identity.birthDate || '').substr(0, 4)) : null) },
                          ] }
                          align={ 80 }
                      />
                </div>
            </div>
        );
    }

    renderHeader() {
      return (
        <div style={{backgroundColor: colors.grey200, padding: '5px'}}>
          <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%',textAlign: 'left' }}>
            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.person" />
          </div>
          <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '40%',textAlign: 'left', color: colors.userCold }}>
            Infos
          </div>

          <div style={{ color: colors.grey400, fontSize: '13px', verticalAlign: 'middle', display: 'inline-block', width: '50%',textAlign: 'right' }}>
            <Icon style={{ verticalAlign: 'middle', fill: colors.grey400, height: '30px', width: '30px' }} type="main.today" />
            <div style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <div>Last Update</div>
              <div>09/10/2018</div>
            </div>
          </div>
        </div>
      );
    }
}
