import PropTypes from 'prop-types';
import React from 'react';

import { get, forEach } from 'lodash';
import t from 'app/translation';
import colors from 'app/theme/colors';
import DeefindProvider from 'app/layout/providers/deefind';
import Views from 'app/views';

const styles = {
    container: {},
    values: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    table: {
        width: '100%',
        marginTop: '20px'
    },
    header: {
        padding: '10px 5px',
        color: colors.blueGrey200,
        fontWeight: 'bold',
        fontSize: '18px'
    },
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
        width: 'auto',
        textAlign: 'center'
    },
    headerCell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
        width: '20%',
        backgroundColor: colors.bgCold,
        textAlign: 'left'
    },
    hover: {
        backgroundColor: colors.bgCold,
    },
};

const fields = {
    keyData: {
        'Financials & employees': [
            'opre',
            'plbt',
            'pl',
            'cf',
            'toas',
            'shfd',
            'curr',
            'prma',
            'rshf',
            'rceme',
            'solr',
            'empl',
        ],
    },
    globalFormat: {
        Assets: ['fias', 'ifas', 'tfas', 'ofas', 'cuas', 'stok', 'debt', 'ocas', 'cash'],
        'LIABILITIES & EQUITY': ['shfd', 'capi', 'ncli', 'ltdb', 'oncl', 'prov', 'culi', 'loan', 'cred', 'ocli'],
        'MEMO LINES': ['wkca', 'ncas', 'empl'],
        'Profit & loss account': [
            'opre',
            'turn',
            'oppl',
            'fipl',
            'fire',
            'fiex',
            'plbt',
            'taxa',
            'plat',
            'extr',
            'exre',
            'exex',
            'pl',
        ],
        'LINES ': ['expt', 'mate', 'staf', 'depr', 'oopi', 'inte', 'cf', 'av', 'ebta'],
    },
    globalRatio: {
        'Profitability ratios': [
            'rshf',
            'rcem',
            'rtas',
            'roe',
            'roce',
            'roa',
            'prma',
            'etma',
            'ebma',
            'cfop',
        ],
        'Operational ratios': ['nat', 'ic', 'stot', 'coll', 'crpe', 'exop', 'R&D'],
        'Structure ratios': [
            'curr',
            'liqr',
            'shlq',
            'solr',
            'soll',
            'gear',
            'ppe',
            'tpe',
            'sct',
            'ace',
            'sfpe',
            'wcpe',
            'tape',
        ],
    },
};
const formater = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 });
export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.accounting.tab';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        type: PropTypes.string,
    };

    state = {
        hover: false,
    };

    render() {

        return (
            <DeefindProvider
                type="accounting"
                id={this.props.entity.id}
                renderLoader={progress => {
                    return <Views.Common.Loader progress={progress} />;
                }}
                renderEntity={(data, state) => {
                    if (!data || !data.date || data.date.length === 0) {
                        return null;
                    }
                    const panels = fields[this.props.type];
                    const tables = {};

                    forEach(panels, (items, key) => {
                        const rows = [];
                        forEach(items, (item)=>{
                            const label = t(`dna.deefind.accounting.${item}`);
                            rows.push([label].concat(get(data,item)));
                        });

                        tables[key] = rows;
                    });

                    return this.renderTables(tables, data);
                }}
            />
        );
    }

    renderTables(tables, data){
        const content = [];
        const dates = data.date;
        forEach(tables, (table, label)=>{
            content.push(
                <div key={label}>
                    <table key={label} style={styles.table} cellPadding={0} cellSpacing={0}>
                        <thead>
                        <tr style={styles.header}>{[label, dates[0], dates[1], dates[2], dates[3], dates[4]].map((item, idx) => this.renderLabel(item,  'M-' + idx, idx))}</tr>
                        </thead>
                        <tbody>{table.map(this.renderRow.bind(this))}</tbody>
                    </table>
                </div>
            )
        });

        if (content.length){
            return content.reduce((prev, curr) => [prev, ' ', curr]);
        }

        return <div />;
    }

    renderLabel(label, key, index) {
        let cell = 'cell';
        if(index<1)
        {
            cell = 'headerCell';
        }
        if (typeof label === 'number'){
            label = formater.format(label)
        }
        return (
            <td style={styles[cell]} key={key}>
                {(label)?label:'-'}
            </td>
        );
    }

    renderRow(tr, index) {
        const cellProps = {
            onMouseEnter: event => this.setState({ hover: index }),
            onMouseLeave: event => this.setState({ hover: undefined }),
        };
        const style = this.state.hover === index ? Object.assign({}, styles.cell, styles.hover) : styles.cell;
        return (
            <tr key={index} style={style} {...cellProps}>
                {tr.map((item, idx) => this.renderLabel(item, index + '-' + idx, idx))}
            </tr>
        );
    }
}

/*
KEYS

opre  Operating revenue (Turnover)
plbt  P/L before tax
Pl    /L for period [=Net income]
cf    Cash flow
toas  Total assets
shfd  Shareholders funds
curr  Current ratio (x)
prma  Profit margin (%)
rshf  ROCE using P/L before tax (%)
rceme ROCE using P/L before tax (%)
solr  Solvency ratio (Asset based) (%)
empl  Number of employeess

****
GLOBAL Standard Format
Assets
fias  Fixed assets
ifas  Intangible fixed assets
tfas  Tangible fixed assets
ofas  Other fixed assets
cuas  Current assets
stok  Stock
debt  debtors
ocas  Other current assets
cash  Cash & cash equivalent
TOTAL

LIABILITIES & EQUITY
shfd Shareholders funds
capi Capital
null Other shareholders funds
ncli Non-current liabilities
ltdb Long term debt
oncl Other non-current liabilities
prov Provisions
culi Current liabilities
loan Loans
cred Creditors
ocli Other current liabilities
TOTAL

MEMO LINES
wkca Working capital
ncas Net current assets
null Enterprise value
empl Number of employees

Profit & loss account
opre Operating revenue (Turnover)
turn Sales
null Costs of goods sold
null Gross profit
null Other operating expenses
oppl Operating P/L [=EBIT]
fipl Financial P/L
fire Financial revenue
fiex Financial expenses
plbt P/L before tax
taxa Taxation
plat P/L after tax
extr Extr. and other P/L
exre Extr. and other revenue
exex Extr. and other expenses
pl   P/L for period [=Net income]

MEMO LINES
expt Export revenue
mate Material costs
staf Costs of employees
depr Depreciation & Amortization
oopi Other operating items
inte Interest paid
null Research & Development expenses
cf   Cash flow
av   Added value
ebta EBITDA

*****
Global ratios
Profitability ratios
rshf ROE using P/L before tax (%)
rcem ROCE using P/L before tax (%)
rtas ROA using P/L before tax (%)
roe ROE using Net income (%)
roce ROCE using Net income (%)
roa ROA using Net income (%)
prma Profit margin (%)
null Gross margin (%)
etma EBITDA margin (%)
ebma EBIT margin (%)
cfop Cash flow / Operating revenue (%)
null Enterprise value / EBITDA (x)
null Market cap / Cash flow from operations (x)

Operational ratios
nat Net assets turnover (x)
ic  Interest cover (x)
stot Stock turnover (x)
coll Collection period (days)
crpe Credit period (days)
exop Export revenue / Operating revenue (%)
R&D expenses / Operating revenue (%)

Structure ratios
curr Current ratio (x)
liqr Liquidity ratio (x)
shlq Shareholders liquidity ratio (x)
solr Solvency ratio (Asset based) (%)
soll Solvency ratio (Liability based) (%)
gear Gearing (%)
ppe  Profit per employee (th)
tpe  Operating revenue per employee (th)
sct  Costs of employees / Operating revenue (%)
ace  Average cost of employee (th)
sfpe Shareholders funds per employee (th)
wcpe Working capital per employee (th)
tape Total assets per employee (th)
* */
