import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Form from '../common/form';
import Comments from '../common/comments';
import Contacts from '../common/contacts';
import Nda from '../common/nda';
import Status from '../common/status';

import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

import get from 'lodash/get';
import { COUNTRIES } from '../../studies/common/survey/field/widget/scalar/country';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        marginBottom: '30px',
    },
    main: {
        width: 'auto',
        flexGrow: '1',
        display: 'flex',
    },

    mainAvatar: {},
    mainRating: {
        marginRight: '20px',
    },
    mainTitle: {
        margin: '0 20px',
    },
    mainAum: {
        lineHeight: '40px',
        margin: '0 20px',
        fontSize: '15px',
        color: colors.grey700,
    },

    statusDraft: {
        color: colors.grey500,
        lineHeight: '40px',
    },
    statusIcon: {
        margin: '8px',
        float: 'left',
    },
    statusApproved: {
        color: colors.userGreen,
        lineHeight: '40px',
    },
    statusRejected: {
        color: colors.userHot,
        lineHeight: '40px',
    },

    actions: {
        width: 'auto',
        flexGrow: '0',
        display: 'flex',
    },
    actionsSeparator: {
        margin: '8px 15px',
        width: '0',
        borderLeft: '1px solid ' + colors.grey400,
    },

    contactContainer: {
        margin: '20px',
        lineHeight: '24px',
        minWidth: '200px',
    },
    contactTitle: {
        color: colors.userCold,
        borderBottom: '1px solid ' + colors.grey300,
        marginBottom: '10px',
    },
    contactValue: {
        margin: '2px 0',
    },
    contactIcon: {
        height: '16px',
        width: '16px',
        margin: '4px 10px 4px 0',
        float: 'left',
        fill: colors.grey500,
    },
};

@inject(stores => ({
    store: stores.deeligenzStudies,
    contacts: stores.auth.contacts,
    global: stores.global,
    state: stores.state,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.header';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onAbort: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onComment: PropTypes.func.isRequired,
        onApply: PropTypes.func.isRequired,
        readOnly: PropTypes.bool
    };

    testUserRole(allowed: string[]) {
        const team = this.props.entity.teamUid;
        const session = this.props.global.session.get();

        if (session.level !== 'account') return false;
        let level = '';

        for (const account of session.user.accounts || []) {
            const membership = account.memberships[team];
            if (membership) level = membership.level;
        }

        return allowed.includes(level);
    }

    isSameApplier(transition) {
        return 1 === (this.props.entity.history || []).filter(e => e.transition === transition && e.ownerUid === this.props.state.id).length;
    }

    renderOwner(owner, payload) {
        return (
            <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                <Icon
                    type="base.attendee"
                    style={{ fill: colors.userCold, marginRight: '5px', width: '18px', height: '18px' }}
                />

                <span style={{ lineHeight: '18px' }}>{owner.firstName + ' ' + owner.lastName}</span>

                {payload.expectedAum ? (
                    <span
                        style={{
                            lineHeight: '18px',
                            marginLeft: '15px',
                            color: colors.grey500,
                        }}
                    >
                        Exp. AUM&nbsp;&nbsp;{payload.expectedAum}&nbsp;M€
                    </span>
                ) : null}
            </div>
        );
    }

    renderDetail(type, payload) {
        if (type === 'fund') {
            return (
                <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                    <Icon
                        type="deeligenz.company"
                        style={{ fill: colors.userCold, marginRight: '5px', width: '18px', height: '18px' }}
                    />

                    <span title={payload.manager} style={{ lineHeight: '18px' }}>
                        {payload.manager}
                    </span>
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                <img
                    style={{ height: '12px', width: '16px', float: 'left', margin: '3px 6px 0 0' }}
                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${(
                        payload.country || ''
                    ).toLowerCase()}.svg`}
                />
                {COUNTRIES[payload.country] || ''}
            </div>
        );
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.main}>
                    <div style={styles.mainTitle}>
                        <Views.Common.Header
                            light
                            icon={
                                <Icon
                                    type={
                                        get(this.props.entity, 'payload.target.type') === 'fund'
                                            ? 'deeligenz.fund'
                                            : 'deeligenz.company'
                                    }
                                />
                            }
                            title={
                                get(this.props.entity, 'payload.target.type') === 'fund'
                                    ? this.props.entity.payload.fund
                                    : this.props.entity.payload.manager
                            }
                            content={[
                                this.renderDetail(this.props.entity.payload.target.type, this.props.entity.payload),
                                this.renderOwner(
                                    this.props.contacts.users[this.props.entity.ownerUid] || {},
                                    this.props.entity.payload,
                                ),
                            ]}
                            aside={[this.renderStatus()]}
                        />
                    </div>

                    {/* { this.renderRating() }
                    { this.renderAum() } */}
                </div>

                <div style={styles.actions}>
                    <Comments
                        channel={'main'}
                        type="button"
                        title={
                            this.props.entity.payload.fund
                                ? this.props.entity.payload.fund + ' ' + this.props.entity.payload.manager
                                : this.props.entity.payload.manager
                        }
                        comments={(toJS(this.props.entity.comments) || []).filter(item => item.channel === 'main')}
                        transitions={toJS(this.props.entity.history || [])}
                        onComment={this.props.onComment}
                    />

                    <Contacts entity={this.props.entity} />
                    <div style={styles.actionsSeparator} />

                    <Nda
                        external={false}
                        entity={this.props.entity}
                        onComment={this.props.onComment}
                        // signed={ true }
                        // value={ this.props.entity.payload.nda }
                        // onChange={ value => this.props.onSetNda(value) }
                        // onRead={ this.props.onReadNda }
                        // onWrite={ this.props.onWriteNda }
                    />

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="deeligenz.library" />}
                        label="Docs"
                        onClick={this.props.onToggleLibrary}
                    />

                    {this.props.readOnly ? null :
                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="do.update" />}
                        label="Edit"
                        onClick={event => this.refs.edit.open()}
                    />}

                    {this.renderTerminate()}
                </div>

                <Form ref="edit" entity={this.props.entity} onSubmit={this.props.onUpdate} />
            </div>
        );
    }

    // renderRating () {
    //     const value = get(this.props, 'entity.payload.rating');
    //
    //     if (! isNumber(value)) {
    //         return null;
    //     }
    //
    //     return (
    //         <Rating
    //             value={value}
    //             style={styles.mainRating}
    //         />
    //     );
    // }
    //
    // renderAum () {
    //     const value = get(this.props, 'entity.payload.aum');
    //
    //     if (! isNumber(value)) {
    //         return value;
    //     }
    //
    //     return (
    //         <span style={ styles.mainAum }>
    //             AUM&nbsp;&nbsp;
    //
    //             <Views.Common.Number
    //                 value={ value }
    //                 scale="K"
    //                 type="currency"
    //             />
    //         </span>
    //     );
    // }

    renderStatus() {
        switch (this.props.entity.status) {
            case 'draft':
                return <span style={styles.statusDraft}>DRAFT</span>;

            case 'input':
            case 'ddq':
            case 'onSite':
            case 'report':
                return <Status entity={this.props.entity} />;

            case 'approved':
                return (
                    <span style={styles.statusApproved}>
                        <Icon type="deeligenz.approved" style={styles.statusIcon} color={colors.halfGreen} />
                        Waiting confirmation
                    </span>
                );

            case 'confirmed':
                return (this.props.entity.fields || []).filter(id => get(this.props.store.fields.get(id), 'answerPayload.flags.redFlag')).length ? (
                    <span style={Object.assign({}, styles.statusApproved, {color: colors.halfHot})}>
                        <Icon type="deeligenz.approved" style={styles.statusIcon} color={colors.halfHot} />
                        Confirmed with redFlag
                    </span>
                ) : (
                    <span style={styles.statusApproved}>
                        <Icon type="deeligenz.approved" style={styles.statusIcon} color={colors.userGreen} />
                        Confirmed
                    </span>
                );
            case 'ignoredRedFlag':
                return (
                    <span style={styles.statusApproved}>
                        <Icon type="deeligenz.approved" style={styles.statusIcon} color={colors.userGreen} />
                        Confirmed
                    </span>
                );

            case 'rejected':
                return (
                    <span style={styles.statusRejected}>
                        <Icon type="deeligenz.rejected" style={styles.statusIcon} color={colors.userHot} />
                        Rejected
                    </span>
                );

            case 'vetoed':
                return (
                    <span style={styles.statusRejected}>
                        <Icon type="deeligenz.rejected" style={styles.statusIcon} color={colors.userHot} />
                        Vetoed
                    </span>
                );
        }

        return null;
    }

    // renderContacts() {
    //     const payload = this.props.entity.payload;
    //
    //     const contacts = [
    //         {
    //             title: 'ODD contact',
    //             name: payload.oddContact.name,
    //             email: payload.oddContact.email,
    //             phone: payload.oddContact.phone,
    //         },
    //     ];
    //
    //     if (
    //         payload.salesContact &&
    //         (payload.salesContact.name || payload.salesContact.email || payload.salesContact.phone)
    //     ) {
    //         contacts.unshift({
    //             title: 'Sales contact',
    //             name: payload.salesContact.name,
    //             email: payload.salesContact.email,
    //             phone: payload.salesContact.phone,
    //         });
    //     }
    //
    //     if (
    //         payload.extraContact &&
    //         (payload.extraContact.name || payload.extraContact.email || payload.extraContact.phone)
    //     ) {
    //         contacts.unshift({
    //             title: 'Secondary contact',
    //             name: payload.extraContact.name,
    //             email: payload.extraContact.email,
    //             phone: payload.extraContact.phone,
    //         });
    //     }
    //
    //     return (
    //         <Views.Common.Button type="flat" color="cold" label={contacts.length} icon={<Icon type="base.attendee" />}>
    //             <div>
    //                 {contacts.map((contact, index) => (
    //                     <div style={styles.contactContainer} key={'c' + index}>
    //                         <div style={styles.contactTitle}>{contact.title}&nbsp;</div>
    //
    //                         <div style={styles.contactValue}>
    //                             <Icon type="base.attendee" style={styles.contactIcon} />
    //                             {contact.name}&nbsp;
    //                         </div>
    //
    //                         <div style={styles.contactValue}>
    //                             <Icon type="base.email" style={styles.contactIcon} />
    //                             {contact.email}&nbsp;
    //                         </div>
    //
    //                         <div style={styles.contactValue}>
    //                             <Icon type="base.phone" style={styles.contactIcon} />
    //                             {contact.phone}&nbsp;
    //                         </div>
    //                     </div>
    //                 ))}
    //             </div>
    //         </Views.Common.Button>
    //     );
    // }

    renderTerminate() {
        if (['rejected', 'vetoed'].includes(this.props.entity.status)) {
            return null;
        }

        if (this.props.entity.status === 'draft') {
            return (
                <Views.Common.Button
                    type="flat"
                    color="hot"
                    icon={<Icon type="do.delete" />}
                    label="Abort"
                    onClick={this.props.onAbort}
                />
            );
        }

        let next = null;
        let revoke = null;

        switch (this.props.entity.status) {
            case 'input':
                next = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmation"
                        message="Are you sure you want to approve input phase ?"
                        onClick={event => {
                            this.props.onApply('approveInput');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Approve input"
                            onClick={event => this.refs.confirmation.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;

            case 'ddq':
                next = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmation"
                        message="Are you sure you want to approve analysis phase ?"
                        onClick={event => {
                            this.props.onApply('approveDdq');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Approve Analysis"
                            onClick={event => this.refs.confirmation.open()}
                        />
                    </Views.Modal.Confirm>
                );

                revoke = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationRevoke"
                        message="Are you sure you want to reopen input phase ?"
                        onClick={event => {
                            this.props.onApply('revokeDdq');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Reopen Input"
                            onClick={event => this.refs.confirmationRevoke.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;

            case 'onSite':
                next = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmation"
                        message="Are you sure you want to approve meeting phase ?"
                        onClick={event => {
                            this.props.onApply('approveOnSite');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Approve meeting"
                            onClick={event => this.refs.confirmation.open()}
                        />
                    </Views.Modal.Confirm>
                );

                revoke = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationRevoke"
                        message="Are you sure you want to reopen analysis phase ?"
                        onClick={event => {
                            this.props.onApply('revokeOnSite');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Reopen Analysis"
                            onClick={event => this.refs.confirmationRevoke.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;

            case 'report':
                next = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        message="Are you sure you want to approve report phase ?"
                        ref="confirmation"
                        onClick={event => {
                            this.props.onApply('approveReport');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Approve report"
                            onClick={event => this.refs.confirmation.open()}
                        />
                    </Views.Modal.Confirm>
                );

                revoke = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationRevoke"
                        message="Are you sure you want to reopen meeting phase ?"

                        onClick={event => {
                            this.props.onApply('revokeReport');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Reopen meeting"
                            onClick={event => this.refs.confirmationRevoke.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;

            case 'approved':
                if (
                    !this.testUserRole(['manager', 'director']) ||
                    this.isSameApplier('approveReport') ||
                    this.props.state.id === this.props.entity.ownerUid
                ) {
                    break;
                }

                next = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        message="Are you sure you want to approve report phase ?"
                        ref="confirmation"
                        onClick={event => {
                            this.props.onApply('confirmReport');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Confirm"
                            onClick={event => this.refs.confirmation.open()}
                        />
                    </Views.Modal.Confirm>
                );

                revoke = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationRevoke"
                        message="Are you sure you want to reopen meeting phase ?"
                        onClick={event => {
                            this.props.onApply('revokeReport');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Reopen meeting"
                            onClick={event => this.refs.confirmationRevoke.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;

            case 'confirmed':
                if (!this.testUserRole(['director'])) {
                    break;
                }

                next = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmation"
                        message="Are you sure you want to ignore red flag(s) report phase ?"
                        onClick={event => {
                            this.props.onApply('ignoreRedFlag');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Confirm against red flag"
                            onClick={event => this.refs.confirmation.open()}
                        />
                    </Views.Modal.Confirm>
                );

                revoke = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationRevoke"
                        message="Are you sure you want to reopen report ?"
                        onClick={event => {
                            this.props.onApply('cancelConfirm');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Reopen report"
                            onClick={event => this.refs.confirmationRevoke.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;
            case 'ignoredRedFlag':
                if (!this.testUserRole(['director'])) {
                    break;
                }

                next = <div></div>;

                revoke = (
                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationRevoke"
                        message="Are you sure you want to reopen report ?"
                        onClick={event => {
                            this.props.onApply('cancelConfirm');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText="Reopen report"
                            onClick={event => this.refs.confirmationRevoke.open()}
                        />
                    </Views.Modal.Confirm>
                );
                break;
        }

        return (
            !next && !revoke ? null :
            <Views.Common.Button ref="apply" type="flat" color="cold" icon={<Icon type="do.open" />} label="Next">
                <Menu>
                    {next}
                    {revoke}

                    <Views.Modal.Confirm
                        comment={true}
                        title="Confirmation"
                        ref="confirmationReject"
                        onClick={comment => {
                            this.props.onApply('reject', comment);
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.no" color={colors.userHot} />}
                            primaryText="Reject"
                            onClick={event => this.refs.confirmationReject.open()}
                        />
                    </Views.Modal.Confirm>

                    <Views.Modal.Confirm
                        title="Confirmation"
                        ref="confirmationVeto"
                        onClick={event => {
                            this.props.onApply('veto');
                            this.refs.apply.closePopover();
                        }}
                    >
                        <MenuItem
                            leftIcon={<Icon type="base.no" color={colors.userHot} />}
                            primaryText="Veto"
                            onClick={event => this.refs.confirmationVeto.open()}
                        />
                    </Views.Modal.Confirm>
                </Menu>

            </Views.Common.Button>
        );
    }
}
