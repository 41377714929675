// @flow

import type { ProviderSearchQuery } from '@deecision/deefind-types/server';

import sortBy from 'lodash/sortBy';
import slugify from 'speakingurl';

export function encodeSearchKey(query: ProviderSearchQuery): ?string {
    if (query.identifier) {
        return `${query.type}:identifier:${query.identifier}`;
    }

    if (query.name) {
        return `${query.type}:name:${sortBy(slugify(query.name).split('-')).join('-')}`;
    }
}
