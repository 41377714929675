import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'block',
        width: '320px',
        margin: '40px auto'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.security.login.logo';

    render () {
        return (
            <svg style={ styles.container } width="320" height="46" xmlns="http://www.w3.org/2000/svg">
                <g fill={ colors.userHot }>
                    <path d="m81.336742,0.013848c0,1.5 0,3 0,4.45c0,0.3 -0.05,0.4 -0.4,0.4c-3.95,0 -7.9,0 -11.85,0c-1.4,0 -2.8,0 -4.2,0.05c-3.6,0.05 -6.8,1.25 -9.7,3.4c-2.7,2 -4.5,4.7 -5.5,7.9c-0.4,1.25 -0.8,2.55 -1.1,3.8c-0.1,0.45 -0.1,0.45 0.4,0.45c0.05,0 0.1,0 0.1,0c10.6,0 21.2,0 31.8,0c0.4,0 0.5,0.1 0.5,0.5c-0.05,1.35 0,2.65 0,4c0,0.3 -0.05,0.4 -0.4,0.4c-6.9,0 -13.8,0.05 -20.7,0.1c-3.8,0 -7.6,0.05 -11.4,0.05c-0.3,0 -0.35,0.05 -0.25,0.35c0.45,1.85 1,3.7 1.75,5.45c1.35,3.15 3.7,5.4 6.55,7.15c2.3,1.4 4.8,2.1 7.5,2.05c5.5,-0.05 11,-0.05 16.5,-0.1c0.4,0 0.5,0.1 0.5,0.5c-0.05,1.35 0,2.65 0,4c0,0.25 -0.05,0.35 -0.35,0.35c-5.95,0 -11.9,0.05 -17.85,0c-3.55,-0.05 -6.75,-1.25 -9.6,-3.35c-4.7,-3.45 -7.85,-7.9 -9.15,-13.65c-0.6,-2.6 -0.65,-5.25 -0.45,-7.9c0.25,-3.25 1.05,-6.4 2.65,-9.3c3.15,-5.65 7.75,-9.4 14.2,-10.65c0.7,-0.15 1.4,-0.2 2.15,-0.3c0.1,0 0.2,0.05 0.3,-0.1l18,0z"/>
                    <path d="m102.866734,0.013848c0.5,0.2 1.05,0.15 1.55,0.2c6.05,0.9 10.7,4 14.1,9c2.1,3.1 3.25,6.55 3.65,10.25c0.4,3.75 0.25,7.4 -1,11c-2.2,6.2 -6.2,10.75 -12.2,13.5c-2,0.95 -4.15,1.3 -6.4,1.3c-5.65,0 -11.35,0 -17,0c-0.4,0 -0.45,-0.1 -0.45,-0.45c0,-1.3 0.05,-2.6 0,-3.9c0,-0.4 0.1,-0.45 0.45,-0.45c5.3,0.05 10.6,0 15.9,0.1c4.1,0.1 7.5,-1.45 10.55,-4.05c2.55,-2.2 4,-5.05 4.9,-8.25c0.25,-0.9 0.5,-1.8 0.75,-2.8c-1.25,0 -2.45,0 -3.65,0c-9.2,-0.05 -18.35,-0.1 -27.55,-0.1c-0.35,0 -0.75,-0.05 -1.1,0c-0.25,0 -0.3,-0.1 -0.3,-0.35c0,-1.4 0,-2.75 0,-4.15c0,-0.35 0.1,-0.4 0.45,-0.4c7.4,0 14.8,0 22.2,0c3.2,0 6.45,0 9.65,0c0.3,0 0.4,-0.05 0.3,-0.4c-0.6,-2.2 -1.2,-4.45 -2.2,-6.5c-1.5,-3.05 -3.9,-5.2 -6.85,-6.8c-2.5,-1.35 -5.15,-1.9 -7.95,-1.9c-5.05,0 -10.1,-0.05 -15.15,-0.05c-0.35,0 -0.45,-0.1 -0.45,-0.45c0,-1.5 0,-2.95 0,-4.45l17.8,0.1z"/>
                </g>
                <g fill={ colors.userCold }>
                    <path d="m5.214691,23.006349c0,5.8 0,11.6 0,17.4c0,0.35 0.1,0.45 0.4,0.45c1.3,0 2.6,0.05 3.9,0.05c3.05,0 6.15,0 9.2,0.05c2.9,0.05 5.6,-0.8 8,-2.4c2.5,-1.7 4.65,-3.75 5.9,-6.55c2.15,-4.9 2.5,-9.95 0.95,-15.1c-1.05,-3.4 -2.8,-6.2 -5.7,-8.3c-2.9,-2.15 -6.1,-3.3 -9.7,-3.35c-1.25,0 -2.55,-0.05 -3.8,-0.05c-2.9,-0.05 -5.8,-0.05 -8.65,-0.1c-0.4,0 -0.5,0.15 -0.5,0.5c0,5.8 0,11.6 0,17.4m-4.9,-22.75c5.95,0 11.95,0 17.9,0c4.4,0 8.45,1.15 12,3.8c4.3,3.15 7.05,7.35 8.3,12.55c1.2,5 1.1,10 -0.65,14.85c-1.25,3.4 -3.25,6.3 -5.9,8.8c-2.2,2.05 -4.65,3.7 -7.5,4.6c-1.6,0.5 -3.25,0.75 -4.95,0.75c-6.4,0 -12.8,0 -19.15,0l0,-45.35l-0.05,0z"/>
                    <path d="m289.586033,6.154145l0,0.6c0,12.4 0,24.8 0,37.2c0,0.55 0,0.55 -0.55,0.55c-1.2,0 -2.35,0 -3.55,0c-0.35,0 -0.45,-0.1 -0.4,-0.4c0,-0.05 0,-0.15 0,-0.2c0,-14.2 0,-28.4 0,-42.55c0,-0.75 0,-0.6 0.65,-0.6c1.65,0 3.35,0 5,0c0.25,0 0.45,0.05 0.6,0.3c4.45,6.65 8.9,13.3 13.35,19.9c3.45,5.15 6.95,10.35 10.4,15.5c0.1,0.15 0.2,0.25 0.35,0.45c0,-0.25 0,-0.4 0,-0.55c0,-11.7 0,-23.4 0,-35.1c0,-0.55 0,-0.55 0.55,-0.55c1.15,0 2.35,0 3.5,0c0.35,0 0.45,0.05 0.45,0.45c-0.05,1 0,2.05 0,3.05c0,13.25 0,26.5 0,39.75c0,0.65 0.05,0.55 -0.55,0.55c-1.15,0 -2.35,0 -3.5,0c-0.3,0 -0.45,-0.1 -0.6,-0.35c-2.7,-4 -5.4,-8 -8.05,-12c-5.8,-8.45 -11.6,-17 -17.35,-25.55c-0.05,-0.1 -0.15,-0.25 -0.3,-0.45"/>
                    <path d="m189.934284,30.736444c0.7,0 1.35,0 2.05,0c0.3,0 0.4,0.05 0.45,0.35c0.2,2.55 1.15,4.8 2.85,6.7c1.55,1.7 3.5,2.8 5.8,3.2c3,0.55 5.95,0.3 8.75,-0.95c2.45,-1.1 4,-3 4.45,-5.65c0.4,-2.35 -0.05,-4.45 -2,-6.1c-0.85,-0.75 -1.85,-1.35 -2.85,-1.85c-2.35,-1.1 -4.85,-1.75 -7.3,-2.55c-2.35,-0.75 -4.7,-1.45 -6.9,-2.65c-2.2,-1.2 -4.1,-2.8 -5.3,-5.05c-0.9,-1.65 -1.15,-3.45 -1.15,-5.3c0.05,-4.65 2.75,-7.95 7,-9.55c2.85,-1.1 5.85,-1.35 8.9,-1.15c1.9,0.1 3.75,0.5 5.5,1.25c4,1.65 6.3,4.7 7.3,8.9c0.2,0.8 0.3,1.55 0.35,2.35c0,0.3 -0.05,0.35 -0.35,0.35c-1.4,0 -2.8,0 -4.2,0c-0.25,0 -0.3,-0.1 -0.3,-0.35c-0.25,-2.2 -1.05,-4.15 -2.6,-5.7c-1.45,-1.4 -3.25,-2.2 -5.2,-2.5c-2.7,-0.4 -5.35,-0.2 -7.85,0.95c-1.95,0.9 -3.3,2.3 -3.7,4.45c-0.45,2.65 0.45,4.75 2.6,6.35c1.5,1.1 3.2,1.8 4.95,2.45c2.95,1 6,1.85 8.95,2.95c1.9,0.7 3.65,1.6 5.25,2.9c2.6,2.15 3.85,4.9 3.8,8.25c-0.05,2.8 -0.7,5.35 -2.5,7.55c-1.6,2 -3.65,3.3 -6.1,4.05c-4.05,1.25 -8.2,1.5 -12.3,0.2c-4.75,-1.55 -8.1,-4.6 -9.9,-9.3c-0.5,-1.35 -0.75,-2.75 -0.8,-4.2c0,-0.3 0.05,-0.4 0.35,-0.35c0.6,0 1.3,0 2,0"/>
                    <path d="m167.708286,12.188798c-0.7,0 -1.4,0 -2.1,0c-0.2,0 -0.35,-0.05 -0.5,-0.25c-2.85,-4.05 -6.75,-6.55 -11.6,-7.4c-5.55,-1.05 -10.55,0.4 -14.85,4.05c-3.35,2.85 -5.5,6.45 -6.4,10.8c-1,5.2 0.15,9.9 3.25,14.15c2.7,3.7 6.25,6.2 10.7,7.25c5.25,1.25 10.05,0.15 14.4,-2.95c1.75,-1.25 3.25,-2.8 4.55,-4.5c0.2,-0.25 0.4,-0.35 0.7,-0.3c1.4,0 2.75,0 4.15,0c0.45,0 0.45,0.05 0.25,0.4c-2.75,4.8 -6.6,8.25 -11.75,10.35c-2.45,1 -4.95,1.5 -7.6,1.6c-9.6,0.3 -18.45,-5.4 -21.95,-14.25c-1.25,-3.2 -1.75,-6.5 -1.55,-9.9c0.35,-4.85 2,-9.15 5.05,-12.9c3.3,-4 7.45,-6.55 12.5,-7.65c7.65,-1.6 14.45,0.1 20.4,5.2c1.9,1.6 3.55,3.4 4.65,5.6c0.35,0.7 0.35,0.7 -0.45,0.7c-0.6,0 -1.25,0 -1.85,0"/>
                    <path d="m225.965092,22.365296c0,-7.1 0,-14.25 0,-21.35c0,-0.4 0.1,-0.55 0.5,-0.5c1.15,0.05 2.3,0 3.45,0c0.55,0 0.55,0 0.55,0.55c0,4.2 0,8.4 0,12.6c0,10.05 0,20.05 0,30.1c0,0.55 0,0.55 -0.55,0.6c-1.15,0 -2.3,0 -3.45,0c-0.4,0 -0.5,-0.1 -0.5,-0.5c0,-4.5 0,-8.95 0,-13.4l0,-8.1z"/>
                    <path d="m177.380436,22.689591c0,-7.1 0,-14.25 0,-21.35c0,-0.4 0.1,-0.55 0.5,-0.5c1.15,0.05 2.3,0 3.45,0c0.55,0 0.55,0 0.55,0.55c0,5.25 0,10.5 0,15.75c0,9 0,17.95 0,26.95c0,0.55 0,0.55 -0.55,0.55c-1.15,0 -2.3,0 -3.45,0c-0.4,0 -0.5,-0.1 -0.5,-0.5c0,-4.6 0,-9.15 0,-13.75l0,-7.7z"/>
                    <path d="m272.377934,33.517846c-2.7,3.7 -6.25,6.2 -10.7,7.25c-1.3,0.3 -2.55,0.5 -3.8,0.5c0,0 -0.05,0 -0.05,0c0,0 -0.05,0 -0.05,0c-1.25,-0.05 -2.5,-0.2 -3.8,-0.5c-4.45,-1.05 -8,-3.6 -10.7,-7.25c-3.1,-4.25 -4.3,-8.95 -3.25,-14.15c0.85,-4.35 3,-7.95 6.4,-10.8c2.3,-1.95 4.85,-3.3 7.55,-3.9c0.2,-0.05 0.4,-0.1 0.6,-0.15c1,-0.2 2,-0.3 3,-0.3c0.1,0 0.25,0 0.35,0c0.1,0 0.25,0 0.35,0c1,0.05 1.95,0.15 3,0.3c0.2,0.05 0.4,0.1 0.6,0.1c2.7,0.65 5.2,1.95 7.55,3.9c3.2,2.9 5.35,6.5 6.2,10.85c1.05,5.2 -0.15,9.9 -3.25,14.15m8.1,-12.3c-0.25,-3.4 -1.1,-6.5 -2.65,-9.4c-0.05,-0.1 -0.1,-0.2 -0.15,-0.35c-1.05,-2.05 -2.5,-3.8 -4.2,-5.3c-2.05,-1.95 -4.45,-3.45 -7.05,-4.5c-2.7,-1.1 -5.55,-1.65 -8.55,-1.6c-3,-0.05 -5.85,0.5 -8.55,1.6c-2.65,1 -5,2.5 -7.05,4.5c-1.7,1.5 -3.15,3.25 -4.2,5.3c-0.1,0.15 -0.15,0.25 -0.15,0.35c-1.55,2.85 -2.45,6 -2.65,9.4c-0.25,3.4 0.25,6.7 1.55,9.9c0.25,0.7 0.6,1.35 0.9,1.95c0,0.05 0,0.15 0.1,0.35c0.4,0.7 0.85,1.4 1.3,2.05c2.35,3.45 5.65,6.15 9.4,7.85c0.35,0.15 0.65,0.3 1,0.45c2.35,0.95 4.8,1.45 7.35,1.55c0,0 0,0 0.05,0c0.05,0 0.1,0 0.2,0c0.15,0 0.3,0 0.45,0c0.1,0 0.25,0 0.35,0c0.1,0 0.25,0 0.35,0c0.15,0 0.3,0 0.45,0c0.05,0 0.1,0 0.2,0c0,0 0,0 0.05,0c2.55,-0.1 5,-0.6 7.35,-1.55c0.35,-0.15 0.7,-0.3 1,-0.45c3.75,-1.7 7.05,-4.4 9.4,-7.85c0.45,-0.65 0.9,-1.35 1.3,-2.05c0.1,-0.2 0.15,-0.3 0.1,-0.35c0.35,-0.65 0.65,-1.3 0.9,-1.95c1.25,-3.2 1.75,-6.5 1.55,-9.9"/>
                </g>
            </svg>

        );
    }
};
