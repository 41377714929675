import { get } from 'lodash';
import formatAnswer from '../answer';

const mapping = {
    'FUND_ASSET_CLASS': 'assetClass',
    'FUND_GEO_AREA': 'geographicalArea',
    'FUND_STYLE': 'strategyStyle',
    'INVEST_UNIVERSE': 'investmentUniverse',
    'ESG_APPROACH': 'esgApproach',
    'FUND_COMPANY': 'investmentManager',
    'STRATEGY_INCEPTION': 'strategyCreation',
    'FUND_BASE_CURRENCY': 'baseCurrency',
    'STRATEGY_AUM': 'strategyAum',
    'FUND_BENCHMARK': 'officialBenchmark',
};

export default async function (data: Object, services: Object): Promise<Object> {
    const values = {};

    for (const field of data.fields || []) {
        const tag = get(field, 'questionPayload.report');

        if (tag && mapping[tag]) {
            values[mapping[tag]] = await formatAnswer(field);
        }
    }

    return values;
}
