// flow

import type { EntitySource, EntityEstimate, EntityAddress, VigilanceFile } from '@deecision/deefind-types/model';

import {
    cleanName,
    cleanShape,
    cleanIdentifier,
    cleanArray,
    cleanNumber,
    cleanPercentage,
    cleanDate,
    cleanString,
} from './core';

export function cleanAddress(value: any, source?: EntitySource): EntityAddress | undefined {
    if (!value || typeof value !== 'object') {
        return undefined;
    }

    return cleanShape(value, {
        name: cleanName,
        country: cleanIdentifier,
        province: cleanName,
        city: cleanName,
        zipCode: cleanIdentifier,
        details: [cleanArray, cleanName],
        phone: cleanName,
        email: cleanName,
        source: [cleanSource, source],
    });
}

export function cleanEstimate(value: any, fallback?: EntitySource): EntityEstimate | undefined {
    if (value && typeof value === 'object') {
        return {
            value: cleanNumber(value.value),
            deviation: cleanNumber(value.deviation),
            reliability: cleanPercentage(value.reliability),
        };
    }

    const number = cleanNumber(value);

    if (number) {
        return { value: number, deviation: 0, reliability: 100 };
    }

    return undefined;
}

export function cleanSource(value: any, fallback?: EntitySource): EntitySource | undefined {
    if (value && typeof value === 'string') {
        return value.match(/^[a-z]+:\/\//) ? { url: value } : { name: value };
    }

    if (!value || typeof value !== 'object') {
        return fallback;
    }

    return Object.assign({}, fallback || {}, {
        name: cleanName(value.name || null),
        url: cleanName(value.url || null),
        info: cleanDate(value.creation || null),
        update: cleanDate(value.update || null),
        details: cleanArray(value.details || [], [
            cleanShape,
            {
                name: cleanName(value.name || null),
                url: cleanName(value.url || null),
            },
        ]),
    });
}

export function cleanCompliance(value: any): VigilanceFile[] | undefined {
    return cleanArray(value, [
        cleanShape,
        {
            type: cleanIdentifier,
            title: cleanString,
        },
    ]);
}
