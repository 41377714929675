import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        cursor: 'pointer',
        width: '100%'
    },
    hover: {
        background: colors.grey200
    },
    selected: {
        background: colors.userCold,
        color: 'white',
    },
    avatar: {
        flexGrow: '0',
        padding: '5px',
        float: 'left',
        margin: '5px',
        height: '20px',
        borderRadius: '15px',
        background: colors.grey300
    },
    icon: {
        width: '20px',
        height: '20px',
        fill: 'white'
    },
    content: {
        flexGrow: '1',
        width: '100%',
        height: '30px',
        overflow: 'hidden',
        margin: '5px'
    },
    title: {
        fontSize: '15px',
        lineHeight: '15px'
    },
    subtitle: {
        fontSize: '11px',
        lineHeight: '11px',
        color: colors.grey500,
        marginTop: '4px'
    },
    text: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    alone: {
        lineHeight: '30px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.select.item';

    static propTypes = {
        title: PropTypes.node.isRequired,
        subtitle: PropTypes.node,
        icon: PropTypes.element,
        selected: PropTypes.bool,
        style: PropTypes.object,
        onSelect: PropTypes.func
    };

    state = {
        hover: false
    };

    render () {
        const properties = { style: Object.assign({},
            styles.container,
            this.props.selected ? styles.selected : this.state.hover ? styles.hover : {},
            this.props.style
        ) };

        if (this.props.onSelect) {
            Object.assign(properties, {
                onMouseOver: event => this.setState({ hover: true }),
                onMouseLeave: event => this.setState({ hover: false }),
                onClick: this.props.onSelect
            });
        }

        return (
            <div { ...properties }>
                { this.renderAvatar() }
                { this.renderContent() }
            </div>
        );
    }

    renderAvatar () {
        if (! this.props.icon)
            return null;

        return (
            <div style={ styles.avatar }>
                { React.cloneElement(this.props.icon, {
                    style: Object.assign({}, styles.icon, this.props.icon.props.style)
                }) }
            </div>
        );
    }

    renderContent () {
        if (! this.props.subtitle)
            return (
                <div style={ Object.assign({}, styles.title, styles.text, styles.alone, styles.content) }>
                    { this.props.title }
                </div>
            );

        return (
            <div style={ styles.content }>
                <div style={ Object.assign({}, styles.title, styles.text) }>
                    { this.props.title }
                </div>
                <div style={ Object.assign({}, styles.subtitle, styles.text) }>
                    { this.props.subtitle }
                </div>
            </div>
        );
    }
}
