// @flow

import type { ResolverSpecs } from 'app/stores/state/types';
import type { ScreenStage } from './types';

import React from 'react';
import Scope from 'app/layout/providers/scope';

const styles = {
    content: {
        margin: '20px auto',
        width: '1400px'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.screen.stage';

    scope: ResolverSpecs;

    props: {
        state: Object,
        fallback: string,
        stages: { [type: string]: ScreenStage },
        empty?: Function,
    };

    render () {
        if (! this.props.state || ! this.props.state.items || ! this.props.state.items.length) {
            return this.props.empty
                ? React.cloneElement(this.props.empty, { style: styles.content })
                : (<span></span>);
        }

        const Content = this.resolveContent();

        return (
            <Scope path=":current" type="router">
                <Content style={ styles.content } />
            </Scope>
        );
    }

    resolveContent () {
        const items = this.props.state.items || [];
        const cursor = this.props.state.cursor || 0;
        const type = items[cursor].type;


        if (! this.props.stages[type]) {
            throw new Error(
                `Found invalid stage type "${type}", ` +
                `valid ones are: ${Object.keys(this.props.stages).join(', ')}`
            );
        }

        return this.props.stages[type].content;
    }
}
