import React from 'react';
import PropTypes from 'prop-types';
import Base from './base';
import Table from './table';

const styles = {
    content: {
        padding: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.figures';

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.object,
    };

    render() {
        return (
            <Base title={this.props.title}>
                {this.renderContent()}
            </Base>
        );
    }

    renderContent() {
        return (
            <div style={styles.content}>
                <Table head={this.makeHead()} body={this.makeBody()} />
            </div>
        );
    }

    makeHead() {
        return ['', ...(this.props.data.dates || [])];
    }

    makeBody() {
        const body = [
            [`AUM (${this.props.data.currency})`, ...(this.props.data.aum || [])],
            ['Employees', ...(this.props.data.employees || [])],
        ];

        for (let index = 0; index < length; index++) {
            const row = data[index] || [];
            body.push([labels[index], row[0], row[1], row[2]]);
        }

        return body;
    }
}
