import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import t from 'app/translation';
import get from 'lodash/get';
import colors from 'app/theme/colors';

const styles = {
    container: {
        margin: '0 170px 0 0'
    },
    year: {
        color: colors.grey500,
        display: 'inline-block',
        width: '45px',
    },
    old: {
        color: colors.userWarning,
    },
    veryold: {
        color: colors.userHot,
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.card.properties';

    static propTypes = {
        entity: PropTypes.object
    };

    render () {
        let age = false;
        const year = get(this.props.entity, 'preview.publication', 0);
        if ((year || 0) <= new Date().getFullYear() - 3) {
            age = 'old';
        }
        if ((year || 0) <= new Date().getFullYear() - 4) {
            age = 'veryold';
        }
        return (
            <Views.Common.Properties
                style={ styles.container }
                align={ 70 }
                values={[
                    {
                        label: t('common.label.capital'),
                        value: (
                            <span>
                                <span style={Object.assign({}, styles.year, age ? styles[age] : {})}>{(year>0)?year:''}</span>
                                <Views.Common.Number
                                    type="currency"
                                    scale="M"
                                    value={ get(this.props.entity, 'preview.capital') }
                                />
                            </span>
                        )
                    }, {
                        label: t('common.label.turnover'),
                        value: (
                            <span>
                                <span style={Object.assign({}, styles.year, age ? styles[age] : {})}>{(year>0)?year:''}</span>
                                <Views.Common.Number
                                    type="currency"
                                    scale="M"
                                    value={ get(this.props.entity, 'preview.turnover') }
                                />
                            </span>
                        )
                    }, {
                        label: t('common.label.ebitda'),
                        value: (
                            <span>
                                <span style={Object.assign({}, styles.year, age ? styles[age] : {})}>{(year>0)?year:''}</span>
                                <Views.Common.Number
                                    type="currency"
                                    scale="M"
                                    value={ get(this.props.entity, 'preview.ebitda') }
                                />
                            </span>
                        )
                    }, {
                        label: t('common.label.valuation'),
                        value: (
                            <span>
                                <span style={Object.assign({}, styles.year, age ? styles[age] : {})}>{(year>0)?year:''}</span>
                                <Views.Common.Number
                                    type="currency"
                                    scale="M"
                                    value={ get(this.props.entity, 'preview.valuation') }
                                />
                            </span>
                        )
                    }
                ]}
            />
        );
    }
}
