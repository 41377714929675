import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';

const LEVELS = {
    i: 'INV',
    s: 'SHA',
    b: 'BOA',
    a: 'ADV',
    c: 'COU',
    e: 'EXE',
    m: 'MAN',
};

let styles = {
    container: {
        display: 'grid',
        margin: '30px 0',
        gridTemplateColumns: '32% 32% 32%',
        justifyContent: 'space-between',
    },
    item: {
        border: '1px solid ' + colors.userCold,
        background: colors.grey100,
        borderRadius: '3px',
        padding: '20px',
        height: '50px',
        display: 'flex',
    },
    left: {
        flexGrow: '1',
        lineHeight: '24px',
    },
    right: {
        flexGrow: '0',
        height: '50px',
        alignItems: 'center',
        display: 'flex',
    },
    header: {
        lineHeight: '24px',
        display: 'flex',
        marginBottom: '2px',
    },
    icon: {
        fill: colors.grey500,
    },
    level: {
        color: colors.userCold,
        fontWeight: 'bold',
        margin: '0 10px',
    },
    count: {
        color: colors.userHot,
    },
    direct: {
        color: colors.grey500,
        letterSpacing: '2px',
    },
    loader: {
        marginLeft: '10px',
    },
    throughLabel: {
        display: 'inline-block',
        color: colors.userCold,
        marginRight: '10px',
    },
    throughName: {
        color: colors.grey700,
        textDecoration: 'underline',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.clusters';

    static propTypes = {
        list: PropTypes.arrayOf(PropTypes.object),
    };

    render () {
        if (!this.props.list || !this.props.list.length) {
            return null;
        }

        return (
            <div style={ styles.container }>
                { this.props.list.map(this.renderItem.bind(this)) }
            </div>
        );
    }

    renderItem (item: Object, index: number) {
        return (
            <div
                key={ index }
                style={ styles.item }
            >
                <div style={styles.left}>
                    <div style={styles.header}>
                        <Icon type={ 'dna.company' } style={ styles.icon } />
                        <span style={ styles.level }>{ this.renderLabel(item.level) }</span>
                        <span style={ styles.count }>{ item.entities.length }&nbsp;&nbsp;Omitted</span>
                    </div>

                    { this.renderThrough(item.through)}
                </div>
                <div style={styles.right}>
                    <Views.Common.Button
                        type="round"
                        color="cold"
                        icon={<Icon type="do.open" />}
                        style={ styles.loader }
                        disabled
                        mini
                    />
                </div>
            </div>
        );
    }

    renderLabel (level: string) {
        return LEVELS[level] || level.toUpperCase();
    }

    renderThrough (through: ?Object) {
        if (!through) {
            return (<div style={styles.direct}>Direct</div>);
        }

        return (
            <div style={styles.through}>
                <span style={styles.throughLabel}>Via</span>
                {(through.flags || []).includes('holding') ? <span style={styles.throughHolding}>H</span> : null}
                <span style={styles.throughName}>{through.name}</span>
            </div>
        );
    }
}
