import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import Actions from './aside/actions';
import Flags from './aside/flags';
import History from './aside/history';
import Remarks from './remarks';
import Comments from '../../../common/comments';
import Answer from './answer';

const styles = {
    container: {
        padding: '20px 0',
        borderTop: '1px solid ' + colors.grey300,
        display: 'flex',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
//        zIndex: '999',
    },
    aside: {
        width: '25%',
    },

    question: {
        paddingLeft: '20px',
    },
    questionLabel: {
        color: colors.black,
        marginBottom: '10px',
    },
    questionFlag: {
        display: 'inline-block',
        margin: '0 5px',
        textTransform: 'upper-case',
        padding: '0 5px',
        lineHeight: '19px',
        height: '19px',
        borderRadius: '3px',
        background: colors.bgHot,
        color: colors.userHot,
        fontSize: '11px',
    },
    questionIndex: {
        display: 'inline-block',
        color: colors.grey500,
        width: '34px',
        textAlign: 'right',
        margin: '0 5px 0 -10px',
    },

    toolbar: {
        padding: '10px 0 20px 50px',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies, contacts: stores.auth.contacts }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.studies.common.survey.field';

    static propTypes = {
        id: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        onAnswer: PropTypes.func,
        onCorrection: PropTypes.func,
        onApply: PropTypes.func,
        onComment: PropTypes.func.isRequired,
        onRemark: PropTypes.func,
        onFlags: PropTypes.func,
        scope: PropTypes.oneOf(['input', 'ddq', 'onSite', 'report']),
        embedded: PropTypes.bool,
        external: PropTypes.bool,
        readOnly: PropTypes.bool
    };

    render() {
        const field = toJS(this.props.store.fields.get(this.props.id));

        if (!field) {
            return null;
        }

        let background = colors.white;

        if (this.props.scope === 'input') {
            switch (field.status) {
                case 'pending':
                    background = colors.bgCold;
                    break;
                case 'validated':
                    background = colors.bgGreen;
                    break;
                case 'rejected':
                    background = colors.bgHot;
                    break;
            }
        } else {
            const flags = (field.answerPayload || {}).flags || {};

            if (flags.redFlag) {
                background = colors.bgHot;
            } else if (flags.allRight) {
                background = colors.bgGreen;
            } else if (flags.concern) {
                background = colors.bgCold;
            }
        }

        return (
            <div style={Object.assign({}, styles.container, { background }, this.props.style || {})}>
                {this.renderBody(field)}
                {this.renderAside(field)}
            </div>
        );
    }

    renderBody(field) {
        const question = field.questionPayload || {};

        return (
            <div style={styles.body}>
                <div style={styles.question}>
                    <div style={styles.questionLabel}>
                        <span style={styles.questionIndex}>{this.props.index + '.'}</span>
                        {question.label}
                        {(question.flags || []).includes('key') ? <span style={styles.questionFlag}>KEY</span> : null}
                        {(question.flags || []).includes('gdpr') ? <span style={styles.questionFlag}>GDPR</span> : null}
                    </div>
                </div>

                {this.renderToolbar(field)}

                <Answer
                    field={field}
                    readOnly={!this.props.onAnswer && question.scope !== 'ddq'}
                    onAnswer={this.props.onAnswer}
                    onCorrection={this.props.withCorrection ? this.props.onCorrection : undefined}
                    onFlags={this.props.onFlags}
                    external={this.props.external}
                />
            </div>
        );
    }

    renderToolbar(field) {
        const question = field.questionPayload || {};

        return (
            <div style={styles.toolbar}>
                <Comments
                    type="link"
                    title={question.label}
                    comments={field.comments || []}
                    transitions={field.history || []}
                    onComment={this.props.onComment}
                />

                <Remarks
                    title={question.label}
                    answer={field.answerPayload || {}}
                    scope={this.props.scope}
                    onRemark={this.props.onRemark}
                />
            </div>
        );
    }

    renderAside(field) {
        return (
            <div style={styles.aside}>
                {this.renderActions(field.status)}
                {this.renderFlags(field.answerPayload || {})}
                <History scope={this.props.scope} history={field.history || []} />
            </div>
        );
    }

    renderActions(status) {
        if (!this.props.onApply) {
            return null;
        }

        return <Actions readOnly={this.props.readOnly} status={status} onApply={this.props.onApply} />;
    }

    renderFlags(answer) {
        if (!this.props.onFlags) {
            return null;
        }

        return <Flags readOnly={this.props.readOnly} answer={answer} scope={this.props.scope} onFlags={this.props.onFlags} />;
    }
}
