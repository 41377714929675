import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Entity from './entity';
import Group from './group';
import Handle from './handle';
import Node from './node';
import DeefindProvider from 'app/layout/providers/deefind';
import { formatStockValue } from '../../utils';

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    item: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
    },
    wire: {
        position: 'absolute',
        right: '-1px',
        top: '0',
        bottom: '0',
        width: '0',
        border: '1px solid ' + colors.grey400,
    },
    wire_top: {
        top: '50%',
    },
    wire_bottom: {
        bottom: '50%',
    },
    wire_middle: {},
    wire_none: {
        display: 'none',
    },
};

export default class Parents extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.stocks.parents';

    static propTypes = {
        stocks: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
        collapsed: PropTypes.bool,
        parentId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            expanded: !props.collapsed,
            loading: false,
            partial: !Array.isArray(props.stocks),
            stocks: props.stocks,
        };
    }

    render() {
        if (!this.state.stocks) {
            return null;
        }

        if (this.state.loading) {
            return (
                <DeefindProvider
                    type="stocks"
                    id={this.state.loading}
                    mini={true}
                    direction={'parents'}
                    renderEntity={(stocks, state) => {
                        this.setState({ stocks: stocks.parents, loading: false, partial: false });
                        return (
                            <div style={styles.container}>
                                {stocks.parents.map((stock, index) =>
                                    this.renderStock(stock, index, this.state.stocks.length),
                                )}
                                {this.renderHandle()}
                            </div>
                        );
                    }}
                />
            );
        }

        if (this.state.partial && this.state.stocks.total > 0) {
            return (
                <Node
                    title={`${this.state.stocks.total} entities`}
                    output={''}
                    onClick={event => {
                        this.setState({ loading: this.props.parentId });
                    }}
                />
            );
        }

        if (!this.state.stocks.length) {
            return null;
        }

        if (!this.state.expanded) {
            return (
                <Group
                    count={this.state.stocks.length}
                    output={''}
                    onClick={event => this.setState({ expanded: true })}
                    handleStyle={{ right: '-10px' }}
                />
            );
        }

        return (
            <div style={styles.container}>
                {this.state.stocks.map((stock, index) => this.renderStock(stock, index, this.state.stocks.length))}
                {this.renderHandle()}
            </div>
        );
    }

    renderStock(stock, index, count) {
        let position = 'middle';

        switch (true) {
            case count === 1:
                position = 'none';
                break;

            case index === 0:
                position = 'top';
                break;

            case index === count - 1:
                position = 'bottom';
                break;
        }

        return (
            <div style={styles.item} key={'P' + index}>
                <div style={Object.assign({}, styles.wire, styles['wire_' + position])} />

                <Parents
                    parentId={stock.target.id}
                    stocks={stock.parents || []}
                    collapsed={(stock.parents || []).length > 4}
                />

                <Entity
                    output={
                        stock.value ? formatStockValue(stock.value) : stock.beneficial ? 'BO' : stock.guess ? '?' : ''
                    }
                    input={stock.parents && (stock.parents.length || stock.parents.total) ? '' : undefined}
                    target={
                        stock.target && stock.target.name === '(?)'
                            ? { type: 'unknown', name: stock.label }
                            : stock.target
                    }
                    ubo={stock.value && stock.value.min > 50}
                />
            </div>
        );
    }

    renderHandle() {
        if (this.props.stocks.length < 2) {
            return null;
        }

        return (
            <Handle
                expanded={this.state.expanded}
                style={{ right: '-10px' }}
                onClick={event => this.setState({ expanded: !this.state.expanded })}
            />
        );
    }
}
