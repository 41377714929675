import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        flexGrow: '1',
        flexShrink: '1',
        border: '1px solid ' + colors.grey400,
        background: colors.grey200
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.cell';

    static propTypes = {
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                { this.props.children }
            </div>
        );
    }
}
