// @flow

import type { EntityType } from '@deecision/deefind-types/model';
import type { ProviderSearchQuery } from '@deecision/deefind-types/server';

import crypto from 'crypto';

export const ENTITY_TYPE_CODES = {
    invalid: 0,
    company: 1,
    person: 2,
};

export function encodeEntityType(type: EntityType): number {
    switch (type) {
        case 'company':
            return ENTITY_TYPE_CODES.company;
        case 'person':
            return ENTITY_TYPE_CODES.person;
        default:
            return ENTITY_TYPE_CODES.invalid;
    }
}

export function decodeEntityType(type: number): EntityType {
    switch (type) {
        case ENTITY_TYPE_CODES.company:
            return 'company';
        case ENTITY_TYPE_CODES.person:
            return 'person';
        default:
            return 'invalid';
    }
}

export function encodeEntityId(type: EntityType, provider: number, identifier: string): string {
    const sha1 = crypto
        .createHash('sha1')
        .update(identifier)
        .digest('hex');

    return [
        `${encodeEntityType(type)}${provider}${sha1.substr(0, 6)}`,
        sha1.substr(6, 4),
        sha1.substr(10, 4),
        sha1.substr(14, 4),
        sha1.substr(18, 12),
    ].join('-');
}

export function decodeEntityId(id: string): { type: EntityType, provider: number } {
    return {
        type: decodeEntityType(parseInt(id.substr(0, 1))),
        provider: parseInt(id.substr(1, 1)),
    };
}
