// @flow

import managementScope from './management/scope';
import prospectsScope from './prospects/scope';

export default {
    type: 'object',
    children: {
        management: managementScope,
        prospects: prospectsScope,
    }
};
