import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import colors from 'app/theme/colors';

const styles = {
    textarea: {
        display: 'inline-block',
        color: colors.grey500,
        fontSize: '13px',
        margin: '2px 0',
        height: '50px',
        resize: 'vertical',
        verticalAlign: 'middle',
        border: '1px solid ' + colors.grey400,
        borderRadius: '3px',
        padding: '0 10px',
        boxSizing: 'border-box',
        lineHeight: '48px',
        height: '50px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.onSite.textarea';

    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        styles: PropTypes.object,
        color: PropTypes.string,
    };

    state = {
        value: this.props.value,
    };

    render() {
        return (
            <div style={Object.assign({}, styles.textarea, this.props.styles)}>
                <textarea
                    style={{
                        color: this.props.color,
                        resize: 'none',
                        fontSize: '15px',
                        border: 'none',
                        height: '100%',
                        width: this.props.value !== this.state.value ? '82%' : '100%',
                        verticalAlign: 'middle',
                    }}
                    value={this.state.value}
                    onChange={e => this.setState({ value: e.target.value })}
                />
                {this.props.value !== this.state.value
                    ? [
                          <Views.Common.Button
                              onClick={e => this.props.onChange(this.state.value)}
                              mini={true}
                              color="cold"
                              type="raised"
                              icon={<Icon type="do.submit" />}
                              style={{ verticalAlign: 'middle' }}
                          />,
                          <Views.Common.Button
                              onClick={e => this.setState({ value: this.props.value })}
                              label="reset"
                              mini={true}
                              color="hot"
                              type="raised"
                              icon={<Icon type="do.cancel" />}
                              style={{ verticalAlign: 'middle' }}
                          />,
                      ]
                    : null}
            </div>
        );
    }
}
