import PropTypes from 'prop-types';
import React from 'react';
import BoolChoice from './bools/choice';
import Choices from './common/choices';
import union from 'lodash/union';
import difference from 'lodash/difference';

const styles = {
    container: {
        padding: '5px',
        display: 'flex',
    },
    vertical: {
        flexDirection: 'column',
    },
    horizontal: {
        flexDirection: 'row',
        height: '40px',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.bools';

    static propTypes = {
        value: PropTypes.any,
        choices: Choices.propType,
        vertical: PropTypes.bool,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    render () {
        const value = Array.isArray(this.props.value) ? this.props.value : [];

        return (
            <div style={ Object.assign({}, this.props.style, styles.container, this.props.vertical ? styles.vertical : styles.horizontal) }>
                { this.renderChoices(value) }
            </div>
        );
    }

    renderChoices (selection) {
        return Choices(this.props.choices).map(choice => {
            const checked = selection.includes(choice.value);

            return (
                <BoolChoice
                    key={ choice.key }
                    style={ choice.style }
                    icon={ choice.icon }
                    label={ choice.title }
                    checked={ checked }
                    onCheck={ event => {
                        if (checked) {
                            this.props.onChange(difference(selection, [choice.value]));
                        } else {
                            this.props.onChange(union(selection, [choice.value]));
                        }
                    } }
                    onFocus={ this.props.onFocus }
                />
            );
        });
    }
}
