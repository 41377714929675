import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'app/theme/colors';
import Modal from '../../modal/feature';
import Button from 'app/views/common/button';
import Thread from '../thread';

import TitleIcon from 'material-ui/svg-icons/action/question-answer';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

import t from 'app/translation';

const styles = {
    loading: {
        padding: '20px',
        textAlign: 'center',
        fontSize: '21px',
        color: Colors.grey500,
        height: '100%',
        lineHeight: '100%'
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.button.modal';

    static propTypes = {
        entities: PropTypes.array,
        preview: PropTypes.element.isRequired,
        target: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired,
    };

    open () {
        this.refs.modal.open();
    }

    render () {
        return (
            <Modal
                ref="modal"
                name="Discussion"
                icon={ <TitleIcon /> }
                aside={[ this.props.preview ]}
                control={[
                    <Button
                        type="round"
                        color="hot"
                        icon={ <CloseIcon /> }
                        onClick={ event => this.refs.modal.close() }
                    />
                ]}
            >
                { this.renderContent() }
            </Modal>
        );
    }

    renderContent () {
        if (!this.props.entities)
            return (
                <div style={ styles.loading }>
                    {t('common.message.loading')}
                </div>
            );

        return (
            <Thread
                entities={ this.props.entities }
                target={ this.props.target }
                handleSubmit={ this.props.handleSubmit }
                handleDelete={ this.props.handleDelete }
            />
        );
    }
}
