import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import colors from 'app/theme/colors';

const styles = {
    input: {
        display: 'inline-block',
        fontSize: '17px',
        border: '1px solid ' + colors.grey400,
        borderRadius: '3px',
        padding: '0 10px',
        boxSizing: 'border-box',
        lineHeight: '38px',
        height: '52px',
        background: 'white',
        verticalAlign: 'middle',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.onSite.input';

    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        styles: PropTypes.object,
        color: PropTypes.string,
    };

    state = {
        value: this.props.value,
    };

    render() {
        return (
            <div style={{ display: 'inline-block' }}>
                <div style={Object.assign({}, styles.input, this.props.styles)}>
                    <input
                        type="text"
                        style={{
                            color: this.props.color,
                            fontSize: '15px',
                            border: 'none',
                            height: '100%',
                            width: this.props.value !== this.state.value ? '79%' : '100%',
                            verticalAlign: 'middle',
                        }}
                        value={this.state.value}
                        onChange={e => this.setState({ value: e.target.value })}
                    />
                </div>
                {this.props.value !== this.state.value ? (
                    <div style={{ marginTop: '15px' }}>
                        <Views.Common.Button
                            onClick={e => this.props.onChange(this.state.value)}
                            label="submit"
                            mini={true}
                            color="cold"
                            type="raised"
                            icon={<Icon type="do.submit" />}
                            style={{ verticalAlign: 'middle' }}
                        />
                        ,
                        <Views.Common.Button
                            onClick={e => this.setState({ value: this.props.value })}
                            label="reset"
                            mini={true}
                            color="hot"
                            type="raised"
                            icon={<Icon type="do.cancel" />}
                            style={{ verticalAlign: 'middle' }}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}
