import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Header from './field/header';
import Body from './field/body';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        flexShrink: '1',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch',
        border: '1px solid ' + colors.grey400,
        background: 'transparent',
    },
    focus: {
        background: colors.bgCold,
        boxShadow: `0 0 3px ${colors.userCold} inset`,
        zIndex: '10',
    },
    error: {
        background: colors.bgHot,
        boxShadow: `0 0 3px ${colors.userHot} inset`,
        zIndex: '10',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.form.field';

    static contextTypes = {
        form: PropTypes.object.isRequired,
    };

    static propTypes = {
        path: PropTypes.string.isRequired,
        label: PropTypes.node,
        widget: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.element]).isRequired,
        required: PropTypes.bool,
        info: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        onOutput: PropTypes.func,
        readOnly: PropTypes.bool,
    };

    constructor(props: any, context: any) {
        super(props, context);
        const value = context.form.getValue(this.props.path);
        const blank = value === null || value === undefined || value === '';
        context.form.setError(this.props.path, this.props.required && blank ? 'Required' : undefined);
    }

    render() {
        const context = this.context.form;
        const focus = context.hasFocus(this.props.path);
        const error = context.getError(this.props.path);

        let value = context.getValue(this.props.path);

        if (this.props.onInput) {
            value = this.props.onInput(value);
        }

        const style = Object.assign(
            {},
            styles.container,
            focus ? (error ? styles.error : styles.focus) : {},
            this.props.style,
        );

        return (
            <label style={style}>
                <Header label={this.props.label} info={this.props.info} focus={focus} error={error} />

                <Body
                    readOnly={this.props.readOnly}
                    widget={this.props.widget}
                    focus={focus}
                    value={value}
                    onFocus={event => context.setFocus(this.props.path)}
                    onBlur={event => context.setFocus(undefined)}
                    onError={label => context.setError(this.props.path, label)}
                    onAsync={this.props.onAsync}
                    onChange={value => {
                        if (this.props.onOutput) {
                            value = this.props.onOutput(value);
                        }

                        const blank = value === null || value === undefined || value === '';
                        const error = context.getError(this.props.path);

                        if (this.props.required && blank) {
                            context.setError(this.props.path, 'Required');
                        } else if (error === 'Required') {
                            context.setError(this.props.path, null);
                        }

                        context.setValue(this.props.path, value);

                        if (this.props.onChange) {
                            this.props.onChange(value);
                        }
                    }}
                />
            </label>
        );
    }
}
