// @flow

import type StateStore from 'app/stores/state';
import type { GlobalStore } from '../core/types';
import type { ObservableMap } from 'mobx';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';

import { observable, runInAction, toJS, action } from 'mobx';
import { without } from 'lodash';
import cleerance from '@deecision/cleerance-utils';

const PATH = 'comeet.prospects';

export default class ComeetProspectsStore {
    global: GlobalStore;
    state: StateStore;
    team: ?string;
    prospects: ObservableMap<string, Object>;
    fields: ObservableMap<string, Object>;
    fetched: any;

    constructor(global: GlobalStore, state: StateStore) {
        this.global = global;
        this.state = state;
        this.team = null;
        this.prospects = observable.map();
        this.fields = observable.map();
        this.fetched = [];
    }

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        this.team = cursor.team;

        if (!cleerance.resolveFlags(session, cursor).includes('comeet.access')) {
            return;
        }

        this.listProspects();
    }

    // workspace management

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };

    @action openProspect = (id: string): void => {
        const prospect = this.prospects.get(id);

        if (!prospect) {
            console.error('invalid prospect id', id);
        }
        const menu = this.state.enter(PATH, 'menu');
        const data = menu.read() || {};

        let cursor = -1;

        (data.items || []).forEach((item, index) => {
            if (cursor < 0 && item.type === 'display' && item.id === id) {
                cursor = index;
            }
        });

        if (cursor < 0) {
            menu.assign(':next', { type: 'display', id }).select(':last');
        } else {
            menu.select(cursor);
        }
    };

    // prospects management

    @action listProspects = async (): Promise<void> => {
        const prospects = await this.global.execute('comeet', 'prospectings.list');

        runInAction(() => {
            prospects.forEach(prospect => this.prospects.set(prospect.id, prospect));
        });

        for (const prospect of prospects) {
            this.fetchProspect(prospect.id);
        }
    };

    @action fetchProspect = async (id: string): Promise<any> => {
        const prospect = await this.global.execute('comeet', 'prospectings.get', { id });

        runInAction(() => {
            const events = [];

            for (const event of prospect.events) {
                this.fields.set(event.id, event);
                events.push(event.id);
            }

            delete prospect.events;
            prospect.events = events;

            const milestones = [];

            for (const milestone of prospect.milestones) {
                this.fields.set(milestone.id, milestone);
                milestones.push(milestone.id);
            }

            delete prospect.milestones;
            prospect.milestones = milestones;

            this.prospects.set(prospect.id, prospect);
            this.fetched.push(prospect.id);
        });

        return this.prospects.get(prospect.id);
    };

    @action createProspect = async (data: Object): Promise<any> => {
        const prospect = await this.global.execute('comeet', 'prospectings.create', {
            team: this.team,
            payload: data.payload,
        });

        runInAction(() => {
            this.prospects.set(prospect.id, prospect);
        });

        return prospect;
    };

    @action updateProspect = async (id: string, data: Object): Promise<any> => {
        const prospect = await this.global.execute('comeet', 'prospectings.update', { id, payload: data.payload });

        runInAction(() => {
            delete prospect.events;
            delete prospect.milestones;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));
        });

        return this.prospects.get(prospect.id);
    };

    // @todo refacto by applyProspect
    @action startProspect = async (id: string): Promise<any> => {
        const prospect = await this.global.execute('comeet', 'prospectings.apply', { id, transition: 'start' });

        runInAction(() => {
            delete prospect.events;
            delete prospect.milestones;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));
        });

        return this.prospects.get(prospect.id);
    };

    // @todo rename to applyProspect
    @action endProspect = async (id: string, status: string): Promise<any> => {
        const prospect = await this.global.execute('comeet', 'prospectings.apply', { id, transition: status });

        runInAction(() => {
            delete prospect.events;
            delete prospect.milestones;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));
        });

        return this.prospects.get(prospect.id);
    };

    @action commentProspect = async (prospectId: string, data: Object, parentId: ?string): Promise<any> => {
        const prospect = await this.global.execute('comeet', 'prospectings.comment', {
            id: prospectId,
            payload: data,
            parentId,
        });

        runInAction(() => {
            delete prospect.events;
            delete prospect.milestones;

            this.prospects.set(prospect.id, Object.assign(toJS(this.prospects.get(prospect.id)) || {}, prospect));
        });

        return prospect;
    };

    @action removeComment = async (prospectId: string, commentId: string): Promise<void> => {
        await this.global.execute('comeet', 'comments.remove', { id: commentId });
        await this.fetchProspect(prospectId);
    };

    // events management

    @action createEvent = async (prospectId: string, data: Object): Promise<Object> => {
        const event = await this.global.execute('comeet', 'events.create', { id: prospectId, payload: data.payload });

        runInAction(() => {
            this.fields.set(event.id, event);
            const prospect = this.prospects.get(prospectId);

            if (prospect) {
                if (!prospect.events) {
                    prospect.events = [];
                }

                prospect.events.push(event.id);
            }
        });

        return event;
    };

    @action updateEvent = async (id: string, data: Object): Promise<Object> => {
        const event = await this.global.execute('comeet', 'events.update', { id, payload: data.payload });

        runInAction(() => {
            this.fields.set(event.id, event);
        });

        return event;
    };

    @action removeEvent = async (prospectId: string, id: string): Promise<void> => {
        const event = await this.global.execute('comeet', 'events.remove', { id });

        runInAction(() => {
            const prospect = this.prospects.get(prospectId);

            if (prospect) {
                prospect.events = without(prospect.events, id);
            }

            this.fields.delete(id);
        });

        return event;
    };

    @action commentEvent = async (eventId: string, data: Object, parentId: ?string): Promise<Object> => {
        const event = await this.global.execute('comeet', 'events.comment', { id: eventId, payload: data, parentId });

        runInAction(() => {
            this.fields.set(event.id, Object.assign(toJS(this.fields.get(event.id)) || {}, event));
        });

        return event;
    };

    // milestones management

    @action createMilestone = async (prospectId: string, data: Object): Promise<Object> => {
        const milestone = await this.global.execute('comeet', 'milestones.create', {
            id: prospectId,
            payload: data.payload,
        });

        runInAction(() => {
            this.fields.set(milestone.id, milestone);
            const prospect = this.prospects.get(prospectId);

            if (prospect) {
                if (!prospect.milestones) {
                    prospect.milestones = [];
                }

                prospect.milestones.push(milestone.id);
            }
        });

        return milestone;
    };

    @action updateMilestone = async (id: string, data: Object): Promise<Object> => {
        const milestone = await this.global.execute('comeet', 'milestones.update', { id, payload: data.payload });

        runInAction(() => {
            this.fields.set(milestone.id, milestone);
        });

        return milestone;
    };

    @action removeMilestone = async (prospectId: string, id: string): Promise<void> => {
        const milestone = await this.global.execute('comeet', 'milestones.remove', { id });

        runInAction(() => {
            const prospect = this.prospects.get(prospectId);

            if (prospect) {
                prospect.milestones = without(prospect.milestones, id);
            }

            this.fields.delete(id);
        });

        return milestone;
    };

    @action commentMilestone = async (milestoneId: string, data: Object, parentId: ?string): Promise<Object> => {
        const milestone = await this.global.execute('comeet', 'milestones.comment', {
            id: milestoneId,
            payload: data,
            parentId,
        });

        runInAction(() => {
            this.fields.set(milestone.id, Object.assign(toJS(this.fields.get(milestone.id)) || {}, milestone));
        });

        return milestone;
    };

    @action checkMilestone = async (milestoneId: string, list: []): Promise<Object> => {
        const milestone = await this.global.execute('comeet', 'milestones.check', { id: milestoneId, list });

        runInAction(() => {
            const field = this.fields.get(milestone.id);

            if (field) {
                field.payload.checklist = list;
            }
        });

        return milestone;
    };
}
