import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import Slider from 'material-ui/Slider';
import colors from 'app/theme/colors';

const styles = {
    container: {
    },
    item: {
        display: 'table-cell',
        padding: '5px 5px',
        width: '100%',
        cursor: 'pointer',
        minHeight: '20px',
    },
    hoverItem: {
        background: colors.grey200
    },
    label: {
        width: 'calc(100% - 50px)',
        fontSize: '15px',
        lineHeight: '20px',
        minHeight: '20px',
        color: colors.grey800
    },
    checkedLabel: {
        color: colors.grey500,
        textDecoration: 'line-through',
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: '10px',
        fill: colors.userCold,
    },
    percentage: {
        display: 'table-cell',
        verticalAlign: 'top',
        textAlign: 'right',
        minWidth: '60px',
        fontSize: '15px',
        lineHeight: '20px',
        padding: '5px 5px',
        cursor: 'pointer',
        minHeight: '20px',
        color: colors.grey800
    },
    percentageHover: {
        background: colors.grey200
    },
    slider: {
        display:' none',
        width: '275px',
        backgroundColor: 'white',
        margin:'10px 5px 5px 5px',
        padding: '8px',
        height: '10px',
        zIndex: '1',
    },
    sliderDisplayed: {
        display: 'block',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.checklist';

    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            checked: PropTypes.bool,
            percentage: PropTypes.number
        })).isRequired,
        onChange: PropTypes.func,
        style: PropTypes.object
    };

    state = {
        hover: null,
        hoverPercentage: null,
        slider: false,
        percentage: 0,
    };

    check = (checked, item) => this.props.onChange(_.map(
        this.props.items,
        input => input.id === item.id ? Object.assign({}, input, { checked}) : input
    ));

    handleCheck = (item) => {
        if (item.percentage === 100) {
            this.setState({ slider: item.id });
            this.handleSlider(95, item);
        } else {
            this.setState({ slider: null });
            this.handleSlider(100, item);
        }
    };

    handleSlider = (value, item) => {
        item.percentage = value;
        this.setState({ percentage: value });
        if (value === 100)
            this.check(true, item);
        else
            this.check(false, item);
    };

    render () {
        return (
            <div
                style={ Object.assign({}, styles.container, this.props.style) }
                onMouseLeave={ event => this.setState({ hover: null, hoverPercentage: null }) }
            >
                { this.props.items.map(this.renderItem.bind(this)) }
            </div>
        );
    }

    renderItem (item) {
        item.percentage = item.percentage === undefined ? 0 : item.percentage;

        const itemStyle = Object.assign({},
            styles.item,
            this.state.hover === item.id ? styles.hoverItem : {},
        );

        const arrow = this.state.slider === item.id ? '▴' : '▾';

        const percentageStyle = Object.assign({},
            styles.percentage,
            this.state.hoverPercentage === item.id ? styles.percentageHover : {}
        );

        const labelStyle = Object.assign({},
            styles.label,
            item.checked ? styles.checkedLabel : {}
        );

        const sliderStyle = Object.assign({},
            styles.slider,
            this.state.slider === item.id ? styles.sliderDisplayed : {}
        );

        return (
            <div>
                <Checkbox
                    key={ item.id }
                    label={ item.label }
                    style={ itemStyle }
                    labelStyle={ labelStyle }
                    iconStyle={ styles.icon }
                    checked={ !! item.checked }
                    onCheck={ event => this.handleCheck(item) }
                    onMouseEnter={ event => this.setState({ hover: item.id, hoverPercentage: null}) }
                />
                <div
                    style={ percentageStyle }
                    onMouseEnter={ event => this.setState({ hoverPercentage: item.id, hover: null }) }
                    onClick={ event => this.setState({ slider: this.state.slider === item.id ? null : item.id }) }
                >
                    { item.percentage + '% ' + arrow }
                </div>
                <Slider
                    sliderStyle={ sliderStyle }
                    min={ 0 } max={ 100 }
                    defaultValue={ item.percentage }
                    value={ item.percentage }
                    onChange={ (event, value) => (this.state.slider === item.id ? (this.handleSlider(value, item)) : undefined) }
                    onMouseEnter={ event => this.setState({hover: null, hoverPercentage: item.id }) }
                    step={ 5 }
                />
            </div>
        );
    }
}
