import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const WIDTHS = [ 200, 350, 500 ];

const styles = {
    empty: {
        color: colors.grey500,
        textAlign: 'center',
        padding: '20px',
        background: colors.grey100,
    },
    container: {
        margin: '20px 0 0 0',
    },
    title: {
        color: colors.userCold,
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '20px',
        background: colors.grey100,
    },
    row: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey300,
        justifyContent: 'stretch',
        alignContent: 'flex-start',
    },
    cell: {
        padding: '20px',
        lineHeight: '20px',
        verticalAlign: 'top',
        flexGrow: '1',
    },
    heading: {
        color: colors.userCold,
        fontWeight: 'bold',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.compliance.report.block';

    static propTypes = {
        title: PropTypes.string.isRequired,
        empty: PropTypes.string.isRequired,
        rows: PropTypes.array.isRequired,
    };

    render () {
        if (! this.props.rows.length) {
            return (
                <div style={ styles.empty }>
                    { this.props.empty }
                </div>
            );
        }

        const rows = this.props.rows || [];

        return (
            <div style={ styles.container }>
                <div style={ styles.title }>
                    { this.props.title }
                </div>

                {
                    rows.map((row, index) => (
                        <div
                            key={ `r${index}` }
                            style={ styles.row }
                        >
                            {
                                row.map((cell, index) => {
                                    const style = Object.assign({},
                                        styles.cell,
                                        index === 0 ? styles.heading : {},
                                        { width: WIDTHS[index] + 'px' }
                                    );

                                    return (
                                        <div key={ `c${index}` } style={ style }>
                                            { cell }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}
