import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {},
    item: {
        display: 'block',
        padding: '5px 5px',
        width: 'auto',
        cursor: 'pointer',
        borderRadius: '3px',
    },
    hover: {
        background: colors.grey200,
    },
    active: {
        background: colors.userCold,
        color: 'white',
    },
    index: {
        display: 'inline-block',
        width: '30px',
        opacity: '0.5',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.meetings.summary';

    static propTypes = {
        steps: PropTypes.arrayOf(PropTypes.object).isRequired,
        active: PropTypes.number,
        onSelect: PropTypes.func.isRequired,
    };

    state = { hover: null };

    render() {
        return (
            <div style={styles.container}>
                {this.props.steps.map((step: Object, index: number) => this.renderStep(step, index))}
            </div>
        );
    }

    renderStep(step: Object, index: number) {
        return (
            <div
                key={step.id}
                onMouseEnter={() => this.setState({ hover: step.id })}
                onMouseLeave={() => this.setState({ hover: null })}
                onClick={() => this.props.onSelect(index)}
                style={Object.assign(
                    {},
                    styles.item,
                    this.props.active === index ? styles.active : this.state.hover === step.id ? styles.hover : {},
                )}
            >
                <span style={styles.index}>{index + 1}.</span>
                {step.title}
            </div>
        );
    }
}
