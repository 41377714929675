import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';

const styles = {
    modal: {
        width: '742px',
    },
    body: {
        width: '700px',
    },
    textarea: {
        padding: '10px',
        fontSize: '15px',
        width: '700px',
        height: '150px',
        border: '1px solid ' + colors.grey500,
        borderRadius: '5px',
    },
    action: {
        marginTop: '20px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.survey.field.remarks.modal';

    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func,
    };

    state = { value: this.props.value };

    open() {
        this.refs.modal.open();
    }

    render() {
        return (
            <Views.Modal.Show
                ref="modal"
                icon={<Icon type="deeligenz.survey.concern" />}
                title={this.props.label}
                style={styles.modal}
            >
                <div style={styles.body}>
                    <textarea
                        style={styles.textarea}
                        value={this.state.value}
                        onChange={event => this.setState({ value: event.target.value })}
                        readOnly={!this.props.onChange}
                    />

                    {this.renderActions()}
                </div>
            </Views.Modal.Show>
        );
    }

    renderActions() {
        if (!this.props.onChange) {
            return null;
        }

        const onClick = () => {
              this.props.onChange(this.state.value);
              this.refs.modal.close();
          };

        return (
            <div style={styles.action}>
                <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    label="Submit remark"
                    onClick={onClick}
                />
            </div>
        );
    }
}
