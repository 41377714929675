// @flow

import React from 'react';
import PropTypes from 'prop-types';
import t from 'app/translation';
import colors from 'app/theme/colors';
import Create from './create';

const styles = {
    container: {
        display: 'flex',
        marginBottom: '10px',
        alignItems: 'flex-start',
    },
    blank: {
        flexGrow: '1',
    },
    filter: {
        display: 'flex',
        lineHeight: '40px',
        padding: '0 15px',
        borderRadius: '3px',
        background: colors.grey200,
        marginLeft: '1px',
        cursor: 'pointer',
        color: colors.userCold,
    },
    filterActive: {
        background: colors.userCold,
        color: 'white',
    },
    filterHover: {
        background: colors.grey300,
    },
    label: {
    },
    count: {
        color: colors.grey500,
        marginLeft: '10px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.members.header';

    static propTypes = {
        team: PropTypes.string.isRequired,
        counts: PropTypes.object.isRequired,
        level: PropTypes.string,
        onLevel: PropTypes.func.isRequired,
    };

    state = { hover: null };

    render() {
        return (
            <div style={styles.container}>
                {this.renderFilter('director')}
                {this.renderFilter('manager')}
                {this.renderFilter('member')}
                {this.renderFilter('observer')}

                <div style={styles.blank} />
                <Create team={this.props.team} />
            </div>
        );
    }

    renderFilter(level: string) {
        return (
            <div
                style={Object.assign(
                    {},
                    styles.filter,
                    level === this.props.level
                        ? styles.filterActive
                        : level === this.state.hover
                        ? styles.filterHover
                        : {},
                )}
                onMouseEnter={() => this.setState({ hover: level })}
                onMouseLeave={() => this.setState({ hover: null })}
                onClick={() => this.props.onLevel(this.props.level === level ? null : level)}
            >
                <span style={styles.label}>{t(`cleerance.admin.levels.${level}`)}</span>
                <span style={styles.count}>{this.props.counts[level]}</span>
            </div>
        );
    }
}
