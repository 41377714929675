// @flow

import React from 'react';
import Views from 'app/views';

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.application.forbidden';

    render () {
        return (
            <div style={{ width: '600px', margin: '100px auto' }}>
                <Views.Common.Empty
                    title="Access denied"
                    content={
                        <div>
                            You have no access to this application.
                            <br/>
                            If you think it's an error, please contact an administrator.
                        </div>
                    }
                />
            </div>
        );
    }
}
