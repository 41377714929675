// @flow

export function makeList(scores: number[]): number {
    return scores.length ? Math.max(...scores) : 1;
}

export function makeBlock(scores: number[]): number {
    return scores.length ? Math.max(...scores) : 0;
}

export function makeAggregate(scores: number[]): number {
    return scores.length ? Math.max(...scores) : 0;
}

export function makeTotal(direct: number, companies: number, persons: number): number {
    return direct + Math.max(0, companies - direct) / 4 + Math.max(0, persons - direct) / 4;
}
