import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import get from 'lodash/get';

const styles = {
    container: {
        lineHeight: '25px',
        width: '48%',
    },
    title: {
        color: colors.userCold,
        fontSize: '13px',
        borderBottom: '1px solid ' + colors.grey300,
        marginBottom: '15px',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        height: '35px',
    },
    icon: {
        width: '24px',
        marginRight: '10px',
    },
    label: {
        lineHeight: '17px',
    },
    left: {
        marginTop: '15px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.summary.status';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        const days = get(this.props.entity, 'computed.timeLeft', {});

        return (
            <div style={styles.container}>
                <div style={styles.title}>Status</div>
                {this.renderSurvey()}
                {this.renderVisit()}
                {this.renderNDA()}
                <div style={styles.left}>{Math.max(0, parseInt(days))} days left</div>
            </div>
        );
    }

    renderSurvey() {
        const progress = get(this.props.entity, 'computed.progress.input', {});

        return this.renderItem('pending', progress + '% of questions and documents are valid');
    }

    renderVisit() {
        const data = get(this.props.entity, 'payload.onSite', {});

        if (data.finalDate && (data.members || []).length > 0) {
            return this.renderItem('validated', 'Meeting is correctly scheduled');
        }

        return this.renderItem('pending', 'Meeting is not fully scheduled');
    }

    renderNDA() {
        const required = get(this.props.entity, 'payload.nda.required');
        const docs = get(this.props.entity, 'payload.nda.documents', {});

        if (!required) {
            return this.renderItem('empty', 'No NDA required');
        }

        if (!docs.unsigned) {
            return this.renderItem('pending', 'Please upload NDA');
        }

        if (!docs.signed) {
            return this.renderItem('pending', 'Waiting for signed NDA');
        }

        return this.renderItem('validated', 'Signed NDA is provided');
    }

    renderItem(status, label) {
        let color = colors.grey500;
        let icon = 'base.empty';

        if (status === 'pending') {
            color = colors.userCold;
            icon = 'deeligenz.survey.pending';
        }

        if (status === 'validated') {
            color = colors.userGreen;
            icon = 'do.submit';
        }

        return (
            <div style={styles.item}>
                <Icon type={icon} style={Object.assign({}, styles.icon, { fill: color })} />
                <div style={Object.assign({}, styles.label, { color })}>{label}</div>
            </div>
        );
    }
}
