// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Add from './add';
import List from './list';

const styles = {
    container: {
        width: '800px',
        height: '650px',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        background: colors.grey100,
        borderBottom: '1px solid ' + colors.grey300,
    },
    body: {
        overflow: 'auto',
    },
    title: {
        flexGrow: '1',
        margin: '0 15px',
        lineHeight: '60px',
        color: colors.grey500,
    },
    team: {
        display: 'inline-block',
        margin: '0 10px',
        color: colors.userCold,
        fontWeight: 'bold',
    },
    add: {
        margin: '10px',
    },
};

@inject('global')
export default class extends React.PureComponent<any, any> {
    static displayName = 'layout.application.header.members';

    static propTypes = {
        global: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        level: PropTypes.string.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <div style={styles.title}>
                        Team:
                        <span style={styles.team}>{this.props.data.profile.name}</span>
                    </div>

                    <Add
                        ref="add"
                        onSubmit={data =>
                            this.props.global.execute('cleerance', 'team.include', {
                                team: this.props.id,
                                level: data.level,
                                user: {
                                    email: data.email,
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                },
                            })
                        }
                    />

                    <Views.Common.Button
                        style={styles.add}
                        type="raised"
                        color="cold"
                        label="Add member"
                        icon={<Icon type="do.create" />}
                        onClick={() => this.refs.add.open()}
                    />
                </div>

                <div style={styles.body}>
                    <List
                        members={this.props.data.members}
                        level={this.props.level}
                        onExclude={email =>
                            this.props.global.execute('cleerance', 'team.exclude', {
                                team: this.props.id,
                                email,
                            })
                        }
                        onLevel={({ email, level }) =>
                            this.props.global.execute('cleerance', 'team.include', {
                                team: this.props.id,
                                level,
                                user: { email },
                            })
                        }
                    />
                </div>
            </div>
        );
    }
}
