import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Sum from 'dna/entity/common/display/sum';
import t from 'app/translation';
import { get } from 'lodash';

const LABELS = {
    i: 'investor',
    s: 'shareholder',
    b: 'boardmember',
    a: 'advisor',
    c: 'councilor',
    x: 'executive',
    m: 'manager',
}
const styles = {

};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.cockpit.relations';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
      const data = this.props.network.relations.persons;
      let intensity = 0;
      data.map(item => ['i', 's'].includes(item.level) ? intensity += 1 : null);
      intensity = intensity / data.length * 100;
        return (
            <div style={{marginTop: '5px', marginRight: '5px', marginLeft: '5px', border: '2px solid '+colors.grey400 }}>
                { this.renderHeader() }
                <div>
                  <div style={{padding: '5px'}}>
                    <Views.Common.Properties
                        values={ this.formatValues(get(this.props.network, 'computed.sums.persons', {})) }
                        fallback={t('veegilenzData.details.noData')}
                        align={ 100 }
                    />
                  </div>
                  <div style={{borderTop: '1px solid ' + colors.grey300, padding: '5px'}}>
                    <Views.Chart.Container
                      title='persons'
                      type="half-pie"
                      style={ styles.chart }
                      >
                        { this.renderEntries(data) }
                      </Views.Chart.Container>
                  </div>
                  <div style={{borderTop: '1px solid ' + colors.grey300, padding: '5px'}}>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}>
                      <div style={{borderRight: '1px solid ' + colors.grey300, textAlign: 'center'}}>

                        <span>Network Size</span>
                      </div>
                    </div>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>

                        <span>Network Intensity</span>

                    </div>
                  </div>

                  <div style={{borderTop: '1px solid ' + colors.grey300, padding: '5px'}}>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}>
                      <div style={{borderRight: '1px solid ' + colors.grey300, textAlign: 'center'}}>

                        <div>
                          <Icon style={{ fill: colors.userCold, height: '60px', width: '60px', verticalAlign: 'middle'}} type={data.length >= 50 ? 'base.signal.high' : data.length >= 20 ? 'base.signal.medium' : 'base.signal.small' } />
                          <span style={{ verticalAlign: 'middle'}}>{data.length >= 50 ? 'high' : data.length >= 20 ? 'medium' : 'small' }</span>
                        </div>
                      </div>
                    </div>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>

                        <div>
                          <Icon style={{ fill: colors.userCold, height: '60px', width: '60px', verticalAlign: 'middle'}} type={ intensity >= 50 ? 'base.signal2.high' : intensity >= 20 ? 'base.signal2.medium' :  'base.signal2.small' } />
                          <span style={{ verticalAlign: 'middle'}} >{ intensity >= 50 ? 'high' : intensity >= 20 ? 'medium' :  'small' }</span>
                        </div>

                    </div>
                  </div>

                </div>
            </div>
        );
    }

    renderHeader() {
      return (
        <div style={{backgroundColor: colors.grey200, padding: '5px'}}>
          <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%',textAlign: 'left' }}>
            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.relations" />
          </div>
          <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '40%',textAlign: 'left', color: colors.userCold }}>
            Relations
          </div>
        </div>
      );
    }

    formatValues (aggregates) {
        return [
            { label: t('dna.deefind.veegilenzData.person.sumSumTurnoverLabel'), value: (<Sum data={ aggregates.turnover } />) },
            { label: t('dna.deefind.veegilenzData.person.sumWeasseLabel'), value: (<Sum data={ aggregates.midWeasse } />) },
            { label: t('dna.deefind.veegilenzData.person.sumWeasseMaxLabel'), value: (<Sum data={ aggregates.maxWeasse } />) },
            { label: t('dna.deefind.veegilenzData.person.sumFortuneLabel'), value: (<Sum data={ aggregates.fortune } />) },
        ];
    }

    renderEntries (list) {
      const data = {};
      list.map(item => {
        if (!data[item.level]) {
          data[item.level] = {ratio: 0, level: item.level, label: LABELS[item.level]}
        }
        data[item.level].ratio += 1;
      })

        return Object.values(data).map((entry, index) => (
            <Views.Chart.Entry
                key={ 'e' + index }
                label={ entry.label  }
                value={ entry.ratio / list.length * 100  }
            />
        ));
    }
}
