import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Property from './properties/property';
import t from 'app/translation';

let styles = {
    container: {
        listStyleType: 'none',
        margin: '0',
        padding: '0',
    },
    loading: {
        color: colors.grey500,
    },
    fallback: {
        display: 'inline-block',
        color: colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.common.properties';

    static propTypes = {
        title: PropTypes.string,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.node.isRequired,
                value: PropTypes.node,
                style: PropTypes.object,
                tooltip: PropTypes.string,
            }),
        ).isRequired,
        loading: PropTypes.bool,
        fallback: PropTypes.string,
        inline: PropTypes.bool,
        align: PropTypes.number,
        style: PropTypes.object,
    };

    render() {
        if (this.props.loading) return this.renderLoading();

        if (!this.props.values.length) return this.renderFallback();

        const list = (
            <ul style={Object.assign({}, styles.container, this.props.style)}>
                {this.props.values.map(this.renderProperty.bind(this))}
            </ul>
        );

        return this.props.title ? (
            <div>
                <div style={{ marginBottom: '10px', textDecoration: 'underline', color: colors.userCold }}>
                    {this.props.title}
                </div>
                {list}
            </div>
        ) : (
            list
        );
    }

    renderProperty(property, index) {
        return (
            <Property
                key={index}
                label={property.label}
                value={property.value}
                style={property.style}
                tooltip={property.tooltip}
                inline={this.props.inline}
                align={this.props.align}
            />
        );
    }

    renderLoading() {
        return <div style={styles.loading}>{t('common.message.loading')}</div>;
    }

    renderFallback() {
        return <div style={styles.fallback}>{this.props.fallback}</div>;
    }
}
