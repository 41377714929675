import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    range: {
    },
    billion: {
        color: colors.userHot,
        fontWeight: 'bold',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'number';

    static propTypes = {
        value: PropTypes.oneOfType([ PropTypes.number, PropTypes.object, PropTypes.array ]),
        deviation: PropTypes.number,
        type: PropTypes.oneOf([ 'currency', 'percent' ]),
        scale: PropTypes.oneOf([ 'U', 'K', 'M', 'G' ]),
        style: PropTypes.object,
        fallback: PropTypes.string,
        round: PropTypes.number
    };

    roundNumber (value, estimate = false) {
        if (_.isInteger(value))
            return value;

        let round = this.props.round;

        if (! round)
            switch (this.props.type) {
                case 'currency':
                    round = value > 100 ? 0 : (value > 10 || estimate ? 1 : 2);
                    break;

                case 'percent':
                    round = 0;
                    break;

                default:
                    round = 3;
            }

        return Math.round(value * Math.pow(10, round)) / Math.pow(10, round);
    }

    formatNumber (value) {
        const bits = ('' + value).split('.');
        const integer = bits[0].replace(/./g, (c, i, a) => i && ((a.length - i) % 3 === 0) ? ' ' + c : c);

        return integer + (bits.length > 1 ? '.' + bits[1] : '');
    }

    computeScale (value) {
        if (this.props.type === 'currency') {
            if (value >= 100000000000) return 'G';
            if (value >= 100000000) return 'M';
            if (value >= 100000) return 'K';
        }

        return 'U';
    }

    renderValue (value, scale, estimate = false) {
        let scaledValue = value;

        switch (scale) {
            case 'K': scaledValue = value / 1000; break;
            case 'M': scaledValue = value / 1000000; break;
            case 'G': scaledValue = value / 1000000000; break;
        }

        return this.formatNumber(this.roundNumber(scaledValue, estimate));
    }

    renderSuffix (scale) {
        let suffix = '';

        switch (scale) {
            case 'K': suffix = 'K'; break;
            case 'M': suffix = 'M'; break;
            case 'G': suffix = 'G'; break;
        }

        if (this.props.type === 'currency') {
            if (scale === 'G') {
                return (
                    <span>
                        <span style={ styles.billion }>
                            B
                        </span>
                        €
                    </span>
                );
            }

            suffix += '€';
        }

        return suffix ? ' ' + suffix : '';
    }

    render () {
        let value;
        let deviation = this.props.deviation;

        if (_.isArray(this.props.value)) {
            if (this.props.value.length === 1) {
                value = this.props.value[0];
            }

            else if (this.props.value.length > 1) {
                const max = Math.max(this.props.value[0], this.props.value[1]);
                const min = Math.min(this.props.value[0], this.props.value[1]);

                deviation = (max - min) / 2;
                value = min + deviation;
            }
        }

        else if (_.isObject(this.props.value)) {
            value = this.props.value.value || this.props.value.avg;
            deviation = this.props.value.deviation || this.props.value.dev || deviation;
        }

        else {
            value = this.props.value;
        }

        if (typeof value !== 'number') {
            return (
                <span style={ Object.assign({ color: colors.grey500 }, this.props.style) }>
                    { this.props.fallback || '—' }
                </span>
            );
        }

        let scale = this.props.scale || this.computeScale(value);

        if (this.props.type === 'currency' && scale === 'M' && value - deviation >= 10000000000) {
            scale = 'G';
        }

        if (deviation) {
            return (
                <span style={ this.props.style }>
                    { this.renderValue(value - deviation, scale, true) }
                    &nbsp;→&nbsp;
                    { this.renderValue(value + deviation, scale, true) }
                    &nbsp;
                    { this.renderSuffix(scale) }
                </span>
            );
        }

        return (
            <span style={ this.props.style }>
                { this.renderValue(value, scale) }
                &nbsp;
                { this.renderSuffix(scale) }
            </span>
        )
    }
}
