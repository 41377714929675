import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Header from './header';
import Question from '../questions/question/item';
import Row from './row';
import capitalize from 'lodash/capitalize';
import Scope from 'app/layout/providers/scope';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import get from 'lodash/get';

const KIND_LABELS = {
    M: 'Manager',
    F: 'Fund',
    S: 'Service Providers',
};

@inject(stores => ({ store: stores.deeligenzStudies, management: stores.deeligenzManagement }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.management.display';

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    render() {
        if (!this.props.store || !this.props.state || !this.props.management.templatesMap.size) {
            return null;
        }

        const template = toJS(this.props.management.templatesMap.get(this.props.state.id));

        if (!template) return null;

        return (
            <div style={Object.assign({}, this.props.style || {})}>
                <Header
                    entity={template}
                    onUpdate={payload => {
                        this.props.management.updateTemplate(this.props.state.id, payload)
                    }}
                />
                {this.renderQuestions(template)}
            </div>
        );
    }

    renderQuestions(template) {
        const entities = (get(template, 'payload.questions') || [])
            .map(id => this.props.management.questionsMap.get(id))
            .filter(e => e && e.payload && !e.payload.embedded);

        return (
            <Scope path="list" type="list">
                <Views.List.Container entities={entities} state={this.state}>
                    <Views.List.Reducer
                        name="current"
                        title="Current"
                        type="groups"
                        value="yes"
                        specs={this.buildSpecs(template)}
                    />

                    <Views.List.Reducer
                        type="fuse"
                        specs={{
                            paths: ['payload.label'],
                        }}
                    />

                    <Views.List.Frame specs={{ labels: [] }} type="table" element={<Row />} />
                </Views.List.Container>
            </Scope>
        );
        //return (template.payload.questions || []).map(id => <Question id={id} additional={false} readOnly={true} />);
    }

    buildSpecs(template) {
        const categories = {};
        for (var questionId of get(template, 'payload.questions') || []) {
            const question = this.props.management.questionsMap.get(questionId);

            if (!question) {
                continue;
            }

            const category = this.props.management.categoriesMap.get(question.category);

            if (!Object.keys(categories).includes(category.id)) {
                categories[category.id] = {
                    label: category.payload.label,
                    info: KIND_LABELS[category.payload.kind] || capitalize((category.payload.kind || '').toLowerCase()),
                    filter: item => item.category === category.id,
                };
            }
        }

        return categories;
    }
}
