// @flow
import t from 'app/translation';

export function computeStatusLabel (status: any) {
    switch (status) {
        case 'started': return t('dna.comeet.status.ongoing');
        case 'finish': return t('dna.teem.status.finish');
        case 'aborted': return t('dna.comeet.status.aborted');
    }

    return t('dna.comeet.status.draft');
}

export function computeStartedDate(history) {
  for (const i of history) {
    if (i.transition === 'start')
      return i.createStamp;
  }
}
