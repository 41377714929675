import PropTypes from 'prop-types';
import React from 'react';
import Country from 'dna/entity/common/country';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.info';

    static propTypes = {
        entity: PropTypes.object,
    };

    render() {
        const main = this.renderMain();

        return (
            <span>
                {main}
                {main && this.props.entity.preview.country ? ' — ' : ''}
                <Country code={this.props.entity.preview.country} />
            </span>
        );
    }

    renderMain() {
        if (!this.props.entity) {
            return null;
        }

        const info = [];

        switch (this.props.entity.preview.gender) {
            case 1:
            case 'M':
            case 'male':
                info.push(t('dna.deefind.person.genderOptions.male'));
                break;

            case 2:
            case 'F':
            case 'female':
                info.push(t('dna.deefind.person.genderOptions.female'));
                break;
        }

        const birthDate = this.props.entity.preview.birthDate;
        const birthYear = birthDate ? parseInt(birthDate.substr(0, 4)) : undefined;

        if (birthYear) {
            info.push(t('dna.deefind.person.yearShort', { value: new Date().getFullYear() - birthYear }));
        }

        return info.length ? info.join(' — ') : null;
    }
}
