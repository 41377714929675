import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import get from 'lodash/get';
import set from 'lodash/set';
import clone from 'lodash/clone';
import RadioButton from 'material-ui/RadioButton';
import Table from '../../common/survey/field/widget/table';
import Files from './files';

const styles = {
    container: {
        width: '730px',
        margin: '60px auto',
    },
    form: {
        marginBottom: '20px',
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        lineHeight: '24px',
    },
    title: {
        padding: '10px',
        color: colors.userCold,
        background: colors.grey100,
    },
    field: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey300,
    },
    label: {
        width: '300px',
        padding: '10px',
    },
    widget: {
        flexGrow: '1',
        padding: '10px',
    },
    radios: {
        display: 'flex',
    },
    textarea: {
        padding: '10px',
        border: '1px solid ' + colors.grey500,
        borderRadius: '3px',
        width: '380px',
        fontSize: '15px',
    },
    actions: {
        padding: '10px',
        color: colors.userCold,
        background: colors.grey100,
        textAlign: 'right',
        borderTop: '1px solid ' + colors.grey300,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.agenda.report';

    static propTypes = {
        data: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = { data: {} };

    render() {
        return (
            <div style={styles.container}>
                {this.renderAttendees()}
                {this.renderForm('Global assessment', [
                    {
                        path: 'report.transparency',
                        label: 'Level of transparency of the company',
                        type: 'radios',
                        choices: { high: 'High', medium: 'Medium', low: 'Low' },
                    },
                    {
                        path: 'report.withheld',
                        label: 'Was there any information withheld ?',
                        type: 'radios',
                        choices: { no: 'No', yes: 'Yes' },
                    },
                    {
                        path: 'report.comment',
                        label: 'General comment',
                        type: 'text',
                    },
                ])}

                <div style={styles.form}>
                    <div style={styles.title}>Additional interviewed individuals</div>
                    <div style={styles.field}>
                        <Table
                            readOnly={false}
                            answer={{ rows: this.state.data.extraPersons || this.props.data.extraPersons || [] }}
                            onChange={answer =>
                                this.setState({
                                    data: Object.assign({}, this.state.data, { extraPersons: answer.rows }),
                                })
                            }
                            columns={[
                                {
                                    type: 'string',
                                    label: 'First name',
                                },
                                {
                                    type: 'string',
                                    label: 'Last name',
                                },
                                {
                                    type: 'string',
                                    label: 'Position',
                                },
                            ]}
                            style={{ margin: '15px auto 10px auto' }}
                        />
                    </div>
                    {this.renderActions(['extraPersons'])}
                </div>

                <Files
                    ref="files"
                    files={this.props.data.files}
                    onUpload={this.props.onUpload}
                    onChange={files => {
                        const data = clone(this.props.data);

                        if (data.files) {
                            data.files = data.files.concat(files);
                        } else {
                            data.files = files;
                        }

                        this.props.onChange(data);
                        this.setState(data);
                    }}
                    onDelete={id => {
                        const data = clone(this.props.data);
                        let files = data.files.slice(0);
                        for (const i in files) {
                            if (files[i] && id === files[i].id) {
                                files[i] = null;
                            }
                        }

                        files = files.filter(v => v !== null);

                        data.files = files;

                        this.props.onChange(data);
                    }}
                    onDownload={this.props.onFetchUpload}
                />
            </div>
        );
    }

    renderAttendees() {
        const results = [];
        let blockIndex = 0;

        for (const block of this.props.data.program || []) {
            if (block.attendees && block.attendees.length) {
                results.push(
                    this.renderForm(
                        block.title + ' — Individual level of responsiveness',
                        block.attendees.map((attendee: Object, attendeeIndex: number) => ({
                            label: (attendee.lastName || '').toUpperCase() + ' ' + attendee.firstName,
                            path: `program[${blockIndex}].attendees[${attendeeIndex}].responsiveness`,
                            type: 'radios',
                            choices: { high: 'High', medium: 'Medium', low: 'Low' },
                        })),
                    ),
                );
            }

            blockIndex++;
        }

        return results;
    }

    renderForm(title: string, fields: Object[]) {
        const rows = [];
        const paths = [];
        let actions = false;

        for (const field of fields) {
            paths.push(field.path);

            if (this.state.data[field.path] && this.state.data[field.path] !== get(this.props.data, field.path)) {
                actions = true;
            }

            rows.push(
                <div key={field.path} style={styles.field}>
                    <div style={styles.label}>{field.label}</div>
                    <div style={styles.widget}>{this.renderWidget(field)}</div>
                </div>,
            );
        }

        return (
            <div key={title} style={styles.form}>
                <div style={styles.title}>{title}</div>
                {rows}
                {actions ? this.renderActions(paths) : null}
            </div>
        );
    }

    renderWidget(field: Object) {
        const value = this.state.data[field.path] || get(this.props.data, field.path);
        const onChange = value => this.setState({ data: Object.assign({}, this.state.data, { [field.path]: value }) });

        if (field.type === 'radios') {
            return (
                <div style={styles.radios}>
                    {Object.keys(field.choices || {}).map(key => (
                        <RadioButton
                            key={key}
                            style={{ width: 'auto', marginRight: '40px' }}
                            label={field.choices[key]}
                            checked={value === key}
                            onCheck={event => onChange(key)}
                        />
                    ))}
                </div>
            );
        }

        if (field.type === 'text') {
            return (
                <textarea
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    rows={4}
                    style={styles.textarea}
                />
            );
        }
    }

    renderActions(paths: string[]) {
        return (
            <div style={styles.actions}>
                <Views.Common.Button
                    key="undo"
                    type="flat"
                    color="hot"
                    icon={<Icon type="do.undo" />}
                    label="Undo"
                    onClick={event => {
                        const next = clone(this.state.data);
                        paths.forEach(path => delete next[path]);
                        this.setState({ data: next });
                    }}
                    style={styles.submit}
                />
                &nbsp;&nbsp;&nbsp;
                <Views.Common.Button
                    key="submit"
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    label="Submit"
                    onClick={event => {
                        const next = clone(this.state.data);
                        paths.forEach(path => delete next[path]);
                        this.setState({ data: next });

                        const data = clone(this.props.data);
                        paths.forEach(path => set(data, path, this.state.data[path]));
                        this.props.onChange(data);
                    }}
                    style={styles.submit}
                />
            </div>
        );
    }
}
