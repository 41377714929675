// @flow

import type StateStore from 'app/stores/state';
import type { IObservableValue, IObservableArray, ObservableMap } from 'mobx';
import type { GlobalStore } from '../core/types';

import { action, observable, runInAction, toJS } from 'mobx';
import { sortBy, uniq, max, difference, union, omit } from 'lodash';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';
import cleerance from '@deecision/cleerance-utils';

const PATH = 'deeligenz.management';

export default class DeeligenzManagementStore {
    global: GlobalStore;
    state: StateStore;

    team: ?string;
    loading: IObservableValue<boolean>;
    embedded: ObservableMap<string, Object>;
    questionsMap: ObservableMap<string, Object>;
    categoriesMap: ObservableMap<string, Object>;
    questionsLists: ObservableMap<string, Object>;
    templatesMap: ObservableMap<string, Object>;
    categoriesList: ObservableMap<string, Object>;
    managerCategoriesList: IObservableArray<string>;
    fundCategoriesList: IObservableArray<string>;
    servicesCategoriesList: IObservableArray<string>;

    constructor(global: GlobalStore, state: StateStore) {
        this.global = global;
        this.state = state;

        this.team = null;
        this.loading = observable.box(true);
        this.embedded = observable.map();
        this.questionsMap = observable.map();
        this.categoriesMap = observable.map();
        this.questionsLists = observable.map();
        this.templatesMap = observable.map();
        this.categoriesList = observable.map();
        this.managerCategoriesList = observable.array();
        this.fundCategoriesList = observable.array();
        this.servicesCategoriesList = observable.array();
    }

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        if (session.level !== 'account') {
            return;
        }

        this.team = cursor.team;

        runInAction(() => {
            this.loading.set(true);
            this.embedded.clear();
            this.questionsMap.clear();
            this.categoriesMap.clear();
            this.questionsLists.clear();
            this.templatesMap.clear();
            this.categoriesList.clear();
            this.managerCategoriesList.clear();
            this.fundCategoriesList.clear();
            this.servicesCategoriesList.clear();
        });

        if (!cleerance.resolveFlags(session, cursor).includes('deeligenz.access')) {
            return;
        }

        const templates = await this.global.execute('deeligenz', 'templates.list', { teamUid: this.team });
        const questions = await this.global.execute('deeligenz', 'questions.list', { teamUid: this.team });
        const categories = await this.global.execute('deeligenz', 'categories.list', { teamUid: this.team });

        runInAction(() => {
            templates.forEach(template => this.templatesMap.set(template.id, template));
            questions.forEach(question => this.questionsMap.set(question.id, question));
            categories.forEach(category => this.categoriesMap.set(category.id, category));
            this.computeLists();
            this.loading.set(false);
        });
    }

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };

    @action createQuestion = async (category: string, payload: Object): Promise<Object> => {
        const question = await this.global.execute('deeligenz', 'questions.create', {
            category,
            payload,
            teamUid: this.team,
        });

        runInAction(() => {
            this.questionsMap.set(question.id, question);
            this.computeLists();
        });

        return question;
    };

    @action updateQuestion = async (id: string, payload: Object): Promise<Object> => {
        const question = await this.global.execute('deeligenz', 'questions.update', { id, payload });

        runInAction(() => {
            this.questionsMap.set(question.id, question);
            this.computeLists();
        });

        return question;
    };

    @action removeQuestion = async (id: string): Promise<void> => {
        await this.global.execute('deeligenz', 'questions.remove', { id });

        runInAction(() => {
            this.questionsMap.delete(id);
            this.computeLists();
        });
    };

    @action createCategory = async (payload: Object): Promise<Object> => {
        const category = await this.global.execute('deeligenz', 'categories.create', {
            payload,
            teamUid: this.team,
        });

        runInAction(() => {
            this.categoriesMap.set(category.id, category);
            this.computeLists();
        });

        return category;
    };

    @action updateCategory = async (id: string, payload: Object): Promise<Object> => {
        const category = await this.global.execute('deeligenz', 'categories.update', { id, payload });

        runInAction(() => {
            this.categoriesMap.set(category.id, category);
            this.computeLists();
        });

        return category;
    };

    @action orderCategory = async (ids: string[], indexes: number[]): Promise<Object> => {
        const categories = await this.global.execute('deeligenz', 'categories.order', { ids, indexes });

        runInAction(() => {
            categories.map(category => {
                this.categoriesMap.set(category.id, category);
                this.computeLists();
            });
        });

        return categories;
    };

    @action orderQuestion = async (ids: string[], indexes: number[]): Promise<Object> => {
        const questions = await this.global.execute('deeligenz', 'questions.order', { ids, indexes });

        runInAction(() => {
            questions.map(question => {
                this.questionsMap.set(question.id, question);
            });
            this.computeLists();
        });

        return questions;
    };

    @action removeCategory = async (id: string): Promise<void> => {
        await this.global.execute('deeligenz', 'categories.remove', { id });

        runInAction(() => {
            this.categoriesMap.delete(id);
            this.computeLists();
        });
    };

    @action duplicateTemplate = async (id: string): Promise<Object> => {
        const template = this.templatesMap.get(id);
        const clone = toJS(template);
        const data = clone.payload;

        data.label += ' DUPLICATA';
        data.type = clone.type;

        return await this.createTemplate(payload);
    };

    @action createTemplate = async (data: Object): Promise<Object> => {
        const template = await this.global.execute('deeligenz', 'templates.create', {
            type: data.type,
            payload: omit(data, 'type'),
        });

        runInAction(() => {
            this.templatesMap.set(template.id, template);
            this.computeLists();
        });

        return template;
    };

    @action updateTemplate = async (id: string, data: Object): Promise<Object> => {
        const template = await this.global.execute('deeligenz', 'templates.update', {
            id,
            type: data.type,
            payload: omit(data, 'type'),
        });

        runInAction(() => {
            this.templatesMap.set(template.id, template);
        });

        return template;
    };

    @action removeTemplate = async (id: string): Promise<void> => {
        await this.global.execute('deeligenz', 'templates.remove', { id });

        runInAction(() => {
            this.templatesMap.delete(id);
            this.computeLists();
        });
    };

    @action openTemplate = (id: string): void => {
        const template = this.templatesMap.get(id);

        if (!template) {
            console.error('invalid template id', id);
        }

        const menu = this.state.enter(PATH, 'menu');
        const data = menu.read() || {};
        const type = 'display';

        let cursor = -1;

        (data.items || []).forEach((item, index) => {
            if (cursor < 0 && item.type === type && item.id === id) {
                cursor = index;
            }
        });

        if (cursor < 0) {
            menu.assign(':next', { type, id }).select(':last');
        } else {
            menu.select(cursor);
        }
    };

    resolveQuestions(questions: string[]): string[] {
        const results: string[] = [];

        for (const id of uniq(questions)) {
            const question = this.questionsMap.get(id);

            if (!question) {
                // unknown question
                continue; // nothing to do
            }

            if (question.payload.embedded && question.payload.embedded.question) {
                // embedded question
                continue; // we take root questions only
            }

            results.push(id);

            for (const embed of this.embedded.get(id) || []) {
                results.push(embed.question); // we take all children
            }
        }

        return uniq(results);
    }

    @action toggleQuestion = (templateId: string, questionId: string): void => {
        const template = toJS(this.templatesMap.get(templateId));

        if (!template) {
            // template not found
            return;
        }

        let results = template.payload.questions || [];

        if (results.includes(questionId)) {
            // question is already included, let exclude it
            results = difference(results, this.resolveQuestions([questionId]));
        } else {
            // question is not included, let include it
            results = union(results, this.resolveQuestions([questionId]));
        }

        template.payload.questions = results;
        runInAction(() => this.updateTemplate(templateId, { ...template.payload, type: template.type }));
    };

    @action selectCategory = (templateId: string, questionIds: string[]): void => {
        const template = toJS(this.templatesMap.get(templateId));

        if (!template) {
            // template not found
            return;
        }

        template.payload.questions = union(template.payload.questions || [], this.resolveQuestions(questionIds));
        runInAction(() => this.updateTemplate(templateId, { ...template.payload, type: template.type }));
    };

    @action unselectCategory = (templateId: string, questionIds: string[]): void => {
        const template = toJS(this.templatesMap.get(templateId));

        if (!template) {
            // template not found
            return;
        }

        template.payload.questions = difference(template.payload.questions || [], this.resolveQuestions(questionIds));
        runInAction(() => this.updateTemplate(templateId, { ...template.payload, type: template.type }));
    };

    @action selectedCategory = (templateId: string, categoryId: string): [] => {
        const template = toJS(this.templatesMap.get(templateId));
        const questions = template.payload.questions || [];
        const selected = [];

        for (const id of questions) {
            const question = this.questionsMap.get(id);

            if (question && question.category === categoryId && !question.payload.embedded) {
                selected.push(id);
            }
        }

        return selected;
    };

    questionSelected = (templateId: string, questionId: string): boolean => {
        const template = this.templatesMap.get(templateId);

        return (template.payload.questions || []).includes(questionId);
    };

    computeLists() {
        for (const id of this.questionsMap.keys()) {
            const question = this.questionsMap.get(id);

            if (!question) {
                continue;
            }

            if (question.payload.type === 'boolean' && question.payload.scoring) {
                question.payload.weight = max([question.payload.scoring.yes, question.payload.scoring.no]);
            }

            if (question.payload.type === 'select' && question.payload.options) {
                question.payload.weight = max(
                    (question.payload.options.choices || []).map(choice => (choice && choice.score) || 0),
                );
            }

            this.questionsMap.set(id, question);
        }

        const categories = {};
        let categoriesRaw = [];

        Array.from(this.categoriesMap.values()).forEach(element => {
            if (!categories[element.payload.kind]) categories[element.payload.kind] = [];
            categories[element.payload.kind].push(element);
        });

        const categoriesList: any = {};

        for (const key of Object.keys(categories)) {
            categoriesList[key] = sortBy(categories[key], 'index', 'asc').map((item: Object) => item.id);
            categoriesRaw = categoriesRaw.concat(categories[key]);
        }

        this.categoriesList.replace(categoriesList);

        const mc = Array.from(this.categoriesMap.values()).filter(category => category.payload.kind === 'M');
        const fc = Array.from(this.categoriesMap.values()).filter(category => category.payload.kind === 'F');
        const sc = Array.from(this.categoriesMap.values()).filter(category => category.payload.kind === 'S');

        this.managerCategoriesList.replace(sortBy(mc, 'index', 'asc').map((item: Object) => item.id));
        this.fundCategoriesList.replace(sortBy(fc, 'index', 'asc').map((item: Object) => item.id));
        this.servicesCategoriesList.replace(sortBy(sc, 'index', 'asc').map((item: Object) => item.id));
        this.embedded.clear();

        for (const category of categoriesRaw) {
            const questions = Array.from(toJS(this.questionsMap.values())).filter(
                question => question.category === category.id,
            );

            const root = questions.filter(
                question => !(question.payload.embedded && question.payload.embedded.question),
            );

            this.questionsLists.set(
                category.id,
                sortBy(root, ['index']).map(question => question.id),
            );

            for (const question of questions) {
                if (question.payload.embedded && question.payload.embedded.question) {
                    this.embedded.set(
                        question.payload.embedded.question,
                        (toJS(this.embedded.get(question.payload.embedded.question)) || []).concat([
                            { question: question.id, value: question.payload.embedded.value },
                        ]),
                    );
                }
            }
        }
    }
}
