import { get } from 'lodash';

export default async function (data: Object, services: Object): Promise<Object> {
    const scoring = {
        data: [],
        levels: 5,
        risk: get(data, 'computed.scoring.rating', 5),
    };

    for (const item of get(data, 'computed.scoring.details', [])) {
        scoring.data.push({
            label: item.label,
            value: item.rating,
        });
    }

    return { scoring };
}
