export const ACTIVITIES = {
    '4615': 'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
    '4616': 'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
    '4618': 'Agents specialised in the sale of other particular products',
    '4644': 'Wholesale of china and glassware and cleaning materials',
    '4645': 'Wholesale of perfume and cosmetics',
    '4647': 'Wholesale of furniture, carpets and lighting equipment',
    '4648': 'Wholesale of watches and jewellery',
    '4751': 'Retail sale of textiles in specialised stores',
    '4753': 'Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
    '4759': 'Retail sale of furniture, lighting equipment and other household articles in specialised stores',
    '4775': 'Retail sale of cosmetic and toilet articles in specialised stores',
    '6411': 'Central banking',
    '6419': 'Other monetary intermediation',
    '6420': 'Activities of holding companies',
    '6430': 'Trusts, funds and similar financial entities',
    '6491': 'Financial leasing',
    '6492': 'Other credit granting',
    '6499': 'Other financial service activities, except insurance and pension funding n.e.c.',
    '6511': 'Life insurance',
    '6512': 'Non-life insurance',
    '6520': 'Reinsurance',
    '6612': 'Security and commodity contracts brokerage',
    '6619': 'Other activities auxiliary to financial source, except insurance and pension funding',
    '6622': 'Activities of insurance agents and brokers',
    '6629': 'Other activities auxiliary to insurance and pension funding',
    '6810': 'Buying and selling of own real estate',
    '6831': 'Real estate agencies',
    '6832': 'Management of real estate on a fee or contract basis',
    '6920': 'Accounting, bookkeeping and auditing activities; tax consultancy',
    '9003': 'Artistic creation',
    '9004': 'Operation of arts facilities',
    '9200': 'Gambling and betting activities',
    '9311': 'Operation of sports facilities',
    '9312': 'Activities of sport clubs',
};

export const COUNTRIES = {
    'ET': 'Ethiopia',
    'IR': 'Iran (Islamic Republic of)',
    'IQ': 'Iraq',
    'LK': 'Sri Lanka',
    'SY': 'Syrian Arab Republic',
    'TT': 'Trinidad and Tobago',
    'TN': 'Tunisia',
    'VU': 'Vanuatu',
    'YE': 'Yemen',
};
