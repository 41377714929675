import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Concentric} from "./concentric";
import {Concentric2} from "./concentric2";
import infoCircles  from "./details";
import infoCircles2  from "./details2";
import Button from '@material-ui/core/Button';

export class Zones extends React.PureComponent<any, any> {

    constructor(props) {
        super(props);
        this.state = {isToggleOn: true};
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(toogle) {
        this.setState({ isToggleOn: toogle });
    }

    onSelect(event) {
        console.log(event);
    }

    render() {
        return (
            <div>
                <Button variant="contained" color="primary" style={{ marginLeft: "20" }} onClick={() => this.handleClick(true)}> PRIORISATION 1 </Button>
                <Button variant="contained" color="primary" style={{ marginLeft: "20" }} onClick={() => this.handleClick(false)}> PRIORISATION 2 </Button>
                <Grid container spacing={8} style={{ margin: "10" }}>
                    <Grid>
                        <Paper style={{ margin: "10" }}>
                            {this.state.isToggleOn ? <Concentric /> : <Concentric2 /> }
                        </Paper>
                    </Grid>
                    <div>
                        {this.state.isToggleOn ? infoCircles() : infoCircles2() }

                    </div>
                </Grid>
            </div>
        );
    }
}

