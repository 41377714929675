// @flow

import type {
    VigilanceNorkom,
    VigilanceReport,
    VigilanceReportDirect,
    VigilanceReportNetwork,
} from '@deecision/deefind-types/model';

import { makeTotal } from './score';

export default function makeVigilanceReport(parts: {
    direct?: VigilanceReportDirect,
    companies?: VigilanceReportNetwork,
    persons?: VigilanceReportNetwork,
    norkom?: VigilanceNorkom,
}): VigilanceReport {
    return {
        direct: parts.direct,
        companies: parts.companies,
        persons: parts.persons,
        norkom: parts.norkom,
        score: makeTotal(
            parts.direct ? parts.direct.score : 0,
            parts.companies ? parts.companies.score : 0,
            parts.persons ? parts.persons.score : 0,
        ),
    };
}
