import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';

const styles = {
    container: {
        display: 'inline-block',
        color: 'white',
        textAlign: 'center',
        height: '20px',
        lineHeight: '20px',
        width: '20px',
        fontSize: '14px',
        borderRadius: '50%',
        verticalAlign: 'middle',
        cursor: 'default'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.flag';

    static propTypes = {
        label: PropTypes.string.isRequired,
        tooltip: PropTypes.string.isRequired,
        color: PropTypes.oneOf(['cold', 'hot']),
        light: PropTypes.bool,
        active: PropTypes.bool,
        style: PropTypes.object
    };

    state = { hover: false };

    render () {
        if (! this.props.active) {
            return null;
        }

        const background = this.props.light
            ? (this.props.color === 'cold' ? colors.halfCold : colors.halfHot)
            : (this.props.color === 'cold' ? colors.userCold : colors.userHot);

        const style = Object.assign({},
            styles.container,
            this.props.style,
            { background }
        );

        return (
            <Views.Common.Tooltip content={ this.props.tooltip }>
                <span style={ style }>
                    { this.props.label }
                </span>
            </Views.Common.Tooltip>
        );
    };
}
