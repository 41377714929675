// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import t from 'app/translation';

const styles = {
    container: {
        marginTop: '20px',
    },
    item: {
        display: 'flex',
        marginTop: '5px',
        lineHeight: '40px',
        padding: '0 20px',
        color: colors.grey500,
        border: '1px solid ' + colors.grey300,
        borderColor: colors.grey300,
        borderRadius: '3px',
        cursor: 'default',
    },
    label: {
        width: '70%',
    },
    count: {},
    hover: {
        background: colors.grey300,
        borderColor: colors.grey300,
        color: 'black',
        cursor: 'pointer',
    },
    active: {
        background: colors.grey200,
        borderColor: colors.grey200,
        color: 'black',
        cursor: 'pointer',
    },
    current: {
        background: colors.userCold,
        borderColor: colors.userCold,
        color: 'white',
    },
};

const LABELS = {
    person: 'Persons',
    company: t('common.label.companies'),
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.inputs.menu';

    static propTypes = {
        counts: PropTypes.object.isRequired,
        type: PropTypes.oneOf(['person', 'company']).isRequired,
        onType: PropTypes.func.isRequired,
    };

    state = {};

    render() {
        return (
            <div style={styles.container}>
                {this.renderType('person')}
                {this.renderType('company')}
            </div>
        );
    }

    renderType(type: string) {
        const count = this.props.counts[`${type}Results`] || 0;
        const onClick = count > 0 ? () => this.props.onType(type) : undefined;
        const onEnter = count > 0 ? () => this.setState({ hover: type }) : undefined;
        const onLeave = count > 0 ? () => this.setState({ hover: null }) : undefined;

        const style = Object.assign(
            {},
            styles.item,
            count > 0
                ? type === this.props.type
                    ? styles.current
                    : this.state.hover === type
                    ? styles.hover
                    : styles.active
                : {},
        );

        return (
            <div style={style} onClick={onClick} onMouseEnter={onEnter} onMouseLeave={onLeave}>
                <span style={styles.label}>{LABELS[type]}</span>
                <span style={styles.count}>{count}</span>
            </div>
        );
    }
}
