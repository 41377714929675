// @flow

import type {
    SdkWorker,
    SdkMake,
    CoreLogger,
    MakeFollow,
    PortActionQuery,
    PortFeedback,
} from '@deecision/infra-types/client';

import uuid from 'uuid';
import assign from 'lodash/assign';

export default function(worker: SdkWorker, logger: CoreLogger): SdkMake<*> {
    return function<T>(action: PortActionQuery, follow?: MakeFollow): Promise<T> {
        const token = uuid.v4();
        const query = assign({}, action, { token });

        return new Promise((resolve: Function, reject: Function) => {
            const dispose = worker.send(query, (feedback: PortFeedback) => {
                switch (feedback.type) {
                    case 'progress':
                        if (follow) {
                            follow({ queue: feedback.queue, step: feedback.step, percent: feedback.percent });
                        }
                        break;

                    case 'error':
                        dispose();
                        reject({ code: feedback.code, reason: feedback.reason });
                        break;

                    case 'result':
                        dispose();
                        resolve(feedback.data);
                        break;

                    default:
                        logger.alert(`Received invalid "${feedback.type}" feedback for action.`);
                }
            });
        });
    };
}
