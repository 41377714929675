import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import Form from './form';
import Item from './item';

const styles = {
    container: {},
};

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.attendees.list';

    static contextTypes = {
        createAttendee: PropTypes.func.isRequired,
    };

    static propTypes = {
        project: PropTypes.object.isRequired,
        users: PropTypes.array.isRequired,
        attendees: PropTypes.array.isRequired,
        edit: PropTypes.bool.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <Views.List.Container
                state={this.props.state}
                entities={this.props.users.concat(this.props.attendees)}
                style={styles.container}
            >
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        lastName: {
                            label: 'Last name',
                            path: 'lastName',
                            type: 'asc',
                        },
                        firstName: {
                            label: 'First name',
                            path: 'firstName',
                            type: 'asc',
                        },
                    }}
                />

                <Views.List.Reducer
                    name="groups"
                    title=""
                    type="groups"
                    specs={{
                        internal: {
                            label: 'Core team',
                            filter: entity => !entity.intervention,
                        },
                        external: {
                            label: 'External',
                            filter: entity => !!entity.intervention,
                        },
                    }}
                />

                <Views.List.Reducer type="fuse" specs={{ paths: ['firstName', 'lastName', 'email'] }} />

                {this.props.edit ? (
                    <Views.List.Block position="right">
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            label="Add attendee"
                            icon={<Icon type="do.create" />}
                            onClick={() => this.refs['form'].open()}
                        />

                        <Form
                            ref="form"
                            project={this.props.project}
                            onSubmit={profile =>
                                this.context.createAttendee(
                                    this.props.project.id,
                                    Object.assign({}, profile, { team: this.props.project.teamUid }),
                                )
                            }
                        />
                    </Views.List.Block>
                ) : null}

                <Views.List.Frame
                    type="table"
                    specs={{ labels: ['First name', 'Last name', 'Email address', 'Role'] }}
                    element={
                        <Item
                            edit={this.props.edit}
                            project={this.props.project}
                        />
                    }
                />
            </Views.List.Container>
        );
    }
}
