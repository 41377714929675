// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Paper from 'material-ui/Paper';

const styles = {
    container: {
        width: '500px',
        margin: '100px auto',
        border: '1px solid ' + colors.grey100,
        borderRadius: '5px',
        background: colors.grey100,
        padding: '40px 20px',
    },
    title: {
        fontSize: '21px',
        textAlign: 'center',
        color: colors.userCold,
        marginBottom: '10px',
    },
    notice: {
        fontSize: '17px',
        textAlign: 'center',
        color: colors.grey500,
        marginBottom: '40px',
    },
    error: {
        fontSize: '17px',
        textAlign: 'center',
        color: colors.userHot,
        margin: '40px 0',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.base.base.splash';

    static propTypes = {
        title: PropTypes.node.isRequired,
        notice: PropTypes.node,
        error: PropTypes.node,
    };

    render() {
        return (
            <Paper style={styles.container}>
                <div style={styles.title}>{this.props.title}</div>
                {this.props.notice ? <div style={styles.notice}>{this.props.notice}</div> : undefined}
                {this.props.error ? <div style={styles.error}>{this.props.error}</div> : undefined}
                {this.props.children}
            </Paper>
        );
    }
}
