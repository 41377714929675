import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
    container: {
        margin: '15px 0',
        display: 'block',
        lineHeight: '16px',
    },
    score: {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        display: 'inline-block'
    },
    scoreIcon: {
        width: '16px',
        height: '16px',
        fill: 'white',
    },
    label: {
        margin: '0 8px',
        fontWeight: 'bold',
        verticalAlign: 'text-top',
    },
    value: {paddingLeft: '24px', marginTop: '5px'},
    valueEmpty: {
        color: colors.grey500,
        paddingLeft: '24px',
        marginTop: '5px'
    },
    valueItem: {},
    valueCount: {
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: '15px',
    },
    valueFlag: {
        height: '16px',
        verticalAlign: 'bottom',
        marginRight: '10px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.vigilance.item';

    static propTypes = {
        label: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
    };

    state = { hover: false };

    render() {
        let color, icon;

        switch (true) {
            case this.props.score >= 4:
                color = 'black';
                icon = 'veegilenz.alert';
                break;

            case this.props.score >= 3:
                color = colors.userHot;
                icon = 'veegilenz.alert';
                break;

            case this.props.score >= 2:
                color = colors.userWarning;
                icon = 'veegilenz.warning';
                break;

            case this.props.score >= 1:
                color = colors.userGreen;
                icon = 'veegilenz.allright';
                break;

            default:
                color = colors.grey500;
                icon = 'veegilenz.allright';
        }

        return (
            <div style={styles.container}>
                <div style={Object.assign({}, styles.score, { background: color })}>
                    <Icon style={styles.scoreIcon} type={icon} />
                </div>

                {this.props.info ? <Tooltip title={this.props.info}>
                  <span style={Object.assign({}, styles.label, { color })}>{this.props.label}</span>
                </Tooltip> : <span style={Object.assign({}, styles.label, { color })}>{this.props.label}</span> }
                <br/>{this.renderValue()}
            </div>
        );
    }

    renderValue() {
        const data = this.props.data;

        if (!data) {
            return <div style={styles.valueEmpty}>No data</div>;
        }

        switch (data.type) {
            case 'aggregate':
                return this.renderAggregate(data);

            case 'country':
                if (!data.codes || !data.codes.length) {
                    return <div style={styles.valueEmpty}>No data</div>;
                }

                return (
                    <div style={styles.value}>
                        {data.codes.map(code => (
                            <div key={code} style={styles.valueItem}>
                                <img
                                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${code.toLowerCase()}.svg`}
                                    style={styles.valueFlag}
                                />
                                {t(`common.country.${code.toUpperCase()}`)}
                            </div>
                        ))}
                    </div>
                );

            case 'activity':
                if (!data.codes || !data.codes.length) {
                    return <div style={styles.valueEmpty}>No data</div>;
                }

                return (
                    <div style={styles.value}>
                        {data.codes.map(code => (
                            <div key={code} style={styles.valueItem}>
                                {code}
                                &nbsp;&nbsp;&nbsp;
                                {code}
                            </div>
                        ))}
                    </div>
                );

            case 'mandates':
                return data.mandates > 0 ? (
                    <div style={styles.value}>{data.mandates} mandates found</div>
                ) : (
                    <div style={styles.valueEmpty}>No mandates found</div>
                );

            case 'listedRelations':
                return data.files.length > 0 ? (
                    <div style={styles.value}>{data.files.length} Mandates in listed issues found</div>
                ) : (
                    <div style={styles.valueEmpty}>No Mandates in listed issues found</div>
                );

            case 'politic':
                return data.files.length > 0 ? (
                    <div style={styles.value}>{data.files.length} politic issues found</div>
                ) : (
                    <div style={styles.valueEmpty}>No politic issues found</div>
                );

            case 'justice':
                return data.files.length > 0 ? (
                    <div style={styles.value}>{data.files.length} justice issues found</div>
                ) : (
                    <div style={styles.valueEmpty}>No politic issues found</div>
                );

            case 'publication':
                return data.date > 0 ? (
                    <div style={styles.value}>Last accounting publication : {data.date}</div>
                ) : (
                    <div style={styles.valueEmpty}>No accounting publication found</div>
                );

            case 'norkom':
                return `${data.answer} (${data.score})`;

            default:
                return <div style={styles.valueEmpty}>No data</div>;
        }
    }

    renderAggregate(data: Object) {
        const items = [];

        if (data.vetos > 0) {
            items.push(
                <span key="vetos" style={Object.assign({}, styles.valueCount, { color: 'black' })}>
                    {data.vetos} vetos
                </span>,
            );
        }

        if (data.alerts > 0) {
            items.push(
                <span key="alerts" style={Object.assign({}, styles.valueCount, { color: colors.userHot })}>
                    {data.alerts} alerts
                </span>,
            );
        }

        if (data.warnings > 0) {
            items.push(
                <span key="warnings" style={Object.assign({}, styles.valueCount, { color: colors.userWarning })}>
                    {data.warnings} warnings
                </span>,
            );
        }

        if (!items.length) {
            items.push(
                <span
                    key="ok"
                    style={Object.assign({}, styles.valueCount, { color: colors.grey500, fontWeight: 'normal' })}
                >
                    {data.total ? 'No data found' : 'No problem found'}
                </span>,
            );
        }

        if (data.total > 0) {
            items.push(
                <span
                    key="total"
                    style={Object.assign({}, styles.valueCount, { color: colors.grey500, fontWeight: 'normal' })}
                >
                    ({data.total})
                </span>,
            );
        }

        return <div style={styles.value}>{items}</div>;
    }
}
