// flow

import type {
    LinkData,
    LinkMandate,
    LinkStock,
    LinkBase,
    LinkConnection,
    EntitySource,
} from '@deecision/deefind-types/model';

import {
    cleanIdentifier,
    cleanName,
    cleanNumber,
    cleanShape,
    cleanDate,
    cleanBoolean,
    cleanArray, cleanString,
} from './core';

import { cleanSource } from './base';

export function cleanLink(value: any, source?: EntitySource): LinkData | undefined {
    return cleanShape(value, {
        mandates: [cleanArray, [cleanLinkMandate, source]],
        positions: [cleanArray, [cleanLinkMandate, source]],
        stocks: [cleanArray, [cleanLinkStock, source]],
        shareholders: [cleanArray, [cleanLinkStock, source]],
        connections: [cleanArray, [cleanLinkConnection, source]],
    });
}

export function cleanLinkMandate(value: any, source?: EntitySource): LinkMandate | undefined {
    const base = cleanLinkBase(value, source);

    return base
        ? Object.assign(
              base,
              cleanShape(value, {
                  level: cleanIdentifier,
                  titles: [cleanArray, cleanName],
              }),
          )
        : undefined;
}

export function cleanLinkStock(value: any, source?: EntitySource): LinkStock | undefined {
    const base = cleanLinkBase(value, source);

    return base
        ? Object.assign(
              base,
              cleanShape(value, {
                  label: cleanString,
                  direct: cleanBoolean,
                  value: [cleanShape, { min: cleanNumber, max: cleanNumber }],
                  beneficial: cleanBoolean,
                  ultimate: cleanBoolean,
              }),
          )
        : undefined;
}

export function cleanLinkConnection(value: any, source?: EntitySource): LinkConnection | undefined {
    const base = cleanLinkBase(value, source);

    return base
        ? Object.assign(
              base,
              cleanShape(value, {
                  nature: cleanName,
                  family: cleanName,
                  level: cleanName,
                  proxy: cleanName,
              }),
          )
        : undefined;
}

export function cleanLinkBase(value: any, source?: EntitySource): ?LinkBase {
    return cleanShape(value, {
        start: cleanDate,
        end: cleanDate,
        ceased: cleanBoolean,
        guessed: cleanBoolean,
        source: [cleanSource, source],
    });
}
