// @flow

import type { ResolverInterface, ResolverSpecs, StateLocation } from '../../types';
import type StateStore from '../../index';
import type StatePath from '../../path';
import type StateNode from '../../node';

export default class ArrayResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;
    prototype: ResolverSpecs;
    pinned: ResolverSpecs[];
    dimension: number;
    store: ?StateStore;
    max: number;

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
        this.pinned = (specs.pinned || []).map(item => Object.assign(item, { pinned: true }));
        this.max = specs.max || 100;
        this.dimension = 0;
        this.store = null;

        if (typeof this.specs.prototype === 'object' && this.specs.prototype.type) {
            this.prototype = specs.prototype;
        }

        else if (typeof this.specs.prototype === 'string') {
            this.prototype = { type: specs.prototype };
        }

        else {
            throw new Error(`Invalid prototype passed to "${this.path.toString()}" array normalizer: ${JSON.stringify(specs.prototype)}.`);
        }
    }

    register (store: StateStore): void {
        this.store = store;
        this.adjustDimension(this.pinned.length);
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        if (value && value.slice) {
            value = value.slice();
        }

        if (! Array.isArray(value)) {
            value = [];
        }

        if (this.pinned.length) {
            value = this.pinned.concat(value.slice(this.pinned.length));
        }

        const result = [];
        this.adjustDimension(value.length);

        for (let index = 0; index < value.length ; index ++) {
            const itemValue = node.forge(index, this.prototype).normalize(value[index], external);

            if (itemValue) {
                result.push(itemValue);
            }
        }

        return result.slice(0, this.max);
    }

    locate (node: StateNode, data: any, location: StateLocation): StateLocation {
        const items = node.read(data) || [];

        switch (location) {
            case ':first':
                return 0;

            case ':last':
                return items.length -1;

            case ':next':
                this.adjustDimension(items.length + 1);
                return items.length >= this.max ? items.length - 1 : items.length;
        }

        if (typeof location === 'number') {
            if (location < 0) {
                console.error(`Located invalid "array" scope index ${location}.`);
                return 0;
            }

            // next position is authorized
            if (location > items.length) {
                console.error(`Located out of range "array" scope index ${location}.`);
                return items.length;
            }
        }

        return location;
    }

    remove (node: StateNode, data: any, location: StateLocation): any {
        if (location === ':all') {
            return this.path.write(data, []);
        }

        const items = node.read(data) || [];

        if (typeof location === 'string') {
            const moreThan = location.match(/^>(\d+)$/);

            if (moreThan) {
                return this.path.write(data, items.slice(0, parseInt(moreThan[1]) + this.pinned.length + 1));
            }
        }

        const index = this.locate(node, data, location);

        if (index < this.pinned.length) {
            console.error(`Tried to remove a pinned item from state.`);
            return data;
        }

        const result = (index > 0 ? items.slice(0, index) : [])
            .concat(index < items.length ? items.slice(index + 1) : []);

        return this.path.write(data, result);
    }

    adjustDimension (value: number): void {
        if (! this.store) {
            console.error(`Tried to adjust array scope resolver dimension without store.`);
            return;
        }

        if (value < this.pinned.length) {
            console.error(`Tried to adjust array scope with wrong dimension.`);
            return;
        }

        if (value < this.dimension) {
            console.error(`Tried to adjust array scope with wrong dimension.`);
            return;
        }

        if (value > this.max) {
            value = this.max;
        }

        for (let index = this.dimension ; index <= value ; index ++) {
            this.store.define(this.path.concat(index), this.prototype);
        }
    }
}
