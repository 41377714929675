// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import Questions from './questions';
import Templates from './templates';
import Display from './display';

import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.deeligenzManagement }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management';

    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        createCategory: PropTypes.func.isRequired,
        updateCategory: PropTypes.func.isRequired,
        removeCategory: PropTypes.func.isRequired,
        createQuestion: PropTypes.func.isRequired,
        updateQuestion: PropTypes.func.isRequired,
        removeQuestion: PropTypes.func.isRequired,
        openTemplate: PropTypes.func.isRequired,
    };

    getChildContext () {
        return {
            createCategory: this.props.store.createCategory,
            updateCategory: this.props.store.updateCategory,
            removeCategory: this.props.store.removeCategory,
            createQuestion: this.props.store.createQuestion,
            updateQuestion: this.props.store.updateQuestion,
            removeQuestion: this.props.store.removeQuestion,
            openTemplate: this.props.store.openTemplate,
        };
    }

    render () {
        return (
            <Screen
                path="management"
                type="workspace"
                select={ this.props.store.selectStage }
                close={ this.props.store.closeStage }

                stages={{
                    templates: {
                        entry: { icon: 'deeligenz.questions', label: 'Templates' },
                        content: Templates,
                    },
                    questions: {
                        entry: { icon: 'deeligenz.questions', label: 'Questions' },
                        content: Questions,
                    },
                    display: {
                        entry: this.computeDisplayEntry.bind(this),
                        content: Display,
                    }
                }}
            />
        );
    }

    computeDisplayEntry (state?: Object) {
        let label = 'Template';

        if (state) {
            const template = this.props.store.templatesMap.get(state.id);

            if (template && template.payload) {
                label = template.payload.label;
            }
        }

        return {
            icon: 'deeligenz.study',
            label
        };
    }
}
