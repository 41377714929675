import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Head from './head';
import Request from './request';
import Status from './status';
import Invitations from './invitations';
import colors from 'app/theme/colors';
import Form from '../../common/form/invitation';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    item: {
        background: colors.grey100,
        borderRadius: '5px',
        padding: '20px',
        margin: '20px auto',
        width: '800px',
    },
    body: {
        marginTop: '20px',
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.summary';

    static contextTypes = {
        openStudy: PropTypes.func.isRequired,
    };

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    render() {
        const entities = this.props.store.studies;
        const loaded = toJS(this.props.store.loaded);

        if (!loaded) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return <div style={this.props.style}>{this.renderList(Array.from(entities.values()))}</div>;
    }

    renderList(entities) {
        if (!entities.length) {
            return <Views.Common.Empty title="Nothing to do now" />;
        }

        return entities.map(entity => {
            entity = toJS(entity);

            const mainExternal = (((this.props.store.global.session.get() || {}).user || {}).interventions || [])
                .map(i => i.id)
                .includes(entity.externalUid);

            return (
                <div style={styles.item} key={entity.id}>
                    {!mainExternal ? null : (
                        <Form
                            ref="form"
                            onSubmit={e => {
                                this.props.store.sendInvitation(entity.id, e);
                            }}
                        />
                    )}

                    <Head
                        entity={entity}
                        onOpenInvitation={
                            !mainExternal
                                ? null
                                : () => {
                                      this.refs.form.open();
                                  }
                        }
                        onOpen={event => this.context.openStudy(entity.id)}
                    />

                    <div style={styles.body}>
                        <Request entity={entity} />
                        <Status entity={entity} />
                    </div>

                    {/*<div style={{ marginTop: '20px' }}>*/}
                    {/*    <Invitations*/}
                    {/*        entity={entity}*/}
                    {/*        mainExternal={mainExternal}*/}
                    {/*        handleDeleteInvitation={(entityId, invitation) => {*/}
                    {/*            this.props.store.deleteInvitation(entity.id, invitation.id);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            );
        });
    }
}
