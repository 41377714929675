export default {
    '302020': 'Food Products',
    '151040': 'Metals & Mining',
    '101020': 'Oil, Gas & Consumable Fuels',
    '302010': 'Beverages',
    '302030': 'Tobacco',
    '252030': 'Textiles, Apparel & Luxury Goods',
    '151050': 'Paper & Forest Products',
    '151010': 'Chemicals',
    '352020': 'Pharmaceuticals',
    '452020': 'Technology Hardware, Storage & Peripherals',
    '201040': 'Electrical Equipment',
    '201060': 'Machinery',
    '251020': 'Automobiles',
    '251010': 'Auto Components',
    '252010': 'Household Durables',
    '551010': 'Electric Utilities',
    '551040': 'Water Utilities',
    '202010': 'Commercial Services & Supplies',
    '201030': 'Construction & Engineering',
    '255040': 'Specialty Retail',
    '255010': 'Distributors',
    '255030': 'Multiline Retail',
    '203040': 'Road & Rail',
    '203020': 'Airlines',
    '203050': 'Transportation Infrastructure',
    '203010': 'Air Freight & Logistics',
    '254010': 'Media',
    '501010': 'Diversified Telecommunication Services',
    '451020': 'IT Services',
    '402010': 'Diversified Financial Services',
    '403010': 'Insurance',
    '601020': 'Real Estate Management & Development',
    '202020': 'Professional Services',
    '253020': 'Diversified Consumer Services',
    '351020': 'Health Care Providers & Services',
    '253010': 'Hotels, Restaurants & Leisure',
    '202021': 'Professional Services',
};
