// @flow

import crypto from 'crypto';

export function createToken() {
    return crypto
        .randomBytes(48)
        .toString('base64')
        .replace(/\//g, '_')
        .replace(/\+/g, '-');
}

export function createPassword() {
    return crypto
        .randomBytes(48)
        .toString('base64')
        .replace(/\//g, '')
        .replace(/\+/g, '')
        .substr(0, 8);
}

export function createSecret() {
    return crypto
        .randomBytes(128)
        .toString('hex')
        .substr(0, 128);
}
