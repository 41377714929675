// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from './button';
import Menu from './menu';
import { union, flatten } from 'lodash';

const styles = {
    container: {
        position: 'relative',
    },
};

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'layout.application.header.cursor';

    static propTypes = {
        global: PropTypes.object.isRequired,
    };

    state = { open: false, anchor: null };

    render() {
        const session = this.props.global.session.get();
        const cursor = this.props.global.cursor.get();
        const account = session.user.accounts[0];
        const interventions = session.user.interventions || [];

        const teams = union(
            account ? Object.keys(account.memberships || {}) : [],
            flatten(interventions.map(intervention => Object.keys(intervention.memberships))),
        );

        if (teams.length < 2) {
            return null;
        }

        return (
            <div style={styles.container}>
                <Button value={cursor.team} onClick={event => this.setState({ open: true, anchor: event.target })} />

                <Menu
                    anchor={this.state.anchor}
                    open={this.state.open}
                    value={cursor.team}
                    items={teams}
                    onChange={team => {
                        this.props.global.select({ team });
                        this.setState({ open: false });
                    }}
                    onClose={() => this.setState({ open: false })}
                />
            </div>
        );
    }
}
