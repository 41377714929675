const colors = {
    0: 'rgb(98,189,112)',
    1: 'rgb(187,210,116)',
    2: 'rgb(239,237,87)',
    3: 'rgb(246,193,66)',
    4: 'rgb(239,126,116)',
};

const thresholds = {
    concerns: [5, 10, 15, 20], // percent
    keyFindings: [1, 2, 3, 4], // count
    redFlags: [0, 1, 2, 3], // count
    totalKeyFindings: [5, 7, 9, 11], // count
    totalRedFlags: [0, 1, 3, 5], // count
};

export default async function(data: Object, services: Object): Promise<Object> {
    const computed = data.computed || {};
    const labels: string[] = [];
    const values: Object[] = [];
    const totals = { total: 0, concern: 0, redFlag: 0, keyFinding: 0 };

    for (const category of Object.keys(computed.mainIssues || {})) {
        const issue = computed.mainIssues[category];
        const total = issue.total || 0;

        if (total <= 0) {
            continue;
        }

        labels.push(issue.label);

        values.push([
            {
                count: issue.concern,
                ratio: issue.concern / total,
                color: makeColor(thresholds.concerns, (100 * issue.concern) / total),
            },
            {
                count: issue.redFlag,
                ratio: issue.redFlag / total,
                color: makeColor(thresholds.redFlags, issue.redFlag),
            },
            {
                count: issue.keyFinding,
                ratio: issue.keyFinding / total,
                color: makeColor(thresholds.keyFindings, issue.keyFinding),
            },
        ]);

        totals.total += total;
        totals.concern += issue.concern;
        totals.redFlag += issue.redFlag;
        totals.keyFinding += issue.keyFinding;
    }

    return {
        issues: {
            labels,
            data: values,
            total: {
                concern: {
                    count: totals.concern,
                    ratio: totals.concern / totals.total,
                    color: makeColor(thresholds.concerns, (100 * totals.concern) / totals.total),
                },
                redFlag: {
                    count: totals.redFlag,
                    ratio: totals.redFlag / totals.total,
                    color: makeColor(thresholds.totalRedFlags, totals.redFlag),
                },
                keyFinding: {
                    count: totals.keyFinding,
                    ratio: totals.keyFinding / totals.total,
                    color: makeColor(thresholds.totalKeyFindings, totals.keyFinding),
                },
            },
        },
    };
}

function makeColor(thresholds: number[], value: number): string {
    if (value === 0) {
        return colors[0];
    }

    for (let index = 0; index < thresholds.length; index ++) {
        if (value <= thresholds[index]) {
            return colors[index + 1];
        }
    }

    return colors[4];
}
