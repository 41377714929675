export default {
    admin: {
        label: 'Admin',
        levels: {
            director: 'Directeur',
            manager: 'Manager',
            member: 'Membre',
            observer: 'Observateur',
        },
        teams: {
            label: 'Equipes',
            list: {
                title: 'Equipes',
                create: 'Nouvelle',
            },
            menu: {
                settings: 'Config',
                members: 'Membres',
                links: 'Liens',
            },
            profile: {
                title: 'Profil',
                noName: '< Aucun nom donné >',
                noDescription: '< Aucune description donnée >',
            },
        },
        services: {
            status: {
                disabled: 'Pas d\'accès',
                cloud: 'Service cloud',
                private: 'Service privé',
            },
            cleerance: {
                title: 'Cleerance',
                admin: 'Admin',
            },
            deeligenz: {
                title: 'Deeligenz',
                analysis: 'Analysis',
                calendar: 'Calendar',
                library: 'Library',
            },
            deefind: {
                title: 'Deefind',
                search: 'Recherche',
            },
            deetect: {
                title: 'Deetect',
            },
            reech: {
                title: 'Reech',
            },
            deel: {
                title: 'Deel',
            },
            teem: {
                title: 'Teem',
            },
        },
    },
};
