import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'app/theme/colors';
import TYPES from '../message/types';

const styles = {
    container: {},
    panel: {
        width: '513px',
        padding: '10px',
        fontSize: '15px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '5px',
        borderColor: Colors.grey300,
        outline: 'none',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'app.views.discussion.thread.composer.editor';

    static propTypes = {
        type: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        onContentChange: PropTypes.func.isRequired,
    };

    render() {
        const style = _.extend({}, styles.panel, { borderColor: TYPES[this.props.type].color });

        return (
            <div style={styles.container}>
                <textarea
                    ref="input"
                    rows={6}
                    style={style}
                    onChange={event => this.props.onContentChange(event.target.value)}
                    value={this.props.content}
                />
            </div>
        );
    }
}
