import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';
import Tabs from 'dna/entity/common/display/tabs';
import t from 'app/translation';
import _ from 'lodash';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.cockpit';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <Tabs
                cursor={this.props.state.tab || 0}
                items={[
                    {
                        icon: 'dna.identity',
                        label: 'Key',
                    },
                    {
                        icon: 'dna.deals',
                        label: 'Global format',
                    },
                    {
                        icon: 'dna.financial.list',
                        label: 'Global ratios',
                    },
                    {
                        icon: 'dna.financial.tree',
                        label: 'Detailed format',

                    },
                    {
                        icon: 'dna.analyze',
                        label: 'Credit analysis',
                    },
                    {
                        icon: 'dna.financial',
                        label: 'Graphs',
                    },
                ]}
                onChange={index => this.context.updateState({ tab: index })}
            />
        );
    }
}
