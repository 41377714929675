import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import defaultsDeep from 'lodash/defaultsDeep';
import Target from './target';
import get from 'lodash/get';
import Country from '../survey/field/widget/scalar/country';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { fund: get(props, 'entity.payload.target.type', 'manager') !== 'manager' };
    }

    handleChange(data) {
        const fund = get(data, 'target.type', 'manager') !== 'manager';

        if (fund !== this.state.fund) {
            this.setState({ fund });
        }
    }

    open(extra) {
        this.refs.dialog.open(extra);
    }

    unlock() {
        this.refs.dialog.unlock();
    }

    render() {
        const payload = defaultsDeep({}, this.props.entity ? this.props.entity.payload || {} : {}, {
            durations: { input: 40, ddq: 10, onSite: 1, report: 10 },
            target: { type: 'manager', variant: 'traditional' },
            managementCompanies: [{ position: 'Management company' }, { position: 'Advisor' }],
            serviceProviders: [
                { position: 'Corporate auditor' },
                { position: 'Custodian' },
                { position: 'Administrator' },
                { position: 'Fund auditor' },
                { position: 'Prime broker' },
                { position: 'Clearer / ISDA' },
            ],
        });

        return (
            <Views.Modal.Edition
                ref="dialog"
                data={payload}
                label="Save due diligence"
                onSubmit={this.props.onSubmit}
                onChange={this.handleChange.bind(this)}
            >
                <Views.Form.Row>
                    <Views.Form.Field label="Type" path="target" widget={<Target />} required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label="Investment manager" path="manager" widget="string" size={2} required />
                    <Views.Form.Field label="Country" path="country" widget={<Country />} size={1} required />
                </Views.Form.Row>

                <Views.Form.Row>
                    {this.state.fund ? (
                        <Views.Form.Field label="Fund name" path="fund" widget="string" required size={2} />
                    ) : (
                        <Views.Form.Value size={2} />
                    )}

                    <Views.Form.Field
                        label="Expected AUM"
                        path="expectedAum"
                        widget={{ type: 'number', decimals: 2, unit: 'M€' }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Management entities"
                        path="managementCompanies"
                        widget={{
                            type: 'collection',
                            fields: { position: { type: 'string', disabled: true }, name: 'string', country: 'string' },
                            flags: ['fixed', 'active', 'labels'],
                        }}
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Service providers"
                        path="serviceProviders"
                        widget={{
                            type: 'collection',
                            fields: { position: { type: 'string', disabled: true }, name: 'string', country: 'string' },
                            flags: ['fixed', 'active', 'labels'],
                        }}
                    />
                </Views.Form.Row>

                <br />

                <Views.Form.Row title="Planning">
                    <Views.Form.Field
                        label="Input"
                        path="durations.input"
                        widget={{ type: 'number', unit: 'days' }}
                        required
                    />
                    <Views.Form.Field
                        label="Analysis"
                        path="durations.ddq"
                        widget={{ type: 'number', unit: 'days' }}
                        required
                    />
                    <Views.Form.Field
                        label="Meeting"
                        path="durations.onSite"
                        widget={{ type: 'number', unit: 'days' }}
                        required
                    />
                    <Views.Form.Field
                        label="Report"
                        path="durations.report"
                        widget={{ type: 'number', unit: 'days' }}
                        required
                    />
                </Views.Form.Row>
                <Views.Form.Row title="Global Preferred List">
                    <Views.Form.Field label="GPL" path="gpl" widget="boolean" />
                </Views.Form.Row>
                <Views.Form.Row title="Due diligence manager">
                    <Views.Form.Field label="Full name" path="oddContact.name" widget="string" required />

                    <Views.Form.Field
                        label="Email address"
                        path="oddContact.email"
                        widget={{ type: 'string', pattern: 'email' }}
                        required
                    />

                    <Views.Form.Field label="Phone number" path="oddContact.phone" widget="string" />
                </Views.Form.Row>

                <Views.Form.Row title="Sales contact">
                    <Views.Form.Field label="Full name" path="salesContact.name" widget="string" />

                    <Views.Form.Field
                        label="Email address"
                        path="salesContact.email"
                        widget={{ type: 'string', pattern: 'email' }}
                    />

                    <Views.Form.Field label="Phone number" path="salesContact.phone" widget="string" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
