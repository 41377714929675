// @flow

import type { AuthTeamProfile } from '@deecision/infra-types/common';

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.settings.profile.edit';

    static propTypes = {
        id: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        global: PropTypes.object.isRequired,
    };

    open() {
        this.refs['dialog'].open();
    }

    render() {
        return (
            <Views.Modal.Edition
                key="dialog"
                ref="dialog"
                label="Save profile"
                style={{ width: '500px' }}
                data={this.props.data.profile}
                onSubmit={async (profile: AuthTeamProfile) => {
                    this.props.global.execute('cleerance', 'team.update', { id: this.props.id, profile });
                }}
            >
                <Views.Form.Row>
                    <Views.Form.Field label="Name" path="name" widget="string" required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label="Description (optional)" path="description" widget="text" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
