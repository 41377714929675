// @flow

import Evaluator from './evaluator';

const evaluator = Evaluator();

export const questions = [
    {
        id: 'ResidenceFlag',
        idxNorkom: 2,
        label: 'Flag Résident /Non Resident', //fr
        intern: false,
        type: 'boolean',
        rules: [(input: string) => evaluator.equal(input.toUpperCase(), 'FR', 'LO'), () => 'HI'],
        person: {
            LO: 0,
            HI: 250,
        },
        company: {
            LO: 0,
            HI: 250,
        },
    },
    {
        id: 'PersonAge',
        idxNorkom: 8,
        label: 'Age de la PP',
        intern: false,
        lot2: false,
        type: 'number',
        rules: [
            (input: number) => evaluator.inferior(input, 18, 'LO'),
            (input: number) => evaluator.superiorOrEqual(input, 80, 'HI'),
            () => 'ML',
        ],
        person: {
            LO: 0,
            ML: 1,
            MH: 10,
            HI: 10,
        },
        company: undefined,
    },
    {
        id: 'OccupationCode', // waiting
        idxNorkom: 10,
        label: 'Profession (sur le code Categorie Socio Prof)',
        type: 'list',
        intern: false,
        lot2: false,
        rules: [() => 'ML'],
        person: {
            LO: 0,
            ML: 1,
            MH: 5,
            HI: 25,
        },
        company: undefined,
    },
    {
        id: 'SensitiveOccupationFlag', // PO a une liste, ou pas... PPE
        idxNorkom: 11,
        label: 'Top Fonction Sensible',
        type: 'boolean',
        intern: false,
        lot2: false,
        rules: [(input: boolean) => evaluator.equal(input, true, 'HI'), () => 'LO'],
        person: {
            LO: 0,
            HI: 1000,
        },
        company: undefined,
    },
    {
        id: 'CompanyAge', // voir dans orbis avec JEF
        idxNorkom: 15,
        label: 'Age de la PM',
        intern: false,
        lot2: false,
        type: 'number',
        rules: [
            (input: number) => evaluator.superiorOrEqual(input, 3, 'LO'),
            (input: number) => evaluator.superiorOrEqual(input, 2, 'ML'),
            (input: number) => evaluator.superiorOrEqual(input, 1, 'MH'),
            (input: number) => evaluator.inferior(input, 1, 'HI'),
        ],
        company: {
            LO: 0,
            ML: 2,
            MH: 5,
            HI: 10,
        },
        person: undefined,
    },
    {
        id: 'CompanyForm', // voir dans orbis avec JEF
        idxNorkom: 16,
        label: 'Forme juridique',
        intern: false,
        lot2: false,
        type: 'list',
        rules: [(input: string) => evaluator.equal(input, 'SA', 'LO')],
        company: {
            LO: 0,
            ML: 1,
            MH: 5,
            HI: 25,
        },
        person: undefined,
    },
    {
        id: 'BusinessSegment1', // voir classeur spé.
        idxNorkom: 17,
        label: "Secteur principal d'activité à risque",
        intern: false,
        lot2: false,
        type: 'list',
        rules: [(input: string[]) => evaluator.codeNaf(input)],
        company: {
            LO: 0,
            ML: 1,
            MH: 500,
            HI: 1000,
        },
        person: undefined,
    },
    {
        id: 'SanctionedFlag', // amf
        idxNorkom: 23,
        label: 'Client cible de sanction',
        intern: false,
        lot2: false,
        type: 'boolean',
        rules: [(input: boolean) => evaluator.equal(input, true, 'HI'), () => 'LO'],
        company: {
            LO: 0,
            HI: 1000,
        },
        person: {
            LO: 0,
            HI: 1000,
        },
    },
    {
        id: 'PEPDomesticFlag', // typo depute senat
        idxNorkom: 25,
        label: 'Client ou Affilié PPE domestique',
        intern: false,
        lot2: false,
        type: 'boolean',
        rules: [(input: boolean) => evaluator.equal(input, true, 'HI'), () => 'LO'],
        company: undefined,
        person: {
            LO: 0,
            HI: 1000,
        },
    },
    {
        id: 'PEPInternationalFlag', // voir wolrd leader
        idxNorkom: 26,
        label: 'Client ou Affilié PPE internationaux',
        intern: false,
        lot2: false,
        type: 'boolean',
        rules: [(input: boolean) => evaluator.equal(input, true, 'HI'), () => 'LO'],
        company: undefined,
        person: {
            LO: 0,
            HI: 1000,
        },
    },
    {
        id: 'TrustFiducie', // forme juridique
        idxNorkom: 27,
        label: 'Trust et Fiducie',
        intern: false,
        lot2: false,
        type: 'boolean',
        rules: [(input: boolean) => evaluator.equal(input, true, 'HI'), () => 'LO'],
        company: undefined,
        person: {
            LO: 0,
            HI: 1000,
        },
    },
    {
        id: 'CountriesOfAddress',
        idxNorkom: 38,
        label: "Pays d'adresse",
        intern: false,
        lot2: false,
        type: 'list',
        rules: [
            (input: string[]) => evaluator.riskBycountriesBestOf(input), // IPAD & IRAD residence et impots
        ],
        company: {
            LO: 0,
            ML: 1,
            MH: 500,
            HI: 1000,
        },
        person: {
            LO: 0,
            ML: 1,
            MH: 500,
            HI: 1000,
        },
    },
    {
        id: 'Nationality',
        idxNorkom: 40,
        label: 'Nationalité',
        intern: false,
        lot2: false,
        type: 'list',
        rules: [(input: string) => evaluator.riskBycountries(input)],
        company: undefined,
        person: {
            LO: 0,
            ML: 2,
            MH: 25,
            HI: 150,
        },
    },
    {
        id: 'CountryOfOrigin',
        idxNorkom: 41,
        label: 'Pays de naissance',
        intern: false,
        lot2: false,
        type: 'list',
        rules: [(input: string) => evaluator.riskBycountries(input)],
        company: undefined,
        person: {
            LO: 0,
            ML: 2,
            MH: 25,
            HI: 150,
        },
    },
    {
        id: 'CountryOfIncorporation',
        idxNorkom: 42,
        label: "Pays d'immatriculation",
        intern: false,
        lot2: false,
        type: 'list',
        rules: [(input: string) => evaluator.riskBycountries(input)],
        person: undefined,
        company: {
            LO: 0,
            ML: 1,
            MH: 500,
            HI: 1000,
        },
    },
];
