// @flow

import type { AuthSettings } from '@deecision/infra-types/common';

import { uniq } from 'lodash';

export default function(input: ?Object): AuthSettings {
    if (!input) {
        return { flags: [] };
    }

    const urls = input.urls || {};
    const flags = input.flags || [];

    // old format
    for (const service of [
        'cleerance',
        'deefind',
        'deetect',
        'deemand',
        'deeligenz',
        'reech',
        'comeet',
        'deel',
        'teem',
    ]) {
        if (!input[service]) continue;
        if (input[service].access) flags.push(`${service}.access`);
        if (input[service].url) urls[service] = input[service].url;
    }

    // flags
    if (input.deefind && input.deefind.search) flags.push('deefind.search');
    if (input.deefind && input.deefind.deetect) flags.push('deetect.access');
    if (input.cleerance && input.cleerance.admin) flags.push('cleerance.admin');
    if (input.deeligenz && input.deeligenz.analysis) flags.push('deeligenz.analysis');
    if (input.deeligenz && input.deeligenz.calendar) flags.push('deeligenz.calendar');
    if (input.deeligenz && input.deeligenz.library) flags.push('deeligenz.library');

    // comeet -> deel
    if (urls.comeet) urls.deel = urls.comeet;
    if (flags.includes('comeet.access')) flags.push('deel.access');

    return { urls, flags: uniq(flags) };
}
