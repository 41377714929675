// @flow

import type { DealAttachment, EntityEstimate } from '@deecision/deefind-types/model';
import sum from 'lodash/sum';
import filter from 'lodash/filter';

export function makeDealsAttachments(relevanceMap: { [deal: string]: number[] }): DealAttachment[] {
    const attachments = [];

    for (const deal of Object.keys(relevanceMap)) {
        const values = relevanceMap[deal] || [];

        if (values.length) {
            attachments.push({ deal, relevance: Math.max(...values.map(value => value || 0)) });
        }
    }

    return attachments;
}

export function makeDealsSum(deals: { [id: string]: Object }, ids: string[]): EntityEstimate {
    if (!ids.length) {
        return { value: 0, reliability: 0 };
    }

    const values = ids.map(id => ((deals[id] && !['rumour', 'rumourexpired'].includes(deals[id].status.kind)) ? deals[id].value || 0 : 0));
    const reliability = values.length ? (100 * values.filter(v => !!v).length) / values.length : undefined;

    return { value: sum(values), reliability };
}
