import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'relative',
        width: '230px',
        height: '125px'
    },
    graph: {
        position: 'absolute',
        top: '0',
        left: '30px',
        height: '100px',
        width: '200px',
        background: 'white',
    },
    years: {
        position: 'absolute',
        bottom: '0',
        left: '30px',
        width: '200px',
        fontSize: '13px',
        color: colors.grey700
    },
    firstYear: {
        float: 'left'
    },
    lastYear: {
        float: 'right'
    },
    scale: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '20px',
        height: '100px',
        fontSize: '13px',
        lineHeight: '15px',
        color: colors.grey700,
    },
    scaleCeil: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
    scaleUnit: {
        position: 'absolute',
        top: '50%',
        right: '0',
        marginTop: '-7px',
    },
    scaleFloor: {
        position: 'absolute',
        right: '0',
        bottom: '0',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.display.tabs.financial.graph';

    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.number,
        years: PropTypes.array.isRequired,
        values: PropTypes.array.isRequired,
        style: PropTypes.object,
    };

    render () {
        const scale = {
            ceil: Math.ceil(_.max(this.props.values) / 1000000),
            floor: Math.floor(_.min(this.props.values) / 1000000)
        };

        if (scale.ceil <= scale.floor) {
            return (
                <div style={ styles.empty }>
                    No change
                </div>
            );
        }

        return (
            <div style={ Object.assign({}, styles.container, this.props.style || {}) }>
                { this.renderContent(scale) }
                { this.renderYears() }
                { this.renderScale(scale) }
            </div>
        );
    }

    renderYears () {
        return (
            <div style={ styles.years }>
                <span style={ styles.firstYear }>
                    { this.props.years[0] }
                </span>

                <span style={ styles.lastYear }>
                    { this.props.years[this.props.years.length - 1] }
                </span>
            </div>
        );
    };

    renderScale (scale) {
        return (
            <div style={ styles.scale }>
                <span style={ styles.scaleCeil }>
                    { scale.ceil }
                </span>

                <span style={ styles.scaleUnit }>
                    M€
                </span>

                <span style={ styles.scaleFloor }>
                    { scale.floor }
                </span>
            </div>
        );
    }

    renderContent (scale) {
        const valuesLines = [];
        const yearsLines = [];

        const yearDelta = 200 / (this.props.values.length - 1);
        const valueDelta = 100 / ((scale.ceil - scale.floor));

        let lastStep;

        for (let index = 0; index < this.props.values.length; index ++) {
            const scaledValue = this.props.values[index] / 1000000;

            const step = {
                x: index * yearDelta,
                y: 100 - (scaledValue - scale.floor) * valueDelta,
            };

            yearsLines.push(
                <line
                    key={ 'yl' + index }
                    x1={ step.x }
                    y1="5"
                    x2={ step.x }
                    y2="105"
                    stroke={ colors.grey200 }
                    strokeWidth={ 1 }
                    fill="none"
                />
            );

            if (! this.props.values[index]) {
                continue;
            }

            if (lastStep) {
                valuesLines.push(
                    <line
                        key={ 'v' + index }
                        x1={ lastStep.x }
                        y1={ lastStep.y + 5 }
                        x2={ step.x }
                        y2={ step.y + 5 }
                        stroke={ colors.userCold }
                        strokeWidth={ 3 }
                        fill="none"
                    />
                );
            }

            lastStep = step;
        }

        return (
            <svg style={ styles.graph } viewBox={ `0 0 200 110` }>
                { yearsLines }
                <line x1="0" y1="5" x2="200" y2="5" stroke={ colors.grey300 } strokeWidth={ 1 } fill="none" />
                <line x1="0" y1="30" x2="200" y2="30" stroke={ colors.grey300 } strokeWidth={ 1 } fill="none" />
                <line x1="0" y1="55" x2="200" y2="55" stroke={ colors.grey300 } strokeWidth={ 1 } fill="none" />
                <line x1="0" y1="80" x2="200" y2="80" stroke={ colors.grey300 } strokeWidth={ 1 } fill="none" />
                <line x1="0" y1="105" x2="200" y2="105" stroke={ colors.grey300 } strokeWidth={ 1 } fill="none" />
                { valuesLines }
            </svg>
        );
    }
}
