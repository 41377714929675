// @flow

import React from 'react';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Row from './row';
import { isRelationMode, makeDirectSorters, makeRelationSorters, makeDirectLabels, makeRelationLabels, makePredictLabels, makeUpsellLabels, makeCovid19Labels, makeAugmentedLabels, makePredictNetworkLabels } from './specs';

const styles = {};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.results.list';

    state = {};

    open(state: Object) {
        this.setState(state, () => this.refs.modal.open());
    }

    render() {
        return (
            <Views.Modal.Show ref="modal" icon={<Icon type="base.attendee" />} title={this.renderTitle()}>
                <Views.List.Container entities={this.state.results}>
                    <Views.List.Reducer type="sort" specs={this.makeSorters()} />

                    <Views.List.Frame
                        type="table"
                        element={<Row mode={this.state.mode || 'segment'} />}
                        specs={{ labels: this.makeLabels() }}
                    />
                </Views.List.Container>
            </Views.Modal.Show>
        );
    }

    makeSorters() {
        return isRelationMode(this.state.mode)
            ? makeRelationSorters(this.state.source, this.state.target)
            : makeDirectSorters(this.state.source);
    }

    makeLabels() {

        if (this.state.mode === 'predict' && this.state.target) {
            return makePredictNetworkLabels();
        }
        if (this.state.mode === 'predict') {
            return makePredictLabels();
        }
        if (this.state.mode === 'upsell') {
            return makeUpsellLabels(this.state.source);
        }
        if (this.state.mode === 'covid19') {
            return makeCovid19Labels(this.state.source);
        }
        if (this.state.mode === 'augmented') {
            return makeAugmentedLabels(this.state.source);
        }

        return isRelationMode(this.state.mode)
            ? makeRelationLabels(this.state.source, this.state.target)
            : makeDirectLabels(this.state.source);
    }

    renderTitle() {
        return 'Segment results';
    }
}
