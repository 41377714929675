// @flow

import { makeHeader as makeCompanyHeader, makeResult as makeCompanyResult } from './company';
import { makeHeader as makePersonHeader, makeResult as makePersonResult } from './person';
import { makeHeader as makeAccountingHeader, makeResult as makeAccountingResult } from './accounting';
import get from 'lodash/get';
import xlsx from 'xlsx';

export default async function(results: Map<string, any>, id: string, reech: Object): Promise<void> {
    const network = results.get(`network:${id}`);
    const companies = [];
    const persons = [];

    for (const relation of get(network, 'relations.companies', [])) {
        const id = relation.target.id;
        const status = relation.ceased ? 'Past' : 'Current';
        companies.push([status].concat(await makeCompanyResult(results, id, reech, false)));
    }

    for (const relation of get(network, 'relations.persons', [])) {
        const id = relation.target.id;
        const status = relation.ceased ? 'Past' : 'Current';
        persons.push([status].concat(await makePersonResult(results, id, reech, false)));
    }

    sendFile(
        makeName(results, id),
        makeFile([
            { name: 'Direct', data: await makeDirect(results, id, reech) },
            { name: 'Companies', data: makeRelations(makeCompanyHeader(false), companies) },
            { name: 'Persons', data: makeRelations(makePersonHeader(false), persons) },
            { name: 'Accounting - key data', data: makeAccounting(results, id, 'keyData') },
            { name: 'Accounting - global', data: makeAccounting(results, id, 'globalFormat') },
            { name: 'Accounting - global ratio', data: makeAccounting(results, id, 'globalRatio') },
        ]),
    );
}

function makeName(results: Map<string, any>, id: string): string {
    return get(results.get(`entity:${id}`), 'preview.name', 'Unknown');
}

function makeAccounting(results: Map<string, any>, id: string, page: string): string {
    switch (get(results.get(`entity:${id}`), 'type')) {
        case 'person': {
            return [];
        }

        case 'company': {
            return  makeAccountingResult(results, id, page);
        }
    }

    return [];
}

async function makeDirect(results: Map<string, any>, id: string, reech: Object): Promise<string[][]> {
    switch (get(results.get(`entity:${id}`), 'type')) {
        case 'person': {
            const row = await makePersonResult(results, id, reech, true);
            return makePersonHeader(true).map((header: string, index: number) => [header, row[index] || '']);
        }

        case 'company': {
            const row = await makeCompanyResult(results, id, reech, true);
            return makeCompanyHeader(true).map((header: string, index: number) => [header, row[index] || '']);
        }
    }

    return [];
}

function makeRelations(header: string[], rows: any[][]): string[][] {
    return [['Status'].concat(header)].concat(rows);
}

function makeFile(sheets: Object[]): ArrayBuffer {
    const book = xlsx.utils.book_new();

    for (const sheet of sheets) {
        xlsx.utils.book_append_sheet(book, xlsx.utils.aoa_to_sheet(sheet.data), sheet.name);
    }

    const content = xlsx.write(book, { type: 'binary', bookType: 'xlsx' });
    const buffer = new ArrayBuffer(content.length);
    const view = new Uint8Array(buffer);

    // https://docs.sheetjs.com/#writing-workbooks
    for (let i = 0; i < content.length; ++i) {
        view[i] = content.charCodeAt(i) & 0xff;
    }

    return buffer;
}

function sendFile(name: string, buffer: ArrayBuffer): void {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const file = `${name}.xlsx`;

    if (window.navigator.msSaveOrOpenBlob) {
        return window.navigator.msSaveBlob(blob, file);
    }

    const link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = file;

    if (document.body) {
        document.body.appendChild(link);
    }

    link.click();

    if (document.body) {
        document.body.removeChild(link);
    }
}
