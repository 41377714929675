// @flow

export function matchAll(match: Function, a?: string[], b?: string[]): number {
    if (!Array.isArray(a) || !a.length || !Array.isArray(b) || !b.length) {
        return 0;
    }

    const invert = b.length < a.length;
    const targets = invert ? a : b;
    const positives = [];
    const negatives = [];

    for (const value of invert ? b : a) {
        let best = 0;
        let worst = 0;

        for (const target of targets) {
            const score = match(value, target);

            if (score > best) {
                best = score;
            }

            if (score < worst) {
                worst = score;
            }
        }

        if (best > 0) {
            positives.push(best);
        } else if (worst < 0) {
            negatives.push(worst);
        }
    }

    return negatives.length ? Math.min(...negatives) : positives.length ? Math.max(...positives) : 0;
}

export function matchBest(match: Function, a?: string[], b?: string[]): number {
    if (!Array.isArray(a) || !a.length || !Array.isArray(b) || !b.length) {
        return 0;
    }

    let best = 0;

    for (const first of a) {
        for (const second of b) {
            const score = match(first, second);

            if (score > best) {
                best = score;
            }
        }
    }

    return best;
}
