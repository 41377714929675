import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Flag from './flag';

let styles = {
    container: {
        border: '1px solid transparent',
        borderBottomWidth: '0',
        padding: '10px',
        background: 'transparent',
        borderRadius: '5px 5px 0 0',
        marginBottom: '-1px',
        cursor: 'pointer',
        marginRight: '5px'
    },
    current: {
        border: '1px solid ' + colors.grey300,
        borderBottomWidth: '0',
        background: 'white',
        cursor: 'default'
    },
    hover: {
        background: colors.grey300,
    },
    relevance: {
        display: 'inline-block',
        marginLeft: '5px',
        color: colors.userCold,
        fontWeight: 'bold'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.common.entry';

    static propTypes = {
        flag: PropTypes.node.isRequired,
        relevance: PropTypes.number.isRequired,
        onOpen: PropTypes.func.isRequired,
    };

    state = { hover: false };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.current
                ? styles.current
                : (this.state.hover ? styles.hover : {})
        );

        return (
            <div
                style={ style }
                onClick={ this.props.onOpen }
                onMouseEnter={ event => this.setState({ hover: true })}
                onMouseLeave={ event => this.setState({ hover: false })}
            >
                { this.props.flag }
                { this.renderRelevance() }
            </div>
        );
    }

    renderRelevance () {
        return (
            <span style={ styles.relevance }>
                { Math.round(this.props.relevance) + ' %' }
            </span>
        );
    }
}
