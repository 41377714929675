import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Graph from './halfPie/graph';
import List from './halfPie/list';
import { extend } from 'lodash';

const styles = {
    container: {
        display: 'flex',
    },
    titleContainer: {
        width: '30px',
        position: 'relative',
    },
    titleContent: {
        color: colors.userCold,
        transform: 'rotate(-90deg)',
        position: 'absolute',
        top: '50%',
        left: '-85px',
        whiteSpace: 'nowrap',
        width: '200px',
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        marginTop: '-10px',
        textTransform: 'uppercase',
        fontSize: '13px',
    },
    graph: {
    },
    list: {
        flexGrow: '1',
    },
};

const SIZE = 200;

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.chart.halfPie';

    static propTypes = {
        entries: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            value: PropTypes.number.isRequired,
            percent: PropTypes.number.isRequired,
        })).isRequired,
        title: PropTypes.string,
        highlighted: PropTypes.string,
        onHighlight: PropTypes.func.isRequired,
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ extend({}, styles.container, this.props.style )}>
                { this.renderTitle() }

                <Graph
                    entries={ this.props.entries }
                    size={ SIZE }
                    height={ this.props.entries.length * 25 }
                    highlighted={ this.props.highlighted }
                    onHighlight={ this.props.onHighlight }
                    style={ styles.graph }
                />
                <List
                    entries={ this.props.entries }
                    size={ SIZE }
                    highlighted={ this.props.highlighted }
                    onHighlight={ this.props.onHighlight }
                    style={ styles.list }
                />
            </div>
        );
    }

    renderTitle () {
        if (! this.props.title) {
            return undefined;
        }

        return (
            <div style={ styles.titleContainer }>
                <span style={ styles.titleContent }>{ this.props.title }</span>
            </div>
        )
    }
}
