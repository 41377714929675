import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        border: '1px solid ' + colors.grey500,
        background: 'white',
        borderRadius: '2px',
        overflow: 'hidden'
    },
    disabled: {
        border: '1px solid ' + colors.grey200,
        background: colors.grey100,
    },
    marker: {
        background: colors.grey300,
        float: 'left'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.header.axis';

    static propTypes = {
        size: PropTypes.number,
        disabled: PropTypes.bool,
        cursor: PropTypes.number
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.disabled ? styles.disabled : {},
            { height: (this.props.size - 2) + 'px' }
        );

        return (
            <div style={ style }>
                { this.renderMarker() }
            </div>
        );
    }

    renderMarker () {
        if (! this.props.cursor || this.props.disabled) {
            return null;
        }

        const style = Object.assign({}, styles.marker, {
            height: (this.props.size - 2) + 'px',
            width: this.props.cursor + '%'
        });

        return (
            <div style={ style }></div>
        );
    }
}
