// @flow

import type { CountsAllocation } from '../../types';

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        width: '90px',
        height: '90px',
        position: 'relative',
    },
    count: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '90px',
        height: '90px',
        lineHeight: '90px',
        color: colors.userCold,
        fontSize: '17px',
        textAlign: 'center',
        fontWeight: 'bold',
    }
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.results.donut';

    static propTypes = {
        count: PropTypes.number.isRequired,
        allocation: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                {this.renderGraph(this.props.allocation)}
                <div style={styles.count}>{this.props.count}</div>
            </div>
        );
    }

    renderGraph(allocation: CountsAllocation) {
        const total = allocation.low + allocation.mid + allocation.high;
        const low = total ? Math.round(100 * allocation.low / total) : 0;
        const mid = total ? Math.round(100 * allocation.mid / total) : 0;
        const high = total ? Math.round(100 * allocation.high / total) : 0;

        return (
            <svg width="90px" height="90px" viewBox="0 0 40 40">
                {this.renderLayer(low, 0, colors.userHot)}
                {this.renderLayer(mid, low, colors.userWarning)}
                {this.renderLayer(high, low + mid, colors.userGreen)}
            </svg>
        );
    }

    renderLayer(value: number, offset: number, color: string) {
        const base = { cx: '20', cy: '20', r: '15.91549430918954', fill: 'transparent' };

        return [
            <circle
                key="back"
                {...base}
                stroke="#FFFFFF"
                strokeWidth="6"
                strokeDasharray={`${value} ${100 - value - 1}`}
                strokeDashoffset={`-${offset - 0.5}`}
            />,
            <circle
                key="front"
                {...base}
                stroke={color}
                strokeWidth="5"
                strokeDasharray={`${value} ${100 - value}`}
                strokeDashoffset={`-${offset}`}
            />,
        ];
    }
}
