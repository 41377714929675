import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import Picker from 'material-ui/TimePicker/TimePickerDialog';
import Button from '../../common/button';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        display: 'flex'
    },
    input: {
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer'
    },
    empty: {
        color: colors.grey500
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.time';

    static propTypes = {
        value: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    state = {
        open: false
    };

    render () {
        const inputStyle = Object.assign({},
            this.props.style,
            styles.input,
            this.props.value ? {} : styles.empty
        );

        return (
            <div style={ styles.container }>
                <input
                    type="text"
                    value={ this.props.value ? this.props.value.substr(0, 5) : 'Click here' }
                    style={ inputStyle }
                    onFocus={ this.props.onFocus }
                    onClick={ event => {
                        this.props.onFocus(event);
                        this.state.open = true;
                        this.refs.picker.show();
                    } }
                    readOnly
                />

                <Picker
                    ref="picker"
                    format="24hr"
                    minutesStep={5}
                    defaultTime={ Moment(this.props.value, 'HH:mm:ss').toDate() }
                    container="dialog"
                    style={ styles.picker }
                    onDismiss={ event => {
                        this.state.open = false;
                    } }
                    onAccept={ date => {
                        this.state.open = false;
                        this.props.onChange(Moment(date).format('HH:mm:ss'));
                    } }
                />

                {this.renderClear()}
            </div>
        );
    }

    renderClear() {
        if (!this.props.value) {
            return null;
        }

        return (
            <Button
                type="flat"
                color="hot"
                icon={<Icon type="do.cancel" />}
                onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.props.onChange(undefined);
                }}
            />
        );
    }
}
