import Moment from 'moment';
import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import type { FileAttachment } from '@deecision/deefind-types/model';
import XlsxTemplate from 'xlsx-template';
import t from 'app/translation';
import fiche from 'app/../../assets/discovery.xlsx'


export default async function(entity: Object, stocks: Object, network: Object): string[][][] {

    const formater = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 });

    const currentDate = Moment()
        .locale('fr')
        .format('L');
    const capital500 = entity.preview.weasse > 500000 ? 'oui' : 'non';

    const occupationObject = find(entity.vigilance.norkom.values, function(o) {
        return o.id === 'OccupationCode';
    });
    const occupation = get(occupationObject, 'answer');

    let topCompanyOwned = network.relations.companies.filter(
        company =>
            company.owned &&
            company.owned.ceased === false &&
            company.target.name === get(entity.preview, 'topCompany.name'),
    );
    topCompanyOwned = (topCompanyOwned.length) ? topCompanyOwned[0] : false;

    let companyActivity = '';
    if (topCompanyOwned) {
        companyActivity = t('common.activity.' + String(topCompanyOwned.target.activity).toUpperCase());
    }

    //sci
    const properties = network.relations.companies.filter(
        company =>
            company.owned &&
            company.owned.ceased === false &&
            company.target.flags.includes('property'),
    ).length;

    const sensitiveFunctions = entity.files.filter((file: FileAttachment) => file.data.type === 'politic').length > 0;

    const prsc = entity.files.filter((file: FileAttachment) => file.data.type === 'mandate').length > 0;


    // block risked country
    let riskedCountryYes = '';
    let riskedCountryNo = '☑';
    const riskedCountries = filter(entity.vigilance.norkom.values, function(o) {
        return ['ResidenceFlag', 'CountriesOfAddress', 'CountryOfOrigin', 'CountryOfIncorporation'].includes(o.id);
    });

    if (riskedCountries.length > 0 && find(riskedCountries, function(o) {
        return o.lvl === 'HI';
    })){
        riskedCountryYes = '☑';
        riskedCountryNo = '';
    }

    const employerName = (!topCompanyOwned)?entity.preview.topCompany.name:'';
    const companyName = (topCompanyOwned)?topCompanyOwned.target.name:'';
    const companyTurnover = (topCompanyOwned)?`${formater.format(topCompanyOwned.target.turnover)} €`:'';
    const companyEmployees = (topCompanyOwned)?topCompanyOwned.target.employees:'';
    const pepYes = (sensitiveFunctions)?'☑':'';
    const pepNo = (sensitiveFunctions)?'':'☑';
    const riskedActivityYes = (sensitiveFunctions || prsc)?'☑':'';
    const riskedActivityNo = (sensitiveFunctions || prsc)?'':'☑';

    const values = {
        entity,
        capital500,
        currentDate,
        occupation,
        topCompanyOwned,
        companyName,
        employerName,
        companyActivity,
        companyTurnover,
        companyEmployees,
        properties,
        pepYes,
        pepNo,
        riskedActivityYes,
        riskedActivityNo,
        riskedCountryYes,
        riskedCountryNo,
    };

    const file = await fetch(fiche);
    const response = await file.arrayBuffer();

    const template = new XlsxTemplate(response);
    const sheetNumbers = [1,2,5];
    for(const page of sheetNumbers) {
        template.substitute(page, values);
    }

    return template.generate({ type: 'uint8array' });
}
