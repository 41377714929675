// @flow

import type { DeetectResult } from '@deecision/deefind-types/client';

import React from 'react';
import get from 'lodash/get';
import Views from 'app/views';
import t from 'app/translation';

export function isRelationMode(mode: string): boolean {
    return ['coopt', 'family'].includes(mode);
}

export function makeDirectSorters(source: 'person' | 'company'): Object {
    return {
        sourcePotential: {
            label: 'Source Potential',
            path: 'source.scores.potential.value',
            type: 'desc',
        },
        sourceRisk: {
            label: 'Source Risk',
            path: 'source.scores.risk',
            type: 'asc',
        },
        sourceCompleteness: {
            label: 'Source Completeness',
            path: 'source.scores.potential.reliability',
            type: 'desc',
        },
    };
}

export function makeRelationSorters(source: 'person' | 'company', target: 'person' | 'company'): Object {
    return Object.assign({}, makeDirectSorters(source), {
        targetPotential: {
            label: 'Relation Potential',
            path: 'target.scores.potential.value',
            type: 'desc',
        },
        targetRisk: {
            label: 'Relation Risk',
            path: 'target.scores.risk',
            type: 'asc',
        },
        relationCompleteness: {
            label: 'Relation Completeness',
            path: 'target.scores.potential.reliability',
            type: 'desc',
        },
    });
}

export function makeDirectLabels(source: 'person' | 'company'): string[] {
    let labels: string[] = [];

    if (source === 'person') {
        labels = labels.concat(['First Name', 'Last Name', 'Birth Date', 'Reech', 'Potential', 'Risk']);
    }

    if (source === 'company') {
        labels = labels.concat(['Name', 'Country', 'Reech', 'Potential', 'Risk']);
    }

    return labels;
}

export function makeUpsellLabels(source: 'person' | 'company'): string[] {
    if (source === 'person') {
        return ['First Name', 'Last Name', 'Birth Date', 'Reech', 'AUM', 'Upsell', 'Upsell %', 'CashOut', 'Potential'];
    }

    if (source === 'company') {
        return ['Name', 'Reech', 'AUM', 'Upsell', 'Upsell %', 'CashOut', 'Potential'];
    }
    return [];
}

export function makeUpsellCells(source: DeetectResult, target: DeetectResult, props: Object): any[] {

    if (source.type === 'person') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.firstName')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.lastName')}
            </td>,
            <td key="3" {...props}>
                {get(source, 'profile.birthDate')}
            </td>,
            <td key="4" {...props}>
                {get(source, 'reech')}
            </td>,
            <td key="5" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.upsell.aum')} />
            </td>,
            <td key="6" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.upsell.upsell')} />
            </td>,
            <td key="7" {...props}>
                <Views.Common.Number type="percent" value={get(source, 'scores.upsell.upsellPrct')} />
            </td>,
            <td key="8" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.upsell.cashout')} />
            </td>,
            <td key="9" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potential.value')} />
            </td>,
        ];
    }
    if (source.type === 'company'){
        let src = (target)?target:source;
        return [
            <td key="1" {...props}>
                {get(src, 'profile.name')}
            </td>,
            <td key="4" {...props}>
                {get(src, 'reech')}
            </td>,
            <td key="5" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(src, 'scores.upsell.aum')} />
            </td>,
            <td key="6" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(src, 'scores.upsell.upsell')} />
            </td>,
            <td key="7" {...props}>
                <Views.Common.Number type="percent" value={get(src, 'scores.upsell.upsellPrct')} />
            </td>,
            <td key="8" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(src, 'scores.upsell.cashout')} />
            </td>,
            <td key="9" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(src, 'scores.potential.value')} />
            </td>,
        ];
    }
    return [];
}

export function makePredictCells(source: DeetectResult, props: Object): any[] {
    return [
        <td key="1" {...props}>
            {get(source, 'profile.firstName')}
        </td>,
        <td key="2" {...props}>
            {get(source, 'profile.lastName')}
        </td>,
        <td key="3" {...props}>
            {get(source, 'profile.birthDate')}
        </td>,
        <td key="4" {...props}>
            <Views.Common.Number type="percent" value={get(source, 'scores.currentPrediction.probability')} />
        </td>,
        <td key="5" {...props}>
            <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.currentPrediction.potentialCashout')} />
        </td>,
        <td key="6" {...props}>
            <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.currentPrediction.expectedValue')} />
        </td>,
    ];
}

export function makeAugmentedLabels(source: 'person' | 'company'): string[] {
    if (source === 'person') {
        return ['Nom', 'Prénom', 'Birth Date', 'Entité', 'Segmentation Initiale', 'AUM (actifs sous gestion)', 'Segmentation Augmentée', 'Potentiel sélectionné'];
    }
    if (source === 'company') {
        return ['Nom', 'Pays', 'Entité', 'Segmentation Initiale', 'AUM (actifs sous gestion)', 'Segmentation Augmentée', 'Potentiel sélectionné'];
    }
    return [];
}

export function makeAugmentedCells(source: DeetectResult, props: Object): any[] {
    if (source.type === 'person') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.firstName')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.lastName')}
            </td>,
            <td key="3" {...props}>
                {get(source, 'profile.birthDate')}
            </td>,
            <td key="4" {...props}>
                {get(source, 'scores.committeeDna')}
            </td>,
            <td key="5" {...props}>
                {get(source, 'scores.segment')}
            </td>,
            <td key="6" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.upsell.aum')} />
            </td>,
            <td key="7" {...props}>
                {get(source, 'scores.segmentDna')}
            </td>,
            <td key="8" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.selectedPotential')} />
            </td>,
        ];
    }

    if (source.type === 'company') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.name')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.country', '').toUpperCase()}
            </td>,
            <td key="4" {...props}>
                {get(source, 'scores.committeeDna')}
            </td>,
            <td key="5" {...props}>
                {get(source, 'scores.segment')}
            </td>,
            <td key="6" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.upsell.aum')} />
            </td>,
            <td key="7" {...props}>
                {get(source, 'scores.segmentDna')}
            </td>,
            <td key="8" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potential.value')} />
            </td>,
        ];
    }

    return [];
}

export function makeCovid19Cells(source: DeetectResult, props: Object): any[] {
    if (source.type === 'person') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.firstName')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.lastName')}
            </td>,
            <td key="3" {...props}>
                {get(source, 'profile.birthDate')}
            </td>,
            <td key="4" {...props}>
                {get(source, 'reech')}
            </td>,
            <td key="5" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potential.value')} />
            </td>,
            <td key="6" {...props}>
                {get(source, 'scores.risk')}
            </td>,
            <td key="7" {...props}>
                <Views.Common.Number type="percent" value={get(source, 'scores.potentialCovid19.ratio')} />
            </td>,
            <td key="8" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potentialCovid19.value')} />
            </td>,
        ];
    }

    if (source.type === 'company') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.name')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.country', '').toUpperCase()}
            </td>,
            <td key="4" {...props}>
                {get(source, 'reech')}
            </td>,
            <td key="5" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potential.value')} />
            </td>,
            <td key="6" {...props}>
                {get(source, 'scores.risk')}
            </td>,
            <td key="7" {...props}>
                {get(source, 'scores.potentialCovid19.ratio')}
            </td>,
            <td key="8" {...props}>
                {t(`common.gics.${get(source, 'profile.gicsCode')}`)}
            </td>,
        ];
    }

    return [];
}

export function makeCovid19Labels(source: 'person' | 'company'): string[] {
    if (source === 'person') {
        return ['First Name', 'Last Name', 'Birth Date', 'Reech', 'Potential', 'Risk', 'c19 pot. %', 'c19 pot.'];
    }
    if (source === 'company') {
        return ['Name', 'Country', 'Reech', 'Potential', 'Risk', 'cv19 impact', 'Activity'];
    }
    return [];
}

export function makePredictLabels(): string[] {
   return ['First Name', 'Last Name', 'Birth Date', 'current year probability', 'current year potential cash out', 'current year excepected cash out'];
}

export function makePredictNetworkLabels(): string[] {
    return ['company', 'First Name', 'Last Name', 'Birth Date', 'current year probability', 'current year potential cash out', 'current year excepected cash out'];
}

export function makeRelationLabels(source: 'person' | 'company', target: 'person' | 'company'): string[] {
    return ['Source', 'Relation'].concat(makeDirectLabels(source));
}

export function makeDirectCells(source: DeetectResult, props: Object): any[] {
    if (source.type === 'person') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.firstName')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.lastName')}
            </td>,
            <td key="3" {...props}>
                {get(source, 'profile.birthDate')}
            </td>,
            <td key="4" {...props}>
                {get(source, 'reech')}
            </td>,
            <td key="5" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potential.value')} />
            </td>,
            <td key="6" {...props}>
                {get(source, 'scores.risk')}
            </td>,
        ];
    }

    if (source.type === 'company') {
        return [
            <td key="1" {...props}>
                {get(source, 'profile.name')}
            </td>,
            <td key="2" {...props}>
                {get(source, 'profile.country', '').toUpperCase()}
            </td>,
            <td key="4" {...props}>
                {get(source, 'reech')}
            </td>,
            <td key="5" {...props}>
                <Views.Common.Number type="currency" scale="M" value={get(source, 'scores.potential.value')} />
            </td>,
            <td key="6" {...props}>
                {get(source, 'scores.risk')}
            </td>,
        ];
    }

    return [];
}

export function makePredictNetworkCells(source: DeetectResult, target: DeetectResult, props: Object): any[] {
    return [
        <td key="source" {...props}>
            {get(source, 'profile.name')}
        </td>,
        <td key="1" {...props}>
            {get(target, 'profile.firstName')}
        </td>,
        <td key="2" {...props}>
            {get(target, 'profile.lastName')}
        </td>,
        <td key="3" {...props}>
            {get(target, 'profile.birthDate')}
        </td>,
        <td key="4" {...props}>
            <Views.Common.Number type="percent" value={get(target, 'scores.currentPrediction.probability')} />
        </td>,
        <td key="5" {...props}>
            <Views.Common.Number type="currency" scale="M" value={get(target, 'scores.currentPrediction.potentialCashout')} />
        </td>,
        <td key="6" {...props}>
            <Views.Common.Number type="currency" scale="M" value={get(target, 'scores.currentPrediction.expectedValue')} />
        </td>,
    ];
}

export function makeRelationCells(source: DeetectResult, target: DeetectResult, props: Object): any[] {
    return [
        <td key="source" {...props}>
            {source.type === 'person'
                ? `${get(source, 'profile.lastName').toUpperCase()} ${get(source, 'profile.firstName')}`
                : get(source, 'profile.name')}
        </td>,
        <td key="relation" {...props}>
            {get(target, 'relation.label')}
        </td>,
    ].concat(makeDirectCells(target, props));
}
