// @flow

import type { ValuationProfile } from '../../../types/utils';
import type { CompanyDeemaze } from '../../../types/model/index';

export default function makeListedValuation(profile: ValuationProfile, market: number): CompanyDeemaze {
    return {
        estimate: {
            value:
                Number(market) +
                Number(profile.minorityInterest || 0) +
                Number(profile.preferredShares || 0) +
                Number(profile.longTermDebt || 0) +
                Number(profile.loans || 0) +
                Number(profile.shortTermDebt || 0) -
                Number(profile.cash || 0),
        },
    };
}
