// @flow
import t from 'app/translation';

export function computeEventCost (event: any) {
    if (! event || !event.payload) {
        return 0;
    }

    return (event.payload.settings.unitPrice || 0)
        * (event.payload.settings.duration || 1)
        * (event.payload.settings.attendees || 1)
        + (event.payload.settings.adjustment || 0);
}

export function computeProspectValues (payload: any) {

    if (! payload || ! payload.target || ! payload.target.fortune || ! payload.settings)
        return { deposit: 0, profit: 0, investment: 0 };

    const deposit = payload.target.fortune * payload.settings.aum / 100;
    const margin = deposit * payload.settings.margin / 100;
    const profit = payload.settings.period * margin * (100 - payload.settings.attrition) / 100;
    const investment = profit * payload.settings.cost / 100;

    return { deposit, profit, investment };
}

export function computeStatusLabel (status: any) {
    switch (status) {
        case 'started': return t('dna.comeet.status.ongoing');
        case 'won': return t('dna.comeet.status.won');
        case 'lost': return t('dna.comeet.status.lost');
        case 'aborted': return t('dna.comeet.status.aborted');
    }

    return t('dna.comeet.status.draft');
}
