import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import CompliancePreview from 'dna/entity/common/compliance/preview';
import Section from 'dna/entity/common/display/section';
import News from 'dna/entity/common/news';

import Investments from './identity/investments';
import Scope from 'app/layout/providers/scope';

const styles = {
    container: {},
    aggregates: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    investment: {
        width: '40%',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.tabs.identity';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    static contextTypes = {
        stateNode: PropTypes.object,
    };

    state = {};

    render() {
        return (
            <div style={styles.container}>
                {this.renderEducations(this.props.entity.data.educations)}
                {this.props.entity.data.biographies.map((item, index) => this.renderBiography(item, index))}

                <CompliancePreview entity={this.props.entity} />

                <div style={styles.aggregates}>
                    <DeefindProvider
                        state={this.props.state}
                        type="network"
                        id={this.props.entity.id}
                        depth={1}
                        renderEntity={(network, state) => (
                            <Investments style={styles.investment} data={network.computed.involvements} />
                        )}
                    />

                    <Scope path="news" type="object">
                        <DeefindProvider
                            type="media"
                            id={this.props.entity.id}
                            renderEntity={(media, state) => (
                                <News
                                    name={this.props.entity.preview.firstName + ' ' + this.props.entity.preview.lastName}
                                    identifier={this.props.entity.id}
                                    news={media.news}
                                    state={state}
                                />
                            )}
                        />
                    </Scope>
                </div>
            </div>
        );
    }

    renderBiography(biography, index) {
        const source = biography.source ? biography.source.name : null;

        return (
            <Section key={index} title={source ? 'Biography — ' + source : 'Biography'}>
                {biography.content}
            </Section>
        );
    }

    renderEducations(educations) {
        if (!educations || !educations.length) {
            return;
        }

        return (
            <Section key={'educations'} title={'Education'}>
                {educations.map((education, index) => (
                    <div key={index}>
                        {education.degree} - {education.college}
                    </div>
                ))}
            </Section>
        );
    }
}
