// @flow

import type {
    VigilanceReportNetwork,
    VigilanceReportDirect,
    VigilanceReportNetworkAggregate,
    VigilanceReportNetworkCounts,
} from '@deecision/deefind-types/model';

import { makeAggregate as makeAggregateScore, makeBlock } from './score';

export default function makeVigilanceNetwork(
    current: VigilanceReportDirect[],
    ceased: VigilanceReportDirect[],
): VigilanceReportNetwork {
    const aggregates = {
        current: makeAggregate(current),
        ceased: makeAggregate(current),
    };

    return {
        current: makeAggregate(current),
        ceased: makeAggregate(current),
        score: makeBlock([aggregates.current.score, aggregates.ceased.score]),
    };
}
function makeAggregate(reports: VigilanceReportDirect[]): VigilanceReportNetworkAggregate {
    const counts = {
        country: makeCounts(reports.map(report => report.country.score)),
        activity: makeCounts(reports.map(report => report.activity.score)),
        mandates: makeCounts(reports.map(report => report.mandates.score)),
        politic: makeCounts(reports.map(report => report.politic.score)),
        justice: makeCounts(reports.map(report => report.justice.score)),
    };

    return {
        country: counts.country,
        activity: counts.activity,
        mandates: counts.mandates,
        politic: counts.politic,
        justice: counts.justice,
        score: makeBlock([
            counts.country.score,
            counts.activity.score,
            counts.mandates.score,
            counts.politic.score,
            counts.justice.score,
        ]),
    };
}

function makeCounts(scores: number[]): VigilanceReportNetworkCounts {
    let warnings = 0;
    let alerts = 0;
    let vetos = 0;

    for (const score of scores) {
        switch (true) {
            case score >= 4:
                vetos += 1;
                break;

            case score >= 3:
                alerts += 1;
                break;

            case score >= 2:
                warnings += 1;
        }
    }

    return {
        warnings,
        alerts,
        vetos,
        total: scores.length,
        score: makeAggregateScore(scores),
    };
}
