// @flow

export default {
    type: 'menu',
    pinned: [
        {
            type: 'summary',
            pinned: true,
        },
    ],
    prototype: {
        type: 'router',
        routes: {
            summary: {
                pinned: { type: 'boolean' },
                list: { type: 'list' },
            },
            display: {
                id: { type: 'string' },
                library: {
                    type: 'object',
                    children: {
                        filter: {
                            type: 'object',
                            prototype: 'string',
                        },
                    },
                }
            },
        },
    },
};
