// @flow

const surveyScope = {
    type: 'object',
    children: {
        filter: {
            type: 'object',
            prototype: 'string',
        },
    },
};

export default {
    type: 'menu',
    pinned: [
        {
            type: 'summary',
            pinned: true,
        },
    ],
    prototype: {
        type: 'router',
        routes: {
            summary: {
                pinned: { type: 'boolean' },
                list: { type: 'list' },
            },
            display: {
                id: { type: 'string' },
                contacts: { type: 'list' },
                tab: { type: 'string' },
                onSiteView: { type: 'string' },
                reportView: { type: 'string' },
                library: surveyScope,
                input: surveyScope,
                ddq: surveyScope,
                onSite: surveyScope,
                report: surveyScope,
            },
        },
    },
};
