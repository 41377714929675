import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Card from './card';
import Row from './row';
import get from 'lodash/get';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.summary.list';

    static propTypes = {
        account: PropTypes.string,
        type: PropTypes.string.isRequired,
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        onCreate: PropTypes.func.isRequired,
        state: PropTypes.object,
    };

    state = {
        toggle_view: 'cards'
    }

    render() {
        return (
            <Views.List.Container entities={this.props.entities} state={this.props.state}>
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        aum: { label: 'Expected AUM', path: entity => entity.payload.expectedAum },
                        manager: { label: 'Company name', path: 'payload.manager' },
                        type: { label: 'Type', path: entity => entity.payload.target.type || 'manager' },
                        status: { label: 'Phase', path: entity => entity.status },
                        globalRiskScore: { label: 'Global Risk Score', path: entity => get(entity, 'computed.scoring.rating', 5) },
                        globalAaisRating: { label: 'AAIS Global Rating', path: entity => Math.max(0, Math.min(5, Math.round(entity.payload.rating || get(entity, 'computed.scoring.rating', 5)))) },
                        globalEsgRating: { label: 'AAIS ESG Rating', path: entity => get(entity.payload, 'header.esg', String.fromCharCode(64 + get(entity, 'computed.scoringEsg.rating', 5))) },
                        owner: { label: 'Author', path: entity => entity.ownerUid },
                        dateOfAnalysis: { label: 'Date of Analysis', path: entity => Moment(get(entity, 'computed.deadline')) },
                        nextReview: { label: 'Next Review', path: entity => Moment(get(entity, 'computed.nextReview.date')) },
                    }}
                />

                <Views.List.Block position="left" >
                    <div>
                        <Views.Common.Toggle
                        key="view"
                        value={this.state.toggle_view}
                        items={[
                            { value: 'cards', icon: 'deeligenz.summary', help: 'View as Cards' },
                            { value: 'table', icon: 'base.list', help: 'View as Table' },
                        ]}
                        onChange={(value) => {this.setState({toggle_view: value})}}
                        />
                    </div>
                </Views.List.Block>
                {this.props.account ? (
                    <Views.List.Reducer
                        type="groups"
                        name="type"
                        specs={{
                            my: { label: 'My', filter: item => item.ownerUid === this.props.account },
                            odd: { label: 'ODD', filter: item => item.type === 'odd' },
                            mdd: { label: 'MDD', filter: item => item.type === 'mdd' },
                        }}
                    />
                ) : null}

                <Views.List.Reducer
                    type="groups"
                    name="status"
                    title="Ongoing"
                    specs={{
                        draft: { label: 'Draft', filter: item => item.status === 'draft' },
                        input: { label: 'Input', filter: item => item.status === 'input' },
                        ddq: { label: 'DDQ', filter: item => item.status === 'ddq' },
                        onSite: { label: 'Meeting', filter: item => item.status === 'onSite' },
                        report: { label: 'Report', filter: item => ['report', 'approved'].includes(item.status) },
                        terminated: { label: 'Done', filter: item => ['confirmed', 'ignoredRedFlag', 'vetoed', 'rejected'].includes(item.status) },
                    }}
                />
                <Views.List.Reducer
                    type="fuse"
                    specs={{
                        paths: ['payload.manager', 'payload.fund'],
                        threshold: 0.1
                    }}
                />

                <Views.List.Frame type={this.state.toggle_view} specs={
                    {labels: [
                        '', 'Name', 'Type', 'Phase',
                        <div style={{textAlign: 'center'}}>Global Risk Score</div>,
                        <div style={{textAlign: 'center'}}>AAIS Global Rating</div>,
                        <div style={{textAlign: 'center'}}>AAIS ESG Rating</div>,
                        'Author',
                        'Date of Analysis', 'Next Review',
                        <div style={{textAlign: 'center'}}>Summary Report</div>,
                        <div style={{textAlign: 'center'}}>Full Report</div>
                    ]}} element={this.state.toggle_view === 'cards' ? <Card /> : <Row />} />

                {this.props.type === 'intervention' ? null : (
                    <Views.List.Block position="right">
                        <Views.Common.Button
                            label="New Due Diligence"
                            icon={<Icon type="do.create" />}
                            onClick={() => this.props.onCreate()}
                        />
                    </Views.List.Block>
                )}
            </Views.List.Container>
        );
    }
}
