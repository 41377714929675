// @flow

import type { MakeFollow, GlobalStatus, SdkService, GlobalCursor, GlobalEvent } from '@deecision/infra-types/client';
import type { GlobalStore } from './types';
import type { IObservableValue } from 'mobx';
import type { AuthCursor, AuthSession, AuthSettingsFlag, AuthTarget } from '@deecision/infra-types/common';

import { observable, action, toJS } from 'mobx';
import utils from '@deecision/cleerance-utils';

export default function(sdk: SdkService, history: Object): GlobalStore {
    const options = { deep: false };
    const status: IObservableValue<GlobalStatus> = observable.box({ task: 'init' }, options);
    const session: IObservableValue<AuthSession> = observable.box({ level: 'anonymous' }, options);
    const cursor: IObservableValue<GlobalCursor> = observable.box({ locale: 'en' }, options);
    const flags: IObservableValue<AuthSettingsFlag[]> = observable.box([], options);
    const lastEmail: IObservableValue<?string> = observable.box(window.localStorage.getItem('last:email'), options);

    sdk.global.listen(
        action((event: GlobalEvent) => {
            if (event.changes.includes('status')) status.set(event.status);
            if (event.changes.includes('session')) session.set(event.session);
            if (event.changes.includes('cursor')) cursor.set(event.cursor);
            if (event.changes.includes('flags')) flags.set(event.flags);

            if (event.changes.includes('cursor')) {
                const cf = flags.get();
                const path = window.location.pathname;
                const move = url => history.push(url);

                switch (true) {
                    case cf.includes('cleerance.admin'):
                        if (!path.startsWith('/cleerance')) move('/cleerance/admin');
                        break;

                    case cf.includes('deeligenz.access'):
                        if (
                            cf.includes('deeligenz.library') &&
                            !cf.includes('deeligenz.analysis') &&
                            !cf.includes('deeligenz.calendar')
                        ) {
                            move('/deeligenz/library');
                        } else if (path.startsWith('/deeligenz')) {
                            break;
                        }

                        if (cf.includes('deeligenz.analysis')) move('/deeligenz/studies');
                        else if (cf.includes('deeligenz.library')) move('/deeligenz/library');
                        else if (cf.includes('deeligenz.calendar')) move('/deeligenz/calendar');
                        break;

                    case cf.includes('deetect.access'):
                        if (!path.startsWith('/dna')) move('/dna/explorer');
                        break;

                    case cf.includes('deefind.access'):
                        if (!path.startsWith('/dna')) move('/dna/explorer');
                        break;

                    case cf.includes('teem.access'):
                        if (!path.startsWith('/teem')) move('/teem/projects');
                        break;
                }
            }
        }),
    );

    sdk.global.connect();

    return {
        status,
        session,
        cursor,
        flags,
        lastEmail,

        execute(service: string, command: string, params?: Object, follow?: MakeFollow): Promise<any> {
            return sdk.execute(service, command, params, follow);
        },

        select(next: AuthCursor): Promise<void> {
            return sdk.global.select(next);
        },

        open(target: AuthTarget): Promise<void> {
            const cs = session.get();
            const cc = cursor.get();

            const user = cs.level === 'anonymous' ? undefined : cs.user;

            if (!user) {
                return Promise.resolve();
            }

            const next = utils.findCursor(user, target);

            if (next.team === cc.team && next.account === cc.account && next.intervention === cc.intervention) {
                return Promise.resolve();
            }

            return sdk.global.select(next);
        },
    };
}
