import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

import Container from './header/container';
import Count from './header/count';
import Groups from './header/groups';
import Sort from './header/sort';
import Fuse from './header/fuse';

const styles = {
    child: {
        flexGrow: '0',
        flexShrink: '0'
    },
    status: {
        marginLeft: '10px',
        fill: colors.userCold
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.header';

    static propTypes = {
        blocks: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
        reducers: PropTypes.arrayOf(PropTypes.object).isRequired,
        total: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
        onReduce: PropTypes.func.isRequired
    };

    render () {
        return (
            <Container>
                <Count
                    value={ this.props.count }
                    total={ this.props.total }
                    style={ styles.child }
                />

                { this.renderBlocks('left') }
                { this.renderReducers() }
                { this.renderBlocks('right') }
            </Container>
        );
    }

    renderBlocks (position) {
        const blocks = this.props.blocks[ position ];

        if (! Array.isArray(blocks)) {
            return null;
        }

        return blocks.map((child, index) => React.cloneElement(child, { key: index }));
    }

    renderReducers () {
        return this.props.reducers.map((reducer, index) => {
            switch (reducer.type) {
                case 'groups': return this.renderGroups('g' + index, reducer);
                case 'sort': return this.renderSort('s' + index, reducer);
                case 'fuse': return this.renderFuse('f' + index, reducer);
            }
        })
        .filter(v => !! v);
    }

    renderGroups (key, reducer) {
        return (
            <Groups
                key={ key }
                title={ reducer.title }
                groups={ reducer.specs }
                value={ reducer.value }
                style={ Object.assign({}, styles.child, reducer.style) }
                onChange={ value => this.props.onReduce(reducer.name, value) }
            />
        )
    }

    renderSort (key, reducer) {
        return (
            <Sort
                key={ key }
                items={ reducer.specs }
                value={ reducer.value }
                style={ Object.assign({}, styles.child, reducer.style) }
                onChange={ value => this.props.onReduce(reducer.name, value) }
            />
        )
    }

    renderFuse (key, reducer) {
        return (
            <Fuse
                key={ key }
                value={ reducer.value }
                style={ Object.assign({}, styles.child, reducer.style) }
                onChange={ value => this.props.onReduce(reducer.name, value) }
            />
        )
    }
}
