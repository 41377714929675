// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Slider from './slider';
import Download from './download';
import Views from 'app/views';
import t from 'app/translation';
import get from 'lodash/get';

const styles = {
    accuracy: {
        margin: '10px 0 15px 0',
    },
    predictType: {
        margin: '20px 0 15px 0',
    },
    upsellmethod: {
        margin: '10px 0px 35px 0',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.filters';

    static propTypes = {
        source: PropTypes.string.isRequired,
        tool: PropTypes.string,
        counts: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        exports: PropTypes.shape({ person: PropTypes.array, company: PropTypes.array }),
        enabled: PropTypes.arrayOf(PropTypes.string).isRequired,
        onScores: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    render() {
        return (
            <div style={this.props.style}>
                {this.renderEnabled()}
                <Download tool={this.props.tool}  listedRelations={this.props.exports.listedRelations||[]} predict={this.props.exports.predict||[]} persons={this.props.exports.person} companies={this.props.exports.company} />
            </div>
        );
    }

    renderEnabled() {
        const bits = [];

        if (this.props.source === 'company' && this.props.enabled.includes('potentialCovid19')) {
            bits.push(
                <Views.Common.Toggle
                    key="covidCompany"
                    value={this.props.filters.potentialCovid19}
                    style={styles.upsellmethod}
                    title={t('dna.deetect.common.label.impactedCovid19')}
                    tooltip={''}
                    items={[
                        {
                            value: 1,
                            label: 'Yes',
                            help: '',
                        },
                        {
                            value: 0,
                            label: 'No',
                            help: '',
                        }
                    ]}
                    onChange={value => {
                        this.props.onScores(Object.assign({}, this.props.filters, { potentialCovid19: value }));
                        this.props.onSave(Object.assign({}, this.props.filters, { potentialCovid19: value }));
                    }}
                />,
            );
        }

        if (this.props.enabled.includes('upsell')) {
            bits.push(
                <Views.Common.Toggle
                    key="upsellMethod"
                    value={this.props.filters.upsellMethod}
                    style={styles.upsellmethod}
                    title={t('dna.deetect.common.label.upsellSource')}
                    tooltip={''}
                    items={[
                        {
                            value: 'cashout',
                            label: t('dna.deetect.common.label.cashout'),
                            help: '',
                        },
                        {
                            value: 'potential',
                            label: t('dna.deetect.common.label.potential'),
                            help: '',
                        }
                    ]}
                    onChange={value => {
                        this.props.onScores(Object.assign({}, this.props.filters, { upsellMethod: value }));
                        this.props.onSave(Object.assign({}, this.props.filters, { upsellMethod: value }));
                    }}
                />,
            );
        }

        if (this.props.source === 'person' && this.props.enabled.includes('accuracy') || get(this.props, 'filters.upsellMethod') === 'potential') {
            bits.push(
                <Views.Common.Toggle
                    key="status"
                    value={this.props.filters.accuracy}
                    style={styles.accuracy}
                    title={t('dna.deetect.common.label.pal')}
                    tooltip={t('dna.deetect.common.tooltip.potAccuracyLevel')}
                    items={[
                        {
                            value: 'low',
                            label: 'Low',
                            count: this.props.counts.potentialLow,
                            help: 'Based on Σ Turnovers',
                        },
                        {
                            value: 'mid',
                            label: 'Mid',
                            count: this.props.counts.potentialMid,
                            help: 'Based on WeasseMax',
                        },
                        {
                            value: 'high',
                            label: 'High',
                            count: this.props.counts.potentialHigh,
                            help: 'Based on Weasse',
                        },
                    ]}
                    onChange={value => {
                        this.props.onScores(Object.assign({}, this.props.filters, { accuracy: value }));
                        this.props.onSave(Object.assign({}, this.props.filters, { accuracy: value }));
                    }}
                />,
            );
        }

        if (this.props.enabled.includes('years')) {
            bits.push(
                <Views.Common.Toggle
                    key="status"
                    value={this.props.filters.currentHorizon}
                    style={styles.accuracy}
                    title={t('dna.deetect.common.label.horizon')}
                    items={[
                        {
                            value: 1,
                            label: '1',
                            help: '',
                        },
                        {
                            value: 2,
                            label: '2',
                            help: '',
                        },
                        {
                            value: 3,
                            label: '3',
                            help: '',
                        },
                        {
                            value: 4,
                            label: '4',
                            help: '',
                        },
                        {
                            value: 5,
                            label: '5',
                            help: '',
                        },
                    ]}
                    onChange={value => {
                        this.props.onScores(Object.assign({}, this.props.filters, { currentHorizon: value }));
                        this.props.onSave(Object.assign({}, this.props.filters, { currentHorizon: value }));
                    }}
                />,
            );
        }

        if (this.props.enabled.includes('upsellPrct')) {
            bits.push(
                this.renderFilter('upsellPrct', {
                    label: t('dna.deetect.common.label.upsellPrct'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.upsellPrct,
                    minCount: this.props.counts.upsellPrctExcluded,
                    maxCount: this.props.counts.upsellPrctSelected,
                    unit: '%',
                }),
            );
        }

        if (this.props.enabled.includes('upsell')) {
            bits.push(
                this.renderFilter('upsell', {
                    label: t('dna.deetect.common.label.upsell'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.upsell,
                    minCount: this.props.counts.upsellExcluded,
                    maxCount: this.props.counts.upsellSelected,
                    unit: 'M€',
                    logScale: true,
                }),
            );
        }

        if (this.props.enabled.includes('potential')) {
            bits.push(
                this.renderFilter('potential', {
                    label: t('dna.deetect.common.label.potential'),
                    tooltip: t('dna.deetect.common.tooltip.potential'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.potential,
                    minCount: this.props.counts.potentialExcluded,
                    maxCount: this.props.counts.potentialSelected,
                    unit: 'M€',
                    logScale: true,
                }),
            );
        }

        if (this.props.enabled.includes('risk')) {
            bits.push(
                this.renderFilter('risk', {
                    label: t('dna.deetect.common.label.risk'),
                    tooltip: t('dna.deetect.common.tooltip.risk'),
                    minValue: 4,
                    maxValue: 1,
                    value: this.props.filters.risk,
                    minCount: this.props.counts.riskExcluded,
                    maxCount: this.props.counts.riskSelected,
                }),
            );
        }

        if (this.props.enabled.includes('proximity')) {
            bits.push(
                this.renderFilter('proximity', {
                    label: t('dna.deetect.common.label.proximity'),
                    tooltip: t('dna.deetect.common.tooltip.proximity'),
                    minValue: 0,
                    maxValue: 12,
                    value: this.props.filters.proximity,
                    minCount: this.props.counts.proximityExcluded,
                    maxCount: this.props.counts.proximitySelected,
                }),
            );
        }

        if (this.props.enabled.includes('completeness')) {
            bits.push(
                this.renderFilter('completeness', {
                    label: t('dna.deetect.common.label.completeness'),
                    tooltip: t('dna.deetect.common.tooltip.completeness'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.completeness,
                    minCount: this.props.counts.completenessExcluded,
                    maxCount: this.props.counts.completenessSelected,
                    unit: '%',
                }),
            );
        }

        if (this.props.source === 'person' && this.props.enabled.includes('potentialCovid19')) {
            bits.push(
                this.renderFilter('potentialCovid19', {
                    label: t('dna.deetect.common.label.potentialCovid19'),
                    tooltip: t('dna.deetect.common.tooltip.potentialCovid19'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.potentialCovid19,
                    minCount: this.props.counts.potentialCovid19Excluded,
                    maxCount: this.props.counts.potentialCovid19Selected,
                    unit: '%',
                }),
            );
        }

        if (this.props.enabled.includes('predictionType')) {
            bits.push(
                <Views.Common.Toggle
                    key="status"
                    value={this.props.filters.predictionType}
                    style={styles.predictType}
                    title={t('dna.deetect.common.label.predictionType')}
                    tooltip={t('dna.deetect.common.tooltip.predictionType')}
                    items={[
                        {
                            value: 'all',
                            label: 'All',
                            count: this.props.counts.predictions,
                            help: 'All predictions types',
                        },
                        {
                            value: 'age',
                            label: 'Age',
                            count: this.props.counts.predictionsAge,
                            help: 'Based on age prediction',
                        },
                        {
                            value: 'vc',
                            label: 'VC',
                            count: this.props.counts.predictionsVc,
                            help: 'Based on vc prediction',
                        },
                    ]}
                    onChange={value => {
                        this.props.onScores(Object.assign({}, this.props.filters, { predictionType: value }));
                        this.props.onSave(Object.assign({}, this.props.filters, { predictionType: value }));
                    }}
                />
            );
        }

        if (this.props.enabled.includes('probability')) {
            bits.push(
                this.renderFilter('probability', {
                    label: t('dna.deetect.common.label.probability'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.probability,
                    minCount: this.props.counts.probabilityExcluded,
                    maxCount: this.props.counts.probabilitySelected,
                    unit: '%',
                }),
            );
        }

        if (this.props.enabled.includes('potentialCashout')) {
            bits.push(
                this.renderFilter('potentialCashout', {
                    label: t('dna.deetect.common.label.potentialCashout'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.potentialCashout,
                    minCount: this.props.counts.potentialCashoutExcluded,
                    maxCount: this.props.counts.potentialCashoutSelected,
                    unit: 'M€',
                    logScale: true,
                }),
            );
        }

        if (this.props.source == 'person' && this.props.enabled.includes('expectedValue')) {
            bits.push(
                this.renderFilter('expectedValue', {
                    label: t('dna.deetect.common.label.cashoutExpectancy'),
                    minValue: 0,
                    maxValue: 100,
                    value: this.props.filters.expectedValue,
                    minCount: this.props.counts.expectedValueExcluded,
                    maxCount: this.props.counts.expectedValueSelected,
                    unit: 'M€',
                    logScale: true,
                }),
            );
        }

        return bits;
    }

    renderFilter(key: string, props: Object) {
        return (
            <Slider
                key={key}
                {...props}
                value={this.props.filters[key] || 0}
                onChange={(value: number) =>
                    this.props.onScores(Object.assign({}, this.props.filters, { [key]: value }))
                }
                onSet={(value: number) => this.props.onSave(Object.assign({}, this.props.filters, { [key]: value }))}
            />
        );
    }
}
