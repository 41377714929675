// @flow

import type { LinkBase } from '@deecision/deefind-types/model';

export default function makeLinksBase(links: (LinkBase & Object)[]): LinkBase {
    const result = { start: undefined, end: undefined, ceased: true, guessed: true };

    for (const link of links) {
        if (link.start && (!result.start || new Date(link.start) < new Date(result.start))) {
            result.start = link.start;
        }

        if (link.end && (!result.end || new Date(link.end) > new Date(result.end))) {
            result.end = link.end;
        }

        if (!link.ceased) {
            result.ceased = false;
        }

        if (!link.guessed) {
            result.guessed = false;
        }
    }

    return result;
}
