// @flow

import type { ApplicationEntry } from './types';

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Scope from 'app/layout/providers/scope';
import Header from './header/index';
import Forbidden from './forbidden';

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.application';

    props: {
        help: string,
        path: string,
        title: [string, string],
        index: string,
        entries: ApplicationEntry[]
    };

    render () {
        return (
            <div>
                <Scope path={ this.props.path } type="object">
                    <Header
                        help={this.props.help}
                        path={ this.props.path }
                        title={ this.props.title }
                        entries={ this.props.entries }
                    />
                </Scope>

                <Scope path={ this.props.path } type="object">
                    <Switch>
                        { this.renderRoutes() }

                        <Redirect
                            from={ `/${this.props.path}` }
                            to={ `/${this.props.path}/${this.props.index}` }
                        />
                    </Switch>
                </Scope>
            </div>
        );
    }

    renderRoutes () {
        const routes = [];

        for (const entry of this.props.entries) {
            if (! entry.path || ! entry.screen) {
                continue;
            }

            routes.push(
                <Route
                    key={ entry.path }
                    path={ `/${this.props.path}/${entry.path}` }
                    component={ entry.access ? entry.screen : Forbidden }
                />
            );
        }

        return routes;
    }
}
