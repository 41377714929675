import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Preview from '../../common/preview';
import Flag from '../../common/flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.typologies.fr.opendata.deputies.preview';

    static propTypes = {
        data: PropTypes.object.isRequired,
        relevance: PropTypes.number.isRequired,
        onOpen: PropTypes.func.isRequired,
    };

    render () {
        return (
            <Preview
                flag={ <Flag char="P" shape="square" color="cold" type="full" /> }
                title={t('dna.deefind.typology.deputiesTitle')}
                relevance={ this.props.relevance }
                onOpen={ this.props.onOpen }

                label1={t('dna.deefind.typology.deputiesPresence')}
                value1={ `${_.sum(_.map(this.props.data.mandates, mandate => parseInt(mandate.presenceCount)))} (${this.props.data.mandates.length} mandates)` }

                label2={t('common.label.job')}
                value2={ this.props.data.job }
            />
        );
    }
}
