import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import EventDisplay from 'comeet/common/events/display';
import MilestoneDisplay from 'comeet/common/milestones/display';
import EventForm from 'comeet/common/events/form';
import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject(stores => ({ store: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.prospects.display.timeline';

    static contextTypes = {
        startProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        updateEvent: PropTypes.func.isRequired,
        updateMilestone: PropTypes.func.isRequired,
        removeEvent: PropTypes.func.isRequired,
        removeMilestone: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        events: PropTypes.array.isRequired,
        milestones: PropTypes.array.isRequired,
    };

    findLastDate() {
      const period = this.props.entity.payload.settings.period;

      const date = Moment(this.props.entity.createStamp).add(period, 'month');

      let EDate = null;
      for (const event of this.props.events) {
        const tmpDate = Moment(event.payload.date);
        if (!EDate || tmpDate > EDate) {
          EDate = tmpDate;
        }
      }

      let MDate = null;
      for (const milestone of this.props.milestones) {
        const tmpDate = Moment(milestone.payload.date);
        if (!MDate || tmpDate > MDate) {
          MDate = tmpDate;
        }
      }

      if (EDate > date && EDate > MDate) {
        return EDate
      }

      if (MDate > date) {
        return MDate
      }

      return date;
    }

    render () {
        if (this.props.entity.status === 'draft') {
            return this.renderDraft();
        }

        if (! this.props.events.length) {
            return this.renderEmpty();
        }

        return (
            <Views.Timeline.Display
                start={ this.props.entity.createStamp }
                end={ this.findLastDate() }
            >
                <Views.Timeline.Rule unit="month" />

                { this.props.events.map(this.renderEvent.bind(this)) }
                { this.props.milestones.map(this.renderMilestone.bind(this)) }
            </Views.Timeline.Display>
        );
    }

    renderEvent (event) {
        return (
            <Views.Timeline.Event
                key={ event.id }
                id={ event.id }
                date={ event.payload.date }
                dimmed={ Moment(event.payload.date) < Moment().add(1, 'day') }
            >
                <EventDisplay
                    entity={ event }
                    prospect={ this.props.entity }
                    onUpdate={ data => this.context.updateEvent(event.id, data) }
                    onRemove={ data => this.context.removeEvent(this.props.entity.id, event.id) }
                />
            </Views.Timeline.Event>
        );
    }

    renderMilestone (milestone) {
        return (
            <Views.Timeline.Event
                key={ milestone.id }
                id={ milestone.id }
                date={ milestone.payload.date }
                dimmed={ Moment(milestone.payload.date) < Moment().add(1, 'day') }
            >
                <MilestoneDisplay
                    entity={ milestone }
                    prospect={ this.props.entity }
                    onUpdate={ data => this.context.updateMilestone(milestone.id, data) }
                    onRemove={ data => this.context.removeMilestone(this.props.entity.id, milestone.id) }
                />
            </Views.Timeline.Event>
        );
    }

    renderEmpty () {
        if (this.props.entity.status !== 'started') {
            return (
                <Views.Common.Empty
                    title={t('dna.comeet.noEvent')}
                />
            );
        }

        return (
            <div>
                <Views.Common.Empty
                    title={t('dna.comeet.noEvent')}
                    actions={[
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            icon={ <Icon type="do.create" /> }
                            label={t('dna.comeet.addEvent')}
                            onClick={ event => this.refs.eventCreator.open() }
                        />
                    ]}
                />

                <EventForm
                    ref="eventCreator"
                    onSubmit={ data => this.context.createEvent(this.props.entity.id, data) }
                />
            </div>
        );
    }

    renderDraft () {
        return (
            <Views.Common.Empty
                title={t('dna.comeet.noStartedProspecting')}
                actions={[
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        icon={ <Icon type="do.start" /> }
                        label={t('common.label.start')}
                        onClick={ event => this.context.startProspect(this.props.entity.id) }
                    />
                ]}
            />
        );
    }
}
