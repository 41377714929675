import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';

import Header from './header';
import Download from './download';
import { inject, observer } from 'mobx-react';

import PropertiesBlock from './blocks/properties';
import TextsBlock from './blocks/texts';
import TextBlock from './blocks/text';
import ScoringBlock from './blocks/scoring';
import IssuesBlock from './blocks/issues';
import FindingsBlock from './blocks/findings';
import FiguresBlock from './blocks/figures';

const styles = {
    container: {
        border: '10px solid ' + colors.grey300,
        width: '1200px',
        margin: '0 auto',
    },
    row: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        flexGrow: '1',
        flexBasis: '0',
        flexShrink: '1',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        flexGrow: '1',
        flexBasis: '0',
        flexShrink: '1',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report';

    static propTypes = {
        readOnly: PropTypes.bool,
        entity: PropTypes.object.isRequired,
        onOpenFields: PropTypes.func,
        onSetParagraph: PropTypes.func,
        onSetHeader: PropTypes.func,
        onSetRating: PropTypes.func,
    };

    state = { data: null };

    loadData() {
        this.setState({ data: null }, async () => {
            this.setState({ data: await this.props.store.fillReport(this.props.entity.id) });
        });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(props: Object) {
        if (this.props.entity.id !== props.entity.id) {
            this.loadData();
        }
    }

    render() {
        if (!this.state.data) {
            return <Views.Common.Loader title="Loading report" />;
        }

        return (
            <div>
                <div style={{ width: '1315px', textAlign: 'right', paddingBottom: '10px' }}>
                    <div style={{ display: 'inline-block', padding: '5px' }}>
                        <Download id={this.props.entity.id} reportType='summary' reportName='summary report'/>
                        { (this.props.entity.type === 'odd') &&
                            <>
                                <span>&nbsp;</span>
                                <Download id={this.props.entity.id} reportType='full' reportName='full report'/>
                            </>
                        }
                    </div>
                </div>

                <div id="report" style={styles.container}>
                    <Header
                        entity={this.props.entity}
                        onSetHeader={this.props.onSetHeader}
                        onSetRating={this.props.onSetRating}
                    />

                    {this.state.data.isMdd ? this.renderMDD() : this.renderODD()}
                </div>
            </div>
        );
    }

    renderODD() {
        const frozen = ['approved', 'confirmed', 'ignoredRedFlag'].includes(this.props.entity.status);

        const onSave = frozen
            ? undefined
            : async data => {
                  const header = { ...this.props.entity.payload.header, ...data };
                  await this.props.store.updateStudy(this.props.entity.id, { ...this.props.entity.payload, header });
                  this.setState({ data: await this.props.store.fillReport(this.props.entity.id) });
              };

        const onUpload = frozen
            ? undefined
            : async (path, data) => {
                  return this.props.store.uploadFileReport(this.props.entity.id, path, data);
              };

        let miscellaneous = [];
        let characteristics = [
            { label: 'Investment manager', path: 'investmentManager' },
            { label: 'Legal structure', path: 'legalStructure' },
            { label: 'Corporate auditor', path: 'corporateAuditor' },
        ];

        if (this.state.data.isFund) {
            characteristics = characteristics.concat([
                { label: 'Investment advisor', path: 'investmentAdvisor' },
                { label: 'Fund name', path: 'fundName' },
                { label: 'Registration', path: 'registration' },
                { label: 'Type', path: 'type' },
                { label: 'Morningstar Category', path: 'morningstarCategory' },
                { label: 'Fund AUM', path: 'fundAum' },
                { label: 'Management company', path: 'managementCompany' },
                { label: 'Custodian', path: 'custodian' },
                { label: 'Administrator', path: 'administrator' },
                { label: 'Fund Auditor', path: 'fundAuditor' },
            ]);

            miscellaneous = miscellaneous.concat([
                { label: 'Type of investors', path: 'typeInvestors', widget: 'text' },
                {
                    label: "Brief introduction to the fund's strategy",
                    path: 'introductionFundStrategy',
                    widget: 'text',
                },
                { label: 'United Nations principle for responsible investment', path: 'unpri', widget: 'text' },
                { label: 'Operational framework', path: 'operationalFramework', widget: 'text' },
            ]);
        } else {
            characteristics = characteristics.concat([
                { label: 'Total AUM', path: 'totalAum' },
                { label: 'Domicile', path: 'domicile' },
            ]);

            miscellaneous = miscellaneous.concat([
                { label: 'Type of investors', path: 'typeInvestors', widget: 'text' },
                { label: 'Main activity of the investment manager', path: 'strategyInvestmentManager', widget: 'text' },
                { label: 'United Nations principle for responsible investment', path: 'unpri', widget: 'text' },
                { label: 'Operational framework', path: 'operationalFramework', widget: 'text' },
                { label: 'Future plans', path: 'futurePlans', widget: 'text' },
            ]);
        }

        return (
            <div style={styles.main}>
                <div style={styles.row}>
                    <PropertiesBlock
                        title="Investment manager characteristics"
                        items={characteristics}
                        data={this.state.data}
                        onSave={onSave}
                    />

                    <PropertiesBlock
                        title="Operational due diligence"
                        items={[
                            { label: 'Analyst', path: 'analyst' },
                            { label: 'Validation date', path: 'validationDate' },
                            { label: 'Final validation', path: 'finalValidation' },
                            { label: 'On-site visit date', path: 'onSiteVisitDate' },
                            { label: 'Next review', path: 'nextReview' },
                        ]}
                        data={this.state.data}
                        onSave={onSave}
                    />
                </div>

                <TextBlock title="Assessment" path="assessment" data={this.state.data} onSave={onSave} />

                <div style={styles.row}>
                    <ScoringBlock title="Risk radar" data={this.state.data.scoring} />
                    <IssuesBlock title="Main issues" data={this.state.data.issues} />
                </div>

                <FindingsBlock title="Key findings" data={this.state.data.findings} />

                <TextBlock
                    title="Global presentation of the investment manager"
                    path="globalPresentationInvestmentManager"
                    data={this.state.data}
                    onSave={onSave}
                />

                <div style={styles.row}>
                    <TextBlock
                        title="Historical milestones"
                        path="historicalMilestonesText"
                        picture="historicalMilestonesPicture"
                        data={this.state.data}
                        onSave={onSave}
                        onUpload={onUpload}
                    />

                    <TextBlock
                        title="Shareholder structure"
                        path="shareholdingStructureText"
                        picture="shareholdingStructurePicture"
                        data={this.state.data}
                        onSave={onSave}
                        onUpload={onUpload}
                    />
                </div>

                <FiguresBlock title="Key figures" data={this.state.data.figures} />
                <PropertiesBlock title="Miscellaneous" items={miscellaneous} data={this.state.data} onSave={onSave} />
            </div>
        );
    }

    renderMDD() {
        const frozen = ['approved', 'confirmed', 'ignoredRedFlag'].includes(this.props.entity.status);

        const onSave = frozen
            ? undefined
            : async data => {
                  const header = { ...(this.props.entity.payload.header || {}), ...data };
                  await this.props.store.updateStudy(this.props.entity.id, { ...this.props.entity.payload, header });
                  this.setState({ data: await this.props.store.fillReport(this.props.entity.id) });
              };

        return (
            <div style={styles.main}>
                <div style={styles.row}>
                    <div style={styles.column}>
                        <PropertiesBlock
                            title="Due diligence"
                            items={[
                                { label: 'Analyst', path: 'analyst' },
                                { label: 'Validation date', path: 'validationDate' },
                                { label: 'Final validation', path: 'finalValidation' },
                                { label: 'On-site visit date', path: 'onSiteVisitDate' },
                                { label: 'Next review', path: 'nextReview' },
                            ]}
                            data={this.state.data}
                            onSave={onSave}
                        />

                        <PropertiesBlock
                            title="Identity card"
                            items={[
                                { label: 'Asset class', path: 'assetClass' },
                                { label: 'Geographical area', path: 'geographicalArea' },
                                { label: 'Market capitalization', path: 'marketCapitalization' },
                                { label: 'Style', path: 'strategyStyle' },
                                { label: 'Investment universe', path: 'investmentUniverse' },
                                { label: 'Portfolio concentration', path: 'portfolioConcentration' },
                                { label: 'Financial objective', path: 'financialObjective' },
                                { label: 'Structural bias', path: 'structuralBias' },
                                { label: 'ESG approach', path: 'esgApproach' },
                                { label: 'ESG criteria', path: 'esgCriteria' },
                                { label: 'ESG objective', path: 'esgObjective' },
                                { label: 'Investment manager', path: 'investmentManager' },
                                { label: 'Strategy creation date', path: 'strategyCreation' },
                                { label: 'Base currency', path: 'baseCurrency' },
                                { label: "Strategy's AUM (date)", path: 'strategyAum' },
                                { label: "Fund's AUM (date)", path: 'fundAum' },
                                { label: 'Official benchmark', path: 'officialBenchmark' },
                                { label: 'Appropriate benchmark according to analyst', path: 'appropriateBenchmark' },
                            ]}
                            data={this.state.data}
                            onSave={onSave}
                        />
                    </div>

                    <TextsBlock
                        title="Executive summary"
                        items={[
                            { label: 'Philosophy', path: 'summaryPhilosophy' },
                            { label: 'Performance', path: 'summaryPerformance' },
                            { label: 'Portfolio', path: 'summaryPortfolio' },
                            { label: 'Process', path: 'summaryProcess' },
                            { label: 'People', path: 'summaryPeople' },
                        ]}
                        data={this.state.data}
                        onSave={onSave}
                    />
                </div>

                <TextBlock title="Conclusion" path="conclusion" data={this.state.data} onSave={onSave} />

                <div style={styles.row}>
                    <ScoringBlock title="Risk radar" data={this.state.data.scoring} />
                    <IssuesBlock title="Main issues" data={this.state.data.issues} />
                </div>

                <div style={styles.row}>
                    <TextBlock title="Pros" path="pros" data={this.state.data} onSave={onSave} />
                    <TextBlock title="Cons" path="cons" data={this.state.data} onSave={onSave} />
                </div>

                <TextBlock title="Process" path="process" data={this.state.data} onSave={onSave} />
                <FindingsBlock title="Key findings" data={this.state.data.findings} />
            </div>
        );
    }
}
