// @flow

import colors from 'app/theme/colors';

export default {
    container: {
        base: {
            maxWidth: '250px',
            minWidth: '100px',
            width: 'auto',
            flexGrow: '1',
            marginTop: '-1px',
            marginRight: '1px',
            padding: '10px 15px',
            lineHeight: '25px',
            height: '25px',
            border: '1px solid transparent',
            display: 'flex',
            borderRadius: '0 0 5px 5px',
            borderWidth: '2px 1px',
            borderTopColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            cursor: 'pointer'
        },
        hover: {
            background: colors.grey200,
            borderTopColor: 'rgba(0, 0, 0, 0.15)',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
        },
        current: {
            background: colors.grey100,
            borderTopColor: colors.grey100,
            borderBottomColor: colors.userHot,
            borderLeftColor: colors.grey400,
            borderRightColor: colors.grey400,
            cursor: 'default'
        },
        disabled: {
        }
    },
    icon: {
        base: {
            width: '25px',
            height: '25px',
            fill: colors.grey300
        },
        hover: {
            fill: colors.grey500
        },
        current: {
            fill: colors.grey500
        },
        disabled: {
            fill: colors.grey300
        }
    },
    label: {
        base: {
            display: 'inline-block',
            margin: '0 10px',
            color: colors.grey500,
            fontSize: '15px',
            textOverflow: 'ellipsis',
            flexGrow: '1',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        hover: {
            color: colors.grey600
        },
        current: {
            color: colors.userLogo
        },
        disabled: {
            color: colors.grey300
        }
    },
    count: {
        base: {

        },
        hover: {

        },
        current: {

        },
        disabled: {

        }
    },
    close: {
        base: {
            display: 'inline-block',
            width: '15px',
            textAlign: 'center',
            fontSize: '13px',
            fontWeight: 'bold',
            color: colors.userHot,
            cursor: 'pointer',
            borderRadius: '3px',
            opacity: '0.5'
        },
        hover: {
            opacity: '1'
        },
        current: {
            opacity: '1'
        },
        disabled: {
            color: colors.grey300
        }
    }
};
