// @flow

import React from 'react';
import Views from 'app/views';

import Kind from './form/kind';
import Criteria from './form/criteria';
import Actions from './form/actions';
import Result from './result/index';
import t from 'app/translation';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        marginBottom: '30px',
    },
    form: {
        margin: '50px',
        display: 'flex',
    },
};

@inject(stores => ({
    deefind: stores.deefind,
    dna: stores.dnaExplorer,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.explorer.search.display';

    props: {
        state: ?Object,
        deefind: Object,
        dna: Object,
    };

    UNSAFE_componentWillMount() {
        const ids = Array.from(this.props.deefind.results.values()).map(item => item ? item.id : null).filter(v => !!v);

        if (
            this.props.state.result &&
            this.props.state.result.ids &&
            this.props.state.result.ids.length &&
            this.props.state.result.ids.filter(id => !ids.includes(id)).length
        ) {
            this.props.dna.submitSearch();
        }
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.form}>
                    <Kind state={this.props.state} />
                    <Criteria state={this.props.state} />
                    <Actions state={this.props.state} />
                </div>

                {this.renderLoading()}
                {this.renderEmpty()}
                {this.renderFailure()}
                {this.renderResult()}
            </div>
        );
    }

    renderLoading() {
        if (!this.props.state || !this.props.state.loading) {
            return null;
        }

        return (
            <Views.Common.Loader
                title={t('dna.deefind.workspace.searchTitle')}
                progress={this.props.state ? { percent: this.props.state.progress } : undefined}
            />
        );
    }

    renderEmpty() {
        if (!this.props.state || this.props.state.status !== 'empty') {
            return null;
        }

        return <Views.Common.Empty title={t('common.message.noResult')} />;
    }

    renderFailure() {
        if (!this.props.state || this.props.state.status !== 'failure') {
            return null;
        }

        return <Views.Common.Empty title={t('common.error.global')} />;
    }

    renderResult() {
        if (!this.props.state || this.props.state.loading) {
            return null;
        }

        if (
            !this.props.state.status ||
            this.props.state.status === 'success' ||
            (this.props.state.status === 'pending' &&
                this.props.state.result &&
                this.props.state.result.ids &&
                this.props.state.result.ids.length > 0)
        ) {
            return <Result state={this.props.state} />;
        }
    }
}
