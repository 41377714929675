import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

import Form from 'teem/common/prospects/form';
import EventForm from 'teem/common/events/form';
import MilestoneForm from 'teem/common/milestones/form';
import MeetingForm from 'teem/common/meetings/form';
import Attendees from 'teem/common/attendees';

import Files from 'teem/common/files';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';

import { toJS } from 'mobx';

import { inject, observer } from 'mobx-react';

import t from 'app/translation';
const styles = {
    container: {
        display: 'flex',
        marginBottom: '30px',
    },
    main: {
        width: 'auto',
        flexGrow: '1',
        display: 'flex',
    },
    avatar: {},
    title: {
        lineHeight: '40px',
        fontSize: '19px',
        margin: '0 20px',
    },
    budget: {
        fontSize: '19px',
        lineHeight: '40px',
    },
    actions: {
        width: 'auto',
        flexGrow: '0',
        display: 'flex',
    },
    separator: {
        margin: '8px 15px',
        width: '0',
        borderLeft: '1px solid ' + colors.grey400,
    },
    properties: {
        padding: '20px',
    },
    property: {
        lineHeight: '25px',
    },
    status: {
        lineHeight: '40px',
    },
    statusIcon: {
        float: 'left',
        margin: '8px 10px 8px 0',
    },
    addActions: {
        position: 'relative',
    },
    addLabel: {
        position: 'absolute',
        left: '0',
        right: '0',
        top: '-18px',
        borderBottom: '1px solid ' + colors.grey400,
        color: colors.grey500,
        textAlign: 'center',
        lineHeight: '15px',
        fontSize: '13px',
    },
};

@inject(stores => ({ store: stores.teemProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects.display.header';

    static contextTypes = {
        startProspect: PropTypes.func.isRequired,
        endProspect: PropTypes.func.isRequired,
        updateProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        createMilestone: PropTypes.func.isRequired,
        createMeeting: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        manager: PropTypes.bool.isRequired,
    };

    render() {
        const target = this.props.entity.payload.target || {};

        return (
            <div style={styles.container}>
                <div style={styles.main}>
                    <Avatar style={styles.avatar} icon={<Icon type="comeet.prospect" />} />

                    <div style={styles.title}>{target.name || '<no name defined>'}</div>
                </div>

                {this.renderActions()}

                <Form
                    ref="editor"
                    entity={toJS(this.props.entity)}
                    onSubmit={data => this.context.updateProspect(this.props.entity.id, data)}
                />

                <EventForm ref="eventCreator" onSubmit={data => this.context.createEvent(this.props.entity.id, data)} />
                <MilestoneForm
                    ref="milestoneCreator"
                    onSubmit={data => this.context.createMilestone(this.props.entity.id, data)}
                />
                <MeetingForm
                    ref="meetingCreator"
                    onSubmit={data => this.context.createMeeting(this.props.entity.id, data)}
                />
            </div>
        );
    }

    renderActions() {
        switch (this.props.entity.status) {
            case 'draft':
                return this.renderDraftActions();

            case 'started':
            case 'published':
                return this.renderStartedActions();

            case 'succeeded':
            case 'failed':
            case 'canceled':
                return this.renderEndActions();
        }
    }

    renderDraftActions() {
        return (
            <div style={styles.actions}>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.update" />}
                    label="Edit"
                    onClick={event => this.refs.editor.open()}
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.start" />}
                    label="Start"
                    onClick={event => this.context.startProspect(this.props.entity.id)}
                />
            </div>
        );
    }

    renderStartedActions() {
        const target = this.props.entity.payload.target || {};

        return (
            <div style={styles.actions}>
                <div style={styles.addActions}>
                    <div style={styles.addLabel}>ADD</div>

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="comeet.event" />}
                        label="Event"
                        onClick={() => this.refs.eventCreator.open()}
                    />

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="comeet.meeting" />}
                        label="Meeting"
                        onClick={() => this.refs.meetingCreator.open()}
                    />

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="comeet.milestone" />}
                        label="Milestone"
                        onClick={() => this.refs.milestoneCreator.open()}
                    />
                </div>

                <div style={styles.separator} />

                <Views.Discussion.Button
                    color={'cold'}
                    type={'flat'}
                    target={this.props.entity.id}
                    entities={toJS(this.props.entity.comments)}
                    handleSubmit={(data, parentId) => {
                        this.props.store.commentProspect(this.props.entity.id, data, parentId);
                    }}
                    handleDelete={commentId => {
                        this.props.store.removeComment(this.props.entity.id, commentId);
                    }}
                    preview={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar style={styles.avatar} icon={<Icon type="comeet.prospect" />} />

                            <div style={{ fontSize: '19px', marginLeft: '10px', flexGrow: '1' }}>
                                {target.name || '<no name defined>'}
                            </div>
                        </div>
                    }
                />

                <Files
                    type="prospect"
                    parent={this.props.entity.id}
                    files={this.props.entity.payload.files || []}
                    onSubmit={this.props.store.uploadFile}
                    onDownload={this.props.store.downloadFile}
                    onDelete={fileId => this.props.store.deleteFile('prospect', this.props.entity.id, fileId)}
                />

                <Attendees project={this.props.entity} edit={this.props.manager} />

                <div style={styles.separator} />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.update" />}
                    label="Edit"
                    onClick={event => this.refs.editor.open()}
                    disabled={!this.props.manager}
                />

                <Views.Common.Button
                    type="flat"
                    color="hot"
                    icon={<Icon type="do.terminate" />}
                    label="End"
                    disabled={!this.props.manager}
                >
                    <Menu>
                        <MenuItem
                            leftIcon={<Icon type="base.yes" color={colors.userCold} />}
                            primaryText={t('dna.teem.status.prospectFinish')}
                            onClick={event => this.context.endProspect(this.props.entity.id, 'finish')}
                        />

                        <Divider />

                        <MenuItem
                            leftIcon={<Icon type="do.delete" color={colors.userHot} />}
                            primaryText={t('dna.comeet.teem.prospectingAborted')}
                            onClick={event => this.context.endProspect(this.props.entity.id, 'abort')}
                        />
                    </Menu>
                </Views.Common.Button>
            </div>
        );
    }

    renderEndActions() {
        switch (this.props.entity.status) {
            case 'succeeded':
                return (
                    <div style={styles.status}>
                        <Icon type="base.yes" color={colors.userCold} style={styles.statusIcon} />
                        <span style={{ color: colors.userCold }}>Won</span>
                    </div>
                );

            case 'failed':
                return (
                    <div style={styles.status}>
                        <Icon type="base.no" color={colors.userHot} style={styles.statusIcon} />
                        <span style={{ color: colors.userHot }}>Lost</span>
                    </div>
                );

            case 'canceled':
                return (
                    <div style={styles.status}>
                        <Icon type="do.delete" color={colors.userHot} style={styles.statusIcon} />
                        <span style={{ color: colors.userHot }}>Canceled</span>
                    </div>
                );
        }
    }
}
