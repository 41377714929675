import get from 'lodash/get';
import { last } from 'lodash';
import Moment from 'moment';
import next from '../../compute/next';

export default async function(data: Object, services: Object): Promise<Object> {
    const validation = last(
        (data.study.history || []).filter(e => ['confirmReport', 'ignoreRedFlag'].includes(e.transition)),
    );

    const meeting = get(data, 'study.payload.onSite.finalDate');
    const baseRating = get(data, 'computed.scoring.rating', 5);
    const realRating = Math.max(0, Math.min(5, Math.round(get(data, 'study.payload.rating') || baseRating)));

    const aum =
        get(data, 'study.payload.expectedAum', '') ||
        Number(get(data, 'study.payload.archive.expectedAUM', '').replace(/ | /g, '')) / 1000000;

    const analysisDate = Moment(get(data, 'study.payload.archive.validationDate') || data.computed.deadline);

    const review = next(
        analysisDate,
        get(data, 'study.payload.target.variant', '')
            .toLowerCase()
            .replace(/ | /g, ''),
        realRating,
        aum,
    );

    return {
        analyst: await services.acquireUser(data.study.ownerUid),
        validationDate: validation ? Moment(validation.createStamp).format('DD/MM/YYYY') : '',
        finalValidation: validation ? await services.acquireUser(validation.ownerUid) : '',
        onSiteVisitDate: meeting ? Moment(meeting).format('DD/MM/YYYY') : '',
        nextReview: review ? Moment(review.date).format('DD/MM/YYYY') : '',
    };
}
