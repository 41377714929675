import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import defaultsDeep from 'lodash/defaultsDeep';
import Target from 'deeligenz/studies/common/form/target';
import get from 'lodash/get';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { fund: get(props, 'entity.payload.target.type', 'manager') !== 'manager' };
    }

    handleChange(data) {
        const fund = get(data, 'target.type', 'manager') !== 'manager';

        if (fund !== this.state.fund) {
            this.setState({ fund });
        }
    }

    open(extra) {
        this.refs.dialog.open(extra);
    }

    unlock() {
        this.refs.dialog.unlock();
    }

    render() {
        const payload = defaultsDeep({}, this.props.entity ? this.props.entity.payload || {} : {}, {
            target: { type: 'manager', variant: 'traditional' },
        });

        const type = this.props.entity ? this.props.entity.type : undefined;

        return (
            <Views.Modal.Edition
                ref="dialog"
                data={{ ...payload, type }}
                label="Save template"
                onSubmit={this.props.onSubmit}
                onChange={this.handleChange.bind(this)}
            >
                <Views.Form.Row>
                    <Views.Form.Field label="Duedil type" path="type" widget={{
                        type: 'radios',
                        choices: [{ title: 'ODD', value: 'odd' }, { title: 'MDD', value: 'mdd' }]
                    }} required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label="Target type" path="target" widget={<Target />} required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label="Template name" path="label" widget="string" size={2} required />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
