import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Compliance from 'dna/entity/common/compliance/button';
import Vigilance from 'dna/entity/common/vigilance/button';
import Relation from 'dna/entity/common/relation';
import TopRelated from 'dna/entity/common/topRelated';
import Flag from 'dna/entity/common/flag';
import Info from '../info';

const styles = {
    container: {
        marginBottom: '20px',
        position: 'relative',
    },
    listed: {
        position: 'absolute',
        bottom: '40px',
        left: '3px',
    },
    c19: {
        position: 'absolute',
        bottom: '1px',
        left: '-3px',
        width: '40px',
        borderRadius: '20px',
    },
    fortune: {
        position: 'absolute',
        bottom: '-1px',
        left: '0',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.person.card.header';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object,
        parent: PropTypes.object,
        relation: PropTypes.object,
    };

    render() {
        const topCompany = this.props.entity.preview.topCompany || {};

        return (
            <div style={styles.container}>
                <Views.Common.Header
                    light
                    icon={<Icon type="dna.person" />}
                    title={this.props.entity.preview.name || '(?)'}
                    content={[
                        <Info entity={this.props.entity} />,
                        <TopRelated id={topCompany.id} name={topCompany.name} />,
                    ]}
                    aside={this.renderAside()}
                />

                {this.props.entity
                    ? [
                          <Flag
                              key="fortune"
                              label="F"
                              tooltip="Ranked fortune owner"
                              active={this.props.entity.preview.flags.includes('fortune')}
                              style={styles.fortune}
                          />,
                          <Flag
                              key="listed-direct"
                              label="Ld"
                              tooltip="Direct relation with listed company"
                              active={this.props.entity.preview.flags.includes('listed-direct')}
                              style={styles.listed}
                          />,
                          <Flag
                              key="CV19"
                              label="SC19"
                              tooltip="Sensitivity to covid-19 crisis"
                              active={this.props.entity.preview.flags.includes('crisis-direct')}
                              style={styles.c19}
                          />,
                          <Flag
                              key="listed-indirect"
                              label="Li"
                              light={true}
                              tooltip="Indirect relation with listed company"
                              active={this.props.entity.preview.flags.includes('listed-indirect')}
                              style={styles.listed}
                          />,
                      ]
                    : null}
            </div>
        );
    }

    renderAside() {
        const items = [];

        if (this.props.entity) {
            items.push(<Compliance key="compliance" entity={this.props.entity} />);
            items.push(<Vigilance key="vigilance" entity={this.props.entity} />);
        }

        if (this.props.relation) {
            items.push(<Relation key="relation" entity={this.props.parent} data={this.props.relation} />);
        }

        return items;
    }
}
