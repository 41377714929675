// @flow

import type { EntityPreview, EntityType } from '@deecision/deefind-types/model';

import { matchPersonProfile, matchCompanyProfile } from './profile';
import { matchBest } from './array';
import { matchIdentifier } from './scalar';
import { matchName, PERSON_NAME_EXCLUSIONS } from './name';

export function matchProfile(type: string, a: Object, b: Object, alone: boolean = false): number {
    const bonus = alone ? 25 : 0;

    if (!a || !b) {
        return -100;
    }

    if (type === 'company') {
        return normalize(matchCompanyProfile(a, b) + bonus);
    }

    if (type === 'person') {
        return normalize(matchPersonProfile(a, b) + bonus);
    }

    return 0;
}

export function matchTarget(type: EntityType, preview: EntityPreview, target: Object, alone: boolean = false): number {
    if (!target.type || target.type !== type) {
        return -100;
    }

    const a: Object = {
        name: target.name,
        firstName: target.firstName,
        lastName: target.lastName,
        country: target.country,
        persons: target.persons,
        companies: target.companies,
        identifiers: target.identifiers,
        birthDate: target.birthDate,
        naceCode: target.naceCode,
    };

    return target.type ? matchProfile(target.type, a, preview, alone) : 0;
}

export function matchIdentifiers(a: void | string | string[], b: void | string | string[]): number {
    if (!a || !b) {
        return 0;
    }

    return normalize(matchBest(matchIdentifier, Array.isArray(a) ? a : [a], Array.isArray(a) ? a : [a]));
}

export function matchLastName(a: ?string, b: ?string): number {
    return normalize(matchName(a, b, PERSON_NAME_EXCLUSIONS));
}

function normalize(relevance: number): number {
    return Math.max(-100, Math.min(100, relevance));
}
