// @flow

import type {
    RelationEntry,
    RelationNode,
    EntityInnerTarget,
    LinkMandateLevel,
} from '@deecision/deefind-types/model';

import orderBy from 'lodash/orderBy';
import { makePathStrength, makeLevelsStrength, makeTargetStrength } from './strength';
import { makeHighestLevel } from '../link/level';
import { makePathGuessed, makePathCeased, makePathProxemia, makePathYears, makePathStocks, makePathFamily } from './utils';

export default function makeRelationEntry(
    source: EntityInnerTarget,
    paths: RelationNode[][],
    target: EntityInnerTarget,
    omitted?: boolean = false,
): ?RelationEntry {
    if (!paths.length) {
        return undefined;
    }

    paths = orderBy(paths, valuatePath, 'desc');

    const globalBase = { target, paths, connections: [] };
    const globalLevels: LinkMandateLevel[][] = [];
    let minPathLength = 99;

    let profile = {
        start: undefined,
        end: undefined,
        strength: 0,
        circle: 5,
        label: '-',
        family: undefined,
        proxemia: undefined,
        guessed: true,
        ceased: paths.filter(path => !makePathCeased(path)).length === 0,
        width: 0,
        level: 'o',
    };

    for (const path of paths) {
        if (path.length < minPathLength) {
            minPathLength = path.length;
        }

        const { start, end } = makePathYears(path);
        const levels = path.map(node => node.level);
        const strength = makePathStrength(levels, start, end);
        const guessed = makePathGuessed(path);
        const proxemia = makePathProxemia(path);

        const family = makePathFamily(path);

        globalLevels.push(levels);

        profile.start = start && (!profile.start || profile.start > start) ? start : profile.start;
        profile.end = end && (!profile.end || profile.end < end) ? end : profile.end;
        profile.strength = profile.strength + strength;
        profile.guessed = profile.guessed && guessed;

        profile.width += 1;
        profile.level = makeHighestLevel([profile.level, levels[levels.length - 1]]) || 'o';
        profile.proxemia =
            profile.proxemia && proxemia
                ? Math.min(profile.proxemia, proxemia)
                : proxemia
                ? proxemia
                : profile.proxemia;
        profile.family = profile.family ? profile.family : family;
     }

    const labelA = makeHighestLevel(globalLevels.map(bits => bits[0] || 'o'));
    const labelB = minPathLength > 1 ? makeHighestLevel(globalLevels.map(bits => bits[bits.length - 1] || 'o')) : '';

    profile.strength = profile.strength + makeLevelsStrength(globalLevels) + makeTargetStrength(target);
    profile.label = [profile.width, labelA, labelB].join('').toUpperCase();

    return Object.assign({}, globalBase, profile, makePathStocks(paths), { omitted });
}

function valuatePath(path: RelationNode[]): number {
    const { start, end } = makePathYears(path);
    const levels = path.map(node => node.level);

    return makePathStrength(levels, start, end) + (makePathCeased(path) ? 0 : 10);
}
