import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    common: {
        display: 'inline-block',
        fontSize: '13px',
        textAlign: 'center',
        marginRight: '10px',
        verticalAlign: 'middle',
        flexGrow: '0',
        flexShrink: '0',
        lineHeight: '20px',
        width: '20px'
    },

    round: {
        borderRadius: '10px',
    },
    square: {
        borderRadius: '3px',
    },

    cold: {
        color: colors.userCold,
        backgroundColor: colors.userCold,
        borderColor: colors.userCold,
    },
    hot: {
        color: colors.userHot,
        backgroundColor: colors.userHot,
        borderColor: colors.userHot,
    },
    grey: {
        color: colors.grey500,
        backgroundColor: colors.grey500,
        borderColor: colors.grey500,
    },

    full: {
        lineHeight: '20px',
        width: '20px',
        color: 'white'
    },
    outline: {
        lineHeight: '15px',
        width: '16px',
        borderWidth: '2px',
        borderStyle: 'solid',
        background: 'white'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.common.flag';

    static propTypes = {
        char: PropTypes.string.isRequired,
        shape: PropTypes.oneOf([ 'round', 'square' ]).isRequired,
        color: PropTypes.oneOf([ 'cold', 'hot', 'grey' ]).isRequired,
        type: PropTypes.oneOf([ 'outline', 'full' ]).isRequired,
        style: PropTypes.object
    };

    render () {
        let style = Object.assign({},
            styles.common,
            // this.props.style
        );

        switch (this.props.shape) {
            case 'round': Object.assign(style, styles.round); break;
            case 'square': Object.assign(style, styles.square); break;
        }

        switch (this.props.color) {
            case 'cold': Object.assign(style, styles.cold); break;
            case 'hot': Object.assign(style, styles.hot); break;
            case 'grey': Object.assign(style, styles.grey); break;
        }

        switch (this.props.type) {
            case 'full': Object.assign(style, styles.full); break;
            case 'outline': Object.assign(style, styles.outline); break;
        }

        return (<span style={ style }>{ this.props.char }</span>);
    }
}
