import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Moment from 'moment';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import sortBy from 'lodash/sortBy';

const MIN_COUNT = 1;
const MAX_COUNT = 10;

const styles = {
    container: {
        margin: '40px 120px',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    monthName: {
        display: 'inline-block',
        width: '150px',
        fontSize: '19px',
        verticalAlign: 'middle',
    },
    monthMatrix: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
    },
    dayName: {
        color: colors.userCold,
        textAlign: 'center',
        borderRight: '1px solid ' + colors.grey300,
        background: colors.grey100,
        lineHeight: '40px',
        fontSize: '13px',
    },
    daySelected: {
        background: colors.userCold,
        color: 'white',
    },
    dayHover: {
        background: colors.bgCold,
    },
    dayDate: {
        borderTop: '1px solid ' + colors.grey300,
        borderRight: '1px solid ' + colors.grey300,
        lineHeight: '50px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    dayDisabled: {
        background: colors.grey100,
        color: colors.grey400,
        cursor: 'default',
    },
    footer: {
        marginTop: '40px',
        textAlign: 'center',
    },
    footerLabel: {
        display: 'inline-block',
        fontSize: '19px',
        marginRight: '40px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.agenda.planning';

    static propTypes = {
        dates: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.UNSAFE_componentWillReceiveProps(props);
    }

    buildMoment() {
        return Moment(`${this.state.year}-${this.state.month}-1`, 'YYYY-M-D');
    }

    moveMonth(offset: number) {
        const moment = this.buildMoment().add(offset, 'months');

        this.setState({ year: moment.year(), month: moment.month() + 1 });
    }

    UNSAFE_componentWillReceiveProps(props) {
        const dates = sortBy(props.dates || []);
        const start = dates.length ? dates[0] : new Date().toISOString();

        this.state = {
            hover: null,
            year: parseInt(start.substr(0, 4)),
            month: parseInt(start.substr(5, 2)),
            dates: props.dates,
            unlocked: false,
            filled: props.dates.length > 0,
        };
    }

    render() {
        return (
            <div style={styles.container}>
                {this.renderHeader()}
                {this.renderMonth()}
                {this.renderFooter()}
            </div>
        );
    }

    renderHeader() {
        return (
            <div style={styles.header}>
                <Views.Common.Button
                    type="flat"
                    icon={<Icon type="nav.arrow.left" />}
                    onClick={event => this.moveMonth(-1)}
                />

                <span style={styles.monthName}>{this.buildMoment().format('MMMM YYYY')}</span>

                <Views.Common.Button
                    type="flat"
                    icon={<Icon type="nav.arrow.right" />}
                    onClick={event => this.moveMonth(1)}
                />
            </div>
        );
    }

    renderMonth() {
        const first = this.buildMoment().isoWeek();
        const last = this.buildMoment()
            .endOf('month')
            .isoWeek();
        const days = [];

        for (let week = first; week <= (last === 1 ? 53 : last); week++) {
            const moment = this.buildMoment()
                .day('monday')
                .isoWeek(week);

            for (let day = 0; day < 7; day++) {
                days.push(this.renderDay(moment));
                moment.add(1, 'days');
            }
        }

        return (
            <div style={styles.monthMatrix}>
                <span style={styles.dayName}>Monday</span>
                <span style={styles.dayName}>Tuesday</span>
                <span style={styles.dayName}>Wednesday</span>
                <span style={styles.dayName}>Thursday</span>
                <span style={styles.dayName}>Friday</span>
                <span style={Object.assign({}, styles.dayName, styles.dayDisabled)}>Saturday</span>
                <span style={Object.assign({}, styles.dayName, styles.dayDisabled)}>Sunday</span>
                {days}
            </div>
        );
    }

    renderDay(moment) {
        const date = moment.format('YYYY-MM-DD');

        const disabled =
            (this.state.filled && !this.state.unlocked) ||
            this.state.dates.length >= MAX_COUNT ||
            [0, 6].includes(moment.day()) ||
            moment.month() + 1 !== this.state.month ||
            moment.isBefore(Moment()) ||
            moment.isAfter(Moment().add(6, 'months'));

        const style = this.state.dates.includes(date)
            ? Object.assign({position: 'relative'}, styles.dayDate, styles.daySelected, this.props.date ? { background: colors.halfCold } : {}, date === this.props.date ? {background: colors.userCold} : {})
            : disabled
            ? Object.assign({}, styles.dayDate, styles.dayDisabled)
            : this.state.hover === date
            ? Object.assign({}, styles.dayDate, styles.dayHover)
            : styles.dayDate;

        return (
            <span
                key={moment.format('YYYY-MM-DD')}
                style={style}
                onMouseEnter={disabled ? null : event => this.setState({ hover: date })}
                onMouseLeave={disabled ? null : event => this.setState({ hover: null })}
                onClick={
                    this.state.filled && !this.state.unlocked
                        ? null
                        : event => {
                              if (this.state.dates.includes(date)) {
                                  this.setState({ dates: this.state.dates.filter(v => v !== date) });
                              } else if (!disabled) {
                                  this.setState({ dates: this.state.dates.concat([date]) });
                              }
                          }
                }
            >
                {moment.date()}
                {this.props.date === date && this.props.lock ? <Icon type="base.lock" style={{color: 'white', position: 'absolute', top: '2px', right: '2px'}} /> : null}
            </span>
        );
    }

    renderFooter() {
        const count = this.state.dates.length;

        if (this.state.filled && !this.state.unlocked) {
            return (
                <div style={styles.footer}>
                    <span style={styles.footerLabel}>{`${count} dates have been proposed`}</span>
                    {this.props.lock ? null :
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        icon={<Icon type="do.update" />}
                        label="Change dates"
                        onClick={event => this.setState({ unlocked: true })}
                    />}
                </div>
            );
        }

        return (
            <div style={styles.footer}>
                <span style={styles.footerLabel}>
                    {count === 0
                        ? 'No dates selected'
                        : count === 1
                        ? '1 date selected'
                        : count >= MAX_COUNT
                        ? 'Max dates selected'
                        : `${count} dates selected`}
                </span>

                <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.submit" />}
                    label="Submit"
                    disabled={count < MIN_COUNT || this.state.dates === this.props.dates}
                    onClick={event => this.props.onChange(this.state.dates)}
                />
            </div>
        );
    }
}
