import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.model.block';

    static propTypes = {
        position: PropTypes.oneOf([ 'left', 'right', 'empty', 'below' ]).isRequired
    };

    render () {
        throw new Error(`Model component must not be rendered.`);
    }
}
