import React from 'react';
import PropTypes from 'prop-types';
import ScopeProvider from 'app/layout/providers/scope';
import { inject, observer } from 'mobx-react';
import Header from './header';
import Survey from '../../common/survey';
import Tabs from './tabs';
import Attendees from '../../common/attendees';
import Views from 'app/views';

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.display';

    static contextTypes = {
        fetchStudy: PropTypes.func.isRequired,
        commentStudy: PropTypes.func.isRequired,
        answerField: PropTypes.func.isRequired,
        commentField: PropTypes.func.isRequired,
        getFileUrl: PropTypes.func.isRequired,
        setStudyOnSite: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    componentDidMount() {
        if (this.props.state && this.props.state.id) {
            this.context.fetchStudy(this.props.state.id);
        }
    }

    render() {
        if (!this.props.store || !this.props.state) {
            return null;
        }

        const entity = this.props.store.studies.get(this.props.state.id);
        const fetched = this.props.store.fetched;

        if (!entity) {
            return null;
        }

        if (!fetched.includes(this.props.state.id)) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div style={this.props.style}>
                <Header
                    entity={entity}
                    onComment={(channel, comment) => this.context.commentStudy(entity.id, channel, comment)}
                />

                <Tabs entity={entity} tab={this.props.state ? this.props.state.tab || 'input' : 'input'} />
                {this.renderContent(entity)}
            </div>
        );
    }

    renderContent(entity) {
        const tab = this.props.state ? this.props.state.tab || 'input' : 'input';

        switch (tab) {
            case 'input':
                return (
                    <ScopeProvider path="input" type="object" key="input">
                        <Survey
                            entity={entity}
                            scope="input"
                            withRemark={false}
                            onScope={(scope, flags) => ['both', 'input'].includes(scope)}
                            onAnswer={this.context.answerField}
                            onComment={this.context.commentField}
                            getFileUrl={this.context.getFileUrl}
                            withStatus
                            withAutoFlags={false}
                            readOnly={entity.status !== 'input'}
                            external
                        />
                    </ScopeProvider>
                );

            case 'onSite':
                return (
                    <ScopeProvider path="onSite" type="object" key="onSite">
                        <Attendees
                            entity={entity}
                            external={true}
                            onChange={data => this.context.setStudyOnSite(entity.id, data)}
                        />
                    </ScopeProvider>
                );
        }
    }
}
