// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Splash from './splash';
import Button from 'app/views/common/button';
import RadioButtonGroup from 'material-ui/RadioButton/RadioButtonGroup';
import RadioButton from 'material-ui/RadioButton/RadioButton';
import Moment from 'moment';
import xlsx from 'xlsx';
import t from 'app/translation';

const TITLES = {
    augmented: t('dna.deetect.common.title.augmented'),
    coopt: t('dna.deetect.common.title.coopt'),
    segment:  t('dna.deetect.common.title.segment'),
    family:  t('dna.deetect.common.title.family'),
    predict:  t('dna.deetect.common.title.predict'),
    covid19:  t('dna.deetect.common.title.covid19'),
    upsell:  t('dna.deetect.common.title.upsell'),
};

const NOTICES = {
    person: t('dna.deetect.common.label.person'),
    company: t('dna.deetect.common.label.company'),
};

const styles = {
    format: {
        width: '260px',
        margin: '40px auto',
    },
    title: {
        color: colors.userCold,
        textAlign: 'center',
    },
    button: {
        display: 'block',
        margin: '10px auto',
        width: '250px',
    },
    sources: {
        display: 'flex',
        margin: '0 75px',
    },
    column: {
        position: 'relative',
        lineHeight: '20px',
    },
    rowLabel: {
        display: 'inline-block',
        marginLeft: '10px',
    },
    rowRequired: {
        display: 'inline-block',
        marginLeft: '15px',
        color: colors.userHot,
        fontSize: '13px',
    },
    rowInfo: {
        display: 'inline-block',
        marginLeft: '15px',
        color: colors.grey500,
        fontSize: '13px',
    },
    orContainer: {
        position: 'absolute',
        right: '0',
        bottom: '1px',
        height: '40px',
        width: '35px',
        display: 'flex',
        justifyContent: 'stretch',
    },
    orBracket: {
        width: '5px',
        border: '1px solid ' + colors.userHot,
        borderLeftWidth: '0',
        borderRadius: '0 3px 3px 0'
    },
    orLabel: {
        lineHeight: '40px',
        color: colors.userHot,
        marginLeft: '10px',
        fontSize: '13px',
    },
};

const COLUMNS = {
    person: (
        <div style={styles.column}>
            1.<span style={styles.rowLabel}>{t('common.label.lastname')}</span><span style={styles.rowRequired}>{t('common.label.required')}</span>
            <br />
            2.<span style={styles.rowLabel}>{t('common.label.firstname')}</span><span style={styles.rowRequired}>{t('common.label.required')}</span>
            <br />
            3.<span style={styles.rowLabel}>{t('common.label.birthdate')}</span><span style={styles.rowInfo}>{t('common.label.dateFormatYYYYMMDD')}</span>
            <br />
            4.<span style={styles.rowLabel}>{t('dna.deetect.common.label.linkedCompany')}</span>

            <div style={styles.orContainer}>
                <div style={styles.orBracket} />
                <div style={styles.orLabel}>OR</div>
            </div>
        </div>
    ),

    company: (
        <div style={styles.column}>
            1.<span style={styles.rowLabel}>{t('common.label.companyName')}</span><span style={styles.rowRequired}>{t('common.label.required')}</span>
            <br />
            2.<span style={styles.rowLabel}>{t('common.label.identifier')}</span><span style={styles.rowInfo}>SIREN / LEI / ...</span>
            <br />
            3.<span style={styles.rowLabel}>{t('dna.deetect.common.label.linkedPerson')}</span>

            <div style={styles.orContainer}>
                <div style={styles.orBracket} />
                <div style={styles.orLabel}>OR</div>
            </div>
        </div>
    ),
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.base.upload';

    static propTypes = {
        state: PropTypes.object,
    };

    static contextTypes = {
        pushJobs: PropTypes.func.isRequired,
        setStage: PropTypes.func.isRequired,
    };

    state = { error: null };

    render() {
        if (this.state.error) {
            return this.renderError(this.state.error);
        }

        return (
            <Splash title={TITLES[this.props.state.tool]} notice={NOTICES[this.props.state.source]}>
                {this.renderSource()}

                <div style={styles.format}>
                    <div style={styles.title}>{t('dna.deetect.common.label.uploadExcel')}</div>
                    <br />
                    {COLUMNS[this.props.state.source]}
                </div>

                <input
                    ref="upload"
                    type="file"
                    style={{ visibility: 'hidden', position: 'fixed', top: '-999px' }}
                    onChange={event => this.handleUpload(event)}
                />
                <div style={styles.format}>
                    <input
                        type="checkbox"
                        name="deep"
                        onChange={(event) => {this.context.setStage({ deep: event.currentTarget.checked })}}
                    />
                    <label>{t('dna.deetect.common.label.completeSearch')} <br/>
                        <em>{t('dna.deetect.common.label.completeSearchSub')}</em>
                    </label>
                </div>
                <Button
                    type="raised"
                    color="cold"
                    label="Upload file"
                    onClick={event => this.refs.upload.click()}
                    style={styles.button}
                />
            </Splash>
        );
    }

    renderSource() {
      const buttons = [];

      if (this.props.state.persons) {
          buttons.push(<RadioButton key="person" value="person" label={t('common.label.persons')} />);
      }

      if (this.props.state.companies) {
          buttons.push(<RadioButton key="company" value="company" label={t('common.label.companies')} />);
      }

        return <RadioButtonGroup
            name="source"
            defaultSelected={this.props.state.source}
            onChange={(event, type) => this.context.setStage({ source: type })}
            style={styles.sources}
        >
            { buttons }
        </RadioButtonGroup>;
    }

    handleUpload(event: any) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = event => {
            try {
                const book = xlsx.read(event.target.result, { type: 'binary' });
                const sheet = book.Sheets[book.SheetNames[0]];
                const raw = xlsx.utils.sheet_to_json(sheet, { header: 1 });
                const data = raw.filter(v => !!v && v.length > 1).map(row => this.normalizeRow(row)).filter(row => this.validateRow(row));

                if (data.length > 0) {
                    this.context.pushJobs(data);
                } else {
                    this.setState({ error: 'empty' });
                }
            } catch (error) {
                console.error(error);
                this.setState({ error: 'invalid' });
            }
        };

        reader.readAsBinaryString(file);
    }

    normalizeRow(row: string[]): string[] {
        // on trim toutes les valeurs
        row = row.map(value => value ? String(value).replace(/^\s+|\s+$/g, '') : '');

        if (this.props.state.source === 'person') {
            // cas du format de date pourri d'excel
            if (row[2] && row[2].match(/^\d+$/)) {
                row[2] = Moment('1899-12-30').add('days', parseInt(row[2])).format().substr(0, 10);
            }

            // cas des dates au format français
            else if (row[2] && row[2].match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                row[2] = `${row[2].substr(6, 4)}-${row[2].substr(3, 2)}-${row[2].substr(0, 2)}`;
            }
        }

        return row;
    }

    validateRow(row: string[]) {
        switch (this.props.state.source) {
            case 'person':
                return (
                    (row[0] && row[0].length > 2) &&
                    (row[1] && row[1].length > 2) &&
                    (!row[2] || row[2].match(/^\d{4}-\d{2}-\d{2}$/)) &&
                    (!row[3] || row[3].length > 2) &&
                    (row[2] || row[3])
                );

            case 'company':
                return (
                    (row[0] && row[0].length > 2) &&
                    (!row[1] || row[1].length > 2) &&
                    (!row[2] || row[2].length > 2) &&
                    (row[1] || row[2])
                );

            default:
                return false;
        }
    }

    renderError(type: string) {
        const message =
            type === 'empty' ? (
                <span>
                    The uploaded CSV or Excel file is empty
                    <br />
                    or contains only invalid rows
                </span>
            ) : (
                <span>The uploaded file is not a valid CSV or Excel file</span>
            );

        return (
            <Splash title={t('common.error.invalidUpload')} error={message}>
                <Button
                    type="raised"
                    color="cold"
                    label={t('common.error.tryAgain')}
                    onClick={event => this.setState({ error: null })}
                    style={styles.button}
                />
            </Splash>
        );
    }
}
