// @flow

import type { CompanyPreview, CompanyValuation, EntityEstimate } from '@deecision/deefind-types/model';

export default function makeValuationPreview(preview: CompanyPreview, valuation: CompanyValuation): ?EntityEstimate {
    const flags = preview.flags || [];

    if (flags.includes('listed')) {
        return valuation.market && valuation.market.value ? { value: valuation.market.value } : undefined;
    }

    if (valuation.vadis) {
        return { value: valuation.vadis };
    }

    if (flags.includes('holding') || flags.includes('fund')) {
        const stocksValue = valuation.stocks ? valuation.stocks.value || 0 : 0;
        const capitalValue = valuation.capital || 0;

        return capitalValue > stocksValue ? { value: capitalValue } : valuation.stocks;
    }

    if (valuation.deemaze && valuation.deemaze.estimate) {
        if (valuation.stocks) {
            return {
                value: (valuation.deemaze.estimate.value || 0) + (valuation.stocks.value || 0),
                deviation: (valuation.deemaze.estimate.deviation || 0) + (valuation.stocks.deviation || 0),
            };
        }

        return valuation.deemaze.estimate;
    }

    return undefined;
}
