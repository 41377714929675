import React from 'react';
import PropTypes from 'prop-types';
import Scope from 'app/layout/providers/scope';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Form from './form';
import List from './list';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.deeligenzManagement, storeStudies: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.templates.summary';

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    async handleSubmit(data: Object) {
        const template = await this.props.store.createTemplate(data);
        this.refs.form.unlock();
        await this.props.storeStudies.addTemplate(template);
    }

    render() {
        const entities = this.props.store.templatesMap;
        const loaded = !toJS(this.props.store.loading);

        if (!loaded) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div style={this.props.style}>
                {this.renderList(Array.from(entities.values()))}
                <Form ref="form" onSubmit={this.handleSubmit.bind(this)} />
            </div>
        );
    }

    renderList(entities) {
        entities = entities.filter(e => !(!!e.payload.archive));

        if (!entities.length) {
            return (
                <Views.Common.Empty
                    title="No templates found"
                    actions={[
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            icon={<Icon type="do.create" />}
                            label="New template"
                            onClick={event => this.refs.form.open()}
                        />,
                    ]}
                />
            );
        }

        return (
            <Scope path="list" type="list">
                <List
                    entities={entities}
                    onCreate={event => this.refs.form.open()}
                />
            </Scope>
        );
    }
}
