import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.model.rule';

    static propTypes = {
        unit: PropTypes.oneOf([ 'month' ]).isRequired
    };

    render () {
        throw new Error(`Model component must not be rendered.`);
    }
}
