import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        paddingBottom: '15px',
        marginBottom: '20px',
        textAlign: 'right',
        height: '25px'
    },
    title: {
        color: colors.userHot,
        fontWeight: 'bold',
        float: 'left',
        lineHeight: '25px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.tab.header';

    static propTypes = {
        title: PropTypes.string.isRequired,
        status: PropTypes.string,
        noStatus: PropTypes.bool,
    };

    render () {
        return (
            <div style={ styles.container }>
                <span style={ styles.title }>
                    { this.props.title }
                </span>

                { this.props.children }
            </div>
        );
    }
}
