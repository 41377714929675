// @flow

import * as utils from './utils';
import get from 'lodash/get';
import size from 'lodash/size';
import sum from 'lodash/sum';
import map from 'lodash/map';

const baseHeader = [
    'Last name',
    'First name',
    'Birth date',
    'Age',
    'F500 rank',
    'Top company name',
    'Top company country',
    'Home country',
    'Reech type',
    'Reech relevance',
];

const extendedHeader = [
    'Current companies count',
    'Past companies count',
    'Current holdings count',
    'Current mandates count',
    'Past mandates count',
    'Investor current mandates',
    'Shareholder current mandates',
    'Board member current mandates',
    'Executive current mandates',
    'Investor past mandates',
    'Shareholder past mandates',
    'Board member past mandates',
    'Executive past mandates',
    'Reech relations count',
    'Current Reech relations',
    'Past relations count',
    'F500 relations count',
    'Reech F500 relations',
    'WEASSE value',
    'WEASSE MAX value',
    'WEASSE SUM',
    'Vigilance Directe',
    'Vigilance Societes',
    'Vigilance Relations',
    'TURNOVER SUM',
    'CAPITAL SUM',
    'VALUATION SUM',
    'Nb Deals Zephyr',
    'Somme Deals Zephyr',
    'Nb Deals Deduits',
    'Somme Deals Deduits',
    'Annee Dernier Deal Deduit',
];

export function makeHeader(extended: boolean): string[] {
    return extended ? baseHeader.concat(extendedHeader) : baseHeader;
}

export async function makeResult(
    results: Map<string, any>,
    id: string,
    reechService: Object,
    extended: boolean,
): Promise<string[]> {
    const entity = results.get(`entity:${id}`);
    const network = results.get(`network:${id}`);
    const deals = results.get(`deals:${id}`);

    const companies = get(network, 'relations.companies', []);
    const guessedDeals = utils.guessDeals(companies);

    const reech = await utils.makeReechResult(reechService, {
        id: get(entity, 'id', ''),
        type: 'person',
        preview: get(entity, 'preview', {}),
    });

    let result = [
        get(entity, 'preview.lastName', ''),
        get(entity, 'preview.firstName', ''),
        get(entity, 'preview.birthDate', ''),
        utils.makePersonAge(get(entity, 'preview.birthDate', '')),
        utils.makePersonF500(get(entity, 'files', [])),
        get(entity, 'preview.topCompany.name', ''),
        get(entity, 'preview.topCompany.country', ''),
        get(entity, 'preview.country', ''),
        reech.type,
        reech.relevance,
    ];

    if (extended)
        result = result.concat([
            utils.countRelations(get(network, 'relations.companies', []), false),
            utils.countRelations(get(network, 'relations.companies', []), true),
            utils.countRelations(get(network, 'relations.companies', []), false, 'holding'),

            utils.countMandates(companies, false),
            utils.countMandates(companies, true),
            utils.countMandates(companies, false, ['i']),
            utils.countMandates(companies, false, ['s']),
            utils.countMandates(companies, false, ['b']),
            utils.countMandates(companies, false, ['x']),
            utils.countMandates(companies, true, ['i']),
            utils.countMandates(companies, true, ['s']),
            utils.countMandates(companies, true, ['b']),
            utils.countMandates(companies, true, ['x']),

            await utils.countReechRelations(reechService, get(network, 'relations.persons', [])),
            utils.countRelations(get(network, 'relations.persons', []), false),
            utils.countRelations(get(network, 'relations.persons', []), true),
            utils.countRelations(get(network, 'relations.persons', []), false, 'fortune'),
            await utils.countReechRelations(reechService, get(network, 'relations.persons', []), 'fortune'),

            get(network, 'preview.weasse.value', ''),
            get(network, 'preview.weasseMax.value', ''),
            get(network, 'computed.sums.persons.weasse.value', ''),

            get(network, 'vigilance.direct.score', ''),
            get(network, 'vigilance.companies.score', ''),
            get(network, 'vigilance.persons.score', ''),

            get(network, 'computed.sums.companies.turnover.value', ''),
            get(network, 'computed.sums.companies.capital.value', ''),
            get(network, 'computed.sums.companies.valuation.value', ''),

            size(get(deals, 'details', {})) || '',
            sum(map(get(deals, 'details', {}), deal => get(deal, 'value', 0))) || '',
            guessedDeals.count ? String(guessedDeals.count) : '',
            guessedDeals.value ? String(guessedDeals.value) : '',
            guessedDeals.date ? String(guessedDeals.date) : '',
        ]);

    return result;
}
