import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';
import t from 'app/translation';

const styles = {
    logo: {
        fontFamily: 'Montserrat, sans-serif',
        letterSpacing: '-0.05em',
        color: colors.userLogo,
        fontSize: '35px',
        display: 'inline-block',
    },
    ee: {
        fill: colors.userHot,
        height: '25px',
        margin: '0 1px 0 3px',
    },
};
//dna.deefind.veegilenzData.common.logo
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.vigilance.title';

    render() {
        const svg = (
            <svg style={ styles.ee } viewBox="0 0 80 46">
                <path stroke="null" id="svg_2" d="m38.167005,0.13655c0,1.518419 0,3.036838 0,4.504642c0,0.303684 -0.050614,0.404912 -0.404912,0.404912c-3.998503,0 -7.997006,0 -11.995508,0c-1.417191,0 -2.834382,0 -4.251573,0.050614c-3.644205,0.050614 -6.883499,1.265349 -9.819108,3.441749c-2.733154,2.024558 -4.555256,4.757712 -5.567536,7.997006c-0.404912,1.265349 -0.809823,2.581312 -1.113507,3.846661c-0.101228,0.455526 -0.101228,0.455526 0.404912,0.455526c0.050614,0 0.101228,0 0.101228,0c10.730159,0 21.460319,0 32.190478,0c0.404912,0 0.50614,0.101228 0.50614,0.50614c-0.050614,1.366577 0,2.68254 0,4.049117c0,0.303684 -0.050614,0.404912 -0.404912,0.404912c-6.984726,0 -13.969453,0.050614 -20.954179,0.101228c-3.846661,0 -7.693322,0.050614 -11.539983,0.050614c-0.303684,0 -0.354298,0.050614 -0.25307,0.354298c0.455526,1.872717 1.012279,3.745433 1.771489,5.516922c1.366577,3.188679 3.745433,5.466308 6.630429,7.237796c2.328242,1.417191 4.85894,2.125786 7.592094,2.075172c5.567536,-0.050614 11.135071,-0.050614 16.702607,-0.101228c0.404912,0 0.50614,0.101228 0.50614,0.50614c-0.050614,1.366577 0,2.68254 0,4.049117c0,0.25307 -0.050614,0.354298 -0.354298,0.354298c-6.023061,0 -12.046122,0.050614 -18.069184,0c-3.593591,-0.050614 -6.832885,-1.265349 -9.71788,-3.391135c-4.757712,-3.492363 -7.946392,-7.997006 -9.262355,-13.817611c-0.607368,-2.631926 -0.657981,-5.314466 -0.455526,-7.997006c0.25307,-3.289907 1.062893,-6.478587 2.68254,-9.414197c3.188679,-5.719377 7.845164,-9.515424 14.374365,-10.780773c0.708595,-0.151842 1.417191,-0.202456 2.1764,-0.303684c0.101228,0 0.202456,0.050614 0.303684,-0.101228l18.221026,0z"/>
                <path stroke="null" id="svg_3" d="m59.961366,0.13655c0.50614,0.202456 1.062893,0.151842 1.569033,0.202456c6.124289,0.911051 10.831387,4.049117 14.273137,9.110513c2.125786,3.138066 3.289907,6.630429 3.694819,10.375862c0.404912,3.796047 0.25307,7.490866 -1.012279,11.135071c-2.227014,6.276131 -6.276131,10.882001 -12.349806,13.665769c-2.024558,0.961665 -4.200959,1.315963 -6.478587,1.315963c-5.719377,0 -11.489369,0 -17.208746,0c-0.404912,0 -0.455526,-0.101228 -0.455526,-0.455526c0,-1.315963 0.050614,-2.631926 0,-3.947889c0,-0.404912 0.101228,-0.455526 0.455526,-0.455526c5.36508,0.050614 10.730159,0 16.095239,0.101228c4.150345,0.101228 7.592094,-1.467805 10.679546,-4.099731c2.581312,-2.227014 4.049117,-5.11201 4.960168,-8.351303c0.25307,-0.911051 0.50614,-1.822103 0.759209,-2.834382c-1.265349,0 -2.480084,0 -3.694819,0c-9.312969,-0.050614 -18.575323,-0.101228 -27.888292,-0.101228c-0.354298,0 -0.759209,-0.050614 -1.113507,0c-0.25307,0 -0.303684,-0.101228 -0.303684,-0.354298c0,-1.417191 0,-2.783768 0,-4.200959c0,-0.354298 0.101228,-0.404912 0.455526,-0.404912c7.490866,0 14.981732,0 22.472598,0c3.239293,0 6.529201,0 9.768494,0c0.303684,0 0.404912,-0.050614 0.303684,-0.404912c-0.607368,-2.227014 -1.214735,-4.504642 -2.227014,-6.579815c-1.518419,-3.087452 -3.947889,-5.263852 -6.934113,-6.883499c-2.530698,-1.366577 -5.213238,-1.92333 -8.04762,-1.92333c-5.11201,0 -10.22402,-0.050614 -15.33603,-0.050614c-0.354298,0 -0.455526,-0.101228 -0.455526,-0.455526c0,-1.518419 0,-2.986224 0,-4.504642l18.01857,0.101228z"/>
            </svg>
        );

        return (
            <Views.Common.Tooltip content={t('dna.deefind.veegilenzData.common.logo')}>
                <div style={ styles.logo }>
                    V{ svg }GILENZ
                </div>
            </Views.Common.Tooltip>
        );
    }
}
