// @flow

import React from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'app/layout/providers/data';
import colors from 'app/theme/colors';

const styles = {
    container: {
        margin: '10px 0',
        color: colors.userCold,
        borderRadius: '3px',
        lineHeight: '40px',
        cursor: 'pointer',
        background: 'transparent',
        display: 'flex',
    },
    hover: {
        background: colors.grey300,
    },
    name: {
        padding: '0 5px 0 12px',
    },
    arrow: {
        paddingRight: '12px',
        color: colors.grey400,
        fontSize: '9px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'layout.application.header.cursor.button';

    static propTypes = {
        value: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    state = { hover: false };

    render() {
        return (
            <div
                style={Object.assign({}, styles.container, this.state.hover ? styles.hover : {})}
                onClick={this.props.onClick}
                onMouseEnter={() => this.setState({ hover: true })}
                onMouseLeave={() => this.setState({ hover: false })}
            >
                <DataProvider
                    service="cleerance"
                    model="team"
                    id={this.props.value}
                    scope="public"
                    renderValue={value => {
                        return <span style={styles.name}>{value.profile.name}</span>;
                    }}
                    renderLoader={() => {
                        return <span style={styles.name}>Loading</span>;
                    }}
                />

                <span style={styles.arrow}>▼</span>
            </div>
        );
    }
}
