import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

let styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    avatar: {
        width: '24px',
        height: '24px',
        marginRight: '20px',
    },
    avatarLight: {
        marginRight: '15px'
    },
    letter: {
        display: 'inline-block',
        width: '40px',
        lineHeight: '40px'
    },
    main: {
        flexGrow: '1',
        WebkitBoxFlex: '1',
        MsFlexPositive: '1',
        overflow: 'hidden'
    },
    aside: {
        flexShrink: '0',
        MsFlexNegative: '0',
        marginLeft: '10px'
    },
    title: {
        display: 'block',
        fontSize: '23px',
        lineHeight: '24px',
        fontWeight: 'normal',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left'
    },
    content: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: '15px',
        color: colors.grey500
    },
    titleAlone: {
        height: '40px',
        lineHeight: '40px',
    },
    titleLight: {
        fontSize: '19px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.header';

    static propTypes = {
        icon: PropTypes.node,
        title: PropTypes.node.isRequired,
        content: PropTypes.arrayOf(PropTypes.node),
        aside: PropTypes.arrayOf(PropTypes.node),
        light: PropTypes.bool,
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                { this.renderAvatar() }
                { this.renderMain() }
                { this.renderAside() }
            </div>
        );
    }

    renderAvatar () {
        if (! this.props.icon)
            return null;

        const style = this.props.light
            ? Object.assign({}, styles.avatar, styles.avatarLight)
            : styles.avatar;

        return (
            <div style={ style }>
                {
                    React.cloneElement(this.props.icon, {
                        style: { fill: colors.userCold }
                    })
                }
            </div>
        );
    }

    renderMain () {
        const style = _.clone(styles.title);

        if (! this.props.content || ! this.props.content.length)
            Object.assign(style, styles.titleAlone);

        if (this.props.light)
            Object.assign(style, styles.titleLight);

        return (
            <div style={ styles.main }>
                <div title={this.props.title} style={ style }>
                    { this.props.title }
                </div>
                { this.renderContent() }
            </div>
        );
    }

    renderContent () {
        if (! this.props.content)
            return null;

        return _.filter(this.props.content).map((content, index) => (
            <div key={ index } style={ styles.content }>{ content }</div>
        ));
    }

    renderAside () {
        if (! this.props.aside)
            return null;

        return _.filter(this.props.aside).map((aside, index) => (
            <div key={ index } style={ styles.aside }>{ aside }</div>
        ));
    }
}
