import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Form from '../templates/form';
import Questions from '../questions';

import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

import get from 'lodash/get';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        marginBottom: '30px',
    },
    main: {
        width: 'auto',
        flexGrow: '1',
        display: 'flex',
    },

    mainAvatar: {},
    mainRating: {
        marginRight: '20px',
    },
    mainTitle: {
        margin: '0 20px',
    },
    mainAum: {
        lineHeight: '40px',
        margin: '0 20px',
        fontSize: '15px',
        color: colors.grey700,
    },

    statusDraft: {
        color: colors.grey500,
        lineHeight: '40px',
    },
    statusIcon: {
        margin: '8px',
        float: 'left',
    },
    statusApproved: {
        color: colors.userGreen,
        lineHeight: '40px',
    },
    statusRejected: {
        color: colors.userHot,
        lineHeight: '40px',
    },

    actions: {
        width: 'auto',
        flexGrow: '0',
        display: 'flex',
    },
    actionsSeparator: {
        margin: '8px 15px',
        width: '0',
        borderLeft: '1px solid ' + colors.grey400,
    },

    contactContainer: {
        margin: '20px',
        lineHeight: '24px',
        minWidth: '200px',
    },
    contactTitle: {
        color: colors.userCold,
        borderBottom: '1px solid ' + colors.grey300,
        marginBottom: '10px',
    },
    contactValue: {
        margin: '2px 0',
    },
    contactIcon: {
        height: '16px',
        width: '16px',
        margin: '4px 10px 4px 0',
        float: 'left',
        fill: colors.grey500,
    },
};

@inject(stores => ({
    store: stores.deeligenzStudies,
    contacts: stores.auth.contacts,
    global: stores.global,
    management: stores.deeligenzManagement
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.display.header';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
    };

    renderOwner(owner, payload) {
        return (
            <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                <Icon
                    type="base.attendee"
                    style={{ fill: colors.userCold, marginRight: '5px', width: '18px', height: '18px' }}
                />

                <span style={{ lineHeight: '18px' }}>{owner.firstName + ' ' + owner.lastName}</span>
            </div>
        );
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.main}>
                    <div style={styles.mainTitle}>
                        <Views.Common.Header
                            light
                            icon={
                                <Icon
                                    type={
                                        get(this.props.entity, 'payload.target.type') === 'fund'
                                            ? 'deeligenz.fund'
                                            : 'deeligenz.company'
                                    }
                                />
                            }
                            title={get(this.props.entity, 'payload.label')}
                            content={[
                                this.renderOwner(
                                    this.props.contacts.users[this.props.entity.owner] || {},
                                    this.props.entity.payload,
                                ),
                            ]}
                            aside={[]}
                        />
                    </div>

                </div>

                <div style={styles.actions}>
                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="do.update" />}
                        label="Edit"
                        onClick={event => this.refs.edit.open()}
                    />

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={<Icon type="do.update" />}
                        label="Add questions"
                        onClick={event => this.refs.questions.open()}
                    />

                </div>

                <Form ref="edit" entity={this.props.entity} onSubmit={this.props.onUpdate} />
                <Views.Modal.Show ref="questions" title="Questions" style={{ width: '1400px' }}>
                    <Questions style={this.props.style} readOnly={true} templateId={this.props.entity.id} onSelect={(id => this.props.management.toggleQuestion(this.props.entity.id, id))}/>
                </Views.Modal.Show>
            </div>
        );
    }

}
