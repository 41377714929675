import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';

const styles = {
    container: {
        minHeight: 'auto',
    },
    heading: {
        margin: '15px 0',
        display: 'flex',
    },

    header: {
        marginLeft: '20px',
        width: '100%',
        overflow: 'hidden'
    },
    name: {
        fontSize: '23px',
        marginBottom: '15px',
        lineHeight: '24px'
    },
    title: {
        fontWeight: 'normal',
        borderBottom: '1px solid ' + colors.grey300,
        paddingBottom: '15px',
        marginBottom: '15px',
        paddingRight: '100px'
    },
    relevance: {
        float: 'right',
        color: colors.userCold,
        display: 'inline-block',
        fontSize: '23px',
        marginLeft: '10px',
    },
    country: {
        float: 'right',
        color: 'white',
        fontWeight: 'bold',
        display: 'inline-block',
        padding: '0 5px',
        fontSize: '13px',
        marginLeft: '10px',
        borderRadius: '3px',
        background: colors.grey500
    },

    subtitle: {
        display: 'inline-block',
        margin: '10px 0',
        color: colors.grey600
    },
    layer: {
        margin: '20px 0',
    },

    pictureContainer: {
        flexShrink: '0',
        flexGrow: '0',
        borderRadius: '3px',
        width: '150px',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    picturePlaceholder: {
        background: colors.grey200
    },
    pictureIcon: {
        width: '40px',
        height: '40px',
        fill: 'white'
    },
    pictureTag: {
        maxWidth: '150px',
        maxHeight: '200px',
        borderRadius: '3px',
    },

    content: {
        margin: '10px 0',
        fontSize: '15px',
        color: colors.grey800,
        textAlign: 'justify'
    },
    source: {
        display: 'block',
        padding: '10px',
        fontSize: '15px',
        color: colors.userCold,
        textDecoration: 'underline',
        background: 'white'
    },
    sourceHover: {
        background: colors.grey100
    },

    tabs: {
        display: 'flex'
    },

    tabItem: {
        padding: '10px',
        textAlign: 'center',
        background: 'transparent',
        color: colors.userCold,
        fontWeight: 'bold',
        flexGrow: '1',
        cursor: 'pointer'
    },
    tabCurrent: {
        background: colors.grey100,
        borderBottom: '2px solid ' + colors.userHot,
        cursor: 'default'
    },
    tabHover: {
        background: colors.grey100,
    },
    tabDisabled: {
        color: colors.grey400,
        cursor: 'default'
    },
    tabLabel: {
        verticalAlign: 'middle',
        display: 'inline-block',
        padding: '0 10px'
    },
    tabCount: {
        verticalAlign: 'middle',
        color: colors.userHot,
    },
    tabIcon: {
        verticalAlign: 'middle',
        fill: colors.grey400,
    },

    relationItem: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey100,
    },
    relationValue: {
        width: '50%',
        padding: '10px 0',
        color: colors.grey800
    },
    relationTitle: {
        margin: '25px 0',
        color: colors.userCold,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '17px'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.common.display';

    static propTypes = {
        flag: PropTypes.node,
        relevance: PropTypes.number,
        country: PropTypes.string,
        title: PropTypes.node,

        name: PropTypes.node,
        picture: PropTypes.string,

        properties: PropTypes.array,
        relations: PropTypes.array,
        contents: PropTypes.array,
        sources: PropTypes.array,
    };

    constructor (props) {
        super(props);
        this.state = { sourceHover: undefined };
        this.resolveState(props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.resolveState(props);
    }

    resolveState (props) {
        const contents = props.contents ? props.contents.slice().length : 0;
        const sources = props.sources ? props.sources.slice().length : 0;

        let relations = 0;
        let tab = 0;

        for (const group of this.props.relations ? this.props.relations.slice() : []) {
            relations += group.items.length;
        }

        if (contents) tab = 1;
        else if (relations) tab = 2;
        else if (sources) tab = 3;

        this.state = { contents, relations, sources, tab };
    }

    render () {
        return (
            <div style={ styles.container }>
                <div style={ styles.heading }>
                    { this.renderPicture() }

                    <div style={ styles.header }>
                        <div style={ styles.name }>
                            <span style={ styles.relevance }>
                                { Math.round(this.props.relevance) + ' %' }
                            </span>

                            { this.props.flag }
                            { this.props.name }
                        </div>

                        <div style={ styles.title }>
                            { this.props.title }
                        </div>

                        { this.renderProperties() }
                    </div>
                </div>

                <div style={ styles.tabs }>
                    { this.renderTab(1, 'Information', 'dna.identity', this.state.contents) }
                    { this.renderTab(2, t('common.label.relations'), 'dna.relations', this.state.relations) }
                    { this.renderTab(3, 'Sources', 'dna.source', this.state.sources) }
                </div>

                { this.renderContents() }
                { this.renderRelations() }
                { this.renderSources() }
            </div>
        );
    }

    renderTab (index, label, icon, count) {
        const style = Object.assign({},
            styles.tabItem,
            index === this.state.tab
                ? styles.tabCurrent
                : (count === 0
                    ? styles.tabDisabled
                    : (index === this.state.hover ? styles.tabHover : {})
                )
        );

        const events = count
            ? {
                onMouseEnter: event => this.setState({ hover: index }),
                onMouseLeave: event => this.setState({ hover: undefined }),
                onClick: event => this.setState({ tab: index })
            }
            : {};

        return (
            <div key={ 'tab' + index } style={ style } { ...events }>
                <Icon style={ styles.tabIcon } type={icon} />
                <span style={ styles.tabLabel }>{ label }</span>
                <span style={ styles.tabCount }>{ count > 0 ? count : '' }</span>
            </div>
        );
    }

    renderPicture () {
        if (! this.props.picture) {
            return (
                <div style={ Object.assign({}, styles.pictureContainer, styles.picturePlaceholder) }>
                    <Icon type="base.picture" style={ styles.pictureIcon } />
                </div>
            );
        }

        return (
            <div style={ styles.pictureContainer }>
                <img
                    style={ styles.pictureTag }
                    src={ this.props.picture }
                />
            </div>
        );
    }

    renderProperties () {
        const values = [];

        for (const property of this.props.properties ? this.props.properties.slice() : []) {
            if (typeof property === 'string') {
                values.push({ label: '', value: (<div style={ styles.subtitle }>{ property }</div>) })
            }

            if (typeof property === 'object') {
                values.push(property);
            }
        }

        return (<Views.Common.Properties values={ values } />);
    }

    renderContents () {
        if (this.state.tab !== 1) {
            return null;
        }

        const contents = this.props.contents ? this.props.contents.slice() : [];

        if (! contents.length) {
            return null;
        }

        return (
            <div style={ styles.layer }>
                { contents.map((content, index) => (
                    <div key={ 'c' + index } style={ styles.content }>
                        { content }
                    </div>
                ))}
            </div>
        );
    }

    renderRelations () {
        if (this.state.tab !== 2) {
            return null;
        }

        const results = [];

        for (const group of this.props.relations ? this.props.relations.slice() : []) {
            if (! group.items || ! group.items.length) {
                continue;
            }

            if (group.label) {
                results.push(
                    <div
                        key={ 'c' + results.length }
                        style={ styles.relationTitle }
                    >
                        { group.label}
                    </div>
                );
            }

            for (const item of group.items ? group.items.slice() : []) {
                results.push(
                    <div
                        key={ 'c' + results.length }
                        style={ styles.relationItem }
                    >
                        <span style={ styles.relationValue }>{ item.name }</span>
                        <span style={ styles.relationValue }>{ item.description || '—' }</span>
                    </div>
                );
            }
        }

        return results;
    }

    renderSources () {
        if (this.state.tab !== 3) {
            return null;
        }

        const sources =  this.props.sources ? this.props.sources.slice() : [];

        if (! sources.length) {
            return null;
        }

        return (
            <div style={ styles.layer }>
                { sources.map((source, index) => (
                    <a  key={ 's' + index }
                        style={ index === this.state.sourceHover ? Object.assign({}, styles.source, styles.sourceHover) : styles.source }
                        href={ source }
                        target="_blank"
                        onMouseEnter={ event => this.setState({ sourceHover: index }) }
                        onMouseLeave={ event => this.setState({ sourceHover: undefined }) }
                    >
                        { source }
                    </a>
                ))}
            </div>
        );
    }
}
