// @flow

import React from 'react';
import PropTypes from 'prop-types';
import t from 'app/translation';
import Block from './block';
import DataProvider from 'app/layout/providers/data';
import ProfileBody from './profile/body';
import ProfileEdit from './profile/edit';
import ServiceBody from './service/body';
import ServiceEdit from './service/edit';
import { inject } from 'mobx-react';

const styles = {
    container: {
        flexGrow: '1',
    },
};

@inject('global')
export default class extends React.Component<any, any> {
    static displayName = 'cleerance.admin.teams.details.settings';

    static propTypes = {
        id: PropTypes.string,
        global: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <DataProvider
                    service="cleerance"
                    model="team"
                    scope="public"
                    id={this.props.id}
                    renderValue={data => {
                        return (
                            <Block
                                title={t('cleerance.admin.teams.profile.title')}
                                body={<ProfileBody data={data} />}
                                edit={<ProfileEdit global={this.props.global} id={this.props.id} data={data} />}
                            />
                        );
                    }}
                />
                <DataProvider
                    service="cleerance"
                    model="team"
                    scope="settings"
                    id={this.props.id}
                    renderValue={data => {
                        const flags = data.flags || [];
                        const urls = data.urls || {};

                        return [
                            <Block
                                key="cleerance"
                                title={t('cleerance.admin.services.cleerance.title')}
                                body={
                                    <ServiceBody
                                        access={true}
                                        flags={{ 'cleerance.admin': flags.includes('cleerance.admin') }}
                                    />
                                }
                            />,
                            <Block
                                key="deeligenz"
                                title={t('cleerance.admin.services.deeligenz.title')}
                                body={
                                    <ServiceBody
                                        access={flags.includes('deeligenz.access')}
                                        url={urls.deeligenz}
                                        flags={{
                                            'deeligenz.analysis': flags.includes('deeligenz.analysis'),
                                            'deeligenz.calendar': flags.includes('deeligenz.calendar'),
                                            'deeligenz.library': flags.includes('deeligenz.library'),
                                        }}
                                    />
                                }
                                edit={
                                    <ServiceEdit
                                        global={this.props.global}
                                        id={this.props.id}
                                        service="deeligenz"
                                        data={data}
                                        flags={['analysis', 'calendar', 'library']}
                                    />
                                }
                            />,
                            <Block
                                key="deefind"
                                title={t('cleerance.admin.services.deefind.title')}
                                body={
                                    <ServiceBody
                                        access={flags.includes('deefind.access')}
                                        url={urls.deefind}
                                        flags={{ 'deefind.search': flags.includes('deefind.search') }}
                                    />
                                }
                                edit={
                                    <ServiceEdit
                                        global={this.props.global}
                                        id={this.props.id}
                                        service="deefind"
                                        data={data}
                                        flags={['search']}
                                    />
                                }
                            />,
                            <Block
                                key="deetect"
                                title={t('cleerance.admin.services.deetect.title')}
                                body={<ServiceBody access={flags.includes('deefind.deetect')} url={urls.deetect} />}
                                edit={
                                    <ServiceEdit
                                        global={this.props.global}
                                        id={this.props.id}
                                        service="deetect"
                                        data={data}
                                    />
                                }
                            />,
                            <Block
                                key="reech"
                                title={t('cleerance.admin.services.reech.title')}
                                body={<ServiceBody access={flags.includes('reech.access')} url={urls.reech} />}
                                edit={
                                    <ServiceEdit
                                        global={this.props.global}
                                        id={this.props.id}
                                        service="reech"
                                        data={data}
                                    />
                                }
                            />,
                            <Block
                                key="deel"
                                title={t('cleerance.admin.services.deel.title')}
                                body={<ServiceBody access={flags.includes('deel.access')} url={urls.deel} />}
                                edit={
                                    <ServiceEdit
                                        global={this.props.global}
                                        id={this.props.id}
                                        service="deel"
                                        data={data}
                                    />
                                }
                            />,
                            <Block
                                key="teem"
                                title={t('cleerance.admin.services.teem.title')}
                                body={<ServiceBody access={flags.includes('teem.access')} url={urls.teem} />}
                                edit={
                                    <ServiceEdit
                                        global={this.props.global}
                                        id={this.props.id}
                                        service="teem"
                                        data={data}
                                    />
                                }
                            />,
                        ];
                    }}
                />
            </div>
        );
    }
}
