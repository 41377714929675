// @flow

import type { SdkService, SdkWatch, MakeFollow } from '@deecision/infra-types/client';

import Logger from '../core/logger';
import Worker from './worker';
import Make from './make';
import Watch from './watch';
import Global from './global';
import File from './file';
import Model from './model';
import Queue from './queue';

export default function(build: string): SdkService {
    const logger = Logger(true);
    const worker = Worker(logger, build);
    const make = Make(worker, logger);
    const watch = Watch(worker, logger);

    return {
        logger,

        support: {
            socket: !!(global && global.WebSocket),
            crypto: !!(global.crypto && global.crypto.subtle && global.TextEncoder && global.TextDecoder),
            store: !!(global.indexedDB || global.mozIndexedDB || global.webkitIndexedDB || global.msIndexedDB),
            worker: !!(navigator && navigator.serviceWorker && navigator.serviceWorker.controller),
        },

        execute(service: string, command: string, params?: Object, follow?: MakeFollow): Promise<any> {
            return make({ type: 'execute', service, command, params: params || {} }, follow);
        },

        global: Global(worker, make),
        file: File(make),
        model: Model(watch),
        queue: Queue(make, watch),

        state: {
            store(service: string, path: string, value: any): Promise<void> {
                return make({ type: 'state.set', service, path, value });
            },

            watch(service: string, path: string): SdkWatch<any> {
                return watch({ type: 'state.watch', service, path }, 'state.value');
            },
        },
    };
}
