import PropTypes from 'prop-types';
import React from 'react';

import DeefindProvider from 'app/layout/providers/deefind';
import Stocks from 'dna/entity/common/stocks';
import Weasse from 'dna/entity/common/weasse';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.person.display.tabs.financial';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        view: PropTypes.string,
        children: PropTypes.object.isRequired,
    };

    render () {
        return (
            <DeefindProvider
                type="stocks"
                id={ this.props.entity.id }
                renderEntity={ (stocks, state) => {
                    if (this.props.view === 'tree') {
                        return (
                             <div>
                                 <Stocks entity={ this.props.entity } stocks={stocks} />
                             </div>
                         );
                     }

                    return (
                        <div>
                            <Weasse entity={ this.props.entity } stocks={stocks} children={this.props.children} />
                        </div>
                    )
                }}
            />
        );
    }
}
