import * as colors from 'material-ui/styles/colors';

colors.userHot = 'rgb(229, 57, 53)';
colors.userCold = colors.blue900; // 'rgb(121, 134, 203)';
colors.userGreen = 'rgb(124, 179, 66)';
colors.userWarning = 'rgb(255, 152, 0)';
colors.userLogo = 'rgb(76, 97, 95)';

colors.halfHot = 'rgba(229, 57, 53, 0.5)';
colors.halfCold = 'rgba(121, 134, 203, 0.5)';
colors.halfGreen = 'rgba(124, 179, 66, 0.5)';
colors.halfWarning = 'rgba(255, 152, 0, 0.5)';

colors.bgHot = 'rgba(229, 57, 53, 0.075)';
colors.bgCold = 'rgba(121, 134, 203, 0.075)';
colors.bgGreen = 'rgba(124, 179, 66, 0.075)';
colors.bgWarning = 'rgba(255, 152, 0, 0.075)';
colors.bgBlack = 'rgba(0, 0, 0, 0.075)';

// colors.black = colors.userText;
// colors.grey900 = colors.userText;
// colors.grey800 = colors.userText;

export default colors;
