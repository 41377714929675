import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';

const ACTIVATIONS = {
    'M': { external: 'External manager', delegated: 'Delegated management' },
    'F': { traditional: 'Traditional', passive: 'Passive/ETF', equity: 'Private Eq.', alternative: 'Alternative' },
    'S': { traditional: 'Traditional', passive: 'Passive/ETF', equity: 'Private Eq.', alternative: 'Alternative' },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.questions.category.form';

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    state = { kind: '?' };

    open (data) {
        if (! data.payload.variant) data.payload.variant = 'all';
        this.setState({ kind: data.payload.kind || '?' }, () => this.refs.dialog.open(data));
    }

    render () {
        const activations = ACTIVATIONS[this.state.kind];

        return (
            <Views.Modal.Edition
                ref="dialog"
                label="Save category"
                onSubmit={ this.props.onSubmit }
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        size={ 3 }
                        label="Name"
                        path="payload.label"
                        widget={{ type: 'string' }}
                        required
                    />

                    <Views.Form.Field
                        label="Weight"
                        path="payload.weight"
                        widget={{ type: 'number' }}
                    />
                </Views.Form.Row>
                <Views.Form.Row>
                    <Views.Form.Field
                        label="Default activation"
                        path="payload.activations"
                        widget={{ type: 'bools', choices: activations || {} }}
                        readOnly={!activations}
                    />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
