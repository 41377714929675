import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Value from './select/value';
import Item from './select/item';
import Choices from './common/choices';
import Popover from 'material-ui/Popover/Popover';
import colors from 'app/theme/colors';

const styles = {
    container: {
        padding: '5px',
        display: 'block',
        overflow: 'none',
        position: 'relative',
    },
    popover: {
        border: '1px solid ' + colors.userCold,
        margin: '-1px',
    },
    input: {
        position: 'absolute',
        top: '-10002999px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.select';

    static propTypes = {
        placeholder: PropTypes.string,
        value: PropTypes.any,
        choices: Choices.propType,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    state = {
        hover: false,
        open: false,
    };

    render () {
        const choices = Choices(this.props.choices);

        return (
            <div style={ Object.assign({}, this.props.style, styles.container) }>
                <input
                    type="text"
                    style={ styles.input }
                    onFocus={ this.props.onFocus }
                />

                <Value
                    ref={ element => this.popoverAnchor = ReactDOM.findDOMNode(element) }
                    placeholder={ this.props.placeholder }
                    choice={ choices.find(this.props.value) }
                    onOpen={ event => {
                        this.setState({ open: true });
                        this.props.onFocus(event);
                    } }
                />

                { this.renderPopover(choices) }
            </div>
        );
    }

    renderPopover (choices) {
        if (! this.popoverAnchor)
            return null;

        return (
            <Popover
                anchorEl={ this.popoverAnchor }
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                style={ Object.assign({ minWidth: this.popoverAnchor.clientWidth }, styles.popover) }
                open={ this.state.open }
                onRequestClose={ event => this.setState({ open: false }) }
            >
                { choices.map(this.renderChoice.bind(this)) }
            </Popover>
        );
    }

    renderChoice (choice) {
        return (
            <Item
                key={ choice.key }
                title={ choice.title }
                icon={ choice.icon }
                subtitle={ choice.subtitle }
                selected={ choice.value === this.props.value }
                onSelect={ event => {
                    this.setState({ open: false });
                    this.props.onChange(choice.value);
                } }
            />
        );
    }
}
