// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'app/views/common/button';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';

const styles = {
    container: {
        display: 'flex',
    },
    icon: {
        margin: '15px 0 0 15px',
        padding: '10px',
        width: '30px',
        height: '30px',
        fill: colors.white,
        background: colors.grey300,
        borderRadius: '50%',
    },
    content: {
        margin: '15px',
        width: '300px',
    },
    title: {
        fontSize: '19px',
        paddingBottom: '5px',
        color: colors.grey700,
    },
    email: {
        fontSize: '13px',
        paddingBottom: '20px',
        color: colors.grey500,
    },
    actions: {
        display: 'flex',
    },
    logout: {
        marginLeft: '10px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.layout.application.header.account';

    static propTypes = {
        user: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
        onLogout: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <Icon type="dna.person" style={styles.icon} />

                <div style={styles.content}>
                    <div style={styles.title}>
                        {this.props.user.profile.firstName
                            ? this.props.user.profile.firstName + ' ' + this.props.user.profile.lastName
                            : this.props.user.profile.name}
                    </div>

                    <div style={styles.email}>
                        {this.props.user.email}
                    </div>

                    <div style={styles.actions}>
                        <Button
                            type="raised"
                            color="cold"
                            label={t('common.profile.editButton')}
                            icon={<Icon type="do.update" />}
                            onClick={() => this.props.onEdit()}
                        />

                        <Button
                            type="raised"
                            color="hot"
                            label={t('common.profile.logoutButton')}
                            icon={<Icon type="do.close" />}
                            style={styles.logout}
                            onClick={() => this.props.onLogout()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
