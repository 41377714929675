import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '20px',
        height: '20px',
        fill: colors.userCold,
    },
    title: {
        display: 'inline-block',
        marginLeft: '35px',
        fontSize: '17px',
        marginBottom: '2px',
        lineHeight: '20px',
        width: 'calc(100% - 85px)',
    },
    percentage: {
        display: 'inline-block',
        marginLeft: '0px',
        fontSize: '17px',
        marginBottom: '2px',
        lineHeight: '20px',
        textAlign: 'right',
        width: '42px',
    },
    owner: {
        marginLeft: '35px',
        fontSize: '13px',
        color: colors.grey500,
        display: 'block',
    },
    date: {
        marginLeft: '35px',
        fontSize: '13px',
        color: colors.userCold,
        display: 'block',
    },
    description: {
        marginTop: '15px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.milestone.preview';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render() {
        const checklist = (this.props.entity.payload.checklist || []);
        const percentage = checklist.length
            ? checklist.reduce((sum, { percentage }) => sum + percentage, 0) / checklist.length
            : 100;

        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                <Icon type="comeet.milestone" style={styles.icon} />

                <div style={styles.title}>{this.props.entity.payload.title}</div>
                <div style={styles.percentage}>{(isNaN(percentage) ? 0 : percentage.toFixed(0)) + '%'}</div>

                <Views.Common.Moment date={this.props.entity.payload.date} style={styles.date} />
                <Views.Common.User id={this.props.entity.ownerUid} style={styles.owner} />

                <Views.Common.Description value={this.props.entity.payload.description} style={styles.description} />
            </div>
        );
    }
}
