import PropTypes from 'prop-types';
import React from 'react';
import Container from './button/container';

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.button';

    static propTypes = {
        entities: PropTypes.array,
        type: PropTypes.string,
        color: PropTypes.string,
        preview: PropTypes.element.isRequired,
        target: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired,
    };

    render () {
        return (
              <Container
                  type={ this.props.type }
                  preview={ this.props.preview }
                  color={ this.props.color }
                  entities={this.props.entities}
                  target={this.props.target}
                  handleSubmit={ this.props.handleSubmit }
                  handleDelete={ this.props.handleDelete }
              />
        );
    }
}
