import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import { inject } from 'mobx-react';
import Dialog from 'material-ui/Dialog';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import slugify from 'speakingurl';

@inject((stores) => ({ store: stores.deeligenzStudies, drive: stores.drive }))
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.library.studies.download';

    static propTypes = {
        store: PropTypes.object,
        drive: PropTypes.object,
        entity: PropTypes.object,
    };

    state = { open: false };

    render() {
        const disabled = !['confirmed', 'ignoredRedFlag', 'archive'].includes(this.props.entity.status);

        const items = disabled
            ? []
            : this.props.entity.status === 'archive'
            ? (this.props.entity.payload.archive.files || []).map((file) => (
                  <MenuItem
                      key={file.id}
                      primaryText={file.name}
                      onClick={async () =>
                          this.props.drive.download(
                              { service: 'deeligenz', bucket: 'deeligenz', id: file.id },
                              file.name,
                          )
                      }
                  />
              ))
            : [
                  <MenuItem
                      key="summary"
                      primaryText="Summary report"
                      onClick={async () => await this.props.store.downloadSummaryReport(this.props.entity.id, 'summary-report.pdf')}
                  />,

                  (this.props.entity.type === 'odd') &&
                  <MenuItem
                      key="full"
                      primaryText="Full report"
                      onClick={async () => await this.props.store.downloadFullReport(this.props.entity.id, 'full-report.pdf')}
                  />,
              ];

        return (
            <>
                <Views.Common.Button
                    type="round"
                    color="cold"
                    icon={<Icon type="do.download" />}
                    disabled={disabled}
                    onClick={(event) => this.setState({ open: true })}
                />

                <Dialog
                    open={this.state.open}
                    autoScrollBodyContent={true}
                    onRequestClose={() => this.setState({ open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    targetOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Menu>{items}</Menu>
                </Dialog>
            </>
        );
    }

    handle(type, data) {
        const name =
            this.props.entity.payload.target.type === 'manager'
                ? this.props.entity.payload.manager
                : this.props.entity.payload.fund;

        const filename = slugify(`${type}-${name}`);
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', data);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', `${filename}.pdf`);
        element.style.display = '';
        element.click();
        document.body.removeChild(element);

        this.setState({ open: false });
    }
}
