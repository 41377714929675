// @flow

import type { ResolverSpecs } from 'app/stores/state/types';
import type StateNode from 'app/stores/state/node';
import type StateStore from 'app/stores/state/index';

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

@inject('state')
@observer
export default class ScopeProvider extends React.Component<any, any> {
    static displayName = 'app.layout.providers.scope';

    static contextTypes = {
        stateNode: PropTypes.object
    };

    static childContextTypes = {
        stateNode: PropTypes.object.isRequired,
        updateState: PropTypes.func.isRequired,
        entities: PropTypes.object,
        entity: PropTypes.object,
    };

    node: StateNode;

    props: {
        state: StateStore,
        path: string,
        type: string | ResolverSpecs,
        debug?: boolean,
        children: any,
        entity?: Object,
        entities?: Object[],
    };

    constructor (props: any, context: any) {
        super(props, context);
        this.state = {};
        this.node = this.getNode(props, context);

        if (props.debug) {
            console.log('[scope.constructor]', props.path, this.state.data);
        }
    }

    getChildContext() {
        if (this.props.debug) {
            console.log('[scope.child]', this.props.path, this.node.path.segments);
        }

        return {
            stateNode: this.node,
            updateState: this.props.state
                ? (values: Object) => this.props.state.update(this.node.path, values)
                : (values: Object) => console.error(`Updated non initialized state`)
        };
    }

    UNSAFE_componentWillReceiveProps (props: any, context: any) {
        this.node = this.getNode(props, context);

        if (props.debug) {
            console.log('[scope.update]', props.path);
        }
    }

    componentWillUnmount () {
        if (this.state.dispose) {
            this.state.dispose();
        }
    }

    getNode (props: any, context: any): StateNode {
        const parent = context.stateNode
            ? context.stateNode
            : props.state.nodes.get('');

        const data = toJS(props.state.data) || {};
        const path = parent.locate(data, props.path);

        if (props.debug) {
            console.log('[scope.parent]', props.path, context.stateNode ? context.stateNode.path.segments : undefined);
            console.log('[scope.locate]', props.path, path.segments);
            console.log('[scope.type]', props.type);
        }

        let node;

        if (typeof props.type === 'object') {
            node = props.state.define(path, props.type);
        }

        if (typeof props.type === 'string') {
            node = props.state.get(path, props.type);
        }

        if (! node) {
            throw new Error(`Found invalid scope type: ${JSON.stringify(props.type)}`);
        }

        // if (this.state.dispose) {
        //     this.state.dispose();
        // }
        //
        // this.state.dispose = node.listen(data => {
        //     if (props.debug && node) {
        //         console.log('[scope.listen]', node.path.toString(), data);
        //     }
        //
        //     this.setState({ data });
        // });
        //
        // this.state.data = node.read(data);
        //
        // if (props.debug) {
        //     console.log('[scope.init]', node.path.toString(), toJS(props.state.data));
        // }

        return node;
    }

    render () {
        const state = this.node.normalize(this.node.read(toJS(this.props.state.data) || {}));

        if (this.props.debug) {
            console.log('[scope.render]', this.node.path.toString(), state);
        }

        const props: Object = { state };

        if (this.props.entity) {
            props.entity = this.props.entity;
        }

        if (this.props.entities) {
            props.entities = this.props.entities;
        }

        return React.cloneElement(React.Children.only(this.props.children), props);
    }
}
