import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import Sum from 'dna/entity/common/display/sum';
import t from 'app/translation';
import { get } from 'lodash';

const styles = {

};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.cockpit.identity';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
        return (
            <div style={{marginTop: '5px', marginRight: '5px', marginLeft: '5px', border: '2px solid '+colors.grey400 }}>
                { this.renderHeader() }
                <div>
                  <Views.Common.Properties
                      style={{padding: '5px'}}
                      values={[
                          {
                              label: t('common.label.weasse'),
                              value: <Sum data={ get(this.props.entity, 'preview.weasse') } />
                          },
                          {
                              label: t('dna.deefind.veegilenzData.person.weasseMaxLabel'),
                              value: <Sum data={ get(this.props.network, 'preview.weasseMax') } />
                          },
                          {
                              label: t('common.label.fortune'),
                              value: (
                                  <Views.Common.Number
                                      type="currency"
                                      scale="M"
                                      value={ this.props.entity.preview.fortune }
                                  />
                              )
                          }
                      ]}
                      fallback="No known financial values"
                      align={ 90 }
                  />
                </div>
            </div>
        );
    }

    renderHeader() {
      return (
        <div style={{backgroundColor: colors.grey200, padding: '5px'}}>
          <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%',textAlign: 'left' }}>
            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.identity" />
          </div>
          <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '40%',textAlign: 'left', color: colors.userCold }}>
            Identity
          </div>
        </div>
      );
    }
}
