import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/index';
import Sdk from '@deecision/infra-client/sdk';
import uuid from 'uuid';

const sdk = Sdk(process.env.APP_BUILD || uuid.v4());

ReactDOM.render(<App sdk={sdk} />, document.getElementById('main'));

if (module && module.hot) {
    module.hot.accept('./app/index', () => {
        const App = require('./app/index').default;
        ReactDOM.render(<App sdk={sdk} />, document.getElementById('main'));
    });
}
