// @flow

import type {
    CompanyData,
    EntityType,
    FileAttachment, LinkProfile,
    PersonData,
    VigilanceNorkom,
    VigilanceReportDirect,
} from '@deecision/deefind-types/model';

import getAnswers from './answers';
import makeNorkomScore from './questions';
import _ from 'lodash';

export function makeVigilanceNorkomNetwork(current: VigilanceNorkom[], ceased: VigilanceNorkom[]): VigilanceNorkom {
    const scores = current.concat(ceased);

    const thirdPartyIds = {
        CountryOfOrigin: { score: 0, label: 'Pays de naissance' },
        CountryOfIncorporation: { score: 0, label: "Pays d'immatriculation" },
        SensitiveOccupationFlag: { score: 0, label: 'Top Fonction Sensible' },
        SanctionedFlag: { score: 0, label: 'Client cible de sanction' },
        CountriesOfAddress: { score: 0, label: "Pays d'adresse" },
        Nationality: { score: 0, label: 'Nationalité' },
    };
    const result = { score: 0, missed: [], values: [] };
    let total = 0;
    for (const id in thirdPartyIds) {
        for (const score of scores) {
            const currentItem = _.filter(score.values, function(o) {
                return o.id == id;
            });
            const value = _.get(currentItem[0], 'score');
            if (value > _.get(thirdPartyIds[id], 'score')) {
                currentItem[0]['id'] = `Tiers${currentItem[0]['id']}`;
                currentItem[0]['label'] = `Tiers Lié - ${currentItem[0]['label']}`;
                thirdPartyIds[id] = currentItem[0];
            }
        }
        if (_.get(thirdPartyIds[id], 'score') > 0) {
            total += thirdPartyIds[id]['score'];
            result.values.push(thirdPartyIds[id]);
        } else {
            result.missed.push(`Tiers Lié - ${thirdPartyIds[id].label}`);
        }
    }
    result.score = total;

    return result;
}

export function makeVigilanceNorkom(
    type: EntityType,
    data: CompanyData | PersonData,
    files: FileAttachment[],
    links: LinkProfile[]
): VigilanceNorkom {
    const answers = getAnswers(type, data, files, links);

    return answers ? makeNorkomScore(answers) : { score: 0, missed: [], values: [] };
}
