import Moment from 'moment';
import { merge } from 'lodash';

export default function(studies) {
  let computed = {};
  for (const study of Object.values(studies)) {
    const started = getStarted(study.history);
    if (!started) {
      continue;
    }

    const input = getInput(study, started);
    const ddq = getDDQ(study, input);
    const onSite = getOnSite(study, ddq);
    const report = getReport(study, onSite);

    let cursor = Moment(started);

    // INPUT
    for (let i = 0; i < Math.ceil(input.diff(started, 'days', true)); i++) {
      if (!computed[cursor.format('YYYY-MM-DD')]) {
        computed[cursor.format('YYYY-MM-DD')] = {};
      }

      if (!computed[cursor.format('YYYY-MM-DD')][study.ownerUid]) {
        computed[cursor.format('YYYY-MM-DD')][study.ownerUid] = [];
      }

      computed[cursor.format('YYYY-MM-DD')][study.ownerUid].push({status: 'input', study: study.id, warning: computed[cursor.format('YYYY-MM-DD')][study.ownerUid].filter(item => item.study === study.id).length ? true : false});
      cursor.add(1, 'days');
    }

    //  DDQ

    for (let i = 0; i < Math.ceil(ddq.diff(input, 'days', true)); i++) {
      if (!computed[cursor.format('YYYY-MM-DD')]) {
        computed[cursor.format('YYYY-MM-DD')] = {};
      }

      if (!computed[cursor.format('YYYY-MM-DD')][study.ownerUid]) {
        computed[cursor.format('YYYY-MM-DD')][study.ownerUid] = [];
      }

      computed[cursor.format('YYYY-MM-DD')][study.ownerUid].push({status: 'ddq', study: study.id, warning: computed[cursor.format('YYYY-MM-DD')][study.ownerUid].filter(item => item.study === study.id).length ? true : false});
      cursor.add(1, 'days');
    }

    // onSite

    if (!computed[Moment(onSite).format('YYYY-MM-DD')]) {
      computed[Moment(onSite).format('YYYY-MM-DD')] = {};
    }

    if (!computed[Moment(onSite).format('YYYY-MM-DD')][study.ownerUid]) {
      computed[Moment(onSite).format('YYYY-MM-DD')][study.ownerUid] = [];
    }

    computed[Moment(onSite).format('YYYY-MM-DD')][study.ownerUid].push({status: 'onSite', study: study.id, warning: computed[Moment(onSite).format('YYYY-MM-DD')][study.ownerUid].filter(item => item.study === study.id).length ? true : false});

    // REPORT
    cursor = Moment(onSite);
    cursor.add(1, 'days');

    for (let i = 0; i < Math.ceil(report.diff(onSite, 'days', true)); i++) {
      if (!computed[cursor.format('YYYY-MM-DD')]) {
        computed[cursor.format('YYYY-MM-DD')] = {};
      }

      if (!computed[cursor.format('YYYY-MM-DD')][study.ownerUid]) {
        computed[cursor.format('YYYY-MM-DD')][study.ownerUid] = [];
      }

      computed[cursor.format('YYYY-MM-DD')][study.ownerUid].push({status: 'report', study: study.id, warning: computed[cursor.format('YYYY-MM-DD')][study.ownerUid].filter(item => item.study === study.id).length ? true : false});
      cursor.add(1, 'days');
    }
  }

  return computed;
}

function getStarted(history) {
  for (const item of history) {
    if (item.transition === 'start') {
      return Moment(item.createStamp.substr(0, 19));
    }
  }

  return null;
}

function getInput(study, started) {
  for (const item of study.history) {
    if (item.transition === 'approveInput') {
      return Moment(item.createStamp.substr(0, 19));
    }
  }

  return addWorkDay(Moment(started), study.payload.durations.input);
}

function getDDQ(study, input) {
  for (const item of study.history) {
    if (item.transition === 'approveDdq') {
      return Moment(item.createStamp.substr(0, 19));
    }
  }

  return addWorkDay(Moment(input), study.payload.durations.ddq);
}

function getOnSite(study, ddq) {
  if (study.payload.onSite && study.payload.onSite.finalDate) {
    return Moment(study.payload.onSite.finalDate)
  }

    return addWorkDay(Moment(ddq), study.payload.durations.onSite);
}

function getReport(study, onSite) {
  for (const item of study.history) {
    if (item.transition === 'approveReport') {
      return Moment(item.createStamp.substr(0, 19));
    }
  }

  return addWorkDay(Moment(onSite), study.payload.durations.report);
}

function addWorkDay(start, length) {
  for (let i = 0; i < length; i++) {
    start.add(1, 'days');
    // if (start.day() === 6) {
    //   start.add(2, 'days');
    // } else if (start.day() === 0) {
    //   start.add(1, 'days');
    // }
  }

  return start;
}
