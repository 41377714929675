import React from 'react';
import PropTypes from 'prop-types';

import { forEach } from 'lodash';


const styles = {
    container: {},
    table: {
        height: '80px',
        width: '100% !important',
        display: 'flex',
        boxSizing: 'border-box',
        marginLeft: '20px',
        textDecoration: 'none',
    },
    element: {
        borderTop: '1px solid rgb(224, 224, 224)',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '.15rem',
        paddingRight: '.15rem',
        verticalAlign: 'top !important',
        boxSizing: 'border-box',
        minWidth: '120px',
        width: '15%',
        cursor: 'pointer',
    },
    title: {
        opacity: '.76',
        color: '#171f2a',
        fontSize: '.7rem',
        lineHeight: '14px',
        marginBottom: '.4rem',
        boxSizing: 'border-box',
        marginTop: '0',
        display: ' block',
        marginBlockEnd: '.5em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
        lineHeight: '15px',
        maxHeight: '30px',
        marginTop: '0',
        marginBottom: '.5rem',
        boxSizing: 'border-box',
        marginBlockEnd: '.5em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        color: '#000',
        fontSize: '.8rem',
    },
    hover: {
        backgroundColor: 'rgb(245, 245, 245)',
    }
}

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.weasse.listPatents';

    static propTypes = {
        items: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hover: undefined,
        };
    }

    render() {
        return (
            <div style={styles.container}>{this.renderPatents()}</div>
        );
    }

    getList(list) {
        let result = '';
        let i = 0;
        forEach(list, (item) => {
            if (i > 0) result += ', ';
            result += item.orgname || item.lastname || item.text;
            i++;
        })
        return result;
    }

    renderPatents() {
        const result = [];

        forEach(this.props.items, (patent) => {

            const cellProps = {
                onMouseEnter: event => this.setState({ hover: patent._id }),
                onMouseLeave: event => this.setState({ hover: undefined }),
            };

            const deposants = this.getList(patent.applicants);
            const inventeurs = this.getList(patent.inventors);
            const classifications = this.getList(patent.classificationsIpcr);

            const table = this.state.hover === patent._id ? Object.assign({}, styles.table, styles.hover) : styles.table;
            const titre = Object.assign({ }, styles.element, { minWidth: '180px', width: '25%' });
            const invent = Object.assign({ }, styles.text, { color: '#ca151b', fontWeight: '600' });

            result.push(
                <a key={patent._id} style={table} {...cellProps} target='_blank' href={ 'https://data.inpi.fr/brevets/' + patent.publicationReferenceNumberWithCountryCode }>
                    <div style={titre}>
                        <p style={styles.title}>Titre</p>
                        <p style={styles.text}><span>{patent.inventionTitle || ''}</span></p>
                    </div>
                    <div style={styles.element}>
                        <p style={styles.title}>Publication</p>
                        <p style={styles.text}><span>{(new Date(patent.publicationReference.date)).toLocaleDateString()}</span></p>
                    </div>
                    <div style={styles.element}>
                        <p style={styles.title}>N° du brevet</p>
                        <p style={styles.text}><span>{patent.publicationReferenceNumberWithCountryCode}</span></p>
                    </div>
                    <div style={styles.element}>
                        <p style={styles.title}>Déposant</p>
                        <p style={styles.text}><span>{deposants}</span></p>
                    </div>
                    <div style={styles.element}>
                        <p style={styles.title}>Inventeur</p>
                        <p style={invent}><span>{inventeurs}</span></p>
                    </div>
                    <div style={styles.element}>
                        <p style={styles.title}>Classification CIB</p>
                        <p style={styles.text}><span>{classifications}</span></p>
                    </div>
                </a>
            );
        });

        return result;
    }
}
