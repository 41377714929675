import type {
    ApiError,
    PortWatchQuery,
    SdkModel,
    SdkWatch,
    SdkWatcher,
    StoreDataEntity,
    StoreIndiceList,
    StoreIndiceQuery,
} from '@deecision/infra-types/client';

export default function<T>(watch: SdkWatcher<T>): SdkModel {
    function get<T>(query: PortWatchQuery, type: 'string'): Promise<T> {
        const watcher = watch(query, type);

        return new Promise((resolve, reject) => {
            watcher.onError((error: ApiError) => {
                reject(error);
                watcher.end();
            });

            watcher.onValue((value: T) => {
                resolve(value);
                watcher.end();
            });
        });
    }

    return {
        watchList(service: string, model: string, where: StoreIndiceQuery): SdkWatch<StoreIndiceList> {
            return watch({ type: 'model.watch.list', service, model, where }, 'model.list');
        },

        watchScope(service: string, model: string, id: string, scope: string): SdkWatch<Object> {
            return watch({ type: 'model.watch.scope', service, model, id, scope }, 'model.scope');
        },

        watchEntity(service: string, model: string, id: string, scopes?: string[]): SdkWatch<StoreDataEntity> {
            return watch({ type: 'model.watch.entity', service, model, id }, 'model.entity');
        },

        watchCollection(service: string, model: string, where: StoreIndiceQuery): SdkWatch<StoreDataEntity[]> {
            return watch({ type: 'model.watch.collection', service, model, where }, 'model.collection');
        },

        getList(service: string, model: string, where: StoreIndiceQuery): Promise<StoreIndiceList> {
            return get({ type: 'model.watch.list', service, model, where }, 'model.list');
        },

        getScope(service: string, model: string, id: string, scope: string): Promise<Object> {
            return get({ type: 'model.watch.scope', service, model, id, scope }, 'model.scope');
        },

        getEntity(service: string, model: string, id: string, scopes?: string[]): Promise<StoreDataEntity> {
            return get({ type: 'model.watch.entity', service, model, id, scopes }, 'model.entity');
        },

        getCollection(service: string, model: string, where: StoreIndiceQuery): Promise<StoreDataEntity[]> {
            return get({ type: 'model.watch.collection', service, model, where }, 'model.collection');
        },
    };
}
