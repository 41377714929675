// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        padding: '10px',
        background: colors.grey200,
        borderRadius: '3px',
        lineHeight: '20px',
        alignItems: 'center',
    },
    circle: {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        border: '5px solid ' + colors.grey500,
        borderRadius: '50%',
    },
    color: {
        display: 'inline-block',
        width: '15px',
        height: '15px',
        marginLeft: '20px',
        borderRadius: '3px',
    },
    label: {
        display: 'inline-block',
        color: colors.grey500,
        marginLeft: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.results.legend';

    static propTypes = {
        style: PropTypes.object,
    };

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <span style={styles.circle} />
                <span style={styles.label}>Completeness</span>
                <span style={Object.assign({}, styles.color, { background: colors.userHot })} />
                <span style={styles.label}>Low</span>
                <span style={Object.assign({}, styles.color, { background: colors.userWarning })} />
                <span style={styles.label}>Mid</span>
                <span style={Object.assign({}, styles.color, { background: colors.userGreen })} />
                <span style={styles.label}>High</span>
            </div>
        );
    }
}
