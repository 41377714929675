import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

// http://codepen.io/martinwolf/pen/qlFdp

const styles = {
    container: {
        fontSize: '13px',
        color: colors.grey700,
        whiteSpace: 'normal'
    },

    light: {
        fontSize: '11px',
        color: colors.grey500
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.description';

    static propTypes = {
        value: PropTypes.string,
        light: PropTypes.bool,
        style: PropTypes.object
    };

    render () {
        if (! this.props.value) {
            return null;
        }

        const style = Object.assign({},
            styles.container,
            this.props.light ? styles.light : {},
            this.props.style
        );

        return (
            <div style={ style }>
                { this.props.value }
            </div>
        );
    }
}
