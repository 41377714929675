import React from "react";
import * as d3 from "d3";

const data1 = [1];
const data2 = [1];
const data3 = [1];

const dataconfort = [0.30];
const dataexplore = [0.50];
const datadanger = [0.20];

const mininnerRadius = 0;
const maxouterRadius = 350;

const outerOne = dataconfort * maxouterRadius;
const innerTow = outerOne;
const outerTow = (dataexplore * maxouterRadius) + innerTow;
const innerTree = outerTow ;
const outerTree = (datadanger * maxouterRadius) + innerTree;


const arcOne = d3.arc().innerRadius(innerTow).outerRadius(outerTow);

const arcTwo = d3.arc().innerRadius(mininnerRadius).outerRadius(outerOne);

const arctree = d3.arc().innerRadius(innerTree).outerRadius(outerTree);

const pie = d3.pie().sort(null);

const getArcAttr = (d) => ({
    startAngle: d.startAngle,
    endAngle: d.endAngle
});

const emptyGrey = "gainsboro";

const colorOne = "#7cb342";
const colorTwo = "#ff9800";
const colorTree = "#e53935";

export class Concentric2 extends React.PureComponent<any, any> {
    constructor() {
        super();

        this._containerRef = React.createRef();
    }

    componentDidMount() {
        this.attachChart();
    }

    attachChart() {
        this._svg = d3
            .select(this._containerRef.current)
            .append("svg")
            .attr("width", 800)
            .attr("height", 800);

        this._svg
            .append("g")
            .attr("transform", "translate(400 400)")
            .selectAll("path")
            .data(pie(data1))
            .enter()
            .append("path")
            .on("mouseover", function (d) {
                d3.select(this).style("opacity", 0.5);
            })
            .on("mouseout", function () {
                d3.select(this).style("opacity", 1);
            })
            .attr("d", (d) => arcOne(getArcAttr(d)))
            .attr("fill", (_, i) => (i === 0 ? colorOne : emptyGrey));

        this._svg
            .append("g")
            .attr("transform", "translate(400 400)")
            .selectAll("path")
            .data(pie(data2))
            .enter()
            .append("path")
            .on("mouseover", function (d) {
                d3.select(this).style("opacity", 0.5);
            })
            .on("mouseout", function () {
                d3.select(this).style("opacity", 1);
            })
            .attr("d", (d) => arcTwo(getArcAttr(d)))
            .attr("fill", (_, i) => (i === 0 ? colorTwo : emptyGrey));

        this._svg
            .append("g")
            .attr("transform", "translate(400 400)")
            .selectAll("path")
            .data(pie(data3))
            .enter()
            .append("path")
            .on("mouseover", function (d) {
                d3.select(this).style("opacity", 0.5);
            })
            .on("mouseout", function () {
                d3.select(this).style("opacity", 1);
            })
            .attr("d", (d) => arctree(getArcAttr(d)))
            .attr("fill", (_, i) => (i === 0 ? colorTree : emptyGrey));
    }

    render() {
        return <div ref={this._containerRef} />;
    }
}
