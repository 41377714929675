// @flow

import type { ResolverInterface, ResolverSpecs } from '../../types';
import type StateStore from '../../index';
import type StatePath from '../../path';
import type StateNode from '../../node';

export default class ListResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
    }

    register (store: StateStore) {
        store.define(this.path.concat('reducers'), {
            type: 'object',
            prototype: 'string',
        });
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        return value;

        // return {
        //     reducers: node
        //         .get('reducers', 'object')
        //         .read(value ? value.reducers : undefined, external),
        // };
    }
}
