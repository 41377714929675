import PropTypes from 'prop-types';
import React from 'react';

import DeefindProvider from 'app/layout/providers/deefind';
import PersonDisplay from './person/display';
import CompanyDisplay from 'dna/entity/company/display';
import { inject } from 'mobx-react';

@inject('deefind')
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.display';

    static propTypes = {
        id: PropTypes.string.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <DeefindProvider
                state={this.props.state}
                type="display"
                id={this.props.id}
                renderEntity={(entity, state) => {
                    switch (entity.type) {
                        case 'person':
                            return <PersonDisplay state={state} entity={entity} />;

                        case 'company':
                            return <CompanyDisplay state={state} entity={entity} />;

                        default:
                            console.error('Invalid entity for display:', entity);
                    }
                }}
            />
        );
    }
}
