import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

import Budget from 'comeet/common/prospects/budget';
import Form from 'comeet/common/prospects/form';
import EventForm from 'comeet/common/events/form';
import MilestoneForm from 'comeet/common/milestones/form';

import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';

import { computeProspectValues } from '../../common/compute';
import { toJS } from 'mobx';

import { inject, observer } from 'mobx-react';

import t from 'app/translation'; 

const styles = {
    container: {
        display: 'flex',
        marginBottom: '30px',
    },
    main: {
        width: 'auto',
        flexGrow: '1',
        display: 'flex',
    },
    avatar: {
    },
    title: {
        lineHeight: '40px',
        fontSize: '19px',
        margin: '0 20px',
    },
    budget: {
        fontSize: '19px',
        lineHeight: '40px'
    },
    actions: {
        width: 'auto',
        flexGrow: '0',
        display: 'flex',
    },
    separator: {
        margin: '8px 15px',
        width: '0',
        borderLeft: '1px solid ' + colors.grey400,
    },
    properties: {
        padding: '20px',
    },
    property: {
        lineHeight: '25px',
    },
    status: {
        lineHeight: '40px',
    },
    statusIcon: {
        float: 'left',
        margin: '8px 10px 8px 0',
    }
};

@inject(stores => ({ store: stores.comeetProspects }))

@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.prospects.display.header';

    static contextTypes = {
        startProspect: PropTypes.func.isRequired,
        endProspect: PropTypes.func.isRequired,
        updateProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        createMilestone: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        cost: PropTypes.number.isRequired,
    };

    render () {
        const target = (this.props.entity.payload.target) || {};
        const values = computeProspectValues(this.props.entity.payload);

        return (
            <div style={ styles.container }>
                <div style={ styles.main }>
                    <Avatar
                        style={ styles.avatar }
                        icon={ <Icon type="comeet.prospect" /> }
                    />

                    <div style={ styles.title }>
                        { target.name || '<no name defined>' }
                    </div>

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        label={
                            <Budget
                                style={ styles.budget }
                                entity={ this.props.entity }
                                cost={ this.props.cost }
                            />
                        }
                    >
                        <div style={ styles.properties }>
                            <Views.Common.Properties
                                align={ 70 }
                                values={[
                                    {
                                        label: t('common.label.fortune'),
                                        value: <Views.Common.Number type="currency" scale="M" value={ target.fortune || 0 } />,
                                        style: styles.property,
                                    }, {
                                        label: t('dna.comeet.aum'),
                                        value: <Views.Common.Number type="currency" scale="K" value={ values.deposit } />,
                                        style: styles.property,
                                    }, {
                                        label: t('dna.comeet.profit'),
                                        value: <Views.Common.Number type="currency" scale="K" value={ values.profit } />,
                                        style: styles.property,
                                    }, {
                                        label: t('dna.comeet.budget'),
                                        value: <Views.Common.Number type="currency" scale="K" value={ values.investment } />,
                                        style: styles.property,
                                    }
                                ]}
                            />
                            <br/>
                            <Views.Common.Properties
                                align={ 120 }
                                values={[
                                    {
                                        label: t('dna.comeet.budgetedCost'),
                                        value: <Views.Common.Number type="currency" scale="K" value={ values.investment } />,
                                        style: styles.property,
                                    }, {
                                        label: t('dna.comeet.actuaCost'),
                                        value: <Views.Common.Number type="currency" scale="K" value={ this.props.cost } />,
                                        style: styles.property,
                                    }, {
                                        label: t('dna.comeet.actualBudget'),
                                        value: <Views.Common.Number type="currency" scale="K" value={ values.investment - this.props.cost } />,
                                        style: styles.property,
                                    }
                                ]}
                            />
                        </div>
                    </Views.Common.Button>
                </div>

                { this.renderActions() }

                <Form
                    ref="editor"
                    entity={ toJS(this.props.entity) }
                    onSubmit={ data => this.context.updateProspect(this.props.entity.id, data) }
                />

                <EventForm
                    ref="eventCreator"
                    onSubmit={ data => this.context.createEvent(this.props.entity.id, data) }
                />

                <MilestoneForm
                    ref="milestoneCreator"
                    onSubmit={ data => this.context.createMilestone(this.props.entity.id, data) }
                />
            </div>
        );
    }

    renderActions () {
        switch (this.props.entity.status) {
            case 'draft':
                return this.renderDraftActions();

            case 'started':
            case 'published':
                return this.renderStartedActions();

            case 'succeeded':
            case 'failed':
            case 'canceled':
                return this.renderEndActions();
        }
    }

    renderDraftActions () {
        return (
            <div style={ styles.actions }>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.update"/>}
                    label="Edit"
                    onClick={ event => this.refs.editor.open() }
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.start"/>}
                    label="Start"
                    onClick={ event => this.context.startProspect(this.props.entity.id) }
                />
            </div>
        );
    }

    renderStartedActions () {
      const target = (this.props.entity.payload.target) || {};
      const values = computeProspectValues(this.props.entity.payload);

        return (
            <div style={ styles.actions }>
                <Views.Common.Button
                    ref="add"
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.create"/>}
                    label="Add"
                >
                    <Menu>
                        <MenuItem
                            leftIcon={ <Icon type="comeet.event" color={ colors.userCold } /> }
                            primaryText={t('dna.comeet.addEvent')}
                            onClick={ event => { this.refs.eventCreator.open(); this.refs.add.closePopover(); } }
                        />
                        <MenuItem
                            leftIcon={ <Icon type="comeet.milestone" color={ colors.userCold } /> }
                            primaryText={t('dna.comeet.addMilestone')}
                            onClick={ event => { this.refs.milestoneCreator.open(); this.refs.add.closePopover(); } }
                        />
                    </Menu>
                </Views.Common.Button>

                <div style={ styles.separator } />

                <Views.Discussion.Button
                  color={'cold'}
                  type={'flat'}
                  target={ this.props.entity.id }
                  entities={ toJS(this.props.entity.comments) }
                  handleSubmit={(data, parentId) => {
                    this.props.store.commentProspect(this.props.entity.id, data, parentId)
                  }}
                  handleDelete={(commentId) => {
                    this.props.store.removeComment(this.props.entity.id, commentId)
                  }}
                  preview={<div style={{textAlign: 'center'}}>
                        <div>
                        <Avatar
                            style={ styles.avatar }
                            icon={ <Icon type="comeet.prospect" /> }
                        />

                        <div style={{lineHeight: '40px', fontSize: '19px',}}>
                            { target.name || '<no name defined>' }
                        </div>

                        <div style={{ borderBottom: '1px solid rgb(224, 224, 224)'}}>
                        <Budget
                            style={ styles.budget }
                            entity={ this.props.entity }
                            cost={ this.props.cost }
                        />
                        </div>
                        </div>
                            <div style={ { padding: '20px', backgroundColor: colors.bgGreen } }>
                                <Views.Common.Properties
                                    align={ 70 }
                                    values={[
                                        {
                                            label: t('common.label.fortune'),
                                            value: <Views.Common.Number type="currency" scale="M" value={ target.fortune || 0 } />,
                                            style: styles.property,
                                        }, {
                                            label: t('dna.comeet.aum'),
                                            value: <Views.Common.Number type="currency" scale="K" value={ values.deposit } />,
                                            style: styles.property,
                                        }, {
                                            label: t('dna.comeet.profit'),
                                            value: <Views.Common.Number type="currency" scale="K" value={ values.profit } />,
                                            style: styles.property,
                                        }, {
                                            label: t('dna.comeet.budget'),
                                            value: <Views.Common.Number type="currency" scale="K" value={ values.investment } />,
                                            style: styles.property,
                                        }
                                    ]}
                                />
                                <br/>
                                <Views.Common.Properties
                                    align={ 120 }
                                    values={[
                                        {
                                            label: t('dna.comeet.budgetedCost'),
                                            value: <Views.Common.Number type="currency" scale="K" value={ values.investment } />,
                                            style: styles.property,
                                        }, {
                                            label: t('dna.comeet.actuaCost'),
                                            value: <Views.Common.Number type="currency" scale="K" value={ this.props.cost } />,
                                            style: styles.property,
                                        }, {
                                            label: t('dna.comeet.actualBudget'),
                                            value: <Views.Common.Number type="currency" scale="K" value={ values.investment - this.props.cost } />,
                                            style: styles.property,
                                        }
                                    ]}
                                />
                            </div>
                    </div>}
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="0"
                    icon={ <Icon type="base.attachment" />}
                    disabled
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="0"
                    icon={ <Icon type="base.attendee" />}
                    disabled
                />

                <div style={ styles.separator } />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="do.update"/>}
                    label="Edit"
                    onClick={ event => this.refs.editor.open() }
                />

                <Views.Common.Button
                    type="flat"
                    color="hot"
                    icon={<Icon type="do.terminate"/>}
                    label="End"
                >
                    <Menu>
                        <MenuItem
                            leftIcon={ <Icon type="base.yes" color={ colors.userCold } /> }
                            primaryText={t('dna.comeet.prospect.prospectWon')}
                            onClick={ event => this.context.endProspect(this.props.entity.id, 'win') }
                        />

                        <MenuItem
                            leftIcon={ <Icon type="base.no" color={ colors.userHot }/> }
                            primaryText={t('dna.comeet.prospect.prospectLost')}
                            onClick={ event => this.context.endProspect(this.props.entity.id, 'loose') }
                        />

                        <Divider />

                        <MenuItem
                            leftIcon={ <Icon type="do.delete" color={ colors.userHot }/> }
                            primaryText={t('dna.comeet.prospect.prospectingAborted')}
                            onClick={ event => this.context.endProspect(this.props.entity.id, 'abort') }
                        />
                    </Menu>
                </Views.Common.Button>
            </div>
        );
    }

    renderEndActions () {
        switch (this.props.entity.status) {
            case 'succeeded':
                return (
                    <div style={ styles.status }>
                        <Icon type="base.yes" color={ colors.userCold } style={ styles.statusIcon } />
                        <span style={{ color: colors.userCold }}>Won</span>
                    </div>
                );

            case 'failed':
                return (
                    <div style={ styles.status }>
                        <Icon type="base.no" color={ colors.userHot } style={ styles.statusIcon } />
                        <span style={{ color: colors.userHot }}>Lost</span>
                    </div>
                );

            case 'canceled':
                return (
                    <div style={ styles.status }>
                        <Icon type="do.delete" color={ colors.userHot } style={ styles.statusIcon } />
                        <span style={{ color: colors.userHot }}>Canceled</span>
                    </div>
                );
        }
    }
}
