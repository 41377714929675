import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.list.body.cards';

    static propTypes = {
        element: PropTypes.element.isRequired,
        extra: PropTypes.object.isRequired,
        entities: PropTypes.array,
    };

    render() {
        if (!this.props.entities) {
            return null;
        }

        return (
            <div style={styles.container}>
                {_.filter(this.props.entities.map(this.renderCard.bind(this)))}
            </div>
        );
    }

    renderCard(entity, index) {
        if (!entity) {
            console.error(`Rendering card for an undefined entity.`);
            return null;
        }

        const extra = {};

        for (const name of Object.keys(this.props.extra)) {
            if (this.props.extra[name] && this.props.extra[name][entity.id]) {
                extra[name] = this.props.extra[name][entity.id];
            }
        }

        return React.cloneElement(
            this.props.element,
            Object.assign({}, this.props.element.props, {
                key: entity.id || `i${index}`,
                entity: entity,
                extra: extra,
            }),
        );
    }
}
