import PropTypes from 'prop-types';
import React from 'react';
import Handle from 'dna/entity/common/handle';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.explorer.search.handle';

    static propTypes = {
        state: PropTypes.object,
        onSelect: PropTypes.func.isRequired
    };

    render () {
        if (! this.props.state || ! this.props.state.form) {
            return (<Handle title={t('common.message.loading')} />);
        }

        return (
            <Handle
                onSelect={ this.props.onSelect }
                icon="dna.search"
                title={ this.props.state.form.value || '—' }
                subtitle={ this.props.state.form.kind || '—' }
            />
        );
    }
}
