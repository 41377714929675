// @flow

import type { StoreBoxes } from './types';
import type { IObservableValue } from 'mobx';

import { observable } from 'mobx';

export default function<T> (initial: T): StoreBoxes<T> {
    const map: Map<string, IObservableValue<T>> = new Map();

    return {
        add(key: string): void {
            if (map.has(key)) {
                console.warn(`Tried to add known box value.`);
            }

            map.set(key, observable.box(initial, { deep: false }));
        },

        get(key: string): IObservableValue<T> {
            let box = map.get(key);

            if (!box) {
                console.warn(`Tried to get unknown box value.`);
                box = observable.box(initial, { deep: false });
                map.set(key, box);
            }

            return box;
        },

        set(key: string, value: T): void {
            let box = map.get(key);

            if (!box) {
                console.warn(`Tried to set unknown box value.`);
                box = observable.box(initial, { deep: false });
                map.set(key, box);
            }

            box.set(value);
        },

        delete(key: string): void {
            if (!map.has(key)) {
                console.warn(`Tried to delete unknown box value.`);
            }

            map.delete(key);
        },
    };
}
