import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import colors from 'app/theme/colors';
import Scope from 'app/layout/providers/scope';
import Views from 'app/views';

import Modal from './modal';
import Deals from './tab/deals';
import Stakeholders from './tab/stakeholders';
import { toJS } from 'mobx';

import t from 'app/translation';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch',
        margin: '20px 0',
    },
    loader: {
        margin: '20px 0',
    },
    empty: {
        textAlign: 'center',
        color: colors.grey500,
        padding: '40px 0',
    },
    column: {
        flexGrow: '1',
        width: '25%',
        marginLeft: '10px',
        borderLeft: '1px solid ' + colors.grey300,
        paddingLeft: '10px',
    },
    first: {
        marginLeft: '0',
        borderLeftWidth: '0',
        paddingLeft: '0',
    },
    title: {
        fontSize: '19px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.deals.tab';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        deals: PropTypes.object,
    };

    render() {
        if (this.props.state.dealsView === 'direct'){
            const deals = toJS(this.props.deals);
            return this.renderDeals(deals, false);
        } else if(this.props.state.dealsView === 'indirect'){
            const dealsCollection = this.props.deals.relatives;
            const block = [];
            for(const targetName in dealsCollection){
                if(!dealsCollection[targetName]){

                    continue;
                }
                const deals = dealsCollection[targetName];
                if (!deals || (!deals.asAcquiror && !deals.asVendor && !deals.asTarget)) {
                    continue;
                }
                block.push(<div style={styles.title}>{targetName}</div>)
                block.push(this.renderDeals(deals, true));
            }

            return <div>{block}</div>;
        }
    }

    renderDeals(deals, relatives){
        if (!deals || (!deals.asAcquiror.length && !deals.asVendor.length && !deals.asTarget.length)) {
            return <Views.Common.Empty title={t('common.label.noDeal')} />;
        }
        return (
            <div style={styles.container}>
                <Scope path="deals.sales" type="object">
                    <Deals
                        title={t('common.label.sales')}
                        empty={t('dna.deals.noSale')}
                        attachments={deals.asVendor || []}
                        details={deals.details}
                        style={Object.assign({}, styles.column, styles.first)}
                        onOpen={deal => this.refs.modal.open([deal], 'Sale')}
                        rumour={false}
                    />
                </Scope>

                <Scope path="deals.purchases" type="object">
                    <Deals
                        title={t('common.label.purchases')}
                        empty={t('dna.deals.noPurchase')}
                        attachments={deals.asAcquiror || []}
                        details={deals.details}
                        style={styles.column}
                        onOpen={deal => this.refs.modal.open([deal], 'Purchase')}
                        rumour={false}
                    />
                </Scope>

                {this.renderTransactions(deals, relatives)}

                <Scope path="deals.stakeholders" type="object">
                    <Stakeholders
                        stakeholders={deals.stakeholders || []}
                        details={deals.details}
                        style={styles.column}
                        onOpen={(deals, name) => this.refs.modal.open(deals, 'Stakeholder: ' + name)}
                    />
                </Scope>

                {this.renderRumours(deals, relatives)}

                <Modal ref="modal" />
            </div>
        );
    }

    renderTransactions(deals: Object, relatives) {
        if (this.props.entity.type !== 'company' && !relatives) {
            return null;
        }

        return (
            <Scope path="deals.transactions" type="object">
                <Deals
                    title={t('common.label.transactions')}
                    empty={t('dna.deals.noTransaction')}
                    attachments={deals.asTarget || []}
                    details={deals.details}
                    style={styles.column}
                    onOpen={deal => this.refs.modal.open([deal], 'Transaction')}
                    rumour={false}
                />
            </Scope>
        );
    }

    renderRumours(deals: Object, relatives) {
        if (this.props.entity.type === 'company' && !relatives) {
            return null;
        }

        const attachments = _.filter(
            _.union(deals.asAcquiror || [], deals.asTarget || [], deals.asVendor || []),
            attachment => ['rumour', 'rumourexpired'].includes(_.get(deals.details[attachment.deal], 'status.kind')),
        );

        return (
            <Scope path="deals.rumours" type="object">
                <Deals
                    title={t('dna.deals.rumours')}
                    empty={t('dna.deals.noRumours')}
                    attachments={attachments}
                    details={deals.details}
                    style={styles.column}
                    onOpen={deal => this.refs.modal.open([deal], 'Rumour')}
                    rumour={true}
                    noStatus
                />
            </Scope>
        );
    }
}
