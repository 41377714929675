// @flow

import CommonButton from './common/button';
import CommonDescription from './common/description';
import CommonCard from './common/card';
import CommonEmpty from './common/empty';
import CommonHeader from './common/header';
import CommonLoader from './common/loader';
import CommonNumber from './common/number';
import CommonProperties from './common/properties';
import CommonMoment from './common/moment';
import CommonChecklist from './common/checklist';
import CommonToggle from './common/toggle';
import CommonTooltip from './common/tooltip';
import CommonUser from './common/user';

import ListContainer from './list/container';
import ListData from './list/model/data';
import ListFrame from './list/model/frame';
import ListReducer from './list/model/reducer';
import ListBlock from './list/model/block';

import ModalShow from './modal/show';
import ModalFeature from './modal/feature';
import ModalRemoval from './modal/removal';
import ModalEdition from './modal/edition';
import ModalPdf from './modal/pdf';
import ModalImg from './modal/img';
import ModalConfirm from './modal/confirm';

import ChartContainer from './chart/container';
import ChartEntry from './chart/entry';

import TimelinePreview from './timeline/preview';
import TimelineDisplay from './timeline/display';
import TimelineEvent from './timeline/model/event';
import TimelineRule from './timeline/model/rule';
import TimelineBlock from './timeline/model/block';

import FormContainer from './form/container';
import FormRow from './form/row';
import FormColumn from './form/column';
import FormCell from './form/cell';
import FormField from './form/field';
import FormValue from './form/value';

import DiscussionButton from './discussion/button';

export default {
    Common: {
        Button: CommonButton,
        Description: CommonDescription,
        Loader: CommonLoader,
        Card: CommonCard,
        Empty: CommonEmpty,
        Number: CommonNumber,
        Properties: CommonProperties,
        Header: CommonHeader,
        Moment: CommonMoment,
        Checklist: CommonChecklist,
        Toggle: CommonToggle,
        Tooltip: CommonTooltip,
        User: CommonUser,
    },
    List: {
        Container: ListContainer,
        Data: ListData,
        Frame: ListFrame,
        Reducer: ListReducer,
        Block: ListBlock,
    },
    Modal: {
        Show: ModalShow,
        Feature: ModalFeature,
        Removal: ModalRemoval,
        Edition: ModalEdition,
        Pdf: ModalPdf,
        Img: ModalImg,
        Confirm: ModalConfirm,
    },
    Chart: {
        Container: ChartContainer,
        Entry: ChartEntry,
    },
    Timeline: {
        Preview: TimelinePreview,
        Display: TimelineDisplay,
        Event: TimelineEvent,
        Rule: TimelineRule,
        Block: TimelineBlock,
    },
    Form: {
        Container: FormContainer,
        Row: FormRow,
        Column: FormColumn,
        Cell: FormCell,
        Field: FormField,
        Value: FormValue,
    },
    Discussion: {
      Button: DiscussionButton
    },
};
