// @flow

import React from 'react';
import Icon from 'app/theme/icon';
import Button from 'app/views/common/button';

const styles = {
    container: {
        borderTop: '1px solid rgba(0, 0, 0, 0.15)',
        height: '47px',
        marginBottom: '30px'
    },
    wrapper: {
        width: '1400px',
        margin: '0 auto',
        display: 'flex',
        flexWrap: 'nowrap'
    },
    add: {
        margin: '7px',
        width: '40px',
        flexShrink: '0',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.screen.tabs.container';

    props: {
        add: Function,
        select: Function,
        children: any,
    };

    render () {
        return (
            <div style={ styles.container }>
                <div style={ styles.wrapper }>
                    { this.props.children }
                    { this.renderAdd() }
                </div>
            </div>
        )
    }

    renderAdd () {
        if (! this.props.add) {
            return null;
        }

        return (
            <Button
                type="raised"
                mini
                icon={ <Icon type="do.create" /> }
                onClick={ this.props.add }
                style={ styles.add }
            />
        )
    }
}
