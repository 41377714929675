import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        display: 'flex'
    },
    title: {
        color: colors.userCold,
        marginBottom: '5px',
        textTransform: 'uppercase',
        fontSize: '11px',
        fontWeight: 'bold'
    },
    empty: {
        color: colors.grey500
    },
    actor: {
        margin: '10px 0',
        fontSize: '15px',
        lineHeight: '20px'
    },
    icon: {
        width: '20px',
        height: '20px',
        fill: colors.grey500,
        marginRight: '5px'
    },
    name: {
        color: colors.black,
        verticalAlign: 'top'
    },
    link: {
        borderBottom: '1px solid ' + colors.grey500,
        cursor: 'pointer'
    },
    description: {
        color: colors.grey500,
        display: 'block',
        fontSize: '13px',
        lineHeight: '15px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.modal.deal';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired
    };

    static propTypes = {
        title: PropTypes.string.isRequired,
        actors: PropTypes.array.isRequired,
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ this.props.style }>
                <div style={ styles.title }>
                    { this.props.title }
                </div>

                { this.renderActors() }
            </div>
        );
    }

    renderActors () {
        if (! this.props.actors.length)
            return (
                <div style={ styles.empty }>
                    { this.props.title + ' unknown' }
                </div>
            );

        return this.props.actors.map(this.renderActor.bind(this));
    }

    renderActor (actor, index) {
        return (
            <div key={ '' + index } style={ styles.actor }>
                { this.renderIcon(actor.type) }
                { this.renderName(actor) }

                <span style={ styles.description }>
                    { actor.description }
                </span>
            </div>
        );
    }

    renderIcon (type) {
        switch (type) {
            case 'company':
                return (<Icon type="dna.company" style={ styles.icon } />);

            case 'person':
                return (<Icon type="dna.person" style={ styles.icon } />);

            case 'group':
                return (<Icon type="dna.typology" style={ styles.icon } />);
        }

        return null;
    }

    renderName (actor) {
        if (! actor.id) {
            return (
                <span style={ styles.name }>
                    { actor.name }
                </span>
            );
        }

        return (
            <span
                style={ Object.assign({}, styles.name, styles.link) }
                onClick={ event => this.context.openEntity(actor.id) }
            >
                { actor.name }
            </span>
        );
    }
}
