// @flow

import { createPassword, createSecret, createToken } from './crypto';
import { encodeLevel, decodeLevel, isManager } from './level';
import { makeSession, cleanSession } from './session';
import { makeName } from './name';
import { obfuscateEmail } from './email';
import { normalizeSettings, adaptSettings, resolveSettings } from './settings';
import { findCursor, updateCursor } from './cursor';
import { allowAdmin } from './allow';
import { resolveFlags } from './flags';

export default {
    createPassword,
    createSecret,
    createToken,
    encodeLevel,
    decodeLevel,
    isManager,
    makeName,
    obfuscateEmail,
    makeSession,
    cleanSession,
    normalizeSettings,
    adaptSettings,
    resolveSettings,
    findCursor,
    updateCursor,
    allowAdmin,
    resolveFlags,
};
