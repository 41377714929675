import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Views from 'app/views';
import get from 'lodash/get';

const ENTRIES = [
    {
        status: 'input',
        label: 'Input',
        icon: 'deeligenz.survey.input',
        done: ['ddq', 'onSite', 'report', 'accepted', 'rejected', 'vetoed'],
    },
    {
        status: 'ddq',
        label: 'Analysis',
        icon: 'deeligenz.survey.ddq',
        done: ['onSite', 'report', 'accepted', 'rejected', 'vetoed'],
    },
    {
        status: 'onSite',
        label: 'Meeting',
        icon: 'deeligenz.survey.onSite',
        done: ['report', 'accepted', 'rejected', 'vetoed'],
    },
    {
        status: 'report',
        label: 'Report',
        icon: 'deeligenz.survey.report',
        done: ['accepted', 'rejected', 'vetoed'],
    },
];

const styles = {
    container: {
        marginBottom: '40px',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        position: 'relative',
    },
    label: {
        display: 'inline-block',
        marginRight: '20px',
    },
    item: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '20%',
        padding: '10px',
        cursor: 'pointer',
        color: colors.grey600,
        textTransform: 'uppercase',
        borderLeft: '1px solid ' + colors.grey200,
        borderBottom: '2px solid transparent',
    },
    current: {
        background: colors.grey100,
        borderBottom: '2px solid ' + colors.userHot,
        color: colors.userCold,
        cursor: 'default',
    },
    hover: {
        background: colors.grey100,
        color: colors.grey700,
    },
    icon: {
        display: 'inline-block',
        marginRight: '10px',
        verticalAlign: 'middle',
        fill: colors.grey500,
    },
    ongoing: {
        fontSize: '11px',
        color: 'white',
        display: 'inline-block',
        marginLeft: '20px',
        padding: '3px 5px',
        background: colors.userCold,
        borderRadius: '3px',
    },
    switchWrapper: {
        display: 'inline-block',
        margin: '0 1px',
        cursor: 'pointer',
    },
    switchIcon: {
        borderRadius: '3px',
        padding: '5px',
        fill: colors.grey500,
        border: '1px solid transparent',
    },
    switchHover: {
        fill: colors.userCold,
        background: 'white',
        border: '1px solid ' + colors.grey300,
    },
    switchCurrent: {
        fill: colors.userHot,
        background: 'white',
        border: '1px solid ' + colors.grey300,
    },
    progress: {
        marginRight: '20px',
        display: 'inline-block',
        color: colors.userCold,
        fontWeight: 'bold',
    },
    done: {
        marginRight: '20px',
        display: 'inline-block',
        fill: colors.userCold,
        opacity: '0.5',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.display.tabs';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        tab: PropTypes.string,
        onSiteView: PropTypes.string,
        reportView: PropTypes.string,
        entity: PropTypes.object.isRequired,
    };

    state = { hovered: null };

    render() {
        return (
            <div style={styles.container} onMouseLeave={event => this.setState({ hovered: false })}>
                {ENTRIES.map(this.renderEntry.bind(this))}
            </div>
        );
    }

    renderEntry(entry: Object, index: number) {
        let style = styles.item;

        if (index === 0) style = Object.assign({}, style, { borderLeftWidth: '0' });

        if (entry.status === this.props.tab) style = Object.assign({}, style, styles.current);
        else if (entry.status === this.state.hovered) style = Object.assign({}, style, styles.hover);

        return (
            <a
                key={entry.status}
                style={style}
                onMouseEnter={event => this.setState({ hovered: entry.status })}
                onClick={event => this.context.updateState({ tab: entry.status })}
            >
                <Icon type={entry.icon} style={styles.icon} />
                <span style={styles.label}>{entry.label}</span>

                {this.renderProgress(entry.status, entry.done.includes(this.props.entity.status))}

                {entry.status === 'onSite'
                    ? [
                          this.renderSwitch('onSiteView', 'fields', 'deeligenz.survey.fields', 'Show Fields'),
                          this.renderSwitch('onSiteView', 'agenda', 'deeligenz.survey.agenda', 'Show Agenda'),
                          this.renderSwitch(
                              'onSiteView',
                              'questions',
                              'deeligenz.survey.onSite.questions',
                              'Show additional questions',
                          ),
                      ]
                    : null}
                {entry.status === 'report'
                    ? [
                          this.renderSwitch('reportView', 'overview', 'deeligenz.survey.overview', 'Show Report'),
                          this.renderSwitch('reportView', 'fields', 'deeligenz.survey.keyFinding', 'Show Key Findings'),
                      ]
                    : null}
            </a>
        );
    }

    renderSwitch(type, id, icon, tooltip) {
        const style = Object.assign(
            {},
            styles.switchIcon,
            id === this.state.switchHover ? styles.switchHover : {},
            this.props[type] === id ? styles.switchCurrent : {},
        );

        return (
            <Views.Common.Tooltip key={id} content={tooltip}>
                <span
                    style={styles.switchWrapper}
                    onMouseEnter={event => this.setState({ switchHover: id })}
                    onMouseLeave={event => this.setState({ switchHover: undefined })}
                    onClick={event => this.context.updateState({ [type]: id })}
                >
                    <Icon type={icon} style={style} />
                </span>
            </Views.Common.Tooltip>
        );
    }

    renderProgress(status, done) {
        if (done) {
            return <Icon type="base.yes" style={styles.done} />;
        }

        const progress = get(this.props, 'entity.computed.progress.' + status, 0);

        if (progress || status === this.props.entity.status) {
            return <span style={styles.progress}>{progress + ' %'}</span>;
        }

        return null;
    }
}
