import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';

import DeefindProvider from 'app/layout/providers/deefind';
import PersonCard from './person/card';
import CompanyCard from './company/card';

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.card';

    static propTypes = {
        levels: PropTypes.object,
        parent: PropTypes.object,
        entity: PropTypes.object,
        extra: PropTypes.object,
        dimmed: PropTypes.bool,
    };

    render() {
        const relation = this.props.parent && this.props.entity ? this.props.entity : null;

        return (
            <DeefindProvider
                type="entity"
                id={this.props.entity.id}
                renderLoader={progress => {
                    const properties = {
                        parent: this.props.parent,
                        relation,
                        entity: this.props.entity,
                        progress: progress,
                        dimmed: this.props.dimmed,
                    };

                    switch (this.props.entity.type) {
                        case 'person':
                            return <PersonCard {...properties} />;

                        case 'company':
                            return <CompanyCard {...properties} />;

                        default:
                            return <Views.Common.Loader progress={progress} />;
                    }
                }}
                renderEntity={(entity, state) => {
                    const properties = {
                        parent: this.props.parent,
                        relation,
                        entity,
                        state,
                        dimmed: this.props.dimmed || (this.props.entity.preview && this.props.entity.preview.closed),
                    };

                    switch (entity.type) {
                        case 'person':
                            return <PersonCard {...properties} />;
                        case 'company':
                            return <CompanyCard {...properties} />;
                    }

                    console.error(`Invalid entity for card "${this.props.entity}".`);
                }}
            />
        );
    }
}
