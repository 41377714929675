import { get } from 'lodash';

export default async function (data: Object, services: Object): Promise<Object> {
    const header = get(data, `study.payload.header`, {});
    const values = {};

    for (const key of Object.keys(header)) {
        const value = header[key];

        switch (true) {
            case !value:
                break;

            case value.type === 'file':
                values[key] = await services.acquireFile(value.value);
                break;

            case value.type === 'text':
                values[key] = value.value;
                break;

            case typeof value === 'string' && key.endsWith('Picture'):
                values[key] = await services.acquireFile(value);
                break;

            case typeof value === 'string':
                values[key] = value;
                break;
        }
    }

    return values;
}
