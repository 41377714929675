import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';

import colors from 'app/theme/colors';
import Display from './display';

import WocoPreview from './woco/preview';
import FrChallengesF500 from './typologies/fr.challenges.f500/preview';
import FrJdnIncomes from './typologies/fr.jdn.incomes/preview';
import FrOpendataMayors from './typologies/fr.opendata.mayors/preview';
import FrOpendataDeputies from './typologies/fr.opendata.deputies/preview';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    placeholder: {
        margin: '20px 0',
        width: '436px',
        height: '92px',
        border: '2px solid ' + colors.grey100,
        borderRadius: '3px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.compliance.preview';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const entries = this.props.entity.files || [];

        if (!entries.length) {
            return null;
        }

        return (
            <div style={styles.container}>
                <Display ref="display" entity={this.props.entity} />
                {entries.map((entry, index) => this.renderPreview(entry, index))}
                {this.renderPlaceholders(entries)}
            </div>
        );
    }

    renderPreview(entry, index) {
        switch (entry.data.type) {
            case 'fortune':
                return (
                    <FrChallengesF500
                        key={index}
                        data={entry.data}
                        relevance={entry.relevance}
                        onOpen={() => this.refs.display.open()}
                    />
                );

            case 'politic':
            case 'justice':
            case 'compliance':
            case 'mandate':
                return (
                    <WocoPreview
                        key={index}
                        data={entry.data}
                        relevance={entry.relevance}
                        onOpen={() => this.refs.display.open()}
                    />
                );

            // case 'woco': return (<WocoPreview { ...props } />);
            // case 'fr.jdn.incomes': return (<FrJdnIncomes { ...props } />);
            // case 'fr.opendata.mayors': return (<FrOpendataMayors { ...props } />);
            // case 'fr.opendata.deputies': return (<FrOpendataDeputies { ...props } />);
        }

        return null;
    }

    renderPlaceholders(entries) {
        const result = [];

        for (let index = 0; index < 3 - (entries.length % 3); index++) {
            result.push(
                <div key={'P' + index} style={styles.placeholder}>
                    <svg viewBox="0 0 436 92">
                        <line x1="0" y1="0" x2="436" y2="92" stroke={colors.grey100} strokeWidth="2" />
                        <line x1="436" y1="0" x2="0" y2="92" stroke={colors.grey100} strokeWidth="2" />
                    </svg>
                </div>,
            );
        }

        return result;
    }
}
