// @flow

import type {
    ValuationProfile,
    ValuationReference,
    ComputedInitReference,
    ComputedValuationProfile,
    ComputedValuationReference,
    ComputedDeltas,
} from '../../../types/utils';

import type { CompanyDeemaze } from '../../../types/model/index';

import { get, set, sortBy, slice } from 'lodash';

export default function makeValuationEstimate(
    profile: ValuationProfile,
    references: ValuationReference[],
): CompanyDeemaze {
    // console.log('BEGIN computed references')
    // console.log(require('util').inspect({profile, references}, {depth: null}));
    // console.log('END profile & references')

    const turnovers = profile.turnovers || [undefined, undefined, undefined];
    const ebitdas = filterPositiveEbitdas(profile.ebitdas || [undefined, undefined, undefined]);

    const computedProfile = {
        growth: computeGrowth(turnovers),
        margin: computeMargin(ebitdas, turnovers),
    };

    const scoresRefs = {};
    const computedReferences = references.map((reference, index) => {
        const rTurnovers = reference.profile.turnovers || [undefined, undefined, undefined];
        const rEbitdas = filterPositiveEbitdas(reference.profile.ebitdas || [undefined, undefined, undefined]);
        const init = initComputedReference(reference);

        const deltas = computeDeltas(
            Object.assign({}, profile, { computed: computedProfile }),
            Object.assign({}, reference, { computed: init }),
        );

        const computed = Object.assign({}, init, {
            pe: (reference.value / Number(reference.profile.netProfit) || undefined) || undefined,
            xCA: (Number(init.ve) / Number(rTurnovers[0]) || undefined) || undefined,
            xEBITDA: (Number(init.ve) / Number(rEbitdas[0]) || undefined) || undefined,
            deltas,
        });

        //  @todo TRICKS

        if (computed.pe === Infinity) computed.pe = undefined;
        if (computed.xCA === Infinity) computed.xCA = undefined;
        if (computed.xEBITDA === Infinity) computed.xEBITDA = undefined;

        pushScoresRefs(scoresRefs, computed['deltas'], index);

        return Object.assign({}, reference, { computed });
    });

    computeScoresRefs(scoresRefs, computedReferences);

    const peerGroup = slice(sortBy(computedReferences, i => i.computed.score), -10, computedReferences.length);
    const medianxCA = median(peerGroup.map(reference => reference.computed.xCA));
    const medianxEBITDA = median(peerGroup.map(reference => reference.computed.xEBITDA));
    const medianPE = median(peerGroup.map(reference => reference.computed.pe));

    // @todo Pour le calcul des capitaux propres
    const other =
        Number(profile.cash || 0) -
        Number(profile.minorityInterest || 0) -
        Number(profile.preferredShares || 0) -
        Number(profile.longTermDebt || 0) -
        Number(profile.loans || 0) -
        Number(profile.shortTermDebt || 0);

    const VExCA = {
        reliability:
            correlation(
                peerGroup.map(ref => ref.computed.ve),
                peerGroup.map(ref => (ref.profile.turnovers || [])[0] || undefined),
            ) || undefined,
        value: ((Number(turnovers[0]) * medianxCA) / 4) * 3 || undefined,
        // type: 'VExCA'
    };
    const VExEBITDA = {
        reliability:
            correlation(peerGroup.map(ref => ref.computed.ve), peerGroup.map(ref => (ref.profile.ebitdas || [])[0])) ||
            undefined,
        value: ((Number(ebitdas[0]) * medianxEBITDA) / 4) * 3 || undefined,
        // type: "VExEBITDA"
    };
    const VExPE = {
        reliability:
            correlation(peerGroup.map(ref => ref.profile.netProfit), peerGroup.map(ref => ref.value)) || undefined,
        value: ((Number(profile.netProfit) * medianPE - other) / 4) * 3 || undefined,
        // type: "VExPE"
    };

    const sortedCorrelation = sortBy([VExCA, VExEBITDA, VExPE].filter(i => i.value), ['reliability']);

    return {
        estimate: sortedCorrelation[sortedCorrelation.length - 1] || { value: undefined },
        turnover: {
            valuation: VExCA.value || 0,
            correlation: VExCA.reliability || 0, // percent
            peerMultiple: medianxCA, // multiple median du peer group
            fullMultiple: 1, // multiple median de toutes les sociétés quotés
        },
        ebitda: {
            valuation: VExEBITDA.value || 0,
            correlation: VExEBITDA.reliability || 0, // percent
            peerMultiple: medianxEBITDA, // multiple median du peer group
            fullMultiple: 1, // multiple median de toutes les sociétés quotés
        },
    };
}

function computeGrowth(turnovers: [?number, ?number, ?number]): [?number, ?number] {
    return [
        // Current Year
        (get(turnovers, '0') - get(turnovers, '1')) / get(turnovers, '1') || undefined,
        // Year -1
        (get(turnovers, '1') - get(turnovers, '2')) / get(turnovers, '2') || undefined,
    ];
}

function computeMargin(
    ebitdas: [?number, ?number, ?number],
    turnovers: [?number, ?number, ?number],
): [?number, ?number, ?number] {
    return [
        // Current Year
        get(ebitdas, '0') / get(turnovers, '0') || undefined,
        // Year -1
        get(ebitdas, '1') / get(turnovers, '1') || undefined,
        // Year -2
        get(ebitdas, '2') / get(turnovers, '2') || undefined,
    ];
}

function initComputedReference(reference: ValuationReference): ComputedInitReference {
    const rTurnovers = reference.profile.turnovers || [undefined, undefined, undefined];
    const rEbitdas = filterPositiveEbitdas(reference.profile.ebitdas || [undefined, undefined, undefined]);

    return {
        growth: computeGrowth(rTurnovers),
        margin: computeMargin(rEbitdas, rTurnovers),
        ve:
            Number(reference.value || 0) +
            Number(reference.profile.preferredShares || 0) +
            Number(reference.profile.longTermDebt || 0) +
            Number(reference.profile.loans || 0) +
            Number(reference.profile.shortTermDebt || 0) -
            Number(reference.profile.cash || 0),
        score: 0,
    };
}

function computeDeltas(profile: ComputedValuationProfile, reference: ComputedValuationReference): ComputedDeltas {
    const pTurnovers = profile.turnovers || [undefined, undefined, undefined];
    const rTurnovers = reference.profile.turnovers || [undefined, undefined, undefined];
    const pEbitdas = filterPositiveEbitdas(profile.ebitdas || [undefined, undefined, undefined]);
    const rEbitdas = filterPositiveEbitdas(reference.profile.ebitdas || [undefined, undefined, undefined]);

    return {
        CA2: Math.abs(Number(pTurnovers[2]) - Number(rTurnovers[2])) || undefined,
        CA1: Math.abs(Number(pTurnovers[1]) - Number(rTurnovers[1])) || undefined,
        CA0: Math.abs(Number(pTurnovers[0]) - Number(rTurnovers[0])) || undefined,
        CCA1: Math.abs(Number(profile.computed.growth[1]) - Number(reference.computed.growth[1])) || undefined,
        CCA0: Math.abs(Number(profile.computed.growth[0]) - Number(reference.computed.growth[0])) || undefined,
        E2: Math.abs(Number(pEbitdas[2]) - Number(rEbitdas[2])) || undefined,
        E1: Math.abs(Number(pEbitdas[1]) - Number(rEbitdas[1])) || undefined,
        E0: Math.abs(Number(pEbitdas[0]) - Number(rEbitdas[0])) || undefined,
        xE2:
            Math.abs(Number(pEbitdas[2]) / Number(pTurnovers[2]) - Number(rEbitdas[2]) / Number(rTurnovers[2])) ||
            undefined,
        xE1:
            Math.abs(Number(pEbitdas[1]) / Number(pTurnovers[1]) - Number(rEbitdas[1]) / Number(rTurnovers[1])) ||
            undefined,
        xE0:
            Math.abs(Number(pEbitdas[0]) / Number(pTurnovers[0]) - Number(rEbitdas[0]) / Number(rTurnovers[0])) ||
            undefined,
        N: Math.abs(Number(profile.netProfit) - Number(reference.profile.netProfit)) || undefined,
    };
}

function pushScoresRefs(scores: {}, deltas: {}, index: number): void {
    for (let key in deltas) {
        if (undefined === deltas[key]) continue;

        !scores[key] ? set(scores, key, []) : null;
        scores[key].push({ ref: index, value: deltas[key] });
    }
}

function computeScoresRefs(scoresRefs: {}, references: ComputedValuationReference[]): void {
    const debug = {};

    Object.keys(scoresRefs).forEach(i => {
        sortBy(scoresRefs[i], ['value']).map((item, ii) => {
            const score = scoresRefs[i].length - ii;
            set(debug, [i, item.ref], { score, reference: item.ref });

            references[item.ref].computed.score += score;
        });
    });
}

function median(values: number[]): number {

    let filteredValues = values.filter(i => !!i);
    filteredValues.sort(function(a, b) {
        return a - b;
    });

    const half = Math.floor(filteredValues.length / 2);

    if (filteredValues.length % 2) return filteredValues[half];
    else return (filteredValues[half - 1] + filteredValues[half]) / 2.0;
}

function correlation(x: number[], y: number[]): number {
    const add = (a, b) => a + b;
    const n = Math.min(x.length, y.length);

    if (n === 0) {
        return 0;
    }

    [x, y] = [x.slice(0, n), y.slice(0, n)];
    const [sum1, sum2] = [x, y].map(l => l.reduce(add));
    const [pow1, pow2] = [x, y].map(l => l.reduce((a, b) => a + Math.pow(b, 2), 0));
    const mulSum = x.map((n, i) => n * y[i]).reduce(add);
    const dense = Math.sqrt((pow1 - Math.pow(sum1, 2) / n) * (pow2 - Math.pow(sum2, 2) / n));
    if (dense === 0) {
        return 0;
    }

    return (mulSum - (sum1 * sum2) / n) / dense;
}

function filterPositiveEbitdas(values: [?number, ?number, ?number]): [?number, ?number, ?number] {
    return [
        Number(values[0]) >= 0 ? values[0] : undefined,
        Number(values[1]) >= 0 ? values[1] : undefined,
        Number(values[2]) >= 0 ? values[2] : undefined,
    ];
}
