// @flow

import React from 'react';
import PropTypes from 'prop-types';
import t from 'app/translation';
import colors from 'app/theme/colors';
import Create from './create';
import Select from './select';

const styles = {
    container: {
        width: '300px',
        background: colors.grey200,
        borderRadius: '3px',
        marginRight: '40px',
        flexShrink: '0',
    },
    header: {
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.grey300}`,
    },
    title: {
        flexGrow: '1',
        paddingLeft: '5px',
        fontSize: '19px',
        color: colors.grey500,
    },
};


export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.list';

    static propTypes = {
        selection: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <div style={styles.title}>{t('cleerance.admin.teams.list.title')}</div>
                    <Create />
                </div>

                <Select selection={this.props.selection} onSelect={this.props.onSelect} />
            </div>
        );
    }
}
