import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Rule from '../header/rule';
import Events from '../header/events';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    left: {
        flexShrink: '1',
        marginRight: '20px'
    },
    main: {
        flexGrow: '1'
    },
    right: {
        flexShrink: '1',
        marginLeft: '20px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.display.header';

    static propTypes = {
        cursor: PropTypes.number,
        rules: PropTypes.arrayOf(PropTypes.shape({ labels: PropTypes.arrayOf(PropTypes.object) })),
        events: PropTypes.arrayOf(PropTypes.object),
        blocks: PropTypes.arrayOf(PropTypes.object),
        highlighted: PropTypes.string,
        onHighlight: PropTypes.func.isRequired,
        onLink: PropTypes.func.isRequired
    };

    render () {
        if (! this.props.blocks.length)
            return this.renderMain();

        return (
            <div style={ styles.container }>
                { this.renderBlocks('left') }
                { this.renderMain() }
                { this.renderBlocks('right') }
            </div>
        );
    }

    renderMain () {
        return (
            <div style={ styles.main }>
                { this.props.rules.map((rule, index) => (
                    <Rule
                        key={ 'rule' + index }
                        labels={ rule.labels }
                    />
                )) }
                <Events
                    cursor={ this.props.cursor }
                    events={ this.props.events }
                    highlighted={ this.props.highlighted }
                    onHighlight={ this.props.onHighlight }
                    onLink={ this.props.onLink }
                />
            </div>
        )
    }

    renderBlocks (type) {
        return _.filter(this.props.blocks, block => block.position === type).map((block, index) => (
            <div key={ type + index } style={ styles[ type ] }>
                { block.children }
            </div>
        ));
    }
}
