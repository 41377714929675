import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Container from './container';
import Axis from './axis';
import Point from './point';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.header.events';

    static propTypes = {
        events: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            position: PropTypes.number.isRequired,
            dimmed: PropTypes.bool.isRequired,
            tooltip: PropTypes.node.isRequired,
            icon: PropTypes.element
        })),
        cursor: PropTypes.number,
        highlighted: PropTypes.string,
        onHighlight: PropTypes.func.isRequired,
        onLink: PropTypes.func.isRequired
    };

    state = {
        footprint: undefined,
        mustSendLinks: true
    };

    componentDidMount () {
        this.sendLinks();
    }

    UNSAFE_componentWillReceiveProps (props) {
        const footprint = _.map(props.events, event => `${event.id}:${event.position}`).join(',');
        this.state.mustSendLinks = this.state.footprint !== footprint;
        this.state.footprint = footprint;
    }

    componentDidUpdate () {
        if (! this.state.mustSendLinks)
            return;

        this.sendLinks();
        this.state.mustSendLinks = false
    }

    sendLinks () {
        const links = {};

        _.each(this.props.events, event => {
            const ref = this.refs[ 'point' + event.id ];
            if (ref) links[ event.id ] = ReactDOM.findDOMNode(ref).offsetLeft + 8;
        });

        this.props.onLink(links);
    }

    render () {
        return (
            <Container size={ 18 } cursor={ this.props.cursor }>
                <Axis cursor={ this.props.cursor } />
                { this.props.events.map(this.renderEvent.bind(this)) }
            </Container>
        );
    }

    renderEvent (event) {
        const highlighted = this.props.highlighted === event.id;

        return (
            <Point
                key={ event.id }
                ref={ 'point' + event.id }
                position={ event.position }
                tooltip={ event.tooltip }
                icon={ event.icon }
                dimmed={ event.dimmed && ! highlighted }
                highlighted={ highlighted }
                onMouseEnter={ e => this.props.onHighlight(event.id) }
                onMouseLeave={ e => this.props.onHighlight(null) }
            />
        );
    }
}
