// @flow

const search = {
    type: 'object',
    children: {
        open: {
            type: 'boolean',
            fallback: true,
        },

        list: 'list',

        loading: {
            type: 'boolean',
            internal: true,
        },

        status: {
            internal: true,
            type: 'string',
            values: ['pending', 'invalid', 'failure', 'success', 'empty'],
            fallback: 'pending',
        },

        progress: {
            internal: true,
            type: 'number',
            min: 0,
            max: 100,
        },

        error: {
            type: 'string',
            internal: true,
        },

        form: {
            type: 'object',
            children: {
                // values
                kind: {
                    type: 'string',
                    values: ['person', 'company', 'typology'],
                    fallback: 'person',
                },
                type: {
                    type: 'string',
                    values: ['name', 'code'],
                    fallback: 'name',
                },
                value: {
                    type: 'string',
                    fallback: undefined,
                },

                // criteria

                company: 'string',
                age: 'string',
                gender: 'string',
                person: 'string',
                activity: 'string',
                typology: {
                    type: 'object',
                    children: {
                        provider: 'number',
                        version: 'number',
                    },
                },

                // auto-complete

                companies: {
                    type: 'array',
                    prototype: 'string',
                },
                persons: {
                    type: 'array',
                    prototype: 'string',
                },
                activities: {
                    type: 'array',
                    prototype: 'string',
                },
            },
        },

        result: {
            type: 'object',
            children: {
                kind: {
                    type: 'string',
                    values: ['person', 'company', 'typology'],
                    fallback: 'person',
                },

                ids: {
                    type: 'array',
                    prototype: 'string',
                    fallback: [],
                },

                typology: 'string',
                items: {
                    type: 'array',
                    prototype: 'object',
                    internal: true,
                },
            },
        },
    },
};

const entities = {
    type: 'menu',
    prototype: {
        type: 'object',
        children: {
            id: 'string',
            open: 'string',
            export: {
                type: 'object',
                children: {
                    progress: {
                        type: 'number',
                        internal: true,
                    },
                },
            },
            step: {
                type: 'string',
                fallback: 'compliance',
            },
            tab: {
                type: 'number',
                min: 0,
                max: 4,
            },
            financial: {
                type: 'string',
                values: ['list', 'tree'],
                fallback: 'list',
            },
            contacts: {
                type: 'object',
                children: {
                    list: 'list',
                    scope: {
                        type: 'string',
                        internal: true,
                    },
                },
            },
            companies: {
                type: 'object',
                children: {
                    list: 'list',
                    scope: {
                        type: 'string',
                        internal: true,
                    },
                },
            },
            persons: {
                type: 'object',
                children: {
                    list: 'list',
                    scope: {
                        type: 'string',
                        internal: true,
                    },
                },
            },
            dealsView: {
                type: 'string',
                values: ['direct', 'indirect'],
                fallback: 'direct',
            },
            deals: {
                type: 'object',
                children: {
                    sales: { type: 'object', prototype: 'string' },
                    purchases: { type: 'object', prototype: 'string' },
                    transactions: { type: 'object', prototype: 'string' },
                    rumours: { type: 'object', prototype: 'string' },
                    stakeholders: { type: 'object', prototype: 'string' },
                },
            },
            news: {
                type: 'object',
                children: {
                    type: 'string',
                    reliability: 'string',
                    category: 'string',
                    moment: 'string',
                    domain: 'string',
                },
            },
        },
    },
};

const depth = {
    type: 'number',
    min: 1,
    max: 5,
};

export default {
    type: 'menu',
    prototype: {
        type: 'router',
        routes: {
            search: { search, entities },
            entity: { entities, depth },
        },
    },
};
