import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.augmented.charts.pieChart';

    static propTypes = {
        data: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    };

    render() {
        return (
            <Plot
                data={[
                    {
                        values: this.props.data,
                        labels: ['1', '2', '3', '4'],
                        type: 'pie',
                        marker: {
                            colors: ['rgb(80, 175, 51)', 'rgb(178, 193, 76)', 'rgb(238, 117, 47)', 'rgb(216, 109, 109)']
                        },
                    },
                ]}
                layout={{
                    width: this.props.width,
                    height: this.props.height,
                    title: {
                        text: this.props.title,
                        font: {
                            family: 'Arial',
                            size: 18,
                            color: '#000000'
                        },
                        xref: 'paper',
                        x: 0.05,
                    },
                }}
                config={{displayModeBar: false}}
            />
        );
    }
}
