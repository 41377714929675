import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Paper from 'material-ui/Paper';
import Avatar from 'material-ui/Avatar';
import PersonIcon from 'material-ui/svg-icons/social/person';
import BackIcon from 'material-ui/svg-icons/navigation/arrow-back';
import Logo from './logo';
import Initialize from './initialize';
import Login from './login';
import Start from './start';
import Breadcrumbs from './breadcrumbs';
import Button from 'app/views/common/button';

let styles = {
    container: {
        width: '400px',
        margin: '20px auto'
    },
    paper: {
        position: 'relative',
        border: '1px solid ' + colors.grey100,
        borderRadius: '5px',
        background: colors.grey100,
        display: 'block',
        width: '400px',
        margin: '20px auto',
        padding: '20px'
    },
    back: {
        position: 'absolute',
        bottom: '45px',
        left: '45px',
        borderRadius: '50%',
        transition: '0.5s',
        zIndex: '1000000',
    },
    invisibleBack: {
        opacity: 0
    },
    avatar: {
        display: 'block',
        margin: '10px auto 0 auto'
    },
    breadcrumbs: {
        borderTop: '1px solid ' + colors.grey200,
        margin: '-15px 0 15px 0'
    },
    wrapper: {
        position: 'relative',
        width: '360px',
        height: '125px',
        margin: '25px 0',
        overflow: 'hidden'
    },
    steps: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '1200px',
        display: 'flex',
        justifyContent: 'space-around',
        transition: '0.25s'
    },
    step: {
        width: '360px',
        height: '125px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.layout.security.login';

    static propTypes = {
        lastEmail: PropTypes.string,
        onRequest: PropTypes.func.isRequired,
        onLogin: PropTypes.func.isRequired,
        onStart: PropTypes.func.isRequired
    };

    state = { step: 1 };

    render () {
        const decay = -400 * (this.state.step - 1) - 20;

        return (
            <div style={ styles.container }>
                <Logo style={ styles.logo } />

                <Paper style={ styles.paper }>
                    { this.renderBack() }

                    <Avatar
                        style={ styles.avatar }
                        icon={ <PersonIcon /> }
                        size={ 96 }
                        color={ colors.grey100 }
                        backgroundColor={ colors.grey300 }
                    />

                    <Breadcrumbs
                        style={ styles.breadcrumbs }
                        step={ this.state.step }
                        onMove={ step => this.setState({ step }) }
                    />

                    <div style={ styles.wrapper }>
                        <div style={ Object.assign({}, styles.steps, { left: decay + 'px' }) }>
                            <Initialize
                                lastEmail={ this.props.lastEmail }
                                focus={ this.state.step === 1 }
                                style={ styles.step }
                                onRequest={ this.props.onRequest }
                                onNext={ event => this.setState({ step: 2 }) }
                            />

                            <Login
                                lastEmail={ this.props.lastEmail }
                                focus={ this.state.step === 2 }
                                style={ styles.step }
                                onLogin={ this.props.onLogin }
                                /* onNext={ event => this.setState({ step: 3 }) } */
                            />

                            <Start
                                focus={ this.state.step === 3 }
                                style={ styles.step }
                                onStart={ this.props.onStart }
                            />
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }

    renderBack () {
        const target = this.state.step - 1;

        const style = target > 0
            ? styles.back
            : Object.assign({}, styles.back, styles.invisibleBack);

        return (
            <Button
                mini
                type="round"
                color="cold"
                icon={ <BackIcon /> }
                style={ style }
                onClick={ event => this.setState({ step: target })}
            />
        );
    }
}
