import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Node from './node';
import t from 'app/translation';
import { get } from 'lodash';
import Country from '../country';

const styles = {
    flag: {
        display: 'inline-block',
        marginRight: '5px',
        lineHeight: '15px',
        textAlign: 'center',
        borderRadius: '10px',
        width: '15px',
        fontSize: '11px',
        fontWeight: 'bold',
        color: colors.white,
        background: colors.userHot,
        verticalAlign: 'text-bottom'
    },
    country: {
        display: 'block',
        lineHeight: '15px',
        marginTop: '7px',
    },
    valuation: {
        fontWeight: 'bold',
        textAlign: 'right'
    },
    ubo: {
        display: 'inline-block',
        marginLeft: '5px',
        lineHeight: '15px',
        fontSize: '11px',
        fontWeight: 'bold',
        color: colors.userHot,
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.stocks.entity';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        target: PropTypes.object,
        input: PropTypes.string,
        output: PropTypes.string,
        ubo: PropTypes.bool,
    };

    render () {
        if (this.props.target.type === 'company') {
            const flags = [];

            if (this.props.target.flags.includes('listed')) flags.push('L');
            if (this.props.target.flags.includes('holding')) flags.push('H');
            if (this.props.target.flags.includes('fund')) flags.push('F');

            return (
                <Node
                    title={ this.renderTitle(this.props.target.name, flags, this.props.target.country) }
                    input={ this.props.input }
                    output={ this.props.output }
                    onClick={ event => this.context.openEntity(this.props.target.id) }
                >
                    { this.renderCompanyValue() }
                </Node>
            );
        }

        if (this.props.target.type === 'person') {
            const flags = [];

            if (this.props.target.flags.includes('fortune')) flags.push('F');
            if (this.props.target.flags.includes('listed-direct')) flags.push('Ld');
            if (this.props.target.flags.includes('listed-indirect')) flags.push('Li');

            return (
                <Node
                    title={ this.renderTitle(this.props.target.name, flags, this.props.target.country) }
                    input={ this.props.input }
                    output={ this.props.output }
                    onClick={ event => this.context.openEntity(this.props.target.id) }
                />
            );
        }

        if (this.props.target.type === 'unknown') {
            return (
                <Node
                    title={ this.renderTitle(this.props.target.name, [], this.props.target.country) }
                    input={ this.props.input }
                    output={ this.props.output }
                    onClick={ event => {} }
                >
                    { this.renderCompanyValue() }
                </Node>
            );
        }

        return null;
    }

    renderTitle (title, flags, country) {
        return (
            <span>
                { flags.map(flag => (<span key={ flag } style={ styles.flag }>{ flag }</span>)) }
                <span>{ title }</span>
                { this.props.ubo ? <span style={styles.ubo}>UBO</span> : null }
                <span style={ styles.country }>
                    <Country
                        code={country}
                    />
                </span>
            </span>
        );
    }

    renderCompanyValue () {
        if (!this.props.target.valuation ) {
            return null;
        }

        return (
            <div style={ styles.valuation }>
                <Views.Common.Number type="currency" scale="M" value={ this.props.target.valuation } />
            </div>
        );
    }
}
