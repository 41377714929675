// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import Summary from './summary/index';
import Display from './display/index';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject(stores => ({ store: stores.teemProspects, session: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects';

    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        openProspect: PropTypes.func.isRequired,
        createProspect: PropTypes.func.isRequired,
        updateProspect: PropTypes.func.isRequired,
        startProspect: PropTypes.func.isRequired,
        fetchProspect: PropTypes.func.isRequired,
        selectProject: PropTypes.func.isRequired,
        endProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        updateEvent: PropTypes.func.isRequired,
        removeEvent: PropTypes.func.isRequired,
        createMilestone: PropTypes.func.isRequired,
        updateMilestone: PropTypes.func.isRequired,
        removeMilestone: PropTypes.func.isRequired,
        createMeeting: PropTypes.func.isRequired,
        updateMeeting: PropTypes.func.isRequired,
        removeMeeting: PropTypes.func.isRequired,
        createAttendee: PropTypes.func.isRequired,
        removeAttendee: PropTypes.func.isRequired,
    };

    getChildContext() {
        return {
            openProspect: this.props.store.openProspect,
            createProspect: this.props.store.createProspect,
            updateProspect: this.props.store.updateProspect,
            fetchProspect: this.props.store.fetchProspect,
            selectProject: this.props.store.selectProject,
            startProspect: this.props.store.startProspect,
            endProspect: this.props.store.endProspect,
            createEvent: this.props.store.createEvent,
            updateEvent: this.props.store.updateEvent,
            removeEvent: this.props.store.removeEvent,
            createMilestone: this.props.store.createMilestone,
            updateMilestone: this.props.store.updateMilestone,
            removeMilestone: this.props.store.removeMilestone,
            createMeeting: this.props.store.createMeeting,
            updateMeeting: this.props.store.updateMeeting,
            removeMeeting: this.props.store.removeMeeting,
            createAttendee: this.props.store.createAttendee,
            removeAttendee: this.props.store.removeAttendee,
        };
    }

    render() {
        const session = this.props.session.get();

        return (
            <Screen
                path="prospects"
                type="workspace"
                fallback="summary"
                select={this.props.store.selectStage}
                close={this.props.store.closeStage}
                stages={{
                    summary: {
                        entry: { icon: 'comeet.summary', label: 'Summary' },
                        content: Summary,
                    },
                    display: {
                        entry: this.computeEntry.bind(this),
                        content: Display,
                    },
                }}
            />
        );
    }

    computeEntry(state?: Object) {
        const session = this.props.session.get();
        let label = t('common.message.loading');
        if (state) {
            const prospect = this.props.store.prospects.get(state.id);
            if (prospect && prospect.payload) {
                label = prospect.payload.target.name;
            }
        }

        return {
            icon: 'comeet.prospect',
            label,
        };
    }
}
