// @flow

import type { CompanyPreview, PersonPreview } from '@deecision/deefind-types/model';

import sum from 'lodash/sum';
import { cleanNumber, cleanArray } from '../../clean/core';

export function makeCompanyScore(preview: CompanyPreview): number {
    return (
        makeMoneyScore(cleanNumber(preview.turnover || null)) +
        makeRelationsScore(cleanArray(preview.persons || null)) +
        ((preview.flags || []).includes('listed') ? 1 : 0)
    );
}

export function makePersonScore(preview: PersonPreview): number {
    return (
        makeMoneyScore(preview.weasse ? preview.weasse.value : 0) +
        makeRelationsScore(preview.companies || []) +
        ((preview.flags || []).includes('fortune') ? 1 : 0)
    );
}

export function computeMoneyAverage(value: any): number {
    if (typeof value === 'number') {
        return value;
    }

    if (Array.isArray(value)) {
        const values = value.filter(v => typeof v === 'number');
        return values.length ? sum(values) / values.length : 0;
    }

    return 0;
}

export function makeMoneyScore(value: any): number {
    return typeof value === 'number' && value > 0 ? Math.max(0, Math.log10(value) - 3) / 3 : 0;
}

export function makeRelationsScore(list: any): number {
    return Array.isArray(list) && list.length ? Math.log10(list.length) : 0;
}
