import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Graph from './relation/graph';
import RaisedButton from 'material-ui/RaisedButton';
import t from 'app/translation';
import { formatStockValue } from '../utils';

const styles = {
    container: {
        position: 'relative',
    },
    button: {
        background: colors.grey500,
        minWidth: '40px',
        height: '40px',
    },
    label: {
        color: colors.white,
        textTransform: 'uppercase',
        fontSize: '19px',
        lineHeight: '40px',
    },
    infoBelow: {
        position: 'absolute',
        color: colors.userHot,
        fontWeight: 'bold',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        top: '45px',
        right: '0',
    },
    infoAside: {
        position: 'absolute',
        color: colors.userHot,
        fontWeight: 'bold',
        lineHeight: '20px',
        whiteSpace: 'nowrap',
        top: '10px',
        right: '100%',
        paddingRight: '10px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.relation';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        data: PropTypes.object,
        style: PropTypes.object,
        info: PropTypes.oneOf(['below', 'aside']),
    };

    render() {
        if (!this.props.data) {
            return null;
        }

        let label = this.props.data.label;
        let proxemia = 'none';

        switch (this.props.data.proxemia) {
            case 1:
                proxemia = 'public';
                break;
            case 2:
                proxemia = 'business';
                break;
            case 3:
                proxemia = 'personal';
                break;
        }

        return (
            <Views.Common.Tooltip content={'Strength: ' + this.props.data.strength}>
                <div style={Object.assign({}, styles.container, this.props.style)}>
                    <RaisedButton
                        style={styles.button}
                        backgroundColor={colors.grey500}
                        onClick={event => this.refs.details.open()}
                    >
                        <span style={styles.label}>{label}</span>
                    </RaisedButton>

                    {this.renderInfo()}

                    <Views.Modal.Show
                        ref="details"
                        title={t('dna.deefind.relations.relationGraphTitle')}
                        style={{ width: '1200px' }}
                    >
                        <Graph
                            source={{
                                id: this.props.entity.id,
                                type: this.props.entity.type,
                                name: this.props.entity.preview.name,
                                flags: this.props.entity.preview.flags,
                            }}
                            data={this.props.data || {}}
                        />
                    </Views.Modal.Show>
                </div>
            </Views.Common.Tooltip>
        );
    }

    renderInfo() {
        let info;

        if (this.props.data.owned && this.props.data.owned.value) {
            info = t('dna.deefind.relations.info.owns', { value: formatStockValue(this.props.data.owned.value) });
        } else if (this.props.data.shared && this.props.data.shared.value) {
            info = t('dna.deefind.relations.info.share', {
                value: formatStockValue(this.props.data.shared.value),
            });
        }

        if (this.props.data.shared && this.props.data.shared.beneficial) {
            info = t('dna.deefind.relations.info.beneficialy'); // yes, the opposite
        }

        if (this.props.data.owned && this.props.data.owned.beneficial) {
            info = t('dna.deefind.relations.info.beneficial'); // yes, the opposite
        } else if (this.props.data.start && this.props.data.end) {
            info = t('dna.deefind.relations.info.fromTo', { start: this.props.data.start, end: this.props.data.end });
        } else if (this.props.data.start) {
            info = t('dna.deefind.relations.info.since', { value: this.props.data.start });
        } else if (this.props.data.end) {
            info = t('dna.deefind.relations.info.until', { value: this.props.data.end });
        }

        if (!info) return null;

        const style = this.props.info === 'aside' ? styles.infoAside : styles.infoBelow;

        return <div style={style}>{info}</div>;
    }
}
