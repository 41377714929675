// @flow

import type DnaExplorerStore from '../../app/stores/dnaExplorer/index';

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Screen from 'app/layout/screen';

import Empty from './empty';
import Stack from './stack/index';
import t from 'app/translation';

@inject('dnaExplorer')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.explorer';

    props: {
        dnaExplorer: DnaExplorerStore
    };

    static childContextTypes = {
        addStage: PropTypes.func.isRequired,
        openEntity: PropTypes.func.isRequired,
        exportEntities: PropTypes.func.isRequired,
        selectHandle: PropTypes.func.isRequired,
        selectSearch: PropTypes.func.isRequired,
        submitSearch: PropTypes.func.isRequired,
    };

    getChildContext () {
        return {
            addStage: this.props.dnaExplorer.addStage,
            openEntity: this.props.dnaExplorer.openEntity,
            exportEntities: this.props.dnaExplorer.exportEntities,
            selectHandle: this.props.dnaExplorer.selectHandle,
            selectSearch: this.props.dnaExplorer.selectSearch,
            submitSearch: this.props.dnaExplorer.submitSearch,
        };
    }

    render () {
        return (
            <Screen
                path="explorer"
                type="workspace"
                fallback="search"

                empty={ <Empty addTab={ this.props.dnaExplorer.addTab } /> }
                add={ this.props.dnaExplorer.addStage }
                select={ this.props.dnaExplorer.selectStage }
                close={ this.props.dnaExplorer.closeStage }

                stages={{
                    search: {
                        entry: this.computeEntry.bind(this),
                        content: Stack
                    },
                    entity: {
                        entry: this.computeEntry.bind(this),
                        content: Stack
                    }
                }}
            />
        );
    }

    computeEntry (state?: Object) {
        const entry = { icon: 'dna.search', label: 'New search' };
        const form = (state && state.search) ? state.search.form : undefined;

        if (form) {
            switch (form.kind) {
                case 'person':
                    entry.icon = 'dna.person';
                    entry.label = form.value || t('dna.deefind.person.tabTitle');
                    break;

                case 'company':
                    entry.icon = 'dna.company';
                    entry.label = form.value || t('dna.deefind.company.tabTitle');
                    break;

                case 'typology':
                    let search;
                    if (form.typology) {
                        switch (form.typology.provider) {
                            case 301:
                                search = t('dna.deefind.typology.typologieTitle.challenges', {year: '2016'});
                                break;
                            case 'fr.jdn.incomes':
                                search =t('dna.deefind.typology.typologieTitle.incomesCAC40SBF120');
                                break;
                            case 'fr.opendata.mayors':
                                search = t('dna.deefind.typology.typologieTitle.mayors', {value: 2014});
                                break;
                            case 302:
                                search = t('dna.deefind.typology.typologieTitle.deputies', {value: 2015});
                                break;
                        }
                    }
                    entry.icon = 'dna.typology';
                    entry.label = search || t('dna.deefind.typology.tabTitle');
                    break;
            }
        }

        return entry;
    }
}
