import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    link: {
        display: 'inline-block',
        lineHeight: '24px',
        cursor: 'pointer',
        marginLeft: '20px',
    },
    icon: {
        float: 'left',
        marginRight: '5px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.survey.field.remarks.button';

    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        onClick: PropTypes.func,
    };

    state = { hover: false };

    render() {
        const blue = this.props.value || this.state.hover;
        const icon = this.props.value ? 'deeligenz.survey.concern' : this.props.onClick ? 'do.update' : 'base.empty';

        return (
            <span
                style={Object.assign({}, styles.link, { cursor: this.props.onClick ? 'pointer' : 'default' })}
                onMouseEnter={this.props.onClick ? event => this.setState({ hover: true }) : undefined}
                onMouseLeave={this.props.onClick ? event => this.setState({ hover: false }) : undefined}
                onClick={this.props.onClick}
            >
                <Icon type={icon} style={styles.icon} color={blue ? colors.userCold : colors.grey400} />

                <span
                    style={{
                        color: blue ? colors.userCold : colors.grey500,
                        textDecoration: this.state.hover ? 'underline' : 'none',
                    }}
                >
                    {this.props.label}
                </span>
            </span>
        );
    }
}
