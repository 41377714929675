// @flow

import type { LinkProfile, StockPrediction } from '@deecision/deefind-types/model';

export function makeStocksExitPrediction(
    links: LinkProfile[],
    horizonMax: number,
    vcsLinked: { [id: string]: LinkProfile[] },
): StockPrediction[] {
    const predicts = [];

    for (const link of links) {
        if (link.target.funded && link.owned && !link.owned.ceased) {
            for (const vc of vcsLinked[link.target.id]) {
                const fundingYear = getFundingYear(link, vc);
                const potentialCashout = makePotentialCashout(link);

                const results = getPredictionsByHorizon(potentialCashout, fundingYear);

                predicts.push({
                    type: 'vc',
                    label: link.target.name,
                    fund: vc.target.name,
                    year1: results[0],
                    year2: results[1],
                    year3: results[2],
                    year4: results[3],
                    year5: results[4],
                });
            }
        }
    }

    return predicts;
}

function getPrediction(yearsInFound: number, yearsInFuture: number): ?number {
    const predictions = [
        [3.41, 15.25, 43.33, 80.76, 98.81, 100.0],
        [12.26, 33.13, 66.05, 93.82, 99.92, 100.0],
        [23.79, 49.23, 81.79, 98.65, 100.0, 100.0],
        [33.38, 64.14, 92.56, 99.87, 100.0, 100.0],
        [46.16, 79.27, 98.21, 99.99, 100.0, 100.0],
        [61.49, 91.36, 99.61, 100.0, 100.0, 100.0],
        [77.58, 95.52, 100.0, 100.0, 100.0, 100.0],
        [80.0, 100.0, 100.0, 100.0, 100.0, 100.0],
        [100.0, 100.0, 100.0, 100.0, 100.0, 100.0],
    ];
    if (predictions[yearsInFound - 1] && predictions[yearsInFound - 1][Math.max(yearsInFuture-1,0)]) {
        return predictions[yearsInFound - 1][Math.max(yearsInFuture-1,0)];
    }

    return undefined;
}

function getPredictionsByHorizon(value: number, funded: number): Object[] {
    const results = [];
    const yearsInFound = new Date().getFullYear() - funded;

    for (let i = 1; i <= 5; i++) {
        const currentProba = getPrediction(yearsInFound, i);
        results.push({
            probability: currentProba,
            potentialCashout: value,
            expectedValue: (value * currentProba) / 100,
            horizon: i,
        });
    }

    return results;
}

function makePotentialCashout(link: LinkProfile): number {
    return link.target.valuation && link.target.valuation.value ? link.target.valuation.value : 0;
}

function getFundingYear(link: LinkProfile, vc: LinkProfile): number {
    const sharedStart = vc.shared && vc.shared.start ? vc.shared.start : undefined;

    if (typeof sharedStart === 'undefined' && typeof link.target.funded !== 'number') {
        return new Date().getFullYear() - 3;
    }

    if (typeof sharedStart !== 'undefined') {
        return parseInt(sharedStart.substr(0, 4));
    }

    if (typeof link.target.funded === 'number') {
        return parseInt(String(link.target.funded).substr(0, 4));
    }

    return new Date().getFullYear() - 3;
}

export function getPredictStockExit(funded: number): number {
    const years = new Date().getFullYear() - funded;
    return getCurrentPrediction(years, 1);
}
