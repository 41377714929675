import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { forEach } from 'lodash';

const styles = {
    subCheckboxRow: {
        display: 'none',
        fontSize: '14px',
        position: 'absolute',
        backgroundColor: 'rgb(245, 245, 245)',
        border: '1px solid rgb(238, 238, 238)',
        paddingLeft: '15px',
        paddingRight: '5px',
        zIndex: '99',
    },
    modalSubCheckboxRow: {
        fontSize: '14px',
        margin: '8px',
        zIndex: '99',
    },
    checkboxCell: {
        display: 'inline-block',
    },
    modalCheckboxCell: {
        display: '',
        width: '100%',
        margin: '5px',
        border: 'solid 1px rgba(0,0,0,0.12)',
        borderRadius: '3px',
        backgroundColor: 'rgb(245, 245, 245)',
    },
    checkbox: {
        display: 'inline-block',
        marginLeft: '12px',
        marginRight: '0px',
    },
    modalCheckbox: {
        display: '',
        marginLeft: '12px',
    },
    subCheckbox: {
        float: 'none',
        textAlign: 'left',
        marginRight: '0px',
    },
    modalSubCheckbox: {
        width: '100%',
        border: 'solid 1px rgba(0,0,0,0.12)',
        borderRadius: '3px',
        margin: '5px',
    },
    checkboxHover: {
        display: 'block',
    },
}

let state;

export const filtersList = {
    'Annonces diverses' : [true, 0],
    'Créations' : [true, 0],
    'Dépôt des comptes' : [true, 0],
    'Immatriculations' : [true, 0],
    'Modifications' : [true, 0],
    'Préventions' : [true, 0],
    'Prévention des difficultés des entreprises ou Procédures collectives' : [true, 0],
    'Radiations' : [true, 0],
    'Ventes et cessions' : [true, 0],
}

export const subfiltersList = {
    'administration' : [true, 0],
    'survenue sur le capital' : [true, 0],
    'du capital' : [true, 0],
    '\'activité' : [true, 0],
    'nouveau siège' : [true, 0],
}

export const renderFilters = (handleChange, handleSubChange, handleMouseHover, myState, modal) => {
    let result = [];
    state = myState;

    forEach(Object.keys(state.filters), (filter) => {
        state.filters[filter][1] > 0 ? result.push(renderCheckbox(handleChange, handleSubChange, handleMouseHover, filter, modal)): { };
    });
    return result;
}

const renderCheckbox = (handleChange, handleSubChange, handleMouseHover, filter, modal) => {

    const cellProps = {
        onMouseEnter: event => handleMouseHover(filter),
        onMouseLeave: event => handleMouseHover(undefined),
    };

    let CheckBoxStyle = state.filterDisp === 'Modifications'
        ? Object.assign({ }, styles.subCheckboxRow, styles.checkboxHover) : styles.subCheckboxRow;
    CheckBoxStyle= modal ? styles.modalSubCheckboxRow : CheckBoxStyle;

    let checkBoxSelection = modal ? styles.modalCheckboxCell : styles.checkboxCell;
    checkBoxSelection = !state.filters[filter][0] && modal ?
        Object.assign({ }, checkBoxSelection, { opacity: '0.5' }) : checkBoxSelection;

    return (
        <div key={ filter } style={ checkBoxSelection }>
            <FormControlLabel
                style={ { width: '100%' } }
                control={
                    <Checkbox
                        checked={ state.filters[filter][0] }
                        onChange={ handleChange }
                        name={ filter }
                        color="primary"
                        style={ modal ? styles.modalCheckbox : styles.checkbox }
                    />
                }
                label={ filter + ' (' + state.filters[filter][1] + ')' +
                (filter === 'Modifications' && !modal ? (state.filterDisp === 'Modifications' ? ' ▲' : ' ▼') : '')}
                { ...cellProps }
            />
            {filter === 'Modifications' ? <FormGroup row style={ CheckBoxStyle } { ...cellProps }>
                { renderSubFilters(handleSubChange, modal) }
            </FormGroup> : ''}
        </div>
    )
}

const renderSubFilters = (handleSubChange, modal) => {
    let result = [];

    forEach(Object.keys(state.subfilters), (filter) => {
        state.subfilters[filter][1] > 0 ?
            result.push(renderSubCheckbox(handleSubChange, filter, modal)): { };
    });
    return result;
}

const renderSubCheckbox = (handleSubChange, filter, modal) => {
    let modalSubCheckbox = !state.subfilters[filter][0] ?
        Object.assign({ }, styles.modalSubCheckbox,
            { backgroundColor: 'rgb(255, 255, 255)', opacity : '0.5' }) : styles.modalSubCheckbox;
    return (
        <FormControlLabel
            key={ filter }
            style={ modal ? modalSubCheckbox : { display: 'flex' } }
            control={
                <Checkbox
                    checked={ state.subfilters[filter][0] }
                    onChange={ handleSubChange }
                    name={ filter }
                    color="primary"
                    style={ styles.subCheckbox }
                    disabled={ !state.filters['Modifications'][0] }
                />
            }
            label={ (filter === '\'activité' ? 'de l' + filter : filter) + ' (' + state.subfilters[filter][1] + ')' }
        />
    )
}

export const getFilters = (state, substate, list) => {
    forEach(list, (entity) => {
        forEach(Object.keys(state), (filter) => {
            if (entity.source.name !== 'Qwant' && entity.category.toLowerCase().includes(filter.toLowerCase())) {
                state[filter][1] += 1;
                if (filter === 'Modifications') {
                    forEach(Object.keys(substate), (subfilter) => {
                        if (entity.desc.toLowerCase().includes(subfilter.toLowerCase())) {
                            substate[subfilter][1] += 1;
                        }
                    });
                }
            }
        });
    });
}

export const parseObject = (list, filters, subfilters) => {
    let myEntities = [];

    forEach((list), (entity) => {
        entity.urlPdf = entity.urlPdf.replace('/annonce/telecharger/BODACC_', '/telechargements/COMMERCIALES/PDF/').replace('%5C', '/');
        forEach(Object.keys(filters), (filter) => {
            if (entity.source.name !== 'Qwant' && entity.category.toLowerCase().includes(filter.toLowerCase()) && filters[filter][0]) {
                if (filter === 'Modifications') {
                    forEach(Object.keys(subfilters), (subfilter) => {
                        if (entity.desc.toLowerCase().includes(subfilter.toLowerCase()) && subfilters[subfilter][0]) {
                            let inIt = false;
                            forEach(myEntities, (myEnt) =>{
                                if (entity === myEnt) {
                                    inIt = true;
                                }
                            })
                            !inIt ? myEntities.push(entity): {} ;
                        }
                    });
                } else {
                    myEntities.push(entity);
                }
            }
        });
    });
    return myEntities;
}
