// @flow

import type { DeetectInput, DeetectResult } from '@deecision/deefind-types/client';
import { sortBy, get } from 'lodash';
import t from 'app/translation';

const ACCURACY = {
    '3': 'High',
    '2': 'Mid',
    '1': 'Low',
};

export function makeExportHeader(
    type: string,
    source: 'person' | 'company',
    relation?: 'person' | 'company',
): string[] {
    const direct1 =
        source === 'company'
            ? ['Name', 'Country']
            : ['Last name', 'First name', 'Birth date', 'National Id. label', 'National Id. value'];
    const direct2 = ['Reech', 'Risk', 'Potential', 'Accuracy', 'Completeness'];
    const direct3 = ['Country', 'City', 'Address', 'Email', 'Phone', 'LinkedIn'];
    const direct4 =
        source === 'company'
            ? [
                'CEO first name',
                'CEO last name',
                'CEO title',
                'CFO first name',
                'CFO last name',
                'CFO title',
                'MD first name',
                'MD last name',
                'MD title',
            ]
            : [];

    let direct5 = [];
    if (type === 'upsell') {
        direct5 = ['Upsell', 'Upsell %', 'cashout', 'aum'];
    }

    if (type === 'augmented') {
        direct5 = ['Entité', 'Segmentation Initiale', 'AUM (actifs sous gestion)', 'Potentiel sélectionné', 'Segmentation Augmentée'];
    }

    if (type === 'covid19' && source === 'person') {
        direct5 = ['Ratio covid19 %', 'Impacted potential covid19'];
    } else if (type === 'covid19' && source === 'company') {
        direct5 = ['Covid19 Impacted', 'Activity'];
    }

    if (!relation) {
        return direct1
            .concat(direct2)
            .concat(direct3)
            .concat(direct4)
            .concat(direct5);
    }

    if (type === 'network') {
        return networkExport(relation, direct1
            .concat(direct2)
            .concat(direct3)
            .concat(direct4)
            .concat(direct5));
    }

    if (type === 'networkPredict') {
        return networkExportPredict(relation, direct1
            .concat(direct2)
            .concat(direct3)
            .concat(direct4)
            .concat(direct5));
    }
}

function networkExportPredict(relation, direct) {
    const network1 = ['Relation proximity', 'Relation level'];

    const network2 = ['Relation last name', 'Relation first name', 'Relation birth date'];

    const network3 = [
        'Relation reech',
        'Relation risk',
        'Relation potential',
        'Relation accuracy',
        'Relation completeness',
        'Relation score',
    ];

    const network4 = [
        'Company Turnover',
        'Prediction Type',
        'Infos',
        'Probability Year 1',
        'Probability Year 2',
        'Probability Year 3',
        'Probability Year 4',
        'Probability Year 5',
    ];


    return direct
        .concat(network1)
        .concat(network2)
        .concat(network3)
        .concat(network4);
}

function networkExport(relation, direct) {
    const network1 = ['Relation proximity', 'Relation level'];

    const network2 =
        relation === 'company'
            ? ['Relation name', 'Relation country']
            : ['Relation last name', 'Relation first name', 'Relation birth date'];

    const network3 = [
        'Relation reech',
        'Relation risk',
        'Relation potential',
        'Relation accuracy',
        'Relation completeness',
        'Relation score',
    ];

    const network4 = [
        'Relation country',
        'Relation city',
        'Relation address',
        'Relation email',
        'Relation phone',
        'National Id. label',
        'National Id. value',
    ];

    const network5 =
        relation === 'company'
            ? [
                'Relation CEO first name',
                'Relation CEO last name',
                'Relation CEO title',
                'Relation CFO first name',
                'Relation CFO last name',
                'Relation CFO title',
                'Relation MD first name',
                'Relation MD last name',
                'Relation MD title',
                'turnover',
            ]
            : [];

    return direct
        .concat(network1)
        .concat(network2)
        .concat(network3)
        .concat(network4)
        .concat(network5);
}

export function makeExportRow(
    type: string,
    input: DeetectInput,
    result: DeetectResult,
    relation?: DeetectResult,
): string[] {
    const direct1 =
        input.type === 'company'
            ? [input.profile.name || '', input.profile.country || '']
            : [
                input.profile.lastName || '',
                input.profile.firstName || '',
                input.profile.birthDate ? input.profile.birthDate : result.profile.birthDate || '',
                get(result, 'profile.nationalIdentifier.label', ''),
                get(result, 'profile.nationalIdentifier.value', ''),
            ];

    const direct2 = [
        result.reech || '',
        result.scores ? formatNumber(result.scores.risk, 2) : '',
        result.scores && result.scores.potential ? formatNumber(result.scores.potential.value) : '',
        result.scores && result.scores.potential ? ACCURACY[result.scores.potential.accuracy] || '' : '',
        result.scores && result.scores.potential ? formatNumber(result.scores.potential.reliability) : '',
    ];

    const direct3 = [
        result.profile.address ? result.profile.address.country || '' : '',
        result.profile.address ? result.profile.address.city || '' : '',
        result.profile.address ? result.profile.address.details || '' : '',
        result.profile.address ? result.profile.address.email || '' : '',
        result.profile.address ? result.profile.address.phone || '' : '',
        result.profile.linkedin ? result.profile.linkedin.join('\n') : '',
    ];

    const direct4 =
        input.type === 'company'
            ? [
                result.profile.roles
                    ? result.profile.roles.ceo
                    ? result.profile.roles.ceo.firstName || ''
                    : ''
                    : '',
                result.profile.roles ? (result.profile.roles.ceo ? result.profile.roles.ceo.lastName || '' : '') : '',
                result.profile.roles ? (result.profile.roles.ceo ? result.profile.roles.ceo.label || '' : '') : '',
                result.profile.roles
                    ? result.profile.roles.cfo
                    ? result.profile.roles.cfo.firstName || ''
                    : ''
                    : '',
                result.profile.roles ? (result.profile.roles.cfo ? result.profile.roles.cfo.lastName || '' : '') : '',
                result.profile.roles ? (result.profile.roles.cfo ? result.profile.roles.cfo.label || '' : '') : '',
                result.profile.roles ? (result.profile.roles.md ? result.profile.roles.md.firstName || '' : '') : '',
                result.profile.roles ? (result.profile.roles.md ? result.profile.roles.md.lastName || '' : '') : '',
                result.profile.roles ? (result.profile.roles.md ? result.profile.roles.md.label || '' : '') : '',
            ]
            : [];

    let direct5 = [];
    if (type === 'upsell') {
        direct5 = [
            formatNumber(get(result, 'scores.upsell.upsell', undefined)),
            formatNumber(get(result, 'scores.upsell.upsellPrct', undefined)),
            formatNumber(get(result, 'scores.upsell.cashaout', undefined)),
            formatNumber(get(result, 'scores.upsell.aum', undefined)),
        ];
    }

    if (type === 'augmented') {
        direct5 = [
            get(result, 'scores.committeeDna', undefined),
            get(result, 'scores.segment', undefined),
            get(result, 'scores.upsell.aum', undefined),
            get(result, 'scores.selectedPotential', undefined),
            get(result, 'scores.segmentDna', undefined),
        ];
    }

    if (type === 'covid19') {
        direct5 =
            input.type === 'person'
                ? [
                    result.scores.potentialCovid19 ? formatNumber(result.scores.potentialCovid19.ratio) || '' : '',
                    result.scores.potentialCovid19 ? formatNumber(result.scores.potentialCovid19.value) || '' : '',
                ]
                : [
                    result.scores.potentialCovid19 ? formatNumber(result.scores.potentialCovid19.ratio) || '' : '',
                    result.profile.gicsCode ? t(`common.gics.${get(result, 'profile.gicsCode')}`) || '' : '',
                ];
    }

    const direct = direct1
        .concat(direct2)
        .concat(direct3)
        .concat(direct4)
        .concat(direct5);

    if (!relation) {
        return direct;
    }

    const network1 = [
        formatNumber(relation.scores.proximity, 1),
        relation.relation ? relation.relation.label || '' : '',
    ];

    const network2 =
        relation.type === 'company'
            ? [relation.profile.name || '', relation.profile.country || '']
            : [relation.profile.firstName || '', relation.profile.lastName || '', relation.profile.birthDate || ''];

    const network3 = [
        relation.reech || '',
        formatNumber(relation.scores.risk, 2),
        relation.scores.potential ? formatNumber(relation.scores.potential.value) : '',
        relation.scores.potential ? ACCURACY[relation.scores.potential.accuracy] || '' : '',
        relation.scores.potential ? formatNumber(relation.scores.potential.reliability) : '',
        formatNumber(relation.scores.global),
    ];

    const base = direct
        .concat(network1)
        .concat(network2)
        .concat(network3);

    if (type === 'network') {
        return exportRowNetwork(relation, base);
    }

    if (type === 'networkPredict') {
        return exportRowNetworkPredict(relation, base, result);
    }

    return [];
}

function exportRowNetworkPredict(relation, base, result) {
    const network4 = [
        result.profile.turnover,
        relation.prediction.type,
        `${relation.prediction.label} ${get(relation,'prediction.fund','ans')}`,
        Math.round(relation.prediction.year1.probability),
        Math.round(relation.prediction.year2.probability),
        Math.round(relation.prediction.year3.probability),
        Math.round(relation.prediction.year4.probability),
        Math.round(relation.prediction.year5.probability),
    ];


    return base.concat(network4);
}

function exportRowNetwork(relation, base) {
    const network4 = [
        relation.profile.address ? relation.profile.address.country || '' : '',
        relation.profile.address ? relation.profile.address.city || '' : '',
        relation.profile.address ? relation.profile.address.details || '' : '',
        relation.profile.address ? relation.profile.address.email || '' : '',
        relation.profile.address ? relation.profile.address.phone || '' : '',
        get(relation, 'profile.nationalIdentifier.label', ''),
        get(relation, 'profile.nationalIdentifier.value', ''),
    ];

    const network5 =
        input.type === 'company'
            ? [
                relation.profile.roles
                    ? relation.profile.roles.ceo
                    ? relation.profile.roles.ceo.firstName || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.ceo
                    ? relation.profile.roles.ceo.lastName || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.ceo
                    ? relation.profile.roles.ceo.label || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.cfo
                    ? relation.profile.roles.cfo.firstName || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.cfo
                    ? relation.profile.roles.cfo.lastName || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.cfo
                    ? relation.profile.roles.cfo.label || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.md
                    ? relation.profile.roles.md.firstName || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.md
                    ? relation.profile.roles.md.lastName || ''
                    : ''
                    : '',
                relation.profile.roles
                    ? relation.profile.roles.md
                    ? relation.profile.roles.md.label || ''
                    : ''
                    : '',
            ]
            : [];

    return base.concat(network4).concat(network5);
}

function formatNumber(value: ?number, decimals?: number = 0): string {
    if (!value) {
        return '';
    }

    return value.toFixed(decimals);
}

export function makeExportPredictHeader(): string[] {
    return [
        'Last name',
        'First name',
        'Birth date',
        '1Y Probability',
        '1Y Potential Cash Out',
        '1Y Cash Out Expectancy',
        '2Y Probability',
        '2Y Potential Cash Out',
        '2Y Cash Out Expectancy',
        '3Y Probability',
        '3Y Potential Cash Out',
        '3Y Cash Out Expectancy',
        '4Y Probability',
        '4Y Potential Cash Out',
        '4Y Cash Out Expectancy',
        '5Y Probability',
        '5Y Potential Cash Out',
        '5Y Cash Out Expectancy',
    ];
}

export function makeExportPredictRow(input: DeetectInput, result: DeetectResult): string[] {
    let curPred = {};
    if (result.scores.predictions && result.scores.predictions.length >= 0) {
        const sortedPredictions = sortBy(result.scores.predictions, `year1.probablity`);
        curPred = sortedPredictions.pop();
    }

    return [
        input.profile.lastName || '',
        input.profile.firstName || '',
        input.profile.birthDate ? input.profile.birthDate : result.profile.birthDate || '',
        curPred && curPred.year1 ? formatNumber(curPred.year1.probability) : '',
        curPred && curPred.year1 ? formatNumber(curPred.year1.potentialCashout) : '',
        curPred && curPred.year1 ? formatNumber(curPred.year1.expectedValue) : '',
        curPred && curPred.year2 ? formatNumber(curPred.year2.probability) : '',
        curPred && curPred.year2 ? formatNumber(curPred.year2.potentialCashout) : '',
        curPred && curPred.year2 ? formatNumber(curPred.year2.expectedValue) : '',
        curPred && curPred.year3 ? formatNumber(curPred.year3.probability) : '',
        curPred && curPred.year3 ? formatNumber(curPred.year3.potentialCashout) : '',
        curPred && curPred.year3 ? formatNumber(curPred.year3.expectedValue) : '',
        curPred && curPred.year4 ? formatNumber(curPred.year4.probability) : '',
        curPred && curPred.year4 ? formatNumber(curPred.year4.potentialCashout) : '',
        curPred && curPred.year4 ? formatNumber(curPred.year4.expectedValue) : '',
        curPred && curPred.year5 ? formatNumber(curPred.year5.probability) : '',
        curPred && curPred.year5 ? formatNumber(curPred.year5.potentialCashout) : '',
        curPred && curPred.year5 ? formatNumber(curPred.year5.expectedValue) : '',
    ];
}

export function makeExportListedRelationsHeader(): string[] {
    return ['Last name', 'First name', 'PRSC-S5EB', 'PRSC-Detention', 'PRSC-SocieteCotée', 'ISIN'];
}

export function makeExportListedRelationsRow(
    input: DeetectInput,
    result: DeetectResult,
    listedRelationExport: Array<string[]>,
): Array<string[]> {
    const levelLabel = {
        x: 'executive',
        b: 'boardmember',
        s: 'shareholder',
    };

    if (!result.profile.listedRelations || !result.profile.listedRelations.length) {
        return listedRelationExport;
    }
    for (const listedRelation of result.profile.listedRelations) {
        listedRelationExport.push([
            input.profile.lastName || '',
            input.profile.firstName || '',
            levelLabel[String(listedRelation.level)] || '',
            formatNumber(listedRelation.stock) || '',
            listedRelation.company || '',
            listedRelation.isin || '',
        ]);
    }

    return listedRelationExport;
}
