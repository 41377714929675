// @flow

import type { EntityAnyTarget, EntityInnerTarget, EntityMultiTarget } from '@deecision/deefind-types/model';

import flatten from 'lodash/flatten';
import map from 'lodash/map';

export function makeDealsTargets(targetMap: { [id: string]: EntityAnyTarget[] }): EntityAnyTarget[] {
    const results: EntityAnyTarget[] = [];

    for (const id of Object.keys(targetMap)) {
        const targets = targetMap[id] || [];

        if (!targets.length) {
            continue;
        }

        if (targets.length === 1) {
            results.push(targets[0]);
            continue;
        }

        const relevance = Math.max(...targets.map(target => target.relevance || 0));
        const selection = targets.filter(target => target.hasOwnProperty('id') && (target.relevance || 0) > relevance);

        if (!selection.length) {
            continue;
        }

        if (selection.length === 1) {
            results.push(selection[0]);
            continue;
        }

        const target: EntityMultiTarget = {
            name: selection[0].name || '',
            country: selection[0].country,
            relevance,
            ids: map(selection, 'id'),
        };

        results.push(target);
    }

    return results;
}

export function mergeDealsMultiTargets(targets: EntityMultiTarget[]): ?EntityMultiTarget {
    if (!targets.length) {
        return undefined;
    }

    if (targets.length === 1) {
        return targets[0];
    }

    const relevance = Math.max(...targets.map(target => target.relevance || 0));

    return {
        name: targets[0].name,
        country: targets[0].country,
        relevance,
        ids: flatten(targets.map(target => target.ids)),
    };
}

export function makeDealsInnerTarget(target: any): ?EntityInnerTarget {
    return target.id ? target : null;
}

export function makeDealsMultiTarget(target: any): ?EntityMultiTarget {
    return Array.isArray(target.ids) ? target : null;
}
