import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import Display from '../../common/display';
import Flag from '../../common/flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.typologies.fr.jdn.incomes.display';

    static propTypes = {
        relevance: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        relations: PropTypes.array,
    };

    render () {
        return (
            <Display
                flag={ <Flag char="I" shape="round" color="grey" type="outline" /> }
                relevance={ this.props.relevance }
                relations={ this.props.data.relations }

                name={ this.props.data.person }
                title={t('dna.deefind.typology.CACtitle')}
                contents={[]}
                sources={[ this.props.data.identifier ]}

                properties={[
                    {
                        label: 'Global',
                        value: (<Views.Common.Number
                            type="currency"
                            scale="M"
                            value={ this.props.data.total.global }
                        />)
                    },
                    {
                        label: 'Year',
                        value: this.props.data.total.year
                    },
                    'Detailed parts',
                    {
                        label: t('dna.deefind.typology.CACFixedPart'),
                        value: (<Views.Common.Number
                            type="currency"
                            scale="M"
                            value={ this.props.data.total.fixed }
                        />)
                    },
                    {
                        label: t('dna.deefind.typology.CACVariablePart'),
                        value: (<Views.Common.Number
                            type="currency"
                            scale="M"
                            value={ this.props.data.total.variable }
                        />)
                    },
                    {
                        label: t('dna.deefind.typology.CACExceptionPart'),
                        value: (<Views.Common.Number
                            type="currency"
                            scale="M"
                            value={ this.props.data.total.exceptional }
                        />)
                    },
                    {
                        label: t('dna.deefind.typology.CACBenefitsInKind'),
                        value: (<Views.Common.Number
                            type="currency"
                            scale="M"
                            value={ this.props.data.total.fringe }
                        />)
                    },
                    {
                        label: t('dna.deefind.typology.CACPresenceToken'),
                        value: (<Views.Common.Number
                            type="currency"
                            scale="M"
                            value={ this.props.data.total.presence }
                        />)
                    },
                ]}
            />
        );
    }
}
