import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'app/theme/colors';
import Message from './message';
import Composer from './composer';
// import Branch from './branch';

// import TYPES from './message/types';
const ROOT_TYPES = [ 'info', 'idea', 'question', 'decision', 'alert' ];

const styles = {
    container: {
        marginLeft: '10px',
        position: 'relative'
    },
    wrapper: {
        position: 'relative'
    }
};

export default class List extends React.Component<any, any> {

    static displayName = 'app.views.discussion.thread.list';

    static propTypes = {
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        handleDelete: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isChildren: PropTypes.bool
    };

    state = {
        editions: [],
        replies: []
    };

    render () {
        let entities = {};
        if (this.props.isChildren) {
          entities = this.props.entities;
        } else {
          for (const entity of this.props.entities) {
            if (!entity.parentId) {
                entities[entity.id] = _.extend(entities[entity.id], entity, {children: []});
            } else if (entity.parentId) {
                if (!entities[entity.parentId]) {
                  entities[entity.parentId] = {children: []};
                }

                entities[entity.parentId].children.push(entity);
            }
          }
        }

        return (
            <div style={ styles.container }>
                {/* this.props.parent ? (<Branch type="enter" />) : null */}
                { Object.values(entities).map(this.renderWrapper.bind(this)) }
            </div>
        );
    }

    renderWrapper (entity, index) {
        // const next = this.props.parent
        //     ? ( index < this.props.entities.length - 1
        //         || this.security.discussion.hasReply(this.props.parent.$identifier)
        //     )
        //     : true;
        //
        // const child = (
        //     this.security.discussion.hasChild(entity.$identifier)
        //     || this.security.discussion.hasReply(entity.$identifier)
        //     || ( this.props.parent && this.security.discussion.hasReply(this.props.parent.$identifier))
        // );
        //
        return (
            <div
                key={ 'wrapper:' + entity.id }
                style={ styles.wrapper }
            >
                {/* next ? (<Branch type="next" />) : null */}
                { this.renderMessage(entity, !! this.props.isChildren) }
                { this.renderChildren(entity) }
                { this.renderReply(entity.id) }
            </div>
        );
    }

    renderMessage (entity, isChild) {
        // if (_.includes(this.state.editions, entity.$identifier))
        //     return (
        //         <Composer
        //             entity={ entity }
        //             types={ this.props.parent ? TYPES[ this.props.parent.type ].children : ROOT_TYPES }
        //             target={ this.props.target }
        //             team={ this.props.team }
        //             onClose={ event => this.setState({ editions: _.difference(this.state.editions, [ entity.$identifier ]) }) }
        //         />
        //     );
        //
        // const onEdit = this.security.security.allowUpdate('feat:messages', entity)
        //     ? event => this.setState({ editions: _.union(this.state.editions, [ entity.$identifier ]) })
        //     : undefined;
        return (
            <Message
                key={ 'message:' + entity.id }
                entity={ entity }
                child={ isChild }
                onEdit={ () => { } }
                handleDelete={ this.props.handleDelete }
                handleReply={ (id) => {this.state.replies.push(id); this.setState({ replies:  this.state.replies})}}
            />
        );
    }

    renderChildren (entity) {
        if (this.props.isChildren || ! entity.children.length)
            return null;
        return (
            <div style={{marginLeft: '25px'}}>
            <List
                key={ 'list:' + entity.id }
                target={ entity.id }
                entities={ entity.children }
                parent={ entity.id }
                handleDelete={ this.props.handleDelete }
                handleSubmit= { this.props.handleSubmit }
                isChildren={true}
            />
            </div>
        );
    }

    renderReply (id: any) {
        if (this.state.replies.indexOf(id) >= 0)
        return (
            <div style={{marginLeft: '55px'}}>
            <Composer
                types={ this.props.parent ? TYPES[ this.props.parent.type ].children : ROOT_TYPES }
                target={ this.props.target }
                onClose={ () => {this.state.replies.splice(this.state.replies.indexOf(id)); this.setState({replies: this.state.replies})} }
                parent={ id }
                handleSubmit={ this.props.handleSubmit}
            />
            </div>
        );
    }
}
