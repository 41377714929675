import { extend } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import HalfPie from './halfPie';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.chart.container';

    static propTypes = {
        type: PropTypes.oneOf([ 'half-pie' ]),
        sort: PropTypes.oneOf([ 'asc', 'desc' ]),
        title: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        type: 'half-pie',
        sort: 'desc'
    };

    constructor (props) {
        super(props);
        this.state = { highlighted: undefined };
        this.initialize(props);
    }

    UNSAFE_componentWillReceiveProps (props) {
        this.initialize(props);
    }

    initialize (props) {
        let total = 0;
        const entries = [];

        React.Children.forEach(props.children, child => {
            if (! child) return;

            switch (child.type.displayName) {
                case 'app.views.chart.entry':
                    total += child.props.value;

                    entries.push({
                        id: `item-${entries.length + 1}`,
                        label: child.props.label,
                        value: child.props.value,
                        // children: React.Children.toArray(child.props.children)
                    });
                    break;
            }
        });

        const sort = this.props.sort === 'desc'
            ? (a, b) => b.value - a.value
            : (a, b) => a.value - b.value;

        this.state.entries = entries.sort(sort).map(entry => extend({}, entry, {
            percent: total ? Math.round(100 * entry.value / total) : undefined
        }));
    }

    render () {
        const props = {
            style: this.props.style,
            title: this.props.title,
            entries: this.state.entries,
            highlighted: this.state.highlighted,
            onHighlight: key => this.setState({ highlighted: key }),
        };

        switch (this.props.type) {
            case 'half-pie': return (<HalfPie { ...props } />);
        }

        throw new Error(`Invalid chart type "${this.props.type}".`);
    }
}
