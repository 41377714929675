// @flow

import React from 'react';
import PropTypes from 'prop-types';
import List from './list';
import Details from './details';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: '17px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams';

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    state = { selection: null };

    render() {
        return (
            <div style={Object.assign({}, this.props.style, styles.container)}>
                <List selection={this.state.selection} onSelect={(id: string) => this.setState({ selection: id })} />
                <Details id={this.state.selection} />
            </div>
        );
    }
}
