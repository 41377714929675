import React from 'react';
import PropTypes from 'prop-types';
import Base from './base';
import colors from 'app/theme/colors';

const styles = {
    content: {
        padding: '15px 20px',
    },
    item: {
        display: 'flex',
        padding: '5px 0',
    },
    label: {
        width: '25%',
        color: colors.userCold,
        fontSize: '12px',
    },
    value: {
        width: '75%',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.properties';

    static propTypes = {
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.object),
        data: PropTypes.object,
        onSave: PropTypes.func,
    };

    render() {
        return (
            <Base
                title={this.props.title}
                fields={this.props.items || []}
                data={this.props.data}
                onSave={this.props.onSave}
            >
                {this.renderContent()}
            </Base>
        );
    }

    renderContent() {
        const data = this.props.data || {};

        return (
            <div style={styles.content}>
                {this.props.items.map(item => (
                    <div key={item.path} style={styles.item}>
                        <div style={styles.label}>{item.label}</div>
                        <div style={styles.value}>{data[item.path]}</div>
                    </div>
                ))}
            </div>
        );
    }
}
