import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import t from 'app/translation';

const SCOPES = [
    { key: 'all', label: 'Involvements' },
    { key: 'stocks', label: 'Investments' },
];

const STATUS = [
    { key: 'all', label: 'All' },
    { key: 'current', label: 'Current' },
];

const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
    },
    chart: {
    },
    header: {
        marginBottom: '20px',
    },
    buttons: {
        display: 'inline-block',
        borderRadius: '3px',
        overflow: 'hidden',
        marginRight: '15px',
    },
    button: {
        display: 'inline-block',
        padding: '0 15px',
        background: colors.grey100,
        color: colors.userCold,
        cursor: 'pointer',
        lineHeight: '40px',
    },
    buttonHover: {
        background: colors.grey200,
    },
    buttonCurrent: {
        background: colors.userCold,
        color: colors.white,
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.person.display.tabs.identity.investments';

    static propTypes = {
        data: PropTypes.array,
        style: PropTypes.object,
    };

    state = {
        scope: 'all',
        status: 'all',
        hover: null
    };

    render () {
        if (! this.props.data) {
            return (
                <Views.Common.Empty
                    style={this.props.style}
                    title="No investment data available"
                />
            );
        }

        const data = this.props.data.filter(item => item.scope === this.state.scope && item.status === this.state.status);

        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <div style={ styles.header }>
                    { this.renderSwitch(SCOPES, 'scope') }
                    { this.renderSwitch(STATUS, 'status') }
                </div>

                <Views.Chart.Container
                    title={ `${this.state.scope} activity` }
                    type="half-pie"
                    style={ styles.chart }
                >
                    { this.renderEntries(data.filter(item => item.type === 'activity'), 'common.activity') }
                </Views.Chart.Container>

                <Views.Chart.Container
                    title={ `${this.state.scope} country` }
                    type="half-pie"
                    style={ styles.chart }
                >
                    { this.renderEntries(data.filter(item => item.type === 'location'), 'common.country') }
                </Views.Chart.Container>
            </div>
        );
    }

    renderEntries (list, key) {
        return list.map((entry, index) => (
            <Views.Chart.Entry
                key={ 'e' + index }
                label={ t(key + '.' + String(entry.code).toUpperCase())  }
                value={ entry.ratio }
            />
        ));
    }

    renderSwitch (items, prop) {
        const current = this.state[prop];
        const buttons = items.map(item => (
            <span
                key={ item.key }
                style={ Object.assign({}, styles.button, item.key === current ? styles.buttonCurrent : (this.state.hover === item.key ? styles.buttonHover : {})) }
                onMouseEnter={ event => this.setState({ hover: item.key }) }
                onMouseLeave={ event => this.setState({ hover: null }) }
                onClick={ event => this.setState({ [prop]: item.key }) }
            >
                { item.label }
            </span>
        ));

        return (
            <span style={ styles.buttons }>
                { buttons }
            </span>
        )
    }
}
