import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Checklist from 'deeligenz/calendar/workload/checklist';
import colors from 'app/theme/colors';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import intersection from 'lodash/intersection';

const styles = {
    container: {
        width: '350px',
        padding: '20px',
        background: colors.grey200,
        borderRadius: '3px',
    },
    header: {
        marginBottom: '20px',
        lineHeight: '40px',
        height: '40px',
    },
    update: {
        float: 'right',
    },
    title: {
        fontSize: '19px',
    },
    empty: {
        lineHeight: '25px',
        color: colors.grey500,
    },
    attendee: {
        lineHeight: '25px',
    },
    modal: {
        width: '400px',
    },
};

@inject(stores => ({ teamMates: stores.auth.teamMates, contacts: stores.auth.contacts, sessionUser: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.agenda.members';

    static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired,
        contacts: PropTypes.object.isRequired,
    };

    listItems() {
        const items = [];

        for (const teamMate of this.props.teamMates || []) {
            items.push({
                id: teamMate.user.id,
                checked: (this.props.ids || []).indexOf(teamMate.user.id) >= 0,
                label: `${teamMate.user.firstName} ${teamMate.user.lastName}`,
            });
        }

        return items;
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    {this.props.readOnly ? null : <Views.Common.Button
                        type="round"
                        color="cold"
                        icon={<Icon type="do.update" />}
                        onClick={event => this.refs['form'].open()}
                        style={styles.update}
                        mini
                    />}

                    <Views.Modal.Show
                        ref="form"
                        icon={<Icon type="base.attendee" />}
                        title="Meeting attendees"
                        style={styles.modal}
                    >
                        <div>
                            <Checklist
                                items={this.listItems()}
                                onChange={items => {
                                    this.props.onChange((items || []).filter(e => e.checked === true).map(e => e.id));
                                }}
                            />
                        </div>
                    </Views.Modal.Show>

                    <span style={styles.title}>Attendees</span>
                </div>

                {Object.keys(this.props.contacts.users).length && this.props.ids.length ? (
                    this.props.ids.map((id: string) => (
                        <div key={id} style={styles.attendee}>
                        { this.props.contacts.users[id] ? `${this.props.contacts.users[id].firstName} ${this.props.contacts.users[id].lastName}` : null}

                        </div>
                    ))
                ) : (
                    <div style={styles.empty}>No attendees</div>
                )}
            </div>
        );
    }
}
