import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Tooltip from 'app/views/common/tooltip';

let styles = {
    container: {
        margin: '0',
        padding: '0',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    inline: {
        display: 'inline-block',
        marginRight: '20px'
    },
    wrapper: {
        display: 'inline-block',
        position: 'relative'
    },
    label: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        marginRight: '10px',
        color: colors.userCold,
        verticalAlign: 'top'
    },
    empty: {
        color: colors.grey500
    },
    value: {
        color: colors.grey700,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.properties.property';

    static propTypes = {
        label: PropTypes.node.isRequired,
        tooltip: PropTypes.string,
        value: PropTypes.node,
        inline: PropTypes.bool,
        align: PropTypes.number,
        style: PropTypes.object
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.inline ? styles.inline : {},
            this.props.style
        );

        if (this.props.tooltip)
            return (
                <li style={ style }>
                    <Tooltip content={this.props.tooltip}>
                        <div style={ styles.wrapper }>
                            { this.renderLabel() }
                            { this.renderValue() }
                        </div>
                    </Tooltip>
                </li>
            );

        return (
            <li style={ style }>
                { this.renderLabel() }
                { this.renderValue() }
            </li>
        );
    }

    renderLabel () {
        const style = this.props.align
            ? Object.assign({}, styles.label, { minWidth: `${this.props.align}px` })
            : styles.label;

        return (
            <span style={ style }>
                { this.props.label }
            </span>
        );
    }

    renderValue () {
        if (! this.props.value)
            return (
                <span style={ styles.empty }>
                    —
                </span>
            );

        return (
            <span style={ styles.value }>
                { this.props.value }
            </span>
        );
    }
}
