// @flow

import type { VigilanceSetup } from '@deecision/deefind-types/model';

export default function makeVigilanceSetup(config?: Object): VigilanceSetup {
    // @todo: use config data

    return {
        usa: 3,
        mandates: {
            warn: 50,
            alert: 150,
            veto: 5000,
        },
        lists: [
            {
                type: 'country',
                name: 'FATF blacklist',
                url: 'https://en.wikipedia.org/wiki/FATF_blacklist',
                score: 3,
                codes: ['ir', 'kp'],
            },
            {
                type: 'country',
                name: 'EU blacklist',
                url: 'https://ec.europa.eu/taxation_customs/tax-common-eu-list_en',
                score: 3,
                codes: ['as', 'bs', 'gu', 'na', 'pw', 'ws', 'kn', 'tt', 'vi'],
            },
            {
                type: 'country',
                name: 'FMI blacklist',
                url: 'http://www.imf.org/external/NP/ofca/OFCA.aspx',
                score: 3,
                codes: ['ad', 'ai', 'aw', 'bs', 'bz', 'bm', 'vg', 'ky', 'ck']
                    .concat(['cy', 'gi', 'gg', 'im', 'je', 'li', 'mo', 'my', 'mc'])
                    .concat(['ms', 'an', 'pw', 'pa', 'ws', 'sc', 'tc', 'vu']),
            },
            {
                type: 'activity',
                name: 'AML blacklist',
                score: 3,
                codes: ['4615', '4616', '4618', '4644', '4645', '4647', '4648', '4751', '4753', '4759', '4775']
                    .concat(['6411', '6419', '6420', '6430', '6491', '6492', '6499', '6511', '6512', '6520'])
                    .concat(['6612', '6619', '6622', '6629', '6810', '6831', '6832', '6920'])
                    .concat(['9003', '9004', '9200', '9311', '9312']),
            },
        ],
    };
}
