import PropTypes from 'prop-types';
import React from 'react';
import { extend } from 'lodash';
import colors from 'app/theme/colors';

const HEIGHT_OFFSET = 10;
const ITEM_HEIGHT = 25;

const styles = {
    container: {
        cursor: 'default',
    },
    entry: {
        lineHeight: ITEM_HEIGHT + 'px',
        height: ITEM_HEIGHT + 'px',
        background: 'white',
        fontSize: '15px',
        borderRadius: '3px',
    },
    entryHighlighted: {
        background: colors.grey200,
    },
    percent: {
        display: 'inline-block',
        width: '45px',
        textAlign: 'right',
        paddingRight: '3px',
        opacity: 0.75,
        background: 'transparent',
        borderRadius: '3px 0 0 3px',
    },
    percentEven: {
        color: colors.grey500,
    },
    percentOdd: {
        color: colors.userCold,
    },
    percentHighlighted: {
        background: colors.userHot,
        color: 'white',
        opacity: 1
    },
    triangle: {
        display: 'inline-block',
        width: '0',
        height: '',
        border: '5px solid transparent',
        borderWidth: '12.5px 5px',
        borderLeftColor: 'transparent',
        verticalAlign: 'middle'
    },
    triangleHighlighted: {
        borderLeftColor: colors.userHot,
    },
    label: {
        color: colors.grey700,
        whiteSpace: 'nowrap',
        maxWidth: '220px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        verticalAlign: 'middle',
        position: 'absolute'
    },
    labelHighlighted: {
        color: colors.black,
        //width: 'auto'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.chart.halfPie.list';

    static propTypes = {
        entries: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            percent: PropTypes.number.isRequired,
        })).isRequired,
        size: PropTypes.number.isRequired,
        highlighted: PropTypes.string,
        onHighlight: PropTypes.func.isRequired,
        style: PropTypes.object
    };

    render () {
        const height = this.props.entries.length * ITEM_HEIGHT;
        const size = this.props.size + HEIGHT_OFFSET * 2;

        return (
            <div
                style={ extend(
                    { height: height, marginTop: height > size ? 0 : (size - height) / 2 },
                    styles.container,
                    this.props.style
                ) }
                onMouseLeave={ event => this.props.onHighlight(undefined) }
            >
                { this.props.entries.map(this.renderEntry.bind(this)) }
            </div>
        );
    }

    renderEntry (entry, index) {
        const highlighted = this.props.highlighted === entry.id;

        return (
            <div
                key={ entry.id }
                style={ extend({},
                    styles.entry,
                    highlighted ? styles.entryHighlighted : {}
                )}
                onMouseEnter={ event => this.props.onHighlight(entry.id) }
            >
                <span style={ extend({},
                    styles.percent,
                    index % 2 === 0 ? styles.percentEven : styles.percentOdd,
                    highlighted ? styles.percentHighlighted : {}
                ) }>
                    { entry.percent || '0' } %
                </span>

                <span style={ extend({},
                    styles.triangle,
                    highlighted ? styles.triangleHighlighted : {}
                )} />

                <span style={ extend({},
                    styles.label,
                    highlighted ? styles.labelHighlighted : {}
                ) }>
                    { entry.label }
                </span>
            </div>
        );
    }
}
