import PropTypes from 'prop-types';
import React from 'react';
import slugify from 'speakingurl';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import t from 'app/translation';

const styles = {
    container: {
        border: '1px solid ' + colors.grey200,
        borderRadius: '10px',
        lineHeight: '20px'
    },
    entry: {
        borderTop: '1px solid ' + colors.grey200,
        display: 'flex'
    },
    type: {
        padding: '20px',
        width: '15%',
        fontWeight: 'bold',
        borderRight: '1px solid ' + colors.grey200,
    },
    relevance: {
        fontWeight: 'normal',
        color: colors.userCold,
    },
    warning: {
        float: 'left',
        marginRight: '10px',
        width: '20px',
        height: '20px',
        fill: colors.userHot
    },
    data: {
        padding: '20px',
        width: '85%',
    },
    link: {
        position: 'relative',
        margin: '20px 0 0 80px',
    },
    linkArrow: {
        position: 'absolute',
        top: '0',
        left: '-70px',
        fontSize: '30px',
        color: colors.userCold,
    },
    matchedContainer: {

    },
    matchedIcon: {
        width: '15px',
        height: '15px',
        marginRight: '10px',
        verticalAlign: 'middle',
    },
    separator: {
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid ' + colors.grey200,
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.reech.details';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        entries: PropTypes.array.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                { this.props.entries.map(this.renderEntry.bind(this)) }
            </div>
        );
    }

    renderEntry (entry: Object, index: number) {
        const style = index > 0
            ? styles.entry
            : Object.assign({}, styles.entry, { borderTopWidth: '0' });

        return (
            <div
                key={ `e${index}` }
                style={ style }
            >
                <div style={ styles.type }>
                    { entry.kind === 'warning' ? <Icon type="dna.reech.warning" style={ styles.warning } /> : null }
                    { entry.label || entry.kind }

                    <div style={ styles.relevance }>
                        { Math.round(entry.relevance) } %
                    </div>
                </div>
                <div style={ styles.data }>
                    <Views.Common.Properties
                        align={ 100 }
                        values={ this.computeProperties(entry.type, entry.profile, entry.extra) }
                    />

                    {this.renderLinks(t('common.label.companies'), entry.profile.companies)}
                    {this.renderLinks('Persons', entry.profile.persons)}
                </div>
            </div>
        );
    }

    computeProperties (type: string, profile: Object, extra: Object[]) {
        const properties = [];

        if (type === 'person') {
            properties.push({ label: 'First name', value: profile.firstName });
            properties.push({ label: 'Last name', value: profile.lastName });
            properties.push({ label: 'Birth date', value: profile.birthDate });
        }

        if (type === 'company') {
            properties.push({ label: 'Name', value: profile.name });
            properties.push({ label: 'Identifier', value: (profile.identifiers || []).join(', ') });
            properties.push({ label: 'Country', value: profile.country });
        }

        if (profile.aum) {
            properties.push({ label: 'AUM', value: profile.aum });
        }

        if (profile.segment) {
            properties.push({ label: 'Segment', value: profile.segment });
        }

        for (const property of extra || []) {
            properties.push(property);
        }

        return properties;
    }

    renderLinks(label: string, names: ?string[]) {
        if (!names || !names.length) {
            return null;
        }

        const values = [{ label, value: names[0] }];

        for (const name of names.slice(1)) {
            values.push({ label: ' ', value: name });
        }

        return (
            <Views.Common.Properties style={styles.separator} align={ 100 } values={values} />
        );
    }
}
