// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Views from '../../views';

@inject('deefind')
@observer
export default class DeefindEntityProvider extends React.Component<any, any> {
    static displayName = 'app.layout.providers.deefind.entity';

    static contextTypes = {
        updateState: PropTypes.func.isRequired
    };

    static propTypes = {
        deefind: PropTypes.object,
        id: PropTypes.string,
        ids: PropTypes.arrayOf(PropTypes.string),
        type: PropTypes.oneOf(['list', 'display', 'entity', 'network', 'stocks', 'deals', 'media', 'family', 'dealsCompany', 'accounting']).isRequired,
        depth: PropTypes.number,
        direction: PropTypes.string,
        mini: PropTypes.bool,
        renderLoader: PropTypes.func,
        renderEntity: PropTypes.func,
        renderError: PropTypes.func,
        renderList: PropTypes.func,
        state: PropTypes.any,
        style: PropTypes.object
    };

    render () {
        if (this.props.type === 'list') {
            const list = this.props.deefind.provideList(this.props.ids);
            return this.renderList(list, this.props.state);
        }

        if (this.props.type === 'display') {
            this.props.deefind.provideDisplay(this.props.id);

            const entity = this.props.deefind.results.get(`entity:${this.props.id}`);
            const progress = this.props.deefind.progress.get(`entity:${this.props.id}`);

            return entity ? this.renderEntity(entity, this.props.state) : this.renderLoader(progress);
        }

        const [key, key2] = this.props.deefind.provideScope(this.props.type, this.props.id, this.props.depth, this.props.direction);
        const errors = this.props.deefind.errors.get(key);
        const result = this.props.deefind.results.get(key);
        const progress = this.props.deefind.progress.get(key2);

        if (errors) {
            return this.renderError(errors);
        }

        if (result && (!this.props.depth || !result.depth || result.depth >= this.props.depth)) {
            return this.renderEntity(result, this.props.state);
        }

        return this.renderLoader(progress);
    }

    renderError (error: any) {
        if (this.props.renderError) {
            return this.props.renderError(error);
        }

        return <pre>{JSON.stringify(error, null, 4)}</pre>;
    }

    renderLoader(progress: ?Object) {
        if (this.props.renderLoader) {
            return this.props.renderLoader(progress);
        }

        return (<Views.Common.Loader progress={progress} tiny={this.props.mini} />);
    }

    renderList(entities: Object[], state: any) {
        return this.props.renderList(entities, state);
    }

    renderEntity(entity: Object, state: any) {
        entity = toJS(entity);

        if (this.props.renderEntity) {
            return this.props.renderEntity(entity, state);
        }

        const children = React.Children.map(this.props.children, (child, index) =>
            child
                ? React.cloneElement(child, Object.assign({}, child.props, { entity, state }, { key: `c${index}` }))
                : null
        );

        if (children.length === 1) {
            return children[0];
        }

        return (<span style={ this.props.style }>{ children }</span>);
    }
}
