import { get } from 'lodash';

export default async function (data: Object, services: Object): Promise<Object> {
    const values = {};
    const header = get(data, 'study.payload.header', {});

    if (header.historicalMilestonesPicture) {
        values.historicalMilestonesPicture = await services.acquireFile(header.historicalMilestonesPicture);
    }

    if (header.shareholdingStructurePicture) {
        values.shareholdingStructurePicture = await services.acquireFile(header.shareholdingStructurePicture);
    }

    return values;
}
