import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import colors from 'app/theme/colors';

let styles = {
    container: {
        height: '40px',
        paddingRight: '10px',
        position: 'relative',
    },
    placeholder: {
        position: 'absolute',
        left: '12px',
        top: '2px',
        lineHeight: '36px',
        color: colors.grey400,
        fontSize: '15px',
    },
    input: {
        background: 'transparent',
        display: 'block',
        width: '100%',
        lineHeight: '36px',
        boxSizing: 'border-box',
        height: '40px',
        padding: '0 10px',
        border: '2px solid ' + colors.grey300,
        borderRadius: '3px',
        fontSize: '15px',
        outline: 'none',
    },
    error: {
        color: colors.userHot,
        fontWeight: 'bold',
    },
    choices: {
        position: 'absolute',
        left: '4px',
        top: '4px',
        height: '32px',
        display: 'flex',
    },
    choice: {
        lineHeight: '32px',
        textAlign: 'center',
        width: '48px',
        color: colors.userCold,
        borderRadius: '3px',
        fontSize: '13px',
        cursor: 'pointer',
    },
    choiceSoft: {
        background: colors.halfCold,
        color: 'white',
    },
    choiceHard: {
        background: colors.userCold,
        color: 'white',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.form.criteria.main';

    static propTypes = {
        state: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    state = {};

    constructor(props) {
        super(props);
        this.state = { focused: false, type: props.form ? props.form.type : undefined };
    }

    componentDidMount() {
        ReactDOM.findDOMNode(this.refs.input).focus();
    }

    handleTyping(event) {
        if (event.keyCode === 13) return this.props.onSubmit();
    }

    handleChange(event: any) {
        const value = event.target.value || undefined;
        const type = this.state.type || this.getDefaultType(value);

        this.props.onUpdate({
            'form.value': value,
            'form.type': type,
        });
    }

    handleFocus(event) {
        this.setState({ focused: true });
        event.target.style.borderColor = colors.userCold;
    }

    handleBlur(event) {
        this.setState({ focused: false });
        event.target.style.borderColor = colors.grey300;
    }

    render() {
        const form = this.props.state.form || {};

        return (
            <div style={styles.container}>
                {this.renderInput(form)}
                {this.renderChoices(form)}
                {this.renderPlaceholder()}
            </div>
        );
    }

    renderInput() {
        const form = this.props.state.form || {};
        const style = Object.assign({}, styles.input, {
            paddingLeft: form.kind === 'company' ? '116px' : '10px'
        });

        return (
            <input
                type="text"
                ref="input"
                style={style}
                value={form.value || ''}
                onKeyUp={this.handleTyping.bind(this)}
                onChange={this.handleChange.bind(this)}
                disabled={this.props.state.status === 'loading'}
                onFocus={this.handleFocus.bind(this)}
                onBlur={this.handleBlur.bind(this)}
            />
        );
    }

    renderChoices(form: Object) {
        if (form.kind !== 'company') {
            return null;
        }

        return (
            <div style={styles.choices}>
                {this.renderChoice(form, 'name')}
                {this.renderChoice(form, 'code')}
            </div>
        );
    }

    renderChoice(form: Object, key: string) {
        const fallback = this.getDefaultType(form.value);

        const style = Object.assign(
            {},
            styles.choice,
            this.state.type
                ? this.state.type === key
                    ? styles.choiceHard
                    : {}
                : fallback === key
                ? styles.choiceSoft
                : {},
        );

        const handle = () => {
            if (key === this.state.key) {
                this.setState({ type: undefined });
            } else {
                this.setState({ type: key });
            }
        };

        return (
            <div style={style} onClick={handle}>
                {key.toUpperCase()}
            </div>
        );
    }

    renderPlaceholder() {
        if (this.props.value) return null;

        let label =
            this.props.state.status === 'invalid' ? (
                <span style={styles.error}>This field is required!</span>
            ) : (
                this.props.label
            );

        return (
            <span style={styles.placeholder} onClick={event => this.refs.input.focus()}>
                {label}
            </span>
        );
    }

    getDefaultType(value: ?string) {
        return value && value.match(/\d\d\d/) ? 'code' : 'name';
    }
}
