// @flow

import React from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'app/layout/providers/data';
import colors from 'app/theme/colors';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

const styles = {
    container: {
        padding: '0 0 0 20px',
        height: '60px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        background: colors.grey200,
        marginBottom: '1px',
    },
    name: {
        flexGrow: '1',
        color: colors.userCold,
    },
    group: {
        display: 'flex',
    },
    radio: {
        width: '175px',
        opacity: '0.75',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.links.item';

    static propTypes = {
        id: PropTypes.string.isRequired,
    };

    render() {
        return (
            <DataProvider
                service="cleerance"
                model="team"
                id={this.props.id}
                scope="public"
                renderValue={value => {
                    return (
                        <div style={styles.container}>
                            <div style={styles.name}>
                                {value.profile.name}
                            </div>

                            <RadioButtonGroup style={styles.group} name={`link_${this.props.id}`} valueSelected="none">
                                <RadioButton
                                    value="none"
                                    label="No link"
                                    style={styles.radio}
                                />
                                <RadioButton
                                    value="observe"
                                    label="Observe"
                                    disabled={true}
                                    style={styles.radio}
                                />
                                <RadioButton
                                    value="invade"
                                    label="Invade"
                                    disabled={true}
                                    style={styles.radio}
                                />
                            </RadioButtonGroup>
                        </div>
                    );
                }}
            />
        );
    }
}
