// @flow

import slugify from 'speakingurl';
import match from 'js-levenshtein';

export function matchIdentifier(a?: string, b?: string): number {
    if (!a || !b || typeof a !== 'string' || typeof b !== 'string') {
        return 0;
    }

    a = slugify(a).replace(/-/g, '');
    b = slugify(b).replace(/-/g, '');

    if (!a || !b) {
        return 0;
    }

    if (a !== b) {
        return -100;
    }

    return 100;
}

export function matchWord(a?: string, b?: string): number {
    if (!a || !b || typeof a !== 'string' || typeof b !== 'string') {
        return 0;
    }

    a = slugify(a);
    b = slugify(b);

    if (a.length < 3 || b.length < 3) {
        return 0;
    }

    const distance = match(a, b);
    const threshold = Math.floor(Math.min(a.length, b.length) / 5);

    if (distance > threshold) {
        return -100;
    }

    if (distance > 0) {
        return 75;
    }

    return 100;
}

export function matchDate(a: ?string, b: ?string): number {
    if (!a || !b || typeof a !== 'string' || typeof b !== 'string') {
        return 0;
    }

    const regex = /^\d{4}(-\d{2}){0,2}/;

    a = slugify(a);
    b = slugify(b);

    if (!a.match(regex) || !b.match(regex)) {
        return 0;
    }

    if (a.substr(0, 4) !== b.substr(0, 4)) {
        return -100; // different years
    }

    // full dates given
    if (a.length >= 10 && b.length >= 10) {
        if (a.substr(0, 7) !== b.substr(0, 7)) {
            return -100; // same year but different months
        }

        return a.substr(0, 10) === b.substr(0, 10)
            ? 100 // same date
            : -50; // same year and month but different days
    }

    // only months given
    if (a.length >= 7 && b.length >= 7) {
        if (a.substr(0, 7) !== a.substr(0, 7)) {
            return -1; // same years but different month
        }

        return 0; // same month
    }

    return 0; // same year
}
