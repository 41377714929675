import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Base from './base';

const styles = {
    content: {},
    svg: {
        margin: '10px 25%',
        width: '50%',
    },
    dot: {
        fill: colors.userHot,
    },
    label: {
        fill: colors.userCold,
        textAnchor: 'middle',
        baselineShift: '-0.5ex',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '14px',
    },
    value: {
        fill: colors.userHot,
        fontWeight: 'bold',
        textAnchor: 'middle',
        baselineShift: '-0.5ex',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '14px',
    },
    scale: {
        strokeWidth: '0.5',
        stroke: 'rgba(0, 0, 0, 0.25)',
    },
    polygon: {
        strokeWidth: '1',
        stroke: colors.userHot,
        fill: 'rgba(229, 57, 53, 0.05)',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.scoring';

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.object,
    };

    render() {
        return <Base title={this.props.title}>{this.renderContent()}</Base>;
    }

    renderContent() {
        if (!this.props.data) {
            return null;
        }

        const min = 0;
        const max = 5;
        const step = 1;
        const size = max - min;
        const items = this.props.data.data || [];
        const count = items.length;

        if (count < 3) {
            return null;
        }

        const lines: string[] = [];
        const labels: string[] = [];
        const points: string[] = [];
        const dots: string[] = [];
        const values: string[] = [];

        for (let index = 0; index < count; index++) {
            const { value, label } = items[index];
            const radius = 100 * (min + (items[index].value - min) / size);
            const [px, py] = this.makePoint(index / count, radius);
            const [vx, vy] = this.makePoint(index / count, radius + 10);
            const [lx, ly] = this.makePoint(index / count, 125);

            points.push(`${px} ${py}`);
            dots.push(<circle style={styles.dot} key={'dot_' + index} cx={px} cy={py} r="2" />);

            labels.push(
                <text style={styles.label} key={'label_' + index} x={lx} y={ly} width="50" height="5">
                    {label}
                </text>,
            );

            values.push(
                <text style={styles.value} key={'value_' + index} x={vx} y={vy} width="10" height="5">
                    {value}
                </text>,
            );

            for (let level = min; level <= max; level += step) {
                const radius = 100 * (min + (level - min) / size);
                const [x1, y1] = this.makePoint(index / count, radius);
                const [x2, y2] = this.makePoint((index + 1) / count, radius);
                lines.push(
                    <line style={styles.scale} key={'scale_' + index + '_' + level} x1={x1} y1={y1} x2={x2} y2={y2} />,
                );
            }
        }

        const polygon = <polygon style={styles.polygon} key="polygon" points={points.join(' ')} />;
        const content = [...lines, ...labels, polygon, ...dots, ...values];

        return (
            <div style={styles.content}>
                <svg style={styles.svg} viewBox="-150 -150 300 300">
                    {content}
                </svg>
            </div>
        );
    }

    makePoint(alpha: number, radius: number): [number, number] {
        const angle = 2 * Math.PI * alpha;
        return [-Math.sin(angle) * radius, Math.cos(angle) * radius];
    }
}
