import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Header from './header';
import Filter from './filter';
import Deal from './deal';
import t from 'app/translation';

const styles = {
    empty: {
        textAlign: 'center',
        color: colors.grey500,
        padding: '40px 0',
    },
    separator: {
        margin: '10px 0',
        borderTop: '1px solid ' + colors.grey300,
        height: '0',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.deals.tab.deals';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        title: PropTypes.string.isRequired,
        empty: PropTypes.string.isRequired,
        attachments: PropTypes.array.isRequired,
        details: PropTypes.object.isRequired,
        state: PropTypes.object,
        style: PropTypes.object,
        noStatus: PropTypes.bool,
        onOpen: PropTypes.func.isRequired,
        rumour: PropTypes.bool,
    };

    render() {
        if (!this.props.attachments.length) {
            return <div style={Object.assign({}, this.props.style, styles.empty)}>{this.props.empty}</div>;
        }

        return (
            <div style={this.props.style}>
                <Header title={this.props.title}>
                    <Filter
                        value={this.props.state ? this.props.state.status : undefined}
                        onChange={status => this.context.updateState({ status })}
                        choices={{
                            completed: t('dna.deals.completed'),
                            withdrawn: t('dna.deals.withdrawn'),
                            pending: t('dna.deals.pending'),
                        }}
                    />
                </Header>

                {this.renderDeals()}
            </div>
        );
    }

    renderDeals() {
        const status = this.props.state ? this.props.state.status : undefined;

        let items = _.orderBy(
            this.props.attachments
                .map(attachment =>
                    Object.assign({}, attachment, {
                        data: this.props.details[attachment.deal],
                    }),
                )
                .filter(item => !!item.data),
            item => item.data.dates.completion || item.data.dates.announce || item.data.dates.rumour || 0,
            'desc',
        );

        if(!this.props.rumour){
            items = items.filter(item => !['rumour', 'rumourexpired'].includes(item.data.status.kind));
        }

        if (status) {
            items = _.filter(items, item => item.data.status.kind === status);
        }

        let year,
            result = [],
            index = 0;

        _.each(items, item => {
            if (!item.data) {
                return;
            }

            let dealYear = '';
            if((item.data.dates.completion || item.data.dates.announce || item.data.dates.rumour)){
                dealYear = (item.data.dates.completion || item.data.dates.announce || item.data.dates.rumour).substr(
                    0,
                    4,
                );
            }

            if (!year) year = dealYear;

            if (year !== dealYear) {
                result.push(<div key={'sep' + year} style={styles.separator} />);
                year = dealYear;
            }

            result.push(<Deal key={index++} deal={item.data} relevance={item.relevance} onOpen={event => this.props.onOpen(item.data)} />);
        });

        return result;
    }
}
