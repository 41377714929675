// @flow

import type { ResolverInterface, ResolverSpecs } from '../../types';
import type StateStore from '../../index';
import type StatePath from '../../path';
import type StateNode from '../../node';

import difference from 'lodash/difference';

export default class ObjectResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;
    children: { [key: string]: ResolverSpecs };

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
        this.children = {};

        for (const key of Object.keys(this.specs.children || {})) {
            let child = this.specs.children[key];

            if (typeof child === 'string') {
                child = { type: child };
            }

            if (typeof child !== 'object' || ! child.type) {
                throw new Error(`Invalid specs passed to "${this.path.toString()}" scope resolver.`);
            }

            this.children[key] = child;
        }
    }

    register (store: StateStore) {
        for (const key of Object.keys(this.children)) {
            store.define(this.path.concat(key), this.children[key]);
        }
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        const result = {};

        if (value === undefined) {
            return this.specs.fallback || {};
        }

        if (typeof value !== 'object') {
            console.error(`Found invalid "object" value at "${this.path.toString()}": ${JSON.stringify(value)}`);

            return this.specs.fallback || {};
        }

        for (const key of Object.keys(this.children)) {
            result[key] = node.get(key, this.children[key].type).normalize(value[key], external);
        }

        if (this.specs.prototype) {
            let prototype = this.specs.prototype;

            if (typeof prototype === 'string') {
                prototype = { type: prototype };
            }

            if (typeof prototype !== 'object' || ! prototype.type) {
                throw new Error(`Invalid prototype specs passed to "${this.path.toString()}" scope resolver.`);
            }

            for (const key of difference(Object.keys(value), Object.keys(result))) {
                result[key] = node.forge(key, prototype).normalize(value[key], external);
            }
        }

        return result;
    }
}
