// @flow

import type { KernelUser } from '@deecision/infra-types/server';

export function allowAdmin(user: KernelUser | void): boolean {
    if (!user) return false;

    for (const account of user.accounts) {
        for (const team of Object.keys(account.memberships)) {
            const flags = account.memberships[team].flags || [];
            if (flags.includes('cleerance.admin')) return true;
        }
    }

    return false;
}
