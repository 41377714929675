// @flow

import type { ResolverInterface, ResolverSpecs } from '../../types';
import type StatePath from '../../path';
import type StateNode from '../../node';

export default class NumberResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        if (value === undefined) {
            return this.specs.fallback;
        }

        if (typeof value !== 'number') {
            if (process.env.DEBUG) {
                console.warn(`Found invalid "number" value at "${this.path.toString()}": ${JSON.stringify(value)}`);
            }

            return this.specs.fallback;
        }

        if (Array.isArray(this.specs.values)) {
            if(this.specs.values.includes(value)) {
                return value;
            }

            console.warn(
                `Found invalid "number" value "${value}" at "${this.path.toString()}", ` +
                `allowed values are: ${this.specs.values.join(', ')}.`
            );

            return this.specs.fallback;
        }

        if (typeof this.specs.min === 'number' && value < this.specs.min) {
            console.warn(
                `Found invalid "number" value "${value}" at "${this.path.toString()}", ` +
                `must be ${this.specs.min} at min.`
            );

            return this.specs.min;
        }

        if (typeof this.specs.max === 'number' && value > this.specs.max) {
            console.warn(
                `Found invalid "number" value "${value}" at "${this.path.toString()}", ` +
                `must be ${this.specs.max} at max.`
            );

            return this.specs.max;
        }

        return value;
    }
}
