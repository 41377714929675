import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const names = {
    reference: 'Reference code',
    kind: 'Kind code',
    firstName: 'First name',
    lastName: 'Last name',
    birthDate: 'Birth date',
    country: 'Country code',
    aum: 'A.U.M.',
    segment: 'Segment code',
    committee: 'Committee code',
    name: 'Name',
    identifier: 'Identifier',
    person: 'Person ref.',
    company: 'Company ref.',
};

const formats = {
    birthDate: 'YYYY-MM-DD',
    aum: 'Number',
    kind: '"C" or "P" or nothing',
    country: '2 letters ISO country code',
    segment: '"H" or "U" or "V" or "R"',
    committee: '"R" or "W" or "P" or "C"',
    person: 'Reference code',
    company: 'Reference code',
    identifier: 'Text (national identifier)',
};

const styles = {
    container: {
        background: colors.grey200,
        borderRadius: '3px',
        flex: '1 1 0',
    },
    title: {
        marginBottom: '5px',
        color: colors.grey600,
    },
    table: {
        display: 'grid',
        gridTemplateColumns: '40px 1fr 2fr',
        gridGap: '1px',
        background: colors.grey300,
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
    },
    head: {
        color: colors.grey600,
        padding: '5px',
    },
    cell: {
        padding: '5px',
        background: colors.grey100,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.settings.reech.columns';

    static propTypes = {
        list: PropTypes.array.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.title}>Expected file columns</div>

                <div style={styles.table}>
                    <div style={styles.head}>#</div>
                    <div style={styles.head}>Name</div>
                    <div style={styles.head}>Format</div>
                    {this.props.list.map(row => this.renderRow(row))}
                </div>
            </div>
        );
    }

    renderRow(row: Object) {
        let name = names[row.type] || row.type;
        let format = formats[row.type] || 'Text';

        if (row.type === 'extra') {
            name = row.label ? `Extra: ${row.label}` : '-';
            format = row.label ? 'Text' : '-';
        }

        return [
            <div key="index" style={styles.cell}>{row.index + 1}</div>,
            <div key="name" style={styles.cell}>{name}</div>,
            <div key="format" style={styles.cell}>{format}</div>,
        ];
    }
}
