// @flow

import type { AuthSettings } from '@deecision/infra-types/common';
import type { KernelConfig } from '@deecision/infra-types/server';

const casa = ['credit-agricole-sa.deecision.com', 'preprod-credit-agricole-sa.deecision.com'];

export default function(settings: AuthSettings, config: KernelConfig): AuthSettings {
    if (casa.includes(config.domain) || config.domain === 'localhost') {
        settings.flags.push('deefind.norkom');
    }

    return settings;
}
