import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Row from './303-row';
import _ from 'lodash';

const styles = {
    list: {
        marginBottom: '20px',
    },
    message: {
        background: colors.grey200,
        padding: '40px',
        textAlign: 'center',
        fontSize: '30px',
        color: colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.result.typology.303';

    static propTypes = {
        items: PropTypes.array,
        state: PropTypes.object,
    };

    render() {
        if (!this.props.items) {
            return null;
        }

        if (!this.props.items.length) {
            return <div>No result</div>;
        }
        return (
            <Views.List.Container state={this.props.state} entities={this.props.items} style={styles.list}>
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        location: {
                            label: 'Date',
                            path: 'file.date',
                            type: 'asc',
                        },
                        name: {
                            label: 'Name',
                            path: 'target.name',
                            type: 'asc',
                        },
                    }}
                />

                <Views.List.Reducer type="fuse" specs={{ paths: ['file.ref','target.name'] }} />

                <Views.List.Frame
                    type="table"
                    specs={{ labels: ['Reference', 'Type','Name', 'Status'] }}
                    element={<Row />}
                />
            </Views.List.Container>
        );
    }
}
