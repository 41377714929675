import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Moment from 'moment';
import get from 'lodash/get';
import Rating from '../../studies/common/rating';
import RatingEsg from '../../studies/common/ratingEsg';
import next from '@deecision/deeligenz-utils/compute/next';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

const styles = {
    container: {
        padding: '20px',
        height: '200px',
    },
    header: {
        marginBottom: '15px',
    },
    preview: {
        marginRight: '74px',
    },
    opener: {
        position: 'absolute',
        right: '0',
        bottom: '0',
    },
    opener2: {

    },
    body: {
        display: 'flex',
        marginTop: '5px',
        color: colors.grey800,
    },
    aum: {
        lineHeight: '18px',
        marginLeft: '15px',
        color: colors.grey800,
    },
    icon: {
        fill: colors.userCold,
        marginRight: '5px',
        width: '18px',
        height: '18px',
    },
    owner: {
        lineHeight: '18px',
    },
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
        background: 'white',
    },
    hover: {
        background: colors.grey200,
    }
};

@inject(stores => ({ contacts: stores.auth.contacts, store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.summary.row';

    static propTypes = {
        entity: PropTypes.object,
        contacts: PropTypes.object,
    };

    state = { hover: false };

    render() {
        if (!this.props.entity) {
            return null;
        }

        const payload = this.props.entity.payload || {};
        const type = payload.target.type || 'manager';
        const contacts = this.props.contacts;
        const owner = contacts && contacts.users ? contacts.users[this.props.entity.ownerUid] || {} : {};
        const baseEsg = String.fromCharCode(64 + get(this.props.entity, 'computed.scoringEsg.rating', 5));
        const realEsg = get(this.props.entity.payload, 'header.esg', this.props.entity.payload.archive  ? 'N/A' : baseEsg).toUpperCase();

        const baseRating = get(this.props.entity, 'computed.scoring.rating', 5);
        const realRating = Math.max(0, Math.min(5, Math.round(this.props.entity.payload.rating || baseRating)));
        const aum = get(this.props.entity, 'payload.expectedAum') || (Number(get(this.props.entity, 'payload.archive.expectedAUM').replace(/ | /g, '')) / 1000000);
        const analysisDate = Moment(get(this.props.entity, 'payload.archive.validationDate') || get(this.props.entity, 'computed.deadline'));
        const nextReview = next(analysisDate, get(this.props.entity, 'payload.target.variant').toLowerCase().replace(/ | /g, ''), realRating, aum);

        return (<tr>
            {this.renderCell((
                <span>{type === 'fund' ? payload.fund : payload.manager}</span>
            ))}

            {this.renderCell((
                <Icon type={type === 'fund' ? 'deeligenz.fund' : 'deeligenz.company'} />
            ))}

            {this.renderCell(
                <div style={{display:'inline-block'}}>
                    <Rating value={realRating} />
                </div>
            , {textAlign: 'center'})}


            {this.renderCell(
                <div style={{display:'inline-block'}}>
                <RatingEsg value={realEsg} /></div>
            , {textAlign: 'center'})}

            {this.renderCell(this.renderOwner(owner, payload))}

            {this.renderCell(analysisDate.format('ll'))}

            {this.renderCell(nextReview ? nextReview.date === 'not defined' ? nextReview.date : Moment(nextReview.date).format('ll') : '-')}

            {this.renderCell((
                <div style={{display:'inline-block'}}>
                <Views.Common.Button
                    key="summary"
                    onClick={async () => {
                        if (this.props.entity.status === 'archive' && get(this.props.entity, 'payload.archive.reports.summary.id') && get(this.props.entity, 'payload.archive.reports.summary.name')) {
                            this.props.store.downloadFile(get(this.props.entity, 'payload.archive.reports.summary.id'), get(this.props.entity, 'payload.archive.reports.summary.name'))
                        } else {
                            const filename = 'summary-report-' + (type === 'fund' ? payload.fund : payload.manager) + '.pdf';
                            this.props.store.downloadSummaryReport(this.props.entity.id, filename);
                            //this.handleDownload('summary-report-' + (type === 'fund' ? payload.fund : payload.manager), result);
                        }
                    }}
                    icon={<Icon style={{ width: '30px', height: '30px' }} type="do.pdf" />}
                />
                </div>
            ), {textAlign: 'center'})}

            {this.renderCell((
                <div style={{display:'inline-block'}}>
                { ( (this.props.entity.status === 'archive' && get(this.props.entity, 'payload.archive.reports.full.id'))
                    || (this.props.entity.type === 'odd') ) &&
                <Views.Common.Button
                    key="full"
                    onClick={() => {
                        if (this.props.entity.status === 'archive' && get(this.props.entity, 'payload.archive.reports.full.id') && get(this.props.entity, 'payload.archive.reports.full.name')) {
                            this.props.store.downloadFile(get(this.props.entity, 'payload.archive.reports.full.id'), get(this.props.entity, 'payload.archive.reports.full.name'))
                        } else {
                            const filename = 'full-report-' + (type === 'fund' ? payload.fund : payload.manager) + '.pdf';
                            this.props.store.downloadFullReport(this.props.entity.id, filename);
                            // .then(result => this.handleDownload('full-report-' + (type === 'fund' ? payload.fund : payload.manager), result));
                        }
                    }}
                    icon={<Icon style={{ width: '30px', height: '30px' }} type="do.pdf" />}
                    style={{ marginLeft: '10px' }}
                /> }
                </div>
            ), {textAlign: 'center'})}
        </tr>);
    }

    renderCell(value, style) {
        return (
            <td onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} style={Object.assign({}, style, styles.cell, this.state.hover ? styles.hover : {})}>
                {value}
            </td>
        );
    }

    renderStatus() {
        const payload = toJS(this.props.entity.payload) || {};

        switch (this.props.entity.status) {
            case 'draft':
            case 'input':
            case 'onSite':
            case 'report':
            case 'rejected':
            case 'vetoed':
                return (this.props.entity.status);

            case 'ddq':
            case 'analysis':
                return 'Analysis';

            case 'approved':
                return ('Awaiting Second Validation');

            case 'confirmed':
                return (this.props.entity.fields || []).filter(id => get(this.props.store.fields.get(id), 'answerPayload.flags.redFlag')).length ? (
                    'Awaiting Director Validation'
                ) : (
                    'Validated'
                );

            case 'ignoredRedFlag':
                return (
                    'Validated'
                );
        }

        return 'Unknown';
    }

    renderOwner(owner, payload) {
        return (
            <span style={styles.owner}>{owner.firstName + ' ' + owner.lastName}</span>
        );
    }

    handleDownload(filename: string, data: string) {
        const element = document.createElement('a');
        const body: any = document.body;
        body.appendChild(element);
        element.setAttribute('href', data);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', `${filename}.pdf`);
        element.style.display = '';
        element.click();
        body.removeChild(element);
    }
}
