// @flow

import type {
    AuthAccount,
    AuthCursor,
    AuthIntervention,
    AuthMembership,
    AuthSession,
    AuthSettings,
} from '@deecision/infra-types/common';

export default function (session: AuthSession, cursor: AuthCursor): AuthSettings {
    if (session.level === 'anonymous') {
        return { flags: [] };
    }

    if (cursor.account && cursor.team) {
        const filter = (account: AuthAccount) => account.id === cursor.account;
        const account: ?AuthAccount = session.user.accounts.filter(filter)[0];
        const membership: ?AuthMembership = account && cursor.team ? account.memberships[cursor.team] : undefined;

        return membership ? { urls: membership.urls, flags: membership.flags } : { flags: [] };
    }

    if (cursor.intervention && cursor.team) {
        const filter = (intervention: AuthIntervention) => intervention.id === cursor.intervention;
        const intervention: ?AuthIntervention = session.user.interventions.filter(filter)[0];
        const membership: ?AuthMembership = intervention && cursor.team ? intervention.memberships[cursor.team] : undefined;

        return membership ? { urls: membership.urls, flags: membership.flags } : { flags: [] };
    }

    return { flags: [] };
}
