import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';

const FLAG_NAMES = {
    gdpr: 'GDPR',
    key: 'Key',
};

const SCOPE_NAMES = {
    input: 'Input',
    analysis: 'Analysis',
    both: 'Both',
};

const AUTO_FLAG_NAMES = {
    '': '—',
    allRight: 'All right',
    concern: 'Concern',
    redFlag: 'Red flag',
};

const TYPE_NAMES = {
    file: 'Document — Single',
    'file[]': 'Document — List',
    string: 'Single — String',
    text: 'Single — Text',
    number: 'Single — Number',
    country: 'Single — Country',
    date: 'Single — Date',
    money: 'Single — Money',
    object: 'Single — Compound',
    location: 'Single — Location',
    'string[]': 'List — String',
    'number[]': 'List — Number',
    'country[]': 'List — Country',
    'date[]': 'List — Date',
    'location[]': 'List — Location',
    boolean: 'Scored — Yes - No',
    select: 'Scored — Select',
    persons: 'Table — Persons',
    companies: 'Table — Companies',
    table: 'Table — Custom',
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.questions.question.form';

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    state = { data: {} };

    open(data) {
        this.setState({ data }, () => this.refs.dialog.open(data));
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                data={this.state.data}
                label="Save question"
                onSubmit={this.props.onSubmit}
                onCancel={this.props.onCancel}
                onChange={data => {
                    this.setState({ data });
                    return data;
                }}
            >
                <Views.Form.Field
                    label="Label"
                    path="questionPayload.label"
                    widget={{ type: 'text', rows: 4 }}
                    required
                />

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Scope"
                        path="questionPayload.scope"
                        size={2}
                        widget={{ type: 'radios', choices: {onSite: 'onSite'} }}
                        required
                    />
                    <Views.Form.Field
                        label="Flags"
                        path="questionPayload.flags"
                        size={2}
                        widget={{ type: 'bools', choices: FLAG_NAMES }}
                    />
                </Views.Form.Row>

                <Views.Form.Row title="Field type">
                    <Views.Form.Field
                        label="Type"
                        path="questionPayload.type"
                        widget={{ type: 'select', choices: TYPE_NAMES }}
                        required
                        size={2}
                    />

                    {this.renderOptions()}
                </Views.Form.Row>

                {this.renderOption3()}
            </Views.Modal.Edition>
        );
    }

    renderOptions() {
        const type = this.state.data.payload ? this.state.data.questionPayload.type : null;

        switch (type) {
            case 'number':
                return [
                    <Views.Form.Field
                        key="min-value"
                        size={2}
                        label="Min value"
                        path="questionPayload.options.min"
                        widget={{ type: 'number' }}
                    />,
                    <Views.Form.Field
                        key="max-value"
                        size={2}
                        label="Max value"
                        path="questionPayload.options.max"
                        widget={{ type: 'number' }}
                    />,
                ];

            case 'boolean':
                return [
                    <Views.Form.Field
                        key="yes-score"
                        label="Yes score"
                        path="questionPayload.options.scores.yes"
                        widget={{ type: 'number' }}
                    />,
                    <Views.Form.Field
                        key="yes-flag"
                        label="Yes flag"
                        path="questionPayload.options.flags.yes"
                        widget={{ type: 'select', choices: AUTO_FLAG_NAMES }}
                    />,
                    <Views.Form.Field
                        key="no-score"
                        label="No score"
                        path="questionPayload.options.scores.no"
                        widget={{ type: 'number' }}
                    />,
                    <Views.Form.Field
                        key="no-flag"
                        label="No flag"
                        path="questionPayload.options.flags.no"
                        widget={{ type: 'select', choices: AUTO_FLAG_NAMES }}
                    />,
                ];

            case 'select':
                return [
                    <Views.Form.Field
                        key="multiple"
                        size={2}
                        label="Multiple choices"
                        path="questionPayload.options.multiple"
                        widget={{ type: 'boolean', title: 'Multiple' }}
                    />,
                    <Views.Form.Field
                        key="other"
                        size={2}
                        label={'With "other" choice'}
                        path="questionPayload.options.other"
                        widget={{ type: 'boolean', title: 'With "other"' }}
                    />,
                ];

            default:
                return <Views.Form.Cell size={4} />;
        }
    }

    renderOption3() {
        const type = this.state.data.payload ? this.state.data.questionPayload.type : null;

        if (type === 'select') {
            return (
                <Views.Form.Field
                    label="Fields list"
                    path="questionPayload.options.choices"
                    widget={{
                        type: 'collection',
                        uuid: true,
                        sort: true,
                        fields: {
                            label: 'string',
                            score: {
                                type: 'integer',
                                label: 'Score',
                            },
                            flag: {
                                type: 'select',
                                label: 'Flag',
                                options: AUTO_FLAG_NAMES,
                            },
                        },
                    }}
                />
            );
        }

        if (type === 'object') {
            return (
                <Views.Form.Field
                    label="Choices list"
                    path="questionPayload.options.fields"
                    widget={{
                        type: 'collection',
                        uuid: true,
                        sort: true,
                        fields: {
                            label: { type: 'string', label: 'Label' },
                            type: {
                                type: 'select',
                                label: 'Type',
                                options: {
                                    string: 'String',
                                    text: 'Text',
                                    number: 'Number',
                                    country: 'Country',
                                    date: 'Date',
                                    location: 'Location',
                                },
                            },
                            flag: {
                                type: 'select',
                                label: 'Auto flag',
                                options: {
                                    '': '—',
                                    allRight: 'All right',
                                    concern: 'Concern',
                                    redFlag: 'Red flag',
                                },
                            },
                            score: { type: 'integer', label: 'Score' },
                        },
                    }}
                />
            );
        }

        if (type === 'table') {
            return (
                <Views.Form.Field
                    label="Columns list"
                    path="questionPayload.options.columns"
                    widget={{
                        type: 'collection',
                        uuid: true,
                        sort: true,
                        fields: {
                            label: { type: 'string', label: 'Label' },
                            type: {
                                type: 'select',
                                label: 'Type',
                                options: {
                                    string: 'String',
                                    number: 'Number',
                                    date: 'Date',
                                },
                            },
                        },
                    }}
                />
            );
        }

        if (['persons', 'companies'].includes(type)) {
            return (
                <Views.Form.Field
                    label="Extra columns"
                    path="questionPayload.options.columns"
                    widget={{
                        type: 'collection',
                        uuid: true,
                        sort: true,
                        fields: {
                            label: { type: 'string', label: 'Label' },
                            type: {
                                type: 'select',
                                label: 'Type',
                                options: {
                                    string: 'String',
                                    number: 'Number',
                                    date: 'Date',
                                },
                            },
                        },
                    }}
                />
            );
        }

        return null;
    }
}
