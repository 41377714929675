import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Preview from '../common/preview';
import Flag from './flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.woco.preview';

    static propTypes = {
        data: PropTypes.object.isRequired,
        relevance: PropTypes.number.isRequired,
        onOpen: PropTypes.func.isRequired,
    };

    render () {
        return (
            <Preview
                flag={ <Flag data={ this.props.data } /> }
                title={ this.props.data.subheading }
                relevance={ this.props.relevance }
                onOpen={ this.props.onOpen }
                value={ this.props.data.heading }
            />
        );
    }
}
