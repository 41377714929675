import PropTypes from 'prop-types';
import React from 'react';

const PATTERNS = {
    uuid: /[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/i,
    email: /^[0-9a-z._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$/i,
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'string';

    static propTypes = {
        value: PropTypes.string,
        style: PropTypes.object,
        length: PropTypes.number,
        pattern: PropTypes.oneOf(['email', 'uuid']),
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        length: 255,
    };

    render() {
        if (this.props.readOnly) {
            return (
                <input
                    readOnly
                    type="text"
                    maxLength={this.props.length}
                    value={this.props.value || ''}
                    style={this.props.style}
                    onChange={() => {}}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                />
            );
        }

        return (
            <input
                autoComplete="nope"
                type="text"
                maxLength={this.props.length}
                value={this.props.value || ''}
                style={this.props.style}
                onChange={event => {
                    const value = event.target.value || undefined;
                    const pattern = this.props.pattern ? PATTERNS[this.props.pattern] : undefined;
                    this.props.onError(value && pattern && !pattern.test(value) ? 'Invalid' : undefined);
                    this.props.onChange(value);
                }}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
            />
        );
    }
}
