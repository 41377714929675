// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Splash from './splash';
import Upload from './upload';
import Work from '../work';
import Augmented from '../augmented';
import Button from 'app/views/common/button';
import Icon from 'app/theme/icon';
import { augmentedSegment as computeAugmented, covid19 as computeCovid19, coopt as computeCoopt, segment as computeSegment, family as computeFamily, predict as computePredict, upsell as computeUpsell } from '../compute';

const styles = {
    button: {
        display: 'block',
        margin: '10px auto',
        width: '250px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.base.batch';

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    static contextTypes = {
        setStage: PropTypes.func.isRequired,
        pushJobs: PropTypes.func.isRequired,
    };

    render() {
        switch (this.props.state.step) {
            case 'index':
                return this.renderIndex();

            case 'upload':
                return <Upload state={this.props.state} />;

            case 'work':
                switch (this.props.state.tool) {
                    case 'covid19':
                        return (
                            <Work
                                style={this.props.style}
                                state={this.props.state}
                                filters={['risk', 'potential', 'completeness', 'potentialCovid19']}
                                compute={computeCovid19}
                            />
                        );
                    case 'segment':
                        return (
                            <Work
                                style={this.props.style}
                                state={this.props.state}
                                filters={['risk', 'potential', 'completeness', 'accuracy']}
                                compute={computeSegment}
                            />
                        );

                    case 'coopt':
                        return (
                            <Work
                                style={this.props.style}
                                state={this.props.state}
                                filters={['risk', 'proximity', 'potential', 'completeness', 'accuracy']}
                                compute={computeCoopt}
                            />
                        );
                    case 'family':
                        return (
                            <Work
                                style={this.props.style}
                                state={this.props.state}
                                filters={['risk', 'proximity', 'potential', 'completeness', 'accuracy']}
                                compute={computeFamily}
                            />
                        );
                    case 'predict':
                        return (
                            <Work
                                style={this.props.style}
                                state={this.props.state}
                                axes={{ x: 'probability', y: 'potentialCashout', extra: 'risk' }}
                                filters={['years', 'predictionType', 'potentialCashout', 'probability', 'expectedValue']}
                                compute={computePredict}
                            />
                        );
                    case 'upsell':
                        return (
                            <Work
                                style={this.props.style}
                                state={this.props.state}
                                axes={{ x: 'risk' , y: 'upsell' ,  extra: 'risk'}}
                                filters={['risk', 'completeness', 'upsell', 'upsellPrct']}
                                compute={computeUpsell}
                            />
                        );
                    case 'augmented':
                        return (
                            <Augmented
                                style={this.props.style}
                                state={this.props.state}
                                filters={['potential']}
                                compute={computeAugmented}
                            />
                        );
                }

            default:
                return this.renderIndex();
        }
    }

    renderIndex() {
        return (
            <Splash title="New Deetect batch" notice="Please select the tool you want to use">
                {this.renderButton('Segmentation augmentée', 'augmented', true, true)}
                {this.renderButton('Evénement : COVID-19', 'covid19', true, true)}
                {this.renderButton('Segmentation', 'segment', true, true)}
                {this.renderButton('Upsell', 'upsell', true, true)}
                {this.renderButton('Cooptation', 'coopt', true, true)}
                {this.renderButton('Family group', 'family', false, true)}
                {this.renderButton('Prediction', 'predict', true, true)}
                <Button
                    type="flat"
                    color="cold"
                    label="Add/edit tools"
                    icon={<Icon type="do.configure" />}
                    onClick={() => {}}
                    style={styles.button}
                />
            </Splash>
        );
    }

    renderButton(label: string, tool?: string, withCompanies?: boolean, withPersons?: boolean) {
        return (
            <Button
                type="raised"
                color="cold"
                label={label}
                disabled={!tool}
                onClick={tool ? event => this.context.setStage({ step: 'upload', tool, companies: withCompanies, persons: withPersons }) : undefined}
                style={styles.button}
            />
        );
    }
}
