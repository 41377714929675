// @flow

import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';

import { observable } from 'mobx';
import { makeUrl } from '@deecision/infra-utils/locate';

export default class AuthStore {
    @observable contacts = { users: {}, teams: {} };
    @observable teamMates = [];
    @observable interventions = {};
    @observable members = {};

    query: ?string = null;

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        this.contacts = { users: {}, teams: {} };
        this.teamMates = [];
        this.interventions = {};
        this.members = {};

        const query = ['token=' + session.token];
        if (cursor.account) query.push('account=' + cursor.account);
        if (cursor.intervention) query.push('intervention=' + cursor.intervention);
        if (cursor.team) query.push('team=' + cursor.team);
        this.query = query.join('&');

        const myTeams = cursor.team ? [cursor.team] : [];

        const [teamsMap, interventionsMap] = await Promise.all([
            this.fetchModel('team'),
            this.fetchModel('intervention'),
        ]);

        await Promise.all(
            Object.keys(teamsMap).map(async id => {
                const team = await this.fetchModel(`team/${id}`);
                const data = team.public[1];
                this.contacts.teams[id] = Object.assign({}, data.profile, { id, members: data.members });
            }),
        );

        const promises = [];

        for (const team of Object.keys(this.contacts.teams)) {
            this.members[team] = [];

            for (const id of Object.keys(this.contacts.teams[team].members)) {
                promises.push(new Promise(async (resolve: Function) => {
                    const account = await this.fetchModel(`account/${id}`);
                    const data = account.public[1];
                    const memberships = data.memberships || {};
                    this.contacts.users[id] = Object.assign({}, data.profile, { id, email: data.email });

                    if (myTeams.includes(team)) {
                        this.teamMates.push({ team, user: this.contacts.users[id] });
                    }

                    const entry = Object.assign({}, this.contacts.users[id], { role: memberships[team].level, id });
                    this.members[team].push(entry);
                }));
            }
        }

        for (const id of Object.keys(interventionsMap)) {
            promises.push(new Promise(async (resolve: Function) => {
                const intervention = await this.fetchModel(`intervention/${id}`);
                const value = Object.assign({}, intervention.public[1].profile, { id });
                if (!this.contacts.users[id]) this.contacts.users[id] = value;
                this.interventions[id] = value;
            }));
        }

        await Promise.all(promises);
    }

    async fetchModel(url: string): Promise<any> {
        const domain = process.env.APP_DOMAIN || window.location.hostname;
        const response = await fetch(
            `${makeUrl(domain, 'cleerance', 'api')}/api/cleerance/models/${url}?${this.query || ''}`,
            { method: 'GET' },
        );

        try {
            return await response.json();
        } catch (error) {
            console.error(error);
            return {};
        }
    }
}
