import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Budget from '../../common/prospects/budget';

import sum from 'lodash/sum';
import { inject, observer } from 'mobx-react';
import { computeEventCost, computeProspectValues, computeStatusLabel } from '../../common/compute';
import t from 'app/translation';

const styles = {
    header: {
        fontSize: '25px',
        marginBottom: '10px'
    },
    fortune: {
        color: colors.grey500,
        fontSize: '15px',
        marginLeft: '15px',
    },
    budget: {
        display: 'block',
        marginTop: '17px',
        fontSize: '17px'
    },
    properties: {
        marginTop: '10px'
    },
    opener: {
        position: 'absolute',
        right: '10px',
        bottom: '10px'
    },
    status: {
        color: colors.userCold,
        lineHeight: '40px',
    },
};

@inject(stores => ({ store: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.prospects.summary.card';

    static contextTypes = {
        openProspect: PropTypes.func.isRequired
    };

    props: {
        entity: Object,
    };

    render () {
        if (! this.props.entity)
            return null;

        const values = computeProspectValues(this.props.entity.payload);

        const entity = this.props.entity;
        const target = this.props.entity.payload.target || {};
        const period = this.props.entity.payload.settings.period || {};

        const events = this.props.entity.events || [];
        const milestones = this.props.entity.milestones || [];
        const cost = sum(events.map(computeEventCost));

        return (
            <Views.Common.Card>
                <Views.Common.Header
                    light
                    icon={ <Icon type="comeet.prospect" /> }
                    style={{ marginBottom: '15px' }}
                    title={
                        <div>
                            <span style={ styles.name }>
                                { target.name || '< no name defined>' }
                            </span>

                            <Views.Common.Number
                                style={ styles.fortune }
                                scale="K"
                                type="currency"
                                value={ target.fortune || 0 }
                            />
                        </div>
                    }
                    aside={[ <span style={ styles.status }>{ computeStatusLabel(this.props.entity.status) }</span> ]}
                />

                <Views.Timeline.Preview
                    start={ entity.createStamp }
                    end={ Moment(entity.createStamp).add(period, 'month') }
                >
                    { events.map(this.renderEvent.bind(this)) }
                    { milestones.map(this.renderMilestone.bind(this)) }
                </Views.Timeline.Preview>

                <Budget
                    style={ styles.budget }
                    cost={ cost }
                    entity={ this.props.entity }
                />

                <Views.Common.Properties
                    style={ styles.properties }
                    align={ 70 }
                    values={[{
                        label: t('dna.comeet.aum'),
                        value: (
                            <Views.Common.Number
                                scale="K"
                                type="currency"
                                value={ values.deposit }
                            />
                        )
                    }, {
                        label: t('dna.comeet.prospect.revenue'),
                        value: (
                            <Views.Common.Number
                                scale="K"
                                type="currency"
                                value={ values.profit }
                            />
                        )
                    }]}
                />

                <Views.Common.Button
                    type="round"
                    style={ styles.opener }
                    icon={ <Icon type="do.open" /> }
                    onClick={ event => this.context.openProspect(this.props.entity.id) }
                />
            </Views.Common.Card>
        );
    }

    renderEvent (entity) {
        const event = this.props.store.fields.get(entity);

        return (
            <Views.Timeline.Event
                key={ event.id }
                id={ event.id }
                date={ event.payload.date }
                dimmed={ Moment(event.payload.date) < Moment().add(1, 'day') }
            />
        );
    }

    renderMilestone (entity) {
      const milestone = this.props.store.fields.get(entity);

        return (
            <Views.Timeline.Event
                key={ milestone.id }
                id={ milestone.id }
                date={ milestone.payload.date }
                dimmed={ Moment(milestone.payload.date) < Moment().add(1, 'day') }
            />
        );
    }
}
