import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Progress from './progress';
import Status from '../../studies/common/status';

import { computeInputDaysLeft, computeInputWorkPercent, computeInputTimePercent } from '../utils';

import { inject } from 'mobx-react';
import get from 'lodash/get';

const styles = {
    draft: {
        lineHeight: '58px',
        textAlign: 'center',
        color: colors.grey400,
        letterSpacing: '3px',
    },
    inputInfo: {
        paddingTop: '10px',
        color: colors.grey600,
        textAlign: 'center',
    },
    inputProgress: {},
    inputTime: {
        color: colors.userGreen,
    },
    inputOver: {
        color: colors.userHot,
    },
    inputWork: {
        color: colors.userCold,
    },
    inputSeparator: {
        color: colors.grey400,
        margin: '0 15px',
        display: 'inline-block',
    },
    message: {
        marginLeft: '8px',
    },
    messageTitle: {
        display: 'inline-block',
        marginLeft: '13px',
        lineHeight: '24px',
        fontWeight: 'bold',
    },
    messageBody: {
        margin: '3px 0 0 47px',
        color: colors.grey600,
        lineHeight: '15px',
        fontSize: '13px',
        height: '15px',
    },
    messageIcon: {
        float: 'left',
        marginRight: '10px',
    },
    messageDate: {
        display: 'inline-block',
        fontWeight: 'normal',
        color: colors.grey600,
        marginLeft: '5px',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.archives.summary.preview';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render() {
        const payload = this.props.entity.payload || {};

        switch (this.props.entity.status) {
            case 'draft':
                return <div style={Object.assign({}, styles.draft, this.props.style || {})}>DRAFT</div>;

            case 'input':
            case 'ddq':
            case 'onSite':
            case 'report':
                return (
                    <div style={this.props.style}>
                        <Progress entity={this.props.entity} />

                        <Status entity={this.props.entity} />
                    </div>
                );

            case 'analysis':
                return <div style={Object.assign({}, styles.draft, this.props.style || {})}>ANALYSIS</div>;

            case 'approved':
                return (
                    <div style={Object.assign({}, styles.message, this.props.style || {})}>
                        <Icon type="deeligenz.approved" style={styles.messageIcon} color={colors.halfGreen} />

                        <div style={Object.assign({}, styles.messageTitle, { color: colors.halfGreen })}>
                            Awaiting Second Validation
                            <span style={styles.messageDate}>{Moment(get(payload, 'compute.dates.approved')).format('LL')}</span>
                        </div>

                        <div style={styles.messageBody}>{payload.comment}</div>
                    </div>
                );

            case 'confirmed':
                return (this.props.entity.fields || []).filter(id => get(this.props.store.fields.get(id), 'answerPayload.flags.redFlag')).length ? (
                    <div style={Object.assign({}, styles.message, this.props.style || {})}>
                        <Icon type="deeligenz.approved" style={styles.messageIcon} color={colors.halfHot} />

                        <div style={Object.assign({}, styles.messageTitle, { color: colors.halfHot })}>
                            Awaiting Director Validation
                            <span style={styles.messageDate}>{Moment(get(payload, 'compute.dates.confirmed')).format('LL')}</span>
                        </div>

                        <div style={styles.messageBody}>{payload.comment}</div>
                    </div>
                ) : (
                    <div style={Object.assign({}, styles.message, this.props.style || {})}>
                        <Icon type="deeligenz.approved" style={styles.messageIcon} color={colors.userGreen} />

                        <div style={Object.assign({}, styles.messageTitle, { color: colors.userGreen })}>
                            Validated
                            <span style={styles.messageDate}>{Moment(payload.update).format('LL')}</span>
                        </div>

                        <div style={styles.messageBody}>{payload.comment}</div>
                    </div>
                );

            case 'ignoredRedFlag':
                return (
                    <div style={Object.assign({}, styles.message, this.props.style || {})}>
                        <Icon type="deeligenz.approved" style={styles.messageIcon} color={colors.userGreen} />

                        <div style={Object.assign({}, styles.messageTitle, { color: colors.userGreen })}>
                            Validated
                            <span style={styles.messageDate}>{Moment(payload.update).format('LL')}</span>
                        </div>

                        <div style={styles.messageBody}>{payload.comment}</div>
                    </div>
                );

            case 'rejected':
                return (
                    <div style={Object.assign({}, styles.message, this.props.style || {})}>
                        <Icon type="deeligenz.rejected" style={styles.messageIcon} color={colors.userHot} />

                        <div style={Object.assign({}, styles.messageTitle, { color: colors.userHot })}>
                            Rejected
                            <span style={styles.messageDate}>{Moment(payload.update).format('LL')}</span>
                        </div>

                        <div style={styles.messageBody}>{payload.comment}</div>
                    </div>
                );

            case 'vetoed':
                return (
                    <div style={Object.assign({}, styles.message, this.props.style || {})}>
                        <Icon type="deeligenz.vetoed" style={styles.messageIcon} color={colors.userHot} />

                        <div style={Object.assign({}, styles.messageTitle, { color: colors.userHot })}>
                            Vetoed
                            <span style={styles.messageDate}>{Moment(payload.update).format('LL')}</span>
                        </div>

                        <div style={styles.messageBody}>{payload.comment}</div>
                    </div>
                );
        }

        return <div />;
    }
}
