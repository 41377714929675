// @flow

import get from 'lodash/get';

export function makePersonAge(date: string): string {
    const year = date.substr(0, 4);
    if (year.length < 4) return '';
    return String(new Date().getFullYear() - parseInt(year));
}

export function makePersonF500(files: Object[]): string {
    const fortune = files.filter(file => file && file.type === 'fortune');
    return fortune.length ? String(fortune[0].rank || 'fortune') : '';
}

export function countRelations(list: Object[], ceased: boolean, flag?: string): string {
    let count = 0;

    for (const relation of list || []) {
        if ((relation.ceased || false) !== ceased) {
            continue;
        }

        const flags = relation.target.flags || [];

        if (flag && !flags.includes(flag)) {
            continue;
        }

        count += 1;
    }

    return count ? String(count) : '';
}

export function makeValuationType(valuation: Object): string {
    if (valuation.market) return 'market';
    if (valuation.vadis) return 'vadis';
    if (valuation.deemaze) return 'deemaze';
    if (valuation.stocks) return 'stocks';
    if (valuation.capital) return 'capital';
    return '';
}

export async function makeReechResult(reech: Object, query: Object): Object {
    const results = await reech.matchEntity(query);
    if (!results.length) return { type: '', relevance: '' };
    return { type: results[0].kind, relevance: String(results[0].relevance) + ' %' };
}

export async function countReechRelations(reech: Object, list: Object[], flag?: string): Promise<string> {
    let count = 0;

    for (const relation of list || []) {
        if (relation.ceased) {
            continue;
        }

        const flags = relation.target.flags || [];

        if (flag && !flags.includes(flag)) {
            continue;
        }

        const results = await reech.matchEntity({
            id: relation.target.id,
            type: 'person',
            preview: relation.target,
        });

        if (results.length) {
            count += 1;
        }
    }

    return count ? String(count) : '';
}

export function countMandates(companies: Object[], ceased: boolean, types?: string[]): string {
    let count = 0;

    for (const relation of companies || []) {
        if (relation.circle > 0) {
            continue;
        }

        for (const path of relation.paths || []) {
            if (!path[0] || ceased !== (path[0].ceased) || !path[0].forward) {
                continue;
            }

            if (types && !types.includes(path[0].forward)) {
                continue;
            }

            count += 1;
        }
    }

    return count ? String(count) : '';
}

export function guessDeals(companies: Object[]): Object {
    const result = { count: 0, value: 0, date: 0 };

    for (const relation of companies || []) {
        if (relation.circle > 0 || !relation.ceased || !['i', 's'].includes(relation.level)) {
            continue;
        }

        result.count += 1;
        result.value += get(relation, 'target.valuation.value', 0);

        if (relation.end > 0) {
            result.date = result.date > 0 ? Math.max(result.date, relation.end) : relation.end;
        }
    }

    return result;
}
