// @flow

import type { LinkProfile } from '@deecision/deefind-types/model';

import orderBy from 'lodash/orderBy';
import * as valuate from './valuate';
import { makeRanks, mergeRanks } from './base';

export default function makeLinkRank(links: LinkProfile[]): LinkProfile[] {
    const companies: LinkProfile[] = [];
    const persons: LinkProfile[] = [];
    const invalids: LinkProfile[] = [];

    for (const link of links) {
        if (!link.target || !link.target.id || link.ceased) {
            invalids.push(link);
            continue;
        }

        if (link.target.type === 'company') {
            companies.push(link);
            continue;
        }

        if (link.target.type === 'person') {
            persons.push(link);
            continue;
        }

        invalids.push(link);
    }

    return rankProfiles(companies, valuate.company)
        .concat(rankProfiles(persons, valuate.person))
        .concat(invalids.map(link => Object.assign({}, link, { ranks: 0 })));
}

function rankProfiles(links: LinkProfile[], valuations: Function[]): LinkProfile[] {
    const ids: string[] = links.map(link => link.target.id);
    const max = links.length + 1;

    const ranks = mergeRanks(ids, valuations.map((valuate: Function) => makeRanks(links, valuate)));

    for (const link of links) {
        const id = link.target.id;
        link.rank = ranks.hasOwnProperty(id) ? ranks[id] : max;
    }

    return orderBy(links, 'rank', 'desc');
}
