import PropTypes from 'prop-types';
import React from 'react';
import ItemNorkom from './itemNorkom';
import Score from './score';
import t from 'app/translation';
import colors from 'app/theme/colors';
import Views from 'app/views';
import { inject } from 'mobx-react';

const styles = {
    container: {
        marginTop: '30px',
        width: '310px',
        background: colors.grey100,
        padding: '10px 20px 20px 20px',
        borderRadius: '3px',
        flexGrow: '1',
    },
    header: {
        marginBottom: '20px',
    },
    title: {
        fontSize: '21px',
        marginBottom: '5px',
    },
    test: {
        backgroundColor: 'red',
    },
};

@inject('global')
export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.vigilance.block';

    static propTypes = {
        global: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        data: PropTypes.object,
        progress: PropTypes.object,
        style: PropTypes.object,
    };

    render() {
        if (!this.props.global.flags.get().includes('deefind.norkom')) {
            return null;
        }

        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <div style={styles.title}>
                    {this.props.title} ({this.props.data ? this.props.data.score : 0})
                </div>
                <Score score={this.props.data ? Math.ceil(this.props.data.score / 500) : 0} />
                <br />
                <br />
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        if (this.props.data) {
            return this.renderItems();
        }

        if (this.props.progress) {
            return <Views.Common.Loader progress={this.props.progress} />;
        }

        return null;
    }

    renderItems() {
        const data = this.props.data || {};
        const items = [];

        const scoring = { HI: 4, MH: 3, ML: 2, LO: 1 };

        let idx = 0;
        for (const value of data.values) {
            items.push(
                <ItemNorkom
                    style={Object.assign({}, styles.test, this.props.style)}
                    key={idx++}
                    label={value.label}
                    score={scoring[value.lvl]}
                    data={{
                        score: value.score,
                        answer: value.answer,
                    }}
                />,
            );
        }

        for (const missed of data.missed) {
            items.push(<ItemNorkom key={idx++} label={missed} score={0} data={{undefined}} />);
        }

        return items;
    }
}
