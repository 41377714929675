import { get } from 'lodash';

export default async function (data: Object, services: Object, reportType: string): Promise<Object> {
  const scopes = (reportType == 'full') ? ['both', 'input', 'ddq'] : ['both', 'input']; // ddq is visble only in 'full' report but not in 'survey'

    const outData = [];
    const labels = [];
  
    function extract(e): ?[string, string] {
        if (get(e, 'answerPayload.na')) return ['N/A', 'N/A'];
        if (get(e, 'answerPayload.nd')) return ['N/D', 'N/D'];
  
        if (get(e, 'answerPayload.value') || get(e, 'answerEditPayload.value')) return [get(e, 'answerEditPayload.value.name') || get(e, 'answerPayload.value.name'), 'Yes'];
        if (get(e, 'answerPayload.values') || get(e, 'answerEditPayload.values')) return [(get(e, 'answerEditPayload.values') || get(e, 'answerPayload.values', [])).map(item => item.name).join(', '), 'Yes'];
  
        return ['', 'No']
    }
  
    (data.study.survey || [])
      .filter(e => ['file', 'file[]'].includes(get(e, 'questionPayload.type')))
      .filter(e => (scopes || ['both', 'input', 'ddq']).includes(get(e, 'questionPayload.scope')))
      .map(e => {
        outData.push(extract(e));
        labels.push(get(e, 'questionPayload.label'))
      });
  
    return {
        documents: {
            labels: labels,
            data: outData,
        }
    };
}
