// @flow

export default function makeEntityGics(naceCode: ?string): ?string {
    return naceCode ? map[naceCode.substr(0, 2)] : undefined;
}

const map = {
    '01': '302020',
    '02': '151050',
    '03': '302020',
    '05': '151040',
    '06': '101020',
    '07': '151040',
    '08': '151040',
    '09': '151040',
    '10': '302020',
    '11': '302010',
    '12': '302030',
    '13': '252030',
    '14': '252030',
    '15': '252030',
    '16': '151050',
    '17': '151050',
    '18': '254010',
    '19': '101020',
    '20': '151010',
    '21': '352020',
    '22': '251010',
    '23': '151040',
    '24': '151040',
    '25': '151040',
    '26': '452020',
    '27': '201040',
    '28': '201060',
    '29': '251020',
    '30': '251010',
    '31': '252010',
    // '32': 'XXXXXX',
    '33': '202010',
    '35': '551010',
    '36': '551040',
    '37': '551040',
    '38': '202010',
    '39': '202010',
    '41': '201030',
    '42': '201030',
    '43': '201030',
    '45': '255040',
    '46': '255010',
    '47': '255030',
    '49': '203040',
    '50': '551040',
    '51': '203020',
    '52': '203050',
    '53': '203010',
    '55': '253010',
    '56': '202010',
    '58': '254010',
    '59': '254010',
    '60': '254010',
    '61': '501010',
    '62': '202020',
    '63': '451020',
    '64': '402010',
    '65': '403010',
    '66': '402010',
    '68': '601020',
    '69': '202020',
    '70': '202020',
    '71': '202020',
    '72': '202020',
    '73': '202020',
    '74': '202020',
    '75': '202020',
    '77': '202020',
    '78': '202020',
    '79': '253010',
    '80': '202020',
    '81': '202020',
    '82': '202021',
    // '84': 'XXXXXX',
    '85': '253020',
    '86': '351020',
    '87': '351020',
    '88': '351020',
    '90': '253010',
    '91': '253010',
    '92': '253010',
    '93': '253010',
    // '94': 'XXXXXX',
    '95': '202021',
    // '96': 'XXXXXX',
    // '97': 'XXXXXX',
    // '98': 'XXXXXX',
    // '99': 'XXXXXX'
};
