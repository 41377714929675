import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';

import colors from 'app/theme/colors';
import Relation from './relations/relation';

let styles = {
    container: {
        position: 'absolute',
        bottom: '0',
        right: '70px',
        width: '100px',
        height: '68px',
        margin: '0',
        padding: '0',
        listStyleType: 'none'
    },
    message: {
        fontSize: '11px',
        fontWeight: 'bold',
        textAlign: 'right',
        color: colors.grey500,
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.card.properties';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                <Relation
                    type="companies"
                    count={ get(this.props.entity, 'preview.companies',[]).length }
                />
                <Relation
                    type="persons"
                    count={ get(this.props.entity, 'preview.persons', []).length }
                />
            </div>
        );
    }
}
