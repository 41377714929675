import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '20px',
        height: '20px',
        fill: colors.userCold,
    },
    title: {
        marginLeft: '35px',
        fontSize: '17px',
        marginBottom: '2px',
        lineHeight: '20px',
    },
    owner: {
        marginLeft: '35px',
        fontSize: '13px',
        color: colors.grey500,
        display: 'block',
    },
    date: {
        marginLeft: '35px',
        fontSize: '13px',
        color: colors.userCold,
        display: 'block',
    },
    description: {
        marginTop: '15px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.meetings.preview';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                <Icon type="comeet.meeting" style={styles.icon} />

                <div style={styles.title}>{this.props.entity.payload.title}</div>

                <Views.Common.Moment
                    date={this.props.entity.payload.date}
                    time={this.props.entity.payload.time}
                    style={styles.date}
                />

                <Views.Common.User id={this.props.entity.ownerUid} style={styles.owner} />
                <Views.Common.Description value={this.props.entity.payload.description} style={styles.description} />
            </div>
        );
    }
}
