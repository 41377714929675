import PropTypes from 'prop-types';
import React from 'react';

import Container from 'dna/entity/common/display/blocks';
import DealsBlock from 'dna/entity/common/deals/block';

import IdentityBlock from './blocks/identity';
import FinancialBlock from './blocks/financial';
import CompaniesBlock from './blocks/companies';
import RelationsBlock from './blocks/relations';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.person.display.blocks';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        children: PropTypes.object.isRequired,
    };

    render () {
        return (
            <Container tab={ this.props.state.tab || 0 }>
                <IdentityBlock entity={ this.props.entity } />
                <FinancialBlock entity={ this.props.entity } children={ this.props.children } />
                <CompaniesBlock entity={ this.props.entity } />
                <RelationsBlock entity={ this.props.entity } />
                <DealsBlock entity={ this.props.entity } />
            </Container>
        );
    }
}
