// flow

import type { AccountingScope } from '@deecision/deefind-types/model';


import { cleanObject } from './core';

export function cleanAccounting(value: any): AccountingScope | undefined {
    return cleanObject(value);
}

