// @flow

import type { GlobalCursor, GlobalEvent, SdkGlobal, SdkMake, SdkWorker } from '@deecision/infra-types/client';

export default function(worker: SdkWorker, make: SdkMake<*>): SdkGlobal {
    worker.listen((event: GlobalEvent) => {
        //console.log('global:', event);

        if (event.changes.includes('session')) {
            const session = event.session;

            if (session.level !== 'anonymous') {
                window.localStorage.setItem('last:token', session.token);
                window.localStorage.setItem('last:email', session.user.email);
            } else {
                window.localStorage.removeItem('last:token');
            }
        }

        if (event.changes.includes('cursor')) {
            const cursor = event.cursor;

            window.localStorage.setItem('last:team', cursor.team || '');
            window.localStorage.setItem('last:account', cursor.account || '');
            window.localStorage.setItem('last:intervention', cursor.intervention || '');
        }
    });

    return {
        connect(): void {
            const token = window.localStorage.getItem('last:token');
            const cursor = {
                team: window.localStorage.getItem('last:team'),
                account: window.localStorage.getItem('last:account'),
                intervention: window.localStorage.getItem('last:intervention'),
            };

            const dispose = worker.send({ type: 'connect', token, cursor }, () => {});
            dispose();
        },

        async select(cursor: $Shape<GlobalCursor>): Promise<void> {
            await make({ type: 'select', cursor });
        },

        listen(listener: (event: GlobalEvent) => any): Function {
            return worker.listen(listener);
        },
    };
}
