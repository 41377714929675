// @flow

import type { ValuationProfile } from '@deecision/deefind-types/utils';
import type { CompanyData, CompanyPreview } from '@deecision/deefind-types/model';
import { get } from 'lodash';

export default function makeValuationProfile(preview: CompanyPreview, data: CompanyData): ValuationProfile {
    return {
        name: preview.name || '',
        identifiers: preview.identifiers,
        country: preview.country || '',
        naceCode: preview.naceCode || '',
        turnovers: [(preview.turnovers || [])[0], (preview.turnovers || [])[1], (preview.turnovers || [])[2]],
        ebitdas: [(preview.ebitdas || [])[0], (preview.ebitdas || [])[1], (preview.ebitdas || [])[2]],
        minorityInterest: get(data, 'financial.minorityInterest'),
        preferredShares: get(data, 'financial.preferredShares'),
        loans: get(data, 'financial.loans'),
        longTermDebt: get(data, 'financial.longTermDebt'),
        shortTermDebt: get(data, 'financial.shortTermDebt'),
        cash: get(data, 'financial.cash'), // Cash & Cash Equivalent
        netProfit: get(data, 'financial.netProfit'),
    };
}
