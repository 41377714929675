export default {
    admin: {
        label: 'Admin',
        levels: {
            director: 'Director',
            manager: 'Manager',
            member: 'Member',
            observer: 'Observer',
        },
        teams: {
            label: 'Teams',
            list: {
                title: 'Teams list',
                create: 'New team',
            },
            menu: {
                settings: 'Settings',
                members: 'Members',
                links: 'Links',
            },
            profile: {
                title: 'Profile',
                noName: '< No name given >',
                noDescription: '< No description given >',
            },
        },
        services: {
            status: {
                disabled: 'No access',
                cloud: 'Cloud service',
                private: 'Private service',
            },
            cleerance: {
                title: 'Cleerance',
                admin: 'Admin',
            },
            deeligenz: {
                title: 'Deeligenz',
                analysis: 'Analysis',
                calendar: 'Calendar',
                library: 'Library',
            },
            deefind: {
                title: 'Deefind',
                search: 'Search',
            },
            deetect: {
                title: 'Deetect',
            },
            reech: {
                title: 'Reech',
            },
            deel: {
                title: 'Deel',
            },
            teem: {
                title: 'Teem',
            },
        },
    },
};
