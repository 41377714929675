import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';
import orderBy from 'lodash/orderBy';
import colors from 'app/theme/colors';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import Details from './reech/details';
import t from 'app/translation';

@inject(stores => ({
    reech: stores.reech,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.reech';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        reech: PropTypes.object,
        style: PropTypes.object,
        flagMode: PropTypes.bool,
    };

    componentDidMount() {
        this.props.reech.matchEntity(this.props.entity);
    }

    render() {
        const status = this.props.reech.statuses.get(this.props.entity.id);

        switch (status) {
            case 'customer':
            case 'prospect':
            case 'known':
                const entries = this.props.reech.results.get(this.props.entity.id);
                const severe = entries.filter(entry => entry.kind.warning).length > 0;

                return (
                    <span style={ this.props.style }>
                        <Views.Common.Button
                            icon={<Icon type="dna.reech.flag"/>}
                            label={`${entries.length} found`}
                            color={severe ? 'hot' : 'cold'}
                            type={this.props.flagMode ? 'round' : 'raised'}
                            mini={!!this.props.flagMode}
                            onClick={ event => this.refs.details.open() }
                        />

                        <Views.Modal.Show
                            ref="details"
                            title={ `REECH — ${this.props.entity.preview.name}` }
                            style={{ width: '800px' }}
                        >
                            <Details
                                entity={ this.props.entity }
                                entries={ entries }
                            />
                        </Views.Modal.Show>
                    </span>
                );

            case 'loading':
                return (
                    <Views.Common.Button
                        icon={<Icon type="dna.reech.flag"/>}
                        label="Loading"
                        type={this.props.flagMode ? 'round' : 'raised'}
                        mini={!!this.props.flagMode}
                        disabled
                        style={this.props.style}
                    />
                );

            default:
                return (
                    <Views.Common.Button
                        icon={<Icon type="dna.reech.flag"/>}
                        label={t('common.label.noMatch')}
                        type={this.props.flagMode ? 'round' : 'raised'}
                        mini={!!this.props.flagMode}
                        disabled
                        style={this.props.style}
                    />
                );
        }
    }
}
