import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Button from 'app/views/common/button';
import NextIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import t from 'app/translation';

let styles = {
    label: {
        display: 'block',
        marginBottom: '25px',
        color: colors.black,
        lineHeight: '40px',
        fontSize: '15px'
    },
    input: {
        float: 'right',
        border: '1px solid ' + colors.grey400,
        background: 'white',
        lineHeight: '38px',
        height: '38px',
        width: '240px',
        padding: '0 10px',
        fontSize: '15px',
        borderRadius: '3px'
    },
    focusedInput: {
        border: '1px solid ' + colors.userCold
    },
    invalidInput: {
        border: '1px solid ' + colors.userHot
    },
    button: {
        display: 'block',
        width: '120px',
        margin: '0 auto'
    },
    info: {
        padding: '0 0 5px 120px',
        color: colors.grey500,
        fontSize: '13px'
    },
    invalidInfo: {
        color: colors.userHot
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.layout.security.login.login';

    static propTypes = {
        lastEmail: PropTypes.string,
        style: PropTypes.object,
        onLogin: PropTypes.func.isRequired,
        //onNext: PropTypes.func.isRequired
    };

    constructor (props) {
        super(props);

        this.state = {
            focused: false,
            invalid: false,
            value: ''
        };
    }

    componentDidMount () {
        if (this.props.focus) {
            this.input.focus();
        }
    }

    isValid () {
        return this.state.value
            && this.state.value.length > 0;
    }

    async handleSubmit (event) {
        event.preventDefault();
        event.stopPropagation();

        try {
            await this.props.onLogin(this.props.lastEmail, this.state.value);
            // this.props.onNext();
        } catch(error) {
            console.error(error);
            this.setState({ invalid: true });
        }
    };

    render () {
        const inputStyle = Object.assign({},
            styles.input,
            this.state.focused ? styles.focusedInput : {},
            this.state.invalid ? styles.invalidInput : {}
        );

        return (
            <form
                style={ this.props.style }
                onSubmit={ this.handleSubmit.bind(this) }
            >
                { this.renderInfo() }

                <label style={ styles.label }>
                    {t('common.login.accessCode')}

                    <input
                        ref={ input => this.input = input }
                        type="password"
                        style={ inputStyle }
                        value={ this.state.value }
                        onBlur={ event => this.setState({ focused: false }) }
                        onFocus={ event => this.setState({ focused: true }) }
                        onChange={ event => this.setState({ invalid: false, value: event.target.value })}
                    />
                </label>

                <Button
                    type="raised"
                    color="cold"
                    label={ t('common.button.next')}
                    icon={ <NextIcon /> }
                    style={ styles.button }
                    onClick={ this.handleSubmit.bind(this) }
                    disabled={ ! this.isValid() }
                />
            </form>
        );
    }

    renderInfo () {
        if (this.state.invalid) {
            return (
                <div style={ Object.assign({}, styles.info, styles.invalidInfo) }>
                    Invalid code, please retry
                </div>
            );
        }

        return (
            <div style={ styles.info }>
                {t('common.login.infoAccessCode')}
            </div>
        );
    }
}
