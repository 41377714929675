// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Tooltip from './tooltip';
import isNumber from 'lodash/isNumber';
import Views from 'app/views';

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
    },
    title: {
        position: 'absolute',
        top: '-14px',
        left: '0',
        right: '0',
        color: colors.userCold,
        textAlign: 'center',
        fontSize: '11px',
        lineHeight: '11px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        borderBottom: '1px solid ' + colors.userCold,
    },
    tooltip: {
        flexGrow: '1',
    },

    link: {
        flexGrow: '1',
        position: 'relative',
        cursor: 'pointer',
        height: '30px',
        lineHeight: '30px',
        padding: '5px 10px',
        background: colors.grey200,
        display: 'flex',
        justifyContent: 'center',
    },
    hoverLink: {
        background: colors.grey300,
    },
    currentLink: {
        background: colors.userCold,
    },
    firstLink: {
        borderRadius: '3px 0 0 3px',
    },
    lastLink: {
        borderRadius: '0 3px 3px 0',
    },
    emptyLink: {
        background: colors.grey100,
    },

    label: {
        display: 'inline-block',
        height: '30px',
        color: colors.userCold,
        fill: colors.grey500,
        minWidth: '25px',
        textAlign: 'center',
    },
    currentLabel: {
        color: 'white',
        fill: 'white',
    },
    emptyLabel: {
        color: colors.grey400,
    },

    count: {
        display: 'inline-block',
        color: colors.grey500,
        marginLeft: '10px',
    },
    currentCount: {
        color: 'white',
    },
    emptyCount: {
        color: colors.grey400,
    },

    icon: {
        fill: colors.grey500,
        padding: '3px 0',
        marginRight: '5px',
    },
    currentIcon: {
        fill: 'white',
    },
    emptyIcon: {
        fill: colors.grey400,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.common.toggle';

    static propTypes = {
        title: PropTypes.node,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        empty: PropTypes.bool,
        tooltip: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                icon: PropTypes.string,
                label: PropTypes.node,
                help: PropTypes.node,
                count: PropTypes.node,
            }).isRequired,
        ),

        onChange: PropTypes.func,
        style: PropTypes.object,
    };

    state = {
        hover: undefined,
    };

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                <Views.Common.Tooltip content={this.props.tooltip}>
                    {this.renderTitle()}
                </Views.Common.Tooltip>
                {this.props.items.map(this.renderItem.bind(this))}
            </div>
        );
    }

    renderTitle() {
        if (!this.props.title) {
            return null;
        }

        return <div style={styles.title}>{this.props.title}</div>;
    }

    renderItem(item: any, index: any) {
        const current = item.value === this.props.value;

        let style = Object.assign(
            {},
            styles.link,
            current
                ? styles.currentLink
                : item.count === 0
                ? styles.emptyLink
                : item.value === this.state.hover
                ? styles.hoverLink
                : {},
        );

        if (index === 0) {
            style = Object.assign(style, styles.firstLink);
        }

        if (index === (this.props.items || []).length - 1) {
            style = Object.assign(style, styles.lastLink);
        }

        return (
            <Tooltip content={item.help} style={styles.tooltip} key={item.value}>
                <a
                    style={style}
                    onClick={event => this.props.onChange(current ? undefined : item.value)}
                    onMouseEnter={event => this.setState({ hover: item.value })}
                    onMouseLeave={event => this.setState({ hover: undefined })}
                >
                    {this.renderIcon(item, current)}
                    {this.renderLabel(item, current)}
                    {this.renderCount(item, current)}
                </a>
            </Tooltip>
        );
    }

    renderIcon(item: any, current: any) {
        if (!item.icon) return null;

        const style = Object.assign(
            {},
            styles.icon,
            current ? styles.currentIcon : item.count === 0 ? styles.emptyIcon : {},
        );

        return <Icon style={style} type={item.icon} />;
    }

    renderLabel(item: any, current: any) {
        if (!item.label) return null;

        const style = Object.assign(
            {},
            styles.label,
            current ? styles.currentLabel : item.count === 0 ? styles.emptyLabel : {},
        );

        return <span style={style}>{item.label}</span>;
    }

    renderCount(item: any, current: any) {
        if (!isNumber(item.count)) return null;

        const style = Object.assign(
            {},
            styles.count,
            current ? styles.currentCount : item.count === 0 ? styles.emptyCount : {},
        );

        return <span style={style}>{item.count}</span>;
    }
}
