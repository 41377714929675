// @flow

import type { ResolverInterface, ResolverSpecs, StateLocation } from '../../types';
import type StateStore from '../../index';
import type StatePath from '../../path';
import type StateNode from '../../node';

import ArrayResolver from '../base/array';

export default class MenuResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;
    children: { [key: string]: ResolverSpecs };
    itemsResolver: ArrayResolver;

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
        this.children = {};

        this.itemsResolver = new ArrayResolver(path.concat('items'), {
            type: 'array',
            pinned: this.specs.pinned,
            prototype: this.specs.prototype,
            max: this.specs.max,
        });

        for (const key of Object.keys(this.specs.children || {})) {
            let child = this.specs.children[key];

            if (typeof child === 'string') {
                child = { type: child };
            }

            if (typeof child !== 'object' || ! child.type) {
                throw new Error(`Invalid specs passed to "${this.path.toString()}" scope resolver.`);
            }

            this.children[key] = child;
        }
    }

    register (store: StateStore) {
        store.define(this.path.concat('items'), this.itemsResolver.specs);
        this.itemsResolver.register(store);

        store.define(this.path.concat('cursor'), {
            type: 'number',
            min: '0',
            fallback: 0
        });

        for (const key of Object.keys(this.children)) {
            store.define(this.path.concat(key), this.children[key]);
        }
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        if (! value || typeof value !== 'object') {
            value = {};
        }

        const items = node
            .get('items', 'array')
            .normalize(value.items, external);

        const cursor = node
            .get('cursor', 'number')
            .normalize(value.cursor, external);

        const children = {};

        for (const key of Object.keys(this.children)) {
            children[key] = node
                .get(key, this.children[key].type)
                .normalize(value[key], external);
        }

        return Object.assign(children, {
            cursor: Math.min(cursor, items.length - 1),
            items
        });
    }

    locate (node: StateNode, data: any, location: StateLocation): StateLocation {
        const value = this.resolveValue(node, data);

        if (location === ':current') {
            return `items.${value.cursor}`;
        }

        if (location === ':next') {
            return `items.${value.items.length}`;
        }

        if (location === 'cursor') {
            return 'cursor';
        }

        if (typeof location === 'number') {
            return `items.${location}`;
        }

        throw new Error(`Invalid menu scope location: ${JSON.stringify(location)}`);
    }

    remove (node: StateNode, data: any, location: StateLocation): any {
        const value = this.resolveValue(node, data);

        if (location === '>:cursor') {
            location = `>${value.cursor}`;
        }

        data = node.get('items', 'array').remove(data, location);

        const value2 = this.resolveValue(node, data);

        data = this.path.concat('cursor').write(data, value2.cursor);

        return data;
    }

    select (node: StateNode, data: any, location: StateLocation): any {
        const value = this.resolveValue(node, data);

        if (location === ':current') {
            location = value.cursor;
        }

        location = this.itemsResolver.locate(node.get('items', 'array'), data, location);

        if (typeof location !== 'number') {
            throw new Error(`Selected menu location must be a number, ${JSON.stringify(location)} given.`);
        }

        return this.path.concat('cursor').write(data, location);
    }

    resolveValue (node: StateNode, data: any): any {
        const items = node.get('items', 'array').normalize(node.path.concat('items').read(data));
        const cursor = node.get('cursor', 'number').normalize(node.path.concat('cursor').read(data));

        return { items, cursor: Math.max(0, Math.min(items.length - 1, cursor)) };
    }
}
