// flow

import type {
    PersonData,
    EntitySource,
    PersonEducation,
    PersonBiography,
    PersonIdentity,
    PersonPreview,
} from '@deecision/deefind-types/model';

import {
    cleanIdentifier,
    cleanName,
    cleanShape,
    cleanNumber,
    cleanArray,
    cleanDate,
    cleanString,
    cleanBoolean, cleanOr,
} from './core';

import { cleanSource, cleanAddress, cleanEstimate } from './base';

export function cleanPersonData(value: any, source?: EntitySource): PersonData | undefined {
    if (!value || typeof value !== 'object') {
        return undefined;
    }

    return {
        identity: cleanPersonIdentity(value.identity, source),
        addresses: cleanArray(value.addresses || [], [cleanAddress, source]),
        biographies: cleanPersonBiographies(value.biographies, source),
        educations: cleanPersonEducations(value.educations, source),
    };
}

export function cleanPersonPreview(value: any): PersonPreview | undefined {
    return cleanShape(value, {
        firstName: cleanName,
        lastName: cleanName,
        name: cleanName,
        birthDate: cleanDate,
        country: cleanIdentifier,
        homeCountry: cleanIdentifier,
        birthCountry: cleanIdentifier,
        nationality: cleanIdentifier,
        deceased: [cleanOr, [cleanDate, cleanBoolean]],
        gender: cleanPersonGender,
        weasse: cleanEstimate,
        weasseMax: cleanEstimate,
        persons: [cleanArray, cleanName],
        companies: [cleanArray, cleanName],
        fortune: cleanNumber,
        income: cleanNumber,
        topCompany: [cleanShape, { name: cleanName, id: cleanIdentifier }],
        vigilance: cleanNumber,
        flags: [cleanArray, cleanName],
        mandates: cleanNumber,
        turnoversSum: cleanEstimate,
        ebitdasSum: cleanEstimate,
        capitalsSum: cleanEstimate,
        valuationsSum: cleanEstimate,
    });
}

export function cleanPersonGender(gender: any): 'male' | 'female' | undefined {
    if ([1, 'm', 'male'].includes(gender)) {
        return 'male';
    }

    if ([2, 'f', 'female'].includes(gender)) {
        return 'female';
    }

    return undefined;
}

export function cleanPersonBiographies(value: any, source?: EntitySource): PersonBiography[] {
    return cleanArray(value, [
        cleanShape,
        {
            title: cleanName,
            content: cleanString,
            date: cleanDate,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanPersonEducations(value: any, source?: EntitySource): PersonEducation[] {
    return cleanArray(value, [
        cleanShape,
        {
            college: cleanName,
            country: cleanIdentifier,
            degree: cleanName,
            mayor: cleanBoolean,
            date: cleanDate,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanPersonIdentity(value: any, source?: EntitySource): PersonIdentity {
    return cleanShape(value, {
        bareTitle: cleanName,
        firstName: cleanName,
        lastName: cleanName,
        name: cleanName,
        middleName: cleanName,
        gender: cleanPersonGender,
        country: cleanIdentifier,
        homeCountry: cleanIdentifier,
        nationality: cleanIdentifier,
        birthDate: cleanDate,
        birthCountry: cleanIdentifier,
        birthCity: cleanName,
        deathStatus: cleanBoolean,
        deathDate: cleanDate,
        secondary: [cleanArray, cleanPersonIdentity],
        source: [cleanSource, source],
    });
}
