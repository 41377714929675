import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import uuid from 'uuid/v4';

const styles = {
    container: {},
    button: {
        marginRight: '15px',
    },
    name: {
        display: 'inline-block',
        margin: '5px 0',
        color: colors.grey500,
    },
    error: {
        color: colors.userHot,
        fontWeight: 'bold',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.scalar.file';

    static contextTypes = {
        getFileUrl: PropTypes.func.isRequired,
        downloadFile: PropTypes.func.isRequired,
    };

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        value: PropTypes.object,
        onChange: PropTypes.func.isRequired,
    };

    state = { };

    render() {
        return (
            <div style={styles.container}>
                {this.renderUpload()}
                {this.renderView()}
            </div>
        );
    }

    handleUpload(event) {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();

        reader.onload = async event => {
            const content = event.target.result;
            let value = null;
            if (file.type.match(/^image\//g)) {
                value = {
                    type: file.type,
                    name: file.name,
                    size: file.size,
                    content,
                };
            } else {
                const pdf = await PDFJS.getDocument({ data: new Uint8Array(content) }).promise;

                value = {
                    type: file.type,
                    name: file.name,
                    size: file.size,
                    pages: pdf.numPages,
                    content,
                    key: uuid(),
                };
            }
            this.setState({ content: content });
            this.props.onChange(value);
        };

        reader.readAsArrayBuffer(file);
    }

    async handleDownload(event) {
        await this.context.downloadFile(this.props.value.id, this.props.value.name);
    }

    async handleView(event) {
        if (!this.props.value.content) {
            this.state.url = await this.context.getFileUrl(this.props.value.id);
        }

        if (['pdf', 'application/pdf'].includes(this.props.value.type)) {
            this.refs['pdf_modal'].open(await PDFJS.getDocument(this.props.value.content ? { data: new Uint8Array(this.props.value.content) } : this.state.url).promise);
        } else {
            if (this.state.content) {
                const blob = new Blob([this.props.value.content], { type: 'application/octet-binary' });
                const reader = new FileReader();
                reader.onload = event => this.refs['img_modal'].open(event.target.result);
                reader.readAsDataURL(blob);
            } else if (this.state.url) {
                this.refs['img_modal'].open(this.state.url);
            }
        }
    }

    renderUpload() {
        if (this.props.readOnly) {
            return null;
        }

        const label =
            !this.props.value || (!this.props.value.name && !this.props.value.size)
                ? 'Upload'
                : this.props.value.uploadError
                ? 'Retry'
                : 'Replace';

        return (
            <span>
                <input
                    ref={element => (this.inputElement = element)}
                    type="file"
                    hidden
                    accept=".pdf,.jpg,.jpeg,.png,.gif"
                    onChange={this.handleUpload.bind(this)}
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label={label}
                    icon={<Icon type="do.upload" />}
                    style={styles.button}
                    onClick={event => this.inputElement.click()}
                />
            </span>
        );
    }

    renderView() {
        if (!this.props.value || (!this.props.value.name && !this.props.value.size)) {
            return null;
        }

        if (this.props.value && this.props.value.uploadError) {
            return <span style={styles.error}>Upload failed, please retry</span>;
        }

        return (
            <span>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Show"
                    icon={<Icon type="do.watch" />}
                    style={styles.button}
                    onClick={this.handleView.bind(this)}
                />
                { this.props.value.content ? null : <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Download"
                    icon={<Icon type="do.download" />}
                    style={styles.button}
                    onClick={this.handleDownload.bind(this)}
                />}
                <br />
                <span style={styles.name}>Name: {this.props.value.name}</span>
                <br />
                <span style={styles.name}>Type: {this.props.value.type}</span>

                <Views.Modal.Img onTop={true} ref="img_modal" />
                <Views.Modal.Pdf onTop={true} ref="pdf_modal" />
            </span>
        );
    }

    renderError() {
        if (this.props.value && this.props.value.uploadError) {
            return <div style={styles.error}>Upload failed, please retry</div>;
        }
    }
}
