// @flow

import type { AuthTeamProfile } from '@deecision/infra-types/common';

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import { inject } from 'mobx-react';

@inject('global')
export default class extends React.Component<any, any> {
    static displayName = 'cleerance.admin.teams.list.create';

    static propTypes = {
        global: PropTypes.object.isRequired,
    };

    render() {
        return [
            <Views.Common.Button
                key="button"
                type="round"
                icon={<Icon type="do.create" />}
                onClick={() => this.refs['dialog'].open()}
                mini
            />,

            <Views.Modal.Edition
                key="dialog"
                ref="dialog"
                label="Save team"
                style={{ width: '500px' }}
                onSubmit={async (profile: AuthTeamProfile) => {
                    this.props.global.execute('cleerance', 'team.create', { profile });
                }}
            >
                <Views.Form.Row>
                    <Views.Form.Field label="Name" path="name" widget="string" required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label="Description (optional)" path="description" widget="text" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        ];
    }
}
