// @flow

import type {
    DealAttachment,
    DealCollection,
    DealDetails,
    DealStakeholder,
} from '@deecision/deefind-types/model';
import utils from '../index';

export default function mergeDeals(collections: DealCollection[]): DealCollection {
    let details: { [id: string]: DealDetails } = {};
    let stakeholders: { [key: string]: DealStakeholder } = {};
    let asVendor: DealAttachment[] = [];
    let asTarget: DealAttachment[] = [];
    let asAcquiror: DealAttachment[] = [];
    let asRegulator: DealAttachment[] = [];

    for (const collection of collections) {
        details = Object.assign({}, details, collection.details);
        stakeholders = Object.assign({}, stakeholders, collection.stakeholders);
        asVendor = asVendor.concat(collection.asVendor);
        asTarget = asTarget.concat(collection.asTarget);
        asAcquiror = asAcquiror.concat(collection.asAcquiror);
        asRegulator = asVendor.concat(collection.asRegulator);
    }

    return {
        details,
        stakeholders,
        asVendor,
        asTarget,
        asAcquiror,
        asRegulator,
        sums: {
            sales: utils.makeDealsSum(details, asVendor.map(attachment => attachment.deal)),
            purchases: utils.makeDealsSum(details, asTarget.map(attachment => attachment.deal)),
            transactions: utils.makeDealsSum(details, asAcquiror.map(attachment => attachment.deal)),
            regulations: utils.makeDealsSum(details, asRegulator.map(attachment => attachment.deal)),
        },
    }
}
