// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Profile from './profile';
import Edit from './edit';

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.layout.application.header.account';

    static propTypes = {
        user: PropTypes.object.isRequired,
        onEdit: PropTypes.func.isRequired,
        onLogout: PropTypes.func.isRequired,
    };

    state = { edit: false };

    render() {
        if (this.state.edit) {
            return (
                <Edit
                    user={this.props.user}
                    onSave={(profile: Object) => this.props.onEdit(profile)}
                    onCancel={() => this.setState({ edit: false })}
                />
            );
        }

        return (
            <Profile
                user={this.props.user}
                onEdit={() => this.setState({ edit: true })}
                onLogout={() => this.props.onLogout()}
            />
        );
    }
}
