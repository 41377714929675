import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Moment from 'moment';

const styles = {
    error: {
        color: colors.userHot,
    },
    success: {
        color: colors.userGreen,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.status.onSite';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        withLocation: PropTypes.bool,
    };

    render() {
        const data = this.props.entity.payload.onSite || {};

        if (!data.proposedDates || !data.proposedDates.length) {
            return <span style={styles.error}>No dates proposed</span>;
        }

        if (!data.finalDate) {
            return <span style={styles.success}>Dates proposed</span>;
        }

        return <span style={styles.success}>Scheduled</span>;
    }
}
