import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Button from 'app/views/common/button';
import Scalar from './scalar';

const styles = {
    table: {
        border: '1px solid ' + colors.grey500,
        borderWidth: '0 0 0 1px',
        maxWidth: '950px',
    },
    row: {},
    cell: {
        border: '1px solid ' + colors.grey500,
        borderWidth: '0 1px 1px 0',
        background: 'white',
    },
    head: {
        border: '1px solid ' + colors.grey500,
        borderWidth: '1px 1px 1px 0',
        lineHeight: '40px',
        fontWeight: 'normal',
        color: colors.userCold,
        background: colors.grey200,
    },
    button: {
        borderWidth: '0',
    },
    widget: {
        width: 'auto',
        borderRadius: '0',
        borderWidth: '0',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.table';

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        answer: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    render() {
        const cols = this.props.columns || [];
        const rows = (this.props.answer.rows || []);

        if (!this.props.readOnly && !rows.length) {
            rows.push([]);
        }

        if (this.props.readOnly && !rows.length) {
            return null;
        }

        return (
            <div>
            <table style={Object.assign({}, styles.table, this.props.style || {})} cellSpacing={0} cellPadding={0}>
                <thead>
                    <tr>
                        {cols.map((col, x) => (
                            <th key={'x' + x} style={styles.head}>
                                {col.label}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, y) => (
                        <tr key={'y' + y}>
                            {cols.map((col, x) => (
                                <td key={'x' + x} style={styles.cell}>
                                    <Scalar
                                        type={col.type}
                                        options={col.options || {}}
                                        answer={{ value: (row || [])[x] }}
                                        readOnly={this.props.readOnly}
                                        style={Object.assign({}, styles.widget)}
                                        onChange={answer => {
                                            const row = rows[y] || [];
                                            row[x] = answer.value;
                                            rows[y] = row;

                                            this.props.onChange({
                                                rows: rows
                                            });
                                        }}
                                    />
                                </td>
                            ))}
                            {this.props.readOnly || row && row.filter(i => !!i).length === 0 ? null : (
                                <td style={styles.button}>
                                    <Button
                                        type="flat"
                                        icon={<Icon type="do.delete" />}
                                        color="hot"
                                        onClick={e => {
                                            delete rows[y];
                                            this.props.onChange({
                                                rows: [].concat(rows.slice(0, y)).concat(rows.slice(y+1))
                                            });
                                        }}
                                    />
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
            {this.props.readOnly ? null :
            <Button
                type="flat"
                icon={<Icon type="do.create" />}
                color="cold"
                onClick={e => {
                    this.props.onChange({
                        rows: rows.concat([undefined])
                    });
                }}
            />}
            </div>
            </div>
        );
    }
}
