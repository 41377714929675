import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import t from 'app/translation';

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.events.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.setState(this.props.entity);
    }

    open(extra) {
        this.refs.dialog.open(extra);
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                model="comeet:events"
                data={this.props.entity}
                label="Save event"
                onSubmit={this.props.onSubmit}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        size={2}
                        label={t('dna.comeet.title')}
                        path="payload.title"
                        widget="string"
                        required
                    />

                    <Views.Form.Field
                        size={1}
                        label={t('dna.comeet.date')}
                        path="payload.date"
                        widget="date"
                        required
                    />

                    <Views.Form.Field size={1} label={t('dna.comeet.time')} path="payload.time" widget="time" />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label="Description" path="payload.description" widget="text" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
