// @flow

import _ from 'lodash';

export default function() {
    return {
        equal(input: mixed, val: mixed, lvl: string): ?string {
            if (input === val) {
                return lvl;
            }
        },
        notEqual(input: mixed, val: mixed, lvl: string): ?string {
            if (input !== val) {
                return lvl;
            }
        },
        superior(input: number, val: number, lvl: string): ?string {
            if (input > val) {
                return lvl;
            }
        },
        superiorOrEqual(input: number, val: number, lvl: string): ?string {
            if (input >= val) {
                return lvl;
            }
        },
        inferior(input: number, val: number, lvl: string): ?string {
            if (input < val) {
                return lvl;
            }
        },
        inferiorOrEqual(input: number, val: number, lvl: string): ?string {
            if (input <= val) {
                return lvl;
            }
        },
        codeNaf(input: string[]): string {
            const riskScale = ['HI', 'MH', 'ML', 'LO'];
            let res = [];
            input.forEach(code => {res.push(_.indexOf(riskScale,riskByCodeNace[code]))});

            return riskScale[res.sort()[0]];
        },
        familyProduct(input: string) {
            return 'LO';
        },
        riskBycountries(countryCode: string): string {
            return riskBycountryCode[countryCode.toUpperCase()];
        },
        riskBycountriesBestOf(countryCodes: string[]): string {
            const riskScale = ['HI', 'MH', 'ML', 'LO'];
            let res = [];
            countryCodes.forEach(countryCode => {res.push(_.indexOf(riskScale,riskBycountryCode[countryCode.toUpperCase()]))});

            return riskScale[res.sort()[0]];
        },
    };
}

// evaluators

const riskBycountryCode = {
    AF: 'HI',
    AX: 'HI',
    AL: 'HI',
    DZ: 'MH',
    AS: 'HI',
    VI: 'HI',
    AD: 'MH',
    AO: 'HI',
    AI: 'HI',
    AQ: 'HI',
    AG: 'HI',
    AR: 'ML',
    AM: 'MH',
    AW: 'HI',
    AU: 'ML',
    AT: 'ML',
    AZ: 'HI',
    BS: 'MH',
    BH: 'MH',
    BD: 'HI',
    BB: 'HI',
    BY: 'HI',
    BE: 'ML',
    BZ: 'HI',
    BJ: 'MH',
    BM: 'MH',
    BT: 'MH',
    BO: 'HI',
    BQ: 'HI',
    BA: 'HI',
    BW: 'HI',
    BV: 'HI',
    BR: 'ML',
    VG: 'MH',
    BN: 'MH',
    BG: 'ML',
    BF: 'MH',
    BI: 'HI',
    KH: 'HI',
    CM: 'MH',
    CA: 'ML',
    CV: 'MH',
    KY: 'MH',
    CF: 'HI',
    TD: 'HI',
    IO: 'HI',
    CL: 'ML',
    CN: 'ML',
    CX: 'HI',
    CC: 'HI',
    CO: 'MH',
    KM: 'HI',
    CG: 'HI',
    CD: 'HI',
    CK: 'HI',
    CR: 'MH',
    HR: 'ML',
    CU: 'HI',
    CW: 'MH',
    CY: 'MH',
    CZ: 'ML',
    DK: 'ML',
    DJ: 'MH',
    DM: 'HI',
    DO: 'MH',
    EC: 'MH',
    EG: 'HI',
    GQ: 'HI',
    ER: 'HI',
    ET: 'HI',
    FK: 'HI',
    FO: 'HI',
    FJ: 'HI',
    FI: 'ML',
    FR: 'ML',
    PF: 'HI',
    TF: 'HI',
    GA: 'MH',
    GM: 'MH',
    GE: 'MH',
    DE: 'ML',
    GH: 'MH',
    GI: 'MH',
    GR: 'ML',
    GL: 'HI',
    GD: 'HI',
    GU: 'HI',
    GT: 'HI',
    GG: 'MH',
    GN: 'HI',
    GW: 'HI',
    GY: 'MH',
    HT: 'HI',
    HM: 'HI',
    VA: 'HI',
    HN: 'HI',
    HK: 'ML',
    HU: 'ML',
    IS: 'ML',
    IN: 'ML',
    ID: 'MH',
    IR: 'HI',
    IQ: 'HI',
    IE: 'ML',
    IM: 'MH',
    IL: 'ML',
    IT: 'ML',
    CI: 'MH',
    JM: 'MH',
    JP: 'ML',
    JE: 'MH',
    JO: 'MH',
    KZ: 'MH',
    KE: 'HI',
    KI: 'HI',
    KP: 'HI',
    XK: 'HI',
    KW: 'MH',
    KG: 'HI',
    LA: 'HI',
    LV: 'MH',
    LB: 'HI',
    LS: 'MH',
    LR: 'MH',
    LY: 'HI',
    LI: 'ML',
    LU: 'ML',
    MO: 'MH',
    MK: 'HI',
    MG: 'HI',
    MW: 'MH',
    MY: 'MH',
    MV: 'MH',
    ML: 'HI',
    MH: 'HI',
    MR: 'MH',
    MU: 'MH',
    YT: 'MH',
    MX: 'MH',
    FM: 'HI',
    UM: 'HI',
    MD: 'MH',
    MC: 'ML',
    MN: 'MH',
    ME: 'HI',
    MS: 'HI',
    MA: 'MH',
    MZ: 'HI',
    MM: 'HI',
    NA: 'MH',
    NR: 'HI',
    NP: 'MH',
    NC: 'MH',
    NZ: 'ML',
    NI: 'HI',
    NE: 'MH',
    NG: 'HI',
    NU: 'HI',
    NF: 'HI',
    MP: 'HI',
    NO: 'ML',
    OM: 'MH',
    PK: 'HI',
    PW: 'HI',
    PS: 'HI',
    PA: 'HI',
    PG: 'HI',
    PY: 'HI',
    PE: 'MH',
    PH: 'MH',
    PN: 'HI',
    PL: 'ML',
    PT: 'ML',
    PR: 'MH',
    QA: 'ML',
    KR: 'ML',
    RO: 'ML',
    RU: 'HI',
    RW: 'MH',
    SH: 'HI',
    KN: 'HI',
    LC: 'HI',
    MF: 'HI',
    PM: 'HI',
    VC: 'HI',
    SV: 'MH',
    WS: 'HI',
    SM: 'HI',
    ST: 'HI',
    SA: 'MH',
    SN: 'MH',
    RS: 'HI',
    SC: 'MH',
    SL: 'HI',
    SG: 'ML',
    SK: 'ML',
    SI: 'ML',
    SB: 'HI',
    SO: 'HI',
    ZA: 'ML',
    GS: 'HI',
    SS: 'HI',
    ES: 'ML',
    LK: 'HI',
    SD: 'HI',
    SR: 'HI',
    SJ: 'HI',
    SZ: 'MH',
    SE: 'ML',
    CH: 'ML',
    SY: 'HI',
    TW: 'ML',
    TJ: 'HI',
    TH: 'MH',
    NL: 'ML',
    TL: 'HI',
    TG: 'MH',
    TK: 'HI',
    TO: 'HI',
    TT: 'MH',
    TN: 'HI',
    TR: 'HI',
    TM: 'HI',
    TC: 'HI',
    TV: 'HI',
    UG: 'HI',
    UA: 'HI',
    AE: 'MH',
    TZ: 'MH',
    GB: 'ML',
    US: 'ML',
    UY: 'MH',
    UZ: 'HI',
    VU: 'HI',
    VE: 'HI',
    VN: 'MH',
    WF: 'HI',
    EH: 'HI',
    YE: 'HI',
    ZM: 'MH',
    ZW: 'HI',
    EE: 'MH',
    LT: 'MH',
    MT: 'MH',
};

const riskByCodeNace = {
    '0': 'HI',
    '296': 'HI',
    '362': 'HI',
    '371': 'HI',
    '372': 'HI',
    '9003': 'HI',
    '000': 'HI',
    '525': 'HI',
    '553': 'HI',
    '4777': 'HI',
    '652': 'HI',
    '746': 'HI',
    '923': 'HI',
    '927': 'HI',
    '7490': 'HI',
    '3040': 'HI',
    '3212': 'HI',
    '2051': 'HI',
    '2441': 'HI',
    '2540': 'HI',
    '3811': 'HI',
    '3812': 'HI',
    '3822': 'HI',
    '3831': 'HI',
    '3832': 'HI',
    '4672': 'HI',
    '4677': 'HI',
    '4742': 'HI',
    '5610': 'HI',
    '6430': 'HI',
    '6499': 'HI',
    '7312': 'HI',
    '8010': 'HI',
    '8121': 'HI',
    '8122': 'HI',
    '8129': 'HI',
    '8559': 'HI',
    '9001': 'HI',
    '9200': 'HI',
    '9329': 'HI',
    '9492': 'HI',
    '9900': 'HI',
    '0000': 'HI',
    '0721': 'HI',
};
