import React from 'react';
import PropTypes from 'prop-types';
import Flag from './flag';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        marginBottom: '20px',
        flexDirection: 'column',
    },
    group: {
        display: 'flex',
        alignItems: 'center',
    },
    label: {
        color: colors.grey400,
        width: '60px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.aside.flags';

    static propTypes = {
        answer: PropTypes.object.isRequired,
        scope: PropTypes.oneOf(['input', 'ddq', 'onSite', 'report']),
        onFlags: PropTypes.func.isRequired,
        readOnly: PropTypes.bool
    };

    render() {
        if (this.props.scope === 'input') {
            return null;
        }

        const flags = this.props.answer.flags || {};

        return (
            <div style={styles.container}>
                <div style={styles.group}>
                    <div style={styles.label}>Rating:</div>
                    {this.renderRating(flags)}
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Flags:</div>
                    {this.renderFlags(flags)}
                </div>
            </div>
        );
    }

    renderRating(flags) {
        return [
            <Flag
                readOnly={this.props.readOnly}
                key="allRight"
                label="All Right"
                icon="deeligenz.survey.allRight"
                color="green"
                value={!!flags.allRight}
                onChange={
                    !this.props.readOnly && this.props.onFlags
                        ? value =>
                              this.props.onFlags(
                                  Object.assign(
                                      {},
                                      flags,
                                      value ? { allRight: true, concern: false, redFlag: false } : { allRight: false },
                                  ),
                              )
                        : undefined
                }
            />,

            <Flag
                readOnly={this.props.readOnly}
                key="concern"
                label="Concern"
                icon="deeligenz.survey.concern"
                color="cold"
                value={!!flags.concern}
                onChange={
                    !this.props.readOnly && this.props.onFlags
                        ? value =>
                              this.props.onFlags(
                                  Object.assign(
                                      {},
                                      flags,
                                      value ? { allRight: false, concern: true, redFlag: false } : { concern: false },
                                  ),
                              )
                        : undefined
                }
            />,

            <Flag
                readOnly={this.props.readOnly}
                key="redFlags"
                label="Red Flag"
                icon="deeligenz.survey.flag"
                color="hot"
                value={!!flags.redFlag}
                onChange={
                    !this.props.readOnly && this.props.onFlags
                        ? value =>
                              this.props.onFlags(
                                  Object.assign(
                                      {},
                                      flags,
                                      value ? { allRight: false, concern: false, redFlag: true } : { redFlag: false },
                                  ),
                              )
                        : undefined
                }
            />,
        ];
    }

    renderFlags(flags) {
        return [
            <Flag
                readOnly={this.props.readOnly}
                key="onSite"
                label="Meeting"
                icon="deeligenz.survey.onSite"
                color="grey"
                value={!!flags.onSite}
                onChange={
                    !this.props.readOnly && this.props.onFlags
                        ? value => this.props.onFlags(Object.assign({}, flags, { onSite: value }))
                        : undefined
                }
            />,

            <Flag
                readOnly={this.props.readOnly}
                key="keyFinding"
                label="Key Finding"
                icon="deeligenz.survey.keyFinding"
                color="grey"
                comment={true}
                value={flags.keyFinding}
                onChange={
                    !this.props.readOnly && this.props.onFlags
                        ? value => this.props.onFlags(Object.assign({}, flags, { keyFinding: value }))
                        : undefined
                }
            />,

            <Flag
                readOnly={this.props.readOnly}
                key="nextReview"
                label="Next Review"
                icon="deeligenz.survey.nextReview"
                color="grey"
                value={!!flags.nextReview}
                onChange={
                    !this.props.readOnly && this.props.onFlags
                        ? value => this.props.onFlags(Object.assign({}, flags, { nextReview: value }))
                        : undefined
                }
            />,
        ];
    }
}
