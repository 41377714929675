// @flow

import type { AuthCursor, AuthUser } from '@deecision/infra-types/common';

export default function(user: AuthUser, current: AuthCursor, next: $Shape<AuthCursor>): AuthCursor {
    if (next.intervention) {
        for (const intervention of user.interventions) {
            if (intervention.id !== next.intervention) continue;
            if (next.team && !intervention.memberships[next.team]) continue;

            return {
                team: next.team || Object.keys(intervention.memberships)[0],
                intervention: intervention.id,
            };
        }

        return current;
    }

    if (next.account) {
        for (const account of user.accounts) {
            if (account.id !== next.account) continue;
            if (next.team && !account.memberships[next.team]) continue;

            return {
                team: next.team || Object.keys(account.memberships)[0],
                account: account.id,
            };
        }

        return current;
    }

    if (next.team) {
        for (const account of user.accounts) {
            if (next.team && account.memberships[next.team]) {
                return {
                    team: next.team,
                    account: account.id,
                };
            }
        }

        for (const intervention of user.interventions) {
            if (next.team && intervention.memberships[next.team]) {
                return {
                    team: next.team,
                    intervention: intervention.id,
                };
            }
        }
    }

    return current;
}
