import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import colors from 'app/theme/colors';
import t from 'app/translation';
import Icon from 'app/theme/icon';

const styles = {
    message: {
        padding: '0px',
        color: colors.userHot,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    container: {
        padding: 0,
        margin: '20px 0 0 0',
    },
    list: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    blockTitle: {
        color: colors.userCold,
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '20px',
        background: colors.grey100,
    },
    item: {
        color: colors.grey500,
        borderTop: '1px solid ' + colors.grey300,
    },
    hover: {
        background: colors.grey100,
        color: colors.grey700
    },
    link: {
        display: 'block',
        padding: '10px',
        color: 'inherit',
        textDecoration: 'none'
    },
    star: {
        display: 'inline-block',
        marginRight: '5px',
        color: colors.userHot,
    },
    icon: {
        display: 'inline-block',
        marginRight: '5px',
        verticalAlign: 'bottom'
    },
    title: {
        display: 'block',
        margin: '5px',
        color: colors.userCold,
        fontWeight: 'bold'
    },
    info: {
        display: 'block',
        margin: '5px',
        color: colors.grey900
    },
    description: {
        display: 'block',
        margin: '5px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.report.news';

    static propTypes = {
        items: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        style: PropTypes.object
    };

    constructor (props) {
        super(props);
        this.state = { hover: null };
    }

    render () {
        if (this.props.loading)
            return (
                <div style={ styles.container }>
                    <div style={ styles.blockTitle }>
                    {t('common.message.loading')}
                    </div>
                </div>
            );

        if (! this.props.items.length)
            return (
                <div style={ styles.container }>
                    <div style={ styles.blockTitle }>
                        {this.props.title}
                    </div>
                    {t('common.message.noFound')}
                </div>
            );

        const news = _.orderBy(this.props.items.filter(item=> (item.icon==='veegilenz.warning') && (typeof item.date !== 'undefined') && Moment(item.date) > Moment().subtract(5, 'years')),'date', 'desc');
        
        return (
            <div style={ styles.container }>
                <div style={ styles.blockTitle }>
                    {this.props.title}
                </div>
                <ul style={ Object.assign({}, styles.list, this.props.style) }>
                    { news.map(this.renderItem.bind(this)) }
                </ul>
            </div>
        );
    }

    renderItem (item: any, index: any) {
        const style = this.state.hover === index
            ? Object.assign({}, styles.item, styles.hover)
            : styles.item;

        return (
            <li key={ index }
                style={ style }
                onMouseEnter={ event => this.setState({ hover: index }) }
            >
                <a href={ item.url } target="_blank" style={ styles.link }>
                    <span style={ styles.title }>
                    { item.title }
                    </span>
                    <span style={ styles.info }>
                        { this.renderInfo(item) }
                    </span>

                    <span style={ styles.description } dangerouslySetInnerHTML={{ __html: item.desc}} />
                </a>
            </li>
        );
    }

    renderIcon (type: string) {
        if (! type) {
            return null;
        }

        return (
            <span style={styles.icon}><Icon style={styles.star} type={ type } /></span>
        );
    }

    renderInfo (item) {
        const values = [];

        if (item.date)
            values.push(Moment(item.date).format('dddd DD MMMM YYYY'));

        if (item.domain)
            values.push(item.domain);

        return values.join(' — ');
    }
}
