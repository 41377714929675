import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Scalar from './scalar';

const styles = {
    table: {},
    header: {
        display: 'flex',
        alignItems: 'stretch',
        background: colors.grey100,
        textAlign: 'center',
        color: colors.grey500,
        lineHeight: '40px',
    },
    title: {
        fontWeight: 'normal',
        color: colors.grey500,
        textAlign: 'left',
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    widget: {
        paddingRight: '10px',
    },
    field: {
        display: 'flex',
    },
    label: {
        display: 'inline-block',
        width: '50%',
        lineHeight: '40px',
        textAlign: 'right',
        textTransform: 'uppercase',
        color: colors.grey500,
        marginRight: '10px',
        fontSize: '13px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.object';

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        answer: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    render() {
        const fields = this.props.fields || [];
        const values = this.props.answer.values || [];

        if (this.props.readOnly && !values.length) {
            return null;
        }

        return (
            <div>
                {fields.map((field, index) => (
                    <div
                        key={'field' + index}
                        style={Object.assign({}, styles.field, { marginTop: index > 0 ? '10px' : '0' })}
                    >
                        <div style={styles.label}>{field.label}</div>

                        <div style={{ width: '50%' }}>
                            <Scalar
                                type={field.type}
                                options={{decimal: true}}
                                answer={{ value: values[index] }}
                                readOnly={this.props.readOnly}
                                style={styles.widget}
                                onChange={answer => {
                                    values[index] = answer.value;
                                    this.props.onChange({ values });
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}
