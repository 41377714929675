// @flow

import PropTypes from 'prop-types';

import React from 'react';
import Views from 'app/views';
import t from 'app/translation';

const styles = {
    container: {
        width: '260px',
        flexShrink: '0'
    },
    submit: {
        display: 'block',
        height: '40px',
        width: '100%'
    },
    progress: {
        position: 'absolute',
        left: '-10px',
        top: '-10px'
    },
    cancel: {
        display: 'block',
        marginLeft: '50px',
        height: '40px'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.form.actions';

    static contextTypes = {
        submitSearch: PropTypes.func.isRequired
    };

    props: {
        state?: Object
    };

    render () {
        const loading = this.props.state
            ? this.props.state.loading
            : false;

        return (
            <div style={ styles.container }>
                <Views.Common.Button
                    type="raised"
                    style={ styles.submit }
                    label={ loading ? t('common.message.loading') : t('dna.deefind.workspace.searchButton') }
                    disabled={ loading }
                    onClick={ event => this.context.submitSearch() }
                    color="hot"
                />
            </div>
        );
    }
}
