import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.model.frame';

    static propTypes = {
        title: PropTypes.node,
        filter: PropTypes.oneOfType([ PropTypes.object, PropTypes.func ]),
        type: PropTypes.oneOf([ 'cards', 'table' ]).isRequired,
        folded: PropTypes.bool,
        specs: PropTypes.object,
        element: PropTypes.element.isRequired,
        loading: PropTypes.element,
        missing: PropTypes.element,
        size: PropTypes.number,
        page: PropTypes.number,
    };

    render () {
        throw new Error(`Model component must not be rendered.`);
    }
}
