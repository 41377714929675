// @flow

import type StateStore from 'app/stores/state';
import type { GlobalStore } from '../core/types';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';
import type { IObservableValue } from 'mobx';

import { action, observable, runInAction } from 'mobx';
import cleerance from '@deecision/cleerance-utils';

const PATH = 'dna.settings';

export default class DnaSettingsStore {
    global: GlobalStore;
    state: StateStore;
    reechSources: IObservableValue<Object[] | null>;
    deeggerSources: IObservableValue<Object[] | null>;

    constructor(global: GlobalStore, state: StateStore) {
        this.global = global;
        this.state = state;
        this.reechSources = observable.box(null);
        this.deeggerSources = observable.box(null);
    }

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        runInAction(() => {
            this.reechSources.set(null);
            this.deeggerSources.set(null);
        });

        if (!cleerance.resolveFlags(session, cursor).includes('reech.access')) {
            return;
        }

        const sources = await this.global.execute('reech', 'source.list');
        const deeggerSources = await this.global.execute('deefind', 'deegger.source.list');

        runInAction(() => {
            this.reechSources.set(sources);
            this.deeggerSources.set(deeggerSources)
        });
    }

    @action uploadReechSource = async (id: string, data: string[][]): Promise<void> => {
        await this.global.execute('reech', 'source.upload', { id, data });
        await this.global.execute('reech', 'update.one', { id });
        const sources = await this.global.execute('reech', 'source.list');
        runInAction(() => this.reechSources.set(sources));
    };

    @action deeggerExecInitialize = async (id: string, data: string[][]): Promise<void> => {
        await this.global.execute('deefind', 'deegger.source.init', { id, data });
        const deeggerSources = await this.global.execute('deefind', 'deegger.source.list');
        runInAction(() => this.deeggerSources.set(deeggerSources));
    };
    @action deeggerExecReset = async (id: string, data: string[][]): Promise<void> => {
        await this.global.execute('deefind', 'deegger.source.reset', { id, data });
        const deeggerSources = await this.global.execute('deefind', 'deegger.source.list');
        runInAction(() => this.deeggerSources.set(deeggerSources));
    };
    @action deeggerExecUpdate = async (id: string, data: string[][]): Promise<void> => {
        await this.global.execute('deefind', 'deegger.source.update', { id, data });
        const deeggerSources = await this.global.execute('deefind', 'deegger.source.list');
        runInAction(() => this.deeggerSources.set(deeggerSources));
    };
}
