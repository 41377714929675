import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';

const styles = {
    container: {
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    step: {
        position: 'relative',
        width: '25%',
        textAlign: 'center',
        padding: '2px 0',
        color: colors.userCold,
        lineHeight: '24px',
        height: '24px',
    },
    progress: {
        fontWeight: 'bold',
    },
    current: {
        boxShadow: colors.userCold + ' 0 0 1px 1px inset',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.archives.summary.progress';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render() {
        return (
            <div style={Object.assign({}, this.props.style, styles.container)}>
                {this.renderStep('input', 'Input phase', ['ddq', 'onSite', 'report'])}
                {this.renderStep('ddq', 'DDQ phase', ['onSite', 'report'], true)}
                {this.renderStep('onSite', 'Meeting phase', ['report'], true)}
                {this.renderStep('report', 'Report phase', [], true)}
            </div>
        );
    }

    renderStep(status, label, next, sep) {
        const computed = this.props.entity.computed || {};
        const progress = (computed.progress || {})[status];

        let content, color;

        switch (true) {
            case next.includes(this.props.entity.status):
                content = <Icon type="base.flow.success" color={colors.userGreen} />;
                color = colors.bgGreen;
                break;

            case status === this.props.entity.status:
                content = <span style={styles.progress}>{(progress || 0) + ' %'}</span>;
                color = colors.bgCold;
                break;

            case progress > 0:
                content = <span style={styles.progress}>{(progress || 0) + ' %'}</span>;
                break;

            default:
                content = <Icon type="base.no" color={colors.grey300} />;
        }

        const style = Object.assign(
            {},
            styles.step,
            sep ? { borderLeft: '1px solid ' + colors.grey300 } : {},
            color ? { background: color } : {},
            status === this.props.entity.status ? styles.current : {},
        );

        return (
            <Views.Common.Tooltip content={label}>
                <div style={style}>{content}</div>
            </Views.Common.Tooltip>
        );
    }
}
