import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    notActive: {
        display: 'inline-block',
        cursor: 'pointer',
        padding: '10px',
        color: 'rgb(13, 71, 161)',
        fontWeight: 'bold',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '3px',
    },
    active: {
        backgroundColor: 'rgb(245, 245, 245)',
        color: 'rgb(229, 57, 53)',
    },
    tabManager: {
        display: 'inline-block',
        width: '100%',
        padding: '0px',
    },
}

export class TabManager extends React.PureComponent<any, any> {
    state = {}

    render() {
        const { activeTab, handleTab, tabs } = this.props;

        let styleActive = Object.assign({ }, styles.notActive, styles.active);

        return (
            <td style={styles.tabManager}>
                {tabs.map(({ label, value }) => (
                    <div
                        key={ value }
                        style={value === activeTab ? styleActive : styles.notActive }
                        onClick={() => { handleTab(value); }}
                    >
                        { label }
                    </div>
                ))}
            </td>
        );
    }
}

TabManager.propTypes = {
    activeTab: PropTypes.number.isRequired,
    handleTab: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(Object).isRequired,
};
