import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Index from './index';
import Actions from './actions';
import Field from './field';
import Active from './active';

const styles = {
    container: {
        marginTop: '1px',
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch',
    },
    hover: {
        background: colors.grey100,
    },
    inactive: {
        opacity: '0.5',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.form.widget.collection.row';

    static propTypes = {
        fields: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
        flags: PropTypes.array.isRequired,
        data: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
    };

    state = {
        hover: false,
    };

    render() {
        const style = Object.assign({},
            styles.container,
            this.state.hover ? styles.hover : {},
            this.props.flags.includes('active') && !(this.props.data || {}).active ? styles.inactive : {}
        );

        return (
            <div
                style={style}
                onMouseEnter={event => this.setState({ hover: true })}
                onMouseLeave={event => this.setState({ hover: false })}
            >
                {this.props.flags.includes('index') ? (
                    <Index index={this.props.index} sort={this.props.flags.includes('sort')} empty={!this.props.data} />
                ) : null}

                {this.props.flags.includes('active') ? (
                    <Active data={this.props.data} onChange={this.props.onChange} />
                ) : null}

                {this.renderFields()}

                {this.props.flags.includes('fixed') ? null : (
                    <Actions onRemove={this.props.onRemove} empty={!this.props.data} />
                )}
            </div>
        );
    }

    renderFields() {
        const size = _.sum(_.map(this.props.fields, field => field.size || 1));

        return _.map(this.props.fields, (field, name) => (
            <Field
                key={name}
                type={_.isString(field) ? field : field.type}
                options={_.isString(field) ? {} : _.omit(field, 'type')}
                data={_.get(this.props.data, name)}
                focus={this.state.focus === name}
                style={{ width: (100 * (field.size || 1)) / size + '%' }}
                onFocus={this.props.onFocus}
                onChange={data => {
                    const object = this.props.data ? _.clone(this.props.data) : {};
                    _.set(object, name, data);
                    this.props.onChange(object);
                }}
            />
        ));
    }
}
