// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.settings.profile.edit';

    static propTypes = {
        global: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
        service: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        flags: PropTypes.arrayOf(PropTypes.string.isRequired),
    };

    state = { access: null };

    open() {
        this.refs['dialog'].open();
    }

    render() {
        return (
            <Views.Modal.Edition
                key="dialog"
                ref="dialog"
                label="Save setup"
                data={this.makeData()}
                style={{ width: '500px' }}
                onChange={(data: Object) => this.setState({ access: data.access })}
                onSubmit={async (data: Object) => {
                    this.props.global.execute('cleerance', 'team.setup', {
                        team: this.props.id,
                        setup: {
                            service: this.props.service,
                            access: data.access,
                            url: data.access === 'private' ? data.url : undefined,
                            flags: data.flags,
                        }
                    });
                }}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        label="Access type"
                        path="access"
                        widget={{ type: 'radios', choices: { none: 'None', cloud: 'Cloud', private: 'Private' } }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Service URL"
                        path="url"
                        widget="string"
                        readOnly={this.state.access !== 'private'}
                        required={this.state.access === 'private'}
                    />
                </Views.Form.Row>

                {this.renderFlags()}
            </Views.Modal.Edition>
        );
    }

    makeData() {
        const urls = this.props.data.urls || {};
        const flags = this.props.data.flags || [];
        const data = { access: 'none', url: '', flags: [] };

        if (flags.includes(`${this.props.service}.access`)) {
            if (urls[this.props.service]) {
                data.access = 'private';
                data.url = urls[this.props.service];
            } else {
                data.access = 'cloud';
                data.url = window.location.origin;
            }
        } else {
            data.access = 'none';
            data.url = '';
        }

        for (const key of this.props.flags || []) {
            if (flags.includes(`${this.props.service}.${key}`)) {
                data.flags.push(key);
            }
        }

        return data;
    }

    renderFlags() {
        if (!this.props.flags || !this.props.flags.length) {
            return null;
        }

        const choices = {};

        for (const key of this.props.flags) {
            choices[key] = t(`cleerance.admin.services.${this.props.service}.${key}`);
        }

        return (
            <Views.Form.Row>
                <Views.Form.Field label="Features" path="flags" widget={{ type: 'bools', choices }} />
            </Views.Form.Row>
        );
    }
}
