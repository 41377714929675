import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.augmented.charts.barChartStacked';

    static propTypes = {
        dataAumOverPotential: PropTypes.array.isRequired,
        dataAugmented: PropTypes.array.isRequired,
        dataComputedSameColumn: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    };

    render() {
        return (
            <Plot
                data={[
                    {
                        type: 'bar',
                        x: this.props.dataAumOverPotential.map(d => d.label),
                        y: this.props.dataAumOverPotential.map(d => d.value),
                        text:this.props.dataAumOverPotential.map(d => d.text),
                        marker: {color: 'rgb(51,63,100)'},
                        textposition: 'auto',
                        name: 'Pot. < AUM'
                    },
                    {
                        type: 'bar',
                        x: this.props.dataComputedSameColumn.map(d => d.label),
                        y: this.props.dataComputedSameColumn.map(d => d.value),
                        marker: {color: 'rgb(162, 189, 206)'},
                        text: this.props.dataComputedSameColumn.map(d => d.text),
                        textposition: 'auto',
                        name: 'Même catégorisation'
                    },
                    {
                        type: 'bar',
                        x: this.props.dataAugmented.map(d => d.label),
                        y: this.props.dataAugmented.map(d => d.value),
                        marker: {color: 'rgb(80, 175, 51)'},
                        text: this.props.dataAugmented.map(d => d.text),
                        textposition: 'auto',
                        name: 'Augmentés'
                    },

                ]}
                layout={{
                    width: this.props.width,
                    height: this.props.height,
                    title: {
                        text: this.props.title,
                        font: {
                            family: 'Arial',
                            size: 18,
                            color: '#000000'
                        },
                        xref: 'paper',
                        x: 0.05,
                    },
                    showlegend: false,
                    barmode: 'stack',
                    yaxis: {
                        dtick: 10,
                        ticksuffix: '%',
                        showgrid: true,
                    },
                    margin: {
                        l: 60,
                        r: 60,
                        b: 100,
                        t: 30,
                        pad: 10
                    },
                }}
                config={{displayModeBar: false}}
            />
        );
    }
}
