import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import { pick } from 'lodash';

const styles = {
    container: {
        border: '1px solid ' + colors.grey300,
        flexGrow: '1',
        flexBasis: '0',
        flexShrink: '1',
    },
    head: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + colors.grey200,
        background: colors.grey100,
    },
    title: {
        padding: '15px 0 15px 20px',
        flexGrow: '1',
        color: colors.userCold,
        lineHeight: '20px',
    },
    edit: {
        margin: '5px',
    },
    body: {
        minHeight: '100px',
        fontSize: '14px',
        lineHeight: '16px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.base';

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.object,
        fields: PropTypes.arrayOf(PropTypes.object),
        onSave: PropTypes.func,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.head}>
                    <div style={styles.title}>{this.props.title}</div>
                    {this.renderEdit()}
                </div>

                <div style={styles.body}>{this.props.children}</div>
            </div>
        );
    }

    renderEdit() {
        if (!this.props.fields || !this.props.onSave) {
            return null;
        }

        return (
            <div style={styles.edit}>
                <Views.Common.Button
                    type="raised"
                    color="cold"
                    label="Edit"
                    icon={<Icon type="do.update" />}
                    onClick={() => this.refs['edit'].open()}
                />

                <Views.Modal.Edition
                    ref="edit"
                    label="Save"
                    data={this.props.data || {}}
                    onSubmit={(data) => {
                        const paths = this.props.fields.map(field => field.path);
                        this.props.onSave(pick(data, paths));
                    }}
                >
                    {this.props.fields.map(field => (
                        <Views.Form.Row key={field.path}>
                            <Views.Form.Field
                                label={field.label}
                                path={field.path}
                                widget={field.widget || 'string'}
                                onAsync={field.onAsync}
                            />
                        </Views.Form.Row>
                    ))}
                </Views.Modal.Edition>
            </div>
        );
    }
}
