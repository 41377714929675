// @flow

import camelCase from 'lodash/camelCase';
import slugify from 'speakingurl';

function apply(value: any, clean: any): any {
    if (typeof clean === 'function') {
        return clean(value);
    } else if (Array.isArray(clean)) {
        const func = clean[0];

        if (typeof func === 'function') {
            return func(value, ...clean.slice(1));
        }
    }

    return undefined;
}

export function cleanBoolean(value: any): boolean {
    return !!value;
}

export function cleanNumber(value: any): ?number {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    if (typeof value !== 'number' || isNaN(value)) {
        return undefined;
    }

    return value;
}

export function cleanYear(value: any): ?number {
    value = cleanNumber(value) || 0;

    if (value < 1900 || value > 2200) {
        return undefined;
    }

    return Math.round(value);
}

export function cleanPercentage(value: any): ?number {
    value = cleanNumber(value);

    return value === undefined ? undefined : Math.max(0, Math.min(100, value || 0));
}

export function cleanString(value: any): ?string {
    if (typeof value === 'number') {
        return '' + value;
    }

    if (typeof value !== 'string') {
        return undefined;
    }

    value = value.replace(/^\s+|\s+$/gm, '');

    return value || undefined;
}

export function cleanIdentifier(value: any): ?string {
    value = cleanString(value);

    return value ? slugify(value).replace(/-/g, '') : undefined;
}

export function cleanKey(value: any): ?string {
    value = cleanString(value);

    if (value && typeof value === 'string') {
        value = camelCase(value.toLowerCase().replace(/[^0-9a-z]+/gi, '-'));
    }

    return value || undefined;
}

export function cleanName(value: any): ?string {
    value = cleanString(value);

    if (value && typeof value === 'string') {
        value = value.replace(/\s+/g, ' ');
    }

    return value || undefined;
}

export function cleanDate(value: any): ?string {
    value = cleanString(value);

    if (!value || typeof value !== 'string') {
        return undefined;
    }

    value = value.replace(/[^0-9]/g, '');

    if (value.length >= 8) {
        return `${value.substr(0, 4)}-${value.substr(4, 2)}-${value.substr(6, 2)}`;
    }

    if (value.length >= 6) {
        return `${value.substr(0, 4)}-${value.substr(4, 2)}`;
    }

    if (value.length >= 4) {
        return `${value.substr(0, 4)}`;
    }

    return undefined;
}

export function cleanArray(value: any, clean?: Function | [Function] = v => v): any[] {
    if (!Array.isArray(value) || !value.length) {
        return [];
    }

    if (clean) {
        value = value.map(value => apply(value, clean));
    }

    return value.filter(v => v !== undefined && v !== null);
}

export function cleanObject(value: any): ?Object {
    if (!value || typeof value !== 'object') {
        return undefined;
    }

    return value;
}

// common function cleanVector(value: any, clean?: Function | [Function] = v => v): Object | undefined {
//     if (!value || typeof value !== 'object') {
//         return undefined;
//     }
//
//     const result = {};
//
//     for (const key of Object.keys(value)) {
//         const normVal = apply(value[key], clean);
//         const normKey = cleanKey(key);
//
//         if (normKey && null !== normVal && undefined !== normVal) {
//             result[normKey] = normVal;
//         }
//     }
//
//     return result;
// }

export function cleanShape(value: any, shape?: Object = {}): ?Object {
    if (!value || typeof value !== 'object') {
        return undefined;
    }

    const result = {};

    for (const key of Object.keys(shape)) {
        const temp = apply(value[key], shape[key]);

        if (null !== temp && undefined !== temp) {
            result[key] = temp;
        }
    }

    return result;
}

export function cleanOr(value: any, options: Function[]): any {
    for (const option of options) {
        const result = apply(value, option);

        if (result !== undefined) {
            return result;
        }
    }

    return undefined;
}
