import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import { computeProspectValues } from '../compute';

const styles = {
    container: {
        color: colors.grey500
    },
    separator: {
        display: 'inline-block',
        margin: '0 15px'
    },
    ok: {
        color: colors.userCold
    },
    ko: {
        color: colors.userHot
    }
};

export default class extends React.Component<any, any> {
    static displayName = 'comeet.common.prospects.budget';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        cost: PropTypes.number.isRequired,
        style: PropTypes.object,
    };

    render () {
        const style = Object.assign({}, styles.container, this.props.style);
        const values = computeProspectValues(this.props.entity.payload);
        const pot = values.investment - this.props.cost;

        return (
            <span style={ style }>
                <Views.Common.Number
                    style={ (pot <= values.investment / 10) ? styles.ko : styles.ok }
                    value={ pot }
                    scale="K"
                    type="currency"
                />

                <span style={ styles.separator }>/</span>

                <Views.Common.Number
                    value={ values.investment }
                    type="currency"
                    scale="K"
                />
            </span>
        );
    }
}
