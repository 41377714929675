import PropTypes from 'prop-types';
import React from 'react';
import Item from './item';
import Score from './score';
import t from 'app/translation';
import Views from 'app/views';

const styles = {
    container: {
        width: '1',
        flexGrow: '1',
        flexShrink: '1',
        flex: '1 0 21%'
    },
    header: {
        marginBottom: '20px',
    },
    title: {
        fontSize: '21px',
        marginBottom: '5px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.vigilance.block';

    static propTypes = {
        title: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        data: PropTypes.object,
        with: PropTypes.array,
        type: PropTypes.oneOf(['direct', 'network']).isRequired,
        progress: PropTypes.object,
        style: PropTypes.object,
    };

    render() {

        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <Views.Common.Tooltip content={this.props.tooltip}>
                    <div style={styles.title}>{this.props.title}</div>
                </Views.Common.Tooltip>
                <Score score={this.props.data ? this.props.data.score : 0} />
                <br />
                <br />
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        if (this.props.data) {
            return this.renderItems();
        }

        if (this.props.progress) {
            return <Views.Common.Loader progress={this.props.progress} />;
        }

        return null;
    }

    renderItems() {
        const data = this.props.data || {};
        const items = [];

        if ((this.props.with || []).includes('activity')) {
          items.push(<Item
              key={'activity'}
              label={t('common.label.activity')}
              score={this.makeScore(data.activity)}
              data={this.makeData('activity', data.activity)}
          />)
        }

        if ((this.props.with || []).includes('country')) {
          items.push(<Item
              key={'country'}
              label={t('common.label.country')}
              score={this.makeScore(data.country)}
              data={this.makeData('country', data.country)}
          />)
        }

        if ((this.props.with || []).includes('mandates')) {
          items.push(<Item
              key={'mandates'}
              label={t('common.label.mandates')}
              score={this.makeScore(data.mandates)}
              data={this.makeData('mandates', data.mandates)}
          />)
        }

        if ((this.props.with || []).includes('listedRelations')) {
            items.push(<Item
                key={'listedRelations'}
                info={'mandates listed company'}
                label={t('dna.deefind.person.listedRelations')}
                score={this.makeScore(data.listedRelations)}
                data={this.makeData('listedRelations', data.listedRelations)}
            />)
        }

        if ((this.props.with || []).includes('politic')) {
          items.push(<Item
              key={'politic'}
              info={'Politically exposed person'}
              label={t('dna.deefind.person.exposedRelations')}
              score={this.makeScore(data.politic)}
              data={this.makeData('politic', data.politic)}
          />)
        }

        if ((this.props.with || []).includes('justice')) {
          items.push(<Item
              key={'justice'}
              label={t('dna.deefind.veegilenzData.details.justiceLabel')}
              score={this.makeScore(data.justice)}
              data={this.makeData('justice', data.justice)}
          />)
        }

        if ((this.props.with || []).includes('publication')) {
            items.push(<Item
                key={'publication'}
                label={t('dna.deefind.veegilenzData.details.publicationLabel')}
                score={this.makeScore(data.publication)}
                data={this.makeData('publication', data.publication)}
            />)
        }

        return items;
    }

    makeScore(data?: Object = {}): Object {
        return data ? data.score || 0 : 0;
    }

    makeData(type: string, data?: Object = {}): Object {
        if (this.props.type === 'network') {
            return {
                type: 'aggregate',
                warnings: data.warnings || 0,
                alerts: data.alerts || 0,
                vetos: data.vetos || 0,
            };
        }

        if (['country', 'activity'].includes(type)) {
            return {
                type,
                codes: data.codes || [],
            };
        }

        if (['mandates'].includes(type)) {
            return {
                type,
                mandates: data.mandates || 0,
                positions: data.positions || 0,
            };
        }

        if (['politic', 'justice', 'listedRelations'].includes(type)) {
            return {
                type,
                files: data.files || [],
            };
        }

        if (['publication'].includes(type)) {
            return {
                type,
                date: data.date || '',
            };
        }
    }
}
