// @flow

import type StateStore from 'app/stores/state';

import { action } from 'mobx';

const PATH = 'deeligenz.calendar';

export default class DeeligenzStudiesStore {
    state: StateStore;

    constructor (state: StateStore) {
        this.state = state;
    }

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

}
