// @flow

import type {
    ComputedCompaniesSums,
    EntityInnerTarget,
    EntityEstimate,
    StockChild,
    StockStats,
} from '@deecision/deefind-types/model';

export default function makeStockSums(children: StockChild[] | StockStats): ComputedCompaniesSums {
    if (!Array.isArray(children) || !children.length) {
        return {};
    }

    const targets: EntityInnerTarget[] = [];

    for (const child of children) {
        const target = child.target;

        if (target && child.direct && !child.ceased && target.type === 'company' && !target.closed) {
            targets.push(target);
        }
    }

    return {
        turnover: compute(targets, 'turnover') || undefined,
        ebitda: compute(targets, 'ebitda') || undefined,
        capital: compute(targets, 'capital') || undefined,
        valuation: compute(targets, 'valuation') || undefined,
    };
}

function compute(targets: EntityInnerTarget[], property: string): ?EntityEstimate {
    if (!targets.length) {
        return undefined;
    }

    let value = 0;
    let deviation = 0;
    let nulls = 0;

    for (const target of targets) {
        const result = target[property];

        if (!result) {
            nulls++;
            continue;
        }

        if (typeof result === 'number') {
            value += result;
            continue;
        }

        if (result.value) {
            value += result.value;
            deviation += result.deviation || 0;
        }
    }

    if (!value) {
        return undefined;
    }

    return { value, deviation, reliability: (100 * (targets.length - nulls)) / targets.length };
}
