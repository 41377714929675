import type { EntityPreview, FileAttachment } from '@deecision/deefind-types/model';

import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';
import { get } from 'lodash';
import t from 'app/translation';
const WIDTHS = [200, 350, 500];

const styles = {
    empty: {
        color: colors.grey500,
        textAlign: 'center',
        padding: '20px',
        background: colors.grey100,
    },
    container: {
        margin: '20px 0 0 0',
    },
    companyName: {
        margin: '0 0 15px 0',
        fontSize: '17px',
        fontWeight: 'bold',
        color: colors.grey600,
    },
    title: {
        color: colors.userCold,
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '20px',
        background: colors.grey100,
    },
    text: {
        color: colors.grey600,
        fontSize: '16px',
    },
    row: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey300,
        justifyContent: 'stretch',
        alignContent: 'flex-start',
    },
    cell: {
        padding: '20px',
        lineHeight: '20px',
        verticalAlign: 'top',
    },
    cell3: {
        width: '33%',
    },
    heading: {
        color: colors.userCold,
        fontWeight: 'bold',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.compliance.report.identityBlockPerson';

    static propTypes = {
        title: PropTypes.string.isRequired,
        entity: PropTypes.object.isRequired,
        norkom: PropTypes.bool.isRequired,
    };

    render() {
        const name = getName(this.props.entity.preview);
        const address = getAdress(get(this.props.entity,'data.addresses.0'));
        const civ = getCiv(this.props.entity.preview.gender);
        const frResident = isResident(get(this.props.entity,'data.addresses.0'), this.props.entity.preview);
        const sensitiveFunctions = (
            this.props.entity.files.filter((file: FileAttachment) => file.data.type === 'politic') || []
        )
            .map(file => file.data.subheading)
            .join(', ');
        const sanctions = (this.props.entity.files.filter((file: FileAttachment) => file.data.type === 'justice') || [])
            .map(file => file.data.heading)
            .join(', ');

        const prsc = this.props.entity.files.filter(
            (file: FileAttachment) => file.data.type === 'mandate',
        ).length > 0
            ? 'oui'
            : 'non';

        const prscLength = this.props.entity.files.filter(
            (file: FileAttachment) => file.data.type === 'mandate',
        ).length || '0';

        return (
            <div style={styles.container}>
                <div style={styles.title}>{this.props.title}</div>
                <div style={styles.row}>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <h2 style={styles.companyName}>
                            {civ} {name}
                        </h2>
                        <Views.Common.Properties
                            align={100}
                            style={styles.separator}
                            values={[{ label: 'Né le', value: get(this.props.entity, 'preview.birthDate') }]}
                        />
                        <Views.Common.Properties
                            align={100}
                            style={styles.separator}
                            values={[
                                {
                                    label: 'À',
                                    value: `${get(this.props.entity, 'data.identity.birthCity', '')} ${get(
                                        this.props.entity,
                                        'data.identity.birthCountry',
                                        '',
                                    )}`,
                                },
                            ]}
                        />
                        <br />
                        <Views.Common.Properties
                            align={100}
                            style={styles.separator}
                            values={[{ label: 'Adresse', value: address }]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>&nbsp;</div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        {this.props.norkom && (
                            <Views.Common.Properties
                                align={140}
                                style={styles.separator}
                                values={[
                                    { label: 'Score Norkom', value: get(this.props.entity, 'vigilance.norkom.score') },
                                    {
                                        label: 'Niveau Norkom',
                                        value: getNorkomLvl(this.props.entity.vigilance.norkom.score),
                                    },
                                ]}
                            />
                        )}
                        <br />
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Nationalité', value: get(this.props.entity, 'preview.country') }]}
                        />
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Résident Fr', value: frResident }]}
                        />
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Résident', value: get(this.props.entity,'data.addresses.0.country') }]}
                        />
                    </div>
                </div>
                <div style={styles.row}>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: "Nombres d'entreprises liées",
                                    value: get(this.props.entity, 'preview.companies.length'),
                                },
                            ]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={60}
                            values={[
                                {
                                    label: t('common.label.activity'),
                                    value: this.props.entity.preview.activity
                                        ? t(
                                              'common.activity.' +
                                                  String(this.props.entity.preview.activity).toUpperCase(),
                                          )
                                        : 'non',
                                },
                            ]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Fonctions sensibles', value: (sensitiveFunctions)?sensitiveFunctions:'0' }]}
                        />
                    </div>
                </div>
                <div style={styles.row}>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: 'PRSC',
                                    value: prsc,
                                },
                            ]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: "Nombre d'entreprises PRSC",
                                    value: prscLength,
                                },
                            ]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}></div>
                </div>
                <div style={styles.row}>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: 'PPE Domestique',
                                    value:
                                        this.props.entity.files.filter(
                                            (file: FileAttachment) =>
                                                file.data.type === 'politic' &&
                                                ['local', 'national'].includes(file.data.level),
                                        ).length > 0
                                            ? 'oui'
                                            : 'non',
                                },
                            ]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: 'PPE International',
                                    value:
                                        this.props.entity.files.filter(
                                            (file: FileAttachment) =>
                                                file.data.type === 'politic' && file.data.level === 'international',
                                        ).length > 0
                                            ? 'oui'
                                            : 'non',
                                },
                            ]}
                        />
                    </div>
                    <div style={Object.assign({}, styles.cell, styles.cell3)}>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Sanctions', value: sanctions ? sanctions : 'non' }]}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function getName(preview: EntityPreview): string {
    let name = `${preview.firstName} ${preview.lastName}`;
    if (!name) {
        name = preview.name;
    }

    return name;
}

function getAdress(address?: EntityAddress): string {
    if (!address) {
        return '';
    }

    return (
        <div>
            {address.details}
            <br />
            {address.zipCode} {address.city}
            <br />
            {address.country}
        </div>
    );
}

function isResident(address?: EntityAddress, preview: EntityPreview): ?boolean {
    if (!address) {
        if (!preview.country) {
            return '';
        }
        if (preview.country && (preview.country.toLowerCase() === 'france' || preview.country.toLowerCase() === 'fr')) {
            return 'Oui';
        }
        return 'Non';
    }

    if (address.country.toLowerCase() === 'france' || address.country.toLowerCase() === 'fr') {
        return 'Oui';
    }

    return 'Non';
}

function getNorkomLvl(score: number): string {
    let norkomLevel = 'A';
    if (score >= 500) {
        norkomLevel = 'B';
    }
    if (score >= 1500) {
        norkomLevel = 'C';
    }

    return norkomLevel;
}

function getCiv(gender: string): string {
    let civ = '';
    if (gender === 'male') {
        civ = 'Mr';
    }
    if (gender === 'female') {
        civ = 'Mme';
    }

    return civ;
}
