import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

let styles = {
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
    },
    remove: {
        color: colors.userHot,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'teem.common.attendees.item';

    static contextTypes = {
        removeAttendee: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object,
        style: PropTypes.object,
        project: PropTypes.object.isRequired,
        edit: PropTypes.bool.isRequired,
    };

    render() {
        const cellProps = { style: styles.cell };
        console.log('-->', this.props.entity);

        return (
            <tr>
                <td {...cellProps}>{this.props.entity.firstName}</td>
                <td {...cellProps}>{this.props.entity.lastName}</td>
                <td {...cellProps}>{this.props.entity.email}</td>
                <td {...cellProps}>{this.props.entity.role || 'attendee'}</td>
                {this.props.edit ? (
                    <td {...cellProps}>
                        {this.props.entity.intervention ? (
                            <a
                                style={styles.remove}
                                onClick={() => this.remove()}
                            >
                                Remove
                            </a>
                        ) : null}
                    </td>
                ) : null}
            </tr>
        );
    }

    remove() {
        const name = [this.props.entity.firstName, this.props.entity.lastName].join(' ');

        if (confirm(`Are you sure you want to remove ${name} as project attendee?`)) {
            this.context.removeAttendee(this.props.project.id, this.props.entity.intervention);
        }
    }
}
