import Colors from 'app/theme/colors';
import InfoIcon from 'material-ui/svg-icons/action/info';
import IdeaIcon from 'material-ui/svg-icons/image/wb-incandescent';
import CommentIcon from 'material-ui/svg-icons/communication/comment';
import QuestionIcon from 'material-ui/svg-icons/action/help';
import AnswerIcon from 'material-ui/svg-icons/content/reply';
import DecisionIcon from 'material-ui/svg-icons/action/check-circle';
import AlertIcon from 'material-ui/svg-icons/content/report';

export default {
    info: {
        label: 'Information',
        color: Colors.grey500,
        icon: InfoIcon,
        children: [ 'comment', 'question', 'decision', 'alert' ]
    },
    idea: {
        label: 'Idea',
        color: Colors.grey500,
        icon: IdeaIcon,
        children: [ 'comment', 'question', 'decision', 'alert' ]
    },
    comment: {
        label: 'Comment',
        color: Colors.grey500,
        icon: null,
        children: [ 'comment', 'question', 'decision', 'alert' ]
    },
    question: {
        label: 'Question',
        color: Colors.grey500,
        icon: QuestionIcon,
        children: [ 'comment', 'answer', 'decision', 'alert' ]
    },
    answer: {
        label: 'Answer',
        color: Colors.grey500,
        icon: AnswerIcon,
        children: [ 'comment', 'question', 'decision', 'alert' ]
    },
    decision: {
        label: 'Decision',
        color: Colors.userCold,
        icon: DecisionIcon,
        children: [ 'comment', 'question', 'decision', 'alert' ]
    },
    alert: {
        label: 'Alert',
        color: Colors.userHot,
        icon: AlertIcon,
        children: [ 'comment', 'question', 'decision', 'alert' ]
    }
};
