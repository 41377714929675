import get from 'lodash/get';

export default async function (data: Object, services: Object): Promise<Object> {
    const baseGlobal = get(data, 'computed.scoring.rating', 5);
    const realGlobal = get(data, 'study.payload.rating', baseGlobal);
    const globalScore = Math.max(0, Math.min(5, realGlobal));

    const baseEsg = String.fromCharCode(64 + get(data, 'computed.scoringEsg.rating', 5));
    const esgScore = get(data, 'study.payload.header.esg', baseEsg).toUpperCase();

    return { globalScore, esgScore };
}
