import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import colors from 'app/theme/colors';
import t from 'app/translation';
import Icon from 'app/theme/icon';

const styles = {
    message: {
        padding: '0px',
        color: colors.userHot,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    container: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    item: {
        color: colors.grey500
    },
    hover: {
        background: colors.grey100,
        color: colors.grey700
    },
    link: {
        display: 'block',
        padding: '10px',
        color: 'inherit',
        textDecoration: 'none'
    },
    star: {
        display: 'inline-block',
        marginRight: '5px',
        color: colors.userHot,
    },
    icon: {
        display: 'inline-block',
        marginRight: '5px',
        verticalAlign: 'bottom'
    },
    title: {
        display: 'block',
        margin: '5px',
        color: colors.userCold,
        fontWeight: 'bold'
    },
    info: {
        display: 'block',
        margin: '5px',
        color: colors.grey900
    },
    description: {
        display: 'block',
        margin: '5px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.news.list';

    static propTypes = {
        items: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        style: PropTypes.object
    };

    constructor (props) {
        super(props);
        this.state = { hover: null };
    }

    render () {
        if (this.props.loading)
            return (
                <div style={ Object.assign({}, styles.message, this.props.style) }>
                    {t('common.message.loading')}
                </div>
            );

        if (! this.props.items.length)
            return (
                <div style={ Object.assign({}, styles.message, this.props.style) }>
                    {t('common.message.noFound')}
                </div>
            );

        return (
            <ul style={ Object.assign({}, styles.container, this.props.style) }
                onMouseLeave={ event => this.setState({ hover: null }) }
            >
                { this.props.items.map(this.renderItem.bind(this)) }
            </ul>
        );
    }

    renderItem (item: any, index: any) {
        const style = this.state.hover === index
            ? Object.assign({}, styles.item, styles.hover)
            : styles.item;

        return (
            <li key={ index }
                style={ style }
                onMouseEnter={ event => this.setState({ hover: index }) }
            >
                <a href={ item.url } target="_blank" style={ styles.link }>
                    <span style={ styles.title }>
                    { item.icon
                        ? this.renderIcon(item.icon)
                        : ['o', 'c'].includes(item.reliability) ? <span style={styles.star}>★</span> : null
                    }
                    { item.title }
                    </span>
                    <span style={ styles.info }>
                        { this.renderInfo(item) }
                    </span>

                    <span style={ styles.description } dangerouslySetInnerHTML={{ __html: item.desc}} />
                </a>
            </li>
        );
    }

    renderIcon (type: string) {
        if (! type) {
            return null;
        }

        return (
            <span style={styles.icon}><Icon style={styles.star} type={ type } /></span>
        );
    }

    renderInfo (item) {
        const values = [];

        if (item.date)
            values.push(Moment(item.date).format('dddd DD MMMM YYYY'));

        if (item.domain)
            values.push(item.domain);

        return values.join(' — ');
    }
}
