// @flow

import type { ReechEntity } from './types';
import type { GlobalStore } from '../core/types';

import { observable, action, toJS } from 'mobx';

export default class DeefindStore {
    global: GlobalStore;
    results: Map<string, Object[]>;
    errors: string[];

    @observable statuses: Map<string, 'loading' | 'error' | 'empty' | 'customer' | 'prospect' | 'known'> = new Map();
    @observable customers: string[] = [];
    @observable prospects: string[] = [];
    @observable known: string[] = [];

    constructor(global: GlobalStore) {
        this.global = global;
        this.results = new Map();
        this.errors = [];
    }

    @action matchEntity = async (entity: ReechEntity): Promise<Object[]> => {
        if (this.statuses.has(entity.id)) {
            return this.results.get(entity.id) || []; // already done
        }

        // if (! this.api.allow('reech')) {
        //     return Promise.resolve([]); // not allowed
        // }

        if (!entity.id) {
            console.error(`Tried to match deefind entity with no ID.`);
            return []; // invalid entity
        }

        this.statuses.set(entity.id, 'loading');
        const query = { id: entity.id, type: entity.type, profile: toJS(entity.preview) };

        try {
            const result = await this.global.execute('reech', 'find.one', { query });
            this.handleSuccess(entity.id, result);
            return result;
        } catch (error) {
            this.handleFailure(entity.id, error);
            return [];
        }
    };

    @action handleFailure = (id: string, error: Error): void => {
        this.errors.push(error.message);
        this.statuses.set(id, 'error');
    };

    @action handleSuccess = (id: string, results: Object[]): void => {
        this.results.set(id, results);

        if (!results.length) {
            this.statuses.set(id, 'empty');
        } else if (results.filter(result => result.kind === 'customer').length > 0) {
            this.statuses.set(id, 'customer');
            this.customers.push(id);
            this.known.push(id);
        } else if (results.filter(result => result.kind === 'prospect').length > 0) {
            this.statuses.set(id, 'prospect');
            this.prospects.push(id);
            this.known.push(id);
        } else {
            this.statuses.set(id, 'known');
            this.known.push(id);
        }
    };
}
