// @flow

export function concatArrays(list: Object[], keys: string[]): Object {
    const result = {};

    for (const key of keys) {
        result[key] = [];

        for (const item of list) {
            if (!item || !Array.isArray(item[key])) {
                continue;
            }

            result[key] = result[key].concat(item[key]);
        }
    }

    return result;
}

export function takeFirst(list: Object[], key: string): any {
    for (const item of list) {
        if (item && item[key]) {
            return item[key];
        }
    }

    return undefined;
}
