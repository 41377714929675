import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';

import Views from 'app/views';
import t from 'app/translation';

export default class extends React.Component<any, any> {

    static displayName = 'teem.common.milestone.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    open (extra) {
        this.refs.dialog.open(extra);
    }

    render () {
        return (
            <Views.Modal.Edition
                ref="dialog"
                model="comeet:milestones"
                data={ this.props.entity ? toJS(this.props.entity) : {payload: {}} }
                label={t('dna.comeet.saveMilestones')}
                onSubmit={ this.props.onSubmit }
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        size={ 2 }
                        label={t('dna.comeet.title')}
                        path="payload.title"
                        widget="string"
                        required
                    />

                    <Views.Form.Field
                        label={t('dna.comeet.date')}
                        path="payload.date"
                        widget="date"
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label={t('dna.comeet.checklist')}
                        path="payload.checklist"
                        widget={{
                            type: 'collection',
                            sort: true,
                            flags: ['uuid'],
                            fields: { label: 'string' }
                        }}
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label={t('dna.comeet.description')}
                        path="payload.description"
                        widget="text"
                    />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
