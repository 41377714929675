// @flow

import type {
    EntityInnerTarget,
    StockItem,
    StockChild,
    StockParent,
    StockLink,
    StockStats,
    StockValue,
    EntityEstimate,
} from '@deecision/deefind-types/model';

import omit from 'lodash/omit';

export function makeStockItem(link: StockLink, target: EntityInnerTarget): StockItem {
    link.total = makeStockTotal(link.value, target.valuation) || undefined;

    return Object.assign({}, omit(link, 'entity'), { target });
}

export function makeStockChild(
    link: StockLink,
    target: EntityInnerTarget,
    children?: StockChild[] | StockStats,
): StockChild {
    return Object.assign({}, makeStockItem(link, target), { children });
}

export function makeStockParent(
    link: StockLink,
    target: EntityInnerTarget,
    parents?: StockParent[] | StockStats,
): StockParent {
    return Object.assign({}, makeStockItem(link, target), { parents });
}

export function makeStockTotal(value: ?StockValue, valuation: ?EntityEstimate): ?StockValue {
    if (!valuation || !value) {
        return undefined;
    }

    return {
        min: ((valuation.value || 0) - (valuation.deviation || 0)) * ((value.min || 0) / 100),
        max: ((valuation.value || 0) + (valuation.deviation || 0)) * ((value.max || 100) / 100),
    };
}

export function makeStockChildren(
    children?: StockChild[] | StockStats,
): StockChild[] | StockStats | void {
    if (!Array.isArray(children)) {
        return children;
    }

    const current = [];

    for (const child of children) {
        if (child.ceased || (child.target && child.target.type === 'company' && child.target.closed)) {
            continue; // ceased
        }

        child.children = makeStockChildren(child.children);
        if (!child.children) delete child.children;
        current.push(child);
    }

    return current;
}

export function makeStockParents(
    parents?: StockParent[] | StockStats,
): StockParent[] | StockStats | void {
    if (!Array.isArray(parents)) {
        return parents;
    }

    const current = [];

    for (const parent of parents) {
        if (parent.ceased || (parent.target && parent.target.type === 'company' && parent.target.closed)) {
            continue; // ceased
        }

        parent.parents = makeStockParents(parent.parents);
        if (!parent.parents) delete parent.parents;
        current.push(parent);
    }

    return current;
}
