import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Preview from './preview';
import Summary from './summary';

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    header: {
        display: 'flex',
        margin: '20px 20px 15px 20px',
        alignItems: 'center',
    },
    headerTitle: {
        fontSize: '19px',
        lineHeight: '24px',
        flexGrow: '1',
        textAlign: 'center',
    },
    editor: {
        display: 'flex',
        margin: '0 15px',
        flexGrow: '1',
    },
    editorAside: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
    },
    editorBlock: {
        position: 'relative',
        flexGrow: '2',
        margin: '5px',
    },
    editorTitle: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        lineHeight: '30px',
        padding: '0 10px',
        background: colors.grey100,
        border: '1px solid ' + colors.grey400,
        color: colors.grey700,
        borderRadius: '3px 3px 0 0',
    },
    editorInput: {
        position: 'relative',
        width: '100%',
        height: '100%',
        flexGrow: '1',
        border: '1px solid ' + colors.grey400,
        borderRadius: '3px',
        padding: '40px 10px 10px 10px',
        fontSize: '17px',
        fontFamily: 'inherit',
        lineHeight: '23px',
    },
    editorInputFocus: {
        border: '1px solid ' + colors.userCold,
        borderColor: colors.userCold,
    },
    editorTitleFocus: {
        background: colors.userCold,
        color: 'white',
        border: '1px solid ' + colors.userCold,
    },
    actions: {
        margin: '15px 20px 20px 20px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.meetings.report';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
    };

    state = { inputs: {} };

    open(index?: number) {
        this.setState({ active: index || 0 }, () => this.refs['modal'].open());
    }

    render() {
        return (
            <Views.Modal.Feature
                ref="modal"
                name="Meeting report"
                icon={<Icon type="comeet.report" />}
                aside={[
                    <Preview entity={this.props.entity} />,
                    <Summary
                        steps={this.props.entity.payload.steps || []}
                        onSelect={(index: number) => this.setState({ active: index })}
                        active={this.state.active}
                    />,
                ]}
                control={[
                    <Views.Common.Button
                        type="round"
                        color="cold"
                        icon={<Icon type="do.submit" />}
                        onClick={() => this.handleSave()}
                    />,
                ]}
            >
                <div style={styles.content}>
                    {this.renderHeader()}
                    {this.renderEditor()}
                    {this.renderActions()}
                </div>
            </Views.Modal.Feature>
        );
    }

    handleSave() {
        const entity = this.props.entity;
        const inputs = this.state.inputs || {};

        for (const [index, step] of (entity.payload.steps || []).entries()) {
            for (const type of ['report', 'decisions', 'alerts']) {
                const key = `${type}_${index}`;
                if (inputs[key]) step[type] = inputs[key];
            }
        }

        this.props.onUpdate(entity);
        this.refs['modal'].close();
    }

    renderHeader() {
        const step = this.props.entity.payload.steps[this.state.active];

        return (
            <div style={styles.header}>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="nav.arrow.left" />}
                    onClick={() => this.setState({ active: this.state.active - 1 })}
                    disabled={this.state.active <= 0}
                />

                <div style={styles.headerTitle}>{step ? step.title : ''}</div>

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    icon={<Icon type="nav.arrow.right" />}
                    onClick={() => this.setState({ active: this.state.active + 1 })}
                    disabled={this.state.active >= this.props.entity.payload.steps.length - 1}
                />
            </div>
        );
    }

    renderEditor() {
        const step = this.props.entity.payload.steps[this.state.active] || {};

        return (
            <div style={styles.editor}>
                {this.renderEditorBlock('report', step.report || '', 'General debriefing')}

                <div style={styles.editorAside}>
                    {this.renderEditorBlock('decisions', step.decisions || '', 'Decisions taken')}
                    {this.renderEditorBlock('alerts', step.alerts || '', 'Alerts raised')}
                </div>
            </div>
        );
    }

    renderEditorBlock(type: string, value: string, title: string) {
        const key = `${type}_${this.state.active}`;
        const focus = this.state.focus === type;
        const inputs = this.state.inputs || {};

        return (
            <div style={styles.editorBlock}>
                <textarea
                    style={Object.assign({}, styles.editorInput, focus ? styles.editorInputFocus : {})}
                    value={inputs[key] || value}
                    onChange={event => {
                        const value = event.target.value;
                        this.setState({ inputs: Object.assign({}, inputs, { [key]: value }) });
                    }}
                    onFocus={() => this.setState({ focus: type })}
                    onBlur={() => this.setState({ focus: null })}
                />

                <div style={Object.assign({}, styles.editorTitle, focus ? styles.editorTitleFocus : {})}>{title}</div>
            </div>
        );
    }

    renderActions() {
        return (
            <div style={styles.actions}>
                <Views.Common.Button
                    type="flat"
                    color="hot"
                    icon={<Icon type="do.cancel" />}
                    label="Cancel"
                    onClick={() => {
                        this.setState({ inputs: {} }, () => this.refs['modal'].close());
                    }}
                />
                &nbsp;&nbsp;&nbsp;
                <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.submit" />}
                    label="Save changes"
                    onClick={() => this.handleSave()}
                />
            </div>
        );
    }
}
