// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Moment from 'moment';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import uuid from 'uuid';

const styles = {
    header: {
        marginLeft: '201px',
        textAlign: 'center',
        marginBottom: '20px',
    },
    monthName: {
        display: 'inline-block',
        width: '150px',
        fontSize: '19px',
        verticalAlign: 'middle',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies, contacts: stores.auth.contacts }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.calendar.workload.planning';

    static propTypes = {
        users: PropTypes.array.isRequired,
        data: PropTypes.object.isRequired,
        date: PropTypes.object.isRequired,
        status: PropTypes.string,
        onPrevious: PropTypes.func.isRequired,
        onNext: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    buildMoment() {
        return Moment(`${this.props.year}-${this.props.month}-1`, 'YYYY-M-D');
    }

    renderHeader() {
        return (
            <div style={{ marginLeft: '201px' }}>
                <div
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderTop: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '30px',
                        background: colors.bgCold,
                        color: colors.userCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>Monday</span>
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderTop: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '30px',
                        background: colors.bgCold,
                        color: colors.userCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>Tuesday</span>
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderTop: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '30px',
                        background: colors.bgCold,
                        color: colors.userCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>Wednesday</span>
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderTop: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '30px',
                        background: colors.bgCold,
                        color: colors.userCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>Thursday</span>
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderTop: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '30px',
                        background: colors.bgCold,
                        color: colors.userCold,
                        borderRight: '1px solid ' + colors.halfCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>Friday</span>
                </div>
            </div>
        );
    }

    renderWeekDays(moment) {
        const start = 1;
        return (
            <div key={moment.format('YYYY-MM-DD')} style={{ marginLeft: '201px' }}>
                <div
                    onClick={() => {
                        this.props.onClick(moment);
                    }}
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '20px',
                        background: colors.bgCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>{moment.date()}</span>
                </div>
                <div
                    onClick={() => {
                        this.props.onClick(Moment(moment).add(+1, 'days'));
                    }}
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '20px',
                        background: colors.bgCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>
                        {Moment(moment)
                            .add(+1, 'days')
                            .date()}
                    </span>
                </div>
                <div
                    onClick={() => {
                        this.props.onClick(Moment(moment).add(+2, 'days'));
                    }}
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '20px',
                        background: colors.bgCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>
                        {Moment(moment)
                            .add(+2, 'days')
                            .date()}
                    </span>
                </div>
                <div
                    onClick={() => {
                        this.props.onClick(Moment(moment).add(+3, 'days'));
                    }}
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '20px',
                        background: colors.bgCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>
                        {Moment(moment)
                            .add(+3, 'days')
                            .date()}
                    </span>
                </div>
                <div
                    onClick={() => {
                        this.props.onClick(Moment(moment).add(+4, 'days'));
                    }}
                    style={{
                        textAlign: 'right',
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderRight: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '20px',
                        background: colors.bgCold,
                    }}
                >
                    <span style={{ padding: '5px' }}>
                        {Moment(moment)
                            .add(+4, 'days')
                            .date()}
                    </span>
                </div>
            </div>
        );
    }

    renderEmpty() {
        return (
            <div>
                <div
                    style={{
                        color: colors.userCold,
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '200px',
                        height: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ padding: '10px' }} />
                </div>

                <div
                    style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ padding: '10px' }} />
                </div>
                <div
                    style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ padding: '10px' }} />
                </div>
                <div
                    style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ padding: '10px' }} />
                </div>
                <div
                    style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ padding: '10px' }} />
                </div>
                <div
                    style={{
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '35px',
                        textAlign: 'center',
                    }}
                >
                    <div style={{ padding: '10px' }} />
                </div>
            </div>
        );
    }

    renderDay(moment, user) {
        const badges = [];
        if (this.props.data[moment.format('YYYY-MM-DD')] && this.props.data[moment.format('YYYY-MM-DD')][user.id]) {
            for (const item of this.props.data[moment.format('YYYY-MM-DD')][user.id]) {
                const payload = (this.props.store.studies.get(item.study) || {}).payload || {};

                if (this.props.status && this.props.status !== 'all' && this.props.status !== item.status) {
                    continue;
                }

                badges.push(
                    this.renderBadge(
                        payload.manager,
                        item.status,
                        false,
                        false,
                        item.warning,
                    ),
                );
            }
        }

        return (
            <div
                key={user.id + '-' + moment.format('YYYY-MM-DD')}
                onClick={() => {
                    this.props.onClick(moment);
                }}
                style={Object.assign(
                    {},
                    {
                        verticalAlign: 'top',
                        display: 'inline-block',
                        borderLeft: '1px solid ' + colors.halfCold,
                        borderBottom: '1px solid ' + colors.halfCold,
                        width: '150px',
                        height: '35px',
                        textAlign: 'center',
                    },
                    this.props.date.format('YYYY-MM-DD') === moment.format('YYYY-MM-DD')
                        ? { background: colors.halfCold }
                        : {},
                )}
            >
                {badges.length > 4
                    ? badges.slice(0, 4).concat(
                          this.renderBadge(`+${badges.length - 4}`, '...', false, true, false),
                      )
                    : badges}
            </div>
        );
    }

    renderOccupation(moment) {
        const occupations = [];
        for (const user of this.props.users || []) {
            user.checked
                ? occupations.push(
                      <div key={moment.format('YYYY-MM-DD') + '-' + user.id}>
                          <div
                              style={{
                                  color: colors.userCold,
                                  verticalAlign: 'top',
                                  display: 'inline-block',
                                  borderBottom: '1px solid ' + colors.halfCold,
                                  width: '200px',
                                  height: '35px',
                                  textAlign: 'center',
                              }}
                          >
                              <div style={{ padding: '10px' }}>
                                  {user.firstName} {user.lastName}
                              </div>
                          </div>

                          {this.renderDay(Moment(moment), user)}
                          {this.renderDay(Moment(moment).add('1', 'days'), user)}
                          {this.renderDay(Moment(moment).add('2', 'days'), user)}
                          {this.renderDay(Moment(moment).add('3', 'days'), user)}
                          {this.renderDay(Moment(moment).add('4', 'days'), user)}
                      </div>,
                  )
                : null;
        }

        return (
            <div
                key={uuid.v4()}
                style={{
                    width: '955px',
                    borderTop: '1px solid ' + colors.halfCold,
                    borderLeft: '1px solid ' + colors.halfCold,
                    borderRight: '1px solid ' + colors.halfCold,
                }}
            >
                {occupations}
            </div>
        );
    }

    // renderWeek(moment) {
    //   return [this.renderWeekDays(moment), this.renderOccupation(moment)];
    // }

    renderBadge(name, label, status, more, warning) {
        return (
            <Views.Common.Tooltip key={uuid.v4()} content={name}>
                <div
                    style={{
                        color: 'white',
                        border: '1px solid white',
                        fontSize: '12px',
                        background: status
                            ? colors.userGreen
                            : more
                                ? colors.halfCold
                                : warning
                                    ? colors.userHot
                                    : colors.userCold,
                        display: 'inline-block',
                        padding: '3px',
                        marginTop: '6px',
                    }}
                >
                    {label.slice(0, 3).toUpperCase()}
                </div>
            </Views.Common.Tooltip>
        );
    }

    renderCalendar() {
        const weeks = [];

        let monday = this.buildMoment().startOf('month').day('monday');

        if (monday.date() > 7) {
            weeks.push(this.renderWeekDays(monday));
            weeks.push(this.renderOccupation(monday));
            monday.add(7, 'd');
        }

        while (monday.month() + 1 === this.props.month) {
            weeks.push(this.renderWeekDays(monday));
            weeks.push(this.renderOccupation(monday));
            monday.add(7, 'd');
        }

        return (
            <div>
                <div style={styles.header}>
                    <Views.Common.Button
                        type="flat"
                        icon={<Icon type="nav.arrow.left" />}
                        onClick={event => {
                            this.props.onPrevious();
                        }}
                    />

                    <span style={styles.monthName}>{this.buildMoment().format('MMMM YYYY')}</span>

                    <Views.Common.Button
                        type="flat"
                        icon={<Icon type="nav.arrow.right" />}
                        onClick={event => {
                            this.props.onNext();
                        }}
                    />
                </div>

                {this.renderHeader()}

                {weeks}
            </div>
        );
    }

    render() {
        if (!this.props.store) {
            return null;
        }

        if (!toJS(this.props.store.loaded)) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div>
                <div style={{ width: '958px', display: 'inline-block', verticalAlign: 'top' }}>
                    {this.renderCalendar()}
                </div>
            </div>
        );
    }
}
