import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.attendees.form';

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    open() {
        this.refs.dialog.open();
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                data={{}}
                label="Add attendee"
                onSubmit={this.props.onSubmit}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        label="Email address"
                        path="email"
                        widget={{ type: 'string', pattern: 'email' }}
                        required
                    />
                </Views.Form.Row>
                <Views.Form.Row>
                    <Views.Form.Field
                        label="First name"
                        path="firstName"
                        widget="string"
                        required
                    />
                    <Views.Form.Field
                        label="Last name"
                        path="lastName"
                        widget="string"
                        required
                    />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
