import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Scope from 'app/layout/providers/scope';
import Card from 'dna/entity/card';
import Clusters from 'dna/entity/common/display/clusters';
import get from 'lodash/get';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';
import Family from '../blocks/family';

@inject('reech')
@observer
export default class extends React.Component<any, any> {

    static displayName = 'dna.entity.person.display.tabs.relations';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
        const entities = this.props.network.relations.persons.map(item => Object.assign({}, item, {id: item.target.id}));
        const reechCustomers = this.props.reech.customers;
        const reechProspects = this.props.reech.prospects;
        const reechKnown = this.props.reech.known;
        const reechCount = reechKnown.length;
        return (
            <Scope path="persons" type="object">
                <div>
                    <Clusters list={get(this.props, 'network.clusters.persons')} />
                    <Scope path="list" type="list">
                        <Views.List.Container entities={entities}>

                            <Views.List.Frame
                                type="cards"
                                element={ <Card parent={ this.props.entity } /> }
                            />

                            <Views.List.Reducer
                                type="sort"
                                specs={{
                                    relation: {
                                        label: 'Rel. strength',
                                        path: (item) => item.strength || 0,
                                        type: 'desc'
                                    },
                                    name: {
                                        label: t('common.label.lastname'),
                                        path: 'target.name',
                                        type: 'asc'
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="current"
                                title="Current"
                                type="groups"
                                value="yes"
                                specs={{
                                    yes: {
                                        label: '✓',
                                        info: 'Current relations',
                                        filter: (item) => !item.ceased
                                    },
                                    no: {
                                        label: '✕',
                                        info: 'Ceased relations',
                                        filter: (item) => !!item.ceased
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="reech"
                                type="groups"
                                title="Reech"
                                specs={{
                                    any: {
                                        label: '✓',
                                        info: 'Any Reech match',
                                        filter: item => reechKnown.includes(item.id),
                                    },
                                    customer: {
                                        label: 'C',
                                        info: 'Customer match',
                                        filter: item => reechCustomers.includes(item.id),
                                    },
                                    prospect: {
                                        label: 'P',
                                        info: 'Prospect match',
                                        filter: item => reechProspects.includes(item.id),
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="mandate"
                                type="groups"
                                title="Relation type"
                                specs={{
                                    sha: {
                                        label: 'Sha',
                                        info: 'Shareholders',
                                        filter: (item) => item.level === 's' || item.level === 'i'
                                    },
                                    boa: {
                                        label: 'Boa',
                                        info: 'Board members',
                                        filter: (item) => item.level === 'b'
                                    },
                                    adv: {
                                        label: 'Adv',
                                        info: 'Advisors',
                                        filter: (item) => item.level === 'a'
                                    },
                                    cou: {
                                        label: 'Cou',
                                        info: 'Counselor',
                                        filter: (item) => item.level === 'c'
                                    },
                                    exe: {
                                        label: 'Exe',
                                        info: 'Executive',
                                        filter: (item) => item.level === 'x'
                                    },
                                    man: {
                                        label: 'Man',
                                        info: 'Managers',
                                        filter: (item) => item.level === 'm'
                                    },
                                    fam: {
                                        label: 'Fam',
                                        info: 'Family',
                                        filter: (item) => item.family === 1,
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="compliance"
                                title={t('common.label.compliance')}
                                type="groups"
                                specs={{
                                    fortune: {
                                        label: 'For',
                                        info: 'Ranked fortunes',
                                        filter: item => item.target.flags.includes('fortune')
                                    },
                                    justice: {
                                        label: 'Jus',
                                        info: 'Justice issues',
                                        filter: item => item.target.flags.includes('justice')
                                    },
                                    politic: {
                                        label: 'Pep',
                                        info: 'Politically exposed',
                                        filter: item => item.target.flags.includes('politic')
                                    },
                                    c19: {
                                        label: 'c19',
                                        info: 'Sensitivity to covid19 crisis',
                                        filter: item => (item.target.flags || []).includes('crisis-impact') || (item.target.flags || []).includes('crisis-direct'),
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                type="fuse"
                                specs={{ paths: [ 'target.name' ] }}
                            />
                        </Views.List.Container>
                    </Scope>
                    {(get(this.props.state,'persons.list.reducers.mandate', '') === "fam")?<Family entity={this.props.entity}></Family>:''}
                </div>
            </Scope>
        )
    }
}
