// @flow

import React from 'react';
import PropTypes from 'prop-types';
import t from 'app/translation';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import { inject } from 'mobx-react';

@inject('global')
export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.members.create';

    static propTypes = {
        global: PropTypes.object.isRequired,
        team: PropTypes.string.isRequired,
    };

    render() {
        return [
            <Views.Common.Button
                key="button"
                type="raised"
                color="cold"
                icon={<Icon type="do.create" />}
                label="Add member"
                onClick={() => this.refs['dialog'].open()}
            />,

            <Views.Modal.Edition
                key="dialog"
                ref="dialog"
                label="Add member"
                style={{ width: '650px' }}
                onSubmit={async (data: Object) => {
                    this.props.global.execute('cleerance', 'team.include', {
                        team: this.props.team,
                        level: data.level,
                        user: {
                            email: data.email,
                            firstName: data.firstName,
                            lastName: data.lastName,
                        },
                    });
                }}
            >
                <Views.Form.Row>
                    <Views.Form.Field label="First name" path="firstName" widget="string" required />
                    <Views.Form.Field label="Last name" path="lastName" widget="string" required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Email address"
                        path="email"
                        widget={{ type: 'string', pattern: 'email' }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Level"
                        path="level"
                        widget={{
                            type: 'radios',
                            choices: {
                                director: t(`cleerance.admin.levels.director`),
                                manager: t(`cleerance.admin.levels.manager`),
                                member: t(`cleerance.admin.levels.member`),
                                observer: t(`cleerance.admin.levels.observer`),
                            },
                        }}
                    />
                </Views.Form.Row>
            </Views.Modal.Edition>,
        ];
    }
}
