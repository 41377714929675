// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Moment from 'moment';
import Search from '../common/search';
import compute from './compute';
import Fuse from 'fuse.js';
import { COUNTRIES } from '../../studies/common/survey/field/widget/scalar/country';
import Checklist from '../workload/checklist';
import get from 'lodash/get';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    bar: { display: 'inline-block', verticalAlign: 'top', height: '20px', background: colors.userCold },
    barPrevious: {
        background: 'inherit',
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderRight: '9px solid ' + colors.userCold,
    },
    barNext: {
        background: 'inherit',
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '9px solid ' + colors.userCold,
    },
    monthContainer: {
        height: '90px',
        borderLeft: '1px solid ' + colors.grey300,
        display: 'inline-block',
        verticalAlign: 'top',
        width: '208px',
    },
    monthPart: { marginTop: '35px', display: 'inline-block', verticalAlign: 'top', width: '52px', height: '10px' },
};

@inject(stores => ({
    store: stores.deeligenzStudies,
    teamMates: stores.auth.teamMates,
    contacts: stores.auth.contacts,
    session: stores.global.session,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.calendar.project';

    static propTypes = {
        store: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        teamMates: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            date: Moment(),
            teamMates: this.props.teamMates
                .map(item => Object.assign({}, toJS(item.user), { checked: true }))
                .map(item => {
                    return { id: item.id, checked: item.checked, label: `${item.firstName} ${item.lastName}` };
                }),
        };
    }

    renderBar(status, date) {
        switch (status) {
            case 'previous':
                return (
                    <div>
                        <div style={Object.assign({}, styles.bar, styles.barPrevious)} />
                        <div style={Object.assign({}, styles.bar, { width: '43px' })}>
                            <span style={{ color: 'white', lineHeight: '20px', fontSize: '10px' }}>
                                {date.format('DD MMM')}
                            </span>
                        </div>
                    </div>
                );
                break;
            case 'begin':
                return (
                    <div>
                        <div
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                                width: '52px',
                                height: '20px',
                                background: colors.userCold,
                            }}
                        >
                            <span style={{ color: 'white', lineHeight: '20px', fontSize: '10px', paddingLeft: '5px' }}>
                                {date.format('DD MMM')}
                            </span>
                        </div>
                    </div>
                );
                break;
            case 'end':
                return (
                    <div>
                        <div
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'top',
                                width: '52px',
                                height: '20px',
                                background: colors.userCold,
                                textAlign: 'right',
                            }}
                        >
                            <span style={{ paddingRight: '5px', color: 'white', lineHeight: '20px', fontSize: '10px' }}>
                                {date.format('DD MMM')}
                            </span>
                        </div>
                    </div>
                );
                break;
            case 'full':
                return <div style={{ width: '100%', height: '20px', background: colors.userCold }} />;
                break;
            case 'empty':
                return <div style={{ width: '100%', height: '20px', background: 'inherit' }} />;
                break;
            case 'next':
                return (
                    <div>
                        <div style={Object.assign({}, styles.bar, { width: '43px' })}>
                            <span style={{ color: 'white', lineHeight: '20px', fontSize: '10px' }}>
                                {date.format('DD MMM')}
                            </span>
                        </div>
                        <div style={Object.assign({}, styles.bar, styles.barNext)} />
                    </div>
                );
                break;
        }
    }

    renderBadge(label, status, warning) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    color: 'white',
                    border: '1px solid white',
                    fontSize: '12px',
                    width: '150px',
                    background: status ? colors.userCold : warning ? colors.userHot : colors.grey400,
                    display: 'inline-block',
                    padding: '5px',
                }}
            >
                {label.toUpperCase()}
            </div>
        );
    }

    renderFilter(studies, filtered) {
        const session = this.props.session.get();

        return (
            <div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
                    <Views.Common.Button
                        onClick={() => {
                            this.refs.modal.open();
                        }}
                        color="cold"
                        icon={<Icon type="main.account" />}
                        label="MEMBERS"
                    />
                    <Views.Modal.Show ref="modal" icon={<Icon type="base.attendee" />} title={'Members'}>
                        <div>
                            <Checklist
                                items={this.state.teamMates}
                                onChange={teamMates => {
                                    this.setState({ teamMates });
                                }}
                            />
                        </div>
                    </Views.Modal.Show>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
                    <a
                        style={{
                            flexGrow: 1,
                            position: 'relative',
                            cursor: 'pointer',
                            height: '30px',
                            lineHeight: '30px',
                            padding: '5px 10px',
                            background: 'rgb(245, 245, 245)',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <span style={{ display: 'inline-block', color: 'rgb(189, 189, 189)', marginLeft: '10px' }}>
                            {filtered.length}/{studies.length}
                        </span>
                    </a>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                    <a
                        style={{
                            flexGrow: 1,
                            position: 'relative',
                            cursor: 'pointer',
                            height: '30px',
                            lineHeight: '30px',
                            padding: '5px 10px',
                            background: 'rgb(245, 245, 245)',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <span style={{ display: 'inline-block', color: 'rgb(189, 189, 189)', marginLeft: '10px' }}>
                            MANAGER
                        </span>
                    </a>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
                    <Views.Common.Toggle
                        key="status"
                        value={this.state.manager}
                        items={[
                            { value: 'down', label: <Icon type="nav.arrow.down" /> },
                            { value: 'up', label: <Icon type="nav.arrow.up" /> },
                        ]}
                        onChange={toggle => {
                            this.setState({ manager: toggle });
                        }}
                    />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                    <a
                        style={{
                            flexGrow: 1,
                            position: 'relative',
                            cursor: 'pointer',
                            height: '30px',
                            lineHeight: '30px',
                            padding: '5px 10px',
                            background: 'rgb(245, 245, 245)',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <span style={{ display: 'inline-block', color: 'rgb(189, 189, 189)', marginLeft: '10px' }}>
                            AUM
                        </span>
                    </a>
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
                    <Views.Common.Toggle
                        key="status"
                        value={this.state.aum}
                        items={[
                            { value: 'down', label: <Icon type="nav.arrow.down" /> },
                            { value: 'up', label: <Icon type="nav.arrow.up" /> },
                        ]}
                        onChange={toggle => {
                            this.setState({ aum: toggle });
                        }}
                    />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
                    <Views.Common.Toggle
                        key="status"
                        value={this.state.owner}
                        items={[
                            {
                                value: 'owner',
                                label: 'My DDs',
                                count: studies.filter(item => item.ownerUid === session.account).length,
                            },
                        ]}
                        onChange={toggle => {
                            this.setState({ owner: toggle });
                        }}
                    />
                </div>
                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>
                    <Views.Common.Toggle
                        title="ONGOING"
                        key="status"
                        value={this.state.status || 'all'}
                        items={[
                            { value: 'all', label: 'All', count: studies.length },
                            {
                                value: 'draft',
                                label: 'Draft',
                                count: studies.filter(item => item.status === 'draft').length,
                            },
                            {
                                value: 'input',
                                label: 'Input',
                                count: studies.filter(item => item.status === 'input').length,
                            },
                            { value: 'ddq', label: 'Analysis', count: studies.filter(item => item.status === 'ddq').length },
                            {
                                value: 'onsite',
                                label: 'Meeting',
                                count: studies.filter(item => item.status === 'onSite').length,
                            },
                            {
                                value: 'report',
                                label: 'Report',
                                count: studies.filter(item => item.status === 'report').length,
                            },
                            {
                                label: 'Validated',
                                value: 'confirmed',
                                count: studies.filter(item => item.status === 'confirmed').length,
                            }
                        ]}
                        onChange={toggle => {
                            this.setState({ status: toggle });
                        }}
                    />
                </div>
                <div style={{ display: 'block' }}>
                    <Search
                        value={this.state.search}
                        onChange={e => {
                            this.setState({ search: e });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderHeader() {
        return (
            <div style={{ borderBottom: '1px solid ' + colors.grey300 }}>
                <div
                    style={{
                        marginLeft: '564px',
                        display: 'inline-block',
                        verticalAlign: 'top',
                        height: '60px',
                        width: '836px',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            lineHeight: '60px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: '209px',
                        }}
                    >
                        <Views.Common.Button
                            type="flat"
                            icon={<Icon type="nav.arrow.left" />}
                            onClick={event => {
                                this.setState({ date: Moment(this.state.date).add(-1, 'months') });
                            }}
                            style={{ marginRight: '40px' }}
                        />

                        {this.state.date.format('MMM YY')}
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            lineHeight: '60px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: '209px',
                        }}
                    >
                        {Moment(this.state.date)
                            .add('1', 'months')
                            .format('MMM YY')}
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            lineHeight: '60px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: '209px',
                        }}
                    >
                        {Moment(this.state.date)
                            .add('2', 'months')
                            .format('MMM YY')}
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            lineHeight: '60px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                            width: '209px',
                        }}
                    >
                        {Moment(this.state.date)
                            .add('3', 'months')
                            .format('MMM YY')}

                        <Views.Common.Button
                            type="flat"
                            icon={<Icon type="nav.arrow.right" />}
                            onClick={event => {
                                this.setState({ date: Moment(this.state.date).add(1, 'months') });
                            }}
                            style={{ marginLeft: '40px' }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderMonthSequence(studyDate, date, first, end) {
        if (studyDate.begin >= date && studyDate.begin < Moment(date).add(7, 'days')) {
            if (end) {
                return <div style={styles.monthPart}>{this.renderBar('next', studyDate.begin)}</div>;
            }

            return <div style={styles.monthPart}>{this.renderBar('begin', studyDate.begin)}</div>;
        }

        if (studyDate.end >= date && studyDate.end < Moment(date).add(7, 'days')) {
            if (first) {
                return <div style={styles.monthPart}>{this.renderBar('previous', studyDate.end)}</div>;
            }

            return <div style={styles.monthPart}>{this.renderBar('end', studyDate.end)}</div>;
        }

        if (studyDate.begin < date && studyDate.end > date) {
            if (first) {
                return <div style={styles.monthPart}>{this.renderBar('previous', studyDate.begin)}</div>;
            }

            if (end) {
                return <div style={styles.monthPart}>{this.renderBar('next', studyDate.end)}</div>;
            }
            return <div style={styles.monthPart}>{this.renderBar('full')}</div>;
        }

        return <div style={styles.monthPart}>{this.renderBar('empty')}</div>;
    }

    renderMonth(studyDate, date, first, end) {
        if (!studyDate) {
            return (<div style={styles.monthContainer}></div>);
        }

        return (
            <div style={styles.monthContainer}>
                {this.renderMonthSequence(studyDate, date, first)}
                {this.renderMonthSequence(studyDate, Moment(date).add(7, 'days'))}
                {this.renderMonthSequence(studyDate, Moment(date).add(14, 'days'))}
                {this.renderMonthSequence(studyDate, Moment(date).add(21, 'days'), false, end)}
            </div>
        );
    }

    renderOwner(owner, payload) {
        return (
            <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                <Icon
                    type="base.attendee"
                    style={{ fill: colors.userCold, marginRight: '5px', width: '18px', height: '18px' }}
                />

                <span style={{ lineHeight: '18px' }}>{owner.firstName + ' ' + owner.lastName}</span>

                {payload.expectedAum ? (
                    <span
                        style={{
                            lineHeight: '18px',
                            marginLeft: '15px',
                            color: colors.grey500,
                        }}
                    >
                        Exp. AUM&nbsp;&nbsp;{payload.expectedAum}&nbsp;M€
                    </span>
                ) : null}
            </div>
        );
    }

    formatStatus(status, fields) {
        switch (status) {
            case 'draft':
            case 'input':
            case 'onSite':
            case 'report':
                return status;
            case 'ddq':
                return 'Analysis';

            case 'approved':
                return 'Awaiting Second Validation';

            case 'confirmed':
                return (fields || []).filter(id => get(this.props.store.fields.get(id), 'answerPayload.flags.redFlag')).length ? 'Awaiting Director Validation' : 'Validated';
            case 'ignoredRedFlag':
                return 'Validated';

            case 'rejected':
                return 'Rejected';

            case 'vetoed':
                return 'Veto';
        }

        return 'Unknown';
    }

    renderDetail(type, payload) {
        if (type === 'fund') {
            return (
                <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                    <Icon
                        type="deeligenz.company"
                        style={{ fill: colors.userCold, marginRight: '5px', width: '18px', height: '18px' }}
                    />

                    <span title={payload.manager} style={{ lineHeight: '18px' }}>
                        {payload.manager}
                    </span>
                </div>
            );
        }

        return (
            <div style={{ display: 'flex', marginTop: '5px', color: colors.grey800 }}>
                <img
                    style={{ height: '12px', width: '16px', float: 'left', margin: '3px 6px 0 0' }}
                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${(
                        payload.country || ''
                    ).toLowerCase()}.svg`}
                />
                {COUNTRIES[payload.country] || ''}
            </div>
        );
    }

    render() {
        const session = this.props.session.get();

        if (!this.props.store) {
            return null;
        }

        if (!toJS(this.props.store.loaded)) {
            return <Views.Common.Loader title="Loading data" />;
        }

        const studies = Object.values(toJS(this.props.store.studies)).filter(e => !e.payload.archive);
        let filtered = studies.filter(item => {
            if (!!this.state.owner === true && item.ownerUid !== session.account) {
                return false;
            }

            if (
                !this.state.teamMates
                    .filter(item => item.checked)
                    .map(item => item.id)
                    .includes(item.ownerUid)
            ) {
                return false;
            }

            if (!this.state.status || this.state.status === 'all') {
                return true;
            }

            return this.state.status === item.status;
        });
        if (this.state.search)
            filtered = new Fuse(filtered, {
                findAllMatches: true,
                threshold: 0.6,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ['payload.manager', 'payload.fund'],
            }).search(this.state.search);

        if (this.state.aum === 'down') {
            filtered.sort((a, b) => a.payload.expectedAum - b.payload.expectedAum);
        }

        if (this.state.aum === 'up') {
            filtered.sort((a, b) => b.payload.expectedAum - a.payload.expectedAum);
        }

        if (this.state.manager === 'down') {
            filtered.sort((a, b) => String(a.payload.fund || a.payload.manager).toLowerCase() < String(b.payload.fund || b.payload.manager).toLowerCase() ? 1 : -1);
        }

        if (this.state.manager === 'up') {
            filtered.sort((a, b) => String(b.payload.fund || b.payload.manager).toLowerCase() < String(a.payload.fund || a.payload.manager).toLowerCase() ? 1 : -1);
        }

        const studiesDates = compute(
            studies.filter(item => (!this.state.toggle ? true : item.status === this.state.toggle)),
        );

        let list = [];

        return (
            <div style={this.props.style}>
                <div style={{ width: '1400px' }}>
                    {this.renderFilter(studies, filtered)}
                    <div id="planning">
                        {this.renderHeader()}

                        {filtered.map(study => (
                            <div key={study.id} style={{ borderBottom: '1px solid ' + colors.grey300 }}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        height: '70px',
                                        width: '382px',
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    {/* <div style={{paddingTop: '10px', paddingLeft: '10px'}}><Icon style={{color: colors.userCold}} type="dna.company" /> <span style={{ verticalAlign: 'super'}}>{study.payload.manager} / Exp. AUM {study.payload.expectedAum}M€</span></div>
                  <div style={{paddingLeft: '10px'}}><Icon style={{color: colors.userCold}} type="main.account" /> <span style={{ verticalAlign: 'super'}}>{this.props.contacts.users[study.ownerUid].firstName} {this.props.contacts.users[study.ownerUid].lastName}</span></div> */}

                                    <Views.Common.Header
                                        light
                                        icon={
                                            <Icon
                                                type={
                                                    study.payload.target.type === 'fund'
                                                        ? 'deeligenz.fund'
                                                        : 'deeligenz.company'
                                                }
                                            />
                                        }
                                        title={
                                            study.payload.target.type === 'fund'
                                                ? study.payload.fund
                                                : study.payload.manager
                                        }
                                        content={[
                                            this.renderDetail(study.payload.target.type, study.payload),
                                            this.renderOwner(
                                                this.props.contacts.users[study.ownerUid] || {},
                                                study.payload,
                                            ),
                                        ]}
                                        // aside={[<Rank value={10} />]}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        height: '90px',
                                        width: '182',
                                    }}
                                >
                                    {/*
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                padding: '10px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    verticalAlign: 'bottom',
                                                    marginRight: '10px',
                                                    display: 'inline-block',
                                                    borderRadius: '50%',
                                                    background: colors.userCold,
                                                    padding: '5px',
                                                }}
                                            >
                                                <Icon
                                                    style={{ width: '20px', height: '20px', color: 'white' }}
                                                    type="do.open"
                                                />
                                            </div>
                                        </div>
                                    */}
                                    {/* <div style={{ marginTop: '20px', display: 'inline-block', verticalAlign: 'top', padding: '10px'}}>
                    <div style={{ verticalAlign: 'initial', display: 'inline-block', marginRight: '10px',color: colors.userGreen, border: '2px solid '+colors.userGreen, width: '26px', height: '26px'}}>
                      <div style={{verticalAlign: 'middle', textAlign: 'center', lineHeight: '26px'}}>5</div>
                    </div>
                  </div> */}
                                    <div
                                        style={{
                                            lineHeight: '19px',
                                            marginTop: '20px',
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            padding: '10px',
                                        }}
                                    >
                                        {console.log(study)}
                                        {this.renderBadge(this.formatStatus(study.status, study.fields), !!studiesDates[study.id])}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        height: '90px',
                                        width: '836px',
                                    }}
                                >
                                    {this.renderMonth(studiesDates[study.id], Moment(this.state.date).dates(1), true)}
                                    {this.renderMonth(
                                        studiesDates[study.id],
                                        Moment(this.state.date)
                                            .add(1, 'months')
                                            .dates(1),
                                    )}
                                    {this.renderMonth(
                                        studiesDates[study.id],
                                        Moment(this.state.date)
                                            .add(2, 'months')
                                            .dates(1),
                                    )}
                                    {this.renderMonth(
                                        studiesDates[study.id],
                                        Moment(this.state.date)
                                            .add(3, 'months')
                                            .dates(1),
                                        false,
                                        true,
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
