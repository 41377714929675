import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import t from 'app/translation';
import DeefindProvider from 'app/layout/providers/deefind';
import { get } from 'lodash';
import Sum from 'dna/entity/common/display/sum';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.block';

    static propTypes = {
        entity: PropTypes.object
    };

    render () {
        return (
            <DeefindProvider
                state={this.props.state}
                type="deals"
                id={this.props.entity.id}
                depth={1}
                mini
                renderEntity={(deals, state) => (
                    <Views.Common.Properties values={ this.getValues(deals) } align={ 100 } />
                )}
            />
        );
    }
    getValues (deals) {
        return [
            { label: t('dna.deefind.veegilenzData.person.sumSalesLabel'), tooltip: t('common.tooltip.salesSum'), value: <Sum data={ get(deals, 'sums.sales') } /> },
            { label: t('dna.deefind.veegilenzData.person.sumPurchasesLabel'), tooltip: t('common.tooltip.purchasesSum'), value: <Sum data={ get(deals, 'sums.purchases') } /> },
            { label: t('dna.deefind.veegilenzData.person.sumTransactionsLabel'), tooltip: t('common.tooltip.transactionsSum'), value: <Sum data={ get(deals, 'sums.transactions') } /> },
        ];
    }
}
