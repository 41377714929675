import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.model.data';

    static propTypes = {
        name: PropTypes.string.isRequired,
        values: PropTypes.object.isRequired
    };

    render () {
        throw new Error(`Model component must not be rendered.`);
    }
}
