import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import defaultsDeep from 'lodash/defaultsDeep';
import Target from './target';
import get from 'lodash/get';
import Country from '../survey/field/widget/scalar/country';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.invitation';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {  };
    }

    open(extra) {
        this.refs.dialog.open(extra);
    }

    unlock() {
        this.refs.dialog.unlock();
    }

    render() {
        const payload = {};

        return (
            <Views.Modal.Edition
                ref="dialog"
                data={payload}
                label="Save"
                onSubmit={this.props.onSubmit}
            >
                <Views.Form.Row title="Due diligence contact">
                    <Views.Form.Field label="Name" path="name" widget="string" required />

                    <Views.Form.Field
                        label="Email"
                        path="email"
                        widget={{ type: 'string', pattern: 'email' }}
                        required
                    />

                    <Views.Form.Field label="Phone" path="phone" widget="string" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
