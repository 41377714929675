// @flow

import type { SdkService, ApiError, ApiProgress } from '@deecision/infra-types/client';
import type { DataStore, StateStore } from './types';
import type { IObservableValue } from 'mobx';

import { observable, action } from 'mobx';
import Data from './data';

export default function(sdk: SdkService): StateStore {
    const data: DataStore<any> = Data();

    return {
        watch(service: string, path: string): Function {
            const key = [service, path].join(':');
            data.watch(key, () => sdk.state.watch(service, path));

            return () => data.end(key);
        },

        get(service: string, path: string): IObservableValue<any> {
            return data.getData([service, path].join(':'));
        },

        set(service: string, path: string, value: any): Promise<void> {
            return sdk.state.store(service, path, value);
        },
    };
}
