// @flow

import type ReechStore from 'app/stores/reech';
import type { GlobalStore } from '../core/types';
import type { EntityPreview, StockDirection } from '@deecision/deefind-types/model';
import type { ApiProgress, MakeFollow } from '@deecision/infra-types/client';
import type { CompilerTypologyItem } from '@deecision/deefind-types/server/engine/compiler';

import { observable, action, toJS, runInAction } from 'mobx';
import download from './download';

export default class DeefindStore {
    global: GlobalStore;
    reech: ReechStore;

    @observable previews: Map<string, ?Object> = new Map();
    @observable results: Map<string, ?Object> = new Map();
    @observable progress: Map<string, ?Object> = new Map();
    @observable errors: Map<string, ?(string[])> = new Map();
    @observable depths: Map<string, number> = new Map();
    @observable times: Map<string, number> = new Map();
    @observable status: Object = {};

    constructor(global: GlobalStore, reech: ReechStore) {
        this.global = global;
        this.reech = reech;
    }

    async searchName(type: string, name: string, follow?: MakeFollow): Promise<EntityPreview[]> {
        return this.global.execute('deefind', 'search.name', { type, name }, follow);
    };

    async searchTypology(provider: number, version?: number, follow?: MakeFollow): Promise<CompilerTypologyItem[]> {
        return this.global.execute('deefind', 'search.typology', { provider, version }, follow);
    };

    async searchIdentifier(type: string, identifier: string, follow?: MakeFollow): Promise<EntityPreview[]> {
        return this.global.execute('deefind', 'search.identifier', { type, identifier }, follow);
    };

    async fetch(scope: string, id: string, depth?: number = 1, direction?: StockDirection, follow?: MakeFollow ): Promise<void> {
        const key = `${scope}:${id}`;
        const key2 = scope === 'network' ? `${scope}${depth}:${id}` : key;
        const query: Object = { start: new Date().getTime() / 1000 };

        if (depth <= (this.depths.get(key) || 0)) {
            return;
        }

        if (this.progress.has(key2)) {
            return;
        }

        const start = Math.round(new Date().getTime() / 1000);

        const follow2: MakeFollow = action((progress: ApiProgress) => {
            this.progress.set(key2, progress);

            if (follow) {
                follow(progress);
            }
        });

        let result: Object;

        try {
            switch (scope) {
                case 'entity':
                    result = await this.global.execute('deefind', 'fetch.entity', { id }, follow2);
                    break;

                case 'stocks':
                    result = await this.global.execute('deefind', 'fetch.stocks', { id, direction }, follow2);
                    break;

                case 'network':
                    result = await this.global.execute('deefind', 'fetch.network', { id, depth }, follow2);
                    break;

                case 'deals':
                    result = await this.global.execute('deefind', 'fetch.deals', { id }, follow2);
                    break;

                case 'dealsCompany':
                    result = await this.global.execute('deefind', 'fetch.deals', { id, depth }, follow2);
                    break;

                case 'media':
                    result = await this.global.execute('deefind', 'fetch.media', { id }, follow2);
                    break;

                case 'family':
                    result = await this.global.execute('deefind', 'fetch.family', { id, depth }, follow2);
                    break;

                case 'accounting':
                    result = await this.global.execute('deefind', 'fetch.accounting', { id }, follow2);
                    break;

                default:
                    console.error(`Tried to fetch invalid deefind "${scope}" scope.`);
                    return;
            }
        } catch(error) {
            runInAction(() => {
                this.errors.set(key, error);
            })
        }

        if (result) {
            runInAction(() => {
                this.times.set(key2, Math.round(new Date().getTime() / 1000) - start);
                this.errors.set(key, null);
                this.results.set(key, result);
                this.progress.set(key2, null);
                this.depths.set(key, result.depth || 1);

                if (scope.startsWith('network') || scope.startsWith('stocks')) {
                    const entity = toJS(this.results.get(`entity:${id}`));

                    if (entity) {
                        if (result.preview) entity.preview = result.preview;
                        if (result.vigilance) entity.vigilance = result.vigilance;
                        this.results.set(`entity:${id}`, entity);
                    }
                }

                if (query.id) {
                    const duration = Math.round((new Date().getTime() / 1000) - query.start);
                    this.global.execute('deefind', 'report.query', { id: query.id, duration });
                }
            });
        }
    };

    provideScope(scope: string, id: string, depth?: number = 1, direction?: StockDirection): [string, string] {
        const key = `${scope}:${id}`;
        const key2 = scope === 'network' ? `${scope}${depth}:${id}` : key;
        const entity = this.results.get(`entity:${id}`);

        if (scope === 'network' && this.progress.has(`stocks:${id}`) && !this.results.has(`stocks:${id}`)) {
            // asking for network while loading stocks
            return [key, key2];
        }

        if (scope === 'media' && !this.results.has(`network:${id}`)) {
            return [key, key2];
        }

        if (this.results.has(key) && scope === 'network') {
            const network = this.results.get(`network:${id}`);
            depth = entity && entity.type === 'person' ? 2 : 1;

            if (network && network.depth < depth) {
                this.fetch(scope, id, depth);
            }
        }

        if (!this.results.has(key)) {
            this.fetch(scope, id, depth, direction);
        }

        runInAction(() => {
            if (!this.errors.has(key)) this.errors.set(key, null);
            if (!this.progress.has(key2)) this.progress.set(key2, null);
            if (!this.times.has(key2)) this.times.set(key, 0);
        });

        return [key, key2];
    };

    provideList(ids: string[]): Object {
        const list = observable([]);

        for (const id of ids) {
            list.push(this.results.get(`entity:${id}`) || this.previews.get(id));
        }

        return list;
    };

    provideDisplay(id: string): ?Object {
        const entity = this.results.get(`entity:${id}`);

        if (!entity) {
            this.provideScope('entity', id);
            return null;
        }

        if (!this.results.has(`stocks:${id}`)) {
            this.provideScope('stocks', id);
            return entity;
        }

        if (!this.results.has(`network:${id}`)) {
            this.provideScope('network', id);
            return entity;
        }

        return entity;
    };

    async download(id: string): Promise<void> {
        const network = this.results.get(`network:${id}`) || { relations: {} };
        const relations = (network.relations.companies || []).concat(network.relations.persons || []);
        const ids = relations.map(relation => relation.target ? relation.target.id : undefined).filter(v => !!v);
        await Promise.all(ids.map(id => this.fetch('entity', id)));

        await download(this.results, id, this.reech);
    };
}
