// flow

import type {
    CompanyData,
    CompanyPreview,
    CompanyIdentity,
    CompanyActivity,
    CompanyIdentifier,
    CompanyIndicator,
    CompanyNotation,
    CompanyAuditor,
    EntitySource,
    CompanyValuation,
    CompanyFinancial,
} from '@deecision/deefind-types/model';

import {
    cleanIdentifier,
    cleanBoolean,
    cleanName,
    cleanShape,
    cleanArray,
    cleanNumber,
    cleanYear,
    cleanDate,
    cleanString,
    cleanOr,
} from './core';

import { cleanSource, cleanEstimate, cleanAddress } from './base';

export function cleanCompanyData(value: any, source?: EntitySource): CompanyData | undefined {
    if (!value || typeof value !== 'object') {
        return undefined;
    }

    return {
        identity: cleanCompanyIdentity(value.identity),
        identifiers: cleanCompanyIdentifiers(value.identifiers),
        addresses: cleanArray(value.addresses || [], [cleanAddress, source]),
        activities: cleanCompanyActivities(value.activities),
        indicators: cleanCompanyIndicators(value.indicators),
        notations: cleanCompanyNotations(value.notations),
        auditors: cleanCompanyAuditors(value.auditors),
        valuation: cleanCompanyValuation(value.valuation),
        financial: cleanCompanyFinancial(value.financial),
    };
}

export function cleanCompanyPreview(value: any): CompanyPreview | undefined {
    return cleanShape(value, {
        name: cleanName,
        identifiers: [cleanArray, cleanIdentifier],
        isins: [cleanArray, cleanIdentifier],
        country: cleanIdentifier,
        naceCode: cleanIdentifier,
        domains: [cleanArray, cleanName],
        persons: [cleanArray, cleanName],
        companies: [cleanArray, cleanName],
        gicsCode: cleanIdentifier,
        turnover: cleanNumber,
        capital: cleanNumber,
        ebitda: cleanNumber,
        equity: cleanNumber,
        valuation: cleanEstimate,
        employees: cleanNumber,
        publication: cleanNumber,
        creation: cleanDate,
        closed: [cleanOr, [cleanDate, cleanBoolean]],
        funded: [cleanOr, [cleanNumber, cleanBoolean]],
        vigilance: cleanNumber,
        flags: [cleanArray, cleanName],
        turnovers: [cleanArray, cleanNumber],
        ebitdas: [cleanArray, cleanNumber],
        mandates: cleanNumber,
        positions: cleanNumber,
    });
}

export function cleanCompanyIdentity(value: any, source?: EntitySource): CompanyIdentity | undefined {
    return cleanShape(value, {
        name: cleanName,
        country: cleanIdentifier,
        category: cleanNumber,
        employees: cleanNumber,
        affiliates: cleanNumber,
        creation: cleanDate,
        closed: [cleanShape, { reason: cleanString, date: cleanDate, source: cleanString }],
        leiCode: cleanIdentifier,
        isinCode: cleanIdentifier,
        giinCode: cleanIdentifier,
        legalForm: [
            cleanShape,
            {
                standard: cleanName,
                national: cleanName,
            },
        ],
        status: [
            cleanShape,
            {
                type: cleanName,
                label: cleanName,
            },
        ],
        websites: [cleanArray, cleanName],
        emails: [cleanArray, cleanName],
        source: [cleanSource, source],
    });
}

export function cleanCompanyIdentifiers(value: any, source?: EntitySource): CompanyIdentifier[] {
    return cleanArray(value, [
        cleanShape,
        {
            type: cleanName,
            label: cleanName,
            value: cleanIdentifier,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanCompanyActivities(value: any, source?: EntitySource): CompanyActivity[] {
    return cleanArray(value, [
        cleanShape,
        {
            primary: cleanBoolean,
            naceCode: cleanIdentifier,
            gicsCode: cleanIdentifier,
            label: cleanName,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanCompanyIndicators(value: any, source?: EntitySource): CompanyIndicator[] {
    return cleanArray(value, [
        cleanShape,
        {
            year: cleanYear,
            turnover: cleanNumber,
            capital: cleanNumber,
            ebitda: cleanNumber,
            equity: cleanNumber,
            valuation: cleanEstimate,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanCompanyNotations(value: any, source?: EntitySource): CompanyNotation[] {
    return cleanArray(value, [
        cleanShape,
        {
            label: cleanName,
            value: cleanName,
            provider: cleanName,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanCompanyAuditors(value: any, source?: EntitySource): CompanyAuditor[] {
    return cleanArray(value, [
        cleanShape,
        {
            type: cleanName,
            name: cleanName,
            date: cleanDate,
            source: [cleanSource, source],
        },
    ]);
}

export function cleanCompanyValuation(value: any, source?: EntitySource): CompanyValuation[] {
    return cleanShape(value, {
        vadis: cleanNumber,
        market: [
            cleanShape,
            {
                value: cleanNumber,
                update: cleanDate,
                isin: cleanIdentifier,
                source: [cleanSource, source],
            },
        ],
    });
}

export function cleanCompanyFinancial(value: any, source?: EntitySource): CompanyFinancial {
    return cleanShape(value, {
        minorityInterest: cleanNumber,
        preferredShares: cleanNumber,
        longTermDebt: cleanNumber,
        shortTermDebt: cleanNumber,
        cash: cleanNumber,
        loans: cleanNumber,
        netProfit: cleanNumber,
    });
}
