import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';

let styles = {
    container: {
        background: colors.grey200,
        borderRadius: '3px'
    },
    select: {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: '40px',
        height: '40px',
        background: colors.grey200,
        borderRadius: '3px 0 0 3px'
    },
    selectHover: {
        background: colors.grey300,
    },
    label: {
        lineHeight: '40px',
        color: colors.userCold,
        padding: '0 10px',
        background: 'transparent',
        cursor: 'pointer'
    },
    underline: {
        display: 'none'
    },
    arrow: {
        display: 'none'
    },
    button: {
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '40px',
    },
    wrapper: {
        display: 'inline-block',
        verticalAlign: 'middle',
        textAlign: 'center',
        width: '25px',
        height: '24px',
        padding: '8px',
        background: colors.grey200,
        cursor: 'pointer'
    },
    wrapperHover: {
        background: colors.grey300,
    },
    wrapperCurrent: {
        background: colors.userCold,
    },
    wrapperAsc: {
        borderRadius: '0 3px 3px 0'
    },
    icon: {
        fill: colors.userCold,
    },
    iconCurrent: {
        fill: 'white'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.header.sort';

    static propTypes = {
        items: PropTypes.object.isRequired,
        value: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func
    };

    state = { hover: undefined };

    render () {
        const key = _.first(_.keys(this.props.items));
        const currentValue = this.props.value || (key
            ? (this.props.items[key].type === 'asc' ? '+' : '-') + key
            : undefined);

        const currentType = currentValue ? currentValue.substr(0, 1) : '-';
        const currentProp = currentValue ? currentValue.substr(1) : undefined;

        const selectStyle = Object.assign({},
            styles.select,
            this.state.hover === 'menu' ? styles.selectHover : {}
        );

        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <DropDownMenu
                    style={ selectStyle }
                    labelStyle={ styles.label }
                    underlineStyle={ styles.underline }
                    iconStyle={ styles.arrow }
                    menuStyle={ styles.menu }
                    value={ currentType + currentProp }
                    autoWidth={ true }
                    onChange={ (event, index, value) => this.props.onChange(value) }
                    onMouseEnter={ event => this.setState({ hover: 'menu' }) }
                    onMouseLeave={ event => this.setState({ hover: undefined }) }
                >
                    { _.map(this.props.items, (item, key) => this.renderItem(item, key, currentProp, currentType)) }
                </DropDownMenu>

                { this.renderButton('-', 'nav.arrow.down', currentProp, currentType, t('common.label.sortDesc')) }
                { this.renderButton('+', 'nav.arrow.up', currentProp, currentType, t('common.label.sortAsc')) }
            </div>
        );
    }

    renderItem (item, key, currentProp, currentType) {
        const value = currentProp === key
            ? currentType + key
            : (item.type === 'desc' ? '-' : '+') + key;

        return (
            <MenuItem
                key={ key }
                value={ value }
                primaryText={ item.label }
            />
        );
    }

    renderButton (type, icon, currentProp, currentType, tooltip) {
        const wrapperStyle = Object.assign({},
            styles.wrapper,
            type === '+' ? styles.wrapperAsc : {},
            currentType === type
                ? styles.wrapperCurrent
                : (this.state.hover === type ? styles.wrapperHover : {}),
        );

        const iconStyle = Object.assign({},
            styles.icon,
            currentType === type ? styles.iconCurrent : {}
        );

        return (
            <span
                style={ styles.button }
            >
                <Views.Common.Tooltip content={ tooltip }>
                    <span
                        style={ wrapperStyle }
                        onMouseEnter={ event => this.setState({ hover: type }) }
                        onMouseLeave={ event => this.setState({ hover: undefined }) }
                    >
                        <Icon
                            type={ icon }
                            style={ iconStyle }
                            onClick={ event => this.props.onChange(type + currentProp) }
                        />
                    </span>
                </Views.Common.Tooltip>
            </span>
        );
    }
}
