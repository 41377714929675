import PropTypes from 'prop-types';
import React from 'react';
import Header from 'dna/entity/common/display/header';
import Container from 'dna/entity/common/display/container';
import VigilancePanel from 'dna/entity/common/vigilance/panel';
import Blocks from './display/blocks';
import Cockpit from './display/cockpit';
import Tabs from './display/tabs';
import { inject, observer } from 'mobx-react';
import getPatents from './patents';

@inject((stores) => ({ comeet: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.person.display';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object,
    };

    static childContextTypes = {
        fetchProspect: PropTypes.func.isRequired,
        startProspect: PropTypes.func.isRequired,
        endProspect: PropTypes.func.isRequired,
        updateProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        updateEvent: PropTypes.func.isRequired,
        removeEvent: PropTypes.func.isRequired,
        createMilestone: PropTypes.func.isRequired,
        updateMilestone: PropTypes.func.isRequired,
        removeMilestone: PropTypes.func.isRequired,
    };

    getChildContext() {
        return {
            fetchProspect: this.props.comeet.fetchProspect,
            startProspect: this.props.comeet.startProspect,
            endProspect: this.props.comeet.endProspect,
            updateProspect: this.props.comeet.updateProspect,
            createEvent: this.props.comeet.createEvent,
            updateEvent: this.props.comeet.updateEvent,
            removeEvent: this.props.comeet.removeEvent,
            createMilestone: this.props.comeet.createMilestone,
            updateMilestone: this.props.comeet.updateMilestone,
            removeMilestone: this.props.comeet.updateMilestone,
        };
    }

    render() {
        getPatents(this.props.entity.preview).then(res => {
                if (!this.state || !this.state.patents) this.setState({ patents: res });
            }
        );
        return (
            <Container>
                <Header
                    entity={this.props.entity}
                    state={this.props.state || {}}
                    onComeet={(id: string) => this.setState({ comeet: id })}
                />

                {this.renderContent()}
            </Container>
        );
    }

    renderContent() {
        if (!this.props.entity) {
            return null;
        }

        const step = (this.props.state ? this.props.state.step : null) || 'compliance';

        switch (step) {
            case 'compliance':
                return (
                    <div>
                        <VigilancePanel
                            entity={this.props.entity}
                            onOpen={() => this.context.updateState({ step: 'display' })}
                        />
                    </div>
                );

            case 'display':
                return (
                    <div>
                        <Blocks entity={this.props.entity} state={this.props.state} children={this.state} />
                        <Tabs entity={this.props.entity} state={this.props.state} children={this.state} />
                    </div>
                );

            case 'cockpit':
                return (
                    <div>
                        <Cockpit entity={this.props.entity} />
                    </div>
                );
        }
    }
}
