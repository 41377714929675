import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import colors from 'app/theme/colors';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import RoundButton from 'material-ui/FloatingActionButton';
import Popover from 'material-ui/Popover/Popover';

const styles = {
    wrapper: {
        position: 'relative'
    },
    miniButton: {
        minHeight: '30px',
        minWidth: '40px'
    },
    normButton: {
        minHeight: '40px',
        height: '40px',
        minWidth: '40px'
    },
    label: {
        verticalAlign: 'middle',
        padding: '0 10px',
        lineHeight: '40px',
        minWidth: '20px'
    },
    miniLabel: {
        lineHeight: '30px',
        padding: '5px',
        minWidth: '20px'
    },
    icon: {
        marginTop: '-2px',
        verticalAlign: 'middle',
        display: 'inline-block'
    },
    miniIcon: {
        marginTop: '-2px',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.button';

    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        type: PropTypes.oneOf([ 'flat', 'raised', 'round' ]),
        color: PropTypes.oneOf([ 'cold', 'hot', 'none', 'green', 'grey' ]),
        style: PropTypes.object,
        label: PropTypes.node,
        icon: PropTypes.element,
        url: PropTypes.string,
        mini: PropTypes.bool,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        submit: PropTypes.bool
    };

    static defaultProps = {
        type: 'raised',
        color: 'cold',
        disabled: false
    };

    state = {
        open: false
    };

    handleClick (event) {
        if (this.props.onClick)
            this.props.onClick(event);

        if (this.props.children)
            this.setState({ open: true, anchor: ReactDOM.findDOMNode(this.refs.button) });

        if (this.props.url)
            this.context.router.push(this.props.url);
    }

    closePopover () {
        this.setState({ open: false });
    }

    render () {
        if (! this.props.children)
            return this.renderButton();

        return (
            <span style={{ position: 'relative' }}>
                { this.renderButton({ width: '100%' }) }
                { this.renderPopover() }
            </span>
        );
    }

    renderButton (extraStyle?: Object) {
        const props = {
            ref: 'button',
            disabled: this.props.disabled,
            onClick: this.handleClick.bind(this)
            //onTouchTap: event => { event.preventDefault() }
        };

        switch (this.props.type) {
            case 'flat':
                return (
                    <FlatButton
                        primary={ this.props.color === 'cold' }
                        secondary={ this.props.color === 'hot' }
                        { ...this.getRectangularProps(extraStyle) }
                        { ...props }
                    >
                    </FlatButton>
                );
            case 'raised':
                return (
                    <RaisedButton
                        primary={ this.props.color === 'cold' }
                        secondary={ this.props.color === 'hot' }
                        labelPosition="before"
                        containerElement="label"
                        { ...this.getRectangularProps(extraStyle) }
                        { ...props }
                    >
                    {this.props.submit ? <button disabled={this.props.disabled} style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        width: '100%',
                        opacity: 0,
                    }} type="submit"/> : null}
                    </RaisedButton>
                );
            case 'round':
                return (
                    <RoundButton
                        secondary={ this.props.color === 'hot' }
                        style={ Object.assign({}, this.props.style, extraStyle || {}) }
                        backgroundColor={ this.props.color === 'green' ? colors.userGreen : this.props.color === 'grey' ? colors.grey300 : undefined }
                        mini={ this.props.mini }
                        { ...props }
                    >
                        { this.props.icon }
                    </RoundButton>
                );
        }
    }

    renderPopover () {
        return (
            <Popover
                anchorEl={ this.state.anchor }
                open={ this.state.open }
                useLayerForClickAway
                onRequestClose={ reason => this.setState({ open: false }) }
            >
                { this.props.children }
            </Popover>
        );
    }

    getRectangularProps (extraStyle?: Object) {
        let props = {};
        let color = undefined;

        props.style = Object.assign({},
            this.props.mini ? styles.miniButton : styles.normButton,
            this.props.style,
            extraStyle || {}
        );

        if (this.props.disabled)
            color = colors.grey500;

        else switch (this.props.type) {
            case 'raised':
                switch (this.props.color) {
                    case 'none':
                        props.backgroundColor = colors.white;
                        color = colors.userCold;
                        break;

                    case 'green':
                        props.backgroundColor = colors.userGreen;
                        color = colors.white;
                        break;

                    default:
                        color = colors.white;
                        break;
                }
                break;

            case 'flat':
                switch (this.props.color) {
                    case 'hot':
                        color = colors.userHot;
                        break;

                    case 'cold':
                        color = colors.userCold;
                        break;

                    case 'green':
                        color = colors.userGreen;
                        break;

                    case 'none':
                        color = colors.grey500;
                        break;
                }
                break;
        }

        const iconProps = {
            style: Object.assign({},
                color ? Object.assign({}, styles.icon, { fill: color }) : styles.icon,
                this.props.mini ? styles.miniIcon : {},
                this.props.icon ? this.props.icon.props.style : {}
            )
        };

        const labelStyle = Object.assign(
            { color: color },
            styles.label,
            this.props.mini ? styles.miniLabel : {}
        );

        if (! this.props.icon)
            return Object.assign(props, {
                label: this.props.label,
                labelStyle: labelStyle
            });

        if (! this.props.label)
            return Object.assign(props, {
                label: React.cloneElement(this.props.icon, iconProps),
                labelStyle: labelStyle
            });

        return Object.assign(props, {
            labelStyle: Object.assign({ color: color }, styles.label),
            label: (
                <span>
                    { React.cloneElement(this.props.icon, iconProps) }

                    <span style={ labelStyle }>
                        { this.props.label }
                    </span>
                </span>
            ),
        });
    }
}
