import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import Views from 'app/views';
import colors from 'app/theme/colors';
import { get } from 'lodash';
import Sum from 'dna/entity/common/display/sum';
import t from 'app/translation';

const styles = {
    scaleContainer: {
        position: 'absolute',
        top: '10px',
        right: '0',
        width: '35px',
        height: '53px',
        cursor: 'default'
    },
    scaleUnit: {
        position: 'absolute',
        left: '15px',
        height: '12px',
        width: '5px',
        border: '1px solid ' + colors.userCold,
        borderLeftWidth: '0'
    },
    scaleLabel: {
        position: 'absolute',
        width: '11px',
        textAlign: 'right',
        color: colors.grey500,
        fontSize: '9px',
        lineHeight: '12px'
    },
    scaleCursor: {
        position: 'absolute',
        fontSize: '13px',
        lineHeight: '13px',
        color: colors.userHot,
        left: '23px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.person.display.blocks.financial';

    static propTypes = {
      entity: PropTypes.object.isRequired,
      state: PropTypes.object.isRequired,
      children: PropTypes.object.isRequired,
    };

    render() {
        return (
            <DeefindProvider
                type="stocks"
                id={this.props.entity.id}
                mini
                renderEntity={(stocks) => this.renderContent(stocks)}
            />
        );
    }

    renderContent(stocks: Object) {
        return (
            <div style={{ position: 'relative' }}>
                { /* this.renderScale(this.props.entity.preview) */ }

                <Views.Common.Properties
                    values={[
                        {
                            label: t('common.label.fortune'),
                            tooltip: t('common.tooltip.fortune'),
                            value: (
                                <Views.Common.Number
                                    type="currency"
                                    scale="M"
                                    value={ this.props.entity.preview.fortune }
                                />
                            )
                        },
                        {
                            label: t('common.label.weasse'),
                            tooltip: t('common.tooltip.weasse'),
                            value: <Sum data={ get(stocks, 'preview.weasse') } />
                        },
                        {
                            label: t('dna.deefind.veegilenzData.person.weasseMaxLabel'),
                            tooltip: t('common.tooltip.weasseMax'),
                            value: <Sum data={ get(stocks, 'preview.weasseMax') } />
                        },
                        {
                            label: t('common.label.sumTurnover'),
                            tooltip: t('common.tooltip.turnoverSum'),
                            value: <Sum data={ get(stocks, 'sums.turnover') } />
                        },
                        {
                            label: 'Patents',
                            tooltip: 'Nombre de brevets détenus',
                            value: (
                                <Views.Common.Number
                                    type=""
                                    scale=""
                                    value={ this.props.children && this.props.children.patents.length }
                                />)
                        },
                    ]}
                    fallback="No known financial values"
                    align={ 90 }
                />
            </div>
        );
    }

    renderScale (preview) {
        const estimate = preview.weasse ? preview.weasse.value : undefined;

        if (! estimate)
            return null;

        let value = 0;
        if (estimate > 50000000) value = 3;
        else if (estimate > 10000000) value = 2;
        else if (estimate > 5000000) value = 1;

        return (
            <div style={ styles.scaleContainer }>
                <div style={ Object.assign({}, styles.scaleUnit, { top: '0' }) } />
                <div style={ Object.assign({}, styles.scaleUnit, { top: '13px' }) } />
                <div style={ Object.assign({}, styles.scaleUnit, { top: '26px' }) } />
                <div style={ Object.assign({}, styles.scaleUnit, { top: '39px' }) } />
                <div style={ Object.assign({}, styles.scaleLabel, { top: '8px' }) }>50</div>
                <div style={ Object.assign({}, styles.scaleLabel, { top: '21px' }) }>10</div>
                <div style={ Object.assign({}, styles.scaleLabel, { top: '34px' }) }>5</div>
                <div style={ Object.assign({}, styles.scaleCursor, { top: ((3 - value) * 13) + 'px' }) }>◀</div>
            </div>
        );
    }

    renderWeasse (weasse) {
        const value = (
            <Views.Common.Number
                type="currency"
                scale="M"
                value={ weasse }
            />
        );

        if (value && value.reliability) {
            return (
                <span>
                    { value }
                    <span>
                        { value.reliability + ' %' }
                    </span>
                </span>
            )
        }

        return value;
    }
}
