import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

let styles = {
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    column: {
        alignSelf: 'stretch',
        overflow: 'hidden',
        flexGrow: '1',
        width: '50%'
    },
    left: {
        paddingRight: '10px'
    },
    right: {
        paddingLeft: '10px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.columns';

    static propTypes = {
        left: PropTypes.node,
        right: PropTypes.node,
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <div style={ Object.assign({}, styles.column, styles.left) }>
                    { this.props.left }
                </div>
                <div style={ Object.assign({}, styles.column, styles.right) }>
                    { this.props.right }
                </div>
            </div>
        );
    }
}
