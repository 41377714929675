import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import Moment from 'moment';
import colors from 'app/theme/colors';
import Actors from './actors';
import t from 'app/translation';
const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch'
    },
    info: {
        width: '40%'
    },
    actors: {
        width: '20%',
        paddingLeft: '15px',
        marginLeft: '15px',
        borderLeft: '1px solid ' + colors.grey300
    },
    date: {
        color: colors.grey500
    },
    type: {
        marginTop: '5px'
    },
    description: {
        marginTop: '5px',
        color: colors.grey700
    },
    properties: {
        marginTop: '15px'
    },
    kind: {
        color: colors.black
    },
    percent: {
        color: colors.userCold,
        fontWeight: 'bold'
    },
    separator: {
        color: colors.grey500
    },
    target: {
        color: colors.black
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.modal.deal';

    static propTypes = {
        deal: PropTypes.object.isRequired,
        style: PropTypes.object
    };

    render () {
        const date = this.props.deal.dates.completion || this.props.deal.dates.announce || this.props.deal.dates.rumour;

        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <div style={ styles.info }>
                    <div style={ styles.date }>
                        { (date)?Moment(date).format('dddd D MMMM YYYY'):'' }
                    </div>

                    <div style={ styles.type }>
                        { this.renderType() }
                    </div>

                    <div style={ styles.description }>
                        { this.props.deal.description }
                    </div>

                    <Views.Common.Properties
                        style={ styles.properties }
                        values={[
                            { label: 'Status', value: [this.props.deal.status.kind, this.props.deal.status.details].filter(v => !! v).join('—') },
                            { label: 'Value', value: <Views.Common.Number type="currency" scale="M" value={ this.props.deal.value } /> },
                            { label: 'Payment', value: this.props.deal.payment }
                        ]}
                    />
                </div>

                <Actors
                    title={t('dna.deals.vendors')}
                    actors={ this.props.deal.vendors }
                    style={ styles.actors }
                />

                <Actors
                    title={t('dna.deals.targets')}
                    actors={ this.props.deal.targets }
                    style={ styles.actors }
                />

                <Actors
                    title={t('dna.deals.buyers')}
                    actors={ this.props.deal.acquirors }
                    style={ styles.actors }
                />

                <Actors
                    title={t('dna.deals.regulators')}
                    actors={ this.props.deal.regulators }
                    style={ styles.actors }
                />
            </div>
        );
    }

    renderType () {
        const type = this.props.deal.type;
        const items = [];

        if (type.kind)
            items.push(<span key="kind" style={ styles.kind }>{ type.kind }</span>);

        if (type.percent) {
            items.push(<span key="sep1" style={ styles.separator }> {t('dna.deals.of')}</span>);
            items.push(<span key="percent" style={ styles.percent }>{ ' ' + type.percent + ' %' }</span>);
        }

        if (type.target) {
            items.push(<span key="sep2" style={ styles.separator }> {t('dna.deals.on')}</span>);
            items.push(<span key="target" style={ styles.target }>{ ' ' + type.target }</span>);
        }

        return items;
    }
}
