// @flow

import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'absolute',
        width: '100%',
        height: 'auto',
        bottom: '0',
        top: '0',
        left: '0',
    },
    content: {
        position: 'absolute',
        width: '200px',
        height: '30px',
        top: '50%',
        left: '50%',
        marginTop: '-15px',
        marginLeft: '-100px',
        fontSize: '13px',
        lineHeight: '30px',
        textAlign: 'center',
        color: colors.userCold,
        textTransform: 'uppercase',
        letterSpacing: '2px'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.security.loader';

    props: {
        message: string
    };

    render () {
        return (
            <div style={ styles.container }>
                <div style={ styles.content }>
                    { this.props.message }
                </div>
            </div>
        );
    }
}
