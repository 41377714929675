// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.model }))
@observer
export default class DeefindEntityProvider extends React.Component<Object, Object> {
    static displayName = 'app.layout.providers.data';

    static propTypes = {
        store: PropTypes.object.isRequired,
        service: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        scope: PropTypes.string.isRequired,
        renderValue: PropTypes.func.isRequired,
        renderLoader: PropTypes.func,
        renderError: PropTypes.func,
    };

    state = {};

    startWatch(props: Object) {
        this.state.dispose = this.props.store.watchScope(
            props.service,
            props.model,
            props.id,
            props.scope,
        );
    }

    stopWatch() {
        if (this.state.dispose) {
            this.state.dispose();
        }
    }

    UNSAFE_componentWillMount() {
        this.startWatch(this.props);
    }

    UNSAFE_componentWillUpdate(props: Object) {
        if (
            props.service !== this.props.service ||
            props.model !== this.props.model ||
            props.id !== this.props.id ||
            props.scope !== this.props.scope
        ) {
            this.stopWatch();
            this.startWatch(props);
        }
    }

    componentWillUnmount() {
        this.stopWatch();
    }

    render() {
        const data = toJS(this.props.store.getData(this.props.service, this.props.model, this.props.id, this.props.scope));
        const error = toJS(this.props.store.getError(this.props.service, this.props.model, this.props.id, this.props.scope));

        if (data) {
            return this.props.renderValue(data);
        }

        if (error) {
            return this.props.renderError ? this.props.renderError(error) : null;
        }

        return this.props.renderLoader ? this.props.renderLoader() : null;
    }
}
