// @flow

import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        margin: '5px',
        padding: '5px 10px',
        lineHeight: '25px',
        background: colors.grey300,
        display: 'flex',
        borderRadius: '3px',
        cursor: 'pointer',
        flexGrow: '0',
        flexShrink: '0'
    },
    containerHover: {
        background: colors.grey200,
    },
    icon: {
        width: '25px',
        height: '25px',
        fill: colors.grey500,
    },
    iconHover: {
        fill: colors.userCold
    },
    count: {
        display: 'inline-block',
        marginLeft: '10px',
        color: colors.userCold,
        fontWeight: 'bold'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.screen.tabs.group';

    props: {
        count: number,
        select: Function,
    };

    state = { hover: false };

    render () {
        const containerStyle = Object.assign({}, styles.container, this.state.hover ? styles.containerHover : {});
        const iconStyle = Object.assign({}, styles.icon, this.state.hover ? styles.iconHover : {});

        return (
            <div
                style={ containerStyle }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.select }
            >
                <Icon
                    type="base.tabs.group"
                    style={ iconStyle }
                />

                <span style={ styles.count }>
                    { this.props.count }
                </span>
            </div>
        );
    }
}
