import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        background: colors.grey200,
        width: '100%',
    },
    heading: {
        background: 'white',
        color: colors.userCold,
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: '14px',
    },
    cell: {
        background: 'white',
        fontSize: '14px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.table';

    static propTypes = {
        head: PropTypes.array,
        foot: PropTypes.array,
        body: PropTypes.arrayOf(PropTypes.array).isRequired,
    };

    render() {
        return (
            <table style={styles.container} cellSpacing="1" cellPadding="10">
                {this.renderHead()}
                {this.renderBody()}
                {this.renderFoot()}
            </table>
        );
    }

    renderHead() {
        if (!this.props.head) {
            return null;
        }

        return <thead>{this.renderRow(this.props.head, 'key', true)}</thead>;
    }

    renderFoot() {
        if (!this.props.foot) {
            return null;
        }

        return <thead>{this.renderRow(this.props.foot, 'foot', true)}</thead>;
    }

    renderBody() {
        return <thead>{this.props.body.map((row, index) => this.renderRow(row, index))}</thead>;
    }

    renderRow(row: any[], key: string | number, heading: boolean) {
        return <tr key={key}>{row.map((data, index) => this.renderCell(data, index, heading || index === 0))}</tr>;
    }

    renderCell(data: any, key: string | number, heading: boolean) {
        const style = {};
        let content: string = '';

        switch (true) {
            case ['string', 'number', 'boolean'].includes(typeof data):
                content = String(data);
                break;

            case !!data && data.hasOwnProperty('data'):
                content = String(data.data);
                if (data.color) style.backgroundColor = data.color;
                break;

            default:
                content = '';
        }

        return heading ? (
            <th key={key} style={{ ...styles.heading, ...style }}>
                {content}
            </th>
        ) : (
            <td key={key} style={{ ...styles.cell, ...style }}>
                {content}
            </td>
        );
    }
}
