// @flow

import type { AuthUserProfile } from '@deecision/infra-types/common';

export function makeName(profile: AuthUserProfile): string {
    if (profile.firstName && profile.lastName) {
        return `${profile.firstName} ${profile.lastName}`;
    }

    if (profile.name) {
        return profile.name;
    }

    return '';
}
