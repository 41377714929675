import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import { COUNTRIES } from 'dna/entity/aml';
import t from 'app/translation';

const styles = {
    amlFlag: {
        fontSize: '9px',
        background: colors.userHot,
        borderRadius: '3px',
        padding: '4px',
        display: 'inline-block',
        color: 'white',
        verticalAlign: 'bottom',
        marginRight: '5px',
    },
    flag: {
        display: 'inline-block',
        width: '16px',
        height: '12px',
        marginRight: '5px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.country';

    static propTypes = {
        code: PropTypes.string,
    };

    render () {
        if (! this.props.code) {
            return null;
        }

        const aml = COUNTRIES[this.props.code]
            ? <span style={ styles.amlFlag }>AML</span>
            : null;

        return (
            <span>
                {aml}

                <img
                    src={ `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${this.props.code}.svg` }
                    style={ styles.flag }
                />

                { t('common.country.' + String(this.props.code).toUpperCase()) }
            </span>
        );
    };
}
