import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import { inject, observer } from 'mobx-react';
import Moment from 'moment';
import get from 'lodash/get';

const styles = {
    container: {
        lineHeight: '25px',
        width: '48%',
    },
    title: {
        color: colors.userCold,
        fontSize: '13px',
        borderBottom: '1px solid ' + colors.grey300,
        marginBottom: '5px',
    },
    light: {
        color: colors.grey500,
    },
    icon: {
        width: '16px',
        height: '16px',
        float: 'left',
        fill: colors.grey400,
        margin: '4px 9px 4px 0',
    },
};

@inject(stores => ({ contacts: stores.auth.contacts }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.summary.request';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.title}>Requester</div>
                <div>ABN Amro Investment Solutions</div>
                {this.renderRequester()}
                <br />
                {this.renderSchedule()}
            </div>
        );
    }

    renderRequester() {
        const owner = this.props.contacts.users[this.props.entity.ownerUid];

        if (!owner) {
            return null;
        }

        return [
            <div key="n">
                <Icon type="base.attendee" style={styles.icon} />
                {owner.firstName} {owner.lastName}
            </div>,
            <div key="e">
                <Icon type="base.email" style={styles.icon} />
                {owner.email}
            </div>,
        ];
    }

    renderSchedule() {
        const start = this.props.entity.history.filter(i => i.transition === 'start')[0];

        if (!start) {
            return null;
        }

        const from = Moment(start.createStamp).format('DD / MM / YYYY');
        const to = addWorkDays(start.createStamp, get(this.props.entity, 'payload.durations.input', 40)).format('DD / MM / YYYY');

        return [
            <div key="s" style={styles.title}>
                Schedule
            </div>,
            <div key="ft">
                <span style={styles.light}>From&nbsp;&nbsp;</span>
                {from}
                <span style={styles.light}>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                {to}
            </div>,
        ];
    }
}

function addWorkDays(from, days) {
    const cursor = Moment(from);
    let count = days;
    while(count > 0) {
        if (![6, 7].includes(cursor.isoWeekday())) count--;
        cursor.add(1, 'days');
    }

    return cursor;
}
