// @flow

import _ from 'lodash';

import en from './locales/en';
import fr from './locales/fr';

const defaultLanguage = 'en';
const languages = { en, fr };

const getLanguage = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    return browserLanguage && languages[browserLanguage] ? browserLanguage : defaultLanguage;
};

export default function (key: string, values: Object = {}): string {
    let translation = _.get(languages[getLanguage()], key);

    if (!translation) {
        return `(trans ${key}: not found)`;
    }

    if (typeof translation !== 'string') {
        return `(trans ${key}: not a string)`;
    }

    for (const key of Object.keys(values || {})) {
        translation = translation.replace(new RegExp(`\\{\\\{\\s*${key}\\s*\\}\\}`), String(values[key]));
    }

    return translation;
};
