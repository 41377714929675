// @flow

import type { ResolverInterface, ResolverSpecs } from '../../types';
import type StatePath from '../../path';
import type StateNode from '../../node';

export default class StringResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        if (value === undefined) {
            return this.specs.fallback;
        }

        if (typeof value !== 'string') {
            // console.error(`Found invalid "string" value at "${this.path.toString()}": ${JSON.stringify(value)}`);

            return this.specs.fallback;
        }

        if (Array.isArray(this.specs.values)) {
            if(! this.specs.values.includes(value)) {
                console.error(
                    `Found invalid "string" value "${value}" at "${this.path.toString()}", ` +
                    `allowed values are: ${this.specs.values.join(', ')}.`
                );

                return this.specs.fallback;
            }
        }

        return value;
    }
}
