import React from 'react';

let styles = {
    container: {
        position: 'relative',
        fontFamily: 'Roboto',
        fontSize: '15px',
        paddingBottom: '30px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.container';

    render () {
        return (
            <div style={ styles.container }>
                { this.props.children }
            </div>
        );
    }
};
