import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Section from 'dna/entity/common/display/section';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.cockpit.biography';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
        return (
            <div style={{marginTop: '5px', marginRight: '5px', marginLeft: '5px', border: '2px solid '+colors.grey400 }}>
                { this.renderHeader() }
                <div>
                  { this.props.entity.data.biographies.map((biography, index) =>
                    <Section key={ index } title={ 'Source — ' + biography.source.name }>
                        { biography.content }
                    </Section>  ) }
                </div>
            </div>
        );
    }

    renderHeader() {
      return (
        <div style={{backgroundColor: colors.grey200, padding: '5px'}}>
          <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%',textAlign: 'left' }}>
            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="main.text" />
          </div>
          <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '40%',textAlign: 'left', color: colors.userCold }}>
            Biography
          </div>
        </div>
      );
    }
}
