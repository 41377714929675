import { get, last } from 'lodash';
import Moment from 'moment';

export default async function (data: Object, services: Object): Promise<Object> {
    const fields = {
        aum: undefined,
        histoAum: undefined,
        histoStaff: undefined,
    };

    for (const field of data.fields || []) {
        const tag = get(field, 'questionPayload.report');

        switch (tag) {
            case 'M_AUM':
                fields.aum = field;
                break;

            case 'M_HISTO_AUM':
                fields.histoAum = field;
                break;

            case 'M_HISTO_STAFF':
                fields.histoStaff = field;
                break;
        }
    }

    const figures = {
        currency: 'EURO',
        aum: [],
        dates: [],
        employees: [],
    };

    if (fields.histoAum && fields.histoAum.questionPayload.type === 'table') {
        // version 1

        const answerAum = fields.aum.answerEditPayload || fields.aum.answerPayload || {};
        const answerHistoAum = fields.histoAum.answerEditPayload || fields.histoAum.answerPayload || {};
        const answerHistoStaff = fields.histoStaff.answerEditPayload || fields.histoStaff.answerPayload || {};

        figures.currency = String(last(get(answerAum, 'values', [])) || 'EURO')
        figures.aum = get(answerHistoAum, 'rows', []).map(row => row[1] || 0).filter(v => !!v);
        figures.dates = get(answerHistoAum, 'rows', []).map(row => row[0] || 0).filter(v => !!v);
        figures.employees = get(answerHistoStaff, 'rows', []).map(row => row[1] || 0).filter(v => !!v);
    }

    if (fields.histoAum && fields.histoAum.questionPayload.type === 'object') {
        // version 2

        const answerHistoAum = fields.histoAum.answerEditPayload || fields.histoAum.answerPayload || {};
        const answerHistoStaff = fields.histoStaff.answerEditPayload || fields.histoStaff.answerPayload || {};

        figures.aum = get(answerHistoAum, 'values', []).reverse();

        figures.dates = [
            Moment(answerHistoAum.date).format('YYYY') - 3,
            Moment(answerHistoAum.date).format('YYYY') - 2,
            Moment(answerHistoAum.date).format('YYYY') - 1,
        ];

        figures.employees = get(answerHistoStaff, 'values', []).reverse().filter(v => !!v);
    }

    return { figures };
}
