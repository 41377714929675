// @flow

export default {
    type: 'menu',
    pinned: [
        {
            type: 'studies',
            pinned: true,
        },
    ],
    prototype: {
        type: 'router',
        routes: {
            studies: {
                pinned: { type: 'boolean' },
                list: { type: 'list' },
            },
        },
    },
};
