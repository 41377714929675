import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import { computeEventCost } from '../compute';

import t from 'app/translation';

const TEMPLATES = {
    meeting: {
        label: t('dna.comeet.meeting'),
        attendees: 3,
        duration: 1
    },
    conference: {
        label: t('dna.comeet.conference'),
        attendees: 3,
        duration: 1
    },
    study: {
        label: t('dna.comeet.study'),
    },
    invitation: {
        label: t('dna.comeet.invitation'),
        attendees: 3,
        duration: 1
    },
    restaurant: {
        label: t('dna.comeet.restaurant'),
        attendees: 3,
        duration: 1
    }
};

const DEFAULTS = {
    payload: {
      settings: { type: _.first(_.keys(TEMPLATES)) }
    }
};

const styles = {
    costLabel: {
        padding: '5px 10px',
        fontSize: '11px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        color: colors.grey500,
    },
    costValue: {
        display: 'block',
        padding: '10px',
        textAlign: 'right',
        color: colors.userCold,
        fontWeight: 'bold',
        lineHeight: '24px'
    },
    costNC: {
        display: 'block',
        padding: '10px',
        textAlign: 'center',
        lineHeight: '24px'
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'comeet.common.events.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount() {
      this.setState(this.computeState(this.props.entity));
    }

    state = {
        cost: 0,
        properties: [],
        type: undefined
    };

    open (extra) {
        this.refs.dialog.open(extra);
        this.handleChange(this.props.entity || DEFAULTS);
    }

    computeState (data) {
        if (! data || ! data.payload) {
            return { properties: [], cost: 0 };
        }

        const cost = computeEventCost(data.payload);

        return {
            cost,
            properties: ['adjustment', 'unitPrice'].concat(Object.keys(TEMPLATES[ data.payload.settings.type ] || {}))
        };
    }

    handleChange (data) {
        const template = TEMPLATES[ data.payload.settings.type ];

        if (template && (data.payload.settings.type !== this.state.type)) {
            data.payload.settings.attendees = template.attendees || undefined;
            data.payload.settings.duration = template.duration || undefined;
            //data.payload.settings.unitPrice = template.unitPrice || undefined;
            //data.payload.settings.adjustment = 0;
            //data.payload.time = template.time;
            data.payload.duration = template.duration ? template.duration : undefined;
            this.state.type = data.payload.settings.type;
        }

        this.setState(this.computeState(data));
    }

    render () {
        return (
            <Views.Modal.Edition
                ref="dialog"
                model="comeet:events"
                data={ this.props.entity || _.clone(DEFAULTS) }
                label="Save event"
                onChange={ this.handleChange.bind(this) }
                onSubmit={ this.props.onSubmit }
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        path="payload.settings.type"
                        widget={{
                            type: 'radios',
                            choices: _.transform(TEMPLATES, (choices, type, key) => {
                                choices[ key ] = type.label
                            })
                        }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        size={ 3 }
                        label={t('dna.comeet.title')}
                        path="payload.title"
                        widget="string"
                        required
                    />

                    <Views.Form.Field
                        size={ 2 }
                        label={t('dna.comeet.date')}
                        path="payload.date"
                        widget="date"
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    { this.renderSetting('attendees', t('common.label.people')) }
                    { this.renderSetting('duration', t('dna.comeet.days')) }
                    { this.renderSetting('unitPrice', t('dna.comeet.unitPrice'), '€') }
                    { this.renderSetting('adjustment', t('dna.comeet.adjustment'), '€') }
                    { this.renderCost() }
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Description"
                        path="payload.description"
                        widget="text"
                    />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }

    renderCost () {
        return (
            <Views.Form.Cell>
                <div style={ styles.costLabel }>
                    {t('dna.comeet.totalCost')}
                </div>

                <Views.Common.Number
                    style={ this.state.cost ? styles.costValue : styles.costNC }
                    type="currency"
                    value={ this.state.cost }
                />
            </Views.Form.Cell>
        );
    }

    renderSetting (name, label, unit) {
        if (! this.state.properties.includes(name)) {
            return (
                <Views.Form.Cell />
            );
        }

        return (
            <Views.Form.Field
                label={ label }
                path={ 'payload.settings.' + name }
                widget={{ type: 'number', unit }}
                required
            />
        )
    }
}
