// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.model }))
@observer
export default class DeefindEntityProvider extends React.Component<Object, Object> {
    static displayName = 'app.layout.providers.list';

    static propTypes = {
        store: PropTypes.object.isRequired,
        service: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
        query: PropTypes.object,
        renderList: PropTypes.func.isRequired,
        renderLoader: PropTypes.func,
        renderError: PropTypes.func,
    };

    state = {};

    startWatch(props: Object) {
        this.state.dispose = this.props.store.watchList(props.service, props.model, props.query);
    }

    stopWatch() {
        if (this.state.dispose) {
            this.state.dispose();
        }
    }

    UNSAFE_componentWillMount() {
        this.startWatch(this.props);
    }

    UNSAFE_componentWillUpdate(props: Object) {
        if (props.service !== this.props.service || props.model !== this.props.model) {
            this.stopWatch();
            this.startWatch(props);
        }
    }

    componentWillUnmount() {
        this.stopWatch();
    }

    render() {
        const data = toJS(this.props.store.getList(this.props.service, this.props.model, this.props.query));

        if (data) {
            return this.props.renderList(data);
        }

        return this.props.renderLoader ? this.props.renderLoader() : null;
    }
}
