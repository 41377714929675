import PropTypes from 'prop-types';
import React from 'react';

import DeefindProvider from 'app/layout/providers/deefind';
import Stocks from 'dna/entity/common/stocks';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.display.tabs.shareholders';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render () {
        return (
            <DeefindProvider
                type="stocks"
                id={ this.props.entity.id }
                renderEntity={ (stocks, state) => {
                    return (<Stocks entity={ this.props.entity } stocks={stocks} />);
                }}
            />
        );
    }
}
