import React from 'react';
import PropTypes from 'prop-types';
import Filter from './filter';
import List from './list';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const KIND_LABELS = {
    M: 'Manager',
    F: 'Fund',
    S: 'Service Providers',
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch',
        fontFamily: 'Roboto, sans-serif',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.documents';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object,
        style: PropTypes.object,
        onComment: PropTypes.func.isRequired,
        onUpdateState: PropTypes.func.isRequired,
    };

    state = { fields: [] };

    render() {
        const fields = this.props.entity.fields ? this.props.entity.fields.slice() : [];

        if (!fields.length) {
            return null;
        }

        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <Filter
                    data={this.props.state ? this.props.state.filter || {} : {}}
                    menu={this.computeMenu(fields)}
                    fields={fields}
                    fund={!!this.props.entity.payload.fund}
                    onChange={filter => {
                        this.props.onUpdateState({ filter });
                        this.setState({ fields: this.filterFields(filter) });
                    }}
                />

                <List
                    fields={this.state.fields}
                    onComment={this.props.onComment}
                />
            </div>
        );
    }

    filterFields(filter) {
        return this.props.entity.fields
            .map(id => toJS(this.props.store.fields.get(id)))
            .filter(
                field =>
                    ['file', 'file[]'].includes(field.questionPayload.type) &&
                    (filter.kind ? filter.kind === field.questionPayload.kind : true) &&
                    (filter.category ? filter.category === field.questionPayload.category.id : true),
            );
    }

    computeMenu(fields) {
        const data = {};

        for (const id of fields) {
            const field = this.props.store.fields.get(id) || {};
            const question = field.questionPayload || {};
            const answer = field.answerEditPayload || field.answerPayload || {};

            if (!['file', 'file[]'].includes(question.type)) {
                continue;
            }

            if (!data[question.kind]) {
                data[question.kind] = {
                    type: 'kind',
                    label: KIND_LABELS[question.kind],
                    count: 0,
                    done: 0,
                    categories: {},
                };
            }

            if (!data[question.kind].categories[question.category.id]) {
                data[question.kind].categories[question.category.id] = {
                    type: 'category',
                    kind: question.kind,
                    id: question.category.id,
                    label: question.category.label,
                    count: 0,
                    done: 0,
                };
            }

            data[question.kind].count += 1;
            data[question.kind].categories[question.category.id].count += 1;

            if (question.type === 'file' && answer.value && answer.value.id) {
                data[question.kind].done += 1;
                data[question.kind].categories[question.category.id].done += 1;
            }

            if (question.type === 'file[]' && (answer.values || []).filter(value => !!value.id).length) {
                data[question.kind].done += 1;
                data[question.kind].categories[question.category.id].done += 1;
            }
        }

        return data;
    }
}
