import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        textAlign: 'center',
        padding: '15px',
        background: colors.grey100
    },
    step: {
        display: 'inline-block',
        lineHeight: '25px',
        width: '25px',
        textAlign: 'center',
        color: colors.grey400,
        background: colors.grey200,
        borderRadius: '50%',
        fontSize: '13px',
        fontWeight: 'bold',
        margin: '0 15px'
    },
    active: {
        background: colors.userCold,
        color: 'white'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.layout.security.login.breadcrumbs';

    static propTypes = {
        step: PropTypes.number.isRequired,
        onMove: PropTypes.func.isRequired
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.style
        );

        return (
            <div style={ style }>
                { this.renderStep(1) }
                { this.renderStep(2) }
                { this.renderStep(3) }
            </div>
        );
    }

    renderStep (step) {
        const style = step === this.props.step
            ? Object.assign({}, styles.step, styles.active)
            : styles.step;
        return (
            <span style={ style }>
                { step }
            </span>
        );
    }
}
