import get from 'lodash/get';

export default async function(data: Object, services: Object): Promise<Object> {
    const findings = {};

    for (const finding of get(data, 'computed.indexes.keyFindings')) {
        if (!findings[finding.category]) {
            findings[finding.category] = [];
        }

        findings[finding.category].push({
            title: finding.title,
            text: finding.comment,
        });
    }

    return { findings };
}
