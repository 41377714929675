import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

let styles = {
    table: {
        width: '100%'
    },
    header: {
        padding: '10px 5px',
        color: colors.userCold,
        fontWeight: 'bold'
    },
    loading: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
        cursor: 'pointer'
    },
    hover: {
        background: colors.grey200
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.body.table';

    static propTypes = {
        element: PropTypes.element.isRequired,
        entities: PropTypes.array,
        specs: PropTypes.shape({
            labels: PropTypes.array
        })
    };

    state = { hover: undefined };

    render () {
        if (! this.props.entities) {
            return null;
        }

        return (
            <table style={ styles.table } cellPadding={ 0 } cellSpacing={ 0 }>
                <thead>
                    <tr>
                        { this.props.specs.labels.map(this.renderLabel.bind(this)) }
                    </tr>
                </thead>
                <tbody>
                    { this.props.entities.map(this.renderRow.bind(this)) }
                </tbody>
            </table>
        );
    }

    renderLabel (label, index) {
        return (
            <td key={ index } style={ styles.header }>
                { label }
            </td>
        );
    }

    renderRow (entity, index) {
        if (! entity) {
            console.error(`Rendering table row for an undefined entity.`);
            return null;
        }

        return this.renderClone(this.props.element, entity, index);
    }

    renderClone (element, entity, index) {
        return React.cloneElement(element, {
            key: index,
            entity: entity,
            onMouseEnter: (event) => { this.setState({ hover: entity.id })},
            onMouseLeave: (event) => { this.setState({ hover: undefined })},
            style: entity.id === this.state.hover ? styles.hover : undefined
        });
    }
}
