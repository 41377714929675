import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import List from './list';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import Scope from 'app/layout/providers/scope';

const styles = {
    modal: {},
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.contacts';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        store: PropTypes.object,
    };

    render() {
        const payload = this.props.entity.payload || {};
        const sales = payload.salesContact || {};
        const manager = payload.oddContact || {};
        const attendees = payload.oddInvitations || [];

        return [
            <Views.Common.Button
                key="button"
                type="flat"
                color="cold"
                label={String(attendees.length + (sales.name ? 1 : 0) + (manager.email ? 1 : 0))}
                icon={<Icon type="base.attendee" />}
                onClick={() => this.refs.modal.open()}
            />,

            <Views.Modal.Show
                key="modal"
                ref="modal"
                icon={<Icon type="base.attendee" />}
                title="Due diligence contacts"
                style={styles.modal}
            >
                <Scope path="contacts" type="list">
                    <List
                        sales={sales}
                        manager={manager}
                        attendees={attendees}
                        onUpdateSales={() => {}}
                        onUpdateManager={() => {}}
                        onAddAttendee={(profile: Object) =>
                            this.props.store.sendInvitation(this.props.entity.id, profile)
                        }
                        onRemoveAttendee={(profile: Object) =>
                            this.props.store.deleteInvitation(this.props.entity.id, profile.id)
                        }
                    />
                </Scope>
            </Views.Modal.Show>,
        ];
    }
}
