import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
    container: {
        margin: '10px 0',
        lineHeight: '16px',
        width: '270px',
        height: '70px',
        float: 'left',
    },
    score: {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        float: 'left',
    },
    scoreIcon: {
        width: '16px',
        height: '16px',
        fill: 'white',
    },
    label: {
        margin: '0 10px 0 28px',
        fontWeight: 'bold',
        display: 'block'
    },
    values: {
        margin: '5px 0 10px 30px',
        color: colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.vigilance.item';

    static propTypes = {
        label: PropTypes.string.isRequired,
        score: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    state = { hover: false };

    render() {
        let color, icon;

        switch (true) {
            case this.props.score >= 4:
                color = 'black';
                icon = 'veegilenz.alert';
                break;

            case this.props.score >= 3:
                color = colors.userHot;
                icon = 'veegilenz.alert';
                break;

            case this.props.score >= 2:
                color = colors.userWarning;
                icon = 'veegilenz.warning';
                break;

            case this.props.score >= 1:
                color = colors.userGreen;
                icon = 'veegilenz.allright';
                break;

            default:
                color = colors.grey500;
                icon = 'veegilenz.warning';
        }

        let valueBlock = '';
        if (typeof this.props.data.score !== 'undefined') {
            valueBlock = valueBlockRender(this.props.data.answer, this.props.data.score);
        }

        return (
            <div style={styles.container}>
                <div style={Object.assign({}, styles.score, { background: color })}>
                    <Icon style={styles.scoreIcon} type={icon} />
                </div>

                {this.props.info ? (
                    <Tooltip title={this.props.info}>
                        <span style={Object.assign({}, styles.label, { color })}>{this.props.label}</span>
                    </Tooltip>
                ) : (
                    <span style={Object.assign({}, styles.label, { color })}>{this.props.label}</span>
                )}
                {valueBlock}
            </div>
        );
    }
}

function valueBlockRender(answer, score) {
    let answerTxt = answer;

    if (answer === false ) {
        answerTxt = 'faux';
    }
    if (answer === true ) {
        answerTxt = 'vrai';
    }

    return (
        <div style={Object.assign({}, styles.values)}>
            valeur : {answerTxt} <br />
            score : {score}
        </div>
    );
}
