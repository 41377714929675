import PropTypes from 'prop-types';
import React from 'react';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import Display from './display';
import Badge from 'material-ui/Badge';

const styles = {
    container: {
        position: 'absolute',
        marginTop: '-15px',
        marginLeft: '-50px',
        padding: '0px',
    },
    logo: {
        margin: '-2px 0 2px 12px',
        verticalAlign: 'middle',
    },
    label: {
        lineHeight: '25px',
        fontSize: '15px',
        color: 'white',
        backgroundColor: 'rgb(13, 71, 161)',
        padding: '10px 14px',
    },
    badge: {
        backgroundColor: 'rgb(255, 152, 0)',
        top: '10px',
        right: '15px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.compliance.button';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
        mini: PropTypes.bool,
    };

    render() {
        const files = this.props.entity.files || [];
        return files.length ? this.renderButton(files.length) : null;
    }

    renderButton(label: string) {
        return (
            <span style={Object.assign({}, styles.container, this.props.style || {})}>
                <Badge badgeStyle={styles.badge} badgeContent={label} onClick={event => this.refs['details'].open()}>
                    <span onClick={event => this.refs['details'].open()} style={styles.label}>
                        C
                    </span>
                </Badge>
                <Display ref="details" entity={this.props.entity} />
            </span>
        );
    }
}
