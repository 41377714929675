import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import List from './news/list';
import Menu from './news/menu';
import Views from 'app/views';

import t from 'app/translation';
import moment from 'moment';
import sortBy from 'lodash/sortBy';

const styles = {
    container: {
        display: 'flex',
        flex: '2',
    },
    menu: {
        width: '25%',
    },
    list: {
        width: '75%',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.news';

    static propTypes = {
        identifier: PropTypes.string.isRequired,
        state: PropTypes.object,
        provider: PropTypes.string,
        moment: PropTypes.string,
        news: PropTypes.array,
    };

    static defaultProps = {
        type: 'news',
        filters: {},
    };

    findResult() {
        let news = this.props.news || [];

        news = sortBy(news, function(o) {
            return typeof o.date !== 'undefined' ? -o.date.replace(/-/g, '') : undefined;
        });

        if (!this.props.state) {
            return { loading: false, items: news };
        }

        return { loading: !news, items: news };
    }

    applyFilters(items) {
        if (this.props.state && this.props.state.moment) {
            items = _.filter(items, newsItem => {
                if (this.props.state.moment === 'earlier') {
                    return true;
                }

                if (newsItem.moment === 'earlier') {
                    return false;
                }

                if (this.props.state.moment === 'year') {
                    return true;
                }

                if (
                    this.props.state.moment === 'month' &&
                    (newsItem.moment === 'month' || newsItem.moment === 'week')
                ) {
                    return true;
                }

                if (this.props.state.moment === 'week' && newsItem.moment === 'week') {
                    return true;
                }

                return false;
            });
        }

        if (this.props.state && this.props.state.domain) {
            items = _.filter(items, { domain: this.props.state.domain });
        }

        if (this.props.state && this.props.state.category) {
            items = _.filter(items, { category: this.props.state.category });
        }

        if (this.props.state && this.props.state.reliability && this.props.state.reliability !== 'all') {
            items = _.filter(items, { reliability: this.props.state.reliability });
        }

        return items;
    }

    render() {
        if (!this.props.news || !this.props.news.length) {
            return <Views.Common.Empty title={t('dna.deefind.veegilenzData.common.noNew')} style={{ flex: '2' }} />;
        }

        for (let item in this.props.news) {
            this.props.news[item].moment = this.setMoment(this.props.news[item].date);
        }

        const { loading, items } = this.findResult();
        const result = loading ? [] : this.applyFilters(items);

        return (
            <div style={styles.container}>
                <Menu items={items} state={this.props.state} style={styles.menu} name={this.props.name} />
                <List style={styles.list} items={result} loading={loading} />
            </div>
        );
    }

    setMoment(date) {
        if (!date || !date.length) {
            return '';
        }

        if (moment(date).isBefore(moment().subtract(1, 'year'))) {
            return 'earlier';
        }

        if (moment(date).isBefore(moment().subtract(1, 'month'))) {
            return 'year';
        }

        if (moment(date).isBefore(moment().subtract(1, 'week'))) {
            return 'month';
        }

        return 'week';
    }
}
