import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.chart.entry';

    static propTypes = {
        label: PropTypes.node.isRequired,
        value: PropTypes.number.isRequired,
        style: PropTypes.object
    };

    render () {
        throw new Error('Chart entry can not be rendered.');
    }
}
