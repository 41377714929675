// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Menu from './menu';
import Settings from './settings';
import Members from './members';
import Links from './links';

const styles = {
    container: {
        flexGrow: '1',
        display: 'flex',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details';

    static propTypes = {
        id: PropTypes.string,
    };

    state = { selection: 'settings' };

    render() {
        if (!this.props.id) {
            return null;
        }

        return (
            <div style={styles.container}>
                <Menu selection={this.state.selection} onSelect={(selection: string) => this.setState({ selection })} />
                {this.renderEntry()}
            </div>
        );
    }

    renderEntry() {
        switch (this.state.selection) {
            case 'settings':
                return <Settings id={this.props.id} />;

            case 'members':
                return <Members id={this.props.id} />;

            case 'links':
                return <Links id={this.props.id} />;

            default:
                return <div style={{ flexGrow: '1' }} />;
        }
    }
}
