import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Header from './header';
import Question from '../questions/question/item';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.deeligenzStudies, management: stores.deeligenzManagement }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.management.display.row';

    static propTypes = {
        entity: PropTypes.object
    };

    render() {
        return <tr><td><Question id={this.props.entity.id} additional={false} readOnly={true} /></td></tr>
    }
}
