import PropTypes from 'prop-types';
import React from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Title from './title';
import Block from './block';
import { inject, observer } from 'mobx-react';

const styles = {
    matrix: {
        display: 'flex',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
};

@inject('deefind')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.vigilance.button';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render() {
        const report = this.props.entity.vigilance || {};

        if (!report || !report.score) {
            return null;
        }

        let background = colors.grey200;
        let icon = 'veegilenz.alert';
        const score = report.direct ? report.direct.score : 0;

        switch (true) {
            case report.score >= 4:
                background = 'black';
                icon = 'veegilenz.alert';
                break;

            case report.score >= 3:
                background = colors.userHot;
                icon = 'veegilenz.alert';
                break;

            case report.score >= 2:
                background = colors.userWarning;
                icon = 'veegilenz.warning';
                break;

            case report.score >= 1:
                background = colors.userGreen;
                icon = 'veegilenz.allright';
                break;

            default:
                background = colors.grey500;
                icon = 'veegilenz.allright';
        }

        return (
            <span style={this.props.style}>
                <Views.Common.Tooltip content={`Veegilenz ${score.toFixed(2)}`}>
                    <FloatingActionButton
                        backgroundColor={background}
                        onClick={(event) => this.refs['details'].open()}
                        zDepth={1}
                        mini
                    >
                        <Icon type={icon} />
                    </FloatingActionButton>
                </Views.Common.Tooltip>

                <Views.Modal.Show
                    ref="details"
                    style={{ width: '400px' }}
                    title={
                        <span style={styles.title}>
                            <Title />
                        </span>
                    }
                >
                    <div style={styles.matrix}>
                        <Block
                            with={['country', 'mandates', 'positions', 'listedRelations', 'politic', 'justice']}
                            title="Direct index"
                            data={report.direct}
                            type="direct"
                        />
                    </div>
                </Views.Modal.Show>
            </span>
        );
    }
}
