import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Dialog from 'material-ui/Dialog';

import Button from '../common/button';
import Form from '../form/container';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';


const styles = {
    container: {
        overflow: 'scroll',
        marginBottom: '74px',
    },
    content: {
        maxWidth: '800px'
    },
    body: {
        background: colors.white,
        padding: '20px',
        overflow: 'auto',
    },
    actions: {
        padding: '0 20px 20px 20px'
    },
    submit: {
        marginLeft: '10px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.modal.edition';

    static propTypes = {
        data: PropTypes.object,
        label: PropTypes.node,
        style: PropTypes.object,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
    };

    static defaultProps = {
        label: 'Submit'
    };

    state = {
        open: false,
        extra: {},
        changed: false,
        error: false,
        locked: false
    };

    open (extra = {}) {
        this.setState({
            open: true,
            extra: extra,
            changed: false,
            error: false,
            locked: false
        });
    }

    close (extra = {}) {
        this.setState({
            open: false,
            extra: extra,
            changed: false,
            error: false,
            locked: false
        });
    }

    getData () {
        return this.refs.form.getData();
    }

    setData (data) {
        this.refs.form.setData(data || {});
    }

    unlock () {
      this.setData();
      this.setState({ locked: false, });
    }

    handleChange (data) {
        if (this.props.onChange) {
            const result = this.props.onChange(data);

            if (_.isObject(result)) {
                this.refs.form.setData(result);
            }
        }

        this.setState({
            error: this.refs.form.hasError(),
            changed: true
        });
    }

    handleSubmit () {
        if (! this.props.onSubmit || this.state.locked || _.isEmpty(this.refs.form.getData())) {
            return;
        }

        this.setState({ open: false, locked: true });

        const formData = this.refs.form.getData();
        this.props.onSubmit(formData);
    }

    render () {
        return (
            <Dialog
                modal
                open={ this.state.open }
                style={ styles.container }
                contentStyle={ Object.assign({}, styles.content, this.props.style) }
                bodyStyle={ styles.body }
                actionsContainerStyle={ styles.actions }
                actions={[
                    this.renderCancel(),
                    this.renderSubmit()
                ]}
            >
                <Form
                    ref="form"
                    data={ Object.assign({}, this.props.data || {}, this.state.extra) }
                    onChange={ this.handleChange.bind(this) }
                >
                    { this.props.children }
                </Form>
            </Dialog>
        );
    }

    renderCancel () {
        return (
            <Button
                type="flat"
                color="hot"
                icon={ <Icon type="do.cancel" /> }
                label="Cancel"
                onClick={ event => {
                    if (this.props.onCancel) {
                        this.props.onCancel();
                    }

                    this.setState({ open: false })
                } }
            />
        );
    }

    renderSubmit () {
        return (
            <Button
                type="raised"
                style={ styles.submit }
                color="cold"
                icon={ <Icon type="do.submit" /> }
                label={ this.props.label }
                disabled={ ! this.props.onSubmit || this.state.error || ! this.state.changed }
                onClick={ this.handleSubmit.bind(this) }
            />
        );
    }
}
