import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'inline-block',
        color: colors.grey500,
        cursor: 'pointer',
        borderBottom: '1px solid ' + colors.grey300,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.topRelated';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        is: PropTypes.string,
        name: PropTypes.string,
    };

    render() {
        if (!this.props.id) {
            return <span style={styles.container}>&nbsp;</span>;
        }

        return (
            <span style={styles.container} onClick={event => this.context.openEntity(this.props.id)}>
                {this.props.name}
            </span>
        );
    }
}
