// @flow

const UUID_PATTERN = /[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/i;
const EMAIL_PATTERN = /^[a-z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i;

import { get, gt, isString } from 'lodash';
import Moment from 'moment';

export function isUuid(value: mixed): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    return !!UUID_PATTERN.test(value);
}

export function isTransitionAllowed(status: string, transition: string, transitions: Object): boolean {
    const rule = get(transitions, transition);

    if (!rule) {
        return false;
    }

    return rule.from.includes(status);
}

export function isMoreThanZero(value: mixed): boolean {
    if (typeof value !== 'number') {
        return false;
    }

    return gt(value, 0);
}

export function isDate(value: string): boolean {
    return isString(value) ? Moment(value).isValid() : false;
}

export function isEmail(value: mixed): boolean {
    if (typeof value !== 'string') {
        return false;
    }

    return !!EMAIL_PATTERN.test(value);
}

export function addWorkDays(momentFrom: string, days: number) {
    const cursor = Moment(momentFrom);
    let count = days;

    while (count > 0) {
        //if (![6, 7].includes(cursor.isoWeekday()))
        count--;
        cursor.add(1, 'days');
    }

    return cursor;
}

export function countWorkDays(momentFrom: string, momentTo: string): number {
    const cursor = Moment(momentFrom);
    let count = 0;

    while (cursor.isBefore(Moment(momentTo))) {
        //if (![6, 7].includes(cursor.isoWeekday()))
        count++;
        cursor.add(1, 'days');
    }

    return count;
}
