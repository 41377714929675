// @flow

import type { LinkMandate, RelationNode, EntityInnerTarget, LinkProfile } from '@deecision/deefind-types/model';

import slugify from 'speakingurl';
import makeLinkBase from '../link/base';
import { makeHighestLevel } from '../link/level';
import { makeDateYear } from './utils';

export default function makeRelationNode(source: EntityInnerTarget, link: LinkProfile): RelationNode {
    const wires = (link.mandates || []).concat(link.positions || []);
    const base = makeLinkBase(wires);
    const ceased = base.ceased || (link.target.type === 'company' && !!link.target.closed);

    const forward =
        makeHighestLevel(
            (link.mandates || [])
                .filter(mandate => ceased || !mandate.ceased)
                .map((mandate: LinkMandate) => mandate.level),
        ) || undefined;

    const backward =
        makeHighestLevel(
            (link.positions || [])
                .filter(mandate => ceased || !mandate.ceased)
                .map((mandate: LinkMandate) => mandate.level),
        ) || undefined;

    return {
        target: link.target,
        mandates: link.mandates,
        positions: link.positions,
        owned: link.owned && (ceased || !link.owned.ceased) ? link.owned : undefined,
        shared: link.shared && (ceased || !link.shared.ceased) ? link.shared : undefined,
        level: makeHighestLevel([forward, backward]) || 'o',
        width: wires.length,
        proxemia: makeProxemia(source, wires, link.target),
        family: makeFamily(source, link.target),
        guessed: base.guessed,
        ceased,
        start: makeDateYear(base.start) || undefined,
        end: makeDateYear(base.end) || undefined,
        forward,
        backward,
    };
}

function makeProxemia(source: EntityInnerTarget, mandates: LinkMandate[], target?: EntityInnerTarget): 1 | 2 | 3 {
    const levels = mandates.map(link => link.level || 'o');
    const shareholders = levels.filter(level => ['s', 'i'].includes(level)).length;
    const boardMembers = levels.filter(level => ['b'].includes(level)).length;

    if (target && makeFamily(source, target) > 0) {
        return 3;
    }

    if ((levels.length === 1 && shareholders > 0) || (levels.length <= 3 && shareholders > 1) || shareholders > 1) {
        return 3;
    }

    if (shareholders > 0 || boardMembers > 0) {
        return 2;
    }

    return 1;
}

function makeFamily(source: EntityInnerTarget, target: EntityInnerTarget): number {
    if (source.type !== 'person' || target.type !== 'person') {
        return 0;
    }

    const a = slugify(source.lastName);
    const b = slugify(target.lastName);

    return a && b && (a === b || a.includes(b) || b.includes(a)) ? 1 : 0;
}
