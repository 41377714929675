import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import RadioButton from 'material-ui/RadioButton';
import Checkbox from 'material-ui/Checkbox';

import union from 'lodash/union';
import difference from 'lodash/difference';

const styles = {
    item: {
        padding: '3px 5px',
        width: '500px',
    },
    other: {
        display: 'flex',
        marginBottom: '5px',
    },
    otherItem: {
        width: 'auto',
        marginRight: '10px',
        padding: '3px 5px',
    },
    otherInput: {
        width: '100%',
        background: colors.white,
        border: '1px solid ' + colors.grey400,
        padding: '5px',
        margin: '-5px 0',
        borderRadius: '3px',
        fontFamily: 'inherit',
        fontSize: 'inherit',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.select';

    static propTypes = {
        options: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
        answer: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleChange(value) {
        this.props.onChange(Object.assign({}, this.props.answer, value));
    }

    render() {
        const multiple = this.props.options.multiple || false;
        const listedChoices = this.props.options.choices || [];
        const checkedChoices = this.props.answer.choices || [];

        if (this.props.readOnly && !checkedChoices.length) {
            return null;
        }

        const choices = listedChoices.map((choice, index) => {
            const checked = checkedChoices.includes(index);

            return this.renderChoice(choice, checked, multiple, event =>
                this.handleChange({
                    choices: multiple
                        ? checked
                            ? difference(checkedChoices, [index])
                            : union(checkedChoices, [index])
                        : [index],
                }),
            );
        });

        if (this.props.options.other) {
            choices.push(this.renderOther(checkedChoices, multiple));
        }

        return choices;
    }

    renderChoice(choice, checked, multiple, onCheck) {
        const props = {
            key: 'choice' + choice.label,
            checked,
            onCheck,
            label: choice.label,
            disabled: this.props.readOnly,
            style: styles.item,
            iconStyle: { fill: checked ? colors.black : colors.grey500 },
            labelStyle: { color: checked ? colors.black : colors.grey500 },
        };

        return multiple ? <Checkbox {...props} /> : <RadioButton {...props} />;
    }

    renderOther(checkedChoices, multiple) {
        const checked = checkedChoices.includes('other');
        const props = {
            checked,
            label:"Other:",
            disabled: this.props.readOnly,
            style: styles.otherItem,
            iconStyle:{ fill: checked ? colors.userCold : colors.grey500 },
            labelStyle:{ color: checked ? colors.userCold : colors.grey700 },
            onCheck:(event) => {
                this.handleChange({
                    choices: multiple
                        ? checked
                            ? difference(checkedChoices, ['other'])
                            : union(checkedChoices, ['other'])
                        : ['other'],
                })
            }
        }
        return (
            <div key="other" style={styles.other}>
            {multiple ? <Checkbox {...props} /> : <RadioButton {...props} />}


                {checked ? (
                    <input
                        style={styles.otherInput}
                        value={this.props.answer.other || ''}
                        disabled={this.props.readOnly}
                        onChange={event =>
                            this.handleChange({
                                other: event.target.value,
                            })
                        }
                    />
                ) : null}
            </div>
        );
    }
}
