import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../common/button';
import Properties from '../common/properties';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

import Avatar from 'material-ui/Avatar';
import Dialog from 'material-ui/Dialog';

const styles = {
    container: {
        overflow: 'scroll',
        marginBottom: '74px'
    },
    content: {
        maxWidth: '600px'
    },
    body: {
        padding: '20px',
        display: 'flex'
    },
    actions: {
        padding: '0 20px 20px 20px'
    },
    remove: {
        marginLeft: '10px'
    },
    message: {
        flexGrow: '1',
        marginLeft: '20px'
    },
    title: {
        fontSize: '19px',
        lineHeight: '24px',
        paddingTop: '8px',
        color: colors.black,
    },
    preview: {
        marginTop: '20px',
        padding: '20px',
        background: colors.grey100,
        borderRadius: '3px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.modal.removal';

    static propTypes = {
        subject: PropTypes.string.isRequired,
        onSubmit: PropTypes.func.isRequired,
        style: PropTypes.object
    };

    state = {
        open: false
    };

    open () {
        this.setState({ open: true });
    }

    handleRemove () {
    }

    render () {
        return (
            <Dialog
                open={ this.state.open }
                style={ styles.container }
                contentStyle={ Object.assign({}, styles.content, this.props.style) }
                bodyStyle={ styles.body }
                actionsContainerStyle={ styles.actions }
                actions={[
                    this.renderCancel(),
                    this.renderRemove()
                ]}
            >
                <Avatar
                    backgroundColor={ colors.userHot }
                    icon={ <Icon type="do.delete" /> }
                />

                <div style={ styles.message }>
                    <div style={ styles.title }>
                        Do you really want to remove this { this.props.subject }?
                    </div>

                    <div style={ styles.preview }>
                        { this.props.children }
                    </div>
                </div>
            </Dialog>
        );
    }

    renderProperties () {
        if (! this.props.properties)
            return null;

        const values = [];
        _.each(this.props.properties, (value, label) => {
            values.push({ value: value, label: label });
        });

        return (
            <Properties
                style={ styles.properties }
                values={ values }
            />
        );
    }

    renderCancel () {
        return (
            <Button
                type="flat"
                color="hot"
                icon={ <Icon type="do.cancel" /> }
                label="No, cancel"
                onClick={ event => this.setState({ open: false }) }
            />
        );
    }

    renderRemove () {
        return (
            <Button
                type="raised"
                style={ styles.remove }
                color="hot"
                icon={ <Icon type="do.delete" /> }
                label="Yes, remove"
                onClick={ this.props.onSubmit }
            />
        );
    }
}
