import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Button from 'app/views/common/button';
import Checkbox from 'material-ui/Checkbox';
import StartIcon from 'material-ui/svg-icons/navigation/check';

const styles = {
    info: {
        margin: '6px 0 25px 0',
        color: colors.grey500,
        fontSize: '13px'
    },
    button: {
        display: 'block',
        width: '120px',
        margin: '0 auto'
    },
    warning: {
        color: colors.userHot
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.layout.security.login.start';

    static propTypes = {
        style: PropTypes.object,
        onStart: PropTypes.func.isRequired
    };

    state = { checked: false };

    handleSubmit () {
        this.props.onStart(this.state.checked);
    }

    render () {
        return (
            <div style={ this.props.style }>
                <Checkbox
                    label="Keep this session alive"
                    onCheck={ (event, checked) => this.setState({ checked }) }
                />

                <div style={ styles.info }>
                    If you do so,&nbsp;
                    <span style={ this.state.checked ? styles.warning : {} }>
                        anybody accessing to your browser in the future may automatically connect to your account
                    </span>
                    .
                </div>

                <Button
                    type="raised"
                    color="cold"
                    label="Start"
                    icon={ <StartIcon /> }
                    style={ styles.button }
                    onClick={ this.handleSubmit.bind(this) }
                />
            </div>
        );
    }
}
