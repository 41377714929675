// @flow

import type { KernelMode } from '@deecision/infra-types/server';

const ports = {
    base: 6000,
    services: { cleerance: 10, deefind: 20, deemand: 30, reech: 40, comeet: 50, deeligenz: 60, teem: 70, reeport: 80, deegger: 90},
    modes: { api: 1, bot: 2, hub: 3 },
};

// domains by environment
const domains = {
    prod: 'deecision.com',
    dev: 'deecision.dev',
};

// globals services domains
const globals = {
    deegger: 'deegger.deecision.ovh',
};

export function makePort(service: string, mode: KernelMode): number {
    if (!ports.services[service]) {
        throw new Error(`Tried to locate port for invalid "${service}" service.`);
    }

    if (!ports.modes[mode]) {
        throw new Error(`Tried to locate port for invalid "${mode}" mode.`);
    }

    return ports.base + ports.services[service] + ports.modes[mode];
}

export function makeUrl(domain: string, service: string, mode: KernelMode): string {
    if (domain === 'localhost') {
        return `http://localhost:${makePort(service, mode)}`;
    }

    if (globals[service]) {
        return `https://${globals[service]}`;
    }

    return `https://${domain}`;
}

export function makeBucket(domain: string, space: string): string {
    if (domain === 'localhost') {
        return `local.${space}`;
    }

    if (domain.endsWith(`.${domains.dev}`)) {
        return `dev.${space}`;
    }

    if (!domain.endsWith(`.${domains.prod}`)) {
        throw new Error(`Tried to locate bucket for invalid "${domain}" domain.`);
    }

    return `${domain.split('.')[0]}.${space}`;
}
