// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Moment from 'moment';
import Planning from './planning';
import compute from './compute';
import Checklist from './checklist';
import { COUNTRIES } from '../../studies/common/survey/field/widget/scalar/country';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({
    store: stores.deeligenzStudies,
    teamMates: stores.auth.teamMates,
    contacts: stores.auth.contacts,
    sessionUser: stores.global.session,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.calendar.workload.list';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        teamMates: PropTypes.object.isRequired,
        style: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const start = new Date().toISOString();

        this.state = {
            year: parseInt(start.substr(0, 4)),
            month: parseInt(start.substr(5, 2)),
            date: Moment(),
            checked: [],
        };
    }

    buildMoment() {
        return Moment(`${this.state.year}-${this.state.month}-1`, 'YYYY-M-D');
    }

    moveMonth(offset: number) {
        const moment = this.buildMoment().add(offset, 'months');

        this.setState({ year: moment.year(), month: moment.month() + 1 });
    }

    renderBadge(label, status, warning) {
        return (
            <div
                style={{
                    width: '50px',
                    textAlign: 'center',
                    color: 'white',
                    border: '1px solid white',
                    fontSize: '12px',
                    background: status ? colors.userGreen : warning ? colors.userHot : colors.userCold,
                    display: 'inline-block',
                    padding: '3px',
                    marginTop: '6px',
                }}
            >
                {label.toUpperCase()}
            </div>
        );
    }

    renderDetail(type, payload) {
        if (type === 'fund') {
            return (
                <div
                    style={{
                        display: 'flex',
                        marginTop: '5px',
                        color: colors.grey800,
                    }}
                >
                    <Icon
                        type="deeligenz.company"
                        style={{
                            fill: colors.userCold,
                            marginRight: '5px',
                            width: '18px',
                            height: '18px',
                        }}
                    />

                    <span title={payload.manager} style={{ lineHeight: '18px' }}>
                        {payload.manager}
                    </span>
                </div>
            );
        }

        return (
            <div
                style={{
                    display: 'flex',
                    marginTop: '5px',
                    color: colors.grey800,
                }}
            >
                <img
                    style={{ height: '12px', width: '16px', float: 'left', margin: '3px 6px 0 0' }}
                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${(
                        payload.country || ''
                    ).toLowerCase()}.svg`}
                />
                {COUNTRIES[payload.country] || ''}
            </div>
        );
    }

    renderOccupation(user, computed) {
        const studies = [];
        for (const item of computed) {
            const study = this.props.store.studies.get(item.study);
            studies.push(
                <div key={user.id + '-' + study.id} style={{ marginBottom: '10px' }}>
                    <div
                        style={{ display: 'inline-block', width: '250px', paddingRight: '10px', verticalAlign: 'top' }}
                    >
                        {/* <div><Icon style={{color: colors.userCold}} type="dna.company" /> <span style={{ verticalAlign: 'super'}}>{study.payload.manager.toUpperCase()}</span></div>
              <div style={{ paddingLeft: '10px', color: colors.grey600}}>0 days left, 0% complete</div> */}
                        <Views.Common.Header
                            light
                            icon={
                                <Icon
                                    type={study.payload.target.type === 'fund' ? 'deeligenz.fund' : 'deeligenz.company'}
                                />
                            }
                            title={study.payload.target.type === 'fund' ? study.payload.fund : study.payload.manager}
                            content={[
                                this.renderDetail(study.payload.target.type, study.payload),
                                `Exp. AUM ${study.payload.expectedAum}M€`,
                            ]}
                            // aside={[<Rank value={10} />]}
                        />
                    </div>

                    <div
                        style={{
                            display: 'inline-block',
                            textAlign: 'right',
                            width: '110px',
                            marginBottom: '10px',
                            verticalAlign: 'top',
                            paddingTop: '15px',
                        }}
                    >
                        <div
                            style={{
                                verticalAlign: 'bottom',
                                marginRight: '10px',
                                display: 'inline-block',
                                borderRadius: '50%',
                                background: colors.userCold,
                                padding: '5px',
                            }}
                        >
                            <Icon style={{ width: '15px', height: '15px', color: 'white' }} type="do.open" />
                        </div>
                        {/* <div style={{ verticalAlign: 'initial', display: 'inline-block', marginRight: '10px', background: 'white',color: colors.userCold, border: '2px solid '+colors.userCold, width: '20px', height: '20px'}}>
                <div style={{verticalAlign: 'middle', textAlign: 'center', lineHeight: '20px'}}>5</div>
              </div> */}
                        <div style={{ verticalAlign: 'intial', display: 'inline-block' }}>
                            {this.renderBadge(item.status, false, item.warning)}
                        </div>
                    </div>
                    <hr />
                </div>,
            );
        }

        return (
            <div key={user.id}>
                <div>
                    <Icon style={{ color: colors.userCold }} type="main.account" />{' '}
                    <span style={{ color: colors.userCold, fontWeight: 'bold', verticalAlign: 'super' }}>
                        {user.firstName} {user.lastName}
                    </span>
                </div>
                <div style={{ borderLeft: '1px solid ' + colors.userCold, marginLeft: '12px', paddingLeft: '20px' }}>
                    {studies}
                </div>
            </div>
        );
    }

    renderDay(occupations) {
        const data = [];

        if (occupations[this.state.date.format('YYYY-MM-DD')]) {
            for (const userId in occupations[this.state.date.format('YYYY-MM-DD')]) {
                const teamMate = this.props.teamMates.filter(item => item.id === userId)[0];

                if (!teamMate || ! (!!this.state.checked.indexOf(teamMate.id))) {
                    continue;
                }

                const user = this.props.contacts.users[userId];
                if (user)
                  data.push(this.renderOccupation(user, occupations[this.state.date.format('YYYY-MM-DD')][userId]));
            }
        }

        return (
            <div
                style={{
                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 10px',
                    marginLeft: '10px',
                    marginRight: '10px',
                    background: colors.bgCold,
                    color: colors.grey800,
                    padding: '10px',
                }}
            >
                <div style={{ marginBottom: '25px', fontSize: '19px' }}>{this.state.date.format('D MMMM YYYY')}</div>

                {data}
            </div>
        );
    }

    render() {
        if (!this.props.store || !this.props.teamMates.length) {
            return null;
        }

        if (!toJS(this.props.store.loaded)) {
            return <Views.Common.Loader style={this.props.style} title="Loading data" />;
        }

        // compute data from store studies {[date]: {[name]: [{computed: {status: 'DDQ | ONS | INP | REP'}, odd: uuid}]}}
        const studies = Object.values(toJS(this.props.store.studies));
        const occupations = compute(
            studies.filter(item => {
                if (!!this.state.owner === true && item.ownerUid !== toJS(this.props.sessionUser).account) {
                    return false;
                }

                if (!this.state.status || this.state.status === 'all') {
                    return true;
                }

                return this.state.status === item.status;
            }),
        );
        // this.security.updateState({filter: {toto: 'tata'}})
        return (
            <div style={Object.assign({}, this.props.style, { fontFamily: 'Roboto' })}>
                <div style={{ marginBottom: '10px' }}>
                    <div style={{ display: 'inline-block', verticalAlign: 'top', width: '201px', textAlign: 'center' }}>
                        <Views.Common.Button
                            onClick={() => {
                                this.refs.modal.open();
                            }}
                            color="cold"
                            icon={<Icon type="main.account" />}
                            label="MEMBERS"
                        />
                        <Views.Modal.Show ref="modal" icon={<Icon type="base.attendee" />} title={'Members'}>
                            <div>
                                <Checklist
                                    items={(this.props.teamMates
                                    ).map(item => {

                                        return {
                                            id: item.user.id,
                                            checked: this.state.checked.indexOf(item.user.id) > -1,
                                            label: `${item.user.firstName} ${item.user.lastName}`,
                                        };
                                    })}
                                    onChange={teamMates =>
                                        {
                                          this.setState(
                                            {checked: teamMates.filter(i => i.checked === true).map(i => i.id)},
                                            state => {
                                                this.context.updateState(
                                                    Object.assign({}, this.props.state.filter, {
                                                        filter: { checked: this.state.checked },
                                                    }),
                                                );
                                            },
                                          )
                                      }
                                    }
                                />
                            </div>
                        </Views.Modal.Show>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'top', width: '756px', textAlign: 'right' }}>
                        {/*<div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>*/}
                        {/*    <Views.Common.Toggle*/}
                        {/*        key="status"*/}
                        {/*        value={this.props.state.filter ? this.props.state.filter.owner : null}*/}
                        {/*        items={[*/}
                        {/*            {*/}
                        {/*                value: 'owner',*/}
                        {/*                label: 'My ODDs',*/}
                        {/*                count: studies.filter(*/}
                        {/*                    item => item.ownerUid === toJS(this.props.sessionUser).account,*/}
                        {/*                ).length,*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/*        onChange={toggle => {*/}
                        {/*            this.context.updateState({*/}
                        {/*                filter: Object.assign({}, this.props.state.filter, {*/}
                        {/*                    owner: toggle,*/}
                        {/*                }),*/}
                        {/*            });*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div style={{ display: 'inline-block' }}>
                        <Views.Common.Toggle
                            title="ONGOING"
                            key="status"
                            value={this.state.filter ? this.state.filter.status : this.props.state.filter ? this.props.state.filter.status || 'all' : 'all'}
                            style={{ width: '500px', marginRight: '0' }}
                            items={[
                                { value: 'all', label: 'All', count: studies.length },
                                {
                                    value: 'draft',
                                    label: 'Draft',
                                    count: studies.filter(item => item.status === 'draft').length,
                                },
                                {
                                    value: 'input',
                                    label: 'Input',
                                    count: studies.filter(item => item.status === 'input').length,
                                },
                                {
                                    value: 'ddq',
                                    label: 'Analysis',
                                    count: studies.filter(item => item.status === 'ddq').length,
                                },
                                {
                                    value: 'onsite',
                                    label: 'Meeting',
                                    count: studies.filter(item => item.status === 'onSite').length,
                                },
                                {
                                    value: 'report',
                                    label: 'Report',
                                    count: studies.filter(item => item.status === 'report').length,
                                },
                            ]}
                            onChange={toggle => {
                                this.setState({filter: { status: toggle }}, state => this.context.updateState({
                                    filter: Object.assign({}, this.props.state.filter, { status: toggle }),
                                }));
                            }}
                        />
                        </div>
                    </div>
                </div>
                <div id="planning" style={{ width: '958px', display: 'inline-block', verticalAlign: 'top' }}>
                    <Planning
                        users={
                          (this.props.teamMates
                          ).map(item => {
                              return {
                                  id: item.user.id,
                                  checked: this.state.checked.indexOf(item.user.id) > -1,
                                  firstName: item.user.firstName,
                                  lastName: item.user.lastName
                              };
                          })
                        }
                        data={occupations}
                        year={this.state.year}
                        month={this.state.month}
                        date={this.state.date}
                        status={(this.props.state.filter || this.state.filter || {}).status}
                        onClick={date => {
                            this.setState({ date });
                        }}
                        onPrevious={() => {
                            this.moveMonth(-1);
                        }}
                        onNext={() => {
                            this.moveMonth(1);
                        }}
                    />
                </div>
                <div style={{ width: '442px', display: 'inline-block', verticalAlign: 'top', marginTop: '60px' }}>
                    {this.renderDay(occupations)}
                </div>
            </div>
        );
    }
}
