import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';

import Filter from './filter';
import Field from './field';
import List from './list';

import { inject, observer } from 'mobx-react';
import { filterFields } from '@deecision/deeligenz-utils/compute/filter';
import { toJS } from 'mobx';

const styles = {
    container: {},
    filter: {
        margin: '20px 0',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies, contacts: stores.auth.contacts }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.studies.common.survey';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        withAnswer: true,
        withFlags: true,
        withAutoFlags: true,
        withComment: true,
        withStatus: false,
        withNa: true,
        withNd: true,
        withRemark: true,
        withCorrection: false,
        readOnly: false,
        external: false,
        additional: false,
    };

    static propTypes = {
        additional: PropTypes.bool,
        entity: PropTypes.object.isRequired,
        scope: PropTypes.string.isRequired,
        onScope: PropTypes.func.isRequired,
        onCreate: PropTypes.func,
        onAnswer: PropTypes.func,
        onCorrection: PropTypes.func,
        onRemark: PropTypes.func,
        onFlags: PropTypes.func,
        onApply: PropTypes.func,
        onComment: PropTypes.func.isRequired,
        withAnswer: PropTypes.bool,
        withRemark: PropTypes.bool,
        withCorrection: PropTypes.bool,
        withFlags: PropTypes.bool,
        withAutoFlags: PropTypes.bool,
        withComment: PropTypes.bool,
        withStatus: PropTypes.bool,
        withAnalysis: PropTypes.bool,
        withNa: PropTypes.bool,
        withNd: PropTypes.bool,
        state: PropTypes.object,
        readOnly: PropTypes.bool,
        external: PropTypes.bool,
    };

    state = {
        fields: null,
    };

    componentDidUpdate(prev: Object) {
        if (prev.entity.id !== this.props.entity.id) {
            this.setState({ fields: null });
        }
    }

    render() {
        if (!this.props.store.loaded) {
            return <Views.Common.Loader title="Loading data" />;
        }

        const fields = this.props.entity.fields ? this.props.entity.fields.slice() : [];

        if (!fields.length) {
            return null;
        }

        return (
            <div style={styles.container}>
                <Filter
                    additional={this.props.additional}
                    ref="filter"
                    onScope={this.props.onScope}
                    onChange={(data, fields) => {
                        this.context.updateState({ filter: data });
                        this.setState({ fields: fields, refresh: false });
                    }}
                    refresh={!this.state.fields}
                    readOnly={this.props.readOnly}
                    data={this.props.state ? this.props.state.filter || {} : {}}
                    fields={fields}
                    filteredFields={this.state.fields || []}
                    withStatus={this.props.withStatus}
                    withAnalysis={this.props.withAnalysis}
                    style={styles.filter}
                    entity={this.props.entity}
                />

                <List baseHeight={140}>
                    {(this.state.fields || []).map((id, index) => (
                        <Field
                            key={id}
                            id={id}
                            index={index + 1}
                            scope={this.props.scope}
                            onAnswer={
                                this.props.onAnswer
                                    ? async payload => { await this.props.onAnswer(id, payload); this.setState({refresh:true})}
                                    : undefined
                            }
                            onCorrection={
                                this.props.onCorrection ? async payload => { await this.props.onCorrection(id, payload);  this.setState({refresh:true})} : undefined
                            }
                            onRemark={
                                this.props.onRemark
                                    ? (type, remark) => this.props.onRemark(id, type, remark)
                                    : undefined
                            }
                            onFlags={this.props.onFlags ? flags => this.props.onFlags(id, flags) : undefined}
                            onApply={
                                this.props.onApply && !this.props.readOnly
                                    ? transition => this.props.onApply(id, transition)
                                    : undefined
                            }
                            onComment={(channel, content) => this.props.onComment(id, content)}
                            withRemark={this.props.withRemark}
                            withCorrection={this.props.withCorrection}
                            withAutoFlags={this.props.withAutoFlags}
                            withFlags={this.props.withFlags}
                            withStatus={this.props.withStatus}
                            withNa={this.props.withNa}
                            withNd={this.props.withNd}
                            external={this.props.external}
                            readOnly={this.props.readOnly}
                        />
                    ))}
                </List>
            </div>
        );
    }
}
