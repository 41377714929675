import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import Views from 'app/views';
import Sum from 'dna/entity/common/display/sum';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.blocks.companies';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <DeefindProvider
                state={this.props.state}
                type="network"
                id={this.props.entity.id}
                depth={1}
                mini
                renderEntity={(network, state) => (
                    <Views.Common.Properties values={this.formatValues(network.computed.sums.companies)} align={80} />
                )}
            />
        );
    }

    formatValues(aggregates) {
        if (!aggregates) {
            return [];
        }

        return [
            {
                label: t('common.label.sumTurnover'),
                tooltip: t('common.tooltip.turnoverSumCompanies'),
                value: <Sum data={aggregates.turnover} />,
            },
            {
                label: t('dna.deefind.veegilenzData.person.sumCapitalLabel'),
                tooltip: t('common.tooltip.capitalSum'),
                value: <Sum data={aggregates.capital} />,
            },
            {
                label: t('dna.deefind.veegilenzData.person.sumEbitdaLabel'),
                tooltip: t('common.tooltip.ebitdaSum'),
                value: <Sum data={aggregates.ebitda} />,
            },
            {
                label: t('dna.deefind.veegilenzData.person.sumValuationLabel'),
                tooltip: t('common.tooltip.valuationSum'),
                value: <Sum data={aggregates.valuation} />,
            },
        ];
    }
}
