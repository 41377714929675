// @flow

import type { CoreLogger } from '@deecision/infra-types/client';

import Moment from 'moment';

export default function(debug?: boolean): CoreLogger {
    function prefix() {
        return `[${Moment().format('HH:mm:ss')}]`;
    }

    return {
        debug(message: string, context: Object = {}): void {
            if (debug) {
                console.log(prefix(), message, context);
            }
        },

        warn(message: string, context: Object = {}): void {
            console.warn(prefix(), message, context);
        },

        alert(message: string, context: Object = {}): void {
            console.error(prefix(), message, context);
        },

        catch(error: Error, context: Object = {}): void {
            console.error(prefix(), error.message, Object.assign({}, context, { error }));
        },
    };
}
