import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Checkbox from 'material-ui/Checkbox';
import RadioButton from 'material-ui/RadioButton/RadioButton';
import RadioButtonGroup from 'material-ui/RadioButton/RadioButtonGroup';
import { toJS } from 'mobx';

const styles = {
    templates: {
        width: '300px',
        margin: '0 auto 30px auto',
        textAlign: 'left',
        paddingLeft: '90px',
    },
    kind: {
        lineHeight: '24px',
        color: colors.userCold,
        margin: '30px 0 10px 0',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.start';

    static propTypes = {
        management: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
        onStart: PropTypes.func.isRequired,
        templates: PropTypes.array.isRequired,
    };

    state = { open: false, categories: {}, started: false, selected: null };

    render() {
        if (this.state.started) {
            return <Views.Common.Loader title="Initializing Due Diligence" />;
        }

        if (!this.state.open) {
            return (
                <Views.Common.Empty
                    title="This due diligence is a draft"
                    key="empty"
                    actions={[
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            icon={<Icon type="do.open" />}
                            label="Start Due Diligence"
                            onClick={event => this.setState({ open: true })}
                        />,
                    ]}
                />
            );
        }

        return (
            <Views.Common.Empty
                title="Select template"
                key="empty"
                content={this.renderSelect()}
                actions={[
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        icon={<Icon type="do.open" />}
                        label="Validate and start"
                        disabled={!this.state.selected}
                        onClick={event =>
                            this.setState({ open: false, categories: [], started: true }, () =>
                                this.props.onStart(this.state.selected),
                            )
                        }
                    />,
                ]}
            />
        );
    }

    renderSelect() {
        const templates = this.props.templates.filter(
            template => template.payload.target.type === this.props.entity.payload.target.type,
        );

        if (!templates.length) {
            return <div>No template found, please contact your manager.</div>;
        }

        const children = [];

        for (const template of templates) {
            children.push(<RadioButton key={template.id} value={template.id} label={template.payload.label} />);
        }

        return (
            <RadioButtonGroup
                name="template"
                style={styles.templates}
                onChange={(event, value) => this.setState({ selected: value })}
            >
                {children}
            </RadioButtonGroup>
        );
    }

    computeCategories() {
        const categories = [];

        if (!this.props.management.managerCategoriesList.length) {
            // we are in MDD
            for (const kind in toJS(this.props.management.categoriesList)) {
                for (const id of toJS(this.props.management.categoriesList.get(kind))) {
                    const category = toJS(this.props.management.categoriesMap.get(id));

                    if (!category) {
                        continue;
                    }

                    categories.push({
                        id,
                        label: category.payload.label,
                        kind: category.payload.kind,
                        active: true,
                    });
                }
            }
        } else {
            categories.push(<div style={styles.kind}>Manager</div>);
            const variant = this.props.entity.payload.target.variant;

            for (const id of this.props.management.managerCategoriesList) {
                const category = toJS(this.props.management.categoriesMap.get(id));

                if (!category) {
                    continue;
                }

                categories.push({
                    id,
                    label: category.payload.label,
                    kind: 'M',
                    active:
                        get(this.props.entity, 'payload.target.type') === 'fund' ||
                        (category.payload.activations || []).includes(variant),
                });
            }

            if (this.props.entity.payload.target.type === 'fund') {
                for (const id of this.props.management.fundCategoriesList) {
                    const category = toJS(this.props.management.categoriesMap.get(id));

                    if (!category) {
                        continue;
                    }

                    categories.push({
                        id,
                        label: category.payload.label,
                        kind: 'F',
                        active: (category.payload.activations || []).includes(variant),
                    });
                }

                for (const id of this.props.management.servicesCategoriesList) {
                    const category = toJS(this.props.management.categoriesMap.get(id));

                    if (!category) {
                        continue;
                    }

                    categories.push({
                        id,
                        label: category.payload.label,
                        kind: 'S',
                        active: (category.payload.activations || []).includes(variant),
                    });
                }
            }
        }

        return categories;
    }

    renderCategories(title, kind, categories, selection) {
        if (!title && !kind) {
            return Object.keys(toJS(this.props.management.categoriesList)).map(index => {
                const categories = this.props.management.categoriesList.get(index);
                return (
                    <div>
                        <div style={styles.kind}>{index}</div>
                        {categories.map(id => {
                            const category = toJS(this.props.management.categoriesMap.get(id));
                            return (
                                <Checkbox
                                    key={category.id}
                                    label={category.payload.label}
                                    checked={selection.includes(category.id)}
                                    onCheck={(event, checked) => {
                                        this.setState({
                                            categories: Object.assign({}, this.state.categories, { [id]: checked }),
                                        });
                                    }}
                                />
                            );
                        })}
                    </div>
                );
            });
        }

        if (['F', 'S'].includes(kind) && this.props.entity.payload.target.type !== 'fund') {
            return null;
        }

        return (
            <div>
                <div style={styles.kind}>{title}</div>

                {categories
                    .filter(category => category.kind === kind)
                    .map(category => (
                        <Checkbox
                            key={category.id}
                            label={category.label}
                            checked={selection.includes(category.id)}
                            onCheck={(event, checked) => {
                                this.setState({
                                    categories: Object.assign({}, this.state.categories, { [category.id]: checked }),
                                });
                            }}
                        />
                    ))}
            </div>
        );
    }
}
