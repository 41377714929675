import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../common/button';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Dialog from 'material-ui/Dialog';

const styles = {
    container: {
        overflow: 'scroll',
        marginBottom: '74px'
    },
    header: {
        color: colors.userCold,
        background: colors.grey100,
        borderBottom: '1px solid ' + colors.grey300,
        fontSize: '17px',
        lineHeight: '40px',
        padding: '10px 0',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        zIndex: 99
    },
    icon: {
        fill: colors.grey500,
        width: '24px',
        height: '24px',
        marginLeft: '10px',
        flexGrow: '0',
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: '10px',
        flexGrow: '1',
    },
    close: {
        margin: '0 10px',
        flexGrow: '0',
        minWidth: '110px',
    },
    content: {
        maxWidth: '1500px',
    },
    body: {
        padding: '80px 20px 20px 20px',
        overflow: 'auto',
        color: colors.black,
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.modal.show';

    static propTypes = {
        style: PropTypes.object,
        title: PropTypes.node,
        icon: PropTypes.element,
        onClose: PropTypes.func,
        open: PropTypes.bool
    };

    state = {
        open: !!this.props.open
    };

    open () {
        this.setState({ open: true });
    }

    close () {
        this.setState({ open: false });
    }

    handleClose () {
        this.setState({ open: false }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
    }

    render () {
        return (
            <Dialog
                open={ this.state.open }
                style={ styles.container }
                onRequestClose={ this.handleClose.bind(this) }
                contentStyle={ Object.assign({}, styles.content, this.props.style) }
                bodyStyle={ Object.assign({}, styles.body, _.pick(this.props.style, [ 'background' ])) }
                title={ this.renderTitle() }
            >
                { this.props.children }
            </Dialog>
        );
    }

    renderTitle () {
        if (! this.props.title && ! this.props.onClose) {
            return null;
        }

        return (
            <div style={ styles.header }>
                { this.renderIcon() }

                <span title={this.props.title} style={ styles.title }>
                    { this.props.title }
                </span>

                <Button
                    style={ styles.close }
                    type="flat"
                    label="Close"
                    icon={ <Icon type="do.close" /> }
                    color="hot"
                    onClick={ this.handleClose.bind(this) }
                />
            </div>
        );
    }

    renderIcon () {
        if (! this.props.icon)
            return null;

        return React.cloneElement(this.props.icon, {
            style: Object.assign({}, styles.icon, this.props.icon.props.style)
        });
    }
}
