import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import { get } from 'lodash';
import t from 'app/translation';
import fibenLogo from 'app/../../assets/fiben.svg';
import Icon from 'app/theme/icon';

const styles = {
    year: {
        color: colors.grey500,
        display: 'inline-block',
        width: '45px',
    },
    old: {
        color: colors.userWarning,
    },
    veryold: {
        color: colors.userHot,
    },
    linkFiben: {
        cursor: 'pointer',
        marginLeft: '80px',
        marginBottom: '-15px',
    },
    modalFiben: {
        maxWidth: '666px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.blocks.financial';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        const publicationDate = get(this.props.entity, `data.indicators.0.year`, []);

        let fibenButton = '';
        if ((publicationDate || 0) <= new Date().getFullYear() - 4) {
            fibenButton = (
                <div style={styles.linkFiben}>
                    <Views.Modal.Confirm
                        ref="modal"
                        title={t('dna.compliance.fibenModalLabel')}
                        icon={ <Icon type="dna.report" /> }
                        message={' '}
                        style={styles.modalFiben}
                    >
                    </Views.Modal.Confirm>

                    <a onClick={() => this.refs['modal'].open()} >
                        <img src={fibenLogo} width={60} />
                    </a>
                </div>
            );
        }

        return (
            <div>
                <Views.Common.Properties values={this.getValues()} align={70} />
                {fibenButton}
            </div>
        );
    }


    getValues() {
        return [
            { label: t('common.label.turnover'), value: this.renderValue('turnover') },
            { label: t('common.label.ebitda'), value: this.renderValue('ebitda') },
            { label: t('common.label.capital'), value: this.renderValue('capital') },

            {
                label: t('common.label.valuation'),
                value: <Views.Common.Number type="currency" scale="M" value={this.props.entity.preview.valuation} />,
            },
        ];
    }

    renderValue(property) {
        // @TODO
        const historyValues = get(this.props.entity, `data.indicators`, []).map(item => item[property]);
        const historyYears = get(this.props.entity, `data.indicators`, []).map(item => item.year);

        let value = undefined;
        let year = undefined;

        for (let index = 0; index < 10; index++) {
            if (historyValues[index]) {
                value = historyValues[index];
                year = historyYears[index];
                break;
            }
        }

        if (!value || !year) {
            return (
                <Views.Common.Number type="currency" scale="M" value={get(this.props.entity, `preview.${property}`)} />
            );
        }

        let age = false;
        if ((year || 0) <= new Date().getFullYear() - 3) {
            age = 'old';
        }
        if ((year || 0) <= new Date().getFullYear() - 4) {
            age = 'veryold';
        }

        return (
            <span>
                <span style={Object.assign({}, styles.year, age ? styles[age] : {})}>{year}</span>
                <Views.Common.Number type="currency" scale="M" value={value} />
            </span>
        );
    }

    openFibenModal(){
        console.log('groskwik')
    }
}
