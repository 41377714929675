// @flow

import type { CoreChannel, CoreLogger } from '@deecision/infra-types/client';

import uuid from 'uuid';
import Ignore from './ignore';

export default function<T>(logger: CoreLogger): CoreChannel<T> {
    const listeners: { [id: string]: Function } = {};
    const ignore = Ignore<T>();

    return {
        async send(event: T): Promise<void> {
            if (ignore(event)) {
                return;
            }

            const promises: Promise<any>[] = [];

            for (const id of Object.keys(listeners)) {
                promises.push(listeners[id](event));
            }

            await Promise.all(promises);
        },

        listen(listener: (event: T) => any): Function {
            if (typeof listener !== 'function') {
                console.error(`Tried to register non-function listener.`);
                return () => {};
            }

            const id = uuid.v4();
            listeners[id] = listener;

            return () => delete listeners[id];
        },

        clear(): void {
            for (const id of Object.keys(listeners)) {
                delete listeners[id];
            }
        },
    };
}
