// @flow

import type { AuthSession, AuthUser, AuthUserSession } from '@deecision/infra-types/common';

import omit from 'lodash/omit';

export function makeSession(user: AuthUser, token: string): AuthUserSession {
    const accounts = user.accounts || [];

    return {
        level: accounts.length ? 'account' : 'intervention',
        token,
        user,
    };
}

export function cleanSession(session: AuthSession): AuthSession {
    if (session.level === 'anonymous') {
        return session;
    }

    const clean = data => omit(data, 'credentials');
    session.user.accounts = session.user.accounts.map(clean);
    session.user.interventions = session.user.interventions.map(clean);

    return session;
}
