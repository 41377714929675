import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { forEach } from 'lodash';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Line from './line';
import t from 'app/translation';
import { formatStockValue } from '../../utils';
import Number from 'app/views/common/number';

const styles = {
    container: {},
    loading: {
        padding: '5px 0 15px 0',
        color: colors.userHot,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    row: {
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        display: 'flex',
        color: colors.grey700,
        lineHeight: '35px',
        borderTop: '1px solid ' + colors.grey300,
    },
    header: {
        borderBottom: '1px solid ' + colors.grey300,
        color: colors.userCold,
        marginBottom: '5px',
        cursor: 'pointer',
    },
    sorted: {
        borderBottom: '2px solid ' + colors.userHot,
    },
    label: {
        width: '50%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        color: colors.userCold,
    },
    value: {
        paddingLeft: '15px',
        textAlign: 'right',
        width: '10%',
        whiteSpace: 'nowrap',
    },
    indent: {
        display: 'inline-block',
        width: '25px',
        marginRight: '10px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.weasse.list';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        items: PropTypes.array.isRequired,
        patents: PropTypes.object.isRequired,
    };

    state = {
        opened: [],
        sort: 'turnover',
    };

    render() {
        const items = this.props.items;

        if (!items || (Array.isArray(items) && ! items.length)) {
            return <Views.Common.Empty title={t('dna.deefind.veegilenzData.common.noStock')} />;
        }

        if (!Array.isArray(items)) {
            return <Views.Common.Empty title={`${items.total} companies found`} />;
        }

        const company = () => {
            return (
                <span
                    style={this.state.sort === 'name' ? Object.assign({}, styles.label, styles.sorted) : styles.label}
                    onClick={event => this.setState({ sort: 'name' })}
                >
                    <span style={styles.indent} />
                    {t('common.label.company')}
                </span>
            );
        };

        const value = (name, label) => {
            return (
                <span
                    style={this.state.sort === name ? Object.assign({}, styles.value, styles.sorted) : styles.value}
                    onClick={event => this.setState({ sort: name })}
                >
                    {label}
                </span>
            );
        };

        return (
            <div style={styles.container}>
                <div style={Object.assign({}, styles.row, styles.header)}>
                    {company()}
                    {value('turnover', t('common.label.turnover'))}
                    {value('capital', t('common.label.capital'))}
                    {value('ebitda', t('common.label.ebitda'))}
                    {value('val.min', t('dna.deefind.veegilenzData.person.financial.valMinLabel'))}
                    {value('val.max', t('dna.deefind.veegilenzData.person.financial.valMaxLabel'))}
                    {value('stock', t('dna.deefind.veegilenzData.person.financial.stockLabel'))}
                    {value('total.min', t('dna.deefind.veegilenzData.person.financial.totalMinLabel'))}
                    {value('total.max', t('dna.deefind.veegilenzData.person.financial.totalMaxLabel'))}
                </div>

                {this.resolveLines(items).map(this.renderLine.bind(this))}
                {this.RenderSums(items)}
            </div>
        );
    }

    RenderSums(items) {
        const sums = this.sumValues(items);
        return (
            <div style={styles.row}>
                <span style={styles.label}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TOTAL
                </span>

                <Number value={sums.turnover} type="currency" scale="M" style={styles.value} />
                <Number value={sums.capital} type="currency" scale="M" style={styles.value} />
                <Number value={sums.ebitda} type="currency" scale="M" style={styles.value} />
                <Number value={sums.min} type="currency" scale="M" style={styles.value} />
                <Number value={sums.max || undefined} type="currency" scale="M" style={styles.value} />
                <span style={styles.value}>—</span>
                <Number value={sums.totalMin || undefined} type="currency" scale="M" style={styles.value} />
                <Number value={sums.totalMax || undefined} type="currency" scale="M" style={styles.value} />
            </div>
        );
    }

    renderLine(line: Object, index: number) {
        const stock = line.stock || {};
        const target = stock.target || {};

        return (
            <Line
                key={index}
                target={target}
                stock={stock}
                level={line.level}
                index={line.index}
                open={this.state.opened.includes(target.id)}
                onOpen={handle => this.setState({ opened: _.union(this.state.opened, [handle]) })}
                onClose={handle => this.setState({ opened: _.difference(this.state.opened, [handle]) })}
            />
        );
    }

    resolveLines(stocks, level = 0, offset = 0) {
        let lines = [];
        let index = offset;

        for (const stock of this.sortStocks(stocks)) {
            lines.push({ index: index++, level, stock });

            if (!stock.children) {
                continue;
            }

            if (!this.state.opened.includes(stock.target.id)) {
                continue;
            }

            if (!Array.isArray(stock.children)) {
                lines.push({
                    stock: { target: { name: `${stock.children.total} companies` } },
                    level: level + 1,
                    index: index++,
                });
                continue;
            }

            if (stock.children.length) {
                const children = this.resolveLines(stock.children, level + 1, index);
                lines = lines.concat(children);
                index += children.length;
            }
        }

        return lines;
    }

    sumValues(stocks) {
        const sums = {};
        sums.turnover = _.sumBy(stocks, stock => this.preview(stock, 'turnover', 0));
        sums.capital = _.sumBy(stocks, stock => this.preview(stock, 'capital', 0));
        sums.ebitda = _.sumBy(stocks, stock => this.preview(stock, 'ebitda', 0));
        sums.min = _.sumBy(stocks, stock => this.valuation(stock, 'min'));
        sums.max = _.sumBy(stocks, stock => this.valuation(stock, 'max'));
        sums.totalMin = _.sumBy(stocks, stock => stock.total ? stock.total.min || 0 : 0);
        sums.totalMax = _.sumBy(stocks, stock => stock.total ? stock.total.max || 0 : 0);

        return sums;
    }

    preview(stock, property, fallback) {
        return _.get(stock, `target.${property}`, fallback);
    };
    
    valuation(stock, type){
        const valuation = _.get(stock, `target.valuation`);

        if (!valuation || !valuation.value) {
            return 0;
        }

        switch (type) {
            case 'min':
                return valuation.value - (valuation.deviation || 0);

            case 'max':
                return valuation.value + (valuation.deviation || 0);
        }

        return 0;
    }

    sortStocks(stocks) {
        stocks = _.orderBy(stocks, stock => (stock.direct ? 1 : 0), 'desc');

        switch (this.state.sort) {
            case 'name':
                return _.orderBy(stocks, stock => this.preview(stock,'name', ''), 'asc');
            case 'turnover':
                return _.orderBy(stocks, stock => this.preview(stock,'turnover', 0), 'desc');
            case 'capital':
                return _.orderBy(stocks, stock => this.preview(stock,'capital', 0), 'desc');
            case 'ebitda':
                return _.orderBy(stocks, stock => this.preview(stock, 'ebitda', 0), 'desc');
            case 'val.min':
                return _.orderBy(stocks, stock => this.valuation(stock,'min'), 'desc');
            case 'val.max':
                return _.orderBy(stocks, stock => this.valuation(stock,'max'), 'desc');
            case 'stock':
                return _.orderBy(stocks, stock => stock.value ? stock.value.min || 0 : 0, 'desc');
            case 'total.min':
                return _.orderBy(stocks, stock => stock.total ? stock.total.min || 0 : 0, 'desc');
            case 'total.max':
                return _.orderBy(stocks, stock => stock.total ? stock.total.max || 0 : 0, 'desc');
        }

        return stocks;
    }
}
