// @flow

import type { DriveStore, DriveFile, DriveFormat } from './types';
import type { SdkService } from '@deecision/infra-types/client';

export default function(sdk: SdkService): DriveStore {
    async function locate(file: DriveFile): Promise<string> {
        return sdk.file.locate(file.service, file.bucket, file.id);
    }

    async function download(file: DriveFile, name: string): Promise<number> {
        console.log('JNG - drive.download - name', name);
        const location = await locate(file);
        const response = await fetch(location, { method: 'HEAD' });

        if (response.status === 200) {
            const link = window.document.createElement('a');
            link.setAttribute('href', location);
            //link.setAttribute('target', '_blank');
            link.setAttribute('download', name || 'unnamed-file');
            link.style.display = '';
            window.document.body.appendChild(link);
            link.click();
            window.document.body.removeChild(link);
        }

        return response.status;
    }

    async function upload(file: DriveFile, content: string): Promise<void> {
        const url = await locate(file);
        const options = { method: 'POST', body: content, mode: 'cors' };
        let trial = 0;

        while (trial < 3) {
            const response: Response = await fetch(url, options);
            if (response.status < 299) return;
            trial ++;
        }

        throw new Error('Upload failed 3 times.');
    }

    async function read(file: DriveFile, format: DriveFormat): Promise<string> {
        const url = await locate(file);
        const result = await fetch(url, { method: 'GET', mode: 'cors' });

        const blob = await result.blob();
        const reader = new FileReader();

        const promise = new Promise((resolve: Function, reject: Function) => {
            reader.onload = event => resolve(event.target.result);
            reader.onerror = event => reject(new Error('Failed to read downloaded file.'));
        });

        switch (format) {
            case 'url':
                reader.readAsDataURL(blob);
                break;

            case 'buffer':
                reader.readAsArrayBuffer(blob);
                break;

            case 'binary':
                reader.readAsBinaryString(blob);
                break;

            case 'utf8':
                reader.readAsText(blob, 'utf8');
                break;
        }

        return promise;
    }

    return { locate, upload, download, read };
}
