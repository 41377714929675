import PropTypes from 'prop-types';
import React from 'react';
import Toggle from 'material-ui/Toggle';
import colors from 'app/theme/colors';

export default class extends React.PureComponent<any, any> {

    static displayName = 'boolean';

    static propTypes = {
        value: PropTypes.bool,
        label: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onError: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        readOnly: PropTypes.bool
    };

    static defaultProps = {
        value: false,
        style: {},
        readOnly: false
    };

    render () {
        return (
            <Toggle
                style={{ width: 'auto', margin: '13px auto' }}
                label={this.props.label}
                toggled={ !! this.props.value }
                onToggle={ (event, value) => this.props.onChange(value) }
                onFocus={ this.props.onFocus }
            />
        );
    }
}
