import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import Scope from 'app/layout/providers/scope';
import Card from 'dna/entity/card';
import Clusters from 'dna/entity/common/display/clusters';
import get from 'lodash/get';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject('reech')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.company.display.tabs.contacts';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired,
        relations: PropTypes.object,
    };

    render() {
        if (!this.props.network.relations) {
            return null;
        }

        const contacts = this.props.network.relations.persons
            .concat(this.props.network.relations.companies)
            .map(item => Object.assign({}, item, { id: item.target.id }));

        const clusters = get(this.props, 'network.clusters.persons', []).concat(get(this.props, 'network.clusters.companies', []));

        const reechCustomers = this.props.reech.customers;
        const reechProspects = this.props.reech.prospects;
        const reechKnown = this.props.reech.known;
        const reechCount = reechKnown.length;

        return (
            <Scope path="contacts" type="object">
                <div>
                    <Clusters list={clusters} />

                    <Scope path="list" type="list">
                        <Views.List.Container entities={contacts}>
                            <Views.List.Frame
                                type="cards"
                                element={<Card parent={this.props.entity} />}
                            />

                            <Views.List.Reducer
                                type="sort"
                                specs={{
                                    relation: {
                                        label: 'Rel. strength',
                                        path: item => item.strength || 0,
                                        type: 'desc',
                                    },
                                    name: {
                                        label: 'Name',
                                        path: 'target.name',
                                        type: 'asc',
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="current"
                                title="Current"
                                type="groups"
                                value="yes"
                                specs={{
                                    yes: {
                                        label: '✓',
                                        info: 'Current relations',
                                        filter: item => item.ceased === false,
                                    },
                                    no: {
                                        label: '✕',
                                        info: 'Ceased relations',
                                        filter: item => item.ceased === true,
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="reech"
                                type="groups"
                                title="Reech"
                                specs={{
                                    any: {
                                        label: '✓',
                                        info: 'Any Reech match',
                                        filter: item => reechKnown.includes(item.id),
                                    },
                                    customer: {
                                        label: 'C',
                                        info: 'Customer match',
                                        filter: item => reechCustomers.includes(item.id),
                                    },
                                    prospect: {
                                        label: 'P',
                                        info: 'Prospect match',
                                        filter: item => reechProspects.includes(item.id),
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="mandate"
                                type="groups"
                                title="Relation type"
                                specs={{
                                    boa: {
                                        label: 'Boa',
                                        info: 'Board members',
                                        filter: item => item.level === 'b',
                                    },
                                    adv: {
                                        label: 'Adv',
                                        info: 'Advisors',
                                        filter: item => item.level === 'a',
                                    },
                                    cou: {
                                        label: 'Cou',
                                        info: 'Counselor',
                                        filter: item => item.level === 'c',
                                    },
                                    exe: {
                                        label: 'Exe',
                                        info: 'Executive',
                                        filter: item => item.level === 'x',
                                    },
                                    man: {
                                        label: 'Man',
                                        info: 'Managers',
                                        filter: item => item.level === 'm',
                                    },
                                    beneficial: {
                                        label: 'BO',
                                        info: 'Beneficial owners',
                                        filter: item => item.owned && item.owned.beneficial,
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="compliance"
                                type="groups"
                                title={t('common.label.compliance')}
                                specs={{
                                    listed: {
                                        label: 'Lis',
                                        info: 'Listed companies',
                                        filter: item => item.target.flags.includes('listed'),
                                    },
                                    holding: {
                                        label: 'Hol',
                                        info: 'Holding companies',
                                        filter: item => item.target.flags.includes('holding'),
                                    },
                                    fund: {
                                        label: 'Fun',
                                        info: 'Holding companies',
                                        filter: item => item.target.flags.includes('fund'),
                                    },
                                    justice: {
                                        label: 'Jus',
                                        info: 'Justice issues',
                                        filter: item => item.target.flags.includes('justice'),
                                    },
                                    politic: {
                                        label: 'Pol',
                                        info: 'Politically exposed',
                                        filter: item => item.target.flags.includes('politic'),
                                    },
                                    c19: {
                                        label: 'c19',
                                        info: 'Sensitivity to covid19 crisis',
                                        filter: item => (item.target.flags || []).includes('crisis-impact') || (item.target.flags || []).includes('crisis-direct'),
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="type"
                                type="groups"
                                title="Type"
                                specs={{
                                    persons: {
                                        label: <Icon type="dna.person" style={{ margin: '3px 0', fill: 'inherit' }} />,
                                        info: 'Individual contacts',
                                        filter: item => item.target.type === 'person',
                                    },
                                    companies: {
                                        label: <Icon type="dna.company" style={{ margin: '3px 0', fill: 'inherit' }} />,
                                        info: 'Company contacts',
                                        filter: item => item.target.type === 'company',
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                type="fuse"
                                specs={{ paths: ['preview.firstName', 'preview.lastName', 'preview.name'] }}
                            />
                        </Views.List.Container>
                    </Scope>
                </div>
            </Scope>
        );
    }
}
