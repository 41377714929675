import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'flex-start',
    },
    timeLeft: {
        display: 'inline-block',
        color: colors.userGreen,
        marginRight: '15px',
    },
    timeLate: {
        display: 'inline-block',
        color: colors.userHot,
        marginRight: '15px',
    },
    progress: {
        display: 'inline-block',
        color: colors.userCold,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.status.input';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        withFlag: PropTypes.bool,
    };

    render() {
        const computed = this.props.entity.computed || {};

        return (
            <span style={styles.container}>
                {this.renderTimeLeft(computed.timeLeft)}
                {this.renderProgress(computed.progress || {})}
            </span>
        );
    }

    renderTimeLeft(timeLeft) {
        if (timeLeft < 0) {
            return <span style={styles.timeLate}>{-timeLeft + ' days late'}</span>;
        }

        if (timeLeft > 0) {
            return <span style={styles.timeLeft}>{timeLeft + ' days left'}</span>;
        }

        return <span style={styles.timeLate}>Time over</span>;
    }

    renderProgress(progress) {
        return <span style={styles.progress}>{(progress.input || 0) + ' % complete'}</span>;
    }
}
