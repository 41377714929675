// @flow

export default {
    type: 'menu',
    pinned: [{
        type: 'dashboard',
        pinned: true
    }],
    prototype: {
        type: 'router',
        routes: {
            dashboard: {
                pinned: {
                    type: 'boolean'
                },
            },
        }
    }
};
