// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import Summary from './summary';
import Display from './display';

import { inject, observer } from 'mobx-react';
import get from 'lodash/get';

@inject(stores => ({ store: stores.deeligenzStudies, session: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.archives';

    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        openStudy: PropTypes.func.isRequired,
        createStudy: PropTypes.func.isRequired,
        updateStudy: PropTypes.func.isRequired,
        removeStudy: PropTypes.func.isRequired,
        fetchStudy: PropTypes.func.isRequired,
        startStudy: PropTypes.func.isRequired,
        applyStudy: PropTypes.func.isRequired,
        setStudyParagraph: PropTypes.func.isRequired,
        setStudyHeader: PropTypes.func.isRequired,
        setStudyRating: PropTypes.func.isRequired,
        setStudyOnSite: PropTypes.func.isRequired,
        commentStudy: PropTypes.func.isRequired,
        answerField: PropTypes.func.isRequired,
        answerEditField: PropTypes.func.isRequired,
        applyField: PropTypes.func.isRequired,
        setFieldFlags: PropTypes.func.isRequired,
        setFieldRemark: PropTypes.func.isRequired,
        commentField: PropTypes.func.isRequired,
        getFileUrl: PropTypes.func.isRequired,
        downloadFile: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired,
    };

    getChildContext() {
        return {
            openStudy: this.props.store.openArchive,
            createStudy: this.props.store.createStudy,
            updateStudy: this.props.store.updateStudy,
            removeStudy: this.props.store.removeStudy,
            fetchStudy: this.props.store.fetchStudy,
            startStudy: this.props.store.startStudy,
            applyStudy: this.props.store.applyStudy,
            commentStudy: this.props.store.commentStudy,
            setStudyParagraph: this.props.store.setStudyParagraph,
            setStudyHeader: this.props.store.setStudyHeader,
            setStudyRating: this.props.store.setStudyRating,
            setStudyOnSite: this.props.store.setStudyOnSite,
            answerField: this.props.store.answerField,
            answerEditField: this.props.store.answerEditField,
            applyField: this.props.store.applyField,
            setFieldFlags: this.props.store.setFieldFlags,
            setFieldRemark: this.props.store.setFieldRemark,
            commentField: this.props.store.commentField,
            getFileUrl: this.props.store.getFileUrl,
            downloadFile: this.props.store.downloadFile,
            uploadFile: this.props.store.uploadFile,
        };
    }

    componentDidMount() {
        // todo: do not refresh everything, just the ta label!
        //this.disposeListener = this.props.store.studies.observe(() => this.forceUpdate());
    }

    componentWillUnmount() {
        //this.disposeListener();
    }

    render() {
        const session = this.props.session.get();

        return (
            <Screen
                path="archives"
                type="workspace"
                fallback="reducer"
                select={this.props.store.selectArchiveStage}
                close={this.props.store.closeArchiveStage}
                stages={{
                    summary: {
                        entry: { icon: 'deeligenz.summary', label: 'Summary' },
                        content: Summary,
                    },
                    display: {
                        entry: this.computeDisplayEntry.bind(this),
                        content: Display,
                    },
                }}
            />
        );
    }

    computeDisplayEntry(state?: Object) {
        let label = 'Due diligence';

        if (state) {
            const study = this.props.store.studies.get(state.id);

            if (study && study.payload) {
                label = study.payload.fund ? study.payload.fund : study.payload.manager;
            }
        }

        return {
            icon: 'deeligenz.study',
            label,
        };
    }
}
