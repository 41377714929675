// @flow

import type {
    SdkWatch,
    SdkWorker,
    ApiError,
    ApiProgress,
    CoreChannel,
    CoreLogger,
    PortFeedback,
    PortWatchQuery,
    SdkWatcher,
} from '@deecision/infra-types/client';

import Channel from '../core/channel';
import uuid from 'uuid';
import assign from 'lodash/assign';
import get from 'lodash/get';

export default function<T>(worker: SdkWorker, logger: CoreLogger): SdkWatcher<T> {
    return function<T>(watch: PortWatchQuery, type: string): SdkWatch<T> {
        const progressChannel: CoreChannel<ApiProgress> = Channel(logger);
        const errorChannel: CoreChannel<ApiError> = Channel(logger);
        const dataChannel: CoreChannel<T> = Channel(logger);

        const token = uuid.v4();
        const query = assign({}, watch, { token });
        const state = {};

        const dispose = worker.send(query, async (feedback: PortFeedback) => {
            console.log('watch feedback', watch.type, watch.model, feedback);

            if (feedback.type === type) {
                const data: T = get(feedback, 'data');
                state.data = data;

                return dataChannel.send(data);
            }

            switch (feedback.type) {
                case 'progress':
                    return progressChannel.send({
                        queue: feedback.queue,
                        step: feedback.step,
                        percent: feedback.percent,
                    });

                case 'error':
                    const error = { code: feedback.code, reason: feedback.reason };
                    state.error = error;

                    return errorChannel.send(error);
            }

            logger.alert(`Received invalid "${feedback.type}" feedback for "${watch.type}" watch.`);
        });

        return {
            onProgress(listener: (progress: ApiProgress) => any): Function {
                return progressChannel.listen(listener);
            },

            onError(listener: (error: ApiError) => any): Function {
                return errorChannel.listen(listener);
            },

            onValue(listener: (data: T) => any): Function {
                return dataChannel.listen(listener);
            },

            get(): Promise<T> {
                if (state.hasOwnProperty('data')) {
                    return Promise.resolve(state.data);
                }

                if (state.hasOwnProperty('error')) {
                    return Promise.reject(state.error);
                }

                return new Promise((resolve: Function, reject: Function) => {
                    const dispose1 = dataChannel.listen((data: T) => {
                        resolve(data);
                        dispose1();
                        dispose2();
                    });

                    const dispose2 = errorChannel.listen((error: ApiError) => {
                        reject(error);
                        dispose1();
                        dispose2();
                    });
                });
            },

            end(): void {
                console.log('end watch call');
                progressChannel.clear();
                errorChannel.clear();
                dataChannel.clear();
                dispose();
            },
        };
    };
}
