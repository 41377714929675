import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.moment';

    static propTypes = {
        date: PropTypes.string.isRequired,
        time: PropTypes.string,
        style: PropTypes.object
    };

    render () {
        let moment = Moment(this.props.date).format('ddd D MMMM YYYY');

        if (this.props.time)
            moment += ' at ' + Moment(`${this.props.date.substr(0, 10)}T${this.props.time}`).format('hh:mm');

        return (
            <span style={ this.props.style }>
                { moment }
            </span>
        );
    }
}
