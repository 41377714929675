import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

let styles = {
    container: {
        margin: '20px 0'
    },
    title: {
        fontSize: '15px',
        fontWeight: 'bold',
        color: colors.userCold,
        margin: '0',
        padding: '0 0 10px 20px'
    },
    content: {
        background: colors.grey100,
        padding: '20px',
        borderRadius: '5px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.section';

    static propTypes = {
        title: PropTypes.node,
        style: PropTypes.object
    };

    render () {
        if (! this.props.title)
            return (
                <div style={ Object.assign({}, styles.content, this.props.style) }>
                    { this.props.children }
                </div>
            );

        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <h4 style={ styles.title }>
                    { this.props.title }
                </h4>
                <div style={ styles.content }>
                    { this.props.children }
                </div>
            </div>
        );
    }
}
