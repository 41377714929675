import React from 'react';
import Application from 'app/layout/application';

import Prospects from './prospects/index';
import t from 'app/translation';
import { inject, observer } from 'mobx-react';

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem';

    render () {
        const flags = this.props.global.flags.get();

        return (
            <Application
                path="teem"
                title="Deecision Teem"
                index="projects"
                entries={[
                    {
                        path: 'projects',
                        icon: 'comeet.prospect',
                        label: t('dna.teem.prospects'),
                        screen: Prospects,
                        access: flags.includes('teem.access'),
                    },
                    {
                        icon: 'comeet.customer',
                        label: t('dna.comeet.customers'),
                        access: flags.includes('deefind.access'),
                    }
                ]}
            />
        );
    }
}
