// @flow

import React from 'react';
import sum from 'lodash/sum';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Rule from './rule';
import Charts from './charts';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import t from 'app/translation';

const styles = {
    container: {
        display: 'flex',
    },
    properties: {
        background: colors.grey100,
        borderRadius: '3px',
        padding: '20px',
        width: '25%',
        lineHeight: '25px',
    },
    main: {
        padding: '20px',
        width: '75%',
    },
    subtitle: {
        marginBottom: '10px',
        color: colors.grey500,
        borderBottom: '1px solid ' + colors.grey300
    },
};

@inject(stores => ({ store: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.management.dashboard.prospects';

    props: {
        store: any
    };

    render () {
        const stats = this.computeStats();

        return (
            <div style={ styles.container }>
                <div style={ styles.properties }>
                    <div style={ styles.subtitle }>
                        {t('common.label.count')}
                    </div>

                    <Views.Common.Properties
                        align={ 130 }
                        values={[
                            { label: t('number'), value: stats.totalCount || '—' },
                            { label: t('dna.comeet.prospect.ongoing'), value: stats.startedCount || '—' },
                            { label: t('dna.comeet.prospect.won'), value: stats.succeededCount || '—' },
                            { label: t('dna.comeet.prospect.lost'), value: stats.failedCount || '—' },
                            { label: t('dna.comeet.prospect.aborted'), value: stats.canceledCount || '—' },
                        ]}
                    />

                    <br/>

                    <div style={ styles.subtitle }>
                        {t('dna.comeet.prospect.rates')}
                    </div>

                    <Views.Common.Properties
                        align={ 130 }
                        values={[
                            {
                                label: 'conversionRate',
                                value: stats.conversionRate ? (Math.round(10 * stats.conversionRate) / 10) + ' %' : '—'
                            },
                        ]}
                    />

                    <br/>

                    <div style={ styles.subtitle }>
                    {t('dna.comeet.prospect.averages')}
                    </div>

                    <Views.Common.Properties
                        align={ 130 }
                        values={[
                            {
                                label: t('dna.comeet.prospect.conversionTime'),
                                value: stats.averageConversionTime ? (Math.round(10 * stats.averageConversionTime) / 10) + ' months' : '—'
                            },
                            {
                                label: t('dna.comeet.prospect.netRevenue'),
                                value: (<Views.Common.Number value={ stats.averageNetRevenue } type="currency" />)
                            },
                            {
                                label: t('dna.comeet.prospect.eventsNb'),
                                value: (Math.round(10 * stats.averageEventsCount) / 10) || '—'
                            },
                        ]}
                    />
                </div>

                <div style={ styles.main }>
                    <Rule
                        title={t('dna.comeet.prospect.revenue')}
                        color={ colors.userCold }
                        max={{ label: 'Potential revenue', value: stats.potentialRevenue }}
                        points={[
                            { label: 'Net revenue', value: stats.netRevenue },
                            { label: 'Revenue', value: stats.realRevenue },
                        ]}
                    />

                    <Rule
                        title={t('dna.comeet.prospect.cost')}
                        color={ colors.userHot }
                        max={{ label: t('dna.comeet.budgetedCost'), value: stats.budgetedCost }}
                        points={[
                            { label: t('dna.comeet.prospect.cost'), value: stats.realCost },
                        ]}
                        style={{ marginTop: '25px' }}
                    />

                    <Charts
                        typeCosts={ stats.typeCosts }
                        statusCosts={ stats.statusCosts }
                        style={{ marginTop: '40px' }}
                    />
                </div>
            </div>
        );
    }

    computeStats () {
        const counts = { total: 0, started: 0, succeeded: 0, failed: 0, canceled: 0 };
        const values = { events: [], times: [], netRevenues: [] };
        const totals = { realRevenue: 0, potentialRevenue: 0, realCost: 0, budgetedCost: 0 };
        const typeCosts = {};
        const statusCosts = {};

        const entities = toJS(this.props.store.prospects);

        for (const id in entities) {
            const entity = entities[id];

            if (!entity || entity.status === 'draft') {
                continue;
            }

            const events = (entity.events || []).map(id => toJS(this.props.store.fields.get(id)));
            const cost = sum(events.map(event => this.computeCost(event)));

            for (const event of events) {
                const type = event.payload.settings.type || 'unknown';

                if (! typeCosts[type]) {
                    typeCosts[type] = 0;
                }

                typeCosts[type] += this.computeCost(event);
            }

            if (! statusCosts[entity.status]) {
                statusCosts[entity.status] = 0;
            }

            statusCosts[entity.status] += cost;

            const results = this.computeResults(entity.payload);

            totals.realCost += cost;
            if (results) {
                totals.budgetedCost += results.cost;
                totals.potentialRevenue += results.profit;
            }
            values.events.push(events.length);

            counts.total += 1;

            switch (entity.status) {
                case 'started':
                    counts.started += 1;
                    break;

                case 'won':
                    if (results) {
                        totals.realRevenue += results.profit;
                        values.netRevenues.push(results.profit);
                    }
                    counts.succeeded += 1;
                    break;

                case 'lost':
                    counts.failed += 1;
                    values.netRevenues.push(0);
                    break;

                case 'aborted':
                    counts.canceled += 1;
                    values.netRevenues.push(0);
                    break;
            }

            if (entity.payload.settings.period.start && entity.payload.settings.period.end) {
                values.times.push(moment(entity.payload.settings.period.end).diff(entity.payload.settings.period.start, 'months', true));
            }
        }

        return {
            totalCount: counts.total,
            startedCount: counts.started,
            succeededCount: counts.succeeded,
            failedCount: counts.failed,
            canceledCount: counts.canceled,
            conversionRate: (counts.succeeded + counts.failed + counts.canceled > 0) ? 100 * counts.succeeded / (counts.succeeded + counts.failed + counts.canceled) : 0,
            averageConversionTime: (values.times.length > 0) ? (sum(values.times) / values.times.length) : 0,
            averageNetRevenue: (values.netRevenues.length > 0) ? (sum(values.netRevenues) / values.netRevenues.length) : 0,
            averageEventsCount: (values.events.length > 0) ? (sum(values.events) / values.events.length) : 0,
            realRevenue: totals.realRevenue,
            netRevenue: totals.realRevenue - totals.realCost,
            potentialRevenue: totals.potentialRevenue,
            realCost: totals.realCost,
            budgetedCost: totals.budgetedCost,
            typeCosts,
            statusCosts,
        };
    }

    computeResults (entity: any) {
        if (! entity || ! entity.target || ! entity.target.fortune || ! entity.settings)
            return undefined;

        const aum = entity.target.fortune * entity.settings.aum / 100;
        const margin = aum * entity.settings.margin / 100;
        const profit = entity.settings.period * margin * (100 - entity.settings.attrition) / 100;
        const cost = profit * entity.settings.cost / 100;

        return { aum, profit, cost };
    }

    computeCost (event: any) {
        if (!event || !event.payload || !event.payload.settings) {
            return undefined;
        }

        return (event.payload.settings.unitPrice || 0)
            * (event.payload.settings.duration || 1)
            * (event.payload.settings.attendees || 1)
            + (event.payload.settings.offset || 0);
    }
}
