// @flow

import type StateStore from 'app/stores/state';
import type { ObservableMap } from 'mobx';
import type { GlobalStore } from '../core/types';

import { action } from 'mobx';
import { observable } from 'mobx';

const PATH = 'comeet.management';

export default class ComeetManagementStore {
    global: GlobalStore;
    state: StateStore;
    teams: string[];
    prospects: ObservableMap<string, Object>;
    fields: ObservableMap<string, Object>;

    constructor(global: GlobalStore, state: StateStore) {
        this.global = global;
        this.state = state;
        this.teams = [];
        this.prospects = observable.map();
        this.fields = observable.map();
    }

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };
}
