import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import colors from 'app/theme/colors';
import { ACTIVITIES } from 'dna/entity/aml';
import Country from 'dna/entity/common/country';
import { get } from 'lodash';
import t from 'app/translation';
import { inject } from 'mobx-react';
import DeefindProvider from 'app/layout/providers/deefind';

const styles = {
    activity: {
        margin: '0',
        color: colors.grey500,
    },
    link: {
        color: colors.userCold,
        textDecoration: 'underline',
        display: 'block',
        lineHeight: '20px',
    },
    amlFlag: {
        fontSize: '9px',
        lineHeight: '9px',
        background: colors.userHot,
        borderRadius: '3px',
        padding: '3px',
        display: 'inline-block',
        color: 'white',
        verticalAlign: 'text-bottom',
        marginRight: '5px',
    },
    icon: {
        width: '16px',
        height: '16px',
        marginRight: '10px',
        float: 'left',
    },
};

@inject('global')
export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.blocks.identity';

    static propTypes = {
        global: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
    };

    render() {
        if (!this.props.entity.data) {
            return null;
        }

        const webSites = get(this.props.entity, 'data.identity.websites', []);
        const isinCode = get(this.props.entity, 'preview.isins', []).join(', ');
        const country = <Country code={get(this.props.entity, 'preview.country')} />;

        const norkom = this.props.global.flags.get().includes('deefind.norkom')
            ? this.props.entity.vigilance.norkom.score
            : undefined;

        const website = webSites.length
            ? webSites.map((url, index) => (
                  <a style={styles.link} href={'http://' + url} target="_blank" key={index}>
                      {url}
                  </a>
              ))
            : undefined;

        return (
            <div>
                <Views.Common.Properties
                    values={[
                        { label: 'ISIN', value: isinCode.toUpperCase() },
                        { label: 'NACE', value: this.renderNaceCode() },
                        { label: t('common.label.country'), value: country },
                        norkom ? { label: 'Norkom', value: norkom } : { label: 'Web', value: website },
                    ]}
                    align={50}
                />
                <DeefindProvider
                    type="media"
                    id={this.props.entity.id}
                    mini={true}
                    renderEntity={(media, state) => this.renderLinks(media.links || [])}
                />
            </div>
        );
    }

    renderNaceCode() {
        const code = get(this.props.entity, 'preview.naceCode');

        if (!ACTIVITIES[code]) {
            return code || '—';
        }

        return (
            <span>
                <span style={styles.amlFlag}>AML</span>
                {code}
            </span>
        );
    }

    renderLinks(links: Object[]) {
        const wikipedia = links.filter(link => {
            if (link.url.includes('wikipedia.org')) {
                const result = link.url.match(/(\w+)\.wikipedia\.org/);
                const lang = result ? result[1].toUpperCase() : '';

                return ['FR', 'EN'].includes(lang);
            }

            return false;
        });

        const linkedin = links.filter(link => {
            return link.url.includes('linkedin.com');
        });

        const results = [];

        if (wikipedia.length) {
            results.push(
                <a key={wikipedia[0].url} href={wikipedia[0].url} target="_blank" style={styles.link}>
                    <img style={styles.icon} src={wikipedia[0].icon} />
                    Wikipedia
                </a>,
            );
        }

        if (linkedin.length) {
            results.push(
                <a key={linkedin[0].url} href={linkedin[0].url} target="_blank" style={styles.link}>
                    <img style={styles.icon} src={linkedin[0].icon} />
                    LinkedIn
                </a>,
            );
        }

        return results;
    }
}
