// @flow

import prospectsScope from './prospects/scope';

export default {
    type: 'object',
    children: {
        prospects: prospectsScope,
    }
};
