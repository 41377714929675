import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import colors from 'app/theme/colors';
import t from 'app/translation';
import DeefindProvider from 'app/layout/providers/deefind';
import Selector from '../../../common/news/selector';
import { inject } from 'mobx-react';
import Country from 'dna/entity/common/country';
import utils from '@deecision/deefind-utils';

const styles = {
    properties: {
        marginBottom: '5px',
    },
    link: {
        color: colors.userCold,
        textDecoration: 'underline',
        display: 'block',
        lineHeight: '20px',
    },
    icon: {
        width: '16px',
        height: '16px',
        marginRight: '10px',
        float: 'left',
    },
};

@inject('global')
export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.blocks.identity';

    static propTypes = {
        global: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
    };

    render() {
        let country = utils.normCountryCodeLower(_.get(this.props.entity.data, 'addresses.0.country'));
        const properties = [
            {
                label: t('common.label.birthdate'),
                value: this.props.entity.data.identity.birthDate,
            },
            {
                label: t('common.label.residence'),
                value: (
                    <Country
                        code={country}
                    />
                ),
            },
        ];

        if (this.props.global.flags.get().includes('deefind.norkom')) {
            properties.push({
                label: 'Norkom',
                value: this.props.entity.vigilance.norkom.score,
            });
        }

        return (
            <div>
                <Views.Common.Properties values={properties} align={70} style={styles.properties} />

                <DeefindProvider
                    type="media"
                    id={this.props.entity.id}
                    mini={true}
                    renderEntity={(media, state) => this.renderLinks(media.links || [])}
                />
            </div>
        );
    }

    renderLinks(links: Object[]) {
        const wikipedia = links.filter(link => {
            if (link.url.includes('wikipedia.org')) {
                const result = link.url.match(/(\w+)\.wikipedia\.org/);
                const lang = result ? result[1].toUpperCase() : '';

                return ['FR', 'EN'].includes(lang);
            }

            return false;
        });

        const linkedin = links.filter(link => {
            return link.url.includes('linkedin.com');
        });

        const results = [];

        if (wikipedia.length) {
            results.push(
                <a key={wikipedia[0].url} href={wikipedia[0].url} target="_blank" style={styles.link}>
                    <img style={styles.icon} src={wikipedia[0].icon} />
                    Wikipedia
                </a>,
            );
        }

        if (linkedin.length) {
            results.push(
                <a key={linkedin[0].url} href={linkedin[0].url} target="_blank" style={styles.link}>
                    <img style={styles.icon} src={linkedin[0].icon} />
                    LinkedIn
                </a>,
            );
        }

        return results;
    }
}
