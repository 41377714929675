import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.survey.list';

    static propTypes = {
        baseHeight: PropTypes.number.isRequired,
    };

    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleMove.bind(this));
        this.registerObservers();
    }

    componentDidUpdate() {
        this.observer.disconnect();
        this.registerObservers();
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    registerObservers() {
        for (const key of Object.keys(this.refs || {})) {
            const node = ReactDOM.findDOMNode(this.refs[key]);
            node.dataset.key = key;
            this.observer.observe(node);
        }
    }

    handleMove(entries) {
        for (const entry of entries || []) {
            const key = entry.target.dataset.key;
            const placeholder = key ? this.refs[key] : undefined;

            if (placeholder && placeholder.setVisibility) {
                placeholder.setVisibility(entry.isIntersecting);
            }
        }
    }

    render() {
        return React.Children.map(this.props.children, (element, index) => (
            <Placeholder
                ref={`placeholder_${index}`}
                key={`placeholder_${index}`}
                height={this.props.baseHeight}
                element={element}
            />
        ));
    }
}

class Placeholder extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.survey.list.placeholder';

    static propTypes = {
        height: PropTypes.number.isRequired,
        element: PropTypes.node.isRequired,
    };

    state = { visibility: false, height: null };

    setVisibility(visibility) {
        if (visibility && this.state.visibility !== visibility) {
            this.setState({ visibility });
        }
    }

    componentDidUpdate() {
        if (!this.state.visibility) {
            return;
        }

        const node = ReactDOM.findDOMNode(this.refs.wrapper);
        this.state.height = node.getBoundingClientRect().height;
    }

    render() {
        const height = this.state.visibility ? 'auto' : (this.state.height || this.props.height) + 'px';

        return (
            <div ref="wrapper" style={{ height }}>
                {this.state.visibility ? this.props.element : null}
            </div>
        );
    }
}
