// @flow

import type { CompanyData, PersonData, MediaCollection, LinkData } from '@deecision/deefind-types/model';

import { concatArrays, takeFirst } from './tools';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';

export function mergeCompanyData(list: CompanyData[]): ?CompanyData {
    if (!list.length) {
        return undefined;
    }

    return Object.assign(
        {},
        concatArrays(list, ['identifiers', 'addresses', 'activities', 'indicators', 'notations', 'auditors']),
        {
            identity: takeFirst(list, 'identity'),
            valuation: takeFirst(list, 'valuation'),
            financial: takeFirst(list, 'financial'),
            accounting: takeFirst(list, 'accounting'),
        },
    );
}

export function mergePersonData(list: PersonData[]): ?PersonData {
    if (!list.length) {
        return undefined;
    }

    return Object.assign({}, concatArrays(list, ['addresses', 'biographies', 'educations']), {
        identity: takeFirst(list, 'identity'),
    });
}

export function mergeMedia(list: MediaCollection[]): MediaCollection {
    const collection = concatArrays(list, ['news', 'links']);

    return {
        news: uniqBy(collection.news, 'url'),
        links: collection.links,
    };
}

export function mergeLinks(links: LinkData[]): LinkData {
    const mandates = flatten(links.map(data => data.mandates || []));
    const positions = flatten(links.map(data => data.positions || []));
    const stocks = flatten(links.map(data => data.stocks || []));
    const shareholders = flatten(links.map(data => data.shareholders || []));
    const connections = flatten(links.map(data => data.connections || []));

    return {
        mandates: mandates.length ? mandates : undefined,
        positions: positions.length ? positions : undefined,
        stocks: stocks.length ? stocks : undefined,
        shareholders: shareholders.length ? shareholders : undefined,
        connections: connections.length ? connections : undefined,
    };
}
