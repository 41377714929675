import PropTypes from 'prop-types';
import React from 'react';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ contacts: stores.auth.contacts }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'app.views.common.user';

    static propTypes = {
        id: PropTypes.string.isRequired,
        style: PropTypes.object
    };

    render () {
        return (
            <span style={ this.props.style }>
                { this.renderName(this.props.id) }
            </span>
        );
    }

    renderName(uuid) {
        const users = this.props.contacts ? this.props.contacts.users : {};

        if (users[uuid] && users[uuid].firstName) {
            return users[uuid].firstName + ' ' + users[uuid].lastName;
        }

        if (users[uuid] && users[uuid].name) {
            return users[uuid].name;
        }

        return 'Unknown';
    }
}
