// @flow

import type { SdkWatch, ApiError, ApiProgress } from '@deecision/infra-types/client';
import type { IObservableValue } from 'mobx';
import type { DataStatus, DataStore, StoreBoxes } from './types';

import Boxes from './boxes';
import { action } from 'mobx';

export default function<T>(): DataStore<T> {
    const counts: Map<string, number> = new Map();
    const watches: Map<string, SdkWatch<T>> = new Map();
    const data: StoreBoxes<?T> = Boxes(null);
    const errors: StoreBoxes<?ApiError> = Boxes(null);
    const progresses: StoreBoxes<?ApiProgress> = Boxes(null);
    const statuses: StoreBoxes<DataStatus> = Boxes('progress');

    return {
        watch(key: string, make: () => SdkWatch<T>): void {
            const count = counts.get(key) || 0;
            counts.set(key, count + 1);

            if (watches.has(key)) {
                return;
            }

            const watch = make();

            data.add(key);
            errors.add(key);
            progresses.add(key);
            statuses.add(key);

            watch.onValue(action((value: T) => {
                data.set(key, value);
                errors.set(key, null);
                progresses.set(key, null);
                statuses.set(key, 'data');
            }));

            watch.onError(action((error: ApiError) => {
                if (data.get(key).get()) {
                    return;
                }

                errors.set(key, error);
                statuses.set(key, 'error');
            }));

            watch.onProgress(action((progress: ApiProgress) => {
                if (data.get(key).get()) {
                    return;
                }

                progresses.set(key, progress);
                statuses.set(key, 'progress');
            }));

            watches.set(key, watch);
        },

        end(key: string | string[]): void {
            const keys = Array.isArray(key) ? key : [key];

            keys.forEach((key: string) => {
                const count = counts.get(key) || 0;

                if (count > 1) {
                    counts.set(key, count - 1);
                } else {
                    counts.delete(key);
                    data.delete(key);
                    errors.delete(key);
                    progresses.delete(key);
                    statuses.delete(key);
                    watches.delete(key);
                }
            });
        },

        getData(key: string): IObservableValue<?T> {
            return data.get(key);
        },

        getError(key: string): IObservableValue<?ApiError> {
            return errors.get(key);
        },

        getProgress(key: string): IObservableValue<?ApiProgress> {
            return progresses.get(key);
        },

        getStatus(key: string): IObservableValue<DataStatus> {
            return statuses.get(key);
        },
    };
}
