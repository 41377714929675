import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';

import Children from './stocks/children';
import Parents from './stocks/parents';
import Subject from './stocks/subject';
import t from 'app/translation';
import { get } from 'lodash';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.stocks';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        stocks: PropTypes.object.isRequired,
    };

    render() {
        const name = this.props.entity.preview.name;

        return (
            <div style={styles.container}>
                {this.renderParents()}

                <Subject type={this.props.entity.type} name={name} />

                {this.renderChildren()}
            </div>
        );
    }

    renderParents() {
        if (this.props.entity.type === 'person') {
            return null;
        }

        if (!this.props.stocks.parents) {
            return <Views.Common.Empty title={t('dna.deefind.veegilenzData.common.noShareholders')} />;
        }

        return (
            <Parents
                parentId={this.props.entity.id}
                stocks={this.props.stocks.parents}
                collapsed={(this.props.stocks.parents || []).length > 10}
            />
        );
    }

    renderChildren() {
        if (!this.props.stocks.children) {
            return <Views.Common.Empty title={t('dna.deefind.veegilenzData.common.noStock')} />;
        }

        return (
            <Children
                parentId={this.props.entity.id}
                stocks={this.props.stocks.children}
                collapsed={(this.props.stocks.children || []).length > 10}
            />
        );
    }
}
