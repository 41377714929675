import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    children: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch',
        flexDirection: 'column',
    },
    title: {
        // marginTop: '10px',
        padding: '10px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '11px',
        color: colors.grey600,
        border: '1px solid ' + colors.grey400,
        background: colors.grey300,
        borderBottomWidth: '0'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.column';

    static propTypes = {
        title: PropTypes.node,
        style: PropTypes.object,
    };

    render () {
        if (! this.props.title) {
            return (
                <div style={ Object.assign({}, styles.children, this.props.style || {}) }>
                    { this.renderChildren() }
                </div>
            );
        }

        return (
            <div style={ Object.assign({}, styles.container, this.props.style || {}) }>
                <div style={ styles.title }>
                    { this.props.title }
                </div>

                <div style={ styles.children }>
                    { this.renderChildren() }
                </div>
            </div>
        );
    }

    renderChildren () {
        let children = React.Children.toArray(this.props.children);

        if (! children.length)
            return null;

        const size = _.sum(_.map(children, child => child.props.size || 1));

        children = _.map(children, (child, index) => {
            const style = {}; // { height: (100 * (child.props.size || 1) / size) + '%' };

            if (index > 0)
                style.marginTop = '-1px';

            const key = child.props.key || child.props.path || index;
            Object.assign(style, child.props.style);

            return React.cloneElement(child, { key, style });
        });

        return children;
    }
}
