import PropTypes from 'prop-types';
import React from 'react';
import uuid from 'uuid';
import Row from './collection/row';
import Header from './collection/header';

const styles = {
    container: {
        display: 'block',
        padding: '4px 5px 5px 5px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.collection';

    static propTypes = {
        value: PropTypes.array,
        fields: PropTypes.object.isRequired,
        flags: PropTypes.array,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    render () {
        const flags = this.props.flags || [];
        const items = flags.includes('fixed')
            ? this.props.value || []
            : (this.props.value || []).concat([ undefined ]);

        return (
            <div style={ styles.container }>
                <Header fields={ this.props.fields } />
                {flags.includes('labels') ? this.renderLabels() : null}
                { items.map(this.renderItem.bind(this)) }
            </div>
        );
    }

    renderLabels() {
        return null;
    }

    renderItem (data, index) {
        const onChange = data => {
            const list = this.props.value || [];

            if ((this.props.flags || []).includes('uuid') && ! data.id)
                data.id = uuid.v4();

            if (index > list.length - 1) list.push(data);
            else Object.assign(list[ index ], data);

            this.props.onChange(list);
        };

        const onRemove = event => {
            const list = this.props.value.slice();
            list.splice(index, 1);
            this.props.onChange(list);
        };

        return (
            <Row
                key={ 'item:' + index }
                fields={ this.props.fields }
                index={ index }
                data={ data }
                flags={ this.props.flags || [] }
                onChange={ onChange }
                onRemove={ onRemove }
                onFocus={ this.props.onFocus }
            />
        );
    }
}
