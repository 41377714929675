import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        marginRight: '10px',
        listStyleType: 'none'
    },
    choice: {
        textAlign: 'right',
        padding: '10px',
        color: colors.grey500,
        cursor: 'pointer'
    },
    icon: {
        marginTop: '-4px',
        float: 'left',
        fill: colors.grey500,
    },
    hover: {
        background: colors.grey100,
        color: colors.grey700
    },
    current: {
        color: colors.userCold,
        background: colors.grey100,
        borderRight: '2px solid ' + colors.userHot
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.news.selector';

    static propTypes = {
        type: PropTypes.oneOf([ 'main', 'filter' ]).isRequired,
        choices: PropTypes.array.isRequired,
        value: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired
    };

    constructor (props) {
        super(props);
        this.state = { hover: null };
    }

    render () {
        if (! this.props.choices.length)
            return null;

        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }
                onMouseLeave={ event => this.setState({ hover: null }) }
            >
                { this.props.choices.map(this.renderChoice.bind(this)) }
            </div>
        );
    }

    renderChoice (choice) {
        const style = Object.assign({},
            styles.choice,
            this.props.value === choice.value
                ? styles.current
                : (this.state.hover === choice.value ? styles.hover : {})
        );

        const onClick = () => {
            let value = choice.value;

            if (this.props.type === 'filter' && value === this.props.value)
                value = undefined;

            this.props.onChange(value);
        };

        return (
            <div key={ choice.value }
                style={ style }
                onMouseEnter={ event => this.setState({ hover: choice.value }) }
                onClick={ onClick }
            >
                { this.renderIcon(choice.icon) }
                { choice.label }
            </div>
        );
    }

    renderIcon (type: string) {
        if (! type) {
            return null;
        }

        return (
            <Icon style={ styles.icon } type={ type } />
        );
    }
}
