import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '20px',
        height: '20px',
        fill: colors.userCold,
    },
    title: {
        marginLeft: '30px',
        fontSize: '17px',
        marginBottom: '2px',
        lineHeight: '20px',
    },
    date: {
        marginLeft: '30px',
        fontSize: '13px',
        color: colors.grey500,
    },
    description: {
        marginTop: '15px',
    },
};

export default class extends React.Component<any, any> {

    static displayName = 'comeet.common.events.preview';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style || {}) }>
                <Icon
                    type="comeet.event"
                    style={ styles.icon }
                />

                <div style={ styles.title }>
                    { this.props.entity.payload.title }
                </div>

                <Views.Common.Moment
                    date={ this.props.entity.payload.date }
                    style={ styles.date }
                />

                <Views.Common.Description
                    value={ this.props.entity.payload.description }
                    style={ styles.description }
                />
            </div>
        );
    }

    renderIcon () {
        return (<Icon type="comeet.event" />);
        // switch (this.props.entity.settings.type) {
        //     case 'diner': return (<Icon type="comeet.events.diner" />);
        //     case 'travel': return (<Icon type="comeet.events.diner" />);
        //     case 'travel': return (<Icon type="comeet.events.diner" />);
        //     default: return (<Icon type="comeet.event" />);
        // }
    }
}
