import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        flexGrow: '0',
        flexShrink: '0',
        borderRadius: '3px 0 0 3px',
        paddingRight: '10px',
        width: '30px',
        textAlign: 'right',
        color: colors.grey500,
        lineHeight: '30px'
    },
    hover: {
        color: colors.userCold
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'index';

    static propTypes = {
        index: PropTypes.number.isRequired,
        sort: PropTypes.bool,
        empty: PropTypes.bool
    };

    state = {
        hover: false
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.state.hover ? styles.hover : {}
        );

        const label = this.props.empty ? '+' : `${this.props.index + 1}.`;

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
            >
                { label }
            </div>
        );
    }
}
