import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Header from './field/header';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        flexShrink: '1',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch',
        border: '1px solid ' + colors.grey400,
        background: colors.grey200
    },
    body: {
        padding: '10px',
        fontSize: '17px',
        lineHeight: '24px',
        height: '24px',
        color: colors.userCold,
        textAlign: 'center',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.value';

    static contextTypes = {
        form: PropTypes.object.isRequired
    };

    static propTypes = {
        label: PropTypes.node,
        info: PropTypes.node,
        compute: PropTypes.func,
        value: PropTypes.node,
    };

    render () {
        return (
            <label style={ Object.assign({}, styles.container, this.props.style) }>
                <Header
                    label={ this.props.label }
                    info={ this.props.info }
                />

                <div style={ styles.body }>
                    { this.renderValue() }
                </div>
            </label>
        );
    }

    renderValue () {
        if (this.props.value) {
            return this.props.value;
        }

        if (this.props.compute) {
            return this.props.compute(this.context.form.getData());
        }
    }
}
