// @flow

import type { StockPrediction, StockWeasse, StockYearlyPrediction } from '@deecision/deefind-types/model';

export function makeAgeExitPrediction(entity: Object, weasse: StockWeasse, horizonMax: number): ?StockPrediction {
    const birthDate = entity.preview.birthDate;
    const birthYear = birthDate ? parseInt(birthDate.substr(0, 4)) : undefined;
    const nbCompanies = entity.preview.companies.length;
    if (!birthYear) return undefined;
    const age = new Date().getFullYear() - birthYear;
    if (age < Math.max(60, 64 - nbCompanies / 2)) return undefined;
    if (!weasse.stocks.max) return undefined;
    const weasseMax = weasse.stocks.max.value;

    // put data per year in results
    const results = getPredictionsByHorizon(weasseMax, nbCompanies, age, horizonMax);

    return {
        type: 'age',
        label: String(age),
        year1: makeYearlyPrediction(results[0]),
        year2: makeYearlyPrediction(results[1]),
        year3: makeYearlyPrediction(results[2]),
        year4: makeYearlyPrediction(results[3]),
        year5: makeYearlyPrediction(results[4]),
    };
}

// format data from results in a clean object
function makeYearlyPrediction(data: Object): StockYearlyPrediction {
    return {
        probability: data.probaExpectedValue,
        potentialCashout: data.sumPotentialCashout,
        expectedValue: data.sumExpectedValue,
        horizon: data.horizon,
    };
}

function getPredictionsByHorizon(weasseMax: number, participations: number, age: number, horizonMax: number) {
    const results = [];
    results.push(firstYear(weasseMax, participations, age));

    for (let i = 2; i <= horizonMax; i++) {
        results.push(yearsAfter(results, weasseMax, i, participations));
    }

    return results;
}

function firstYear(weasseMax: number, participations: number, age: number) {
    const horizon = 1;
    const probabilityAge = getAgeExitProbability(age);
    const nbCompaniesSold = 1 + ((participations - horizon) * 20) / 100;
    const potentialCashout = Math.min(weasseMax, (weasseMax / participations) * nbCompaniesSold);

    const expectedValue = (potentialCashout * probabilityAge) / 100;
    const probaExpectedValue = (expectedValue / potentialCashout) * 100;

    const prediction = {
        age,
        horizon,
        probabilityAge,
        weasseMax,
        participations,
        nbCompaniesSold,
        potentialCashout,
        expectedValue,
        sumPotentialCashout: potentialCashout,
        sumExpectedValue: expectedValue,
        probaExpectedValue,
    };

    return prediction;
}

function yearsAfter(results: Object[], weasseMaxBase: number, horizon: number, participationsBase: number) {
    const prevYear = results[horizon - 2];
    const age = prevYear.age + 1;
    const weasseMax = weasseMaxBase - prevYear.expectedValue;
    const probabilityAge = getAgeExitProbability(age);

    const participations = prevYear.participations - (prevYear.nbCompaniesSold * prevYear.probabilityAge) / 100;
    const nbCompaniesSold = getNbCompanySoldByHorizon(participationsBase, horizon);
    //
    const potentialCashout = Math.min(weasseMax, (weasseMax / participations) * nbCompaniesSold);

    const expectedValue = (potentialCashout * probabilityAge) / 100;

    const sumExpectedValue = Math.min(weasseMaxBase, prevYear.sumExpectedValue + expectedValue);
    const sumPotentialCashout = Math.min(weasseMaxBase, prevYear.sumPotentialCashout + potentialCashout);
    const probaExpectedValue = (sumExpectedValue / sumPotentialCashout) * 100;

    const prediction = {
        age,
        horizon,
        probabilityAge,
        weasseMax,
        participations,
        nbCompaniesSold,
        potentialCashout,
        expectedValue,
        sumExpectedValue,
        sumPotentialCashout,
        probaExpectedValue,
    };

    return prediction;
}

function getNbCompanySoldByHorizon(participations: number, horizon: number): number {
    return 1 + ((participations - horizon) * 20) / 100;
}

function getAgeExitProbability(age) {
    return Math.min(2 * age - 80, 80);
}
