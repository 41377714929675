import React from 'react';
import PropTypes from 'prop-types';
import Scope from 'app/layout/providers/scope';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import List from './list';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.deeligenzStudies, session: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.archives.summary';

    static contextTypes = {
        createStudy: PropTypes.func.isRequired,
        openStudy: PropTypes.func.isRequired,
    };

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    async handleSubmit(data: Object) {
        const study = await this.context.createStudy(data);
        this.refs.form.unlock();
        //this.security.openStudy(study.id);
    }

    render() {
        const entities = this.props.store.archives;
        const loaded = toJS(this.props.store.loaded);
        const session = this.props.session.get();

        if (!loaded) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div style={this.props.style}>
                {this.renderList(Array.from(entities.values()))}
            </div>
        );
    }

    renderList(entities) {
        entities = entities.filter(e => (!!e.payload.archive))
        if (!this.props.session) {
            return null;
        }

        if (!entities.length && this.props.session.get().level !== 'intervention') {
            return (
                <Views.Common.Empty
                    title="No Archives found"
                    actions={[]}
                />
            );
        }

        return (
            <Scope path="list" type="list">
                <List
                    account={this.props.session.get().account}
                    entities={entities}
                    onCreate={event => this.refs.form.open()}
                    type={this.props.session.get().level}
                />
            </Scope>
        );
    }
}
