// @flow

import type { LinkProfile, StockUltimate } from '@deecision/deefind-types/model';

export default function (links: ?LinkProfile[]): StockUltimate {
    const result: StockUltimate = {};

    for (const link of links || []) {
        if (link.guo) {
            if (link.target.type === 'person') {
                result.ubo = link.target;
            }

            if (link.target.type === 'company') {
                result.upc = link.target;
            }
        }
    }

    return result;
}
