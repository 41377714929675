// @flow

import colors from 'app/theme/colors';

export default {
    container: {
        base: {
            lineHeight: '30px',
            padding: '5px 10px',
            margin: '10px 5px',
            verticalAlign: 'middle',
            display: 'flex',
            background: colors.grey100,
            borderRadius: '3px',
            cursor: 'pointer'
        },
        hover: {
            background: colors.grey300,
        },
        current: {
            background: colors.userCold,
        },
        disabled: {
            cursor: 'default'
        }
    },
    icon: {
        base: {
            width: '24px',
            height: '24px',
            margin: '3px',
            fill: colors.userCold,
            opacity: 0.75,
        },
        hover: {
            fill: colors.userCold,
            opacity: 1,
        },
        current: {
            fill: colors.white,
            opacity: 0.75
        },
        disabled: {
            fill: colors.grey400,
            opacity: 1,
        }
    },
    label: {
        base: {
            margin: '0 5px',
            color: colors.userCold,
            opacity: 0.75,
        },
        hover: {
            color: colors.userCold,
            opacity: 1,
        },
        current: {
            color: colors.white,
            opacity: 1,
        },
        disabled: {
            color: colors.grey400,
            opacity: 1,
        }
    },
    count: {
        base: {
            margin: '0 5px',
            color: colors.grey500,
        },
        hover: {
            color: colors.grey700,
        },
        current: {
            background: colors.white,
            opacity: 0.75
        },
        disabled: {
            fill: colors.grey400,
        }
    }
};
