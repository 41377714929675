import PropTypes from 'prop-types';
import React from 'react';
import RadioChoice from './radios/choice';
import Choices from './common/choices';

const styles = {
    container: {
        padding: '5px',
        display: 'flex',
    },
    vertical: {
        flexDirection: 'column',
    },
    horizontal: {
        flexDirection: 'row',
        height: '40px',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.radios';

    static propTypes = {
        value: PropTypes.any,
        choices: Choices.propType,
        vertical: PropTypes.bool,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    render () {
        return (
            <div style={ Object.assign({}, this.props.style, styles.container, this.props.vertical ? styles.vertical : styles.horizontal) }>
                { this.renderChoices() }
            </div>
        );
    }

    renderChoices () {
        return Choices(this.props.choices).map(choice => (
            <RadioChoice
                key={ choice.key }
                style={ choice.style }
                icon={ choice.icon }
                label={ choice.title }
                checked={ this.props.value === choice.value }
                vertical={ this.props.vertical }
                onCheck={ event => this.props.onChange(choice.value) }
                onFocus={ this.props.onFocus }
            />
        ));
    }
}
