import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'inline-block',
    },
    empty: {
        color: colors.grey500,
    },
    percent: {
        display: 'inline-block',
        marginLeft: '5px',
        color: colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.sum';

    static propTypes = {
        data: PropTypes.object,
        style: PropTypes.object,
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.style,
        );

        const empty = ! this.props.data || ! this.props.data.value;

        if (empty) {
            return (
                <span style={ Object.assign({}, style, styles.empty) }>
                    —
                </span>
            );
        }

        let reliability = this.props.data.reliability;

        if (reliability > 100) {
            reliability = reliability / 20;
        }

        return (
            <span style={ style }>
                <Views.Common.Number
                    type="currency"
                    scale="M"
                    value={ this.props.data.value }
                    deviation={ this.props.data.deviation || 0 }
                />

                <span style={ styles.percent }>
                    { reliability ? Math.round(reliability) + ' %' : null }
                </span>
            </span>
        );
    }
}
