import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import VigilanceButton from 'dna/entity/common/vigilance/button';
import ComplianceButton from 'dna/entity/common/compliance/button';
import Reech from 'dna/entity/common/reech';
import t from 'app/translation';

const SCOPES = [
    { key: 'all', label: 'Involvements' },
    { key: 'stocks', label: 'Investments' },
];

const STATUS = [
    { key: 'all', label: 'All' },
    { key: 'current', label: 'Current' },
];

const styles = {
    container: {
        // display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
    },
    chart: {
    },
    header: {
        marginBottom: '20px',
        padding: '5px',
        textAlign: 'center'
    },
    buttons: {
        display: 'inline-block',
        borderRadius: '3px',
        overflow: 'hidden',
        marginRight: '15px',
    },
    button: {
        display: 'inline-block',
        padding: '0 15px',
        background: colors.grey100,
        color: colors.userCold,
        cursor: 'pointer',
        lineHeight: '40px',
    },
    buttonHover: {
        background: colors.grey200,
    },
    buttonCurrent: {
        background: colors.userCold,
        color: colors.white,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.cockpit.equity';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    state = {
        scope: 'all',
        status: 'all',
        hover: null
    };

    render () {
        return (
            <div style={{marginTop: '5px', marginRight: '5px', marginLeft: '5px', border: '2px solid '+colors.grey400 }}>
                { this.renderHeader() }
                <div>
                    { this.renderCharts() }
                </div>
            </div>
        );
    }

    renderHeader() {
      return (
        <div style={{backgroundColor: colors.grey200, padding: '5px'}}>
          <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '5%',textAlign: 'left' }}>
            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.equity" />
          </div>
          <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '45%',textAlign: 'left', color: colors.userCold }}>
            Private equity breakdown
          </div>
        </div>
      );
    }

    renderCharts () {
      const data = this.props.network.computed.involvements.filter(item => item.scope === this.state.scope && item.status === this.state.status);
        if (! data) {
            return (
                <Views.Common.Empty
                    style={this.props.style}
                    title="No investment data available"
                />
            );
        }


        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <div style={ styles.header }>
                    { this.renderSwitch(SCOPES, 'scope') }
                    { this.renderSwitch(STATUS, 'status') }
                </div>
                <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle'}}>
                  <Views.Chart.Container
                    title={ `${this.state.scope} activity` }
                    type="half-pie"
                    style={ styles.chart }
                    >
                      { this.renderEntries(data.filter(item => item.type === 'activity'), 'common.activity') }
                    </Views.Chart.Container>
                </div>
                <div style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle'}}>
                  <Views.Chart.Container
                    title={ `${this.state.scope} country` }
                    type="half-pie"
                    style={ styles.chart }
                    >
                      { this.renderEntries(data.filter(item => item.type === 'location'), 'common.country') }
                    </Views.Chart.Container>
                </div>

            </div>
        );
    }

    renderEntries (list, key) {
        return list.map((entry, index) => (
            <Views.Chart.Entry
                style={{
                  whiteSpace: 'nowrap',
                  width: '100px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                key={ 'e' + index }
                label={ t(key + '.' + String(entry.code).toUpperCase())  }
                value={ entry.ratio }
            />
        ));
    }

    renderSwitch (items, prop) {
        const current = this.state[prop];
        const buttons = items.map(item => (
            <span
                key={ item.key }
                style={ Object.assign({}, styles.button, item.key === current ? styles.buttonCurrent : (this.state.hover === item.key ? styles.buttonHover : {})) }
                onMouseEnter={ event => this.setState({ hover: item.key }) }
                onMouseLeave={ event => this.setState({ hover: null }) }
                onClick={ event => this.setState({ [prop]: item.key }) }
            >
                { item.label }
            </span>
        ));

        return (
            <span style={ styles.buttons }>
                { buttons }
            </span>
        )
    }
}
