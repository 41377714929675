// @flow

import crypto from 'crypto';

export function encodeArtifactId(provider: number, version: number, identifier: string): string {
    const sha1 = crypto
        .createHash('sha1')
        .update(identifier)
        .digest('hex');

    return [
        provider
            .toString(16)
            .padStart(3, '0')
            .substr(0, 3) +
            version
                .toString(16)
                .padStart(5, '0')
                .substr(0, 5),
        sha1.substr(0, 4),
        sha1.substr(4, 4),
        sha1.substr(8, 4),
        sha1.substr(12, 12),
    ].join('-');
}

export function decodeArtifactId(id: string): { provider: number, version: number } {
    return {
        provider: parseInt(id.substr(0, 3), 16),
        version: parseInt(id.substr(3, 5), 16),
    };
}
