// @flow

const GROUPS = [
    ['de', 'at', 'be', 'dk', 'es', 'fi', 'fr', 'ie', 'is', 'it', 'li', 'lu', 'no', 'nl', 'pt', 'gb', 'se', 'ch'],
];

export default function makeValuationCountries(country: string): string[] {
    for (const group of GROUPS) {
        if (group.includes(country.toUpperCase())) {
            return group.map(code => code.toUpperCase());
        }
    }

    return [country.toUpperCase()]
}
