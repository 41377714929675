import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Preview from './preview';
import Form from './form';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
    },
    preview: {
        margin: '10px 0',
    },
    actions: {
        display: 'flex',
        marginTop: '20px',
        borderTop: '1px solid ' + colors.grey400,
        paddingTop: '7px',
    },
    action: {
        width: 'auto',
    },
    spacer: {
        width: 'auto',
        flexGrow: '1',
    },
    checklist: {
        margin: '20px 0',
    }
};

@inject(stores => ({ store: stores.comeetProspects }))

@observer
export default class extends React.Component<any, any> {

    static displayName = 'comeet.common.milestone.display';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        prospect: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                <Preview
                    entity={ toJS(this.props.entity) }
                    style={ styles.preview }
                />

                <Views.Common.Checklist
                    items={ this.props.entity.payload.checklist ? this.props.entity.payload.checklist.slice() : [] }
                    style={ styles.checklist }
                    onChange={ (list) => {
                      this.props.store.checkMilestone(this.props.entity.id, list);
                    } }
                />

                <div style={ styles.actions }>
                    <Views.Discussion.Button
                      color={'cold'}
                      type={'flat'}
                      target={ this.props.entity.id }
                      entities={ toJS(this.props.entity.comments) }
                      handleSubmit={(data, parentId) => {
                        this.props.store.commentMilestone(this.props.entity.id, data, parentId)
                      }}
                      handleDelete={(commentId) => {
                        this.props.store.removeComment(this.props.prospect.id, commentId)
                      }}
                      preview={
                        <div>
                          <Preview
                              entity={ toJS(this.props.entity) }
                              style={ styles.preview }
                          />
                          <div style={ { padding: '20px', backgroundColor: colors.bgGreen } }>
                          <Views.Common.Checklist
                              items={ this.props.entity.payload.checklist ? this.props.entity.payload.checklist.slice() : [] }
                              style={ styles.checklist }
                              onChange={ (list) => {
                                this.props.store.checkMilestone(this.props.entity.id, list);
                              } }
                          />
                        </div>
                        </div>
                      }
                    />

                    <Views.Common.Button
                        type="flat"
                        color="none"
                        icon={ <Icon type="base.attachment" /> }
                        disabled
                    />

                    <Views.Common.Button
                        type="flat"
                        color="none"
                        icon={ <Icon type="base.attendee" /> }
                        disabled
                    />

                    <div style={ styles.spacer } />

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={ <Icon type="do.update" /> }
                        onClick={ event => this.refs.editor.open() }
                    />

                    <Views.Common.Button
                        type="flat"
                        color="hot"
                        icon={ <Icon type="do.delete" /> }
                        onClick={ event => this.refs.remover.open() }
                    />
                </div>

                <Form
                    ref="editor"
                    entity={ this.props.entity }
                    onSubmit={ this.props.onUpdate }
                />

                <Views.Modal.Removal
                    ref="remover"
                    subject="milestone"
                    onSubmit={ this.props.onRemove }
                >
                    <Preview entity={ this.props.entity } />
                </Views.Modal.Removal>
            </div>
        );
    }
}
