// @flow

import type { StockValue } from '@deecision/deefind-types/model';

function debug(...args) {
    // uncomment to log
    // console.log(...args);
}

export function formatStockValue(value: ?StockValue): string {
    if (!value || (!value.min && !value.max)) {
        debug('--> stock:', value, '-');
        return '—';
    }

    const min = parseFloat((parseFloat(value.min) || 0).toFixed(2));
    const max = parseFloat((parseFloat(value.max) || 100).toFixed(2));

    if (min <= 0 && max >= 100) {
        debug('--> stock:', value, '-');
        return '—';
    }

    if (min === max) { // exact value
        debug('--> stock:', value, `${min} %`);
        return `${min} %`;
    }

    if (max >= 100) { // min only
        debug('--> stock:', value, `> ${min} %`);
        return `> ${min} %`;
    }

    if (min <= 0) { // max only
        debug('--> stock:', value, `< ${max} %`);
        return `< ${max} %`;
    }

    debug('--> stock:', value, `${min} → ${max} %`);
    return `${min} → ${max} %`; // range
}
