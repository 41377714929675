import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Paper from 'material-ui/Paper';
import Icon from 'app/theme/icon';
import Toggle from '../common/toggle';
import Button from '../common/button';

const styles = {
    onTop: {
        zIndex: 200000
    },
    wrapper: {
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        background: colors.lightBlack
    },
    paper: {
        position: 'absolute',
        top: '20px',
        bottom: '20px',
        left: '50%',
        marginLeft: '-700px',
        width: '1400px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
    },
    head: {
        height: '40px',
        padding: '10px',
        background: colors.grey100,
        borderBottom: '1px solid ' + colors.grey300,
        flexGrow: '0',
        flexShrink: '0',
        position: 'relative',
        textAlign: 'center',
        color: colors.grey500,
        lineHeight: '40px',
    },
    scale: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
    },
    range: {
        margin: '0 10px',
    },
    page: {
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        lineHeight: '30px',
        width: '40px',
        textAlign: 'center',
        fontSize: 'inherit',
        fontFamily: 'inherit',
    },
    close: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    body: {
        flexGrow: '1',
        flexShrink: '2',
        background: colors.grey200,
        overflow: 'scroll',
    },
    canvas: {
        display: 'block',
        margin: '20px auto',
        border: '1px solid ' + colors.grey300,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.modal.img';

    static propTypes = {
        onTop: PropTypes.bool,
        slot1: PropTypes.node,
        onClose: PropTypes.func,
    };

    state = {
        open: false,
    };

    open(img) {
        window.document.body.style.overflow = 'hidden';
        this.setState({ open: true, img });
    }

    close() {
        window.document.body.style.overflow = 'auto';
        this.setState({ open: false, img: null });

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        if (!this.state.open) {
            return null;
        }

        return (
            <div style={Object.assign({}, styles.wrapper, this.props.onTop ? styles.onTop : {})}>
                <Paper style={styles.paper} zDepth={2}>
                    <div style={styles.head}>
                        <Button
                            type="flat"
                            color="hot"
                            icon={<Icon type="do.close" />}
                            label="Close"
                            style={styles.close}
                            onClick={event => this.close()}
                        />
                    </div>

                    <div style={styles.body}>
                        <img src={this.state.img} />
                    </div>
                </Paper>
            </div>
        );
    }
}
