import { get } from 'lodash';
import formatAnswer from '../answer';

const headerValueDefs = {
    'legalStructure': {
        reportTags: ['F_Legal_Structure'],
    },
    'shareholdingStructureText': {
        reportTags: ['Shareholding_Text'],
        legacyPath: 'payload.header.managerShareholding',
    },
    'historicalMilestonesText': {
        reportTags: ['Milestones_Text'],
        legacyPath: 'payload.header.managerMilestones',
    },
    // Note: Not defined here because they are in 'pictures.js'
    // ========================================================
    // 'shareholdingStructurePicture': {
    //     reportTags: ['Shareholding_Graph'],
    // },
    // 'historicalMilestonesPicture': {
    //     reportTags: ['Milestones_Graph'],
    // },
    // ========================================================
    'corporateAuditor': {
        reportTags: ['M_Corp_Aud'],
    },
    'registration': {
        reportTags: ['F_Regu_Regi'],
    },
    'morningstarCategory': {
        reportTags: ['F_Morningstar_Category'],
    },
    'fundAum': {
        reportTags: ['F_AUM'],
    },
    'managementCompany': {
        reportTags: ['M_ManCo'],
    },
    'custodian': {
        reportTags: ['F_Custodian'],
    },
    'administrator': {
        reportTags: ['F_Administrator'],
    },
    'fundAuditor': {
        reportTags: ['F_Auditor'],
    },
    'totalAum': {
        reportTags: ['M_AUM'],
    },
    'strategyInvestmentManager': {
        reportTags: ['M_MAIN_ACTIVITY'],
        legacyPath: 'payload.header.managerStrategy',
    },
    'futurePlans': {
        reportTags: ['M_FUT_PLAN'],
        legacyPath: 'payload.header.fundPlans',
    },
    'unpri': {
        reportTags: ['M_UNPRI'],
        legacyPath: 'payload.header.fundUNPRI',
    },
    'typeInvestors': {
        reportTags: ['M_INV_PROFILE', 'F_INV_PROFILE'],
        legacyPath: 'payload.header.managerInvestors',
    },
    'globalPresentationInvestmentManager': {
        legacyPath: 'payload.report.managerPresentation',
    },
    'introductionFundStrategy': {
        legacyPath: 'payload.header.fundStrategy',
    },
    'operationalFramework': {
        legacyPath: 'payload.header.fundFramework',
    },
};

function findQuestionWithTag(data: Object, tag: string): Object {

    for (const field of data.fields || []) {
        const fieldTag = get(field, 'questionPayload.report');
        if (fieldTag == tag) {
            return field;
        }
    }
    return undefined;
}

export default async function (data: Object, services: Object): Promise<Object> {
    const values = {};

    for (const valueName in headerValueDefs) {
        // For the value in header...
        const headerValueDef = headerValueDefs[valueName];

        // First, take the value in 'study.payload.header'
        const headerOverrideValue = get(data, 'study.payload.header.'+valueName, undefined);
        if (headerOverrideValue != undefined) {
            values[valueName] = headerOverrideValue;
            continue; // go to next value
        }

        // Otherwise, try the legacy value
        const legacyPath = headerValueDef.legacyPath;
        if (legacyPath) {
            const legacyValue = get(data, 'study.'+legacyPath, undefined);
            if (legacyValue != undefined) {
                values[valueName] = legacyValue;
                continue; // go to next value
            }
        }

        // Otherwise, use the first value tagged in the answers
        for (const tag of headerValueDef.reportTags || []) {
            for (const field of data.fields || []) {
                const fieldTag = get(field, 'questionPayload.report');
                if (fieldTag == tag) {
                    const fieldValue = await formatAnswer(field);
                    values[valueName] = fieldValue;
                    continue;
                }
            }
        }

    }

    return values;
}
