import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Reech from '../reech';
import Compliance from '../compliance/button';
import Flag from '../flag';
import Scope from 'app/layout/providers/scope';
import Export from './export';
import PersonInfo from 'dna/entity/person/info';
import CompanyInfo from 'dna/entity/company/info';
import TopRelated from 'dna/entity/common/topRelated';
import Activity from 'dna/entity/common/activity';
import Report from 'dna/entity/common/compliance/report';
import Comeet from 'comeet/prospects/display';
import Closed from './closed';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';
import { toJS } from 'mobx';
import t from 'app/translation';

const styles = {
    container: {
        margin: '10px 0 20px 0',
        display: 'flex',
    },
    icon: {
        margin: '10px 20px 10px 10px',
        fill: colors.userCold,
    },
    main: {
        flexGrow: '1',
    },
    title: {
        fontSize: '21px',
        lineHeight: '24px',
        margin: '10px 0',
        display: 'flex',
        alignContent: 'center',
    },
    flag: {
        marginLeft: '5px',
    },
    flagC19: {
        marginLeft: '5px',
        width: '40px',
        borderRadius: '20px',
        top: '-2px',
        marginRight: '5px',
    },
    info: {
        color: colors.grey500,
    },
    aside: {
        margin: '10px',
        display: 'flex',
        flexShrink: '1',
    },
    button: {
        marginLeft: '10px',
    },
    tabs: {
        display: 'flex',
        height: '40px',
    },
    tabBase: {
        padding: '0 10px',
        lineHeight: '40px',
        color: colors.userCold,
        fontWeight: 'bold',
        cursor: 'pointer',
        borderRadius: '3px',
    },
    tabActive: {
        color: colors.userHot,
        background: colors.grey100,
    },
    closed:{
        marginLeft: '15px',
    }
};

@inject(stores => ({ comeet: stores.comeetProspects, deefind: stores.deefind }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.display.header';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object,
        state: PropTypes.object.isRequired,
        onComeet: PropTypes.func.isRequired,
    };

    state = { comeet: null };

    render() {
        return (
            <div style={styles.container}>
                <Icon style={styles.icon} type={`dna.${this.props.entity.type}`} />

                <div style={styles.main}>
                    {this.renderTitle()}
                    {this.renderInfo()}
                </div>

                <div style={styles.aside}>
                    <Compliance entity={this.props.entity} mini style={{ marginRight: '10px' }} />
                    {this.renderTabs()}

                    <Report
                        entity={this.props.entity}
                        stocks={this.props.deefind.results.get(`stocks:${this.props.entity.id}`)}
                        network={this.props.deefind.results.get(`network:${this.props.entity.id}`)}
                        style={styles.button}
                    />

                    <Reech entity={this.props.entity} style={styles.button} />

                    <Scope path="export" type="object" entity={this.props.entity}>
                        <Export entity={this.props.entity} style={styles.button} />
                    </Scope>

                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        icon={<Icon type="do.create" />}
                        label="deel"
                        onClick={async () => {
                            const id = this.props.entity.id;
                            const data = Object.values(toJS(this.props.comeet.prospects)).filter(
                                item => get(item, 'payload.target.uid') === id,
                            );

                            let prospect;

                            if (data.length) {
                                prospect = data[0].id;
                            } else {
                                const result = await this.props.comeet.createProspect({
                                    payload: {
                                        settings: {
                                            aum: 6,
                                            margin: 1.4,
                                            period: 4,
                                            attrition: 1.2,
                                            cost: 15,
                                            months: 9,
                                        },
                                        target: { name: this.props.entity.preview.name || '?', uid: id },
                                    },
                                });

                                prospect = result.id;
                            }

                            if (prospect) {
                                this.setState({ comeet: prospect }, () => this.refs['comeet'].open());
                            }
                        }}
                        style={styles.button}
                    />

                    <Views.Modal.Show ref="comeet" onClose={() => this.setState({ comeet: null })}>
                        <Comeet state={{ id: this.state.comeet }} />
                    </Views.Modal.Show>
                </div>
            </div>
        );
    }

    renderTitle() {
        let flags = [];

        switch (this.props.entity.type) {
            case 'company':
                flags = [
                    <Flag
                        key="l"
                        label="L"
                        tooltip="Listed company"
                        active={this.props.entity.preview.flags.includes('listed')}
                        style={styles.flag}
                    />,
                    <Flag
                        key="h"
                        label="H"
                        tooltip="Holding company"
                        color="cold"
                        active={this.props.entity.preview.flags.includes('holding')}
                        style={styles.flag}
                    />,
                    <Flag
                        key="f"
                        label="F"
                        tooltip="Investment fund"
                        color="cold"
                        active={this.props.entity.preview.flags.includes('fund')}
                        style={styles.flag}
                    />,
                    <Flag
                        key="vc"
                        label="VC"
                        tooltip={
                            typeof this.props.entity.preview.funded === 'number'
                                ? `Funded since ${this.props.entity.preview.funded}`
                                : t('dna.deefind.flags.fund')
                        }
                        active={!!this.props.entity.preview.funded}
                        style={styles.flag}
                    />,
                    <Flag
                        key="c19"
                        label="C19"
                        tooltip="Covid-19 impacted company"
                        active={this.props.entity.preview.flags.includes('crisis-impact')}
                        style={styles.flagC19}
                    />,
                ];
                break;

            case 'person':
                flags = [
                    <Flag
                        key="f"
                        label="F"
                        tooltip="Ranked fortune owner"
                        active={this.props.entity.preview.flags.includes('fortune')}
                        style={styles.flag}
                    />,
                    <Flag
                        key="ld"
                        label="Ld"
                        tooltip="Direct relation with listed company"
                        active={this.props.entity.preview.flags.includes('listed-direct')}
                        style={styles.flag}
                    />,
                    <Flag
                        key="li"
                        label="Li"
                        tooltip="Indirect relation with listed company"
                        active={this.props.entity.preview.flags.includes('listed-indirect')}
                        style={styles.flag}
                    />,
                ];
                break;
        }

        return (
            <div style={styles.title}>
                {this.props.entity.preview.name}
                {this.renderClosed()}
                {flags}
            </div>
        );
    }

    renderClosed() {
        if (this.props.entity.type === 'company' && this.props.entity.preview.closed) {
            return <Closed data={this.props.entity.data.identity.closed} style={styles.closed} />;
        }

        return null;
    }

    renderInfo() {
        switch (this.props.entity.type) {
            case 'company':
                return (
                    <div style={styles.info}>
                        <CompanyInfo entity={this.props.entity} />
                        {' — '}
                        <Activity nace={this.props.entity.preview.naceCode} gics={this.props.entity.preview.gicsCode} />
                    </div>
                );

            case 'person':
                const topCompany = this.props.entity.preview.topCompany || {};

                return (
                    <div style={styles.info}>
                        <PersonInfo entity={this.props.entity} />
                        {' — '}
                        <Flag
                            key="c19"
                            label="SC19"
                            tooltip="Sensitivity to covid-19 crisis"
                            active={this.props.entity.preview.flags.includes('crisis-direct')}
                            style={styles.flagC19}
                        />
                        <TopRelated id={topCompany.id} name={topCompany.name} />
                    </div>
                );
        }

        return null;
    }

    renderTabs() {
        const step = this.props.state.step || 'compliance';

        const renderTab = (key: string, label: string) => {
            return (
                <div
                    style={Object.assign({}, styles.tabBase, step === key ? styles.tabActive : {})}
                    onClick={event => this.context.updateState({ step: key })}
                >
                    {label}
                </div>
            );
        };

        return (
            <div style={styles.tabs}>
                {renderTab('compliance', t('common.label.compliance'))}
                {renderTab('display', t('common.label.details'))}
                {(this.props.entity.type === 'company')?renderTab('accounting', t('common.label.accounting')):''}
                {(this.props.entity.type === 'company')?renderTab('heestory', 'Heestory'):''}
                {renderTab('cockpit', 'Cockpit')}
            </div>
        );
    }
}
