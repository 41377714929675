import formatBase from './base';
import formatDuedil from './duedil';
import formatReport from './report';
import formatScoring from './scoring';
import formatFindings from './findings';
import formatIssues from './issues';
import formatScores from './scores';

export default async function(data: Object, services: Object): Promise<Object> {
    return {
        ...(await formatBase(data, services)),
        ...(await formatDuedil(data, services)),
        ...(await formatReport(data, services)),
        ...(await formatScoring(data, services)),
        ...(await formatFindings(data, services)),
        ...(await formatIssues(data, services)),
        ...(await formatScores(data, services)),
    };
}
