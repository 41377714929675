import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import colors from 'app/theme/colors';

const styles = {
    container: {
        padding: '8px',
        borderRadius: '3px',
        background: 'transparent',
        flexGrow: '1',
        cursor: 'pointer',
    },
    icon: {
        float: 'left',
        fill: colors.grey500
    },
    checkedIcon: {
        fill: colors.grey800
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.bools.choice';

    static propTypes = {
        style: PropTypes.object,
        checked: PropTypes.bool.isRequired,
        icon: PropTypes.element,
        label: PropTypes.node,
        onCheck: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired
    };

    state = { hover: false };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.checked ? styles.checked : {},
            this.props.vertical ? { height: '40px' } : {},
            this.state.hover ? { background: colors.bgBlack } : {},
        );

        const iconStyle = this.props.checked
            ? { fill: colors.userCold }
            : { fill: colors.grey500 };

        const labelStyle = this.props.checked
            ? { color: colors.black }
            : { color: colors.grey500 };

        return (
            <span
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
            >
                <Checkbox
                    style={{ width: '100%' }}
                    iconStyle={ iconStyle }
                    labelStyle={ labelStyle }
                    label={ this.renderLabel () }
                    checked={ this.props.checked }
                    onCheck={ this.props.onCheck }
                    onFocus={ this.props.onFocus }
                />
            </span>
        );
    }

    renderLabel () {
        if (! this.props.icon && ! this.props.label)
            return '[ No label ]';

        if (this.props.icon && ! this.props.label)
            return this.renderIcon();

        if (! this.props.icon && this.props.label)
            return this.props.label;

        return (
            <span>
                { this.renderIcon() }
                { this.props.label }
            </span>
        );
    }

    renderIcon () {
        return React.cloneElement(this.props.icon, {
            style: Object.assign({},
                styles.icon,
                this.props.checked ? styles.checkedIcon : {},
                this.props.icon.props.style
            )
        });
    }
}
