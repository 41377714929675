import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Columns from './columns';
import Upload from './upload';
import Moment from 'moment';

const styles = {
    container: {
        background: colors.grey200,
        borderRadius: '3px',
        margin: '10px',
        flex: '1 1 0',
        padding: '10px',
    },
    title: {
        fontSize: '19px',
        margin: '10px',
        color: colors.userCold,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    status: {
        margin: '20px',
        textAlign: 'center',
        color: colors.grey500,
    },
};

const titles = {
    person: 'Persons list',
    company: 'Companies list',
    link: 'Links list',
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.settings.reech.source';

    static propTypes = {
        data: PropTypes.object.isRequired,
        onUpload: PropTypes.func.isRequired,
    };

    render() {
        const fixGender =
            this.props.data.type === 'person' &&
            ['credit-agricole-sa.deecision.com', 'preprod-credit-agricole-sa.deecision.com'].includes(
                process.env.APP_DOMAIN || window.location.hostname,
            );

        return (
            <div style={styles.container}>
                <div style={styles.title}>{titles[this.props.data.type]}</div>
                <div style={styles.status}>{this.renderStatus()}</div>
                <Columns list={this.normalizeColumns(this.props.data.columns, fixGender)} />
                <Upload onUpload={(data: string[][]) => this.props.onUpload(this.normalizeUpload(data, fixGender))} />
            </div>
        );
    }

    renderStatus() {
        const report = this.props.data.report || {};
        const total = report.total || 0;
        const invalid = report.invalid || 0;

        if (!total) return <span style={{ color: colors.userHot }}>This file is empty</span>;
        if (!invalid) return <span style={{ color: colors.userCold }}>{total} valid items</span>;

        return (
            <span>
                <span style={{ color: colors.userCold }}>{total - invalid} valid</span>
                &nbsp;and&nbsp;
                <span style={{ color: colors.userHot }}>{invalid} invalid</span>
                &nbsp;items
            </span>
        );
    }

    normalizeColumns(columns: Object[], fixGender: boolean): Object[] {
        if (!fixGender) {
            return columns;
        }

        columns.splice(4, 1);

        for (const column of columns) {
            if (column.index >= 4) {
                column.index -= 1;
            }
        }

        return columns;
    }

    normalizeUpload(data: string[][], fixGender: boolean) {
        const references: string[] = [];
        const normalized: string[][] = [];

        for (let row of data) {
            row = this.normalizeRow(row);

            if (!row.filter((v) => !!v).length) {
                continue; // remove empty row
            }

            const reference = this.extractReference(row);

            if (references.includes(reference)) {
                continue; // remove known reference}
            }

            if (this.props.data.type === 'person') {
                row = this.normalizePerson(row, fixGender);
            }

            references.push(reference);
            normalized.push(row);
        }

        return normalized;
    }

    normalizeRow(values: string[]): string[] {
        return values.map((value) => (value ? String(value).replace(/^\s+|\s+$/g, '') : ''));
    }

    normalizeDate(value: string): string {
        if (value && value.match(/^\d+$/)) {
            value = Moment('1899-12-30').add('days', parseInt(value)).format().substr(0, 10);
        }

        // cas des dates au format français
        else if (value && value.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
            value = `${value.substr(6, 4)}-${value.substr(3, 2)}-${value.substr(0, 2)}`;
        }

        return value;
    }

    normalizePerson(row: string[], fixGender: boolean): string[] {
        if (fixGender) {
            row.splice(4, 0, ''); // add missing gender column
        }

        row[5] = this.normalizeDate(row[5]);

        return row;
    }

    extractReference(row: string[]): string {
        if (this.props.data.type === 'link') {
            return `${row[0]}:${row[2]}`;
        }

        return row[0];
    }
}
