import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function infoCircles() {

    return (
        <div style={{width:600}}>
            <Accordion style={{margin:10}}>
                <Typography style={{color:"#4c615f" , fontWeight: 'bold' ,textAlign: 'center' , fontSize: 20}} >ZONES DE PRIORISATION</Typography>
            </Accordion>
            <Accordion style={{margin:10}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <span style={{
                        height: 10,
                        width: 10,
                        margin:5,
                        background: "#ff9800"
                    }} />
                    <Typography style={{color:"#ff9800"}}>Zone de Confort 20%</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Typography>
                        Segments du portefeuille client ayant atteint leur potentiel à cet instant
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        POTENTIEL = AUM
                    </Typography>
                </AccordionDetails>

            </Accordion>
            <Accordion style={{margin:10}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <span style={{
                        height: 10,
                        width: 10,
                        margin:5,
                        background: "#7cb342"
                    }} />
                    <Typography style={{color:"#7cb342"}}>Zone d'Exploration 70%</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Segments du portefeuille de clients qui peuvent être améliorés & prospects avec proximité client, potentiel et bon niveau de conformité
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        CLIENTS POTENTIEL ACTUEL > AUM
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        PROSPECTS POTENTIEL * CONFORMITE OK PROXÉMIE CLIENT FORTE
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{margin:10}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <span style={{
                        height: 10,
                        width: 10,
                        margin:5,
                        background: "#e53935"
                    }} />
                    <Typography style={{color:"#e53935"}}>Zone de faible opportunité 10%</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Clients & prospects sans priorité et/ou avec un niveau de risque élevé
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        PROSPECTS FAIBLE/GROS POTENTIEL * CONFORMITE KO
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
