import React from 'react';
import Application from 'app/layout/application';

import Studies from './studies';
import Management from './management';
import Calendar from './calendar';
import Archives from './archives';
import Library from './library';

import { inject, observer } from 'mobx-react';

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz';

    render() {
        const session = this.props.global.session.get();
        const cursor = this.props.global.cursor.get();
        const flags = this.props.global.flags.get();

        let entries = [];

        if (session.level === 'account') {
            const account = session.user.accounts.filter((account) => account.id === cursor.account)[0];
            const membership = account ? account.memberships[cursor.team || ''] : undefined;
            const level = membership ? membership.level : undefined;

            entries = entries.concat([
                {
                    path: 'studies',
                    icon: 'deeligenz.studies',
                    label: 'Due diligences',
                    screen: Studies,
                    access: flags.includes('deeligenz.access') && flags.includes('deeligenz.analysis'),
                },
                {
                    path: 'archives',
                    icon: 'deeligenz.archive',
                    label: 'Archives',
                    screen: Archives,
                    access: flags.includes('deeligenz.access') && flags.includes('deeligenz.analysis'),
                },
                {
                    path: 'calendar',
                    icon: 'deeligenz.calendar',
                    label: 'Calendar',
                    screen: Calendar,
                    access: flags.includes('deeligenz.access') && flags.includes('deeligenz.calendar'),
                },
                {
                    path: 'library',
                    icon: 'deeligenz.library',
                    label: 'Library',
                    screen: Library,
                    access: flags.includes('deeligenz.access') && flags.includes('deeligenz.library'),
                },
                {
                    path: 'management',
                    icon: 'deeligenz.management',
                    label: 'Settings',
                    screen: Management,
                    access:
                        flags.includes('deeligenz.access') &&
                        flags.includes('deeligenz.analysis') &&
                        ['director', 'manager'].includes(level),
                },
            ]);
        }

        if (session.level === 'intervention') {
            entries = entries.concat([
                {
                    path: 'studies',
                    icon: 'deeligenz.studies',
                    label: 'Due diligences',
                    screen: Studies,
                    access: true,
                },
            ]);
        }

        return (
            <Application
                help={
                    this.props.global.session.get().level === 'intervention'
                        ? 'https://www.dropbox.com/s/g7iw8jmoelvwtgd/DEECISION%20DEELIGENZ%20HANDBOOK%2011%202018.pdf?dl=0'
                        : 'https://www.dropbox.com/s/yzpa8uws8ohahhn/DEECISION%20DEELIGENZ%20ODD%20HANDBOOK%2009%202018.pdf?dl=0'
                }
                path="deeligenz"
                title="Deeligenz"
                index="studies"
                entries={entries}
            />
        );
    }
}
