import get from 'lodash/get';
import t from 'app/translation';
import { forEach } from 'lodash';

const fields = {
    keyData: {
        'Financials & employees': [
            'opre',
            'plbt',
            'pl',
            'cf',
            'toas',
            'shfd',
            'curr',
            'prma',
            'rshf',
            'rceme',
            'solr',
            'empl',
        ],
    },
    globalFormat: {
        Assets: ['fias', 'ifas', 'tfas', 'ofas', 'cuas', 'stok', 'debt', 'ocas', 'cash'],
        'LIABILITIES & EQUITY': ['shfd', 'capi', 'ncli', 'ltdb', 'oncl', 'prov', 'culi', 'loan', 'cred', 'ocli'],
        'MEMO LINES': ['wkca', 'ncas', 'empl'],
        'Profit & loss account': [
            'opre',
            'turn',
            'oppl',
            'fipl',
            'fire',
            'fiex',
            'plbt',
            'taxa',
            'plat',
            'extr',
            'exre',
            'exex',
            'pl',
        ],
        'MEMO LINES': ['expt', 'mate', 'staf', 'depr', 'oopi', 'inte', 'cf', 'av', 'ebta'],
    },
    globalRatio: {
        'Profitability ratios': [
            'rshf',
            'rcem',
            'rtas',
            'roe',
            'roce',
            'roa',
            'prma',
            'etma',
            'ebma',
            'cfop',
        ],
        'Operational ratios': ['nat', 'ic', 'stot', 'coll', 'crpe', 'exop', 'R&D'],
        'Structure ratios': [
            'curr',
            'liqr',
            'shlq',
            'solr',
            'soll',
            'gear',
            'ppe',
            'tpe',
            'sct',
            'ace',
            'sfpe',
            'wcpe',
            'tape',
        ],
    },
};

export  function makeResult(
    results: Map<string, any>,
    id: string,
    page: string,
): string[] {
    const entity = results.get(`entity:${id}`);
    const data = entity.data.accounting
    const result = [];

    const panels = fields[page];
    forEach(panels, (items, key) => {
        result.push([key].concat(get(data, 'date', [])));
        forEach(items, (item) => {
            result.push([t(`dna.deefind.accounting.${item}`)].concat(get(data, item, '')));
        });
        result.push([[]]);
        result.push([[]]);
    });

    return result;
}