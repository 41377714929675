import PropTypes from 'prop-types';
import React from 'react';

import DeefindProvider from 'app/layout/providers/deefind';
import t from 'app/translation';
import Tab from './history/tab';
import { filtersList, subfiltersList } from './history/utils';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.history';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div>
                <DeefindProvider
                    type="media"
                    id={this.props.entity.id}
                    mini={true}
                    renderEntity={(media) => this.renderLinks(media.news || [])}
                />
            </div>
        );
    }

    callWithLinks() {

    }

    renderLinks(news: Object[]) {

        const bodacc = news.filter(link => {
            return link.url.includes('bodacc.fr');
        });

        const results = [];

        if (bodacc) {
            results.push(
                <div
                    key={ 'history' }
                >
                    <table style={ { borderCollapse: 'collapse'} }>
                        <tbody>
                            <Tab list={ { bodacc } } entity={this.props.entity} />
                        </tbody>
                    </table>
                </div>
            );
        }

        return results;
    }
}
