import type { StockRecord } from '@deecision/deefind-types/model';

export default function makeStockDirects(records: StockRecord[]): { [source: string]: { [target: string]: number } } {
    const lists = {};

    for (const record of records) {
        if (!lists[record.source]) {
            lists[record.source] = {};
        }

        if (!lists[record.source][record.target]) {
            lists[record.source][record.target] = [];
        }

        for (const stock of record.stocks) {
            if (stock.direct) {
                lists[record.source][record.target].push(stock.value && stock.value.min || 0);
            }
        }
    }

    const values = {};

    for (const source of Object.keys(lists)) {
        for (const target of Object.keys(lists[source])) {
            if (!lists[source][target].length) {
                continue;
            }

            if (!values[source]) {
                values[source] = {};
            }

            values[source][target] = Math.max(...lists[source][target]);
        }
    }

    return values;
}
