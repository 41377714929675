import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch'
    },
    input: {
        textAlign: 'right',
        minWidth: '10px',
    },
    unit: {
        flexGrow: '0',
        flexShrink: '0',
        width: 'auto',
        color: colors.grey500,
        whiteSpace: 'nowrap',
        paddingLeft: '0'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'number';

    static propTypes = {
        value: PropTypes.number,
        decimals: PropTypes.number,
        scale: PropTypes.oneOf(['K', 'M', 'G']),
        min: PropTypes.number,
        max: PropTypes.number,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    state = {
        minus: false,
        dot: false,
    };

    roundValue (value: ?number): ?number {
        if (! isNumber(value)) {
            return undefined;
        }

        if (! this.props.decimals) {
            return Math.floor(value);
        }

        const scale = Math.pow(10, this.props.decimals);

        return Math.floor(value * scale) / scale;
    }

    parseValue (value: string): ?number {
        if (! value) {
            return undefined;
        }

        const float = parseFloat(value.replace(/\s+/g, '').replace(',', '.'));

        if (isNaN(float)) {
            return undefined;
        }

        return this.roundValue(float);
    }

    formatValue (value: ?number): string {
        const prefix = this.state.minus ? '- ' : '';
        const suffix = this.state.dot ? '.' : '';

        if (! isNumber(value)) {
            return prefix + suffix;
        }

        const parts = ('' + this.roundValue(value)).match(/^-?([0-9]+)(\.[0-9]+)?$/);

        if (! parts) {
            return prefix + suffix;
        }

        return prefix
            + parts[ 1 ].replace(/./g, (c, i, a) => i && ((a.length - i) % 3 === 0) ? ' ' + c : c)
            + (parts[ 2 ] || '')
            + suffix;
    }

    handleChange (event: any) {
        const value = event.target.value;
        this.state.minus = !! (value && value.match(/^\s*-\s*$/));
        this.state.dot = !! (value && value.match(/\.\s*$/));
        this.props.onChange(this.parseValue(value));
    }

    handleFocus (event) {
        event.target.select();
        this.props.onFocus(event);
    }

    render () {
        if (! this.props.unit) {
            return this.renderInput();
        }

        return (
            <div style={ styles.container }>
                { this.renderInput() }
                { this.renderUnit() }
            </div>
        );
    }

    renderInput () {
        const style = Object.assign({},
            this.props.style,
            styles.input,
            this.props.unit ? { width: '100%' } : { textAlign: 'center' }
        );

        return (
            <input
                key="input"
                type="text"
                value={ this.formatValue(this.props.value) }
                style={ style }
                onChange={ this.handleChange.bind(this) }
                onFocus={ this.handleFocus.bind(this) }
                onBlur={ this.props.onBlur }
            />
        );
    }

    renderUnit () {
        return (
            <span style={ Object.assign({}, this.props.style, styles.unit) }>
                { this.props.unit }
            </span>
        );
    }
}
