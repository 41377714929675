export default {
    'AG': 'Agriculture',
    'ER': 'Energy resources',
    'HI': 'Mining & heavy industries',
    'FI': 'Food industry',
    'TB': 'Tobacco',
    'MC': 'Manufacture of clothings',
    'MW': 'Manufacture of wood/paper',
    'CM': 'Communication',
    'CI': 'Chemical industries',
    'EE': 'Electronic & electrical equipment',
    'TM': 'Transportation & mechanical',
    'MA': 'Manufacture',
    'IS': 'Industries source',
    'WW': 'Water & waste treatment',
    'CE': 'Construction & civil engineering',
    'WS': 'Wholesale',
    'RT': 'Retail',
    'TC': 'Transportation of commodities',
    'RH': 'Real estate + hospitality',
    'FB': 'Food & beverage source',
    'IT': 'Telecom & IT',
    'BF': 'Bank, finance & insurance source',
    'RE': 'Real estate',
    'LA': 'Legal, accounting & consultancy',
    'ST': 'Scientific & technical',
    'RN': 'Rental',
    'BB': 'B2B source',
    'LE': 'Leisure & entertainment source',
    'SC': 'Security',
    'PA': 'Public administration',
    'ED': 'Education',
    'HE': 'Health',
    'GB': 'Gambling',
    'BC': 'B2C source',
}
