import React from 'react';
import { forEach, cloneDeep } from 'lodash';

export default function getFilters(person) {
    let arr = [];
    let i = 0;

    if (person) {
        forEach(person[0], (filter) => {
            let innerArr = [];
            let x = 0;

            forEach(person, (item) => {
                if (item !== person[0] && item[i] !== '') {
                    innerArr.push({
                        'element' : item[i],
                        'number' : getNumberItems(item[i], person, i),
                    });
                    x++;
                }
            });
            if (x > 0) {
                arr.push({
                    [filter]: getUniqueListBy(innerArr, 'element'),
                });
            }

            i++;
        });

        let valueToRemove = [];

        forEach(arr, (value) => {
            if ((value[Object.keys(value)[0]]).length === 1 &&
                value[Object.keys(value)[0]][0]['element'] === ''
            ) {
                valueToRemove.push(value);
            }
        });

        forEach(valueToRemove, (value) => {
           removeItemOnce(arr, value);
        });
    }

    return arr;
}

function removeItemOnce(arr, value) {
    let index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}


function getNumberItems(element, persons, i) {
    let number = 0;

    forEach(persons, (person) => {
        if (person[i] === element) {
            number++;
        }
    });

    return number;
}

export function getInter(exports, filters, elements) {
    let i = 0;
    let myFilters = [];
    forEach(filters, () => {
        myFilters.push(filters[i] + ' : ' + elements[i]);
        i++;
    });
    return (getPersons(parseWithTable(exports, getTable(exports, true)), myFilters)).length - 1;
}

export function getExports(exports, group) {
    let arr = [];

    if (exports) {
        arr.push(exports[0]);
        forEach(group, (selected) => {
            let index = exports[0].indexOf(selected.split(' : ')[0]);
            forEach(exports, (item) => {
                if (item !== exports[0]) {
                    if (item[index] === selected.split(' : ')[1]) {
                        if (arr.find(elem => elem === item) === undefined) {
                            arr.push(item);
                        }
                    }
                }
            })
        });
    }

    return arr;
}

export function getPersons(exports, group) {
    let arr = [];
    let j = 0;
    let groupOfUniqueFilter = [];

    forEach(group, (filter) => {
        if (groupOfUniqueFilter.find(elem => elem === filter.split(' : ')[0]) === undefined) {
            groupOfUniqueFilter.push(filter.split(' : ')[0]);
        }
    });
    if (exports) {
        arr.push(exports[0]);
        forEach(exports, (person) => {
            if (person !== exports[0]) {
                let i = 0;
                forEach(group, (filter) => {
                    let index = exports[0].indexOf(filter.split(' : ')[0]);
                    if (person[index] === filter.split(' : ')[1]) {
                        if (arr.find(elem => elem === person) === undefined) {
                            i++;
                        }
                    }
                    j++;
                });
                if (i === groupOfUniqueFilter.length) {
                    arr.push(person);
                }
            }
        });
    }

    return arr;
}

export function parseWithTable(exports, table) {
    let exportsCopy = [];
    let tableCopy = [];
    let i = 0;

    if (exports && exports.person && table) {
        exportsCopy = cloneDeep(exports.person);
        tableCopy = cloneDeep(table);
        forEach(exportsCopy[0], (label) => {
            if (tableCopy[i] && tableCopy[i].disabled) {
                forEach(exportsCopy, (item) => {
                    item.splice(i, 1);
                });
                tableCopy.splice(i, 1);
            } else if (tableCopy[i].ranges) {
                forEach(exportsCopy, (item) => {
                    if (exportsCopy[0] !== item) {
                        forEach(tableCopy[i].ranges, (range) =>{
                            if (!range.min) {
                                if (item[i] <= range.max)
                                    item[i] = range.label;
                            } else if (!range.max) {
                                if (item[i] >= range.min)
                                    item[i] = range.label;
                            } else {
                                if (item[i] <= range.max && item[i] >= range.min)
                                    item[i] = range.label;
                            }
                        });
                    }
                });
                i++;
            } else {
                i++;
            }
        });
    }
    return exportsCopy;
}

export function getTable(exports, getDisabled) {
    let arr = [];

    if (exports && exports.person) {
        forEach(exports.person[0], (item) => {
            let disabled = false;
            let ranges = null;
            if (item === '' ||
                item === 'Name' ||
                item === 'First name' ||
                item === 'Last name' ||
                item === 'Birth date' ||
                item === 'Email' ||
                item === 'LinkedIn' ||
                item === 'Country' ||
                item === 'CEO first name' ||
                item === 'CEO last name' ||
                item === 'Phone' ||
                item === 'CFO first name' ||
                item === 'CFO last name' ||
                item === 'MD first name' ||
                item === 'MD last name' ||
                item === 'Relation first name' ||
                item === 'Relation last name' ||
                item === 'City' ||
                item === 'Address' ||
                item === 'Relation birth date'
            ) {
                disabled = true;
            }
            if (item === 'Relation completeness') {
                ranges = [
                    {label: '<= 50', min: null, max: 50},
                    {label: '> 50', min: 51, max: null},
                ];
            } else if (item === 'Company Turnover') {
                ranges = [
                    {label: '< 45M€', min: null, max: 44999999.99},
                    {label: 'Between 45M€ and 47M€', min: 45000000, max: 47000000},
                    {label: '> 47M€', min: 47000000.01, max: null},
                ];
            } else if (item === 'Relation potential') {
                ranges = [
                    {label: '< 1M€', min: null, max: 999999.99},
                    {label: 'Between 1M€ and 50M€', min: 1000000, max: 50000000},
                    {label: '> 50M€', min: 50000000.01, max: null},
                ];
            } else if (item === 'Probability Year 1' ||
                item === 'Probability Year 2' ||
                item === 'Probability Year 3' ||
                item === 'Probability Year 4' ||
                item === 'Probability Year 5') {
                ranges = [
                    {label: '<= 70', min: null, max: 70},
                    {label: '> 70', min: 71, max: null},
                ];
            }
            arr.push({
                label: item,
                disabled : getDisabled ? false : disabled,
                ranges: ranges,
            });
        });
    }

    return arr;
}