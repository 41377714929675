// @flow

import type { CountsSegment } from '../../types';

import React from 'react';
import PropTypes from 'prop-types';
import Matrix from './matrix';
import Donut from './donut';
import Extra from './extra';
import Legend from './legend';
import List from './list';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import colors from 'app/theme/colors';
import some from 'lodash/some';

const LABELS = {
    potentialCovid19: 'CV19 %',
    potential: 'Pot',
    risk: 'Risk',
    proximity: 'Prox',
    probability: 'Prob',
    potentialCashout: 'Cash out pot.',
    expectedValue: 'Cash out exp. ',
    upsell: 'Upsell',
    upsellPrct: 'Upsell %'
};
const casa = ['credit-agricole-sa.deecision.com', 'preprod-credit-agricole-sa.deecision.com'].includes(process.env.APP_DOMAIN || window.location.hostname);
const moneyLimit = (casa)?['0,5M€', '3M€']:['1M€', '5M€'];
const STEPS = {
    potentialCovid19: ['40%', '70%'],
    potential: moneyLimit,
    risk: [2, 3],
    proximity: [4, 8],
    probability: ['40%', '70%'],
    potentialCashout: moneyLimit,
    expectedValue: moneyLimit,
    upsell: moneyLimit,
    upsellPrct: ['50%', '100%'],
};

const styles = {
    container: {
        position: 'relative',
        paddingTop: '20px',
    },
    legend: {
        position: 'absolute',
        top: '-10px',
        right: '0',
    },
    cell: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    select: {
        width: '175px',
        textAlign: 'left',
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        paddingLeft: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.results';

    static propTypes = {
        counts: PropTypes.object.isRequired,
        axes: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        onAxis: PropTypes.func.isRequired,
        proximity: PropTypes.bool,
        onOpen: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    open(state: Object) {
        this.refs['list'].open(state);
    }

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <Matrix
                    cells={[
                        this.renderSegment(this.props.counts.segment13),
                        this.renderSegment(this.props.counts.segment23),
                        this.renderSegment(this.props.counts.segment33),

                        this.renderSegment(this.props.counts.segment12),
                        this.renderSegment(this.props.counts.segment22),
                        this.renderSegment(this.props.counts.segment32),

                        this.renderSegment(this.props.counts.segment11),
                        this.renderSegment(this.props.counts.segment21),
                        this.renderSegment(this.props.counts.segment31),
                    ]}
                    xLabel={this.renderAxis('x')}
                    yLabel={this.renderAxis('y')}
                    xStep1={STEPS[this.props.axes.x][0]}
                    xStep2={STEPS[this.props.axes.x][1]}
                    yStep1={STEPS[this.props.axes.y][0]}
                    yStep2={STEPS[this.props.axes.y][1]}
                />

                <Legend style={styles.legend} />
                <List ref="list" />
            </div>
        );
    }

    renderSegment(segment: CountsSegment) {
        const count = segment.ids.length;

        if (!count) {
            return null;
        }

        const contents = [<Donut key="donut" count={count} allocation={segment.completeness} />];

        if (this.props.proximity) {
            contents.push(<Extra key="extra" label={LABELS[this.props.axes.extra]} allocation={segment.extra} />);
        }

        return (
            <div onClick={() => this.props.onOpen(segment.ids)} style={styles.cell}>
                {contents}
            </div>
        );
    }

    renderAxis(axis: 'x' | 'y') {
        const contents = [];

        if (typeof this.props.filters.potential !== 'undefined') {
            contents.push(<MenuItem key="potential" value="potential" primaryText="Potential" />);
        }

        if (typeof this.props.filters.potentialCovid19 !== 'undefined') {
            contents.push(<MenuItem key="potentialCovid19" value="potentialCovid19" primaryText="potential Covid19 %" />);
        }

        if (typeof this.props.filters.risk !== 'undefined') {
            contents.push(<MenuItem key="risk" value="risk" primaryText="Vigilance level" />);
        }

        if (this.props.proximity) {
            contents.push(<MenuItem key="proximity" value="proximity" primaryText="Proximity" />);
        }

        if (typeof this.props.filters.probability !== 'undefined') {
            contents.push(<MenuItem key="probability" value="probability" primaryText="Probability" />);
        }

        if (typeof this.props.filters.potentialCashout !== 'undefined') {
            contents.push(<MenuItem key="potentialCashout" value="potentialCashout" primaryText="Potential Cashout" />);
        }

        if (typeof this.props.filters.expectedValue !== 'undefined') {
            contents.push(<MenuItem key="expectedValue" value="expectedValue" primaryText="Cashout expectancy" />);
        }

        if (typeof this.props.filters.upsell !== 'undefined') {
            contents.push(<MenuItem key="upsell" value="upsell" primaryText="Upsell" />);
        }

        if (typeof this.props.filters.upsellPrct !== 'undefined') {
            contents.push(<MenuItem key="upsellPrct" value="upsellPrct" primaryText="Upsell %" />);
        }

        return (
            <SelectField
                autoWidth
                value={this.props.axes[axis]}
                onChange={(event, index, value) => this.props.onAxis(axis, value)}
                style={styles.select}
                underlineStyle={{ display: 'none' }}
                iconStyle={{ fill: colors.grey500 }}
            >
                {contents}
            </SelectField>
        );
    }
}
