import type { AuthCursor, AuthSession } from '@deecision/infra-types/common';

export function resolveFlags(session: AuthSession, cursor: AuthCursor): string[] {
    if (session.level === 'anon') {
        return [];
    }

    if (cursor.intervention) {
        const intervention = session.user.interventions.filter(int => int.id === cursor.intervention)[0];
        const membership = intervention ? intervention.memberships[intervention.team] : undefined;
        return membership ? membership.flags : [];
    }

    if (cursor.account && cursor.team) {
        const account = session.user.accounts.filter(acc => acc.id === cursor.account)[0];
        const membership = account ? account.memberships[cursor.team] : undefined;
        return membership ? membership.flags : [];
    }

    return [];
}
