// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import { computeStatusLabel } from '../../common/compute';
import t from 'app/translation';

const styles = {
    container: {
        display: 'flex',
    },
    chart: {
        width: '50%',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'comeet.management.dashboard.charts';

    static propTypes = {
        typeCosts: PropTypes.object.isRequired,
        statusCosts: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style || {}) }>
                <Views.Chart.Container
                    title={t('dna.comeet.eventCostTitle')}
                    type="half-pie"
                    style={ styles.chart }
                >
                    {
                        Object
                            .keys(this.props.typeCosts)
                            .filter((type: string) => !! this.props.typeCosts[type])
                            .map((type: string) => (
                                <Views.Chart.Entry
                                    key={ type }
                                    label={ type }
                                    value={ this.props.typeCosts[type] }
                                />
                            ))
                    }
                </Views.Chart.Container>

                <Views.Chart.Container
                    title={t('dna.comeet.prospectCostTitle')}
                    type="half-pie"
                    style={ styles.chart }
                >
                    {
                        Object
                            .keys(this.props.statusCosts)
                            .filter((type: string) => !! this.props.statusCosts[type])
                            .map((type: string) => (
                                <Views.Chart.Entry
                                    key={ type }
                                    label={ computeStatusLabel(type) }
                                    value={ this.props.statusCosts[type] }
                                />
                            ))
                    }
                </Views.Chart.Container>
            </div>
        )
    }
}
