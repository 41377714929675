// @flow

import _, { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Card from 'dna/entity/card';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject(stores => ({
    reech: stores.reech,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.explorer.search.result.companies';

    props: {
        state: Object,
        reech: Object,
        entities: Object[],
        person: string,
        activity: string,
    };

    filteredEntities: any[];

    constructor(props: any) {
        super(props);
        this.filterEntities(props);
    }

    UNSAFE_componentWillReceiveProps(props: any) {
        if (
            props.entities !== this.props.entities ||
            props.person !== this.props.person ||
            props.activity !== this.props.activity
        ) {
            this.filterEntities(props);
        }
    }

    filterEntities(props: any) {
        let entities = (props.entities || []).slice();

        if ((!props.person && !props.activity) || !entities.length) {
            this.filteredEntities = entities;
        }

        if (props.person) {
            entities = entities.filter(entity => {
                const names = get(entity, 'preview.persons', []).slice();
                return names.filter(name => name.toLowerCase().includes(props.person.toLowerCase())).length > 0;
            });
        }

        if (props.activity) {
            entities = entities.filter(entity => {
                const code = get(entity, 'preview.activity', '');
                return !code || code === props.activity;
            });
        }

        this.filteredEntities = entities;
    }

    render() {
        const reechCustomers = this.props.reech.customers;
        const reechProspects = this.props.reech.prospects;
        const reechKnown = this.props.reech.known;
        const reechCount = reechKnown.length;

        return (
            <Views.List.Container state={this.props.state} entities={this.filteredEntities}>
                <Views.List.Frame type="cards" element={<Card />} />

                <Views.List.Reducer
                    type="sort"
                    specs={{
                        contacts: {
                            label: 'Contacts',
                            path: item =>
                                _.keys(_.get(item, 'preview.companies')).length +
                                _.keys(_.get(item, 'preview.persons')).length,
                            type: 'desc',
                        },
                        name: {
                            label: t('common.label.name'),
                            path: 'preview.name',
                            type: 'asc',
                        },
                        capital: {
                            label: t('common.label.fortune'),
                            path: entity =>
                                ((entity.preview.flags || []).includes('listed') ? 1e12 : 0) +
                                (entity.preview.capital || 0),
                            type: 'desc',
                        },
                        turnover: {
                            label: t('common.label.turnover'),
                            path: entity =>
                                ((entity.preview.flags || []).includes('listed') ? 1e12 : 0) +
                                (entity.preview.turnover || 0),
                            type: 'desc',
                        },
                        equity: {
                            label: t('common.label.equity'),
                            path: entity =>
                                ((entity.preview.flags || []).includes('listed') ? 1e12 : 0) +
                                (entity.preview.equity || 0),
                            type: 'desc',
                        },
                        ebitda: {
                            label: t('common.label.ebitda'),
                            path: entity =>
                                ((entity.preview.flags || []).includes('listed') ? 1e12 : 0) +
                                (entity.preview.ebitda || 0),
                            type: 'desc',
                        },
                    }}
                />

                <Views.List.Reducer
                    name="contacts"
                    title={t('dna.deefind.company.contactsFilter')}
                    type="groups"
                    specs={{
                        yes: {
                            label: '✓',
                            info: t('dna.deefind.company.contact.yes'),
                            filter: entity =>
                                _.get(entity, 'preview.mandates', 0) + _.get(entity, 'preview.positions', 0) > 0,
                        },
                        no: {
                            label: '✕',
                            info: t('dna.deefind.company.contact.no'),
                            filter: entity =>
                                _.get(entity, 'preview.mandates', 0) + _.get(entity, 'preview.positions', 0) === 0,
                        },
                    }}
                />

                <Views.List.Reducer
                    name="open"
                    title={'Open'}
                    type="groups"
                    specs={{
                        yes: {
                            label: '✓',
                            info: t('dna.deefind.company.contact.yes'),
                            filter: item => !(item.preview.flags || []).includes('closed'),
                        },
                        no: {
                            label: '✕',
                            info: t('dna.deefind.company.contact.no'),
                            filter: item => (item.preview.flags || []).includes('closed'),
                        },
                    }}
                />

                <Views.List.Reducer
                    name="reech"
                    type="groups"
                    title="Reech"
                    specs={{
                        any: {
                            label: '✓',
                            info: 'Any Reech match',
                            filter: item => reechKnown.includes(item.id),
                        },
                        customer: {
                            label: 'C',
                            info: 'Customer match',
                            filter: item => reechCustomers.includes(item.id),
                        },
                        prospect: {
                            label: 'P',
                            info: 'Prospect match',
                            filter: item => reechProspects.includes(item.id),
                        },
                    }}
                />

                <Views.List.Reducer
                    name="compliance"
                    title={t('common.label.compliance')}
                    type="groups"
                    specs={{
                        listed: {
                            label: t('dna.deefind.company.contact.listedCompaniesShort'),
                            info: t('dna.deefind.company.contact.listedCompanies'),
                            filter: item => (item.preview.flags || []).includes('listed'),
                        },
                        holding: {
                            label: t('dna.deefind.company.contact.holdingShort'),
                            info: t('dna.deefind.company.contact.holding'),
                            filter: item => (item.preview.flags || []).includes('holding'),
                        },
                        fund: {
                            label: 'Fun',
                            info: 'Investment fund',
                            filter: item => (item.preview.flags || []).includes('fund'),
                        },
                        // gambling: {
                        //     label: t('dna.deefind.company.contact.gamblingShort'),
                        //     info: t('dna.deefind.company.contact.gambling'),
                        //     filter: item => (item.preview.flags || []).includes('gambling'),
                        // },
                        justice: {
                            label: t('dna.deefind.company.contact.sanctionedShort'),
                            info: t('dna.deefind.company.contact.sanctioned'),
                            filter: item => (item.preview.flags || []).includes('justice'),
                        },
                        c19: {
                            label: 'c19',
                            info: 'Sensitivity to covid19 crisis',
                            filter: item => (item.preview.flags || []).includes('crisis-impact') || (item.preview.flags || []).includes('crisis-direct'),
                        }
                    }}
                />

                <Views.List.Reducer type="fuse" specs={{ paths: ['preview.name'] }} />
            </Views.List.Container>
        );
    }
}
