// @flow

import * as utils from './utils';
import get from 'lodash/get';
import size from 'lodash/size';

const baseHeader = [
    'Name',
    'Capital',
    'Identifiers',
    'Country',
    'EBITDA Value',
    'EBITDA Date',
    'Holding?',
    'Incorporation date',
    'ISIN',
    'Listed?',
    'NACE code',
    'Size',
    'Turnover (€)',
    'Turnover date',
    'Valuation (€)',
    'Valuation source',
    'Veegilenz level',
    'Reech type',
    'Reech relevance',
];

const extendedHeader = [
    'Current companies count',
    'Past companies count',
    'Current F500 relations count',
    'Current relations count',
    'Past relations count',
    'Reech relations count',
    'Reech F500 relations count',
    'Deals count',
];

export function makeHeader(extended: boolean): string[] {
    return extended ? baseHeader.concat(extendedHeader) : baseHeader;
}

export async function makeResult(
    results: Map<string, any>,
    id: string,
    reechService: Object,
    extended: boolean,
): Promise<string[]> {
    const entity = results.get(`entity:${id}`);
    const network = results.get(`network:${id}`);
    const deals = results.get(`deals:${id}`);

    const year = Math.min(
        ...get(entity, 'data.indicators', [])
            .filter(indicator => parseInt(indicator.year) > 0)
            .map(indicator => parseInt(indicator.year)),
    );

    const reech = await utils.makeReechResult(reechService, {
        id: get(entity, 'id', ''),
        type: 'company',
        preview: get(entity, 'preview', {}),
    });

    let result = [
        get(entity, 'preview.name', ''),
        get(entity, 'preview.capital', ''),
        get(entity, 'preview.identifiers', []).join(' / '),
        get(entity, 'preview.country', ''),
        get(entity, 'preview.ebitda', ''),
        get(entity, 'preview.ebitda', '') ? String(year) : '',
        get(entity, 'preview.flags', []).includes('holding') ? 'holding' : '',
        get(entity, 'data.identity.creation', ''),
        get(entity, 'preview.isins', []).join(' / '),
        get(entity, 'preview.flags', []).includes('listed') ? 'listed' : '',
        get(entity, 'preview.naceCode', ''),
        get(entity, 'data.identity.employees', ''),
        get(entity, 'preview.turnover', ''),
        get(entity, 'preview.turnover', '') ? String(year) : '',
        get(entity, 'preview.valuation.value', ''),
        utils.makeValuationType(get(entity, 'data.valuation', {})),
        get(entity, 'vigilance.direct.score', ''),
        reech.type,
        reech.relevance,
    ];

    if (extended) result = result.concat([
        utils.countRelations(get(network, 'relations.companies', []), false),
        utils.countRelations(get(network, 'relations.companies', []), true),
        utils.countRelations(get(network, 'relations.persons', []), false, 'fortune'),
        utils.countRelations(get(network, 'relations.persons', []), false),
        utils.countRelations(get(network, 'relations.persons', []), true),
        await utils.countReechRelations(reechService, get(network, 'relations.persons', [])),
        await utils.countReechRelations(reechService, get(network, 'relations.persons', []), 'fortune'),
        size(get(deals, 'details', {})) || '',
    ]);

    return result;
}
