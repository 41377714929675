import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

let styles = {
    container: {
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    block: {
        alignSelf: 'stretch',
        padding: '0 10px 15px 10px',
        overflow: 'hidden',
        flexGrow: '1',
        width: '20%',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.blocks';

    static propTypes = {
        tab: PropTypes.number.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                { React.Children.map(this.props.children, this.renderBlock.bind(this)) }
            </div>
        );
    }

    renderBlock (content, index) {
        let style = _.clone(styles.block);

        if (index > 0) {
            style.borderLeft = '1px solid ' + colors.grey200;
        }

        if (index === this.props.tab) {
            // style.background = colors.grey100;
        }

        return (
            <div style={ style }>
                { content }
            </div>
        );
    }
};
