import _ from 'lodash';
import Moment from 'moment';

export default function (start, end) {

    start = Moment.utc(start);
    end = Moment.utc(end);

    const duration = end.diff(start, 'days');

    function position (date) {
        if (! duration) return 0;
        return 100 * (Moment.utc(date).diff(start, 'days') + 1) / duration;
    }

    return {
        getDatePosition (date) {
            return position(date);
        },

        getRuleLabels (unit: string) {
            const units = [];
            unit = unit === 'year' || unit === 'month' ? unit : 'month';
            let date = Moment.utc(start).add(1, unit);

            do {
                units.push({
                    year: date.format('YYYY'),
                    month: date.format('MMM'),
                    number: date.format('MM')
                });

                date.add(1,unit);
            } while (end > date);

            const labels = [];
            let year = undefined;

            _.each(units, step => {
                let content = step[unit];

                if (year !== step.year) {
                    content = unit === 'year' ? ' ' + step.year : content + ' ' + step.year;
                    year = step.year;
                }

                labels.push({
                    position: position(`${step.year}-${step.number}-01`),
                    content: content
                })
            });

            return labels;
        },

        getEventsFootprint (events) {
            return _.map(events, event => `${event.id}:${event.position}`).join(',');
        }
    }
}
