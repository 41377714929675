// @flow

import React from 'react';
import { withRouter } from 'react-router-dom'
import Icon from 'app/theme/icon';
import styles from './styles';

import Popover from 'material-ui/Popover';

@withRouter
export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.application.header.entry';

    props: {
        history?: Object,
        url?: string,
        icon: string,
        label?: string,
        count?: number,
        disabled?: boolean,
        children?: any,
        onClick: Function,
    };

    state = {
        hover: false,
        focus: false,
        anchor: undefined,
    };

    computeStyle (element: string): Object {
        return Object.assign({},
            styles[element].base,
            this.getElementStyle(element)
        );
    }

    getElementStyle(element: string): Object {
        if (this.props.disabled) {
            return styles[element].disabled || {};
        }

        if (this.props.url === this.getPathname()) {
            return styles[element].current || {};
        }

        if (this.state && this.state.hover) {
            return styles[element].hover || {};
        }

        return {};
    }

    getPathname(): string {
        const history = this.props.history;

        if (!history || !history.location || !history.location.pathname) {
            return '';
        }

        return history.location.pathname;
    }

    handleClick = () => {
        if (this.props.disabled) {
            return;
        }

        if (this.props.url && this.props.history) {
            this.props.history.push({ pathname: this.props.url });
        }

        this.setState({ focus: true });
    };

    render () {
        return (
            <span
                ref={ element => this.state.anchor = element }
                style={ this.computeStyle('container') }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onClick ? this.props.onClick : this.handleClick }
            >
                { this.renderIcon() }
                { this.renderLabel() }
                { this.renderCount() }
                { this.renderPanel() }
            </span>
        );
    }

    renderIcon () {
        if (! this.props.icon) {
            return null;
        }

        return (
            <Icon
                type={ this.props.icon }
                style={ this.computeStyle('icon') }
            />
        );
    }

    renderLabel () {
        if (! this.props.label) {
            return null;
        }

        return (
            <span style={ this.computeStyle('label') }>
                { this.props.label }
            </span>
        );
    }

    renderCount () {
        if (! this.props.count) {
            return null;
        }

        return (
            <span style={ this.computeStyle('count') }>
                { this.props.count }
            </span>
        );
    }

    renderPanel () {
        if (!this.props.children) {
            return null;
        }

        return (
            <Popover
                anchorEl={ this.state.anchor }
                open={ this.state.focus }
                useLayerForClickAway
                onRequestClose={ reason => this.setState({ focus: false }) }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                targetOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                { this.props.children }
            </Popover>
        );
    }
}
