import React from 'react';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Card from './card';
import t from 'app/translation';
import { toJS } from 'mobx';

export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects.summary.list';

    props: {
        state?: Object,
        entities: Object,
        onCreate: Function
    };

    render () {
        return (
            <Views.List.Container
                entities={ toJS(this.props.entities) }
                state={ this.props.state }
            >
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        name: { label: 'Name', path: 'payload.target.name' },
                        //
                        // start: { label: t('dna.comeet.startDate'), path: 'period.start' },
                        // deadline: { label: t('dna.comeet.deadline'), path: 'period.deadline' },
                        //
                        // events: {
                        //     label: t('dna.comeet.eventsCount'),
                        //     path: item => (item.events || []).length
                        // },
                    }}
                />

                <Views.List.Reducer
                    type="groups"
                    name="status"
                    title={t('dna.comeet.eventsCount')}
                    specs={{
                        draft: { label: t('dna.comeet.status.draft'), filter: item => item.status === 'draft' },
                        started: { label: t('dna.comeet.status.ongoing'), filter: item => item.status === 'started' },
                        finish: { label: t('dna.teem.status.finish'), filter: item => item.status === 'finish' },
                    }}
                />

                <Views.List.Reducer
                    type="fuse"
                    specs={{ paths: ['payload.target.name'] }}
                />

                <Views.List.Frame
                    type="cards"
                    element={ <Card /> }
                />

                <Views.List.Block position="right">
                    <Views.Common.Button
                        label={t('dna.teem.newProspect')}
                        icon={ <Icon type="do.create" /> }
                        onClick={ this.props.onCreate }
                    />
                </Views.List.Block>
            </Views.List.Container>
        );
    }
}
