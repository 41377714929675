import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Country from './country';
import Currency from './currency';
import File from './file';
import Button from 'app/views/common/button';
import { isNull, get } from 'lodash';
import Moment from 'moment';

const styles = {
    input: {
        background: colors.white,
        border: '1px solid ' + colors.grey400,
        borderRadius: '3px',
        padding: '0 10px',
        boxSizing: 'border-box',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        lineHeight: '38px',
        height: '40px',
    },
    footer: {
        marginTop: '20px',
        textAlign: 'right',
    },
    submit: {
        marginLeft: '20px',
    },
    undo: {
        marginLeft: '20px',
    },

    listItem: {
        display: 'flex',
    },
    listRemove: {
        marginRight: '10px',
        float: 'left',
    },
    listNew: {
        display: 'inline-lock',
        padding: '8px 10px',
        marginRight: '10px',
        float: 'left',
    },

    readOnly: {
        background: 'transparent',
        borderWidth: '0',
        cursor: 'default',
    },
    inputFocus: {
        boxShadow: 'inset 0 0 3px ' + colors.userCold,
    },

    stringInput: {
        width: '500px',
    },
    textInput: {
        fontSize: '15px',
        whiteSpace: 'pre-line',
        width: '950px',
        height: '150px',
        background: colors.white,
        border: '1px solid ' + colors.grey400,
        borderRadius: '3px',
        padding: '10px 15px',
        boxSizing: 'border-box',
        fontFamily: 'inherit',
        lineHeight: '18px',
    },
    dateInput: {
        width: '250px',
        letterSpacing: '2px',
        textAlign: 'center',
    },
    numberInput: {
        width: '250px',
        letterSpacing: '2px',
    },
    numberUnit: {
        margin: '0 20px',
        color: colors.grey500,
        fontSize: '19px',
        lineHeight: '40px',
    },
    booleanInput: {
        display: 'flex',
        borderRadius: '3px',
        overflow: 'hidden',
        color: colors.grey500,
        width: '200px'
    },
    booleanEdit: {
        background: colors.white,
        border: '1px solid ' + colors.grey400,
        cursor: 'pointer',
    },
    booleanItem: {
        background: 'transparent',
        width: '100px',
        lineHeight: '38px',
        height: '38px',
        textAlign: 'center',
    },
    booleanHover: {
        background: colors.grey200,
        color: colors.userCold,
    },
    booleanActive: {
        background: colors.userCold,
        color: colors.white,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.scalar';

    static propTypes = {
        type: PropTypes.string.isRequired,
        list: PropTypes.bool,
        options: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
        answer: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    state = {
        hover: { index: 0, id: null },
        focus: { index: 0, id: null },
        flags: { decimal: true },
    };

    render() {
        // if (this.props.answer.value === undefined && this.props.answer.values === undefined && this.props.readOnly && this.props.type !== 'file') {
        //     return null;
        // }
        if (this.props.readOnly && isNull(this.props.answer.value) && isNull(this.props.answer.values)) {
            return null;
        }

        let render;

        switch (this.props.type) {
            case 'string':
            case 'location':
            case 'email':
                render = this.renderString.bind(this);
                break;

            case 'number':
                render = this.renderNumber.bind(this);
                break;

            case 'date':
                render = this.renderDate.bind(this);
                break;

            case 'text':
                render = this.renderText.bind(this);
                break;

            case 'file':
                render = this.renderFile.bind(this);
                break;

            case 'country':
                render = this.renderCountry.bind(this);
                break;

            case 'currency':
                render = this.renderCurrency.bind(this);
                break;

            case 'boolean':
                render = this.renderBoolean.bind(this);
                break;

            default:
                return <pre>{JSON.stringify(this.props.type)}</pre>;
        }

        if (!this.props.list) {
            return render(0, this.props.answer.value, value => this.props.onChange({ value }));
        }

        return this.renderList(render);
    }

    renderList(render) {
        const values = this.props.answer.values || [undefined];
        const list = this.props.readOnly ? values : values;

        const data = list.map((value, index) => (
            <div
                key={`item-${get(value, 'key') || new String(index)}`}
                style={Object.assign({}, styles.listItem, index > 0 ? { marginTop: '15px' } : {})}
            >
                {this.props.readOnly ? null : value ? (
                    <Views.Common.Button
                        type="flat"
                        color="hot"
                        icon={<Icon type="do.delete" />}
                        style={styles.listRemove}
                        onClick={event => {
                            this.props.onChange({ values: [].concat(values.slice(0, index)).concat(values.slice(index+1)) }, true);
                        }}
                    />
                ) : (
                    <span style={styles.listNew}>
                        <Icon type="do.create" color={colors.grey500} />
                    </span>
                )}
                {render(index, value, value => {
                    values[index] = value;
                    this.props.onChange({ values });
                })}
            </div>
        ));

        return (
            <div>
            {data}
            <div>
            {this.props.readOnly ? null :
            <Button
                type="flat"
                icon={<Icon type="do.create" />}
                color="cold"
                onClick={e => {
                    this.props.onChange({
                        values: values.concat([undefined])
                    });
                }}
            />}
            </div>
            </div>
        )
    }

    renderString(index, value, onChange) {
        const style = Object.assign(
            {},
            styles.input,
            styles.stringInput,
            this.props.readOnly ? styles.readOnly : {},
            this.state.focus.index === index && this.state.focus.id === 'input' ? styles.inputFocus : {},
            this.props.style,
        );

        return this.props.readOnly ? <p style={{padding: '3px'}}>{value}</p> : (
            <input
                required="required"
                style={style}
                value={value || ''}
                onFocus={this.props.readOnly ? null : event => this.setState({ focus: { index, id: 'input' } })}
                onBlur={this.props.readOnly ? null : event => this.setState({ focus: { index, id: null } })}
                onChange={event => {
                    const value = event.target.value;

                    if (value !== this.props.answer.value || '') {
                        onChange(value);
                    }
                }}
            />
        );
    }

    renderNumber(index, value, onChange) {
        const style = Object.assign(
            {},
            styles.input,
            styles.numberInput,
            this.props.readOnly ? styles.readOnly : {},
            this.state.focus.index === index && this.state.focus.id === 'input' ? styles.inputFocus : {},
            this.props.style,
        );

        value = undefined === value ? '' : '' + (value || 0);
        let [int, dec] = value.split('.');
        int = (int || '').replace(/./g, (c, i, a) => (i && (a.length - i) % 3 === 0 ? ' ' + c : c));
        dec = (dec || '').replace(/./g, (c, i) => (i && i % 3 === 0 ? ' ' + c : c));
        value = (int.length ? int : dec.length ? '0' : '') + (dec.length ? '.' + dec : '');

        if (this.state.decimal && !dec.length) {
            value = value + '.';
        }

        const items = [
            <input
                required="required"
                ref="number"
                key="input"
                type="text"
                style={style}
                value={value}
                readOnly={this.props.readOnly}
                onFocus={this.props.readOnly ? null : event => this.setState({ focus: { index, id: 'input' } })}
                onBlur={this.props.readOnly ? null : event => this.setState({ focus: { index, id: null } })}
                onChange={event => {
                    const value = event.target.value;
                    this.state.decimal = this.props.options.decimal && [',', '.'].includes(value.substr(-1));
                    let float = parseFloat(value.replace(/[^\d.]+/g, ''));

                    if (typeof this.props.options.max === 'number') {
                        float = Math.min(float, this.props.options.max);
                    }

                    if (typeof this.props.options.min === 'number') {
                        float = Math.max(float, this.props.options.min);
                    }

                    onChange(float);
                }}
            />,
        ];
        if (this.props.options.unit) {
            items.push(
                <span key="unit" style={styles.numberUnit}>
                    {this.props.options.unit}
                </span>,
            );
        }

        return items;
    }

    renderDate(index, value, onChange) {
        const style = Object.assign(
            {},
            styles.input,
            styles.dateInput,
            this.props.readOnly ? styles.readOnly : {},
            this.props.readOnly ? { textAlign: 'left' } : {},
            this.state.focus.index === index && this.state.focus.id === 'input' ? styles.inputFocus : {},
            this.props.style,
            // this.state.error ? {border: '2px solid red'} : {},
        );

        const moment = Moment(value, 'DD / MM / YYYY');
        let isoDate;
        if (moment.isValid()) {
            isoDate = moment.format('YYYY-MM-DD');
        } else {
            isoDate = undefined;
        }

        return (
            <input
                required="required"
                style={style}
                type="date"
                min="1900-01-01"
                max="2500-01-01"
                defaultValue={isoDate || ''}
                readOnly={this.props.readOnly}
                placeholder={this.props.readOnly ? undefined : 'dd / mm / yyyy'}
                onFocus={this.props.readOnly ? null : event => this.setState({ focus: { index, id: 'input' } })}
                onBlur={this.props.readOnly ? null : event => this.setState({ focus: { index, id: null } })}
                onChange={event => {
                    const storedDate = event.target.value.split('-').reverse().join(' / ');
                    onChange(storedDate);
                }}
            />
        );
    }

    renderText(index, value, onChange) {
        const style = Object.assign(
            {},
            styles.textInput,
            this.props.readOnly ? styles.readOnly : {},
            this.state.focus.index === index && this.state.focus.id === 'input' ? styles.inputFocus : {},
            this.props.style,
        );

        return this.props.readOnly ? <p style={{padding: '3px'}}>{value}</p> : (
            <textarea
                required="required"
                style={style}
                rows={6}
                value={value}
                onFocus={this.props.readOnly ? null : event => this.setState({ focus: { index, id: 'input' } })}
                onBlur={this.props.readOnly ? null : event => this.setState({ focus: { index, id: null } })}
                onChange={event => onChange(event.target.value)}
            />
        );
    }

    renderCountry(index, value, onChange) {
        return <Country readOnly={this.props.readOnly} value={value} onChange={onChange} />;
    }

    renderCurrency(index, value, onChange) {
        return <Currency readOnly={this.props.readOnly} value={value} onChange={onChange} />;
    }

    renderFile(index, value, onChange) {
        return (
            <File
                readOnly={this.props.readOnly}
                value={value}
                onChange={onChange}
            />
        );
    }

    renderBoolean(index, value, onChange) {
        if (this.props.readOnly) {
            if (value === true) {
                return <div style={styles.booleanItem}>Yes</div>;
            }

            if (value === false) {
                return <div style={styles.booleanItem}>No</div>;
            }

            return null;
        }

        return (
            <div
                style={Object.assign(
                    {},
                    styles.booleanInput,
                    this.props.readOnly ? {} : styles.booleanEdit,
                    this.props.style,
                )}
            >
                <div
                    style={Object.assign(
                        {},
                        styles.booleanItem,
                        value === true
                            ? styles.booleanActive
                            : this.state.hover.index === index && this.state.hover.id === 'yes'
                            ? styles.booleanHover
                            : {},
                    )}
                    onMouseEnter={this.props.readOnly ? null : event => this.setState({ hover: { index, id: 'yes' } })}
                    onMouseLeave={this.props.readOnly ? null : event => this.setState({ hover: { index, id: null } })}
                    onClick={this.props.readOnly ? null : event => onChange(true)}
                >
                    Yes
                </div>
                <div
                    style={Object.assign(
                        {},
                        styles.booleanItem,
                        { borderLeft: '1px solid ' + colors.grey300 },
                        value === false
                            ? styles.booleanActive
                            : this.state.hover.index === index && this.state.hover.id === 'no'
                            ? styles.booleanHover
                            : {},
                    )}
                    onMouseEnter={this.props.readOnly ? null : event => this.setState({ hover: { index, id: 'no' } })}
                    onMouseLeave={this.props.readOnly ? null : event => this.setState({ hover: { index, id: null } })}
                    onClick={this.props.readOnly ? null : event => onChange(false)}
                >
                    No
                </div>
            </div>
        );
    }
}
