import React from 'react';
import PropTypes from 'prop-types';
import Form from 'teem/common/prospects/form';
import List from './list';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Scope from 'app/layout/providers/scope';
import t from 'app/translation';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.teemProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects.summary';

    static contextTypes = {
        createProspect: PropTypes.func.isRequired,
        openProspect: PropTypes.func.isRequired,
    };

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    async handleSubmit(data: Object) {
        const entity = this.context.createProspect(data);
    }

    render() {
        const entities = Array.from(toJS(this.props.store.prospects.values()));

        return (
            <div style={this.props.style}>
                {this.renderList(entities)}

                <Form ref="form" onSubmit={this.handleSubmit.bind(this)} />
            </div>
        );
    }

    renderList(entities) {
        if (this.props.store.loading.get()) {
            return <Views.Common.Loader title="Loading projects" />;
        }

        if (!entities.length) {
            return (
                <Views.Common.Empty
                    title={t('dna.teem.noProspect')}
                    actions={[
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            icon={<Icon type="do.create" />}
                            label="Add new project"
                            onClick={event => this.refs.form.open()}
                        />,
                    ]}
                />
            );
        }

        return (
            <Scope path="list" type="list" entities={entities}>
                <List entities={entities} onCreate={event => this.refs.form.open()} />
            </Scope>
        );
    }
}
