import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Avatar from 'material-ui/Avatar';
import Views from 'app/views';

const styles = {
    container: {
        position: 'relative',
        padding: '0 20px',
        width: '100px'
    },
    wrapper: {
        textAlign: 'center',
        paddingTop: '20px',
    },
    avatar: {
        background: colors.userCold,
        color: 'white'
    },
    name: {
        position: 'relative',
        display: 'block',
        textAlign: 'center',
        paddingTop: '5px',
        lineHeight: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: '13px',
        color: colors.grey500
    },
    wire: {
        position: 'absolute',
        top: '50%',
        marginTop: '-1px',
        width: '48px',
        height: '0',
        border: '1px solid ' + colors.grey400
    },
    wire_input: {
        left: '0'
    },
    wire_output: {
        right: '0'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.stocks.subject';

    static propTypes = {
        type: PropTypes.string,
        name: PropTypes.string,
    };

    render () {
        return (
            <div style={ styles.container }>
                { this.renderWire('input') }
                { this.renderWire('output') }
                { this.renderAvatar() }
            </div>
        );
    }

    renderAvatar () {
        return (
            <Views.Common.Tooltip content={ this.props.name }>
                <div style={ styles.wrapper }>
                    <Avatar
                        style={ styles.avatar }
                        icon={ <Icon type={ `dna.${this.props.type}` } /> }
                    />

                    <span style={ styles.name }>
                        { this.props.name }
                    </span>
                </div>
            </Views.Common.Tooltip>
        );
    }

    renderWire (type) {
        if (type === 'input' && this.props.type === 'person') {
            // no input for persons
            return null;
        }

        const style = Object.assign({},
            styles.wire,
            styles[ 'wire_' + type ]
        );

        return (
            <span style={ style } />
        );
    }
}
