// @flow

import type { AllowLevel } from '@deecision/infra-types/utils';

const map = {
    '0': 'invalid',
    '1': 'observer',
    '2': 'external',
    '3': 'member',
    '4': 'manager',
    '5': 'director',
};

export function encodeLevel(name: AllowLevel): number {
    for (const code of Object.keys(map)) {
        if (map[code] === name) {
            return parseInt(code);
        }
    }

    return 0;
}

export function decodeLevel(code: number): AllowLevel {
    return map[code] || 'invalid';
}

export function isManager(level: AllowLevel): boolean {
    return ['manager', 'director'].includes(level);
}
