import PropTypes from 'prop-types';
import React from 'react';

import { forEach } from 'lodash';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    rows: {
        borderBottom: '1px solid rgb(238, 238, 238)',
    },
    years: {
        display: 'inline-block',
        padding: '15px',
        fontSize: '18px',
        fontWeight: 'bold',
        width: '42px',
        color: 'rgb(176, 190, 197)',
        backgroundColor: 'rgba(121, 134, 203, 0.075)',
    },
    events: {
        display: 'inline-block',
        padding: '16px',
        fontSize: '17px',
        width: 'calc(100% - 105px)',
        color: 'rgb(117, 117, 117)',
    },
    info: {
        marginLeft: '72px',
        display: 'block',
        color: 'rgb(176, 190, 197)',
        borderTop: '1px solid rgb(238, 238, 238)',
    },
    date: {
        display: 'inline-block',
        padding: '15px',
        fontSize: '16px',
        fontWeight: 'bold',
        width: '42px',
        backgroundColor: 'rgba(121, 134, 203, 0.05)',
    },
    cell: {
        display: 'inline-block',
        padding: '15px',
        width: 'calc(100% - 103px - 184px)',
    },
    pdfCell: {
        display: 'inline-block',
        right: '0',
        padding: '13px',
    },
    link: {
        cursor: 'pointer',
        color: 'rgb(117, 117, 117)',
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: colors.bgCold,
    },
    pdfHover: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    on: {
        cursor: 'pointer',
        borderBottom: '2px solid rgb(229, 57, 53)',
        backgroundColor: colors.bgCold,
    },
    pdfText: {
        display: 'inline-block',
        marginLeft: '5px',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: 'rgb(117, 117, 117)',
    },
    information: {
        marginLeft: '72px',
        padding: '15px',
        paddingTop: '0px',
        color: 'rgb(158, 158, 158)',
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        lineHeight: '22px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.history.listing';

    static propTypes = {
        children: PropTypes.object.isRequired,
        i: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.state = {
            events: {
            },
            hover: undefined,
            pdfHover: undefined,
        };
    }

    displayEvents (children) {
        let result = [];

        forEach(children,(link) => {
            let infos =  link.desc;
            let desc = link.desc.replace(/\n/g, ' ');
            let descArray = desc
                .replace(/<span>|<\/span>|<dl>|<\/dl>|<dt>|<\/dt>|<dd>|<\/dd>/g, '\n')
                .replace(/\n\n/g, '\n').split('\n');

            const cellProps = {
                onMouseEnter: event => this.setState({ pdfHover: link.urlPdf }),
                onMouseLeave: event => this.setState({ pdfHover: undefined }),
            };

            let styleEvents = this.state.pdfHover === link.urlPdf
                ? Object.assign({ }, styles.pdfText, styles.pdfHover) : styles.pdfText;
            result.push(
                <div key={ link } style={ styles.info }>
                    <div style={ styles.date }>{ link.date.split('-')[2] + '/' + link.date.split('-')[1] }</div>
                    <div style={ styles.cell }>
                        <a style={ styles.link } target='_blank' href={ link.url }>
                            { descArray[1] + ' - ' + link.title }
                        </a>
                    </div>
                    <a style={ styles.pdfCell } target='_blank' href={ link.urlPdf } { ...cellProps }>
                        <div style={ styles.link } >
                            <Icon type={ 'do.download' } style={ styles.icon } />
                            <div style={ styleEvents }>{ 'Télécharger le pdf' }</div>
                        </div>
                    </a>
                    <div
                        style={styles.information}
                        dangerouslySetInnerHTML={ !infos.includes('<script>') ? {__html: infos.split('<\/span>')[1]
                                .replace(/\r?\n|\r|<dd>|<\/dd>|<\/dt>|<dl>|<\/dl>/g, ' ')
                                .replace(/<dt>/g, '\n').substring(2)} : {__html: ''} }
                    >
                    </div>
                </div>
            );
        })

        return result;
    }

    createRow (children, year) {
        let result = [];

        const cellProps = {
            onMouseEnter: event => this.setState({ hover: year }),
            onMouseLeave: event => this.setState({ hover: undefined }),
        };
        let styleEvents = this.state.hover === year
            ? Object.assign({ }, styles.events, styles.hover) : styles.events;
        styleEvents = this.state.events[year] ? Object.assign({ }, styles.events, styles.on) : styleEvents;
        let styleYears = this.state.events[year] ? Object.assign({ }, styles.years, styles.on) : styles.years;

        result.push(
            <tr key={ year } style={ styles.rows }>
                <td style={ styleYears }>{ `${ year } ` }</td>
                <td
                    style={ styleEvents }
                    onClick={
                        event => this.state.events[year] === true
                            ? this.setState({ events: { ...this.state.events, [year]: false } })
                            : this.setState({ events: { ...this.state.events, [year]: true } })
                    }
                    { ...cellProps }
                >
                    <div>
                        { `${ children.length } événements` }
                    </div>
                </td>
                { this.state.events[year] ? this.displayEvents(children) : undefined }
            </tr>
        );

        return result;
    }


    render() {
        return this.createRow(this.props.children.children, this.props.i);
    }
}