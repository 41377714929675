import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import { computeStartedDate } from 'teem/common/compute';

import { inject, observer } from 'mobx-react';
import { computeStatusLabel } from '../../common/compute';

const styles = {
    header: {
        marginBottom: '15px',
    },
    opener: {
        position: 'absolute',
        right: '10px',
        bottom: '10px',
    },
    status: {
        color: colors.userCold,
        lineHeight: '40px',
    },
};

@inject(stores => ({ store: stores.teemProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects.summary.card';

    static contextTypes = {
        openProspect: PropTypes.func.isRequired,
    };

    props: {
        entity: Object,
    };

    render() {
        if (!this.props.entity) return null;

        const entity = this.props.entity;
        const target = this.props.entity.payload.target || {};
        const period = this.props.entity.payload.settings.months || {};

        const events = this.props.entity.events || [];
        const milestones = this.props.entity.milestones || [];
        const meetings = this.props.entity.meetings || [];

        return (
            <Views.Common.Card>
                <Views.Common.Header
                    light
                    icon={<Icon type="comeet.prospect" />}
                    style={styles.header}
                    title={target.name || '< no name defined>'}
                    aside={[<span style={styles.status}>{computeStatusLabel(this.props.entity.status)}</span>]}
                />

                <Views.Timeline.Preview
                    start={computeStartedDate(entity.history)}
                    end={Moment(entity.createStamp).add(period, 'month')}
                >
                    {events.map(this.renderEvent.bind(this))}
                    {milestones.map(this.renderEvent.bind(this))}
                    {meetings.map(this.renderEvent.bind(this))}
                </Views.Timeline.Preview>

                <div style={{ marginTop: '10px' }}>
                    <Views.Common.Description value={this.props.entity.payload.description} />
                </div>

                <Views.Common.Button
                    type="round"
                    style={styles.opener}
                    icon={<Icon type="do.open" />}
                    onClick={event => this.context.openProspect(this.props.entity.id)}
                />
            </Views.Common.Card>
        );
    }

    renderEvent(entity) {
        const event = this.props.store.fields.get(entity);

        return event ? (
            <Views.Timeline.Event
                key={event.id}
                id={event.id}
                date={event.payload.date}
                dimmed={Moment(event.payload.date) < Moment().add(1, 'day')}
            />
        ) : null;
    }
}
