import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Selector from './selector';
import Search from './search';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { get, concat, set } from 'lodash';
import { filterFields } from '@deecision/deeligenz-utils/compute/filter';

const KIND_LABELS = {
    M: 'Manager',
    F: 'Fund',
    S: 'Service providers',
};

const styles = {
    container: {
        height: '120px',
    },
    filters: {
        display: 'flex',
    },
    filter: {
        marginLeft: '20px',
    },
    readOnly: {
        marginLeft: '20px',
        padding: '8px 15px 8px 10px',
        lineHeight: '24px',
        height: '24px',
        background: colors.userWarning,
        color: 'white',
        borderRadius: '3px',
    },
    lockIcon: {
        fill: 'white',
        marginRight: '10px',
        float: 'left',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies, management: stores.deeligenzManagement }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.studies.common.survey.filter';

    static propTypes = {
        readOnly: PropTypes.bool,
        data: PropTypes.object.isRequired,
        fields: PropTypes.arrayOf(PropTypes.string).isRequired,
        filteredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
        onScope: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        withStatus: PropTypes.bool,
        withAnalysis: PropTypes.bool,
        style: PropTypes.object,
        additional: PropTypes.bool,
    };

    handleChange(fields: Object[], value: Object) {
        const data = Object.assign({}, this.props.data, value);

        if (!data.kind && !data.category && this.props.management.managerCategoriesList.length) {
            data.kind = Object.keys(this.computeMenu(fields))[0] || 'M';
        } else if (!data.kind && !data.category) {
            data.kind =
                Object.keys(this.computeMenu(fields))[0] ||
                get(toJS(this.props.store.fields.get(this.props.fields[0])), 'questionPayload.kind');
        }

        const filter = field =>
            this.filterScope(field) &&
            this.filterFlag(field, data.flag) &&
            this.filterStatus(field, data.status) &&
            this.filterType(field, data.type) &&
            this.filterSelector(field, data.kind, data.category) &&
            this.filterSearch(field, data.search);

        this.props.onChange(
            data,
            fields.filter(field => filter(field)).map((field: Object) => field.id),
        );
    }

    componentDidMount() {
        this.handleChange(filterFields(this.props.fields.map(id => toJS(this.props.store.fields.get(id)))), {});
    }

    render() {
        if (this.props.additional) {
            return null;
        }

        const fields = filterFields(this.props.fields.map(id => toJS(this.props.store.fields.get(id))));

        if (this.props.refresh) {
            this.handleChange(fields, {});
        }

        const counts = {};

        this.props.filteredFields.map(i => {
            const kind = this.props.store.fields.get(i).questionPayload.kind;
            counts[kind] ? counts[kind]++ : (counts[kind] = 1);
        });

        const menu = this.computeMenu(fields);

        return (
            <div style={styles.container}>
                <div style={Object.assign({}, styles.filters, this.props.style)}>
                    {this.props.readOnly ? (
                        <div style={styles.readOnly}>
                            <Icon type="base.lock" style={styles.lockIcon} />
                            Read only
                        </div>
                    ) : null}

                    {this.renderFilters(fields)}

                    <Search
                        value={this.props.data.search}
                        onChange={value => this.handleChange(fields, { search: value })}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Selector
                        counts={counts}
                        menu={menu}
                        kind={this.props.data.kind || Object.keys(menu)[0]}
                        category={this.props.data.category || null}
                        onChange={(kind, category) => this.handleChange(fields, { kind, category })}
                    />
                </div>
            </div>
        );
    }

    renderFilters(fields: Object[]) {
        const filters = [];

        if (this.props.withStatus) {
            filters.push(
                <Views.Common.Toggle
                    key="type"
                    value={this.props.data.type}
                    style={styles.filter}
                    items={[
                        { value: 'input', label: 'Forms', icon: 'deeligenz.survey.input', help: 'Show Forms' },
                        { value: 'file', label: 'Docs', icon: 'deeligenz.survey.file', help: 'Show Docs' },
                    ]}
                    onChange={value => this.handleChange(fields, { type: value })}
                />,
            );
        }

        if (this.props.withStatus && !this.props.withAnalysis) {
            const statuses = this.computeStatuses(fields);

            filters.push(
                <Views.Common.Toggle
                    key="status"
                    value={this.props.data.status}
                    style={styles.filter}
                    items={[
                        {
                            value: 'todo',
                            label: statuses.todo || '0',
                            icon: 'deeligenz.survey.todo',
                            help: 'Show To Do',
                        },
                        {
                            value: 'draft',
                            label: statuses.draft || '0',
                            icon: 'deeligenz.survey.draft',
                            help: 'Show Draft',
                        },
                        {
                            value: 'pending',
                            label: statuses.pending || '0',
                            icon: 'deeligenz.survey.pending',
                            help: 'Show Pending',
                        },
                        {
                            value: 'validated',
                            label: statuses.validated || '0',
                            icon: 'deeligenz.survey.validated',
                            help: 'Show Validated',
                        },
                        {
                            value: 'rejected',
                            label: statuses.rejected || '0',
                            icon: 'deeligenz.survey.rejected',
                            help: 'Show Rejected',
                        },
                    ]}
                    onChange={value => this.handleChange(fields, { status: value })}
                />,
            );
        }

        if (this.props.withAnalysis) {
            const flags = this.computeFlags(fields);

            filters.push(
                <Views.Common.Toggle
                    key="flags"
                    value={this.props.data.flag}
                    style={styles.filter}
                    items={[
                        {
                            value: 'notRated',
                            label: flags.notRated || '0',
                            icon: 'deeligenz.survey.todo',
                            help: 'Show Not Rated',
                        },
                        {
                            value: 'allRight',
                            label: flags.allRight || '0',
                            icon: 'deeligenz.survey.allRight',
                            help: 'Show All Right',
                        },
                        {
                            value: 'concern',
                            label: flags.concern || '0',
                            icon: 'deeligenz.survey.concern',
                            help: 'Show Concern',
                        },
                        {
                            value: 'redFlag',
                            label: flags.redFlag || '0',
                            icon: 'deeligenz.survey.flag',
                            help: 'Show Red Flag',
                        },
                        {
                            value: 'onSite',
                            label: flags.onSite || '0',
                            icon: 'deeligenz.survey.onSite',
                            help: 'Show Meeting',
                        },
                        {
                            value: 'nextReview',
                            label: flags.nextReview || '0',
                            icon: 'deeligenz.survey.nextReview',
                            help: 'Next Review',
                        },
                        {
                            value: 'keyFinding',
                            label: flags.keyFinding || '0',
                            icon: 'deeligenz.survey.keyFinding',
                            help: 'Key Finding',
                        },
                    ]}
                    onChange={value => this.handleChange(fields, { flag: value })}
                />,
            );
        }

        return filters;
    }

    filterScope(field) {
        const flags = (field.answerPayload || {}).flags || {};
        const scope = field.questionPayload ? field.questionPayload.scope || null : null;

        return this.props.onScope(scope, flags);
    }

    filterType(field, type) {
        if (!this.props.withStatus || !type) {
            return true;
        }

        const question = field.questionPayload || {};
        const isFile = ['file', 'file[]'].includes(question.type);

        return type === 'file' ? isFile : !isFile;
    }

    filterStatus(field, status) {
        if (this.props.additional || !this.props.withStatus || this.props.withAnalysis) {
            return true;
        }

        if (status === 'draft' && field.answerPayload.draft) {
            return true;
        }

        return status ? field.status === status : true;
    }

    filterSelector(field, kind, category) {
        if (this.props.additional) {
            return true;
        }

        return (
            field.questionPayload.kind === kind && (category ? field.questionPayload.category.label === category : true)
        );
    }

    filterFlag(field, flag) {
        if (!this.props.withAnalysis || !flag) {
            return true;
        }

        const flags = (field.answerPayload || {}).flags || {};

        if (flag === 'notRated') {
            return !flags.allRight && !flags.concern && !flags.redFlag;
        }

        return !!flags[flag];
    }

    filterSearch(field, value) {
        if (!value) {
            return true;
        }

        const label = field.questionPayload.label.toLocaleLowerCase();

        for (const bit of value.split(' ')) {
            if (label.indexOf(bit.toLowerCase()) < 0) {
                return false;
            }
        }

        return true;
    }

    computeMenu(fields: Object[]) {
        const data = {};

        for (const field of fields) {
            if (!this.filterScope(field)) {
                continue;
            }

            const question = field.questionPayload;

            if (!data[question.kind]) {
                data[question.kind] = {
                    type: 'kind',
                    label: KIND_LABELS[question.kind] || question.kind,
                    count: 0,
                    done: 0,
                    categories: {},
                };
            }

            if (!data[question.kind].categories[question.category.label]) {
                data[question.kind].categories[question.category.label] = {
                    type: 'category',
                    kind: question.kind,
                    label: question.category.label,
                    count: 0,
                    done: 0,
                };
            }

            data[question.kind].categories[question.category.label].count += 1;
            data[question.kind].count += 1;

            if (field.status === 'validated') {
                data[question.kind].done += 1;
                data[question.kind].categories[question.category.label].done += 1;
            }
        }

        return data;
    }

    computeStatuses(fields: Object[]) {
        const counts = { draft: 0 };

        for (const field of fields) {
            if (
                !field ||
                !this.filterScope(field) ||
                // !this.filterEmbedded(field) ||
                !this.filterType(field, this.props.data.type) ||
                !this.filterSelector(field, this.props.data.kind, this.props.data.category)
            ) {
                continue;
            }

            if (!counts[field.status]) {
                counts[field.status] = 0;
            }

            if (field.answerPayload.draft) {
                counts.draft++;
            }

            counts[field.status] += 1;
        }

        return counts;
    }

    computeFlags(fields: Object[]) {
        const counts = { notRated: 0 };

        for (const field of fields) {
            if (
                !field ||
                !this.filterScope(field) ||
                !this.filterSelector(field, this.props.data.kind, this.props.data.category)
            ) {
                continue;
            }

            const flags = Object.assign(
                {},
                field.answerPayload ? field.answerPayload.flags : {},
                field.answerEditPayload ? field.answerEditPayload.flags : {},
            );

            for (const key of Object.keys(flags)) {
                if (!flags[key]) {
                    continue;
                }

                if (!counts[key]) {
                    counts[key] = 0;
                }

                counts[key]++;
            }

            if (!flags.allRight && !flags.concern && !flags.redFlag) {
                counts.notRated++;
            }
        }

        return counts;
    }
}
