import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import t from 'app/translation';
import { inject, observer } from 'mobx-react';

@inject('deefind')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.display.export';

    static contextTypes = {
        exportEntities: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        style: PropTypes.object,
    };

    state = { loading: false };

    handleClick(event) {
        this.setState({ loading: true }, async () => {
            await this.context.exportEntities([this.props.entity.id]);
            this.setState({ loading: false });
        });
    }

    render() {
        const entity = this.props.entity;
        const network = this.props.deefind.results.get(`network:${entity.id}`);

        if (
            !network ||
            (entity.type === 'company' && network.depth < 1) ||
            (entity.type === 'person' && network.depth < 2)
        ) {
            return this.renderButton('Building', false);
        }

        if (this.state.loading) {
            return this.renderButton('Exporting', false);
        }

        return this.renderButton('Export', true);
    }

    renderButton(label, enabled) {
        return (
            <Views.Common.Button
                id="export"
                type="raised"
                color="cold"
                icon={<Icon type="dna.export" />}
                label={label}
                onClick={this.handleClick.bind(this)}
                disabled={!enabled}
                style={this.props.style}
            />
        );
    }
}
