import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'absolute',
        right: '0',
        bottom: '7px',
    },
    label: {
        width: '54px',
        height: '54px',
        lineHeight: '54px',
        textAlign: 'center',
        color: colors.userCold,
        fontSize: '17px',
        borderRadius: '50%',
        background: colors.grey200,
    },
    progress: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '54px',
        height: '54px',
    },
    waitWrapper: {
        borderRadius: '50%',
        background: colors.grey200,
    },
    waitIcon: {
        width: '24px',
        height: '24px',
        margin: '15px',
        fill: colors.grey500,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.card.opener';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        id: PropTypes.string,
        progress: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    };

    render() {
        if (this.props.progress) {
            return (
                <Views.Common.Loader
                    style={{
                        position: 'absolute',
                        right: '0',
                        bottom: '7px',
                    }}
                    progress={ this.props.progress }
                    tiny={true}
                />
            );
        }
        return (
            <Views.Common.Button
                type="round"
                style={styles.container}
                onClick={event => this.context.openEntity(this.props.id)}
                color="cold"
                icon={<Icon type="do.open" />}
            />
        );
    }
}
