import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { get } from 'lodash';

import Views from 'app/views';
import t from 'app/translation';

export default class extends React.Component<any, any> {
    static displayName = 'teem.common.meetings.form';

    static propTypes = {
        entity: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    state = { flags: [] };

    open(extra) {
        const flags = get(this.props.entity, 'payload.location.flags', []);
        this.setState({ flags }, () => this.refs.dialog.open(extra));
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                model="comeet:meetings"
                data={this.props.entity ? toJS(this.props.entity) : { payload: {} }}
                label={t('dna.comeet.saveMeeting')}
                onSubmit={this.props.onSubmit}
                onChange={data => this.setState({ flags: get(data, 'payload.location.flags', []) })}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        size={2}
                        label={t('dna.comeet.title')}
                        path="payload.title"
                        widget="string"
                        required
                    />

                    <Views.Form.Field label={t('dna.comeet.date')} path="payload.date" widget="date" required />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        size={2}
                        label="Meeting type"
                        path="payload.location.flags"
                        widget={{
                            type: 'bools',
                            choices: { physical: 'Physical', online: 'Online call', phone: 'Phone call' },
                        }}
                        required
                    />

                    <Views.Form.Field label={t('dna.comeet.time')} path="payload.time" widget="time" required />
                </Views.Form.Row>

                {this.renderLocation()}

                <Views.Form.Row>
                    <Views.Form.Field
                        label={t('dna.comeet.program')}
                        path="payload.steps"
                        widget={{
                            type: 'collection',
                            sort: true,
                            flags: ['uuid'],
                            fields: { title: 'string' },
                        }}
                        required
                    />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field label={t('dna.comeet.description')} path="payload.description" widget="text" />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }

    renderLocation() {
        const fields = [];

        if (this.state.flags.includes('physical')) {
            fields.push(
                <Views.Form.Field
                    key="physical"
                    size={1}
                    label="Physical location"
                    path="payload.location.address"
                    widget="string"
                />
            );
        }

        if (this.state.flags.includes('online')) {
            fields.push(
                <Views.Form.Field
                    key="online"
                    size={1}
                    label="Online URL"
                    path="payload.location.url"
                    widget="string"
                />
            );
        }

        if (this.state.flags.includes('phone')) {
            fields.push(
                <Views.Form.Field
                    key="phone"
                    size={1}
                    label="Phone number"
                    path="payload.location.phone"
                    widget="string"
                />
            );
        }

        if (!fields.length) {
            return null;
        }

        return (
            <Views.Form.Row>
                {fields}
            </Views.Form.Row>
        );
    }
}
