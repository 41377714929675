import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';

const styles = {
    button: {
        width: '250px',
        textAlign: 'left',
    },
    flag: {
        width: '24px',
        height: '18px',
        margin: '0 10px -5px 0',
    },
    empty: {
        color: colors.userCold,
        textAlign: 'center',
    },
    body: {
        display: 'flex',
        height: '400px',
    },
    top: {
        width: '50%',
        paddingRight: '10px',
        overflow: 'auto',
    },
    select: {
        width: '50%',
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    filter: {
        position: 'relative',
    },
    filterInput: {
        background: colors.grey200,
        border: '1px solid ' + colors.grey300,
        lineHeight: '24px',
        borderRadius: '3px',
        boxSizing: 'border',
        height: '36px',
        padding: '6px 35px',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        marginBottom: '10px',
        width: '100%',
    },
    filterIcon: {
        position: 'absolute',
        top: '6px',
        left: '6px',
        fill: colors.grey500,
    },
    filterClear: {
        position: 'absolute',
        top: '3px',
        right: '3px',
        background: colors.userHot,
        borderRadius: '3px',
        cursor: 'pointer',
        padding: '3px',
    },
    list: {
        height: '100%',
        overflow: 'auto',
    },
    option: {
        borderRadius: '3px',
        background: colors.white,
        color: colors.grey500,
        padding: '5px',
        cursor: 'pointer',
        fontSize: '13px',
    },
    optionTop: {
        padding: '10px',
        fontSize: '15px',
    },
    optionHover: {
        background: colors.grey300,
    },
    optionCurrent: {
        background: colors.userCold,
        color: colors.white,
    },
    optionCode: {
        color: colors.black,
        display: 'inline-block',
        margin: '0 10px 0 0',
    },
    optionName: {
        color: colors.grey500,
        textTransform: 'none',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.scalar.currency';

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    state = { mainHover: false, optionHover: null };

    render() {
        return [
            <div style={{position: 'relative'}}><Views.Common.Button
                key="button"
                type="flat"
                color="cold"
                label={this.renderLabel(this.props.value)}
                style={styles.button}
                disabled={this.props.readOnly}
                onClick={event => this.refs.modal.open()}
            /><input style={{position: 'absolute', display: 'none', zIndex: '-1', top: 0, left: 0}} required value={this.props.value} /></div>,
            <Views.Modal.Show key="modal" title="Pick a country" ref="modal" style={{ width: '600px' }}>
                <div style={styles.body}>
                    <div style={styles.top}>{TOP_CURRENCIES.map(code => this.renderOption(code, true))}</div>

                    <div style={styles.select}>
                        <div style={styles.filter}>
                            <input
                                style={styles.filterInput}
                                value={this.state.filter || ''}
                                onChange={event => this.setState({ filter: event.target.value.toLowerCase() })}
                            />

                            <Icon style={styles.filterIcon} type="do.search" />

                            {this.state.filter ? (
                                <div style={styles.filterClear} onClick={event => this.setState({ filter: null })}>
                                    <Icon type="do.cancel" color="white" />
                                </div>
                            ) : null}
                        </div>

                        <div style={styles.list}>
                            {this.filterCurrencies().map(code => this.renderOption(code, false))}
                        </div>
                    </div>
                </div>
            </Views.Modal.Show>,
        ];
    }

    renderLabel() {
        const name = CURRENCIES[this.props.value];

        if (!name) {
            return this.props.readOnly ? '' : 'Pick a currency';
        }

        return [
            <span key="code" style={styles.optionCode}>
                {this.props.value}
            </span>,
            <span key="name" style={styles.optionName}>
                ({name})
            </span>,
        ];
    }

    renderOption(code, top) {
        const style = Object.assign(
            {},
            styles.option,
            top ? styles.optionTop : {},
            this.state.optionHover === code ? styles.optionHover : {},
            this.props.value === code ? styles.optionCurrent : {},
        );

        return (
            <div
                key={code || '?'}
                style={style}
                onMouseEnter={event => this.setState({ optionHover: code })}
                onMouseLeave={event => this.setState({ optionHover: null })}
                onClick={event => {
                    this.props.onChange(code);
                    this.refs.modal.close();
                }}
            >
                <span
                    key="code"
                    style={Object.assign({}, styles.optionCode, this.props.value === code ? styles.optionCurrent : {})}
                >
                    {code}
                </span>

                <span
                    key="name"
                    style={Object.assign({}, styles.optionName, this.props.value === code ? styles.optionCurrent : {})}
                >
                    {CURRENCIES[code] || '?'}
                </span>
            </div>
        );
    }

    filterCurrencies() {
        let currencies = Object.keys(CURRENCIES);

        if (this.state.filter) {
            currencies = currencies.filter(
                code =>
                    (CURRENCIES[code] && CURRENCIES[code].toLowerCase().indexOf(this.state.filter) > -1) ||
                    code.toLowerCase().indexOf(this.state.filter) > -1,
            );
        }

        return currencies;
    }
}

const TOP_CURRENCIES = ['EUR', 'USD', 'JPY', 'GBP'];

const CURRENCIES = {
    AED: 'United Arab Emirates Dirham',
    AFN: 'Afghanistan Afghani',
    ALL: 'Albania Lek',
    AMD: 'Armenia Dram',
    ANG: 'Netherlands Antilles Guilder',
    AOA: 'Angola Kwanza',
    ARS: 'Argentina Peso',
    AUD: 'Australia Dollar',
    AWG: 'Aruba Guilder',
    AZN: 'Azerbaijan Manat',
    BAM: 'Bosnia and Herzegovina Convertible Marka',
    BBD: 'Barbados Dollar',
    BDT: 'Bangladesh Taka',
    BGN: 'Bulgaria Lev',
    BHD: 'Bahrain Dinar',
    BIF: 'Burundi Franc',
    BMD: 'Bermuda Dollar',
    BND: 'Brunei Darussalam Dollar',
    BOB: 'Bolivia Bolíviano',
    BRL: 'Brazil Real',
    BSD: 'Bahamas Dollar',
    BTN: 'Bhutan Ngultrum',
    BWP: 'Botswana Pula',
    BYN: 'Belarus Ruble',
    BZD: 'Belize Dollar',
    CAD: 'Canada Dollar',
    CDF: 'Congo/Kinshasa Franc',
    CHF: 'Switzerland Franc',
    CLP: 'Chile Peso',
    CNY: 'China Yuan Renminbi',
    COP: 'Colombia Peso',
    CRC: 'Costa Rica Colon',
    CUC: 'Cuba Convertible Peso',
    CUP: 'Cuba Peso',
    CVE: 'Cape Verde Escudo',
    CZK: 'Czech Republic Koruna',
    DJF: 'Djibouti Franc',
    DKK: 'Denmark Krone',
    DOP: 'Dominican Republic Peso',
    DZD: 'Algeria Dinar',
    EGP: 'Egypt Pound',
    ERN: 'Eritrea Nakfa',
    ETB: 'Ethiopia Birr',
    EUR: 'Euro Member Countries',
    FJD: 'Fiji Dollar',
    FKP: 'Falkland Islands (Malvinas) Pound',
    GBP: 'United Kingdom Pound',
    GEL: 'Georgia Lari',
    GGP: 'Guernsey Pound',
    GHS: 'Ghana Cedi',
    GIP: 'Gibraltar Pound',
    GMD: 'Gambia Dalasi',
    GNF: 'Guinea Franc',
    GTQ: 'Guatemala Quetzal',
    GYD: 'Guyana Dollar',
    HKD: 'Hong Kong Dollar',
    HNL: 'Honduras Lempira',
    HRK: 'Croatia Kuna',
    HTG: 'Haiti Gourde',
    HUF: 'Hungary Forint',
    IDR: 'Indonesia Rupiah',
    ILS: 'Israel Shekel',
    IMP: 'Isle of Man Pound',
    INR: 'India Rupee',
    IQD: 'Iraq Dinar',
    IRR: 'Iran Rial',
    ISK: 'Iceland Krona',
    JEP: 'Jersey Pound',
    JMD: 'Jamaica Dollar',
    JOD: 'Jordan Dinar',
    JPY: 'Japan Yen',
    KES: 'Kenya Shilling',
    KGS: 'Kyrgyzstan Som',
    KHR: 'Cambodia Riel',
    KMF: 'Comorian Franc',
    KPW: 'Korea (North) Won',
    KRW: 'Korea (South) Won',
    KWD: 'Kuwait Dinar',
    KYD: 'Cayman Islands Dollar',
    KZT: 'Kazakhstan Tenge',
    LAK: 'Laos Kip',
    LBP: 'Lebanon Pound',
    LKR: 'Sri Lanka Rupee',
    LRD: 'Liberia Dollar',
    LSL: 'Lesotho Loti',
    LYD: 'Libya Dinar',
    MAD: 'Morocco Dirham',
    MDL: 'Moldova Leu',
    MGA: 'Madagascar Ariary',
    MKD: 'Macedonia Denar',
    MMK: 'Myanmar (Burma) Kyat',
    MNT: 'Mongolia Tughrik',
    MOP: 'Macau Pataca',
    MRU: 'Mauritania Ouguiya',
    MUR: 'Mauritius Rupee',
    MVR: 'Maldives (Maldive Islands) Rufiyaa',
    MWK: 'Malawi Kwacha',
    MXN: 'Mexico Peso',
    MYR: 'Malaysia Ringgit',
    MZN: 'Mozambique Metical',
    NAD: 'Namibia Dollar',
    NGN: 'Nigeria Naira',
    NIO: 'Nicaragua Cordoba',
    NOK: 'Norway Krone',
    NPR: 'Nepal Rupee',
    NZD: 'New Zealand Dollar',
    OMR: 'Oman Rial',
    PAB: 'Panama Balboa',
    PEN: 'Peru Sol',
    PGK: 'Papua New Guinea Kina',
    PHP: 'Philippines Piso',
    PKR: 'Pakistan Rupee',
    PLN: 'Poland Zloty',
    PYG: 'Paraguay Guarani',
    QAR: 'Qatar Riyal',
    RON: 'Romania Leu',
    RSD: 'Serbia Dinar',
    RUB: 'Russia Ruble',
    RWF: 'Rwanda Franc',
    SAR: 'Saudi Arabia Riyal',
    SBD: 'Solomon Islands Dollar',
    SCR: 'Seychelles Rupee',
    SDG: 'Sudan Pound',
    SEK: 'Sweden Krona',
    SGD: 'Singapore Dollar',
    SHP: 'Saint Helena Pound',
    SLL: 'Sierra Leone Leone',
    SOS: 'Somalia Shilling',
    SPL: 'Seborga Luigino',
    SRD: 'Suriname Dollar',
    STN: 'São Tomé and Príncipe Dobra',
    SVC: 'El Salvador Colon',
    SYP: 'Syria Pound',
    SZL: 'Swaziland Lilangeni',
    THB: 'Thailand Baht',
    TJS: 'Tajikistan Somoni',
    TMT: 'Turkmenistan Manat',
    TND: 'Tunisia Dinar',
    TOP: "Tonga Pa'anga",
    TRY: 'Turkey Lira',
    TTD: 'Trinidad and Tobago Dollar',
    TVD: 'Tuvalu Dollar',
    TWD: 'Taiwan New Dollar',
    TZS: 'Tanzania Shilling',
    UAH: 'Ukraine Hryvnia',
    UGX: 'Uganda Shilling',
    USD: 'United States Dollar',
    UYU: 'Uruguay Peso',
    UZS: 'Uzbekistan Som',
    VEF: 'Venezuela Bolívar',
    VND: 'Viet Nam Dong',
    VUV: 'Vanuatu Vatu',
    WST: 'Samoa Tala',
    XAF: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
    XCD: 'East Caribbean Dollar',
    XDR: 'International Monetary Fund (IMF) Special Drawing Rights',
    XOF: 'Communauté Financière Africaine (BCEAO) Franc',
    XPF: 'Comptoirs Français du Pacifique (CFP) Franc',
    YER: 'Yemen Rial',
    ZAR: 'South Africa Rand',
    ZMW: 'Zambia Kwacha',
    ZWD: 'Zimbabwe Dollar',
};
