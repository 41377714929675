// @flow

import type { SdkService, GlobalEvent } from '@deecision/infra-types/client';
import type { AuthUserSession } from '@deecision/infra-types/common';

import StateStore from './state';
import AuthStore from './auth';
import CoreStores from './core';
import DeefindStore from './deefind';
import ReechStore from './reech';
import DnaExplorerStore from './dnaExplorer';
import DnaDetectorStore from './dnaDetector';
import DnaSettingsStore from './dnaSettings';
import ComeetProspectsStore from './comeetProspects';
import ComeetManagementStore from './comeetManagement';
import TeemProspectsStore from './teem/index';
import DeeligenzStudiesStore from './deeligenzStudies';
import DeeligenzManagementStore from './deeligenzManagement';
import DeeligenzCalendarStore from './deeligenzCalendar';
import DeeligenzLibraryStore from './deeligenzLibrary';

export default function(sdk: SdkService, history: Object) {
    const { global, model, queue, drive } = CoreStores(sdk, history);
    const state = new StateStore();
    const auth = new AuthStore();

    const initializers = [
        (session, cursor) => auth.initialize(session, cursor),
        (session, cursor) => state.initialize(session, cursor),
    ];

    sdk.global.listen(async (event: GlobalEvent) => {
        if (!event.changes.includes('session') && !event.changes.includes('cursor')) {
            return;
        }

        if (event.session.level !== 'anonymous') {
            const session: AuthUserSession = event.session;
            await Promise.all(initializers.map(init => init(session, event.cursor)));
        }
    });

    const reech = new ReechStore(global);
    const deefind = new DeefindStore(global, reech);
    const dnaExplorer = new DnaExplorerStore(state, deefind);
    const dnaDetector = new DnaDetectorStore(queue, state, deefind, reech);
    const dnaSettings = new DnaSettingsStore(global, state);
    const deeligenzStudies = new DeeligenzStudiesStore(global, state, auth, drive);
    const deeligenzManagement = new DeeligenzManagementStore(global, state);
    const deeligenzCalendar = new DeeligenzCalendarStore(state);
    const deeligenzLibrary = new DeeligenzLibraryStore(state);
    const comeetProspects = new ComeetProspectsStore(global, state);
    const comeetManagement = new ComeetManagementStore(global, state);
    const teemProspects = new TeemProspectsStore(global, state, auth, drive);

    initializers.push((session, cursor) => dnaSettings.initialize(session, cursor));
    initializers.push((session, cursor) => comeetProspects.initialize(session, cursor));
    initializers.push((session, cursor) => deeligenzStudies.initialize(session, cursor));
    initializers.push((session, cursor) => deeligenzManagement.initialize(session, cursor));
    initializers.push((session, cursor) => teemProspects.initialize(session, cursor));

    return {
        state,
        global,
        model,
        queue,
        auth,
        drive,
        reech,
        deefind,
        dnaExplorer,
        dnaDetector,
        dnaSettings,
        deeligenzStudies,
        deeligenzManagement,
        deeligenzCalendar,
        deeligenzLibrary,
        comeetProspects,
        comeetManagement,
        teemProspects,
    };
}
