import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Deal from './modal/deal';

const styles = {
    separator: {
        marginTop: '20px',
        paddingTop: '20px',
        borderTop: '1px solid ' + colors.grey300
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.modal';

    state = {
        deals: [],
        title: ''
    };

    open (deals, title) {
        this.setState({ deals, title });
        this.refs.modal.open();
    }

    render () {
        return (
            <Views.Modal.Show
                ref="modal"
                title={ this.state.title }
            >
                { this.state.deals.map((deal, index) => (
                    <Deal
                        key={ index }
                        deal={ deal }
                        style={ index > 0 ? styles.separator : undefined }
                    />
                )) }
            </Views.Modal.Show>
        );
    }
}
