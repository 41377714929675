import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Graph from './graph';

const styles = {
    container: {
        width: '260px',
        textAlign: 'center'
    },
    label: {
        color: colors.grey700,
        display: 'inline-block',
        fontSize: '15px',
    },
    value: {
        color: colors.userCold,
        display: 'inline-block',
        fontSize: '19px',
        marginLeft: '10px'
    },
    empty: {
        marginTop: '15px',
        lineHeight: '100px',
        color: colors.grey500,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '13px',
        background: colors.grey100
    },
    graph: {
        marginTop: '15px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.display.tabs.financial.value';

    static propTypes = {
        label: PropTypes.string,
        value: PropTypes.oneOfType([ PropTypes.number, PropTypes.object ]),
        historyYears: PropTypes.array,
        historyValues: PropTypes.array,
    };

    render () {
        return (
            <div style={ styles.container }>
                <div style={ styles.label }>
                    { this.props.label }
                </div>

                { this.renderNumber() }
                { this.renderContent() }
            </div>
        );
    }

    renderContent () {
        if (! this.props.historyYears
            || this.props.historyYears.length < 2
            || ! this.props.historyValues
            || this.props.historyValues.filter(v => !! v).length  < 2
        ) {
            return (
                <div style={ styles.empty }>
                    No history
                </div>
            );
        }

        const years = [];
        const values = [];

        for (const index in this.props.historyValues) {
            if (this.props.historyValues[index]) {
                years.push(this.props.historyYears[index]);
                values.push(this.props.historyValues[index]);
            }
        }

        return (
            <Graph
                style={ styles.graph }
                years={ years }
                values={ values }
            />
        );
    }

    renderNumber () {
        if (! this.props.value) {
            return (
                <span style={ styles.value }>
                    —
                </span>
            );
        }

        return (
            <Views.Common.Number
                style={ styles.value }
                value={ this.props.value }
                type="currency"
                scale="M"
            />
        )
    }
}
