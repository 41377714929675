import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        display: 'flex',
        marginBottom: '20px',
    },
    action: {
        marginRight: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.aside.actions';

    static propTypes = {
        status: PropTypes.oneOf(['todo', 'pending', 'validated', 'rejected']).isRequired,
        onApply: PropTypes.func.isRequired,
        readOnly: PropTypes.bool
    };

    state = {};

    render() {
        return (
            <div style={styles.container}>
                {this.renderValidate()}
                {this.renderReject()}
            </div>
        );
    }

    handleValidate() {
        if (this.props.readOnly || this.state.clicked) return
        this.setState({clicked: true}, () => {
            this.props.onApply('validate').then(() => this.setState({clicked: false}));
        })
    }

    handleReject() {
        if (this.props.readOnly || this.state.clicked) return
        this.setState({clicked: true}, () => {
            this.props.onApply('reject').then(() => this.setState({clicked: false}));
        })
    }

    renderValidate() {
        return (
            <Views.Common.Button
                type={['validated', 'rejected'].includes(this.props.status) ? 'flat' : 'raised'}
                color="green"
                icon={<Icon type="base.yes" />}
                style={styles.action}
                onClick={this.handleValidate.bind(this)}
                label="Validate"
                disabled={['todo', 'validated'].includes(this.props.status)}
            />
        );
    }

    renderReject() {
        return (
            <Views.Common.Button
                type={['validated', 'rejected'].includes(this.props.status) ? 'flat' : 'raised'}
                color="hot"
                icon={<Icon type="base.no" />}
                style={styles.action}
                onClick={this.handleReject.bind(this)}
                label="Reject"
                disabled={['todo', 'rejected'].includes(this.props.status)}
            />
        );
    }
}
