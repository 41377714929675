import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'inline-block',
        verticalAlign: 'top',
        marginLeft: '5px'
    },
    choice: {
        display: 'inline-block',
        color: colors.grey500,
        fill: colors.grey500,
        background: 'white',
        lineHeight: '25px',
        paddingLeft: '5px',
        paddingRight: '5px',
        fontSize: '13px',
        cursor: 'pointer',
        verticalAlign: 'top'
    },
    hover: {
        color: colors.grey700,
        fill: colors.grey700,
        background: colors.grey100
    },
    current: {
        color: colors.white,
        fill: colors.white,
        background: colors.userCold
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.tab.filter';

    static propTypes = {
        value: PropTypes.node,
        choices: PropTypes.object.isRequired,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired
    };

    state = { hover: null };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                { _.map(this.props.choices, this.renderChoice.bind(this)) }
            </div>
        );
    }

    renderChoice (label, value) {
        const style = Object.assign({},
            styles.choice,
            this.props.value === value
                ? styles.current
                : (this.state.hover === value ? styles.hover : {})
        );

        const events = {
            onMouseEnter: event => this.setState({ hover: value }),
            onMouseLeave: event => this.setState({ hover: null }),
            onClick: event => this.props.onChange(this.props.value === value ? undefined : value)
        };

        if (_.isString(label))
            return (
                <span
                    key={ value }
                    style={ style }
                    { ...events }
                >
                    { label }
                </span>
            );

        return React.cloneElement(label, Object.assign({
            key: value,
            style: Object.assign({}, label.props.style, style)
        }, events));
    }
}
