import PropTypes from 'prop-types';
import React from 'react';

import { forEach } from 'lodash';
import FormGroup from '@material-ui/core/FormGroup';
import { TabManager } from './tabsManager';
import Listing from './listing';
import Timeline from './timeline';
import Download from './downloadManager';
import { renderFilters, filtersList, subfiltersList, parseObject, getFilters } from './utils';

const styles = {
    checkboxRow: {
        display: 'inline-block',
        backgroundColor: 'rgb(245, 245, 245)',
        width: '1376px',
        paddingLeft: '12px',
        paddingRight: '12px',
        marginBottom: '25px',
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '3px',
    },
    tab: {
        width: '100%',
        borderCollapse: 'collapse',
        borderSpacing: '0',
        borderTop: '1px solid rgb(238, 238, 238)',
        overflow: 'hidden',
    },
    rows: {
        borderBottom: '1px solid rgb(238, 238, 238)',
    },
    td: {
        display: 'inline-block',
        padding: '0px',
    }
};

const tabs = [
    { label: "Liste", value: 1 },
    { label: "Timeline", value: 2 }
];

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.history.tab';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        list: PropTypes.object.isRequired,
        type: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            displayed: false,
            filterDisp: undefined,
            filters: _.cloneDeep(filtersList),
            subfilters: _.cloneDeep(subfiltersList),
        };
        this.baseState = _.cloneDeep(this.state);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.entity.id !== this.props.entity.id) {
            this.setState(_.cloneDeep(this.baseState));
        }
    }

    handleChange = (event) => {
        let state = { ...this.state.filters };
        state[event.target.name][0] = event.target.checked;
        this.setState({ filters : state });
    };

    handleSubChange = (event) => {
        let state = { ...this.state.subfilters };
        state[event.target.name][0] = event.target.checked;
        this.setState({ subfilters : state });
    };

    handleMouseHover = (filter) => {
        this.setState({ filterDisp: filter })
    }

    createTable () {
        if (!this.state.displayed) {
            getFilters(this.state.filters, this.state.subfilters, this.props.list.bodacc);
            this.setState({ displayed: true });
        }

        let table = [];

        table.push(
            <tr key={ 'tabs' }>
                <TabManager tabs={tabs} activeTab={this.state.activeTab} handleTab={(value) => this.setState({ activeTab: value })} />
                <td style={ styles.td }>
                    <FormGroup row style={ styles.checkboxRow }>
                        { renderFilters(this.handleChange, this.handleSubChange, this.handleMouseHover, this.state, false) }
                    </FormGroup>
                </td>
            </tr>
        );

        let myEntities = parseObject(this.props.list.bodacc, this.state.filters, this.state.subfilters);

        if (this.state.activeTab === 1) {
            table.push(
                <tr key={ 'download' } style={styles.rows}>
                    <Download
                        entity={ this.props.entity }
                        list={ this.props.list }
                    />
                </tr>
            );
            for (let i = new Date().getFullYear(); i > 2000; i--) {
                let children = []
                for (let j = 12; j > 0; j--) {
                    forEach(myEntities, (entity) => {
                        let year = entity.date ? entity.date.split('-')[0] : 0;
                        let month = entity.date ? entity.date.split('-')[1] : 0;
                        (year + '-' + month) === (i + '-' + ('0' + j).slice(-2))
                            ? children.push(entity) : undefined;
                    });
                }
                children.length >= 1 ? table.push(<Listing key={ i } children={ { children } } i={i} />) : undefined;
            }
        } else if (this.state.activeTab === 2) {
            table.push(<Timeline events={myEntities} />);
        }
        return table;
    }

    render() {
        return this.createTable();
    }
}