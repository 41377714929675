import PropTypes from 'prop-types';
import React from 'react';

const styles = {
    input: {
        fontSize: '15px',
        lineHeight: 'auto'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.text';

    static propTypes = {
        value: PropTypes.string,
        style: PropTypes.object,
        rows: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        readOnly: PropTypes.bool
    };

    static defaultProps = {
        rows: 4
    };

    render () {
      if (this.props.readOnly) {
        return (
            <textarea
                readOnly
                rows={ this.props.rows }
                value={ this.props.value || '' }
                style={ Object.assign({}, this.props.style, styles.input) }
                onChange={ () => {} }
                onFocus={ this.props.onFocus }
                onBlur={ this.props.onBlur }
            />
        )
      }
        return (
            <textarea
                rows={ this.props.rows }
                value={ this.props.value || '' }
                style={ Object.assign({}, this.props.style, styles.input) }
                onChange={ event => this.props.onChange(event.target.value || undefined) }
                onFocus={ this.props.onFocus }
                onBlur={ this.props.onBlur }
            />
        );
    }
}
