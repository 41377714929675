import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Colors from 'app/theme/colors';
import InvalidIcon from 'material-ui/svg-icons/av/not-interested';
import TYPES from './types';
import UserIcon from 'material-ui/svg-icons/social/person';
import User from '../../../common/user';

const styles = {
    container: {
        position: 'relative',
        padding: '5px 0 10px 10px'
    },
    avatar: {
        position: 'absolute',
        top: '5px',
        left: '-56px'
    },
    type: {
        position: 'absolute',
        top: '1px',
        left: '-28px',
        width: '24px',
        height: '24px',
        padding: '2px',
        borderRadius: '50%',
        background: 'white'
    },
    altType: {
        background: Colors.grey100
    },
    typeIcon: {
        width: '24px',
        height: '24px'
    },
    owner: {
        color: Colors.userCold
    },
    date: {
        color: Colors.grey500,
        marginLeft: '10px'
    },
    time: {
        color: Colors.grey500,
        marginLeft: '10px'
    },
    notification: {
        margin: '-6px 0 -2px 10px',
        verticalAlign: 'middle'
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.thread.message.header';

    // static contextTypes = {
    //     discussion: React.PropTypes.object.isRequired
    // };

    static propTypes = {
        entity: PropTypes.object.isRequired
    };

    render () {
        return (
            <div style={ styles.container }>
                { this.renderType() }

                <User id={this.props.entity.ownerUid} style={ styles.owner } />

                <span style={ styles.date }>
                    { Moment(this.props.entity.createStamp.substr(0, 19)).format('dddd DD MMMM YYYY') }
                </span>

                <span style={ styles.time }>
                    { Moment(this.props.entity.createStamp.substr(0, 19)).format('HH : mm') }
                </span>
            </div>
        );
    }

    renderType () {
        const type = TYPES[ this.props.entity.payload.type ];

        if (! type.icon)
            return null;

        const TypeIcon = type ? type.icon : InvalidIcon;
        const typeColor = type ? type.color : Colors.grey500;

        const style = styles.type;

        return (
            <span style={ style }>
                <TypeIcon style={ _.extend({}, styles.typeIcon, { fill: typeColor }) } />
            </span>
        );
    }
}
