import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        padding: '5px 10px 5px 5px',
        background: 'white',
        fontSize: '15px',
        lineHeight: '20px',
        cursor: 'pointer'
    },
    icon: {
        float: 'left',
        marginRight: '5px',
        fill: colors.grey400,
        width: '20px',
        height: '20px'
    },
    hover: {
        background: colors.grey100
    },
    name: {
        color: colors.grey700,
        marginRight: '10px'
    },
    count: {
        color: colors.grey500
    },
    value: {
        float: 'right',
        color: colors.userCold,
        fontWeight: 'bold',
        marginLeft: '5px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.tab.stakeholder';

    static propTypes = {
        stakeholder: PropTypes.object.isRequired,
        onOpen: PropTypes.func.isRequired
    };

    state = { hover: false };

    render () {
        const style = this.state.hover
            ? Object.assign({}, styles.container, styles.hover)
            : styles.container;

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onOpen }
            >
                <Views.Common.Number
                    type="currency"
                    scale="M"
                    value={ this.props.stakeholder.value ? this.props.stakeholder.value.value : null }
                    style={ styles.value }
                />

                { this.renderIcon() }

                <span style={ styles.name }>
                    { this.props.stakeholder.target.name }
                </span>

                <span style={ styles.count }>
                    { this.props.stakeholder.deals.length }
                </span>
            </div>
        );
    }

    renderIcon () {
        switch (this.props.stakeholder.target.type) {
            case 'company':
                return (<Icon type="dna.company" style={ styles.icon } />);

            case 'person':
                return (<Icon type="dna.person" style={ styles.icon } />);

            case 'group':
                return (<Icon type="dna.typology" style={ styles.icon } />);
        }

        return null;
    }
}
