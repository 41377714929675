import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Display from '../common/display';
import Flag from './flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.compliance.woco.display';

    static propTypes = {
        relevance: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        relations: PropTypes.array,
    };

    render() {
        return (
            <Display
                flag={<Flag data={this.props.data} />}
                relevance={this.props.relevance}
                country={this.props.data.country}
                title={this.props.data.heading}
                name={this.props.data.subheading}
                properties={this.computeProperties(this.props.data)}
                picture={this.props.data.picture ? 'data:image/png;base64,' + this.props.data.picture : undefined}
                contents={this.props.data.details || []}
                sources={(this.props.data.links || []).concat(
                    this.props.data.source.url ? [this.props.data.source.url] || [] : [],
                )}
                relations={[
                    { label: 'Family members', items: this.props.data.family || [] },
                    { label: 'Personal relations', items: this.props.data.persons || [] },
                    { label: 'Related companies', items: this.props.data.companies || [] },
                ]}
            />
        );
    }

    computeProperties(data) {
        return [
            {
                label: t('common.label.source'),
                value: _.get(data, 'source.name', 'N/C'),
            },
            {
                label: 'Last update',
                value: data.date || 'N/C',
            },
        ];
    }
}
