import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'absolute',
        top: '50%',
        marginTop: '-10px',
        width: '20px',
        lineHeight: '20px',
        background: colors.userCold,
        borderRadius: '10px',
        textAlign: 'center',
        fontSize: '15px',
        color: 'white',
        fontWeight: 'bold',
        zIndex: '999'
    },
    clickable: {
        cursor: 'pointer'
    },
    hover: {
        background: colors.userHot,
        color: colors.white
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.stocks.handle';

    static propTypes = {
        expanded: PropTypes.bool,
        style: PropTypes.object,
        onClick: PropTypes.func,
    };

    state = { hover: false };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.style || {},
            this.props.onClick ? styles.clickable : {},
            this.props.onClick && this.state.hover ? styles.hover : {}
        );

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onClick }
            >
                { this.props.expanded ? '-' : '+' }
            </div>
        );
    }
}
