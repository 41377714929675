import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Header from 'dna/entity/common/display/header';

import Container from 'dna/entity/common/display/container';
import VigilancePanel from 'dna/entity/common/vigilance/panel';
import Cockpit from './display/cockpit';
import Accounting from './display/accounting';
import History from './display/history';

import Blocks from './display/blocks';
import Tabs from './display/tabs';

import Views from 'app/views';
import Comeet from 'comeet/prospects/display';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    header: {
        padding: '30px 30px 20px 30px',
    },
    name: {
        display: 'inline-block',
        marginRight: '15px',
        verticalAlign: 'middle',
    },
    button: {
        display: 'inline-block',
        marginRight: '15px',
        verticalAlign: 'middle',
    },
    info: {
        display: 'inline-block',
        fontSize: '17px',
        marginLeft: '20px',
        color: colors.grey500,
        verticalAlign: 'middle',
    },
    flag: {
        marginLeft: '5px',
        marginTop: '8px',
    },
};


@inject(stores => ({ comeet: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.company.display';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static childContextTypes = {
        fetchProspect: PropTypes.func.isRequired,
        startProspect: PropTypes.func.isRequired,
        endProspect: PropTypes.func.isRequired,
        updateProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        updateEvent: PropTypes.func.isRequired,
        removeEvent: PropTypes.func.isRequired,
        createMilestone: PropTypes.func.isRequired,
        updateMilestone: PropTypes.func.isRequired,
        removeMilestone: PropTypes.func.isRequired,
    };

    getChildContext() {
        return {
            fetchProspect: this.props.comeet.fetchProspect,
            startProspect: this.props.comeet.startProspect,
            endProspect: this.props.comeet.endProspect,
            updateProspect: this.props.comeet.updateProspect,
            createEvent: this.props.comeet.createEvent,
            updateEvent: this.props.comeet.updateEvent,
            removeEvent: this.props.comeet.updateEvent,
            createMilestone: this.props.comeet.createMilestone,
            updateMilestone: this.props.comeet.updateMilestone,
            removeMilestone: this.props.comeet.updateMilestone,
        };
    }

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <Container>
                <Header entity={this.props.entity} state={this.props.state || {}} />
                {this.renderContent()}
            </Container>
        );
    }

    renderContent() {
        if (!this.props.entity) {
            return null;
        }

        const step = (this.props.state ? this.props.state.step : null) || 'compliance';

        switch (step) {
            case 'compliance':
                return (
                  <div>
                    <VigilancePanel
                        entity={this.props.entity}
                        onOpen={() => this.context.updateState({ step: 'display' })}
                    />
                    {this.renderComeet()}
                  </div>
                );

            case 'display':
                return (
                    <div>
                        <Blocks entity={this.props.entity} state={this.props.state} />
                        <Tabs entity={this.props.entity} state={this.props.state} />

                        { this.props.state.open !== true && !this.props.state.prospect ? null : (
                          <Views.Modal.Show open={this.props.state.open} onClose={() => {
                            this.context.updateState({open: false, prospect: null})
                          }}>
                            {this.renderComeet()}
                          </Views.Modal.Show>
                        )}
                    </div>
                );

            case 'accounting':
                return (
                    <div>
                        <Accounting entity={this.props.entity} state={this.props.state} />
                    </div>
                );

            case 'heestory':
                return <div>
                    <History entity={this.props.entity} />
                    {this.renderComeet()}
                </div>
                    ;

            case 'cockpit':
                return <div>
                    <Cockpit entity={this.props.entity} />
                    {this.renderComeet()}
                  </div>
                ;
        }
    }

    renderComeet() {
      return this.props.state.open !== true && !this.props.state.prospect ? null : (
        <Views.Modal.Show open={this.props.state.open} onClose={() => {
          this.context.updateState({open: false, prospect: null})
        }}>
          <Comeet state={{id: this.props.state.prospect}}/>
        </Views.Modal.Show>
      )
    }
}
