import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'app/theme/colors';

import ReplyIcon from 'material-ui/svg-icons/content/reply';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import RemoveIcon from 'material-ui/svg-icons/action/delete';

const styles = {
    container: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        color: Colors.grey500
    },
    action: {
        float: 'right',
        padding: '0 5px',
        marginLeft: '5px',
        borderRadius: '3px',
        cursor: 'pointer',
        background: Colors.userCold
    },
    icon: {
        float: 'left',
        width: '20px',
        height: '20px',
        margin: '3px',
        fill: Colors.white
    },
    label: {
        margin: '0 10px 0 5px',
        lineHeight: '26px',
        color: Colors.white
    },
    highlighted: {
        background: Colors.userHot
    },
    reply: {
    },
    edit: {
    },
    remove: {
        background: Colors.userHot
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.thread.message.actions';

    constructor(props) {
      super(props);
      this.handleDelete = this.handleDelete.bind(this);
    }

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        handleReply: PropTypes.func,
        isChild: PropTypes.bool
    };

    state = {
        highlighted: undefined
    };

    render () {
        return (
            <div style={ styles.container }>
                { this.renderAction('remove', RemoveIcon, this.handleDelete) }
                { this.renderAction('edit', EditIcon, this.props.onEdit) }
                { this.props.child ? null: this.renderAction('reply', ReplyIcon, () => { this.props.handleReply(this.props.entity.id)}, 'Reply') }
            </div>
        );
    }

    handleDelete() {
        if (confirm('Are you sure you want to remove this comment?')) {
            this.props.onRemove(this.props.entity.id);
        }
    }

    renderAction (key, Icon, listener, label) {
        if (! listener)
            return null;

        const actionStyle = _.extend({},
            styles.action,
            styles[ key ],
            key === this.state.highlighted ? styles.highlighted : {}
        );

        return (
            <span
                style={ actionStyle }
                onMouseEnter={ event => this.setState({ highlighted: key }) }
                onMouseLeave={ event => this.setState({ highlighted: undefined }) }
                onClick={ listener }
            >
                <Icon style={ styles.icon } />
                { label ? <span style={ styles.label }>{ label }</span> : null }
            </span>
        );
    }
}
