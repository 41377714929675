import React from 'react';
import Header from './header';
import PropTypes from 'prop-types';
import Timeline from './timeline';

import sum from 'lodash/sum';
import { computeEventCost } from '../../common/compute';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import t from 'app/translation';

@inject(stores => ({ store: stores.comeetProspects }))

@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.prospects.display';

    static contextTypes = {
        fetchProspect: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    componentDidMount () {
        if (this.props.state && this.props.state.id) {
            this.context.fetchProspect(this.props.state.id);
        }
    }

    render () {
        const entity = this.props.store.prospects.get(this.props.state.id);

        if (! entity) {
            return (
                <div style={ this.props.style }>
                    {t('common.message.noFound')}
                </div>
            );
        }

        const eventsIds = entity.events && entity.events.length ? toJS(entity.events) : [];
        const events = [];
        for (const id of eventsIds) {
          events.push(toJS(this.props.store.fields.get(id)));
        }

        const milestonesIds = entity.milestones && entity.milestones.length ? toJS(entity.milestones) : [];
        const milestones = [];
        for (const id of milestonesIds) {
          milestones.push(toJS(this.props.store.fields.get(id)));
        }
        const cost = sum(events.map(computeEventCost));

        return (
            <div style={ this.props.style }>
                <Header
                    entity={ entity }
                    cost={ cost }
                />

                <Timeline
                    entity={ entity }
                    events={ events }
                    milestones={ milestones }
                />
            </div>
        );
    }
}
