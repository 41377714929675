// @flow

import React from 'react';
import PropTypes from 'prop-types';
import t from 'app/translation';
import DataProvider from 'app/layout/providers/data';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { inject } from 'mobx-react';

const styles = {
    container: {
        padding: '0 10px',
        height: '60px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '3px',
        background: colors.grey200,
        marginBottom: '1px',
    },
    profile: {
        flexGrow: '1',
        padding: '0 10px',
    },
    name: {
        color: colors.userCold,
    },
    email: {
        color: colors.grey500,
    },
    level: {
        width: '200px',
    },
    exclude: {
        width: '150px',
        textAlign: 'right',
    },
    select: {
        width: '150px',
        border: '1px solid ' + colors.grey300,
        background: colors.grey100,
        borderRadius: '3px',
        paddingLeft: '20px',
    },
};

@inject('global')
export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.members.item';

    static propTypes = {
        global: PropTypes.object.isRequired,
        team: PropTypes.string.isRequired,
        account: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
    };

    render() {
        return (
            <DataProvider
                service="cleerance"
                model="account"
                id={this.props.account}
                scope="public"
                renderValue={value => {
                    return (
                        <div style={styles.container}>
                            <div style={styles.profile}>
                                <div style={styles.name}>
                                    {value.profile.firstName}
                                    &nbsp;&nbsp;
                                    {(value.profile.lastName || '').toUpperCase()}
                                </div>

                                <div style={styles.email}>{value.email}</div>
                            </div>

                            <div style={styles.level}>
                                <SelectField
                                    value={this.props.level}
                                    onChange={(event, index, level) => {
                                        this.props.global.execute('cleerance', 'team.include', {
                                            team: this.props.team,
                                            level,
                                            user: { email: value.email },
                                        });
                                    }}
                                    style={styles.select}
                                    underlineStyle={{ display: 'none' }}
                                    iconStyle={{ fill: colors.grey500 }}
                                >
                                    <MenuItem value="director" primaryText={t(`cleerance.admin.levels.director`)} />
                                    <MenuItem value="manager" primaryText={t(`cleerance.admin.levels.manager`)} />
                                    <MenuItem value="member" primaryText={t(`cleerance.admin.levels.member`)} />
                                    <MenuItem value="observer" primaryText={t(`cleerance.admin.levels.observer`)} />
                                </SelectField>
                            </div>

                            <div style={styles.exclude}>
                                <Views.Common.Button
                                    key="button"
                                    type="flat"
                                    color="hot"
                                    icon={<Icon type="do.cancel" />}
                                    label="Exclude"
                                    onClick={() => {
                                        this.props.global.execute('cleerance', 'team.exclude', {
                                            team: this.props.team,
                                            email: value.email,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    );
                }}
            />
        );
    }
}
