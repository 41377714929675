// @flow

import type { StateLocation } from './types';

import { toJS } from 'mobx';
import getValue from 'lodash/get';
import setValue from 'lodash/set';

export default class StatePath {
    segments: Array<string | number>;

    constructor (location?: StateLocation = []) {
        this.segments = StatePath.normalizeLocation(location);
    }

    normalize (): StatePath {
        const segments = this.segments
            .map((segment: string | number) => {
                const match = typeof segment === 'string'
                    ? segment.match(/^\w+\+(\w+)$/)
                    : undefined;

                return match ? match[1] : segment;
            })
            .join('.');

        return new StatePath(segments);
    }

    concat (location: StateLocation): StatePath {
        return new StatePath(this.segments.concat(StatePath.normalizeLocation(location)));
    }

    read (data: Object, fallback: any = undefined): any {
        return this.segments.length
            ? getValue(toJS(data), this.segments, fallback)
            : data;
    }

    write (data: Object, value: any): any {
        return this.segments.length
            ? setValue(toJS(data), this.segments, value)
            : value;
    }

    parent (): ?StatePath {
        const length = this.segments.length;

        if (! length) {
            console.warn(`Trying to get root node's parent.`);
            return undefined;
        }

        return new StatePath(this.segments.slice(0, length - 1))
    }

    toString (): string {
        return this.segments.join('.');
    }

    static normalizeLocation (location: StatePath | StateLocation): Array<string | number> {
        if (location instanceof StatePath) {
            return location.segments;
        }

        if (typeof location === 'string') {
            location = location.split('.');
        }

        if (typeof location === 'number') {
            location = [location];
        }

        if (! Array.isArray(location)) {
            throw new Error(`Tried to normalize an invalid path location: ${JSON.stringify(location)}`);
        }

        const output = [];

        for (const segment of location) {
            output.push(StatePath.normalizeSegment(segment));
        }

        return output;
    }

    static normalizeSegment (segment: string | number): string | number {
        switch (typeof segment) {
            case 'string':
                if (! segment) {
                    throw new Error(`Found an empty path segment.`);
                }

                if (segment.indexOf('.') > -1) {
                    throw new Error(`Found "${segment}" path segment containing a "." path separator.`);
                }

                if (segment.match(/^\d+$/)) {
                    return parseInt(segment);
                }

                return segment;

            case 'number':
                if (segment < 0) {
                    throw new Error(`Found "${segment}" negative index path segment.`);
                }

                return segment;

            default:
                throw new Error(`Found "${segment}" invalid path segment.`);
        }
    }
}
