import PropTypes from 'prop-types';
import React from 'react';
import ReactUtils from 'react-dom/test-utils';
import omit from 'lodash/omit';
import colors from 'app/theme/colors';

// basics
import String from '../widget/string';
import Text from '../widget/text';
import Number from '../widget/number';
import Boolean from '../widget/boolean';

// date/time
import Date from '../widget/date';
import Time from '../widget/time';
// import Duration from '../widget/duration';

// multiple
import Collection from '../widget/collection';
import Radios from '../widget/radios';
import Bools from '../widget/bools';
import Select from '../widget/select';
import File from '../widget/file';

const factories = {
    string: String,
    text: Text,
    number: Number,
    date: Date,
    boolean: Boolean,
    time: Time,
    // duration: Duration,
    collection: Collection,
    radios: Radios,
    bools: Bools,
    select: Select,
    file: File
};

const style = {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexGrow: '1',
    flexShrink: '1',
    borderWidth: '0',
    padding: '10px',
    fontSize: '17px',
    lineHeight: '24px',
    outline: 'none',
    background: 'transparent',
    color: colors.grey900
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.field.body';

    static propTypes = {
        widget: PropTypes.oneOfType([ PropTypes.object, PropTypes.string, PropTypes.element ]).isRequired,
        focus: PropTypes.bool.isRequired,
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired,
        readOnly: PropTypes.bool
    };

    render () {
        const props = {
            value: this.props.value,
            style: Object.assign({}, style, this.props.widget.style || {}),
            onChange: this.props.onChange,
            onFocus: this.props.onFocus,
            onBlur: this.props.onBlur,
            onError: this.props.onError,
            readOnly: this.props.readOnly,
            onAsync: this.props.onAsync
        };

        if (ReactUtils.isElement(this.props.widget)) {
            return React.cloneElement(this.props.widget, props);
        }

        const type = typeof this.props.widget === 'string' ? this.props.widget : this.props.widget.type;
        const options = typeof this.props.widget === 'object' ? omit(this.props.widget, [ 'type', 'style' ]) : {};

        if (! factories.hasOwnProperty(type)) {
            throw new Error(`Invalid widget type "${type}".`);
        }

        return React.createElement(factories[ type ], Object.assign(options, props));
    }
}
