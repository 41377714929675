import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import colors from 'app/theme/colors';

const HEIGHT = 60;

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.display.links';

    static propTypes = {
        sources: PropTypes.object.isRequired,
        targets: PropTypes.object.isRequired,
        highlighted: PropTypes.string
    };

    state = { width: undefined };

    componentDidMount () {
        this.setState({ width: ReactDOM.findDOMNode(this.refs.container).offsetWidth });
    }

    render () {
        return (
            <div ref="container" style={{ height: HEIGHT + 'px', marginTop: '-1px' }}>
                { this.renderSvg() }
            </div>
        );
    }

    renderSvg () {
        if (! this.state.width)
            return;

        const links = [];

        _.each(_.intersection(_.keys(this.props.sources), _.keys(this.props.targets)), id => {
            links.push({
                id: id,
                source: this.props.sources[ id ],
                target: this.props.targets[ id ]
            });
        });

        return (
            <svg
                width={ this.state.width }
                height={ HEIGHT }
                viewBox={ `0 0 ${this.state.width} ${HEIGHT}` }
            >
                { links.map(this.renderLink.bind(this)) }
            </svg>
        )
    }

    renderLink (link, index) {
        return (
            <path
                key={ link.id }
                d={ `M${link.source},0 C${link.source},${HEIGHT/2} ${link.target},${HEIGHT/2} ${link.target},${HEIGHT}` }
                stroke={ this.props.highlighted === link.id ? colors.userHot : colors.grey400 }
                strokeWidth={ 1.5 }
                fill="none"
            />
        );
    }
}
