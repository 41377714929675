import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import colors from 'app/theme/colors';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import EventDisplay from 'teem/common/events/display';
import MilestoneDisplay from 'teem/common/milestones/display';
import MeetingDisplay from 'teem/common/meetings/display';
import EventForm from 'teem/common/events/form';
import { computeStartedDate } from 'teem/common/compute';
import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject(stores => ({ store: stores.teemProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects.display.timeline';

    static contextTypes = {
        startProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        updateEvent: PropTypes.func.isRequired,
        updateMilestone: PropTypes.func.isRequired,
        updateMeeting: PropTypes.func.isRequired,
        removeEvent: PropTypes.func.isRequired,
        removeMilestone: PropTypes.func.isRequired,
        removeMeeting: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        events: PropTypes.array.isRequired,
        milestones: PropTypes.array.isRequired,
        meetings: PropTypes.array.isRequired,
    };

    findLastDate() {
        console.log(this.props.entity);
        const period = this.props.entity.payload.settings.months;
        let endDate = Moment(computeStartedDate(this.props.entity.history)).add(period, 'month');

        for (const event of [...this.props.events, ...this.props.milestones, ...this.props.meetings]) {
            const eventDate = Moment(event.payload.date);
            if (eventDate > endDate) endDate = eventDate;
        }

        return endDate;
    }

    render() {
        if (this.props.entity.status === 'draft') {
            return this.renderDraft();
        }

        if (this.props.events.length + this.props.milestones.length + this.props.meetings.length <= 0) {
            return this.renderEmpty();
        }

        return (
            <Views.Timeline.Display start={this.props.entity.createStamp} end={this.findLastDate()}>
                <Views.Timeline.Rule unit="month" />
                {this.props.events.map(this.renderEvent.bind(this))}
                {this.props.milestones.map(this.renderMilestone.bind(this))}
                {this.props.meetings.map(this.renderMeeting.bind(this))}
            </Views.Timeline.Display>
        );
    }

    renderEvent(event) {
        return (
            <Views.Timeline.Event
                key={event.id}
                id={event.id}
                date={event.payload.date}
                dimmed={Moment(event.payload.date) < Moment().add(1, 'day')}
                style={{ background: colors.white }}
            >
                <EventDisplay
                    entity={event}
                    prospect={this.props.entity}
                    onUpdate={data => this.context.updateEvent(event.id, data)}
                    onRemove={data => this.context.removeEvent(this.props.entity.id, event.id)}
                />
            </Views.Timeline.Event>
        );
    }

    renderMilestone(milestone) {
        return (
            <Views.Timeline.Event
                key={milestone.id}
                id={milestone.id}
                style={{ background: colors.white }}
                date={milestone.payload.date}
                dimmed={Moment(milestone.payload.date) < Moment().add(1, 'day')}
            >
                <MilestoneDisplay
                    entity={milestone}
                    prospect={this.props.entity}
                    onUpdate={data => this.context.updateMilestone(milestone.id, data)}
                    onRemove={data => this.context.removeMilestone(this.props.entity.id, milestone.id)}
                />
            </Views.Timeline.Event>
        );
    }

    renderMeeting(meeting) {
        return (
            <Views.Timeline.Event
                key={meeting.id}
                id={meeting.id}
                style={{ background: colors.white }}
                date={meeting.payload.date}
                dimmed={Moment(meeting.payload.date) < Moment().add(1, 'day')}
            >
                <MeetingDisplay
                    entity={meeting}
                    prospect={this.props.entity}
                    onUpdate={data => this.context.updateMeeting(meeting.id, data)}
                    onRemove={data => this.context.removeMeeting(this.props.entity.id, meeting.id)}
                />
            </Views.Timeline.Event>
        );
    }

    renderEmpty() {
        if (this.props.entity.status !== 'started') {
            return <Views.Common.Empty title={t('dna.comeet.noEvent')} />;
        }

        return (
            <div>
                <Views.Common.Empty
                    title={t('dna.comeet.noEvent')}
                    actions={[
                        <Views.Common.Button
                            type="raised"
                            color="cold"
                            icon={<Icon type="do.create" />}
                            label={t('dna.comeet.addEvent')}
                            onClick={event => this.refs.eventCreator.open()}
                        />,
                    ]}
                />

                <EventForm ref="eventCreator" onSubmit={data => this.context.createEvent(this.props.entity.id, data)} />
            </div>
        );
    }

    renderDraft() {
        return (
            <Views.Common.Empty
                title={t('dna.teem.noStartedProspecting')}
                actions={[
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        icon={<Icon type="do.start" />}
                        label={t('common.label.start')}
                        onClick={event => this.context.startProspect(this.props.entity.id)}
                    />,
                ]}
            />
        );
    }
}
