// @flow

import type {
    VigilanceNorkomAnswers,
    VigilanceNorkomRiskModel,
    VigilanceNorkom,
    VigilanceNorkomQuestionsCollection,
} from '@deecision/deefind-types/model';

import { questions } from './components';

export default function(answers: VigilanceNorkomAnswers): VigilanceNorkom {
    let riskModel = {
        LO: 0,
        ML: 0,
        MH: 0,
        HI: 0,
    };

    return loopOverComponents(answers, questions, riskModel);
}

function loopOverComponents(
    answers: VigilanceNorkomAnswers,
    questions: VigilanceNorkomQuestionsCollection,
    riskModel: VigilanceNorkomRiskModel,
): VigilanceNorkom {
    const missed = [];
    const values = [];
    for (const question of questions) {

        if (!question[answers.type]) {
            continue;
        }
        if(typeof answers.values[question.id] === 'undefined'){
            missed.push(question.label);
            continue;
        }

        const value = executeValue(answers.values[question.id], question.rules);
        const questionScoring = question[answers.type];

        if (!value || !questionScoring) {
            missed.push(question.label);
            continue;
        }
        riskModel[value] += questionScoring[value];
        values.push({
            id: question.id,
            label: question.label,
            lvl: value,
            score: questionScoring[value],
            answer: answers.values[question.id]
        });
    }

    return {
        score: computeScore(riskModel),
        missed,
        values
    };
}

function computeScore(riskModel: VigilanceNorkomRiskModel) {
    let score = 0;
    score += riskModel.LO;
    score += riskModel.ML;
    score += riskModel.MH;
    score += riskModel.HI;

    return score;
}

function executeValue(input: any, rules: Function[]): ?('LO' | 'ML' | 'MH' | 'HI') {
    for (const rule of rules) {
        const value = rule(input);
        if (value) {
            return value;
        }
    }

    return undefined;
}
