import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';
import colors from 'app/theme/colors';
import Views from 'app/views';
import get from 'lodash/get';

const styles = {
    title: {
        color: colors.userCold,
    },
    target: {
        display: 'inline-block',
        cursor: 'pointer',
        lineHeight: '20px',
        borderBottom: '1px solid ' + colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.blocks.stocks';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <DeefindProvider
                type="stocks"
                id={this.props.entity.id}
                mini
                renderEntity={(stocks) => this.renderContent(stocks)}
            />
        );
    }

    renderContent(stocks: Object) {
        if (!Array.isArray(stocks.parents)) {
            if (!stocks.parents) {
                return null;
            }

            return (
                <Views.Common.Properties
                    values={[
                        { label: 'Total', value: get(stocks, 'parents.total') },
                        { label: 'Indirect', value: get(stocks, 'parents.indirect') },
                        { label: 'Direct', value: get(stocks, 'parents.direct') },
                        { label: 'valued', value: get(stocks, 'parents.valued') },
                    ]}
                    align={50}
                />
            );
        }

        const ultimate = [];

        if (get(stocks, 'ultimate.ubo')) {
            ultimate.push(
                <div key={'ubo'}>
                    <div style={styles.title}>Ultimate beneficial owner:</div>
                    <div style={styles.target} onClick={() => this.context.openEntity(get(stocks, 'ultimate.ubo.id'))}>
                        {get(stocks, 'ultimate.ubo.name')}
                    </div>
                </div>,
            );
        }

        if (get(stocks, 'ultimate.upc')) {
            ultimate.push(
                <div key={'upc'}>
                    <div style={styles.title}>Ultimate Parent Company:</div>
                    <div style={styles.target} onClick={() => this.context.openEntity(get(stocks, 'ultimate.upc.id'))}>
                        {get(stocks, 'ultimate.upc.name')}
                    </div>
                </div>,
            );
        }

        if (ultimate.length) {
            return ultimate.reduce((prev, curr) => [prev, ' ', curr]);
        }

        return <div />;
    }
}
