// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import type { DeetectResult } from '@deecision/deefind-types/client';
import Inputs from '../work/inputs';
import { isRelationMode } from '../work/results/list/specs';
import { get } from 'lodash';
import Results from './results';
import Pal from './pal';
import { TabManager } from '../../entity/company/display/history/tabsManager';
import { Diagrams } from '../diagrams/diagrams';
import { Zones } from '../zones/zones';

const styles = {
    wrapper: {
        display: 'flex',
        alignItems: 'space-between',
        flexWrap: 'wrap',
        marginTop: '60px',
    },
    sideBar:{
        width: '200px',

    },
    inputs: {
        width: '200px',
    },
    filters: {
        width: '200px',
        margin: '66px 0px 0px 0px',
    },
    results: {
        width: '1150px',
    },
    tabs: {
        display: 'flex',
        margin: '20px auto',
        width: '1400px',
        borderBottom: '2px solid rgb(245, 245, 245)',
    }
};

const tabs = [
    { label: "Data", value: 1 },
    { label: "Diagrammes", value: 2 },
    { label: "Zones", value: 3 }
];

const FILTERS = {
    augmented: { accuracy: 0 },
};

@inject('queue')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.augmented';

    static propTypes = {
        state: PropTypes.object.isRequired,
        queue: PropTypes.object.isRequired,
        compute: PropTypes.func.isRequired,
        filters: PropTypes.arrayOf(PropTypes.string).isRequired,
        style: PropTypes.object,
        loop: PropTypes.bool,
    };

    static contextTypes = {
        setStage: PropTypes.func.isRequired,
    };

    state = { filters: null, segment: null, activeTab: 1 };

    dispose1: ?Function;
    dispose2: ?Function;

    UNSAFE_componentWillMount() {
        this.dispose1 = this.props.queue.watchStatus(this.getScope());
        this.dispose2 = this.props.queue.watchResults(this.getScope());
    }

    UNSAFE_componentWillReceiveProps(props: Object) {
        if (props.state.id !== this.props.state.id) {
            if (this.dispose1) this.dispose1();
            if (this.dispose2) this.dispose2();

            this.dispose1 = this.props.queue.watchStatus(this.getScope(props.state));
            this.dispose2 = this.props.queue.watchResults(this.getScope(props.state));
        }
    }

    componentWillUnmount() {
        if (this.dispose1) this.dispose1();
        if (this.dispose2) this.dispose2();
    }

    getScope(state?: Object) {
        state = state || this.props.state;
        return { service: 'deefind', consumer: 'deetect.' + state.tool, batch: state.id };
    }

    render() {
        let result = [];
        const thresholds = this.getReechThresholds();
        const bankTypes = this.getReechBankTypes();

        const scope = this.getScope();
        const source = this.props.state.source || 'person';
        const filters = this.state.filters || FILTERS[this.props.state.tool];
        const status = this.props.queue.getStatus(scope).get();
        const results = this.props.queue.getResults(scope).get() || [];

        const { counts, exports } = this.props.compute(results, filters, thresholds, bankTypes, source);

        console.log('-- AUGMENTED -->', { source, status, results, counts, exports, filters });

        const dataReech = [];
        const dataComputed = [];
        const dataDiff = [];

        for (let i = 0; i < 4; i++) {
            dataReech.push({ label: thresholds[i].label, value: counts.reechColumnsPrct[i] });
            dataComputed.push({ label: thresholds[i].label, value: counts.computedColumnsPrct[i] });
            dataDiff.push({
                label: thresholds[i].label,
                negative: counts.migrations[i].exitPrct,
                positive: counts.migrations[i].entryPrct,
            });
        }

        result.push(
            <tr style={styles.tabs} key={ 'tabs' }>
                <TabManager tabs={tabs} activeTab={this.state.activeTab} handleTab={(value) => this.setState({ activeTab: value })} />
            </tr>
        );

        if (this.state.activeTab === 1) {
            result.push(
                <div style={Object.assign({}, styles.wrapper, this.props.style)}>
                    <div style={styles.sideBar}>
                        <Inputs
                            queue={this.props.queue}
                            scope={this.getScope()}
                            status={status}
                            counts={counts}
                            results={results}
                            style={styles.inputs}
                            type={source}
                            onType={type => {
                                const next = Object.assign({}, {}, { type });
                                this.state.filters = {};
                                this.context.setStage({ filters: {} });
                            }}
                        />
                        <Pal
                            filters={filters}
                            exports={exports}
                            onScores={filters => {
                                this.setState({ filters })
                            }}
                            onSave={filters => this.context.setStage({ filters })}
                            style={styles.filters}
                            tool={this.props.state.tool}
                        />
                    </div>
                    <Results
                        ref="results"
                        counts={counts}
                        filters={filters}
                        thresholds={thresholds}
                        bankTypes={bankTypes}
                        style={styles.results}
                        onOpen={(ids: string[]) => {
                            this.refs['results'].open({
                                mode: this.props.state.tool,
                                source: source,
                                target: 'person',
                                results: this.makeResults(results, ids),
                            });
                        }}
                    />
                </div>
            );
        } else if (this.state.activeTab === 2) {
            result.push(<Diagrams data={{ source, filters, status, results, counts, exports }} />);
        }
        else if (this.state.activeTab === 3) {
            result.push(<Zones data={{ source, filters, status, results, counts, exports }} />);
        }

        return result;
    }

    getReechThresholds(): Array<{label: string, code: string, min: number, max: number}> {
        return [
            {
                code: 'retail',
                min: 0,
                max: 500000,
                label: 'Retail',
            },
            {
                code: 'hnwi',
                min: 500000,
                max: 2000000,
                label: 'HNW',
            },
            {
                code: 'vhnwi',
                min: 2000000,
                max: 5000000,
                label: 'VHNW',
            },
            {
                code: 'uhnwi',
                min: 5000000,
                max: Infinity,
                label: 'UHNW',
            },
        ];
    }

    getReechBankTypes(): Array<{label: string, order: number}> {
        return [
            { label: 'Retail', order: 1},
            { label: 'BP', order: 2},
            { label: 'BAE', order: 3},
        ];
    }

    makeResults(results: Object[], ids: string[]): Array<{ source: DeetectResult, target?: DeetectResult }> {
        if (!isRelationMode(this.props.state.tool) && !get(results[0], 'output.relations')) {
            return results
                .filter(result => (ids || []).includes(get(result, 'output.result.id')))
                .map(result => ({ source: get(result, 'output.result') }));
        }

        const outputs = [];

        for (const result of results) {
            for (const relation of get(result, 'output.relations', [])) {
                if (ids.includes(relation.id)) {
                    outputs.push({ source: get(result, 'output.result'), target: relation });
                }
            }
            if (this.props.state.tool === 'upsell') {
                outputs.push({ source: get(result, 'output.result') });
            }
        }

        return outputs;
    }
}
