import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';

let styles = {
    container: {
        background: colors.grey200,
        borderRadius: '3px',
        position: 'relative'
    },
    containerHighlighted: {
        background: colors.userCold,
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '8px',
    },
    input: {
        display: 'inline-block',
        verticalAlign: 'middle',
        border: '2px solid ' + colors.grey200,
        boxSizing: 'content-box',
        lineHeight: '36px',
        color: colors.userCold,
        background: colors.grey200,
        width: '50px',
        padding: '0 10px',
        borderRadius: '0 3px 3px 0',
        borderWidth: '0',
        marginRight: '2px',
        outline: 'none',
        fontSize: 'inherit',
        transition: 'background 450ms, width 450ms'
    },
    focus: {
        background: colors.white,
        width: '150px'
    },
    clear: {
        position: 'absolute',
        top: '4px',
        right: '4px',
        padding: '4px',
        cursor: 'pointer',
        fill: colors.userHot
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.header.fuse';

    static propTypes = {
        value: PropTypes.string,
        style: PropTypes.object,
        onChange: PropTypes.func
    };

    state = {
        focus: false,
        timer: undefined,
    };

    handleChange (event: Event) {
        const value = event.target.value;

        if (this.state.timer) {
            clearTimeout(this.state.timer);
        }

        this.state.timer = setTimeout(() => this.props.onChange(value), 250);
    }

    render () {
        const containerStyle = Object.assign({},
            styles.container,
            this.props.value ? styles.containerHighlighted : {},
            this.props.style
        );

        const inputStyle = this.state.focus || this.props.value
            ? Object.assign({}, styles.input, styles.focus)
            : styles.input;

        return (
            <Views.Common.Tooltip content={t('common.label.fullTextSearch')}>
                <div style={ containerStyle }>
                    <Icon
                        type="do.search"
                        color={ this.props.value ? colors.white : colors.userCold }
                        style={ styles.icon }
                        onClick={ event => this.inputNode.focus() }
                    />

                    <input
                        ref={ node => this.inputNode = node }
                        style={ inputStyle }
                        defaultValue={ this.props.value }
                        onFocus={ event => this.setState({ focus: true }) }
                        onChange={ this.handleChange.bind(this) }
                        onBlur={ event => this.setState({ focus: false }) }
                    />

                    { this.renderClear() }
                </div>
            </Views.Common.Tooltip>
        );
    }

    renderClear () {
        if (! this.props.value)
            return null;

        return (
            <Icon
                type="do.clear"
                style={ styles.clear }
                onClick={ event => {
                    this.inputNode.value = '';
                    this.props.onChange('');
                } }
            />
        );
    }
}
