import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Colors from 'app/theme/colors';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

import TYPES from '../message/types';

const styles = {
    container: {
        display: 'inline-block',
        width: '150px',
        flexGrow: '0',
    },
    type: {
        padding: '5px 10px',
        borderRadius: '5px',
        width: '130px'
    },
    icon: {
        fill: Colors.grey400
    },
    label: {
        color: Colors.grey800
    },
    selectedIcon: {
        fill: 'white'
    },
    selectedLabel: {
        color: 'white'
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.thread.composer.type';

    static propTypes = {
        types: PropTypes.arrayOf(PropTypes.oneOf(_.keys(TYPES))).isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render () {
        return (
            <RadioButtonGroup
                style={ styles.container }
                name="type"
                valueSelected={ this.props.value }
                onChange={ (event, type) => this.props.onChange(type) }
            >
                { this.props.types.map(this.renderType.bind(this)) }
            </RadioButtonGroup>
        );
    }

    renderType (type) {
        if (! TYPES[ type ])
            return null;

        const current = this.props.value === type;
        const containerStyle = _.extend({}, styles.type, current ? { background: TYPES[ type ].color } : {});
        const iconStyle = _.extend({}, styles.icon, current ? styles.selectedIcon : {});
        const labelStyle = _.extend({}, styles.label, current ? styles.selectedLabel : {});

        return (
            <RadioButton
                key={ type }
                value={ type }
                label={ TYPES[ type ].label }
                style={ containerStyle }
                iconStyle={ iconStyle }
                labelStyle={ labelStyle }
            />
        );
    }
}
