import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Moment from 'moment';
import orderBy from 'lodash/orderBy';

import { inject, observer } from 'mobx-react';

const styles = {
    link: {
        display: 'inline-block',
        lineHeight: '24px',
        cursor: 'pointer',
    },
    linkIcon: {
        float: 'left',
        marginRight: '5px',
    },
    modal: {
        width: '800px',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        margin: '0px'
    },
    empty: {
        padding: '20px 0',
        textAlign: 'center',
        color: colors.grey500,
    },

    list: {
        flexShrink: '1',
        flexGrow: '1',
        maxHeight: '500px',
        overflow: 'scroll',
    },
    writer: {
        width: '100%',
        background: colors.grey100,
        borderTop: '1px solid ' + colors.grey300,
        flexGrow: '0',
        flexShrink: '0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    writerArea: {
        display: 'block',
        width: '100%',
        margin: '10px',
        padding: '10px',
        borderRadius: '3px',
        border: '1px solid ' + colors.userCold,
        background: 'white',
        height: '64px',
        fontSize: 'inherit',
        fontFamily: 'inherit',
    },
    writerSend: {
        marginRight: '15px',
    },
    commentItem: {
        margin: '20px',
        background: colors.grey100,
        borderRadius: '3px',
        padding: '20px',
    },
    commentHead: {
        marginBottom: '5px',
    },
    commentBody: {
        fontFamily: 'inherit',
        whiteSpace: 'normal',
        margin: '0',
    },
    itemGrey: {
        color: colors.grey500,
    },
    itemBlue: {
        color: colors.userCold,
    },
};

@inject(stores => ({ contacts: stores.auth.contacts }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.comments';

    static propTypes = {
        comments: PropTypes.array.isRequired,
        onComment: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    state = {
        open: false,
        hover: false,
        comment: '',
    };

    render() {
        return (
            <div style={styles.body}>
                {this.renderList()}
                {this.renderWriter()}
            </div>
        );
    }

    renderList() {
        const items = orderBy(
            this.props.comments.map(comment => Object.assign({}, comment, { type: 'comment' })),
            'createStamp',
            'asc',
        );

        return (
            <div style={styles.list}>
                {items.map((item, index) => {
                    switch (item.type) {
                        case 'comment':
                            return this.renderComment(item, index > 0);
                    }
                })}
            </div>
        );
    }

    renderComment(comment, separator) {
        return (
            <div key={comment.id} style={styles.commentItem}>
                <div style={styles.commentHead}>
                    <span style={styles.itemBlue}>{this.renderContactName(comment.ownerUid)}</span>
                    &nbsp;&nbsp;
                    <span style={styles.itemGrey}>{Moment(comment.createStamp).format('lll')}</span>
                </div>

                <pre style={styles.commentBody}>{comment.value}</pre>
            </div>
        );
    }

    renderWriter() {
        return (
            <div style={styles.writer}>
                <textarea
                    style={styles.writerArea}
                    value={this.state.comment}
                    onChange={event => this.setState({ comment: event.target.value })}
                />

                <Views.Common.Button
                    type="round"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    style={styles.writerSend}
                    disabled={!this.state.comment}
                    onClick={event => {
                        this.props.onComment('nda', this.state.comment);
                        this.setState({ comment: '' });
                    }}
                />
            </div>
        );
    }

    renderContactName(uuid) {
        const users = this.props.contacts ? this.props.contacts.users : null;

        if (!users || !users[uuid]) {
            return 'Unknown';
        }

        return users[uuid] ? users[uuid].name : users[uuid].firstName + ' ' + users[uuid].lastName;
    }
}
