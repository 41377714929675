import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Comments from '../../common/comments';
import Contacts from '../../common/contacts';
import Nda from '../../common/nda';
import Avatar from 'material-ui/Avatar';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        marginBottom: '30px',
        justifyContent: 'flex-start',
    },
    title: {
        lineHeight: '40px',
        fontSize: '19px',
        margin: '0 20px',
    },
};

@inject(stores => ({ contacts: stores.auth.contacts, store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.header';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onComment: PropTypes.func.isRequired,
    };

    handleContacts(data) {
        this.props.store.updateContacts(this.props.entity.id, {
            oddContact: data.oddContact || {},
            salesContact: data.salesContact || {},
            extraContact: data.extraContact || {},
        });
    }

    render() {
        const contacts = this.props.contacts;
        const owner = contacts && contacts.users ? contacts.users[this.props.entity.ownerUid] || {} : {};
        const payload = this.props.entity.payload || {};

        return (
            <div style={styles.container}>
                <Avatar icon={<Icon type="main.account" />} />

                <div style={styles.title}>{owner.firstName + ' ' + owner.lastName}</div>

                <Nda external={true} entity={this.props.entity} onComment={this.props.onComment} />

                <Comments
                    type="button"
                    title={payload.manager}
                    channel="main"
                    comments={(toJS(this.props.entity.comments) || []).filter(item => item.channel === 'main')}
                    transitions={toJS(this.props.entity.history || [])}
                    onComment={this.props.onComment}
                />

                {/*<Views.Common.Button*/}
                {/*    type="flat"*/}
                {/*    color="cold"*/}
                {/*    label={this.countContacts()}*/}
                {/*    icon={<Icon type="base.attendee" />}*/}
                {/*    onClick={event => this.refs.contacts.open()}*/}
                {/*    style={{ marginRight: '15px' }}*/}
                {/*/>*/}

                <Contacts entity={this.props.entity} />

                {/*<Views.Modal.Edition*/}
                {/*    ref="contacts"*/}
                {/*    data={payload}*/}
                {/*    label="Save contacts"*/}
                {/*    onSubmit={this.handleContacts.bind(this)}*/}
                {/*>*/}
                {/*    <Views.Form.Row title="Sales contact">*/}
                {/*        <Views.Form.Field label="Name" path="salesContact.name" widget="string" />*/}

                {/*        <Views.Form.Field*/}
                {/*            label="Email"*/}
                {/*            path="salesContact.email"*/}
                {/*            widget={{ type: 'string', pattern: 'email' }}*/}
                {/*        />*/}

                {/*        <Views.Form.Field label="Phone" path="salesContact.phone" widget="string" />*/}
                {/*    </Views.Form.Row>*/}

                {/*    <Views.Form.Row title="Secondary contact">*/}
                {/*        <Views.Form.Field label="Name" path="extraContact.name" widget="string" />*/}

                {/*        <Views.Form.Field*/}
                {/*            label="Email"*/}
                {/*            path="extraContact.email"*/}
                {/*            widget={{ type: 'string', pattern: 'email' }}*/}
                {/*        />*/}

                {/*        <Views.Form.Field label="Phone" path="extraContact.phone" widget="string" />*/}
                {/*    </Views.Form.Row>*/}
                {/*</Views.Modal.Edition>*/}
            </div>
        );
    }

    countContacts() {
        let count = 1;
        const payload = this.props.entity.payload || {};

        if (
            payload.salesContact &&
            (payload.salesContact.name || payload.salesContact.email || payload.salesContact.phone)
        ) {
            count++;
        }

        if (
            payload.extraContact &&
            (payload.extraContact.name || payload.extraContact.email || payload.extraContact.phone)
        ) {
            count++;
        }

        return count;
    }
}
