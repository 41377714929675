import React from 'react';
import PropTypes from 'prop-types';
import List from './thread/list';
import Composer from './thread/composer';

const ROOT_TYPES = [ 'info', 'idea', 'question', 'decision', 'alert' ];

const styles = {
    container: {
        margin: '10px 0',
        fontSize: '15px'
    },
    composer: {
        marginLeft: '10px'
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.thread';

    static propTypes = {
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        target: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                <List
                    entities={ this.props.entities }
                    handleDelete={ this.props.handleDelete }
                    handleSubmit= { this.props.handleSubmit }
                />

                {this.renderComposer()}
            </div>
        );
    }

    renderComposer () {
        return (
            <Composer
                types={ ROOT_TYPES }
                target={ this.props.target }
                style={ styles.composer }
                handleSubmit={ this.props.handleSubmit }
            />
        );
    }
}
