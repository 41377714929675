// @flow

import type { ResolverInterface, ResolverSpecs } from '../../types';
import type StatePath from '../../path';
import type StateNode from '../../node';

export default class BooleanResolver implements ResolverInterface {
    path: StatePath;
    specs: ResolverSpecs;

    constructor (path: StatePath, specs: ResolverSpecs) {
        this.path = path;
        this.specs = specs;
    }

    normalize (node: StateNode, value: any, external?: boolean): any {
        if (value === undefined) {
            return this.specs.fallback;
        }

        return !! value;
    }
}
