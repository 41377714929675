// @flow

import type { EntityFlag, FileAttachment, LinkProfile, PersonPreview } from '../../../types/model/index';
import { makeFortune, makeRelatedNames, makeFilesFlags, makeLinksCount, makeTargetsSum } from './common';
import { normCountryCodeLower, normPersonPreview } from '../../norm';
import get from 'lodash/get';

export default function makePersonPreview(data: Object, files: FileAttachment[], links: LinkProfile[]): PersonPreview {
    const identity = data.identity || {};
    const residency = normCountryCodeLower(get(data, 'identity.country') || get(data, 'addresses.0.country'));
    
    return normPersonPreview({
        country: residency || undefined,
        birthCountry: identity.birthCountry || undefined,
        nationality: identity.nationality || undefined,
        homeCountry: identity.homeCountry || undefined,
        firstName: identity.firstName || undefined,
        lastName: identity.lastName || undefined,
        name: makePersonName(identity.firstName, identity.lastName, identity.name) || undefined,
        birthDate: identity.birthDate || undefined,
        gender: identity.gender || undefined,
        companies: makeRelatedNames(links, 'company'),
        persons: makeRelatedNames(links, 'person'),
        flags: makeFilesFlags(files).concat(makeListedFlags(data, links)).concat(makeWarningFlags(data, links)),
        mandates: makeLinksCount('mandates', links, true),
        turnoversSum: makeTargetsSum('mandates', 'turnover', links) || undefined,
        ebitdasSum: makeTargetsSum('mandates', 'ebitda', links) || undefined,
        capitalsSum: makeTargetsSum('mandates', 'capital', links) || undefined,
        valuationsSum: makeTargetsSum('mandates', 'valuation', links) || undefined,
        fortune: makeFortune(files) || undefined,
        deceased: data.identity.deathStatus ? data.identity.deathDate || true : undefined,
    });
}

export function makeListedFlags(data: Object, links: LinkProfile[]): EntityFlag[] {
    for (const link of links) {
        if (link.target.type === 'company' && (link.target.flags || []).includes('listed')) {
            return ['listed-direct'];
        }

        if (link.target.type === 'person' && (link.target.flags || []).includes('listed-direct')) {
            return ['listed-indirect'];
        }
    }

    return [];
}

export function makeWarningFlags(data: Object, links: LinkProfile[]): EntityFlag[] {
    for (const link of links) {
        if (link.target.type === 'company' && !link.ceased && (link.target.flags || []).includes('crisis-impact')) {
            return ['crisis-direct'];
        }
    }

    return [];
}

export function makePersonName(firstName: ?string, lastName: ?string, name: ?string): ?string {
    if (firstName && lastName) {
        return `${lastName.toUpperCase()} ${firstName}`;
    }

    if (name) {
        return name;
    }

    return lastName || firstName;
}
