// @flow

import React from 'react';
import { inject, observer } from 'mobx-react';
import {toJS} from 'mobx';

import Relation from 'dna/entity/common/relation';

const styles = {
    container: {
        position: 'relative',
        height: '0'
    },
    button: {
        position: 'absolute',
        top: '-20px',
        right: '5px',
        zIndex: '999'
    }
};

// @ASK for "levels" if it's still relevant
@inject(stores => ({
    entities: stores.deefind.results,
    levels: stores.deefind.levels,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.explorer.stack.relation';

    props: {
        entities: ?Map<string, any>,
        levels: ?Map<string, any>,
        source: string,
        target: string
    };

    render () {
        const levels = this.props.levels && this.props.levels.get(this.props.source);

        if (! levels || levels.relations < 1) {
            return null;
        }

        const source = this.props.entities && this.props.entities.get(this.props.source);
        const relations = source ? source.relations : undefined;

        if (! relations) {
            return null;
        }

        let relation = relations.persons ? relations.persons[this.props.target] : undefined;

        if (! relation) {
            relation = relations.companies ? relations.companies[this.props.target] : undefined;
        }

        if (! relation) {
            return null;
        }

        return (
            <div style={ styles.container }>
                <Relation
                    id={ this.props.source }
                    data={ relation }
                    style={ styles.button }
                    info="aside"
                />
            </div>
        );
    }
}
