// @flow

import React from 'react';
import Prospects from './prospects';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

@inject(stores => ({ store: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.management.dashboard';

    props: {
        style?: Object,
    };

    render () {
        return (
            <div style={ this.props.style }>
                <Prospects/>
            </div>
        );
    }
}
