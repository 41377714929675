import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';

import Header from './header';
import Tabs from './tabs';
import Agenda from './agenda';
import Library from './library';
import Report from './report';
import Start from './start';
import Survey from '../common/survey';
import OnSiteQuestions from '../common/onSite/questions';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import ScopeProvider from '../../../app/layout/providers/scope';

@inject(stores => ({ store: stores.deeligenzStudies, management: stores.deeligenzManagement }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.studies.display';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
        updateStudy: PropTypes.func.isRequired,
        removeStudy: PropTypes.func.isRequired,
        fetchStudy: PropTypes.func.isRequired,
        commentStudy: PropTypes.func.isRequired,
        applyStudy: PropTypes.func.isRequired,
        startStudy: PropTypes.func.isRequired,
        setStudyOnSite: PropTypes.func.isRequired,
        setStudyParagraph: PropTypes.func.isRequired,
        setStudyHeader: PropTypes.func.isRequired,
        setStudyRating: PropTypes.func.isRequired,
        answerField: PropTypes.func.isRequired,
        answerEditField: PropTypes.func.isRequired,
        applyField: PropTypes.func.isRequired,
        setFieldFlags: PropTypes.func.isRequired,
        setFieldRemark: PropTypes.func.isRequired,
        commentField: PropTypes.func.isRequired,
        getFileUrl: PropTypes.func.isRequired,
        downloadFile: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired,
        uploadFileMeeting: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    componentDidMount() {
        if (this.props.state && this.props.state.id) {
            this.context.fetchStudy(this.props.state.id);
        }
    }

    render() {
        if (!this.props.store || !this.props.state) {
            return null;
        }

        const entity = toJS(this.props.store.studies.get(this.props.state.id));
        const fetched = this.props.store.fetched;

        if (!entity || entity.payload.archive) {
            return null;
        }

        if (!fetched.includes(this.props.state.id) || !entity.fields) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div style={this.props.style}>
                <Header
                    entity={entity}
                    onAbort={event => this.context.removeStudy(entity.id)}
                    onUpdate={payload => this.context.updateStudy(entity.id, payload)}
                    onComment={(channel, comment) => this.context.commentStudy(entity.id, channel, comment)}
                    onApply={(transition, comment) => this.context.applyStudy(entity.id, transition, comment)}
                    onToggleLibrary={event => this.refs.library.toggle()}
                    onSetNda={this.props.onSetNda}
                    onReadNda={this.props.onReadNda}
                    onWriteNda={this.props.onWriteNda}
                    readOnly={entity.status === 'confirmed' || entity.status === 'ignoredRedFlag'}
                />

                {this.renderTabs(entity)}
                {this.renderContent(entity)}

                <ScopeProvider path="library" type="object">
                    <Library ref="library" entity={entity} />
                </ScopeProvider>
            </div>
        );
    }

    renderTabs(entity) {
        if (entity.status === 'draft') {
            return null;
        }

        return (
            <Tabs
                tab={this.props.state ? this.props.state.tab || 'input' : 'input'}
                onSiteView={this.props.state ? this.props.state.onSiteView || 'fields' : 'fields'}
                reportView={this.props.state ? this.props.state.reportView || 'overview' : 'overview'}
                entity={entity}
            />
        );
    }

    renderContent(entity) {
        if (entity.status === 'draft') {
            return (
                <Start
                    entity={entity}
                    templates={Object.values(toJS(this.props.store.templates))}
                    management={this.props.management}
                    onStart={async templateId => this.context.startStudy(entity.id, templateId)}
                />
            );
        }

        const tab = this.props.state ? this.props.state.tab || 'input' : 'input';
        const ongoing = entity.status === tab;

        switch (tab) {
            case 'input':
                return (
                    <ScopeProvider path="input" type="object" key="input">
                        <Survey
                            entity={entity}
                            scope="input"
                            onScope={(scope, flags) => ['both', 'input'].includes(scope)}
                            onApply={this.context.applyField}
                            onFlags={this.context.setFieldFlags}
                            onComment={this.context.commentField}
                            withStatus={true}
                            withAnalysis={false}
                            withRemark={false}
                            withCorrection={false}
                            readOnly={!ongoing}
                        />
                    </ScopeProvider>
                );

            case 'ddq':
                return (
                    <ScopeProvider path="ddq" type="object" key="ddq">
                        <Survey
                            entity={entity}
                            scope="ddq"
                            onScope={(scope, flags) => ['both', 'ddq'].includes(scope)}
                            onRemark={this.context.setFieldRemark}
                            onFlags={this.context.setFieldFlags}
                            onComment={this.context.commentField}
                            withAnalysis={true}
                            withStatus={true}
                            withRemark={true}
                            withCorrection={true}
                            onAnswer={this.context.answerField}
                            onCorrection={this.context.answerEditField}
                            readOnly={!ongoing && entity.status !== 'input'}
                        />
                    </ScopeProvider>
                );

            case 'onSite':
                switch (this.props.state ? this.props.state.onSiteView || 'fields' : 'fields') {
                    case 'fields':
                        return (
                            <ScopeProvider path="onSite" type="object" key="onSite">
                                <Survey
                                    entity={entity}
                                    scope="onSite"
                                    onScope={(scope, flags) =>
                                        ['both', 'ddq'].includes(scope) && flags && !!flags.onSite
                                    }
                                    onAnswer={this.context.answerField}
                                    onRemark={this.context.setFieldRemark}
                                    onFlags={this.context.setFieldFlags}
                                    onComment={this.context.commentField}
                                    withAnalysis={true}
                                    withRemark={true}
                                    withCorrection={true}
                                    onCorrection={this.context.answerEditField}
                                    readOnly={!ongoing}
                                />
                            </ScopeProvider>
                        );

                    case 'agenda':
                        return (
                            <ScopeProvider path="onSite" type="object" key="onSite">
                                <Agenda
                                    entity={entity}
                                    onChange={data => this.context.setStudyOnSite(entity.id, data)}
                                    onUpload={(file) => this.context.uploadFileMeeting(entity.id, file)}
                                    onFetchUpload={id => this.context.getFileUrl(id)}
                                    readOnly={entity.status === 'confirmed' || entity.status === 'ignoredRedFlag'}
                                />
                            </ScopeProvider>
                        );

                    case 'questions':
                        return (
                            <ScopeProvider path="onSite" type="object" key="onSite">
                                <OnSiteQuestions id={entity.id} readOnly={entity.status === 'confirmed' || entity.status === 'ignoredRedFlag'} />
                            </ScopeProvider>
                        );
                }
                break;

            case 'report':
                switch (this.props.state ? this.props.state.reportView || 'overview' : 'overview') {
                    case 'overview':
                        return (
                            <Report
                                entity={entity}
                                onOpenFields={event => this.context.updateState({ reportView: 'fields' })}
                                onSetParagraph={(key, content) =>
                                    this.context.setStudyParagraph(entity.id, key, content)
                                }
                                onSetHeader={header => this.context.setStudyHeader(entity.id, header)}
                                onSetRating={rating => this.context.setStudyRating(entity.id, rating)}
                                readOnly={entity.status === 'confirmed' || entity.status === 'ignoredRedFlag'}
                            />
                        );

                    case 'fields':
                        return (
                            <ScopeProvider path="report" type="object" key="report">
                                <Survey
                                    entity={entity}
                                    scope="report"
                                    onScope={(scope, flags) => ['both', 'ddq'].includes(scope) && !!flags.keyFinding}
                                    onRemark={this.context.setFieldRemark}
                                    onFlags={this.context.setFieldFlags}
                                    onComment={this.context.commentField}
                                    readOnly={!ongoing}
                                    withAnalysis={true}
                                />
                            </ScopeProvider>
                        );
                }
                break;
        }

        return null;
    }
}
