// @flow

import type { QueueStore } from '../core/types';
import type StateStore from 'app/stores/state';
import type DeefindStore from 'app/stores/deefind';
import type ReechStore from 'app/stores/reech';
import type { DeetectInput } from '@deecision/deefind-types/client';

import { action, observable, runInAction } from 'mobx';
import uuid from 'uuid';
import { normalizeDate } from './utils';

const PATH = 'dna.detector';

export default class DnaSelectorStore {
    queue: QueueStore;
    state: StateStore;
    deefind: DeefindStore;
    reech: ReechStore;
    entityWindow: any;

    constructor(queue: QueueStore, state: StateStore, deefind: DeefindStore, reech: ReechStore) {
        this.queue = queue;
        this.state = state;
        this.deefind = deefind;
        this.reech = reech;
    }

    @action addStage = (): void => {
        this.state
            .enter(PATH, 'menu')
            .assign(':next', {
                type: 'batch',
                step: 'index',
                id: uuid.v4(),
                source: 'person',
            })
            .select(':last');
    };

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action setStage = (state: Object): void => {
        this.state
            .enter(PATH, 'menu')
            .enter(':current', 'router')
            .update(state);
    };

    @action closeStage = (index: number): void => {
        const data = this.state
            .enter(PATH, 'menu')
            .enter(index)
            .read();

        if (data.step === 'work' && !confirm('Are your sure you want to remove this batch?')) {
            return;
        }

        this.queue.drop({ service: 'deefind', consumer: 'deetect.' + data.tool, batch: data.id });
        this.state.enter(PATH, 'menu').remove(index);
    };

    @action pushJobs = (rows: string[][]): void => {
        const node = this.state.enter(PATH, 'menu').enter(':current');
        const state = node.read();

        const inputs: DeetectInput[] = rows.map((row: string[]) => ({
            type: state.source,
            profile: this.makeProfile(state.source, row),
            row,
            deep: state.deep,
        }));

        this.queue.push({ service: 'deefind', consumer: 'deetect.' + state.tool, batch: state.id }, inputs);
        node.update({ step: 'work' });
    };

    @action openEntity = (id: string) => {
        this.state
            .enter('dna.explorer', 'menu')
            .assign(':next', { type: 'entity', entities: { cursor: 0, items: [{ id, open: true }] } })
            .select(':last');

        if (!this.entityWindow || this.entityWindow.closed) {
            const url = `${window.location.protocol}//${window.location.host}/dna/explorer`;
            this.entityWindow = window.open(url, 'entity');
        } else {
            this.entityWindow.focus();
            this.entityWindow.location.reload();
        }
    };

    makeProfile(type: string, row: string[]) {
        switch (type) {
            case 'person':
                return {
                    lastName: row[0] || undefined,
                    firstName: row[1] || undefined,
                    birthDate: normalizeDate(row[2]) || undefined,
                    identifier: row[3] || undefined,
                };

            case 'company':
                return {
                    name: row[0] || undefined,
                    identifier: row[1] || undefined,
                    lastName: row[2] || undefined,
                };

            default:
                return {};
        }
    }
}
