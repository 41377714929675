// @flow

import type { CountsType, SegmentAxis, SegmentAxes, AugmentedCountsType } from '../types';
import type { DeetectScores } from '@deecision/deefind-types/client';
import { sortBy } from 'lodash';

const casa = ['credit-agricole-sa.deecision.com', 'preprod-credit-agricole-sa.deecision.com'].includes(
    process.env.APP_DOMAIN || window.location.hostname,
);
const moneyLimit = casa ? [0.1e6, 3e6] : [1e6, 5e6];
const LIMITS = {
    risk: [2, 3],
    potential: moneyLimit,
    potentialCovid19: [40, 70],
    proximity: [4, 8],
    completeness: [25, 50],
    global: [25, 50],
    probability: [40, 70],
    potentialCashout: moneyLimit,
    expectedValue: moneyLimit,
    upsell: moneyLimit,
    upsellPrct: [50, 100],
};

const assignBankSegment = {
    retail: 0,
    private: 1,
    corporate: 2,
    wealth: 1,
};

export function makeAugmentedSegmentsCounts(
    counts: AugmentedCountsType,
    id: string,
    scores: DeetectScores,
    bankTypes: Object[],
    thresholds: Object[],
): void {
    if (
        typeof assignBankSegment[scores.committee] === 'undefined' ||
        typeof bankTypes[assignBankSegment[scores.committee]] === 'undefined'
    ) {
        return;
    }
    if (!scores.moveRight || scores.computedColumn < 1) {
        return;
    }
    scores.committeeDna = bankTypes[assignBankSegment[scores.committee]].label;
    scores.segmentDna = thresholds[scores.computedColumn].label;
    counts.committee[bankTypes[assignBankSegment[scores.committee]].label]++;
    const rankY = bankTypes[assignBankSegment[scores.committee]].order;
    const rankX = scores.computedColumn;
    const key = `segment${rankX}${rankY}`;
    counts[key].push(id);
}

export function makeSegmentsCounts(counts: CountsType, id: string, scores: DeetectScores, axes: SegmentAxes): void {
    const rankX = makeSegmentsRank(scores, axes.x);
    const rankY = makeSegmentsRank(scores, axes.y);

    const key = `segment${rankX}${rankY}`;
    counts[key].ids.push(id);

    switch (makeSegmentsRank(scores, axes.extra)) {
        case 1:
            counts[key].extra.low += 1;
            break;
        case 2:
            counts[key].extra.mid += 1;
            break;
        case 3:
            counts[key].extra.high += 1;
            break;
    }

    switch (makeSegmentsRank(scores, 'completeness')) {
        case 1:
            counts[key].completeness.low += 1;
            break;
        case 2:
            counts[key].completeness.mid += 1;
            break;
        case 3:
            counts[key].completeness.high += 1;
            break;
    }
}

function makeSegmentsRank(scores: DeetectScores, axis: SegmentAxis): 1 | 2 | 3 {
    const [a, b] = LIMITS[axis];
    let score = 0;

    switch (axis) {
        case 'risk':
            score = scores.risk || 0;
            break;

        case 'potential':
            score = scores.potential.value || 0;
            break;

        case 'proximity':
            score = scores.proximity || 0;
            break;

        case 'completeness':
            score = scores.potential.reliability || 0;
            break;

        case 'potentialCovid19':
            score = scores.potentialCovid19 ? scores.potentialCovid19.ratio || 0 : 0;
            break;

        case 'probability':
            score = scores.currentPrediction ? scores.currentPrediction.probability : 0;
            break;

        case 'potentialCashout':
            score = scores.currentPrediction ? scores.currentPrediction.potentialCashout : 0;
            break;

        case 'expectedValue':
            score = scores.currentPrediction ? scores.currentPrediction.expectedValue : 0;
            break;

        case 'upsell':
            score = scores.upsell ? scores.upsell.upsell : 0;
            break;

        case 'upsellPrct':
            score = scores.upsell ? scores.upsell.upsellPrct : 0;
            break;
    }

    if (score < a) {
        return 1;
    }

    if (score > b) {
        return 3;
    }

    return 2;
}
