import _ from 'lodash';
import React from 'react';

const styles = {
    container: {
        position: 'relative',
        paddingBottom: '20px',
        paddingTop: '10px',
        alignItems: 'center'
    },
    child: {
        display: 'inline-block',
        flexGrow: '0',
        flexShrink: '0',
        marginLeft: '10px',
        minHeight: '40px',
        fontSize: '15px',
        verticalAlign: 'middle'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.header.container';

    render () {
        return (
            <div style={ Object.assign({}, styles.container, { display: 'flex' }) }>
                { this.renderChildren() }
            </div>
        );
    }

    renderChildren () {
        let first = true;

        return _.filter(React.Children.map(this.props.children, (child) => {
            if (! child)
                return null;

            const element = React.cloneElement(child, {
                style: Object.assign({}, styles.child, first ? { marginLeft: '0' } : {}, child.props.style)
            });

            first = false;

            return element;
        }));
    }
}
