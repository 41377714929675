import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import News from 'dna/entity/common/news';
import Views from 'app/views';
import colors from 'app/theme/colors';
import CompliancePreview from 'dna/entity/common/compliance/preview';
import Columns from 'dna/entity/common/display/columns';
import Scope from 'app/layout/providers/scope';
import { ACTIVITIES } from 'dna/entity/aml';
import Country from 'dna/entity/common/country';
import { get } from 'lodash';
import t from 'app/translation';

const LABELS = {
    siret: 'SIRET',
    siren: 'SIREN',
    europeanVat: 'EU VAT',
};

const styles = {
    container: {
        lineHeight: '20px',
    },
    separator: {
        marginTop: '20px',
        marginRight: '20px',
    },
    wrapper: {
        marginTop: '20px',
        display: 'flex',
    },
    properties: {
        width: '40%'
    },
    news: {
        width: '60%'
    },
    amlFlag: {
        fontSize: '9px',
        lineHeight: '9px',
        background: colors.userHot,
        borderRadius: '3px',
        padding: '3px',
        display: 'inline-block',
        color: 'white',
        verticalAlign: 'text-bottom',
        marginRight: '5px',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.display.tabs.identity';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render () {
        if (! this.props.entity.data) {
            return null;
        }

        return (
            <div style={ styles.container }>
                <CompliancePreview entity={ this.props.entity } />

                <div style={ styles.wrapper }>
                    <div style={ styles.properties }>
                        { this.renderMain1() }
                        { this.renderMain2() }
                        { this.renderStrength(this.props.entity.data) }
                        { this.renderAuditors(this.props.entity.data) }
                    </div>

                    <div style={ styles.news }>
                        <Scope path="news" type="object">
                            <DeefindProvider
                                type="media"
                                id={this.props.entity.id}
                                renderEntity={(media, state) => (
                                    <News
                                        name={this.props.entity.preview.name}
                                        identifier={this.props.entity.id}
                                        news={media.news}
                                        state={state}
                                    />
                                )}
                            />
                        </Scope>
                    </div>
                </div>
            </div>
        );
    }

    renderMain1() {
        const preview = this.props.entity.preview || {};
        const data = this.props.entity.data || {};

        let activity;
        if (data.activities && data.activities.length > 0) {
            activity = t('common.activity.'+String(data.activities[0].code).toUpperCase());
        }

        const leftProps = [
            { label: t('common.label.activity'), value: activity },
            { label: 'Creation', value: preview.creation },
            { label: t('common.label.country'), value: <Country code={ preview.country } /> },
            { label: 'NACE', value: this.renderNaceCode(preview) },
        ];

        const rightProps = [
            { label: 'Standard', value: get(data, 'identity.legalForm.standard') },
            { label: 'National', value: get(data, 'identity.legalForm.national') },
        ];

        return (
            <Columns
                style={ styles.separator }
                left={ <Views.Common.Properties align={ 60 } values={ leftProps } /> }
                right={ <Views.Common.Properties title={'Legal form'} align={ 60 } values={ rightProps } /> }
            />
        );
    }

    renderMain2 () {
        const data = this.props.entity.data || {};

        const leftProps = [];
        const rightProps = [];

        for (const identifier of data.identifiers || []) {
            leftProps.push({
                label: identifier.label,
                value: (identifier.value || '').toUpperCase(),
            });
        }

        (data.addresses || []).map(contact => {
            if (contact.details && contact.details.length) rightProps.push({ label: 'Address', value: contact.details[0]});
            if (contact.zipCode) rightProps.push({ label: 'Zip code', value: contact.zipCode});
            if (contact.city) rightProps.push({ label: 'City', value: contact.city});
            if (contact.country) rightProps.push({ label: t('common.label.country'), value: t('common.country.' + String(contact.country).toUpperCase())});
            if (contact.email) rightProps.push({ label: 'Email', value: contact.email});
            if (contact.phone) rightProps.push({ label: 'Phone', value: contact.phone});
        });

        return (
            <Columns
                style={ styles.separator }
                left={ <Views.Common.Properties title={'Identifiers'} align={ 60 } values={ leftProps } /> }
                right={ <Views.Common.Properties title={'Head Office'} align={ 60 } values={ rightProps } /> }
            />
        );
    }

    renderStrength () {
        const data = this.props.entity.data || {};
        const strength = data ? data.strength : undefined;

        if (! strength) {
            return null;
        }

        return (
            <Views.Common.Properties
                align={ 270 }
                style={ styles.separator }
                values={[
                    { label: 'Probability of default (MORE / FALCON)', value: strength.defaultProbability.more + ' / ' + strength.defaultProbability.falcon },
                    { label: 'Credit limit (MORE / FALCON)', value: (strength.score.more || '—') + ' / ' + (strength.score.falcon || '—') },
                    { label: 'Financial stability (CRIF)', value: strength.stability.score },
                    { label: 'Propensity of bankruptcy (VADIS)', value: strength.bankruptcyRisk },
                    { label: 'Environmental risk (TRUCOST)', value: strength.environmentalRisk },
                ]}
            />
        );
    }

    renderAuditors () {
        const data = this.props.entity.data || {};
        const auditors = data ? data.auditors : undefined;

        if (! Array.isArray(auditors) || ! auditors.length) {
            return null;
        }

        return (
            <Views.Common.Properties
                align={ 110 }
                style={ styles.separator }
                values={[
                    { label: 'Known auditors', value: data.auditors.map(auditor => auditor.name).join(', ') },
                ]}
            />
        );
    }

    renderNaceCode () {
        const preview = this.props.entity.preview || {};
        const code = preview.naceCode;

        if (! ACTIVITIES[code]) {
            return code || '—';
        }

        return (
            <span>
                <span style={ styles.amlFlag }>AML</span>
                { code }
            </span>
        );
    }
}
