import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';

const styles = {
    button: {
        width: '250px',
        textAlign: 'left',
    },
    label: {
        textTransform: 'none',
        color: colors.black,
    },
    flag: {
        width: '24px',
        height: '18px',
        margin: '0 10px -5px 0',
    },
    empty: {
        color: colors.userCold,
        textAlign: 'center',
    },
    body: {
        display: 'flex',
        height: '400px',
    },
    top: {
        width: '50%',
        paddingRight: '10px',
        overflow: 'auto',
    },
    select: {
        width: '50%',
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    filter: {
        position: 'relative',
    },
    filterInput: {
        background: colors.grey200,
        border: '1px solid ' + colors.grey300,
        lineHeight: '24px',
        borderRadius: '3px',
        boxSizing: 'border',
        height: '36px',
        padding: '6px 35px',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        marginBottom: '10px',
        width: '100%',
    },
    filterIcon: {
        position: 'absolute',
        top: '6px',
        left: '6px',
        fill: colors.grey500,
    },
    filterClear: {
        position: 'absolute',
        top: '3px',
        right: '3px',
        background: colors.userHot,
        borderRadius: '3px',
        cursor: 'pointer',
        padding: '3px',
    },
    list: {
        height: '100%',
        overflow: 'auto',
    },
    option: {
        borderRadius: '3px',
        background: colors.white,
        color: colors.black,
        lineHeight: '18px',
        padding: '5px',
        cursor: 'pointer',
        fontSize: '13px',
    },
    optionTop: {
        padding: '10px',
        fontSize: '15px',
    },
    optionHover: {
        background: colors.grey300,
    },
    optionCurrent: {
        background: colors.userCold,
        color: colors.white,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.scalar.country';

    static propTypes = {
        readOnly: PropTypes.bool,
        value: PropTypes.string,
        onChange: PropTypes.func,
    };

    state = { mainHover: false, optionHover: null };

    render() {
        return [
            <div key="field" style={{ position: 'relative' }}>
                <Views.Common.Button
                    key="button"
                    type="flat"
                    color="cold"
                    label={this.renderLabel(this.props.value)}
                    style={styles.button}
                    disabled={this.props.readOnly}
                    onClick={event => this.refs.modal.open()}
                />
                <input
                    type="hidden"
                    style={{ position: 'absolute', zIndex: '-1', top: 0, left: 0 }}
                    required
                    value={this.props.value}
                />
            </div>,
            <Views.Modal.Show key="modal" title="Pick a country" ref="modal" style={{ width: '600px' }}>
                <div style={styles.body}>
                    <div style={styles.top}>{TOP_COUNTRIES.map(code => this.renderOption(code, true))}</div>

                    <div style={styles.select}>
                        <div style={styles.filter}>
                            <input
                                style={styles.filterInput}
                                value={this.state.filter || ''}
                                onChange={
                                    this.props.onChange
                                        ? event => this.setState({ filter: event.target.value.toLowerCase() })
                                        : null
                                }
                            />

                            <Icon style={styles.filterIcon} type="do.search" />

                            {this.state.filter ? (
                                <div style={styles.filterClear} onClick={event => this.setState({ filter: null })}>
                                    <Icon type="do.cancel" color="white" />
                                </div>
                            ) : null}
                        </div>

                        <div style={styles.list}>
                            {this.filterCountries().map(code => this.renderOption(code, false))}
                        </div>
                    </div>
                </div>
            </Views.Modal.Show>,
        ];
    }

    renderLabel() {
        const name = COUNTRIES[this.props.value];

        if (!name) {
            return this.props.readOnly ? '' : 'Pick a country';
        }

        return [
            <img
                key="flag"
                style={styles.flag}
                src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${this.props.value.toLowerCase()}.svg`}
            />,
            <span key="label" style={styles.label}>
                {name}
            </span>,
        ];
    }

    renderOption(code, top) {
        const style = Object.assign(
            {},
            styles.option,
            top ? styles.optionTop : {},
            this.state.optionHover === code ? styles.optionHover : {},
            this.props.value === code ? styles.optionCurrent : {},
        );

        return (
            <div
                key={code || '?'}
                style={style}
                onMouseEnter={event => this.setState({ optionHover: code })}
                onMouseLeave={event => this.setState({ optionHover: null })}
                onClick={event => {
                    this.props.onChange(code);
                    this.refs.modal.close();
                }}
            >
                <img
                    style={styles.flag}
                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${code.toLowerCase()}.svg`}
                />

                <span style={styles.label}>{COUNTRIES[code] || '?'}</span>
            </div>
        );
    }

    filterCountries() {
        let countries = Object.keys(COUNTRIES);

        if (this.state.filter) {
            countries = countries.filter(
                code => COUNTRIES[code] && COUNTRIES[code].toLowerCase().indexOf(this.state.filter) > -1,
            );
        }

        return countries;
    }
}

const TOP_COUNTRIES = ['US', 'GB', 'FR', 'DE', 'NL', 'BE', 'ES', 'LU', 'SE', 'DK'];

export const COUNTRIES = {
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua And Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia And Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, Democratic Republic',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Cote D'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island & Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic Of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle Of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States Of',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Reunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthelemy',
    SH: 'Saint Helena',
    KN: 'Saint Kitts And Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre And Miquelon',
    VC: 'Saint Vincent And Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome And Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia And Sandwich Isl.',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard And Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad And Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks And Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis And Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
};
