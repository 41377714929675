// @flow

import { Provider as StoresProvider } from 'mobx-react';
import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import Security from 'app/layout/security';
import ThemeProvider from 'app/theme/provider';
import Stores from 'app/stores';

import Dna from 'dna';
import Comeet from 'comeet';
import Teem from 'teem';
import Deeligenz from 'deeligenz';
import Cleerance from 'cleerance';
import PropTypes from 'prop-types';

export default class Deecision extends React.PureComponent<any, any> {
    static displayName = 'app';

    static propTypes = {
        sdk: PropTypes.object.isRequired,
    };

    render() {
        const history = createBrowserHistory();
        const stores = Stores(this.props.sdk, history);

        return (
            <Router history={history}>
                <StoresProvider {...stores}>
                    <ThemeProvider>
                        <Security ref="security" auth={stores.auth}>
                            <Switch>
                                <Route path="/dna" component={Dna} />
                                <Route path="/comeet" component={Comeet} />
                                <Route path="/deeligenz" component={Deeligenz} />
                                <Route path="/teem" component={Teem} />
                                <Route path="/cleerance" component={Cleerance} />
                                <Redirect from="/" to="/dna/explorer" />
                            </Switch>
                        </Security>
                    </ThemeProvider>
                </StoresProvider>
            </Router>
        );
    }
}
