import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';
import get from 'lodash/get';
import t from 'app/translation';

let styles = {
    table: {
        width: '100%'
    },
    header: {
        padding: '10px 5px',
        color: colors.grey500
    },
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.result.typology.303.row';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired
    };

    static propTypes = {
        entity: PropTypes.object,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        style: PropTypes.object,
    };

    render () {

        const cellProps = {
            onClick: event => this.handleClick(),
            onMouseEnter: this.props.onMouseEnter,
            onMouseLeave: this.props.onMouseLeave,
            style: Object.assign({}, styles.cell, { cursor: 'pointer'  }),
        };

        const firstCellProps = {
            onClick: event => open(this.props.entity.file.source.url),
            onMouseEnter: this.props.onMouseEnter,
            onMouseLeave: this.props.onMouseLeave,
            style: Object.assign({}, styles.cell, { cursor: 'help' }),
        };

        return (
            <tr style={ this.props.style }>
                <td { ...firstCellProps } title={get(this.props.entity, 'file.subheading', '-')}>{ get(this.props.entity, 'file.heading', '-') }</td>
                <td { ...cellProps }>{ get(this.props.entity, 'target.type', '-') }</td>
                <td { ...cellProps }>{ get(this.props.entity, 'target.name',  get(this.props.entity, 'file.subheading', '-')) }</td>
                <td { ...cellProps }>{ this.renderStatus() }</td>
            </tr>
        );
    }

    renderStatus() {
        const relevance = get(this.props.entity, 'relevance', 0);

        if (get(this.props.entity, 'target.id')) {
            if (relevance >= 75) {
                return 'Perfect match';
            } else {
                return `Match (${relevance}%)`;
            }
        }

        const ids = get(this.props.entity, 'target.ids');

        if (ids && ids.length > 0) {
            return `${relevance >= 75 ? 'Multiple' : 'Ambiguous'} (${ids.length})`;

            if (relevance >= 75) {
                return 'Multiple';
            } else {
                return 'Ambiguous';
            }
        }

        return t('common.label.noMatch');
    }

    handleClick() {
        const id = get(this.props.entity, 'target.id');

        if (id) {
            return this.context.openEntity(id);
        }

        const ids = get(this.props.entity, 'target.ids');

        if (ids && ids.length) {
            return this.context.openEntity(ids[0]);
        }

        // open file
    }
}
