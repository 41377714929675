// @flow

import type { CompanyPreview, EntityInnerTarget, EntityType, PersonPreview } from '@deecision/deefind-types/model';

import get from 'lodash/get';

const optional = [
    'country',
    // company
    'turnover',
    'capital',
    'equity',
    'ebitda',
    'valuation',
    'activity',
    'creation',
    'closed',
    'funded',
    'employees',
    // person
    'firstName',
    'lastName',
    'fortune',
    'income',
    'weasse',
    'weasseMax ',
    'turnoversSum ',
    'ebitdasSum',
    'capitalsSum',
    'valuationsSum',
    'deceased',
];

export function makeInnerTarget(
    id: string,
    type: EntityType,
    preview: ?(CompanyPreview | PersonPreview),
    label?: string
): EntityInnerTarget {
    const target: Object = {
        id,
        type,
        name: get(preview, 'name', label || ''),
        flags: get(preview, 'flags', []),
        identifiers: get(preview, 'identifiers', []),
        isins: get(preview, 'isins', []),
        links: get(preview, 'mandates', 0) + get(preview, 'positions', 0),
    };

    for (const field of optional) {
        const value = get(preview, field);

        if (value) {
            target[field] = value;
        }
    }

    return target;
}
