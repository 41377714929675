// @flow

import studiesScope from './studies/scope';
import managementScope from './management/scope';
import calendarScope from './calendar/scope';
import archivesScope from './archives/scope';
import libraryScope from './library/scope';

export default {
    type: 'object',
    children: {
        studies: studiesScope,
        management: managementScope,
        calendar: calendarScope,
        archives: archivesScope,
        library: libraryScope,
    }
};
