import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Context from './context';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.container';

    static childContextTypes = {
        form: PropTypes.object.isRequired
    };

    static propTypes = {
        data: PropTypes.object,
        style: PropTypes.object,
        onChange: PropTypes.func
    };

    constructor (props) {
        super(props);

        const context = Context(this.props.data);

        context.onFocus(focus => this.forceUpdate());
        context.onChange(data => {
            if (props.onChange) {
                props.onChange(data);
            }

            this.forceUpdate();
        });

        this.state = { context };
    }

    getChildContext () {
        return { form: this.state.context };
    }

    getData () {
        return this.state.context.getData();
    }

    hasError () {
        return this.state.context.countErrors() > 0;
    }

    setData (data) {
        this.state.context.reset(data);
        this.forceUpdate();
    }

    render () {
        let children = React.Children.toArray(this.props.children);

        if (! children.length)
            return null;

        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                {
                    _.map(children, (child, index) => React.cloneElement(child, {
                        key: child.key || child.path || index,
                        style: Object.assign({}, child.style, index > 0 ? { marginTop: '-1px' } : {}),
                    }))
                }
            </div>
        );
    }
}
