import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';

export default class extends React.Component<any, any> {
    static displayName = 'layout.application.header.members.add';

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    open() {
        this.refs.dialog.open();
    }

    render() {
        return (
            <Views.Modal.Edition
                ref="dialog"
                data={{ level: 'member' }}
                label="Add attendee"
                onSubmit={this.props.onSubmit}
            >
                <Views.Form.Row>
                    <Views.Form.Field
                        label="Email address"
                        path="email"
                        widget={{ type: 'string', pattern: 'email' }}
                        required
                        size={3}
                    />

                    <Views.Form.Field label="First name" path="firstName" widget="string" required size={2} />
                </Views.Form.Row>

                <Views.Form.Row>
                    <Views.Form.Field
                        label="Membership level"
                        path="level"
                        widget={{
                            type: 'radios',
                            choices: { member: 'Member' /*, manager: 'Manager', director: 'Director'*/ },
                        }}
                        size={3}
                    />
                    <Views.Form.Field label="Last name" path="lastName" widget="string" required size={2} />
                </Views.Form.Row>
            </Views.Modal.Edition>
        );
    }
}
