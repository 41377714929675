import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Documents from '../common/documents';

const styles = {
    split: {
        position: 'relative',
        zIndex: '999',
    },
    float: {
        position: 'absolute',
        top: '-17.5px',
        right: '60px',
    },
    close: {
        position: 'absolute',
        top: '-17.5px',
        right: '10px',
    },

    wrapper: {
        background: 'white',
        position: 'fixed',
        paddingBottom: '10px',
        bottom: '0',
        left: '0',
        right: '0',
    },
    handle: {
        height: '10px',
        borderTop: '5px solid ' + colors.userCold,
        cursor: 'row-resize',
        opacity: '0.5',
    },
    body: {
        width: '1400px',
        margin: '0 auto',
        zIndex: '999',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.display.library';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
        commentField: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object,
    };

    state = { display: 'none' };

    constructor(props) {
        super(props);
        this.portalContainer = window.document.createElement('div');
        this.portalWindow = null;
        this.portalOpen = false;
    }

    toggle() {
        this.setState({ display: this.state.display === 'none' ? 'split' : 'none' });
    }

    componentDidUpdate() {
        if (this.state.display === 'float' && !this.portalOpen) {
            this.portalWindow = window.open('', '', makeWindowFeatures());
            copyStyles(window.document, this.portalWindow.document);
            this.portalWindow.document.body.appendChild(this.portalContainer);
            this.portalOpen = true;
        }

        if (this.state.display !== 'float' && this.portalOpen) {
            this.portalWindow.close();
            this.portalOpen = false;
        }
    }

    componentWillUnmount() {
        if (this.portalWindow) {
            this.portalWindow.close();
        }

        if (this.portalContainer) {
            this.portalContainer.remove();
        }

        this.portalOpen = false;
    }

    render() {
        switch (this.state.display) {
            case 'split':
                return this.renderSplit();

            case 'float':
                return ReactDOM.createPortal(this.renderBody('100%'), this.portalContainer);

            default:
                return null;
        }
    }

    renderSplit() {
        const size = this.props.state ? this.props.state.size || 300 : 300;

        return (
            <div
                style={Object.assign({}, styles.split, { height: size + 'px' })}
                onMouseEnter={event => (window.document.body.style.overflow = 'hidden')}
                onMouseLeave={event => (window.document.body.style.overflow = 'auto')}
            >
                <div style={styles.wrapper}>
                    <div ref="handle" style={styles.handle} onMouseDown={this.handleResize.bind(this)} />

                    <Views.Common.Button
                        style={styles.float}
                        type="round"
                        color="cold"
                        icon={<Icon type="base.companion.open" />}
                        onClick={event => {
                            window.document.body.style.overflow = 'auto';
                            this.setState({ display: 'float' });
                        }}
                        mini
                    />

                    <Views.Common.Button
                        style={styles.close}
                        type="round"
                        color="hot"
                        icon={<Icon type="do.close" />}
                        onClick={event => {
                            window.document.body.style.overflow = 'auto';
                            this.setState({ display: 'none' });
                        }}
                        mini
                    />

                    {this.renderBody(size + 'px')}
                </div>
            </div>
        );
    }

    renderBody(height) {
        return (
            <div style={styles.body}>
                <Documents
                    ref="target"
                    state={this.props.state}
                    entity={this.props.entity}
                    onComment={this.context.commentField}
                    onUpdateState={data => this.context.updateState(data)}
                    style={{ height }}
                />
            </div>
        );
    }

    handleResize(event) {
        const startSize = this.props.state ? this.props.state.size || 300 : 300;
        const resizeStart = event.screenY;
        const targetNode = ReactDOM.findDOMNode(this.refs['target']);
        const handleNode = ReactDOM.findDOMNode(this.refs['handle']);
        const computeSize = y => Math.max(100, Math.min(800, startSize + resizeStart - y));

        const onMouseMove = event => {
            event.preventDefault();
            event.stopPropagation();

            if (targetNode) {
                targetNode.style.height = computeSize(event.screenY) + 'px';
            }
        };

        const onMouseUp = event => {
            event.preventDefault();
            event.stopPropagation();
            handleNode.style.opacity = 0.5;
            window.document.removeEventListener('mousemove', onMouseMove, true);
            window.document.removeEventListener('mouseup', onMouseUp, true);
            this.context.updateState({ size: computeSize(event.screenY) });
        };

        handleNode.style.opacity = 1;
        window.document.addEventListener('mousemove', onMouseMove, true);
        window.document.addEventListener('mouseup', onMouseUp, true);
    }
}

function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
        // if (styleSheet.cssRules) { // for <style> elements
        //     const newStyleEl = sourceDoc.createElement('style');
        //
        //     Array.from(styleSheet.cssRules).forEach(cssRule => {
        //         // write the text of each rule into the body of the style element
        //         newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        //     });
        //
        //     targetDoc.head.appendChild(newStyleEl);
        // } else

        if (styleSheet.href) {
            // for <link> elements loading CSS from a URL
            const newLinkEl = sourceDoc.createElement('link');

            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            targetDoc.head.appendChild(newLinkEl);
        }
    });
}

function makeWindowFeatures() {
    const clientWidth =
        window.innerWidth || window.document.documentElement.clientWidth || window.document.body.clientWidth;
    const clientHeight =
        window.innerHeight || window.document.documentElement.clientHeight || window.document.body.clientHeight;
    const windowLeft = window.screenX || window.screenLeft;
    const windowTop = window.screenY || window.screenTop;

    const width = 1420;
    const height = clientHeight - 300;
    const left = windowLeft + (clientWidth - width) / 2;
    const top = windowTop + 250;

    return `width=${width},height=${height},left=${left},top=${top},dependant,alwaysRaised,modal,dialog,location=no`;
}
