// @flow

import type { StockChild, StockItem, StockStats } from '@deecision/deefind-types/model';

import omit from 'lodash/omit';

export default function makeStockCashout(children: StockChild[] | StockStats): StockItem[] {
    if (!Array.isArray(children) || !children.length) {
        return [];
    }

    const items = [];

    function resolve(child: StockChild): StockItem {
        return omit(child, ['children']);
    }

    for (const child of children) {
        if (
            child.direct &&
            (child.ceased || (child.target && child.target.type === 'company' && !!child.target.closed))
        ) {
            items.push(resolve(child));
        }
    }

    return items;
}
