import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Scope from 'app/layout/providers/scope';
import Card from 'dna/entity/card';
import Clusters from 'dna/entity/common/display/clusters';
import get from 'lodash/get';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject('reech')
@observer
export default class extends React.Component<any, any> {

    static displayName = 'dna.entity.person.display.tabs.mandates';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
        const entities = this.props.network.relations.companies.map(item => Object.assign({}, item, {id: item.target.id}));
        const reechCustomers = this.props.reech.customers;
        const reechProspects = this.props.reech.prospects;
        const reechKnown = this.props.reech.known;
        const reechCount = reechKnown.length;

        return (
            <Scope path="companies" type="object">
                <div>
                    <Clusters list={get(this.props, 'network.clusters.companies')} />

                    <Scope path="list" type="list">
                        <Views.List.Container entities={entities}>
                            <Views.List.Frame
                                type="cards"
                                element={ <Card parent={ this.props.entity } /> }
                            />

                            <Views.List.Reducer
                                type="sort"
                                specs={{
                                    relation: {
                                        label: 'Rel. strength',
                                        path: (item) => item.strength || 0,
                                        type: 'desc'
                                    },
                                    name: {
                                        label: 'Name',
                                        path: 'target.name'
                                    },
                                    valuation: {
                                        label: 'Valuation',
                                        path: (item) => get(item, 'target.valuation.value') || 0,
                                        type: 'desc'
                                    },
                                    turnover: {
                                        label: 'Turnover',
                                        path: (item) => get(item, 'target.turnover') || 0,
                                        type: 'desc'
                                    },
                                    ebitda: {
                                        label: 'Ebitda',
                                        path: (item) => get(item, 'target.ebitda') || 0,
                                        type: 'desc'
                                    },
                                    capital: {
                                        label: 'Capital',
                                        path: (item) => get(item, 'target.capital') || 0,
                                        type: 'desc'
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="current"
                                title="Current"
                                type="groups"
                                value="yes"
                                specs={{
                                    yes: {
                                        label: '✓',
                                        info: 'Current relations',
                                        filter: (item) => !item.ceased
                                    },
                                    no: {
                                        label: '✕',
                                        info: 'Ceased relations',
                                        filter: (item) => !!item.ceased
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="reech"
                                type="groups"
                                title="Reech"
                                specs={{
                                    any: {
                                        label: '✓',
                                        info: 'Any Reech match',
                                        filter: item => reechKnown.includes(item.id),
                                    },
                                    customer: {
                                        label: 'C',
                                        info: 'Customer match',
                                        filter: item => reechCustomers.includes(item.id),
                                    },
                                    prospect: {
                                        label: 'P',
                                        info: 'Prospect match',
                                        filter: item => reechProspects.includes(item.id),
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                name="mandate"
                                type="groups"
                                title="Relation type"
                                specs={{
                                    sha: {
                                        label: 'Sh',
                                        info: 'Shareholders',
                                        filter: (item) => item.level === 's' || item.level === 'i'
                                    },
                                    boa: {
                                        label: 'Bo',
                                        info: 'Board members',
                                        filter: (item) => item.level === 'b'
                                    },
                                    adv: {
                                        label: 'Ad',
                                        info: 'Advisors',
                                        filter: (item) => item.level === 'a'
                                    },
                                    cou: {
                                        label: 'Co',
                                        info: 'Counselor',
                                        filter: (item) => item.level === 'c'
                                    },
                                    exe: {
                                        label: 'Ex',
                                        info: 'Executive',
                                        filter: (item) => item.level === 'x'
                                    },
                                    man: {
                                        label: 'Ma',
                                        info: 'Managers',
                                        filter: (item) => item.level === 'm'
                                    },
                                    beneficial: {
                                        label: 'BO',
                                        info: 'Beneficial owners',
                                        filter: (item) => item.owned && item.owned.beneficial
                                    }
                                }}
                            />

                            <Views.List.Reducer
                                name="compliance"
                                type="groups"
                                title={t('common.label.compliance')}
                                specs={{
                                    listed: {
                                        label: 'Li',
                                        info: 'Listed companies',
                                        filter: item => item.target.flags.includes('listed')
                                    },
                                    holding: {
                                        label: 'Ho',
                                        info: 'Holding companies',
                                        filter: item => item.target.flags.includes('holding')
                                    },
                                    fund: {
                                        label: 'Fu',
                                        info: 'Funds companies',
                                        filter: item => item.target.flags.includes('fund')
                                    },
                                    justice: {
                                        label: 'Ju',
                                        info: 'Justice issues',
                                        filter: item => item.target.flags.includes('justice')
                                    },
                                    politic: {
                                        label: 'Po',
                                        info: 'Politically exposed',
                                        filter: item => item.target.flags.includes('politic')
                                    },
                                    c19: {
                                        label: 'c19',
                                        info: 'Sensitivity to covid19 crisis',
                                        filter: item => (item.target.flags || []).includes('crisis-impact') || (item.target.flags || []).includes('crisis-direct'),
                                    },
                                }}
                            />

                            <Views.List.Reducer
                                type="fuse"
                                specs={{ paths: [ 'target.name' ] }}
                            />
                        </Views.List.Container>
                   </Scope>
                </div>
             </Scope>
        );
    }
}
