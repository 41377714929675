import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Cards from './body/cards';
import Frame from './body/frame';
import Table from './body/table';

const styles = {
    container: {},
    empty: {},
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.body';

    static propTypes = {
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        extra: PropTypes.object.isRequired,
        blocks: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
        frames: PropTypes.arrayOf(PropTypes.object).isRequired,
        onPaginate: PropTypes.func.isRequired,
    };

    render () {
        if (! this.props.entities.length || ! this.props.frames.length) {
            return (
                <div style={ styles.empty }>
                    { this.renderBlocks('empty') }
                </div>
            );
        }

        return (
            <div style={ styles.container }>
                { this.props.frames.map(this.renderFrame.bind(this)) }
                { this.renderBlocks('below') }
            </div>
        );
    }

    renderBlocks (position) {
        const blocks = this.props.blocks[ position ];

        if (! Array.isArray(blocks)) {
            return null;
        }

        return blocks.map((child, index) => React.cloneElement(child, { key: 'b' + index }));
    }

    renderFrame (frame, index) {
        const entities = frame.filter
            ? _.filter(this.props.entities, frame.filter)
            : this.props.entities;

        if (! entities.length) {
            return null;
        }

        const frameProps = {
            key: 'f' + index,
            title: frame.title,
            folded: frame.folded || false,
            entities: entities,
            size: frame.size || 45,
            page: frame.page || 1,
            onPaginate: page => this.props.onPaginate(frame.scope, page),
        };

        const listProps = {
            element: frame.element,
            extra: this.props.extra,
            loading: frame.loading,
            missing: frame.missing,
            specs: frame.specs,
        };

        switch (frame.type) {
            case 'cards': return (
                <Frame { ...frameProps }>
                    <Cards { ...listProps } />
                </Frame>
            );

            case 'table': return (
                <Frame { ...frameProps }>
                    <Table { ...listProps } />
                </Frame>
            );

            default: console.error('Invalid list frame type:', frame.type);
        }
    }
}
