// @flow

import adminScope from './admin/scope';

export default {
    type: 'object',
    children: {
        admin: adminScope,
    }
};
