// @flow

import type { SdkService } from '@deecision/infra-types/client';
import type { StoreIndex } from './types';

import Global from './global';
import Model from './model';
import State from './state';
import Queue from './queue';
import Drive from './drive';

export default function (sdk: SdkService, history: Object): StoreIndex {
    return {
        global: Global(sdk, history),
        model: Model(sdk),
        state: State(sdk),
        queue: Queue(sdk),
        drive: Drive(sdk),
    };
}
