import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import InputStatus from '../../common/status/input';
import OnSiteStatus from '../../common/status/onSite';

const ENTRIES = [
    {
        status: 'input',
        label: 'Questions',
        icon: 'deeligenz.survey.input',
    },
    {
        status: 'onSite',
        label: 'Visit',
        icon: 'deeligenz.survey.onSite',
    },
];

const styles = {
    container: {
        marginBottom: '40px',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        position: 'relative',
    },
    label: {
        display: 'inline-block',
        marginRight: '20px',
    },
    item: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '20%',
        padding: '10px',
        cursor: 'pointer',
        color: colors.grey600,
        borderLeft: '1px solid ' + colors.grey200,
        borderBottom: '2px solid transparent',
    },
    current: {
        background: colors.grey100,
        borderBottom: '2px solid ' + colors.userHot,
        cursor: 'default',
    },
    hover: {
        background: colors.grey100,
    },
    icon: {
        display: 'inline-block',
        marginRight: '10px',
        verticalAlign: 'middle',
        fill: colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.external.tabs';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        tab: PropTypes.string,
        entity: PropTypes.object.isRequired,
    };

    state = { hovered: null };

    render() {
        return (
            <div style={styles.container} onMouseLeave={event => this.setState({ hovered: false })}>
                {ENTRIES.map(this.renderEntry.bind(this))}
            </div>
        );
    }

    renderEntry(entry: Object, index: number) {
        let style = styles.item;

        if (index === 0) style = Object.assign({}, style, { borderLeftWidth: '0' });

        if (entry.status === this.props.tab) style = Object.assign({}, style, styles.current);
        else if (entry.status === this.state.hovered) style = Object.assign({}, style, styles.hover);

        return (
            <a
                key={entry.status}
                style={style}
                onMouseEnter={event => this.setState({ hovered: entry.status })}
                onClick={event => this.context.updateState({ tab: entry.status })}
            >
                <Icon type={entry.icon} style={styles.icon} />
                <span style={styles.label}>{entry.label}</span>

                {entry.status === 'input' ? <InputStatus entity={this.props.entity} /> : null}
                {entry.status === 'onSite' ? <OnSiteStatus entity={this.props.entity} /> : null}
            </a>
        );
    }

    renderStatus(type: string) {
        if (type === 'input') {
            return <InputStatus entity={this.props.entity} />;
        }
    }
}
