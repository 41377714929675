import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import colors from 'app/theme/colors';
import Tabs from 'dna/entity/common/display/tabs';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import t from 'app/translation';

const styles = {
    relationsLabel: {
        color: colors.grey700,
        textTransform: 'uppercase',
    },
    relationsLoading: {
        color: colors.grey500,
    },
    directCount: {
        color: colors.userHot,
    },
    totalCount: {
        color: colors.grey500,
    },
};

@inject('deefind')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.company.display.tabs.header';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
        loads: PropTypes.object,
    };

    state = { financialViewHover: undefined };

    render() {
        const id = this.props.entity.id;

        const network = this.props.deefind.results.get(`network:${id}`);
        const deals = this.props.deefind.results.get(`deals:${id}`);
        const stocks = this.props.deefind.results.get(`stocks:${id}`);

        return (
            <Tabs
                cursor={this.props.state.tab || 0}
                items={[
                    {
                        icon: 'dna.identity',
                        label: t('dna.deefind.veegilenzData.common.tabTitle.identity'),
                    },
                    {
                        icon: 'dna.financial',
                        label: t('dna.deefind.veegilenzData.common.tabTitle.financial'),
                        progress: this.props.deefind.progress.get(`stocks:${id}`),
                        time: this.props.deefind.times.get(`stocks:${id}`),
                    },
                    {
                        icon: 'dna.financial.tree',
                        label: this.renderHoldersLabel(toJS(stocks)),
                        progress: this.props.deefind.progress.get(`stocks:${id}`),
                        time: this.props.deefind.times.get(`stocks:${id}`),
                    },
                    {
                        icon: 'dna.contacts',
                        label: this.renderContactsLabel(network),
                        progress: this.props.deefind.progress.get(`network1:${id}`),
                        time: this.props.deefind.times.get(`network1:${id}`),
                    },
                    {
                        icon: 'dna.deals',
                        label: 'Deals',
                        count: deals ? (deals.asVendor.length + deals.asAcquiror.length + deals.asTarget.length + deals.asRegulator.length) : 0,
                        progress: this.props.deefind.progress.get(`deals:${id}`),
                        time: this.props.deefind.times.get(`deals:${id}`),
                    },
                ]}
                onChange={index => this.context.updateState({ tab: index })}
            />
        );
    }

    renderContactsLabel(network) {
        if (!network) {
            return <span style={styles.relationsLabel}>{t('dna.deefind.company.contactsFilter')}</span>;
        }

        const contacts = network.relations.companies
            .concat(network.relations.persons);

        return (
            <span>
                <span style={styles.relationsLabel}>{t('dna.deefind.company.contactsFilter')}</span>
                &nbsp;&nbsp;&nbsp;
                <span style={styles.directCount}>{contacts.filter(item => !item.ceased).length}</span>
                &nbsp;
                <span style={styles.totalCount}>{'/ ' + contacts.length}</span>
            </span>
        );
    }

    renderHoldersLabel(stocks) {
        if (!stocks) {
            return <span style={styles.relationsLabel}>Hold. / Subsid.</span>;
        }

        const c = stocks.children
            ? Array.isArray(stocks.children)
                ? stocks.children.length
                : stocks.children.total
            : 0;

        const p = stocks.parents
            ? Array.isArray(stocks.parents)
                ? stocks.parents.length
                : stocks.parents.total
            : 0;

        return (
            <span>
                <span style={styles.relationsLabel}>Hold. / Subsid.</span>
                &nbsp;&nbsp;&nbsp;
                <span style={styles.directCount}>{c + p}</span>
            </span>
        );
    }
}
