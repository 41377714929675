import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'absolute',
        top: '0',
        borderLeft: '1px solid ' + colors.grey500,
        color: colors.grey500,
        paddingLeft: '5px',
        fontSize: '13px',
        lineHeight: '13px',
        height: '15px',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.header.label';

    static propTypes = {
        position: PropTypes.number.isRequired,
        content: PropTypes.string.isRequired
    };

    render () {
        const style = Object.assign({}, styles.container, {
            left: this.props.position + '%',
        });

        return (
            <div style={ style }>
                { this.props.content }
            </div>
        );
    }
}
