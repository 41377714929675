import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Progress from 'material-ui/CircularProgress';

let styles = {
    container: {
        marginBottom: '40px',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        position: 'relative'
    },
    item : {
        flexGrow: 1,
        display: 'inline-block',
        position: 'relative',
        width: '20%',
        padding: '10px',
        cursor: 'pointer',
        color: colors.grey600,
        textTransform: 'uppercase',
        borderLeft: '1px solid ' + colors.grey200
    },
    current: {
        background: colors.grey100,
        borderBottom: '2px solid ' + colors.userHot,
        color: colors.userCold,
        cursor: 'default'
    },
    hover: {
        background: colors.grey100,
        color: colors.grey700,
    },
    icon: {
        display: 'inline-block',
        marginRight: '10px',
        verticalAlign: 'middle',
        fill: colors.grey500
    },
    count: {
        display: 'inline-block',
        marginLeft: '10px',
        color: colors.userHot
    },
    load: {
        position: 'absolute',
        top: '14px',
        right: '14px',
        width: '16px',
        height: '16px',
        fill: colors.grey500,
    },
    status: {
        position: 'absolute',
        top: '50px',
        left: '0',
        right: '0',
        textAlign: 'center',
        color: colors.grey500,
        fontSize: '13px',
        textTransform: 'none',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.display.tabs';

    static propTypes = {
        cursor: PropTypes.number.isRequired,
        items: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired
    };

    constructor (props) {
        super(props);
        this.state = { hovered: null };
    }

    render () {
        return (
            <div style={ styles.container } onMouseLeave={ event => this.setState({ hovered: null }) }>
                { this.props.items.map(this.renderItem.bind(this)) }
            </div>
        );
    }

    renderItem (item: any, index: any) {
        let style = styles.item;

        if (index === 0)
            style = Object.assign({}, style, { borderLeftWidth: '0' });

        if (index === this.props.cursor)
            style = Object.assign({}, style, styles.current);

        else if (index === this.state.hovered)
            style = Object.assign({}, style, styles.hover);

        return (
            <a
                key={ index }
                style={ style }
                onMouseEnter={ event => this.setState({ hovered: index }) }
                onClick={ event => this.props.onChange(index) }
            >
                <Icon type={ item.icon } style={ styles.icon } />
                <span style={ styles.label }>{ item.label }</span>
                { this.renderCount(item) }
                { this.renderLoad(item) }
                { this.renderStatus(item) }
            </a>
        );
    }

    renderCount (item: Object) {
        if (isNaN(item.count) || typeof item.count !== 'number' || ! item.count) {
            return null;
        }

        return (
            <span style={ styles.count }>
                { item.count }
            </span>
        );
    }

    renderLoad (item: Object) {
        if (item.time) {
            return (
                <Icon
                    style={ styles.load }
                    type="base.status.success"
                />
            );
        }

        if (!item.progress) {
            return null;
        }

        const percent = item.progress.percent;

        if (typeof percent === 'number') {
            return (
                <Progress
                    style={ styles.load }
                    value={ percent }
                    mode="determinate"
                    size={ 16 }
                    thickness={ 2 }
                    color={ colors.userCold }
                />
            );
        }

        return (
            <Progress
                style={ styles.load }
                mode="indeterminate"
                size={ 16 }
                thickness={ 2 }
                color={ colors.userCold }
            />
        );
    }

    renderStatus (item: Object) {
        if (typeof item.time === 'number' && item.time > 5) {
            const seconds = item.time % 60;
            const minutes = Math.floor(item.time / 60);

            return (<span style={ styles.status }>Time: {minutes ? minutes + 'min ' : ''}{seconds + 's'}</span>);
        }

        if (!item.progress) {
            return null;
        }

        const percent = item.progress.percent;
        const queue = item.progress.queue;

        if (typeof percent === 'number') {
            return (<span style={ styles.status }>Loading: {percent}%</span>);
        }

        if (typeof queue === 'number') {
            return (<span style={ styles.status }>Queued: {queue}</span>);
        }

        return (<span style={ styles.status }>Requested ...</span>);
    }
}
