import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Moment from 'moment';
import union from 'lodash/union';
import difference from 'lodash/difference';

const styles = {
    container: {
        flexGrow: '1',
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
    },
    empty: {
        flexGrow: '1',
        textAlign: 'center',
        lineHeight: '40px',
        color: colors.grey500,
    },
    header: {
        background: colors.grey100,
        color: colors.userCold,
        display: 'flex',
        fontSize: '13px',
    },
    attendee: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey300,
        fontSize: '13px',
    },
    name: {
        width: '40%',
        display: 'flex',
    },
    props: {
        flexGrow: '1',
        display: 'flex',
    },
    dates: {
        flexGrow: '1',
        display: 'flex',
        textAlign: 'center',
    },
    checks: {
        flexGrow: '1',
        display: 'flex',
        textAlign: 'center',
        cursor: 'pointer',
    },
    cell: {
        display: 'inline-block',
        lineHeight: '24px',
        padding: '5px',
        flex: '1',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.attendees.doodle';

    static propTypes = {
        attendees: PropTypes.arrayOf(PropTypes.object).isRequired,
        dates: PropTypes.arrayOf(PropTypes.string),
        onDates: PropTypes.func,
        style: PropTypes.object,
    };

    render() {
        if (!this.props.attendees.length) {
            return <div style={Object.assign({}, styles.empty, this.props.style)}>No attendees</div>;
        }

        const edit = this.props.onDates && this.props.dates && this.props.dates.length;

        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <div style={styles.header}>
                    {this.renderCells(['First name', 'Last name'], styles.name)}

                    {edit
                        ? this.renderCells(this.props.dates.map(date => Moment(date).format('D MMMM')), styles.dates)
                        : this.renderCells(['E-mail', 'Birth date', 'Position'], styles.props)}
                </div>

                {this.props.attendees.map((attendee: Object, index: number) => (
                    <div key={index} style={styles.attendee}>
                        {this.renderCells([attendee.firstName, attendee.lastName], styles.name)}

                        {edit
                            ? this.renderChecks(index, attendee.availabilities || [])
                            : this.renderCells([attendee.email, attendee.birthDay, attendee.position], styles.props)}
                    </div>
                ))}
            </div>
        );
    }

    renderCells(values: string[], style: Object) {
        return (
            <div style={Object.assign({}, styles.cells, style)}>
                {values.map((value: string, index: number) => (
                    <span key={index} style={styles.cell}>
                        {value}
                    </span>
                ))}
            </div>
        );
    }

    renderChecks(index: number, availabilities: string[]) {
        const cells = [];

        for (const date of this.props.dates) {
            const on = availabilities.includes(date);

            let cellColor = 'white';
            let iconColor = colors.userCold;

            if (availabilities.length > 0) {
                cellColor = on ? colors.bgGreen : colors.bgHot;
                iconColor = on ? colors.userGreen : colors.userHot;
            }

            cells.push(
                <span
                    key={date}
                    style={Object.assign({}, styles.cell, { background: cellColor })}
                    onClick={event =>
                        this.props.onDates(
                            index,
                            on ? difference(availabilities, [date]) : union(availabilities, [date]),
                        )
                    }
                >
                    <Icon color={iconColor} type={'base.checkbox.' + (on ? 'on' : 'off')} />
                </span>,
            );
        }

        return <div style={styles.checks}>{cells}</div>;
    }
}
