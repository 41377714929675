// @flow

import type { CompanyProfile, PersonProfile } from '@deecision/deefind-types/model';
import { matchCompanyName, matchPersonName } from './name';
import { matchDate, matchIdentifier } from './scalar';
import { matchBest } from './array';

export function matchCompanyProfile(a: CompanyProfile, b: CompanyProfile): number {
    const identifiers = matchBest(matchIdentifier, a.identifiers, b.identifiers);

    if (identifiers > 0) {
        return 100;
    }

    const name = matchCompanyName(a.name, b.name);
    const country = matchIdentifier(a.country, b.country);
    const naceCode = matchIdentifier(a.naceCode, b.naceCode);
    const domains = matchBest(matchIdentifier, a.domains, b.domains);
    const persons = matchBest(matchPersonName, a.persons, b.persons);
    const companies = matchBest(matchCompanyName, a.companies, b.companies);

    const lowest = Math.min(name, country, naceCode);

    if (lowest < 0) {
        return lowest;
    }

    if (!name) {
        return 0;
    }

    if (companies > 0 || persons > 0 || domains > 0) {
        return 75;
    }

    if (naceCode > 0 && country > 0) {
        return 67.5;
    }

    return 50;
}

export function matchPersonProfile(a: PersonProfile, b: PersonProfile): number {
    const name = matchPersonName(
        a.firstName && a.lastName ? [a.firstName, a.lastName] : a.name,
        b.firstName && b.lastName ? [b.firstName, b.lastName] : b.name,
    );

    const birthDate = matchDate(a.birthDate, b.birthDate);
    const persons = matchBest(matchPersonName, a.persons, b.persons);
    const companies = matchBest(matchCompanyName, a.companies, b.companies);

    const lowest = Math.min(name, birthDate);

    if (lowest < 0) {
        return lowest;
    }

    if (!name) {
        return 0;
    }

    if (birthDate > 0) {
        return 100;
    }

    if (persons > 0 || companies > 0) {
        return 75;
    }

    return 50;
}
