// @flow

import type { DealPosition, LinkConnectionFamily, LinkConnectionNature } from '@deecision/deefind-types/model';

import { encodeArtifactId, decodeArtifactId } from './artifact';

export const TARGET_TYPE_CODES = {
    // main (0-9)
    unknown: 0,
    owner: 1,
    owned: 2,
    vehicle: 3,
    vendor: 5,
    target: 6,
    acquiror: 7,
    regulator: 8,

    // family (10-19)
    family: 10, // unknown
    ancestor: 11,
    parent: 12,
    sibling: 13,
    married: 14,
    child: 15,
    descendant: 16,

    // connection (20-29)
    connection: 20, // unknown
    mandate: 21,
    position: 22,
    collaborator: 23,
    friend: 24,
};

export function encodeTargetId(provider: number, version: number, identifier: string): string {
    return encodeArtifactId(provider, version, identifier);
}

export function decodeTargetId(id: string): { provider: number, version: number } {
    return decodeArtifactId(id);
}

export function encodeTargetType(
    type: DealPosition | LinkConnectionFamily | LinkConnectionNature | 'owner' | 'owned' | 'vehicle',
): number {
    switch (type) {
        case 'owner':
            return TARGET_TYPE_CODES.owner;

        case 'owned':
            return TARGET_TYPE_CODES.owned;

        case 'vehicle':
            return TARGET_TYPE_CODES.vehicle;

        case 'vendor':
            return TARGET_TYPE_CODES.vendor;

        case 'target':
            return TARGET_TYPE_CODES.target;

        case 'acquiror':
            return TARGET_TYPE_CODES.acquiror;

        case 'regulator':
            return TARGET_TYPE_CODES.regulator;

        case 'family':
            return TARGET_TYPE_CODES.family;

        case 'ancestor':
            return TARGET_TYPE_CODES.ancestor;

        case 'parent':
            return TARGET_TYPE_CODES.parent;

        case 'sibling':
            return TARGET_TYPE_CODES.sibling;

        case 'married':
            return TARGET_TYPE_CODES.married;

        case 'child':
            return TARGET_TYPE_CODES.child;

        case 'descendant':
            return TARGET_TYPE_CODES.descendant;

        case 'mandate':
            return TARGET_TYPE_CODES.mandate;

        case 'position':
            return TARGET_TYPE_CODES.position;

        case 'collaborator':
            return TARGET_TYPE_CODES.collaborator;

        case 'friend':
            return TARGET_TYPE_CODES.friend;

        default:
            return TARGET_TYPE_CODES.unknown;
    }
}

export function decodeTargetType(type: number): DealPosition | LinkConnectionFamily | LinkConnectionNature | 'owner' | 'vehicle' {
    switch (type) {
        case TARGET_TYPE_CODES.owner:
            return 'owner';

        case TARGET_TYPE_CODES.vehicle:
            return 'vehicle';

        case TARGET_TYPE_CODES.vendor:
            return 'vendor';

        case TARGET_TYPE_CODES.target:
            return 'target';

        case TARGET_TYPE_CODES.acquiror:
            return 'acquiror';

        case TARGET_TYPE_CODES.regulator:
            return 'regulator';

        case TARGET_TYPE_CODES.family:
            return 'family';

        case TARGET_TYPE_CODES.ancestor:
            return 'ancestor';

        case TARGET_TYPE_CODES.parent:
            return 'parent';

        case TARGET_TYPE_CODES.sibling:
            return 'sibling';

        case TARGET_TYPE_CODES.married:
            return 'married';

        case TARGET_TYPE_CODES.child:
            return 'child';

        case TARGET_TYPE_CODES.descendant:
            return 'descendant';

        case TARGET_TYPE_CODES.mandate:
            return 'mandate';

        case TARGET_TYPE_CODES.position:
            return 'position';

        case TARGET_TYPE_CODES.collaborator:
            return 'collaborator';

        case TARGET_TYPE_CODES.friend:
            return 'friend';

        default:
            return 'unknown';
    }
}
