// @flow

import type { ApplicationEntry } from 'app/layout/application/types';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import colors from 'app/theme/colors';
import DataProvider from 'app/layout/providers/data';
import Title from './title';
import Account from './account';
import Entry from './entry';
import Members from './members';
import Cursor from './cursor';

const styles = {
    container: {
        background: colors.grey100,
        boxShadow: 'rgba(0, 0, 0, 0.15) 0 0 10px',
    },
    wrapper: {
        width: '1400px',
        margin: '0 auto',
        height: '60px',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    title: {},
    menu: {
        marginLeft: '40px',
        display: 'flex',
        flexGrow: '1',
    },
    cursor: {
        marginRight: '5px',
    },
    tools: {
        display: 'flex',
    },
};

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'app.layout.application.header';

    static propTypes = {
        global: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        title: PropTypes.any.isRequired,
        entries: PropTypes.arrayOf(PropTypes.object).isRequired,
        help: PropTypes.string,
    };

    render() {
        const session = this.props.global.session.get();
        const cursor = this.props.global.cursor.get();

        return (
            <div style={styles.container}>
                <div style={styles.wrapper}>
                    <span style={styles.title}>
                        <Title title={this.props.title} />
                    </span>

                    <span style={styles.menu}>
                        {this.props.entries.map((entry: ApplicationEntry, index: number) =>
                            entry.access ? (
                                <Entry
                                    key={`e${index}`}
                                    url={entry.path ? `/${this.props.path}/${entry.path}` : undefined}
                                    icon={entry.icon}
                                    label={entry.label}
                                    count={entry.count}
                                    disabled={!entry.path || !entry.access}
                                />
                            ) : null,
                        )}
                    </span>

                    <span style={styles.cursor}>
                        <Cursor />
                    </span>

                    <span style={styles.tools}>
                        {this.renderMembers(session, cursor)}

                        <Entry icon="main.account">
                            <Account
                                user={session.user}
                                onLogout={() => this.props.global.execute('cleerance', 'auth.logout')}
                                onEdit={(profile: Object) =>
                                    this.props.global.execute('cleerance', 'auth.edit', { profile })
                                }
                            />
                        </Entry>
                    </span>
                </div>
            </div>
        );
    }

    renderMembers(session: AuthUserSession, cursor: AuthCursor) {
        if (session.level === 'intervention') {
            return null;
        }

        const account = session.user.accounts.filter((account) => account.id === cursor.account)[0];
        const membership = account ? account.memberships[cursor.team || ''] : undefined;

        if (!account || !membership) {
            return <Entry icon="main.team" disabled />;
        }

        return (
            <DataProvider
                service="cleerance"
                model="team"
                id={cursor.team}
                scope="public"
                renderLoader={() => <Entry icon="main.team" disabled />}
                renderError={() => <Entry icon="main.team" disabled />}
                renderValue={(value) => (
                    <Entry icon="main.team">
                        <Members id={cursor.team} data={value} level={membership.level} />
                    </Entry>
                )}
            />
        );
    }
}
