// @flow

import React from 'react';
import Screen from 'app/layout/screen';
import { inject } from 'mobx-react';

import Dashboard from './dashboard/index';

@inject(stores => ({ comeetManagement: stores.comeetManagement }))
export default class extends React.PureComponent<any, any> {
    static displayName = 'comeet.management';

    props: {
        comeetManagement: any
    };

    static childContextTypes = {
    };

    getChildContext () {
        return {
        };
    }

    render () {
        return (
            <Screen
                path="management"
                type="workspace"
                fallback="dashboard"

                select={ this.props.comeetManagement.selectStage }
                close={ this.props.comeetManagement.closeStage }

                stages={{
                    dashboard: {
                        entry: { icon: 'main.dashboard', label: 'Dashboard' },
                        content: Dashboard
                    },
                }}
            />
        );
    }
}
