import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        textAlign: 'center',
        background: colors.grey100,
        borderRadius: '5px',
        padding: '50px',
        boxSizing: 'content-box'
    },
    title: {
        fontSize: '19px',
        color: colors.grey500
    },
    content: {
        fontSize: '15px',
        marginTop: '25px',
        color: colors.grey700
    },
    actions: {
        marginTop: '25px'
    },
    action: {
        margin: '0 10px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.empty';

    static propTypes = {
        title: PropTypes.string.isRequired,
        content: PropTypes.node,
        actions: PropTypes.arrayOf(PropTypes.element.isRequired),
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <div style={ styles.title }>
                    { this.props.title }
                </div>

                { this.renderContent() }
                { this.renderButtons() }
            </div>
        );
    }

    renderContent () {
        if (! this.props.content)
            return null;

        return (
            <div style={ styles.content }>
                { this.props.content }
            </div>
        );
    }

    renderButtons () {
        if (! this.props.actions || ! this.props.actions.length)
            return null;

        return (
            <div style={ styles.actions }>
                { this.props.actions.map((action, index) => React.cloneElement(action, {
                    key: index,
                    style: Object.assign({}, action.props.style, styles.action)
                })) }
            </div>
        );
    }
}
