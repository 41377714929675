import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Form from 'app/views/form/container';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Status from '../../common/status/onSite';
import Program from '../../common/onSite/program';
import Doodle from './doodle';
import Edit from './edit';
import Button from 'app/views/common/button';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { toJS } from 'mobx';
import orderBy from 'lodash/orderBy';

const START_CHOICES = {
    '8:30': '8:30',
    '9:00': '9:00',
    '9:30': '9:30',
    '10:00': '10:00',
    '10:30': '10:30',
    '11:00': '11:00',
    '11:30': '11:30',
    '12:00': '12:00',
    '12:30': '12:30',
    '13:00': '13:00',
    '13:30': '13:30',
    '14:00': '14:00',
    '14:30': '14:30',
    '15:00': '15:00',
};

const MEETING_TYPES = {
    'visioConference': 'Visio conference',
    'externalOffice': 'External office',
    'internalOffice': 'AAIS Offices',
    'phoneCall': 'Phone call'
};

const styles = {
    container: {},
    header: {
        fontSize: '19px',
        lineHeight: '40px',
        marginBottom: '30px',
    },
    headerButton: {
        float: 'right',
        marginLeft: '10px',
    },
    separator: {
        fontSize: '19px',
        padding: '20px 0',
        borderRadius: '3px',
        background: colors.grey100,
    },
    time: {
        display: 'inline-block',
        color: colors.userCold,
        fontSize: '19px',
        marginRight: '10px',
        width: '80px',
        textAlign: 'right',
        lineHeight: '20px',
    },
    title: {
        fontSize: '17px',
        lineHeight: '20px',
    },
    block: {
        margin: '20px 0',
        display: 'flex',
        alignItems: 'flex-start',
    },
    left: {
        width: '40%',
    },
    summary: {
        margin: '10px 0 0 90px',
        lineHeight: '20px',
        color: colors.grey500,
    },
    selectorWrap: {
        margin: '40px 70px 10px 30%',
    },
    selectorList: {
        borderRadius: '3px',
        border: '1px solid ' + colors.grey300,
        display: 'flex',
    },
    selectorItem: {
        flexGrow: '1',
        textAlign: 'center',
        color: colors.userHot,
        background: colors.grey100,
        cursor: 'pointer',
    },
    selectorDate: {
        fontSize: '15px',
        lineHeight: '20px',
        padding: '15px 0',
    },
    selectorCount: {
        fontSize: '17px',
        lineHeight: '20px',
        paddingBottom: '5px',
        fontWeight: 'bold',
    },
    steps: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '40px',
        paddingTop: '25px',
    },
    step: {
        lineHeight: '30px',
        fontSize: '19px',
        paddingRight: '10px',
        marginRight: '30px',
    },
    stepIndex: {
        display: 'inline-block',
        color: 'white',
        borderRadius: '50%',
        width: '30px',
        textAlign: 'center',
        marginRight: '15px',
    },
    stepLabel: {
        paddingBottom: '3px',
        borderBottom: '2px solid transparent',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.attendees';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        external: PropTypes.bool,
    };

    state = { selectorHover: null, cursor: 0, meetingType: (toJS(this.props.entity.payload.onSite) || {}).type };

    render() {
        const readOnly = !this.props.external;
        if (!readOnly) return this.renderExternal();

        const data = toJS(this.props.entity.payload.onSite) || {};
        let pointer = Moment(data.startTime || '9:00', 'H:mm');

        const SortableItem = SortableElement(({ value }) => <li style={{ display: 'block' }}>{value}</li>);
        const SortableList = SortableContainer(({ items }) => {
            return (
                <ul style={{ listStyleType: 'none', padding: '0px' }}>
                    {items.map((block: Object, index: number) => {
                        const result = this.renderBlock(index, block, pointer, data, readOnly);
                        pointer = pointer.add(block.duration, 'm');

                        return <SortableItem key={`item-${index}`} index={index} value={result} />;
                    })}
                </ul>
            );
        });

        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    <Program
                        onChange={program => this.props.onChange(Object.assign({}, data, { program }))}
                        external={this.props.external}
                        style={styles.headerButton}
                        program={data.program}
                    />
                    <Status entity={this.props.entity} withLocation />
                    {readOnly ? null : this.renderEdit(data)}
                    {readOnly ? null : this.renderSelector(data)}
                </div>

                <div style={styles.program}>
                    {!this.props.external ? (
                        (data.program || []).map((block: Object, index: number) => {
                            const result = this.renderBlock(index, block, pointer, data, readOnly);
                            pointer = pointer.add(block.duration, 'm');
                            return result;
                        })
                    ) : (
                        <SortableList
                            useDragHandle={true}
                            items={data.program || []}
                            onSortEnd={({ oldIndex, newIndex }) =>
                                this.props.onChange(
                                    Object.assign({}, data, { program: arrayMove(data.program, oldIndex, newIndex) }),
                                )
                            }
                        />
                    )}
                </div>
            </div>
        );
    }

    renderExternal() {
        const data = toJS(this.props.entity.payload.onSite) || {};
        let pointer = Moment(data.startTime || '9:00', 'H:mm');

        const SortableItem = SortableElement(({ value }) => <li style={{ display: 'block' }}>{value}</li>);
        const SortableList = SortableContainer(({ items }) => {
            return (
                <ul style={{ listStyleType: 'none', padding: '0px' }}>
                    {items.map((block: Object, index: number) => {
                        const result = this.renderBlock(index, block, pointer, data, false);
                        pointer = pointer.add(block.duration, 'm');

                        return <SortableItem key={`item-${index}`} index={index} value={result} />;
                    })}
                </ul>
            );
        });

        let max = 1;


        if (data.finalDate) {
            max = 2;
        }

        const cursor = this.state.cursor > 0 ? Math.min(this.state.cursor, max) : max;

        return (
            <div style={styles.container}>
                <div style={styles.header}>
                    {this.renderStep(cursor, max, 1, 'Schedule')}
                    {this.renderStep(cursor, max, 2, 'Agenda')}

                    <Program
                        onChange={program => this.props.onChange(Object.assign({}, data, { program }))}
                        external={this.props.external}
                        style={styles.headerButton}
                        program={data.program}
                        readOnly={data.proposedDates && data.proposedDates.length ? false : true}
                    />
                </div>

                <div style={styles.program}>
                    {cursor === 2 ? (
                        <div>
                            {data.program.filter(item => !item.fixed).length <=
                            data.program.filter(item => item.attendees && item.attendees.length).length ? null : (
                                <div style={{ color: colors.userHot }}>
                                    Please provide at least one attendee and date per section
                                </div>
                            )}

                            <SortableList
                                useDragHandle={true}
                                items={data.program || []}
                                onSortEnd={({ oldIndex, newIndex }) =>
                                    this.props.onChange(
                                        Object.assign({}, data, {
                                            program: arrayMove(data.program, oldIndex, newIndex),
                                        }),
                                    )
                                }
                            />
                        </div>
                    ) : (
                        [this.renderSelector(data), this.renderEdit(data)]
                    )}
                </div>
            </div>
        );
    }

    renderStep(cursor: number, max: number, index: number, label: string) {
        const active = index <= max;
        const current = index === cursor;

        const stepStyle = Object.assign({}, styles.step, {
            color: active ? 'black' : colors.grey400,
            cursor: active ? 'pointer' : 'default',
        });

        const indexStyle = Object.assign({}, styles.stepIndex, {
            background: current ? colors.userHot : active ? colors.userCold : colors.grey300,
        });

        const labelStyle = Object.assign({}, styles.stepLabel, {
            borderBottom:
                '2px solid ' +
                (current ? colors.userHot : this.state.hover === index ? colors.userCold : 'transparent'),
        });

        return (
            <span
                style={stepStyle}
                onMouseEnter={active ? event => this.setState({ hover: index }) : null}
                onMouseLeave={active ? event => this.setState({ hover: null }) : null}
                onClick={active ? event => this.setState({ cursor: index }) : null}
            >
                <span style={indexStyle}>{index}</span>
                <span style={labelStyle}>{label}</span>
            </span>
        );
    }

    renderEdit(data) {
        return (
            <Form
                key="edit"
                ref="form"
                data={data}
                onChange={() => {
                    if (data.lock === true) {
                        this.refs.form.setData(data);
                        return;
                    }

                    this.setState({ changed: true, meetingType: this.refs.form.getData().type });
                }}
            >
                <Views.Form.Row>
                    <Views.Form.Field label="Type of meeting" path="type" size={1} widget={{ type: 'select', choices: MEETING_TYPES }} required />
                    <Views.Form.Field
                        label="Start time"
                        path="startTime"
                        size={1}
                        widget={{ type: 'select', choices: START_CHOICES }}
                        required
                    />
                </Views.Form.Row>

                { this.state.meetingType === 'visioConference' ? <Views.Form.Row><Views.Form.Field label="Link" path="weblink" size={3} widget={{ type: 'string' }} required /></Views.Form.Row> : null}
                { this.state.meetingType === 'phoneCall' ? <Views.Form.Row><Views.Form.Field label="Phone number" path="phoneNumber" size={1} widget={{ type: 'string' }} required /></Views.Form.Row> : null}
                { this.state.meetingType === 'internalOffice' || this.state.meetingType === 'externalOffice' ? [
                    <Views.Form.Row>
                        <Views.Form.Field key="address" label="Address" path="address" size={3} widget={{ type: 'string' }} required />
                        <Views.Form.Field key="city" label="City" path="city" size={3} widget={{ type: 'string' }} required />
                    </Views.Form.Row>,
                    <Views.Form.Row>
                        <Views.Form.Field key="addressComplement" label="Address complement" path="addressComplement" size={3} widget={{ type: 'text' }} />
                    </Views.Form.Row>,
                ] : null}
                <div>
                    {!this.state.changed
                        ? null
                        : [
                              <Button
                                  key="submit"
                                  type="raised"
                                  style={styles.submit}
                                  color="cold"
                                  icon={<Icon type="do.submit" />}
                                  label="Submit"
                                  onClick={() => {
                                      this.props.onChange(this.refs.form.getData());
                                      this.setState({ changed: false });
                                  }}
                              />,
                              <Button
                                  key="cancel"
                                  type="raised"
                                  style={styles.submit}
                                  color="hot"
                                  icon={<Icon type="do.cancel" />}
                                  label="Cancel"
                                  onClick={() => {
                                      this.refs.form.setData(data);
                                      this.setState({ changed: false });
                                  }}
                              />,
                          ]}
                </div>
            </Form>
        );
    }

    renderSelector(data, readOnly) {
        if (readOnly || !data.proposedDates || !data.proposedDates.length) {
            return null;
        }

        const counts = this.countAvailabilities(data);

        return (
            <div key="selector" style={{ marginBottom: '20px' }}>
                <div style={{ verticalAlign: 'middle', textAlign: 'center', display: 'inline-block', width: '30%' }}>
                    Select final date :
                </div>
                <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '70%' }}>
                    <div style={styles.selectorList}>
                        {orderBy(data.proposedDates).map((date: string) => (
                            <div
                                key={date}
                                style={Object.assign(
                                    {},
                                    styles.selectorItem,
                                    data.program.filter(item => !item.fixed).length > 0
                                        ? { color: colors.userCold, background: colors.grey200 }
                                        : {},
                                    data.finalDate === date
                                        ? { color: 'white', background: colors.userCold, cursor: 'default' }
                                        : this.state.selectorHover === date
                                        ? { background: colors.grey300 }
                                        : null,
                                )}
                                onClick={event =>
                                  data.lock ? null :
                                    this.props.onChange(
                                        Object.assign({}, data, {
                                            finalDate: date === data.finalDate ? null : date,
                                        }),
                                    )
                                }
                                onMouseEnter={event => this.setState({ selectorHover: date })}
                                onMouseLeave={event => this.setState({ selectorHover: null })}
                            >
                                <div style={styles.selectorDate}>{Moment(date).format('D MMM')} {data.finalDate === date && data.lock ? <Icon style={{verticalAlign: 'bottom'}} type="base.lock" color="white" /> : null} </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    renderBlock(index: number, block: Object, moment, data, readOnly) {
        if (block.fixed) {
            return readOnly ? null : (
                <div key={index} style={styles.separator}>
                    <span style={styles.time}>{moment.format('HH:mm')}</span>
                    <span style={styles.title}>{block.title}</span>
                </div>
            );
        }

        const blockStyle = Object.assign({}, styles.block, {
            flexDirection: readOnly ? 'column' : 'row',
        });

        const doodleStyle = readOnly ? { width: '100%', margin: '20px 0' } : {};

        const DragHandle = SortableHandle(() => (
            <span style={{ verticalAlign: '-webkit-baseline-middle' }}>
                <Icon style={{ fill: colors.userCold, cursor: 'pointer' }} type="base.grip" />
            </span>
        ));

        return (
            <div key={index} style={blockStyle}>
                <div style={styles.left}>
                    {this.props.external ? <DragHandle /> : null}
                    <span style={Object.assign({}, styles.time, readOnly ? { marginLeft: '0px' } : { width: '56px' })}>
                        {moment.format('HH:mm')}
                    </span>
                    <span style={styles.title}>{block.title}</span>

                    {this.props.external || readOnly ? null : (
                        <div style={styles.summary}>
                            {block.summary.map((summary, index) => (
                                <div key={index}>{summary.title}</div>
                            ))}
                        </div>
                    )}
                </div>

                <Doodle
                    attendees={block.attendees}
                    dates={
                        null
                        // data.finalDate
                        //     ? null
                        //     : data.proposedDates && data.proposedDates.length
                        //     ? data.proposedDates
                        //     : null
                    }
                    onDates={
                        readOnly
                            ? null
                            : (rank: number, dates: string[]) => {
                                  const program = [].concat(data.program || []);

                                  if (program[index] && program[index].attendees[rank]) {
                                      program[index].attendees[rank].availabilities = dates;
                                      this.props.onChange(Object.assign({}, data, { program }));
                                  }
                              }
                    }
                    style={doodleStyle}
                />

                {readOnly ? null : (
                    <Edit
                        attendees={block.attendees}
                        onAttendees={attendees => {
                            const program = [].concat(data.program || []);
                            program[index].attendees = attendees;
                            this.props.onChange(Object.assign({}, data, { program }));
                        }}
                    />
                )}
            </div>
        );
    }

    countAvailabilities(data) {
        const counts = {};

        for (const date of data.proposedDates || []) {
            counts[date] = 0;
        }

        for (const block of data.program || []) {
            for (const attendee of block.attendees || []) {
                for (const availability of attendee.availabilities || []) {
                    if (counts.hasOwnProperty(availability)) {
                        counts[availability]++;
                    }
                }
            }
        }

        return counts;
    }
}
