// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Screen from 'app/layout/screen';
import Batch from './base/batch';
import Empty from './base/empty';

const LABELS = {
    coopt: 'Cooptation',
    segment: 'Segmentation',
    family: 'Family',
    predict: 'Predict',
    augmented: 'Augmented',
};

@inject('dnaDetector')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.detector';

    static propTypes = {
        dnaDetector: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        setStage: PropTypes.func.isRequired,
        pushJobs: PropTypes.func.isRequired,
        openEntity: PropTypes.func.isRequired,
    };

    getChildContext() {
        return {
            setStage: this.props.dnaDetector.setStage,
            pushJobs: this.props.dnaDetector.pushJobs,
            openEntity: this.props.dnaDetector.openEntity,
        };
    }

    render() {
        return (
            <Screen
                path="detector"
                type="workspace"
                fallback="reducer"
                select={this.props.dnaDetector.selectStage}
                add={this.props.dnaDetector.addStage}
                close={this.props.dnaDetector.closeStage}
                empty={<Empty addStage={this.props.dnaDetector.addStage} />}
                stages={{
                    batch: {
                        entry: state => ({ icon: 'dna.analyze', label: LABELS[state.tool] || 'Batch' }),
                        content: Batch,
                    },
                }}
            />
        );
    }
}
