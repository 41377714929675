import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        alignContent: 'stretch',
        margin: '0 35px 1px 40px'
    },
    label: {
        padding: '0 10px',
        borderLeft: '1px solid transparent',
        fontSize: '13px',
        color: colors.grey500,
        lineHeight: '20px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.collection.header';

    static propTypes = {
        fields: PropTypes.objectOf(PropTypes.oneOfType([ PropTypes.string, PropTypes.object ])).isRequired
    };

    render () {
        if (! _.filter(_.map(this.props.fields, field => _.isObject(field) && field.label ? field.label : undefined)).length)
            return null;

        return (
            <div style={ styles.container }>
                { this.renderFields() }
            </div>
        );
    }

    renderFields () {
        const size = _.sum(_.map(this.props.fields, field => field.size || 1));

        return _.map(this.props.fields, (field, name) => (
            <div
                key={ name }
                style={ Object.assign({ width: (100 * (field.size || 1) / size) + '%' }, styles.label) }
            >
                { field.label || '' }
            </div>
        ));
    }
}
