import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Card from './card';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.templates.summary.list';

    static propTypes = {
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        onCreate: PropTypes.func.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <Views.List.Container entities={this.props.entities} state={this.props.state}>
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        index: { label: 'Index', path: entity => entity.index },
                        name: { label: 'Label', path: 'payload.label' },
                    }}
                />

                <Views.List.Reducer
                    type="fuse"
                    specs={{
                        paths: ['payload.label'],
                    }}
                />

                <Views.List.Frame type="cards" element={<Card />} />

                <Views.List.Block position="right">
                    <Views.Common.Button
                        label="New template"
                        icon={<Icon type="do.create" />}
                        onClick={this.props.onCreate}
                    />
                </Views.List.Block>

            </Views.List.Container>
        );
    }
}
