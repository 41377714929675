import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Form from './form';
import Item from './item';

const styles = {
    container: {},
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.contacts.list';

    static propTypes = {
        sales: PropTypes.object.isRequired,
        manager: PropTypes.object.isRequired,
        attendees: PropTypes.array.isRequired,
        onUpdateSales: PropTypes.func.isRequired,
        onUpdateManager: PropTypes.func.isRequired,
        onAddAttendee: PropTypes.func.isRequired,
        onRemoveAttendee: PropTypes.func.isRequired,
        state: PropTypes.object,
    };

    render() {
        const entities = [
            Object.assign({}, this.props.sales, { type: 2 }),
            Object.assign({}, this.props.manager, { type: 1 }),
        ];

        for (const attendee of this.props.attendees || []) {
            entities.push(Object.assign({}, attendee, { type: 0 }));
        }

        return (
            <Views.List.Container
                state={this.props.state}
                entities={entities}
                style={styles.container}
            >
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        type: {
                            label: 'Role',
                            path: 'type',
                            type: 'desc',
                        },
                        name: {
                            label: 'Name',
                            path: 'name',
                            type: 'asc',
                        },
                    }}
                />

                <Views.List.Reducer
                    name="groups"
                    title=""
                    type="groups"
                    specs={{
                        internal: {
                            label: 'Sales',
                            filter: entity => entity.type === 2,
                        },
                        external: {
                            label: 'Manager',
                            filter: entity => entity.type === 1,
                        },
                        attendee: {
                            label: 'Attendee',
                            filter: entity => entity.type === 0,
                        },
                    }}
                />

                <Views.List.Reducer type="fuse" specs={{ paths: ['name', 'email'] }} />

                <Views.List.Block position="right">
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        label="Add attendee"
                        icon={<Icon type="do.create" />}
                        onClick={() => this.refs['form'].open()}
                    />

                    <Form
                        ref="form"
                        project={this.props.project}
                        onSubmit={profile => this.props.onAddAttendee(profile)}
                    />
                </Views.List.Block>

                <Views.List.Frame
                    type="table"
                    specs={{ labels: ['Role', 'Full name', 'Email address', 'Phone number', ''] }}
                    element={
                        <Item
                            onUpdateSales={this.props.onUpdateSales}
                            onUpdateManager={this.props.onUpdateManager}
                            onAddAttendee={this.props.onAddAttendee}
                            onRemoveAttendee={this.props.onRemoveAttendee}
                        />
                    }
                />
            </Views.List.Container>
        );
    }
}
