import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        padding: '0 5px',
        backgroundColor: colors.grey100,
        lineHeight: '40px',
        borderRadius: '3px',
        fontSize: '13px'
    },
    count: {
        color: colors.userCold,
    },
    separator: {
        display: 'inline-block',
        margin: '0 5px',
        color: colors.grey400
    },
    total: {
        color: colors.grey500
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.header.count';

    static propTypes = {
        value: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        style: PropTypes.object
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.style) }>
                <span style={ styles.count }>{ this.props.value }</span>
                <span style={ styles.separator }>/</span>
                <span style={ styles.total }>{ this.props.total }</span>
            </div>
        );
    }
}
