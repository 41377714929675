import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import { ACTIVITIES } from 'dna/entity/aml';
import t from 'app/translation';

const styles = {
    amlFlag: {
        fontSize: '9px',
        background: colors.userHot,
        borderRadius: '3px',
        padding: '4px',
        display: 'inline-block',
        color: 'white',
        verticalAlign: 'bottom',
        marginRight: '5px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.activity';

    static propTypes = {
        nace: PropTypes.string,
        gics: PropTypes.string,
        style: PropTypes.object,
    };

    render () {
        if (! this.props.nace && !this.props.gics) {
            return 'Activity unknown';
        }

        if (ACTIVITIES[this.props.nace]) {
            return (
                <span>
                    <span style={ styles.amlFlag }>AML</span>
                    { ACTIVITIES[this.props.nace] }
                </span>
            );
        }

        return (
            <span style={ Object.assign({}, this.props.style) }>
                {t(`common.gics.${this.props.gics}`)}
            </span>
        );
    };
}
