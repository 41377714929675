// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import t from 'app/translation';

const styles = {
    container: {},
    name: {
    },
    description: {
        color: colors.grey500,
        fontSize: '13px',
        lineHeight: '15px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.settings.profile.body';

    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.name}>
                    {this.props.data.profile.name || t('cleerance.admin.teams.profile.noName')}
                </div>

                <div style={styles.description}>
                    {this.props.data.profile.description || t('cleerance.admin.teams.profile.noDescription')}
                </div>
            </div>
        );
    }
}
