// @flow

import type { CountsType, ResultType, CountsFilters, AugmentedCountsType } from '../types';
import type { DeetectScores } from '@deecision/deefind-types/client';
import { get } from 'lodash';

// probleme de type
export function makeStatusCounts(counts: CountsType, result: ResultType, network: boolean): boolean {
    counts.inputs++;

    if (result.output.status === 'ambiguous') {
        counts.ambiguous++;
        return false;
    }

    if (result.output.status === 'empty') {
        counts.empty++;
        return false;
    }

    if (result.output.status === 'missing') {
        counts.missing++;
        return false;
    }

    if (result.output.status !== 'workable' || !result.output.result) {
        counts.missing++; // error ?
        return false;
    }

    if (network) {
        for (const relation of result.output.relations || []) {
            if (relation.type === 'person') {
                counts.personResults++;
            }

            if (relation.type === 'company') {
                counts.companyResults++;
            }
        }
    } else {
        const type = result.output.result ? result.output.result.type : undefined;

        if (type === 'person') {
            counts.personResults++;
        }

        if (type === 'company') {
            counts.companyResults++;
        }
    }

    counts.workable++;
    return true;
}

export function makeAugmentedScoresCounts(
    counts: AugmentedCountsType,
    scores: DeetectScores,
    thresholds: Array<{ label: string, code: string, min: number, max: number }>,
    filters: CountsFilters,
): boolean {
    counts.total++;
    counts.risk['risk' + scores.risk]++;
    countReechColumns(scores, thresholds, counts);
    countComputedColumns(scores, thresholds, counts, filters);

    return true;
}

function enterColumn(targetColumn: number, counts: AugmentedCountsType) {
    counts.migrations[targetColumn].entry++;
}

function leaveColumn(targetColumn: number , counts: AugmentedCountsType) {
    counts.migrations[targetColumn].exit--;
}

function countReechColumns(scores: DeetectScores, thresholds: Array<{ label: string, code: string, min: number, max: number }>, counts: AugmentedCountsType) {
    for (let i = 0; i < 4; i++) {
        if (scores.segment === thresholds[i].code) {
            counts.reechColumns[i]++;
            scores.reechColumn = i;
            break;
        }
    }
}

function countComputedColumns(scores: DeetectScores, thresholds:  Array<{ label: string, code: string, min: number, max: number }>, counts: AugmentedCountsType, filters: CountsFilters): boolean {
    if (typeof scores.reechColumn === 'undefined' || typeof thresholds[scores.reechColumn] === 'undefined'){
        return false;
    }
    if (typeof scores.potential.value === 'undefined') {
        scores.computedColumn = scores.reechColumn;
        counts.aumOverPotential[scores.reechColumn]++;
        return false;
    }

    const potential = get(scores.potential, `${filters.accuracy}.value`, scores.potential.value);
    scores.selectedPotential = potential;
    scores.moveRight = false;
    for (let i = scores.reechColumn + 1; i < 4; i++) {
        if (thresholds[i].min < potential && potential < thresholds[i].max) {
            counts.computedColumns[i]++;
            scores.computedColumn = i;

            enterColumn(scores.computedColumn, counts);
            leaveColumn(scores.reechColumn, counts);
            scores.moveRight = true;

            break;
        }
    }
    if (scores.moveRight) {
        return true;
    }
    if (thresholds[scores.reechColumn].min < potential && potential < thresholds[scores.reechColumn].max) {
        counts.sameColumn[scores.reechColumn]++;
    } else {
        counts.aumOverPotential[scores.reechColumn]++;
    }

    return true;
}

export function makeScoresCounts(
    counts: CountsType,
    scores: DeetectScores,
    filters: CountsFilters,
    proximity: boolean,
): boolean {
    counts.total++;

    if (!scores.upsell && filters.upsell) {
        counts.upsellExcluded++;
        return false;
    }
    if (scores.upsell && scores.upsell.upsell / 1e6 < (filters.upsell || 0)) {
        counts.upsellExcluded++;
        return false;
    } else {
        counts.upsellSelected++;
    }

    if (!scores.upsell && filters.upsellPrct) {
        counts.upsellPrctExcluded++;
        return false;
    }
    if (scores.upsell && scores.upsell.upsellPrct < (filters.upsellPrct || 0)) {
        counts.upsellPrctExcluded++;
        return false;
    } else {
        counts.upsellPrctSelected++;
    }

    if (!scores.currentPrediction && filters.expectedValue) {
        counts.expectedValueExcluded++;
        return false;
    }
    if (scores.currentPrediction && scores.currentPrediction.expectedValue / 1e6 < (filters.expectedValue || 0)) {
        counts.expectedValueExcluded++;
        return false;
    } else {
        counts.expectedValueSelected++;
    }

    if (!scores.currentPrediction && filters.potentialCashout) {
        counts.potentialCashoutExcluded++;
        return false;
    }
    if (scores.currentPrediction && scores.currentPrediction.potentialCashout / 1e6 < (filters.potentialCashout || 0)) {
        counts.potentialCashoutExcluded++;
        return false;
    } else {
        counts.potentialCashoutSelected++;
    }

    if (!scores.currentPrediction && filters.probability && filters.probability > 0) {
        counts.probabilityExcluded++;
        return false;
    }
    if (scores.currentPrediction && scores.currentPrediction.probability < (filters.probability || 0)) {
        counts.probabilityExcluded++;
        return false;
    } else {
        counts.probabilitySelected++;
    }

    if (!scores.potentialCovid19 && filters.potentialCovid19 && filters.potentialCovid19 > 0) {
        counts.potentialCovid19Excluded++;
        return false;
    }

    if (scores.potentialCovid19 && scores.potentialCovid19.ratio > (filters.potentialCovid19 || 0)) {
        counts.potentialCovid19Excluded++;
        return false;
    } else {
        counts.potentialCovid19Selected++;
    }

    if (scores.potential.high) {
        counts.potentialHigh++;
    }

    if (scores.potential.mid) {
        counts.potentialMid++;
    }

    if (scores.potential.low) {
        counts.potentialLow++;
    }

    if (filters.accuracy && !scores.potential[filters.accuracy]) {
        counts.potentialExcluded++;
        counts.excluded++;
        return false;
    }

    if (scores.potential.value / 1e6 < filters.potential) {
        counts.potentialExcluded++;
        counts.excluded++;
        return false;
    }

    counts.potentialSelected++;

    if (scores.risk > filters.risk) {
        counts.riskExcluded++;
        counts.excluded++;
        return false;
    }

    counts.riskSelected++;

    if (proximity) {
        if ((scores.proximity || 0) < filters.proximity) {
            counts.proximityExcluded++;
            counts.excluded++;
            return false;
        }

        counts.proximitySelected++;
    }

    if (scores.potential.reliability < filters.completeness) {
        counts.completenessExcluded++;
        counts.excluded++;
        return false;
    }

    counts.completenessSelected++;
    counts.selected++;
    return true;
}
