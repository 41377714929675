import React from 'react';
import PropTypes from 'prop-types';
import './diagrams.css';

import { forEach, cloneDeep } from 'lodash';
import getFilters, { getInter, getExports, getTable, parseWithTable, getPersons } from './utils';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import * as d3 from 'd3';
import * as venn from 'venn.js';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import { green, blue, orange, red } from '@material-ui/core/colors';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const themeMainSegmentation = createMuiTheme({
    palette: {
        primary: {
            main: red[400],
        },
        action: {
            disabled: red[400],
        }
    },
});

const themeA = createMuiTheme({
    palette: {
        primary: {
            main: blue[400],
        },
        action: {
            disabled: blue[400],
        }
    },
});

const themeB = createMuiTheme({
    palette: {
        primary: {
            main: orange[400],
        },
        action: {
            disabled: orange[400],
        }
    },
});

const themeC = createMuiTheme({
    palette: {
        primary: {
            main: green[400],
        },
        action: {
            disabled: green[400],
        }
    },
});

const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const OrangeRadio = withStyles({
    root: {
        color: orange[400],
        '&$checked': {
            color: orange[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);



const styles = {
    container: {
        width: '1400px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
    },
    containerGroup: {
        width: '210px',
        margin: '0 50px 0 auto',
    },
    containerFilter: {
        minWidth: '1000px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    groups: {
        minWidth: '1000px',
        minHeight: '60px',
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#eeeeee',
        borderRadius: '3px',
    },
    main: {
        width: '604px',
        margin: '14px auto 0 auto',
        display: 'flex',
        flexDirection: 'row',
    },
    filters: {
        width: '200px',
    },
    fitlerText: {
        padding: '14px',
    },
    list: {
        maxHeight: '500px',
        overflow: 'auto',
    },
    diagram: {
        width: '600px',
        height: '350px',
        textAlign: 'center',
        border: '2px solid #ef5350',
        borderRadius: '300px',
        padding: '125px 0px',
    },
    loader: {
        width: '600px',
        height: '200px',
        textAlign: 'center',
    }
}

const chart = venn.VennDiagram();

export class Diagrams extends React.PureComponent<any, any> {
    static propTypes = {
        data: PropTypes.object.isRequired,
    }

    constructor (props) {
        super(props);
        this.state = {};
    };

    componentDidMount() {
        this.handleReset();
    }

    handleReset = () => {
        this.setState({
            filters: undefined,
            A: undefined,
            B: undefined,
            C: undefined,
            AB: undefined,
            AC: undefined,
            BC: undefined,
            ABC: undefined,
            ALabel: undefined,
            BLabel: undefined,
            CLabel: undefined,
            ABLabel: undefined,
            ACLabel: undefined,
            BCLabel: undefined,
            ABCLabel: undefined,
            progress: 0,
            group: [],
            radio: 'main',
        });
    }

    componentDidUpdate() {
        let myState = cloneDeep(this.state);
        let myData = cloneDeep(this.props.data);
        if (this.state.progress === 100) {
            let sets = [
                {
                    sets: ["A"],
                    size: this.state.A,
                    label: this.state.ALabel ? (this.state.ALabel + ' (' + this.state.A + ')') : ''
                },
                {
                    sets: ["B"],
                    size: this.state.B,
                    label: this.state.BLabel ? (this.state.BLabel + ' (' + this.state.B + ')') : ''
                },
                {
                    sets: ["C"],
                    size: this.state.C,
                    label: this.state.CLabel ? (this.state.CLabel + ' (' + this.state.C + ')') : ''
                },
                {
                    sets: ["A", "B"],
                    size: this.state.AB,
                    label: this.state.ABLabel + this.state.AB
                },
                {
                    sets: ["A", "C"],
                    size: this.state.AC,
                    label: this.state.ACLabel + this.state.AC
                },
                {
                    sets: ["B", "C"],
                    size: this.state.BC,
                    label: this.state.BCLabel + this.state.BC
                },
                {
                    sets: ["A", "B", "C"],
                    size: this.state.ABC,
                    label: this.state.ABCLabel + this.state.ABC
                }
            ];
            let div = d3.select('#venn');
            div.datum(sets).call(chart);

            let tooltip = d3.select("body").append("div")
                .attr("class", "venntooltip");

            div.selectAll("path")
                .style("stroke-opacity", 0)
                .style("stroke", "#fff")
                .style("stroke-width", 3)

            div.selectAll("g")
                .on("mouseover", function(d, i) {
                    venn.sortAreas(div, d.target.__data__);

                    tooltip.transition().duration(400).style("opacity", .9);
                    tooltip.text((d.target.__data__.size) + " persons");

                    let selection = d3.select(this).transition("tooltip").duration(400);
                    selection.select("path")
                        .style("fill-opacity", d.target.__data__.sets.length == 1 ? .4 : .1)
                        .style("stroke-opacity", 1);
                })

                .on("mousemove", function() {
                    tooltip.style("left", (event.pageX) + "px")
                        .style("top", (event.pageY - 28) + "px");
                })

                .on("mouseout", function(d, i) {
                    tooltip.transition().duration(400).style("opacity", 0);
                    let selection = d3.select(this).transition("tooltip").duration(400);
                    selection.select("path")
                        .style("fill-opacity", d.target.__data__.sets.length == 1 ? .25 : .0)
                        .style("stroke-opacity", 0);
                })

                .on("click", function(d, i) {
                    let myGroup = cloneDeep(myState.group);
                    d.target.__data__.sets[0] && myGroup.push(myState[d.target.__data__.sets[0] + 'Label']);
                    d.target.__data__.sets[1] && myGroup.push(myState[d.target.__data__.sets[1] + 'Label']);
                    d.target.__data__.sets[2] && myGroup.push(myState[d.target.__data__.sets[2] + 'Label']);
                    let myPersons = getPersons(parseWithTable(myData.exports, getTable(myData.exports, true)), myGroup);
                    console.log(myPersons);
                });
        }
    }

    handleParameters = (filters, element, filter, exports) => {
        let myData = cloneDeep(this.props.data);
        if (this.state.radio === 'blue') {
            this.setState({
                A: element.number,
                ALabel: filter + ' : ' + element.element,
                progress: this.state.progress > 50 ? this.state.progress : 50
            });
        } else if (this.state.radio === 'orange') {
            this.setState({
                B: element.number,
                BLabel: filter + ' : ' + element.element,
                progress:  this.state.progress > 75 ? this.state.progress : 75
            });
        } else if (this.state.radio === 'green') {
            this.setState({
                C: element.number,
                CLabel: filter + ' : ' + element.element,
                progress: 100
            });
        }
        if (this.state.A !== undefined && this.state.radio === 'orange') {
            this.setState({
                AB: getInter(myData.exports, [this.state.ALabel.split(' :')[0], filter], [this.state.ALabel.split(' : ')[1], element.element])
            });
        }
        if (this.state.B !== undefined) {
            this.setState({
                AC: getInter(myData.exports, [this.state.ALabel.split(' : ')[0], filter], [this.state.ALabel.split(' : ')[1], element.element])});
            this.setState({
                BC: getInter(myData.exports, [this.state.BLabel.split(' : ')[0], filter], [this.state.BLabel.split(' : ')[1], element.element])});
            this.setState({
                ABC: getInter(myData.exports, [this.state.ALabel.split(' : ')[0], this.state.BLabel.split(' : ')[0], filter], [this.state.ALabel.split(' : ')[1], this.state.BLabel.split(' : ')[1], element.element])});
        }
    };

    handleClickGroup = (div) => {
        if (this.state.openGroup === div) {
            this.setState({ openGroup: false });
        } else {
            this.setState({ openGroup: div });
        }
    };

    handleClick = (div) => {
        if (this.state.open === div) {
            this.setState({ open: false });
        } else {
            this.setState({ open: div });
        }
    };

    createPage() {
        let result = [];
        let exports = parseWithTable(this.props.data.exports, getTable(this.props.data.exports, false));

        result.push(
            <div style={styles.containerGroup}>
                <Button variant="contained" style={{marginLeft: '0px', marginBottom: '14px', width: '210px'}} color="primary" onClick={(event) => this.handleReset()}>
                    RESET
                </Button>
                <FormControl component="fieldset" style={{backgroundColor: '#eeeeee', borderRadius: '3px'}}>
                    <RadioGroup row aria-label="position" name="position" defaultValue="main" onChange={(event) => {this.setState({ radio: event.target.value })}}>
                        <FormControlLabel
                            value="main"
                            control={<RedRadio />}
                            label="Main segmentation"
                            labelPlacement="start"
                            style={{color: 'rgba(245, 0, 87, 1)'}}
                        />
                        <FormControlLabel
                        value="secondary"
                        control={<div style={styles.empty} />}
                        label="Secondary segmentation :   "
                        labelPlacement="start"
                        style={{color: 'rgba(245, 0, 87, 1)'}}
                        />
                        <FormControlLabel
                            value="blue"
                            control={<BlueRadio />}
                            label="A"
                            labelPlacement="start"
                            style={{color: 'rgb(31, 119, 180)'}}
                            disabled={this.state.group && (this.state.group).length < 1}
                        />
                        <FormControlLabel
                            value="orange"
                            control={<OrangeRadio />}
                            label="B"
                            labelPlacement="start"
                            style={{color: 'rgb(255, 127, 14)'}}
                            disabled={this.state.group && (this.state.group).length < 1 || this.state.A === undefined}
                        />
                        <FormControlLabel
                            value="green"
                            control={<GreenRadio />}
                            label="C"
                            labelPlacement="start"
                            style={{color: 'rgb(44, 160, 44)'}}
                            disabled={this.state.group && (this.state.group).length < 1 || this.state.A === undefined || this.state.B === undefined || this.state.B === 0}
                        />
                    </RadioGroup>
                </FormControl>
                {this.state.radio === 'main' ? this.createGroup(exports) : this.dispFilters(getExports(exports, this.state.group))}
            </div>
        );
        result.push(
            <div style={styles.containerFilter}>
                {this.createFilter(getExports(exports, this.state.group))}
            </div>
        );

        return result;
    }

    createGroup(exports) {
        let filtersList = [];
        let filters = getFilters(exports);

        forEach(filters, (filter) => {
            let listElements = [];
            forEach(filter[Object.keys(filter)[0]], (element) => {
                let checked = false;
                let i = 0;
                let index = 0;
                forEach(this.state.group, (item) => {
                    if (item === Object.keys(filter)[0] + ' : ' + element[Object.keys(element)[0]]) {
                        checked = true;
                        index = i;
                    }
                    i++;
                });
                listElements.push(
                    <FormControlLabel
                        control={<Checkbox style={{marginLeft: '10px', color: '#ef5350'}} checked={checked} onChange={() => {
                            !checked ?
                                this.setState({
                                    group: [...this.state.group, Object.keys(filter)[0] + ' : ' + element[Object.keys(element)[0]]],
                                    progress: 20,
                                }) : this.setState(state => {
                                    const group = state.group.filter((item, j) => index !== j);
                                    return { group };
                                });
                        }} name={element[Object.keys(element)[0]]} />}
                        label={element[Object.keys(element)[0]]}
                        labelPlacement="end"
                    />
                );
            });
            filtersList.push(
                <List
                    key={JSON.stringify(Object.keys(filter)[0])}
                    style={styles.filters}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem button onClick={(event) => this.handleClickGroup(Object.keys(filter)[0])}>
                        <ListItemText primary={Object.keys(filter)[0]} />
                        {this.state.openGroup === Object.keys(filter)[0] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse style={styles.list} in={this.state.openGroup === Object.keys(filter)[0]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {listElements}
                                </FormGroup>
                            </FormControl>
                        </List>
                    </Collapse>
                </List>

            );
        })

        return filtersList;
    }

    createFilter(exports) {
        let result = [];

        let A = <MuiThemeProvider theme={themeA}><Button variant='outlined' disabled={true} style={{height : '25px', margin: '12px 12px 0px 0px'}}>{this.state.ALabel + ' (' + this.state.A + ')'}</Button></MuiThemeProvider>;
        let B = <MuiThemeProvider theme={themeB}><Button variant='outlined' disabled={true} style={{height : '25px', margin: '12px 12px 0px 0px'}}>{this.state.BLabel + ' (' + this.state.B + ')'}</Button></MuiThemeProvider>;
        let C = <MuiThemeProvider theme={themeC}><Button variant='outlined' disabled={true} style={{height : '25px', margin: '12px 12px 0px 0px'}}>{this.state.CLabel + ' (' + this.state.C + ')'}</Button></MuiThemeProvider>;

        result.push(
            <div style={{ marginTop: '48px' }}>
                <div style={styles.groups}>
                    {this.dispGroupList(exports)}
                </div>
            </div>
        );

        result.push(
            <div style={styles.groups}>
                <div style={styles.fitlerText}>Secondary segmentation :</div>
                {this.state.ALabel && A}{this.state.BLabel && B}{this.state.CLabel && C}
            </div>
        );

        result.push(
            <div style={styles.main}>
                <div key={'diagrams'}>
                    {this.dispDiagram()}
                </div>
            </div>
        );

        return result;
    }

    dispGroupList(exports) {
        let result = [];
        let filters = [];

        result.push(
            <div style={styles.fitlerText}>Main segmentation ({exports.length > 0 ? exports.length - 1 : 0}) : </div>
        );

        forEach(this.state.group, (element) => {
           filters.push(
               <Button style={{height : '25px'}}>
                   {element}
               </Button>
           );
        });

        result.push(
            <MuiThemeProvider theme={themeMainSegmentation}>
                <ButtonGroup disabled={true} variant="outlined" color="primary" style={{padding : '12px 0'}} size="small">
                    {filters}
                </ButtonGroup>
            </MuiThemeProvider>
        );

        return result;
    }

    dispFilters(exports) {
        let filtersList = [];
        let filters = getFilters(exports);

        forEach(filters, (filter) => {
            let listElements = [];
            forEach(filter[Object.keys(filter)[0]], (element) => {
                listElements.push(
                    <ListItem button key={element} onClick={(event) => this.handleParameters(filters, element, Object.keys(filter)[0], exports)}>
                        <ListItemText primary={element[Object.keys(element)[0]]} />
                    </ListItem>
                );
            });
            filtersList.push(
                <List
                    key={JSON.stringify(Object.keys(filter)[0])}
                    style={styles.filters}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem button onClick={(event) => this.handleClick(Object.keys(filter)[0])}>
                        <ListItemText primary={Object.keys(filter)[0]} />
                        {this.state.open === Object.keys(filter)[0] ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse style={styles.list} in={this.state.open === Object.keys(filter)[0]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {listElements}
                        </List>
                    </Collapse>
                </List>
            );
        })

        return filtersList;
    }

    dispDiagram() {
        let result = [];

        if (this.state.progress === 100) {
            result.push(
                <div key={"venn"} id="venn" style={styles.diagram}>
                </div>
            );
        } else {
            result.push(
                <div key={"loader"} style={styles.loader}>
                    <LinearProgress variant="determinate" value={this.state.progress} />
                </div>

            );
        }

        return result;
    }

    render() {
        if (this.props.data) {
            return (<div style={styles.container}>{this.createPage()}
            </div>);
        }
        return [];
    }
}