import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

import Views from 'app/views';
import EventDisplay from './eventsDisplay';

import { forEach } from 'lodash';

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.company.display.history.timeline';

    static propTypes = {
        events: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderEvent(event) {
        return (
            <Views.Timeline.Event
                key={event.url}
                id={event.url}
                date={event.date + 'T00:00:00.000Z'}
                style={{ background: colors.white }}
            >
                <EventDisplay
                    children={event}
                />
            </Views.Timeline.Event>
        );
    }

    getFirstDate() {
        let result = '9999-99-99';

        forEach(this.props.events, (entity) => {
            result = parseInt(entity.date.replace(/-/g, '')) < parseInt(result.replace(/-/g, '')) ? entity.date : result;
        });

        result = result + 'T00:00:00.000Z';
        return result;
    }

    render() {
        const now = new Date();
        return (
            <div style={ { width: '1400px' } }>
                <Views.Timeline.Display start={ this.getFirstDate()} end={now.toISOString() }>
                    <Views.Timeline.Rule unit="year" />
                    { this.props.events.map(this.renderEvent.bind(this)) }
                </Views.Timeline.Display>
            </div>
        );
    }
}