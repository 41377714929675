// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import Reech from './reech';
import Deegger from './deegger';

import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.dnaSettings }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.settings';

    static propTypes = {
        store: PropTypes.object.isRequired,
    };


    render () {
        return (
            <Screen
                path="settings"
                type="workspace"
                select={ this.props.store.selectStage }
                close={ this.props.store.closeStage }

                stages={{
                    reech: {
                        entry: { icon: 'dna.reech.flag', label: 'Reech' },
                        content: Reech,
                    },
                    deegger: {
                        entry: { icon: 'dna.reech.flag', label: 'Deegger' },
                        content: Deegger,
                    },
                }}
            />
        );
    }
}
