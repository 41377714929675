import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import isNumber from 'lodash/isNumber';

const styles = {
    container: {
        borderRadius: '3px',
        width: '36px',
        height: '36px',
        border: '2px solid ' + colors.grey500,
        color: colors.grey500,
        textAlign: 'center',
        lineHeight: '36px',
        fontSize: '20px',
        background: 'white',
        position: 'relative',
    },
    esgBlock: {
        position: 'absolute',
        bottom: '-35px',
        right: '0',
        display: 'flex',
        fontSize: '17px',
    },
    esgLabel: {
        color: colors.grey500,
        marginRight: '5px',
    },
    esgValue: {
        color: colors.userCold,
        textTransform: 'uppercase',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.ratingesg';

    static propTypes = {
        value: PropTypes.string,
        style: PropTypes.object,
    };

    render() {
        const color = this.props.value ===  'A' ? colors.userGreen : this.props.value ===  'N/A' ? colors.userWarning : colors.userHot;

        return (
            <div style={Object.assign({}, styles.container, this.props.style || {}, { color, borderColor: color })}>
                {this.props.value}
            </div>
        );
    }
}
