import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import uniq from 'lodash/uniq';

const styles = {
    container: {
        display: 'inline'
    },
    label: {
        float: 'left',
        textAlign: 'right',
        marginRight: '10px',
        color: colors.userCold,
        lineHeight: '40px'
    },
    highlightedLabel: {
        color: colors.userCold
    },
    reset: {
        float: 'left',
        fill: colors.userHot,
        cursor: 'pointer',
        margin: '7px 0 0 -33px'
    },
    widget: {
        float: 'left',
        borderWidth: '0',
        padding: '0 10px',
        lineHeight: '40px',
        boxSizing: 'border-box',
        height: '40px',
        borderRadius: '3px',
        background: colors.grey200,
        color: colors.grey500,
        marginRight: '35px',
        outline: 'none',
        fontSize: '15px'
    },
    highlightedWidget: {
        background: colors.userCold,
        color: 'white'
    },
    text: {
        width: '200px'
    },
    select: {
        maxWidth: '80%'
    },
    rangeText: {
        width: '50px',
        marginRight: '5px',
        textAlign: 'center'
    },
    rangeSelect: {
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.explorer.search.form.criteria.option';

    static propTypes = {
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
        widget: PropTypes.oneOf([ 'range', 'text', 'select' ]).isRequired,
        options: PropTypes.array,
        onChange: PropTypes.func.isRequired
    };

    render () {
        const highlighted = this.isHighlighted();

        return (
            <div style={ styles.container }>
                <label style={ Object.assign({}, styles.label, highlighted ? styles.highlightedLabel : {}) }>
                    { this.props.label }
                </label>

                { this.renderWidget(highlighted) }
                { this.renderReset(highlighted) }
            </div>
        );
    }

    isHighlighted () {
        switch (this.props.widget) {
            case 'range':
                return this.props.value && this.props.value.split(':')[0];

            case 'text':
            case 'select':
                return !! this.props.value;
        }

        return false;
    }

    renderWidget (highlighted) {
        switch (this.props.widget) {
            case 'range':
                let value = this.props.value ? this.props.value.split(':') : ['', '5'];
                if (value.length < 2) value.push('5');

                return [(
                    <input
                        key="input"
                        style={ Object.assign({}, styles.widget, styles.rangeText, highlighted ? styles.highlightedWidget : {}) }
                        type="number"
                        min="10"
                        max="100"
                        value={ value[0] }
                        onChange={ event => this.props.onChange(event.target.value + ':' + value[1]) }
                    />
                ), (
                    <select
                        key="select"
                        style={ Object.assign({}, styles.widget, styles.rangeSelect, highlighted ? styles.highlightedWidget : {}) }
                        value={ value[1] }
                        onChange={ event => this.props.onChange(value[0] + ':' + event.target.value) }
                    >
                        {this.renderOptionsList()}
                    </select>
                )];

            case 'text':
                return (
                    <span>
                        <input
                            style={ Object.assign({}, styles.widget, styles.text, highlighted ? styles.highlightedWidget : {}) }
                            type="text"
                            value={ this.props.value || '' }
                            onChange={ event => this.props.onChange(event.target.value) }
                            list={ this.props.values ? 'explorer-search-names' : undefined }
                        />
                        { this.renderDataList() }
                    </span>
                );

            case 'select':
                return (
                    <select
                        style={ Object.assign({}, styles.widget, styles.select, highlighted ? styles.highlightedWidget : {}) }
                        value={ this.props.value || this.props.options[0].value }
                        onChange={ event => this.props.onChange(event.target.value) }
                    >
                        {this.renderOptionsList()}
                    </select>
                );
        }
    }

    renderReset (highlighted) {
        if (! highlighted) {
            return null;
        }

        return (
            <Icon
                type="do.clear"
                style={ styles.reset }
                onClick={ event => this.props.onChange(undefined) }
            />
        );
    }

    renderDataList () {
        if (! this.props.values) {
            return null;
        }

        return (
            <datalist id="explorer-search-names">
                {
                    uniq(this.props.values).map(value => (
                        <option key={ value } value={ value }>
                            { value }
                        </option>
                    ))
                }
            </datalist>
        );
    }

    renderOptionsList () {
        if (! this.props.options) {
            return null;
        }

        const known = [];
        const items = [];

        for (const option of this.props.options) {
            if (known.includes(option.value)) {
                continue;
            }

            known.push(option.value);
            items.push(<option key={ option.value } value={ option.value }>{ option.label }</option>);
        }

        return items;
    }
}
