import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import get from 'lodash/get';

const HEADER = {
    assesment: 'Assesment',
};

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
    },
    block: {
        border: '1px solid ' + colors.grey300,
        width: '50%',
    },
    props: {
        position: 'relative',
        width: '50%',
    },
    propsList: {
        margin: '20px',
    },
    propsButton: {
        position: 'absolute',
        top: '20px',
        left: '-65px',
    },
    rating: {
        borderBottom: '1px solid ' + colors.grey300,
        display: 'flex',
        height: '70px',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    ratingTitle: {
        marginLeft: '20px',
        width: '60px',
        color: colors.userCold,
    },
    ratingDesc: {
        width: '370px',
        fontSize: '12px',
        color: colors.grey500,
    },
    ratingScore: {
        width: '60px',
        color: colors.grey500,
        fontSize: '19px',
    },
    ratingValue: {
        width: '70px',
        background: colors.userGreen,
        color: 'white',
        fontSize: '32px',
        textAlign: 'center',
        lineHeight: '60px',
        marginRight: '15px',
        borderRadius: '3px',
    },
    ratingPlus: {
        position: 'absolute',
        top: '0px',
        right: '652px',
    },
    ratingMinus: {
        position: 'absolute',
        top: '0px',
        right: '588px',
    },
    esgPlus: {
        position: 'absolute',
        top: '0px',
        right: '50px',
    },
    esgMinus: {
        position: 'absolute',
        top: '0px',
        right: '-15px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.header';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onSetHeader: PropTypes.func,
        onSetRating: PropTypes.func,
    };

    renderInfo(rate) {
        switch (rate) {
            case 1:
                return (
                    <div style={styles.ratingDesc}>
                        Asset Management Company or fund with almost no concern
                        <br />
                        and vouched for the ABN AMRO group
                    </div>
                );
            case 2:
                return (
                    <div style={styles.ratingDesc}>
                        Asset Management Company or fund with minor concerns
                        <br />
                        and vouched for the group
                    </div>
                );
            case 3:
                return (
                    <div style={styles.ratingDesc}>
                        Asset Management Company or fund with concerns
                        <br />
                        but vouched for the group
                    </div>
                );
            case 4:
                return (
                    <div style={styles.ratingDesc}>
                        Asset Management Company or fund
                        <br />
                        only vouched for AAIS internal needs
                    </div>
                );
            case 5:
                return <div style={styles.ratingDesc}>Veto</div>;
        }
    }

    render() {
        const baseRating = get(this.props.entity, 'computed.scoring.rating', 5);
        const realRating = Math.max(0, Math.min(5, Math.round(this.props.entity.payload.rating || baseRating)));
        const baseEsg = String.fromCharCode(64 + get(this.props.entity, 'computed.scoringEsg.rating', 5));
        const realEsg = get(this.props.entity.payload, 'header.esg', baseEsg).toUpperCase();
        const frozen = ['approved', 'confirmed', 'ignoredRedFlag'].includes(this.props.entity.status);

        const ratingColor = () => {
            switch (realRating) {
                case 1:
                    return '#5f924f';
                case 2:
                    return '#a6c65b';
                case 3:
                    return '#e2b65e';
                case 4:
                    return '#c76941';
                case 5:
                    return '#bf3f39';
                default:
            }
        };

        const ratingColorEsg = () => {
            switch (realEsg.charCodeAt(0)) {
                case 65:
                    return '#5f924f';
                case 66:
                    return '#a6c65b';
                case 67:
                    return '#e2b65e';
                case 68:
                    return '#c76941';
                case 69:
                    return '#bf3f39';
                default:
            }
        };

        return (
            <div style={styles.container}>
                <div style={styles.block}>
                    <div style={styles.rating}>
                        <div style={styles.ratingTitle}>Rating</div>

                        {this.renderInfo(realRating)}

                        {baseRating !== realRating ? (
                            <div style={styles.ratingScore}>{baseRating}&nbsp;&nbsp;&nbsp;➝</div>
                        ) : null}

                        <div style={Object.assign({}, styles.ratingValue, { background: ratingColor() })}>
                            {realRating}
                        </div>
                    </div>
                </div>

                <div style={styles.block}>
                    <div style={styles.rating}>
                        <div style={styles.ratingTitle}>ESG</div>
                        <div style={styles.ratingDesc} />

                        {baseEsg !== realEsg ? (
                            <div style={styles.ratingScore}>{baseEsg}&nbsp;&nbsp;&nbsp;➝</div>
                        ) : null}

                        <div style={Object.assign({}, styles.ratingValue, { background: ratingColorEsg() })}>
                            {realEsg}
                        </div>
                    </div>
                </div>

                {frozen ? null : (
                    <span>
                        <Views.Common.Button
                            type="round"
                            color="cold"
                            disabled={realRating <= 1}
                            mini
                            style={styles.ratingPlus}
                            icon={<Icon type="nav.level.plus" />}
                            onClick={
                                this.props.onSetRating ? event => this.props.onSetRating(realRating - 1) : undefined
                            }
                        />

                        <Views.Common.Button
                            type="round"
                            color="cold"
                            disabled={realRating >= 5}
                            mini
                            style={styles.ratingMinus}
                            icon={<Icon type="nav.level.minus" />}
                            onClick={
                                this.props.onSetRating ? event => this.props.onSetRating(realRating + 1) : undefined
                            }
                        />

                        <Views.Common.Button
                            type="round"
                            color="cold"
                            disabled={realEsg.charCodeAt(0) <= 65}
                            mini
                            style={styles.esgPlus}
                            icon={<Icon type="nav.level.plus" />}
                            onClick={
                                this.props.onSetHeader
                                    ? e =>
                                          this.props.onSetHeader(
                                              Object.assign({}, this.props.entity.payload.header, {
                                                  esg: String.fromCharCode(
                                                      realEsg.charCodeAt(0) <= 65 ? 65 : realEsg.charCodeAt(0) - 1,
                                                  ),
                                              }),
                                          )
                                    : undefined
                            }
                        />

                        <Views.Common.Button
                            type="round"
                            color="cold"
                            disabled={realEsg.charCodeAt(0) >= 69}
                            mini
                            style={styles.esgMinus}
                            icon={<Icon type="nav.level.minus" />}
                            onClick={
                                this.props.onSetHeader
                                    ? e =>
                                          this.props.onSetHeader(
                                              Object.assign({}, this.props.entity.payload.header, {
                                                  esg: String.fromCharCode(
                                                      realEsg.charCodeAt(0) >= 69 ? 69 : realEsg.charCodeAt(0) + 1,
                                                  ),
                                              }),
                                          )
                                    : undefined
                            }
                        />
                    </span>
                )}
            </div>
        );
    }
}
