// @flow

export default {
    type: 'menu',
    pinned: [{
        type: 'templates',
        pinned: true
    },{
        type: 'questions',
        pinned: true
    }],
    prototype: {
        type: 'router',
        routes: {
            questions: {pinned: { type: 'boolean' }},
            templates: {
                list: { type: 'list' },
                pinned: { type: 'boolean' },
                },
            display: {
                id: { type: 'string' },
                tab: { type: 'string' },
                list: { type: 'list' },
            },
        }
    }
};
