import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import colors from 'app/theme/colors';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        margin: '-10px',
        alignItems: 'flex-start',
    },
    table: {
        display: 'table',
        gridTemplateColumns: '40px 1fr 2fr',
        gridGap: '1px',
        background: colors.grey300,
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
    },
    head: {
        display: 'table-cell',
        color: colors.grey600,
        padding: '5px',
    },
    cell: {
        display: 'table-cell',
        padding: '5px',
        background: colors.grey100,
    },
    row: {
        display: 'table-row',
    }
};

@inject(stores => ({ store: stores.dnaSettings }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.settings.deegger';

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    render() {
        const sources = toJS(this.props.store.deeggerSources);

        if (!sources) {
            return <Views.Common.Loader title="Loading information" />;
        }

        return (
            <div style={this.props.style}>
                <div style={styles.container}>
                    <div style={styles.table}>
                        <div key="head" style={styles.row}>
                            <div style={styles.head}>Source</div>
                            <div style={styles.head}>Initialisée</div>
                            <div style={styles.head}>Dernière MAJ</div>
                            <div style={styles.head}>Dernière MAJ</div>
                        </div>

                        {sources.map(source => (
                            this.renderAction(source)
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    renderAction(source){
        return (
            <div key={source.code} style={styles.row} >
                <div style={styles.cell}>{source.name}</div>
                <div style={styles.cell}>
                    {this.initialize(source)}
                </div>
                <div style={styles.cell}>
                    {this.update(source)}
                </div>
                <div style={styles.cell}>
                    {source.last_update}
                </div>
            </div>
        );
    }

    initialize(source){
        if (source.is_initialized === false){
            return <button onClick={(e) => this.props.store.deeggerExecInitialize(source.code)}>Init</button>;
        } else if (source.is_initialized === true){
            return <button onClick={(e) => this.props.store.deeggerExecReset(source.code)}>Reset</button>;
        }
    }

    update(source){
        return <button onClick={(e) => this.props.store.deeggerExecUpdate(source.code)}>Update</button>
    }
}
