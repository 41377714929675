import React from 'react';
import PropTypes from 'prop-types';
import Base from './base';

const styles = {
    content: {},
    picture: {
        margin: '20px',
    },
    text: {
        margin: '20px',
        whiteSpace: 'pre-wrap',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.text';

    static propTypes = {
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        picture: PropTypes.string,
        data: PropTypes.object,
        onSave: PropTypes.func,
        onUpload: PropTypes.func,
    };

    render() {
        const fields = [
            {
                label: this.props.title,
                path: this.props.path,
                widget: { type: 'text', rows: 10 },
            },
        ];

        if (this.props.picture && this.props.onUpload) {
            fields.push({
                label: this.props.title + ' graph',
                path: this.props.picture,
                widget: 'file',
                onAsync: async data => {
                    return await this.props.onUpload(this.props.picture, data);
                },
            });
        }

        return (
            <Base title={this.props.title} fields={fields} data={this.props.data} onSave={this.props.onSave}>
                {this.renderContent()}
            </Base>
        );
    }

    renderContent() {
        const data = this.props.data || {};
        const picture = data[this.props.picture];
        const text = data[this.props.path];

        return (
            <div style={styles.content}>
                {picture ? <img style={{ width: '100%' }} src={picture} /> : null}
                <div style={styles.text}>{text}</div>
            </div>
        );
    }
}
