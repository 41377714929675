// @flow

import type { EntitySource, CompanyData, CompanyPreview, CompanyActivity } from '@deecision/deefind-types/model';

import { cleanCompanyData, cleanCompanyPreview } from '../clean';
import makeEntityActivity from '../make/entity/activity';
import makeEntityGics from '../make/entity/gics';
import utils from '../index';
import slugify from 'speakingurl';
const crisisImpactedNace = [
    '291',
    '292',
    '293',
    '303',
    '411',
    '451',
    '453',
    '454',
    '475',
    '476',
    '477',
    '478',
    '479',
    '491',
    '492',
    '493',
    '494',
    '495',
    '501',
    '502',
    '503',
    '504',
    '511',
    '512',
    '522',
    '551',
    '552',
    '553',
    '559',
    '561',
    '562',
    '563',
    '591',
    '641',
    '643',
    '649',
    '663',
    '681',
    '771',
    '772',
    '773',
    '781',
    '782',
    '783',
    '791',
    '799',
    '823',
    '869',
    '881',
    '900',
    '910',
    '931',
    '932',
];

export function normCompanyPreview(preview: Object): CompanyPreview {
    preview = cleanCompanyPreview(preview);
    preview.activity = makeEntityActivity(preview.naceCode);
    preview.gicsCode = makeEntityGics(preview.naceCode);

    const flags = preview.flags || [];
    const naceCode = preview.naceCode || '';

    if (!flags.includes('listed') && preview.isins && preview.isins.length) {
        flags.push('listed');
    }

    if (!flags.includes('closed') && preview.closed) {
        flags.push('closed');
    }

    if (
        (!flags.includes('holding') && naceCode.substr(0, 3) === '642') ||
        naceCode.substr(0, 4) === '7022' ||
        slugify(preview.name || '')
            .split('-')
            .includes('holding')
    ) {
        flags.push('holding');
    }

    if (
        !flags.includes('crisis-impact') && crisisImpactedNace.includes(naceCode.substr(0, 3))
    ) {
        flags.push('crisis-impact');
    }

    if (!flags.includes('fund') && naceCode.substr(0, 3) === '643') {
        flags.push('fund');
    }

    if (!flags.includes('bank') && ['6411', '6419'].includes(naceCode.substr(0, 4))) {
        flags.push('bank');
    }
    if (!flags.includes('central-bank') && ['6411'].includes(naceCode.substr(0, 4))) {
        flags.push('central-bank');
    }

    if (!flags.includes('property') && (naceCode.substr(0, 3) === '681') || naceCode.substr(0, 3) === '682') {
        flags.push('property');
    }

    preview.flags = flags;

    return preview;
}

export function normCompanyData(data: Object, source?: EntitySource): CompanyData {
    data = cleanCompanyData(data, source);
    data.activities = data.activities.map((activity: CompanyActivity) =>
        Object.assign({}, activity, {
            gicsCode: utils.makeEntityGics(activity.naceCode),
            code: utils.makeEntityActivity(activity.naceCode),
        }),
    );

    return data;
}
