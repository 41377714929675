// @flow

import explorerScope from './explorer/scope';
import detectorScope from './detector/scope';
import settingsScope from './settings/scope';

export default {
    type: 'object',
    children: {
        explorer: explorerScope,
        detector: detectorScope,
        settings: settingsScope,
    }
};
