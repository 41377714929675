import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    input: {
        flexGrow: '1',
        flexShrink: '1',
        lineHeight: '18px',
        fontSize: '17px',
        padding: '5px 10px',
        background: 'transparent',
        borderWidth: '0 0 0 1px',
        borderLeft: '1px solid ' + colors.grey300,
        outline: 'none'
    },
    integer: {
        textAlign: 'right'
    },
    hover: {
        background: colors.grey100
    },
    focus: {
        background: colors.grey300,
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.collection.field';

    static propTypes = {
        type: PropTypes.oneOf([ 'string', 'integer', 'select' ]).isRequired,
        data: PropTypes.any,
        options: PropTypes.object.isRequired,
        style: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func.isRequired
    };

    state = {
        hover: false,
        focus: false
    };

    normalize (data) {
        switch (this.props.type) {
            case 'string':
            case 'select':
                if (_.isString(data)) return data;
                return undefined;

            case 'integer':
                if (_.isNumber(data)) return Math.round(data);
                if (_.isString(data)) {
                    const value = data.replace(/[^0-9]+/, '');
                    return value ? parseInt(value) : undefined;
                }
                return undefined;

            case 'boolean':
                return !!data;
        }

        return data;
    }

    render () {
        const style = Object.assign({},
            styles.input,
            styles[ this.props.type ],
            this.state.focus ? styles.focus : (this.state.hover ? styles.hover : {}),
            this.props.style
        );

        const props = {
            style: style,
            value: this.normalize(this.props.data),
            disabled: !!this.props.options.disabled,
            onMouseEnter: event => this.setState({ hover: true }),
            onMouseLeave: event => this.setState({ hover: false }),
            onChange: event => this.props.onChange(this.normalize(event.target.value)),
            onBlur: event => this.setState({ focus: false }),
            onFocus: event => {
                this.setState({ focus: true });
                this.props.onFocus(event);
            }
        };

        switch (this.props.type) {
            case 'string':
            case 'integer':
                return (
                    <input { ...props } />
                );

            case 'select':
                return (
                    <select { ...props }>
                        <option value={ undefined }></option>
                        { _.map(this.props.options.options, (label, value) => (
                            <option key={ value } value={ value }>{ label }</option>
                        )) }
                    </select>
                );

            case 'boolean':
                return (
                    <checkbox { ...props } />
                );

            default:
                throw new Error(`Unsupported collection field type "${this.props.type}".`);
        }
    }
}
