import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        flexGrow: '0',
        flexShrink: '0',
        borderRadius: '0 3px 3px 0',
        borderLeft: '1px solid ' + colors.grey300,
        padding: '3px 5px',
        width: '24px',
        height: '24px',
        cursor: 'pointer'
    },
    icon: {
        fill: colors.userHot,
        width: '24px',
        height: '24px'
    },
    hover: {
        background: colors.userHot
    },
    empty: {
        background: 'transparent',
        cursor: 'default'
    },
    hoverIcon: {
        fill: 'white'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.collection.actions';

    static propTypes = {
        empty: PropTypes.bool,
        onRemove: PropTypes.func.isRequired
    };

    state = {
        hover: false
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.state.hover ? styles.hover : {},
            this.props.empty ? styles.empty : {}
        );

        const props = { style };

        if (! this.props.empty) {
            props.onMouseEnter = event => this.setState({ hover: true });
            props.onMouseLeave = event => this.setState({ hover: false });
            props.onClick = this.props.onRemove;
        }

        return (
            <div { ...props }>
                { this.renderIcon() }
            </div>
        );
    }

    renderIcon () {
        if (this.props.empty)
            return null;

        const style = Object.assign({},
            styles.icon,
            this.state.hover ? styles.hoverIcon : {}
        );

        return (
            <Icon type="do.delete" style={ style } />
        );
    }
}
