// @flow

import type { VigilanceSetup, EntityType, VigilanceReportDirect, FileAttachment } from '@deecision/deefind-types/model';

import { makeBlock, makeList } from './score';

export default function makeVigilanceDirect(
    setup: VigilanceSetup,
    type: EntityType,
    preview: Object,
    files: FileAttachment[],
): VigilanceReportDirect {
    const profile = {
        countries: preview.country ? [preview.country] : [],
        activities: preview.naceCode ? [preview.naceCode] : [],
        mandates: preview.mandates || 0,
        listedRelations: files
            .filter((file: FileAttachment) => file.data.type === 'mandate')
            .map((file: FileAttachment) => file.id),
        positions: preview.positions || 0,
        politic: files
            .filter((file: FileAttachment) => file.data.type === 'politic')
            .map((file: FileAttachment) => file.id),
        justice: files
            .filter((file: FileAttachment) => file.data.type === 'justice')
            .map((file: FileAttachment) => file.id),
        publication: preview.publication,
    };

    const scores = {
        country: makeListScore('country', setup, profile.countries),
        activity: makeListScore('activity', setup, profile.activities),
        mandates: makeMandatesScore(setup, profile.mandates),
        listedRelations: profile.listedRelations.length ? 2 : 1,
        politic: profile.politic.length ? 3 : 1,
        justice: profile.justice.length ? 3 : 1,
        publication: makePublicationScore(profile.publication)
    };

    return {
        country: {
            score: scores.country,
            codes: profile.countries,
        },
        activity: {
            score: scores.activity,
            codes: profile.activities,
        },
        mandates: {
            score: scores.mandates,
            mandates: profile.mandates,
            positions: profile.positions,
        },
        listedRelations: {
            score: scores.listedRelations,
            files: profile.listedRelations,
        },
        politic: {
            score: scores.politic,
            files: profile.politic,
        },
        justice: {
            score: scores.justice,
            files: profile.justice,
        },
        publication: {
            score: scores.publication,
            date: profile.publication,
        },
        score: makeBlock([scores.country, scores.activity, scores.mandates, scores.politic, scores.justice, scores.listedRelations, scores.publication]),
    };
}

function makePublicationScore(year: number): number{
    const currentYear = new Date().getFullYear();
    if (currentYear - year >= 4){
        return 3;
    } else if(currentYear - year >= 3){
        return 2;
    }
    return 1;
}

function makeMandatesScore(setup: VigilanceSetup, mandates: number): number {
    return mandates >= setup.mandates.veto
        ? 4
        : mandates >= setup.mandates.alert
        ? 3
        : mandates >= setup.mandates.warn
        ? 2
        : 1;
}

function makeListScore(type: 'country' | 'activity', setup: VigilanceSetup, codes: string[]): number {
    if (!codes.length) {
        return 0;
    }

    let scores: number[] = [];

    for (const code of codes) {
        for (const list of setup.lists) {
            if (list.codes.includes(code)) {
                scores.push(list.score);
            }
        }
    }

    if (codes.includes('us') && setup.usa) {
        scores.push(setup.usa);
    }

    return makeList(scores);
}
