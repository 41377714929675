// @flow

export default {
    'account executive': 'o',
    advisor: 'a',
    'advisor to the executive board': 'a',
    agent: 'o',
    analyst: 'o',
    associate: 'x',
    'associate broker/realtor': 'o',
    'associate general counsel': 'a',
    'board of trustees member': 'b',
    'business develoment consultant': 'o',
    'business director': 'x',
    'business manager': 's',
    'business partner': 'o',
    'calendar clerk': 'o',
    'chair executive and global compensation committee': 'a',
    'chairman (compensation committee)': 'x',
    'chairman (remuneration committee)': 'x',
    'chairman (risk committee)': 'a',
    'chairman of the board of directors': 'b',
    'chairman of the supervisory board': 'b',
    'chairperson audit committee': 'a',
    'chairperson strategic planning and finance committee': 'a',
    'chairwoman audit committee': 'x',
    'chairwoman governance and ethics committee': 'x',
    'chief accounting officer': 'x',
    'chief administrative and financial officer': 'x',
    'chief administrative and sales officer': 'x',
    'chief branch officer': 'x',
    'chief claims officer': 'o',
    'chief competitive officer': 'x',
    'chief economist': 'x',
    'chief executive': 'x',
    'chief executive officer': 'x',
    'chief export officer': 'x',
    'chief human resource officer': 'x',
    'chief legal officer': 'x',
    'chief logistic officer': 'x',
    'chief of staff': 'x',
    'chief operating officer': 'x',
    'chief production officer': 'x',
    'chief sales officer': 'x',
    'chief technical officer': 'x',
    'co founder': 'x',
    'co manager': 'm',
    'communications officer': 'o',
    'company secretary': 'x',
    'consulting member of technical staff': 'a',
    'corporate counsel': 'a',
    'deputy chief director of general affairs & human re': 'x',
    'deputy chief executive officer': 'x',
    'deputy director': 'b',
    director: 'b',
    'director representing employees': 'b',
    'director representing employees shareholders': 'b',
    'executive administrator': 'x',
    'executive advisor to the chairman': 'a',
    'executive communications advisor': 'a',
    'executive director': 'x',
    'financial market advisory': 'o',
    founder: 'x',
    'general manager': 's',
    'general services executive': 'x',
    'global alliance management': 'o',
    'global head of multi asset strategies': 'x',
    'group managing director': 'x',
    'head of business development': 'm',
    'head of development and acquisitions': 'x',
    'head of europe, middle east and africa': 'x',
    'head of multi asset strategies and global fixed income': 'x',
    'independant aministrator': 'o',
    'independant director bord of directors': 'b',
    'information technology infrastructure coordinator': 'o',
    'information technology project manager': 'o',
    'intellectual property counsel': 'a',
    'investment banker': 'o',
    'investment manager': 'o',
    'lead director': 'b',
    'lead independant director board of directors': 'b',
    'legal representative': 'a',
    'licensed real estate agent': 'o',
    'logistics team member': 'o',
    'machinist apprentice': 'o',
    'management assistant': 'o',
    'manager, audit and compliance': 'a',
    'managing director': 'x',
    'managing executive officer': 'x',
    'media relations advisor': 'a',
    'member audit committee)': 'a',
    'member board affairs committee)': 'a',
    'member compensation committee)': 'a',
    'member compensation nominating and governance committee': 'a',
    'member executive management)': 'a',
    'member governance committee)': 'a',
    'member leadership development and compensation committee': 'a',
    'member management committee)': 'a',
    'member nominating and corporate governance committee': 'a',
    'member public issues and contributions committee)': 'a',
    'member public responsibilities committee)': 'a',
    'member strategic planning and finance committee': 'a',
    'member technology and ecommerce committee': 'a',
    'member of technical staff': 'o',
    'office administrator': 'o',
    'office manager': 'o',
    'office supervisor': 'o',
    officer: 'o',
    'on boarding specialist': 'o',
    'operating officer': 'o',
    'organizational development manager': 'm',
    owner: 'i',
    'partner development manager': 'x',
    'partner marketing manager': 'x',
    'pension trustee': 'b',
    president: 'x',
    'presiding director': 'b',
    'press officer': 'o',
    'principal member of technical staff': 'o',
    'program specialist': 'o',
    'project manager': 'o',
    'quality control exclusive': 'o',
    'real estate agent': 'o',
    'real estate sales': 'o',
    'realtor associate': 'o',
    'realtor associate broker': 'o',
    'sale associate': 'o',
    'secretary to the board': 'b',
    'senior director': 'm',
    'senior finance manager': 'm',
    'senior managing executive officer': 'x',
    'senior member of technical staff': 'o',
    'senior producer': 'o',
    'senior recruiter': 'o',
    'senior solutions engineer and architect': 'o',
    shareholder: 's',
    'shareholder (holding)': 'i',
    'smb associate account manager': 'o',
    'sole administrator': 'b',
    'strategic advisor': 'a',
    'system engineer': 'o',
    'tax counsel': 'a',
    treasurer: 'x',
    'vice president engineering': 'x',
    'vice president studies': 'o',
    'vice chairman of the supervisory board': 'b',
    'vice president': 'm',
    'works executive': 'o',
};
