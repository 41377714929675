import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.model.event';

    static propTypes = {
        id: PropTypes.string.isRequired,
        date: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
        icon: PropTypes.element,
        dimmed: PropTypes.bool
    };

    render () {
        throw new Error(`Model component must not be rendered.`);
    }
}
