import React from 'react';
import PropTypes from 'prop-types';
import Button from 'app/views/common/button';
import xlsx from 'xlsx';

const styles = {
    container: {
        textAlign: 'center',
        margin: '20px 0 10px 0',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.settings.reech.upload';

    static propTypes = {
        onUpload: PropTypes.func.isRequired,
    };

    state = { processing: false };

    render() {
        return (
            <div style={styles.container}>
                <Button
                    type="raised"
                    color="cold"
                    label={ this.state.processing ? 'Processing file data' : 'Upload CSV or XLS file' }
                    onClick={event => this.refs.upload.click()}
                    style={styles.button}
                    disabled={this.state.processing}
                />

                <input
                    ref="upload"
                    type="file"
                    style={{ visibility: 'hidden', position: 'fixed', top: '-999px' }}
                    onChange={event => this.handleUpload(event)}
                />
            </div>
        );
    }

    handleUpload(event: any) {
        this.setState({ processing: true });

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
            try {
                const book = xlsx.read(event.target.result, { type: 'binary' });
                const sheet = book.Sheets[book.SheetNames[0]];
                const data = xlsx.utils.sheet_to_json(sheet, { header: 1 });
                await this.props.onUpload(data);
            } catch (error) {
                console.error(error);
            }

            this.setState({ processing: false });
        };

        reader.readAsBinaryString(file);
    }
}
