import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const MARGIN = 2;

const styles = {
    container: {
        position: 'absolute',
        top: (MARGIN - 1) + 'px',
        background: colors.userCold,
        borderRadius: '50%',
        cursor: 'pointer',
        border: '1px solid white',
        zIndex: 'auto',
    },
    highlighted: {
        background: colors.userHot,
        zIndex: 2000,
    },
    dimmed: {
        opacity: '0.5'
    },
    icon: {
        fill: 'white',
        width: '12px',
        height: '12px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.header.point';

    static propTypes = {
        size: PropTypes.number,
        position: PropTypes.number.isRequired,
        tooltip: PropTypes.node,
        icon: PropTypes.element,
        dimmed: PropTypes.bool,
        highlighted: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func
    };

    render () {
        const size = {
            left: this.props.position + '%',
            width: (this.props.size - MARGIN * 2) + 'px',
            height: (this.props.size - MARGIN * 2) + 'px',
            marginLeft: (- Math.round((this.props.size - MARGIN * 2) / 2) - 1) + 'px'
        };

        const style = Object.assign({},
            styles.container,
            size,
            this.props.highlighted ? styles.highlighted : {},
            this.props.dimmed ? styles.dimmed : {}
        );

        return (
            <div
                style={ style }
                onMouseEnter={ this.props.onMouseEnter }
                onMouseLeave={ this.props.onMouseLeave }
            >
                { this.renderIcon()}
                { this.renderTooltip()}
            </div>
        );
    }

    renderIcon () {
        if (! this.props.icon)
            return null;

        if (this.props.size < 15)
            return null;

        return React.cloneElement(this.props.icon, {
            style: Object.assign({},
                styles.icon,
                { margin: `${(this.props.size - 12) / 2}px` }
            )
        });
    }

    renderTooltip () {
        if (! this.props.tooltip)
            return null;

        return null;
    }
}
