import type { SdkFile, SdkMake } from '@deecision/infra-types/client/sdk';


export default function (make: SdkMake): SdkFile {
    async function locate(service: string, bucket: string, id: string): Promise<string> {
        return make({ type: 'locate', service, bucket, id });
    }

    async function decode(data: any): Promise<string> {
        if (typeof data === 'string') {
            return data;
        }

        if (data instanceof File) {
            const reader = new FileReader();
            const promise = new Promise((resolve, reject) => {
                reader.onerror = async error => reject(error);
                reader.onload = async event => resolve(event.target.result);
            });
            reader.readAsArrayBuffer(data);
            return promise;
        }

        throw new Error(`Invalid data provided to sdk.file.upload().`);
    }

    return {
        locate(service: string, bucket: string, id: string): Promise<string> {
            return locate(service, bucket, id);
        },

        async upload(service: string, bucket: string, id: string, data: any): Promise<void> {
            const url = await locate(service, bucket, id);
            const options = { method: 'POST', body: await decode(data), mode: 'cors' };
            let trial = 0;

            while (trial < 3) {
                const response: Response = await fetch(url, options);
                if (response.status < 299) return;
                trial ++;
            }

            throw new Error('Upload failed 3 times.');
        },

        async download(service: string, bucket: string, id: string): Promise<void> {
            const url = await locate(service, bucket, id);
            const link = window.document.createElement('a');
            link.setAttribute('href', url);
            // link.setAttribute('target', '_blank');
            // link.setAttribute('download', name);
            window.document.body.appendChild(link);
            link.click();
            window.document.body.removeChild(link);
            URL.revokeObjectURL(url);
        },
    };
}
