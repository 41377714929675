import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import Display from '../../common/display';
import Flag from '../../common/flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.compliance.typologies.fr.challenges.f500.display';

    static propTypes = {
        relevance: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        relations: PropTypes.array,
    };

    render() {
        return (
            <Display
                flag={<Flag char="F" shape="round" color="hot" type="full" />}
                relevance={this.props.relevance}
                picture={this.props.data.picture}
                relations={this.props.relations}
                title={this.props.data.subheading}
                name={this.props.data.heading}
                contents={[this.props.data.biography]}
                sources={[this.props.data.source]}
                properties={[
                    {
                        label: 'Value',
                        value: <Views.Common.Number type="currency" scale="M" value={this.props.data.value} />,
                    },
                    {
                        label: 'Rank',
                        value: this.props.data.rank + ' / 500',
                    },
                    {
                        label: 'Company',
                        value: this.props.data.company,
                    },
                    {
                        label: 'Sector',
                        value: this.props.data.sector,
                    },
                    {
                        label: 'Since',
                        value: this.props.data.since,
                    },
                ]}
            />
        );
    }
}
