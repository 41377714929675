import getMuiTheme from 'material-ui/styles/getMuiTheme';
import spacing from 'material-ui/styles/spacing';
import colors from 'app/theme/colors';
import * as manipulator from 'material-ui/utils/colorManipulator';

const theme = getMuiTheme({
    spacing: spacing,
    fontFamily: 'Roboto, sans-serif',
    palette: {
        primary1Color: colors.userCold,
        primary2Color: colors.userCold,
        primary3Color: colors.lightBlack,
        accent1Color: colors.userHot,
        accent2Color: colors.grey100,
        accent3Color: colors.grey500,
        textColor: colors.userText,
        alternateTextColor: colors.white,
        canvasColor: colors.white,
        borderColor: colors.grey300,
        disabledColor: manipulator.fade(colors.darkBlack, 0.3),
        pickerHeaderColor: colors.userCold
    },
});

theme.checkbox.boxColor = colors.grey400;

theme.zIndex = {
    menu: 2100, //menu: 1000,
    appBar: 1100,
    leftNavOverlay: 1200,
    leftNav: 1300,
    dialogOverlay: 1400,
    dialog: 2100, //dialog: 1500,
    layer: 2100, // layer: 2000,
    popover: 2100,
    snackbar: 2900,
    tooltip: 3000
};

export default theme;
