import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';

const styles = {
    container: {
        padding: '10px',
        background: 'white',
        fontSize: '15px',
        cursor: 'pointer'
    },
    hover: {
        background: colors.grey100
    },
    header: {},
    date: {
        color: colors.grey500,
        marginRight: '10px'
    },
    status: {
        color: colors.userCold
    },
    value: {
        float: 'right',
        color: colors.userCold,
        fontWeight: 'bold'
    },
    body: {
        color: colors.grey700,
        padding: '2px 0'
    },
    footer: {},
    count: {},
    countValue: {
        color: colors.userCold
    },
    countLabel: {
        color: colors.grey500,
        marginLeft: '5px'
    },
    countSeparator: {
        margin: '0 10px',
        color: colors.grey300
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.tab.deal';

    static propTypes = {
        deal: PropTypes.object.isRequired,
        onOpen: PropTypes.func.isRequired
    };

    state = { hover: false };

    render () {
        const style = this.state.hover
            ? Object.assign({}, styles.container, styles.hover)
            : styles.container;

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onOpen }
            >
                <div style={ styles.header }>
                    <Views.Common.Number
                        type="currency"
                        scale="M"
                        value={ this.props.deal.value }
                        style={ styles.value }
                    />

                    <span style={ styles.date }>
                        { this.props.deal.dates.completion || this.props.deal.dates.announce || this.props.deal.dates.rumour }
                    </span>
                    <span style={ styles.status }>
                        { this.renderStatus() }
                    </span>
                </div>
                <div style={ styles.body }>
                    { this.props.deal.description }
                </div>
                <div style={ styles.footer }>
                    { this.renderCounts() }
                </div>
            </div>
        );
    }

    renderStatus () {
        const status = this.props.deal.status;

        return [status.kind, status.details].filter(v => !! v).join(' — ');
    }

    renderCounts () {
        const results = [];
        const acquirors = this.props.deal.acquirors.length;
        const vendors = this.props.deal.vendors.length;
        const targets = this.props.deal.targets.length;

        if (vendors > 0)
            results.push(this.renderCount(vendors, 'vendor', 'vendors'));

        if (targets > 0) {
            if (vendors > 0) results.push(<span key="sep1" style={ styles.countSeparator }>—</span>);
            results.push(this.renderCount(targets, 'target', 'targets'));
        }

        if (acquirors > 0) {
            if (acquirors > 0 || vendors > 0) results.push(<span key="sep2" style={ styles.countSeparator }>—</span>);
            results.push(this.renderCount(acquirors, 'buyer', 'buyers'));
        }

        return results;
    }

    renderCount (count, singular, plural) {
        return (
            <span key={ plural } style={ styles.count }>
                <span style={ styles.countValue }>
                    { count }
                </span>
                <span style={ styles.countLabel }>
                    { count > 1 ? plural : singular }
                </span>
            </span>
        );
    }
}
