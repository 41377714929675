import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Reech from 'dna/entity/common/reech';
import Opener from 'dna/entity/common/card/opener';
import Relations from 'dna/entity/common/card/relations';

import Header from './card/header';
import Properties from './card/properties';

const styles = {
    reech: {
        position: 'absolute',
        top: '100px',
        right: '170px'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.card';

    static propTypes = {
        parent: PropTypes.object,
        relation: PropTypes.object,
        entity: PropTypes.object,
        progress: PropTypes.object,
    };

    render () {
        return (
            <Views.Common.Card dimmed={ this.props.relation && this.props.relation.ceased }>
                <Header
                    entity={ this.props.entity }
                    parent={ this.props.parent }
                    relation={ this.props.relation }
                />

                <Properties entity={ this.props.entity } />
                <Opener id={ this.props.entity.id } progress={ this.props.progress } />
                <Relations entity={ this.props.entity } />
                <Reech entity={ this.props.entity } style={ styles.reech } flagMode />
            </Views.Common.Card>
        );
    }
}
