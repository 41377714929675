// @flow

import intersection from 'lodash/intersection';

export function findCategory(flags: any) {
    const table = {
        structure: 'Fund Structure',
        process: 'Process',
        organisation: 'Organisation',
        governance: 'Governance',
        transparency: 'Transparency',
        control: 'Control',
        compliance: 'Compliance',
    };

    const values = intersection(flags, Object.keys(table));

    if (!values.length) return null;

    return table[values[0]];
}
