import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const styles = {
    content: {
        lineHeight: '1.5em',
    },
};

let cursor = 1;

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.tooltip';

    static propTypes = {
        content: PropTypes.node,
        place: PropTypes.string,
        style: PropTypes.object,
    };

    constructor (props) {
        super(props);
        this.id = `tooltip_${cursor ++}`;
    }

    render () {
        if (! this.props.content) {
            return this.props.children;
        }

        const child = React.cloneElement(
            React.Children.only(this.props.children),
            { key: 'master', 'data-tip': true, 'data-for': this.id }
        );

        return [
            child,
            <ReactTooltip
                key="slave"
                id={ this.id }
                type="dark"
                effect="solid"
                place={this.props.place}
            >
                <span style={ styles.content }>
                    { this.props.content }
                </span>
            </ReactTooltip>
        ];
    }
}
