import PropTypes from 'prop-types';
import React from 'react';
import Block from './block';
import IdentityBlockCompany from './IdentityBlockCompany';
import IdentityBlockPerson from './IdentityBlockPerson';
import colors from 'app/theme/colors';
import _ from 'lodash';

import News from './news';
import Scope from 'app/layout/providers/scope';
import DeefindProvider from 'app/layout/providers/deefind';

const styles = {
    title: {
        color: colors.userCold,
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    info: {
        color: colors.grey600,
        fontWeight: 'normal',
        paddingTop: '5px',
    },
    details: {
        fontSize: '13px',
        color: colors.grey600,
        lineHeight: '17px',
        paddingTop: '5px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.compliance.report.main';

    static propTypes = {
        global: PropTypes.object.isRequired,
        store: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
        network: PropTypes.object,
    };

    render() {
        const norkomFeature = this.props.global.flags.get().includes('deefind.norkom');

        let identityBlock = '';
        if (this.props.entity.type === 'person') {
            identityBlock = (
                <IdentityBlockPerson title={'Indicateurs de base'} entity={this.props.entity} norkom={norkomFeature} />
            );
        }
        if (this.props.entity.type === 'company') {
            identityBlock = (
                <IdentityBlockCompany title={'Indicateurs de base'} entity={this.props.entity} norkom={norkomFeature} />
            );
        }
        let norkomHighValues = false;
        if (norkomFeature) {
            norkomHighValues = _.orderBy(
                this.props.entity.vigilance.norkom.values.filter(comp => ['HI', 'MH', 'ML'].includes(comp.lvl)),
                'score',
                'desc',
            );
        }

        let filesBlock = '';
        if (this.props.entity.files.length > 0) {
            filesBlock = (
                <Block
                    title="Fiches conformités"
                    empty=""
                    rows={this.props.entity.files.map(file => [
                        this.renderHeading(file.data, file.relevance),
                        this.renderThumbnail(file.data, file.relevance),
                    ])}
                />
            );
        }

        return (
            <div>
                {identityBlock}
                {norkomFeature && (
                    <Block
                        title="Indicateurs Norkoms non résolus"
                        empty="Tous les composants Norkoms ont été résolus"
                        rows={(this.props.entity.vigilance.norkom.missed || []).map(label => [label, ''])}
                    />
                )}
                {norkomFeature && (
                    <Block
                        title="Indicateurs Norkoms signifiants"
                        empty="Pas d'indicateurs Norkoms signifiants"
                        rows={(norkomHighValues || []).map(comp => [comp.label, comp.score])}
                    />
                )}
                {filesBlock}
                <Scope path="news" type="object">
                    <DeefindProvider
                        type="media"
                        id={this.props.entity.id}
                        renderEntity={(media, state) => (
                            <News
                                title={'Annonces officielles'}
                                type={'o'}
                                items={media.news.filter(item => ['o'].includes(item.reliability))}
                                state={state}
                            />
                        )}
                    />
                </Scope>
            </div>
        );
    }

    renderHeading(data, relevance) {
        return (
            <div>
                <div style={styles.title}>{data.subheading}</div>
                <div style={styles.info}>{data.source ? data.source.name : ''}</div>
                <div style={styles.info}>{relevance ? `Relevance: ${relevance} %` : null}</div>
            </div>
        );
    }

    renderThumbnail(data) {
        switch (data.type) {
            case 'fortune':
                return <div>{data.heading}</div>;

            case 'politic':
            case 'justice':
            case 'compliance':
            case 'mandate':
                return (
                    <div>
                        {data.heading}
                        {data.details && data.details.map((detail, index) => (
                            <div key={index} style={styles.details}>
                                {detail}
                            </div>
                        ))}
                    </div>
                );
        }
    }
}
