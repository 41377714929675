import PropTypes from 'prop-types';
import React from 'react';

const styles = {
    container: {
        position: 'relative',
        overflow: 'hidden',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.header.container';

    static propTypes = {
        size: PropTypes.number.isRequired,
        style: PropTypes.object
    };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.style,
            { height: this.props.size + 'px' }
        );

        return (
            <div style={ style }>
                { React.Children.map(this.props.children, this.renderChild.bind(this)) }
            </div>
        );
    }

    renderChild (child, index) {
        return React.cloneElement(child, {
            key: index,
            size: this.props.size
        });
    }
}
