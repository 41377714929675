// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Button from 'app/views/common/button';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    flag: {
        display: 'inline-block',
        fontWeight: '500',
        fontSize: '11px',
        color: 'white',
        borderRadius: '3px',
        lineHeight: '20px',
        padding: '0 5px',
        margin: '0 20px',
    },
    message: {
        color: colors.grey500,
    },
    current: {
        color: colors.userCold,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.inputs.status';

    static propTypes = {
        status: PropTypes.object,
        onStop: PropTypes.func.isRequired,
        onStart: PropTypes.func.isRequired,
    };

    render() {
        switch (true) {
            case !this.props.status:
                return this.renderLoading();

            case this.props.status.done:
                return this.renderDone();

            case this.props.status.running:
                return this.renderStarted();

            default:
                return this.renderStopped();
        }
    }

    renderLoading() {
        return (
            <div style={styles.container}>
                <Button type="round" color="cold" icon={<Icon type="do.cancel" />} disabled />

                {this.renderFlag(colors.grey500, 'Loading')}
                {this.renderMessage('Loading jobs')}
            </div>
        );
    }

    renderDone() {
        return (
            <div style={styles.container}>
                <Button type="round" color="cold" icon={<Icon type="do.submit" />} disabled />

                {this.renderFlag(colors.userGreen, 'Done')}
                {this.renderMessage(this.props.status.counts.done + ' done')}
            </div>
        );
    }

    renderStarted() {
        return (
            <div style={styles.container}>
                <Button type="round" color="cold" icon={<Icon type="do.pause" />} onClick={this.props.onStop} />

                {this.renderFlag(colors.userCold, 'Running')}
                {this.renderProgress()}
            </div>
        );
    }

    renderStopped() {
        return (
            <div style={styles.container}>
                <Button type="round" color="cold" icon={<Icon type="do.start" />} onClick={this.props.onStart} />

                {this.renderFlag(colors.userHot, 'Paused')}
                {this.renderProgress()}
            </div>
        );
    }

    renderFlag(background: string, label: string) {
        return <span style={Object.assign({}, styles.flag, { background })}>{label}</span>;
    }

    renderMessage(label: string) {
        return <span style={styles.message}>{label}</span>;
    }

    renderProgress() {
        if (!this.props.status) {
            return null;
        }

        return (
            <span style={styles.message}>
                <span style={styles.current}>{this.props.status.counts.done}</span>
                &nbsp;/&nbsp;
                {this.props.status.counts.total} ({this.props.status.progress} %)
            </span>
        );
    }
}
