import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        width: '350px',
        overflow: 'auto',
    },
    menu: {
        marginRight: '20px',
    },
    menuItem: {
        lineHeight: '40px',
        padding: '0 10px',
        borderRadius: '3px',
        color: colors.grey500,
        background: colors.white,
        cursor: 'pointer',
    },
    itemCount: {
        display: 'inline-block',
        marginLeft: '15px',
        opacity: '0.75',
    },
    hoveredItem: {
        color: colors.grey700,
        background: colors.grey200,
    },
    currentItem: {
        color: colors.white,
        background: colors.userCold,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.documents.filter';

    static propTypes = {
        menu: PropTypes.object.isRequired,
        data: PropTypes.object,
        fund: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    state = { hovered: null };

    componentDidMount() {
        this.props.onChange(this.props.data || {});
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.menu}>
                    {Object.keys(this.props.menu).map(key => this.renderKind(key, this.props.menu[key]))}
                </div>
            </div>
        );
    }

    renderLabel(data) {
        const style = Object.assign({}, styles.labelItem, this.state.hovered ? styles.labelHover : {});

        return (
            <div
                style={style}
                onMouseEnter={event => this.setState({ hovered: true })}
                onMouseLeave={event => this.setState({ hovered: false })}
                onClick={event => this.setState({ open: true })}
            >
                {this.renderEntry(data.label, data.done, data.count)}
            </div>
        );
    }

    renderKind(key, data) {
        if (!data) {
            return null;
        }

        const state = this.props.data || {};

        const style = Object.assign(
            {},
            styles.menuItem,
            state.kind === key && !state.category
                ? styles.currentItem
                : this.state.hovered === key
                ? styles.hoveredItem
                : {},
        );

        const categories = Object.values(data.categories);

        if (!categories.length) {
            return null;
        }

        return (
            <div key={key}>
                <div
                    style={style}
                    onMouseEnter={event => this.setState({ hovered: key })}
                    onMouseLeave={event => this.setState({ hovered: null })}
                    onClick={event => {
                        if (state.kind === key && !state.category) {
                            this.props.onChange({ kind: null, category: null });
                        }

                        this.props.onChange({ kind: key, category: null });
                    }}
                >
                    {this.renderEntry(data.label, data.count, data.done)}
                </div>

                {categories.map((data, index) => this.renderCategory(`${key}.${index}`, data))}
            </div>
        );
    }

    renderCategory(key, data) {
        const state = this.props.data || {};

        const style = Object.assign(
            {},
            styles.menuItem,
            state.category === data.id && state.kind === data.kind
                ? styles.currentItem
                : this.state.hovered === key
                ? styles.hoveredItem
                : {},
        );

        return (
            <div
                key={key}
                style={style}
                onMouseEnter={event => this.setState({ hovered: key })}
                onMouseLeave={event => this.setState({ hovered: null })}
                onClick={event => {
                    if (state.kind === data.kind && state.category === data.label) {
                        this.props.onChange({ kind: null, category: null });
                    }

                    this.props.onChange({ kind: data.kind, category: data.id });
                }}
            >
                {this.renderEntry('\u00A0—\u00A0\u00A0\u00A0' + data.label, data.count, data.done)}
            </div>
        );
    }

    renderEntry(label, count, done) {
        return [
            <span key="label">{label}</span>,
            <span key="count" style={styles.itemCount}>
                {done} / {count}
            </span>,
        ];
    }

    computeLabel() {
        const kind = this.props.menu[this.props.kind] || {};

        if (!this.props.category) {
            return {
                label: kind.label || '?',
                done: kind.done || 0,
                count: kind.count || 0,
            };
        }

        const category = kind.categories ? kind.categories[this.props.category] || {} : {};

        return {
            label: category.kind + ' ➞ ' + category.label || '?',
            done: category.done || 0,
            count: category.count || 0,
        };
    }
}
