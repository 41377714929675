// @flow

import type { ResultType, CountsFilters, CountsType, SegmentAxes, ExportType, AugmentedCountsType } from '../types';

import { makeBaseCounts, makeAugmentedBaseCounts } from './base';
import {
    makeExportHeader,
    makeExportRow,
    makeExportPredictHeader,
    makeExportPredictRow,
    makeExportListedRelationsHeader,
    makeExportListedRelationsRow,
} from './export';
import { makeScoresCounts, makeStatusCounts, makeAugmentedScoresCounts } from './counts';
import { makeAugmentedSegmentsCounts, makeSegmentsCounts } from './segments';
import { sortBy, get } from 'lodash';

export function makeDirect(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = [makeExportHeader('direct', source)];
    const counts = makeBaseCounts();
    let listedRelationsExport = [makeExportListedRelationsHeader()];

    for (const result of results) {
        const direct = result.output.result;

        if (!makeStatusCounts(counts, result, false) || !direct) {
            continue;
        }

        const { type, id, profile, scores } = result.output.result || {};

        if (type !== filters.type) {
            continue;
        }

        if (!profile || !scores || !makeScoresCounts(counts, scores, filters, false)) {
            continue;
        }

        makeSegmentsCounts(counts, id || '', scores, axes);
        exports.push(makeExportRow('direct', result.input, direct));

        listedRelationsExport = makeExportListedRelationsRow(result.input, direct, listedRelationsExport);
    }

    return { counts, exports: { [source]: exports, listedRelations: listedRelationsExport } };
}

export function makeAugmented(
    results: ResultType[],
    filters: CountsFilters,
    thresholds: Array<{ label: string, code: string, min: number, max: number }>,
    bankTypes: Array<{ label: string, order: number }>,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = [makeExportHeader('augmented', source)];
    const counts = makeAugmentedBaseCounts();
    let listedRelationsExport = [makeExportListedRelationsHeader()];

    for (const result of results) {
        const direct = result.output.result;

        if (!makeStatusCounts(counts, result, false) || !direct) {
            continue;
        }

        const { type, id, profile, scores } = result.output.result || {};

        makeAugmentedScoresCounts(counts, scores, thresholds, filters);

        makeAugmentedSegmentsCounts(counts, id || '', scores, bankTypes, thresholds);

        exports.push(makeExportRow('augmented', result.input, direct));

        listedRelationsExport = makeExportListedRelationsRow(result.input, direct, listedRelationsExport);
    }

    for (let i = 0; i < 4; i++) {
        counts.reechColumnsPrct[i] = Math.round((counts.reechColumns[i] * 100) / counts.total) || 0;
        counts.computedColumnsPrct[i] = Math.round((counts.computedColumns[i] * 100) / counts.total || 0);
        counts.migrations[i].exitPrct = Math.round((counts.migrations[i].exit * 100) / counts.total) || 0;
        counts.migrations[i].entryPrct = Math.round((counts.migrations[i].entry / counts.total) * 100) || 0;
    }

    return { counts, exports: { [source]: exports, listedRelations: listedRelationsExport } };
}

export function makeCovid19(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = [makeExportHeader('covid19', source)];
    const counts = makeBaseCounts();
    let listedRelationsExport = [makeExportListedRelationsHeader()];

    for (const result of results) {
        const direct = result.output.result;

        if (!makeStatusCounts(counts, result, false) || !direct) {
            continue;
        }

        const { type, id, profile, scores } = result.output.result || {};

        if (type !== filters.type) {
            continue;
        }

        if (!profile || !scores || !makeScoresCounts(counts, scores, filters, false)) {
            continue;
        }

        makeSegmentsCounts(counts, id || '', scores, axes);
        exports.push(makeExportRow('covid19', result.input, direct));

        listedRelationsExport = makeExportListedRelationsRow(result.input, direct, listedRelationsExport);
    }

    return { counts, exports: { [source]: exports, listedRelations: listedRelationsExport } };
}

export function makePredict(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = [makeExportHeader('predict', source)];
    const counts = makeBaseCounts();
    const predictExport = [makeExportPredictHeader()];
    for (const result of results) {
        const direct = result.output.result;

        if (!makeStatusCounts(counts, result, false) || !direct) {
            continue;
        }

        const { type, id, profile, scores } = result.output.result || {};

        if (type !== filters.type) {
            continue;
        }

        if (scores.predictions && scores.predictions.length > 0) {
            const sortedPredictions = sortBy(scores.predictions, `year${filters.currentHorizon || 1}.probablity`);
            const currentPredictions = sortedPredictions.pop();
            if (currentPredictions && currentPredictions[`year${filters.currentHorizon || 1}`]) {
                scores.currentPrediction = currentPredictions[`year${filters.currentHorizon || 1}`];
            }

            predictExport.push(makeExportPredictRow(result.input, direct));
        }

        if (!profile || !scores || !makeScoresCounts(counts, scores, filters, false)) {
            continue;
        }

        makeSegmentsCounts(counts, id || '', scores, axes);
        exports.push(makeExportRow('predict', result.input, direct));
    }

    return { counts, exports: { [source]: exports, predict: predictExport } };
}

export function makeUpsell(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = [makeExportHeader('upsell', source)];
    const counts = makeBaseCounts();

    for (const result of results) {
        const direct = result.output.result;

        for (const relation of result.output.relations || []) {
            if (relation.type !== filters.type) {
                continue;
            }

            if (!makeScoresCounts(counts, relation.scores, filters, true) || !direct) {
                continue;
            }

            makeSegmentsCounts(counts, relation.id || '', relation.scores, axes);

            if (relation.type === 'company') {
                exports.push(makeExportRow('upsell', result.input, direct, relation));
            }
        }

        if (!direct) {
            continue;
        }

        if (!makeStatusCounts(counts, result, false)) {
            continue;
        }

        const { type, id, profile, scores } = result.output.result || {};

        if (type !== filters.type) {
            continue;
        }

        if (scores.upsell && filters.upsellMethod) {
            let upsellBase = scores.upsell.cashout;
            if (filters.upsellMethod === 'potential') {
                upsellBase = get(scores.potential, `${filters.accuracy}.value`, scores.potential.value);
            }
            scores.upsell.upsell = upsellBase - scores.upsell.aum;
            scores.upsell.upsellPrct = scores.upsell.upsell / scores.upsell.aum;
        }

        if (!profile || !scores || !makeScoresCounts(counts, scores, filters, false)) {
            continue;
        }

        makeSegmentsCounts(counts, id || '', scores, axes);
        exports.push(makeExportRow('upsell', result.input, direct));
    }

    return { counts, exports: { [source]: exports } };
}

export function makeNetwork(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = { person: [], company: [] };
    const counts = makeBaseCounts();

    for (const result of results) {
        if (!makeStatusCounts(counts, result, true)) {
            continue;
        }

        const direct = result.output.result;

        for (const relation of result.output.relations || []) {
            if (relation.type !== filters.type) {
                continue;
            }

            if (!makeScoresCounts(counts, relation.scores, filters, true) || !direct) {
                continue;
            }

            makeSegmentsCounts(counts, relation.id || '', relation.scores, axes);

            console.log(result.input, relation);
            if (relation.type === 'person') {
                exports.person.push(makeExportRow('network', result.input, direct, relation));
            }

            if (relation.type === 'company') {
                exports.company.push(makeExportRow('network', result.input, direct, relation));
            }
        }
    }

    if (exports.person.length > 0) {
        exports.person.unshift(makeExportHeader('network', source, 'person'));
    } else {
        delete exports.person;
    }

    if (exports.company.length > 0) {
        exports.company.unshift(makeExportHeader('network', source, 'company'));
    } else {
        delete exports.company;
    }

    return { counts, exports };
}

export function makeNetworkPredict(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    const exports = { person: [], company: [] };
    const counts = makeBaseCounts();

    for (const result of results) {
        if (!makeStatusCounts(counts, result, true)) {
            continue;
        }

        const direct = result.output.result;

        for (const relation of result.output.relations || []) {
            if (relation.scores.predictions.length === 0) {
                continue;
            }

            if (relation.type !== filters.type) {
                continue;
            }

            if (!makeScoresCounts(counts, relation.scores, filters, true) || !direct) {
                continue;
            }
            const sortedPredictions = sortBy(relation.scores.predictions, `year${filters.currentHorizon || 1}.probablity`);
            const currentPredictions = sortedPredictions.pop();
            if (currentPredictions && currentPredictions[`year${filters.currentHorizon || 1}`]) {
                relation.scores.currentPrediction = currentPredictions[`year${filters.currentHorizon || 1}`];
            }

            makeSegmentsCounts(counts, relation.id || '', relation.scores, axes);

            for(const prediction of relation.scores.predictions){
                relation.prediction = prediction;
                counts.predictions++;
                if(prediction.type === 'age'){
                    counts.predictionsAge++;
                }
                if(prediction.type === 'vc'){
                    counts.predictionsVc++;
                }

                if (filters.predictionType === 'vc' && prediction.type !== 'vc'){
                    continue;
                }
                if (filters.predictionType === 'age' && prediction.type !== 'age'){
                    continue;
                }

                exports.person.push(makeExportRow('networkPredict', result.input, direct, relation));
            }

        }
    }

    if (exports.person.length > 0) {
        exports.person.unshift(makeExportHeader('networkPredict', source, 'person'));
    } else {
        delete exports.person;
    }


    return { counts, exports };
}
