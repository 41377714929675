import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';
import Header from './accounting/header';
import t from 'app/translation';
import _ from 'lodash';
import Tab from './accounting/tab';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.cockpit';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };



    render() {
        return (
            <div>
                <Header entity={this.props.entity} state={this.props.state} />
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        switch (this.props.state.tab || 0) {
            case 0:
                return <Tab type="keyData" entity={this.props.entity}  />;

            case 1:
                return <Tab type="globalFormat" entity={this.props.entity} />;

            case 2:
                return <Tab type="globalRatio" entity={this.props.entity} />;

            case 3:
                return <Tab type="detailedFormat" entity={this.props.entity} />;

            case 4:
                return <Tab type="creditAnalysis" entity={this.props.entity} />;

            case 5:
                return <Tab type="graphs" entity={this.props.entity} />;
        }
    }
}
