// @flow
import type {
    CompanyActivity,
    CompanyData,
    EntityAddress,
    EntityType,
    FileAttachment,
    LinkProfile,
    PersonData,
    VigilanceNorkomAnswers,
} from '@deecision/deefind-types/model';

import { normCountryCode } from '../../../../norm';
import chrono from 'chrono-node';
import get from 'lodash/get';

export default function(
    type: EntityType,
    data: Object,
    files: FileAttachment[],
    links: LinkProfile[],
): ?VigilanceNorkomAnswers {
    if (type === 'person') {
        return makePersonAnswers(data, files, links);
    }
    if (type === 'company') {
        return makeCompanyAnswers(data, files);
    }
}

function makePersonAnswers(data: PersonData, files: FileAttachment[], links: LinkProfile[]) {
    const residency = normCountryCode(
        get(data, 'addresses.0.country') || get(data, 'identity.homeCountry') || get(data, 'identity.country'),
    );
    const occupations = links.map((link: LinkProfile) => link.mandates);
    let occupation = undefined;
    const activities = ['x', 'm', 'a', 'c', 'b', 's'];

    loop1: for (const activity of activities) {
        if (occupations.length) {
            for (const roles of occupations) {
                if (roles.length) {
                    for (const role of roles) {
                        if (role.level === activity && !role.ceased) {
                            occupation = role.titles[0];
                            break loop1;
                        }
                    }
                }
            }
        }
    }

    const answers: VigilanceNorkomAnswers = {
        type: 'person',
        values: {
            ResidenceFlag: residency,
            PersonAge: getAge(get(data, 'identity.birthDate')), // TODO: use chrono node
            OccupationCode: occupation,
            SensitiveOccupationFlag: files.filter((file: FileAttachment) => file.data.type === 'politic').length > 0,
            SanctionedFlag: files.filter((file: FileAttachment) => file.data.type === 'justice').length > 0,
            PEPDomesticFlag:
                files.filter(
                    (file: FileAttachment) =>
                        file.data.type === 'politic' && ['local', 'national'].includes(file.data.level),
                ).length > 0,
            PEPInternationalFlag:
                files.filter(
                    (file: FileAttachment) => file.data.type === 'politic' && file.data.level === 'international',
                ).length > 0,
            TrustFiducie: undefined,
            CountriesOfAddress: (data.addresses || [])
                .map((address: EntityAddress) => normCountryCode(address.country) || '')
                .filter(v => !!v),
            Nationality: get(data, 'identity.country'), // nationaility or residency
            CountryOfOrigin: get(data, 'identity.birthCountry', get(data, 'identity.nationality')),
        },
    };
    return answers;
}
function makeCompanyAnswers(data: CompanyData, files: FileAttachment[]) {
    const answers: VigilanceNorkomAnswers = {
        type: 'company',
        values: {
            ResidenceFlag: get(data, 'identity.country'), // TODO: use homeCountry
            CompanyAge: getAge(get(data, 'identity.creation')),
            CompanyForm: get(data, 'identity.legalForm.national'),
            BusinessSegment1: (data.activities || [])
                .map((activity: CompanyActivity) => activity.naceCode || '')
                .filter(v => !!v),
            SanctionedFlag: files.filter((file: FileAttachment) => file.data.type === 'justice').length > 0,
            TrustFiducie: undefined,
            CountriesOfAddress: (data.addresses || [])
                .map((address: EntityAddress) => address.country || '')
                .filter(v => !!v),
            CountryOfIncorporation: get(data, 'identity.country'),
        },
    };

    return answers;
}
// custom chrono parser, for truncate date
const customChrono = new chrono.Chrono();
const partialISO = new chrono.Parser();
partialISO.pattern = () => /([0-9]{4})-([0-9]{1,2})$/g;
partialISO.extract = (text, ref, match, opt) => {
    return new chrono.ParsedResult({
        ref,
        text: match[0],
        index: match.index,
        start: {
            month: match[2],
            year: match[1],
        },
    });
};
customChrono.parsers.push(partialISO);
function getAge(input: ?string): ?number {
    if (!input) {
        return undefined;
    }
    const results = customChrono.parseDate(input);
    const date = new Date(results);
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}
