import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Computer from './computer';
import Container from './header/container';
import Axis from './header/axis';
import Point from './header/point';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.preview';

    static propTypes = {
        start: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
        end: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ])
    };

    state = { highlighted: undefined };

    computeEvents () {
        if (! this.props.start || ! this.props.end)
            return { cursor: undefined, events: [] };

        const computer = Computer(this.props.start, this.props.end);

        const cursor = computer.getDatePosition(new Date());
        const events = [];

        React.Children.forEach(this.props.children, child => {
            if (! child) return;

            switch (child.type.displayName) {
                case 'app.views.timeline.model.event':
                    events.push({
                        id: child.props.id,
                        position: computer.getDatePosition(child.props.date),
                        dimmed: child.props.dimmed,
                        children: child.props.children
                    });
                    break;
            }
        });

        return { cursor, events: _.sortBy(events, 'position') };
    }

    render () {
        const { cursor, events } = this.computeEvents();

        return (
            <Container size={ 12 }>
                <Axis
                    cursor={ cursor }
                    disabled={ ! this.props.start }
                />

                { events.map(this.renderEvent.bind(this)) }
            </Container>
        );
    }

    renderEvent (event) {
        return (
            <Point
                key={ event.id }
                position={ event.position }
                tooltip={ event.children }
                onMouseEnter={ e => this.setState({ highlighted: event.id }) }
                onMouseLeave={ e => this.setState({ highlighted: undefined }) }
                highlighted={ this.state.highlighted === event.id }
            />
        );
    }
}
