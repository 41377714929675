import React from 'react';

const getPatents = async function(data) {
    const url = 'https://k8s-test.jngconseil.net/api/v1/simple/inpi/patents/search/by_query?' +
        'query=' + data.firstName.toLowerCase() + '%20' + data.lastName.toLowerCase();
    return new Promise(resolve => {
            fetch( url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow'
            }).then(res => {
                return res.json();
            }).then(data => {
                console.log("test:",data)
                if (data) resolve(data);
                resolve({});
            }).catch(error => console.log('error', error));
    })
}

export default getPatents;
