// @flow

import type {
    EntityEstimate,
    CompanyActivity,
    CompanyIndicator,
    FileAttachment,
    CompanyIdentifier,
    CompanyData,
    LinkProfile,
    CompanyIdentity,
    CompanyValuation,
    CompanyPreview,
} from '@deecision/deefind-types/model';

import get from 'lodash/get';
import uniq from 'lodash/uniq';
import union from 'lodash/union';
import makeEntityGics from '../entity/gics';
import makeEntityActivity from '../entity/activity';
import orderBy from 'lodash/orderBy';
import { makeRelatedNames, makeFilesFlags, makeLinksCount } from './common';
import { normCompanyPreview } from '../../norm';

export default function makeCompanyPreview(
    data: CompanyData,
    files: FileAttachment[],
    links: LinkProfile[],
): CompanyPreview {
    const naceCode = makeNaceCode(data.activities || []) || undefined;
    const value = makeValue(data.indicators || []);
    const name = get(data, 'identity.name', '');
    const isin = get(data, 'identity.isinCode');
    const publications = orderBy(data.indicators, 'year', 'desc');

    return normCompanyPreview({
        name,
        identifiers: (data.identifiers || []).map((identifier: CompanyIdentifier) => identifier.value),
        isins: isin ? [isin] : undefined,
        country: get(data, 'identity.country'),
        naceCode: naceCode,
        gicsCode: makeEntityGics(naceCode) || undefined,
        activity: makeEntityActivity(naceCode) || undefined,
        turnover: value.turnover,
        capital: value.capital,
        ebitda: value.ebitda,
        equity: value.equity,
        employees: get(data, 'identity.employees', undefined),
        publication: publications.length && publications[0].year ? publications[0].year : undefined,
        creation: data.identity.creation,
        closed: data.identity.closed ? data.identity.closed.date || true : undefined,
        funded: makeFunded(links) || undefined,
        valuation: makeValuation(data.valuation) || undefined,
        turnovers: makeHistory(data.indicators || [], 'turnover'),
        ebitdas: makeHistory(data.indicators || [], 'ebitda'),
        domains: makeDomains(data.identity),
        companies: makeRelatedNames(links, 'company'),
        persons: makeRelatedNames(links, 'person'),
        flags: makeFilesFlags(files),
        mandates: makeLinksCount('mandates', links),
        positions: makeLinksCount('positions', links),
    });
}

export function makeNaceCode(activities: CompanyActivity[]): ?string {
    const codes = activities
        .filter((activity: CompanyActivity) => activity.primary && !!activity.naceCode)
        .map((activity: CompanyActivity) => activity.naceCode);

    return codes.length ? codes[0] : undefined;
}

export function makeValue(indicators: CompanyIndicator[]): { [key: string]: number } {
    const value = {};

    for (const indicator of orderBy(indicators, 'year', 'desc')) {
        for (const key of ['turnover', 'capital', 'ebitda', 'equity']) {
            if (value[key]) {
                continue;
            }

            if (typeof indicator[key] === 'number') {
                value[key] = indicator[key];
            }
        }
    }

    return value;
}

export function makeHistory(indicators: CompanyIndicator[], property: string): Array<?number> {
    const years = orderBy(
        uniq(indicators.map(indicator => indicator.year)).filter(v => !!v),
        v => v,
        'desc',
    );
    const history = [];

    for (const year of years.slice(0, 5)) {
        history.push(makeValue(indicators.filter(indicator => indicator.year === year))[property]);
    }

    return history;
}

export function makeValuation(valuation: ?CompanyValuation): ?EntityEstimate {
    if (!valuation) {
        return undefined;
    }

    if (valuation.market && valuation.market.value) {
        return { value: valuation.market.value };
    }

    if (valuation.deemaze) {
        return valuation.deemaze.estimate;
    }

    if (valuation.vadis) {
        return { value: valuation.vadis };
    }

    return undefined;
}

export function makeDomains(identity: CompanyIdentity): string[] {
    return union(
        (identity.websites || []).filter(v => !!v).map(url => url.replace(/^\w+:\/\//, '').replace(/^www\./, '')),
        (identity.emails || []).filter(v => !!v).map(email => email.replace(/^[^@]+@(.*)$/, '$1')),
    );
}

export function makeFunded(links: LinkProfile[]): void | boolean | number {
    let found = false;
    const years = [];

    for (const link of links) {
        const shared = link.shared;

        if (!shared || !link.target || !(link.target.flags || []).includes('fund') || shared.ceased) {
            continue;
        }

        found = true;
        const year = shared.start ? parseInt(shared.start.substr(0, 4)) : 0;
        if (year && year > 1900) years.push(year);
    }

    const year = years.length ? Math.min(...years) : 0;

    return found ? (year ? year : true) : undefined;
}
