import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Preview from './preview';
import Rank from '../../studies/common/rating';
import { COUNTRIES } from '../../studies/common/survey/field/widget/scalar/country';

import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        padding: '20px',
        height: '90px',
    },
    header: {
        marginBottom: '15px',
        alignItems: 'flex-start'
    },
    preview: {
        marginRight: '74px',
    },
    opener: {
        right: '0',
        bottom: '0',
    },
    opener2: {
        position: 'absolute',
        right: '60px',
        bottom: '0',
    },
    body: {
        display: 'flex',
        marginTop: '5px',
        color: colors.grey800,
    },
    aum: {
        lineHeight: '18px',
        marginLeft: '15px',
        color: colors.grey800,
    },
    icon: {
        fill: colors.userCold,
        marginRight: '5px',
        width: '18px',
        height: '18px',
    },
    owner: {
        lineHeight: '18px',
    },
};

@inject(stores => ({ contacts: stores.auth.contacts, session: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.archives.summary.card';

    static contextTypes = {
        openStudy: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object,
        contacts: PropTypes.object,
        session: PropTypes.object,
    };

    render() {
        if (!this.props.entity) {
            return null;
        }

        const payload = this.props.entity.payload || {};
        const type = payload.target.type || 'manager';
        const contacts = this.props.contacts;
        const owner = contacts && contacts.users ? contacts.users[this.props.entity.ownerUid] || {} : {};
        const session = this.props.session.get();

        return (
            <Views.Common.Card style={styles.container}>
                <Views.Common.Header
                    light
                    style={styles.header}
                    icon={<Icon type={type === 'fund' ? 'deeligenz.fund' : 'deeligenz.company'} />}
                    title={type === 'fund' ? payload.fund : payload.manager}
                    content={[this.renderOwner(owner, payload)]}
                    aside={[<Rank value={payload.rating} />, <Views.Common.Button
                        type="round"
                        style={styles.opener}
                        icon={<Icon type="do.open" />}
                        onClick={event => this.context.openStudy(this.props.entity.id)}
                    />]}
                />
            </Views.Common.Card>
        );
    }

    renderOwner(owner, payload) {
        return (
            <div style={styles.body}>
                <Icon type="base.attendee" style={styles.icon} />

                <span style={styles.owner}>{owner.firstName + ' ' + owner.lastName}</span>

                {payload.expectedAum ? (
                    <span style={styles.aum}>Exp. AUM&nbsp;&nbsp;{payload.expectedAum}&nbsp;M€</span>
                ) : null}
            </div>
        );
    }

    renderDetail(type, payload) {
        if (type === 'fund') {
            return (
                <div style={styles.body}>
                    <Icon type="deeligenz.company" style={styles.icon} />

                    <span style={styles.owner}>{payload.manager}</span>
                </div>
            );
        }

        return (
            <div style={styles.body}>
                <img
                    style={{ height: '12px', width: '16px', float: 'left', margin: '3px 6px 0 0' }}
                    src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${(
                        payload.country || ''
                    ).toLowerCase()}.svg`}
                />
                {COUNTRIES[payload.country] || ''}
            </div>
        );
    }
}
