// @flow

export default function makeEntityActivity(naceCode: ?string): ?string {
    return naceCode ? map[naceCode.substr(0, 2)] : undefined;
}

const map = {
    '01': 'ag', // Agriculture
    '02': 'ag',
    '03': 'ag',
    '05': 'er', // Energy resources
    '06': 'er',
    '07': 'hi', // Mining & heavy industries
    '08': 'hi',
    '09': 'hi',
    '10': 'fi', // Food industry
    '11': 'fi',
    '13': 'tb', // Tobacco
    '14': 'mc', // Manufacture of clothings
    '15': 'mc',
    '16': 'mw', // Manufacture of wood/paper
    '17': 'mw',
    '18': 'cm', // Communication
    '19': 'er',
    '20': 'ci', // Chemical industries
    '21': 'ci',
    '22': 'ci',
    '23': 'hi',
    '24': 'hi',
    '25': 'hi',
    '26': 'ee', // Electronic & electrical equipment
    '27': 'ee',
    '28': 'tm', // Transportation & mechanical
    '29': 'tm',
    '30': 'tm',
    '31': 'ma', // Manufacture
    '32': 'ma',
    '33': 'is', // Industries source
    '35': 'is',
    '36': 'ww', // Water & waste treatment
    '37': 'ww',
    '38': 'ww',
    '39': 'ww',
    '41': 'ce', // Construction & civil engineering
    '42': 'ce',
    '43': 'ce',
    '45': 'ws', // Wholesale
    '46': 'ws',
    '47': 'rt', // Retail
    '49': 'tc', // Transportation of commodities
    '50': 'tc',
    '51': 'tc',
    '52': 'tc',
    '53': 'tc',
    '55': 'rh', // Real estate + hospitality
    '56': 'fb', // Food & beverage source
    '58': 'cm',
    '59': 'cm',
    '60': 'cm',
    '61': 'it', // Telecom & IT
    '62': 'it',
    '63': 'it',
    '64': 'bf', // Bank, finance & insurance source
    '65': 'bf',
    '66': 'bf',
    '67': 'bf',
    '68': 're', // Real estate
    '69': 'la', // Legal, accounting & consultancy
    '70': 'la',
    '71': 'st', // Scientific & technical
    '72': 'st',
    '73': 'cm',
    '74': 'st',
    '75': 'st',
    '77': 'rn', // Rental
    '78': 'bb', // B2B source
    '79': 'le', // Leisure & entertainment source
    '80': 'sc', // Security
    '81': 'ce',
    '82': 'bb',
    '84': 'pa', // Public administration
    '85': 'ed', // Education
    '86': 'he',
    '87': 'rh',
    '88': 'he', // Health
    '90': 'le',
    '91': 'le',
    '92': 'gb', // Gambling
    '93': 'le',
    '94': 'le',
    '95': 'it',
    '96': 'bc', // B2C source
    '97': 'bc',
    '98': 'bc',
    '99': 'bc',
};
