// @flow

import type { MediaItem, MediaCollection } from '@deecision/deefind-types/model';

import omit from 'lodash/omit';

export function combineMedia(items: MediaItem[]): MediaCollection {
    const collection = { pictures: [], links: [], biographies: [] };

    for (const item of items) {
        switch (item.type) {
            case 'picture':
                collection.pictures.push(omit(item, 'type'));
                break;

            case 'link':
                collection.links.push(omit(item, 'type'));
                break;

            case 'biography':
                collection.biographies.push(omit(item, 'type'));
                break;
        }
    }

    return collection;
}
