// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import ScopeProvider from 'app/layout/providers/scope';
import Views from 'app/views';
import Card from './card';
import Row from './row';
import { toJS } from 'mobx';
import { get, orderBy } from 'lodash';
import next from '@deecision/deeligenz-utils/compute/next';
import Moment from 'moment';

@inject((stores) => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.library.studies';

    static propTypes = {
        store: PropTypes.object.isRequired,
        style: PropTypes.object,
    };

    state = {
        toggle_view: 'table'
    }

    render() {
        const studies = [...(toJS(this.props.store.studies.values()) || [])]
          .filter(study => ['confirmed', 'ignoredRedFlag'].includes(study.status));

        const archives = toJS(this.props.store.archives.values()) || [];

        return (
            <div style={this.props.style}>
                <ScopeProvider path="list" type="list">
                    <Views.List.Container entities={[...studies, ...archives]}>
                        <Views.List.Block position="left" >
                            <div>
                                <Views.Common.Toggle
                                key="type"
                                value={this.state.toggle_view}
                                items={[
                                    { value: 'cards', icon: 'deeligenz.summary', help: 'View as Cards' },
                                    { value: 'table', icon: 'base.list', help: 'View as Table' },
                                ]}
                                onChange={(value) => {this.setState({toggle_view: value || 'cards'})}}
                                />
                            </div>
                        </Views.List.Block>
                        <Views.List.Reducer
                            type="sort"
                            specs={{
                                name: { label: 'Name', path: entity => String(entity.payload.fund || entity.payload.manager).toLowerCase() },
                                type: { label: 'Type', path: entity => entity.payload.target.type || 'manager' },
                                globalAaisRating: { label: 'AAIS Global Rating', path: entity => Math.max(0, Math.min(5, Math.round(entity.payload.rating || get(entity, 'computed.scoring.rating', 5)))) },
                                globalEsgRating: { label: 'AAIS ESG Rating', path: entity => get(entity.payload, 'header.esg', String.fromCharCode(64 + get(entity, 'computed.scoringEsg.rating', 5))) },
                                owner: { label: 'Author', path: 'ownerUid' },
                                dateOfAnalysis: { label: 'Date of Analysis', path: entity => Moment(get(entity, 'computed.deadline') || get(entity, 'payload.onSite.finalDate')) },
                                nextReview: { label: 'Next Review', path: entity => {
                                  const baseRating = get(entity, 'computed.scoring.rating', 5);
                                  const realRating = Math.max(0, Math.min(5, Math.round(entity.payload.rating || baseRating)));
                                  const aum = get(entity, 'payload.expectedAum') || (Number(get(entity, 'payload.archive.expectedAUM').replace(/ | /g, '')) / 1000000);
                                  const analysisDate = Moment(get(entity, 'computed.deadline') || get(entity, 'payload.onSite.finalDate'));
                                  const date = (next(analysisDate, get(entity, 'payload.target.variant').toLowerCase().replace(/ | /g, ''), realRating, aum) || {}).date;

                                  return date === 'not defined' ? Moment() : Moment(date);
                                  } },
                            }}
                        />

                        <Views.List.Reducer
                            type="groups"
                            name="study"
                            specs={{
                                odd: { label: 'ODD', filter: (item) => true },
                                mdd: { label: 'MDD', filter: (item) => false },
                            }}
                        />

                        <Views.List.Reducer
                            type="groups"
                            name="target"
                            specs={{
                                manager: {
                                    label: 'Manager',
                                    filter: (item) => get(item, 'payload.target.type') === 'manager',
                                },
                                fund: { label: 'Fund', filter: (item) => get(item, 'payload.target.type') === 'fund' },
                            }}
                        />

                        <Views.List.Reducer
                            type="groups"
                            name="kind"
                            specs={{
                                study: { label: 'Study', filter: (item) => item.status !== 'archive' },
                                archive: { label: 'Archive', filter: (item) => item.status === 'archive' },
                            }}
                        />

                        <Views.List.Reducer
                            type="fuse"
                            specs={{
                                paths: ['payload.manager', 'payload.fund'],
                                threshold: 0.1
                            }}
                        />

                        <Views.List.Frame type={this.state.toggle_view}
                            specs={
                                { labels: [
                                    'Name',
                                    'Type',
                                    <div style={{textAlign: 'center'}}>AAIS Global Rating</div>,
                                    <div style={{textAlign: 'center'}}>AAIS ESG Rating</div>,
                                    'Author',
                                    'Date of Analysis',
                                    'Next Review',
                                    <div style={{textAlign: 'center'}}>Summary Report</div>,
                                    <div style={{textAlign: 'center'}}>Full Report</div>
                                ]}
                            }
                         element={this.state.toggle_view === 'cards' ? <Card /> : <Row />} />
                    </Views.List.Container>
                </ScopeProvider>
            </div>
        );
    }
}
