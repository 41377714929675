import PropTypes from 'prop-types';
import React from 'react';

import Container from 'dna/entity/common/display/blocks';
import DealsBlock from 'dna/entity/common/deals/block';

import IdentityBlock from './blocks/identity';
import FinancialBlock from './blocks/financial';
import StocksBlock from './blocks/stocks';
import ContactsBlock from './blocks/contacts';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.company.display.blocks';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
    };

    render () {
        return (
            <Container tab={ this.props.state.tab || 0 }>
                <IdentityBlock entity={ this.props.entity } />
                <FinancialBlock entity={ this.props.entity } view={ this.props.state.financial || 'list' } />
                <StocksBlock entity={ this.props.entity } />
                <ContactsBlock entity={ this.props.entity } />
                <DealsBlock entity={ this.props.entity } />
            </Container>
        );
    }
}
