import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import File from './file';
import DownloadAnimation from './animation';
import Comments from './comments';
import { get } from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    modal: {
        width: '800px',
    },
    container: {
        marginTop: '40px',
        textAlign: 'center',
        minHeight: '40px',
        backgroundColor: colors.bgGreen,
    },
    submit: {
        display: 'inline-block',
        marginLeft: '10px',
    },
    marker: {
        width: '16px',
        height: '16px',
        verticalAlign: 'sub',
    },
    error: {
        textAlign: 'center',
        marginBottom: '10px',
        color: colors.userHot,
        fontWeight: 'bold',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.nda';

    static propTypes = {
        external: PropTypes.bool.isRequired,
        entity: PropTypes.object.isRequired,
        onComment: PropTypes.func.isRequired,
    };

    state = {
        file: null,
        isUploading: false,
        toSubmit: false,
        uploaded: !!get(this.props.entity, 'payload.nda.documents.unsigned', false),
        signed: !!get(this.props.entity, 'payload.nda.documents.signed', false),
        uploadError: false,
    };

    render() {
        return (
            <span>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label={
                        <span>
                            <Icon
                                style={styles.marker}
                                color={
                                    (this.state.uploaded && this.state.signed) ||
                                    !get(this.props.entity, 'payload.nda.required', true)
                                        ? colors.userGreen
                                        : colors.userHot
                                }
                                type={'deeligenz.nda.' + (this.state.uploaded && this.state.signed ? 'ok' : 'ko')}
                            />
                            &nbsp;NDA
                        </span>
                    }
                    icon={<Icon type="deeligenz.nda.main" />}
                    onClick={event => {
                        this.refs.modal.open();
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                />

                <Views.Modal.Show ref="modal" icon={<Icon type="base.discussion" />} title={'NDA'} style={styles.modal}>
                    {this.renderStatus()}

                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        {this.props.entity.payload.nda.required
                            ? this.state.isUploading
                                ? 'UPLOADING NDA...'
                                : this.state.uploaded
                                ? this.state.signed
                                    ? 'SIGNED NDA'
                                    : 'PENDING SIGNED NDA'
                                : 'MISSING NDA'
                            : '-'}
                    </div>

                    {this.state.uploadError ? <div style={styles.error}>Upload failed, please retry</div> : null}

                    {this.renderContent()}

                    <Comments
                        comments={(toJS(this.props.entity.comments) || []).filter(item => item.channel === 'nda')}
                        onComment={this.props.onComment}
                    />
                </Views.Modal.Show>
            </span>
        );
    }

    renderContent() {
        if (this.state.isUploading || (!this.state.uploaded && !this.props.external)) {
            return;
        }

        return (
            <div>
                <div style={styles.container}>
                    {!this.props.external
                        ? null
                        : [
                              <label style={{ lineHeight: '35px' }} key="label">
                                  NDA required
                              </label>,
                              <input
                                  key="input"
                                  onClick={e => {
                                      this.props.store.requiredNda(
                                          this.props.entity.id,
                                          !this.props.entity.payload.nda.required,
                                      );
                                  }}
                                  onChange={() => {}}
                                  type="checkbox"
                                  checked={this.props.entity.payload.nda.required}
                              />,
                          ]}
                    <File
                        readOnly={this.state.signed}
                        name={this.props.entity.payload.manager}
                        uploaded={this.state.uploaded}
                        onChange={data => {
                            this.setState({ file: data, toSubmit: true });
                        }}
                        onReadDocument={async () => {
                            if (this.state.signed) {
                                return this.props.store.getFileUrl(
                                    get(this.props.entity, 'payload.nda.documents.signed'),
                                );
                            }

                            return this.props.store.getFileUrl(
                                get(this.props.entity, 'payload.nda.documents.unsigned'),
                            );
                        }}
                    />
                    {this.renderActions()}
                </div>
            </div>
        );
    }

    renderStatus() {
        if (this.state.isUploading) {
            return this.renderDownloading();
        }

        if (this.state.signed || !get(this.props.entity, 'payload.nda.required', true)) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <div style={{ position: 'relative', height: 200, width: 200, margin: 'auto' }}>
                        <div style={{ position: 'absolute' }}>
                            <Icon
                                type="deeligenz.nda.signed"
                                style={{ height: 200, width: 200, color: colors.halfGreen }}
                                alt="test"
                            />
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.uploaded) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <div style={{ position: 'relative', height: 200, width: 200, margin: 'auto' }}>
                        <div style={{ position: 'absolute' }}>
                            <Icon
                                type="deeligenz.nda.missing"
                                style={{ height: 200, width: 200, color: colors.halfCold }}
                                alt="test"
                            />
                        </div>
                        <div style={{ position: 'absolute', height: 200, width: 200 }}>
                            <Icon
                                type="deeligenz.survey.pending"
                                style={{ height: 100, width: 100, marginTop: '60px', color: 'white' }}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ position: 'relative', height: 200, width: 200, margin: 'auto' }}>
                    <div style={{ position: 'absolute' }}>
                        <Icon
                            type="deeligenz.nda.missing"
                            style={{ height: 200, width: 200, color: colors.halfCold }}
                            alt="test"
                        />
                    </div>
                    <div style={{ position: 'absolute', height: 200, width: 200 }}>
                        <Icon
                            type="base.flow.warning"
                            style={{ height: 100, width: 100, marginTop: '50px', color: 'white' }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderActions() {
        if (!this.state.toSubmit) {
            return;
        }

        return (
            <Views.Common.Button
                key="submit"
                type="raised"
                color="cold"
                icon={<Icon type="do.open" />}
                label="Submit"
                onClick={async () => {
                    this.setState({ toSubmit: false, isUploading: true, uploadError: false });
                    let signed = false;

                    setTimeout(async () => {
                        try {
                            if (this.props.external) {
                                await this.props.store.writeDocumentNda(this.props.entity.id, this.state.file);
                            } else {
                                await this.props.store.writeDocumentNdaSigned(this.props.entity.id, this.state.file);
                                signed = true;
                            }

                            this.setState({ isUploading: false, uploaded: true, signed });
                        } catch(error) {
                            this.setState({ isUploading: false, uploaded: false, uploadError: true });
                        }
                    }, 1000);
                }}
                style={styles.submit}
            />
        );
    }

    renderDownloading() {
        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ position: 'relative', height: 200, width: 200, margin: 'auto' }}>
                    <div style={{ position: 'absolute' }}>
                        <Icon
                            type="deeligenz.nda.missing"
                            style={{ height: 200, width: 200, color: colors.halfCold }}
                            alt="test"
                        />
                    </div>
                    <DownloadAnimation />
                </div>
            </div>
        );
    }
}
