// @flow

import type {
    ComputedSums,
    EntityEstimate,
    EntityInnerTarget,
    RelationCollection,
} from '@deecision/deefind-types/model';

export default function makeComputedSums(
    companiesRelations: RelationCollection,
    personsRelations: RelationCollection,
): ComputedSums {
    const companiesAggregates = {};
    const personsAggregates = {};

    for (const relation of companiesRelations) {
        if (!relation.ceased) {
            const target = relation.target;
            register(companiesAggregates, 'turnover', target);
            register(companiesAggregates, 'capital', target);
            register(companiesAggregates, 'ebitda', target);
            register(companiesAggregates, 'equity', target);
            register(companiesAggregates, 'valuation', target);
        }
    }

    for (const relation of personsRelations) {
        if (!relation.ceased) {
            const target = relation.target;
            register(personsAggregates, 'fortune', target);
            register(personsAggregates, 'income', target);
            register(personsAggregates, 'weasse', target);
            register(personsAggregates, 'weasseMax', target);
            register(personsAggregates, 'turnoversSum', target);
            register(personsAggregates, 'ebitdasSum', target);
            register(personsAggregates, 'capitalsSum', target);
            register(personsAggregates, 'valuationsSum', target);
        }
    }

    return {
        companies: {
            turnover: compute(companiesAggregates, 'turnover') || undefined,
            capital: compute(companiesAggregates, 'capital') || undefined,
            ebitda: compute(companiesAggregates, 'ebitda') || undefined,
            equity: compute(companiesAggregates, 'equity') || undefined,
            valuation: compute(companiesAggregates, 'valuation') || undefined,
        },
        persons: {
            fortune: compute(personsAggregates, 'fortune') || undefined,
            income: compute(personsAggregates, 'income') || undefined,
            weasse: compute(personsAggregates, 'weasse') || undefined,
            weasseMax: compute(personsAggregates, 'weasseMax') || undefined,
            turnoversSum: compute(personsAggregates, 'turnoversSum') || undefined,
            ebitdasSum: compute(personsAggregates, 'ebitdasSum') || undefined,
            capitalsSum: compute(personsAggregates, 'capitalsSum') || undefined,
            valuationsSum: compute(personsAggregates, 'valuationsSum') || undefined,
        },
    };
}

export function register(aggregates: Object, prop: string, target: EntityInnerTarget): void {
    if (!aggregates[prop]) {
        aggregates[prop] = [];
    }

    aggregates[prop].push(target[prop]);
}

export function compute(aggregates: Object, prop: string): ?EntityEstimate {
    const items = aggregates[prop];

    if (!Array.isArray(items) || !items.length) {
        return null;
    }

    let value = 0;
    let deviation = 0;
    let nulls = 0;

    for (const item of items) {
        if (typeof item === 'number') {
            value += item;
        } else if (item && typeof item.value === 'number') {
            value += item.value;

            if (typeof item.deviation === 'number') {
                deviation += item.deviation;
            }

            // todo: take item.reliability in account
        } else {
            nulls += 1;
        }
    }

    return { value, deviation, reliability: Math.round((100 * (items.length - nulls)) / items.length) };
}
