// @flow

import type {
    EntityInnerTarget,
    LinkAttachment,
    LinkBase,
    LinkMandate,
    LinkProfile,
    LinkStock,
    StockValue,
} from '@deecision/deefind-types/model';

import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import slugify from 'speakingurl';
import levelsMap from './levelsMap';
import makeLinksBase from './base';
import { makeHighestLevel } from './level';
import { makeStockTotal } from '../stock/item';

const LEVELS = ['o', 'm', 'x', 'c', 'a', 'b', 's', 'i'];

export default function makeLinkProfile(attachment: LinkAttachment): LinkProfile {
    const target: Object = attachment.target;
    const targetHolding = target && (target.flags || []).includes('holding');

    let mandates = normalizeMandates(attachment.mandates || []);
    let positions = normalizeMandates(attachment.positions || []);

    if (targetHolding) {
        positions = positions.map((mandate: LinkMandate) =>
            Object.assign({}, mandate, { level: mandate.level === 's' ? 'i' : mandate.level }),
        );
    }

    if (!isShareholder(mandates) && attachment.target.type === 'company') {
        for (const mandate of mandates) {
            const presidents = mandate.titles.filter((title: string) => {
                return slugify(title, { separator: ' ' }).search(/president/gm) >= 0;
            });

            if (
                !['s', 'i'].includes(mandate.level) &&
                mandate.start &&
                target.creation &&
                presidents.length &&
                mandate.start.substr(0, 4) === (target.creation || '').substr(0, 4)
            ) {
                mandates.push(makeShareholderMandate(mandate, targetHolding));
                break;
            }
        }
    }

    const owned =
        makeStock(
            (attachment.stocks || []).filter(stock => !!stock.direct),
            mandates.filter((mandate: LinkMandate) => ['i', 's'].includes(mandate.level)),
            target,
        ) || undefined;

    const shared =
        makeStock(
            (attachment.shareholders || []).filter(stock => !!stock.direct),
            positions.filter((mandate: LinkMandate) => ['i', 's'].includes(mandate.level)),
            target,
        ) || undefined;

    if (owned && owned.direct && !owned.ceased && !isShareholder(mandates)) {
        mandates.unshift(makeShareholderMandate(owned, targetHolding));
    }

    if (shared && shared.direct && !shared.ceased && !isShareholder(positions)) {
        positions.unshift(makeShareholderMandate(shared, targetHolding));
    }

    const guo =
        makeStock(
            (attachment.shareholders || []).filter(stock => stock.beneficial && stock.ultimate),
            [],
            target,
        ) || undefined;

    const wires = mandates.concat(positions);
    const ceased = wires.filter(wire => wire.ceased).length === wires.length;

    return { mandates, positions, owned, shared, guo, target, ceased };
}

function makeStock(stocks: LinkStock[], mandates: LinkMandate[], target: EntityInnerTarget): ?LinkStock {
    if (!stocks.length && !mandates.length) {
        return undefined;
    }

    const base = makeLinksBase(stocks);

    function mergeInnerValues(stocks: LinkStock[]): { value?: StockValue, guessed: boolean, ceased: boolean } {
        const ceased = stocks.filter(stock => !!stock.ceased);
        const current = stocks.filter(stock => !stock.ceased);
        const list = current.length > 0 ? current : ceased;
        const valuation = target.valuation;

        const value = {
            min: Math.max(...list.map(stock => (stock.value ? stock.value.min || 0 : 0))),
            max: Math.min(...list.map(stock => (stock.value ? stock.value.max || 100 : 100))),
        };

        const total = valuation ? makeStockTotal(value, valuation) : undefined;

        return {
            label: list.map(stock => stock.label).filter(v => !!v)[0],
            value,
            total,
            guessed: list.map(stock => !stock.guessed).length === 0,
            beneficial: list.map(stock => stock.beneficial).length > 0,
            ultimate: list.map(stock => stock.ultimate).length > 0,
            ceased: current.length === 0,
        };
    }

    if (stocks.length) {
        return Object.assign({}, base, mergeInnerValues(stocks), { direct: true });
    }

    if (mandates.length > 0) {
        return Object.assign({}, makeLinksBase(mandates), { direct: true, guessed: true });
    }
}

function isShareholder(mandates: LinkMandate[]): boolean {
    const filter = (mandate: LinkMandate) => ['i', 's'].includes(mandate.level) && (mandate.ceased || false) === false;
    return mandates.filter(filter).length > 0;
}

function makeShareholderMandate(stock: LinkBase, investor: boolean): LinkMandate {
    return {
        level: investor ? 'i' : 's',
        titles: ['Shareholder'],
        direct: true,
        guessed: true,
        start: stock.start,
        end: stock.end,
        ceased: stock.ceased,
        source: stock.source,
    };
}

function normalizeMandates(mandates: LinkMandate[]): LinkMandate[] {
    return orderBy(
        dedupeMandates(remapMandates(mandates)),
        (mandate: LinkMandate) => LEVELS.indexOf(mandate.level) + (mandate.ceased ? 0 : 10),
        'desc',
    );
}

function remapMandates(mandates: LinkMandate[]): LinkMandate[] {
    return mandates.map((mandate: LinkMandate) => {
        mandate.titles = uniq(flatten(mandate.titles.map((title: string) => title.split(/\s*;\s*/g))));

        const titles = mandate.titles.map((title: string) => slugify(title, { separator: ' ' }));
        const level = makeHighestLevel(titles.map(title => levelsMap[title] || null).filter(v => !!v));

        if (level) {
            mandate.level = level;
        }

        return mandate;
    });
}

function dedupeMandates(mandates: LinkMandate[]): LinkMandate[] {
    const map = {};

    for (const mandate of mandates) {
        const key = [
            mandate.level,
            slugify(mandate.titles.join(':')),
            mandate.start || '',
            mandate.end || '',
            mandate.ceased ? 'y' : 'n',
            mandate.guessed ? 'y' : 'n',
        ].join(':');

        map[key] = mandate;
    }

    return Object.keys(map).map(key => map[key]);
}
