import React from 'react';
import PropTypes from 'prop-types';
import InputStatus from './input';
import OnSiteStatus from './onSite';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Moment from 'moment';
import get from 'lodash/get';

const FLAGS = {
    input: 'Input',
    ddq: 'Analysis',
    onSite: 'Meeting',
    report: 'Report',
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'flex-start',
    },
    flag: {
        display: 'inline-block',
        fontSize: '13px',
        textTransform: 'uppercase',
        background: colors.userCold,
        color: 'white',
        marginRight: '15px',
        padding: '2px 5px',
        borderRadius: '3px',
    },
    timeLeft: {
        display: 'inline-block',
        color: colors.userGreen,
        marginRight: '15px',
    },
    timeLate: {
        display: 'inline-block',
        color: colors.userHot,
        marginRight: '15px',
    },
    progress: {
        display: 'inline-block',
        color: colors.userCold,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.status';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        withFlag: PropTypes.bool,
    };

    render() {
        const status = this.props.entity.status;
        if (!this.props.entity.computed) return <Views.Common.Loader tiny={true}/>;

        return ([
            <span key="detailsStatus" style={styles.container}>
                {this.renderFlag(status)}
                {this.renderDetails(status)}
            </span>,
            <div key="detailsDate" style={{color: colors.grey500, textAlign: 'center', padding: '5px'}}>
                begin: {Moment(this.props.entity.computed.dates.begin).format('DD/MM/YYYY')} - end: {Moment(get(this.props.entity, 'computed.deadline')).format('DD/MM/YYYY')}
            </div>
        ]
        );
    }

    renderFlag(status) {
        if (!FLAGS[status]) {
            return null;
        }

        return <span style={styles.flag}>{FLAGS[status]}</span>;
    }

    renderDetails(status) {
        switch (status) {
            case 'input':
                return <InputStatus entity={this.props.entity} />;

            case 'onSite':
                return <OnSiteStatus entity={this.props.entity} />;
        }

        return null;
    }
}
