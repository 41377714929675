// @flow

import React from 'react';
import Scope from 'app/layout/providers/scope';
import get from 'lodash/get';
import T301 from './301-challenges-f500';
import T302 from './302-deputy';
import T303 from './303-amf';
import T304 from './304-senators';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.result.typology';

    props: {
        state: Object,
    };

    render () {
        if (! this.props.state || ! this.props.state.form || ! this.props.state.result) {
            return null;
        }

        if (this.props.state.form.kind !== this.props.state.result.kind) {
            return null;
        }

        switch (get(this.props.state, 'form.typology.provider')) {
            case 301:
                return (
                    <Scope path="list" type="list">
                        <T301 items={ this.props.state.result.items } />
                    </Scope>
                );
            case 302:
                return (
                    <Scope path="list" type="list">
                        <T302 items={ this.props.state.result.items } />
                    </Scope>
                );
            case 303:
                return (
                    <Scope path="list" type="list">
                        <T303 items={ this.props.state.result.items } />
                    </Scope>
                );
            case 304:
                return (
                    <Scope path="list" type="list">
                        <T304 items={ this.props.state.result.items } />
                    </Scope>
                );
            case 310:
                return (
                    <Scope path="list" type="list">
                        <T303 items={ this.props.state.result.items } />
                    </Scope>
                );
        }

        return null;
    }
}
