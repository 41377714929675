import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Embed from './embed';
import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import {SortableHandle} from 'react-sortable-hoc';

const styles = {
    container: {
        display: 'grid',
        lineHeight: '24px',
        gridTemplateColumns: '24px auto 30px',
        margin: '1px 0',
        background: colors.grey200,
        fontSize: '13px',
        color: 'black',
    },
    grip: {
        padding: '8px 3px',
        cursor: 'move',
        borderRadius: '3px 0 0 3px',
    },
    preview: {
        padding: '5px',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'flex-start',
    },
    index: {
        width: '20px',
        fontSize: '13px',
        textAlign: 'right',
        marginRight: '10px',
        color: colors.grey500,
    },
    label: {
        width: '80%',
        fontSize: '15px',
        whiteSpace: 'pre-line',
        lineHeight: '15px',
        padding: '5px 0',
    },
    weight: {
        textAlign: 'center',
        color: 'white',
        width: '24px',
        background: 'rgba(0,0,0,0.25)',
        borderRadius: '50%',
        marginLeft: '10px',
        flexGrow: '0',
        flexShrink: '0',
    },
    type: {
        width: '120px',
        paddingLeft: '10px',
        color: colors.grey500,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    flags: {
        width: '185px',
    },
    flag: {
        marginLeft: '3px',
        background: colors.userCold,
        fontSize: '11px',
        padding: '3px 5px',
        color: 'white',
        borderRadius: '3px',
    },
    autoFlag: {
        marginLeft: '3px',
        background: colors.userCold,
        fontSize: '11px',
        padding: '3px 5px',
        color: 'white',
        borderRadius: '3px',
    },
    remove: {
        textAlign: 'center',
        padding: '8px 0',
        borderRadius: '3px',
        cursor: 'pointer',
        opacity: '0.5',
        background: 'transparent',
    },
    icon: {
        width: '18px',
        height: '18px',
        opacity: '0.5',
        transition: 'none',
    },
};

@inject(stores => ({ store: stores.deeligenzManagement }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.questions.question.item';

    static propTypes = {
        id: PropTypes.string.isRequired,
        index: PropTypes.number,
        store: PropTypes.object.isRequired,
        onEdit: PropTypes.func,
        onRemove: PropTypes.func,
        onSelect: PropTypes.func,
        selected: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    state = { hover: false, previewHover: false, removeHover: false };

    render() {
        const question = toJS(this.props.store.questionsMap.get(this.props.id));

        if (!question) {
            return null;
        }

        const style = Object.assign({}, styles.container, {
            background: this.state.previewHover ? colors.grey300 : colors.grey200,
        });

        const removeStyle = Object.assign(
            {},
            styles.remove,
            this.props.size > 0
                ? { cursor: 'not-allowed' }
                : this.state.removeHover
                ? { background: colors.userHot, opacity: '1' }
                : {},
        );

        const removeIconStyle = Object.assign(
            {},
            styles.icon,
            this.state.removeHover && !this.props.size ? { fill: 'white' } : {},
        );

        const DragHandle = SortableHandle(() => (
            <Icon style={{ verticalAlign: 'middle', fill: 'black', cursor: 'pointer' }} type="base.grip" />
        ));

        return [
            <div
                key="main"
                style={style}
                onMouseEnter={this.props.readOnly ? undefined : event => this.setState({ hover: true })}
                onMouseLeave={this.props.readOnly ? undefined : event => this.setState({ hover: false })}
            >
                <div style={ styles.grip }>
                    {this.props.readOnly ? null : <DragHandle/>}
                </div>

                <span
                    style={Object.assign({}, styles.preview, { cursor: this.props.readOnly ? 'default' : 'pointer' })}
                    onMouseEnter={this.props.readOnly ? undefined : event => this.setState({ previewHover: true })}
                    onMouseLeave={this.props.readOnly ? undefined : event => this.setState({ previewHover: false })}
                    onClick={this.props.readOnly ? undefined : event => this.props.onEdit(question)}
                >
                    {this.props.onSelect ? (
                        <input
                            checked={this.props.selected}
                            onClick={e => {
                                this.props.onSelect(this.props.id);
                            }}
                            type="checkbox"
                        />
                    ) : null}
                    <span style={styles.index}>{this.props.index}</span>
                    <span style={styles.label}>{question.payload.label}</span>

                    <span style={Object.assign({}, styles.weight, { opacity: question.payload.weight ? '1' : '0' })}>
                        {question.payload.weight || 0}
                    </span>

                    <span style={styles.type}>{this.renderType(question.payload.type)}</span>
                    <span style={styles.flags}>{this.renderFlags(question.payload)}</span>
                </span>

                {this.props.readOnly ? null : (
                    <div
                        style={removeStyle}
                        onMouseEnter={event => this.setState({ removeHover: true })}
                        onMouseLeave={event => this.setState({ removeHover: false })}
                        onClick={this.props.onRemove}
                    >
                        <Icon style={removeIconStyle} type="do.clear" />
                    </div>
                )}
            </div>,
        ].concat(this.renderEmbeds(question));
    }

    renderType(type) {
        switch (type) {
            case 'file':
                return 'Document';
            case 'file[]':
                return 'Documents list';

            case 'string':
                return 'String';
            case 'text':
                return 'Text';
            case 'number':
                return 'Number';
            case 'country':
                return 'Country';
            case 'date':
                return 'Date';
            case 'location':
                return 'Location';

            case 'string[]':
                return 'Strings list';
            case 'text[]':
                return 'Texts list';
            case 'number[]':
                return 'Numbers list';
            case 'country[]':
                return 'Countries list';
            case 'date[]':
                return 'Dates list';
            case 'location[]':
                return 'Locations list';

            case 'boolean':
                return 'Yes - no';
            case 'select':
                return 'Select';
            case 'money':
                return 'Money';
            case 'object':
                return 'Compound field';
            case 'persons':
                return 'Persons table';
            case 'companies':
                return 'Companies table';
            case 'table':
                return 'Data table';
        }

        return '> ' + type;
    }

    renderFlags(payload) {
        const flags = [];

        if (payload.flags && payload.flags.includes('key')) {
            flags.push(
                <span key="key" style={styles.flag}>
                    KEY
                </span>,
            );
        }

        if (payload.flags && payload.flags.includes('gdpr')) {
            flags.push(
                <span key="gdpr" style={styles.flag}>
                    GDPR
                </span>,
            );
        }

        payload.options && payload.options.flags && Object.values(payload.options.flags).filter(item => !!item).length
            ? flags.push(
                  <span key="autoflags" style={styles.flag}>
                      AUTO FLAGS
                  </span>,
              )
            : null;
        payload.options &&
        payload.options.choices &&
        Object.values(payload.options.choices).filter(item => item.flag).length
            ? flags.push(
                  <span key="autoflags" style={styles.flag}>
                      AUTO FLAGS
                  </span>,
              )
            : null;

        return flags;
    }

    renderEmbeds(question) {
        const embeds = toJS(this.props.store.embedded.get(question.id)) || [];

        if (question.payload.type === 'boolean') {
            const Yembeds = embeds.filter(embed => embed.value === 'Y').map(embed => embed.question);
            const Nembeds = embeds.filter(embed => embed.value === 'N').map(embed => embed.question);

            return [
                <Embed
                    readOnly={this.props.readOnly}
                    key="yes"
                    store={this.props.store}
                    parent={question.id}
                    children={Yembeds}
                    label="Yes"
                    value="Y"
                />,
                <Embed
                    readOnly={this.props.readOnly}
                    key="no"
                    store={this.props.store}
                    parent={question.id}
                    children={Nembeds}
                    label="No"
                    value="N"
                />,
            ];
        }
        if (question.payload.type === 'select') {
            return question.payload.options.choices.map((item, index) => {
                const children = embeds.filter(embed => embed.value === index).map(embed => embed.question);

                return (
                    <Embed
                        readOnly={this.props.readOnly}
                        key={index}
                        store={this.props.store}
                        parent={question.id}
                        children={children}
                        label={item.label}
                        value={index}
                    />
                );
            });
        }

        return [];
    }
}
