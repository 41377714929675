// @flow

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Card from 'dna/entity/card';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';
import { get } from 'lodash';

@inject(stores => ({
    reech: stores.reech,
}))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.explorer.search.result.persons';

    props: {
        state: Object,
        entities: Object[],
        company: string,
        age: string,
        gender: string,
        reech: any,
    };

    filteredEntities: any[];

    constructor(props: any) {
        super(props);
        this.filterEntities(props);
    }

    UNSAFE_componentWillReceiveProps(props: any) {
        if (
            props.entities !== this.props.entities ||
            props.company !== this.props.company ||
            props.age !== this.props.age ||
            props.gender !== this.props.gender
        ) {
            this.filterEntities(props);
        }
    }

    filterEntities(props: any) {
        let entities = (props.entities || []).slice();

        if (!entities.length) {
            this.filteredEntities = entities;
        }

        // gender
        switch (props.gender) {
            case 1:
            case 'M':
            case 'male':
                entities = entities.filter(
                    entity => !get(entity, 'preview.gender') || get(entity, 'preview.gender') === 'male',
                );
                break;

            case 2:
            case 'F':
            case 'female':
                entities = entities.filter(
                    entity => !get(entity, 'preview.gender') || get(entity, 'preview.gender') === 'female',
                );
                break;
        }

        // age

        const ageMatches = props.age ? props.age.match(/^(\d+):(\d+)$/) : undefined;

        if (ageMatches) {
            const thisYear = new Date().getFullYear();
            const minYear = thisYear - parseInt(ageMatches[1]) - parseInt(ageMatches[2]);
            const maxYear = thisYear - parseInt(ageMatches[1]) + parseInt(ageMatches[2]);

            entities = entities.filter(entity => {
                const birthDate = get(entity, 'preview.birthDate')
                    ? get(entity, 'preview.birthDate')
                    : get(entity, 'data.identity.birthDate')
                    ? get(entity, 'data.identity.birthDate')
                    : undefined;
                const year = birthDate ? parseInt(birthDate.substr(0, 4)) : undefined;

                return !year || (year >= minYear && year <= maxYear);
            });
        }

        // company

        if (props.company) {
            entities = entities.filter(entity => {
                const names = get(entity, 'preview.companies', []).slice();
                return names.filter(name => name.toLowerCase().includes(props.company.toLowerCase())).length > 0;
            });
        }

        this.filteredEntities = entities;
    }

    render() {
        const reechCustomers = this.props.reech.customers;
        const reechProspects = this.props.reech.prospects;
        const reechKnown = this.props.reech.known;
        const reechCount = reechKnown.length;

        return (
            <Views.List.Container state={this.props.state} entities={this.filteredEntities}>
                <Views.List.Frame type="cards" element={<Card />} />

                <Views.List.Reducer
                    type="sort"
                    specs={{
                        relations: {
                            label: t('common.label.relations'),
                            path: item =>
                                _.keys(_.get(item, 'preview.companies')).length +
                                _.keys(_.get(item, 'preview.persons')).length,
                            type: 'desc',
                        },
                        lastName: {
                            label: t('common.label.lastname'),
                            path: 'preview.lastName',
                            type: 'asc',
                        },
                        weasse: {
                            label: t('common.label.weasse'),
                            path: 'preview.weasse.value',
                            type: 'desc',
                        },
                        fortune: {
                            label: t('common.label.fortune'),
                            path: 'preview.fortune',
                            type: 'desc',
                        },
                    }}
                />

                <Views.List.Reducer
                    name="contacts"
                    title={t('dna.deefind.company.contactsFilter')}
                    type="groups"
                    specs={{
                        yes: {
                            label: '✓',
                            info: t('dna.deefind.company.contact.yes'),
                            filter: entity =>
                                _.get(entity, 'preview.mandates', 0) + _.get(entity, 'preview.positions', 0) > 0,
                        },
                        no: {
                            label: '✕',
                            info: t('dna.deefind.company.contact.no'),
                            filter: entity =>
                                _.get(entity, 'preview.mandates', 0) + _.get(entity, 'preview.positions', 0) === 0,
                        },
                    }}
                />

                <Views.List.Reducer
                    name="mandates"
                    title={t('common.label.mandates')}
                    type="groups"
                    specs={{
                        yes: {
                            label: '✓',
                            info: t('common.label.sortIndividualsContacts'),
                            filter: entity =>
                                _.get(entity, 'preview.mandates', 0) + _.get(entity, 'preview.positions', 0) > 0,
                        },
                        no: {
                            label: '✕',
                            info: t('common.label.sortIndividualsWithoutContacts'),
                            filter: entity =>
                                _.get(entity, 'preview.mandates', 0) + _.get(entity, 'preview.positions', 0) === 0,
                        },
                    }}
                />

                <Views.List.Reducer
                    name="reech"
                    type="groups"
                    title="Reech"
                    specs={{
                        any: {
                            label: '✓',
                            info: t('common.label.reechMatch'),
                            filter: item => reechKnown.includes(item.id),
                        },
                        customer: {
                            label: 'C',
                            info: t('common.label.customerMatch'),
                            filter: item => reechCustomers.includes(item.id),
                        },
                        prospect: {
                            label: 'P',
                            info: t('common.label.prospectMatch'),
                            filter: item => reechProspects.includes(item.id),
                        },
                    }}
                />

                <Views.List.Reducer
                    name="flag"
                    title={t('dna.deefind.person.flagFilter')}
                    type="groups"
                    specs={{
                        fortune: {
                            label: t('dna.deefind.person.contact.fortuneShort'),
                            info: t('dna.deefind.person.contact.fortune'),
                            filter: item => (item.preview.flags || []).includes('fortune'),
                        },
                        politic: {
                            label: t('dna.deefind.person.contact.politicShort'),
                            info: t('dna.deefind.person.contact.politic'),
                            filter: item => (item.preview.flags || []).includes('politic'),
                        },
                        justice: {
                            label: t('dna.deefind.person.contact.sanctionShort'),
                            info: t('dna.deefind.person.contact.sanction'),
                            filter: item => (item.preview.flags || []).includes('justice'),
                        },
                        listed: {
                            label: t('dna.deefind.person.contact.listedShort'),
                            info: t('dna.deefind.person.contact.listed'),
                            filter: item => (item.preview.flags || []).includes('listed'),
                        },
                        c19: {
                            label: 'c19',
                            info: 'Sensitivity to covid19 crisis',
                            filter: item => (item.preview.flags || []).includes('crisis-impact') || (item.preview.flags || []).includes('crisis-direct'),
                        },
                    }}
                />

                <Views.List.Reducer type="fuse" specs={{ paths: ['preview.firstName', 'preview.lastName'] }} />
            </Views.List.Container>
        );
    }
}
