// @flow

import type { CountsAllocation } from '../../types';

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'flex',
        height: '100px',
        marginLeft: '30px',
    },
    meter: {
        width: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        border: '1px solid white',
        borderTopWidth: '0',
    },
    bar: {
        borderTop: '1px solid white',
    },
    labels: {
        width: '40px',
        marginLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    label: {
        flexGrow: '1',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.results.extra';

    static propTypes = {
        label: PropTypes.string.isRequired,
        allocation: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.meter}>{this.renderBars(this.props.allocation)}</div>
                <div style={styles.labels}>{this.renderLabels(this.props.allocation)}</div>
            </div>
        );
    }

    renderBars(allocation: CountsAllocation) {
        const bars = [];

        const total = allocation.low + allocation.mid + allocation.high;
        const low = total ? Math.round((100 * allocation.low) / total) : 0;
        const mid = total ? Math.round((100 * allocation.mid) / total) : 0;
        const high = total ? Math.round((100 * allocation.high) / total) : 0;

        if (high) {
            bars.push(
                <div
                    key="high"
                    style={Object.assign({}, styles.bar, { background: colors.userGreen, height: high + '%' })}
                />,
            );
        }

        if (mid) {
            bars.push(
                <div
                    key="mid"
                    style={Object.assign({}, styles.bar, { background: colors.userWarning, height: mid + '%' })}
                />,
            );
        }

        if (low) {
            bars.push(
                <div
                    key="low"
                    style={Object.assign({}, styles.bar, { background: colors.userHot, height: low + '%' })}
                />,
            );
        }

        return bars;
    }

    renderLabels(allocation: CountsAllocation) {
        return [
            <div key="head" style={Object.assign({}, styles.label, { color: colors.userCold })}>
                {this.props.label}
            </div>,

            <div key="high" style={Object.assign({}, styles.label, { color: colors.userGreen })}>
                {allocation.high}
            </div>,

            <div key="mid" style={Object.assign({}, styles.label, { color: colors.userWarning })}>
                {allocation.mid}
            </div>,

            <div key="low" style={Object.assign({}, styles.label, { color: colors.userHot })}>
                {allocation.low}
            </div>,
        ];
    }
}
