import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import Preview from './preview';
import Form from './form';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import t from 'app/translation';

const styles = {
    container: {
    },
    preview: {
        margin: '10px 0',
    },
    actions: {
        display: 'flex',
        marginTop: '20px',
        borderTop: '1px solid ' + colors.grey400,
        paddingTop: '7px',
    },
    action: {
        width: 'auto',
    },
    spacer: {
        width: 'auto',
        flexGrow: '1',
    },
    details: {
        position: 'relative',
    },
    total: {
        position: 'absolute',
        top: '0',
        right: '10px',
        color: colors.userCold,
        fontSize: '19px',
    },
    properties: {
        margin: '20px 10px',
    },
    property: {
        fontSize: '13px',
        lineHeight: '17px',
    }
};

@inject(stores => ({ store: stores.comeetProspects }))

@observer
export default class extends React.Component<any, any> {

    static displayName = 'comeet.common.events.display';

    static propTypes = {
      entity: PropTypes.object.isRequired,
      prospect: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                <Preview
                    entity={ this.props.entity }
                    style={ styles.preview }
                />

                <div style={ styles.details }>
                    <Views.Common.Properties
                        align={ 60 }
                        style={ styles.properties }
                        values={ this.getProperties() }
                    />

                    <Views.Common.Number
                        style={ styles.total }
                        value={ this.getTotal() }
                        type="currency"
                    />
                </div>

                <div style={ styles.actions }>
                  <Views.Discussion.Button
                    color={'cold'}
                    type={'flat'}
                    target={ this.props.entity.id }
                    entities={ toJS(this.props.entity.comments) }
                    handleSubmit={(data, parentId) => {
                      this.props.store.commentEvent(this.props.entity.id, data, parentId)
                    }}
                    handleDelete={(commentId) => {
                      this.props.store.removeComment(this.props.prospect.id, commentId)
                    }}
                    preview={
                      <div>
                        <Preview
                            entity={ this.props.entity }
                            style={ styles.preview }
                        />
                        <div style={ { padding: '20px', backgroundColor: colors.bgGreen } }>
                        <div style={ styles.details }>
                            <Views.Common.Properties
                                align={ 60 }
                                style={ styles.properties }
                                values={ this.getProperties() }
                            />

                            <Views.Common.Number
                                style={ styles.total }
                                value={ this.getTotal() }
                                type="currency"
                            />
                        </div>
                        </div>
                    </div>
                    }
                  />

                    <Views.Common.Button
                        type="flat"
                        color="none"
                        icon={ <Icon type="base.attachment" /> }
                        disabled
                    />

                    <Views.Common.Button
                        type="flat"
                        color="none"
                        icon={ <Icon type="base.attendee" /> }
                        disabled
                    />

                    <div style={ styles.spacer } />

                    <Views.Common.Button
                        type="flat"
                        color="cold"
                        icon={ <Icon type="do.update" /> }
                        onClick={ event => this.refs.editor.open() }
                    />

                    <Views.Common.Button
                        type="flat"
                        color="hot"
                        icon={ <Icon type="do.delete" /> }
                        onClick={ event => this.refs.remover.open() }
                    />
                </div>

                <Form
                    ref="editor"
                    entity={ this.props.entity }
                    onSubmit={ this.props.onUpdate }
                />

                <Views.Modal.Removal
                    ref="remover"
                    subject="event"
                    onSubmit={ this.props.onRemove }
                >
                    <Preview entity={ this.props.entity } />
                </Views.Modal.Removal>
            </div>
        );
    }

    getProperties () {
        const properties = [];

        if (this.props.entity.time)
            properties.push({
                label: t('dna.comeet.time'),
                value: this.props.entity.time.substr(0, 5),
                style: styles.property,
            });

        if (this.props.entity.duration)
            properties.push({
                label: t('dna.comeet.duration'),
                value: <Views.Common.Duration
                    value={ this.props.entity.duration }
                />,
                style: styles.property,
            });

        if (this.props.entity.payload.settings.attendees)
            properties.push({
                label: t('common.label.people'),
                value: <Views.Common.Number
                    value={ this.props.entity.payload.settings.attendees }
                />,
                style: styles.property,
            });

        if (this.props.entity.payload.settings.days)
            properties.push({
                label: t('dna.comeet.days'),
                value: <Views.Common.Number
                    value={ this.props.entity.payload.settings.days }
                />,
                style: styles.property,
            });

        if (this.props.entity.payload.settings.unitPrice)
            properties.push({
                label: t('dna.comeet.unitPrice'),
                value: <Views.Common.Number
                    value={ this.props.entity.payload.settings.unitPrice }
                    type="currency"
                />,
                style: styles.property,
            });

        if (this.props.entity.payload.settings.adjustment)
            properties.push({
                label: t('dna.comeet.adjustment'),
                value: <Views.Common.Number
                    value={ this.props.entity.payload.settings.adjustment }
                    type="currency"
                />,
                style: styles.property,
            });

        return properties;
    }

    getTotal () {
        return (this.props.entity.payload.settings.unitPrice || 0)
            * (this.props.entity.payload.settings.days || 1)
            * (this.props.entity.payload.settings.attendees || 1)
            + (this.props.entity.payload.settings.offset || 0);
    }
}
