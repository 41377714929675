// @flow

import type { ResolverSpecs, ResolverInterface, StateLocation } from './types';
import type StateStore from './index';

import uuid from 'uuid';
import StatePath from './path';
import resolvers from './resolvers/index';

export default class StateNode {
    nodes: Map<string, Object>;
    path: StatePath;
    resolver: ResolverInterface;
    type: string;
    internal: ?boolean;
    fallback: any;
    listeners: Map<string, Function>;

    constructor (nodes: Map<string, Object>, path: StatePath, specs: ResolverSpecs) {
        if (! path instanceof StatePath) {
            throw new Error(`Invalid scope node path: ${JSON.stringify(path)}`);
        }

        const type = specs.type;
        const Resolver = resolvers[type];

        if (! Resolver) {
            throw new Error(`The scope at path "${path.toString()}" has an invalid type "${type}".`);
        }

        this.nodes = nodes;
        this.path = path;
        this.type = type;
        this.internal = specs.internal;
        this.fallback = specs.fallback;
        this.resolver = new Resolver(path, specs);
        this.listeners = new Map();
    }

    register (store: StateStore): void {
        if (this.resolver.register) {
            this.resolver.register(store);
        }
    }

    locate (data: Object, location: StateLocation): StatePath {
        if (location === undefined) {
            throw new Error(`Tried to locate undefined location.`);
        }

        if (this.resolver.locate) {
            location = this.resolver.locate(this, data, location);
        }

        return this.path.concat(location);
    }

    get (location: StateLocation, type: string): StateNode {
        if (typeof type !== 'string' || ! type) {
            console.warn(`Asked ${JSON.stringify(location)} state node with an invalid type: ${JSON.stringify(type)}`);
        }

        const path = this.path.concat(location);
        const node = this.nodes.get(path.toString());

        if (! node) {
            throw new Error(`Tried to get undefined "${path.toString()}" state node.`);
        }

        if (node.type !== type) {
            console.warn(`Asked "${path.toString()}" state node with an invalid type: ${JSON.stringify(type)}`);
        }

        return node;
    }

    normalize (value: any, external?: boolean = false): any {
        if (external && this.internal) {
            return undefined;
        }

        return this.resolver.normalize(this, value, external);
    }

    read (data: Object): any {
        const value = this.path.read(data);

        return value === undefined
            ? this.fallback
            : value;
    }

    enter (data: any, location: StateLocation, type: string): StateNode {
        const path = this.locate(data, location);
        const node = this.nodes.get(path.toString());

        if (! node) {
            throw new Error(`Tried to get undefined "${path.toString()}" state node.`);
        }

        if (node.type !== type) {
            console.warn(`Entered "${path.toString()}" state node with an invalid type: ${JSON.stringify(type)} instead of "${node.type}".`);
        }

        return node;
    }

    forge (location: StateLocation, specs: ResolverSpecs): StateNode {
        return new StateNode(this.nodes, this.path.concat(location), specs);
    }

    assign (data: Object, location: StateLocation, value: any): any {
        const newData = this.locate(data, location).write(data, value);
        this.dispatch(newData);

        return newData;
    }

    update (data: Object, values: ?Object): any {
        for (const location of Object.keys(values || {})) {
            if (location && typeof location === 'string') {
                data = this.locate(data, location).write(data, values[location]);
            }
        }

        this.dispatch(data);

        return data;
    }

    remove (data: Object, location: StateLocation): any {
        if (! this.resolver.remove) {
            throw new Error(`Tried to call "remove" on a "${this.type}" scope node.`);
        }

        const newData = this.resolver.remove(this, data, location);
        this.dispatch(newData);

        return newData;
    }

    select (data: Object, location: StateLocation): any {
        if (! this.resolver.select) {
            throw new Error(`Tried to call "select" on a "${this.type}" scope node.`);
        }

        const newData = this.resolver.select(this, data, location);
        this.dispatch(newData);

        return newData;
    }

    listen (listener: Function): Function {
        const id = uuid.v4();
        this.listeners.set(id, listener);

        return () => this.listeners.delete(id);
    }

    dispatch (data: Object) {
        if (! this.listeners.size) {
            const path = this.path.parent();

            if (! path) {
                console.warn('Undispatched state data', this.path.toString(), data);
                return;
            }

            const node = this.nodes.get(path.toString());

            if (! node) {
                throw new Error(`Could not find "${this.path.toString()}" node's parent`);
            }

            return node.dispatch(data);
        }


        const scopedData = this.path.read(data);
        this.listeners.forEach(listener => listener(scopedData));
    }
}
