// @flow

import React from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'app/layout/providers/data';
import Header from './header';
import Item from './item';
import { sortBy } from 'lodash';

const styles = {
    container: {
        flexGrow: '1',
    },
    list: {},
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.members';

    static propTypes = {
        id: PropTypes.string,
    };

    state = { level: null };

    render() {
        return (
            <DataProvider
                service="cleerance"
                model="team"
                scope="public"
                id={this.props.id}
                renderValue={data => {
                    const items = [];
                    const counts = { director: 0, manager: 0, member: 0, observer: 0 };

                    for (const id of sortBy(Object.keys(data.members))) {
                        const level = data.members[id];
                        if (counts.hasOwnProperty(level)) counts[level] += 1;
                        if (this.state.level && this.state.level !== level) continue;
                        items.push(<Item key={id} team={this.props.id} account={id} level={data.members[id]} />);
                    }

                    return (
                        <div style={styles.container}>
                            <Header
                                team={this.props.id}
                                counts={counts}
                                level={this.state.level}
                                onLevel={level => this.setState({ level })}
                            />

                            <div style={styles.list}>{items}</div>
                        </div>
                    );
                }}
            />
        );
    }
}
