import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Header from './header';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import ScopeProvider from '../../../app/layout/providers/scope';
import get from 'lodash/get';

const styles = {
    rating: {
        display: 'flex',
        height: '70px',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '15px',
        paddingBottom: '15px',
        verticalAlign: 'middle'
    },
    ratingValue: {
        width: '70px',
        background: colors.userGreen,
        color: 'white',
        fontSize: '32px',
        textAlign: 'center',
        lineHeight: '60px',
        marginRight: '5px',
        borderRadius: '3px',
    }
}

@inject(stores => ({ store: stores.deeligenzStudies, management: stores.deeligenzManagement }))
@observer
export default class extends React.Component {
    static displayName = 'deeligenz.archives.display';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
        updateStudy: PropTypes.func.isRequired,
        removeStudy: PropTypes.func.isRequired,
        fetchStudy: PropTypes.func.isRequired,
        commentStudy: PropTypes.func.isRequired,
        applyStudy: PropTypes.func.isRequired,
        startStudy: PropTypes.func.isRequired,
        setStudyOnSite: PropTypes.func.isRequired,
        setStudyParagraph: PropTypes.func.isRequired,
        setStudyHeader: PropTypes.func.isRequired,
        setStudyRating: PropTypes.func.isRequired,
        answerField: PropTypes.func.isRequired,
        answerEditField: PropTypes.func.isRequired,
        applyField: PropTypes.func.isRequired,
        setFieldFlags: PropTypes.func.isRequired,
        setFieldRemark: PropTypes.func.isRequired,
        commentField: PropTypes.func.isRequired,
        getFileUrl: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    componentDidMount() {
        if (this.props.state && this.props.state.id) {
            this.context.fetchStudy(this.props.state.id);
        }
    }

    render() {
        if (!this.props.store || !this.props.state) {
            return null;
        }

        const entity = toJS(this.props.store.studies.get(this.props.state.id));
        const fetched = this.props.store.fetched;

        if (!entity) {
            return null;
        }

        if (!fetched.includes(this.props.state.id)) {
            return <Views.Common.Loader title="Loading data" />;
        }

        return (
            <div style={this.props.style}>
                <Header
                    entity={entity}
                    onAbort={event => this.context.removeStudy(entity.id)}
                    onUpdate={payload => this.context.updateStudy(entity.id, payload)}
                    onComment={(channel, comment) => this.context.commentStudy(entity.id, channel, comment)}
                    onApply={(transition, comment) => this.context.applyStudy(entity.id, transition, comment)}
                    onToggleLibrary={event => this.refs.library.toggle()}
                    onSetNda={this.props.onSetNda}
                    onReadNda={this.props.onReadNda}
                    onWriteNda={this.props.onWriteNda}
                />
                {this.renderContent(entity)}
            </div>
        );
    }

    renderContent(entity) {
        const ratingColor = get(entity, 'payload.rating') > 4 ? colors.userHot : colors.userGreen;

        return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 2, padding: '5px', background: ''}}>
            <div style={{fontWeight: 'bold'}}>Associated files</div>
                <ul style={{listStyle: 'none', padding: 0, borderRadius: '5px', background: colors.bgCold}}>{get(entity, 'payload.archive.files', []).map(file => <li style={{borderBottom: '5px solid white', padding: '5px'}}>{file.name} <br/><Views.Common.Button mini={true} label={"Download"} onClick={(e) => {this.props.store.downloadFile(file.id, file.name)}} /></li>)}</ul>
            </div>
            <div style={{flex: 3, padding: '5px'}}>
                <div style={{display: 'flex'}}>
                    <div>
                        <div style={styles.rating}>
                            <div
                                style={Object.assign({}, styles.ratingValue, {
                                    background: ratingColor,
                                    textTransform: 'uppercase',
                                })}
                            >
                                {get(entity, 'payload.rating')}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{marginTop: '30px'}}>
                            <div style={{fontWeight: 'bold'}}>{get(entity, 'payload.archive.name')}</div>
                            <div style={{fontWeight: 'bold'}}>On Site Visit: {get(entity, 'payload.onSite.finalDate')}</div>
                        </div>
                    </div>
                </div>
                <div style={{ whiteSpace: 'pre-line', borderRadius: '5px', marginBottom: '20px', padding: '10px', background: colors.bgBlack }}>{get(entity, 'payload.archive.summary')}</div>
            </div>
        </div>
    )
    }
}
