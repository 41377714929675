import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import t from 'app/translation';
import Block from './block';
import BlockNorkom from './blockNorkom';
import Title from './title';
import Score from './score';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        borderRadius: '3px',
        padding: '10px 0',
        alignItems: 'flex-start',
    },
    title: {
        paddingLeft: '15px',
        color: colors.black,
        fontSize: '21px',
    },

    matrix: {
        width: '100%',
        margin: '10px 0',
        display: 'flex',
        flexWrap: 'wrap',
    },

    main: {
        width: '310px',
        background: colors.grey100,
        padding: '10px 20px 20px 20px',
        borderRadius: '3px',
        flex: '1 0 21%',
    },
    disclaimer: {
        margin: '20px 0',
        color: colors.grey700,
        fontSize: '13px',
    },
    open: {},
};

@inject('deefind')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.vigilance.panel';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onOpen: PropTypes.func.isRequired,
        deefind: PropTypes.object.isRequired,
    };

    render() {
        const network = toJS(this.props.deefind.results.get(`network:${this.props.entity.id}`));
        const report = (network ? network.vigilance : null) || this.props.entity.vigilance || {};

        const progress1 = this.props.deefind.progress.get(`network1:${this.props.entity.id}`);
        const progress2 =
            this.props.entity.type === 'person'
                ? this.props.deefind.progress.get(`network2:${this.props.entity.id}`)
                : progress1;

        return (
            <div style={Object.assign({}, styles.container)}>
                <div style={styles.matrix}>
                    <Block
                        with={['country', 'mandates', 'positions', 'listedRelations','politic', 'justice', 'publication']}
                        title="Direct index"
                        data={report.direct}
                        type="direct"
                        tooltip={t('dna.deefind.veegilenzData.details.directLabel')}
                    />

                    <Block
                        with={['activity', 'country', 'mandates', 'positions', 'justice']}
                        title="Corporate network"
                        data={report.companies ? report.companies.current :  undefined}
                        progress={progress1}
                        type="network"
                        tooltip={t('dna.deefind.veegilenzData.details.corporateLabel')}
                    />

                    <Block
                        with={['country', 'mandates', 'positions', 'politic', 'justice']}
                        title="Individual network"
                        data={report.persons ? report.persons.current :  undefined}
                        progress={progress2}
                        type="network"
                        tooltip={t('dna.deefind.veegilenzData.details.networkLabel')}
                    />

                    <div style={styles.main}>
                        <Title />
                        <br />
                        <Score score={report.score} big />

                        <div
                            style={styles.disclaimer}
                            dangerouslySetInnerHTML={{ __html: t('dna.deefind.veegilenzData.details.veegilenzInfo') }}
                        />

                        <Views.Common.Button
                            style={styles.open}
                            type="raised"
                            color="cold"
                            label={'Explore entity'}
                            icon={<Icon type="do.open" />}
                            onClick={event => this.props.onOpen()}
                        />
                    </div>
                    
                    <BlockNorkom title="Norkom" data={this.props.entity.vigilance.norkom} progress={progress2} />
                </div>
            </div>
        );
    }
}
