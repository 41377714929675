import PropTypes from 'prop-types';
import React from 'react';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

let styles = {
    container: {
        paddingLeft: '20px'
    },
    item: {
        marginBottom: '5px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.explorer.search.form.criteria.typologies';

    static propTypes = {
        current: PropTypes.object,
        status: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render () {
        const disabled = this.props.status === 'loading';
        return (
            <RadioButtonGroup
                style={ styles.container }
                name="typology"
                valueSelected={this.props.current.provider}
                onChange={ (event, value) => this.props.onChange({ provider: value, version: 0 }) }
            >
                <RadioButton
                    style={ styles.item }
                    value={301}
                    label="Les 500 plus grandes fortunes de france en 2018"
                    disabled={ disabled }
                />
                <RadioButton
                    style={ styles.item }
                    value={302}
                    label="Les Députés français"
                    disabled={ disabled }
                />
                <RadioButton
                    style={ styles.item }
                    value={304}
                    label="Les sénateurs français"
                    disabled={ disabled }
                />
                <RadioButton
                    style={ styles.item }
                    value={303}
                    label="Sanctions AMF"
                    disabled={ disabled }
                />
                <RadioButton
                    style={ styles.item }
                    value={310}
                    label="Arx"
                    disabled={ disabled }
                />
            </RadioButtonGroup>
        );
    }
}
