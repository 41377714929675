import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import t from 'app/translation';
import { get } from 'lodash';
import Views from 'app/views';
import Sum from 'dna/entity/common/display/sum';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.person.display.blocks.relations';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <DeefindProvider
                state={this.props.state}
                type="network"
                id={this.props.entity.id}
                depth={2}
                mini
                renderEntity={(network, state) => (
                    <Views.Common.Properties
                        values={this.formatValues(get(network, 'computed.sums.persons', {}))}
                        fallback={t('veegilenzData.details.noData')}
                        align={90}
                    />
                )}
            />
        );
    }

    formatValues(aggregates) {
        return [
            { label: t('dna.deefind.veegilenzData.person.sumFortuneLabel'), tooltip: t('common.tooltip.fortuneSum'), value: <Sum data={aggregates.fortune} /> },
            { label: t('dna.deefind.veegilenzData.person.sumWeasseLabel'),  tooltip: t('common.tooltip.weasseSum'), value: <Sum data={aggregates.weasse} /> },
            // { label: t('dna.deefind.veegilenzData.person.sumWeasseMaxLabel'), value: (<Sum data={ aggregates.weasseMax } />) },
            // { label: t('dna.deefind.veegilenzData.person.sumSumTurnoverLabel'), value: (<Sum data={ aggregates.turnoversSum } />) },
            {
                label: t('dna.deefind.veegilenzData.person.sumSumEbitdaLabel'),
                tooltip: t('common.tooltip.ebitdaSumSum'),
                value: <Sum data={aggregates.ebitdasSum} />,
            },
            // { label: t('dna.deefind.veegilenzData.person.sumSumCapitalLabel'), value: (<Sum data={ aggregates.capitalsSum } />) },
            {
                label: t('dna.deefind.veegilenzData.person.sumSumValuationLabel'),
                value: <Sum data={aggregates.valuationsSum} />,
            },
        ];
    }
}
