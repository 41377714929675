import PropTypes from 'prop-types';
import React from 'react';
import DeefindProvider from 'app/layout/providers/deefind';

import Views from 'app/views';
import colors from 'app/theme/colors';
import t from 'app/translation';

const styles = {
    matrix: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr 1fr',
    },
    title: {
        color: colors.userCold,
    },
    head: {
        color: colors.userCold,
        textAlign: 'center',
    },
    value: {
        textAlign: 'center',
    },
    empty: {
        textAlign: 'center',
        color: colors.grey500,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.blocks.contacts';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <DeefindProvider
                state={this.props.state}
                type="network"
                id={this.props.entity.id}
                depth={1}
                mini
                renderEntity={(network, state) => {
                    const contacts = []
                        .concat(network.relations.companies.slice() || [])
                        .concat(network.relations.persons.slice() || []);

                    return (
                        <div style={styles.matrix}>
                            <div></div>
                            <div style={styles.head}>Current</div>
                            <div style={styles.head}>Past</div>
                            <div style={styles.title}>Companies</div>
                            {this.renderCount(contacts, 'company', false)}
                            {this.renderCount(contacts, 'company', true)}
                            <div style={styles.title}>Persons</div>
                            {this.renderCount(contacts, 'person', false)}
                            {this.renderCount(contacts, 'person', true)}
                        </div>
                    );
                }}
            />
        );
    }

    renderCount(contacts, type, ceased) {
        const count = contacts.filter(relation => {
            return (
                relation && relation.target && relation.target.type === type && (relation.ceased || false) === ceased
            );
        }).length;

        if (count > 0) {
            return <div style={styles.value}>{count}</div>;
        }

        return <div style={styles.empty}>{count}</div>;
    }

    // countMandateTypes(contacts, level) {
    //     return contacts.filter(relation => relation && relation.level === level).length;
    // }
}
