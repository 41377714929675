import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import RadioButton from 'material-ui/RadioButton';

const styles = {
    container: {
        display: 'flex',
        padding: '5px',
    },
    left: {
        flexGrow: '0',
        flexShrink: '0',
        width: '280px',
    },
    row: {
        display: 'flex',
    },
    right: {
        flexGrow: '1',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    arrow: {
        textAlign: 'center',
        fontSize: '24px',
        color: colors.userHot,
        flexGrow: '0',
        flexShrink: '0',
        width: '50px',
    },
    choice: {
        display: 'flex',
    },
    column: {
        flexShrink: '1',
        flexGrow: '1',
        width: '1',
    },
    radio: {
        padding: '5px 0 5px 10px',
        background: 'transparent',
        borderRadius: '3px',
        boxSizing: 'border-box',
    },
    checkbox: {
        padding: '2px 0 2px 10px',
        background: 'transparent',
        borderRadius: '3px',
        boxSizing: 'border-box',
    },
    hover: {
        background: colors.bgBlack,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.form.target';

    static propTypes = {
        value: PropTypes.object,
        style: PropTypes.object,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    };

    state = { hover: null };

    render() {
        const value = this.props.value || {};

        return (
            <div style={styles.container} onClick={event => (this.props.onFocus ? this.props.onFocus(event) : null)}>
                <div style={styles.left}>
                    <div style={styles.row}>
                        {this.renderRadio('Investment manager', value.type === 'manager', {
                            type: 'manager',
                            variant: 'external',
                        })}
                        <span style={styles.arrow}>{value.type === 'manager' ? '→' : ''}</span>
                    </div>

                    <div style={styles.row}>
                        {this.renderRadio('Fund', value.type === 'fund', { type: 'fund', variant: 'traditional' })}
                        <span style={styles.arrow}>{value.type === 'fund' ? '→' : ''}</span>
                    </div>
                </div>

                {this.renderRight(value)}
            </div>
        );
    }

    renderRight(value) {
        if (value.type === 'fund') {
            return (
                <div style={styles.right}>
                    {this.renderRadio('Traditional', value.variant === 'traditional', {
                        type: 'fund',
                        variant: 'traditional',
                    })}
                    {this.renderRadio('Passive / ETF', value.variant === 'passive', {
                        type: 'fund',
                        variant: 'passive',
                    })}
                    {this.renderRadio('Private Equity', value.variant === 'equity', {
                        type: 'fund',
                        variant: 'equity',
                    })}
                    {this.renderRadio('Alternative', value.variant === 'alternative', {
                        type: 'fund',
                        variant: 'alternative',
                    })}
                </div>
            );
        }

        if (value.type === 'manager') {
            return (
                <div style={styles.right}>
                    {this.renderRadio('External manager', value.variant === 'external', {
                        type: 'manager',
                        variant: 'external',
                    })}
                    {this.renderRadio('Delegated management', value.variant === 'delegated', {
                        type: 'manager',
                        variant: 'delegated',
                    })}
                </div>
            );
        }
    }

    renderRadio(label, checked, value) {
        const style = Object.assign({}, styles.radio, this.state.hover === label ? styles.hover : {});

        return (
            <span
                style={{ flexGrow: '1' }}
                onMouseEnter={event => this.setState({ hover: label })}
                onMouseLeave={event => this.setState({ hover: null })}
            >
                {
                    <RadioButton
                        checked={checked}
                        label={label}
                        style={style}
                        labelStyle={{ color: checked ? colors.black : colors.grey500, whiteSpace: 'nowrap' }}
                        iconStyle={{ fill: checked ? colors.userCold : colors.grey500 }}
                        onCheck={event => this.props.onChange(value)}
                    />
                }
            </span>
        );
    }
}
