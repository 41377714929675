import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

const styles = {
    button: {
        fontSize: '13px',
    },
    form: {
        marginBottom: '20px',
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        lineHeight: '24px',
    },
    title: {
        padding: '10px',
        color: colors.userCold,
        background: colors.grey100,
    },
    field: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey300,
    },
    actions: {
        padding: '10px',
        color: colors.userCold,
        background: colors.grey100,
        borderTop: '1px solid ' + colors.grey300,
        display: 'flex',
        alignItems: 'center',
    },
    actionsError: {
        width: '50%',
        color: colors.userHot,
        fontWeight: 'bold',
    },
    actionsButton: {
        width: '25%',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.onSite.evaluation.widget.files';

    static contextTypes = {
        getFileUrl: PropTypes.func.isRequired,
        downloadFile: PropTypes.func.isRequired,
    };

    static propTypes = {
        files: PropTypes.array,
        onDelete: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onUpload: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = {
        previews: {},
        previewsToUpload: {},
        filesContent: [],
        files: this.props.files || [],
        filesToUpload: [],
        resetInput: Math.random().toString(36),
        pending: false,
        uploadErrors: 0,
    };

    setUploaded() {
        this.setState({
            filesContent: [],
            pending: false,
            resetInput: Math.random().toString(36),
        });
    }

    render() {
        return (
            <div style={styles.form}>
            <Views.Modal.Pdf onTop={true} ref="pdf_modal" />
            <Views.Modal.Img onTop={true} ref="pdf_img" />
                <div style={styles.title}>Additional files</div>
                <div style={styles.field}>
                    <table style={{ width: '100%' }}>
                        <tbody>
                            {!this.state.files
                                ? null
                                : this.state.files.map((item, index) =>
                                      !item ? null : (
                                          <tr key={item.id} style={{ padding: '10px', background: 'white' }}>
                                              <td>
                                                  <div style={{ padding: '10px' }}>{item.name}</div>
                                              </td>
                                              <td style={{ textAlign: 'right' }}>
                                                  {this.state.previews[item.id] ? (
                                                      <audio style={{ verticalAlign: 'inherit' }} controls autoPlay>
                                                          <source src={this.state.previews[item.id]} type={item.type} />
                                                      </audio>
                                                  ) : (
                                                      <Views.Common.Button
                                                          type="flat"
                                                          color="cold"
                                                          label=""
                                                          mini={true}
                                                          icon={<Icon type="do.watch" />}
                                                          style={styles.button}
                                                          onClick={async () => {
                                                              if (item.type === 'application/pdf') {
                                                                  return this.refs['pdf_modal'].open(
                                                                      await PDFJS.getDocument(
                                                                          await this.props.onDownload(item.id),
                                                                      ).promise,
                                                                  );
                                                              }

                                                              if (item.type.match(/^image\//g)) {
                                                                  return this.refs['pdf_img'].open(await this.context.getFileUrl(item.id));
                                                              }

                                                              const previews = this.state.previews;
                                                              previews[item.id] = await this.props.onDownload(item.id);

                                                              this.setState({ previews });
                                                          }}
                                                      />
                                                  )}
                                                  <Views.Common.Button
                                                      type="flat"
                                                      color="cold"
                                                      label=""
                                                      mini={true}
                                                      icon={<Icon type="do.download" />}
                                                      style={styles.button}
                                                      onClick={async () => {
                                                          await this.context.downloadFile(item.id, item.name);
                                                      }}
                                                  />
                                                  <Views.Common.Button
                                                      type="flat"
                                                      color="hot"
                                                      label=""
                                                      mini={true}
                                                      icon={<Icon type="do.delete" />}
                                                      style={styles.button}
                                                      onClick={() => {
                                                          this.props.onDelete(item.id);
                                                          const files = Object.assign(this.state.files);
                                                          delete files[index];

                                                          this.setState({ files: files.filter(item => !!item) });
                                                      }}
                                                  />
                                              </td>
                                          </tr>
                                      ),
                                  )}
                            {!this.state.filesToUpload
                                ? null
                                : this.state.filesToUpload.map((item, index) =>
                                      !item ? null : (
                                          <tr key={item.id} style={{ padding: '10px', background: 'white' }}>
                                              <td>
                                                  <div style={{ padding: '10px' }}>{item.name}</div>
                                              </td>
                                              <td style={{ textAlign: 'right' }}>
                                                  {this.state.previewsToUpload[index] ? (
                                                      <audio style={{ verticalAlign: 'inherit' }} controls autoPlay>
                                                          <source src={URL.createObjectURL(new Blob([this.state.previewsToUpload[index]]))} type={item.type} />
                                                      </audio>
                                                  ) : (
                                                      <Views.Common.Button
                                                          type="flat"
                                                          color="cold"
                                                          label=""
                                                          mini={true}
                                                          icon={<Icon type="do.watch" />}
                                                          style={styles.button}
                                                          onClick={async () => {
                                                              if (item.type === 'application/pdf') {
                                                                  return this.refs['pdf_modal'].open(
                                                                      await PDFJS.getDocument(
                                                                          { data: new Uint8Array(item.content) }
                                                                      ).promise,
                                                                  );
                                                              }

                                                              if (item.type.match(/^image\//g)) {
                                                                  return this.refs['pdf_img'].open(URL.createObjectURL(new Blob([item.content])));
                                                              }

                                                              const previewsToUpload = this.state.previewsToUpload;
                                                              previewsToUpload[index] = item.content;

                                                              this.setState({ previewsToUpload });
                                                          }}
                                                      />
                                                  )}
                                                  <Views.Common.Button
                                                      type="flat"
                                                      color="hot"
                                                      label=""
                                                      mini={true}
                                                      icon={<Icon type="do.delete" />}
                                                      style={styles.button}
                                                      onClick={() => {
                                                          const files = Object.assign(this.state.filesToUpload);
                                                          delete files[index];

                                                          this.setState({ filesToUpload: files.filter(item => !!item) });
                                                      }}
                                                  />
                                              </td>
                                          </tr>
                                      ),
                                  )}
                        </tbody>
                    </table>
                </div>
                <div style={styles.actions}>{this.renderUpload()}</div>
            </div>
        );
    }

    renderUpload() {
        if (this.state.pending) {
            return (
                <div style={{ display: 'inline-block', verticalAlign: 'middle', padding: '10px' }}>
                    Uploading files...
                </div>
            );
        }

        return [
            <div key="error" style={styles.actionsError}>
                {this.state.uploadErrors ? 'Upload failed, please retry' : ''}
            </div>,
            <div key="input" style={styles.actionsButton}>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Upload files"
                    icon={<Icon type="do.create" />}
                    style={styles.button}
                    onClick={event => this.inputElement.click()}
                />

                <input
                    ref={element => (this.inputElement = element)}
                    type="file"
                    hidden
                    //accept=".mp3"
                    multiple="multiple"
                    key={this.state.resetInput}
                    onChange={event => {
                        const files = event.target.files;
                        const filesContent = [];

                        for (const file of files) {
                            const reader = new FileReader();
                            reader.onload = async event => {
                                const content = event.target.result;
                                filesContent.push({ name: file.name, content, type: file.type });
                                if (filesContent.length === files.length) {
                                    this.setState({ filesToUpload: filesContent });
                                }
                            };

                            reader.readAsArrayBuffer(file);
                        }
                    }}
                />
            </div>,
            <div key="submit" style={styles.actionsButton}>
                {!this.state.filesToUpload.length || this.state.pending ? null : <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.upload" />}
                    label="Submit files"
                    style={{}}
                    onClick={event => {
                        this.setState({ pending: true, uploadErrors: 0 }, async () => {
                            const files = [];
                            let uploadErrors = 0;

                            for (const file of this.state.filesToUpload) {
                                try {
                                    files.push(await this.props.onUpload(file));
                                } catch(error) {
                                    uploadErrors ++;
                                }
                            }

                            const concat = this.state.files.concat(files);
                            if (!uploadErrors) this.props.onChange(concat);

                            this.setState({ pending: false, uploadErrors, previewsToUpload: {}, filesToUpload: [], files: concat });
                        });
                    }}
                />}
            </div>,
        ];
    }
}
