// @flow

export default {
    type: 'menu',
    prototype: {
        type: 'router',
        routes: {
            batch: {
                id: { type: 'string' },
                source: { type: 'string' },
                persons: { type: 'boolean' },
                companies: { type: 'boolean' },
                step: { type: 'string' },
                tool: { type: 'string' },
                filters: { type: 'object', prototype: { type: 'number'} },
                axes: { type: 'object', prototype: { type: 'string'} },
            },
        },
    },
};
