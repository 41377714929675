import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import colors from 'app/theme/colors';

const EVENT_WIDTH = 300;
const EVENT_GUTTER = 20;
const EVENT_PADDING = 10;

const scroll = (container, destination, callback) => {
    const TIME = 150;
    const RATE = 15;

    const start = container.scrollLeft;
    const steps = TIME / RATE;
    const step = Math.PI / steps;
    const coef = (destination - container.scrollLeft) / 2;

    let counter = 0;
    const move = () => setTimeout(() => {
        if (counter >= steps)
            return callback();

        counter += 1;
        container.scrollLeft = start + (coef - coef * Math.cos(counter * step));
        requestAnimationFrame(move);
    }, RATE);

    requestAnimationFrame(move);
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        overflowX: 'scroll',
        marginTop: '-3px',
    },
    scrollMode: {
        justifyContent: 'flex-start'
    },
    event: {
        position: 'relative',
        background: colors.grey100,
        border: '1px solid ' + colors.grey500,
        padding: `${EVENT_PADDING - 2}px`,
        borderRadius: '3px',
        width: `${EVENT_WIDTH}px`,
        flexShrink: '0',
        margin: `2px ${EVENT_GUTTER / 2}px`
    },
    highlighted: {
        border: '1px solid ' + colors.userHot,
        boxShadow: '0 0 2px ' + colors.userHot,
    },
    dimmed: {
        WebkitFilter: 'grayscale(1)',
        filter: 'grayscale(1)',
        opacity: '0.5'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.display.events';

    static propTypes = {
        events: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            position: PropTypes.number.isRequired,
            tooltip: PropTypes.node.isRequired,
            dimmed: PropTypes.bool.isRequired
        })),
        highlighted: PropTypes.string,
        onHighlight: PropTypes.func,
        onLink: PropTypes.func.isRequired
    };

    state = {
        footprint: undefined,
        mustSendLinks: true,
        willSendLinks: false,
        scrollMode: false,
        mouseInside: false,
        scrolling: false
    };

    componentDidMount () {
        this.computeMode();
        this.sendLinks();
    }

    UNSAFE_componentWillReceiveProps (props) {
        const footprint = _.map(props.events, event => `${event.id}:${event.position}`).join(',');
        this.state.mustSendLinks = this.state.footprint !== footprint;
        this.state.footprint = footprint;

        if (props.highlighted && ! this.state.mouseInside && props.highlighted !== this.props.highlighted)
            this.showEvent(props.highlighted);
    }

    componentDidUpdate () {
        this.computeMode();

        if (this.state.mustSendLinks) {
            this.sendLinks();
            this.state.mustSendLinks = false
        }
    }

    computeMode () {
        const container = ReactDOM.findDOMNode(this.refs.container);
        const scrollMode = container.scrollWidth > container.clientWidth;

        if (scrollMode !== this.state.scrollMode)
            this.setState({ scrollMode: scrollMode }, this.sendLinks.bind(this));

    }

    sendLinks () {
        if (! this.props.events.length)
            return;

        const links = {};
        let step, offset = - ReactDOM.findDOMNode(this.refs.container).scrollLeft;

        if (this.state.scrollMode) {
            step = EVENT_WIDTH + EVENT_PADDING * 2 + EVENT_GUTTER;
            offset += EVENT_WIDTH / 2;
        } else {
            const width = ReactDOM.findDOMNode(this.refs.container).offsetWidth;
            step = width / this.props.events.length;
            offset += step / 2;
        }

        _.each(this.props.events, (event, index) => {
            links[ event.id ] = offset + step * index;
        });

        this.props.onLink(links);
    }

    showEvent (id: any) {
        if (! this.state.scrollMode || this.state.scrolling)
            return;

        const index = _.findIndex(this.props.events, { id: id });
        if (index < 0) return;

        const container = ReactDOM.findDOMNode(this.refs.container);
        const left = (EVENT_WIDTH + EVENT_PADDING * 2 + EVENT_GUTTER) * index;
        const right = left + EVENT_WIDTH + EVENT_PADDING * 2;
        const margin = EVENT_GUTTER + 10;

        let destination = null;
        if (left < container.scrollLeft)
            destination = left - margin;
        else if (right > (container.clientWidth + container.scrollLeft))
            destination = right - container.clientWidth + margin;

        if (! _.isNull(destination)) {
            this.state.scrolling = true;
            scroll(container, destination, () => this.state.scrolling = false);
        }
    }

    render () {
        const length = this.props.events.length;
        const style = this.state.scrollMode
            ? Object.assign({}, styles.container, styles.scrollMode)
            : styles.container;

        return (
            <div
                ref="container"
                style={ style }
                onScroll={ event => {
                    if (this.state.willSendLinks) return;
                    this.state.willSendLinks = true;
                    setTimeout(function () {
                        this.state.willSendLinks = false;
                        this.sendLinks();
                    }.bind(this), 100);
                } }
                onMouseEnter={ event => this.state.mouseInside = true }
                onMouseLeave={ event => this.state.mouseInside = false }
            >
                { this.props.events.map((event, index) => this.renderEvent(event, index, length)) }
            </div>
        );
    }

    renderEvent (event, index, length) {
        const style = event.id === this.props.highlighted
            ? Object.assign({}, styles.event, styles.highlighted, event.style || {})
            : Object.assign({}, styles.event, event.dimmed ? styles.dimmed : {}, event.style || {});

        if (index === 0)
            style.marginLeft = '0';

        if (index === length - 1)
            style.marginRight = '0';

        return (
            <div
                key={ event.id }
                style={ style }
                onMouseEnter={ e => {
                    this.props.onHighlight(event.id);
                    this.showEvent(event.id);
                } }
                onMouseLeave={ e => this.props.onHighlight(undefined) }
            >
                { event.children }
            </div>
        );
    }
}
