import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

let styles = {
    container: {
        position: 'relative',
        margin: '20px 0',
        width: '410px',
        height: '66px',
        background: colors.grey100,
        borderRadius: '3px',
        padding: '15px',
    },
    header: {
        lineHeight: '20px',
        fontSize: '17px',
        paddingBottom: '10px',
        display: 'flex',
    },
    open: {
        float: 'right',
        margin: '5px 5px 5px 15px',
    },
    title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    relevance: {
        position: 'absolute',
        right: '100px',
        bottom: '20px',
        display: 'inline-block',
        marginLeft: '10px',
        color: colors.userCold,
        flexGrow: '0',
        flexShrink: '0',
        fontSize: '21px',
    },
    value: {
        color: colors.grey500,
        height: '36px',
        overflow: 'hidden',
        paddingRight: '65px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.compliance.common.preview';

    static propTypes = {
        title: PropTypes.string.isRequired,
        relevance: PropTypes.number.isRequired,
        flag: PropTypes.node.isRequired,
        value: PropTypes.string,
        label1: PropTypes.string,
        value1: PropTypes.node,
        label2: PropTypes.string,
        value2: PropTypes.node,
        onOpen: PropTypes.func,
    };

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style)}>
                <Views.Common.Button
                    style={styles.open}
                    type="round"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    onClick={this.props.onOpen}
                />

                <div style={styles.header}>
                    {this.props.flag}

                    <span style={styles.title}>{this.props.title}</span>
                </div>

                {this.renderValue()}
                <span style={styles.relevance}>{Math.round(this.props.relevance) + ' %'}</span>
            </div>
        );
    }

    renderValue() {
        if (this.props.value) {
            return <div style={styles.value}>{this.props.value}</div>;
        }

        return (
            <Views.Common.Properties
                values={[
                    { label: this.props.label1, value: this.props.value1 },
                    { label: this.props.label2, value: this.props.value2 },
                ]}
            />
        )
    }
}
