import PropTypes from 'prop-types';
import React from 'react';

import computer from './computer';
import Header from './header';
import Body from './body';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.container';

    static contextTypes = {
        updateState: PropTypes.func.isRequired
    };

    static propTypes = {
        entities: PropTypes.array,
        state: PropTypes.object,
        style: PropTypes.object
    };

    static defaultProps = {
        entities: [],
        reducers: {},
    };

    state = { pages: {} };

    constructor (props) {
        super(props);
        this.initialize(props);
        this.compute(props);
    }

    UNSAFE_componentWillReceiveProps (props) {
        this.initialize(props);
        this.compute(props);
    }

    initialize (props) {
        this.elements = {
            blocks: {},
            extra: {},
            frames: [],
            reducers: [],
        };

        React.Children.forEach(props.children, child => {
            if (! child) {
                return;
            }

            switch (child.type.displayName) {
                case 'app.views.list.model.block':
                    if (! this.elements.blocks.hasOwnProperty(child.props.position)) {
                        this.elements.blocks[ child.props.position ] = [];
                    }

                    React.Children.forEach(child.props.children, item => {
                        this.elements.blocks[ child.props.position ].push(item);
                    });

                    break;

                case 'app.views.list.model.data':
                    this.elements.extra[ child.props.name ] = child.props.values;

                    break;

                case 'app.views.list.model.frame': {
                    const scope = child.props.scope || ('#' + this.elements.frames.length);

                    this.elements.frames.push({
                        scope: scope,
                        title: child.props.title,
                        filter: child.props.filter,
                        folded: child.props.folded,
                        type: child.props.type,
                        specs: child.props.specs,
                        element: child.props.element,
                        loading: child.props.loading,
                        missing: child.props.missing,
                        size: child.size,
                        page: this.state.pages[ scope ] || child.props.page || 1,
                    });

                    break;
                }

                case 'app.views.list.model.reducer': {
                    const name = child.props.name || child.props.type;

                    this.elements.reducers.push({
                        type: child.props.type,
                        name,
                        title: child.props.title,
                        specs: child.props.specs,
                        style: child.props.style,
                        value: (props.state && props.state.reducers)
                            ? (props.state.reducers[name] || child.props.value)
                            : child.props.value,
                    });

                    break;
                }
            }
        });
    }

    compute (props) {
        if (this.props.state && this.props.state.progress && this.computedEntities) {
            return;
        }

        this.computedEntities = computer.reduceEntities(
            props.entities || [],
            this.elements.reducers,
            this.elements.extra
        );
    }

    render () {
        if (! this.props.entities.length) {
            return null;
        }

        return (
            <div style={ this.props.style }>
                <Header
                    blocks={ this.elements.blocks }
                    reducers={ this.elements.reducers }
                    count={ this.computedEntities.length }
                    total={ this.props.entities.length }
                    onReduce={ (reducer, value) => this.context.updateState({ [`reducers.${reducer}`]: value}) }
                />

                <Body
                    entities={ this.computedEntities }
                    extra={ this.elements.extra }
                    blocks={ this.elements.blocks }
                    frames={ this.elements.frames }
                    onPaginate={ (pager, page) => {
                        this.state.pages[pager] = page;
                        this.initialize(this.props);
                        this.forceUpdate();
                    } }
                />
            </div>
        );
    }
}
