import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        display: 'inline-block',
        marginLeft: '30px',
        borderRadius: '3px',
        background: 'transparent',
        color: colors.grey500,
        textAlign: 'center',
        padding: '0 3px',
        lineHeight: '24px',
        height: '24px',
        textDecoration: 'none',
        margin: '0 15px',
    },
    icon: {
        float: 'left',
        marginRight: '5px',
    },
    hover: {
        color: colors.userCold,
        textDecoration: 'underline',
    },
    on: {
        color: colors.userHot,
        background: colors.bgHot,
    },
    confirmModal: {
        width: '650px',
    },
    confirmMessage: {
        fontSize: '19px',
    },
    confirmQuestion: {
        color: colors.grey700,
        background: colors.grey100,
        padding: '10px',
        borderRadius: '3px',
        margin: '20px 0',
    },
    confirmActions: {
        textAlign: 'right',
        marginTop: '20px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.field.widget.na';

    static propTypes = {
        question: PropTypes.string.isRequired,
        value: PropTypes.bool,
        onChange: PropTypes.func,
    };

    state = { hover: false };

    render() {
        const style = Object.assign(
            {},
            styles.container,
            this.props.value ? styles.on : this.state.hover ? styles.hover : {},
            this.props.onChange ? { cursor: 'pointer' } : {},
        );

        return [
            <Views.Common.Button
                key="button"
                type="flat"
                color="cold"
                label="Non Applicable"
                onClick={
                    this.props.onChange
                        ? event => (this.props.value === null ? this.props.onChange(false) : this.refs.modal.open())
                        : undefined
                }
            />,

            <Views.Modal.Show key="modal" ref="modal" title="N/A" style={styles.confirmModal}>
                <div style={styles.confirmMessage}>
                    Do you confirm that the answer for this question is non applicable?
                </div>

                <div style={styles.confirmQuestion}>{this.props.question}</div>

                <div style={styles.confirmActions}>
                    <Views.Common.Button
                        type="flat"
                        color="hot"
                        label="No, cancel"
                        icon={<Icon type="do.cancel" />}
                        onClick={event => this.refs.modal.close()}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        label="Yes, submit"
                        icon={<Icon type="do.submit" />}
                        onClick={event => {
                            if (this.props.onChange) {
                                this.props.onChange(true);
                            }

                            this.refs.modal.close();
                        }}
                    />
                </div>
            </Views.Modal.Show>,
        ];
    }
}
