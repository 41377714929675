import PropTypes from 'prop-types';
import React from 'react';
import Avatar from 'material-ui/Avatar';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

let styles = {
    container: {
        backgroundColor: colors.grey200,
        position: 'relative',
        fontSize: '15px',
        overflow: 'visible',
        width: '100%',
        height: '40px',
        borderRadius: '3px',
        cursor: 'pointer',
        margin: '2px 0'
    },
    containerHover: {
        backgroundColor: colors.grey300,
    },
    avatar: {
        position: 'absolute',
        top: '0',
        left: '0',
        borderRadius: '3px 0 0 3px',
        backgroundColor: colors.userCold,
        borderRight: '1px solid white'
    },
    content: {
        lineHeight: '40px',
        marginLeft: '40px',
        overflow: 'hidden',
        height: '40px'
    },
    title: {
        display: 'block',
        marginLeft: '20px',
        color: colors.grey800,
        fontWeight: 'normal',
        float: 'left'
    },
    subtitle: {
        marginLeft: '20px',
        color: colors.grey500
    },
    bookmark: {
        float: 'left',
        margin: '8px 10px 0 0',
        width: '24px',
        height: '24px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.handle';

    static propTypes = {
        icon: PropTypes.string,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        onSelect: PropTypes.func
    };

    state = { hover: false };

    render () {
        const style = Object.assign({},
            styles.container,
            this.state.hover
                ? styles.containerHover
                : {}
        );

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onSelect }
            >
                { this.renderAvatar() }

                <div style={ styles.content }>
                    <span style={ styles.title }>
                        { this.props.title }
                    </span>

                    <span style={ styles.subtitle }>
                        { this.props.subtitle }
                    </span>
                </div>
            </div>
        );
    }

    renderAvatar () {
        if (this.props.icon) {
            return (
                <Avatar
                    style={ styles.avatar }
                    icon={ <Icon type={ this.props.icon } /> }
                />
            );
        }
    }
}
