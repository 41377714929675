import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import { Paper } from 'material-ui';
import Views from 'app/views';

const styles = {
    container: {
        display: 'inline-block',
        position: 'relative',
        fontWeight: 'normal',
        color: 'white',
        fontSize: '13px',
        cursor: 'default',
        padding: '0 5px',
        borderRadius: '3px',
        background: colors.userHot,
    },
    panel: {
        position: 'absolute',
        bottom: '35px',
        left: '0',
        fontSize: '15px',
        padding: '10px 15px',
        border: '1px solid ' + colors.userHot,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.display.closed';

    static propTypes = {
        data: PropTypes.object,
    };

    state = { open: false };

    render() {
        if (!this.props.data) {
            return null;
        }

        return (
            <div
                style={ Object.assign({}, styles.container, this.props.style) }
                onMouseEnter={() => this.setState({ open: true })}
                onMouseLeave={() => this.setState({ open: false })}
            >
                Closed
                {this.renderPanel()}
            </div>
        );
    }

    renderPanel() {
        if (!this.state.open) {
            return null;
        }

        return (
            <Paper style={styles.panel}>
                <Views.Common.Properties
                    align={50}
                    values={[
                        { label: 'Reason', value: this.props.data.reason },
                        { label: 'Date', value: this.props.data.date },
                        { label: 'Source', value: this.props.data.source },
                    ]}
                />
            </Paper>
        );
    }
}
