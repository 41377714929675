import _ from 'lodash';
import React from 'react';
import Button from '../../../common/button';
import PropTypes from 'prop-types';

import CancelIcon from 'material-ui/svg-icons/navigation/close';
import SubmitIcon from 'material-ui/svg-icons/navigation/check';

const styles = {
    container: {
        margin: '5px',
        textAlign: 'right'
    },
    submit: {
    },
    cancel: {
        marginRight: '10px'
    }
};

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.thread.composer.actions';

    static propTypes = {
        edition: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onClose: PropTypes.func
    };

    render () {
        return (
            <div style={ styles.container }>
                { this.renderCancel() }

                <Button
                    type="raised"
                    color="cold"
                    icon={ <SubmitIcon /> }
                    label={ this.props.edition ? 'Update message' : 'Send message' }
                    onClick={ this.props.onSubmit }
                />
            </div>
        );
    }

    renderCancel () {
        if (! this.props.onClose)
            return null;

        return (
            <Button
                style={ styles.cancel }
                type="flat"
                color="hot"
                icon={ <CancelIcon /> }
                label="Cancel"
                onClick={ this.props.onClose }
            />
        );
    }
}
