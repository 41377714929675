import PropTypes from 'prop-types';
import React from 'react';
import Container from './container';
import Label from './label';

const styles = {
    container: {
        position: 'relative',
        height: '15px',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.header.rule';

    static propTypes = {
        labels: PropTypes.arrayOf(PropTypes.shape({
            position: PropTypes.number.isRequired,
            content: PropTypes.node.isRequired
        }))
    };

    render () {
        if (! this.props.labels.length)
            return (<span />);

        return (
            <Container size={ 17 } style={ styles.container }>
                { this.props.labels.map(this.renderLabel.bind(this)) }
            </Container>
        );
    }

    renderLabel (label, index) {
        return (
            <Label
                key={ 'label' + index }
                position={ label.position }
                content={ label.content }
            />
        )
    }
}
