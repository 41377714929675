// @flow

import type { SdkService, ApiError, ApiProgress, StoreIndiceList, StoreIndiceQuery } from '@deecision/infra-types/client';
import type { ModelStore, DataStatus, DataStore } from './types';
import type { IObservableValue } from 'mobx';

import Data from './data';
import hashObject from 'object-hash';

export default function(sdk: SdkService): ModelStore {
    const listsData: DataStore<StoreIndiceList> = Data();
    const scopesData: DataStore<Object> = Data();

    return {
        watchList(service: string, model: string, where: StoreIndiceQuery = {}): Function {
            const key = [service, model, hashObject(where)].join(':');
            listsData.watch(key, () => sdk.model.watchList(service, model, where));

            return () => listsData.end(key);
        },

        watchScope(service: string, model: string, id: string, scope: string | string[]): Function {
            const scopes: string[] = Array.isArray(scope) ? scope : [scope];
            const keys: string[] = [];

            for (const scope of scopes) {
                const key = [service, model, id, scope].join(':');
                keys.push(key);
                scopesData.watch(key, () => sdk.model.watchScope(service, model, id, scope));
            }

            return () => scopesData.end(keys);
        },

        getList(service: string, model: string, where: StoreIndiceQuery = {}): IObservableValue<?StoreIndiceList> {
            return listsData.getData([service, model, hashObject(where)].join(':'));
        },

        getStatus(service: string, model: string, id: string, scope: string): IObservableValue<DataStatus> {
            return scopesData.getStatus([service, model, id, scope].join(':'));
        },

        getData(service: string, model: string, id: string, scope: string): IObservableValue<?Object> {
            return scopesData.getData([service, model, id, scope].join(':'));
        },

        getError(service: string, model: string, id: string, scope: string): IObservableValue<?ApiError> {
            return scopesData.getError([service, model, id, scope].join(':'));
        },

        getProgress(service: string, model: string, id: string, scope: string): IObservableValue<?ApiProgress> {
            return scopesData.getProgress([service, model, id, scope].join(':'));
        },
    };
}
