import PropTypes from 'prop-types';
import React from 'react';
import Entity from './entity';
import Link from './link';
import Views from 'app/views';
import t from 'app/translation';

const styles = {
    paths: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: 'column',
    },
    path: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
        flexDirection: 'row',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.relation.graph';

    static propTypes = {
        source: PropTypes.object.isRequired,
        data: PropTypes.object.isRequired,
    };

    render() {
        if (!this.props.data) {
            return this.renderEmpty();
        }

        return (this.props.data.paths || []).map((path, index) => this.renderPath(index, path, this.props.data.target));
    }

    renderEmpty(index) {
        return <Views.Common.Empty key={'e' + index} title={t('common.message.noRelation')} />;
    }

    renderPath(index: number, path: Object[], target: Object) {
        const nodes = [];

        for (const item of path || []) {
            nodes.push(<Link key={'l' + nodes.length} node={item} />);
            nodes.push(<Entity key={ 't' + nodes.length } target={item.target} ceased={item.ceased} />);
        }

        return (
            <div key={ 'p' + index } style={{ display: 'flex', marginTop: index > 0 ? '20px' : '0' }}>
                <Entity target={this.props.source} />
                { nodes }
            </div>
        );
    }
}
