// @flow

import React from 'react';
import PropTypes from 'prop-types';
import ListProvider from 'app/layout/providers/list';
import Item from './item';

const styles = {
    container: {
        flexGrow: '1',
    },
    list: {},
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.links';

    static propTypes = {
        id: PropTypes.string,
    };

    render() {
        return (
            <div style={styles.container}>
                <ListProvider
                    service="cleerance"
                    model="team"
                    renderList={list =>
                        list.items.filter(item => item.id !== this.props.id).map(item => <Item id={item.id} />)
                    }
                />
            </div>
        );
    }
}
