import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        marginLeft: '20px',
        position: 'relative',
        display: 'inline-block',
    },
    input: {
        border: '2px solid ' + colors.grey300,
        borderRadius: '3px',
        background: 'transparent',
        lineHeight: '36px',
        height: '36px',
        boxSizing: 'content-box',
        width: '138px',
        padding: '0 33px',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        zIndex: '3',
    },
    inputIcon: {
        position: 'absolute',
        top: '8px',
        left: '8px',
        fill: colors.grey400,
    },
    reset: {
        position: 'absolute',
        top: '4px',
        right: '4px',
        background: colors.userHot,
        height: '32px',
        width: '32px',
        borderRadius: '3px',
        cursor: 'pointer',
    },
    resetIcon: {
        fill: colors.white,
        margin: '4px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.survey.search';

    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <Icon type="do.search" style={styles.inputIcon} />

                <input
                    style={styles.input}
                    value={this.props.value || ''}
                    onChange={event => this.props.onChange(event.target.value)}
                />

                {this.renderReset()}
            </div>
        );
    }

    renderReset() {
        if (!this.props.value) {
            return null;
        }

        return (
            <div style={styles.reset} onClick={event => this.props.onChange(null)}>
                <Icon type="do.cancel" style={styles.resetIcon} />
            </div>
        );
    }
}
