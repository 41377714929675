import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        display: 'block',
        fontSize: '11px',
        lineHeight: '11px',
        height: '11px',
        padding: '5px'
    },
    label: {
        padding: '0 5px',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        color: colors.grey500
    },
    focus: {
        color: colors.userCold
    },
    error: {
        padding: '1px 3px',
        borderRadius: '3px',
        background: colors.userHot,
        color: 'white',
    },
    info: {
        padding: '0 5px',
        color: colors.grey500
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.field.header';

    static propTypes = {
        label: PropTypes.string,
        focus: PropTypes.bool,
        error: PropTypes.string,
        info: PropTypes.string
    };

    render () {
        if (! this.props.label && ! this.props.error && ! this.props.info)
            return null;

        return (
            <div style={ styles.container }>
                { this.renderLabel() }
                { this.renderError() }
                { this.renderInfo() }
            </div>
        );
    }

    renderLabel () {
        const style = Object.assign({},
            styles.label,
            this.props.focus
                ? (this.props.error ? styles.focusError : styles.focus)
                : {}
        );

        return (
            <span key="label" style={ style }>
                { this.props.label }
            </span>
        );
    }

    renderError () {
        if (! this.props.error)
            return null;

        return (
            <span key="error" style={ styles.error }>
                { this.props.error }
            </span>
        );
    }

    renderInfo () {
        if (! this.props.info)
            return null;

        return (
            <span key="info" style={ styles.info }>
                { this.props.info }
            </span>
        );
    }
}
