import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const CONTROL_OFFSET = 25;
const HEIGHT_OFFSET = 10;
const ITEM_HEIGHT = 25;

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.chart.halfPie.graph';

    static propTypes = {
        size: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        entries: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })).isRequired,
        highlighted: PropTypes.string,
        onHighlight: PropTypes.func.isRequired,
        style: PropTypes.object
    };

    computeEntries () {
        const height = this.props.size + HEIGHT_OFFSET * 2;
        const radius = { link: this.props.size / 2, slice: this.props.size / 2 };
        const offset = { top: this.props.height < height ? (height - this.props.height) / 2 : 0, control: CONTROL_OFFSET };
        const center = { x: 0, y: Math.max(height, this.props.height) / 2 };
        const values = { total: 0, offset: 0, index: 0 };
        const result = [];

        for (const entry of this.props.entries) {
            values.total += entry.value;
        }

        function point (value, radius) {
            const angle = (values.total
                ? Math.PI * (values.offset + value) / values.total
                : 0
            ) - Math.PI / 2;

            return {
                x: center.x + radius * Math.cos(angle),
                y: center.y + radius * Math.sin(angle),
            }
        }

        for (const entry of this.props.entries) {
            result.push({
                key: entry.id,
                link: {
                    start: {
                        anchor: point(entry.value / 2, radius.link),
                        control: point(entry.value / 2, radius.link + offset.control),
                    },
                    end: {
                        anchor: { x: this.props.size * 0.75, y: offset.top + ITEM_HEIGHT / 2 + values.index * ITEM_HEIGHT },
                        control: { x: this.props.size * 0.75 - offset.control, y: offset.top + ITEM_HEIGHT / 2 + values.index * ITEM_HEIGHT },
                    },
                },
                slice: {
                    center: center,
                    radius: radius.slice,
                    start: point(0, radius.slice),
                    end: point(entry.value, radius.slice),
                    color: values.index % 2 == 0 ? colors.grey400 : colors.userCold,
                },
            });

            values.index += 1;
            values.offset += entry.value;
        }

        return result;
    }

    render () {
        const entries = this.computeEntries();
        const height = Math.max(this.props.size + HEIGHT_OFFSET * 2, this.props.height);

        return (
            <svg
                width={ this.props.size * 0.75 }
                height={ height }
                viewBox={ `0 0 ${this.props.size * 0.75} ${height}` }
            >
                { entries.map(this.renderSlice.bind(this)) }
                { entries.map(this.renderLink.bind(this)) }

                <circle
                    cx={ 0 }
                    cy={ height / 2 }
                    r="25"
                    fill="white"
                />
            </svg>
        )
    }

    renderSlice (entry) {
        const high = entry.key === this.props.highlighted;
        const path = `M ${entry.slice.center.x} ${entry.slice.center.y}`
            + ` ${entry.slice.start.x} ${entry.slice.start.y}`
            + `A ${entry.slice.radius} ${entry.slice.radius} 0 0 1`
            + ` ${entry.slice.end.x} ${entry.slice.end.y} Z`;

        return (
            <path
                key={ `link_${entry.key}` }
                d={ path }
                stroke="white"
                strokeWidth={ 1 }
                fill={ high ? colors.userHot : entry.slice.color }
                fillOpacity={ high ? 1 : 0.5 }
                onMouseEnter={ event => this.props.onHighlight(entry.key) }
                onMouseLeave={ event => this.props.onHighlight(undefined) }
            />
        )
    }

    renderLink (entry) {
        const high = entry.key === this.props.highlighted;
        const path = `M ${entry.link.start.anchor.x} ${entry.link.start.anchor.y}`
                + ` C ${entry.link.start.control.x} ${entry.link.start.control.y}`
                + ` ${entry.link.end.control.x} ${entry.link.end.control.y}`
                + ` ${entry.link.end.anchor.x} ${entry.link.end.anchor.y}`;

        return (
            <path
                key={ `slice_${entry.key}` }
                d={ path }
                stroke={ high ? colors.userHot : entry.slice.color }
                strokeWidth={ high ? 1 : 1 }
                strokeOpacity={ high ? 1 : 0.75 }
                fill="none"
            />
        );
    }
}
