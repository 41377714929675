import React from 'react';
import PropTypes from 'prop-types';
import Number from 'app/views/common/number';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import { formatStockValue } from '../../utils';

const styles = {
    name: {
        borderBottom: '1px solid ' + colors.grey500,
        cursor: 'pointer',
    },
    stats: {
        color: colors.userCold,
    },
    row: {
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        display: 'flex',
        color: colors.grey700,
        lineHeight: '35px',
    },
    indirect: {
        opacity: 'O.5',
    },
    label: {
        width: '50%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    value: {
        paddingLeft: '15px',
        textAlign: 'right',
        width: '10%',
        whiteSpace: 'nowrap',
    },
    odd: {
        background: colors.grey100,
    },
    icon: {
        width: '21px',
        height: '21px',
        fill: colors.userCold,
    },
    handle: {
        display: 'inline-block',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: '25px',
        height: '25px',
        background: 'transparent',
        borderRadius: '5px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    indent: {
        display: 'inline-block',
        width: '25px',
        marginRight: '10px',
    },
    level: {
        height: '35px',
        float: 'left',
        width: '0',
        margin: '0 12px',
        borderLeft: '1px solid ' + colors.userCold,
    },
    flag: {
        display: 'inline-block',
        marginLeft: '5px',
        lineHeight: '17px',
        textAlign: 'center',
        borderRadius: '10px',
        width: '17px',
        fontSize: '13px',
        fontWeight: 'bold',
        color: colors.white,
        background: colors.userHot,
        verticalAlign: 'text-top',
    },
    ubo: {
        display: 'inline-block',
        marginLeft: '5px',
        lineHeight: '17px',
        fontSize: '13px',
        fontWeight: 'bold',
        color: colors.userHot,
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.weasse.line';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        target: PropTypes.object.isRequired,
        stock: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        level: PropTypes.number.isRequired,
        open: PropTypes.bool.isRequired,
        onOpen: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    state = {
        opened: [],
        sort: 'turnover',
    };

    render() {
        if (!this.props.target) {
            return null;
        }

        const children = this.props.stock.children;
        const target = this.props.target || {};
        const flags = [];

        if ((target.flags || []).includes('listed')) flags.push('L');
        if ((target.flags || []).includes('holding')) flags.push('H');
        if ((target.flags || []).includes('fund')) flags.push('F');
        if (this.props.stock.exit && this.props.stock.exit > 0) flags.push('Ex');

        const valuation = [this.getMinValuation(target.valuation) || 0, this.getMaxValuation(target.valuation) || 0];
        const stock = this.props.stock || {};
        const total = [stock.total ? stock.total.min : undefined, stock.total ? stock.total.max : undefined];
        const prefix = children && (children.length > 0 || children.total > 0);
        const name = (!target.name || target.name === '(?)') ? (stock.label + ' *') : target.name;

        return (
            <div
                style={Object.assign(
                    {},
                    styles.row,
                    this.props.index % 2 === 0 ? styles.even : styles.odd,
                    stock.direct ? {} : styles.indirect,
                )}
            >
                <span style={styles.label}>
                    {this.renderPrefix(this.props.level, prefix ? target.id : undefined)}

                    <span
                        style={target.id ? styles.name : styles.stats}
                        onClick={target.id ? event => this.context.openEntity(target.id) : undefined}
                    >
                        {name || '—'}
                    </span>

                    {stock.value && stock.value.min > 50 ? <span style={styles.ubo}>UBO</span> : null}

                    {flags.map(flag => (
                        <span key={flag} style={styles.flag}>
                            {flag}
                        </span>
                    ))}
                </span>

                <Number value={target.turnover} type="currency" scale="M" style={styles.value} />
                <Number value={target.capital} type="currency" scale="M" style={styles.value} />
                <Number value={target.ebitda} type="currency" scale="M" style={styles.value} />
                <Number value={valuation[0] || undefined} type="currency" scale="M" style={styles.value} />
                <Number value={valuation[1] || undefined} type="currency" scale="M" style={styles.value} />
                <span style={styles.value}>{formatStockValue(stock.value || {})}</span>
                <Number value={total[0] || undefined} type="currency" scale="M" style={styles.value} />
                <Number value={total[1] || undefined} type="currency" scale="M" style={styles.value} />
            </div>
        );
    }

    renderPrefix(level, handle = undefined) {
        const prefixes = [];

        for (let index = 0; index < level; index++) {
            prefixes.push(<span key={'level' + index} style={styles.level} />);
        }

        if (handle) {
            prefixes.push(
                <span
                    key="handle"
                    style={styles.handle}
                    onClick={event =>
                        this.props.open
                            ? this.props.onClose(this.props.target.id)
                            : this.props.onOpen(this.props.target.id)
                    }
                >
                    {this.props.open ? (
                        <Icon type="base.tree.close" style={styles.icon} />
                    ) : (
                        <Icon type="base.tree.open" style={styles.icon} />
                    )}
                </span>,
            );
        } else {
            prefixes.push(<span key="indent" style={styles.indent} />);
        }

        return prefixes;
    }

    getMinValuation(valuation) {
        if (Array.isArray(valuation)) {
            return valuation[0];
        }

        if (valuation && typeof valuation === 'object') {
            return (valuation.value || 0) - (valuation.deviation || 0);
        }

        return valuation;
    }

    getMaxValuation(valuation) {
        if (Array.isArray(valuation)) {
            return valuation[1];
        }

        if (valuation && typeof valuation === 'object') {
            return (valuation.value || 0) + (valuation.deviation || 0);
        }

        return valuation;
    }
}
