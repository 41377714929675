import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/theme/icon';


const styles = {
    info: {
        display: 'block',
        color: 'rgb(176, 190, 197)',
    },
    date: {
        display: 'inline-block',
        padding: '5px',
        fontSize: '16px',
        fontWeight: 'bold',
        width: '42px',
        position: 'relative',
        verticalAlign: 'top',
    },
    cell: {
        display: 'inline-block',
        padding: '5px',
        paddingRight: '0px',
        width: 'calc(100% - 57px)',
        position: 'relative',
        verticalAlign: 'top',
    },
    pdfCell: {
        marginTop: '10px',
        display: 'inline-block',
        right: '0',
        padding: '5px',
        paddingLeft: '0px',
    },
    link: {
        cursor: 'pointer',
        color: 'rgb(117, 117, 117)',
    },
    pdfHover: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    pdfText: {
        display: 'inline-block',
        marginLeft: '5px',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fill: 'rgb(117, 117, 117)',
    },
    information: {
        padding: '5px',
        paddingTop: '10px',
        color: 'rgb(158, 158, 158)',
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        lineHeight: '22px',
    },
}

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.history.eventsDisplay';

    static propTypes = {
        children: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            pdfHover: undefined,
        };
    }

    render() {
        let result = [];
        let infos =  this.props.children.desc;

        const cellProps = {
            onMouseEnter: event => this.setState({ pdfHover: this.props.children.urlPdf }),
            onMouseLeave: event => this.setState({ pdfHover: undefined }),
        };

        let styleEvents = this.state.pdfHover === this.props.children.urlPdf
            ? Object.assign({ }, styles.pdfText, styles.pdfHover) : styles.pdfText;
        result.push(
            <div style={ styles.info }>
                <div style={ styles.date }>
                    { this.props.children.date.split('-')[2] + '/' + this.props.children.date.split('-')[1] }
                </div>
                <div style={ styles.cell }>
                    <a style={ styles.link } target='_blank' href={ this.props.children.url }>
                        { this.props.children.title }
                    </a>
                </div>
                <a style={ styles.pdfCell } target='_blank' href={ this.props.children.urlPdf } { ...cellProps }>
                    <div style={ styles.link } >
                        <Icon type={ 'do.download' } style={ styles.icon } />
                        <div style={ styleEvents }>{ `Télécharger le pdf` }</div>
                    </div>
                </a>
                <div
                    style={styles.information}
                    dangerouslySetInnerHTML={{__html: infos.split('<\/span>')[1]
                            .replace(/\r?\n|\r|<dd>|<\/dd>|<\/dt>|<dl>|<\/dl>/g, ' ')
                            .replace(/<dt>/g, '\n').substring(2)}}
                >
                </div>
            </div>
        );
        return result;
    }
}