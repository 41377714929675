import React from 'react';
import PropTypes from 'prop-types';
import Base from './base';
import Table from './table';

const styles = {
    content: {
        padding: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.issues';

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.object,
    };

    render() {
        return <Base title={this.props.title}>{this.renderContent()}</Base>;
    }

    renderContent() {
        if (!this.props.data) {
            return null;
        }

        return (
            <div style={styles.content}>
                <Table head={this.makeHead()} foot={this.makeFoot()} body={this.makeBody()} />
            </div>
        );
    }

    makeHead() {
        return ['', 'Concerns', 'Red flags', 'Key findings'];
    }

    makeFoot() {
        const total = this.props.data.total || {};

        return [
            'TOTAL',
            { data: this.makePercent(total.concern.ratio), color: total.concern.color },
            { data: total.redFlag.count, color: total.redFlag.color },
            { data: total.keyFinding.count, color: total.keyFinding.color },
        ];
    }

    makeBody() {
        const data = this.props.data.data || [];
        const labels = this.props.data.labels || [];
        const length = labels.length;
        const body = [];

        for (let index = 0; index < length; index++) {
            const row = data[index] || [];
            body.push([
                labels[index],
                { data: this.makePercent(row[0].ratio), color: row[0].color },
                { data: row[1].count, color: row[1].color },
                { data: row[2].count, color: row[2].color },
            ]);
        }

        return body;
    }

    makePercent(ratio: number): string {
        return `${Math.round((ratio || 0) * 100)} %`;
    }
}
