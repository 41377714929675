import React from 'react';
import Application from 'app/layout/application';

import Explorer from './explorer';
import Detector from './detector';
import Settings from './settings';
import t from 'app/translation';
import { inject, observer } from 'mobx-react';

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna';

    render() {
        const session = this.props.global.session.get();
        const cursor = this.props.global.cursor.get();
        const flags = this.props.global.flags.get();

        const account = session.user.accounts.filter(account => account.id === cursor.account)[0];
        const membership = account ? account.memberships[cursor.team || ''] : undefined;
        const level = membership ? membership.level : undefined;

        return (
            <Application
                path="dna"
                title="Deecision DNA"
                index="explorer"
                entries={[
                    {
                        path: 'deetect',
                        icon: 'dna.detector',
                        label: 'Deetect',
                        screen: Detector,
                        access: flags.includes('deefind.access'),
                    },
                    {
                        path: 'explorer',
                        icon: 'dna.explorer',
                        label: 'Deefind',
                        screen: Explorer,
                        access: flags.includes('deefind.access'),
                    },
                    {
                        path: 'settings',
                        icon: 'deeligenz.management',
                        label: 'Settings',
                        screen: Settings,
                        access: flags.includes('deefind.access') && ['director', 'manager'].includes(level),
                    },
                    // {
                    //     icon: 'dna.sharing',
                    //     label: t('dna.menu.sharing'),
                    //     access: false,
                    // },
                    // {
                    //     icon: 'dna.favorites',
                    //     label: t('dna.menu.favorites'),
                    //     access: false,
                    // },
                ]}
            />
        );
    }
}
