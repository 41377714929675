import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import CategoryItem from './category/item';
import CategoryForm from './category/form';
import QuestionItem from './question/item';
import QuestionForm from './question/form';

import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const KINDS = {
    M: 'Manager',
    F: 'Fund',
    S: 'Service providers',
    MC: 'Management Company',
    A: 'Advisor',
};

const styles = {
    container: {
        display: 'flex',
    },
    title: {
        marginBottom: '10px',
        display: 'flex',
    },
    titleLabel: {
        width: '100%',
        lineHeight: '40px',
        fontSize: '19px',
    },
    titleAdd: {},
    categories: {
        width: '33%',
        marginRight: '20px',
    },
    section: {
        marginBottom: '20px',
    },
    questions: {
        width: '67%',
        marginLeft: '20px',
    },
    message: {
        background: colors.grey200,
        borderRadius: '10px',
        padding: '50px',
        textAlign: 'center',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexBasis: '100%',
        marginBottom: '10px',
    },
    headerTitle: {
        fontSize: '19px',
    },
    headerCount: {
        color: colors.grey500,
        marginLeft: '15px',
        flexGrow: '1',
    },
};

@inject(stores => ({ store: stores.deeligenzManagement, user: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.questions';

    static contextTypes = {
        createCategory: PropTypes.func.isRequired,
        updateCategory: PropTypes.func.isRequired,
        removeCategory: PropTypes.func.isRequired,
        createQuestion: PropTypes.func.isRequired,
        updateQuestion: PropTypes.func.isRequired,
        removeQuestion: PropTypes.func.isRequired,
    };

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
        onSelect: PropTypes.func,
        templateId: PropTypes.string,
        readOnly: PropTypes.bool,
    };

    state = { category: null };

    render() {
        if (!this.props.store) {
            return null;
        }

        if (toJS(this.props.store.loading)) {
            return <Views.Common.Loader title="Loading data" />;
        }

        const questions = this.state.category ? this.props.store.questionsLists.get(this.state.category) : [];
        const category = this.props.store.categoriesMap.get(this.state.category);
        const categories = [];

        for (const key of Object.keys(toJS(this.props.store.categoriesList))) {
            categories.push(this.renderCategories(key, Array.from(this.props.store.categoriesList.get(key))));
        }

        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                <div style={styles.categories}>
                    {categories}

                    <CategoryForm
                        ref="categoryForm"
                        onSubmit={data => {
                            if (!data.payload.activations) {
                                data.payload.activations = {};
                            }

                            if (data.id) {
                                this.context.updateCategory(data.id, data.payload);
                            } else {
                                this.context.createCategory(data.payload);
                            }
                        }}
                    />
                </div>

                <div style={styles.questions}>
                    {this.renderHeader(category, questions)}
                    {this.renderQuestions(questions)}

                    <QuestionForm
                        ref="questionForm"
                        onSubmit={data => {
                            if (data.id) {
                                this.context.updateQuestion(data.id, data.payload);
                            } else {
                                this.context.createQuestion(this.state.category, data.payload);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    renderCategories(kind, categories) {
        const SortableItem = SortableElement(({ id, value }) => (
            <li style={{ listStyle: 'none' }}>
                <CategoryItem
                    key={id}
                    id={id}
                    store={this.props.store}
                    selected={id === this.state.category}
                    size={(this.props.store.questionsLists.get(id) || []).length}
                    sizeSelected={
                        this.props.templateId
                            ? this.props.store.selectedCategory(this.props.templateId, id).length
                            : undefined
                    }
                    onSelect={event => this.setState({ category: id })}
                    onEdit={category => this.refs['categoryForm'].open(toJS(category))}
                    onRemove={event => this.context.removeCategory(id)}
                    readOnly={this.props.readOnly}
                />
            </li>
        ));

        const SortableList = SortableContainer(({ items }) => {
            return (
                <ul style={{ padding: 0 }}>
                    {items.map((id, index) => (
                        <SortableItem disabled={this.props.readOnly} key={`item-${id}`} index={index} id={id} />
                    ))}
                </ul>
            );
        });

        return (
            <div key={kind} style={styles.section}>
                <div style={styles.title}>
                    <span style={styles.titleLabel}>{KINDS[kind] || kind}</span>

                    <Views.Common.Button
                        style={styles.titleAdd}
                        type="round"
                        color="cold"
                        icon={<Icon type="do.create" />}
                        onClick={event => this.refs['categoryForm'].open({ payload: { kind, weight: 1 } })}
                        mini
                    />
                </div>

                {
                    <SortableList
                        useDragHandle={true}
                        items={categories}
                        onSortEnd={async ({ oldIndex, newIndex }) => {
                            const moved = arrayMove(categories, oldIndex, newIndex);
                            await this.props.store.orderCategory(
                                moved,
                                Object.keys(moved).map(e => Number(e)),
                            );
                        }}
                    />
                    // Array.from(categories).map(id => (
                    //     <CategoryItem
                    //         key={ id }
                    //         id={ id }
                    //         store={ this.props.store }
                    //         selected={ id === this.state.category }
                    //         size={ (this.props.store.questionsLists.get(id) || []).length }
                    //         sizeSelected={this.props.templateId ? this.props.store.selectedCategory(this.props.templateId, id).length : undefined}
                    //         onSelect={ event => this.setState({ category: id }) }
                    //         onEdit={ category => this.refs['categoryForm'].open(toJS(category)) }
                    //         onRemove={ event => this.context.removeCategory(id) }
                    //         readOnly={this.props.readOnly}
                    //     />
                    // ))
                }
            </div>
        );
    }

    renderHeader(category, questions) {
        if (!this.state.category || !category || this.props.readOnly === true) {
            return null;
        }

        return (
            <div style={styles.header}>
                <span style={styles.headerTitle}>
                    {KINDS[category.payload.kind]} / {category.payload.label}
                </span>
                <span style={styles.headerCount}>{questions.length} questions</span>

                <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.create" />}
                    label="New question"
                    onClick={event => this.refs['questionForm'].open({ category: this.state.category.payload })}
                />
            </div>
        );
    }

    renderQuestions(questions) {
        if (!this.state.category) {
            return <div style={styles.message}>Please select a category to manage underlying questions</div>;
        }

        if (!questions.length) {
            return <div style={styles.message}>Selected category contains no questions</div>;
        }

        const SortableItem = SortableElement(({ id, order }) => (
            <li style={{ listStyle: 'none' }}>
                <QuestionItem
                    key={id}
                    id={id}
                    index={order + 1}
                    store={this.props.store}
                    onEdit={question => this.refs['questionForm'].open(toJS(question))}
                    onRemove={event => this.context.removeQuestion(id)}
                    onSelect={this.props.onSelect}
                    selected={this.props.onSelect ? this.props.store.questionSelected(this.props.templateId, id) : null}
                    readOnly={this.props.readOnly}
                />
            </li>
        ));

        const SortableList = SortableContainer(({ items }) => {
            return (
                <ul style={{ padding: 0 }}>
                    {items.map((id, index) => (
                        <SortableItem
                            disabled={this.props.readOnly}
                            key={`item-${id}`}
                            order={index}
                            index={index}
                            id={id}
                        />
                    ))}
                </ul>
            );
        });

        return (
            <div>
                <div>
                    {this.props.onSelect ? (
                        Array.from(questions).length ===
                        this.props.store.selectedCategory(this.props.templateId, this.state.category).length ? (
                            <span>
                                <input
                                    checked={true}
                                    onClick={e => {
                                        this.props.store.unselectCategory(this.props.templateId, Array.from(questions));
                                    }}
                                    type="checkbox"
                                />
                                <label>Unselect all questions inside this category</label>
                            </span>
                        ) : (
                            <span>
                                <input
                                    checked={false}
                                    onClick={e => {
                                        this.props.store.selectCategory(this.props.templateId, Array.from(questions));
                                    }}
                                    type="checkbox"
                                />
                                <label>Select all questions inside this category</label>
                            </span>
                        )
                    ) : null}
                </div>
                {
                    <SortableList
                        useDragHandle={true}
                        items={Array.from(questions)}
                        onSortEnd={async ({ oldIndex, newIndex }) => {
                            const moved = arrayMove(questions, oldIndex, newIndex);
                            await this.props.store.orderQuestion(
                                moved,
                                Object.keys(moved).map(e => Number(e)),
                            );
                        }}
                    />
                    // Array.from(questions).map((id, index) => (
                    // <QuestionItem
                    //     key={ id }
                    //     id={ id }
                    //     index={ index + 1 }
                    //     store={ this.props.store }
                    //     onEdit={ question => this.refs['questionForm'].open(toJS(question)) }
                    //     onRemove={ event => this.context.removeQuestion(id) }
                    //     onSelect={this.props.onSelect}
                    //     selected={this.props.onSelect ? this.props.store.questionSelected(this.props.templateId, id) : null}
                    //     readOnly={this.props.readOnly}
                    // />
                    // ))
                }
            </div>
        );
    }
}
