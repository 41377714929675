// @flow

export function normalizeDate(date: ?string): ?string {
    if (!date) {
        return undefined;
    }

    if (date.match(/\d{4}-\d{2}-\d{2}/)) {
        return date;
    }

    if (date.match(/\d{2}\/\d{2}\/\d{4}/)) {
        return `${date.substr(6, 4)}-${date.substr(3, 2)}-${date.substr(0, 2)}`;
    }

    return undefined;
}
