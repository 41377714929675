import countries from './countries-fr';
import activities from './activities-fr';
import gics from './gics-fr';
import cleerance from './cleerance-fr';

const fr = {
    cleerance,

    common: {
        country: countries,
        activity: activities,
        gics: gics,
        label: {
            accounting: 'Accounting',
            required: 'OBLIGATOIRE',
            identifier: 'Identifiant',
            dateFormatYYYYMMDD: 'YYYY-MM-DD',
            financial: 'Finance',
            noMatch: 'Aucune correspondance',
            report: 'Rapport',
            details: 'Détails',
            fullTextSearch: 'Recherche',
            reechMatch: 'Aucune correspondance Reech',
            customerMatch: 'Correspondance client',
            prospectMatch: 'Correspondance prospect',
            sortIndividualsContacts: 'Trier  par ordre décroissant',
            sortIndividualsWithoutContacts: 'Trier  par ordre croissant',
            sortDesc: 'Trier  par ordre décroissant',
            sortAsc: 'Trier  par ordre croissant',
            noDeal: 'No deals',
            weasse: 'Weasse',
            fortune: 'Fortune',
            sumTurnover: 'Σ C.A',
            turnover: 'Turnover',
            capital: 'Capital',
            ebitda: 'Ebitda',
            valuation: 'Valuation',
            activity: 'Activité',
            by: 'par',
            ignore: 'Ignorer',
            result: 'Resultat',
            income: 'Income',
            incomes: 'Incomes',
            relations: 'Relations',
            mandates: 'Mandates',
            lastname: 'Nom',
            firstname: 'Prénom',
            compliance: 'Conformité',
            people: 'Personnes',
            country: 'Pays',
            company: 'Entreprise',
            companies: 'Entreprises',
            persons: 'Personnes',
            companyName: "Nom de l'entreprise",
            birthdate: 'Date de naissance',
            position: 'Position',
            population: 'Population',
            name: 'Nom',
            city: 'Ville',
            remove: 'Remove',
            siren: 'SIREN',
            siret: 'SIRET',
            politic: 'Politic',
            rank: 'Rank',
            site: 'Website',
            sharing: 'Sharing',
            value: 'Value',
            bithCity: 'Birth city',
            email: 'Email address',
            phone: 'Phone number',
            job: 'Job',
            level: 'Level',
            category: 'Category',
            source: 'Source',
            next: 'Suiv.',
            aliases: 'Aliases',
            deals: 'Transactions',
            sales: 'Sales',
            purchases: 'Purchases',
            transactions: 'Transactions',
            person: 'Person',
            for: 'For',
            on: 'On',
            at: 'At',
            type: 'Type',
            id: 'ID',
            update: 'Update',
            close: 'Close',
            print: 'Print',
            save: 'Save',
            news: 'News',
            sourceNoise: 'Bruits',
            sourceOfficial: 'Sources officielles',
            sourceCredible: 'Sources crédibles',
            sourceAll: 'Toutes',
            thisWeek: 'This week',
            thisMonth: 'This month',
            thisYear: 'This year',
            earlier: 'Earlier',
            badGuy: 'Bad guy',
            middlename: 'Middle name',
            reference: 'Reference',
            blacklist: 'Black list',
            function: 'Function',
            bankerName: 'Banker name',
            bankerGroup: 'Banker group',
            customer: 'Customer',
            prospect: 'Prospect',
            residence: 'Résident',
        },
        tooltip: {
            fortune: 'Montant de la fortune si la PP-M fait partie des fortunes 500 françaises',
            weasse: 'Evaluation totale des biens officiels et publiquement déclarés et inclut les cessions passées et évalue les détentions présentes',
            weasseMax: 'Reprend le Weasse en donnant une fourchette d\'évaluation en cas de données partiellement manquantes',
            cashout: 'Evaluation totale des biens officiels et publiquement déclarés qui ont été vendus par la PP-M. Le calcul donne une fourchette d\'évaluation en cas de données partiellement manquantes',
            turnoverSumCompanies: 'Somme de l\'ensemble des chiffres d\'affaires des sociétés liées',
            turnoverSum: 'Somme de l\'ensemble des chiffres d\'affaires des sociétés liées par un statut d\'investisseur ou d\'actionnaire',
            capitalSum: 'Somme de l\'ensemble des Capital des sociétés liées',
            ebitdaSum: 'Somme de l\'ensemble des Excédent Bruts d\'Exploitation des sociétés liées',
            valuationSum: 'Somme de l\'ensemble des valorisations des sociétés liées',
            fortuneSum: 'Somme des Fortunes issues des Fortunes500 dans les relations PP-M de la PP-M',
            weasseSum: 'Somme des Weasse des relations PP-M de la PP-M',
            ebitdaSumSum:
                "Somme de l'ensemble des Excédent Bruts d'Exploitation des sociétés liées aux relations de la PP-M ou de la PM",
            salesSum: 'Somme des ventes effectuées par la PP-M ou la PM',
            purchasesSum: 'Somme des achats effectuées par la PP-M ou la PM',
            transactionsSum: 'Somme des transactions effectuées par la PP-M ou la PM',
            deemaze: 'Valorisation de sociétés non cotées par la méthode des comparables',
        },
        message: {
            noWeasse: 'No Weasse available',
            openWeasse: 'Click to open Weasse',
            computationWeasse: 'Click to start Weasse computation',
            loading: 'Chargement...',
            loading2: 'En cours',
            noFound: 'Nothing found',
            noRelation: 'No relation found',
            invalidData: 'Invalid data',
            invalidSearchType: 'Invalid search type ({{value}})',
            noResult: 'No result found',
        },
        button: {
            export: 'Export',
            seeDetail: 'See detail',
            sendReport: 'Send report',
            next: 'Suite',
        },
        profile: {
            editButton: 'Profil',
            logoutButton: 'Déconnexion',
        },
        login: {
            info: 'Saisir votre email',
            email: 'Adresse email',
            noEmail: 'Email non trouvé, merci de reéssayer',
            infoAccessCode: 'Entrez le code envoyé par mail',
            accessCode: "Code d'accès",
        },
        error: {
            timeout: 'Request timeout',
            timeoutInfo:
                'A problem occurred while running the "{{ request }}" request.\n Please click on "Try again" to relaunch the request, it will work in most cases.\nIf the problem persists, please click on "Send a report" and our tech support team will deal with it.',
            tryAgain: 'Essayer encore',
            global: 'Une erreur est survenue',
            invalidUpload: 'Invalid file uploaded',
        },
    },
    dna: {
        sources: {
            woco: 'World Compliance',
            journalDuNet: 'Le Journal du Net',
            challenges: 'Challenges.fr',
            openData: 'OpenData.fr',
        },
        compliance: {
            fibenModalLabel: 'Would you like to request fresh data from FIBEN, Banque de France ?',
            reportBy: 'Compliance report generated by',
            reportOn: 'on',
            reportAt: 'at',
            "directCompanyIssues'": 'Direct company issues',
            "directRelationIssues'": 'Direct relation issues',
            noDirectCompanyIssue: 'No direct company issue',
            noDirectRelationIssue: 'No direct relation issue',
            asInvestor: 'As investor',
            asShareholder: 'As shareholder',
            asBoardMember: 'As board member',
            asAdvisor: 'As advisor',
            asAuditor: 'As auditor',
            asExecutive: 'As executive',
            asManager: 'As manager',
            listed: 'Listed',
            viaCompany: 'Via {{ value }}',
            noTopCompany: 'No top company',
            topCompany: 'Top company: {{ value }}',
            noDetail: 'No detail given.',
            reportComplianceTitle: 'DNA Compliance report',
            reportComplianceCreationTitle: 'Report creation',
            titleReportComplianceTarget: 'Report target',
            complianceReportTitle: 'Compliance report',
            arkhineoInfo:
                'This is a preview of the report.\nBy saving this compliance report your confirm the verification of the data.\nReports are stored in a digital safe operated by Arkhineo.',
        },
        deals: {
            vendors: 'Vendors',
            targets: 'Targets',
            buyers: 'Buyers',
            regulators: 'Regulators',
            completed: 'Completed',
            withdrawn: 'Withdrawn',
            pending: 'Pending',
            on: 'on',
            of: 'of',
            loading: 'Loading deals data',
            noSale: 'No sales',
            noPurchase: 'No purchases',
            noTransaction: 'No transactions',
            noRumours: 'No rumours',
            rumours: 'Rumours',
            noRumour: 'No rumour',
        },
        menu: {
            sharing: 'Partage',
            favorites: 'Favoris',
        },
        deefind: {
            workspace: {
                titleEmpty: 'Votre espace de travail est vide.',
                newSearchButton: 'Démarrer une nouvelle recherche',
                searchButton: 'Rechercher',
                searchPerson: 'Recherche de personne',
                searchCompany: "Recherche d'entreprise",
                searchTypo: 'Recherche de typologie',
                searchTitle: 'Performing search',
            },
            flags: {
                fortuneShort: 'F',
                credit: 'Credit needed',
                listed: 'Listed',
                listedShort: 'L',
                holding: 'Holding',
                holdingShort: 'H',
                fund: 'PM possède parmi ses investisseurs un Fonds de Private Equity',
                realEstate: 'Real estate',
                gambling: 'Gambling',
                gamblingShort: 'G',
            },
            relations: {
                relationGraphTitle: 'Relation graph',
                info: {
                    owns: 'Owns {{value}}',
                    share: 'Share {{value}}',
                    beneficialy: 'Beneficialy owned',
                    beneficial: 'Beneficial owner',
                    fromTo: '{{start}} to {{end}}',
                    since: 'Since {{value}}',
                    until: 'Until {{value}}',
                },
            },
            person: {
                tabTitle: 'Recherche de personne',
                seachPlaceholder: 'Prénom / Nom de la personne',
                ageLabel: 'Age',
                yearsOptions: '+/- {{ value }} ans',
                yearShort: '{{ value }} yo.',
                genderLabel: 'Genre',
                genderOptions: {
                    any: 'Non défini',
                    male: 'Homme',
                    female: 'Femme',
                },
                flagFilter: 'Flag',
                birthCountry: 'Birth country',
                homeCountry: 'Home country',
                middleName: 'Middle name',
                listedCompanies: 'Listed companies',
                exposedRelations: 'Politically exposed relations',
                listedRelations: 'Mandates in listed relations',
                relationLinks: {
                    beneficialOwner: 'Beneficial owner',
                    investor: 'Investor',
                    boardMember: 'Board member',
                    advisor: 'Advisor',
                    counselor: 'Counselor',
                    executive: 'Executive',
                    manager: 'Manager',
                    shareholder: 'Shareholder',
                    since: 'since {{ value}}',
                    until: 'until {{value}}',
                    onTo: 'on {{ start }} to {{ end }}',
                },
                contact: {
                    fortune: 'Ranked fortunes',
                    fortuneShort: 'For',
                    compliance: 'Politically exposed individuals',
                    complianceShort: 'Pep',
                    sanction: 'Sanctioned individuals',
                    sanctionShort: 'San',
                    enforcement: 'Enforced individuals',
                    enforcementShort: 'Enf',
                    listed: 'Individuals with mandate in listed company',
                    listedShort: 'Lis',
                    politic: 'Politically exposed person',
                    politicShort: 'Pep',
                    reech: 'Reech',
                    reechInfo: 'Persons found in REECH',
                },
            },
            company: {
                tabTitle: "Recherche d'entreprise",
                seachPlaceholder: 'Person first/last name',
                contactLabel: 'Contact',
                activityOptions: {
                    any: 'Any',
                },
                noActivity: '(?) Unknown activity',
                name_sort: 'Name',
                contactsFilter: 'Contacts',
                size: {
                    micro: 'Micro size',
                    mid: 'Mid size',
                    small: 'Small size',
                    large: 'Large size',
                    empty: 'No size information',
                },
                employees: '{{ value }} emp.',
                groupTitle: '{{ value }} companies',
                contact: {
                    yes: 'Companies with contacts',
                    no: 'Companies without any contact',
                    listedCompaniesShort: 'Lis',
                    listedCompanies: 'Listed companies',
                    holdingShort: 'Hol',
                    holding: 'Holding companies',
                    gamblingShort: 'Gam',
                    gambling: 'Gambling companies',
                    sanctionedShort: 'San',
                    sanctioned: 'Sanctioned contacts',
                    enforcementShort: 'Enf',
                    enforcement: 'Enforced contacts',
                    reech: 'Reech',
                    reechInfo: 'Companies found in REECH',
                },
            },
            typology: {
                tabTitle: 'Recherche de typologie',
                typologieTitle: {
                    challenges: 'Les 500 plus grandes fortunes de France en {{ value }}',
                    incomesCAC40SBF120: 'Les salaires des dirigeants français du CA 40 du SBF 120',
                    mayors: 'Liste des maires de France en {{ value }}',
                    deputies: 'Liste des députés de France en {{ value }}',
                },
                challengesTitle: '{{ value}}th french fortune',
                challengesWithPerson: 'with 1 person',
                challengesWithPersons: 'with {{ value }} persons',
                CACtitle: 'Dirigeant français du CAC 40',
                CACFixedPart: 'Fixed part',
                CACVariablePart: 'Variable part',
                CACExceptionPart: 'Exceptional part',
                CACBenefitsInKind: 'Benefits in kind',
                CACPresenceToken: 'Presence token',
                deputiesMandateId: 'Mandate {{ value }}',
                deputiesStartOn: 'Starts on',
                deputiesEndsOn: 'Ends on',
                deputiesPresence: 'Presence',
                deputiesElection: 'Election',
                deputiesTitle: 'French deputy',
                mayorsTitle: 'French city mayor',
            },
            veegilenzData: {
                common: {
                    tabTitle: {
                        identity: 'Identity',
                        financial: 'Financials',
                    },
                    logo:
                        'Indicateur de risque de la PP-M ou de la PM, de valeur comprise entre 1 (risque le plus faible) à 4 (risque excluant)',
                    noNew: 'No news found',
                    noStock: 'No stocks found',
                    loadingStock: 'Loading stock data',
                    noShareholders: 'No shareholders found',
                },
                details: {
                    veegilenzInfo:
                        'Veegilenz indicator is given as an indication. Composite, made from official data available as of today from the resources chosen by our customers.<br><br>It presents an indication of vigilance according to the criteria and 3-typologies of regulations (MIFID2, AML-LCB, CFT-FT, FATCA, ...).',
                    noData: 'No data',
                    noIssue: 'No issues found',
                    noMandate: 'No mandates found',
                    issueNumber: '{{ value }} issues found',
                    mandateNumber: '{{ value }} mandates found',
                    exploreDataButton: 'Explore Data',
                    corporateTitle: 'Corporate',
                    directTitle: 'Direct',
                    directLabel:
                        "Risque évalué d'une PP-M ou PM directement dépendant des informations trouvées sur elle",
                    networkLabel:
                        "Risque évalué d'une PP-M ou PM dépendant des informations trouvées sur les membres de son réseau de PP-M",
                    corporateLabel:
                        "Risque évalué d'une PP-M ou PM dépendant des informations trouvées sur les membres de son réseau de PM",
                    networkTitle: 'Network',
                    directPeopleTitle: 'Direct People Index',
                    directCorporateTitle: 'Direct Corporate Index',
                    networkPeopleTitle: 'Network People Index',
                    networkCorporateTitle: 'Network Corporate Index',
                    nationalityLabel: 'Nationality',
                    nationalitiesLabel: 'Nationalities',
                    residenceLabel: 'Residence',
                    residencesLabel: 'Residences',
                    justiceLabel: 'Justice',
                    mandatesLabel: 'Mandates',
                    involvementActivitiesLabel: 'Involvement Activities',
                    investmentActivitiesLabel: 'Investment Activities',
                    involvementCountriesLabel: 'Involvement Countries',
                    investmentCountriesLabel: 'Investment Countries',
                    publicationLabel: 'Dernière Publication des comptes',
                },
                person: {
                    birthdate: 'Birth date',
                    weasseMaxLabel: 'Weasse Max',
                    sumCapitalLabel: 'Σ Capital',
                    sumEbitdaLabel: 'Σ Ebitda',
                    sumValuationLabel: 'Σ Valuation',
                    sumSumTurnoverLabel: 'Σ Σ Turnover',
                    sumSumEbitdaLabel: 'Σ Σ Ebitda',
                    sumSumCapitalLabel: 'Σ Σ Capital',
                    sumSumValuationLabel: 'Σ Σ Valuation',
                    sumWeasseLabel: 'Σ Weasse',
                    sumWeasseMaxLabel: 'Σ Weasse Max',
                    sumFortuneLabel: 'Σ Fortune',
                    sumSalesLabel: 'Σ Sales',
                    sumPurchasesLabel: 'Σ Purchases',
                    sumTransactionsLabel: 'Σ Transactions',
                    individualsLabel: 'Individuals',
                    businessLabel: 'Business',
                    publicLabel: 'Public',
                    directLabel: 'Direct',
                    extendedLabel: 'Extended',
                    totalLabel: 'Total',
                    allFilter: 'All',
                    currentFilter: 'Current',
                    loading: 'Loading relations...',
                    identity: {
                        involvementsTab: 'Involvements',
                        investmentsTab: 'Investments',
                        involvementsActivity: 'Involvements activity',
                        involvementsCountry: 'Involvements country',
                        investmentsActivity: 'Investments activity',
                        investmentsCountry: 'Investments country',
                    },
                    financial: {
                        companyLabel: 'Company',
                        equtyLabel: 'Equity',
                        valMinLabel: 'Val. min',
                        valMaxLabel: 'Val. max',
                        stockLabel: 'Stock',
                        totalMinLabel: 'Total min',
                        totalMaxLabel: 'Total max',
                    },
                    companies: {
                        tabTitle: 'Companies',
                        privateLabel: 'Private',
                        relStrengthSort: 'Rel. strenght',
                        relationTypeFilter: 'Relation type',
                        ownsLabel: 'Owns',
                    },
                    deals: {
                        tabTitle: 'Deals',
                    },
                },
                company: {
                    isinLabel: 'ISIN',
                    naceLabel: 'NACE',
                    shaLabel: 'Sha',
                    boaLabel: 'Boa',
                    advLabel: 'Adv',
                    exeLabel: 'Exe',
                    identity: {
                        'Standard legal form': 'Standard legal form',
                        'National legal form': 'National legal form',
                        euvat: 'EU VAT',
                        address: 'Address',
                        phone: 'Phone',
                        email: 'Email',
                        probabilityLabel: 'Probability of default (MORE / FALCON)',
                        creditLimitLabel: 'Credit limit (MORE / FALCON)',
                        stabilityLabel: 'Financial stability (CRIF)',
                        propensityLabel: 'Propensity of bankruptcy (VADIS)',
                        envRiskLabel: 'Environmental risk (TRUCOST)',
                    },
                    financial: {
                        legendValuation: 'Private equity valuation / peer group algorithm by Deecision',
                    },
                    holdersSibsidiaries: {
                        tabTitle: 'Hold. / Subsid.',
                        noShareholders: 'No shareholders found',
                    },
                },
                relationGraph: {
                    advisor: 'Advisor',
                    businessOperator: 'Business Operator',
                    beneficialOwner: 'Beneficial Owner',
                    businessManager: 'Business Manager',
                    shareholder: 'Shareholder (Ultimate owner)',
                    holdingShareholder: 'Holding shareholder',
                },
                mayors: {
                    title: 'Mayor of a French city',
                    citydistrictLabel: 'City district',
                    cityPopulationLabel: 'City population',
                    cityNameLabel: 'City name',
                },
            },
            accounting: {
                Pl: 'P/L for period [=Net income] (€)',
                toas: 'Total assets (€)',
                rceme: 'ROCE using P/L before tax (%)',
                fias: 'Fixed assets (€)',
                ifas: 'Intangible fixed assets (€)',
                tfas: 'Tangible fixed assets (€)',
                ofas: 'Other fixed assets (€)',
                cuas: 'Current assets (€)',
                stok: 'Stock (€)',
                debt: 'debtors (€)',
                ocas: 'Other current assets (€)',
                cash: 'Cash & cash equivalent (€)',
                shfd: 'Shareholders funds (€)',
                capi: 'Capital (€)',
                ncli: 'Non-current liabilities (€)',
                ltdb: 'Long term debt (€)',
                oncl: 'Other non-current liabilities (€)',
                prov: 'Provisions (€)',
                culi: 'Current liabilities (€)',
                loan: 'Loans (€)',
                cred: 'Creditors (€)',
                ocli: 'Other current liabilities (€)',
                wkca: 'Working capital (€)',
                ncas: 'Net current assets (€)',
                empl: 'Number of employees',
                opre: 'Operating revenue (Turnover) (€)',
                turn: 'Sales (€)',
                oppl: 'Operating P/L [=EBIT] (€)',
                fipl: 'Financial P/L (€)',
                fire: 'Financial revenue (€)',
                fiex: 'Financial expenses (€)',
                plbt: 'P/L before tax (€)',
                taxa: 'Taxation (€)',
                plat: 'P/L after tax (€)',
                extr: 'Extr. and other P/L (€)',
                exre: 'Extr. and other revenue (€)',
                exex: 'Extr. and other expenses (€)',
                pl: 'P/L for period [=Net income] (€)',
                expt: 'Export revenue (€)',
                mate: 'Material costs (€)',
                staf: 'Costs of employees (€)',
                depr: 'Depreciation & Amortization (€)',
                oopi: 'Other operating items (€)',
                inte: 'Interest paid (€)',
                cf: 'Cash flow (€)',
                av: 'Added value (€)',
                ebta: 'EBITDA (€)',
                rshf: 'ROE using P/L before tax (%)',
                rcem: 'ROCE using P/L before tax (%)',
                rtas: 'ROA using P/L before tax (%)',
                roe: 'ROE using Net income (%)',
                roce: 'ROCE using Net income (%)',
                roa: 'ROA using Net income (%)',
                prma: 'Profit margin (%)',
                etma: 'EBITDA margin (%)',
                ebma: 'EBIT margin (%)',
                cfop: 'Cash flow / Operating revenue (%)',
                nat: 'Net assets turnover (x)',
                ic: 'Interest cover (x)',
                stot: 'Stock turnover (x)',
                coll: 'Collection period (days)',
                crpe: 'Credit period (days)',
                exop: 'Export revenue / Operating revenue (%)',
                'R&D': 'expenses / Operating revenue (%)',
                curr: 'Current ratio (x)',
                liqr: 'Liquidity ratio (x)',
                shlq: 'Shareholders liquidity ratio (x)',
                solr: 'Solvency ratio (Asset based) (%)',
                soll: 'Solvency ratio (Liability based) (%)',
                gear: 'Gearing (%)',
                ppe: 'Profit per employee (th)',
                tpe: 'Operating revenue per employee (th)',
                sct: 'Costs of employees / Operating revenue (%)',
                ace: 'Average cost of employee (th)',
                sfpe: 'Shareholders funds per employee (th)',
                wcpe: 'Working capital per employee (th)',
                tape: 'Total assets per employee (th)',
            },
        },
        deetect: {
            common: {
                button: {
                    uploadFile: 'Upload file',
                    analyse: 'Analyze',
                },
                message: {
                    noJob: 'No job running',
                    uploadCSV: 'Upload a Deetect Excel or CSV file',
                    uploadValidCSV: 'Upload a valid Excel or CSV file',
                    noID: 'No Deetect ID found.',
                    cancel: 'Non, annuler',
                    proceed: 'Oui, procéder',
                    check: '{{ value }} lignes valides ont été trouvées, voici un échantillon:',
                },
                error: {
                    validlines: "Aucune ligne valide n'a été trouvée.",
                    validlinesSelect: 'Les {{ value }} lignes suivantes sont invalides et seront ignorées:',
                },
                title: {
                    coopt: 'Lot de cooptation',
                    segment: 'Lot de segmentation',
                    family: 'Lot de famille',
                    person: 'Envoyez une liste de personnes',
                    company: "Envoyez une liste d'entreprises",
                    covid19: 'Détection potentiel impacté par le covid-19',
                    upsell: 'Upsell',
                    augmented: 'Segmentation augmentée',
                    predict: 'Predeect',
                },
                label: {
                    potentialCovid19: 'CV19 Impact on nb business',
                    impactedCovid19: 'Impacté par le covid19',
                    linkedCompany: "Identifiant de l'entreprise liée",
                    linkedPerson: 'Identifiant de la personne liée',
                    potential: 'Potential',
                    risk: 'Risk',
                    proximity: 'Proximity',
                    completeness: 'Completeness',
                    probability: 'Probability',
                    potentialCashout: 'Potential cashout',
                    cashoutExpectancy: 'Cashout expectancy',
                    upsell: 'Upsell',
                    cashout: 'Cash Out',
                    upsellPrct: 'Upsell',
                    horizon: 'Horizon',
                    pal: 'Potential accuracy level',
                    upsellSource: 'Upsell Method',
                    completeSearch: 'Avec liens linkedin et réseau.',
                    completeSearchSub: 'Cette recherche est plus longue.',
                    uploadExcel: 'Envoyer un fichier Excel\r\navec les colonnes suivantes:',
                    predictionType: 'Type de prédiction',
                },
                tooltip: {
                    potentialCovid19: 'Ratio du potentiel impacté par le covid19',
                    notFound: 'La PP-M ou PM n’a pas été trouvée et ne fait pas partie du jeu de résultats',
                    noLink: 'La PP-M ou PM n’a aucun lien et est ignorée',
                    ambiguous: 'La PP-M ou PM est ambigüe, plusieurs résultats existent',
                    workable: 'La PP-M ou PM a été trouvée et s’inscrira dans le jeu de résultats',
                    potential: 'Evaluation du potentiel économique de la PP-M ou de la PM',
                    risk: 'Indicateur natif Veegilenz',
                    proximity: 'Indicateur natif de la force de la relation',
                    completeness:
                        'Complétude de la donnée, pourcentage d’informations utiles à l’évaluation du potentiel économique ayant pu être récupérées',
                    potAccuracyLevel:
                        "Niveau souhaité de précision quant à l'estimation du potentiel économique d'une PP-M ou d'une PM",
                },
            },
            selector: {
                customer: 'Client',
                group: 'Groupe',
                banker: 'Banquier',
                gcCode: 'Code GC',
                gc: 'GC',
                nridCode: 'Code NRID',
                display: 'Display',
                potential: 'Potential',
                dataCompleteness: 'Data Completeness',
                complianceVigilanceLevel: 'Compliance vigilance level',
                potentialAUM: 'Potential AUM (M€)',
            },
        },
        teem: {
            prospects: 'Projects',
            noProspect: 'No project',
            noStartedProspecting: 'This project is not started',
            saveProspecting: 'Save project',
            newProspect: 'New project',
            prospect: {
                number: '# of projects',
                prospectingAborted: 'Project aborted',
            },
            confCall: 'Conf call',
            infoRequest: 'Information request',
            meeting: 'Meeting',
            task: 'Task',
            location: 'Location',
            status: {
                prospectFinish: 'End prospect',
                finish: 'Finish',
            },
        },
        comeet: {
            management: 'Management',
            prospects: 'Prospects',
            customers: 'Customers',
            noEvent: 'No event registered',
            noProspect: 'No prospect',
            noStartedProspecting: 'This prospecting is not started',
            time: 'Time',
            duration: 'Duration',
            days: 'Days',
            unitPrice: 'Unit price',
            adjustment: 'Adjustment',
            meeting: 'Meeting',
            conference: 'Conference',
            study: 'Study',
            invitation: 'Invitation',
            restaurant: 'Restaurant',
            title: 'Title',
            date: 'Date',
            program: 'Program',
            totalCost: 'Total cost',
            checklist: 'Checklist',
            description: 'Description',
            saveMilestones: 'Save milestone',
            saveMeeting: 'Save meeting',
            saveProspecting: 'Save prospecting',
            addEvent: 'Add an event',
            addMilestone: 'Add a milestone',
            newProspect: 'New prospect',
            wealth: 'Wealth',
            deadline: 'Deadline',
            aumRate: 'AUM rate',
            margin: 'Margin',
            holdingPeriod: 'Holding period',
            attrition: 'Attrition',
            budgetedCost: 'Budgeted cost',
            actuaCost: 'Actual cost',
            actualBudget: 'Actual budget',
            realCost: 'Real cost',
            aum: 'AUM',
            profit: 'Profit',
            budget: 'Budget',
            expectedRevenue: 'Expected revenue',
            startDate: 'Start Date',
            eventsCount: 'Events count',
            status: {
                ongoing: 'Ongoing',
                won: 'Won',
                lost: 'Lost',
                aborted: 'Aborted',
                draft: 'Draft',
            },
            eventCostTitle: 'Cost by event type',
            prospectCostTitle: 'Cost by prospect status',
            prospect: {
                number: '# of prospects',
                ongoing: '→ Ongoing',
                won: '→ Won',
                lost: '→ Lost',
                aborted: '→ Aborted',
                rates: 'Rates',
                conversionRate: 'Conversion rate',
                averages: 'Averages',
                conversionTime: 'Conversion time',
                netRevenue: 'Net revenue',
                eventsNb: 'Number of events',
                revenue: 'Revenue',
                cost: 'Cost',
                prospectWon: 'Prospect won',
                prospectLost: 'Prospect lost',
                prospectingAborted: 'Prospecting aborted',
            },
        },
    },
};
export default fr;
