// @flow

import type { ResolverSpecs, StateLocation } from './types';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';

import { observable, action, toJS } from 'mobx';

import StateNode from './node';
import StatePath from './path';
import StateProxy from './proxy';

import dnaScope from 'dna/scope';
import comeetScope from 'comeet/scope';
import deeligenzScope from 'deeligenz/scope';
import teemScope from 'teem/scope';
import cleeranceScope from 'cleerance/scope';

const ROOT: ResolverSpecs = {
    type: 'object',
    children: {
        dna: dnaScope,
        comeet: comeetScope,
        teem: teemScope,
        deeligenz: deeligenzScope,
        cleerance: cleeranceScope,
    }
};

export default class StateStore {
    id: ?string;
    nodes: Map<string, StateNode>;
    root: StateNode;

    @observable data = {};

    constructor () {
        this.id = null;
        this.nodes = new Map();
        this.root = new StateNode(this.nodes, new StatePath([]), ROOT);
        this.root.register(this);
        this.nodes.set('', this.root);
    }

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        const load = action((id: string) => {
            this.id = id;
            let state = localStorage.getItem(`state:${id}`) || '{}';

            try {
                state = JSON.parse(state);
            } catch(e) {
                state = {};
            }

            state = this.root.normalize(state, true);
            localStorage.setItem(`state:${id}`, JSON.stringify(state));
            this.data = state;
        });

        if (cursor.account) {
            if (this.id === cursor.account) {
                return;
            }

            return await load(cursor.account);
        }

        if (cursor.intervention) {
            if (this.id === cursor.intervention) {
                return;
            }

            return await load(cursor.intervention);
        }

        if (this.id) {
            this.id = null;
            this.data = {};
        }
    }

    @action getData = (): Object => {
        return toJS(this.data);
    };

    @action setData = (data: Object): void => {
        this.data = data;
        this.store();
    };

    @action define = (path: StatePath, specs: ResolverSpecs): StateNode => {
        const key = path.toString();
        const found = this.nodes.get(key);

        if (found) {
            return found;
        }

        const node = new StateNode(this.nodes, path, specs);
        node.register(this);
        this.nodes.set(key, node);

        return node;
    };

    @action get = (path: StatePath, type: string): StateNode => {
        const node = this.nodes.get(path.toString());

        if (! node) {
            throw new Error(`Tried to get undefined "${path.toString()}" state node.`);
        }

        if (node.type !== type) {
            console.warn(`Got "${path.toString()}" state node with an invalid type: ${JSON.stringify(type)}`);
        }

        return node;
    };

    @action update = (path: StatePath, values: Object): void => {
        const node = this.nodes.get(path.toString());

        if (node) {
            const data = node.update(Object.assign({}, this.getData()), values);
            this.setData(data);
        }
    };

    @action enter = (location: StateLocation | StatePath, type: string): StateProxy => {
        return new StateProxy(this, [this.root]).enter(location, type);
    };

    @action store = (): void => {
        const id = this.id;

        if (!id) {
            console.error('tried to store state without id');
            return;
        }

        const state = this.root.normalize(toJS(this.data), true);
        localStorage.setItem(`state:${id}`, JSON.stringify(state));
    };
}
