// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        width: '355px',
        padding: '15px',
    },
    actions: {
        display: 'flex',
        marginTop: '15px',
        alignItems: 'flex-end',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.views.layout.application.header.account';

    static propTypes = {
        user: PropTypes.object.isRequired,
        onSave: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = { data: null, error: false };

    render() {
        const profile = this.props.user.profile || {};

        return (
            <div style={styles.container}>
                <Views.Form.Container
                    ref="form"
                    data={{ firstName: profile.firstName || '', lastName: profile.lastName || '' }}
                    onChange={(data: Object) => this.setState({ data, error: this.refs.form.hasError() })}
                >
                    <Views.Form.Row>
                        <Views.Form.Field label="First name" path="firstName" widget="string" required />
                    </Views.Form.Row>

                    <Views.Form.Row>
                        <Views.Form.Field label="Last name" path="lastName" widget="string" required />
                    </Views.Form.Row>
                </Views.Form.Container>

                <div style={styles.actions}>
                    <Views.Common.Button
                        type="flat"
                        color="hot"
                        icon={<Icon type="do.cancel" />}
                        label="Cancel"
                        onClick={() => this.props.onCancel()}
                    />

                    <span>&nbsp;&nbsp;</span>

                    <Views.Common.Button
                        type="raised"
                        color="cold"
                        icon={<Icon type="do.submit" />}
                        label="Save"
                        disabled={this.state.error || !this.state.data}
                        onClick={() => this.props.onSave(this.state.data)}
                    />
                </div>
            </div>
        );
    }
}
