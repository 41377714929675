// @flow

import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import colors from 'app/theme/colors';
import { isRelationMode, makeDirectCells, makePredictCells, makePredictNetworkCells, makeUpsellCells, makeRelationCells, makeCovid19Cells, makeAugmentedCells } from './specs';

let styles = {
    table: {
        width: '100%',
    },
    header: {
        padding: '10px 5px',
        color: colors.grey500,
    },
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
        cursor: 'pointer',
        background: 'white',
    },
    hover: {
        background: colors.grey200,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.detector.work.results.list.row';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        mode: PropTypes.oneOf(['segment', 'coopt', 'predict', 'upsell', 'covid19', 'augmented']).isRequired,
        entity: PropTypes.object,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        style: PropTypes.object,
    };

    state = { hover: false };

    render() {
        const id = this.props.entity.target
            ? this.props.entity.target.id
            : this.props.entity.source
            ? this.props.entity.source.id
            : null;

        return (
            <tr style={this.props.style}>
                {this.renderCells({
                    onClick: id ? event => this.context.openEntity(id) : undefined,
                    onMouseEnter: id ? () => this.setState({ hover: true }) : undefined,
                    onMouseLeave: id ? () => this.setState({ hover: false }) : undefined,
                    style: Object.assign({}, styles.cell, this.state.hover ? styles.hover : {}),
                })}
            </tr>
        );
    }

    renderCells(props: Object) {
        if (this.props.mode === 'predict' && this.props.entity.target) {
            return makePredictNetworkCells(this.props.entity.source, this.props.entity.target, props);
        }
        if (this.props.mode === 'predict') {
            return makePredictCells(this.props.entity.source, props);
        }
        if (this.props.mode === 'upsell') {
            return makeUpsellCells(this.props.entity.source, get(this.props,'entity.target', undefined), props);
        }
        if (this.props.mode === 'covid19') {
            return makeCovid19Cells(this.props.entity.source, props);
        }
        if (this.props.mode === 'augmented') {
            return makeAugmentedCells(this.props.entity.source, props);
        }
        return isRelationMode(this.props.mode)
            ? makeRelationCells(this.props.entity.source, this.props.entity.target, props)
            : makeDirectCells(this.props.entity.source, props);
    }
}
