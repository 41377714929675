// @flow

import type { LinkProfile, StockAggregate, StockWeasse, EntityEstimate } from '@deecision/deefind-types/model';

import get from 'lodash/get';

export default function makeStockWeasse(links: LinkProfile[]): StockWeasse {
    if (!links.length) {
        return { cash: {}, stocks: {}, total: {} };
    }

    const ceased: LinkProfile[] = [];
    const current: LinkProfile[] = [];

    for (const link of links) {
        const owned = link.owned;

        if (!owned || !owned.direct) {
            continue;
        }

        if (owned.ceased || (link.target && link.target.type === 'company' && link.target.closed)) {
            ceased.push(link);
        } else {
            current.push(link);
        }
    }

    return {
        cash: makeAggregate(ceased),
        stocks: makeAggregate(current),
        total: makeAggregate(ceased.concat(current)),
    };
}

function makeAggregate(links: LinkProfile[]): StockAggregate {
    const minEstimates: EntityEstimate[] = [];
    const maxEstimates: EntityEstimate[] = [];

    for (const link of links) {
        const owned = link.owned;
        const valuation = get(link, 'target.valuation');
        const flags = get(link, 'target.flags', []);
        const total = owned ? owned.total : undefined;

        if (!valuation || !valuation.value) {
            continue;
        }

        if (total) {
            const estimate = {
                value: Math.round((total.max + total.min) / 2),
                deviation: Math.round((total.max - total.min) / 2),
            };

            minEstimates.push(estimate);
            maxEstimates.push(estimate);
        } else {
            const ratio = flags.includes('listed') ? 1 / 100 : 1;

            maxEstimates.push({
                value: Math.round((valuation.value || 0) * ratio),
                deviation: Math.round((valuation.deviation || 0) * ratio),
            });
        }
    }

    const min = makeSum(minEstimates, links.length) || undefined;
    const max = makeSum(maxEstimates, links.length) || undefined;
    const full = makeFull(min, max) || undefined;

    return { min, max, full };
}

function makeSum(estimates: EntityEstimate[], total: number): ?EntityEstimate {
    let value = 0;
    let deviation = 0;

    for (const estimate of estimates) {
        value += estimate.value || 0;
        deviation += estimate.deviation || 0;
    }

    if (!value) {
        return undefined;
    }

    return {
        value: Math.round(value),
        deviation: Math.round(deviation),
        reliability: total ? Math.round((100 * estimates.length) / total) : 0,
    };
}

function makeFull(min: ?EntityEstimate, max: ?EntityEstimate): ?EntityEstimate {
    const minValue = min ? (min.value || 0) - (min.deviation || 0) : 0;
    const maxValue = max ? (max.value || 0) + (max.deviation || 0) : 0;

    if (!minValue && !maxValue) {
        return undefined;
    }

    return {
        value: (minValue + maxValue) / 2,
        deviation: (maxValue - minValue) / 2,
        reliability: max ? max.reliability : min ? min.reliability : 0,
    };
}
