// @flow

import Moment from 'moment';

export default function computeNext(
    start: any,
    fundType: string,
    score: number,
    aum: number,
): { date: string, type: string } {
    if (!start) return { date: '', type: '' };

    switch (fundType) {
        case 'alternative':
        case 'equity':
            if (score <= 4) {
                return {
                    date: Moment(start)
                        .add(2, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            return {
                date: 'not defined'
            };
        case 'etf':
        case 'passive':
            if (score <= 4) {
                return {
                    date: Moment(start)
                        .add(4, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            return {
                date: 'not defined'
            };
        case 'delegatedmanager':
        case 'delegated':
        case 'externalmanager':
        case 'external':
        case 'traditional':
            if (aum < 1000 && score <= 2) {
                return {
                    date: Moment(start)
                        .add(5, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            if ((aum > 1000 && aum < 2000) && score <= 2) {
                return {
                    date: Moment(start)
                        .add(3, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }


            if ((aum > 2000) && score <= 2) {
                return {
                    date: Moment(start)
                        .add(2, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            if ((aum < 2000) && score === 3) {
                return {
                    date: Moment(start)
                        .add(3, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            if ((aum > 2000) && score === 3) {
                return {
                    date: Moment(start)
                        .add(2, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            if (score === 4) {
                return {
                    date: Moment(start)
                        .add(2, 'year')
                        .format('ddd D MMMM YYYY')
                };
            }

            return {
                date: 'not defined'
            };
        default:
            console.error('wrong fund type:', fundType, score, aum);
    }
}
