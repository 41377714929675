import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import {SortableHandle} from 'react-sortable-hoc';


const styles = {
    container: {
        display: 'grid',
        lineHeight: '24px',
        gridTemplateColumns: '24px auto 65px 30px',
        margin: '1px 0',
        background: colors.grey200,
        fontSize: '13px',
    },
    grip: {
        padding: '8px 3px',
        cursor: 'move',
        borderRadius: '3px 0 0 3px',
    },
    preview: {
        padding: '5px',
        display: 'flex',
        cursor: 'pointer',
    },
    label: {
        width: '80%',
        fontSize: '15px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    weight: {
        textAlign: 'center',
        color: 'white',
        width: '24px',
        background: 'rgba(0,0,0,0.25)',
        borderRadius: '50%',
        flexGrow: '0',
        flexShrink: '0',
    },
    size: {
        textAlign: 'right',
        width: '40px',
        marginRight: '10px',
        fontSize: '15px',
        opacity: '0.5',
    },
    edit: {
        height: '24px',
        padding: '5px 10px 5px 5px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '3px',
        opacity: '0.5',
        background: 'transparent',
    },
    remove: {
        textAlign: 'center',
        padding: '8px 0',
        borderRadius: '3px',
        cursor: 'pointer',
        opacity: '0.5',
        background: 'transparent',
    },
    icon: {
        width: '18px',
        height: '18px',
        opacity: '0.5',
        transition: 'none',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.management.questions.category.item';

    static propTypes = {
        id: PropTypes.string.isRequired,
        store: PropTypes.object.isRequired,
        selected: PropTypes.bool,
        size: PropTypes.number,
        onSelect: PropTypes.func.isRequired,
        onEdit: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        sizeSelected: PropTypes.number
    };

    state = { hover: false, previewHover: false, editHover: false, removeHover: false };

    render () {
        const category = this.props.store.categoriesMap.get(this.props.id);

        if (! category) {
            return null;
        }

        const style = Object.assign({},
            styles.container,
            {
                background: this.props.selected ? colors.userCold : this.state.previewHover ? colors.grey300 : colors.grey200,
                color: this.props.selected ? 'white' : 'black',
            },
        );

        const iconStyle = Object.assign({},
            styles.icon,
            { fill: this.props.selected ? 'white' : 'black' },
        );

        const editStyle = Object.assign({},
            styles.edit,
            this.state.editHover ? { background: colors.userCold, color: 'white', opacity: '1' } : {},
        );

        const editIconStyle = Object.assign({},
            iconStyle,
            this.state.editHover ? { fill: 'white' } : {},
        );

        const removeStyle = Object.assign({},
            styles.remove,
            this.props.size > 0
                ? { cursor: 'not-allowed' }
                : this.state.removeHover ? { background: colors.userHot, opacity: '1' } : {},
        );

        const removeIconStyle = Object.assign({},
            iconStyle,
            this.state.removeHover && ! this.props.size ? { fill: 'white' } : {},
        );

        const DragHandle = SortableHandle(() => (
            <Icon style={{ verticalAlign: 'middle', fill: 'black', cursor: 'pointer' }} type="base.grip" />
        ));

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
            >
                <div style={ styles.grip }>
                    {this.props.readOnly ? null : <DragHandle/>}
                </div>

                <span
                    style={ styles.preview }
                    onMouseEnter={ event => this.setState({ previewHover: true }) }
                    onMouseLeave={ event => this.setState({ previewHover: false }) }
                    onClick={ this.props.onSelect }
                >
                    <span style={ styles.label }>{ category.payload.label }</span>
                    <span style={ styles.size }>{this.props.sizeSelected !== undefined ? `${this.props.sizeSelected}/` : null}{ this.props.size || 0 }</span>

                    <span style={ Object.assign({}, styles.weight, { opacity: category.payload.weight ? '1' : '0' }) }>
                        { category.payload.weight || 0 }
                    </span>
                </span>

                {this.props.readOnly ? null : (
                    <div
                        style={ editStyle }
                        onMouseEnter={ event => this.setState({ editHover: true }) }
                        onMouseLeave={ event => this.setState({ editHover: false }) }
                        onClick={ event => this.props.onEdit(category) }
                    >
                        <Icon style={ editIconStyle } type="do.update" />
                        <span>Edit</span>
                    </div>
                )}

                {this.props.readOnly ? null : (
                    <div
                        style={ removeStyle }
                        onMouseEnter={ event => this.setState({ removeHover: true }) }
                        onMouseLeave={ event => this.setState({ removeHover: false }) }
                        onClick={ this.props.onRemove }
                    >
                        <Icon style={ removeIconStyle } type="do.clear" />
                    </div>
                )}
            </div>
        );
    }
}
