import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

const styles = {
    container: {
        width: '250px',
        position: 'relative',
        border: '1px solid ' + colors.grey400,
        background: colors.grey100,
        borderRadius: '3px',
        margin: '5px 35px'
    },
    clickable: {
        cursor: 'pointer'
    },
    hover: {
        border: '1px solid ' + colors.userCold,
        background: colors.white,
    },
    stock: {
        position: 'absolute',
        background: colors.userCold,
        borderRadius: '3px',
        color: 'white',
        fontSize: '11px',
        lineHeight: '20px',
        padding: '0 4px',
        top: '50%',
        marginTop: '-10px',
        textAlign: 'center',
        //width: '25px',
    },
    stock_input: {
        left: '-25px'
    },
    stock_output: {
        right: '-25px'
    },
    stock_guess: {
        background: colors.userHot,
        borderRadius: '10px',
        width: '10px',
        marginLeft: '6px',
        marginRight: '6px',
    },
    title: {
        fontSize: '13px',
        margin: '5px 5px 5px 25px'
    },
    desc: {
        fontSize: '11px',
        color: colors.grey500,
        margin: '5px 5px 5px 25px'
    },
    children: {
        margin: '5px 35px',
        fontSize: '13px',
        color: colors.userCold
    },
    wire: {
        position: 'absolute',
        top: '50%',
        marginTop: '-1px',
        width: '33px',
        height: '0',
        border: '1px solid ' + colors.grey400
    },
    wire_input: {
        left: '-36px'
    },
    wire_output: {
        right: '-36px'
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.stocks.node';

    static propTypes = {
        title: PropTypes.node,
        desc: PropTypes.node,
        input: PropTypes.string,
        output: PropTypes.string,
        style: PropTypes.object,
        onClick: PropTypes.func,
    };

    state = { hover: false };

    render () {
        const style = Object.assign({},
            styles.container,
            this.props.style || {},
            this.props.onClick ? styles.clickable : {},
            this.props.onClick && this.state.hover ? styles.hover : {}
        );

        return (
            <div
                style={ style }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onClick }
            >
                <div style={ styles.content }>
                    { this.renderBlock('title') }
                    { this.renderBlock('desc') }
                    { this.renderChildren() }
                </div>

                { this.renderWire('input') }
                { this.renderWire('output') }

                { this.renderStock('input') }
                { this.renderStock('output') }

            </div>
        );
    }

    renderWire (type) {
        if (this.props[ type ] === undefined) {
            return null;
        }

        const style = Object.assign({},
            styles.wire,
            styles[ 'wire_' + type ]
        );

        return (
            <span style={ style } />
        );
    }

    renderStock (type) {
        if (! this.props[ type ]) {
            return null;
        }

        const style = Object.assign({},
            styles.stock,
            styles[ 'stock_' + type ]
        );

        return (
            <span style={ style }>
                { this.props[ type ] }
            </span>
        );
    }

    renderBlock (name) {
        if (! this.props[ name ])
            return null;

        return (
            <div style={ styles[name] }>
                { this.props[name] }
            </div>
        );
    }

    renderChildren () {
        if (! this.props.children) {
            return null;
        }

        return (
            <div style={ styles.children }>
                { this.props.children }
            </div>
        );
    }
}
