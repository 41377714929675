// @flow

import type { RelationEntry } from '@deecision/deefind-types/model';

import PropTypes from 'prop-types';
import React from 'react';
import Block from './block';
import orderBy from 'lodash/orderBy';
import colors from 'app/theme/colors';
import Views from 'app/views';

const LEVELS = {
    i: 'Investor',
    s: 'Shareholder',
    b: 'Board member',
    a: 'Adviser',
    c: 'Councilor',
    x: 'Executive',
    m: 'Manager',
    o: 'Other',
};

const styles = {
    title: {
        color: colors.userCold,
        fontWeight: 'bold',
        marginBottom: '5px',
    },
    info: {
        color: colors.grey600,
        fontWeight: 'normal',
        paddingTop: '5px',
    },
    details: {
        fontSize: '13px',
        color: colors.grey600,
        lineHeight: '17px',
        paddingTop: '5px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.compliance.report.relations';

    static propTypes = {
        title: PropTypes.string.isRequired,
        relations: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    render() {
        if (!this.props.relations.length) {
            return null;
        }

        return (
            <Block
                title={this.props.title}
                empty={'N/C'}
                rows={orderBy(
                    this.props.relations.filter(
                        relation => relation.target.name !== '(?)' && relation.paths.length && !relation.ceased,
                    ),
                    'strength',
                    'desc',
                ).map(relation => [this.renderLeft(relation), this.renderRight(relation)])}
            />
        );
    }

    renderLeft(relation: RelationEntry) {
        return (
            <div key="left">
                <div style={styles.title}>{LEVELS[relation.level] || 'Unknown'}</div>
                {relation.target.turnover ? (
                    <div style={styles.info}>
                        Turnover: <Views.Common.Number type="currency" scale="M" value={relation.target.turnover} />
                    </div>
                ) : null}
                {relation.start ? <div style={styles.info}>Since {relation.start}</div> : null}
                {relation.end ? <div style={styles.info}>Until {relation.end}</div> : null}
            </div>
        );
    }

    renderRight(relation: RelationEntry) {
        return (
            <div key="right">
                {relation.target.name}

                {(relation.paths[0][0].mandates || [])
                    .map(mandate => mandate.titles.join(' / '))
                    .concat((relation.paths[0][0].positions || []).map(mandate => mandate.titles.join(' / ')))
                    .map((title, index) => (
                        <div key={index} style={styles.details}>
                            {title}
                        </div>
                    ))}
            </div>
        );
    }
}
