// @flow

import type {
    ComputedInvolvement,
    ComputedInvolvementProfile,
    RelationCollection,
} from '@deecision/deefind-types/model';

import union from 'lodash/union';

export default function makeComputedInvolvements(companiesRelations: RelationCollection): ComputedInvolvement[] {
    const locations = {};
    const activities = {};

    for (const relation of companiesRelations) {
        register(locations, relation.target.country, relation.target.id, !!relation.owned, !relation.ceased);
        register(activities, relation.target.activity, relation.target.id, !!relation.owned, !relation.ceased);
    }

    return union(
        compute(locations, 'aa', { type: 'location', scope: 'all', status: 'all' }),
        compute(locations, 'ac', { type: 'location', scope: 'all', status: 'current' }),
        compute(locations, 'sa', { type: 'location', scope: 'stocks', status: 'all' }),
        compute(locations, 'sc', { type: 'location', scope: 'stocks', status: 'current' }),
        compute(activities, 'aa', { type: 'activity', scope: 'all', status: 'all' }),
        compute(activities, 'ac', { type: 'activity', scope: 'all', status: 'current' }),
        compute(activities, 'sa', { type: 'activity', scope: 'stocks', status: 'all' }),
        compute(activities, 'sc', { type: 'activity', scope: 'stocks', status: 'current' }),
    );
}

export function register(map: Object, code?: string, company: string, stocks: boolean, current: boolean): void {
    if (!code) {
        return;
    }

    if (!map[code]) {
        map[code] = { aa: [], ac: [], sa: [], sc: [] };
    }

    map[code].aa.push(company);

    if (current) {
        map[code].ac.push(company);
    }

    if (stocks) {
        map[code].sa.push(company);
    }

    if (stocks && current) {
        map[code].sc.push(company);
    }
}

export function compute(map: Object, prop: string, profile: ComputedInvolvementProfile): ComputedInvolvement[] {
    const involvements: ComputedInvolvement[] = [];
    let total = 0;

    for (const code of Object.keys(map)) {
        const companies = map[code] ? map[code][prop] : null;

        if (!Array.isArray(companies) || !companies.length) {
            continue;
        }

        involvements.push(Object.assign({}, profile, { code, companies, ratio: 0 }));
        total += companies.length;
    }

    if (!total) {
        return [];
    }

    return involvements.map((involvement: ComputedInvolvement) =>
        Object.assign({}, involvement, {
            ratio: Math.round((1000 * involvement.companies.length) / total) / 10,
        }),
    );
}
