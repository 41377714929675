// @flow

import React from 'react';
import PropTypes from 'prop-types';

import List from './list';
import Scope from 'app/layout/providers/scope';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.calendar.workload';

    static propTypes = {
        style: PropTypes.object.isRequired,
    };

    render() {
        return (
            <Scope path="filter" type="object">
                <List style={this.props.style} />
            </Scope>
        );
    }
}
