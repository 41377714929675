import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import get from 'lodash/get';

const styles = {
    container: {
        position: 'relative',
        background: 'white',
        opacity: 0.9,
        fontSize: '15px',
        overflow: 'visible',
        borderRadius: '3px',
        height: '152px'
    },
    hover: {
        background: colors.grey100,
        opacity: 1
    },
    dimmed: {
        position: 'absolute',
        zIndex: 1,
    },
    content: {
        position: 'absolute',
        zIndex: 2,
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.card';

    static propTypes = {
        dimmed: PropTypes.bool,
        style: PropTypes.object
    };

    state = {
        hover: false
    };

    render () {
        const width = 466;
        const height = parseInt(get(this.props, 'style.height')) || 192;
        const padding = parseInt(get(this.props, 'style.padding')) || 20;
        const viewBox = `0 0 ${width - padding} ${height - padding}`;
        const halfPadding = padding / 2;

        const style1 = Object.assign({},
            styles.container,
            this.state.hover ? styles.hover : {},
            this.props.style,
            { height: `${height}px`, padding: `0` }
        );

        const style2 = Object.assign({},
            styles.content,
            {
                height: `${height - 2 * padding}px`, width: `${width - 2 * padding}px`,
                top: `${padding}px`, bottom: `${padding}px`,
                left: `${padding}px`, right: `${padding}px`,
            }
        );

        const style3 = Object.assign({},
            styles.dimmed,
            {
                height: `${height - padding}px`, width: `${width - padding}px`,
                top: `${halfPadding}px`, bottom: `${halfPadding}px`,
                left: `${halfPadding}px`, right: `${halfPadding}px`,
            }
        );

        return (
            <div
                style={ style1 }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
            >
                <div style={ style2 }>
                    { this.props.children }
                </div>

                {
                    this.props.dimmed
                        ? (
                            <svg style={ style3 } viewBox={ viewBox }>
                                <line x1="0" y1="0" x2={ width - padding } y2={ height - padding } stroke={ colors.grey500 } strokeWidth={ 1 } />
                                <line x1="0" y1={ height - padding } x2={ width - padding } y2="0" stroke={ colors.grey500 } strokeWidth={ 1 } />
                            </svg>
                        )
                        : null
                }
            </div>
        );
    }
}
