import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import Table from '../../common/survey/field/widget/table';

const styles = {
    container: {
        width: '40px',
        marginLeft: '30px',
    },
    modal: {
        width: '1200px',
        minWidth: '1200px',
    },
    widget: {
        minHeight: '300px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.attendees.edit';

    static propTypes = {
        attendees: PropTypes.arrayOf(PropTypes.object).isRequired,
        onAttendees: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <Views.Common.Button
                    type="round"
                    color="cold"
                    icon={<Icon type="do.update" />}
                    onClick={event => this.refs['formEdit'].open()}
                    mini
                />

                <Views.Modal.Edition
                    ref="formEdit"
                    data={
                        this.state && this.state.answer
                            ? this.state.answer
                            : { rows: this.parseRows(this.props.attendees) }
                    }
                    title="Attendees list"
                    style={styles.modal}
                    onSubmit={answer => {
                        this.props.onAttendees(this.formatRows(this.state.answer.rows));
                        this.refs['formEdit'].close();
                    }}
                >
                    <Table
                        readOnly={false}
                        onChange={answer => {
                            this.setState({ answer });
                            this.refs['formEdit'].handleChange(answer);
                        }}
                        answer={
                            this.state && this.state.answer
                                ? this.state.answer
                                : { rows: this.parseRows(this.props.attendees) }
                        }
                        columns={[
                            {
                                type: 'string',
                                label: 'First name',
                            },
                            {
                                type: 'string',
                                label: 'Last name',
                            },
                            {
                                type: 'date',
                                label: 'Birth date',
                            },
                            {
                                type: 'string',
                                label: 'Position',
                            },
                            { label: 'Email', type: 'string' },
                        ]}
                    />
                </Views.Modal.Edition>
            </div>
        );
    }

    parseRows(attendees: Object[]) {
        return attendees.map((attendee: Object) => [
            attendee.firstName,
            attendee.lastName,
            attendee.birthDay,
            attendee.position,
            attendee.email,
        ]);
    }

    formatRows(rows: string[][]) {
        return rows.map((row: string[], index: number) =>
            Object.assign({}, this.props.attendees[index] || {}, {
                firstName: row[0],
                lastName: row[1],
                birthDay: row[2],
                position: row[3],
                email: row[4],
            }),
        );
    }
}
