import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.augmented.charts.barChartDiff';

    static propTypes = {
        data: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
    };

    render() {
        return (
            <Plot
                data={[
                    {
                        type: 'bar',
                        x: this.props.data.map(d => d.label),
                        y: this.props.data.map(d => d.positive),
                        text: this.props.data.map(d => d.posText),
                        marker: {color: 'rgb(80, 175, 51)'},
                        textposition: 'auto',
                        name: '',
                    },
                    {
                        type: 'bar',
                        x: this.props.data.map(d => d.label),
                        y: this.props.data.map(d => d.negative),
                        marker: {color: 'rgb(216, 109, 109)'},
                        text: this.props.data.map(d => d.negText),
                        textposition: 'auto',
                        name: '',
                    },
                ]}
                layout={{
                    width: this.props.width,
                    height: this.props.height,
                    title: {
                        text: this.props.title,
                        font: {
                            family: 'Arial',
                            size: 18,
                            color: '#000000',
                        },
                        xref: 'paper',
                        x: 0.05,
                    },
                    showlegend: false,
                    barmode: 'relative',
                    yaxis: {
                        dtick: 10,
                        ticksuffix: '%',
                        showgrid: false,
                    },
                    margin: {
                        l: 60,
                        r: 60,
                        b: 100,
                        t: 30,
                        pad: 10,
                    },
                }}
                config={{ displayModeBar: false }}
            />
        );
    }
}
