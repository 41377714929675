import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';
import { get } from 'lodash';

const styles = {
    container: {
        border: '1px solid ' + colors.grey400,
        background: colors.grey100,
        borderRadius: '3px',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '150px',
        height: '150px',
        flexGrow: '0',
        flexShrink: '0',
    },
    hover: {
        border: '2px solid ' + colors.userCold,
        padding: '4px',
        cursor: 'pointer',
    },
    icon: {
        fill: colors.grey500,
        marginBottom: '10px',
    },
    name: {
        display: 'block',
        fontSize: '13px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    flag: {
        display: 'inline-block',
        margin: '10px 5px 0 5px',
        color: colors.userHot,
        fontSize: '13px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.relation.entity';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired,
    };

    static propTypes = {
        target: PropTypes.object,
        ceased: PropTypes.bool,
    };

    state = { hover: false };

    render() {
        const style = Object.assign(
            {},
            styles.container,
            this.props.target.type === 'person' ? { borderRadius: '50%' } : { margin: '0 10px' },
            this.state.hover ? styles.hover : {},
            this.props.ceased ? { opacity: '0.5', textDecoration: 'line-through' } : {},
        );

        return (
            <div
                style={style}
                onClick={this.props.target.id ? event => this.context.openEntity(this.props.target.id) : undefined}
                onMouseEnter={event => this.setState({ hover: true })}
                onMouseLeave={event => this.setState({ hover: false })}
            >
                {this.renderIcon()}
                {this.renderName()}
                {this.renderFlags()}
            </div>
        );
    }

    renderIcon() {
        if (!this.props.target) {
            return null;
        }

        switch (this.props.target.type) {
            case 'person':
                return <Icon type="dna.person" style={styles.icon} />;
            case 'company':
                return <Icon type="dna.company" style={styles.icon} />;
        }
    }

    renderName() {
        if (!this.props.target) {
            return <span style={styles.name}>{t('common.message.loading')}</span>;
        }

        if (this.props.target.firstName && this.props.target.lastName) {
            return (
                <span style={styles.name}>
                    {this.props.target.lastName.toUpperCase()}
                    <br />
                    {this.props.target.firstName}
                </span>
            );
        }

        return <span style={styles.name}>{this.props.target.name || '< name unknown >'}</span>;
    }

    renderFlags() {
        if (!this.props.target) {
            return null;
        }

        const flags = this.props.target.flags || [];
        const labels = [];

        switch (this.props.target.type) {
            case 'company':
                if (flags.includes('closed')) labels.push('Closed');
                if (flags.includes('listed')) labels.push('Listed');
                if (flags.includes('holding')) labels.push('Holding');
                break;

            case 'person':
                if (flags.includes('fortune')) labels.push('Fortune');
                break;
        }

        if (!labels.length) {
            return null;
        }

        const renderFlag = (label, index) => (
            <span key={'flag' + index} style={styles.flag}>
                {label}
            </span>
        );

        return <div style={styles.flags}>{labels.map(renderFlag)}</div>;
    }
}
