import type { StockAggregate } from '@deecision/deefind-types/model';

import React from 'react';
import PropTypes from 'prop-types';

import colors from 'app/theme/colors';
import Views from 'app/views';
import List from './weasse/list';
import Sum from './display/sum';
import ListPatents from './weasse/listPatents';
import t from 'app/translation';

const styles = {
    container: {},
    tabs: {
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '40px',
        marginBottom: '40px',
        borderRadius: '3px',
    },
    tab: {
        display: 'flex',
        cursor: 'pointer',
        width: '400px',
        background: colors.grey100,
    },
    current: {
        borderBottom: '2px solid ' + colors.userHot,
    },
    empty: {
        display: 'block',
        cursor: 'default',
        color: colors.grey500,
        textAlign: 'center',
    },
    count: {
        color: colors.userHot,
        display: 'inline-block',
        padding: '0 10px',
    },
    label: {
        color: colors.userCold,
        display: 'inline-block',
        padding: '0 10px',
    },
    sum: {
        display: 'inline-block',
        padding: '0 10px',
        color: colors.grey600,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.weasse';

    static propTypes = {
        stocks: PropTypes.object.isRequired,
        children: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        const children = this.props.stocks.children || [];
        this.state = { tab: children.length ? 'stocks' : 'cashout' };
    }

    render() {
        const children = this.props.stocks.children || [];
        const cashout = this.props.stocks.cashout || [];
        const weasse = this.props.stocks.weasse || {};

        if (!children.length && !cashout.length) {
            return <Views.Common.Empty title="No stock and no cash out" />;
        }

        return (
            <div style={styles.container}>
                <div style={styles.tabs}>
                    {this.renderTab('stocks', children, weasse.stocks, 'Stocks', 'No stocks found', '')}
                    {this.renderTab('cashout', cashout, weasse.cash, 'Cash out', 'No cash out found', t('common.tooltip.cashout'))}
                    {this.renderTab('patents', this.props.children && this.props.children.patents || [], {}, 'Patents', 'Patents loading...', 'source : data.inpi.fr')}
                </div>

                {
                    this.state.tab === 'patents' ?
                    <ListPatents items={this.props.children && this.props.children.patents} /> :
                    <List items={this.state.tab === 'cashout' ? cashout : children} />
                }
            </div>
        );
    }

    renderTab(key: string, items: Object[], aggregate?: StockAggregate, label: string, emptyLabel: string, tooltip: string) {
        const empty = items.length === 0;

        if (empty) {
            return (
                <div key={key} style={Object.assign({}, styles.tab, styles.empty)}>
                    {emptyLabel}
                </div>
            );
        }

        const tabStyle = Object.assign({}, styles.tab, key === this.state.tab ? styles.current : {});

        return (
            <div key={key} style={tabStyle} onClick={items.length ? () => this.setState({ tab: key }) : undefined}>
                <Views.Common.Tooltip content={tooltip}>
                    <span style={styles.label}>{label}</span>
                </Views.Common.Tooltip>
                <span style={styles.count}>{items.length}</span>
                <Sum style={styles.sum} data={aggregate ? aggregate.full : undefined} />
            </div>
        );
    }
}
