// @flow

import React from 'react';
import Icon from 'app/theme/icon';
import styles from './styles';

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.screen.tabs.entry';

    props: {
        icon: string,
        label?: string,
        count?: number,
        current?: boolean,
        select?: Function,
        close: Function,
    };

    state = {
        hover: false,
        closeHover: false
    };

    computeStyle (element: string): Object {
        const hover = element === 'close'
            ? this.state.closeHover
            : this.state.hover;

        return Object.assign({},
            styles[element].base,
            this.props.disabled
                ? styles[element].disabled || {}
                : (
                    this.props.current
                        ? styles[element].current || {}
                        : (hover ? styles[element].hover || {} : {})
                )
        );
    }

    render () {
        return (
            <div
                style={ this.computeStyle('container') }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.select }
            >
                { this.renderIcon() }
                { this.renderLabel() }
                { this.renderCount() }
                { this.renderClose() }
            </div>
        )
    }

    renderIcon () {
        if (! this.props.icon) {
            return null;
        }

        return (
            <Icon
                type={ this.props.icon }
                style={ this.computeStyle('icon') }
            />
        );
    }

    renderLabel () {
        if (! this.props.label) {
            return null;
        }

        return (
            <span style={ this.computeStyle('label') }>
                { this.props.label }
            </span>
        );
    }

    renderCount () {
        if (! this.props.count) {
            return null;
        }

        return (
            <span style={ this.computeStyle('count') }>
                { this.props.count }
            </span>
        );
    }

    renderClose () {
        if (! this.props.close) {
            return null;
        }

        const onClick = (event) => {
            this.props.close();
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <span
                style={ this.computeStyle('close') }
                onMouseEnter={ event => this.setState({ closeHover: true }) }
                onMouseLeave={ event => this.setState({ closeHover: false }) }
                onClick={ onClick }
            >
                ✕
            </span>
        );
    }
}
