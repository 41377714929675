import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import { inject, observer } from 'mobx-react';
import Rank from '../../studies/common/rating';
import { COUNTRIES } from '../../studies/common/survey/field/widget/scalar/country';
import Moment from 'moment';
import Download from './download';
import { orderBy } from 'lodash';
import Flag from 'dna/entity/common/flag';
import get from 'lodash/get';

const variants = {
    traditional: 'Traditional',
    passive: 'Passive / ETF',
    equity: 'Private Equity',
    alternative: 'Alternative',
    external: 'External manager',
    delegated: 'Delegated management',
};

const styles = {
    container: {
        padding: '20px',
        height: '200px',
    },
    header: {
        marginBottom: '15px',
    },
    detail: {
        display: 'flex',
        marginTop: '5px',
        color: colors.grey800,
        lineHeight: '18px',
    },
    icon: {
        fill: colors.userCold,
        marginRight: '5px',
        width: '18px',
        height: '18px',
    },
    body: {
        display: 'flex',
        marginBottom: '20px',
        alignItems: 'flex-start',
    },
    props: {
        lineHeight: '24px',
        flexGrow: '1',
    },
    flag: {
        display: 'inline-block',
        marginRight: '5px',
    },
    progress: {
        color: colors.grey500,
        lineHeight: '24px',
        fontSize: '19px',
        fontWeight: 'bold',
    },
};

@inject((stores) => ({ contacts: stores.auth.contacts, session: stores.global.session }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.library.studies.card';

    static propTypes = {
        entity: PropTypes.object,
    };

    render() {
        if (!this.props.entity) {
            return null;
        }

        const payload = this.props.entity.payload || {};
        const status = this.props.entity.status;
        const type = payload.target.type || 'manager';
        const esg = get(this.props.entity.payload, 'header.esg');
        const progress = get(this.props.entity, 'computed.progress.total', 0);

        return (
            <Views.Common.Card style={styles.container}>
                <Views.Common.Header
                    light
                    style={styles.header}
                    icon={<Icon type={type === 'fund' ? 'deeligenz.fund' : 'deeligenz.company'} />}
                    title={
                        <div>
                            <Flag
                                label="A"
                                tooltip="Archive"
                                color="cold"
                                active={this.props.entity.status === 'archive'}
                                style={styles.flag}
                            />
                            <span>{type === 'fund' ? payload.fund : payload.manager}</span>
                        </div>
                    }
                    content={[this.renderDetail1(type, payload), this.renderDetail2(type, payload)]}
                    aside={[
                        ['confirmed', 'ignoredRedFlag', 'archive'].includes(status) ? (
                            <Rank value={payload.rating} esg={esg} />
                        ) : (
                            <span style={styles.progress}>{progress} %</span>
                        ),
                    ]}
                />

                <div style={styles.body}>
                    <div style={styles.props}>
                        Operational Due Diligence
                        <br />
                        {this.renderOwner()}
                        {this.renderStatus()}
                    </div>

                    <Download entity={this.props.entity} />
                </div>
            </Views.Common.Card>
        );
    }

    renderDetail1(type, payload) {
        if (type === 'fund') {
            return (
                <div style={styles.detail}>Fund type: {variants[payload.target.variant] || payload.target.variant}</div>
            );
        }

        if (type === 'manager') {
            return (
                <div style={styles.detail}>
                    Manager type: {variants[payload.target.variant] || payload.target.variant}
                </div>
            );
        }

        return null;
    }

    renderDetail2(type, payload) {
        if (type === 'fund') {
            return (
                <div style={styles.detail}>
                    <Icon type="deeligenz.company" style={styles.icon} />
                    <span>{payload.manager || '(Management company unknown)'}</span>
                </div>
            );
        }

        if (type === 'manager') {
            return (
                <div style={styles.detail}>
                    <img
                        style={{ height: '12px', width: '16px', float: 'left', margin: '3px 6px 0 0' }}
                        src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/${(
                            payload.country || ''
                        ).toLowerCase()}.svg`}
                    />
                    <span>{COUNTRIES[payload.country] || ''}</span>
                </div>
            );
        }

        return null;
    }

    renderOwner() {
        if (this.props.entity.status === 'archive') {
            return null;
        }

        const contacts = this.props.contacts;
        const owner = contacts && contacts.users ? contacts.users[this.props.entity.ownerUid] : undefined;

        if (!owner) {
            return null;
        }

        return (
            <>
                By {owner.firstName + ' ' + owner.lastName}
                <br />
            </>
        );
    }

    renderStatus() {
        if (this.props.entity.status === 'archive') {
            return (
                <>
                    Archive
                    <br />
                </>
            );
        }

        const history = this.props.entity.history || [];
        const deadline = get(this.props.entity, 'computed.deadline');

        const starts = orderBy(
            history.filter((e) => e.transition === 'start'),
            'createStamp',
            'asc',
        );

        const validations = orderBy(
            history.filter((e) => e.transition === 'confirmReport'),
            'createStamp',
            'desc',
        );

        if (validations.length) {
            return (
                <>
                    Validated on {Moment(validations[0].createStamp).format('LL')}
                    <br />
                </>
            );
        }

        if (starts.length) {
            return (
                <>
                    {Moment(starts[0].createStamp).format('LL')}
                    {deadline ? (
                        <span>
                            &nbsp;→&nbsp;
                            {Moment(deadline).format('LL')}
                        </span>
                    ) : null}
                    <br />
                </>
            );
        }

        return (
            <>
                Still in draft status
                <br />
            </>
        );
    }
}
