// @flow

import type { LinkData, LinkConnection } from '@deecision/deefind-types/model';

export function reverseLink(link: LinkData): LinkData {
    return {
        positions: link.mandates,
        mandates: link.positions,
        shareholders: link.stocks,
        stocks: link.shareholders,
        connections: link.connections ? link.connections.map(reverseConnection) : undefined,
    };
}

export function reverseConnection(connection: LinkConnection): LinkConnection {
    const reversed = Object.assign({}, connection);

    if (connection.nature === 'position') {
        reversed.nature = 'mandate';
    }

    if (connection.nature === 'mandate') {
        reversed.nature = 'position';
    }

    if (connection.family === 'ancestor') {
        reversed.family = 'descendant';
    }

    if (connection.family === 'descendant') {
        reversed.family = 'ancestor';
    }

    if (connection.family === 'parent') {
        reversed.family = 'child';
    }

    if (connection.family === 'child') {
        reversed.family = 'parent';
    }

    return reversed;
}
