// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'relative',
        height: '34px',
    },
    line: {
        position: 'absolute',
        height: '2px',
        top: '14px',
        opacity: '0.5',
    },
    minLine: {
        left: '0',
        background: colors.userHot,
    },
    maxLine: {
        right: '0',
        background: colors.userGreen,
    },
    handle: {
        width: '58px',
        height: '32px',
        lineHeight: '32px',
        cursor: 'col-resize',
        position: 'absolute',
        top: '0',
        background: `linear-gradient(white, ${colors.grey100})`,
        border: '1px solid ' + colors.grey400,
        borderRadius: '5px',
        textAlign: 'center',
        color: colors.userCold,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 5px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    },
    highlighted: {
        background: `white`,
        border: '1px solid ' + colors.userCold,
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.filters.slider.body';

    static propTypes = {
        position: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        onEnd: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    state = { hover: false, moving: false, offset: 0 };

    containerNode: Object;
    mouseUpListener: Function;
    mouseMoveListener: Function;

    componentDidMount() {
        this.mouseUpListener = (event: Object) => {
            this.setState({ moving: false });
            this.props.onEnd();

            if (document.body && this.mouseMoveListener) {
                document.body.removeEventListener('mousemove', this.mouseMoveListener);
            }
        };

        if (document.body) {
            document.body.addEventListener('mouseup', this.mouseUpListener);
        }
    }

    componentWillUnmount() {
        if (document.body) {
            document.body.removeEventListener('mouseup', this.mouseUpListener);
        }
    }

    render() {
        const position = this.props.position * 100 + '%';

        return (
            <div ref={node => (this.containerNode = node)} style={styles.container}>
                <div style={Object.assign({}, styles.line, styles.minLine, { width: position })} />
                <div style={Object.assign({}, styles.line, styles.maxLine, { left: position })} />

                <div
                    style={Object.assign(
                        {},
                        styles.handle,
                        this.state.moving ? { background: 'white' } : {},
                        this.state.hover || this.state.moving ? { border: '1px solid ' + colors.userCold } : {},
                        { left: `calc(${position} - 30px)` },
                    )}
                    onMouseEnter={this.props.disabled ? undefined : () => this.setState({ hover: true })}
                    onMouseLeave={this.props.disabled ? undefined : () => this.setState({ hover: false })}
                    onMouseDown={
                        this.props.disabled
                            ? undefined
                            : event => {
                                  this.mouseMoveListener = (event: Object) => {
                                      const diff = (event.clientX - this.state.offset) / this.containerNode.clientWidth;
                                      this.state.offset = event.clientX;
                                      this.props.onChange(this.props.position + diff);
                                  };

                                  if (document.body) {
                                      document.body.addEventListener('mousemove', this.mouseMoveListener);
                                  }

                                  this.setState({ moving: true, offset: event.clientX });

                                  event.preventDefault();
                                  event.stopPropagation();
                                  return false;
                              }
                    }
                >
                    {this.props.value}
                </div>
            </div>
        );
    }
}
