// @flow

import type {
    DealDetails,
    DealStakeholder,
    EntityAnyTarget,
    EntityInnerTarget,
    EntityMultiTarget,
} from '@deecision/deefind-types/model';

import slugify from 'speakingurl';
import { makeDealsInnerTarget, makeDealsMultiTarget, mergeDealsMultiTargets } from './targets';
import { makeDealsSum } from './misc';

export function makeDealsStakeholders(
    details: { [id: string]: DealDetails },
    omit: string[],
): { [key: string]: DealStakeholder } {
    const innerTargets: { [id: string]: EntityInnerTarget } = {};
    const innerDeals: { [id: string]: string[] } = {};
    const multiTargets: { [name: string]: EntityMultiTarget[] } = {};
    const multiDeals: { [name: string]: string[] } = {};

    for (const id of Object.keys(details)) {
        const deal = details[id];

        const targets: EntityAnyTarget[] = (deal.acquirors || [])
            .concat(deal.vendors || [])
            .concat(deal.targets || [])
            .concat(deal.regulators || []);

        for (const target of targets) {
            const inner = makeDealsInnerTarget(target);

            if (inner) {
                if (omit.includes(inner.id)) {
                    continue;
                }

                innerTargets[inner.id] = inner;
                if (!innerDeals[inner.id]) innerDeals[inner.id] = [];
                innerDeals[inner.id].push(id);
                continue;
            }

            const multi = makeDealsMultiTarget(target);

            if (multi) {
                const name = slugify(multi.name);
                if (!multiTargets[name]) multiTargets[name] = [];
                if (!multiDeals[name]) multiDeals[name] = [];
                multiTargets[name].push(multi);
                multiDeals[name].push(id);
            }
        }
    }

    const stakeholders: { [key: string]: DealStakeholder } = {};

    for (const id of Object.keys(innerTargets)) {
        const target: EntityInnerTarget = innerTargets[id];
        const deals: string[] = innerDeals[id] || [];

        if (target && deals.length) {
            stakeholders[id] = { target, deals, value: makeDealsSum(details, deals) };
        }
    }

    for (const name of Object.keys(multiTargets)) {
        const target = mergeDealsMultiTargets(multiTargets[name]);
        const deals: string[] = multiDeals[name] || [];

        if (target && deals.length) {
            stakeholders[name] = { target, deals, value: makeDealsSum(details, deals) };
        }
    }

    return stakeholders;
}
