import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Members from './members';
import Planning from './planning';
import Report from './report';
import Attendees from '../../common/attendees';
import get from 'lodash/get';
import { toJS } from 'mobx';
import Moment from 'moment';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Form from 'app/views/form/container';
import Button from 'app/views/common/button';

const START_CHOICES = {
    '8:30': '8:30',
    '9:00': '9:00',
    '9:30': '9:30',
    '10:00': '10:00',
    '10:30': '10:30',
    '11:00': '11:00',
    '11:30': '11:30',
    '12:00': '12:00',
    '12:30': '12:30',
    '13:00': '13:00',
    '13:30': '13:30',
    '14:00': '14:00',
    '14:30': '14:30',
    '15:00': '15:00',
};

const MEETING_TYPES = {
    'visioConference': 'Visio conference',
    'externalOffice': 'External office',
    'internalOffice': 'AAIS Offices',
    'phoneCall': 'Phone call'
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    title: {
        fontSize: '19px',
    },
    preview: {
        margin: '20px auto',
        display: 'block',
        width: '200px',
    },
    content: {
        flexGrow: '1',
        marginLeft: '40px',
    },
    steps: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '40px',
        paddingTop: '25px',
    },
    step: {
        lineHeight: '30px',
        fontSize: '19px',
        paddingRight: '10px',
    },
    stepIndex: {
        display: 'inline-block',
        color: 'white',
        borderRadius: '50%',
        width: '30px',
        textAlign: 'center',
        marginRight: '15px',
    },
    stepLabel: {
        paddingBottom: '3px',
        borderBottom: '2px solid transparent',
    },
    status: {
        margin: '60px auto',
        width: '690px',
        padding: '20px',
        background: colors.grey100,
        border: '1px solid ' + colors.grey300,
        fontSize: '19px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.agenda';

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        entity: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        onUpload: PropTypes.func.isRequired,
        onFetchUpload: PropTypes.func.isRequired,
    };

    state = { cursor: null, hover: null, meetingType: (toJS(this.props.entity.payload.onSite) || {}).type, lock: (toJS(this.props.entity.payload.onSite) || {}).lock};

    render() {
        const data = toJS(this.props.entity.payload.onSite || {});

        let max = 1;

        if (data.proposedDates && data.proposedDates.length) {
            max = 1;
        }

        if (data.finalDate) {
            max = 2;
        }

        if (data.finalDate && this.state.lock === true) {
            max = 3;
        }

        const cursor = this.state.cursor > 0 ? Math.min(this.state.cursor, max) : max;

        return (
            <div style={styles.container}>
                <div>
                    <Members
                        readOnly={this.props.readOnly}
                        ids={data.members}
                        onChange={members => this.props.onChange(Object.assign({}, data, { members }))}
                    />
                    {this.renderDetails()}
                </div>

                <div style={styles.content}>
                    <div style={styles.steps}>
                        {this.renderStep(cursor, max, 1, 'Schedule (Due diligence team)')}
                        {this.renderStep(cursor, max, 2, 'Agenda (External manager)')}
                        {this.renderStep(cursor, max, 3, 'Outcome (Due diligence team)')}
                    </div>

                    {this.renderContent(cursor)}
                </div>
            </div>
        );
    }

    renderStep(cursor: number, max: number, index: number, label: string) {
        const active = index <= max;
        const current = index === cursor;

        const stepStyle = Object.assign({}, styles.step, {
            color: active ? 'black' : colors.grey400,
            cursor: active ? 'pointer' : 'default',
        });

        const indexStyle = Object.assign({}, styles.stepIndex, {
            background: current ? colors.userHot : active ? colors.userCold : colors.grey300,
        });

        const labelStyle = Object.assign({}, styles.stepLabel, {
            borderBottom:
                '2px solid ' +
                (current ? colors.userHot : this.state.hover === index ? colors.userCold : 'transparent'),
        });

        return (
            <span
                style={stepStyle}
                onMouseEnter={active ? event => this.setState({ hover: index }) : null}
                onMouseLeave={active ? event => this.setState({ hover: null }) : null}
                onClick={active ? event => this.setState({ cursor: index }) : null}
            >
                <span style={indexStyle}>{index}</span>
                <span style={labelStyle}>{label}</span>
            </span>
        );
    }

    renderContent(cursor: number) {
        const data = toJS(this.props.entity.payload.onSite || {});

        switch (cursor) {
            case 1:
                return (
                    <div>
                    <Form
                        key="edit"
                        ref="form"
                        data={data}
                        onChange={() => {
                            if (this.state.lock === true) {
                                this.refs.form.setData(data);
                                return;
                            }
                            this.setState({ changed: true, meetingType: this.refs.form.getData().type });
                        }}
                    >
                        <Views.Form.Row>
                            <Views.Form.Field readOnly={this.props.readOnly} label="Type of meeting" path="type" size={1} widget={{ type: 'select', choices: MEETING_TYPES }} required />
                            <Views.Form.Field
                                readOnly={this.props.readOnly}
                                label="Start time"
                                path="startTime"
                                size={1}
                                widget={{ type: 'select', choices: START_CHOICES }}
                                required
                            />
                        </Views.Form.Row>

                        { this.state.meetingType === 'visioConference' ? <Views.Form.Row><Views.Form.Field readOnly={this.props.readOnly} label="Link" path="weblink" size={3} widget={{ type: 'string' }} required /></Views.Form.Row> : null}
                        { this.state.meetingType === 'phoneCall' ? <Views.Form.Row><Views.Form.Field readOnly={this.props.readOnly} label="Phone number" path="phoneNumber" size={1} widget={{ type: 'string' }} required /></Views.Form.Row> : null}
                        { this.state.meetingType === 'internalOffice' || this.state.meetingType === 'externalOffice' ? [
                            <Views.Form.Row>
                                <Views.Form.Field readOnly={this.props.readOnly} key="address" label="Address" path="address" size={3} widget={{ type: 'string' }} required />
                                <Views.Form.Field readOnly={this.props.readOnly} key="city" label="City" path="city" size={3} widget={{ type: 'string' }} required />
                            </Views.Form.Row>,
                            <Views.Form.Row>
                                <Views.Form.Field readOnly={this.props.readOnly} key="addressComplement" label="Address complement" path="addressComplement" size={3} widget={{ type: 'text' }} />
                            </Views.Form.Row>,
                        ] : null}
                        <div>
                            {!this.state.changed || this.props.readOnly
                                ? null
                                : [
                                      <Button
                                          key="submit"
                                          type="raised"
                                          style={styles.submit}
                                          color="cold"
                                          icon={<Icon type="do.submit" />}
                                          label="Submit"
                                          onClick={() => {
                                              this.props.onChange(this.refs.form.getData());
                                              this.setState({ changed: false });
                                          }}
                                      />,
                                      <Button
                                          key="cancel"
                                          type="raised"
                                          style={styles.submit}
                                          color="hot"
                                          icon={<Icon type="do.cancel" />}
                                          label="Cancel"
                                          onClick={() => {
                                              this.refs.form.setData(data);
                                              this.setState({ changed: false, meetingType: data.type });
                                          }}
                                      />,
                                  ]}
                        </div>
                    </Form>
                    <Planning
                        date={data.finalDate}
                        lock={this.state.lock}
                        dates={this.props.entity.payload.onSite.proposedDates}
                        onChange={dates => this.props.onChange(Object.assign({}, data, { proposedDates: dates }))}
                    />
                    </div>
                );

            case 2:
                return <Attendees external={false} entity={this.props.entity} onChange={this.props.onChange} />;

            case 3:
                return (
                    <Report
                        data={this.props.entity.payload.onSite}
                        onChange={this.props.onChange}
                        onUpload={this.props.onUpload}
                        onFetchUpload={this.props.onFetchUpload}
                    />
                );
        }
    }

    renderDetails() {
        const data = this.props.entity.payload.onSite || {};

        if (data.finalDate) {
            return (
                <div>
                    <div style={{ margin: '15px 0' }}>
                        <span style={{ fontWeight: 'bold', color: colors.userCold }}>Final date:</span>
                        &nbsp;&nbsp;
                        {Moment(data.finalDate).format('LL')}
                    </div>
                    <div style={{ margin: '15px 0' }}>
                        <span style={{ fontWeight: 'bold', color: colors.userCold }}>Start time:</span>
                        &nbsp;&nbsp;
                        {data.startTime || '9:00'}
                    </div>
                    <div style={{ margin: '15px 0' }}>
                    {data.type !== 'visioConference' ? null : this.renderMeetingVisio(data)}
                    {data.type !== 'externalOffice' ? null : this.renderMeetingOffice(data)}
                    {data.type !== 'internalOffice' ? null : this.renderMeetingOffice(data)}
                    {data.type !== 'phoneCall' ? null : this.renderMeetingCall(data)}
                    </div>
                    <div style={{ margin: '15px 0' }}>
                    {
                      this.state.lock
                      ?
                      <Views.Common.Button icon={<Icon type="base.lock" color="white" />} label="Unlock Schedule" onClick={() => {
                          this.setState({lock: false});
                          this.props.onChange(Object.assign({}, data, {lock: false}))
                      }} />
                      :
                      <Views.Common.Button icon={<Icon type="base.lockOpen" color="white" />} label="Lock Schedule" onClick={() => {
                        this.setState({lock: true});
                        this.props.onChange(Object.assign({}, data, {lock: true}))
                      }} />
                    }
                    </div>

                </div>
            );
        }

        if (data.proposedDates && data.proposedDates.length) {
            return (
                <div style={{ margin: '15px 0' }}>
                    <span style={{ fontWeight: 'bold', color: colors.userCold }}>Proposed dates:</span>
                    <br/>
                    {data.proposedDates.map((date, index) => (
                        <div key={index} style={{ margin: '15px' }}>{Moment(date).format('LL')}</div>
                    ))}
                </div>
            );
        }
    }

    renderMeetingVisio(data) {
        return (<div>
            <span style={{ fontWeight: 'bold', color: colors.userCold }}>Web Link:</span>
            &nbsp;&nbsp;
            {data.weblink || 'Not set'}
        </div>)
    }

    renderMeetingOffice(data) {
        return (<div>
            <span style={{ fontWeight: 'bold', color: colors.userCold }}>Address:</span>
            &nbsp;&nbsp;
            {data.address || 'Not set'}
            <br/>
            <span style={{ fontWeight: 'bold', color: colors.userCold }}>City:</span>
            &nbsp;&nbsp;
            {data.city || 'Not set'}
            <br/>
            <span style={{ fontWeight: 'bold', color: colors.userCold }}>Complement:</span>
            &nbsp;&nbsp;
            <span style={{whiteSpace: 'pre'}}>{data.addressComplement || 'Not set'}</span>
        </div>)
    }

    renderMeetingCall(data) {
        return (<div>
            <span style={{ fontWeight: 'bold', color: colors.userCold }}>Phone number:</span>
            &nbsp;&nbsp;
            {data.phoneNumber || 'Not set'}
        </div>)
    }
}
