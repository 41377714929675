import _ from 'lodash';
import Fuse from 'fuse.js';

function getExtra (id, extra) {
    const result = {};

    for (const name of Object.keys(extra || {})) {
        if (extra[name] && extra[name][id]) {
            result[name] = extra[name][id];
        }
    }

    return result;
}

function applyGroups (entities, specs, value, extra) {
    _.each(specs, spec => {
        spec.count = _.filter(entities, entity => spec.filter(entity, getExtra(entity.id, extra))).length;
    });

    if (! value)
        return entities;

    return specs[ value ]
        ? _.filter(entities, entity => specs[ value ].filter(entity, getExtra(entity.id, extra)))
        : entities;
}

function applyFuse (entities, specs, value) {
    if (! value)
        return entities;

    const fuse = new Fuse(entities, {
        caseSensitive: false,
        includeScore: false,
        shouldSort: false,
        threshold: _.get(specs, 'threshold', 0.4),
        ignoreLocation: true,
        minMatchCharLength: 2,
        maxPatternLength: 32,
        keys: specs.paths
    });

    return fuse.search(value);
}

function applySort (entities, specs, value, extra) {
    value = value || ('-' + _.first(_.keys(specs)));
    const spec = value.substr(1);

    if (! specs[ spec ])
        return entities;

    const access = _.isFunction(specs[ spec ].path)
        ? entity => specs[ spec ].path(entity, getExtra(entity.id, extra))
        : entity => _.get(entity, specs[ spec ].path);

    try {
        return _.orderBy(entities, access, value.substr(0, 1) === '-' ? 'desc' : 'asc');
    } catch (error) {
        console.error(error);
        return entities;
    }
}

export default {
    reduceEntities (entities, reducers, extra) {
        entities = entities.filter(entity => !! entity);

        let accumulatedGroup;

        reducers.forEach((reducer, index) => {
            switch (reducer.type) {
                case 'fuse':
                    entities = applyFuse(entities, reducer.specs, reducer.value, extra);
                    break;

                case 'sort':
                case 'sort_table':
                    entities = applySort(entities, reducer.specs, reducer.value, extra);
                    break;

                case 'groups':
                    if (reducers[index + 1] && reducers[index + 1].name === reducer.name) {
                        accumulatedGroup = Object.assign({}, accumulatedGroup || {}, reducer.specs);
                        return;
                    }

                    entities = applyGroups(entities, Object.assign({}, accumulatedGroup || {}, reducer.specs), reducer.value, extra);
                    accumulatedGroup = undefined;
            }
        });

        if (accumulatedGroup) {
            entities = applyGroups(entities, accumulatedGroup, lastGroupValue, extra);
        }

        return entities;
    }
};
