// @flow

import type { ResultType, CountsFilters, CountsType, SegmentAxes, ExportType } from '../types';

import { makeDirect, makeNetwork, makePredict, makeUpsell, makeCovid19, makeAugmented, makeNetworkPredict } from './make';

export function coopt(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    return makeNetwork(results, filters, axes, source);
}

export function predict(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    if ( source === 'person'){
        return makePredict(results, filters, axes, source);
    }
    return makeNetworkPredict(results, filters, axes, source);
}

export function family(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person',
): { counts: CountsType, exports: ExportType } {
    return makeNetwork(results, filters, axes, source);
}

export function segment(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    return makeDirect(results, filters, axes, source);
}

export function augmentedSegment(
    results: ResultType[],
    filters: CountsFilters,
    thresholds: Array<{ label: string, code: string, min: number, max: number }>,
    bankTypes: Array<{ label: string, order: number }>,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    return makeAugmented(results, filters, thresholds, bankTypes, source);
}

export function upsell(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    return makeUpsell(results, filters, axes, source);
}

export function covid19(
    results: ResultType[],
    filters: CountsFilters,
    axes: SegmentAxes,
    source: 'person' | 'company',
): { counts: CountsType, exports: ExportType } {
    return makeCovid19(results, filters, axes, source);
}