// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import { inject, observer } from 'mobx-react';

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.download';

    static propTypes = {
        reportName: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        reportType: PropTypes.string.isRequired,
    };

    state = { loading: false };

    render() {
        return [
            <Views.Common.Button
                key={this.props.reportType}
                label={this.state.loading ? `Building ${this.props.reportName}` : `Export ${this.props.reportName}`}
                disabled={this.state.loading}
                onClick={() => {
                    this.setState({ loading: true }, async () => {
                        const filename = `${this.props.reportType}-report.pdf`;
                        if (this.props.reportType === 'summary') {
                            await this.props.store.downloadSummaryReport(this.props.id, filename);
                            this.setState({ loading: false });
                        } else if (this.props.reportType === 'full') {
                            await this.props.store.downloadFullReport(this.props.id, filename);
                            this.setState({ loading: false });
                        }
                    });
                }}
                icon={<Icon style={{ width: '30px', height: '30px' }} type="do.pdf" />}
            />,
        ];
    }
}
