// @flow

import type { LinkProfile, StockLinks, StockLink, LinkStock } from '@deecision/deefind-types/model';
import { getPredictStockExit } from './prediction';

export default function makeStockLinks(links: LinkProfile[]): StockLinks {
    const children = [];
    const parents = [];

    for (const link of links) {
        const entity = link.target ? link.target.id : undefined;

        if (link.owned && link.owned.direct) {
            children.push(resolveStockLink(entity, link.owned, link.target.funded));
        }

        if (link.shared && link.shared.direct) {
            parents.push(resolveStockLink(entity, link.shared, link.target.funded));
        }
    }

    return { children, parents };
}

function resolveStockLink(entity?: string, stock: LinkStock, funded?: boolean | number): StockLink {
    if (typeof funded === 'number') {
        return Object.assign({}, stock, { entity, exit: getPredictStockExit(funded) });
    }

    return Object.assign({}, stock, { entity });
}
