// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {},
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.inputs.jobs';

    static propTypes = {
    };

    render() {
        return <div style={styles.container} />;
    }
}
