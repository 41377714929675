import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import Sum from 'dna/entity/common/display/sum';
import t from 'app/translation';
import { get } from 'lodash';

import VigilanceButton from 'dna/entity/common/vigilance/button';
import ComplianceButton from 'dna/entity/common/compliance/button';
import Reech from 'dna/entity/common/reech';

const styles = {
    wrapper: {
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '3px',
    },
    rule: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '150px',
        background: `linear-gradient(to right, ${colors.userGreen}, ${colors.userGreen}, ${colors.userGreen}, ${colors.userGreen}, ${colors.userWarning}, ${colors.userWarning}, ${colors.userHot}, ${colors.userHot}, ${colors.userHot})`,
    },
    score: {
        position: 'absolute',
        top: '0',
        right: '5px',
        color: 'white',
    },
    empty: {
        width: '30px',
        borderRadius: '3px',
        background: colors.grey500,
        color: 'white',
        textAlign: 'center',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.cockpit.preesm';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div
                style={{
                    marginTop: '5px',
                    marginRight: '5px',
                    marginLeft: '5px',
                    border: '2px solid ' + colors.grey400,
                }}
            >
                {this.renderHeader()}
                <div>
                    <div style={{ padding: '5px', borderBottom: '1px solid ' + colors.grey300 }}>
                        <div
                            style={{
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                width: '10%',
                                textAlign: 'left',
                            }}
                        >
                            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="base.eye" />
                        </div>
                        <div
                            style={{
                                fontSize: '17px',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                width: '40%',
                                textAlign: 'left',
                                color: colors.userCold,
                            }}
                        >
                            Veegilenz
                        </div>
                    </div>
                    <div>{this.renderVeegilenz()}</div>
                </div>
                <div>
                    <div
                        style={{
                            padding: '5px',
                            borderTop: '1px solid ' + colors.grey300,
                            borderBottom: '1px solid ' + colors.grey300,
                        }}
                    >
                        <div
                            style={{
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                width: '10%',
                                textAlign: 'left',
                            }}
                        >
                            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="deetect.main" />
                        </div>
                        <div
                            style={{
                                fontSize: '17px',
                                verticalAlign: 'middle',
                                display: 'inline-block',
                                width: '40%',
                                textAlign: 'left',
                                color: colors.userCold,
                            }}
                        >
                            Deetect
                        </div>
                    </div>
                    <div>{this.renderDeetect()}</div>
                </div>
                {/* <div>
                  <div style={{ padding: '5px', borderTop: '1px solid ' + colors.grey300, borderBottom: '1px solid ' + colors.grey300}}>
                    <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%',textAlign: 'left' }}>
                      <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.reech.main" />
                    </div>
                    <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '40%',textAlign: 'left', color: colors.userCold }}>
                      Reech
                    </div>
                  </div>
                  <div>
                    { this.renderReech() }
                  </div>
                </div> */}
            </div>
        );
    }

    renderHeader() {
        return (
            <div style={{ backgroundColor: colors.grey200, padding: '5px' }}>
                <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%', textAlign: 'left' }}>
                    <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.preesm" />
                </div>
                <div
                    style={{
                        fontSize: '17px',
                        verticalAlign: 'middle',
                        display: 'inline-block',
                        width: '40%',
                        textAlign: 'left',
                        color: colors.userCold,
                    }}
                >
                    Preesm
                </div>
            </div>
        );
    }

    renderVeegilenz() {
        const score = this.props.network.vigilance.score;
        if (score < 1) {
            return <div style={styles.empty}>0</div>;
        }

        const ruleWidth = 150;
        const width = ruleWidth * (score / 4) + 'px';

        return (
            <div style={{ margin: '10px', textAlign: 'center' }}>
                <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '50%', textAlign: 'center' }}>
                    <div style={Object.assign({}, styles.wrapper, { margin: 'auto', width, lineHeight: '20px' })}>
                        &nbsp;
                        <div style={Object.assign({}, styles.rule, { width: ruleWidth + 'px' })}>&nbsp;</div>
                        <div style={Object.assign({}, styles.score, { right: '5px' })}>{(score || 0).toFixed(2)}</div>
                    </div>
                </div>

                <div
                    style={{
                        verticalAlign: 'middle',
                        margin: 'auto',
                        display: 'inline-block',
                        width: '50%',
                        textAlign: 'center',
                    }}
                >
                    <VigilanceButton entity={this.props.entity} network={this.props.network} style={styles.button} />

                    <ComplianceButton
                        entity={this.props.entity}
                        network={this.props.network}
                        style={styles.button}
                        mini
                    />

                    <Reech entity={this.props.entity} style={styles.button} />
                </div>
            </div>
        );
    }

    renderDeetect() {
        return (
            <div>
                <div style={{ display: 'inline-block', width: '50%' }}>
                    <Views.Common.Properties
                        style={{ padding: '5px' }}
                        values={[
                            {
                                label: t('dna.deefind.veegilenzData.person.sumValuationLabel'),
                                value: <Sum data={get(this.props.network, 'computed.sums.companies.valuation')} />,
                            },
                        ]}
                        fallback="No known financial values"
                        align={90}
                    />
                </div>

                <div style={{ display: 'inline-block', width: '50%' }}>
                    <Views.Common.Properties
                        style={{ padding: '5px' }}
                        values={[
                            {
                                label: t('common.label.weasse'),
                                value: <Sum data={get(this.props.network, 'computed.weasse.mid')} />,
                            },
                        ]}
                        fallback="No known financial values"
                        align={90}
                    />
                </div>
            </div>
        );
    }

    renderReech() {
        return <span>TEST</span>;
    }
}
