// @flow

import PropTypes from 'prop-types';

import React from 'react';
import colors from 'app/theme/colors';

import Main from './main';
import Option from './option';
import Typologies from './typologies';
import t from 'app/translation';

const ageOptions = [
    { value: 5, label: t('dna.deefind.person.yearsOptions', { value: '5' }) },
    { value: 10, label: t('dna.deefind.person.yearsOptions', { value: '10' }) },
    { value: 15, label: t('dna.deefind.person.yearsOptions', { value: '15' }) },
];

const genderOptions = [
    { value: 'any', label: t('dna.deefind.person.genderOptions.any') },
    { value: 'male', label: t('dna.deefind.person.genderOptions.male') },
    { value: 'female', label: t('dna.deefind.person.genderOptions.female') },
];

const styles = {
    container: {
        margin: '0 20px',
        flexGrow: '1',
    },
    extra: {
        margin: '10px 0 0 10px',
        height: '40px',
    },
    reset: {
        textDecoration: 'underline',
        color: colors.userHot,
        lineHeight: '30px',
        cursor: 'pointer',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.form.criteria';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
        submitSearch: PropTypes.func.isRequired,
    };

    props: {
        state: Object,
    };

    static defaultProps = {
        state: {},
    };

    render() {
        if (this.props.state.form.kind === 'typology')
            return (
                <div style={styles.container}>
                    <Typologies
                        status={this.props.state.status || 'pending'}
                        current={
                            typeof this.props.state.form.typology != 'undefined'
                                ? this.props.state.form.typology
                                : { provider: 'undefined', version: 0 }
                        }
                        onChange={typology => {
                            this.context.updateState({ form: Object.assign({}, this.props.state.form, { typology }) });
                        }}
                    />
                </div>
            );

        if (['person', 'company'].indexOf(this.props.state.form.kind) < 0)
            return (
                <div style={styles.container}>
                    {t('common.message.invalidSearchType', { value: this.props.state.form.kind })}
                </div>
            );

        return (
            <div style={styles.container}>
                <Main
                    state={this.props.state || {}}
                    onUpdate={update => this.context.updateState(update)}
                    onSubmit={event => this.context.submitSearch()}
                />

                <div style={styles.extra}>{this.renderOptions()}</div>
            </div>
        );
    }

    renderOptions() {
        const form = this.props.state.form || {};

        switch (form.kind) {
            case 'person': {
                return [
                    <Option
                        key="company"
                        label={t('common.label.company')}
                        widget="text"
                        value={form.company}
                        onChange={company => this.context.updateState({ 'form.company': company })}
                        values={form.companies || []}
                    />,
                    <Option
                        key="age"
                        label={t('dna.deefind.person.ageLabel')}
                        widget="range"
                        options={ageOptions}
                        value={form.age}
                        onChange={age => this.context.updateState({ 'form.age': age })}
                    />,
                    <Option
                        key="gender"
                        label={t('dna.deefind.person.genderLabel')}
                        widget="select"
                        options={genderOptions}
                        value={form.gender}
                        onChange={gender => this.context.updateState({ 'form.gender': gender })}
                    />,
                ];
            }

            case 'company':
                const activityOptions = [{ value: '', label: t('dna.deefind.company.activityOptions.any') }];

                for (const activity of form.activities || []) {
                    activityOptions.push({
                        value: activity,
                        label: t('common.activity.' + activity.toUpperCase()),
                    });
                }

                return [
                    <Option
                        key="person"
                        label={t('dna.deefind.company.contactLabel')}
                        widget="text"
                        value={form.person}
                        onChange={person => this.context.updateState({ 'form.person': person })}
                        values={form.persons || []}
                    />,
                    <Option
                        key="activity"
                        label={t('common.label.activity')}
                        widget="select"
                        options={activityOptions}
                        value={form.activity}
                        onChange={activity => this.context.updateState({ 'form.activity': activity })}
                    />,
                ];

            default:
                return null;
        }
    }
}
