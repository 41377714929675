// @flow

import type { LinkProfile } from '@deecision/deefind-types/model';

export const company = [
    (profile: LinkProfile) => (profile.target.flags || []).includes('listed') ? 1 : 0,
    valuateLevel,
    (profile: LinkProfile) => profile.target.turnover || 0,
    (profile: LinkProfile) => profile.target.links || 0,
];

export const person = [
    (profile: LinkProfile) => (profile.target.flags || []).includes('fortune') ? 1 : 0,
    valuateLevel,
    // (profile: LinkProfile) => profile.target.turnoversSum,
    (profile: LinkProfile) => profile.target.links || 0,
];

function valuateLevel(profile: LinkProfile): number {
    const levels = (profile.mandates || []).concat(profile.positions || []).map(mandate => mandate.level);

    if (levels.includes('i') || levels.includes('s')) {
        return 2;
    }

    if (levels.includes('b')) {
        return 1;
    }

    if (levels.includes('x')) {
        return 0.5;
    }

    return 0;
}
