import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Colors from 'app/theme/colors';
import Type from './composer/type';
import Editor from './composer/editor';
import Actions from './composer/actions';

import TYPES from './message/types';

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        padding: '10px 10px 7px 0',
        width: '730px',
    },
    altContainer: {
        background: Colors.grey100,
    },
    form: {
        marginLeft: '10px',
        flexGrow: '0',
    },
    actions: {
        margin: '5px',
        textAlign: 'right',
    },
    cancel: {
        marginRight: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'app.views.discussion.thread.composer';

    static propTypes = {
        entity: PropTypes.object,
        types: PropTypes.arrayOf(PropTypes.oneOf(_.keys(TYPES))).isRequired,
        parent: PropTypes.string,
        target: PropTypes.string,
        style: PropTypes.object,
        onClose: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            type: props.entity ? props.entity.type : _.first(props.types),
            content: props.entity ? props.entity.content : '',
        };
    }

    handleSubmit(event) {
        const data = {
            type: this.state.type,
            content: this.state.content,
        };

        this.props.handleSubmit(data, this.props.parent);
        this.setState({ content: '' });
    }

    render() {
        const alt = false;
        const style = _.extend({}, styles.container, alt ? styles.altContainer : {}, this.props.style);

        return (
            <div style={style}>
                <Type
                    types={this.props.types}
                    value={this.state.type}
                    onChange={type => this.setState({ type: type })}
                />

                <div style={styles.form}>
                    <Editor
                        ref="editor"
                        team={this.props.team}
                        type={this.state.type}
                        content={this.state.content}
                        onContentChange={content => this.setState({ content: content })}
                    />

                    <Actions
                        edition={!!this.props.entity}
                        onSubmit={this.handleSubmit.bind(this)}
                        onClose={this.props.onClose}
                    />
                </div>
            </div>
        );
    }
}
