import React from 'react';
import Application from 'app/layout/application';

import Management from './management/index';
import Prospects from './prospects/index';
import t from 'app/translation';
import { inject, observer } from 'mobx-react';

@inject('global')
@observer
export default class extends React.PureComponent<any, any> {
    static displayName = 'comeet';

    render() {
        const flags = this.props.global.flags.get();

        return (
            <Application
                path="comeet"
                title="DNA Deel"
                index="prospects"
                entries={[
                    {
                        path: 'management',
                        icon: 'main.dashboard',
                        label: t('dna.comeet.management'),
                        screen: Management,
                        access: flags.includes('comeet.access'),
                    },
                    {
                        path: 'prospects',
                        icon: 'comeet.prospect',
                        label: t('dna.comeet.prospects'),
                        screen: Prospects,
                        access: flags.includes('comeet.access'),
                    },
                    {
                        icon: 'comeet.customer',
                        label: t('dna.comeet.customers'),
                        access: flags.includes('comeet.access'),
                    },
                ]}
            />
        );
    }
}
