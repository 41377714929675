// @flow

import React from 'react';
import PropTypes from 'prop-types';
import BarChart from './charts/barChart';
import BarChartStacked from './charts/barChartStacked';
import PieChart from './charts/pieChart';
import BarChartDiff from './charts/barChartDiff';
import Matrix from './matrix';
import List from '../work/results/list';
import colors from 'app/theme/colors';

const styles = {
    container: {
        maxWidth: '1150px',
        marginLeft: '50px',
        display: 'flex',
        alignItems: 'space-between',
        flexWrap: 'wrap',
        marginTop: '100px',
    },
    row1: {
        marginBottom: '100px',
    },
    inputs: {
        width: '250px',
    },
    containerMatrix: {
        position: 'relative',
        paddingTop: '20px',
        width: '600px',
        display: 'inline-block',
    },
    legend: {
        position: 'absolute',
        top: '-10px',
        right: '0',
    },
    cell: {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: '60px',
    },
    select: {
        width: '175px',
        textAlign: 'left',
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        paddingLeft: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.augmented.results';

    static propTypes = {
        counts: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        thresholds: PropTypes.array.isRequired,
        bankTypes: PropTypes.array.isRequired,
        onOpen: PropTypes.func.isRequired,
        style: PropTypes.object,
    };

    open(state: Object) {
        this.refs['list'].open(state);
    }

    render() {
        const thresholds = this.props.thresholds;
        const counts = this.props.counts;
        const bankTypes = this.props.bankTypes;
        const dataReech = [];
        const dataAugmented = [];
        const dataAumOverPotential = [];
        const dataComputedSameColumn = [];
        const dataDiff = [];
        const dataRisk = [counts.risk.risk1, counts.risk.risk2, counts.risk.risk3, counts.risk.risk4];

        for (let i = 0; i < 4; i++) {
            dataReech.push({
                label: thresholds[i].label,
                value: counts.reechColumnsPrct[i],
                text: counts.reechColumns[i],
            });
            dataDiff.push({
                label: thresholds[i].label,
                negative: counts.migrations[i].exitPrct,
                negText: counts.migrations[i].exit,
                positive: counts.migrations[i].entryPrct,
                posText: counts.migrations[i].entry,
            });
            dataAugmented.push({
                label: thresholds[i].label,
                value: counts.computedColumnsPrct[i],
                text: counts.computedColumns[i],
            });
            dataAumOverPotential.push({
                label: thresholds[i].label,
                value: Math.round((counts.aumOverPotential[i] * 100) / counts.total || 0),
                text: counts.aumOverPotential[i],
            });
            dataComputedSameColumn.push({
                label: thresholds[i].label,
                value: Math.round((counts.sameColumn[i] * 100) / counts.total || 0),
                text: counts.sameColumn[i],
            });
        }

        return (
            <div style={Object.assign({}, styles.container, {})}>
                <BarChart
                    key={'reech'}
                    data={dataReech}
                    title={'Segmentation Initiale'}
                    width={380}
                    height={300}
                ></BarChart>
                <BarChartDiff
                    key={'diff'}
                    data={dataDiff}
                    title={'Augmentation'}
                    width={380}
                    height={300}
                ></BarChartDiff>
                <BarChartStacked
                    key={'computed'}
                    dataAumOverPotential={dataAumOverPotential}
                    dataAugmented={dataAugmented}
                    dataComputedSameColumn={dataComputedSameColumn}
                    title={'Segmentation Augmentée'}
                    width={380}
                    height={300}
                ></BarChartStacked>
                <Matrix
                    cells={[
                        this.renderSegment(counts.segment13),
                        this.renderSegment(counts.segment23),
                        this.renderSegment(counts.segment33),

                        this.renderSegment(counts.segment12),
                        this.renderSegment(counts.segment22),
                        this.renderSegment(counts.segment32),

                        this.renderSegment(counts.segment11),
                        this.renderSegment(counts.segment21),
                        this.renderSegment(counts.segment31),
                    ]}
                    xStep1={thresholds[1].label}
                    xStep2={thresholds[2].label}
                    xStep3={thresholds[3].label}
                    yStep1={bankTypes[2].label}
                    yStep2={bankTypes[1].label}
                    yStep3={bankTypes[0].label}
                />
                <List ref="list" />
                <PieChart key={'risk'} data={dataRisk} title={'Risque de conformité'} width={400} height={300}></PieChart>
            </div>
        );
    }

    renderSegment(segment: string[]) {
        const count = segment.length;

        if (!count) {
            return null;
        }
        return (
            <div onClick={() => this.props.onOpen(segment)} style={styles.cell}>
                {count}
            </div>
        );
    }
}
