import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import colors from 'app/theme/colors';
import get from 'lodash/get';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        position: 'relative',
    },
    icon: {
        float: 'left',
        marginRight: '10px',
        fill: colors.userCold,
    },
    type: {
        color: colors.grey500,
        lineHeight: '30px',
    },
    title: {
        fontSize: '17px',
        lineHeight: '40px',
    },
    open: {
        position: 'absolute',
        top: '0',
        right: '0',
    },
    invitation: {
        position: 'absolute',
        top: '0',
        right: '110px',
    },
    questionnaire: {
        position: 'absolute',
        top: '0',
        right: '300px',
    }
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.summary.head';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        onOpen: PropTypes.func.isRequired,
        onOpenInvitation: PropTypes.func,
    };

    handleDownload(filename, data) {
        var element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', data);
        element.setAttribute('target', '_blank');
        element.setAttribute('download', `${filename}.pdf`);
        element.style.display = '';

        element.click();

        document.body.removeChild(element);
    }

    render() {
        let type = 'Investment company due diligence';
        let title = get(this.props.entity, 'payload.manager');
        let icon = 'deeligenz.company';

        if (get(this.props.entity, 'payload.target.type') === 'fund') {
            type = 'Fund due diligence';
            title = get(this.props.entity, 'payload.fund');
            icon = 'deeligenz.fund';
        }

        return (
            <div style={styles.container}>
                <Icon type={icon} style={styles.icon} />
                <span style={styles.type}>{type}</span>
                <div style={styles.title}>{title}</div>

                {!this.props.onOpenInvitation ? null : <Views.Common.Button
                    type="raised"
                    color="none"
                    icon={<Icon type="base.email" />}
                    label="Send invitation"
                    style={styles.invitation}
                    onClick={this.props.onOpenInvitation}
                /> }

                <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.open" />}
                    label="Open"
                    style={styles.open}
                    onClick={this.props.onOpen}
                />

                <Views.Common.Button
                    key="survey"
                    label="Export"
                    onClick={() => {
                        this.props.store.downloadSurveyReport(this.props.entity.id, 'Questionnaire-'+this.props.entity.id+'.pdf');
                    }}
                    icon={<Icon style={{ width: '30px', height: '30px' }} type="do.pdf" />}
                    style={styles.questionnaire}
                />
            </div>
        );
    }
}
