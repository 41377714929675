import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Tabs from 'dna/entity/common/display/tabs';
import { inject, observer } from 'mobx-react';
import t from 'app/translation';

const styles = {
    financialTab: {},
    financialLabel: {
        display: 'inline-block',
        marginRight: '10px',
    },
    dealsTab: {},
    dealsLabel: {
        display: 'inline-block',
        marginRight: '10px',
    },
    financialWrapper: {
        display: 'inline-block',
        marginLeft: '5px',
        width: '32px',
    },
    financialIcon: {
        margin: '-4px 0 -10px 0',
        cursor: 'pointer',
        borderRadius: '3px',
        padding: '3px',
        fill: colors.grey500,
        border: '1px solid transparent',
    },
    financialHover: {
        fill: colors.userCold,
        background: 'white',
        border: '1px solid ' + colors.grey300,
    },
    financialCurrent: {
        fill: colors.userHot,
        background: 'white',
        border: '1px solid ' + colors.grey300,
    },
    dealsHover: {
        fill: colors.userCold,
        background: 'white',
        border: '1px solid ' + colors.grey300,
    },
    dealsCurrent: {
        fill: colors.userHot,
        background: 'white',
        border: '1px solid ' + colors.grey300,
    },
    relationsLabel: {
        color: colors.grey700,
        textTransform: 'uppercase',
    },
    relationsLoading: {
        color: colors.grey500,
    },
    directCount: {
        color: colors.userHot,
    },
    totalCount: {
        color: colors.grey500,
    },
};

@inject('deefind')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.person.display.tabs.header';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object.isRequired,
        entity: PropTypes.object.isRequired,
        results: PropTypes.object,
    };

    state = { financialViewHover: undefined, dealsViewHover: undefined };

    render() {
        const id = this.props.entity.id;

        const network = this.props.deefind.results.get(`network:${id}`);
        const deals = this.props.deefind.results.get(`deals:${id}`);

        return (
            <Tabs
                cursor={this.props.state.tab || 0}
                items={[
                    {
                        icon: 'dna.identity',
                        label: t('dna.deefind.veegilenzData.common.tabTitle.identity'),
                    },
                    {
                        icon: 'dna.financial',
                        label: (
                            <span style={styles.financialTab}>
                                <span style={styles.financialLabel}>{t('common.label.financial')}</span>
                                {this.renderFinancialView('list', 'dna.financial.list', 'Weasse details')}
                                {this.renderFinancialView('tree', 'dna.financial.tree', 'Stocks graph')}
                            </span>
                        ),
                        progress: this.props.deefind.progress.get(`stocks:${id}`),
                        time: this.props.deefind.times.get(`stocks:${id}`),
                    },
                    {
                        icon: 'dna.company',
                        label: this.renderRelationsLabel(
                            'companies',
                            t('common.label.companies'),
                            network ? network.relations.companies || [] : [],
                        ),
                        progress: this.props.deefind.progress.get(`network1:${id}`),
                        time: this.props.deefind.times.get(`network1:${id}`),
                    },
                    {
                        icon: 'dna.relations',
                        label: this.renderRelationsLabel(
                            'persons',
                            t('common.label.relations'),
                            network ? network.relations.persons || [] : [],
                        ),
                        progress: this.props.deefind.progress.get(`network2:${id}`),
                        time: this.props.deefind.times.get(`network2:${id}`),
                    },
                    {
                        icon: 'dna.deals',
                        label: (
                            <span style={styles.dealsTab}>
                                <span style={styles.dealsLabel}>{t('common.label.deals')}</span>
                                {this.renderDealsView('direct', 'dna.financial.list', 'Deals')}
                                {this.renderDealsView('indirect', 'dna.financial.tree', 'Relatives deals')}
                            </span>
                        ),
                        count: deals ? (Object.keys(deals.details).length) : 0,
                        progress: this.props.deefind.progress.get(`deals:${id}`),
                        time: this.props.deefind.times.get(`deals:${id}`),
                    },
                ]}
                onChange={index => this.context.updateState({ tab: index })}
            />
        );
    }

    renderDealsView(type, icon, tooltip){
        const style = Object.assign(
            {},
            styles.financialIcon,
            type === this.state.dealsViewHover ? styles.dealsHover : {},
            (this.props.state.dealsView || 'direct') === type ? styles.dealsCurrent : {},
        );

        return (
            <Views.Common.Tooltip content={tooltip}>
                <span
                    style={styles.financialWrapper}
                    onMouseEnter={event => this.setState({ dealsViewHover: type })}
                    onMouseLeave={event => this.setState({ dealsViewHover: undefined })}
                    onClick={event => this.context.updateState({ dealsView: type })}
                >
                    <Icon type={icon} style={style} />
                </span>
            </Views.Common.Tooltip>
        );
    }

    renderFinancialView(type, icon, tooltip) {
        const style = Object.assign(
            {},
            styles.financialIcon,
            type === this.state.financialViewHover ? styles.financialHover : {},
            (this.props.state.financial || 'list') === type ? styles.financialCurrent : {},
        );

        return (
            <Views.Common.Tooltip content={tooltip}>
                <span
                    style={styles.financialWrapper}
                    onMouseEnter={event => this.setState({ financialViewHover: type })}
                    onMouseLeave={event => this.setState({ financialViewHover: undefined })}
                    onClick={event => this.context.updateState({ financial: type })}
                >
                    <Icon type={icon} style={style} />
                </span>
            </Views.Common.Tooltip>
        );
    }

    renderRelationsLabel(type: string, label: string, relations: Object[]) {
        if (!relations.length) {
            return <span style={styles.relationsLabel}>{label}</span>;
        }

        return (
            <span>
                <span style={styles.relationsLabel}>{label}</span>
                &nbsp;&nbsp;&nbsp;
                <span style={styles.directCount}>{relations.filter(item => !item.ceased).length}</span>
                &nbsp;
                <span style={styles.totalCount}>{'/ ' + relations.length}</span>
            </span>
        );
    }
}
