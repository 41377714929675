// @flow

import type { LinkMandateLevel } from '@deecision/deefind-types/model';

const orderedLevels = ['o', 'm', 'x', 'c', 'a', 'b', 's', 'i'];

export function makeHighestLevel(levels: (?LinkMandateLevel)[]): ?LinkMandateLevel {
    levels = levels.filter(v => orderedLevels.includes(v));

    if (!levels.length) {
        return undefined;
    }

    let result = orderedLevels[0];

    for (const level of levels) {
        if (orderedLevels.indexOf(level) > orderedLevels.indexOf(result)) {
            result = level;
        }
    }

    return result;
}

export function makeLowestLevel(levels: (?LinkMandateLevel)[]): ?LinkMandateLevel {
    levels = levels.filter(v => orderedLevels.includes(v));

    if (!levels.length) {
        return undefined;
    }

    let result;

    for (const level of levels) {
        if (!result) {
            result = level;
            continue;
        }

        if (orderedLevels.indexOf(level) < orderedLevels.indexOf(result)) {
            result = level;
        }
    }

    return result || orderedLevels[0];
}
