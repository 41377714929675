import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Paper from 'material-ui/Paper';
import RenderToLayer from 'material-ui/internal/RenderToLayer';

const styles = {
    wrapper: {
        position: 'fixed',
        zIndex: '999999999999999999',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        background: colors.lightBlack,
    },
    paper: {
        position: 'absolute',
        top: '20px',
        bottom: '20px',
        left: '2%',
        marginLeft: '0px',
        width: '96%',
        display: 'flex',
        alignItems: 'stretch',
        borderRadius: '5px'
    },
    aside: {
        width: '330px',
        padding: '10px',
        flexGrow: '0',
        flexShrink: '0',
        background: colors.grey100,
        borderRight: '1px solid ' + colors.grey300,
        borderRadius: '5px 0 0 5px'
    },
    children: {
        width: '100%',
        flexGrow: '1',
        flexShrink: '1',
        overflow: 'auto'
    },
    control: {
        width: '56px',
        flexGrow: '0',
        flexShrink: '0',
        padding: '10px'
    },
    title: {
        height: '24px'
    },
    titleIcon: {
        fill: colors.userCold,
        float: 'left',
        width: '24px',
        height: '24px',
        marginRight: '10px'
    },
    titleName: {
        lineHeight: '24px',
        fontSize: '21px',
        color: colors.userCold,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    asideElement: {
        marginTop: '10px',
        paddingTop: '10px',
        borderTop: '1px solid ' + colors.grey300
    },
    controlElement: {
        marginBottom: '10px',
        width: '56px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.modal.feature';

    static contextTypes = {
        muiTheme: PropTypes.object.isRequired
    };

    static propTypes = {
        name: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        aside: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
        control: PropTypes.arrayOf(PropTypes.element.isRequired),
        style: PropTypes.object,
        onClose: PropTypes.func
    };

    state = {
        open: false
    };

    open () {
        this.setState({ open: true });
        window.document.body.style.overflow = 'hidden';
    }

    close () {
        window.document.body.style.overflow = 'auto';
        this.setState({ open: false }, () => {
            if (this.props.onClose)
                this.props.onClose();
        });
    }

    render () {
        return (
            <RenderToLayer
                render={ this.renderContent.bind(this) }
                open={ this.state.open }
                useLayerForClickAway={ false }
            />
        );
    }

    renderContent () {
        return (
            <div style={ styles.wrapper }>
                <Paper
                    style={ Object.assign({}, styles.paper, this.props.style) }
                    zDepth={ 2 }
                >
                    <div key="aside" style={ styles.aside }>
                        <div style={ styles.title }>
                            { React.cloneElement(this.props.icon, { style: styles.titleIcon }) }
                            <span style={ styles.titleName }>{ this.props.name}</span>
                        </div>

                        { this.props.aside.map(this.renderAside.bind(this)) }
                    </div>

                    <div key="children" style={ styles.children }>
                        { this.props.children }
                    </div>

                    { this.renderControl() }
                </Paper>
            </div>
        );
    }

    renderAside (element, index) {
        return (
            <div
                style={ styles.asideElement }
                key={ index }
            >
                { element }
            </div>
        );
    }

    renderControl () {
        if (! this.props.control)
            return null;

        return (
            <div key="control" style={ styles.control }>
                { this.props.control.map((element, index) => (
                    <div style={ styles.controlElement } key={ index } >
                        { element }
                    </div>
                )) }
            </div>
        );
    }
}
