import type { FileAttachment } from '@deecision/deefind-types/model';

import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';

import Activity from 'dna/entity/common/activity';
import Views from 'app/views';
import { get } from 'lodash';

import t from 'app/translation';

const WIDTHS = [200, 350, 500];

const styles = {
    empty: {
        color: colors.grey500,
        textAlign: 'center',
        padding: '20px',
        background: colors.grey100,
    },
    container: {
        margin: '20px 0 0 0',
    },
    companyName: {
        margin: '0 0 15px 0',
        fontSize: '17px',
        fontWeight: 'bold',
        color: colors.grey600,
    },
    title: {
        color: colors.userCold,
        textAlign: 'center',
        fontWeight: 'bold',
        padding: '20px',
        background: colors.grey100,
    },
    text: {
        color: colors.grey600,
        fontSize: '16px',
    },
    row: {
        display: 'flex',
        borderTop: '1px solid ' + colors.grey300,
        justifyContent: 'stretch',
        alignContent: 'flex-start',
    },
    cell: {
        padding: '20px',
        lineHeight: '20px',
        verticalAlign: 'top',
    },
    cell3: {
        width:'33%',
    },
    heading: {
        color: colors.userCold,
        fontWeight: 'bold',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.common.compliance.report.identityBlockCompany';

    static propTypes = {
        title: PropTypes.string.isRequired,
        entity: PropTypes.object.isRequired,
        norkom: PropTypes.bool.isRequired,
    };

    render() {
        const address = this.getAddress(this.props.entity.data.addresses[0]);
        const country = getCountry(this.props.entity.data.addresses[0], this.props.entity.preview);

        const sanctions = (this.props.entity.files.filter((file: FileAttachment) => file.data.type === 'justice') || [])
            .map(file => file.data.heading)
            .join(', ');

        return (
            <div style={styles.container}>
                <div style={styles.title}>{this.props.title}</div>
                <div style={styles.row}>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>
                        <h2 style={styles.companyName}>{this.props.entity.preview.name}</h2>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Adresse', value: address }]}
                        />
                    </div>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>&nbsp;</div>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>
                        { this.props.norkom && <Views.Common.Properties
                            align={160}
                            style={styles.separator}
                            values={[
                                { label: 'Score Norkom', value: get(this.props.entity, 'vigilance.norkom.score') },
                                {
                                    label: 'Niveau Norkom',
                                    value: getNorkomLvl(this.props.entity.vigilance.norkom.score),
                                },
                            ]}
                        />}
                        <br />
                        <Views.Common.Properties
                            align={160}
                            style={styles.separator}
                            values={[
                                {
                                    label: 'Cotée',
                                    value: get(this.props.entity, 'preview.flags').includes('listed') ? 'oui' : 'non',
                                },
                                {
                                    label: 'Holding',
                                    value: get(this.props.entity, 'preview.flags').includes('holding') ? 'oui' : 'non',
                                },
                            ]}
                        />
                        <Views.Common.Properties
                            align={160}
                            style={styles.separator}
                            values={[{ label: "Pays d'immatriculation", value: country }]}
                        />
                    </div>
                </div>
                <div style={styles.row}>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>
                        <Activity
                            style={styles.text}
                            nace={this.props.entity.preview.naceCode}
                            gics={this.props.entity.preview.gicsCode}
                        />
                        <br />
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: 'Standard legal form',
                                    value: get(this.props.entity, 'identity.legalForm.standard'),
                                },
                                {
                                    label: 'National legal form',
                                    value: get(this.props.entity, 'identity.legalForm.national'),
                                },
                            ]}
                        />
                    </div>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>
                        <Views.Common.Properties
                            align={60}
                            values={get(this.props.entity, 'data.activities').map(item => {
                                return {
                                    label: t('common.label.activity'),
                                    value: t('common.activity.' + String(item.code).toUpperCase()),
                                };
                            })}
                        />
                    </div>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[
                                {
                                    label: "Nombres d'entreprises liées",
                                    value: get(this.props.entity, 'preview.companies.length'),
                                },
                            ]}
                        />
                    </div>
                </div>
                <div style={styles.row}>
                    <div style={ Object.assign({}, styles.cell, styles.cell3) }>
                        <Views.Common.Properties
                            align={140}
                            style={styles.separator}
                            values={[{ label: 'Sanctions', value: sanctions ? sanctions : 'non' }]}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getAddress(address?: EntityAddress): string {
        if (!address) {
            return '';
        }

        return (
            <div style={styles.text}>
                {address.details}
                <br />
                {address.zipCode} {address.city}
                <br />
                {address.country}
            </div>
        );
    }
}

function getCountry(address?: EntityAddress, preview: EntityPreview): ?boolean {
    if (!address) {
        return preview.country;
    }

    return address.country;
}

function getNorkomLvl(score: number): string {
    let norkomLevel = 'A';
    if (score >= 500) {
        norkomLevel = 'B';
    }
    if (score >= 1500) {
        norkomLevel = 'C';
    }

    return norkomLevel;
}
