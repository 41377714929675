import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    modal: {
        width: '800px',
    },
    label: {
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: '10px',
        maxWidth: '550px',
    },
};

@inject(stores => ({ global: stores.global }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.common.files';

    static propTypes = {
        files: PropTypes.array.isRequired,
        parent: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['milestone', 'event', 'prospect', 'meeting']),
        onSubmit: PropTypes.func.isRequired,
        onDelete: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
    };

    state = {};

    render() {
        return (
            <div>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label={String(this.props.files.length)}
                    icon={<Icon type="base.attachment" />}
                    onClick={() => {
                        this.refs.modal.open();
                    }}
                />
                <Views.Modal.Show
                    ref="modal"
                    icon={<Icon type="base.attachment" />}
                    title={'Files attachment'}
                    style={styles.modal}
                >
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <Views.Common.Button
                            label={this.state.isUploading ? 'Uploading file...' : 'Upload new attachment'}
                            type="raised"
                            color="cold"
                            disabled={this.state.isUploading}
                            icon={<Icon type="do.upload" />}
                            onClick={event => this.inputElement.click()}
                        />
                        <input
                            ref={element => (this.inputElement = element)}
                            type="file"
                            hidden
                            onChange={this.handleUpload.bind(this)}
                        />
                    </div>

                    {this.props.files.length ? this.renderFiles() : null}
                </Views.Modal.Show>
            </div>
        );
    }

    renderFiles() {
        return toJS(this.props.files).map((file, n) => (
            <div key={n}>
                <Views.Common.Button
                    key={'files-' + n}
                    label={
                        <span style={styles.label}>
                            {file.name}&nbsp;&nbsp;
                            <span style={{ color: colors.grey400, fontSize: '13px' }}>({file.createStamp})</span>
                        </span>
                    }
                    type="flat"
                    color="none"
                    icon={<Icon type="do.download" />}
                    onClick={() => this.props.onDownload(file.id, file.name)}
                />

                <Views.Common.Button
                    label="delete"
                    type="flat"
                    color="hot"
                    icon={<Icon type="do.delete" />}
                    onClick={() => {
                        if (confirm('Sure you want to definitively remove this attachment?')) {
                            this.props.onDelete(file.id);
                        }
                    }}
                />
            </div>
        ));
    }

    handleUpload(event) {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = async event => {
            const content = event.target.result;
            this.setState({ content });
            this.setState({ isUploading: true }, () => {
                this.props.onSubmit(this.props.type, this.props.parent, file.name, file.type, content).then(() => {
                    this.setState({ isUploading: false });
                });
            });
        };

        reader.readAsArrayBuffer(file);
    }
}
