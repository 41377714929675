import React from 'react';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Card from './card';
import { computeEventCost, computeProspectValues } from '../../common/compute';
import t from 'app/translation';

export default class extends React.Component<any, any> {
    static displayName = 'comeet.prospects.summary.list';

    props: {
        state?: Object,
        entities: Object,
        onCreate: Function
    };

    render () {
        return (
            <Views.List.Container
                entities={ this.props.entities }
                state={ this.props.state }
            >
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        name: { label: 'Name', path: 'target.name' },
                        fortune: { label: t('common.label.fortune'), path: 'target.fortune' },

                        deposit: {
                            label: t('dna.comeet.aum'),
                            path: item => computeProspectValues(item.payload).deposit
                        },

                        profit: {
                            label: t('dna.comeet.prospect.revenue'),
                            path: item => computeProspectValues(item.payload).profit
                        },

                        budget: {
                            label: t('dna.comeet.budgetedCost'),
                            path: item => computeProspectValues(item.payload).investment,
                        },

                        cost: {
                            label: t('dna.comeet.realCost'),
                            path: item => {
                                let cost = 0;

                                (item.events.forEach(event => cost += computeEventCost(event)));

                                return cost;
                            },
                        },

                        start: { label: t('dna.comeet.startDate'), path: 'period.start' },
                        deadline: { label: t('dna.comeet.deadline'), path: 'period.deadline' },

                        events: {
                            label: t('dna.comeet.eventsCount'),
                            path: item => (item.events || []).length
                        },
                    }}
                />

                <Views.List.Reducer
                    type="groups"
                    name="status"
                    title={t('dna.comeet.eventsCount')}
                    specs={{
                        draft: { label: t('dna.comeet.status.draft'), filter: item => item.status === 'draft' },
                        started: { label: t('dna.comeet.status.ongoing'), filter: item => item.status === 'started' },
                        succeeded: { label: t('dna.comeet.status.won'), filter: item => item.status === 'won' },
                        failed: { label: t('dna.comeet.status.lost'), filter: item => item.status === 'lost' },
                        canceled: { label: t('dna.comeet.status.aborted'), filter: item => item.status === 'aborted' },
                    }}
                />

                <Views.List.Reducer
                    type="fuse"
                    specs={{ paths: ['target.name'] }}
                />

                <Views.List.Frame
                    type="cards"
                    element={ <Card /> }
                />

                <Views.List.Block position="right">
                    <Views.Common.Button
                        label={t('dna.comeet.newProspect')}
                        icon={ <Icon type="do.create" /> }
                        onClick={ this.props.onCreate }
                    />
                </Views.List.Block>
            </Views.List.Container>
        );
    }
}
