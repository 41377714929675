import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Source from './source';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        margin: '-10px',
        alignItems: 'flex-start',
    },
};

@inject(stores => ({ store: stores.dnaSettings }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'dna.settings.reech';

    static propTypes = {
        style: PropTypes.object,
        store: PropTypes.object,
    };

    render() {
        const sources = toJS(this.props.store.reechSources);

        if (!sources) {
            return <Views.Common.Loader title="Loading information" />;
        }

        return (
            <div style={this.props.style}>
                <div style={styles.container}>
                    {sources.map(source => (
                        <Source
                            key={source.id}
                            data={source}
                            onUpload={data => this.props.store.uploadReechSource(source.id, data)}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
