import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Select from 'app/views/form/widget/select';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import { isString } from 'lodash';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import Input from './input';
import Textarea from './textarea';
import { toJS } from 'mobx';

const styles = {
    container: {},
    modal: {
        width: '1000px',
    },
    separator: {
        padding: '20px',
        background: colors.grey200,
        borderRadius: '3px',
    },
    wrapper: {
        display: 'flex',
    },
    block: {
        padding: '20px',
    },
    titleInput: {
        width: '680px',
    },
    durationInput: {
        display: 'inline-block',
        fontSize: '17px',
        marginLeft: '0px',
    },
    summaryTitleInput: {
        color: colors.userCold,
        width: '680px',
        verticalAlign: 'bottom',
        border: '1px solid ' + colors.grey400,
    },
    summaryValueInput: {
        width: '800px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.onSite.edit';

    static propTypes = {
        program: PropTypes.array.isRequired,
        style: PropTypes.object,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func,
    };

    state = {
        hideSub: false,
        hideSummary: false,
        program: this.props.program.map(item => Object.assign({}, item)),
    };

    render() {
        const SortableItem = SortableElement(({ value }) => (
            <li style={{ position: 'inherit', zIndex: 2102, display: 'block' }}>{value}</li>
        ));
        const SortableList = SortableContainer(({ items }) => (
            <ul style={{ listStyleType: 'none', padding: '0px' }}>
                {items.map((item, index) =>
                    item.fixed ? (
                        <SortableItem
                            key={`item-${index}`}
                            sortIndex={index}
                            index={index}
                            value={this.renderSeparator(item, index)}
                        />
                    ) : (
                        <SortableItem
                            key={`item-${index}`}
                            sortIndex={index}
                            index={index}
                            value={this.renderBlock(item, index)}
                        />
                    ),
                )}
            </ul>
        ));

        return (
            <div style={styles.container}>
                <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                    <div style={{ display: 'inline-block' }}>
                        <Views.Common.Toggle
                            title={'Sorting titles'}
                            value={this.state.sort}
                            empty={true}
                            items={[
                                { value: 'main', label: 'Main' },
                                { value: 'sub', label: 'Sub' },
                                { value: 'summary', label: 'Summary' },
                            ]}
                            onChange={value =>
                                this.setState({
                                    sort: value,
                                    hideSub: value === 'main' ? true : false,
                                    hideSummary: value === 'sub' ? true : false,
                                })
                            }
                        />
                    </div>
                    <Views.Common.Button
                        label={'submit'}
                        icon={<Icon type="do.submit" />}
                        type={'raised'}
                        mini={true}
                        color="cold"
                        style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                        onClick={() => {
                            this.props.onChange(this.state.program);
                        }}
                    />
                    <Views.Common.Button
                        label={'cancel'}
                        icon={<Icon type="do.cancel" />}
                        type={'raised'}
                        mini={true}
                        color="hot"
                        style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                        onClick={() => {
                            this.props.onChange(this.props.program);
                            this.setState({ program: this.props.program.map(item => Object.assign({}, item)) });
                        }}
                    />
                </div>
                <SortableList
                    useDragHandle={true}
                    items={this.state.program || []}
                    onSortEnd={({ oldIndex, newIndex }) => {
                        this.setState({ program: arrayMove(this.state.program, oldIndex, newIndex) });
                    }}
                />

                <Views.Common.Button
                    label={<span style={{ color: 'black' }}>Add title</span>}
                    icon={<Icon style={{ fill: 'black' }} type="do.create" />}
                    type="raised"
                    mini={true}
                    color="none"
                    style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                    onClick={() => {
                        const program = this.state.program;
                        program.push({ duration: 10, attendees: [], title: 'DEFAULT', summary: [] });
                        this.setState({ program });
                    }}
                />
                <div style={{ textAlign: 'right' }}>
                    <Views.Common.Button
                        label="Submit"
                        icon={<Icon type="do.submit" />}
                        type="raised"
                        mini={true}
                        color="cold"
                        style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                        onClick={() => {
                            this.props.onChange(this.state.program);
                        }}
                    />
                    <Views.Common.Button
                        label={'cancel'}
                        icon={<Icon type="do.cancel" />}
                        type={'raised'}
                        mini={true}
                        color="hot"
                        style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                        onClick={() => {
                            this.props.onChange(this.props.program);
                            this.setState({ program: this.props.program.map(item => Object.assign({}, item)) });
                        }}
                    />
                </div>
            </div>
        );
    }

    renderSeparator(data, initIndex) {
        const DragHandle = SortableHandle(() => (
            <Icon style={{ verticalAlign: 'middle', fill: 'black', cursor: 'pointer' }} type="base.grip" />
        ));
        return (
            <div style={styles.separator}>
                <DragHandle />
                <Input
                    color={'black'}
                    value={data.title}
                    onChange={value => {
                        const program = this.state.program;
                        program[initIndex].title = value;
                        this.setState({ program });
                    }}
                    styles={styles.titleInput}
                />
                <div style={styles.durationInput}>
                    <Select
                        onChange={value => {
                            const program = this.state.program;
                            program[initIndex].duration = value;
                            this.setState({ program });
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        value={data.duration}
                        choices={[10, 15, 25, 30, 35, 40, 45, 50, 55, 60]}
                    />
                </div>{' '}
                <span>minutes</span>
                <Views.Common.Button
                    color="hot"
                    icon={<Icon style={{ width: '20px', height: '20px' }} type="do.delete" />}
                    type="flat"
                    style={{ marginLeft: '20px', verticalAlign: 'baseline', width: '20px', height: '20px' }}
                    onClick={() => {
                        const program = this.state.program;
                        delete program[initIndex];

                        this.setState({ program: program.filter(v => !!v) });
                    }}
                />
            </div>
        );
    }

    renderBlock(data, initIndex) {
        const DragHandle = SortableHandle(() => (
            <Icon style={{ verticalAlign: 'middle', fill: 'black', cursor: 'pointer' }} type="base.grip" />
        ));
        const DragHandleSub = SortableHandle(() => (
            <Icon style={{ verticalAlign: 'middle', fill: colors.userCold, cursor: 'pointer' }} type="base.grip" />
        ));

        const SortableItem = SortableElement(({ value, sortIndex }) => {
            const DragHandleSummary = SortableHandle(() => (
                <Icon style={{ verticalAlign: 'middle', fill: colors.grey400, cursor: 'pointer' }} type="base.grip" />
            ));

            const subIndex = sortIndex;
            const SortableSummaryItem = SortableElement(({ value, sortIndex }) => {
                return (
                    <li style={{ position: 'inherit', zIndex: 2102, display: 'block' }}>
                        <div>
                            <DragHandleSummary />
                            {/* <textarea key={'item_' + initIndex + '_' + sortIndex} style={styles.summaryValueInput} value={value.title || value} onChange={e => {
                const program = this.state.program;
                program[initIndex].summary[subIndex].values[sortIndex] = e.target.value;
                this.setState({program});
              }}/> */}
                            <Textarea
                                color={colors.grey500}
                                value={value.title || value}
                                onChange={value => {
                                    const program = this.state.program;
                                    program[initIndex].summary[subIndex].values[sortIndex] = value;
                                    this.setState({ program });
                                }}
                                styles={styles.summaryValueInput}
                            />

                            <Views.Common.Button
                                color="hot"
                                icon={<Icon style={{ width: '20px', height: '20px' }} type="do.delete" />}
                                type="flat"
                                style={{ marginLeft: '20px', verticalAlign: 'middle', width: '20px', height: '20px' }}
                                onClick={() => {
                                    const program = this.state.program;
                                    delete program[initIndex].summary[subIndex].values[sortIndex];
                                    program[initIndex].summary[subIndex].values = program[initIndex].summary[
                                        subIndex
                                    ].values.filter(v => !!v);

                                    this.setState({ program });
                                }}
                            />
                        </div>
                    </li>
                );
            });

            const SortableList = SortableContainer(({ items }) => (
                <ul style={{ listStyleType: 'none', padding: '0px' }}>
                    {items.map((value, index) => {
                        return (
                            <SortableSummaryItem
                                key={`item-summary-${sortIndex}-${index}`}
                                sortIndex={index}
                                index={index}
                                value={value}
                            />
                        );
                    })}
                </ul>
            ));

            return (
                <li style={{ position: 'inherit', zIndex: 2102, display: 'block' }}>
                    <div key={'title_' + sortIndex} style={{ marginTop: '5px' }}>
                        <DragHandleSub />
                        <Input
                            color={colors.userCold}
                            value={value ? value.title : null}
                            onChange={value => {
                                const program = this.state.program;
                                program[initIndex].summary[subIndex].title = value;
                                this.setState({ program });
                            }}
                            styles={styles.summaryTitleInput}
                        />
                        <Views.Common.Button
                            color="hot"
                            icon={<Icon style={{ width: '20px', height: '20px' }} type="do.delete" />}
                            type="flat"
                            style={{ marginLeft: '20px', verticalAlign: 'baseline', width: '20px', height: '20px' }}
                            onClick={() => {
                                const program = this.state.program;
                                delete program[initIndex].summary[subIndex];
                                program[initIndex].summary = program[initIndex].summary.filter(v => !!v);

                                this.setState({ program });
                            }}
                        />
                        {this.state.hideSummary ? null : (
                            <SortableList
                                useDragHandle={true}
                                items={value ? value.values || [] : []}
                                onSortEnd={({ oldIndex, newIndex }) => {
                                    const program = this.state.program;
                                    program[initIndex].summary[sortIndex].values = arrayMove(
                                        value.values,
                                        oldIndex,
                                        newIndex,
                                    );
                                    this.setState({ program });
                                }}
                            />
                        )}
                        <Views.Common.Button
                            label={<span style={{ color: colors.grey500 }}>Add details</span>}
                            icon={<Icon style={{ fill: colors.grey500 }} type="do.create" />}
                            color="none"
                            type="raised"
                            mini={true}
                            style={{ marginTop: '10px', marginLeft: '30px', verticalAlign: 'baseline' }}
                            onClick={() => {
                                const program = this.state.program;
                                program[initIndex].summary[sortIndex].values.push('DEFAULT');
                                this.setState({ program });
                            }}
                        />
                    </div>
                </li>
            );
        });

        const SortableList = SortableContainer(({ items }) => (
            <ul style={{ listStyleType: 'none', padding: '0px' }}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} sortIndex={index} index={index} value={value} />
                ))}
            </ul>
        ));
        return (
            <div style={styles.block}>
                <DragHandle />
                <Input
                    color={'black'}
                    value={data.title}
                    onChange={value => {
                        const program = this.state.program;
                        program[initIndex].title = value;
                        this.setState({ program });
                    }}
                    styles={styles.titleInput}
                />
                <div style={styles.durationInput}>
                    <Select
                        onChange={value => {
                            const program = this.state.program;
                            program[initIndex].duration = value;
                            this.setState({ program });
                        }}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        value={data.duration}
                        choices={[10, 15, 25, 30, 35, 40, 45, 50, 55, 60]}
                    />
                </div>
                <span>minutes</span>
                <Views.Common.Button
                    color="hot"
                    icon={<Icon style={{ width: '20px', height: '20px' }} type="do.delete" />}
                    type="flat"
                    style={{ marginLeft: '20px', verticalAlign: 'baseline', width: '20px', height: '20px' }}
                    onClick={() => {
                        const program = this.state.program;
                        delete program[initIndex];

                        this.setState({ program: program.filter(v => !!v) });
                    }}
                />

                {this.state.hideSub ? null : (
                    <SortableList
                        useDragHandle={true}
                        items={data.summary || []}
                        onSortEnd={({ oldIndex, newIndex }) => {
                            const program = this.state.program;
                            program[initIndex].summary = arrayMove(data.summary, oldIndex, newIndex);
                            this.setState({ program });
                        }}
                    />
                )}
                <Views.Common.Button
                    label={<span style={{ color: colors.userCold }}>Add summary title</span>}
                    icon={<Icon style={{ fill: colors.userCold }} type="do.create" />}
                    type="raised"
                    mini={true}
                    color="none"
                    style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                    onClick={() => {
                        const program = this.state.program;
                        program[initIndex].summary.push({
                            title: 'DEFAULT',
                            values: [],
                        });
                        this.setState({ program });
                    }}
                />
            </div>
        );
    }
}
