// @flow

import hashObject from 'object-hash';

const delay = 500;

export default function<T>(): (event: T) => boolean {
    const memory: { [hash: string]: any } = {};

    return function(event: T): boolean {
        const hash = event === undefined ? 'undefined' : event === null ? 'null' : hashObject(event);
        const exists = memory.hasOwnProperty(hash);

        if (exists) {
            clearTimeout(memory[hash]);
        }

        memory[hash] = setTimeout(() => delete memory[hash], delay);

        return exists;
    };
}
