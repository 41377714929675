import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Progress from 'material-ui/CircularProgress';

const styles = {
    container: {
        textAlign: 'center',
        // background: colors.grey100,
        borderRadius: '5px',
        padding: '50px',
        boxSizing: 'content-box'
    },
    title: {
        fontSize: '19px',
        color: colors.grey500
    },
    wrapper: {
        position: 'relative',
        // marginTop: '45px'
    },
    progress: {
        height: '140px',
    },
    progressTiny: {
        height: '55px',
    },
    value: {
        fontSize: '23px',
        lineHeight: '140px',
        textAlign: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        color: colors.userCold
    },
    valueTiny: {
        fontSize: '9px',
        lineHeight: '55px',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.common.loader';

    static propTypes = {
        title: PropTypes.string,
        progress: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        style: PropTypes.object,
        tiny: PropTypes.bool
    };

    render () {
        return (
            <div style={ Object.assign({}, styles.container, this.props.tiny ? { padding: '0'} : {}, this.props.style) }>
                { this.renderTitle() }

                <div style={ styles.wrapper }>
                    { this.renderProgress() }
                    { this.renderLabel() }
                </div>
            </div>
        );
    }

    renderTitle () {
        if (! this.props.title) {
            return null;
        }

        return (
            <div style={ styles.title }>
                { this.props.title }
            </div>
        );
    }

    renderProgress () {
        const percent = (this.props.progress || {}).percent;

        if (typeof percent !== 'number' || ! percent) {
            return (
                <Progress
                    key="indeterminate"
                    style={ this.props.tiny ? styles.progressTiny : styles.progress }
                    mode="indeterminate"
                    size={ this.props.tiny ? 55 : 140 }
                />
            );
        }

        return (
            <Progress
                key="determinate"
                style={ this.props.tiny ? styles.progressTiny : styles.progress }
                mode="determinate"
                value={ percent }
                size={ this.props.tiny ? 55 : 140 }
            />
        );
    }

    renderLabel() {
        const progress = this.props.progress || {};

        let label = 'Building';

        if (progress.queue) {
            label = `Q: ${progress.queue}`;
        }

        if (progress.percent) {
            label = `${progress.percent} %`;
        }

        return (
            <div style={ this.props.tiny
                ? Object.assign({}, styles.value, styles.valueTiny)
                : styles.value }>
                { label }
            </div>
        );
    }
}
