import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment';
import Computer from './computer';
import Header from './display/header';
import Links from './display/links';
import Events from './display/events';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.timeline.display';

    static propTypes = {
        start: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
        end: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired
    };

    constructor (props) {
        super(props);
        this.state = { highlighted: undefined, sources: {}, targets: {} };
        this.initialize(props);
    }

    UNSAFE_componentWillReceiveProps (props) {
        this.initialize(props);
    }

    initialize (props) {
        const computer = Computer(props.start, props.end);

        this.cursor = computer.getDatePosition(new Date());
        this.rules = [];
        this.events = [];
        this.blocks = [];

        React.Children.forEach(props.children, child => {
            if (! child) return;

            switch (child.type.displayName) {
                case 'app.views.timeline.model.rule':
                    this.rules.push({
                        labels: computer.getRuleLabels(child.props.unit)
                    });
                    break;

                case 'app.views.timeline.model.event':
                    this.events.push({
                        id: child.props.id,
                        position: computer.getDatePosition(child.props.date),
                        icon: child.props.icon,
                        dimmed: child.props.dimmed,
                        children: child.props.children,
                        style: child.props.style,
                        pointStyle: child.props.pointStyle,
                        tooltip: Moment(child.props.date).format('dddd D MMMM YYYY')
                    });
                    break;

                case 'app.views.timeline.model.block':
                    this.blocks.push({
                        position: child.props.position,
                        children: child.props.children
                    });
                    break;
            }
        });

        this.events = _.sortBy(this.events, 'position');
    }

    render () {
        return (
            <div>
                <Header
                    cursor={ this.cursor }
                    rules={ this.rules }
                    events={ this.events }
                    blocks={ this.blocks }
                    highlighted={ this.state.highlighted }
                    onHighlight={ highlighted => this.setState({ highlighted: highlighted }) }
                    onLink={ links => this.setState({ sources: links }) }
                />
                <Links
                    styles={this.events.map(i => Object.assign({}, i.pointStyle))}
                    sources={ this.state.sources }
                    targets={ this.state.targets }
                    highlighted={ this.state.highlighted }
                />
                <Events
                    events={ this.events }
                    highlighted={ this.state.highlighted }
                    onHighlight={ highlighted => this.setState({ highlighted: highlighted }) }
                    onLink={ links => this.setState({ targets: links }) }
                />
            </div>
        );
    }
}
