import React from 'react';
import PropTypes from 'prop-types';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import List from './list';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import Scope from 'app/layout/providers/scope';

@inject(stores => ({ auth: stores.auth, teem: stores.teemProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.common.attendees';

    static propTypes = {
        auth: PropTypes.object.isRequired,
        teem: PropTypes.object.isRequired,
        project: PropTypes.object.isRequired,
        edit: PropTypes.bool.isRequired,
    };

    render() {
        const users = toJS(this.props.auth.members[this.props.project.teamUid]) || [];
        const attendees = toJS(this.props.teem.attendees.get(this.props.project.id)) || [];

        return [
            <Views.Common.Button
                key="button"
                type="flat"
                color="cold"
                label={String(users.length + attendees.length)}
                icon={<Icon type="base.attendee" />}
                onClick={() => this.refs.modal.open()}
            />,

            <Views.Modal.Show
                key="modal"
                ref="modal"
                icon={<Icon type="base.attendee" />}
                title="Project attendees"
            >
                <Scope path="attendees" type="list">
                    <List
                        edit={this.props.edit}
                        project={this.props.project}
                        users={users}
                        attendees={attendees}
                    />
                </Scope>
            </Views.Modal.Show>,
        ];
    }
}
