// @flow

import type { CompilerEntity } from '@deecision/deefind-types/server/engine/compiler';
import type { StockPrediction, StockWeasse } from '@deecision/deefind-types/model';

import { makeAgeExitPrediction } from './age';
import { makeStocksExitPrediction, getPredictStockExit } from './stocks';

const horizonMax = 5;

export { getPredictStockExit };

export function makePrediction(
    entity: CompilerEntity,
    weasse: StockWeasse,
    vcsLinked: { [id: string]: Object[] },
): StockPrediction[] {
    if (entity.type === 'company') return [];

    const preview: Object = entity.preview;
    const predicts = makeStocksExitPrediction(entity.links, horizonMax, vcsLinked);

    if (preview.birthDate) {
        const agePrediction = makeAgeExitPrediction(entity, weasse, horizonMax);
        if (agePrediction) {
            predicts.push(agePrediction);
        }
    }

    return predicts;
}
