import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import Stakeholder from './stakeholder';
import Header from './header';
import Filter from './filter';
import t from 'app/translation';

const styles = {
    empty: {
        textAlign: 'center',
        color: colors.grey500,
        padding: '40px 0'
    },
    icon: {
        width: '20px',
        height: '20px',
        padding: '2px 2px 3px 2px'
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.deals.tab.stakeholders';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        stakeholders: PropTypes.object.isRequired,
        details: PropTypes.object.isRequired,
        style: PropTypes.object,
        state: PropTypes.object,
        type: PropTypes.string,
        onOpen: PropTypes.func.isRequired
    };

    render () {
        let stakeholders = _.values(this.props.stakeholders);

        if (! stakeholders.length) {
            return (
                <div style={ Object.assign({}, this.props.style, styles.empty) }>
                    No stakeholders
                </div>
            );
        }

        const state = this.props.state || {};

        if (state.type)
            stakeholders = _.filter(stakeholders, stakeholder => _.get(stakeholder, 'target.type') === state.type);

        switch (state.sort) {
            case 'name':
                stakeholders = _.orderBy(stakeholders, 'target.name', 'asc');
                break;

            case 'deals':
                stakeholders = _.orderBy(stakeholders, stakeholder => stakeholder.deals.length, 'desc');
                break;

            default:
                stakeholders = _.orderBy(stakeholders, 'value.value', 'desc');
                break;
        }

        return (
            <div style={ this.props.style }>
                <Header title="Holders">
                    <Filter
                        value={ state.type }
                        onChange={ type => this.context.updateState({ type }) }
                        choices={{
                            person: <Icon type="dna.person" style={ styles.icon } />,
                            company: <Icon type="dna.company" style={ styles.icon } />,
                            group: <Icon type="dna.typology" style={ styles.icon } />
                        }}
                    />

                    <Filter
                        value={ state.sort || 'value' }
                        onChange={ sort => this.context.updateState({ sort }) }
                        choices={{
                            value: t('common.label.value'),
                            name: t('common.label.name'),
                            deals: t('common.label.deals')
                        }}
                    />
                </Header>

                { stakeholders.map(stakeholder => (
                    <Stakeholder
                        key={ stakeholder.target.id }
                        stakeholder={ stakeholder }
                        onOpen={ event => this.props.onOpen(
                            _.map(stakeholder.deals, id => this.props.details[id]),
                            stakeholder.target.name
                        ) }
                    />
                )) }
            </div>
        );
    }
}
