import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Base from './base';

const styles = {
    content: {},
    category: {
        display: 'flex',
        margin: '10px',
    },
    title: {
        margin: '10px',
        fontWeight: 'bold',
        width: '25%',
    },
    body: {
        width: '75%',
    },
    item: {},
    label: {
        margin: '10px',
        color: colors.userCold,
        textTransform: 'uppercase',
    },
    text: {
        margin: '10px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.display.report.blocks.findings';

    static propTypes = {
        title: PropTypes.string.isRequired,
        data: PropTypes.object,
    };

    render() {
        return <Base title={this.props.title}>{this.renderContent()}</Base>;
    }

    renderContent() {
        return (
            <div style={styles.content}>
                {Object.keys(this.props.data || {}).map((category, index) =>
                    this.renderCategory(category, this.props.data[category], index),
                )}
            </div>
        );
    }

    renderCategory(title: string, items: Object[], key: number) {
        if (!items.length) {
            return null;
        }

        return (
            <div key={key} style={styles.category}>
                <div style={styles.title}>{title}</div>

                <div style={styles.body}>{items.map((item, index) => this.renderItem(item.title, item.text, index))}</div>
            </div>
        );
    }

    renderItem(title: string, text: string, key: number) {
        return (
            <div key={key} style={styles.item}>
                <div style={styles.label}>{title}</div>
                <div style={styles.text}>{text}</div>
            </div>
        );
    }
}
