import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function normalize (choices) {
    const array = _.isArray(choices);

    return _.map(choices, (choice, index) => {
        switch (true) {
            case _.isString(choice):
            case _.isNumber(choice):
                choice = { title: '' + choice, value: array ? choice : index };
                break;

            case _.isObject(choice):
                if (! _.has(choice, 'value'))
                    choice.value = index;
                break;

            default:
                throw new Error(`Form "choice" widget's choice must be a string, a number or an object.`);
        }

        choice.key = '' + index;

        return choice;
    });
}

const Choices = function (choices) {
    const normalized = normalize(choices);

    return {
        mormalize (value) {
            const choice = _.first(_.filter(normalized, { value: value }));
            return choice ? choice.value : undefined;
        },

        map (mapper) {
            return _.map(normalized, mapper);
        },

        find (value) {
            return _.first(_.filter(normalized, { value: value })) || undefined;
        }
    };
};

Choices.propType = PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
]).isRequired;

export default Choices;
