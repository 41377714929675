// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import Loader from './loader';
import Login from './login/index';

@inject('global')
@observer
export default class extends React.Component<any, any> {
    static displayName = 'app.layout.security';

    static propTypes = {
        global: PropTypes.object.isRequired,
    };

    state = { authEmail: null };

    render() {
        const session = this.props.global.session.get();
        const status = this.props.global.status.get();

        switch (status.task) {
            case 'init':
                return <Loader message="Initializing" />;

            case 'sync':
                return <Loader message="Synchronizing" />;
        }

        if (session.level === 'anonymous') {
            return (
                <Login
                    lastEmail={this.props.global.lastEmail.get()}
                    onRequest={(email: string) => {
                        this.setState({ authEmail: email });

                        return this.props.global.execute('cleerance', 'auth.request', {
                            email,
                            source: window.location.href,
                        });
                    }}
                    onLogin={(email: string, password: string) => {
                        return this.props.global.execute('cleerance', 'auth.login', {
                            email: this.state.authEmail,
                            password,
                        });
                    }}
                    onStart={(remember: boolean) => {
                        // plus d'actualité
                    }}
                />
            );
        }

        return <div>{this.props.children}</div>;
    }
}
