// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        position: 'relative',
        margin: '20px 10px 0 0',
    },
    cells: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        border: '1px solid ' + colors.userCold,
        borderWidth: '0 0 1px 1px',
        background: colors.grey300,
        gridColumnGap: '1px',
        gridRowGap: '1px',
    },
    cell: {
        minHeight: '130px',
        color: colors.userCold,
        textAlign: 'center',
        background: 'white',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cellHover: {
        background: colors.grey200,
    },
    cellDisabled: {
        color: colors.grey400,
        cursor: 'default',
    },
    xLabel: {
        position: 'absolute',
        top: '100%',
        right: '0',
        lineHeight: '40px',
        textAlign: 'right',
        color: colors.grey500,
        marginTop: '20px',
    },
    xArrow: {
        position: 'absolute',
        right: '-17px',
        bottom: '-13px',
        fontSize: '20px',
        color: colors.userCold,
    },
    yLabel: {
        position: 'absolute',
        top: '-50px',
        left: '20px',
        lineHeight: '20px',
        color: colors.grey500,
    },
    yArrow: {
        position: 'absolute',
        top: '-21px',
        left: '-10px',
        fontSize: '20px',
        color: colors.userCold,
    },
    step: {
        position: 'absolute',
        width: '58px',
        height: '32px',
        lineHeight: '32px',
        background: `linear-gradient(white, ${colors.grey100})`,
        border: '1px solid ' + colors.grey400,
        borderRadius: '5px',
        textAlign: 'center',
        color: colors.userCold,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 5px, rgba(0, 0, 0, 0.1) 0px 1px 3px',
    },
    yStep: {
        left: '-30px',
    },
    xStep: {
        bottom: '-17px',
    },
    yStep1: {
        top: 'calc(66.67% - 17px)',
    },
    yStep2: {
        top: 'calc(33.33% - 17px)',
    },
    xStep1: {
        left: 'calc(33.33% - 30px)',
    },
    xStep2: {
        left: 'calc(66.67% - 30px)',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.results.matrix';

    static propTypes = {
        cells: PropTypes.arrayOf(PropTypes.node).isRequired,
        xLabel: PropTypes.node.isRequired,
        yLabel: PropTypes.node.isRequired,
        xStep1: PropTypes.node.isRequired,
        xStep2: PropTypes.node.isRequired,
        yStep1: PropTypes.node.isRequired,
        yStep2: PropTypes.node.isRequired,
        style: PropTypes.object,
    };

    state = { hover: null };

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                <div style={styles.cells}>
                    {this.props.cells.map((value: number, index: number) => this.renderCell(index, value))}
                </div>

                <div style={styles.xLabel}>{this.props.xLabel}</div>
                <div style={styles.xArrow}>▶</div>

                <div style={styles.yLabel}>{this.props.yLabel}</div>
                <div style={styles.yArrow}>▲</div>

                <div style={Object.assign({}, styles.step, styles.xStep, styles.xStep1)}>{this.props.xStep1}</div>
                <div style={Object.assign({}, styles.step, styles.xStep, styles.xStep2)}>{this.props.xStep2}</div>
                <div style={Object.assign({}, styles.step, styles.yStep, styles.yStep1)}>{this.props.yStep1}</div>
                <div style={Object.assign({}, styles.step, styles.yStep, styles.yStep2)}>{this.props.yStep2}</div>
            </div>
        );
    }

    renderCell(index: number, value: number) {
        const style = value
            ? Object.assign({}, styles.cell, this.state.hover === index ? styles.cellHover : {})
            : Object.assign({}, styles.cell, styles.cellDisabled);

        return (
            <span
                key={index}
                style={style}
                onMouseEnter={!value ? undefined : event => this.setState({ hover: index })}
                onMouseLeave={!value ? undefined : event => this.setState({ hover: null })}
            >
                {value}
            </span>
        );
    }
}
