// @flow

import type { EntitySource, PersonData, PersonPreview } from '@deecision/deefind-types/model';

import { cleanPersonData, cleanPersonPreview } from '../clean';

export function normPersonPreview(preview: Object): PersonPreview {
    preview = cleanPersonPreview(preview);
    preview.name = (preview.lastName || '').toUpperCase() + ' ' + (preview.firstName || '');

    return preview;
}

export function normPersonData(data: Object, source?: EntitySource): PersonData {
    return cleanPersonData(data, source);
}
