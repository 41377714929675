import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Moment from 'moment';
import Comments from '../comments';
import { toJS } from 'mobx';
import { get } from 'lodash';
import Icon from 'app/theme/icon';

const styles = {
    container: {
        width: '75%',
        overflow: 'auto',
    },
    field: {
        lineHeight: '50px',
        paddingTop: '5px',
        paddingBottom: '5px',
        display: 'flex',
        color: colors.grey700,
        borderRadius: '3px',
        alignItems: 'center',
    },
    fieldIndent: {
        display: 'inline-block',
        marginLeft: '30px',
        color: colors.grey500,
    },
    fieldName: {
        padding: '0 10px',
        width: '1',
        flexGrow: '1',
        flexShrink: '1',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    fieldComments: {
        width: '100px',
    },
    fieldInfo: {
        padding: '0 10px',
        width: '150px',
        fontSize: '13px',
        color: colors.grey500,
    },
    fieldFlags: {
        padding: '0 10px',
        width: '60px',
        fontSize: '13px',
        color: colors.userCold,
    },
    hoveredField: {
        color: colors.grey900,
        background: colors.grey200,
    },
    flag: {
        display: 'inline-block',
        margin: '0 5px',
        textTransform: 'upper-case',
        padding: '0 5px',
        lineHeight: '19px',
        height: '19px',
        borderRadius: '3px',
        background: colors.bgHot,
        color: colors.userHot,
        fontSize: '11px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'deeligenz.studies.common.documents.list';

    static contextTypes = {
        getFileUrl: PropTypes.func.isRequired,
        downloadFile: PropTypes.func.isRequired,
    };

    static propTypes = {
        fields: PropTypes.arrayOf(PropTypes.object).isRequired,
        onComment: PropTypes.func.isRequired,
    };

    state = { hovered: null };

    async handleDownload(answer: Object) {
        if (!answer.id) {
            return;
        }

        await this.context.downloadFile(answer.id, answer.name);

    }

    async handleOpen(answer: Object) {
        if (!answer.id) {
            return;
        }

        const url = await this.context.getFileUrl(answer.id);

        if ((answer.type || '').match(/^image\//)) {
            this.refs.modalImg.open(url);
        }

        if ((answer.type || '').match(/^application\/pdf/)) {
            PDFJS.getDocument(url).promise.then(pdf => this.refs.modalPdf.open(pdf));
        }
    }

    render() {
        return (
            <div style={styles.container}>
                {this.props.fields.map(this.renderField.bind(this))}
                <Views.Modal.Img onTop={true} ref="modalImg" />
                <Views.Modal.Pdf onTop={true} ref="modalPdf" />
            </div>
        );
    }

    renderField(field) {
        const question = field.questionPayload || { flags: [] };
        const answer = field.answerEditPayload || field.answerPayload || {};

        if (question.type === 'file') {
            return this.renderDocument(field, 0, answer.value || {});
        }

        if (question.type === 'file[]') {
            const values = answer.values || [];
            const documents = [this.renderDocument(field, 0, values[0] || {})];

            for (let index = 1; index <+ values.length; index ++) {
                documents.push(this.renderDocument(field, index, values[index] || {}));
            }

            return documents;
        }

        return null;
    }

    renderDocument(field: Object, index: number, answer: Object) {
        const key = `${field.id}_${index}`;
        const question = field.questionPayload || { flags: [] };
        const validations = (field.history || []).filter(i => i.transition === 'validate');
        const label = index ? <span style={styles.fieldIndent}>→ Document #{index + 1}</span> : question.label;

        const style = Object.assign(
            {},
            styles.field,
            { cursor: answer.id ? 'pointer' : 'default', background: answer.id ? 'white' : colors.bgHot },
            this.state.hovered === key ? styles.hoveredField : {},
        );

        return (
            <div
                key={key}
                style={style}
                onMouseEnter={answer.id ? event => this.setState({ hovered: key }) : undefined}
                onMouseLeave={event => this.setState({ hovered: null })}
            >
                <span style={styles.fieldName}>{label}</span>

                <span style={styles.actions}>
                    <Views.Common.Button mini={true} style={{width: '5px'}} icon={<Icon type="base.eye" />} onClick={event => this.handleOpen(answer)} />
                    &nbsp;
                    <Views.Common.Button mini={true} icon={<Icon fontSizeSmall={true} size={4} type="do.download" />} onClick={event => this.handleDownload(answer)} />
                </span>

                <span style={styles.fieldFlags}>
                    {!index && question.flags.includes('key') ? <span style={styles.flag}>KEY</span> : null}
                    {!index && question.flags.includes('gdpr') ? <span style={styles.flag}>GDPR</span> : null}
                </span>

                <div style={styles.fieldComments}>
                    {index ? null : (
                        <Comments
                            type="button"
                            title={question.label}
                            comments={toJS(field.comments || [])}
                            transitions={toJS(field.history || [])}
                            onComment={content => this.props.onComment(field.id, content)}
                        />
                    )}
                </div>

                <span style={styles.fieldInfo}>
                    {validations.length
                        ? Moment(validations[validations.length - 1].createStamp).format('lll')
                        : answer.id
                        ? 'Not validated'
                        : 'Missing'}
                </span>
            </div>
        );
    }
}
