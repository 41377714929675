import PropTypes from 'prop-types';
import React from 'react';
import Flag from '../common/flag';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.woco.flag';

    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render () {
        switch (this.props.data.type) {
            case 'politic':
                return (<Flag char="P" shape="square" color="cold" type="full" />);

            case 'justice':
                return (<Flag char="J" shape="square" color="cold" type="full" />);

            case 'compliance':
                return (<Flag char="C" shape="square" color="cold" type="outline" />);

            case 'mandate':
                return (<Flag char="M" shape="square" color="cold" type="full" />);
        }

        return null;
    }
}
