// @flow

import slugify from 'speakingurl';
import { matchAll } from './array';
import { matchWord } from './scalar';

export function matchPersonName(a: ?(string | [string, string]), b: ?(string | [string, string])): number {
    if (Array.isArray(a) && Array.isArray(b)) {
        return Math.max(
            Math.min(matchName(a[0], b[0], PERSON_NAME_EXCLUSIONS), matchName(a[1], b[1], PERSON_NAME_EXCLUSIONS)),
            Math.min(matchName(a[0], b[1], PERSON_NAME_EXCLUSIONS), matchName(a[1], b[0], PERSON_NAME_EXCLUSIONS)),
        );
    }

    return matchName(a, b, PERSON_NAME_EXCLUSIONS);
}

export function matchCompanyName(a: ?string, b: ?string): number {
    return matchName(a, b, COMPANY_NAME_EXCLUSIONS);
}

export function matchName(
    a: ?(string | [string, string]),
    b: ?(string | [string, string]),
    exclude: ?(string[]),
): number {
    if (!a || !b) {
        return 0;
    }

    const normalize = (name: string | [string, string]): string[] => {
        return slugify((Array.isArray(name) ? name.join(' ') : name).replace(/\./g, ''))
            .split('-')
            .filter(value => !(exclude || []).includes(value));
    };

    return matchAll(matchWord, normalize(a), normalize(b));
}

export const PERSON_NAME_EXCLUSIONS = ['du', 'de', 'la', 'des', 'o'];
export const COMPANY_NAME_EXCLUSIONS = [];
