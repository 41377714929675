// @flow

import PropTypes from 'prop-types';

import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Button from 'app/views/common/button';
import t from 'app/translation';

const styles = {
    container: {
        padding: '50px',
        textAlign: 'center'
    },
    message: {
        fontSize: '19px',
        color: colors.grey500,
        marginBottom: '20px'
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.empty';

    static contextTypes = {
        addStage: PropTypes.func.isRequired
    };

    render () {
        return (
            <div style={ styles.container }>
                <div style={ styles.message }>
                    {t('dna.deefind.workspace.titleEmpty')}
                </div>

                <Button
                    type="raised"
                    color="cold"
                    icon={ <Icon type="dna.search" /> }
                    label={t('dna.deefind.workspace.newSearchButton')}
                    onClick={ event => this.context.addStage() }
                />
            </div>
        );
    }
}
