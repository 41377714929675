// @flow

import type StateStore from 'app/stores/state';
import type DeefindStore from 'app/stores/deefind';

import { action, runInAction } from 'mobx';
import union from 'lodash/union';
import slugify from 'speakingurl';

const PATH = 'dna.explorer';

const STAGE = {
    type: 'search',
    search: {
        open: true,
        form: {
            kind: 'person',
            typology: { provider: 301, version: 0 },
        },
    },
};

export default class DnaExplorerStore {
    state: StateStore;
    deefind: DeefindStore;

    constructor(state: StateStore, deefind: DeefindStore) {
        this.state = state;
        this.deefind = deefind;
    }

    // workspace management

    @action addTab = (): void => {
        console.warn('Function to define');
    };

    @action addStage = (): void => {
        this.state
            .enter(PATH, 'menu')
            .assign(':next', STAGE)
            .select(':last');
    };

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };

    // explorer actions

    @action openEntity = (id: string): void => {
        const stage = this.state.enter(PATH, 'menu').enter(':current', 'router');
        const search = stage.enter('search', 'object');
        const formOpen = search.enter('open', 'boolean').read();
        search.assign('open', false);

        stage
            .enter('entities', 'menu')
            .remove(formOpen ? ':all' : '>:cursor')
            .assign(':next', { id })
            .select(':last');
    };

    @action selectHandle = (index: number): void => {
        const stage = this.state.enter(PATH, 'menu').enter(':current', 'router');
        stage.enter('search', 'object').assign('open', false);
        stage.enter('entities', 'menu').select(index);
    };

    @action exportEntities = async (id: string, follow: Function): Promise<void> => {
        if (!this.deefind.results.has(`network:${id}`)) {
            await this.deefind.fetch('network', id, 1, follow);
        }

        await this.deefind.download(id);
    };

    // search specific

    @action selectSearch = (): void => {
        const stage = this.state.enter(PATH, 'menu').enter(':current', 'router');
        stage.enter('search', 'object').assign('open', true);
        stage.enter('entities', 'menu').select(':current'); // for refreshing
    };

    @action submitSearch = async (): Promise<void> => {
        const searchNode = this.state
            .enter(PATH, 'menu')
            .enter(':current', 'router')
            .enter('search', 'object');

        const formData = searchNode.enter('form', 'object').read();

        if (!formData.kind || (formData.kind !== 'typology' && !formData.value)) {
            searchNode.assign('status', 'invalid');
            return;
        }

        searchNode.assign('loading', true);

        const cursor = this.state
            .enter(PATH, 'menu')
            .enter('cursor', 'number')
            .read();

        this.state.enter(PATH, 'menu').select(':current'); // for refreshing

        const follow = action((progress) => {
            searchNode.assign('progress', progress ? progress.percent : 0);
        });

        try {
            const result =
                formData.kind === 'typology'
                    ? await this.deefind.searchTypology(formData.typology.provider, 0)
                    : formData.type === 'code'
                    ? await this.deefind.searchIdentifier(formData.kind, formData.value)
                    : await this.deefind.searchName(formData.kind, formData.value);
            this.feedSearch(cursor, result);
        } catch(error) {
            runInAction(() => {
                searchNode.update({ error: error, status: 'failure' });
            });
        }
    };

    @action feedSearch = (cursor: number, result: Object[]): void => {
        const searchNode = this.state
            .enter(PATH, 'menu')
            .enter(cursor, 'router')
            .enter('search', 'object');

        const formData = searchNode.enter('form', 'object').read();
        searchNode.assign('loading', false);

        if (formData.kind === 'typology') {
            searchNode.enter('result', 'object').update({
                kind: 'typology',
                items: result,
            });
        } else {
            let companies = [];
            let persons = [];
            let activities = [];

            for (const entity of result) {
                this.deefind.previews.set(entity.id, entity);

                switch (entity.type) {
                    case 'person':
                        companies = union(companies, entity.preview.companies || []);
                        break;

                    case 'company': {
                        persons = union(persons, entity.preview.persons || []);
                        const activity = entity.preview.activity;

                        if (activity && !activities.includes(activity)) {
                            activities.push(activity);
                        }
                        break;
                    }
                }
            }

            // auto-completes
            searchNode.enter('form', 'object').update({
                companies: companies.map(name => slugify(name, { separator: ' ' })),
                persons: persons.map(name => slugify(name, { separator: ' ' })),
                activities: activities.map(name => slugify(name, { separator: ' ' })),
            });

            // result
            searchNode.enter('result', 'object').update({
                kind: formData.kind,
                ids: result.map(entity => entity.id),
            });
        }

        searchNode.assign('status', 'success');
    };
}
