// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Button from 'app/views/common/button';
import xlsx from 'xlsx';
import t from 'app/translation';

const styles = {
    container: {
        marginTop: '30px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.filters.download';

    static propTypes = {
        companies: PropTypes.array,
        persons: PropTypes.array,
        listedRelations: PropTypes.array,
        predict: PropTypes.array,
        tool: PropTypes.string,
    };

    render() {
        const enabled =
            (this.props.persons && this.props.persons.length > 1) ||
            (this.props.companies && this.props.companies.length > 1);

        return (
            <div style={styles.container}>
                <Button
                    type="raised"
                    color="cold"
                    label="Export results"
                    icon={<Icon type="do.download" />}
                    onClick={() => this.download()}
                    disabled={!enabled}
                />
            </div>
        );
    }

    download() {
        const book = xlsx.utils.book_new();

        if (this.props.persons && this.props.persons.length > 1) {
            xlsx.utils.book_append_sheet(book, xlsx.utils.aoa_to_sheet(this.props.persons), 'Persons');

            if (this.props.tool === 'predict') {
                xlsx.utils.book_append_sheet(book, xlsx.utils.aoa_to_sheet(this.props.predict), 'Predict');
            }

            if (this.props.listedRelations.length) {
                xlsx.utils.book_append_sheet(book, xlsx.utils.aoa_to_sheet(this.props.listedRelations), 'PRSC');
            }
        }

        if (this.props.companies && this.props.companies.length > 1) {
            xlsx.utils.book_append_sheet(book, xlsx.utils.aoa_to_sheet(this.props.companies), t('common.label.companies'));
        }

        const content = xlsx.write(book, { type: 'binary', bookType: 'xlsx' });
        const buffer = new ArrayBuffer(content.length);
        const view = new Uint8Array(buffer);

        // https://docs.sheetjs.com/#writing-workbooks
        for (let i = 0; i < content.length; ++i) {
            view[i] = content.charCodeAt(i) & 0xff;
        }

        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const file = `deetect.xlsx`;

        if (window.navigator.msSaveOrOpenBlob) {
            return window.navigator.msSaveBlob(blob, file);
        }

        const link = window.document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file;

        if (document.body) {
            document.body.appendChild(link);
        }

        link.click();

        if (document.body) {
            document.body.removeChild(link);
        }
    }
}
