import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Handle from './handle';
import Node from './node';
import t from 'app/translation';

const styles = {
    container: {
        position: 'relative'
    },
    node: {
        padding: '15px 0',
        color: colors.userCold,
        fontWeight: 'bold',
    }
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.stocks.group';

    static propTypes = {
        count: PropTypes.number,
        input: PropTypes.string,
        output: PropTypes.string,
        handleStyle: PropTypes.object,
        onClick: PropTypes.func,
    };

    render () {
        return (
            <div style={ styles.container }>
                <Node
                    title={ `${this.props.count} entities` }
                    desc=" "
                    input={ this.props.input }
                    output={ this.props.output }
                    onClick={ this.props.onClick }
                    style={ styles.node }
                />

                <Handle
                    onClick={ this.props.onClick }
                    style={ this.props.handleStyle }
                />
            </div>
        );
    }
}
