import { get } from 'lodash';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';


export default async function (data: Object, services: Object, reportType: string): Promise<Object> {
    const scopes = (reportType == 'full') ? ['both', 'input', 'ddq'] : ['both', 'input']; // ddq is visble only in 'full' report but not in 'survey'
    const withFiles = (reportType == 'full') ? false : true; // no files in question in 'full' report but files shown in question in 'survey'

    let outData = {};
    const labels = {};
    const categories = [];
  
    (data.study.survey || [])
      .filter(e => !(withFiles ? [] : ['file', 'file[]']).includes(get(e, 'questionPayload.type')))
      .filter(e => (scopes || ['both', 'input', 'ddq']).includes(get(e, 'questionPayload.scope')))
      .map(e => {
        const answer = [];
    
        if (get(e, ('answerPayload'))) answer.push({
            type: get(e, 'questionPayload.type'),
            answer: get(e, ('answerPayload')),
            options: get(e, 'questionPayload.options', {})
        })
    
        if (get(e, ('answerEditPayload'))) answer.push({
            edited: true,
            type: get(e, 'questionPayload.type'),
            answer: get(e, ('answerEditPayload')),
            options: get(e, 'questionPayload.options', {})
        })
    
        if (!outData[get(e, 'questionPayload.category.id')]) {
            categories.push(get(e, 'questionPayload.category'))
            outData[get(e, 'questionPayload.category.id')] = [];
            labels[get(e, 'questionPayload.category.id')] = [];
        }
    
        outData[get(e, 'questionPayload.category.id')].push(answer);
        const labelObj = {
          label: get(e, 'questionPayload.label'),
        };
        if (reportType == 'full') {
          labelObj.remark = get(e, ('answerPayload.ddqRemark')); // remark only visible for 'full' (and therefore not in 'survey')
        }
        labels[get(e, 'questionPayload.category.id')].push(labelObj);
      });
  
    forEach(outData, ((i, index) => outData[index] = sortBy(i, ['index'])))
  
    return {
      questions: {
        categories,
        labels,
        data: outData,
      }
    };
}
