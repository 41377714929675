// @flow

import React from 'react';
import Screen from 'app/layout/screen';
import Teams from './teams';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin';

    render() {
        return (
            <Screen
                path="admin"
                type="workspace"
                fallback="teams"
                stages={{
                    teams: {
                        entry: { icon: 'cleerance.teams', label: t('cleerance.admin.teams.label') },
                        content: Teams,
                    },
                }}
            />
        );
    }
}
