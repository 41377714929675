import type {
    QueueResult,
    QueueScope,
    QueueStatus,
    SdkMake,
    SdkQueue,
    SdkWatch,
    SdkWatcher,
    StoreQueueList,
} from '@deecision/infra-types/client';

export default function<T>(make: SdkMake<T>, watch: SdkWatcher<T>): SdkQueue {
    return {
        push(scope: QueueScope, inputs: Object[]): Promise<void> {
            return make({ type: 'queue.push', scope, inputs });
        },

        start(scope: QueueScope): Promise<void> {
            return make({ type: 'queue.start', scope });
        },

        stop(scope: QueueScope): Promise<void> {
            return make({ type: 'queue.stop', scope });
        },

        drop(scope: QueueScope): Promise<void> {
            return make({ type: 'queue.drop', scope });
        },

        watchList(service: string, consumer: string): SdkWatch<StoreQueueList> {
            return watch({ type: 'queue.watch.list', service, consumer }, 'queue.list');
        },

        watchStatus(scope: QueueScope): SdkWatch<QueueStatus> {
            return watch({ type: 'queue.watch.status', scope }, 'queue.status');
        },

        watchResults(scope: QueueScope): SdkWatch<QueueResult[]> {
            return watch({ type: 'queue.watch.results', scope }, 'queue.results');
        },
    };
}
