// @flow

import type { StateLocation } from './types';
import type StateStore from './node';
import type StateNode from './node';

import StatePath from './path';

const DEBUG = true;

export default class StateProxy {
    store: StateStore;
    nodes: StateNode[];

    constructor (store: Object, nodes: StateNode[]) {
        if (! nodes.length) {
            throw new Error(`Tried to create a state proxy without any root node.`);
        }

        this.store = store;
        this.nodes = nodes;
    }

    enter (location: StateLocation | StatePath, type: string): StateProxy {
        const node = this
            .nodes[0]
            .enter(this.store.getData(), location, type);

        return new StateProxy(this.store, [node].concat(this.nodes));
    }

    leave (): StateProxy {
        if (this.nodes.length < 2) {
            throw new Error(`Tried to leave root state proxy.`);
        }

        return new StateProxy(this.store, this.nodes.slice(1));
    }

    read (): any {
        return this
            .nodes[0]
            .read(this.store.getData());
    }

    assign (location: StateLocation, value: any): this {
        console.log('[state.proxy.assign]', this.nodes[0].path.toString(), location, value);

        this.store.setData(
            this.nodes[0].assign(this.store.getData(), location, value)
        );

        return this;
    }

    update (values: ?Object): this {
        console.log('[state.proxy.update]', this.nodes[0].path.toString(), values);

        this.store.setData(
            this.nodes[0].update(this.store.getData(), values)
        );

        return this;
    }

    remove (location: StateLocation): this {
        console.log('[state.proxy.remove]', this.nodes[0].path.toString(), location);

        this.store.setData(
            this.nodes[0].remove(this.store.getData(), location)
        );

        return this;
    }

    select (location: StateLocation): this {
        console.log('[state.proxy.select]', this.nodes[0].path.toString(), location);

        this.store.setData(
            this.nodes[0].select(this.store.getData(), location)
        );

        return this;
    }
}
