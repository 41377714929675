import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import Preview from '../../common/preview';
import Flag from '../../common/flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.typologies.fr.challenges.f500.preview';

    static propTypes = {
        data: PropTypes.object.isRequired,
        relevance: PropTypes.number.isRequired,
        onOpen: PropTypes.func.isRequired,
    };

    render () {
        return (
            <Preview
                flag={ <Flag char="F" shape="round" color="hot" type="full" /> }
                title={ t('dna.deefind.typology.challengesTitle', { value: this.props.data.rank}) }
                relevance={ this.props.relevance }
                onOpen={ this.props.onOpen }

                label1={t('common.label.value')}
                value1={
                    <Views.Common.Number
                        type="currency"
                        scale="M"
                        value={ this.props.data.value }
                    />
                }

                label2={ t('common.label.company') }
                value2={ this.props.data.company }
            />
        );
    }
}
