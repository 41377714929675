import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import colors from 'app/theme/colors';
import Entry from './common/entry';
import Flag from './common/flag';

import WocoFlag from './woco/flag';
import WocoDisplay from './woco/display';

import FrChallengesF500 from './typologies/fr.challenges.f500/display';
import FrJdnIncomes from './typologies/fr.jdn.incomes/display';
import FrOpendataMayors from './typologies/fr.opendata.mayors/display';
import FrOpendataDeputies from './typologies/fr.opendata.deputies/display';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    modal: {
        width: '800px',
    },
    tabs: {
        position: 'absolute',
        top: '19px',
        left: '0',
        paddingLeft: '20px',
        borderBottom: '1px solid ' + colors.grey300,
        display: 'flex',
        zIndex: '99999',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.compliance.display';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { index: 0 };
    }

    open(template) {
        let entryIndex = 0;

        _.each(this.state.entries, (entry, index) => {
            if (entry.template === template) {
                entryIndex = index;
            }
        });

        this.setState({ index: entryIndex }, () => {
            this.refs.modal.open();
        });
    }

    render() {
        const entries = this.props.entity.files || [];

        return (
            <Views.Modal.Show ref="modal" style={styles.modal} title="&nbsp;">
                <div style={styles.tabs}>{entries.map(this.renderEntry.bind(this))}</div>

                {this.renderContent(entries)}
            </Views.Modal.Show>
        );
    }

    renderEntry(entry, index) {
        return (
            <Entry
                key={`entry_${index}`}
                flag={this.renderFlag(entry)}
                relevance={entry.relevance}
                current={index === this.state.index}
                onOpen={event => this.setState({ index })}
            />
        );
    }

    renderFlag(entry) {
        switch (entry.data.type) {
            case 'fortune':
                return <Flag char="F" shape="round" color="hot" type="full" />;
            case 'politic':
                return <Flag char="P" shape="square" color="cold" type="full" />;
            case 'justice':
                return <Flag char="J" shape="square" color="cold" type="full" />;
            case 'compliance':
                return <Flag char="C" shape="round" color="cold" type="full" />;
            case 'mandate':
                return <Flag char="M" shape="square" color="cold" type="full" />;
        }
    }

    renderContent(entries) {
        if (!entries.length) {
            return <Views.Common.Empty title="No details available with your account." />;
        }

        const entry = entries[this.state.index];

        if (!entry) {
            return <Views.Common.Empty title="Detailed data inaccessible" />;
        }

        switch (entry.data.type) {
            case 'fortune':
                return <FrChallengesF500 data={entry.data} relevance={entry.relevance} />;

            case 'politic':
            case 'justice':
            case 'compliance':
            case 'mandate':
                return <WocoDisplay data={entry.data} relevance={entry.relevance} />;

            // case 'fr.jdn.incomes': return (<FrJdnIncomes { ...props } />);
            // case 'fr.opendata.mayors': return (<FrOpendataMayors { ...props } />);
            // case 'fr.opendata.deputies': return (<FrOpendataDeputies { ...props } />);
        }
    }
}
