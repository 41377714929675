import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import omit from 'lodash/omit';

const MB = 1024 * 1024;

const styles = {
    container: {
        display: 'inline-block',
    },
    button: {
        width: '200px',
        textAlign: 'center',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.nda.file';

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        uploaded: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onReadDocument: PropTypes.func.isRequired,
    };

    state = { content: null, pdf: null };

    render() {
        return (
            <div style={styles.container}>
                {this.renderUpload()}
                {this.renderView()}
                {this.renderDownload()}
            </div>
        );
    }

    handleUpload(event) {
        const file = event.target.files[0];

        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = async event => {
            const content = event.target.result;
            this.setState({ content });
            this.props.onChange(content);
        };

        reader.readAsArrayBuffer(file);
    }

    async handleView() {
        const pdf = await PDFJS.getDocument(this.state.content || await this.props.onReadDocument()).promise;
        this.refs.modal.open(pdf);
    }

    async handleDownload(event) {
        const url = await this.props.onReadDocument();
        var element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', url);
        element.setAttribute('target', '_blank');
        element.style.display = '';

        element.click();

        document.body.removeChild(element);
    }

    renderUpload() {
        if (this.props.readOnly) {
            return null;
        }

        return (
            <span>
                <input
                    ref={element => (this.inputElement = element)}
                    type="file"
                    hidden
                    accept=".pdf"
                    onChange={this.handleUpload.bind(this)}
                />

                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Upload"
                    icon={<Icon type="do.upload" />}
                    style={styles.button}
                    onClick={event => this.inputElement.click()}
                />
            </span>
        );
    }

    renderView() {
        if (!this.props.uploaded) {
            return null;
        }

        return (
            <span>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Show"
                    icon={<Icon type="do.watch" />}
                    style={styles.button}
                    onClick={this.handleView.bind(this)}
                />

                <Views.Modal.Pdf onTop={true} ref="modal" />
            </span>
        );
    }

    renderDownload() {
        if (!this.props.uploaded) {
            return null;
        }

        return (
            <span>
                <Views.Common.Button
                    type="flat"
                    color="cold"
                    label="Download"
                    icon={<Icon type="do.download" />}
                    style={styles.button}
                    onClick={this.handleDownload.bind(this)}
                />
            </span>
        );
    }
}
