// @flow

import type { ScreenStage } from './types';

import React from 'react';
import Scope from 'app/layout/providers/scope';
import TabsContainer from './tabs/container';
import TabsPlaceholder from './tabs/placeholder';
import TabsEntry from './tabs/entry';
import TabsGroup from './tabs/group';

export default class extends React.PureComponent<any, any> {
    static displayName = 'app.layout.screen.tabs';

    props: {
        state: ?Object,
        stages: { [type: string]: ScreenStage },
        add?: Function,
        select: Function,
        close: Function,
    };

    render () {
        const cursor = this.props.state
            ? this.props.state.cursor || 0
            : 0;

        const items = this.props.state
            ? this.props.state.items || []
            : [];

        if (! items.length) {
            return <TabsPlaceholder />;
        }

        const add = items.length < 30
            ? this.props.add
            : null;

        return (
            <TabsContainer add={ add }>
                { this.renderTabs(items, cursor) }
            </TabsContainer>
        )
    }

    renderTabs (items: Object[], cursor: number): any {
        if (items.length < 11) {
            return items.map((state: Object, index: number) => this.renderItem(index, state, cursor));
        }

        const tabs = [];
        let index = 0;

        while (index < items.length && items[index].pinned) {
            tabs.push(this.renderItem(index, items[index], cursor));
            index += 1;
        }

        while (index + 7 <= cursor) {
            tabs.push(this.renderGroup(index + 6, 7));
            index += 7;
        }

        for (let i = 0; i < 7; i ++) {
            if (index >= items.length) {
                return tabs;
            }

            tabs.push(this.renderItem(index, items[index], cursor));
            index += 1;
        }

        while (index + 7 < items.length) {
            tabs.push(this.renderGroup(index, 7));
            index += 7;
        }

        // while (index <= items.length - 1) {
        //     tabs.push(this.renderItem(index, items[index], cursor));
        //     index += 1;
        // }

        if (index < items.length) {
            tabs.push(this.renderGroup(index, items.length - index));
        }

        return tabs.filter(v => !! v);
    }

    renderItem (index: number, state: Object, cursor: number) {
        const stage = state ? this.props.stages[state.type] : null;

        if (! stage) {
            console.error(`Found an invalid stage state: ${JSON.stringify(state)}`);
            return null;
        }

        let entry = stage.entry;

        if (typeof entry === 'function') {
            entry = entry(state);
        }

        if (! entry) {
            console.error(`Found an invalid stage state: ${JSON.stringify(state)}`);
            return null;
        }

        const select = this.props.select
            ? event => this.props.select(index)
            : undefined;

        const close = this.props.close
            ? event => this.props.close(index)
            : undefined;

        return (
            <Scope
                key={ `s${index}`}
                path={ index }
                type="router"
            >
                <TabsEntry
                    icon={ entry.icon }
                    label={ entry.label }
                    count={ entry.count }
                    current={ cursor === index }
                    select={ select }
                    close={ state && state.pinned ? null : close }
                />
            </Scope>
        );
    }

    renderGroup (index: number, count: number) {
        const select = this.props.select
            ? event => this.props.select(index)
            : undefined;

        return (
            <TabsGroup
                key={ `g${index}`}
                count={ count }
                select={ select }
            />
        );
    }
}
