import Moment from 'moment';
import { get } from 'lodash';

export default async function(field: Object, services: Object): string {
    const question = field.questionPayload || {};
    const answer = field.answerEditPayload || field.answerPayload || {};

    switch (question.type) {
        case 'text':
        case 'string':
        case 'number':
            return answer.value ? String(answer.value) : '';

        case 'boolean':
            if (answer.value === true) return 'Yes';
            if (answer.value === false) return 'No';
            return '';

        case 'date': {
            if (!answer.value) return '';
            const date = Moment(answer.value);
            return date.isValid() ? date.format('DD/MM/YYYY') : '';
        }

        case 'object':
            return answer.values
                ? answer.values
                      .filter(value => !!value)
                      .map(value => String(value))
                      .join(' — ')
                : '';

        case 'select': {
            const choices = get(question, 'options.choices', []).map(choice => choice.label);

            return (answer.choices || [])
                .map(choice => choices[choice])
                .filter(v => !!v)
                .join(' — ');
        }

        default:
            return '';
    }
}
