import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/theme/icon';
import Views from 'app/views';
import Card from './card';

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.archives.summary.list';

    static propTypes = {
        account: PropTypes.string,
        type: PropTypes.string.isRequired,
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        onCreate: PropTypes.func.isRequired,
        state: PropTypes.object,
    };

    render() {
        return (
            <Views.List.Container entities={this.props.entities} state={this.props.state}>
                <Views.List.Reducer
                    type="sort"
                    specs={{
                        aum: { label: 'Expected AUM', path: entity => entity.payload.expectedAum },
                        manager: { label: 'Company name', path: 'payload.manager' },
                    }}
                />

                <Views.List.Reducer
                    type="groups"
                    name="status"
                    title=""
                    specs={{}}
                />

                <Views.List.Reducer
                    type="fuse"
                    specs={{
                        paths: ['payload.manager', 'payload.fund'],
                        threshold: 0.1
                    }}
                />

                <Views.List.Frame type="cards" element={<Card />} />
            </Views.List.Container>
        );
    }
}
