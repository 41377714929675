// @flow

import type { CountsType, CountsSegment, augmentedCountsType } from '../types';

export function makeBaseCounts(): CountsType {
    return {
        inputs: 0,
        missing: 0,
        empty: 0,
        ambiguous: 0,
        workable: 0,
        total: 0,
        personResults: 0,
        companyResults: 0,
        selected: 0,
        excluded: 0,
        riskSelected: 0,
        riskExcluded: 0,
        potentialHigh: 0,
        potentialMid: 0,
        potentialLow: 0,
        potentialSelected: 0,
        potentialExcluded: 0,
        potentialCovid19: 0,
        potentialCovid19Selected: 0,
        potentialCovid19Excluded: 0,
        expectedValueSelected: 0,
        expectedValueExcluded: 0,
        potentialCashoutSelected: 0,
        potentialCashoutExcluded: 0,
        probabilitySelected: 0,
        probabilityExcluded: 0,
        proximitySelected: 0,
        proximityExcluded: 0,
        predictions: 0,
        predictionsAge: 0,
        predictionsVc: 0,
        upsellSelected: 0,
        upsellExcluded: 0,
        upsellPrctSelected: 0,
        upsellPrctExcluded: 0,
        completenessSelected: 0,
        completenessExcluded: 0,
        segment11: makeBaseSegment(),
        segment12: makeBaseSegment(),
        segment13: makeBaseSegment(),
        segment21: makeBaseSegment(),
        segment22: makeBaseSegment(),
        segment23: makeBaseSegment(),
        segment31: makeBaseSegment(),
        segment32: makeBaseSegment(),
        segment33: makeBaseSegment(),
    };
}

export function makeAugmentedBaseCounts(): augmentedCountsType {
    return {
        inputs: 0,
        empty: 0,
        ambiguous: 0,
        workable: 0,
        total: 0,
        personResults: 0,
        companyResults: 0,
        reechColumns: [0, 0, 0, 0],
        reechColumnsPrct: [0, 0, 0, 0],
        computedColumns: [0, 0, 0, 0],
        computedColumnsPrct: [0, 0, 0, 0],
        sameColumn: [0,0,0,0],
        aumOverPotential: [0,0,0,0],
        risk:{
            risk1: 0,
            risk2: 0,
            risk3: 0,
            risk4: 0,
        },
        committee: {
            Retail: 0,
            BP: 0,
            BAE: 0,
        },
        migrations: [
            {
                exit: 0,
                entry: 0,
                exitPrct: 0,
                entryPrct: 0,
            },
            {
                exit: 0,
                entry: 0,
                exitPrct: 0,
                entryPrct: 0,
            },
            {
                exit: 0,
                entry: 0,
                exitPrct: 0,
                entryPrct: 0,
            },
            {
                exit: 0,
                entry: 0,
                exitPrct: 0,
                entryPrct: 0,
            },
        ],
        segment11: [],
        segment12: [],
        segment13: [],
        segment21: [],
        segment22: [],
        segment23: [],
        segment31: [],
        segment32: [],
        segment33: [],
    };
}

export function makeBaseSegment(): CountsSegment {
    return {
        ids: [],
        completeness: { low: 0, mid: 0, high: 0 },
        extra: { low: 0, mid: 0, high: 0 },
    };
}
