// @flow

import get from 'lodash/get';
import sum from 'lodash/sum';
import orderBy from 'lodash/orderBy';

export default function computeScoring(scoring: {}) {
    const details = [];
    const counts = { bScore: 0, bTotal: 0, wScore: 0, wTotal: 0, total: 0 };
    const weightSum = sum(Object.values(scoring || {}).map(stats => get(stats, 'weight') || 0));
    const ratings = [];

    for (const category of Object.keys(scoring)) {
        const stats = scoring[category] || {};

        if (stats.weight && stats.total) {
            const score = (100 * stats.score) / stats.total;
            const total = ((stats.weight || 0) * score) / weightSum;
            const rating = (4 * (stats.score / stats.total)) + 1;
            ratings.push(rating);

            counts.bScore += stats.score;
            counts.bTotal += stats.total;
            counts.wScore += stats.score * (stats.weight || 0);
            counts.wTotal += stats.total * (stats.weight || 0);
            counts.total += total;

            details.push({
                label: category,
                score,
                weight: weightSum ? ((stats.weight || 0) * 100) / weightSum : 0,
                total,
                rating: Math.round(rating),
            });
        }
    }

    return {
        rating: ratings.length ? Math.round(sum(ratings) / ratings.length) : 5,
        total: {
            score: (100 * counts.bScore) / counts.bTotal,
            weight: 100,
            total: counts.total,
        },
        details: orderBy(details, 'label', 'asc'),
    };
}
