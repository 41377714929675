// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import Summary from './summary/index';
import Display from './display/index';

import { inject, observer } from 'mobx-react';
import t from 'app/translation';

@inject(stores => ({ store: stores.comeetProspects }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'comeet.prospects';

    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        openProspect: PropTypes.func.isRequired,
        createProspect: PropTypes.func.isRequired,
        updateProspect: PropTypes.func.isRequired,
        startProspect: PropTypes.func.isRequired,
        fetchProspect: PropTypes.func.isRequired,
        endProspect: PropTypes.func.isRequired,
        createEvent: PropTypes.func.isRequired,
        updateEvent: PropTypes.func.isRequired,
        removeEvent: PropTypes.func.isRequired,
        createMilestone: PropTypes.func.isRequired,
        updateMilestone: PropTypes.func.isRequired,
        removeMilestone: PropTypes.func.isRequired,
    };

    getChildContext () {
        return {
            openProspect: this.props.store.openProspect,
            createProspect: this.props.store.createProspect,
            updateProspect: this.props.store.updateProspect,
            fetchProspect: this.props.store.fetchProspect,
            startProspect: this.props.store.startProspect,
            endProspect: this.props.store.endProspect,
            createEvent: this.props.store.createEvent,
            updateEvent: this.props.store.updateEvent,
            removeEvent: this.props.store.removeEvent,
            createMilestone: this.props.store.createMilestone,
            updateMilestone: this.props.store.updateMilestone,
            removeMilestone: this.props.store.removeMilestone,
        };
    }

    render () {
        return (
          <Screen
              path="prospects"
              type="workspace"
              fallback="summary"

              select={ this.props.store.selectStage }
              close={ this.props.store.closeStage }

              stages={{
                  summary: {
                      entry: { icon: 'comeet.summary', label: 'Summary' },
                      content: Summary
                  },
                  display: {
                      entry: this.computeEntry.bind(this),
                      content: Display
                  }
              }}
          />
        );
    }


    computeEntry (state?: Object) {
        let label = t('common.message.loading');

        if (state) {
            const prospect = this.props.store.prospects.get(state.id);
            if (prospect && prospect.payload) {
                label = prospect.payload.target.name;
            }
        }

        return {
            icon: 'comeet.prospect',
            label
        };
    }
}
