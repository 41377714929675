// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Screen from 'app/layout/screen';
import Studies from './studies';
import { inject } from 'mobx-react';

@inject((stores) => ({ store: stores.deeligenzLibrary }))
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.library';

    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    render() {
        return (
            <Screen
                path="library"
                type="workspace"
                select={this.props.store.selectStage}
                close={this.props.store.closeStage}
                stages={{
                    studies: {
                        entry: { icon: 'deeligenz.studies', label: 'Due diligences' },
                        content: Studies,
                    },
                }}
            />
        );
    }
}
