import PropTypes from 'prop-types';
import React from 'react';

import Views from 'app/views';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';

import t from 'app/translation';
import Sum from 'dna/entity/common/display/sum';

const LABELS = {
    i: 'investor',
    s: 'shareholder',
    b: 'boardmember',
    a: 'advisor',
    c: 'councilor',
    x: 'executive',
    m: 'manager',
}

const styles = {

};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.cockpit.companies';

    static contextTypes = {
        updateState: PropTypes.func.isRequired,
    };

    static propTypes = {
        entity: PropTypes.object.isRequired,
        network: PropTypes.object.isRequired
    };

    render () {
        const data = this.props.network.relations.companies;

        return (
            <div style={{marginTop: '5px', marginRight: '5px', marginLeft: '5px', border: '2px solid '+colors.grey400 }}>
                { this.renderHeader() }
                <div>
                  <div style={{padding: '5px'}}>
                    <Views.Common.Properties
                        values={ this.formatValues(this.props.network.computed.sums.companies) }
                        align={ 80 }
                    />
                  </div>
                  <div style={{borderTop: '1px solid ' + colors.grey300, padding: '5px'}}>
                    <Views.Chart.Container
                      title='companies'
                      type="half-pie"
                      style={ styles.chart }
                      >
                        { this.renderEntries(data) }
                      </Views.Chart.Container>
                  </div>
                  <div style={{borderTop: '1px solid ' + colors.grey300, padding: '5px'}}>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}>
                      <div style={{borderRight: '1px solid ' + colors.grey300, textAlign: 'center'}}>

                        <span>Ebitda</span>
                      </div>
                    </div>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>

                        <span>Turnover</span>

                    </div>
                  </div>

                  <div style={{borderTop: '1px solid ' + colors.grey300, padding: '5px'}}>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top'}}>
                      <div style={{borderRight: '1px solid ' + colors.grey300, textAlign: 'center'}}>

                        <div>
                          Moyen
                        </div>
                        <div>
                          Median
                        </div>

                      </div>
                    </div>
                    <div style={{width: '50%', display: 'inline-block', verticalAlign: 'top', textAlign: 'center'}}>

                      <div>
                        Moyen
                      </div>
                      <div>
                        Median
                      </div>

                    </div>
                  </div>

                </div>
            </div>
        );
    }

    renderHeader() {
      return (
        <div style={{backgroundColor: colors.grey200, padding: '5px'}}>
          <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '10%',textAlign: 'left' }}>
            <Icon style={{ height: '40px', width: '40px', fill: colors.grey400 }} type="dna.company" />
          </div>
          <div style={{ fontSize: '17px', verticalAlign: 'middle', display: 'inline-block', width: '40%',textAlign: 'left', color: colors.userCold }}>
            {t('common.label.companies')}
          </div>
        </div>
      );
    }

    formatValues (aggregates) {
        if (! aggregates) {
            return [];
        }

        return [
            { label: t('common.label.sumTurnover'), value: (<Sum data={ aggregates.turnover } />) },
            { label: t('dna.deefind.veegilenzData.person.sumCapitalLabel'), value: (<Sum data={ aggregates.capital } />) },
            { label: t('dna.deefind.veegilenzData.person.sumEbitdaLabel'), value: (<Sum data={ aggregates.ebitda } />) },
            { label: t('dna.deefind.veegilenzData.person.sumValuationLabel'), value: (<Sum data={ aggregates.valuation } />) },
        ];
    }

    renderEntries (list) {
      const data = {};
      list.map(item => {
        if (!data[item.level]) {
          data[item.level] = {ratio: 0, level: item.level, label: LABELS[item.level]}
        }
        data[item.level].ratio += 1;
      })

        return Object.values(data).map((entry, index) => (
            <Views.Chart.Entry
                key={ 'e' + index }
                label={ entry.label  }
                value={ entry.ratio / list.length * 100  }
            />
        ));
    }
}
