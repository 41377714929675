// @flow

import type { ResolverSpecs } from 'app/stores/state/types';
import type { ScreenStage } from './types';

import React from 'react';
import Scope from 'app/layout/providers/scope';
import Stage from './stage';
import Tabs from './tabs';

export default class extends React.Component<any, any> {
    static displayName = 'app.layout.screen';

    scope: ResolverSpecs;

    props: {
        path: string,
        stages: { [type: string]: ScreenStage },

        fallback: string,
        empty?: Function,

        add?: Function,
        select?: Function,
        close?: Function,
    };

    render () {
        return (
            <div>
                <Scope path={ this.props.path } type="menu">
                    <Tabs
                        stages={ this.props.stages }
                        add={ this.props.add }
                        select={ this.props.select }
                        close={ this.props.close }
                    />
                </Scope>

                <Scope path={ this.props.path } type="menu">
                    <Stage
                        stages={ this.props.stages }
                        fallback={ this.props.fallback }
                        empty={ this.props.empty }
                    />
                </Scope>
            </div>
        );
    }
}
