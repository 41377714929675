// @flow

import ArrayResolver from './base/array';
import BooleanResolver from './base/boolean';
import NumberResolver from './base/number';
import ObjectResolver from './base/object';
import StringResolver from './base/string';

import RouterResolver from './struc/router';
import MenuResolver from './struc/menu';
import ListResolver from './struc/list';

export default {

    // base
    boolean: BooleanResolver,
    number: NumberResolver,
    string: StringResolver,
    array: ArrayResolver,
    object: ObjectResolver,

    // struct
    menu: MenuResolver,
    router: RouterResolver,
    list: ListResolver,

    // form: FormResolver,
    // timeline: TimelineResolver,

};
