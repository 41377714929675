import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Button from '../../common/button';

const styles = {
    container: {
    },
    title: {
        background: colors.grey200,
        borderRadius: '3px',
        padding: '10px',
        fontWeight: 'bold',
        color: colors.userCold,
        margin: '10px 0',
    },
    pager: {
        width: '100%',
        borderTop: '1px solid ' + colors.grey300,
        margin: '10px 0',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.body.frame';

    static propTypes = {
        title: PropTypes.node,
        folded: PropTypes.bool.isRequired,
        entities: PropTypes.arrayOf(PropTypes.object).isRequired,
        size: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
        onPaginate: PropTypes.func.isRequired,
    };

    render () {
        return (
            <div style={ styles.container }>
                { this.renderTitle() }
                { this.renderChildren() }
                { this.renderPager() }
            </div>
        );
    }

    renderTitle () {
        if (! this.props.title) {
            return null;
        }

        return (
            <div style={ styles.title }>
                { this.props.title }
            </div>
        );
    }

    renderChildren () {
        const entities = this.props.entities.slice(0, this.props.size * this.props.page);

        return React.Children
            .toArray(this.props.children)
            .map((child, index) => React.cloneElement(child, Object.assign({}, child.props, {
                key: 'c' + index,
                entities: entities,
            })));
    }

    renderPager () {
        const size = this.props.size * this.props.page;
        const length = this.props.entities.length;

        if (size >= length) {
            return null;
        }

        const missing = length - size;

        const label = missing > this.props.size
            ? `Show ${this.props.size} more`
            : `Show the ${missing} last`;

        return (
            <Button
                type="flat"
                color="hot"
                style={ styles.pager }
                icon={ <Icon type="nav.drop.down" /> }
                label={ label }
                onClick={ event => this.props.onPaginate(this.props.page + 1) }
            />
        );
    }
}
