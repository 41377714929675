// @flow

import type { EntityType, LinkProfile, LinkTop } from '@deecision/deefind-types/model';

import orderBy from 'lodash/orderBy';

export default function makeLinkTop(links: LinkProfile[], type: EntityType): ?LinkTop {
    const candidates = orderBy(
        links.filter(link => !link.ceased && link.target.type === type && typeof link.rank === 'number'),
        'rank',
        'desc',
    );

    return candidates.length && candidates[0].rank > 0
        ? { id: candidates[0].target.id, name: candidates[0].target.name, country: candidates[0].target.country }
        : undefined;
}
