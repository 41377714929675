import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';
import Modal from './modal';

const ITEMS = [
    { scope: 'ddq', label: 'Analysis remark' },
    { scope: 'onSite', label: 'Meeting remark' },
    { scope: 'report', label: 'Report remark' },
];

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.survey.field.remarks';

    static propTypes = {
        answer: PropTypes.object.isRequired,
        scope: PropTypes.string.isRequired,
        onRemark: PropTypes.func,
    };

    render() {
        if (this.props.scope === 'input') {
            return null;
        }

        const nodes = [];

        for (const item of ITEMS) {
            const edit = item.scope === this.props.scope && !!this.props.onRemark;
            const value = this.props.answer && this.props.answer[item.scope + 'Remark'];

            if (edit || value) {
                nodes.push(this.renderButton(item.scope, item.label, value, edit));
                nodes.push(this.renderModal(item.scope, item.label, value, edit));
            }
        }

        return nodes;
    }

    renderButton(scope: string, label: string, value: ?string, edit: boolean) {
        return (
            <Button
                key={scope + '_button'}
                label={label}
                value={!!value}
                onClick={edit || value ? () => this.refs[scope + '_modal'].open() : undefined}
            />
        );
    }

    renderModal(scope: string, label: string, value: ?string, edit: boolean) {
        return (
            <Modal
                key={scope + '_modal'}
                ref={scope + '_modal'}
                label={label}
                value={value}
                onChange={edit ? (value: string) => this.props.onRemark(scope, value) : undefined}
            />
        );
    }
}
