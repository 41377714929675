import React from 'react';
import PropTypes from 'prop-types';
import Views from 'app/views';
import Select from 'app/views/form/widget/select';
import Icon from 'app/theme/icon';
import colors from 'app/theme/colors';
import { isString } from 'lodash';
import Edit from './edit';

const styles = {
    container: {},
    modal: {
        width: '1000px',
    },
    separator: {
        padding: '20px',
        background: colors.grey200,
        borderRadius: '3px',
    },
    wrapper: {
        display: 'flex',
    },
    block: {
        padding: '20px',
    },
    title: {
        fontSize: '17px',
    },
    duration: {
        clear: 'both',
        display: 'inline-block',
        color: colors.grey500,
        fontSize: '17px',
        marginLeft: '15px',
    },
    summaryTitle: {
        color: colors.userCold,
        margin: '15px 0 5px',
    },
    summaryValue: {
        color: colors.grey500,
        fontSize: '13px',
        margin: '2px 0',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.onSite.program';

    static propTypes = {
        program: PropTypes.array.isRequired,
        style: PropTypes.object,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func,
    };

    state = {
        edit: false,
    };

    render() {
        return (
            <span>
                <Views.Common.Button
                    type="raised"
                    color="cold"
                    icon={<Icon type="do.watch" />}
                    label="Show program"
                    onClick={event => this.refs['modal'].open()}
                    style={this.props.style}
                />

                <Views.Modal.Show
                    ref="modal"
                    icon={<Icon type="do.watch" />}
                    title="On-site visit program"
                    style={styles.modal}
                >
                    <div>
                        {this.state.edit ? (
                            <Edit
                                onChange={program => {
                                    this.props.onChange(program);
                                    this.setState({ edit: false });
                                }}
                                program={this.props.program}
                            />
                        ) : (
                            <div style={styles.container}>
                                <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                                    {!this.props.readOnly ? <Views.Common.Button
                                        icon={<Icon type="do.update" />}
                                        type={'round'}
                                        mini={true}
                                        color="cold"
                                        style={{ marginTop: '10px', marginLeft: '10px', verticalAlign: 'baseline' }}
                                        onClick={() => {
                                            this.setState({ edit: !this.state.edit });
                                        }}
                                    /> : null }
                                </div>
                                {this.props.program.map((item, index) =>
                                    item.fixed ? this.renderSeparator(item, index) : this.renderBlock(item, index),
                                )}
                            </div>
                        )}
                    </div>
                </Views.Modal.Show>
            </span>
        );
    }

    renderSeparator(data, index) {
        return (
            <div key={index} style={styles.separator}>
                <span style={styles.title}>{data.title}</span>
                <span style={styles.duration}>{data.duration} minutes</span>
            </div>
        );
    }

    renderBlock(data, index) {
        return (
            <div key={index} style={styles.block}>
                <span style={styles.title}>{data.title}</span>
                <span style={styles.duration}>{data.duration} minutes</span>

                {(data.summary || []).map((summary, i1) => (
                    <div key={'title_' + i1} style={styles.summary}>
                        <div key="title" style={styles.summaryTitle}>
                            {summary ? summary.title : null}
                        </div>

                        {(summary ? summary.values || [] : []).map((value, i2) => (
                            <div key={'item_' + i1 + '_' + i2} style={styles.summaryValue}>
                                {value ? value.title||value : value}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}
