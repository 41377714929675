import PropTypes from 'prop-types';
import React from 'react';
import Display from '../../common/display';
import Flag from '../../common/flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.typologies.fr.opendata.deputies.mayors';

    static propTypes = {
        relevance: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        relations: PropTypes.array,
    };

    render () {
        return (
            <Display
                flag={ <Flag char="P" shape="square" color="cold" type="full" /> }
                relevance={ this.props.relevance }
                relations={ this.props.relations }

                name={ this.props.data.person.name }
                title={ t('dna.deefind.typology.mayorsTitle')}
                contents={[]}
                sources={[]}
                properties={[
                    {
                        label: t('common.label.birthdate'),
                        value: this.props.data.person.birthDate
                    },
                    {
                        label: t('dna.deefind.veegilenzData.mayors.cityNameLabel'),
                        value: this.props.data.city.name
                    },
                    {
                        label: t('dna.deefind.veegilenzData.mayors.cityDistrictLabel'),
                        value: this.props.data.city.department
                    },
                    {
                        label: t('common.label.population'),
                        value: this.props.data.city.population
                    },
                    {
                        label: t('common.label.position'),
                        value: this.props.data.job.label
                    }
                ]}
            />
        );
    }
}
