// @flow

import type { ComputedModel } from '@deecision/deeligenz-types';

import computeField from './field';
import computeNext from './next';
import computeScoring from './scoring';

import { filterFields } from './filter';
import { findCategory } from './find';
import { countWorkDays, addWorkDays } from '../index';

import get from 'lodash/get';
import Moment from 'moment';

export default function (entity: any, fields: Object[]): ?ComputedModel {
    if (!entity) {
        return null;
    }

    const starts = {};

    for (const event of entity.history || []) {
        switch (event.transition) {
            case 'start':
                starts.input = event.createStamp;
                break;

            case 'approveInput':
                starts.ddq = event.createStamp;
                break;

            case 'approveDdq':
                starts.onSite = event.createStamp;
                break;

            case 'approveOnSite':
                starts.report = event.createStamp;
                break;
            case 'approveReport':
                starts.approved = event.createStamp;
                break;
            case 'confirmReport':
                starts.confirmed = event.createStamp;
                break;
            case 'ignoreRedFlag':
                starts.ignoreRedFlag = event.createStamp;
                break;
        }
    }

    const start = starts[entity.status];
    const duration = get(entity, `payload.durations.${entity.status}`, 0);
    const timeLeft = duration - (start ? Moment().diff(Moment(start), 'days') : 0);
    const scoring = {};
    const nas = [];
    const esg = {};

    const phases = {
        input: { count: 0, done: 0 },
        ddq: { count: 0, done: 0 },
        report: { count: 0, done: 0 },
    };

    const counts = {
        concern: 0,
        redFlag: 0,
    };

    const indexedReport = {};
    const indexedKeyFinding = [];
    const mainIssues = {};

    let index = 0;

    for (const field of filterFields(fields.map((field) => computeField(field)))) {
        const question = field.questionPayload || {};
        const answer = field.answerEditPayload ? field.answerEditPayload : field.answerPayload || {};

        const flags = Object.assign(
            {},
            field.answerPayload ? field.answerPayload.flags : {},
            field.answerEditPayload ? field.answerEditPayload.flags : {},
        );

        if (flags.keyFinding) {
            indexedKeyFinding.push(Object.assign({}, { index, category: question.category.label }, flags.keyFinding));
        }

        if (question.report) {
            indexedReport[question.report] = index;
        }

        index++;

        if (question.kind === 'F' && get(entity, 'payload.target.type') !== 'fund') {
            continue;
        }

        const bothScope = !question.scope || question.scope === 'both' || question.scope === 'odd';
        const ddqScope = question.scope === 'analysis' || question.scope === 'ddq';

        if (bothScope || question.scope === 'input') {
            phases.input.count += 1;

            if (['validated', 'pending'].includes(field.status)) {
                phases.input.done += 1;
            }
        }

        if (bothScope || ddqScope) {
            phases.ddq.count += 1;

            if (flags.allRight || flags.concern || flags.redFlag) {
                phases.ddq.done += 1;
            }

            if (flags.concern) counts.concern += 1;
            if (flags.redFlag) counts.redFlag += 1;
            if (answer.na) nas.push(question.label);
        }

        let category = findCategory(question.flags);

        if (question.weight > 0 && category) {
            const maxScore = parseInt(question.weight);
            let answerScore = maxScore;

            if (!scoring[category]) {
                scoring[category] = {
                    score: 0,
                    total: 0,
                    weight: question.category.weight || 0,
                    kind: question.kind,
                };
            }

            if (answer && answer.draft) {
                continue;
            }

            switch (question.type) {
                case 'boolean':
                    if (answer.value === true) {
                        answerScore = parseInt(get(question, 'options.scores.yes')) || 0;
                    } else if (answer.value === false) {
                        answerScore = parseInt(get(question, 'options.scores.no')) || 0;
                    }
                    break;

                case 'select':
                    const choices = question.options ? question.options.choices || [] : [];
                    const scores = [];

                    for (const index of answer.choices || []) {
                        if (choices[index] && typeof choices[index].score === 'number') {
                            scores.push(choices[index].score);
                        }
                    }

                    if (scores.length) {
                        answerScore = Math.max(...scores);
                    }

                    break;
            }

            scoring[category].score += answerScore;
            scoring[category].total += maxScore;
        }

        if (question.weightEsg > 0 && category) {
            const maxScore = parseInt(question.weightEsg);
            let answerScore = 0;

            if (!esg[category]) {
                esg[category] = {
                    score: 0,
                    total: 0,
                    weight: question.category.weightEsg || 0,
                    kind: question.kind,
                };
            }

            switch (question.type) {
                case 'boolean':
                    if (answer.value === true) {
                        answerScore = parseInt(get(question, 'options.scoresEsg.yes')) || 0;
                    } else if (answer.value === false) {
                        answerScore = parseInt(get(question, 'options.scoresEsg.no')) || 0;
                    }
                    break;

                case 'select':
                    const choices = question.options ? question.options.choices || [] : [];
                    const scores = [];

                    for (const index of answer.choices || []) {
                        if (choices[index] && typeof choices[index].scoreEsg === 'number') {
                            scores.push(choices[index].scoreEsg);
                        }
                    }

                    if (scores.length) {
                        answerScore = Math.max(...scores);
                    }

                    break;
            }

            esg[category].score += answerScore;
            esg[category].total += maxScore;
        }

        const computedFlags = get(field, 'answerPayload.flags', {});

        if (category) {
            if (!mainIssues.hasOwnProperty(category)) {
                mainIssues[category] = {
                    label: findCategory(question.flags),
                    total: 0,
                    concern: 0,
                    redFlag: 0,
                    keyFinding: 0,
                };
            }

            mainIssues[category].total += 1;

            if (computedFlags.concern) {
                mainIssues[category].concern += 1;
            }
            if (computedFlags.redFlag) {
                mainIssues[category].redFlag += 1;
            }
            if (computedFlags.keyFinding) {
                mainIssues[category].keyFinding += 1;
            }
        }
    }

    const reports = get(entity, 'payload.report', {});

    for (const report of [
        'managerPresentation',
        'managerShareholding',
        'managerMilestones',
        'managerInvestors',
        'managerStrategy',
    ]) {
        phases.report.count += 1;

        if (reports[report]) {
            phases.report.done += 1;
        }
    }

    const progress = {
        input: phases.input.count ? Math.round((100 * phases.input.done) / phases.input.count) : 0,
        ddq: phases.ddq.count ? Math.round((100 * phases.ddq.done) / phases.ddq.count) : 0,
        onSite: 0,
        report: phases.report.count ? Math.round((100 * phases.report.done) / phases.report.count) : 0,
        total: 0,
    };

    if (starts.confirmed || starts.ignoreRedFlag) {
        progress.total = 100;
    } else if (starts.report) {
        progress.total = 90;
    } else if (starts.onSite) {
        progress.total = 60;
    } else if (starts.ddq) {
        progress.total = 40 + Math.round((20 * progress.ddq) / 100);
    } else if (starts.input) {
        progress.total = Math.round((40 * progress.input) / 100);
    }

    const review = computeNext(
        starts.ignoreRedFlag || starts.confirmed,
        get(entity, 'payload.target.variant'),
        get(entity, 'payload.rating', 0),
        get(entity, 'payload.expectedAum', 0),
    );

    const durations =
        get(entity, `payload.durations.input`, 0) +
        get(entity, `payload.durations.ddq`, 0) +
        get(entity, `payload.durations.onSite`, 0) +
        get(entity, `payload.durations.report`, 0);

    const timesLeft = {
        input: countWorkDays(Moment(), addWorkDays(Moment(starts.input), get(entity, `payload.durations.input`, 0))),
        ddq: countWorkDays(Moment(), addWorkDays(Moment(starts.ddq), get(entity, `payload.durations.ddq`, 0))),
        onSite: countWorkDays(Moment(), addWorkDays(Moment(starts.onSite), get(entity, `payload.durations.onSite`, 0))),
        report: countWorkDays(Moment(), addWorkDays(Moment(starts.report), get(entity, `payload.durations.report`, 0))),
    };

    const timesSpent = {
        input: starts.input ? countWorkDays(Moment(starts.input), Moment()) : 0,
        ddq: starts.ddq ? countWorkDays(Moment(starts.ddq), Moment()) : 0,
        onSite: starts.onSite ? countWorkDays(Moment(starts.onSite), Moment()) : 0,
        report: starts.report ? countWorkDays(Moment(starts.report), Moment()) : 0,
    };

    let deadline = '';

    if (starts.confirmed || starts.ignoreRedFlag) {
        deadline = starts.confirmed || starts.ignoreRedFlag;
    } else if (starts.report) {
        deadline = addWorkDays(Moment(starts.report), get(entity, `payload.durations.report`, 0))
            .toDate()
            .toISOString();
    } else if (starts.onSite) {
        deadline = addWorkDays(
            Moment(starts.onSite),
            get(entity, `payload.durations.onSite`, 0) + get(entity, `payload.durations.report`, 0),
        )
            .toDate()
            .toISOString();
    } else if (starts.ddq) {
        deadline = addWorkDays(
            Moment(starts.onSite),
            get(entity, `payload.durations.ddq`, 0) +
                get(entity, `payload.durations.onSite`, 0) +
                get(entity, `payload.durations.report`, 0),
        )
            .toDate()
            .toISOString();
    } else {
        deadline = addWorkDays(
            Moment(starts.onSite),
            get(entity, `payload.durations.input`, 0) +
                get(entity, `payload.durations.ddq`, 0) +
                get(entity, `payload.durations.onSite`, 0) +
                get(entity, `payload.durations.report`, 0),
        )
            .toDate()
            .toISOString();
    }

    return {
        computed: { timesLeft, timesSpent },
        mainIssues,
        indexes: { keyFindings: indexedKeyFinding, report: indexedReport },
        dates: {
            begin: Moment(starts.input),
            end: Moment(starts.input).add(durations, 'days'),
            approved: starts
        },
        nextReview: review,
        deadline,
        timeLeft,
        progress,
        counts,
        nas,
        scoring: computeScoring(scoring),
        scoringEsg: computeScoring(esg),
    };
}
