import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import t from 'app/translation';
import { formatStockValue } from '../../utils';

const styles = {
    container: {
        flexGrow: 1,
        flexBasis: '300px',
    },
    ceased: {
        opacity: 0.5,
        textDecoration: 'line-through',
    },
    arrow: {
        position: 'absolute',
        bottom: '-16px',
        fill: colors.grey500,
        color: colors.grey500,
        width: '30px',
        height: '30px',
        lineHeight: '30px',
        fontSize: '17px',
    },
    forwardArrow: {
        right: '-10px',
    },
    backwardArrow: {
        left: '-10px',
    },
    header: {
        position: 'relative',
        borderBottom: '1px solid ' + colors.grey500,
        lineHeight: '12px',
        fontSize: '13px',
        textAlign: 'center',
        padding: '10px 5px',
    },
    level: {
        display: 'inline-block',
        margin: '0 10px',
        color: colors.grey500,
        textTransform: 'capitalize',
        fontWeight: 'bold',
    },
    type: {
        display: 'inline-block',
        margin: '0 5px',
        color: colors.black,
        fontSize: '15px',
    },
    stock: {
        display: 'inline-block',
        margin: '0 5px',
        color: colors.userHot,
        fontWeight: 'bold',
    },
    dates: {
        display: 'inline-block',
        margin: '0 5px',
        color: colors.userCold,
    },
    guess: {
        display: 'inline-block',
        marginRight: '5px',
        color: colors.userHot,
        fontWeight: 'bold',
    },
    mandates: {
        margin: '5px 30px 0 30px',
        width: 'auto',
    },
    mandate: {
        display: 'flex',
        marginTop: '5px',
    },
    mandateType: {
        color: colors.userCold,
        textTransform: 'capitalize',
        fontSize: '15px',
        fontWeight: 'bold',
        width: '20px',
        flexShrink: '0',
    },
    mandateTitle: {
        color: colors.grey700,
        flexGrow: '1',
        fontSize: '13px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.common.relation.link';

    static propTypes = {
        node: PropTypes.shape({
            level: PropTypes.string,
            width: PropTypes.number,
            proxemia: PropTypes.number,
            family: PropTypes.number,
            guessed: PropTypes.bool,
            ceased: PropTypes.bool,
            start: PropTypes.number,
            end: PropTypes.number,
            mandates: PropTypes.arrayOf(PropTypes.object),
            positions: PropTypes.arrayOf(PropTypes.object),
            owned: PropTypes.object,
            shared: PropTypes.object,
        }).isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                {this.renderLink('forward', this.props.node.forward, this.props.node.mandates || [], this.props.node.owned)}
                {this.renderLink('backward', this.props.node.backward, this.props.node.positions || [], this.props.node.shared)}
            </div>
        );
    }

    renderLink(arrow: string, level: string, mandates: Object[], stock: ?Object) {
        if (!level) {
            return null;
        }

        const headerStyle = Object.assign({}, styles.header, this.props.node.ceased ? styles.ceased : {});

        return [
            <div key="header" style={headerStyle}>
                {this.renderArrow(arrow)}

                {/*<span style={styles.level}>{this.props.node.width}</span>*/}

                <span style={styles.type}>
                    {this.renderLevel(level)}
                    {this.renderDates()}
                </span>

                {stock ? (<span style={styles.stock}>{formatStockValue(stock.value || {})}</span>) : null}
            </div>,

            <div key="mandates" style={styles.mandates}>
                {mandates.map(this.renderMandate.bind(this))}
            </div>
        ];
    }

    renderArrow(type: string) {
        if (type === 'forward') {
            const style = Object.assign({},
                styles.arrow,
                styles.forwardArrow,
                this.props.node.ceased ? styles.ceased : {}
            );

            return <span key="forward" style={ style }>▶</span>;
        }

        if (type === 'backward') {
            const style = Object.assign({},
                styles.arrow,
                styles.backwardArrow,
                this.props.node.ceased ? styles.ceased : {}
            );

            return <span key="backward" style={ style }>◀</span>;
        }


        return null;
    }

    renderLevel(level: string) {
        switch (level) {
            case 'i':
                return t('dna.deefind.person.relationLinks.investor');
            case 's':
                return t('dna.deefind.person.relationLinks.shareholder');
            case 'b':
                return t('dna.deefind.person.relationLinks.boardMember');
            case 'a':
                return t('dna.deefind.person.relationLinks.advisor');
            case 'u':
                return t('dna.deefind.person.relationLinks.counselor');
            case 'x':
                return t('dna.deefind.person.relationLinks.executive');
            case 'm':
                return t('dna.deefind.person.relationLinks.manager');
        }

        if (this.props.stock) {
            return t('dna.deefind.person.relationLinks.shareholder');
        }

        return 'Unknown';
    }

    renderDates() {
        if (this.props.node.start && this.props.node.end) {
            return (
                <div style={styles.dates}>
                    {t('dna.deefind.person.relationLinks.onTo', { start: this.props.node.start, end: this.props.node.end })}
                </div>
            );
        }

        if (this.props.node.start) {
            return (
                <div style={styles.dates}>
                    {t('dna.deefind.person.relationLinks.since', { value: this.props.node.start })}
                </div>
            );
        }

        if (this.props.node.end) {
            return (
                <div style={styles.dates}>{t('dna.deefind.person.relationLinks.until', { value: this.props.node.end })}</div>
            );
        }

        return null;
    }

    renderMandate(data, index) {
        const typeStyle = Object.assign({}, styles.mandateType, data.ceased ? styles.ceased : {});
        const titleStyle = Object.assign({}, styles.mandateTitle, data.ceased ? styles.ceased : {});

        return (
            <div style={styles.mandate} key={'mandate' + index}>
                <div style={typeStyle}>{data.level}</div>
                <div style={titleStyle}>{data.titles.map((title, index) => <span key={'t' + index}>{title}<br/></span>)}</div>
            </div>
        );
    }

    renderGuessed(value?: boolean) {
        if (!value) {
            return null;
        }

        return <span style={styles.guess}>?</span>;
    }
}
