// @flow

import type StateStore from 'app/stores/state';
import type { DriveStore, GlobalStore } from '../core/types';
import type { IObservableValue, IObservableArray, ObservableMap } from 'mobx';
import type { AuthCursor, AuthUserSession } from '@deecision/infra-types/common';

import computeStudy from '@deecision/deeligenz-utils/compute/study';
import computeField from '@deecision/deeligenz-utils/compute/field';
import formatReport from '@deecision/deeligenz-utils/format';

import { action, observable, runInAction, toJS } from 'mobx';
import union from 'lodash/union';
import set from 'lodash/set';
import get from 'lodash/get';
import AuthStore from '../auth';
import cleerance from '@deecision/cleerance-utils';

const PATH = 'deeligenz.studies';

export default class DeeligenzStudiesStore {
    contacts: Object[];
    global: GlobalStore;
    state: StateStore;
    auth: AuthStore;
    drive: DriveStore;

    team: ?string;
    parents: Object;

    loaded: IObservableValue<boolean>;
    fetched: IObservableArray<string>;

    studies: ObservableMap<string, Object>;
    archives: ObservableMap<string, Object>;
    templates: ObservableMap<string, Object>;
    fields: ObservableMap<string, Object>;
    // reports: ObservableMap<string, Object>;
    embedded: ObservableMap<string, string[]>;
    answeredCache: ObservableMap<string, Object>;

    constructor(global: GlobalStore, state: StateStore, auth: AuthStore, drive: DriveStore) {
        this.global = global;
        this.state = state;
        this.auth = auth;
        this.drive = drive;

        this.team = null;
        this.parents = {};
        this.loaded = observable.box(false);
        this.fetched = observable.array();
        this.studies = observable.map();
        this.archives = observable.map();
        this.templates = observable.map();
        this.fields = observable.map();
        // this.reports = observable.map();
        this.embedded = observable.map();
        this.answeredCache = observable.map();
    }

    async initialize(session: AuthUserSession, cursor: AuthCursor) {
        this.team = cursor.team;

        runInAction(() => {
            this.parents = {};
            this.loaded.set(false);
            this.fetched.clear();
            this.studies.clear();
            this.templates.clear();
            this.fields.clear();
            // this.reports.clear();
            this.embedded.clear();
            this.answeredCache.clear();
        });

        const access =
            cleerance.resolveFlags(session, cursor).includes('deeligenz.access') ||
            (!cursor.account && cursor.intervention);

        if (!access) {
            return;
        }

        const studies = await this.global.execute('deeligenz', 'studies.list');
        const archives = await this.global.execute('deeligenz', 'studies.archives');
        const templates = session.level === 'account' ? await this.global.execute('deeligenz', 'templates.list') : [];

        runInAction(() => {
            studies.forEach(study => {
                if (!this.fetched.includes(study.id)) {
                    this.studies.set(study.id, study); // important !
                    this.studies.set(study.id, this.computeStudy(study.id));
                }
            });

            archives.forEach(archive => {
                this.archives.set(archive.id, archive);
            });

            templates.forEach(template => {
                this.templates.set(template.id, template);
            });

            this.loaded.set(true);
        });
    }

    getServices = () => {
        const auth = this.auth;
        const drive = this.drive;

        return {
            async acquireUser(uuid): Promise<?string> {
                const user = auth.contacts.users[uuid];
                return user ? user.firstName + ' ' + user.lastName : null;
            },

            async acquireFile(file: { id: string }) {
                return drive.read({ service: 'deeligenz', bucket: 'deeligenz', id: file.id }, 'url');
            },
        };
    };

    @action selectStage = (index: number): void => {
        this.state.enter(PATH, 'menu').select(index);
    };

    @action closeStage = (index: number): void => {
        this.state.enter(PATH, 'menu').remove(index);
    };

    @action selectArchiveStage = (index: number): void => {
        this.state.enter('deeligenz.archives', 'menu').select(index);
    };

    @action closeArchiveStage = (index: number): void => {
        this.state.enter('deeligenz.archives', 'menu').remove(index);
    };

    @action openStudy = (id: string, external: boolean): void => {
        const study = this.studies.get(id);

        if (!study) {
            console.error('invalid study id', id);
        }

        const menu = this.state.enter(PATH, 'menu');
        const data = menu.read() || {};
        const type = external ? 'external' : 'display';

        let cursor = -1;

        (data.items || []).forEach((item, index) => {
            if (cursor < 0 && item.type === type && item.id === id) {
                cursor = index;
            }
        });

        if (cursor < 0) {
            menu.assign(':next', { type, id }).select(':last');
        } else {
            menu.select(cursor);
        }
    };

    @action addTemplate(template: Object) {
        this.templates.set(template.id, template);
    }

    @action openArchive = (id: string, external: boolean): void => {
        const study = this.studies.get(id);

        if (!study) {
            console.error('invalid study id', id);
        }

        const menu = this.state.enter('deeligenz.archives', 'menu');
        const data = menu.read() || {};
        const type = 'display';

        let cursor = -1;

        (data.items || []).forEach((item, index) => {
            if (cursor < 0 && item.type === type && item.id === id) {
                cursor = index;
            }
        });

        if (cursor < 0) {
            menu.assign(':next', { type, id }).select(':last');
        } else {
            menu.select(cursor);
        }
    };

    @action receiveStudy(study: Object) {
        const fields = [];

        for (const field of study.survey || []) {
            this.fields.set(field.id, computeField(field));
            fields.push(field.id);

            this.parents[field.id] = study.id;
            this.answeredCache.set(study.id + '-' + field.questionPayload.question_id, field.id);

            if (field.questionPayload.embedded && field.questionPayload.embedded.question) {
                this.embedded.set(
                    field.questionPayload.embedded.question,
                    union(this.embedded.get(field.questionPayload.embedded.question) || [], [field.id]),
                );
            }
        }

        delete study.survey;
        study.fields = fields || [];
        this.studies.set(study.id, study); // important !
        this.studies.set(study.id, this.computeStudy(study.id));
    }

    fillReport(id: string): Promise<Object> {
        const study = toJS(this.studies.get(id));

        const data = {
            study,
            fields: study.fields.map(i => toJS(this.fields.get(i))),
            computed: study.computed,
        };

        return formatReport(data, this.getServices());
    }

    // @action reportPreFill = async (id: string, key: string, overwrite: boolean = true): Promise<?string> => {
    //     const study = Object.assign({}, toJS(this.studies.get(id)), {
    //         survey: this.studies.get(id).fields.map(i => toJS(this.fields.get(i))),
    //     });
    //     const computed = ReportCompute(study);
    //
    //     if (!computed) {
    //         return null;
    //     }
    //
    //     const services = Object.assign({}, this.getServices(), { acquireAnswer: ReportAcquire(toJS(study), computed) });
    //     const answer = toJS(await ReportCommon(study, computed, services)(key, overwrite));
    //
    //     if (['string', 'number', 'text'].includes(typeof answer)) {
    //         return String(toJS(answer));
    //     }
    //
    //     if (answer && ['string', 'number', 'text', 'boolean', 'select'].includes(answer.type)) {
    //         return String(get(answer, 'value') || '');
    //     }
    //
    //     // @todo hack cause of upload image from report
    //     if (answer && !answer.size) {
    //         return String(get(toJS(answer.answer), 'value') || '');
    //     }
    //
    //     return answer;
    // };

    @action createStudy = async (payload: Object): Promise<Object> => {
        set(payload, 'oddContact.email', get(payload, 'oddContact.email', '').toLowerCase());
        const study = await this.global.execute('deeligenz', 'studies.create', { teamUid: this.team, payload });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.studies.get(study.id);
    };

    @action downloadSummaryReport = async (study: string, name: string): Promise<string> => {
        return this.downloadReport(study, 'summary', name);
    };

    @action downloadFullReport = async (study: string, name: string): Promise<string> => {
        return this.downloadReport(study, 'full', name);
    };

    @action downloadSurveyReport = async (study: string, name: string): Promise<string> => {
        return this.downloadReport(study, 'survey', name);
    };

    downloadReport = async (study: string, reportType: string, name: string): Promise<string> => {
        console.log(`JNG - downloadReport - study: ${study} - reportType: ${reportType} - name: ${name}`);
        const command = (reportType == 'survey') ? 'reports.makeSurvey' : 'reports.make';
        const file = await this.global.execute('deeligenz', command, { id: study, reportType: reportType });
        console.log(`JNG - downloadReport - file:`, file);
        console.log(`JNG - downloadReport - file.id: ${file.id}`);
        return this.drive.download({ service: 'reeport', bucket: 'reeport', id: file.id }, name);
    };

    @action updateStudy = async (id: string, payload: Object): Promise<Object> => {
        set(payload, 'oddContact.email', get(payload, 'oddContact.email', '').toLowerCase());
        const study = await this.global.execute('deeligenz', 'studies.update', { id, payload });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.studies.get(study.id);
    };

    @action setStudyOnSite = async (id: string, data: Object): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.onSite', { id, onSite: data });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.studies.get(study.id);
    };

    @action removeStudy = async (id: string): Promise<Object> => {
        await this.global.execute('deeligenz', 'studies.remove', { id });

        runInAction(() => {
            this.studies.delete(id);

            const index = this.state
                .enter(PATH, 'menu')
                .enter('cursor', 'number')
                .read();

            const menu = this.state.enter(PATH, 'menu');
            menu.select(0);
            menu.remove(index);
        });
    };

    @action fetchStudy = async (id: string): Promise<Object> => {
        if (this.fetched.includes(id)) {
            return this.studies.get(id);
        }

        this.fetched.push(id);
        const study = await this.global.execute('deeligenz', 'studies.fetch', { id });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.studies.get(study.id);
    };

    @action startStudy = async (id: string, template: string): Promise<Object> => {
        await this.global.execute('deeligenz', 'studies.assign', { id, template });
        const study = await this.global.execute('deeligenz', 'studies.apply', { id, transition: 'start' });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action applyStudy = async (id: string, transition: string, comment?: string): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.apply', { id, transition, comment });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action commentStudy = async (id: string, channel: string, comment: string): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.comment', { id, channel, comment });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action setStudyParagraph = async (id: string, key: string, content: string): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.paragraph', { id, key, content });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action setStudyHeader = async (id: string, header: Object): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.header', { id, header });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action setStudyRating = async (id: string, rating: number): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.rating', { id, rating });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action updateContacts = async (
        id: string,
        contacts: {
            oddContact?: {},
            salesContact?: {},
            extraContact?: {},
        },
    ): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'studies.contacts', { id, contacts });

        runInAction(() => {
            this.receiveStudy(study);
        });

        return this.fields.get(study.id);
    };

    @action sendInvitation = async (id: string, payload: Object): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'invitations.create', {
            id,
            payload: Object.assign({}, payload, { email: String(payload.email).toLowerCase() }),
        });

        runInAction(() => {
            this.receiveStudy(study);
        });
    };

    @action deleteInvitation = async (id: string, intervention: string): Promise<Object> => {
        const study = await this.global.execute('deeligenz', 'invitations.delete', { id, intervention });

        runInAction(() => {
            this.receiveStudy(study);
        });
    };

    @action additionalField = async (id: string, payload: Object): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.additional', { id, payload, index: 1 });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));
            const study = toJS(this.studies.get(id));
            study.fields.push(field.id);
            this.studies.set(id, study);
        });

        return this.fields.get(field.id);
    };

    @action answerField = async (id: string, payload: Object): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.answer', { id, payload });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));

            this.answeredCache.set(field.studyId + '-' + field.questionPayload.question_id, field.id);

            if (this.parents[field.id]) {
                this.studies.set(this.parents[field.id], this.computeStudy(this.parents[field.id]));
            }
        });

        return this.fields.get(field.id);
    };

    @action answerEditField = async (id: string, payload: Object): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.answer_edit', { id, payload });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));

            if (this.parents[field.id]) {
                this.studies.set(this.parents[field.id], this.computeStudy(this.parents[field.id]));
            }
        });

        return this.fields.get(field.id);
    };

    @action applyField = async (id: string, transition: string): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.apply', { id, transition });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));

            if (this.parents[field.id]) {
                this.studies.set(this.parents[field.id], this.computeStudy(this.parents[field.id]));
            }
        });

        return this.fields.get(field.id);
    };

    @action commentField = async (id: string, content: string): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.comment', { id, content });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));
        });

        return this.fields.get(field.id);
    };

    @action setFieldFlags = async (id: string, flags: { [flag: string]: boolean }): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.flags', { id, flags });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));

            if (this.parents[field.id]) {
                this.studies.set(
                    this.parents[field.id],
                    this.computeStudy(this.parents[field.id]),
                );
            }
        });

        return this.fields.get(field.id);
    };

    @action setFieldRemark = async (id: string, type: string, remark: string): Promise<Object> => {
        const field = await this.global.execute('deeligenz', 'fields.remark', { id, type, remark });

        runInAction(() => {
            this.fields.set(field.id, computeField(field));

            if (this.parents[field.id]) {
                this.studies.set(this.parents[field.id], this.computeStudy(this.parents[field.id]));
            }
        });

        return this.fields.get(field.id);
    };

    @action uploadFileReport = async (
        studyUid: string,
        identifier: string,
        file: { type: string, content: string },
    ): Promise<?any> => {
        if (!file) {
            return null;
        }

        const touch = await this.global.execute('deeligenz', 'reports.upload', {
            id: studyUid,
            type: file.type,
            identifier,
        });

        await this.uploadFile(touch.file, file.content);

        return Object.assign({}, file, { id: touch.file });
    };

    @action getFileUrl = async (id: string): Promise<string> => {
        return this.drive.locate({ service: 'deeligenz', bucket: 'deeligenz', id });
    };

    @action downloadFile = async (id: string, name: string): Promise<?string> => {
        return this.drive.download({ service: 'deeligenz', bucket: 'deeligenz', id }, name);
    };

    @action uploadFileMeeting = async (
        studyUid: string,
        file: { name: string, type: string, content: string },
    ): Promise<?any> => {
        if (!file) {
            return null;
        }

        const touch = await this.global.execute('deeligenz', 'reports.upload', {
            id: studyUid,
            type: file.type,
            name: file.name,
            identifier: 'meeting',
        });

        await this.uploadFile(touch.file, file.content);

        return Object.assign({}, file, { id: touch.file });
    };

    @action uploadFile = async (id: string, content: string): Promise<void> => {
        return this.drive.upload({ service: 'deeligenz', bucket: 'deeligenz', id }, content);
    };

    @action writeDocumentNda = async (study: string, content: string): Promise<void> => {
        const result = await this.global.execute('deeligenz', 'studies.nda.unsigned', { study });
        await this.uploadFile(result.file, content);

        runInAction(() => {
            const entity = this.studies.get(study);
            set(entity, 'payload.nda.documents.unsigned', result.file);
            this.studies.set(study, entity);
        });
    };

    @action writeDocumentNdaSigned = async (study: string, content: string): Promise<void> => {
        const result = await this.global.execute('deeligenz', 'studies.nda.signed', { study });
        await this.uploadFile(result.file, content);

        runInAction(() => {
            const entity = this.studies.get(study);
            set(entity, 'payload.nda.documents.signed', result.file);
            this.studies.set(study, entity);
        });
    };

    @action requiredNda = async (id: string, required: boolean): Promise<void> => {
        const study = await this.global.execute('deeligenz', 'studies.nda.required', { id, required });
        this.receiveStudy(study);
    };

    computeStudy(id: string) {
        const study = this.studies.get(id);
        const fields = (study.fields || []).map(id => toJS(this.fields.get(id)));
        const computed = computeStudy(study, fields);
        const mainIssues = computed ? computed.mainIssues : {};
        const indexes = computed ? computed.indexes : { report: {}, keyFindings: [] };

        return Object.assign({}, study, { computed, mainIssues, indexes });
    }
}
