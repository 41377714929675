// @flow

import get from 'lodash/get';
import max from 'lodash/max';

export default function computeField(field: any) {
    if (!field) {
        return null;
    }

    const question = field.questionPayload || {};
    const answer = field.answerEditPayload || field.answerPayload || {};
    const flags = (field.answerPayload || {}).flags || {};
    const autoFlags = [];
    const allScores = [];

    if (question.type === 'boolean') {
        const flags = get(question, 'options.flags', {});

        if (answer.value === true) autoFlags.push(flags.yes);
        if (answer.value === false) autoFlags.push(flags.no);

        const scores = get(question, 'options.scores', {});
        if (scores.yes) allScores.push(scores.yes);
        if (scores.no) allScores.push(scores.no);
    }

    if (question.type === 'select' && question.options && question.options.choices) {
        question.options.choices.map((choice, index) => {
            if (answer && answer.choices && answer.choices.includes(index)) {
                if (choice.flag && answer && answer.choices.includes(index)) autoFlags.push(choice.flag);
                if (choice.score) allScores.push(choice.score);
            }
        });
    }

    if (!field.questionPayload) {
        field.questionPayload = {};
    }

    if (!field.questionPayload.flags) {
        field.questionPayload.flags = [];
    }

    if (!field.answerPayload) {
        field.answerPayload = {};
    }

    if (autoFlags.includes('redFlag')) {
        field.answerPayload.flags = Object.assign({}, flags, { allRight: false, concern: false, redFlag: true });
    }

    if (autoFlags.includes('concern') && !flags.redFlag) {
        field.answerPayload.flags = Object.assign({}, flags, { allRight: false, concern: true, redFlag: false });
    }

    if (field.answerEditPayload) {
        if (autoFlags.includes('redFlag')) {
            field.answerEditPayload.flags = Object.assign({}, flags, {
                allRight: false,
                concern: false,
                redFlag: true,
            });
        }

        if (autoFlags.includes('concern') && !flags.redFlag) {
            field.answerEditPayload.flags = Object.assign({}, flags, {
                allRight: false,
                concern: true,
                redFlag: false,
            });
        }
    }

    field.weight = max(allScores) || 0;

    return field;
}
