import PropTypes from 'prop-types';
import React from 'react';
import Display from '../../common/display';
import Flag from '../../common/flag';
import t from 'app/translation';

export default class extends React.PureComponent<any, any> {

    static displayName = 'dna.entity.common.compliance.typologies.fr.opendata.deputies.display';

    static propTypes = {
        relevance: PropTypes.number.isRequired,
        data: PropTypes.object.isRequired,
        relations: PropTypes.array,
    };

    render () {
        let properties = [
            {
                label: t('common.label.birthdate'),
                value: this.props.data.person.birth.date
            },
            {
                label: t('common.label.bithCity'),
                value: this.props.data.person.birth.city
            },
            {
                label: t('email'),
                value: this.props.data.person.contact.mails[0]
            },
            {
                label: t('common.label.phone'),
                value: this.props.data.person.contact.phones[0]
            }
        ];

        for (const mandate of this.props.data.mandates || []) {
            properties = properties.concat([
                t('dna.deefind.typology.deputiesMandateId', { value: mandate.uid }),
                {
                    label: t('dna.deefind.typology.deputiesMandateId'),
                    value: mandate.organe.label
                },
                {
                    label: t('dna.deefind.typology.deputiesStartOn'),
                    value: mandate.dateBegin
                },
                {
                    label: t('dna.deefind.typology.deputiesEndsOn'),
                    value: mandate.dateEnd || '-'
                },
                {
                    label: t('dna.deefind.typology.deputiesPresence'),
                    value: mandate.presenceCount || '-'
                },
                {
                    label: t('dna.deefind.typology.deputiesElection'),
                    value: mandate.election ? mandate.election.reason : '-'
                }
            ]);
        }

        return (
            <Display
                flag={ <Flag char="P" shape="square" color="cold" type="full" /> }
                relevance={ this.props.relevance }
                relations={ this.props.relations }

                name={ this.props.data.person.firstname + ' ' + this.props.data.person.lastname }
                title={t('dna.deefind.typology.deputiesTitle')}
                contents={[]}
                sources={[]}
                properties={ properties }
            />
        );
    }
}
