import PropTypes from 'prop-types';
import React from 'react';
import Views from 'app/views';
import colors from 'app/theme/colors';
import { get } from 'lodash';
import t from 'app/translation';

const styles = {
    container: {
        width: '260px',
        textAlign: 'center',
    },
    title: {
        color: colors.grey700,
        display: 'inline-block',
        fontSize: '15px',
    },
    main: {
        color: colors.userCold,
        display: 'inline-block',
        fontSize: '19px',
        marginLeft: '10px',
    },
    empty: {
        marginTop: '15px',
        lineHeight: '100px',
        color: colors.grey500,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '13px',
        background: colors.grey100,
    },
    details: {
        marginTop: '15px',
        color: colors.grey700,
        lineHeight: '24px',
    },
    line: {
        textAlign: 'center',
    },
    value: {
        textAlign: 'left',
    },
    label: {
        display: 'inline-block',
        width: '80px',
        lineHeight: '24px',
        color: colors.userCold,
    },
    info: {
        color: colors.grey500,
    },
    deemaze: {
        paddingTop: '10px',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'flex-start',
    },
    deemazeLabel: {
        display: 'block',
        width: '80px',
        color: colors.userCold,
        flexShrink: '0',
    },
    deemazeNotice: {
        color: colors.grey500,
        fontSize: '11px',
        lineHeight: '13px',
        paddingTop: '5px',
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.entity.company.display.tabs.financial.valuation';

    static propTypes = {
        entity: PropTypes.object.isRequired,
    };

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.title}>{t('common.label.valuation')}</div>

                {this.renderMain()}
                {this.renderDetails()}
            </div>
        );
    }

    renderMain() {
        if (!this.props.entity.preview.valuation) {
            return <span style={styles.main}>—</span>;
        }

        return (
            <Views.Common.Number
                style={styles.main}
                value={this.props.entity.preview.valuation}
                type="currency"
                scale="M"
            />
        );
    }

    renderDetails() {
        const flags = get(this.props.entity, 'preview.flags', []);

        if (flags.includes('listed')) {
            return (
                <div>
                    <div style={styles.details}>
                        <br />
                        <div style={styles.line}>Market value</div>
                        <Views.Common.Number
                            style={styles.line}
                            value={get(this.props.entity, 'data.valuation.market.value')}
                            type="currency"
                            scale="M"
                        />
                    </div>
                </div>
            );
        }

        return (
            <div style={styles.details}>
                {this.renderValue('Vadis', get(this.props.entity, 'data.valuation.vadis'))}
                {/*this.renderValue('Deals', get(this.props.entity, 'data.valuation.capital'))*/}

                <div style={styles.deemaze}>
                    <Views.Common.Tooltip content={t('common.tooltip.deemaze')}>
                        <span style={styles.deemazeLabel}>Deemaze:</span>
                    </Views.Common.Tooltip>
                        <span style={styles.deemazeNotice}>
                            {flags.includes('holding')
                                ? 'Holding valuation algorithm by Deecision'
                                : flags.includes('fund')
                                ? 'Investment fund valuation algorithm by Deecision'
                                : 'Private equity valuation / peer group algorithm by Deecision'}
                        </span>

                </div>

                {flags.includes('holding')
                    ? this.renderValue('- capital', get(this.props.entity, 'data.valuation.capital'))
                    : flags.includes('fund')
                    ? null
                    : this.renderValue('- head', get(this.props.entity, 'data.valuation.deemaze.estimate'))}

                {this.renderValue('- subsid.', get(this.props.entity, 'data.valuation.stocks'))}
            </div>
        );
    }

    renderValue(label, value) {
        return (
            <div style={styles.value}>
                <span style={styles.label}>{label}</span>

                <Views.Common.Number value={value} type="currency" scale="M" />
            </div>
        );
    }
}
