import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.list.model.reducer';

    static propTypes = {
        type: PropTypes.oneOf([ 'groups', 'sort', 'fuse' ]).isRequired,
        name: PropTypes.string,
        title: PropTypes.node,
        specs: PropTypes.object.isRequired,
        value: PropTypes.string,
        style: PropTypes.object
    };

    render () {
        throw new Error(`Model component must not be rendered.`);
    }
}
