// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';

const styles = {
    container: {
        float: 'left',
        height: '60px',
        position: 'relative',
    },

    baseTitle: {
        color: colors.userLogo,
        fontSize: '30px',
        lineHeight: '30px',
        height: '28px',
        fontWeight: '100',
        paddingTop: '16px',
        fontFamily: 'Montserrat, sans-serif',
        letterSpacing: '-0.05em',
    },
    baseEE: {
        fill: colors.userHot,
        height: '22px',
        margin: '0 1px 0 2px',
    },
    baseVendor: {
        color: colors.userHot,
        fontSize: '11px',
        lineHeight: '15px',
        height: '10px',
        textAlign: 'right',
        letterSpacing: '1px',
        textTransform: 'uppercase',
    },
    baseWord: {
        color: colors.userHot,
    },

    aaisLogo: {
        height: '30px',
        marginTop: '5px',
    },
    aaisTitle: {
        position: 'absolute',
        left: '28px',
        bottom: '10px',
        transformOrigin: 'left bottom',
        transform: 'scale(0.8)',
        width: '312px',
        textAlign: 'center',
    },

    casaLogo: {
        position: 'absolute',
        top: '10px',
        height: '40px',
        left: '0',
    },
    casaTitle: {
        marginLeft: '70px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'app.layout.application.header.title';

    static propTypes = {
        title: PropTypes.string.isRequired,
    };

    render() {
        switch (process.env.APP_DOMAIN || window.location.hostname) {
            case 'abnamro-is.deecision.com':
            case 'preprod-abnamro-is.deecision.com':
                return this.renderAAIS();

            // case 'credit-agricole-sa.deecision.com':
            // case 'preprod-credit-agricole-sa.deecision.com':
            //     return this.renderCASA();

            default:
                return this.renderNormal();
        }
    }

    renderNormal() {
        return (
            <div style={styles.container}>
                <div style={styles.baseTitle}>{this.renderTitle()}</div>
            </div>
        );
    }

    renderAAIS() {
        return (
            <div style={styles.container}>
                {this.renderAAISSvg()}

                <div style={Object.assign({}, styles.baseTitle, styles.aaisTitle)}>{this.renderTitle()}</div>
            </div>
        );
    }

    renderCASA() {
        return (
            <div style={styles.container}>
                {this.renderCASASvg()}

                <div style={Object.assign({}, styles.baseTitle, styles.casaTitle)}>{this.renderTitle()}</div>
            </div>
        );
    }

    renderTitle() {
        const bits = [];
        const words = this.props.title.toUpperCase().split(' ');

        let key = 1;

        for (let index1 = 0; index1 < words.length; index1++) {
            const word = words[index1];

            if (index1 > 0) {
                bits.push(<span key={key++}>&nbsp;</span>);
            }

            if (word.includes('EE')) {
                const parts = word.split('EE');

                for (let index2 = 0; index2 < parts.length; index2++) {
                    if (index2 > 0) {
                        bits.push(this.renderEESvg(key++));
                    }

                    bits.push(<span key={key++}>{parts[index2]}</span>);
                }
            } else {
                bits.push(
                    <span key={key++} style={styles.baseWord}>
                        {word}
                    </span>,
                );
            }
        }

        return bits;
    }

    renderEESvg(key: number) {
        return (
            <svg key={key} style={styles.baseEE} viewBox="0 0  80 46">
                <path
                    stroke="null"
                    id="svg_2"
                    d="m38.167005,0.13655c0,1.518419 0,3.036838 0,4.504642c0,0.303684 -0.050614,0.404912 -0.404912,0.404912c-3.998503,0 -7.997006,0 -11.995508,0c-1.417191,0 -2.834382,0 -4.251573,0.050614c-3.644205,0.050614 -6.883499,1.265349 -9.819108,3.441749c-2.733154,2.024558 -4.555256,4.757712 -5.567536,7.997006c-0.404912,1.265349 -0.809823,2.581312 -1.113507,3.846661c-0.101228,0.455526 -0.101228,0.455526 0.404912,0.455526c0.050614,0 0.101228,0 0.101228,0c10.730159,0 21.460319,0 32.190478,0c0.404912,0 0.50614,0.101228 0.50614,0.50614c-0.050614,1.366577 0,2.68254 0,4.049117c0,0.303684 -0.050614,0.404912 -0.404912,0.404912c-6.984726,0 -13.969453,0.050614 -20.954179,0.101228c-3.846661,0 -7.693322,0.050614 -11.539983,0.050614c-0.303684,0 -0.354298,0.050614 -0.25307,0.354298c0.455526,1.872717 1.012279,3.745433 1.771489,5.516922c1.366577,3.188679 3.745433,5.466308 6.630429,7.237796c2.328242,1.417191 4.85894,2.125786 7.592094,2.075172c5.567536,-0.050614 11.135071,-0.050614 16.702607,-0.101228c0.404912,0 0.50614,0.101228 0.50614,0.50614c-0.050614,1.366577 0,2.68254 0,4.049117c0,0.25307 -0.050614,0.354298 -0.354298,0.354298c-6.023061,0 -12.046122,0.050614 -18.069184,0c-3.593591,-0.050614 -6.832885,-1.265349 -9.71788,-3.391135c-4.757712,-3.492363 -7.946392,-7.997006 -9.262355,-13.817611c-0.607368,-2.631926 -0.657981,-5.314466 -0.455526,-7.997006c0.25307,-3.289907 1.062893,-6.478587 2.68254,-9.414197c3.188679,-5.719377 7.845164,-9.515424 14.374365,-10.780773c0.708595,-0.151842 1.417191,-0.202456 2.1764,-0.303684c0.101228,0 0.202456,0.050614 0.303684,-0.101228l18.221026,0z"
                />
                <path
                    stroke="null"
                    id="svg_3"
                    d="m59.961366,0.13655c0.50614,0.202456 1.062893,0.151842 1.569033,0.202456c6.124289,0.911051 10.831387,4.049117 14.273137,9.110513c2.125786,3.138066 3.289907,6.630429 3.694819,10.375862c0.404912,3.796047 0.25307,7.490866 -1.012279,11.135071c-2.227014,6.276131 -6.276131,10.882001 -12.349806,13.665769c-2.024558,0.961665 -4.200959,1.315963 -6.478587,1.315963c-5.719377,0 -11.489369,0 -17.208746,0c-0.404912,0 -0.455526,-0.101228 -0.455526,-0.455526c0,-1.315963 0.050614,-2.631926 0,-3.947889c0,-0.404912 0.101228,-0.455526 0.455526,-0.455526c5.36508,0.050614 10.730159,0 16.095239,0.101228c4.150345,0.101228 7.592094,-1.467805 10.679546,-4.099731c2.581312,-2.227014 4.049117,-5.11201 4.960168,-8.351303c0.25307,-0.911051 0.50614,-1.822103 0.759209,-2.834382c-1.265349,0 -2.480084,0 -3.694819,0c-9.312969,-0.050614 -18.575323,-0.101228 -27.888292,-0.101228c-0.354298,0 -0.759209,-0.050614 -1.113507,0c-0.25307,0 -0.303684,-0.101228 -0.303684,-0.354298c0,-1.417191 0,-2.783768 0,-4.200959c0,-0.354298 0.101228,-0.404912 0.455526,-0.404912c7.490866,0 14.981732,0 22.472598,0c3.239293,0 6.529201,0 9.768494,0c0.303684,0 0.404912,-0.050614 0.303684,-0.404912c-0.607368,-2.227014 -1.214735,-4.504642 -2.227014,-6.579815c-1.518419,-3.087452 -3.947889,-5.263852 -6.934113,-6.883499c-2.530698,-1.366577 -5.213238,-1.92333 -8.04762,-1.92333c-5.11201,0 -10.22402,-0.050614 -15.33603,-0.050614c-0.354298,0 -0.455526,-0.101228 -0.455526,-0.455526c0,-1.518419 0,-2.986224 0,-4.504642l18.01857,0.101228z"
                />
            </svg>
        );
    }

    renderAAISSvg() {
        return (
            <svg style={styles.aaisLogo} viewBox="0 0 526.18 56.69">
                <defs>
                    <style>{'.cls-1{fill:#f3c000;}.cls-2{fill:#009286;}.cls-3{fill:#868686;}'}</style>
                </defs>
                <title>AA Investment Solutions_RGB</title>
                <polygon className="cls-1" points="21.26 56.69 42.52 35.43 21.26 14.17 21.26 56.69" />
                <polygon className="cls-2" points="0 0 0 35.43 21.26 56.69 21.26 14.17 42.52 35.43 42.52 0 0 0" />
                <path
                    className="cls-3"
                    d="M104.63,70.74l1.4-5.46h7l1.36,5.46h5.42l-6.68-24.61h-6.78L99.55,70.74h5.08ZM112,61.5H107l2.35-10.64h0.07Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M129.39,60h3c2.59,0,3.41,1.33,3.41,3.51,0,3.44-2.45,3.48-3.55,3.48h-2.86V60Zm0-10.06h3.17a3,3,0,0,1,3,3.14c0,2.39-1.57,3.14-2.69,3.14h-3.44V49.91Zm4,20.83c2.18,0,7.6-.27,7.6-6.51,0-3.37-1-5.73-4.6-6.24V57.92c2.8-.54,4.19-2.59,4.19-5.42,0-5.25-3.24-6.37-7.16-6.37h-9.14V70.74h9.1Z"
                    transform="translate(-42.52 -42.52)"
                />
                <polygon
                    className="cls-3"
                    points="109.24 28.22 109.24 10.22 109.31 10.22 116.03 28.22 122.81 28.22 122.81 3.61 118 3.61 118 20.65 117.94 20.65 111.39 3.61 104.44 3.61 104.44 28.22 109.24 28.22"
                />
                <polygon className="cls-3" points="130.89 17.35 134.19 14.05 130.89 10.76 127.6 14.05 130.89 17.35" />
                <path
                    className="cls-3"
                    d="M183.45,70.74l1.4-5.46h7l1.36,5.46h5.42L192,46.13h-6.78l-6.82,24.61h5.08Zm7.33-9.24h-4.91l2.35-10.64h0.07Z"
                    transform="translate(-42.52 -42.52)"
                />
                <polygon
                    className="cls-3"
                    points="165.38 28.22 165.38 9.23 165.45 9.23 169.95 28.22 174.86 28.22 179.6 9.23 179.67 9.23 179.67 28.22 184.68 28.22 184.68 3.61 176.67 3.61 172.58 20.35 172.51 20.35 168.65 3.61 160.37 3.61 160.37 28.22 165.38 28.22"
                />
                <path
                    className="cls-3"
                    d="M239.1,49.91h2.28c1.64,0,2.63.89,2.63,3.24,0,1.57-.58,3.61-2.63,3.61H239.1V49.91Zm0,10.64h1.33c4,0,3.68,2.49,3.68,5.42a11.31,11.31,0,0,0,.55,4.77h5c-0.48-1-.58-5.66-0.58-7.23,0-4.43-3.34-4.71-4.47-4.84V58.61c3.34-.55,4.57-2.9,4.57-6,0-4.19-2.25-6.44-5.76-6.44H234V70.74h5.15V60.55Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M263.16,67.47c-2.9,0-3.27-2.73-3.27-9s0.38-9,3.27-9,3.27,2.73,3.27,9S266.06,67.47,263.16,67.47Zm0,3.68c8.49,0,8.49-6.27,8.49-12.72s0-12.72-8.49-12.72-8.49,6.2-8.49,12.72S254.67,71.15,263.16,71.15Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path className="cls-3" d="M289.58,70.76h-3.16V46h3.16v24.8Z" transform="translate(-42.52 -42.52)" />
                <path
                    className="cls-3"
                    d="M304.49,70.76v-12c0-1.85-.65-3.37-3.06-3.37-3.09,0-3.54,2.75-3.54,5.12V70.76h-2.82V57c0-1.2-.07-2.4-0.17-3.61h3v2.23H298c1.1-2,2.4-2.58,4.64-2.58,3.71,0,4.64,2.23,4.64,5.67v12h-2.82Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M310.26,53.41h3.16l3.88,14.7h0.07l4.19-14.7h3l-5.43,17.35h-3.68Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M329.39,63c0,4.84,1.31,5.8,3.5,5.8,1.92,0,2.92-1.55,3-3.3H339c0,3.85-2.41,5.6-6,5.6s-6.59-1.1-6.59-8.76c0-5.08.58-9.27,6.59-9.27,4.95,0,6.18,2.71,6.18,8.45V63h-9.75Zm6.77-2.13c0-5-1.41-5.67-3.47-5.67-1.79,0-3.26.86-3.3,5.67h6.77Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M348.59,71.1c-3.74,0-6.08-1.65-6-5.63h3.19c0,1,0,3.33,2.89,3.33,1.68,0,3-.86,3-2.64,0-3.09-8.66-3.16-8.66-8.35,0-1.82,1.1-4.74,6-4.74,3.09,0,5.74,1.48,5.5,4.91h-3.13a2.44,2.44,0,0,0-2.71-2.78c-1.51,0-2.68.82-2.68,2.34,0,3.06,8.66,3,8.66,8.28C354.64,69.62,352,71.1,348.59,71.1Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M359,53.41V50.08l2.82-1.27v4.6h3.78v2.13h-3.78V66.15c0,1.1,0,2.51,2.58,2.51,0.21,0,.65-0.07,1.27-0.14v2.16c-0.93.07-1.86,0.27-2.78,0.27-2.68,0-3.88-1.1-3.88-3.09V55.54h-2.85V53.41H359Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M377.65,70.76v-12c0-1.75-.48-3.37-2.75-3.37a3.26,3.26,0,0,0-2.44,1.17,5.17,5.17,0,0,0-.65,2.54V70.76H369V57c0-1.2-.07-2.4-0.17-3.61h3v2.16h0.07c0.83-1.85,2.3-2.51,4.33-2.51,1.58,0,3.4.65,4.05,2.23a4.16,4.16,0,0,1,4.12-2.23c1.82,0,4.74.41,4.74,4.5V70.76h-2.82v-12c0-1.75-.48-3.37-2.75-3.37a2.55,2.55,0,0,0-2.13.65c-0.82.69-1,2.4-1,3.06V70.76h-2.82Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M396.34,63c0,4.84,1.3,5.8,3.5,5.8,1.92,0,2.92-1.55,3-3.3H406c0,3.85-2.4,5.6-6,5.6s-6.59-1.1-6.59-8.76c0-5.08.58-9.27,6.59-9.27,4.95,0,6.18,2.71,6.18,8.45V63h-9.75Zm6.77-2.13c0-5-1.41-5.67-3.47-5.67-1.79,0-3.26.86-3.3,5.67h6.77Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M420.18,70.76v-12c0-1.85-.65-3.37-3.06-3.37-3.09,0-3.54,2.75-3.54,5.12V70.76h-2.82V57c0-1.2-.07-2.4-0.17-3.61h3v2.23h0.14c1.1-2,2.4-2.58,4.64-2.58,3.71,0,4.64,2.23,4.64,5.67v12h-2.82Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M428.59,53.41V50.08l2.82-1.27v4.6h3.78v2.13h-3.78V66.15c0,1.1,0,2.51,2.58,2.51,0.21,0,.65-0.07,1.27-0.14v2.16c-0.93.07-1.85,0.27-2.78,0.27-2.68,0-3.88-1.1-3.88-3.09V55.54h-2.85V53.41h2.85Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M448.21,63.37v0.72c0,3.37,2.13,4.43,4.16,4.43,2.51,0,4.43-1.07,4.43-4,0-5.5-11.13-4.88-11.13-12.33,0-4.4,3.13-6.73,7.28-6.73,4.53,0,7.08,2.2,6.87,7.25h-3.3c0-2.71-.82-4.5-3.81-4.5-1.89,0-3.81,1-3.81,3.64,0,5.46,11.13,4.67,11.13,12.54,0,5.22-3.61,6.9-7.69,6.9-7.32.07-7.32-5.53-7.25-7.9h3.12Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M463.56,62.34c0-5.08.58-9.27,6.59-9.27s6.59,4.19,6.59,9.27c0,7.66-2.92,8.76-6.59,8.76S463.56,70,463.56,62.34Zm10.24-1.2c0-4.74-1.48-5.77-3.64-5.77s-3.64,1-3.64,5.77c0,5.63.72,7.66,3.64,7.66S473.8,66.77,473.8,61.14Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path className="cls-3" d="M484,70.76h-2.82V46H484v24.8Z" transform="translate(-42.52 -42.52)" />
                <path
                    className="cls-3"
                    d="M498.12,53.41h2.82V67.15c0,1.2.07,2.4,0.17,3.61h-3V68.66H498a5,5,0,0,1-4.4,2.44c-3,0-4.67-1.51-4.67-4.47V53.41h2.82v12c0,2.1,1,3.5,3,3.5,1.51,0,3.4-1.13,3.4-4V53.41Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M506.33,53.41V50.08l2.82-1.27v4.6h3.78v2.13h-3.78V66.15c0,1.1,0,2.51,2.58,2.51,0.21,0,.65-0.07,1.27-0.14v2.16c-0.93.07-1.85,0.27-2.78,0.27-2.68,0-3.88-1.1-3.88-3.09V55.54h-2.85V53.41h2.85Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M515.33,46h3.16v3h-3.16V46Zm3,24.8H515.5V53.41h2.82V70.76Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M522.71,62.34c0-5.08.58-9.27,6.59-9.27s6.59,4.19,6.59,9.27c0,7.66-2.92,8.76-6.59,8.76S522.71,70,522.71,62.34Zm10.24-1.2c0-4.74-1.48-5.77-3.64-5.77s-3.64,1-3.64,5.77c0,5.63.72,7.66,3.64,7.66S532.95,66.77,532.95,61.14Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M549.78,70.76v-12c0-1.85-.65-3.37-3.06-3.37-3.09,0-3.54,2.75-3.54,5.12V70.76h-2.82V57c0-1.2-.07-2.4-0.17-3.61h3v2.23h0.14c1.1-2,2.4-2.58,4.64-2.58,3.71,0,4.64,2.23,4.64,5.67v12h-2.82Z"
                    transform="translate(-42.52 -42.52)"
                />
                <path
                    className="cls-3"
                    d="M562.66,71.1c-3.74,0-6.08-1.65-6-5.63h3.19c0,1,0,3.33,2.89,3.33,1.68,0,3-.86,3-2.64,0-3.09-8.66-3.16-8.66-8.35,0-1.82,1.1-4.74,6-4.74,3.09,0,5.74,1.48,5.5,4.91h-3.13a2.44,2.44,0,0,0-2.71-2.78c-1.51,0-2.68.82-2.68,2.34,0,3.06,8.66,3,8.66,8.28C568.7,69.62,566.09,71.1,562.66,71.1Z"
                    transform="translate(-42.52 -42.52)"
                />
            </svg>
        );
    }

    renderCASASvg() {
        return (
            <svg style={styles.casaLogo} viewBox="-200 0 1300 880">
                <g id="g3" transform="translate(319.39838,310.82003)">
                    <g id="g821" transform="matrix(4.5595761,0,0,4.5595761,-440.36219,-467.33137)">
                        <path
                            style={{ fill: '#ed1b2f' }}
                            id="path9"
                            d="M 262.53396,59.148925 212.88782,86.22849 c -3.92634,1.943501 -8.10667,1.420755 -10.9072,-0.940616 -4.32454,-4.88825 -6.39259,-11.846183 -8.087,-17.488236 0,0 6.18446,-1.568239 11.09565,-3.970578 8.64908,-4.512987 57.54305,-29.503206 57.54305,-29.503206 v 24.823071 z"
                        />
                        <path
                            style={{ fill: '#006a4e' }}
                            id="path11"
                            d="m 149.23896,80.586436 -19.47107,40.995424 c -2.19422,4.59165 -4.51299,11.84783 -10.34349,15.23339 -11.84618,8.27381 -40.99707,12.59833 -40.99707,12.59833 l -2.020518,0.26875 c 0,0 36.983888,-69.690743 54.849018,-102.223912 h 39.50586 l 63.44565,140.592502 h -0.63417 -37.79832 c -6.81701,0 -6.34834,-5.65516 -13.54061,-23.78084 -1.50268,-4.13609 -32.99528,-83.683644 -32.99528,-83.683644 z"
                        />
                        <path
                            style={{ fill: '#009597' }}
                            id="path7"
                            d="m 102.12625,53.506872 c 2.25649,6.58103 4.13609,13.352149 5.26515,20.121631 -37.046151,0.752165 -68.074991,19.744732 -91.582183,47.389637 -6.4417407,8.48521 -14.1026769,18.42886 -8.6507117,29.71297 7.8526627,15.56112 39.1158367,12.50494 59.2374697,9.77814 20.780393,-3.85423 36.293995,-8.27382 53.595425,-15.42019 4.88989,-1.88124 14.47957,-5.64206 14.47957,-5.64206 l 9.02762,-4.32454 18.05523,-9.21442 4.88824,-2.44495 31.53684,-16.877 64.55834,-35.495937 v 31.310697 l -51.71583,31.02884 -33.47214,18.42885 c 0,0 -48.14346,23.13028 -73.71705,30.65193 -3.878815,1.17823 -5.407722,1.64525 -9.779781,2.63339 -24.634618,4.88662 -53.595414,8.65072 -76.537253,-2.25649 -12.223085,-5.64205 -22.7550286,-17.41777 -28.162749,-29.33605 -2.254854,-4.88662 -3.408501,-9.96659 -4.349117,-15.23174 0,-1.88124 -0.352321,-4.58346 -0.352321,-6.20741 0,-27.17297 20.6394628,-47.764912 40.195743,-62.996654 4.136086,-3.197109 11.118598,-7.711736 13.868341,-9.191484 3.056182,-1.645258 8.227928,-3.737882 12.929366,-5.994376 9.166905,-3.995157 20.333026,-7.875606 31.310695,-8.698235 7.944427,-0.352321 16.360806,0.376903 19.371106,8.275451 z"
                        />
                        <polygon
                            style={{ fill: '#006a4e' }}
                            transform="matrix(1.6387029,0,0,1.6387029,-39.860797,-23.854652)"
                            id="polygon13"
                            points="172.254,139.59 172.254,153.244 14.233,153.244 14.233,139.59 "
                        />
                    </g>
                </g>
            </svg>
        );
    }
}
