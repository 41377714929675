import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Popover from 'material-ui/Popover';
import { inject, observer } from 'mobx-react';

const KIND_LABELS = {
    M: 'Manager',
    F: 'Fund',
    S: 'Service providers',
};

const styles = {
    container: {
        paddingRight: '15px',
    },
    labelIcon: {
        display: 'inline-block',
        margin: '0 10px 0 15px',
        verticalAlign: 'middle',
    },
    labelItem: {
        display: 'inline-block',
        height: '40px',
        lineHeight: '40px',
        color: colors.grey900,
        cursor: 'pointer',
        fontSize: '19px',
    },
    labelHover: {
        color: colors.userCold,
    },
    menu: {
        background: colors.grey200,
        padding: '5px 0',
        borderRadius: '3px',
        width: '450px',
    },
    kindItem: {
        lineHeight: '26px',
        padding: '0 20px',
        color: colors.userCold,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    kindHover: {
        background: colors.grey300,
    },
    kindCurrent: {
        background: colors.userCold,
        color: colors.white,
    },
    categoryItem: {
        padding: '0 20px 0 50px',
        lineHeight: '26px',
        color: colors.grey600,
        cursor: 'pointer',
    },
    categoryHover: {
        background: colors.grey300,
        color: colors.grey800,
    },
    categoryCurrent: {
        background: colors.userCold,
        color: colors.white,
    },
    itemCount: {
        //fontWeight: 'normal',
        opacity: 0.75,
        display: 'inline-block',
        marginLeft: '10px',
        fontSize: '13px',
    },
    completionWrapper: {
        float: 'right',
        width: '60px',
        //border: '1px solid ' + colors.grey400,
        background: 'white',
        position: 'relative',
        borderRadius: '3px',
        height: '10px',
        marginTop: '8px',
    },
    completionMarker: {
        background: colors.userCold,
        border: '2px solid white',
        borderRadius: '3px',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        maxWidth: '100%',
        boxSizing: 'border-box',
    },
};

@inject(stores => ({ store: stores.deeligenzStudies }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.common.survey.selector';

    static propTypes = {
        menu: PropTypes.object.isRequired,
        kind: PropTypes.string,
        category: PropTypes.string,
        onChange: PropTypes.func.isRequired,
    };

    state = {
        open: false,
        hovered: false,
        hoveredKind: null,
        hoveredCategory: null,
    };

    handleChange(kind, category) {
        this.props.onChange(kind, category);
        this.setState({ open: false });
    }

    render() {
        return (
            <div style={styles.container} ref={element => (this.menuAnchor = element)}>
                <Icon
                    type="nav.chevron.down"
                    style={styles.labelIcon}
                    color={this.state.hovered ? colors.userCold : colors.grey500}
                />

                {this.renderLabel(this.computeLabel())}

                <Popover
                    anchorEl={this.menuAnchor}
                    open={this.state.open}
                    useLayerForClickAway
                    onRequestClose={reason => this.setState({ open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    targetOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <div style={styles.menu}>
                        {Object.keys(this.props.menu).map(key => this.renderKind(key, this.props.menu[key]))}
                    </div>
                </Popover>
            </div>
        );
    }

    renderLabel(data) {
        const style = Object.assign({}, styles.labelItem, this.state.hovered ? styles.labelHover : {});

        return (
            <div
                style={style}
                onMouseEnter={event => this.setState({ hovered: true })}
                onMouseLeave={event => this.setState({ hovered: false })}
                onClick={event => this.setState({ open: true })}
            >
                {this.renderEntry(data.label, data.done, data.count)}
            </div>
        );
    }

    renderKind(key, data) {
        if (!data) {
            return null;
        }

        const style = Object.assign(
            {},
            styles.kindItem,
            this.props.kind === key && !this.props.category
                ? styles.kindCurrent
                : this.state.hoveredKind === key
                ? styles.kindHover
                : {},
        );

        const categories = Object.values(data.categories);

        if (!categories.length) {
            return null;
        }

        return (
            <div key={key}>
                <div
                    style={style}
                    onMouseEnter={event => this.setState({ hoveredKind: key })}
                    onMouseLeave={event => this.setState({ hoveredKind: null })}
                    onClick={event => this.handleChange(key, null)}
                >
                    {this.renderEntry(data.label || key, data.done, data.count, true)}
                </div>

                {categories.map((data, index) => this.renderCategory(`${key}.${index}`, data))}
            </div>
        );
    }

    renderCategory(key, data) {
        const style = Object.assign(
            {},
            styles.categoryItem,
            this.props.category === data.label && this.props.kind === data.kind
                ? styles.categoryCurrent
                : this.state.hoveredCategory === key
                ? styles.categoryHover
                : {},
        );

        return (
            <div
                key={key}
                style={style}
                onMouseEnter={event => this.setState({ hoveredCategory: key })}
                onMouseLeave={event => this.setState({ hoveredCategory: null })}
                onClick={event => this.handleChange(data.kind, data.label)}
            >
                {this.renderEntry(data.label, data.done, data.count, true)}
            </div>
        );
    }

    renderEntry(label, done, count, progress = false) {
        const items = [
            <span key="label">{label}</span>,
            <span key="count" style={styles.itemCount}>
                {done + ' / ' + count}
            </span>,
        ];

        if (progress && count > 0) {
            const percent = (100 * done) / count;

            items.push(
                <span key="progress" style={styles.completionWrapper}>
                    <span style={Object.assign({}, styles.completionMarker, { width: percent + '%' })} />
                </span>,
            );
        }

        return items;
    }

    computeLabel() {
        const kind = this.props.menu[this.props.kind] || {};

        if (!this.props.category) {
            return {
                label: kind.label || '',
                done: kind.done || 0,
                count: kind.count || 0,
            };
        }

        const category = kind.categories ? kind.categories[this.props.category] || {} : {};

        return {
            label: (KIND_LABELS[category.kind] || category.kind) + ' / ' + (category.label || ''),
            done: category.done || 0,
            count: category.count || 0,
        };
    }
}
