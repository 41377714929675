// @flow

import type { EntitySource, LinkItem, LinkData } from '@deecision/deefind-types/model';

import { cleanLink } from '../../clean';
import omit from 'lodash/omit';

export default function makeLinkData(items: LinkItem[], source?: EntitySource): LinkData {
    return cleanLink(combine(items), source);
}

function combine(items: LinkItem[]): LinkData {
    const data = { positions: [], mandates: [], shareholders: [], stocks: [], connections: [] };

    for (const item of items) {
        switch (item.type) {
            case 'position':
                data.positions.push(omit(item, 'type'));
                break;

            case 'mandate':
                data.mandates.push(omit(item, 'type'));
                break;

            case 'shareholder':
                data.shareholders.push(omit(item, 'type'));
                break;

            case 'stock':
                data.stocks.push(omit(item, 'type'));
                break;

            case 'connection':
                data.connections.push(omit(item, 'type'));
                break;
        }
    }

    return data;
}
