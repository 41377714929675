// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';

const styles = {
    container: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: '30px',
        fill: colors.grey500,
    },
    disabled: {
        color: colors.grey500,
    },
    status: {
        lineHeight: '20px',
        width: '300px',
    },
    title: {
    },
    url: {
        color: colors.grey500,
        fontSize: '13px',
        lineHeight: '15px',
    },
    flags: {
        display: 'flex',
    },
    flag: {
        padding: '5px 10px',
        borderRadius: '15px',
        lineHeight: '20px',
        fontSize: '13px',
        color: colors.grey700,
        background: colors.grey300,
        marginLeft: '10px',
    },
    flagActive: {
        color: 'white',
        background: colors.userCold,
    },
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'cleerance.admin.teams.details.settings.service.body';

    static propTypes = {
        access: PropTypes.bool,
        url: PropTypes.string,
        flags: PropTypes.object,
    };

    render() {
        if (!this.props.access) {
            return (
                <div style={styles.container}>
                    <Icon style={styles.icon} type="cleerance.disabled" />
                    <div style={styles.disabled}>{t('cleerance.admin.services.status.disabled')}</div>
                </div>
            );
        }

        const type = this.props.url ? 'private' : 'cloud';
        const url = this.props.url || window.location.origin;

        return (
            <div style={styles.container}>
                <Icon style={styles.icon} type={`cleerance.${type}`} />

                <div style={styles.status}>
                    <div style={styles.title}>
                        {t(`cleerance.admin.services.status.${type}`)}
                    </div>

                    <div style={styles.url}>
                        {url}
                    </div>
                </div>

                {this.renderFlags()}
            </div>
        );
    }

    renderFlags() {
        const children = [];

        for (const key of Object.keys(this.props.flags || {})) {
            const style = Object.assign({}, styles.flag, this.props.flags[key] ? styles.flagActive : {});
            children.push(<div key={key} style={style}>{t(`cleerance.admin.services.${key}`)}</div>);
        }

        return children.length ? (<div style={styles.flags}>{children}</div>) : null;
    }
}
