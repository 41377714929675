import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Item from './item';

const styles = {
    container: {
        display: 'flex',
        cursor: 'pointer',
        flexGrow: '1',
        width: '100%',
        overflow: 'hidden'
    },
    hover: {
        background: colors.bgBlack,
    },
    handle: {
        flexGrow: '0',
        padding: '8px',
    },
    icon: {
        fill: colors.grey500
    },
    empty: {
        fontSize: '15px',
        lineHeight: '40px',
        flexGrow: '1',
        color: colors.grey500,
        padding: '0 5px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
};

export default class extends React.PureComponent<any, any> {

    static displayName = 'app.views.form.widget.select.value';

    static propTypes = {
      placeholder: PropTypes.string,
      choice: PropTypes.object,
      onOpen: PropTypes.func.isRequired
    };

    state = { hover: false };

    render () {
        return (
            <div
                style={ this.state.hover ? Object.assign({}, styles.container, styles.hover) : styles.container }
                onMouseEnter={ event => this.setState({ hover: true }) }
                onMouseLeave={ event => this.setState({ hover: false }) }
                onClick={ this.props.onOpen }
            >
                { this.renderContent() }

                <div style={ styles.handle }>
                    <Icon type="nav.drop.down" style={ styles.icon } />
                </div>
            </div>
        );
    }

    renderContent () {
        if (! this.props.choice)
            return (
                <div style={ styles.empty }>
                    { this.props.placeholder ? this.props.placeholder : 'Click here ...' }
                </div>
            );

        return (
            <Item
                style={ styles.item }
                icon={ this.props.choice.icon }
                title={ this.props.choice.title }
                subtitle={ this.props.choice.subtitle }
            />
        );
    }
}
