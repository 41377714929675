// @flow

import type {
    EntityType,
    EntityFlag,
    FileAttachment,
    EntityEstimate,
    FileRecord,
    LinkProfile,
} from '@deecision/deefind-types/model';

import get from 'lodash/get';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import slugify from 'speakingurl';

export function makeRelatedNames(links: LinkProfile[], type: EntityType): string[] {
    return links
        .filter((link: LinkProfile) => get(link, 'target.type') === type)
        .map((link: LinkProfile) => get(link, 'target.name'))
        .filter(v => !!v)
        .map((name: string) => slugify(name, { separator: ' ' }));
}

export function makeLinksCount(type: 'mandates' | 'positions', links: LinkProfile[], strict: boolean = false): number {
    let count = 0;

    for (const link of links) {
        count += strict ? filter(link[type], link => !link.ceased && !link.guessed).length : (link[type] || []).length;
    }

    return count;
}

export function makeFilesFlags(files: FileAttachment[]): EntityFlag[] {
    return uniq(files.map((file: FileAttachment) => file.data.type).filter(v => !!v));
}

export function makeFortune(files: FileAttachment[]): ?number {
    const values: number[] = [];

    for (const fortune of files) {
        const data: FileRecord = fortune.data;

        if (data.type === 'fortune' && data.value > 0) {
            values.push(data.value);
        }
    }

    return values.length ? Math.max(...values) : undefined;
}

// @todo warning 'type' property does nothing
export function makeTargetsSum(
    type: 'mandates' | 'positions',
    property: string,
    links: LinkProfile[],
): ?EntityEstimate {
    const stats = { found: 0, total: 0, value: 0, deviation: 0 };

    for (const link of links) {
        const target = link.target || {};
        const value = target[property];

        if (link.ceased || (target.type === 'company' && !!target.closed)) {
            continue;
        }

        stats.total += 1;

        if (typeof value === 'number') {
            stats.found += 1;
            stats.value += value;
        } else if (value && value.value) {
            stats.found += 1;
            stats.value += value.value;
            stats.deviation += value.deviation || 0;
        }
    }

    return {
        value: stats.value,
        deviation: stats.deviation,
        reliability: stats.total ? Math.round((100 * stats.found) / stats.total) : undefined,
    };
}
