// @flow

import type { AuthCursor, AuthTarget, AuthUser } from '@deecision/infra-types/common';

import { intersection } from 'lodash';

export default function(user: AuthUser, target: AuthTarget): AuthCursor {
    return findAccount(user, target) || findIntervention(user, target) || {};
}

function findAccount(user: AuthUser, target: AuthTarget): ?AuthCursor {
    for (const account of user.accounts) {
        const teams = intersection(Object.keys(account.memberships), target.teams);

        if (teams.length) {
            return { account: account.id, team: teams[0] };
        }
    }
}

function findIntervention(user: AuthUser, target: AuthTarget): ?AuthCursor {
    for (const intervention of user.interventions) {
        if (target.interventions.includes(intervention.id)) {
            const teams = intersection(Object.keys(intervention.memberships), target.teams);

            if (teams.length) {
                return { intervention: intervention.id, team: teams[0] };
            }
        }
    }
}
