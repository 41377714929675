// @flow

import PropTypes from 'prop-types';

import React from 'react';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import t from 'app/translation';

const styles = {
    container: {
        position: 'relative',
        width: '182px',
        height: '40px',
        flexShrink: '0'
    },
    hint: {
        position: 'absolute',
        margin: '0',
        top: '-25px',
        left: '0',
        color: colors.userCold
    },

    link: {
        backgroundColor: colors.grey200,
        display: 'inline-block',
        width: '60px',
        textAlign: 'center',
        padding: '8px 0',
        cursor: 'pointer'
    },
    linkActive: {
        backgroundColor: colors.userCold,
        display: 'inline-block',
        width: '60px',
        textAlign: 'center',
        padding: '8px 0'
    },
    linkHover: {
        backgroundColor: colors.grey300,
    },
    linkFirst: {
        borderRadius: '3px 0 0 3px'
    },
    linkLast: {
        borderRadius: '0 3px 3px 0'
    },

    icon: {
        fill: colors.userCold,
    },
    iconHover: {
        fill: colors.userCold
    },
    iconActive: {
        fill: 'white'
    }
};

const hints = {
    person: t('dna.deefind.workspace.searchPerson'),
    company: t('dna.deefind.workspace.searchCompany'),
    typology: t('dna.deefind.workspace.searchTypo'),
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.form.kind';

    static contextTypes = {
        updateState: PropTypes.func.isRequired
    };

    props: {
        state?: Object
    };

    state = { hint: '', hover: null };

    render () {
        return (
            <div
                style={ styles.container }
                onMouseLeave={ event => this.setState({ hint: '', hover: null })}
            >
                <p style={ styles.hint }>
                    { this.state.hint }
                </p>

                { this.renderButton('person', 'dna.person', styles.linkFirst) }
                { this.renderButton('company', 'dna.company') }
                { this.renderButton('typology', 'dna.typology', styles.linkLast) }
            </div>
        )
    }

    renderButton (type: string, icon: string, style?: Object = {}) {
        const active = this.props.state && this.props.state.form.kind === type;
        const hover = this.state.hover === type && ! active;

        const containerStyle = Object.assign({},
            styles.link,
            active ? styles.linkActive : {},
            hover ? styles.linkHover : {},
            style
        );

        const iconStyle = Object.assign({},
            styles.icon,
            active ? styles.iconActive : {},
            hover ? styles.iconHover : {},
        );

        const onClick = this.props.state && this.props.state.loading
            ? undefined
            : event => this.context.updateState({ 'form.kind': type });

        return (
            <span
                style={ containerStyle }
                onClick={ onClick }
                onMouseEnter={ event => this.setState({ hint: hints[type] || type, hover: type }) }
            >
                <Icon
                    type={ icon }
                    style={ iconStyle }
                />
            </span>
        )
    }
}
