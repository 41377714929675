import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Download from '../work/filters/download';

const styles = {
    palBox: {
        margin: '50px 10px',
    },
    levels: {
        color: '#333333',
        padding: '12px 12px 0px',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.augmented.pal';

    static propTypes = {
        tool: PropTypes.string,
        filters: PropTypes.object.isRequired,
        exports: PropTypes.shape({ person: PropTypes.array, company: PropTypes.array }),
        onScores: PropTypes.func.isRequired,
        onSave: PropTypes.func.isRequired,
    };

    render() {
        const pal = [
            {
                value: '0',
                label: 'Most accurate',
                default: true,
            },
            {
                value: 'low',
                label: 'Turnovers',
                default: false,
            },
            {
                value: 'mid',
                label: 'WeasseMax',
                default: false,
            },
            {
                value: 'high',
                label: 'Weasse',
                default: false,
            },
        ];

        return (
            <div style={styles.palBox}>
                <div>Potential Accuracy Level</div>
                <div style={styles.levels}>{pal.map((item, index) => this.renderPal(index, item))}</div>
                <Download
                    tool={this.props.tool}
                    listedRelations={this.props.exports.listedRelations || []}
                    predict={this.props.exports.predict || []}
                    persons={this.props.exports.person}
                    companies={this.props.exports.company}
                />
            </div>
        );
    }

    renderPal(index, item) {
        return (
            <div>
                <input
                    type={'radio'}
                    key={index}
                    name={'pal'}
                    value={item.value}
                    id={item.value}
                    checked={(String(this.props.filters.accuracy) === item.value)}
                    onChange={event => {
                        this.props.onScores(Object.assign({}, this.props.filters, { accuracy: event.target.value }));
                        this.props.onSave(Object.assign({}, this.props.filters, { accuracy: event.target.value }));
                    }}
                />
                <label for={item.value}>{item.label}</label>
            </div>
        );
    }
}
