import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import colors from 'app/theme/colors';
import DataProvider from 'app/layout/providers/data';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

const ROLES = {
    director: 'Director',
    manager: 'Manager',
    member: 'Basic',
    invalid: 'Basic',
};

const styles = {
    container: {
        width: '770px',
        margin: '10px 15px',
    },
    head: {
        lineHeight: '30px',
        color: colors.userCold,
        textAlign: 'left',
        height: '30px',
        margin: '0',
        padding: '0',
    },
    cell: {
        lineHeight: '30px',
        borderTop: '1px solid ' + colors.grey300,
        height: '30px',
        margin: '0',
        padding: '0',
    },
    level: {
        color: colors.userCold,
        cursor: 'pointer',
        textDecoration: 'underline',
        textTransform: 'capitalize',
    },
    exclude: {
        color: colors.userHot,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
};

export default class extends React.Component<any, any> {
    static displayName = 'layout.application.header.members.list';

    static propTypes = {
        members: PropTypes.object.isRequired,
        level: PropTypes.string.isRequired,
        onLevel: PropTypes.func.isRequired,
        onExclude: PropTypes.func.isRequired,
    };

    state = {};

    render() {
        const members = [];

        for (const id of Object.keys(this.props.members)) {
            const level = this.props.members[id];
            const edit =
                (level === 'manager' && this.props.level === 'director') ||
                (level === 'member' && ['director', 'manager'].includes(this.props.level));

            members.push({ id, level, edit });
        }

        return (
            <table style={styles.container} cellPadding={0} cellSpacing={0}>
                <thead style={styles.header}>
                    <tr>
                        <th style={styles.head}>First name</th>
                        <th style={styles.head}>Last name</th>
                        <th style={styles.head}>Email</th>
                        <th style={styles.head}>Role</th>
                        <th style={styles.head}></th>
                    </tr>
                </thead>
                <tbody>{orderBy(members, 'level', 'asc').map(this.renderMember.bind(this))}</tbody>
            </table>
        );
    }

    renderMember(member: Object) {
        return (
            <DataProvider
                key={member.id}
                service="cleerance"
                model="account"
                id={member.id}
                scope="public"
                renderValue={value => {
                    return (
                        <tr>
                            <td style={styles.cell}>{value.profile.firstName}</td>
                            <td style={styles.cell}>{value.profile.lastName}</td>
                            <td style={styles.cell}>{value.email}</td>
                            <td style={styles.cell}>{this.renderLevel(value.email, member.level)}</td>
                            <td style={styles.cell}>{member.edit ? this.renderExclude(value.email) : null}</td>
                        </tr>
                    );
                }}
            />
        );
    }

    renderLevel(email: string, level: string) {
        const choices = [];

        if (this.props.level === 'director') {
            if (level === 'member') choices.push('manager');
            if (level === 'manager') choices.push('member');
        }

        if (!choices.length) {
            return (<span style={{ textTransform: 'capitalize' }}>{ROLES[level]}</span>);
        }

        return [
            <a
                key="link"
                style={styles.level}
                onClick={event => {
                    this.setState({ levelOpen: email, levelAnchor: event.currentTarget });
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                }}
            >
                {ROLES[level]}
            </a>,
            <Popover
                key="menu"
                open={this.state.levelOpen === email}
                anchorEl={this.state.levelAnchor}
                anchorOrigin={{horizontal: 'left', vertical: 'top'}}
                targetOrigin={{horizontal: 'left', vertical: 'bottom'}}
                onRequestClose={() => this.setState({ levelOpen: false, levelAnchor: null })}
            >
                <Menu>
                    {choices.map(choice => (
                        <MenuItem
                            key={choice}
                            primaryText={`Turn to ${ROLES[choice]}`}
                            onClick={() => {
                                this.props.onLevel({ email, level: choice });
                                this.setState({ levelOpen: false, levelAnchor: null });
                            }}
                        />
                    ))}
                </Menu>
            </Popover>
        ];
    }

    renderExclude(email: string) {
        return (
            <a
                style={styles.exclude}
                onClick={event => {
                    if (confirm('Are you sure you want to exclude this account from team?')) {
                        this.props.onExclude(email);
                    }

                    event.stopPropagation();
                    return false;
                }}
            >
                Exclude
            </a>
        );
    }
}
