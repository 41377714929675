// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';

const styles = {
    container: {
        display: 'flex',
        lineHeight: '20px',
        margin: '10px 0',
    },
    // minValue: {
    //     textAlign: 'left',
    //     width: '15%',
    //     color: colors.grey500,
    // },
    minCount: {
        textAlign: 'left',
        width: '25%',
        color: colors.userHot,
    },
    label: {
        textAlign: 'center',
        width: '50%',
        color: colors.userCold,
    },
    maxCount: {
        textAlign: 'right',
        width: '25%',
        color: colors.userGreen,
        fontWeight: 'bold',
    },
    // maxValue: {
    //     textAlign: 'right',
    //     width: '15%',
    //     color: colors.grey500,
    // },
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.filters.slider.head';

    static propTypes = {
        minValue: PropTypes.number.isRequired,
        minCount: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        maxCount: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        unit: PropTypes.string,
    };

    render() {
        return (
            <div style={styles.container}>
                {/*<div style={styles.minValue}>{this.props.minValue}</div>*/}
                <div style={styles.minCount}>{this.props.minCount}</div>

                <Views.Common.Tooltip content={this.props.tooltip}>
                <div style={styles.label}>
                    {this.props.label}
                    {this.props.unit ? <span>&nbsp;({this.props.unit})</span> : null}
                </div>
                </Views.Common.Tooltip>

                <div style={styles.maxCount}>{this.props.maxCount}</div>
                {/*<div style={styles.maxValue}>{this.props.maxValue}</div>*/}
            </div>
        );
    }
}
