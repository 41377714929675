// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Icon from 'app/theme/icon';
import Button from 'app/views/common/button';
import Views from 'app/views';
import xlsx from 'xlsx';

const styles = {
    container: {
        border: '1px solid ' + colors.grey300,
        borderRadius: '3px',
        padding: '10px 20px',
        lineHeight: '30px',
        display: 'grid',
        gridTemplateColumns: '2fr 0.8fr 1.2fr',
    },
    export: {
        gridColumn: "span 3",
        margin: '0px',
    }
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.inputs.table';

    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        style: PropTypes.object,
        results: PropTypes.array,
    };

    render() {
        return (
            <div style={Object.assign({}, styles.container, this.props.style || {})}>
                {this.props.items.map((item: Object, index: number) => this.renderItem(index, item))}
                <Button
                    style={styles.export}
                    type="raised"
                    color="cold"
                    label="Exp. Status"
                    icon={<Icon type="do.download" />}
                    onClick={() => this.download()}
                    disabled={!this.props.items.length}
                />
            </div>
        );
    }

    download() {
        const book = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(book, xlsx.utils.aoa_to_sheet(this.props.results), 'Results');

        const content = xlsx.write(book, { type: 'binary', bookType: 'xlsx' });
        const buffer = new ArrayBuffer(content.length);
        const view = new Uint8Array(buffer);

        // https://docs.sheetjs.com/#writing-workbooks
        for (let i = 0; i < content.length; ++i) {
            view[i] = content.charCodeAt(i) & 0xff;
        }

        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const file = `deetect.xlsx`;

        if (window.navigator.msSaveOrOpenBlob) {
            return window.navigator.msSaveBlob(blob, file);
        }

        const link = window.document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file;

        if (document.body) {
            document.body.appendChild(link);
        }

        link.click();

        if (document.body) {
            document.body.removeChild(link);
        }
    }

    renderItem(index: number, item: Object) {
        const percent = typeof item.percent === 'number' ? Math.round(item.percent) + ' %' : '';

        const style = {
            color: item.color === 'red' ? colors.userHot : item.color === 'blue' ? colors.userCold : colors.grey500,
            opacity: item.color === 'red' ? '0.5' : '1',
            textAlign: 'right'
        };

        return [
            <Views.Common.Tooltip key={'l' + index} content={item.tooltip}>
                <span style={style}>{item.label}</span>
            </Views.Common.Tooltip>,
            <span key={'c' + index} style={style}>{item.count}</span>,
            <span key={'r' + index} style={style}>{percent}</span>,
        ];
    }
}
