// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Status from './status';
import Jobs from './jobs';
import Table from './table';
import Menu from './menu';
import t from 'app/translation';

const styles = {
};

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.inputs';

    static propTypes = {
        queue: PropTypes.object.isRequired,
        scope: PropTypes.object.isRequired,
        status: PropTypes.object,
        counts: PropTypes.object.isRequired,
        type: PropTypes.oneOf(['person', 'company']).isRequired,
        onType: PropTypes.func.isRequired,
        style: PropTypes.object,
        results: PropTypes.array.isRequired,
    };

    render() {
        return (
            <div style={this.props.style}>
                <Status
                    status={this.props.status}
                    onStart={event => this.props.queue.start(this.props.scope)}
                    onStop={event => this.props.queue.stop(this.props.scope)}
                />
                <Table
                    style={{ margin: '30px 0' }}
                    items={[
                        this.getItem('missing', 'Not found', t('dna.deetect.common.tooltip.notFound')),
                        this.getItem('empty', 'No link', t('dna.deetect.common.tooltip.noLink')),
                        this.getItem('ambiguous', 'Ambiguous', t('dna.deetect.common.tooltip.ambiguous')),
                        this.getItem('workable', 'Workable', t('dna.deetect.common.tooltip.workable'), 'blue'),
                    ]}
                    results={this.computedResults(this.props.results||[])}
                />

                <Menu
                    counts={this.props.counts}
                    type={this.props.type}
                    onType={this.props.onType}
                />

                <Jobs />
            </div>
        );
    }

    computedResults(results: Object){
        const rows = [];

        if (!results.length){
            return rows;
        }
        for(const result of results){
            rows.push(result.input.row.concat([result.output.status]))
        }

        return rows;
    }

    getItem(type: string, label: string, tooltip: string, color?: string) {
        const total = this.props.counts.inputs || 0;
        const count = this.props.counts[type] || 0;
        const percent = total > 0 ? Math.round(100 * count / total) : 0;

        return { label, count, percent, color, tooltip };
    }
}
