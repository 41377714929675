// flow

import type {
    FileRecord,
    DealRecord,
    FileBase,
    FileFortune,
    FilePolitic,
    FileJustice,
    FileCompliance,
    FileIncome,
    EntitySource,
    EntityOuterTarget,
    EntityInnerTarget,
} from '@deecision/deefind-types/model';

import {
    cleanName,
    cleanIdentifier,
    cleanNumber,
    cleanArray,
    cleanShape,
    cleanString,
    cleanBoolean,
    cleanDate,
    cleanOr,
} from './core';

import { cleanEstimate, cleanSource } from './base';

export function cleanFile(value: any, source?: EntitySource): FileRecord | undefined {
    if (!value || typeof value !== 'object' || !value.type) {
        return undefined;
    }

    switch (value.type) {
        case 'fortune':
            return cleanFortuneFile(value, source);

        case 'politic':
            return cleanPoliticFile(value, source);

        case 'justice':
            return cleanJusticeFile(value, source);

        case 'compliance':
            return cleanComplianceFile(value, source);

        case 'income':
            return cleanIncomeFile(value, source);
    }

    return undefined;
}

export function cleanDeal(value: any, source?: EntitySource): DealRecord | undefined {
    if (!value || typeof value !== 'object' || !value.type || !value.status) {
        return undefined;
    }

    return {
        value: cleanNumber(value.value),
        stake: cleanNumber(value.stake),
        description: cleanString(value.description),
        payment: cleanIdentifier(value.payment),
        dates: cleanShape(value.dates, {
            payment: cleanDate,
            rumour: cleanDate,
            announce: cleanDate,
            completion: cleanDate,
        }),
        type: cleanShape(value.type || {}, {
            kind: cleanIdentifier,
            percent: cleanNumber,
            target: cleanName,
        }),
        status: cleanShape(value.status || {}, {
            kind: cleanIdentifier,
            details: cleanString,
        }),
        source: cleanSource(value.source || null, source),
    };
}

export function cleanTarget(value: any): ?(EntityOuterTarget | EntityInnerTarget) {
    return cleanShape(value, {
        // base
        type: cleanIdentifier,
        name: cleanName,
        relevance: cleanNumber,
        country: cleanIdentifier,

        // outer
        origin: [cleanShape, { provider: cleanNumber, identifier: cleanString }],
        description: cleanString,
        persons: [cleanArray, cleanName],
        companies: [cleanArray, cleanName],
        firstName: cleanName,
        birthDate: cleanDate,

        // inner
        id: cleanString,
        flags: [cleanArray, cleanName],

        // company
        turnover: cleanNumber,
        capital: cleanNumber,
        equity: cleanNumber,
        ebitda: cleanNumber,
        valuation: cleanEstimate,
        activity: cleanIdentifier,
        creation: cleanDate,
        closed: [cleanOr, [cleanDate, cleanBoolean]],
        funded: cleanNumber,

        // person
        lastName: cleanName,
        fortune: cleanNumber,
        income: cleanNumber,
        weasse: cleanEstimate,
        weasseMax: cleanEstimate,
        deceased: [cleanOr, [cleanDate, cleanBoolean]],
    });
}

export function cleanFortuneFile(value: any, source?: EntitySource): FileFortune {
    return Object.assign(
        cleanFileBase(value, source),
        { type: 'fortune' },
        cleanShape(value, {
            value: cleanNumber,
            rank: cleanNumber,
            company: cleanName,
            sector: cleanName,
            since: cleanNumber,
            shared: [
                cleanShape,
                {
                    persons: [cleanArray, cleanName],
                    families: [cleanArray, cleanName],
                    other: cleanBoolean,
                },
            ],
        }),
    );
}

export function cleanPoliticFile(value: any, source?: EntitySource): FilePolitic {
    return Object.assign(
        cleanFileBase(value, source),
        { type: 'politic' },
        cleanShape(value, {
            level: cleanName,
            electoral: cleanBoolean,
            location: cleanName,
            population: cleanNumber,
        }),
    );
}

export function cleanJusticeFile(value: any, source?: EntitySource): FileJustice {
    return Object.assign(
        cleanFileBase(value, source),
        { type: 'justice' },
        cleanShape(value, {
            level: cleanName,
            origin: cleanName,
        }),
    );
}

export function cleanComplianceFile(value: any, source?: EntitySource): FileCompliance {
    return Object.assign(
        cleanFileBase(value, source),
        { type: 'compliance' },
        cleanShape(value, {
            level: cleanName,
        }),
    );
}

export function cleanIncomeFile(value: any, source?: EntitySource): FileIncome {
    return Object.assign(
        cleanFileBase(value, source),
        { type: 'income' },
        cleanShape(value, {
            value: cleanNumber,
            company: cleanName,
        }),
    );
}

export function cleanFileBase(value: any, source?: EntitySource): FileBase | undefined {
    return cleanShape(value, {
        heading: cleanString,
        subheading: cleanString,
        date: cleanDate,
        biography: cleanString,
        picture: cleanString,
        details: [cleanArray, cleanString],
        links: [cleanArray, cleanString],
        version: cleanNumber,
        source: [cleanSource, source],
    });
}
