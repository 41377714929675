import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../common/button';
import Colors from 'app/theme/colors';
import Modal from './modal';
import Icon from 'app/theme/icon';
import toString from 'lodash/toString';

export default class extends React.Component<any, any> {

    static displayName = 'app.views.discussion.button.container';

    static propTypes = {
        type: PropTypes.string.isRequired,
        entities: PropTypes.array,
        preview: PropTypes.element.isRequired,
        color: PropTypes.string,
        target: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        handleDelete: PropTypes.func.isRequired,
    };

    render () {
        return (
          <span>
                <Button
                    type="flat"

                    icon={ <Icon type='base.discussion'/> }
                    label={
                        toString(this.props.entities ? this.props.entities.length : 0 )
                    }
                    color={ this.props.color }
                    onClick={ event => this.refs.modal.open() }
                />
                <Modal
                    ref="modal"
                    entities={ this.props.entities }
                    preview={ this.props.preview }
                    target={ this.props.target }
                    handleSubmit={ this.props.handleSubmit }
                    handleDelete={ this.props.handleDelete }
                />
            </span>
        );
    }
}
