import PropTypes from 'prop-types';
import React from 'react';
import colors from 'app/theme/colors';
import Views from 'app/views';
import get from 'lodash/get';
import t from 'app/translation';

let styles = {
    table: {
        width: '100%'
    },
    header: {
        padding: '10px 5px',
        color: colors.grey500
    },
    cell: {
        borderTop: '1px solid ' + colors.grey200,
        padding: '10px 5px',
    }
};

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.result.typology.302.row';

    static contextTypes = {
        openEntity: PropTypes.func.isRequired
    };

    static propTypes = {
        entity: PropTypes.object,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        style: PropTypes.object,
    };

    render () {
        const openable = !!get(this.props.entity, 'target.id');

        const cellProps = {
            onClick: event => this.handleClick(),
            onMouseEnter: openable ? this.props.onMouseEnter : undefined,
            onMouseLeave: openable ? this.props.onMouseLeave : undefined,
            style: Object.assign({}, styles.cell, { cursor: openable ? 'pointer' : 'default' }),
        };

        return (
            <tr style={ this.props.style }>
                <td { ...cellProps }>{ get(this.props.entity, 'location', '-') }</td>
                <td { ...cellProps }>{ get(this.props.entity, 'target.name', get(this.props.entity, 'file.subheading', '(?)')) }</td>
                <td { ...cellProps }>{ this.renderStatus() }</td>
            </tr>
        );
    }

    renderStatus() {
        const relevance = get(this.props.entity, 'relevance', 0);

        if (get(this.props.entity, 'target.id')) {
            if (relevance >= 75) {
                return 'Perfect match';
            } else {
                return `Match (${relevance}%)`;
            }
        }

        const ids = get(this.props.entity, 'target.ids');

        if (ids && ids.length > 0) {
            return `${relevance >= 75 ? 'Multiple' : 'Ambiguous'} (${ids.length})`;

            if (relevance >= 75) {
                return 'Multiple';
            } else {
                return 'Ambiguous';
            }
        }

        return t('common.label.noMatch');
    }

    handleClick() {
        const id = get(this.props.entity, 'target.id');

        if (id) {
            return this.context.openEntity(id);
        }

        const ids = get(this.props.entity, 'target.ids');

        if (ids && ids.length) {
            // open list
        }

        // open file
    }
}
