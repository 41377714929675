import PropTypes from 'prop-types';
import React from 'react';

import DeefindProvider from 'app/layout/providers/deefind';

import PersonHandle from 'dna/entity/person/handle';
import CompanyHandle from 'dna/entity/company/handle';

export default class extends React.Component<any, any> {
    static displayName = 'dna.entity.handle';

    static propTypes = {
        id: PropTypes.string.isRequired,
        onSelect: PropTypes.func.isRequired
    };

    render () {
        return (
            <DeefindProvider
                type="entity"
                id={ this.props.id }
                renderEntity={ (entity, state) => {
                    const properties = {
                        entity,
                        state,
                        onSelect: this.props.onSelect,
                    };

                    switch (entity.type) {
                        case 'person':
                            return (<PersonHandle { ...properties } />);

                        case 'company':
                            return (<CompanyHandle { ...properties } />);

                        default:
                            console.error('Invalid entity for handle:', entity);
                    }
                } }
            />
        );
    }
}
