import React from 'react';
import Header from './header';
import PropTypes from 'prop-types';
import Timeline from './timeline';
import Views from 'app/views';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import t from 'app/translation';

@inject(stores => ({ store: stores.teemProspects, global: stores.global }))
@observer
export default class extends React.Component<any, any> {
    static displayName = 'teem.prospects.display';

    static contextTypes = {
        selectProject: PropTypes.func.isRequired,
    };

    static propTypes = {
        state: PropTypes.object,
        style: PropTypes.object,
    };

    componentDidMount () {
        if (this.props.state && this.props.state.id) {
            this.context.selectProject(this.props.state.id);
        }
    }

    render () {
        if (this.props.store.loading.get()) {
            return <Views.Common.Loader title="Loading project" />;
        }

        const entity = this.props.store.prospects.get(this.props.state.id);

        if (! entity) {
            return (
                <div style={ this.props.style }>
                    {t('common.message.noFound')}
                </div>
            );
        }

        const events = [];
        for (const id of entity.events && entity.events.length ? toJS(entity.events) : []) {
          events.push(toJS(this.props.store.fields.get(id)));
        }

        const milestones = [];
        for (const id of entity.milestones && entity.milestones.length ? toJS(entity.milestones) : []) {
          milestones.push(toJS(this.props.store.fields.get(id)));
        }

        const meetings = [];
        for (const id of entity.meetings && entity.meetings.length ? toJS(entity.meetings) : []) {
            meetings.push(toJS(this.props.store.fields.get(id)));
        }

        const session = toJS(this.props.global.session.get());
        const cursor = toJS(this.props.global.cursor.get());
        const accounts = session.user ? session.user.accounts || [] : [];
        const account = accounts.filter(account => account.id === cursor.account)[0] || {};
        const memberships = account.memberships || {};
        const manager = ['manager', 'director'].includes((memberships[entity.teamUid] || {}).level);

        return (
            <div style={ this.props.style }>
                <Header
                    entity={ entity }
                    manager={manager}
                />

                <Timeline
                    entity={ entity }
                    events={ events.filter(v => !!v) }
                    milestones={ milestones.filter(v => !!v) }
                    meetings={ meetings.filter(v => !!v) }
                />
            </div>
        );
    }
}
