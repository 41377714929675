// @flow

export default {
    type: 'menu',
    pinned: [{
        type: 'summary',
        pinned: true
    }],
    prototype: {
        type: 'router',
        routes: {
            summary: {
                pinned: {
                    type: 'boolean'
                },
                list: {
                    type: 'list'
                }
            },
            display: {
                id: {
                    type: 'string'
                },
                attendees: {
                    type: 'list'
                }
            }
        }
    }
};
