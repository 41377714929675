// flow

import type { MediaCollection, EntitySource, MediaLink } from '@deecision/deefind-types/model';

import { cleanSource } from './index';
import { cleanName, cleanArray, cleanShape, cleanString, cleanDate, cleanPercentage, cleanObject } from './core';

export function cleanMedia(value: any, source?: EntitySource): MediaCollection | undefined {
    if (!value || typeof value !== 'object' || (!value.news && !value.links)) {
        return undefined;
    }

    return {
        news: cleanMediaLinks(value.news, source),
        links: cleanMediaLinks(value.links, source),
    };
}

export function cleanMediaLinks(value: any, source?: EntitySource): MediaLink[] {
    return cleanArray(value, [
        cleanShape,
        {
            url: cleanName,
            urlPdf: cleanName,
            domain: cleanName,
            title: cleanName,
            desc: cleanString,
            icon: cleanString,
            date: cleanDate,
            category: cleanString,
            registerType: cleanString,
            reliability: cleanString,
            relevance: cleanPercentage,
            source: [cleanSource, source],
        },
    ]);
}
