// @flow

import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Head from './head';
import Body from './body';

const styles = {
    container: {
        borderRadius: '5px',
        marginBottom: '10px',
        padding: '10px 40px',
    },
};

const LOGPOW = 3;

export default class extends React.Component<any, any> {
    static displayName = 'dna.detector.work.filters.slider';

    static propTypes = {
        minValue: PropTypes.number.isRequired,
        minCount: PropTypes.number.isRequired,
        maxValue: PropTypes.number.isRequired,
        maxCount: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        value: PropTypes.number.isRequired,
        unit: PropTypes.string,
        logScale: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onSet: PropTypes.func.isRequired,
    };

    constructor(props: Object) {
        super(props);
        this.state = this.makeState(props);
    }

    UNSAFE_componentWillReceiveProps(props: Object): void {
        this.setState(this.makeState(props));
    }

    normalize(value: number, min: number, max: number) {
        return Math.min(Math.max(min, max), Math.max(Math.min(min, max), value));
    }

    makeState(props: Object) {
        const value = this.normalize(props.value, props.minValue, props.maxValue);
        const position = this.makePosition(value);
        const hover = this.state ? this.state.hover : false;

        return { moving: false, value, position, hover };
    }

    makeValue(position: number) {
        position = this.normalize(position, 0, 1);
        const min = Math.min(this.props.minValue, this.props.maxValue);
        const max = Math.max(this.props.minValue, this.props.maxValue);

        if (this.props.minValue > this.props.maxValue) {
            position = 1 - position;
        }

        if (this.props.logScale) {
            position = Math.pow(position, LOGPOW);
        }

        return this.normValue(min + (max - min) * position);
    }

    makePosition(value: number) {
        if (this.props.minValue === this.props.maxValue) {
            return 0.5;
        }

        const min = Math.min(this.props.minValue, this.props.maxValue);
        const max = Math.max(this.props.minValue, this.props.maxValue);
        let position = (value - min) / (max - min);

        if (this.props.minValue > this.props.maxValue) {
            position = 1 - position;
        }

        if (this.props.logScale) {
            position = Math.pow(position, 1 / LOGPOW);
        }

        return position;
    }

    normValue(value: number) {
        if (value >= 10) {
            return Math.round(value);
        }

        return parseFloat(value.toFixed(1));
    }

    renderValue(value: number) {
        if (value >= 10) {
            return String(Math.round(value));
        }

        return value.toFixed(1);
    }

    render() {
        const disabled = !this.props.minCount && !this.props.maxCount;

        // const opacity = this.state.moving ? '0.5' : '1';
        // const minValue = this.renderValue(this.props.minValue);
        // const maxValue = this.renderValue(this.props.maxValue);

        return (
            <div
                style={Object.assign(
                    {},
                    styles.container,
                    this.state.hover ? { background: colors.grey200 } : {},
                    disabled ? { background: colors.grey200, opacity: '0.5' } : {},
                )}
                onMouseEnter={disabled ? undefined : () => this.setState({ hover: true })}
                onMouseLeave={disabled ? undefined : () => this.setState({ hover: false })}
            >
                <Head
                    minValue={this.props.minValue}
                    maxValue={this.props.maxValue}
                    minCount={this.props.minCount}
                    maxCount={this.props.maxCount}
                    tooltip={this.props.tooltip}
                    label={this.props.label}
                    unit={this.props.unit}
                />

                <Body
                    position={this.state.position}
                    value={this.renderValue(this.state.value)}
                    unit={this.props.unit}
                    disabled={disabled}
                    onEnd={event => this.props.onSet(this.state.value)}
                    onChange={position => {
                        position = this.normalize(position, 0, 1);
                        const value = this.makeValue(position);
                        this.props.onChange(value);
                        this.setState({ position, value });
                    }}
                />
            </div>
        );
    }
}
