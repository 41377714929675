// @flow

import React from 'react';
import Scope from 'app/layout/providers/scope';
import DeefindProvider from 'app/layout/providers/deefind';

import Persons from './persons';
import Companies from './companies';
import Typology from './typology/index';
import Views from 'app/views';

export default class extends React.PureComponent<any, any> {
    static displayName = 'dna.explorer.search.result';

    props: {
        state: Object,
    };

    render() {
        if (!this.props.state || !this.props.state.form || !this.props.state.result) {
            return null;
        }

        if (this.props.state.form.kind !== this.props.state.result.kind) {
            return null;
        }

        if (!(this.props.state.result.items || []).length && !(this.props.state.result.ids || []).length) {
            return <Views.Common.Empty title="No results found" />;
        }

        switch (this.props.state.result.kind) {
            case 'person':
                return (
                    <Scope path="list" type="list">
                        <DeefindProvider
                            type="list"
                            ids={this.props.state.result.ids}
                            renderList={(entities, state) => (
                                <Persons
                                    entities={entities}
                                    state={state}
                                    company={this.props.state.form.company}
                                    age={this.props.state.form.age}
                                    gender={this.props.state.form.gender}
                                />
                            )}
                        />
                    </Scope>
                );

            case 'company':
                return (
                    <Scope path="list" type="list">
                        <DeefindProvider
                            type="list"
                            ids={this.props.state.result.ids}
                            renderList={(entities, state) => (
                                <Companies
                                    entities={entities}
                                    state={state}
                                    person={this.props.state.form.person}
                                    activity={this.props.state.form.activity}
                                />
                            )}
                        />
                    </Scope>
                );

            case 'typology':
                return <Typology state={this.props.state} />;
        }

        return null;
    }
}
