import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import omit from 'lodash/omit';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import uuid from 'uuid';
import Widget from './widget';

const styles = {
    container: {
        display: 'flex',
    },
    widget: {
        marginRight: '50px',
        flexGrow: '1',
    },
    empty: {
        textAlign: 'center',
        lineHeight: '50px',
        color: colors.userHot,
    },
    draft: {
        textAlign: 'center',
        lineHeight: '50px',
        color: colors.userWarning,
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        width: '40px',
        marginRight: '10px',
    },
    menuEntry: {
        padding: '7px',
        borderRadius: '3px',
        background: 'transparent',
        cursor: 'pointer',
        textAlign: 'center',
        marginBottom: '5px',
    },
    menuActive: {
        background: colors.userCold,
    },
    menuDisabled: {
        cursor: 'default',
    },
};

export default class extends React.Component {
    static displayName = 'deeligenz.studies.common.survey.field.answer';

    static contextTypes = {
        uploadFile: PropTypes.func.isRequired,
    };

    static propTypes = {
        field: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        onAnswer: PropTypes.func,
        onCorrection: PropTypes.func,
        onFlags: PropTypes.func,
        external: PropTypes.bool,
    };

    state = { active: 'input' };

    isAnswered(answer) {
        return answer && (
            answer.hasOwnProperty('nd') ||
            answer.hasOwnProperty('na') ||
            answer.hasOwnProperty('value') ||
            answer.hasOwnProperty('values') ||
            answer.hasOwnProperty('choices') ||
            answer.hasOwnProperty('rows')
        ) && !answer.draft;
    }

    isEditable() {
        return !['ddq', 'onSite'].includes(this.props.field.questionPayload.scope);
    }

    componentDidMount() {
        if ((this.isEditable() || this.props.field.questionPayload.scope === 'ddq') && !this.isAnswered(this.props.field.answerPayload)) {
            return this.setState({ active: this.props.readOnly ? 'correction' : 'edit' });
        }

        if (this.props.field.answerEditPayload && this.props.readOnly) {
            this.setState({ active: this.isEditable() ? this.props.external ? 'edit' : 'correction' : 'input' });
        }
    }

    async handleSubmit(question, payload, correction) {
        const submit = correction && this.props.onCorrection ? this.props.onCorrection : this.props.onAnswer;

        if (!submit) {
            console.warn('Tried to handle deeligenz field submit without "onAnswer" prop.');
            return;
        }
        if (payload.na) {
            await submit(payload);
        } else if (payload.nd) {
            const flags = (this.props.field.answerPayload || {}).flags || {};
            flags.onSite = payload.meeting;
            await submit(payload);
            this.props.onFlags(flags);
        } else if (question.type === 'file') {
            if (payload.value.content) {
                const fileId = (payload.value ? payload.value.id : null) || uuid.v4();
                const data = Object.assign({}, omit(payload.value, 'content'), { id: fileId, create: true });
                await submit({ draft: payload.draft, value: Object.assign({}, data, { uploadError: false }) });
                try {
                    await this.context.uploadFile(fileId, payload.value.content);
                } catch(error) {
                    await submit({ draft: payload.draft, value: Object.assign({}, data, { uploadError: true }) });
                }
            } else {
                await submit(payload);
            }
        } else if (question.type === 'file[]') {
            const contents = {};
            const uploadErrors = {};
            payload.values = (payload.values || []).filter(v => !!v);

            for (const value of payload.values || []) {
                if (!value.content) continue;
                if (!value.id) value.id = uuid.v4();
                contents[value.id] = value.content;
                value.create = true;
                delete value.content;
            }

            await submit(payload);

            for (const id of Object.keys(contents)) {
                try {
                    await this.context.uploadFile(id, contents[id]);
                    uploadErrors[id] = false;
                } catch(error) {
                    uploadErrors[id] = true;
                }
            }

            for (const value of payload.values || []) {
                value.uploadError = uploadErrors[value.id];
                delete value.create;
            }

            await submit(payload);
        } else {
            await submit(payload);
        }

        if (this.state.active === 'edit') {
            this.setState({ active: this.isEditable() ? this.props.external ? 'edit' : 'correction' : 'input' });
        }
    }

    render() {
        const question = this.props.field.questionPayload || {};
        let answer = this.props.field.answerPayload || {};
        let readOnly = this.props.readOnly || ['pending', 'validated'].includes(this.props.field.status || 'todo');

        if (!this.props.external && this.props.onAnswer) {
            if (this.state.active === 'input') {
                readOnly = true;
            }

            if (this.state.active === 'correction') {
                answer = this.props.field.answerEditPayload || {};
                readOnly = true;
            }

            if (this.state.active === 'edit') {
                answer =
                    (!this.isEditable() ? this.props.field.answerPayload : this.props.field.answerEditPayload) || {};
                readOnly = this.props.readOnly;
            }
        }

        answer.flags = Object.assign(
            {},
            this.props.field.answerPayload ? this.props.field.answerPayload.flags : {},
            this.props.field.answerEditPayload ? this.props.field.answerEditPayload.flags : {},
        );

        return (
            <div style={styles.container}>
                {this.renderMenu(question.scope)}
                <div style={styles.widget}>{this.renderWidget(question, answer, readOnly)}</div>
            </div>
        );
    }

    renderMenu(scope: string) {
        if (!this.props.onCorrection) {
            return null;
        }

        if (!this.isEditable()) {
            return (
                <div style={styles.menu}>
                    {this.renderEntry('input', 'deeligenz.survey.ddq', 'Show answer')}
                    {this.renderEntry('edit', 'do.update', 'Edit answer')}
                </div>
            );
        }

        return (
            <div style={styles.menu}>
                {this.renderEntry('input', 'deeligenz.survey.input', 'Original answer')}
                {this.renderEntry('correction', 'deeligenz.survey.ddq', 'Edited answer')}
                {this.renderEntry('edit', 'do.update', 'Edit answer')}
            </div>
        );
    }

    renderEntry(state: string, icon: string, tip: string) {
        const active = state === this.state.active;
        const disabled = state === 'correction' && !this.props.field.answerEditPayload;

        const style = Object.assign(
            {},
            styles.menuEntry,
            disabled ? styles.menuDisabled : {},
            active ? styles.menuActive : {},
        );

        return (
            <Views.Common.Tooltip content={tip} place="right">
                <div style={style} onClick={disabled ? undefined : () => this.setState({ active: state })}>
                    <Icon
                        type={icon}
                        style={{ fill: disabled ? colors.grey300 : active ? 'white' : colors.userCold }}
                    />
                </div>
            </Views.Common.Tooltip>
        );
    }

    renderWaiting(question, answer, readOnly) {
        if (answer.draft) {
            return (<div>
                <div style={styles.draft}>Draft</div>
                <div>
                <Widget
                    question={question}
                    answer={answer}
                    readOnly={readOnly}
                    onChange={
                        readOnly
                            ? undefined
                            : payload =>
                                  this.handleSubmit(
                                      question,
                                      payload,
                                      this.state.active === 'edit' && this.isEditable(),
                                  )
                    }
                />
                </div>
            </div>)
        }
        return <div style={styles.empty}>Waiting answer</div>;
    }

    renderWidget(question, answer, readOnly) {
        if (readOnly && answer.na) {
            return <div style={styles.empty}>Non Applicable</div>;
        }

        if (readOnly && answer.nd) {
            if (answer.meeting === true) return <div style={styles.empty}>Non Disclosed, to be reviewed on meeting.</div>;
            if (answer.meeting === false) return <div style={styles.empty}>Non Disclosed, no review</div>;
            return <div style={styles.empty}>Non Disclosed</div>;
        }

        if (readOnly && !this.isAnswered(answer)) {
            return this.state.active === 'correction' ? (
                <div style={styles.empty}>No correction</div>
            ) : (
                this.renderWaiting(question, answer, readOnly)
            );
        }

        return (
            <Widget
                question={question}
                answer={answer}
                readOnly={readOnly}
                onChange={
                    readOnly
                        ? undefined
                        : payload =>
                              this.handleSubmit(
                                  question,
                                  payload,
                                  this.state.active === 'edit' && this.isEditable(),
                              )
                }
            />
        );
    }
}
