import React from 'react';
import PropTypes from 'prop-types';
import colors from 'app/theme/colors';
import Views from 'app/views';
import Icon from 'app/theme/icon';
import { inject } from 'mobx-react';

const styles = {
    container: {
        lineHeight: '25px',
        width: '48%',
    },
    title: {
        color: colors.userCold,
        fontSize: '13px',
        borderBottom: '1px solid ' + colors.grey300,
        marginBottom: '5px',
    },
    light: {
        color: colors.grey500,
    },
    icon: {
        width: '16px',
        height: '16px',
        float: 'left',
        fill: colors.grey400,
        margin: '4px 9px 4px 0',
    },
};

@inject(stores => ({ contacts: stores.auth.contacts }))
export default class extends React.Component<any, any> {
    static displayName = 'deeligenz.studies.external.summary.invitations';

    static propTypes = {
        entity: PropTypes.object.isRequired,
        contacts: PropTypes.object.isRequired,
        handleDeleteInvitation: PropTypes.func,
        mainExternal: PropTypes.bool
    };

    render() {
        const properties = this.computeProperties(this.props.entity);
        return (
            <div style={styles.container}>
                <div style={styles.title}>Invitations</div>
                <div>
                    {/* @todo replace placeholder values by const properties */}
                    <Views.Common.Properties values={properties} />
                </div>
            </div>
        );
    }

    computeProperties(entity) {
        const invitations = entity.payload.oddInvitations || [];

        const properties = [];
        for (let invitation of invitations) {
            // get informations from contacts
            properties.push({label: <div><Icon type="base.attendee" style={styles.icon} />{invitation.name}</div>, value: this.renderPropertyAction(invitation, invitation.email), })
        }

        return properties;
    }

    renderPropertyAction(id, value) {
        return (
            <div>
                <Icon type="base.email" style={styles.icon} /> <span style={{verticalAlign: 'top'}}>{value}</span> {!this.props.mainExternal ? null : <Views.Common.Button style={{verticalAlign: 'super'}} onClick={() => {this.props.handleDeleteInvitation(this.props.entity.id, id)}} type="round" mini={true} color="hot" icon={<Icon type="do.delete"/>} />}
            </div>
        )
    }
}
